import React from 'react';
import PropTypes from 'prop-types';
import withRadioPlayerContext from '../../../context/withRadioPlayerContext';
import RadioHeader1 from '../Radio/RadioHeader1';

const TopHeaderMegamenuChannels = ({ ch1, ch2 }) => (
  <div data-name="channels" className="w-full mb-12 smlg:mb-6 flex -mx-2 sm:mx-0 sm:block justify-between">
    <div className="w-1/2 sm:w-full sm:mb-4 mx-2 sm:mx-0">
      {ch1 && <RadioHeader1 {...ch1.header} program_title_multilang={ch1.program_title_multilang} channel={`ch${ch1.channel}`} category="black" />}
    </div>
    <div className="w-1/2 sm:w-full sm:mb-4">
      {ch2 && <RadioHeader1 {...ch2.header} program_title_multilang={ch2.program_title_multilang} channel={`ch${ch2.channel}`} category="black" />}
    </div>
  </div>

);

TopHeaderMegamenuChannels.propTypes = {
  ch1: PropTypes.shape({}),
  ch2: PropTypes.shape({}),
};
TopHeaderMegamenuChannels.defaultProps = {
  ch1: null,
  ch2: null,
};

export default withRadioPlayerContext(TopHeaderMegamenuChannels);
