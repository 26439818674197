import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import languages from './util/languages';

const LanguageTabsForms = ({ children, activeTabStyle, width, tabStyle }) => {
  const [activeLanguage, setActiveLanguage] = useState('en');

  const handlerActiveLanguage = (e) => {
    const { name } = e.target.dataset;
    setActiveLanguage(name);
  };

  const tabs = languages.map(lang => (
    <span
      key={lang}
      className={`${activeLanguage === lang ? `${activeTabStyle} pl-2 pl-7 flex items-center cursor-pointer font-semibold text-xs rounded-t uppercase` : `${tabStyle} tab pl-2 pl-7 flex items-center cursor-pointer font-semibold text-xs rounded-t uppercase`}`}
      data-name={lang}
      onClick={handlerActiveLanguage}
    >
      {lang}
    </span>
  ));

  return (
    <div className={`text-white language-tabs ${width}`}>
      <div className="flex">
        {tabs}
      </div>
      {children(activeLanguage, languages)}
    </div>
  );
};

LanguageTabsForms.propTypes = {
  children: PropTypes.func.isRequired,
  activeTabStyle: PropTypes.string,
  tabStyle: PropTypes.string,
  width: PropTypes.string,
};

LanguageTabsForms.defaultProps = {
  activeTabStyle: '',
  tabStyle: '',
  width: '',
};

export default LanguageTabsForms;
