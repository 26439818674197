import React from 'react';
import PropTypes from 'prop-types';
import FilterButton from './FilterButton';

const FilterHolder = ({
  onHandleButtonSelect, name, checkedItems,
}) => (
  <FilterButton checkedItems={checkedItems} onHandleButtonSelect={onHandleButtonSelect} params={name} />
);

FilterHolder.propTypes = {

};

FilterHolder.defaultProps = {
};

export default FilterHolder;
