import React from 'react';
import { PropTypes } from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

class PSSelect extends React.Component {
  constructor(props) {
    super(props);
    const { value, options } = props;
    this.state = {
      value: typeof value !== 'undefined' ? value : false,
      options,
    };
  }

  static getDerivedStateFromProps(props) {
    const { options } = props;
    return {
      value: typeof props.value !== 'undefined' ? props.value : false,
      options,
    };
  }

  titleForSelected(selected) {
    const { options } = this.state;
    const { titleKey } = this.props;
    if (Array.isArray(selected)) {
      return selected.map((selectedItem) => {
        if (Array.isArray(options)) {
          return titleKey ? options[selectedItem][titleKey] : selectedItem;
        }
        return titleKey ? options[selectedItem][titleKey] : options[selectedItem];
      }).join(', ');
    }
    return titleKey ? options[selected][titleKey] : selected;
  }

  renderItems() {
    const { titleKey } = this.props;
    const { options } = this.state;
    if (Array.isArray(options)) {
      return (
        options.map(val => (
          this.renderItem(val, titleKey ? val[titleKey] : val)
        ))
      );
    }
    return (
      Object.keys(options).map(val => (
        this.renderItem(val, options?.[val]?.[titleKey])
      ))
    );
  }

  renderItem(val, title) {
    const { value } = this.state;

    const isSelected = ((Array.isArray(value) && value.indexOf(val) > -1) || (!Array.isArray(value) && value === val));

    return (
      <MenuItem
        key={val}
        value={val}
        classes={{
          root: 'font-xs',
        }}
        divider
      >
        {PSSelect.renderItemContent(isSelected, title)}
      </MenuItem>
    );
  }

  static renderItemContent(isSelected, title) {
    return (
      <div className="flex">
        <span className="w-3 h-3 border border-black rounded-sm mr-1 flex">
          {' '}
          {isSelected && <span className="text-base leading-under font-icon icon-checkbox text-green" /> }
          {' '}
        </span>
        {title}
      </div>
    );
  }

  renderValue(selected) {
    const { empty } = this.props;
    if (Array.isArray(selected)) {
      if (selected.length === 0) {
        return empty;
      }
      return PSSelect.renderItemContent(true, this.titleForSelected(selected));
    }
    return selected ? PSSelect.renderItemContent(true, this.titleForSelected(selected)) : empty;
  }

  render() {
    const {
      onChange,
      multiple,
    } = this.props;
    const { value } = this.state;

    const hasValue = ((Array.isArray(value) && value.length > 0) || (!Array.isArray(value) && value));

    return (
      <FormControl>
        <Select
          multiple={multiple}
          displayEmpty
          value={value}
          onChange={onChange}
          classes={{
            root: `h-8 sm:h-12 flex items-center font-bold text-xs ${hasValue ? 'active' : ''}`,
          }}
          IconComponent={() => (
            <div className="h-8 sm:h-12 flex items-center absolute pin-r pin-t mr-5 pb-2 md:pt-4">
              {hasValue ? <span className="font-icon icon-arrow-down text-black text-xs " /> : <span className="font-icon icon-arrow-down text-white text-xs " />}
            </div>
          )}
          renderValue={selected => this.renderValue(selected)}
          MenuProps={{
            getContentAnchorEl: null,
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
          }}
        >
          {this.renderItems()}
        </Select>
      </FormControl>
    );
  }
}

PSSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/require-default-props
  empty: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types, react/require-default-props
  titleKey: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types, react/require-default-props
  value: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types, react/require-default-props
  options: PropTypes.any,
  // eslint-disable-next-line react/require-default-props
  multiple: PropTypes.bool,
};

PSSelect.defaultProps = {
  titleKey: '',
};

export default PSSelect;
