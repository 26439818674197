/* eslint-disable react/no-unused-state */
import React, { useContext } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

import getRadioShowsNow from '../api/graphql/queries/getRadioShowsNow';

const RadioPlayerContext = React.createContext();
export const RadioPlayerConsumer = RadioPlayerContext.Consumer;

export const useRadioContext = () => useContext(RadioPlayerContext);

// radio channels metadata.
const rps1 = {
  streamUrl: 'https://streamer.radio.co/s23e62020a/listen',
  statusUrl: 'https://public.radio.co/stations/s23e62020a/status',
  channel: 1,
  defaultTitle: {
    es: 'RPS',
    en: 'RPS',
    ca: 'RPS',
  },
  header: {
    title: {
      en: 'Now on RPS ',
      ca: 'Ara al RPS ',
      es: 'Ahora en RPS ',
    },
    category: 'radio',
    icon1Url: 'http://static.fragment.agency/ps/current.svg',
    icon2Url: 'http://static.fragment.agency/ps/radio-arrow-down.svg',
    radioPlayButton: {
      playing: false,
      url: '#',
      icon1: 'font-icon icon-player text-white',
      icon2: 'font-icon icon-pause text-white',
    },
  },
};
const rps2 = {
  streamUrl: 'https://s2.radio.co/s06c201c45/listen',
  statusUrl: 'https://public.radio.co/stations/s06c201c45/status',
  channel: 2,
  defaultTitle: {
    es: 'Primavera Sounds',
    en: 'Primavera Sounds',
    ca: 'Primavera Sounds',
  },
  header: {
    title: {
      en: 'Now on Primavera Sounds ',
      ca: 'Ara al Primavera Sound ',
      es: 'Ahora en Primavera Sounds ',
    },
    category: 'radio',
    icon1Url: 'http://static.fragment.agency/ps/current.svg',
    icon2Url: 'http://static.fragment.agency/ps/radio-arrow-down.svg',
    radioPlayButton: {
      playing: false,
      url: '#',
      icon1: 'font-icon icon-player text-white',
      icon2: 'font-icon icon-pause text-white',
    },
  },
};
const rps3 = {
  streamUrl: 'https://s2.radio.co/s06c201c45/listen',
  statusUrl: 'https://public.radio.co/stations/s06c201c45/status',
  channel: 3,
  defaultTitle: {
    es: 'RPS-3 (RANDOM)',
    en: 'RPS-3 (RANDOM)',
    ca: 'RPS-3 (RANDOM)',
  },
  header: {
    title: {
      en: 'Now on RPS-3',
      ca: 'Ara al RPS-3',
      es: 'Ahora en el RPS-3',
    },
    category: 'radio',
    icon1Url: 'http://static.fragment.agency/ps/current.svg',
    icon2Url: 'http://static.fragment.agency/ps/radio-arrow-down.svg',
    radioPlayButton: {
      playing: false,
      url: '#',
      icon1: 'font-icon icon-player text-white',
      icon2: 'font-icon icon-pause text-white',
    },
  },
};

const preroll = {
  streamUrl: 'https://assets.primaverasound.com/preroll/RPS_Locu_Processed_RPS+powered+by+seat.mp3',
  image: 'https://assets.primaverasound.com/preroll/preroll600x600.jpg',
  channel: 3,
  defaultTitle: {
    es: 'RPS-3 (RANDOM)',
    en: 'RPS-3 (RANDOM)',
    ca: 'RPS-3 (RANDOM)',
  },
  header: {
    title: {
      en: 'Now on RPS-3',
      ca: 'Ara al RPS-3',
      es: 'Ahora en el RPS-3',
    },
    category: 'radio',
    icon1Url: 'http://static.fragment.agency/ps/current.svg',
    icon2Url: 'http://static.fragment.agency/ps/radio-arrow-down.svg',
    radioPlayButton: {
      playing: false,
      url: '#',
      icon1: 'font-icon icon-player text-white',
      icon2: 'font-icon icon-pause text-white',
    },
  },
  program_title_multilang: {
    en: 'RPS - Powered by SEAT',
    ca: 'RPS - Powered by SEAT',
    es: 'RPS - Powered by SEAT',
  },
};

class RadioContextProvider extends React.Component {
  state = {
    playerOpen: true,
    playerPosition: 'pin-b',
    playing: false,
    ch1: null,
    ch2: null,
    ch3: null,
    preroll,
    radioActive: 'ch1',
    activeChannel: 'ch1',
    podcast: null,
    showRadioPlayer: false,
    pageHasPlayer: true,
    togglePlaying: channel => this.togglePlaying(channel),
    showPlayer: () => this.showPlayer(),
    hidePlayer: () => this.hidePlayer(),
    hideRadio: value => this.hideRadio(value),
    skipPreroll: () => this.skipPreroll(),
    setPlayerPosition: position => this.setPlayerPosition(position),
    ssrDone: false,
    scrollDirDown: true,
  };

  radioRef = React.createRef();

  // componentWillMount = () => {
  //   const { ssrDone } = this.state;
  //   if (ssrDone) {
  //     const { staticContext: { data } } = this.props;
  //     this.processFetchShows(data);
  //   }
  // }

  componentDidMount() {
    this.setState({ showRadioPlayer: true, ssrDone: true });
    this.fetchShows();

    // only radio 3 fetches from radio all the time, other rps fetch when isPlaylist = true
    // this.interval = setInterval(() => {
    //   this.fetchShows();
    // }, 100000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    window.removeEventListener('ended');
  }

  fetchShows = () => {
    // console.log('********************* Fetching radio shows *********************');

    getRadioShowsNow().then((radioProgramsNow) => {
      if (radioProgramsNow) {
        this.processFetchShows(radioProgramsNow);
      } else {
        // console.log('radioProgramsNow = null');
      }
    });
  };

  processFetchShows = (radioProgramsNow) => {
    const { ch1, ch2 } = this.state;
    const channels = {};
    const prevChannels = { ch1, ch2 };

    radioProgramsNow.forEach((ch) => {
      if (ch.channel === 1) {
        channels.ch1 = { ...ch, ...rps1 };
        // if (ch.isPlaylist) return this.fetchRadioco(rps1, 'ch1');
      }
      // if (ch.channel === 2) {
      //   channels.ch2 = { ...ch, ...rps2 };
      //   // if (ch.isPlaylist) return this.fetchRadioco(rps1, 'ch1');
      // }
    });

    // console.log(prevChannels, channels);

    if (JSON.stringify(prevChannels) !== JSON.stringify(channels)) {
      this.setState({
        ...channels,
      });
    }
    // this.fetchRadioco(rps3, 'ch3');
  }

  // processFetchShows = (radioProgramsNow) => {
  //   radioProgramsNow.forEach((ch) => {
  //     if (ch.channel === 1) {
  //       this.setState({
  //         ch1: {
  //           ...ch,
  //           ...rps1,
  //         },
  //       });
  //       if (ch.isPlaylist) return this.fetchRadioco(rps1, 'ch1');
  //       return 'ok';
  //     }
  //     this.setState({
  //       ch2: {
  //         ...ch,
  //         ...rps2,
  //       },
  //     });
  //     return this.fetchRadioco(rps2, 'ch2');
  //   });
  //   this.fetchRadioco(rps3, 'ch3');
  // }

  fetchRadioco = (radio, channel) => {
    if (radio.statusUrl) {
      axios.get(radio.statusUrl)
        .then((response) => {
          const { data } = response;
          const datas = data.current_track;
          // eslint-disable-next-line react/destructuring-assignment
          const channelData = Object.assign({}, this.state[channel], {
            image: datas.artwork_url_large,
            trackMultilang: {
              es: datas.title,
              en: datas.title,
              ca: datas.title,
            },
          });

          this.setState(() => ({ [channel]: channelData }));
        })
        .catch((error) => {
          console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: fetchRadioco Error: ${error}`);
        });
    }
  };

  prerollPlay = async (channel, showPreroll) => {
    const radio = this.radioRef.current;
    if (showPreroll === 1) {
      await this.updateRadio('preroll');
      radio.play();
      radio.addEventListener('ended', async () => {
        await this.updateRadio(channel);
        this.setState({ playing: true, showRadioPlayer: true });
        setTimeout(() => radio.play(), 500);
      });
    } else {
      await this.updateRadio(channel);
      this.setState({ playing: true, showRadioPlayer: true });
      // setTimeout(() => radio.play(), 500);
    }
  }

  showPlayer = () => {
    this.setState({
      showRadioPlayer: true,
      podcast: null,
    });
  }

  hideRadio = (value) => {
    this.setState({
      pageHasPlayer: !value,
    });
  }

  hidePlayer = () => {
    const { playing } = this.state;
    const radio = this.radioRef.current;
    if (playing) {
      radio.pause();
    }
    this.setState({
      showRadioPlayer: false,
      playing: false,
      podcast: null,
    });
  }

  skipPreroll = () => {
    const { activeChannel } = this.state;
    this.togglePlaying(activeChannel);
  }

  togglePlaying = async (channel) => {
    const {
      playing, radioActive, podcast, scrollDirDown,
    } = this.state;
    const radio = this.radioRef.current;

    if (!radio.src) radio.src = this.state[radioActive]?.streamUrl;

    // show prereoll only if random # is 1, randomness set to 3
    const showPreroll = Math.floor(Math.random() * 3) + 1;

    if (channel) {
      this.setState({ activeChannel: channel });
      // if channel str includes radioprimavera it means that we need to play from mixcloud
      if (/^ch/.test(channel)) {
        console.log("🚀 ~ file: RadioContextProvider.jsx ~ line 314 ~ RadioContextProvider ~ togglePlaying= ~ channel", channel)
        await this.updateRadio(channel);
        if (!playing || channel !== radioActive) {
          // this.prerollPlay(channel, showPreroll);
        }
        if (channel === radioActive) {
          if (!playing) {
            this.setState({ playing: true, showRadioPlayer: true });
            radio.play();
          } else {
            this.setState({ playing: false });
            radio.pause();
          }
        } else {
          this.setState({ playing: true, showRadioPlayer: true });
          radio.play();
        }
      } else {
        this.fetchShows();
        radio.pause();
        if (/^\/RadioPrimaveraSound/i.test(channel)){
          const mixcloudUrl = /^\/RadioPrimaveraSound/i.test(channel) ? `https://www.mixcloud.com${channel}` : `https://open.spotify.com/episode/${channel}`;
          if (podcast === mixcloudUrl) {
            this.setState({ playing: false, podcast: null, showRadioPlayer: false });
          } else {
            this.setState({ podcast: null, playerPosition: 'pin-b -mb-16' });
            setTimeout(() => this.setState({
              playing: false, podcast: mixcloudUrl, showRadioPlayer: false, scrollDirDown: !scrollDirDown, playerPosition: 'pin-b',
            }), 100);
          }
        } else {
          const spotifyUrl = `https://open.spotify.com/episode/${channel}`;
          if(window){
            window.open(spotifyUrl)
          }
        }
      }
    } else {
      if (!playing) {
        this.setState({ playing: true });
        radio.play();
      } else {
        this.setState({ playing: false });
        radio.pause();
      }
    }
  };

  updateRadio = channel => new Promise((resolve) => {
    this.setState({ radioActive: channel }, resolve);
  });

  setPlayerPosition = (playerPosition) => {
    this.setState({
      playerPosition,
    });
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    // console.log(nextState);
    const {
      playerOpen,
      playing,
      radioActive,
      hidePlayer,
      playerOne,
      playerPosition,
      setPlayerPosition,
      showPlayer,
      togglePlaying,
      ch1,
      ch2,
    } = this.state;

    const values = playerOpen !== nextState.playerOpen
    || playing !== nextState.playing
    || radioActive !== nextState.radioActive
    || hidePlayer !== nextState.hidePlayer
    || playerOne !== nextState.playerOne
    || playerPosition !== nextState.playerPosition
    || setPlayerPosition !== nextState.setPlayerPosition
    || showPlayer !== nextState.showPlayer
    || togglePlaying !== nextState.togglePlaying
    || JSON.stringify(ch1) !== JSON.stringify(nextState.ch1)
    || JSON.stringify(ch2) !== JSON.stringify(nextState.ch2);

    return values;
  }

  render() {
    const {
      radioActive,
      playing,
      ssrDone,
    } = this.state;
    const { children } = this.props;

    // if(!ssrDone) {
    //   return (<div>{children}</div>)
    // }

    const radio = this.state[radioActive];
    // console.log('TCL: render -> radio', radio);
    return (
      <RadioPlayerContext.Provider value={this.state}>
        {ssrDone && radio && <audio ref={this.radioRef} />}
        {children}
      </RadioPlayerContext.Provider>
    );
  }
}

RadioContextProvider.propTypes = {
  staticContext: PropTypes.shape(),
  children: PropTypes.shape().isRequired,
};

RadioContextProvider.defaultProps = {
  staticContext: null,
};

export default RadioContextProvider;
