import React from 'react';

const LauncherSkeleton = ({ deviceWidth }) => {
  const launcherSkeletonMedium = () => (
    <>
      {Array(2).fill().map((item, index) => (
        <div key={index} className={`flex w-full ${index === 1 ? 'flex-row-reverse' : 'flex-wrap'}`}>
          {Array(1)
            .fill()
            .map((item, index) => (
              <div key={index} className="my-2 launcherItem llg:w-1/3 lg:w-1/2 md:w-1/2 sm:w-full px-8px sm:px-0 mb-4 sm:h-launcherSingle-sm md:h-launcherSingle-md lg:h-launcherSingle-lg llg:h-launcherSingle-llg">
                <div className="skeleton-bg w-full h-full" />
              </div>
            ))}
          <div className="launcherItem llg:w-1/3 lg:w-1/2 md:w-1/2 sm:w-full px-8px sm:px-0 my-2">
            {Array(2)
              .fill()
              .map((item, index) => (
                <div key={index} className={`flex w-full flex-wrap items-center double ${index === 1 ? 'flex-row-reverse' : ''}`}>
                  <div className="skeleton-bg w-1/2 sm:h-launcherDouble-sm md:h-launcherDouble-md lg:h-launcherDouble-lg llg:h-launcherDouble-lg" />
                  <div className="w-1/2 px-4 lg:px-8px sm:px-8px md:px-8px">
                    <span className="skeleton-bg" width="90%" height="20px" />
                    <div className="sm:my-2 my-3 flex">
                      {Array(2)
                        .fill()
                        .map((item, index) => (
                          <div className="mr-2 skeleton-bg w-1/5" style={{ height: '20px' }} key={index} />
                        ))}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      ))}
    </>
  );

  const launcherSkeletonBig = () => (
    <>
      {Array(1)
        .fill()
        .map((item, index) => (
          <div key={index} className="my-2 launcherItem llg:w-1/3 lg:w-1/2 md:w-1/2 sm:w-full px-8px sm:px-0 mb-4 sm:h-launcherSingle-sm md:h-launcherSingle-md lg:h-launcherSingle-lg llg:h-launcherSingle-llg">
            <div className="skeleton-bg w-full h-full" />
          </div>
        ))}
      <div className="launcherItem llg:w-1/3 lg:w-1/2 md:w-1/2 sm:w-full px-8px sm:px-0 my-2">
        {Array(2)
          .fill()
          .map((item, index) => (
            <div key={index} className={`flex w-full flex-wrap items-center double ${index === 1 ? 'flex-row-reverse' : ''}`}>
              <div className="w-1/2 sm:h-launcherDouble-md md:h-launcherDouble-md lg:h-launcherDouble-lg llg:h-launcherDouble-llg">
                <div className="skeleton-bg w-full h-full" />
              </div>
              <div className="w-1/2 px-4 lg:px-8px sm:px-8px md:px-8px">
                <div className="mb-2 skeleton-bg" width="15%" key={index} style={{ height: '20px' }} />
                <div className="sm:my-2 my-3 flex">
                  {Array(2)
                    .fill()
                    .map((item, index) => (
                      <div className="mr-2 skeleton-bg w-1/5" style={{ height: '20px' }} key={index} />
                    ))}
                </div>
              </div>
            </div>
          ))}
      </div>
      {Array(1)
        .fill()
        .map((item, index) => (
          <div key={index} className="my-2 launcherItem llg:w-1/3 lg:w-1/2 md:w-1/2 sm:w-full px-8px sm:px-0 mb-4 sm:h-launcherSingle-sm md:h-launcherSingle-md lg:h-launcherSingle-lg llg:h-launcherSingle-llg">
            <div className="skeleton-bg w-full h-full" />
          </div>
        ))}
    </>
  );

  const getSkeletonBydeviceWidth = () => {
    if (deviceWidth > 640 && deviceWidth < 1280) {
      return launcherSkeletonMedium();
    } if (deviceWidth > 1279) {
      return launcherSkeletonBig();
    }
    return launcherSkeletonBig();
  };

  return (
    <>
      {getSkeletonBydeviceWidth()}
    </>
  );
};

export default LauncherSkeleton;
