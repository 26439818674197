import React from 'react';
import { withRouter } from 'react-router-dom';
import { PropTypes } from 'prop-types';
// import Linkify from 'linkifyjs/react';

import Emoji from 'react-emoji-render';
import emojione from 'emojione';

import { useTheme } from '../context/themeContext';
import { useChat } from '../context/chatContext';

import useWhisperModalManagement from '../hooks/useWhisperModalManagement';
import ViewerCard from './ViewerCard';
import { updateMutedUsersArray, createPrivateChannel } from '../utils/helpers';

const MessageItem = ({
  message, messageOwner, userColor, isAuth, privateChat,
}) => {
  const {
    notLogged, userNickname, channelGeneral, userData,
  } = useChat();

  if (!messageOwner) {
    return <></>;
  }

  const { color } = useTheme();
  const {
    isWhisperModalOpened,
    openWhisperModal,
    closeWhisperModal,
  } = useWhisperModalManagement();

  const {
    nickname: messageOwnerNickname,
    userId: messageOwnerUserId,
  } = messageOwner;

  const handleSendInvitation = async () => {
    const channelName = messageOwnerUserId !== userData.userId ? messageOwnerNickname : userNickname;
    createPrivateChannel({
      name: channelName, creator: userData, invited: messageOwner,
    }).then(res => console.log(res)).catch(err => console.log(err));
    closeWhisperModal();
  };

  const handleMute = async () => {
    updateMutedUsersArray({
      channelUrl: channelGeneral, userId: messageOwnerUserId, type: 'mute',
    });
  };

  const handleUnMute = async () => {
    updateMutedUsersArray({
      channelUrl: channelGeneral, userId: messageOwnerUserId, type: 'unmute',
    });
  };

  const handleOpenWhisperModal = () => {
    openWhisperModal();
  };


  return (
    <div className={`w-full relative px-4 break-words leading-normal text-${color} message-item`}>
      <span
        onClick={!notLogged && (messageOwnerUserId !== userData.userId) ? handleOpenWhisperModal : null}
        className={`font-americaBold break-all ${!notLogged && messageOwnerUserId !== userData.userId && !privateChat ? 'cursor-pointer hover:underline' : ''}`}
        style={{ color: userColor }}
      >
        {messageOwnerNickname}
      </span>
      <span className="font-americaRegular">:</span>
      {message.includes('https') || message.includes('www')
        ? <span className="font-americaRegular pl-1">
            {/* <Linkify> */}
            {emojione.shortnameToImage(message)}
            {/* </Linkify> */}
          </span>
        : <span className="font-americaRegular pl-1"><Emoji text={message} onlyEmojiClassName="text-xl" /></span>
    }
      {isWhisperModalOpened && !privateChat && (
        <ViewerCard
          {...{
            message,
          }}
          nicknameColor={userColor}
          messageOwner={messageOwner}
          isAuth={isAuth}
          handleMute={handleMute}
          handleUnMute={handleUnMute}
          handleSendInvitation={handleSendInvitation}
          closeWhisperModal={closeWhisperModal}
          userColor={userColor}
        />
      )}
    </div>
  );
};

MessageItem.propTypes = {
  match: PropTypes.shape({}).isRequired,
  userId: PropTypes.string,
  nickname: PropTypes.string,
  message: PropTypes.string,
  userColor: PropTypes.string,
  isAuth: PropTypes.bool,
  privateChat: PropTypes.bool,
  messageOwner: PropTypes.shape({}),
};

MessageItem.defaultProps = {
  userId: '',
  nickname: '',
  userColor: '',
  message: '',
  isAuth: false,
  privateChat: false,
  messageOwner: {},
};

export default withRouter(MessageItem);
