import React from 'react';
import { PropTypes } from 'prop-types';

import { withRouter } from 'react-router-dom';
import Link from '../../../util/link';

import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';
import ComponentIterator from '../../../containers/ComponentIterator';
import imageModifier from '../helpers/imageModifier';
import translateObj from '../../../util/translateObj';
import Navigator from '../Navigator';

const ARIA_LABEL_NEXT = {
  en: 'Next artist',
  es: 'Siguiente artista',
  ca: 'Següent artista',
  pt: 'Próximo artista',
};
const ARIA_LABEL_PREV = {
  en: 'Previous artist',
  es: 'Artista anterior',
  ca: 'Artista anterior',
  pt: 'Artista anterior',
};

const SliderBannerArtist = ({
  image, left, right, prevArtist, nextArtist, match, url, title,
}) => {
  const styleNavButtons = (img, lang) => ({
    background: `url(${imageModifier(translateObj(img, lang), '1440x620')})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  });

  // margin top that will be assigned to each components
  const marginItems = 'mt-4';

  const addMarginToComponents = (component) => {
    const item = component;
    item.margin = marginItems;
    return item;
  };

  // adding margin

  left.map(component => addMarginToComponents(component));
  right.map(component => addMarginToComponents(component));

  return (
    <RoutesContext.Consumer>
      {({ language: contextLanguage, enteredFrom }) => {
        const localization = getLocalization(contextLanguage, enteredFrom, match);

        return (
          <>
            <Navigator title={title} />
            <div key={translateObj(image, localization.language)} className="h-620 md:h-carouselMD sm:h-carouselSM w-full sliderComponent relative" style={styleNavButtons(image, localization.language)}>
              <div className="text-white flex items-end h-full relative">
                {prevArtist && (
                <Link to={`${localization.path}${prevArtist.slug}`} aria-label={translateObj(ARIA_LABEL_PREV, localization.language)}>
                  <div
                    className="flex cursor-pointer ml-7 sm:ml-2 md:ml-5"
                    style={{
                      position: 'absolute',
                      left: '0',
                      top: '50%',
                      zIndex: 39,
                    }}
                  >
                    <img src="https://assets.primaverasound.com/ps/images/arrow_left_white.svg" alt="Prev Artists" />
                    <div className="backgroundImgGray w-80px md:w-60px sm:w-50px h-80px md:h-60px sm:h-50px ml-5 md:ml-2 sm:ml-2 rounded-full" style={styleNavButtons(prevArtist.image)} />
                  </div>
                </Link>
                )}
                <div className="z-30 flex justify-around sm:flex-col sm:justify-end w-full px-36 md:px-20 sm:px-5 h-full items-end pb-20 sm:pb-5">
                  {left && (
                  <div className="w-2/3 sm:w-full h-full sm:h-auto flex flex-col justify-end mr-12 sm:mr-0 sm:mb-4 ">
                    <div>
                      <ComponentIterator components={left} slugName={image} />
                    </div>
                  </div>
                  )}
                  {right && (
                  <div className="w-1/3 sm:w-full flex flex-col justify-start">
                    <div>
                      <ComponentIterator components={right} slugName={image} />
                    </div>
                  </div>
                  )}
                </div>
                {nextArtist && (
                <Link to={`${localization.path}${nextArtist.slug}`} aria-label={translateObj(ARIA_LABEL_NEXT, localization.language)}>
                  <div
                    className="flex cursor-pointer mr-7 sm:mr-2 md:mr-5"
                    style={{
                      position: 'absolute', right: '0', top: '50%', zIndex: 39,
                    }}
                  >
                    <div className="backgroundImgGray w-80px md:w-60px sm:w-50px h-80px md:h-60px sm:h-50px mr-5 md:ml-2 sm:mr-2 rounded-full" style={styleNavButtons(nextArtist.image)} />
                    <img src="https://assets.primaverasound.com/ps/images/arrow_right_white.svg" alt="next Artists" />
                  </div>
                </Link>
                )}
              </div>
            </div>
          </>
        );
      }}
    </RoutesContext.Consumer>
  );
};

SliderBannerArtist.propTypes = {
  image: PropTypes.string.isRequired,
  right: PropTypes.arrayOf(PropTypes.shape()),
  left: PropTypes.arrayOf(PropTypes.shape()),
  match: PropTypes.shape().isRequired,
  prevArtist: PropTypes.shape(),
  nextArtist: PropTypes.shape(),
};

SliderBannerArtist.defaultProps = {
  right: null,
  left: null,
  prevArtist: null,
  nextArtist: null,
};

export default withRouter(SliderBannerArtist);
