/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react';
import { PropTypes } from 'prop-types';

import { LinkWrapper } from '../../util/link';
import imageModifier from './helpers/imageModifier';
import translateObj from '../../util/translateObj';
import { useRoutes } from '../../context/routesContext';
import useResponsiveUtility from '../../util/responsiveUtility';

const IMAGE_SIZE_MOBILE = '1440x755';

const Postal = ({ description, image, kicker, subtitle, title, url, urlLocalized, imageSize }) => {
  const { language, path } = useRoutes();
  const { deviceScreen } = useResponsiveUtility();

  const localizedKicker = translateObj(kicker, language);
  const localizedTitle = translateObj(title, language);
  const localizedSubtitle = translateObj(subtitle, language);
  const imageSizeInput = deviceScreen === 'sm' ? IMAGE_SIZE_MOBILE : imageSize;

  const showImageOnly = !localizedKicker && !localizedTitle && !localizedSubtitle;

  const currentUrl = url || translateObj(urlLocalized, language);

  return (
    <div className="w-full full-width flex flex-col bg-white">
      <LinkWrapper url={currentUrl} path={path} aria-label={localizedTitle} className="flex">
        <img className="w-full" src={imageModifier(translateObj(image, language), imageSizeInput)} alt={localizedTitle} />
      </LinkWrapper>
      {showImageOnly ? null : (
        <div className="flex sm:flex-col px-4 pt-8 pb-20 sm:pb-10 font-americaRegular ">
          <div className="flex-1 pr-4">
            <h4 className="text-sm" tabIndex="0">{localizedKicker}</h4>
            <h2 className="text-26 sm:text-xl" tabIndex="0">{localizedTitle}</h2>
            <h3 className="mt-3 text-xs" tabIndex="0">{localizedSubtitle}</h3>
          </div>
          <div className="flex-3 sm:mt-4">
            <p className="sm:text-sm text-20 leading-regular" tabIndex="0">{translateObj(description, language)}</p>
          </div>
        </div>
      )}
    </div>
  );
};

Postal.propTypes = {
  description: PropTypes.shape().isRequired,
  kicker: PropTypes.shape().isRequired,
  image: PropTypes.shape().isRequired,
  subtitle: PropTypes.shape().isRequired,
  title: PropTypes.shape().isRequired,
  urlLocalized: PropTypes.shape(),
  url: PropTypes.string,
  imageSize: PropTypes.string,
};

Postal.defaultProps = {
  url: null,
  urlLocalized: {},
  imageSize: '2880x1510',
};

export default Postal;
