import React from 'react';
import PropTypes from 'prop-types';

import { LinkWrapper } from '../../../util/link';
import { useRoutes } from '../../../context/routesContext';

import translateObj from '../../../util/translateObj';
import getTranslation from './util/translations/_getTranslation';
import localTranslation from './util/translations/FooterMusicMeets';

import Newsletter from './Newsletter';
import LanguageSelector from './LanguageSelector';
import SocialLogos from './SocialLogos';


const FooterMusicMeets = ({ logo, links, translation }) => {
  const { language, path } = useRoutes();
  const literals = getTranslation(translation, localTranslation);

  return (
    <div className="font-americaMonoRegular w-full full-width px-4 sm:px-0 sm:py-0 py-4">
      <div className="bg-white w-full full-width px-1/12 sm:px-4 pt-8 pb-10 sm:pt-10 sm:pb-12 flex sm:flex-col sm:justify-start sm:items-start justify-between items-end">
        <div className="w-2/5 md:w-1/2 sm:w-full sm:mb-6">
          <div className="w-1/2 flex mb-8 sm:mb-6 sm:w-full sm:justify-center">
            <LinkWrapper url="/" path={path}>
              <img className="h-full w-full sm:w-1/2" src={translateObj(logo, language)} alt="Primavera Pro - music meets" />
            </LinkWrapper>
          </div>
          <Newsletter
            title={literals.titleNewsletter}
            buttonLabel={literals.buttonSubmitNewsletter}
            placeholder={literals.placeholderNewsletter}
          />
        </div>
        <div className="w-2/5 md:w-1/2 sm:w-full flex flex-col items-end justify-end sm:items-center">
          <LanguageSelector languages={literals.languages} />
          <SocialLogos social={literals.social} justify="justify-end sm:justify-center" className="mt-4 -mb-2 -mr-2 sm:-mr-1" />
        </div>
      </div>
      <div className="w-full flex items-center justify-start bg-black">
        <div className="w-1/12 flex items-center justify-start sm:hidden">
          <div
            className="w-pro-top-menu h-pro-top-menu"
            style={{
              background: 'linear-gradient(to top right, #EFEAE7 50%, #000000 50%) no-repeat, linear-gradient(to top right, #EFEAE7 0.1%, #000000 0.1%) no-repeat',
              backgroundSize: '100% 100%, 100% 50%',
              backgroundPosition: '0% 100%, 0% 0%',
            }}
          />
        </div>
        <div className="h-pro-top-menu sm:h-auto w-full text-white flex sm:flex-col justify-between sm:justify-start items-center pr-1/12 sm:px-4 sm:pt-8 sm:pb-10">
          <div className="flex items-center justify-start sm:justify-center flex-wrap">
            {
            links.map((link, index) => (
              <div key={link.key} className="flex items-center justify-start sm:min-w-1/2">
                {index !== 0 && <div className="text-white text-10 pr-4 sm:pr-3 pt-1 sm:py-3px">|</div>}
                <LinkWrapper url={link.link} path={path}>
                  <span className="text-white uppercase text-10 sm:py-3px pr-4 sm:pr-3">{translateObj(link.text, language)}</span>
                </LinkWrapper>
              </div>
            ))
          }
          </div>
          <div className="hidden sm:block sm:w-full sm:my-6 sm:h-px sm:bg-white" />
          <div className="text-white text-10 font-tiemposRegular sm:text-center sm:w-1/2">{translateObj(literals.copyright, language)}</div>
        </div>
      </div>
    </div>
  );
};

FooterMusicMeets.propTypes = {
  logo: PropTypes.shape(),
  links: PropTypes.arrayOf(PropTypes.shape()),
  translation: PropTypes.shape(),
};

FooterMusicMeets.defaultProps = {
  logo: {},
  links: [],
  translation: null,
};

export default FooterMusicMeets;
