import React from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import ViewToggler from './Radio/ViewToggler';

import Filter from './Collapsible/Filter';
import getRadioShows from '../../api/graphql/queries/getRadioShows';
import getRadioTags from '../../api/graphql/queries/getRadioTags';
import RadioShowCard from './Radio/RadioShowCard';
import RadioShowCardListItem from './Radio/RadioShowCardListItem';
import ParagraphLineSM from './Text/ParagraphLineSM';
import NavigatorRadio from './NavigatorRadio';

import withAllContexts from '../../context/withAllContexts';

const menuFilter = {
  text: {
    es: 'OPCIONES DE VISUALIZACIÓN | FILTRAR CONTENIDO',
    en: 'OPTIONS OF VISUALISATION | FILTER CONTENT',
    ca: 'OPCIONES DE VISUALIZACIÓN | FILTRAR CONTENIDO',
  },
  filter: [
    {
      filterHeading: {
        es: 'POR GENERO',
        en: 'BY GENRE',
        ca: 'POR GENERO',
      },
      filterType: 'byTag',
      showAs: 'button',
      menuTitle: {
        text: {
          es: 'TODOS LOS generos',
          en: 'ALL genres',
        },
      },
      filterItems: [],
    },
    {
      filterHeading: {
        es: 'POR TIPO DE SHOW',
        en: 'BY TYPE OF SHOW',
        ca: 'POR TIPO DE SHOW',
      },
      filterType: 'byCategory',
      showAs: 'checkbox',
      menuTitle: {
        text: {
          es: 'TODOS LOS shows',
          en: 'ALL shows',
        },
      },
      filterItems: [],
    },
  ],
};

class RadioShows extends React.Component {
  state = {
    filtered: [],
    menu: {},
    filters: {},
    activeView: 'list',
    offset: 0,
    noLoadMore: false,
    status: 'loaded',
    isLoading: true,
    headLine: {
      en: 'Featured shows',
      es: 'Featured shows',
      ca: 'Featured shows',
    },
  };

  componentWillMount = () => {
    const { radioShowsContext } = this.props;
    if (radioShowsContext.radioShowsData) {
      this.processGetRadioShows(radioShowsContext.radioShowsData);
      radioShowsContext.clear('radioShowsData');
    } else {
      this.fetchRadioShows(true);
    }
  };

  componentDidMount() {
    const { activeView } = this.props;
    this.setState({ activeView });
    getRadioTags()
      .then((res) => {
        this.generateFilterTags(res);
      })
      .catch((err) => {
        console.log('Couldnt fetch tags: ', err);
      });
    this.setState({ offset: 0 });
  }

  componentWillUnmount() {
    this.setState({ offset: 0, filtered: [] });
  }

  fetchRadioShows = (firstFetch = false) => {
    let { limit } = this.props;
    if (!firstFetch) {
      limit = 6;
    }
    const { offset } = this.state;
    getRadioShows({ limit, offset, type: 'shows' }).then((radioProgramsAll) => {
      if (radioProgramsAll) {
        if (radioProgramsAll.length >= limit) {
          this.processGetRadioShows(radioProgramsAll);
        } else {
          this.setState({
            status: 'end',
          });
        }
      } else {
        console.log('radioProgramsAll = null');
      }
    });
  };

  processGetRadioShows = (radioProgramsAll) => {
    this.setState(prevState => ({
      filtered: [...prevState.filtered, ...radioProgramsAll],
    }));
  };

  generateFilterTags = (radioTags) => {
    const tags = [];
    const categories = [];
    // TODO add categories they might act as a tag as well
    for (let i = 0; i < radioTags.length; i++) {
      const tag = {
        category: 'radio',
        text: {
          es: radioTags[i].tagName_multilang.es,
          en: radioTags[i].tagName_multilang.en,
          ca: radioTags[i].tagName_multilang.ca,
        },
        value: radioTags[i].tagName_multilang.en,
        isCategory: radioTags[i].isCategory,
      };
      if (!tag.isCategory) {
        if (!tags.length || tags.some(el => el.value !== radioTags[i].tagName_multilang.en)) {
          tags.push(tag);
        }
      } else {
        if (!categories.length || categories.some(el => el.value !== radioTags[i].tagName_multilang.en)) {
          categories.push(tag);
        }
      }
    }
    menuFilter.filter[0].filterItems = tags;
    menuFilter.filter[1].filterItems = categories;
    this.setState({
      menu: menuFilter,
      isLoading: false,
    });
  };

  onHandleCollapsibleMenuSelect = (filters) => {
    this.setState({ filters });
    this.fetchSelectedTag(filters);
  };

  sortByTitle = programsArr => programsArr.sort((a, b) => a.title_multilang.en.localeCompare(b.title_multilang.en))

  fetchSelectedTag = (filters) => {
    const tagsAND = Object.keys(filters).reduce((accum, current) => accum.concat(filters[current]), []);
    if (!tagsAND.length) {
      this.setState(
        {
          offset: 0,
          headLine: {
            en: 'Featured shows',
            es: 'Featured shows',
            ca: 'Featured shows',
          },
          noLoadMore: false,
          filtered: [],
        },
        () => this.fetchRadioShows(),
      );
    } else {
      getRadioShows({ tagsAND, type: 'shows' }).then((radioProgramsAll) => {
        if (radioProgramsAll !== null) {
          this.setState({
            filtered: radioProgramsAll,
            noLoadMore: true,
            headLine: {
              es: `${radioProgramsAll.length} ${radioProgramsAll.length > 1 ? 'resultados encontrados' : 'resultado encontrado'}`,
              en: `${radioProgramsAll.length} ${radioProgramsAll.length > 1 ? 'results' : 'result'} found`,
              ca: `${radioProgramsAll.length} ${radioProgramsAll.length > 1 ? 'resultats trobats' : 'resultat trobat'}`,
            },
          });
        } else {
          this.setState({ filtered: [], noLoadMore: false });
        }
      });
    }
  };

  onHandleClick = () => {
    const { offset } = this.state;
    const newOffset = offset + 6;
    this.setState(
      {
        offset: newOffset,
      },
      () => {
        this.fetchRadioShows();
      },
    );
  };

  handleSearchInput = (query, lang, filters) => {
    if (query !== '' && query.length >= 3) {
      getRadioShows({ search: query, type: 'shows' }).then((radioProgramsAll) => {
        if (radioProgramsAll !== null) {
          this.setState({
            filtered: radioProgramsAll,
            noLoadMore: true,
            headLine: {
              es: `${radioProgramsAll.length} ${radioProgramsAll.length > 1 ? 'resultados encontrados' : 'resultado encontrado'}`,
              en: `${radioProgramsAll.length} ${radioProgramsAll.length > 1 ? 'results' : 'result'} found`,
              ca: `${radioProgramsAll.length} ${radioProgramsAll.length > 1 ? 'resultats trobats' : 'resultat trobat'}`,
            },
          });
        } else {
          this.setState({ filtered: [], noLoadMore: false });
        }
      });
    }
    if (query === '') {
      this.setState(
        {
          offset: 0,
          noLoadMore: false,
          headLine: {
            en: 'Featured shows',
            es: 'Featured shows',
            ca: 'Featured shows',
          },
          filtered: [],
        },
        () => this.fetchRadioShows(),
      );
    }
  };

  updateView = (view, type) => {
    this.setState({ activeView: view });
  };

  renderRadioShows = () => {
    const { filtered, activeView } = this.state;
    if (activeView === 'grid') {
      return (
        <div className="md:-mx-2 lg:-mx-2 xl:-mx-2 flex flex-wrap">
          {filtered.map((show, i) => (
            <div key={show.id + i} className={`sm:w-full ${filtered.length === 1 ? 'md:w-2/3 lg:w-2/3 xl:w-2/3' : 'md:w-1/2 smlg:w-1/4 lg:w-1/3 xl:w-1/4'}  py-2 px-2 sm:px-0`}>
              <RadioShowCard favouriteStar={false} {...show} />
            </div>
          ))}
        </div>
      );
    }
    return (
      <div className="pt-2 w-full sm:px-0">
        {filtered.map((show, i) => (
          <RadioShowCardListItem key={show.id + i} favouriteStar={false} {...show} />
        ))}
      </div>
    );
  };

  render() {
    const { slug, hasFilters, showToggleView } = this.props;
    const {
      filtered, isLoading, menu, activeView, noLoadMore, status, headLine,
    } = this.state;

    return (
      <>
        {!isLoading && hasFilters && (
          <div className="mt-3 w-full">
            <NavigatorRadio />
            <Filter {...menu} onHandleCollapsibleMenuSelect={this.onHandleCollapsibleMenuSelect} handleSearchInput={this.handleSearchInput} slug={slug} slugname="radio-shows" />
          </div>
        )}
        <div className="flex w-full flex-wrap">
          <ParagraphLineSM classes="uppercase font-bold text-xxs my-6 w-full" text={headLine} />
          {showToggleView && <ViewToggler handleUpdateView={this.updateView} activeView={activeView} type="show" />}
          {filtered.length ? this.renderRadioShows() : null}
        </div>
        {!noLoadMore && (
          <div className="py-2 w-full flex justify-center mt-2" onClick={this.onHandleClick}>
            <span className="font-americaMonoBold text-black text-center text-14 uppercase border-b-2 border-black py-1">
              {status === 'loading' && 'Loading...'}
              {status === 'loaded' && (
                <span>
                  <span className="font-icon icon-arrow-down text-12 bold" />
                  <span className="px-4 cursor-pointer">Load More</span>
                  <span className="font-icon icon-arrow-down text-12 bold" />
                </span>
              )}
              {status === 'end' && 'No more items available'}
              {status === 'failed' && 'Internet connection required'}
            </span>
          </div>
        )}
      </>
    );
  }
}

RadioShows.propTypes = {
  hasFilters: PropTypes.bool,
  showToggleView: PropTypes.bool,
  slug: PropTypes.string,
  menu: PropTypes.shape({}),
  limit: PropTypes.number,
  activeView: PropTypes.string,
  radioShowsContext: PropTypes.shape().isRequired,
};

RadioShows.defaultProps = {
  hasFilters: false,
  slug: null,
  menu: {},
  limit: 1000,
  activeView: 'list',
  showToggleView: true,
};

export default withRouter(withAllContexts(RadioShows));
