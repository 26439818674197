const validateEmail = (values) => {
  const { email } = values;
  const errors = {};
  if (!email) {
    errors.email = 'Required';
  } else if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/i.test(email)) {
    errors.email = 'Invalid email address';
  }
  return errors;
};

export default validateEmail;
