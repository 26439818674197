import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import translateObj from '../../../util/translateObj';

const logos = [
  {
    name: 'twitter',
    icon: 'font-icon icon-twitter-solid',
    link: 'https://twitter.com/intent/tweet?url=',
  },
  {
    name: 'facebook',
    icon: 'font-icon icon-facebook-solid',
    link: 'https://www.facebook.com/sharer/sharer.php?u=',
  },
  // {
  //   name: 'share',
  //   icon: 'font-icon icon-share2',
  //   link: '',
  // },
];
class SocialLogosContainer extends PureComponent {
  state = {
    origin: '',
  };

  // componentDidMount() {
  //   this.setState({
  //     currentUrl: window.location.href,
  //   });
  // }

  componentDidMount() {
    this.setState({
      origin: window.location.origin,
    });
  }

  componentDidUpdate() {
  }

  render() {
    const {
      classNames, imageClassNames, text, currentUrl, imageUrl, localization, assetSlugName, description,
    } = this.props;
    const { origin } = this.state;
    return (
      <div className={classNames}>
        {text && <p className="font-americaMonoRegular mb-4">{text}</p>}
        <FacebookShareButton className="mr-1" quote={description} size={32} url={assetSlugName ? `${origin + localization.path}/share/${assetSlugName}` : origin + currentUrl}>
          <span className={`${logos[1].icon} ${imageClassNames} text-black text-3xl sm:text-xl`} />
        </FacebookShareButton>
        <TwitterShareButton title={description} size={32} url={assetSlugName ? `${origin + localization.path}/share/${assetSlugName}` : origin + currentUrl}>
          <span className={`${logos[0].icon} ${imageClassNames} text-black text-3xl sm:text-xl`} />
        </TwitterShareButton>
      </div>
    );
  }
}

SocialLogosContainer.propTypes = {
  text: PropTypes.string,
  classNames: PropTypes.string.isRequired,
  imageClassNames: PropTypes.string,
  currentUrl: PropTypes.string,
  imageUrl: PropTypes.string,
};
SocialLogosContainer.defaultProps = {
  text: null,
  imageClassNames: '',
  currentUrl: '',
  imageUrl: null,
};
export default SocialLogosContainer;
