import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useRoutes } from '../../../context/routesContext';

import useResponsiveUtility from '../../../util/responsiveUtility';
import translateObj from '../../../util/translateObj';

import FiltersRadioGroup from './FiltersRadioGroup';
import SelectMultipleCountries from './SelectMultipleCountries';

const Filters = ({
  literals,
  onlyFriends,
  radioButtonsOptions,
  radioButtonsValueCallback,
  searchInputEventCallback,
  countrySelectValueCallback,
  children,
}) => {
  const { language } = useRoutes();
  const { deviceScreen } = useResponsiveUtility();
  const [open, setOpen] = useState(true);

  useEffect(() => {
    setOpen(true);
  }, [deviceScreen]);

  return (
    <div className="bg-white px-4 py-6 sm:py-4">
      <div className="hidden sm:flex items-center justify-between">
        <button
          type="button"
          onClick={() => setOpen(!open)}
        >
          <h6 className="uppercase font-americaMonoBold tracking-wide text-12">{translateObj(literals.filtersTitle, language)}</h6>
        </button>
        <div className="flex items-center justify-end">
          {/* search input mobile */}
          <div className={`relative border-2 border-black rounded-full flex items-center ${open ? 'px-3 py-2 w-full h-10 justify-between' : 'w-10 h-10 justify-center'}`}>
            <div className={`font-icon icon-search text-black w-4 h-4 -mt-1 ${open ? 'mr-2' : '-mr-2'}`} />
            <input type="text" name="text" placeholder={translateObj(literals.search, language)} className={`focus:outline-none bg-transparent ${open ? 'w-full' : 'w-0'} font-americaMonoRegular text-12 sear-music-meets`} onChange={searchInputEventCallback} />
          </div>
          {/**/}
          <button
            type="button"
            className="pl-4"
            onClick={() => setOpen(!open)}
          >
            <div
              className="font-icon icon-arrow-up text-black"
              style={{
                transform: !open ? 'rotate(-180deg)' : '',
                transition: 'transform .1s ease-in',
              }}
            />
          </button>
        </div>
      </div>
      <div
        style={{ display: open ? 'flex' : 'none' }}
        className="flex sm:flex-col sm:justify-start sm:items-start items-center justify-between sm:pt-4"
      >
        <FiltersRadioGroup
          className="w-full flex items-center sm:justify-between sm:flex-wrap"
          options={radioButtonsOptions}
          getValueCallback={radioButtonsValueCallback}
        />
        {children && <div className="hidden sm:block w-full">{children}</div>}
        {/* country select */}
        {countrySelectValueCallback && (
        <div className="sm:mr-0 mr-4 sm:mt-4 sm:w-full w-1/4">
          <SelectMultipleCountries
            placeholder={translateObj(literals.filtersCountry, language)}
            onChangeCallback={countrySelectValueCallback}
            labelCleanSelection={translateObj(literals.labelCleanSelection, language)}
            labelNoResults={translateObj(literals.noItemsFoundMessage, language)}
            onlyFriends={onlyFriends}
          />
        </div>
        )}
        {/* search input desktop */}
        <div className="sm:hidden border border-black rounded-full flex items-center justify-between px-4 py-3 w-1/4">
          <div className="font-icon icon-search text-black w-4 h-4 -mt-1 mr-2" />
          <input type="text" name="text" placeholder={translateObj(literals.search, language)} className="focus:outline-none bg-transparent w-full font-americaMonoRegular text-12 sear-music-meets" onChange={searchInputEventCallback} />
        </div>
        {/**/}
      </div>
      {children && <div className="w-full sm:hidden">{children}</div>}
    </div>
  );
};

Filters.propTypes = {
  literals: PropTypes.shape(),
  onlyFriends: PropTypes.bool,
  radioButtonsOptions: PropTypes.arrayOf(PropTypes.shape({})),
  radioButtonsValueCallback: PropTypes.func,
  searchInputEventCallback: PropTypes.func,
  countrySelectValueCallback: PropTypes.func,
  children: PropTypes.node,
};

Filters.defaultProps = {
  literals: null,
  onlyFriends: false,
  radioButtonsOptions: [],
  radioButtonsValueCallback: null,
  searchInputEventCallback: null,
  countrySelectValueCallback: null,
  children: null,
};

export default Filters;
