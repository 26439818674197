import React, { useState, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import uuid from 'react-uuid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { PropTypes } from 'prop-types';
import { Picker } from 'emoji-mart';
import { useRoutes } from '../../../../context/routesContext';
import { useTheme } from '../context/themeContext';
import { useChat } from '../context/chatContext';
import { useFirebaseAuth } from '../context/FirebaseAuthContext';
import { getStringSet } from '../utils/constants';

const MessageInput = ({
  isQuestions, privateChat, shouldToggle, match, updateChat,
}) => {
  const {
    isModerator,
  } = useFirebaseAuth();

  const [userMessage, setUserMessage] = useState('');
  const [isAdminUser, setIsAdmin] = useState(false);
  const [showPicker, setPickerState] = useState(false);
  const { language } = useRoutes(match);
  const stringSet = getStringSet(language);
  const { theme, color } = useTheme();
  const inputref = useRef();
  const handleChange = (e) => {
    const { value } = e.target;
    setUserMessage(value);
  };

  const handleUpdateChat = async () => {
    updateChat(userMessage, isAdminUser);
    setUserMessage('');
    setPickerState(false);
  };

  const handleKeyPress = (e) => {
    const { key } = e;
    if (key === 'Enter') {
      if (!userMessage) return;
      e.preventDefault();
      handleUpdateChat();
    }
  };

  const handleClick = () => {
    if (!userMessage) return;
    handleUpdateChat(userMessage);
  };

  const handleSetAdmin = () => {
    setIsAdmin(!isAdminUser);
  };

  const toggleEmojis = () => {
    setPickerState(!showPicker);
  };

  const handleEmojiClick = (data) => {
    const text = `${userMessage}${data.native}`;
    setUserMessage(text);
    if (inputref.current) inputref.current.focus();
    toggleEmojis();
  };

  return (
    <div className={`w-chat font-americaMonoRegular border-black border-t ${color === 'black' ? 'bg-white' : `bg-${theme}-box`} p-4 absolute pin-b pin-r ${privateChat ? 'mb-18 sm:w-screen sm:mb-6' : 'sm:w-full'} ${shouldToggle && !privateChat ? 'mb-39px' : ''}`}>
      <div className="flex justify-between mb-4 items-center">
        <h3 className="text-white uppercase text-xs">{!privateChat ? !isQuestions ? stringSet.CHAT_BUTTON : stringSet.QUESTIONS_BUTTON : stringSet.PRIVATE_BUTTON}</h3>
        {isModerator && !privateChat && (
        <FormControlLabel
          classes={{
            label: 'text-white font-americaMonoRegular pl-2 uppercase text-xs',
          }}
          control={(
            <AdminCheckbox
              checked={isAdminUser}
              onChange={handleSetAdmin}
              value="checkedG"
            />
)}
          label="As admin"
        />
        )
        }
      </div>
      <div className="flex">
        <div className="relative w-full">
          <input
            type="text"
            autoComplete="off"
            onKeyPress={handleKeyPress}
            onChange={handleChange}
            name={uuid()}
            value={userMessage}
            ref={inputref}
            maxLength="250"
            placeholder={stringSet.INPUT_PLACEHOLDER}
            className={`font-americaRegular w-full h-8 text-xs p-2 bg-grey-darker rounded-lg pr-8 border border-grey-light active:border-barcelona text-${color} ${color === 'black' ? 'bg-white' : 'bg-grey-darker'}`}
          />
          <a
            className="absolute pr-2 pin-r pin-b cursor-pointer h-8 flex items-center"
            onClick={toggleEmojis}
          >
            <span role="img" area-label="toggle picker">😁</span>
          </a>
          {showPicker && (
          <div className="absolute pin-r pin-b mb-8">
            <Picker
              onSelect={handleEmojiClick}
              enableFrequentEmojiSort
              color="#67a0ce"
            />
          </div>
          )}
        </div>
        <button
          type="button"
          className={`ml-2 font-americaMonoRegular text-center ${userMessage && userMessage.length ? 'bg-psworld-orange text-black' : 'bg-grey-darker text-psworld-text-send-grey'} sm:block hidden rounded px-2 py-1 text-xs uppercase cursor-pointer trans`}
          onClick={handleClick}
        >
          send
        </button>
      </div>
    </div>
  );
};

MessageInput.propTypes = {
  match: PropTypes.shape({}).isRequired,
  isQuestions: PropTypes.bool,
  privateChat: PropTypes.bool,
  shouldToggle: PropTypes.bool,
  updateChat: PropTypes.func.isRequired,
};

MessageInput.defaultProps = {
  isQuestions: false,
  privateChat: false,
  shouldToggle: false,
};

export default withRouter(MessageInput);

const AdminCheckbox = withStyles({
  root: {
    padding: 0,
    color: 'white',
    '&$checked': {
      color: '#F25B29',
    },
  },
  checked: {},
})(props => <Checkbox color="secondary" {...props} />);
