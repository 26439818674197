import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import LauncherItemSingle from './LauncherItemSingle';
import LauncherItemDouble from './LauncherItemDouble';
import LauncherItemTriple from './LauncherItemTriple';
import LauncherItemConcert from './LauncherItemConcert';
import LauncherItemRadio from './LauncherItemRadio';
import Filter from '../Collapsible/Filter';
import handleFilter from '../Collapsible/handleFilter';

class LauncherRadio extends React.Component {
  state = {
    filtered: [],
    menu: [],
    isLoading: true,
  };

  componentDidMount() {
    const { params, hasFilters, menu } = this.props;
    this.setState({
      params,
      filtered: params,
      menu,
      isLoading: false,
    });
  }

  checkItemType = (highlight) => {
    const { itemLayout } = highlight[0];
    return itemLayout === 'double' ? highlight.map((content, index) => <LauncherItemDouble {...content} position={index} key={`launcherItem-${index}`} />) : highlight.map((content, index) => <LauncherItemTriple {...content} position={index} key={`launcherItem-${index}`} />);
  };

  launcherItemBuilder = (array) => {
    // helper to make arrays of items depending on how many items are contained in each LauncherItem
    const newArray = [];

    array.map((highlight) => {
      const lastNewArrayItem = newArray[newArray.length - 1];
      // check if last item in newArray is an array if so,
      // check if it accepts 2 o 3 children and fill them until cumpleted
      if (Array.isArray(lastNewArrayItem)) {
        if (lastNewArrayItem[0].itemLayout === 'triple' && newArray[newArray.length - 1].length < 3) {
          newArray[newArray.length - 1].push(highlight);
        } else if (lastNewArrayItem[0].itemLayout === 'double' && newArray[newArray.length - 1].length < 2) {
          newArray[newArray.length - 1].push(highlight);
        } else {
          highlight.itemLayout === 'single' ? newArray.push(highlight) : newArray.push(Array.of(highlight));
        }
      }
      // if last itemLayoutin newArray is not an Array just push the element
      else {
        highlight.itemLayout === 'single' || highlight.itemLayout === 'concert' || highlight.itemLayout === 'radio' ? newArray.push(highlight) : newArray.push(Array.of(highlight));
      }
    });
    return newArray;
  };

  launcherItem2 = (highlight, index) => {
    if (highlight.itemLayout === 'concert') {
      return (
        <div className="launcherItem w-1/2 sm:w-full py-2 px-2" key={`launcherContainer-${index}`}>
          <LauncherItemConcert {...highlight} position={index} key={`launcherItem-${index}`} />
        </div>
      );
    } if (highlight.itemLayout === 'single') {
      return (
        <div className="launcherItem w-1/2 sm:w-full py-2 px-2" key={`launcherContainer-${index}`}>
          <LauncherItemSingle {...highlight} position={index} key={`launcherItem-${index}`} />
        </div>
      );
    } if (highlight.itemLayout === 'radio') {
      return (
        <div className="launcherItem w-1/2 sm:w-full py-2 px-2" key={`launcherContainer-${index}`}>
          <LauncherItemRadio {...highlight} position={index} key={`launcherItem-${index}`} />
        </div>
      );
    }
    return (
      <div className="launcherItem w-1/2  sm:w-full py-2 px-2" key={`launcher-${index}`}>
        {' '}
        {this.checkItemType(highlight)}
      </div>
    );
  };

  onHandleCollapsibleMenuSelect = (filters) => {
    const { params } = this.props;
    const filtered = handleFilter(filters, params);
    this.setState({
      filtered,
    });
  };

  render() {
    const { params } = this.props;

    const { filtered, menu, isLoading } = this.state;

    const highlightsArray = Object.keys(menu).length ? this.launcherItemBuilder(filtered) : this.launcherItemBuilder(params);

    return (
      <Fragment>
        {/* {Object.keys(menu).length !== 0 && <CollapsibleMenu {...menu} onHandleCollapsibleMenuSelect={this.onHandleCollapsibleMenuSelect} />} */}
        {Object.keys(menu).length !== 0 && !isLoading && <Filter {...menu} hasSearchBar={false} onHandleCollapsibleMenuSelect={this.onHandleCollapsibleMenuSelect} />}
        <div className="launcher flex flex-wrap -mx-2">{highlightsArray && highlightsArray.map((highlight, index) => this.launcherItem2(highlight, index))}</div>
      </Fragment>
    );
  }
}

LauncherRadio.propTypes = {
  params: PropTypes.arrayOf(
    PropTypes.shape({
      itemLayout: PropTypes.string.isRequired,
      image: PropTypes.shape({
        src: PropTypes.string.isRequired,
      }).isRequired,
      date: PropTypes.string.isRequired,
      category: PropTypes.string,
      tags: PropTypes.arrayOf(PropTypes.string.isRequired),
    }),
  ).isRequired,
  url: PropTypes.string,
  menu: PropTypes.shape({}),
  hasFilters: PropTypes.bool,
};

LauncherRadio.defaultProps = {
  url: '',
  menu: {},
  hasFilters: false,
};

export default LauncherRadio;
