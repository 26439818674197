import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';
import translateObj from '../../../util/translateObj';
import Link from '../../../util/link';

const FooterLogo = ({ url, name, match }) => (
  // color of the tag is determined by the tag category
  <RoutesContext.Consumer>
    {
      ({ language: contextLanguage, enteredFrom }) => {
        const localization = getLocalization(contextLanguage, enteredFrom, match);
        return (
          <div id={name} className="w-1/2 sm:w-full flex sm:justify-center md:pl-5 order-0">
            <Link
              to=""
              localizationpath={localization.path}
            >
              <img src={translateObj(url, localization.language)} alt={name} style={{ width: '222px' }} />
            </Link>
          </div>
        );
      }
    }
  </RoutesContext.Consumer>
);

FooterLogo.propTypes = {
  url: PropTypes.shape().isRequired,
  name: PropTypes.string.isRequired,
};

export default withRouter(FooterLogo);
