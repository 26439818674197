import { useEffect } from 'react';

const useRewardDrawer = ({ reward, setReward }) => {
  const closeDrawer = () => {
    document
      .querySelector('#reward-drawer-bg')
      .classList.remove('drawer-bg-in');
    document.querySelector('#reward-drawer-bg').classList.add('drawer-bg-out');
    document.querySelector('#reward-drawer').classList.remove('open-drawer');
    document.querySelector('#reward-drawer').classList.add('close-drawer');
    setTimeout(() => {
      setReward(undefined);
    }, 280);
  };

  // const onTopScroll = () => {
  //   if (document.querySelector('.fixedHeaderShow')) {
  //     document.querySelector('#reward-drawer').classList.add('drawer-down');
  //     document.querySelector('#reward-drawer').classList.remove('drawer-up');
  //   } else {
  //     document.querySelector('#reward-drawer').classList.add('drawer-up');
  //     document.querySelector('#reward-drawer').classList.remove('drawer-down');
  //   }
  // };

  useEffect(() => {
    if (!reward) closeDrawer();
  }, [closeDrawer]);

  // useEffect(() => {
  //   document.addEventListener('scroll', onTopScroll);
  //   return () => {
  //     document.removeEventListener('scroll', onTopScroll);
  //   };
  // }, []);

  return { closeDrawer };
};

export default useRewardDrawer;
