import React, { useRef } from 'react';
import PropTypes from 'prop-types';

const CloseButtonAnimated = ({
  closeCallback,
}) => {
  const iconEl = useRef();

  const enterAnimation = () => {
    iconEl.current.style.transform = 'rotate(180deg)';
    iconEl.current.style.transition = 'transform .4s ease-in-out';
  };

  const leaveAnimation = () => {
    iconEl.current.style.transform = 'rotate(0deg)';
    iconEl.current.style.transition = 'transform .4s ease-in-out';
  };

  return (
    <button
      type="button"
      className="p-8 sm:py-6 sm:px-2"
      onClick={() => closeCallback()}
      onMouseEnter={() => enterAnimation()}
      onMouseLeave={() => leaveAnimation()}
    >
      <div ref={iconEl} className="font-icon icon-close text-20 text-white" />
    </button>
  );
};

CloseButtonAnimated.propTypes = {
  closeCallback: PropTypes.func,
};

CloseButtonAnimated.defaultProps = {
  closeCallback: null,
};

export default CloseButtonAnimated;
