import React from 'react';
import { PropTypes } from 'prop-types';

import StreamingVideoPlayerMainMobile from '../StreamingVideoPlayerMainMobile';
import LoadIcon from '../../LoadIcon';

const MobileVideoScreen = ({
  isLoading,
  mainVideo,
  mainMobileVideo,
  PLAYER_RATIO,
  source,
}) => (
  <>
    <div className="bg-black">
      {isLoading && <LoadIcon color="black" type="ThreeDots" />}
      {mainVideo && (
        <div className={`${isLoading ? 'hidden' : 'relative w-full bg-black'} `} style={{ paddingTop: PLAYER_RATIO }}>
          <div className="absolute pin-t pin-l w-full h-full">
            <StreamingVideoPlayerMainMobile
              mainVideo={mainMobileVideo || mainVideo}
              source={source}
            />
          </div>
        </div>
      )}
    </div>
  </>
);

MobileVideoScreen.propTypes = {
  mainVideo: PropTypes.string,
  mainMobileVideo: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  PLAYER_RATIO: PropTypes.string,
  source: PropTypes.string,
};

MobileVideoScreen.defaultProps = {
  isLoading: true,
  mainVideo: '',
  PLAYER_RATIO: '56.25%',
  source: '',
};

export default MobileVideoScreen;
