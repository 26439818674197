import gql from 'graphql-tag';

import { clientWeb, persistor } from '../graphqlAPI';

const GET_MY_AGENDA = gql`
  query getMyAgenda($fetchDate: String, $reunionsPage: Int, $notificationsPage: Int, $sessionsPage: Int, $cancelledReunions: Boolean) {
    getMyAgenda(fetchDate: $fetchDate, reunionsPage: $reunionsPage, notificationsPage: $notificationsPage, sessionsPage: $sessionsPage, cancelledReunions: $cancelledReunions) {
      userId
      notifications {
        _id
        type
        read
        sender {
          name
          lastname
          company
          _id
        }
        relatedId
        event {
          eventReadableName {
            en
            ca
            es
          }
        }
      }
      reunions {
        _id
        title
        date
        participants {
          _id
          nickname
          lastname
          name
          imageUrl
          occupation
          company
          inputCountry
          areFriends
        }
        confirmedParticipants {
          _id
          name
          lastname
          company
        }
        rejectedParticipants {
          _id
        }
        location
        description
        online
        link
        createdBy
        createdByUser {
          _id
          name
          lastname
          company
        }
        roles
        cancelled
        confirmed
      }
      sessions {
        artistSets {
          artistSetSlugName
          status
          dateTimeStartReal
        }
        subtitle {
          en
          es
          ca
          pt
        }
        pretitle {
          en
          es
          ca
          pt
        }
        eventSlugName
        eventReadableName {
          en
          es
          ca
          pt
        }
        beginDateTime
        endDateTime
        image {
          en
          es
          ca
          pt
        }
        mentorsData {
          _id
          name
          lastname
          occupation
          business
        }
        hasReminder
      }
      unreadNotifications
    }
  }
`;

const executeQuery = async (type = 'network', reunionsPage, notificationsPage, sessionsPage, cancelledReunions) => {
  const result = await clientWeb.query({
    query: GET_MY_AGENDA,
    variables: { fetchDate: new Date().getTime().toString(), reunionsPage, notificationsPage, sessionsPage, cancelledReunions },
    fetchPolicy: 'no-cache',
  })
    .then(queryResult => queryResult)
    .catch((queryError) => {
      const errorMessage = type === 'network'
        ? `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error: ${queryError}`
        : `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error after Cache Restore: ${queryError}`;
      console.log('\x1b[31m%s\x1b[0m', errorMessage);

      return null;
    });

  if (result.data && result.data.getMyAgenda) {
    const { data: { getMyAgenda: getMyAgendaData } } = result;
    return getMyAgendaData;
  }

  return [];
};

const getMyAgenda = async (reunionsPage, notificationsPage, sessionsPage, cancelledReunions) => {
  try {
    const JSONObject = await executeQuery('network', reunionsPage, notificationsPage, sessionsPage, cancelledReunions);

    if (persistor) {
      persistor.persist();
    }

    return JSONObject;
  } catch (jsonError) {
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: JSON Object Error: ${jsonError}`);

    if (persistor) {
      const persistorStatus = await persistor.restore().then(() => 'OK');
      console.log(`${new Date().toLocaleTimeString()} ~ Client: Apollo Cache Restored - ${persistorStatus}`);

      try {
        const JSONObject = await executeQuery('cache');

        return JSONObject;
      } catch (jsonErrorAfterCacheRestore) {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: JSON Object Error after Cache Restore, sending 404: ${jsonErrorAfterCacheRestore}`);
        return null;
      }
    }
    return null;
  }
};

export default getMyAgenda;
