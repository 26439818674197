import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import ShareMedia from './ShareMedia';
import ComponentIterator from '../../containers/ComponentIterator';
import getTicketsByEventSlugName from '../../api/graphql/queries/getTicketsByEventSlugName';
import getEventsByPostSlug from '../../api/graphql/queries/getEventsByPostSlug';
import getPostsBySlugNameQuery from '../../api/graphql/queries/getPostsBySlugNameQuery';
import PSResponsiveUtility from './LineUp/PSResponsiveUtility';
import Separator from './Separator';
import SliderBannerArtistPD from './Sliders/SliderBannerArtistPD';

class SingleConcertPage extends Component {
  state = {
    topComponents: null,
    bottomComponents: [],
    deviceScreen: null,
    isLoading: true,
    postDescription: {},
    prevArtist: {},
    nextArtist: {},
    bannerIsLoading: true,
    eventSlugName: '',
    updatedComponents: [],
  };

  responsiveUtility = React.createRef();

  _mounted = false;

  componentDidMount() {
    this._mounted = true;
    const {
      match: { params },
    } = this.props;
    let concertSlug;
    let eventSlugName = 'tours';
    if (params.post) {
      concertSlug = params.post;
      if (params.section !== 'tours') {
        eventSlugName = params.section;
      }
    } else {
      concertSlug = params.section;
    }
    this.getEventTickets(concertSlug, eventSlugName);
    this.fetchPostDrescriptionsBySlugnames([concertSlug]);
    this.setState({ eventSlugName });
  }

  componentDidUpdate(prevProps) {
    const {
      match: { params },
    } = this.props;
    const prevParams = prevProps.match.params;
    if (
      prevParams.section !== params.section
      || prevParams.post !== params.post
    ) {
      let concertSlug;
      let eventSlugName = 'tours';
      if (params.post) {
        concertSlug = params.post;
        if (params.section !== 'tours') {
          eventSlugName = params.section;
        }
      } else {
        concertSlug = params.section;
      }
      if (concertSlug) {
        this.getEventTickets(concertSlug, eventSlugName);
        this.fetchPostDrescriptionsBySlugnames([concertSlug]);
      }
      this.setState({ eventSlugName });
    }
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  fetchPostDrescriptionsBySlugnames = (slugnames) => {
    getPostsBySlugNameQuery(slugnames)
      .then((res) => {
        if (res) {
          if (res) {
            const { postDescription, next, prev } = res[0];
            if (this._mounted) {
              this.setState({
                postDescription,
                nextArtist: next,
                prevArtist: prev,
                bannerIsLoading: false,
              });
            }
          }
          this.displayDate();
        }
      })
      .catch((err) => {
        console.log(
          'Some error happened on fetch of fetchPostDescription:',
          err,
        );
      });
  };

  getEventTickets = async (postSlugName, eventSlugName = 'tours') => {
    const asociatedEvents = await getEventsByPostSlug(postSlugName);
    if (asociatedEvents.length) {
      const eventSlugs = asociatedEvents.map(item => item.eventSlugName);
      const tickets = await getTicketsByEventSlugName(eventSlugs);
      tickets.sort((a, b) => a.beginDateTime - b.beginDateTime);
      const isConcert = Boolean(eventSlugName === 'tours');

      if (tickets && tickets.length) {
        if (this._mounted) {
          this.setState({
            topComponents: [
              {
                type: 'Separator',
                borderWidth: 3,
                marginTop: false,
              },
              {
                type: 'SidebarNextDates',
                isConcert,
                headLine: {
                  es: 'Tickets',
                  en: 'Entradas',
                  ca: 'Entradas',
                },
                data: tickets,
              },
            ],
          });
        }
      }
    }
  };

  handleResize = () => {
    const deviceScreen = this.responsiveUtility.current.deviceScreen(true);
    this.setState({
      deviceScreen,
    });
  };

  displayDate = () => {
    const { components } = this.props;
    const { postDescription } = this.state;
    const updatedComponents = [...components];
    if (updatedComponents.length && updatedComponents[0].type === 'TitleLineH1') {
      updatedComponents[0].margin = 'sm:text-3xl md:text-3xl xl:text-38 lg:text-38 leading-tight mt-0';
      this.setState({ updatedComponents });
    }
    if (components.length && components[0].type === 'TitleLineH1' && postDescription.date) {
      updatedComponents.splice(1, 0, {
        type: 'NewsDate',
        color: 'black',
        margin: 'mt-1',
        createdAt: postDescription.date,
      });
      this.setState({ updatedComponents });
    }
  };

  render() {
    const { classesLeft, classesRight, components } = this.props;
    const {
      topComponents,
      bottomComponents,
      deviceScreen,
      postDescription,
      bannerIsLoading,
      prevArtist,
      nextArtist,
      updatedComponents,
      eventSlugName,
    } = this.state;
    const spotifyPlayer = components.filter(
      comp => comp.type === 'SpotifyPlayer',
    );
    return (
      <React.Fragment>
        <PSResponsiveUtility
          ref={this.responsiveUtility}
          deviceWidthCallback={this.handleResize}
        />
        {!bannerIsLoading ? (
          <SliderBannerArtistPD
            eventSlugName={eventSlugName || 'tours'}
            {...postDescription}
            prevArtist={prevArtist}
            nextArtist={nextArtist}
          />
        ) : (
          <div className="h-620 md:h-carouselMD sm:h-carouselSM" />
        )}
        <div className="py-4 w-full flex justify-end">
          <ShareMedia description={postDescription.title} />
        </div>
        {!bannerIsLoading && (
          <div className="mt-5 w-full flex md:flex-wrap sm:flex-wrap mb-12 sm:mb-0 justify-center">
            <div
              className={`'pt-8 sm:w-full md:w-full lg:w-ticket-lg xl:w-ticket-xl border-box ${classesLeft}'`}
            >
              {topComponents && (
                <ComponentIterator components={topComponents} />
              )}
              {deviceScreen !== 'sm' && deviceScreen !== 'md' ? (
                <div className="mt-6">
                  {spotifyPlayer.length ? (
                    <>
                      <Separator borderWidth={2} />
                      <ComponentIterator components={spotifyPlayer} />
                    </>
                  ) : null}
                  {bottomComponents.length ? (
                    <ComponentIterator components={bottomComponents} />
                  ) : null}
                </div>
              ) : null}
            </div>
            <div
              className={`w-full pl-6 sm:pl-0 md:pl-0 sm:mb-4 pr-4 sm:pr-0 md:pr-0 xl:w-1200 lg:w-1028 -mt-2 ${classesRight} `}
            >
              {deviceScreen === 'md' || deviceScreen === 'sm' ? (
                <Separator borderWidth={2} />
              ) : null}
              <div className="md:my-was 4">
                {deviceScreen === 'md' || deviceScreen === 'sm' ? (
                  spotifyPlayer.length ? (
                    <>
                      <ComponentIterator components={spotifyPlayer} />
                      <Separator borderWidth={2} />
                    </>
                  ) : null
                ) : null}
                <ComponentIterator
                  components={updatedComponents.filter(
                    comp => comp.type !== 'SpotifyPlayer',
                  )}
                />
              </div>
              {/* Previous concerts change place depending on deviceWidth */}
              {deviceScreen === 'md' || deviceScreen === 'sm' ? (
                <div className="mt-6">
                  {bottomComponents.length ? (
                    <ComponentIterator components={bottomComponents} />
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

SingleConcertPage.propTypes = {
  components: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  shareMedia: PropTypes.bool,
  width: PropTypes.number,
  classesLeft: PropTypes.string,
  classesRight: PropTypes.string,
  match: PropTypes.shape({}).isRequired,
};

SingleConcertPage.defaultProps = {
  shareMedia: false,
  width: 4,
  classesLeft: '',
  classesRight: '',
};

export default withRouter(SingleConcertPage);
