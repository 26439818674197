import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter, useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';

import { useAuth } from '@primaveralabs/auth';
import Link from '../../../util/link';

import SearchOverlay from '../SearchOverlay';
import translateObj from '../../../util/translateObj';

import { useRoutes } from '../../../context/routesContext';
import withAllContexts from '../../../context/withAllContexts';
import { HeaderContext } from '../../../context/headerContext';
import AuthWrapper from '../Auth/AuthWrapper';
import { useBraze } from '../../../context/useBraze';
import translation from '../UserPanel/translations/common';

const ARIA_LABEL_SEARCH = {
  en: 'Open search screen',
  es: 'Abrir pantalla de búsqueda',
  ca: 'Obre la pantalla de cerca',
  pt: 'Abrir tela de pesquisa',
};

const AuthButtonMobile = ({ btn, token, onClick, path, notificationsNumber }) => {
  const { icon } = btn;

  if (token && path) {
    return (
      <div className="relative lg:hidden xl:hidden xxl:hidden">
        {notificationsNumber > 0 && <div className="h-3 w-3 rounded-full bg-red absolute pin-t pin-r" />}
        <Link to="/user-panel" localizationpath={path}>
          <div className="h-8 w-8 rounded-full bg-primavera-green flex items-center justify-center rounded-full text-sm">
            <div className={`${icon} text-black`} />
          </div>
        </Link>
      </div>
    );
  } if (!token && onClick) {
    return (
      <button
        type="button"
        className="h-8 w-8 rounded-full bg-white border border-black flex items-center justify-center rounded-full text-sm lg:hidden xl:hidden xxl:hidden"
        onClick={onClick}
      >
        <div className={`${icon} text-black`} />
      </button>
    );
  }
};

const HeaderRedesignLoginSearch = ({ name, registration, params }) => {
  const [isActiveSearch, setIsActiveSearch] = useState(false);
  const { numberOfContentCards } = useBraze();
  const history = useHistory();
  const { path, language } = useRoutes();
  const { setAuthOpen } = useContext(HeaderContext);
  const { user, token } = useAuth();

  const handleSearchOverlay = () => {
    setIsActiveSearch({
      isActiveSearch: !isActiveSearch,
    });
  };

  const handleSearch = (query, lang) => {
    history.push(`/search?query=${query}&lang=${lang}`);
    handleSearchOverlay();
  };

  useEffect(() => {
    const cookieVerified = Cookies.get('verified');
    if (user && !user.verified && !cookieVerified) {
      setTimeout(() => {
        setAuthOpen('complete');
      }, 1000);
    }
  }, [user]);

  const search = params.find((p) => p.key === 'search');
  const login = params.find((p) => p.key === 'login');

  return (
    <>
      <div className="h-full flex items-center justify-center mx-2 flex-row">
        {/* SEARCH BUTTON */}
        <Link
          role="button"
          to={`${search.link}`}
          localizationpath={path}
          aria-label={translateObj(ARIA_LABEL_SEARCH, language)}
          className="w-8 h-8 flex items-center justify-center"
        >
          <div className={`text-base text-black sm:text-2xl md:text-2xl lg:text-xl xl:text-xl ${search.icon}`} />
        </Link>
        <div className="h-3 w-px bg-black-transparent ml-2 mr-4 sm:hidden" />
        {!token ? (
          // MY ACCOUNT BUTTON (user not logged)
          <>
            <button
              type="button"
              className="sm:hidden md:hidden h-8 rounded-full bg-primavera-green px-6 py-1 flex items-center justify-center rounded-full text-sm font-americaMonoMedium uppercase text-black"
              onClick={() => setAuthOpen('login')}
              style={{ maxHeight: 32.02 }}
            >
              {translateObj(translation.myAccount, language)}
            </button>
            <AuthButtonMobile
              token={token}
              btn={login}
              path={path}
              onClick={() => setAuthOpen('login')}
            />
          </>
        ) : (
          // MY ACCOUNT BUTTON (user logged)
          <>
            <a
              type="button"
              className="sm:hidden md:hidden h-8 rounded-full bg-primavera-green p-2px flex items-center justify-between rounded-full text-sm font-americaMedium text-black"
              style={{ gap: 8, maxHeight: 32.02 }}
              href={`/${language}/user-panel`}
            >
              <div
                className="rounded-full flex items-center justify-center relative"
                style={{ backgroundColor: '#5EAD89', height: 28, width: 28 }}
              >
                <span className={`h-4 w-4 ${login.icon}`} />
                {numberOfContentCards > 0 && <div className="h-3 w-3 rounded-full bg-red absolute pin-t pin-r" />}
              </div>
              <div className="flex items-center justify-between pr-2" style={{ gap: 8 }}>
                {`${user?.name || user?.nickname} ${user?.lastname ? `${user.lastname.substring(0, 1)}.` : ''}`}
              </div>
            </a>
            <AuthButtonMobile
              token={token}
              btn={login}
              path={path}
              notificationsNumber={numberOfContentCards}
            />
          </>
        )}
      </div>
      <AuthWrapper registration={registration} />
      {isActiveSearch ? (
        <SearchOverlay
          closeCallback={handleSearchOverlay}
          searchCallback={handleSearch}
        />
      ) : null}
    </>
  );
};

HeaderRedesignLoginSearch.propTypes = {
  name: PropTypes.string.isRequired,
  registration: PropTypes.shape().isRequired,
  params: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
};

AuthButtonMobile.propTypes = {
  btn: PropTypes.shape().isRequired,
  token: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  path: PropTypes.string,
  notificationsNumber: PropTypes.number,
};

AuthButtonMobile.defaultProps = {
  onClick: null,
  path: null,
  notificationsNumber: 0,
};

export default withAllContexts(withRouter(HeaderRedesignLoginSearch));
