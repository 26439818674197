function handleFilter(filters, params) {
  let paramsToReturn = [];
  if (filters.byEvent && filters.byEvent.length) {
    const filterByEvent = [];
    params.forEach((param) => {
      if (hasTag(param.tags, filters.byEvent)) {
        filterByEvent.push(param);
      }
    });
    filterYear(filters, filterByEvent);
  } else {
    filterYear(filters, params);
  }

  function filterYear(filters, params) {
    if (filters.byYear && filters.byYear.length) {
      const filterByYear = [];
      params.forEach((param) => {
        if (hasTag(param.tags, filters.byYear)) {
          filterByYear.push(param);
        }
      });
      filterMaterial(filters, filterByYear);
    } else {
      filterMaterial(filters, params);
    }
  }

  function filterMaterial(filters, params) {
    if (filters.byMaterial && filters.byMaterial.length) {
      const filterByMaterial = [];
      params.forEach((param) => {
        if (hasTag(param.tags, filters.byMaterial)) {
          filterByMaterial.push(param);
        }
      });
      filterByMaterial.sort((a, b) => a.title.en.localeCompare(b.title.en));
      filterDay(filters, filterByMaterial);
    } else {
      filterDay(filters, params);
    }
  }

  function filterDay(filters, params) {
    if (filters.byDay && filters.byDay.length) {
      const filterByDay = [];
      params.forEach((param) => {
        if (hasTag(param.tags, filters.byDay)) {
          filterByDay.push(param);
        }
      });
      filterTag(filters, filterByDay);
    } else {
      filterTag(filters, params);
    }
  }


  function filterTag(filters, params) {
    if (filters.byTag && filters.byTag.length) {
      const filterByTag = [];
      params.forEach((param) => {
        if (hasTag(param.tags, filters.byTag)) {
          filterByTag.push(param);
        }
      });
      paramsToReturn = filterByTag;
    } else {
      paramsToReturn = params;
    }
  }


  function hasTag(filters, tags) {
    return filters.some(filter => tags.includes(filter));
  }

  return paramsToReturn;
}

export default handleFilter;
