import gql from 'graphql-tag';

import { clientWeb, persistor } from '../graphqlAPI';

const GET_MY_AGENDA = gql`
  query getUserFavourites($fetchDate: String) {
    getUserFavourites(fetchDate: $fetchDate) {
      favouriteArtists
    }
  }
`;

const executeQuery = async (type = 'network') => {
  const result = await clientWeb.query({
    query: GET_MY_AGENDA,
    variables: { fetchDate: new Date().getTime().toString() },
    fetchPolicy: 'no-cache',
  })
    .then((queryResult) => queryResult)
    .catch((queryError) => {
      const errorMessage = type === 'network'
        ? `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error: ${queryError}`
        : `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error after Cache Restore: ${queryError}`;
      console.log('\x1b[31m%s\x1b[0m', errorMessage);

      return null;
    });

  if (result.data && result.data.getUserFavourites) {
    const { data: { getUserFavourites: getUserFavouritesData } } = result;
    return getUserFavouritesData?.favouriteArtists || [];
  }

  return [];
};

const getUserFavourites = async (reunionsPage, notificationsPage, sessionsPage) => {
  try {
    const JSONObject = await executeQuery('network', reunionsPage, notificationsPage, sessionsPage);

    if (persistor) {
      persistor.persist();
    }

    return JSONObject;
  } catch (jsonError) {
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: JSON Object Error: ${jsonError}`);

    if (persistor) {
      const persistorStatus = await persistor.restore().then(() => 'OK');
      console.log(`${new Date().toLocaleTimeString()} ~ Client: Apollo Cache Restored - ${persistorStatus}`);

      try {
        const JSONObject = await executeQuery('cache');

        return JSONObject;
      } catch (jsonErrorAfterCacheRestore) {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: JSON Object Error after Cache Restore, sending 404: ${jsonErrorAfterCacheRestore}`);
        return null;
      }
    }
    return null;
  }
};

export default getUserFavourites;
