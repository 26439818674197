import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useRoutes } from '../../../context/routesContext';
import { getCountryFromCode } from '../../../util/getCountryNamesByCode';
import { BUSINESS_OPTIONS } from './util/optionValues';

import translateObj from '../../../util/translateObj';
import getTranslation from './util/translations/_getTranslation';
import localTranslation from './util/translations/MemberItem';
import setFriend from '../../../api/graphql/mutations/setFriend';

import Button from './Button';
import SquareImage from './SquareImage';
import MemberItemDetail from './MemberItemDetail';
import IconButton from './IconButton';

const MemberItem = ({
  _id,
  nickname,
  name,
  lastname,
  inputCountry,
  description,
  business,
  company,
  url,
  imageUrl,
  social,
  areFriends,
  translation,
  favoriteCallback,
  isActive,
  expandCallback,
}) => {
  const { language } = useRoutes();
  const [expanded, setExpanded] = useState(false);
  const [showOptimisticFavourite, setShowOptimisticFavourite] = useState(areFriends);
  const literals = getTranslation(translation, localTranslation);
  const countryISOcode = getCountryFromCode(inputCountry, language);
  const businessTranslations = BUSINESS_OPTIONS.find((o) => o.value === business)?.name;

  const textColor = expanded ? 'text-white' : 'text-black';

  const addFriend = () => {
    setShowOptimisticFavourite(!areFriends);
    favoriteCallback();
    setFriend({ friendId: `${_id}` }).then((res) => {
      if (res) {
        console.log('Friend added/removed successfully');
      }
      favoriteCallback();
    }).catch((err) => {
      favoriteCallback();
      console.log(err);
    });
  };

  const handleExpand = () => {
    setExpanded(!expanded);
    expandCallback();
  };

  useEffect(() => {
    setExpanded(isActive);
  }, [isActive]);

  return (
    <div className={`${expanded ? 'bg-black' : ''} w-full cursor-pointer px-4 -mt-1`}>
      <div className="border-b border-black py-6 sm:py-4 sm:ml-8">
        <div className="w-full flex">
          <div onClick={() => addFriend()} className="w-1/12 sm:w-auto flex items-center justify-center sm:items-stretch sm:justify-start sm:pr-5">
            <div className={`font-icon text-20 sm:text-18 w-full h-full flex items-center justify-center sm:justify-start ${showOptimisticFavourite ? 'icon-star-solid text-pro-online' : `icon-star ${expanded ? 'text-white' : 'text-black'}`} `} />
          </div>
          <div onClick={() => handleExpand()} className="w-9/12 sm:w-full flex">
            <div className="w-1/3 sm:w-full sm:flex sm:items-center sm:justify-between">
              <div className="flex items-center justify-start w-full">
                <div className={`min-w-1/3 w-1/3 sm:min-w-1/4 sm:w-1/4 ${expanded ? 'text-white' : 'text-black'}`}>
                  <SquareImage image={imageUrl} alt={nickname || `${name} ${lastname}`} />
                </div>
                <div className={`sm:flex sm:flex-col pl-4 sm:w-2/4 ${textColor}`}>
                  <div className="flex font-tiemposBold text-14">
                    <span>{`${name} ${lastname}`}</span>
                  </div>
                  <div className={`hidden sm:flex sm:flex-wrap sm:font-tiemposRegular sm:text-12 ${textColor}`}>
                    {company}
                    <span className="sm:px-1">|</span>
                    {translateObj(businessTranslations, language)}
                    <span className="sm:px-1">|</span>
                    {countryISOcode}
                  </div>
                </div>
              </div>
              <IconButton icon="font-icon icon-arrow-right" className="hidden sm:block" />
            </div>
            <div className={`sm:hidden w-2/9 flex items-center justify-start ${textColor}`}>
              {company}
            </div>
            <div className={`sm:hidden w-2/9 flex items-center justify-start ${textColor}`}>
              {translateObj(businessTranslations, language)}
            </div>
            <div className={`sm:hidden w-2/9 flex items-center justify-start ${textColor}`}>
              {countryISOcode}
            </div>
          </div>
          <div className={`sm:hidden w-2/12 flex items-center justify-end ${textColor}`}>
            {expanded
              ? (
                <Button
                  text={translateObj(literals.buttonInvite, language)}
                  plusIcon
                  color="orange"
                  link={`/pro-create-reunion/?id=${_id}&action=invite`}
                />
              )
              : <Button text={translateObj(literals.buttonContact, language)} color="outlineBlack" onClickCallback={() => handleExpand()} />}
          </div>
        </div>
        {expanded && (
          <div className="hidden sm:block sm:pt-6">
            <Button text={translateObj(literals.buttonInvite, language)} plusIcon color="orange" link={`/pro-create-reunion/?id=${_id}&action=invite`} />
          </div>
        )}
      </div>
      {expanded && (
        <div className="w-full">
          <MemberItemDetail
            id={_id}
            name={name}
            lastname={lastname}
            url={url}
            social={social}
            description={description}
            buttonChat={literals.buttonContact}
          />
        </div>
      )}
    </div>
  );
};

MemberItem.propTypes = {
  _id: PropTypes.string,
  imageUrl: PropTypes.string,
  nickname: PropTypes.string,
  name: PropTypes.string,
  lastname: PropTypes.string,
  company: PropTypes.string,
  business: PropTypes.string,
  inputCountry: PropTypes.string,
  url: PropTypes.string,
  social: PropTypes.arrayOf(PropTypes.shape()),
  description: PropTypes.string,
  translation: PropTypes.shape(),
  areFriends: PropTypes.bool,
  favoriteCallback: PropTypes.func,
  isActive: PropTypes.bool,
  expandCallback: PropTypes.func,
};

MemberItem.defaultProps = {
  _id: '',
  imageUrl: '',
  nickname: '',
  name: '',
  lastname: '',
  company: '',
  business: '',
  inputCountry: '',
  url: '',
  social: [],
  description: '',
  translation: null,
  areFriends: false,
  favoriteCallback: null,
  isActive: false,
  expandCallback: null,
};

export default MemberItem;
