import gql from 'graphql-tag';

import { clientRadio } from '../graphqlAPI';

const GET_RADIO_TAGS = gql`
  { radioTags {
    id 
    tagName_multilang{
      en
      es
      ca
    }
    isCategory
  }
  }
`;

const getRadioTags = async () => {
  if (!process.env.RADIO_GRAPHQL_SERVER_URL) {
    return [];
  }

  try {
    const result = await clientRadio
      .query({
        query: GET_RADIO_TAGS,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
        fetchResults: true,
        pollInterva: 300000,
      })
      .then(queryResult => queryResult)
      .catch((queryError) => {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error: ${queryError}`);
        return null;
      });

    const {
      data: { radioTags },
    } = result;

    if (radioTags) {
      return radioTags;
    }
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: GraphQL Query Error: radioTags`);
    return null;
  } catch (error) {
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: getRadioTags Error: ${error}`);
    return null;
  }
};

export default getRadioTags;
