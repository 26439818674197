import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// OPTION STRUCTURE:
// {
//   name: string,
//   value: string,
//   defaultValue: boolean, (optional - mark option as default selection)
// }

const RadioButton = ({ label, isSelected, onClickCallback }) => {
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    setSelected(isSelected);
  }, [isSelected]);

  return (
    <div className="flex items-center mr-4 sm:mr-0 sm:py-1 cursor-pointer sm:w-1/2" onClick={() => onClickCallback()}>
      <div className="h-6 w-6 border border-black rounded-full mr-2 flex items-center justify-center">
        <div className={`rounded-full h-2.5 w-2.5 ${selected ? 'bg-black' : 'bg-transparent'}`} />
      </div>
      <span className="text-12 font-americaMonoRegular uppercase tracking-px sm:w-2/3">
        {label}
      </span>
    </div>
  );
};

const FiltersRadioGroup = ({
  options,
  className,
  getValueCallback,
}) => {
  const [selection, setSelection] = useState(null);

  useEffect(() => {
    const defaultValue = options.find(option => option.defaultValue);
    if (defaultValue) {
      setSelection(defaultValue.value);
    } else {
      setSelection(options[0].value);
    }
  }, []);

  useEffect(() => {
    getValueCallback(selection);
  }, [selection]);

  return (
    <div className={`${className || 'flex items-center sm:justify-between mb-6 sm:mt-4'}`}>
      {options.map(option => (
        <RadioButton key={option.value} label={option.name} isSelected={option.value === selection} onClickCallback={() => setSelection(option.value)} />
      ))}
    </div>
  );
};

RadioButton.propTypes = {
  label: PropTypes.string,
  isSelected: PropTypes.bool,
  onClickCallback: PropTypes.func,
};

RadioButton.defaultProps = {
  label: {},
  isSelected: false,
  onClickCallback: null,
};

FiltersRadioGroup.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape()),
  className: PropTypes.string,
  getValueCallback: PropTypes.func,
};

FiltersRadioGroup.defaultProps = {
  options: [],
  className: '',
  getValueCallback: null,
};

export default FiltersRadioGroup;
