import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import CollapsibleList from './Collapsible/CollapsibleList';
import { useUser } from '../../context/userProfileContext';
import { useRoutes } from '../../context/routesContext';
import LoadIcon from './LoadIcon';

const submitTitle = {
  es: 'ACCEDER',
  en: 'SIGN IN',
  ca: 'ACCEDIR',
  pt: 'Entrar',
};
const registerTitle = {
  es: 'REGISTRATE',
  en: 'REGISTER',
  ca: 'REGISTRA\'T',
  pt: 'REGISTAR',
};

const LoginScreen = ({
  socialLogin, signInFormText, registerFormText, match, sourceWeb,

}) => {
  const [currentData, setCurrentData] = useState({});
  const [searchParamForRegisterRedirect, setSearchParamForRegisterRedirect] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const { userEmail = '', loadTokenForLS = () => {} } = useUser();
  const { language = 'en' } = useRoutes(match);

  const history = useHistory();
  const { search } = useLocation();

  useEffect(() => {
    // * if user is already logged in redirect to user-profile
    if (userEmail) history.push(`/${language}/user-profile${sourceWeb && `-${sourceWeb}`}`);
    else if (search) {
      const searchParams = search.split('=');
      if (searchParams.length > 1) {
        const vars = searchParams[1].split(',');
        if (vars && vars.length > 1 && vars[1]) {
          setSearchParamForRegisterRedirect(vars[1]);
        }
      }
    }
    setIsLoading(false);
  }, [userEmail]);

  const getCurrentData = (currentDataFromFn) => {
    if (currentDataFromFn) {
      setCurrentData(currentDataFromFn);
      history.push(`/${language}/login-screen${sourceWeb && `-${sourceWeb}`}?e=register`);
    }
  };

  if (isLoading) return <LoadIcon type="ThreeDots" />;

  return (
    <div className="w-full">
      <CollapsibleList
        color="white"
        firstClosed
        noLink
        components={
          [
            {
              text: submitTitle,
              button: { category: 'radio', text: submitTitle },
              content: [{
                type: 'SignInForm', socialLogin, getCurrentData, formText: signInFormText, searchParamForRegisterRedirect, sourceWeb,
              }],
            },
            {
              text: registerTitle,
              button: { category: 'barcelona', text: registerTitle },
              content: [{
                type: 'RegisterForm', formText: registerFormText, preFilledValues: currentData, loadTokenForLS: () => loadTokenForLS(), searchParamForRegisterRedirect, sourceWeb,

              }],
            },
          ]
        }
      />

    </div>
  );
};

LoginScreen.propTypes = {
  match: PropTypes.shape().isRequired,
  text: PropTypes.shape({}),
  submitTitle: PropTypes.shape({}),
  registerTitle: PropTypes.shape({}),
  socialLogin: PropTypes.shape({}),
  signInFormText: PropTypes.shape({}),
  registerFormText: PropTypes.shape({}),
  sourceWeb: PropTypes.string,
};

LoginScreen.defaultProps = {
  text: null,
  submitTitle,
  registerTitle,
  socialLogin: null,
  signInFormText: {},
  registerFormText: {},
  sourceWeb: '',
};


export default LoginScreen;
