import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import moment from 'moment';
import { useChat } from '../context/chatContext';
import LoadIcon from './LoadIcon';
import UserListItem from './UserListItem';

const UserList = ({ channel, useNormalCard }) => {
  const { activeUsers, getActiveUsers, loadMoreUsers } = useChat();
  const [isLoading, setIsLoading] = useState(true);
  const [loadMoreCount, setLoadMoreCount] = useState(0);

  useEffect(() => {
    if (activeUsers) {
      setIsLoading(false);
    }
  }, [activeUsers]);


  const renderUserList = () => (
    <div className="pt-2 pb-40">
      {!isLoading ? activeUsers.map(user => (
        <UserListItem channel={channel} isNormalCard={useNormalCard} cardOwner={user} key={user.userId} />
      )) : <LoadIcon width={10} height={10} />}
      {loadMoreUsers && !isLoading
        ? (
          <div className="w-full text-center mt-2">
            <button className="bg-psworld-grey opacity-85 rounded px-4 py-1 font-americaMonoRegular shadow-default hover:bg-white hover:opacity-1 trans text-xs uppercase" type="button" onClick={() => { setLoadMoreCount(loadMoreCount + 1); getActiveUsers(true); }}>Load more</button>
          </div>
        ) : null}
    </div>
  );

  return (
    <div className="flex-2 chat-container overflow-y-auto overflow-x-hidden relative scrollbar__streampage">
      {renderUserList()}
    </div>
  );
};

UserList.propTypes = {
  channel: PropTypes.string,
  useNormalCard: PropTypes.bool,
};
UserList.defaultProps = {
  channel: null,
  useNormalCard: false,
};

export default UserList;
