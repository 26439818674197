import React from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import getRadioShowsNow from '../../../api/graphql/queries/getRadioShowsNow';
import getRadioSchedule from '../../../api/graphql/queries/getRadioSchedule';
import LoadIcon from '../LoadIcon';
import RadioTodayGridHeader from './RadioTodayGridHeader';
import RadioTodayGridTable from './RadioTodayGridTable';

const filters = {
  title: {
    en: 'on Channel 1',
    ca: 'on Channel 1',
    es: 'on Channel 1',
  },
  today: {
    en: 'Today',
    ca: 'Avui',
    es: 'Hoy',
  },
  filterOn: {
    en: 'Filter schedule by date',
    ca: 'Filtrar programació per data',
    es: 'Filtrar programación por fecha',
  },
  text3: {
    en: 'Day',
    ca: 'Dia',
    es: 'Día',
  },
  text4: {
    en: 'Month',
    ca: 'Mes',
    es: 'Mes',
  },
  text5: {
    en: 'Year',
    ca: 'Any',
    es: 'Año',
  },
  dayBeforeText: {
    en: 'Day before',
    ca: 'Dia abans',
    es: 'Día antes',
  },
  dayAfterText: {
    en: 'Day after',
    ca: 'Dia després',
    es: 'Día después',
  },
  dateFormat: {
    en: 'DD/MM/YYYY',
    ca: 'DD/MM/YYYY',
    es: 'DD/MM/YYYY',
  },
  icon1: 'font-icon icon-arrow-left text-white text-xxs',
  icon2: 'font-icon icon-arrow-right text-white text-xxs',
  icon3: 'font-icon icon-arrow-down text-white text-xxs self-center pr-1',
};

const list = {
  category: 'radio',
  text1: {
    en: 'Hora',
    ca: 'Hora',
    es: 'Hora',
  },
  text2: {
    en: 'Programación',
    ca: 'Programación',
    es: 'Programación',
  },
  text3: {
    en: 'Género',
    ca: 'Género',
    es: 'Género',
  },
  text4: {
    en: 'Live Now',
    ca: 'Live Now',
    es: 'Live Now',
  },
  icon1: 'font-icon icon-arrow-up-round text-black text-30',
  icon2: 'font-icon icon-arrow-down-round text-black text-30',
  icon3: 'font-icon icon-current2',
};
class RadioTodayGrid extends React.Component {
  state = {
    channel: null,
    radioSchedule: [],
    items: [],
    isLoading: true,
  }

  componentDidMount() {
    const { match: { params } } = this.props;

    this.setState({ channel: 1 }, () => {
      const now = moment().format('YYYY-MM-DD');
      this.getAllTodayShows(`${now}T06:00:00.000Z`);
    });
  }

  componentDidUpdate(prevProps) {
    const { match: { params } } = this.props;
    const prevChannel = prevProps.match.params.channel;
    if (params.channel !== prevChannel) {
      this.setState({ channel: 1, isLoading: true }, () => {
        const now = moment().format('YYYY-MM-DD');
        this.getAllTodayShows(`${now}T06:00:00.000Z`);
      });
    }
  }

  getAllTodayShows = (date) => {
    const { channel } = this.state;
    getRadioSchedule(date)
      .then((result) => {
        if (result) {
          this.setState({
            radioSchedule: result,
          }, () => this.filterProgramsByChannel());
        }
      })
      .catch((err) => {
        console.log('Couldnt fetch today shows', err);
      });
  }

  handleNewDate = (date) => {
    this.setState({ isLoading: true }, () => {
      const now = moment(date).format('YYYY-MM-DD');
      this.getAllTodayShows(`${now}T06:00:00.000Z`);
    });
  }

  filterProgramsByChannel = () => {
    const { radioSchedule, channel } = this.state;
    const items = radioSchedule.filter(item => item.channel === channel);

    this.setState({
      items,
      isLoading: false,
    });
  }

  render() {
    if (list.items) delete list.items;
    const {
      items, isLoading, channel,
    } = this.state;
    const { match: { params } } = this.props;
    return (
      <div className="w-full">
        {

          <React.Fragment>
            <RadioTodayGridHeader {...list} {...filters} topTitle={params.channel || 'RPS'} channel={channel} changeChannel={this.changeChannel} handleNewDate={this.handleNewDate} />
            {!isLoading
              ? (
                <RadioTodayGridTable channel={channel} {...list} items={items} />
              ) : <div className="h-32 flex items-center justify-center"><LoadIcon color="radio" /></div>}

          </React.Fragment>

        }
      </div>
    );
  }
}

export default withRouter(RadioTodayGrid);
