import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';
import ParagraphLineSM from './Text/ParagraphLineSM';
import imageModifier from './helpers/imageModifier';

import translateObj from '../../util/translateObj';

const downloadText = {
  en: 'Download',
  es: 'Descargar',
  ca: 'descarregar',
};
const Pdf = ({
  fileReadableName, url, match, poster,
}) => (
  <RoutesContext.Consumer>
    {
      ({ language: contextLanguage, enteredFrom }) => {
        const localization = getLocalization(contextLanguage, enteredFrom, match);
        const backgroundStyle = {
          backgroundRepeat: 'no-repeat',
          backgroundImage: `url(${translateObj(poster, localization.language)})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '260px',
        };
        const pdfRow = [{ fileReadableName, url, poster }];
        return (
          <div className="flex flex-wrap md:-mx-2 lg:-mx-2 xl:-mx-2 mt-2 w-full">
            {pdfRow.map(pdf => (
              <div key={translateObj(pdf.fileReadableName, localization.language)} className="cursor-pointer sm:w-full md:w-1/2 lg:w-1/3 xl:w-1/4 py-2 px-2 sm:px-0">
                {/* <p className="font-americaBlack text-18 flex items-end pb-2 h-12">{translateObj(pdf.fileReadableName, localization.language)}</p> */}
                <a href={translateObj(pdf.url, localization.language)} download target="_blank" rel="noopener noreferrer" className="text-black block w-full overflow-hidden">
                  <div
                    className="relative overflow-hidden w-full"
                    style={backgroundStyle}
                  />
                </a>
                <div className="flex justify-end pt-2">
                  <a href={translateObj(pdf.url, localization.language)} download target="_blank" rel="noopener noreferrer" className="text-black">
                    <ParagraphLineSM classes="cursor-pointer uppercase font-bold text-xxs my-0" iconClass="font-icon icon-download1" text={downloadText} />
                  </a>
                </div>
              </div>
            ))}

          </div>
        );
      }
    }
  </RoutesContext.Consumer>
);

Pdf.propTypes = {
  fileReadableName: PropTypes.shape({}),
  poster: PropTypes.shape({}),
  match: PropTypes.shape({}).isRequired,
  url: PropTypes.shape({}),
};

Pdf.defaultProps = {
  url: {},
  fileReadableName: {},
  poster: {},
};

export default withRouter(Pdf);
