import React from 'react';
import PropTypes from 'prop-types';

const TopHeaderMainMenu = ({ children, name }) => (
  <div id={name} className="flex items-center justify-end sm:block md:block relative xl:h-68px lg:h-68px sm:text-right md:text-right mmd:text-right">
    {children}
  </div>
);

TopHeaderMainMenu.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.shape().isRequired,
};


export default TopHeaderMainMenu;
