import React from 'react';
import PropTypes from 'prop-types';
import { useRoutes } from '../../../../context/routesContext';
import { LinkWrapper } from '../../../../util/link';

import CopyIcon from './svg/CopyIcon';
import LinkIcon from './svg/LinkIcon';

const RewardCodeLink = ({ reward, code, rewardLink, linkLabel }) => {
  const { path } = useRoutes();

  const content = (
    <div
      className="flex pl-5 border-l-1 items-center border-grayEFEFEF uppercase"
      style={{ gap: '0.8rem' }}
    >
      <LinkIcon />
      <span className="text-xs text-white">{linkLabel}</span>
    </div>
  );

  const hasHTTP = rewardLink?.toLowerCase().includes('http');

  const anchorOrLink = hasHTTP ? (
    <a href={rewardLink} rel="noreferrer noopener" target="_blank">
      {content}
    </a>
  ) : (
    <div>
      <LinkWrapper url={`/${rewardLink}`} path={path}>
        {content}
      </LinkWrapper>
    </div>
  );
  return (
    <div className="flex p-3 bg-grey-darker rounded-lg">
      {(code) && (
        <button
          type="button"
          onClick={() => navigator.clipboard.writeText(code)}
          className={`flex ${rewardLink ? 'pr-5' : ''} items-center`}
          style={{ gap: '0.8rem' }}
        >
          <CopyIcon />
          <p className="text-xs text-white font-bold">
            {code}
          </p>
        </button>
      )}
      {reward.link || rewardLink ? anchorOrLink : null}
    </div>
  );
};

RewardCodeLink.propTypes = {
  reward: PropTypes.shape().isRequired,
  code: PropTypes.string,
  rewardLink: PropTypes.string,
  linkLabel: PropTypes.string,
};

RewardCodeLink.defaultProps = {
  code: '',
  rewardLink: '',
  linkLabel: '',
};

export default RewardCodeLink;
