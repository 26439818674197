import React from 'react';
import PropTypes from 'prop-types';

const RewardImage = ({ src, alt }) => (
  <div style={{ width: '100%', height: '100%' }}>
    <div style={{
      WebkitMaskImage: 'url(https://assets.primaverasound.com/psweb/dgxbu8t6ztuzw1p4pfx1_1681669022673.png)',
      maskImage: 'url(https://assets.primaverasound.com/psweb/dgxbu8t6ztuzw1p4pfx1_1681669022673.png)',
      maskRepeat: 'no-repeat',
      '-webkit-mask-repeat': 'no-repeat',
      maskPosition: 'center',
      '-webkit-mask-position': 'center',
      maskSize: 'contain',
      '-webkit-mask-size': 'contain',
      aspectRatio: '1/1',
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#F8D628',
    }}
    >
      <div style={{
        WebkitMaskImage: 'url(https://assets.primaverasound.com/psweb/dgxbu8t6ztuzw1p4pfx1_1681669022673.png)',
        maskImage: 'url(https://assets.primaverasound.com/psweb/dgxbu8t6ztuzw1p4pfx1_1681669022673.png)',
        maskRepeat: 'no-repeat',
        '-webkit-mask-repeat': 'no-repeat',
        maskPosition: 'center',
        '-webkit-mask-position': 'center',
        maskSize: 'contain',
        '-webkit-mask-size': 'contain',
        aspectRatio: '1/1',
        width: '94%',
        height: '94%',
        position: 'absolute',
      }}
      >
        <img
          src={src}
          alt={alt}
          style={{
            objectFit: 'cover',
            aspectRatio: '1/1',
          }}
        />
      </div>
    </div>
  </div>
);

RewardImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

RewardImage.defaultProps = {
};

export default RewardImage;
