import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Formsy from 'formsy-react';

import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';
import getCountryNamesByCode, { getCodeFromCountry } from '../../../util/getCountryNamesByCode';
import translateObj from '../../../util/translateObj';

import signupUserForm from '../../../api/graphql/mutations/signupUserForm';

import Filter from '../Collapsible/Filter';
import FormInput from '../Form/FormInput';
import FormCheckbox from '../Form/FormCheckbox';
import FormCheckboxConditions from '../Form/FormCheckboxConditions';
import FormSelectStyle from '../Form/FormSelectStyle';
import FormSelectAutoComplete from '../Form/FormSelectAutoComplete';
import DatePickerFormsy from './DatePickerFormsy';
import LoadIcon from '../LoadIcon';


// const titleByDefault = {
//   es: 'REGISTRATE',
//   en: 'REGISTER',
//   ca: 'REGISTRAT',
// };
const defaultFormText = {
  name: {
    es: 'Nombre',
    en: 'First name',
    ca: 'Nom',
  },
  lastname: {
    es: 'Apellidos',
    en: 'Last name',
    ca: 'Cognoms',
  },
  selectDateMessage: {
    es: 'Selecciona la fecha',
    en: 'Select date',
    ca: 'Selecciona la data',
  },
  email: {
    es: 'Correo electrónico',
    en: 'Email',
    ca: 'Correu electrónic',
  },
  phone: {
    es: 'Teléfono',
    en: 'Phone',
    ca: 'Telefon',
  },
  choose: {
    es: 'Eligir',
    en: 'Choose',
    ca: 'Escullir',
  },
  country: {
    es: 'PAIS*',
    en: 'COUNTRY*',
    ca: 'PAIS*',
  },
  city: {
    es: 'AREA DE CONTACTO*',
    en: 'CITY*',
    ca: 'AREA DE CONTACTO*',
  },
  postalcode: {
    es: 'CÓDIGO POSTAL',
    en: 'POSTAL CODE',
    ca: 'CODI POSTAL',
  },
  password: {
    es: 'CREAR CONTRASEÑA',
    en: 'CREATE PASSWORD',
    ca: 'CREA CONTRASENYA',
  },
  repeatPassword: {
    es: 'REPETIR CONTRASEÑA',
    en: 'RE-ENTER PASSWORD',
    ca: 'REPETIR CONTRASENYA',
  },
  inValidName: {
    es: 'This is not a valid name',
    en: 'This is not a valid name',
    ca: 'This is not a valid name',
  },
  longName: {
    es: 'This name is too long',
    en: 'This name is too long',
    ca: 'This name is too long',
  },
  inValidEmail: {
    es: 'This is not a valid email',
    en: 'This is not a valid email',
    ca: 'This is not a valid email',
  },
  longEmail: {
    es: 'This email is too long',
    en: 'This email is too long',
    ca: 'This email is too long',
  },
  inValidPhone: {
    es: 'This is not a valid phone number',
    en: 'This is not a valid phone number',
    ca: 'This is not a valid phone number',
  },
  longPhone: {
    es: 'This phone number is too long',
    en: 'This phone number is too long',
    ca: 'This phone number is too long',
  },
  invalidpostalcode: {
    es: 'This is not a valid postal code',
    en: 'This is not a valid postal code',
    ca: 'This is not a valid postal code',
  },
  shortPassword: {
    es: 'This password is too short',
    en: 'This password is too short',
    ca: 'This password is too short',
  },
  repeatPasswordError: {
    es: 'Passwords do not match',
    en: 'Passwords do not match',
    ca: 'Passwords do not match',
  },
  text: {
    es: 'Te informamos que los datos personales que facilites pasarán a formar parte de un fichero responsabilidad de PRIMAVERA SOUND S.L. para gestionar tu petición. Puedes ejercer los derechos de acceso, rectificación, cancelación u oposición al tratamiento de tus datos en el e-mail señalado lopd@primaverasound.com o en el domicilio sito en C/ Àlaba, 140, 2º 4ª, 08018 de Barcelona.',
    en: 'We inform you que los datos personales que facilites pasarán a formar parte de un fichero responsabilidad de PRIMAVERA SOUND S.L. para gestionar tu petición. Puedes ejercer los derechos de acceso, rectificación, cancelación u oposición al tratamiento de tus datos en el e-mail señalado lopd@primaverasound.com o en el domicilio sito en C/ Àlaba, 140, 2º 4ª, 08018 de Barcelona.',
    ca: 'Te informamos que los datos personales que facilites pasarán a formar parte de un fichero responsabilidad de PRIMAVERA SOUND S.L. para gestionar tu petición. Puedes ejercer los derechos de acceso, rectificación, cancelación u oposición al tratamiento de tus datos en el e-mail señalado lopd@primaverasound.com o en el domicilio sito en C/ Àlaba, 140, 2º 4ª, 08018 de Barcelona.',
  },
  conditions: {
    es: 'He leído y acepto las condiciones y la Ley de tratamiento de datos.',
    en: 'I read y acepto las condiciones y la Ley de tratamiento de datos.',
    ca: 'He leído y acepto las condiciones y la Ley de tratamiento de datos.',
  },
  submitButtonText: {
    es: 'ENVIAR',
    en: 'SUBMIT',
    ca: 'ENVIAR',
  },
  resetButtonText: {
    es: 'BORRAR',
    en: 'RESET',
    ca: 'BORRAR',
  },
  submitMessage: {
    es: 'GRACIAS, SE HA CREADO TU CUENTA',
    en: 'THANKS, YOUR ACCOUNT HAS BEEN CREATED',
    ca: "GRÀCIES, S'HA CREAT EL TEU COMPTE",
  },
  birthDateText: {
    es: 'Fecha de nacimiento',
    en: 'Date of birth',
    ca: 'Data de naixement',
  },

};


const birthDateText = {
  es: 'Fecha de nacimiento',
  en: 'Date of birth',
  ca: 'Data de naixement',
};

class RegisterForm extends Component {
  state = {
    canSubmit: false,
    didSubmit: false,
    loading: false,
    dateOfBirth: null,
    errorMsg: '',
    beingSubmittedFromData: null,
  };

  componentDidMount = () => {
    const body = document.getElementById('bodyChildContainer');
    body.childNodes[0].setAttribute('style', 'border-top: none');
  }


  submitForm = (formData, language, socialnetwork) => {
    const {
      loadTokenForLS, history, preFilledValues: { spotifyToken }, searchParamForRegisterRedirect = '', sourceWeb,
    } = this.props;
    const { dateOfBirth } = this.state;
    this.setState({
      canSubmit: false,
      loading: true,
    });


    const formDataUpdated = {
      ...formData, language, dateOfBirth, socialnetwork,
    };

    // * add country ISO code instead of country name
    let countryISOCode = '';
    if (formData && formData.inputCountry) {
      countryISOCode = getCodeFromCountry(formData.inputCountry, language) || 'ES';
    }

    if (countryISOCode) {
      formDataUpdated.inputCountry = countryISOCode;
    }

    // add spotify initial code in order to sign in user
    if (socialnetwork === 'spotify') {
      formDataUpdated.spotifyToken = spotifyToken;
    }


    signupUserForm({ ...formDataUpdated }).then((response) => {
      if (response) {
        if (response.isValid) {
          // this.onReset();
          this.setState({
            didSubmit: true,
            loading: false,
          }, () => {
            localStorage.setItem(process.env.AUTH_TOKEN, response.token);
            document.cookie = `${process.env.AUTH_TOKEN}=${response.token}; Secure; HttpOnly`;
            loadTokenForLS();
            // Redirect to main page
            const redirectPath = searchParamForRegisterRedirect || `/${language}${sourceWeb && `/${sourceWeb}`}`;
            history.push(redirectPath);
          });
        } else if (response.error) {
          formData.email = '';
          this.setState({
            beingSubmittedFromData: formData,
            errorMsg: response.error,
            didSubmit: false,
            loading: false,
          });
        } else {
          console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Error - submitForm validated, but returned isValid = false!`);
        }
      } else {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Error - submitForm failed to submit!`);
      }
    });
  };

  onReset = () => {
    this.form.reset();

    this.name.setValue('');
    this.lastname.setValue('');
    this.email.setValue('');
    this.phone.setValue('');
    this.oauthId.setValue('');
    this.postalCode.setValue('');
    this.conditions.setValue(false);
    this.newsletter.setValue(false);
  };

  enableButton = () => {
    this.setState({ canSubmit: true });
  };

  disableButton = () => {
    this.setState({ canSubmit: false });
  };

  getCurrentPrefilledValues = (preFilledValues) => {
    const currentValues = {};

    if (preFilledValues) {
      const {
        isSocialLogin, givenName, familyName, email, googleId, facebookId, spotifyId,
      } = preFilledValues;

      switch (isSocialLogin) {
        case 'google':
          currentValues.name = givenName;
          currentValues.lastname = familyName;
          currentValues.email = email;
          currentValues.isSocialLogin = isSocialLogin;
          currentValues.oauthId = googleId;
          break;
        case 'facebook':
          currentValues.name = givenName;
          currentValues.lastname = familyName;
          currentValues.email = email;
          currentValues.isSocialLogin = isSocialLogin;
          currentValues.oauthId = facebookId;
          break;
        case 'spotify':
          currentValues.name = givenName;
          currentValues.lastname = familyName;
          currentValues.email = email;
          currentValues.isSocialLogin = isSocialLogin;
          currentValues.oauthId = spotifyId;
          break;
        default:
          break;
      }
    }

    return currentValues;
  }

  _didSubmitScreen = (language) => {
    const { formText } = this.props;
    return (
      <div className="flex w-full py-12 justify-center font-americaMonoRegular">
        <h3>{translateObj(formText.submitMessage, language)}</h3>
      </div>
    );
  }

  _formScreen = (language) => {
    const {
      menu, preFilledValues, text,
      formText,
    } = this.props;
    const { beingSubmittedFromData } = this.state;

    let currentValues = {};
    if (preFilledValues && Object.keys(preFilledValues).length) {
      currentValues = this.getCurrentPrefilledValues(preFilledValues);
    } else if (beingSubmittedFromData && Object.keys(beingSubmittedFromData).length) {
      currentValues = beingSubmittedFromData;
    }

    const {
      canSubmit, errorMsg,
    } = this.state;

    const handleDate = (name, date) => {
      this.setState({ dateOfBirth: date });
    };

    return (
      <Fragment>
        <div className={`w-full ${menu && 'pt-12 sm:pt-6'} font-americaMonoRegular text-12 uppercase bg-white px-4 `}>
          <Formsy
            ref={(c) => { this.form = c; return this.form; }}
            onValidSubmit={e => this.submitForm(e, language, currentValues.isSocialLogin)}
            onValid={this.enableButton}
            onInvalid={this.disableButton}
            className="flex sm:flex-col md:flex-col flex-wrap w-full"
          >
            <FormInput
              ref={(c) => { this.name = c; return this.name; }}
              name="name"
              doNotChangeValidColor
              title={translateObj(formText.name, language)}
              validations={{ isSpecialWords: true, maxLength: 50 }}
              validationErrors={{ isSpecialWords: translateObj(formText.inValidName, language), maxLength: translateObj(formText.longName, language) }}
              value={currentValues.name}
              required
              className="w-full lg:w-1/2"
            />

            <FormInput
              ref={(c) => { this.lastname = c; return this.lastname; }}
              name="lastname"
              doNotChangeValidColor
              title={translateObj(formText.lastname, language)}
              validations={{ isSpecialWords: true, maxLength: 50 }}
              validationErrors={{ isSpecialWords: translateObj(formText.inValidName, language), maxLength: translateObj(formText.longName, language) }}
              value={currentValues.lastname}
              required
              className="w-full lg:w-1/2"
            />

            <FormInput
              ref={(c) => { this.email = c; return this.email; }}
              name="email"
              doNotChangeValidColor
              disabled={Boolean(currentValues && currentValues.email)}
              isError={Boolean(errorMsg)}
              title={translateObj(formText.email, language)}
              validations={{ isEmail: true, maxLength: 100 }}
              validationErrors={{ isEmail: translateObj(formText.inValidEmail, language), maxLength: translateObj(formText.longEmail, language) }}
              value={currentValues.email}
              required
              className="w-full lg:w-1/2"
            />
            {/* <FormInput
                        ref={(c) => { this.phone = c; return this.phone; }}
                        name="phone" title={translateObj(formText.phone, language)}
                        value="" validations={{ isNumeric: true, maxLength: 25 }}
                        validationErrors={{ isNumeric: translateObj(validation.inValidPhone, language), maxLength: translateObj(validation.longPhone, language) }}
                      /> */}

            {/* <FormSelectAutoComplete
                        name="country"
                        title={translateObj(fields.country, language)}
                        validations="isSpecialWords"
                        validationError=""
                        options={getCountryNamesByCode(language)}
                        message="No match"
                        required
                      /> */}

            {/* <FormSelectStyle name="country" title={fields.country[language]} validations="isSpecialWords" validationError="" options={countryOptions} required /> */}

            {/* {fields.city
                        && <FormSelectStyle name="ciutat" title={translateObj(fields.city, language)} validations="isSpecialWords" options={cityOptions} required />}
                       */}

            <FormInput
              ref={(c) => { this.postalCode = c; return this.postalCode; }}
              name="postalCode"
              doNotChangeValidColor
              title={translateObj(formText.postalcode, language)}
              validations={{ maxLength: 10 }}
              validationError={translateObj(formText.invalidpostalcode, language)}
              value={currentValues.postalCode}
              className="w-full lg:w-1/2"
            />

            <DatePickerFormsy
              title={formText.birthDateText}
              name="birthDate"
              handleDate={handleDate}
              existingDate={currentValues.birthDate}
              language={language}
              selectDateMessage={formText.selectDateMessage}
              required
              style="pb-4 md:w-full"
            />

            <FormSelectAutoComplete
              ref={(c) => { this.inputCountry = c; return this.inputCountry; }}
              name="inputCountry"
              title={translateObj(formText.country, language)}
              options={getCountryNamesByCode(language)}
              message={translateObj(formText.countryError, language)}
              value={currentValues.inputCountry}

              required
            />

            {!currentValues.isSocialLogin
              ? (
                <div className="w-full">
                  <FormInput
                    ref={(c) => { this.password = c; return this.password; }}
                    name="password"
                    title={translateObj(formText.password, language)}
                    validations={{ minLength: 8 }}
                    validationError={formText.shortPassword[language]}
                    type="password"
                    required
                    className="w-full lg:w-1/2"
                    titleClassName="flex uppercase"
                    infoText={translateObj(formText.passwordInfo, language)}
                  />
                  <FormInput
                    ref={(c) => { this.repeatPassword = c; return this.repeatPassword; }}
                    name="repeatPassword"
                    title={translateObj(formText.repeatPassword, language)}
                    validations="equalsField:password"
                    validationError={translateObj(formText.repeatPasswordError, language)}
                    type="password"
                    required
                    className="w-full lg:w-1/2"
                  />
                </div>
              ) : (
                <FormInput
                  ref={(c) => { this.oauthId = c; return this.oauthId; }}
                  name="oauthId"
                  title="Social Id Code"
                  value={currentValues.oauthId}
                  hidden
                />
              )
            }

            {errorMsg && <span className="pl-3 text-red mt-4">{errorMsg}</span>}

            <div className="flex justify-between mt-8 sm:mt-0 w-full">
              <div className="w-1/2 sm:w-full md:w-full sm:px-3 md:pl-3 font-tiemposRegular normal-case text-12">
                <p style={{ marginTop: 0 }}>{translateObj(text, language)}</p>
                <div>
                  <FormCheckboxConditions
                    required
                    name="conditions"
                    acceptText={translateObj(formText.conditions.acceptText, language)}
                    conditionsText={translateObj(formText.conditions.conditionsText, language)}
                    conditionsLink={translateObj(formText.conditions.conditionsLink, language)}
                    dataTreatmentText={translateObj(formText.conditions.dataTreatmentText, language)}
                    privacyPolicyText={translateObj(formText.conditions.privacyPolicyText, language)}
                    privacyPolicyUrl={translateObj(formText.conditions.privacyPolicyUrl, language)}
                    validations="isTrue"
                    validationError=""
                  />
                </div>
                <div>
                  <FormCheckbox
                    ref={(c) => { this.newsletter = c; return this.newsletter; }}
                    name="newsletter"
                    title={translateObj(formText.newsletter, language)}
                    isBold
                    validationError=""
                    linkText={translateObj(formText.newsletterLinkText, language)}
                    linkUrl={translateObj(formText.newsletterLinkUrl, language)}
                  />
                </div>
                <div className="hidden sm:block sm:w-full mt-6">
                  <button type="submit" disabled={!canSubmit} className={`${canSubmit ? 'bg-black' : 'bg-gray200'} h-12 text-white rounded-full px-3 w-full py-2 font-americaMonoBold`}>{translateObj(formText.submitButtonText, language)}</button>
                </div>
              </div>
              <div className="flex flex-col w-1/4 sm:hidden">
                <button type="submit" disabled={!canSubmit} className={`${canSubmit ? 'bg-black' : 'bg-gray200'} h-12 text-white rounded-full mx-4 py-2 font-americaMonoBold`}>{translateObj(formText.submitButtonText, language)}</button>
              </div>
            </div>
            <div className="m-4 flex items-center ml-auto" />
          </Formsy>
        </div>
      </Fragment>
    );
  }

  render() {
    const {
      match,
    } = this.props;

    const {
      didSubmit, loading,
    } = this.state;

    const { canSubmit } = this.state;


    return (
      <RoutesContext.Consumer>
        {
          ({ language: contextLanguage, enteredFrom }) => {
            const localization = getLocalization(contextLanguage, enteredFrom, match);
            if (loading) return (<LoadIcon height={15} width={15} color="black" type="ThreeDots" />);
            return (
              <Fragment>
                {
                  didSubmit
                    ? this._didSubmitScreen(localization.language)
                    : this._formScreen(localization.language)
                }
              </Fragment>
            );
          }
        }
      </RoutesContext.Consumer>
    );
  }
}

RegisterForm.propTypes = {
  match: PropTypes.shape(),
  menu: PropTypes.shape(),
  preFilledValues: PropTypes.shape(),
  formText: PropTypes.shape({}),
  searchParamForRegisterRedirect: PropTypes.string,
  sourceWeb: PropTypes.string,
};

RegisterForm.defaultProps = {
  match: {},
  menu: {},
  preFilledValues: {},
  formText: defaultFormText,
  searchParamForRegisterRedirect: '',
  sourceWeb: '',
};

export default withRouter(RegisterForm);
