import React from 'react';
import PropTypes from 'prop-types';
import { useRoutes } from '../../../context/routesContext';

import { LinkWrapper } from '../../../util/link';

const getColor = (color) => {
  switch (color) {
    case 'orange':
      return { text: 'text-pro-online', border: 'border-pro-online' };
    case 'black':
    default:
      return { text: 'text-black', border: 'border-black' };
  }
};

const CtaUnderline = ({
  text,
  link,
  onClickCallback,
  className,
  color,
  breakWords,
}) => {
  const { path } = useRoutes();

  const ctaColor = getColor(color);

  const content = (
    <div className={`my-4 text-base sm:text-14 uppercase tracking-px ${ctaColor.text} font-americaMonoBold`}>
      <span className={`border-b-2 ${ctaColor.border} pb-1 ${breakWords ? 'break-words' : ''}`}>{text}</span>
    </div>
  );

  if (onClickCallback) {
    return (
      <button type="button" className={className} onClick={() => onClickCallback()}>
        {content}
      </button>
    );
  }

  return (
    <div className={className}>
      <LinkWrapper path={path} url={link}>
        {content}
      </LinkWrapper>
    </div>
  );
};

CtaUnderline.propTypes = {
  text: PropTypes.string,
  link: PropTypes.string,
  onClickCallback: PropTypes.func,
  className: PropTypes.string,
  color: PropTypes.string,
  breakWords: PropTypes.bool,
};

CtaUnderline.defaultProps = {
  text: '',
  link: '',
  onClickCallback: null,
  className: '',
  color: 'black',
  breakWords: false,
};

export default CtaUnderline;
