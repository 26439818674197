import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import Formsy from 'formsy-react';
import { useAuth } from '@primaveralabs/auth';
import Link from '../../../../../util/link';
import translateObj from '../../../../../util/translateObj'
import userDeleteAccount from '../../../../../api/graphql/mutations/userDeleteAccount';
import DeleteIcon from './DeleteIcon';

import getCountryNamesByCode, { getCountryFromCode } from '../../../../../util/getCountryNamesByCode';

import { useUser } from '../../../../../context/userProfileContext';

import FormInput from '../../../Form/FormInput';
import FormSelectAutoComplete from '../../../Form/FormSelectAutoComplete';
import DatePickerFormsy from '../../DatePickerFormsy';
import SpotifyLogin from '../../SpotifyLogin/SpotifyLogin';
import LoadIcon from '../../../LoadIcon';
import InfoPopover from '../../../Form/InfoPopover';

import {
  addUserToNewsLetter, submitForm, reducer, updateButtonText, deleteButtonText, deleteAlertButtonText, initialState,
} from '../ProfileFormUtils';

const displayNewsLetterFormButton = ({
  isUserInNewsLetter, isUserInSplio, userEmail, isUserVerified, language, newsLetterTermsUrl, newsLetterTermsText, newsletterInfo, newsLetterButtonText, newsLetterConfigureText, newsletterSettingsInfo, newsLetterSubscribeText, isAddingToNewsLetter, dispatch, loadTokenForLS,
}) => {
  if (!userEmail || (!isUserVerified && isUserInNewsLetter)) return null;

  if (!isUserInSplio) {
    return (
      <div className="px-3 mb-4 sm:w-full w-1/2 sm:w-full md:w-full">
        <label className="flex flex-col" htmlFor="newsletterLink">
          <span className="flex">
            <span className="uppercase">
              <span>{translateObj(newsLetterSubscribeText, language)}</span>
              {(newsLetterTermsText && newsLetterTermsText.en && newsLetterTermsUrl && newsLetterTermsUrl.en) && (
              <Link className="text-black underline uppercase cursor-pointer" to={translateObj(newsLetterTermsUrl, language)}>{translateObj(newsLetterTermsText, language)}</Link>
              )}
            </span>
            {newsletterInfo && newsletterInfo.en && (
            <div className="ml-4">
              <InfoPopover
                text={translateObj(newsletterInfo, language)}
              />
            </div>
            )}
          </span>

          <div
            className="cursor-pointer rounded-full bg-inherit bg-black uppercase text-white text-16 xs:text-14 sm:text-14 font-americaMonoBold sm:static sm:mx-0 sm:w-full text-center md:w-full mt-4 px-12 lg:px-16 xl:px-16 py-4 flex items-center justify-center"
            onClick={() => addUserToNewsLetter(userEmail, language, dispatch, loadTokenForLS)}
          >
            {isAddingToNewsLetter ? <LoadIcon color="white" type="ThreeDots" height={15} width={15} />
            //  <div className="font-icon text-white icon-email text-18 -mt-px mr-6" />
              : <div className="uppercase">{translateObj(newsLetterButtonText, language) || 'accept'}</div>}
          </div>
        </label>
      </div>
    );
  }

  // if (isUserInSplio) {
  //   return (
  //     <div className="px-3 mb-4 sm:w-full w-1/2 sm:w-full md:w-full">
  //       <label className="flex flex-col" htmlFor="newsletterLink">
  //         <span className="flex">
  //           <span className="uppercase">
  //             <span>{translateObj(newsLetterConfigureText, language)}</span>
  //           </span>
  //           {newsletterSettingsInfo && newsletterSettingsInfo.en && (
  //           <div className="ml-4">
  //             <InfoPopover
  //               text={translateObj(newsletterSettingsInfo, language)}
  //             />
  //           </div>
  //           )}
  //         </span>
  //         <Link
  //           to={`/user-settings/newsletter-preferences?code=${userEmail}`}
  //           localizationpath={`/${language}`}
  //         >
  //           <div className="cursor-pointer rounded-full bg-inherit bg-black uppercase text-white text-16 xs:text-14 sm:text-14 font-americaMonoBold sm:static sm:mx-0 sm:w-full  text-center md:w-full mt-4 px-12 lg:px-16 xl:px-16 py-4 flex items-center  justify-center ">
  //             <div className="font-icon text-white icon-email text-18 -mt-px mr-6" />
  //           </div>
  //         </Link>
  //       </label>
  //     </div>
  //   );
  // }

  return null;
};

const ProfileFormsy = ({
  title, fields, infoFields, validation, language, setDidSubmit, history, sourceWeb,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { token, user, actions } = useAuth();

  const {
    loadTokenForLS = () => {}, setNewAuthToken,
  } = useUser();

  setNewAuthToken(token);

  const {
    canSubmit, isAddingToNewsLetter, errorMsg,
  } = state;

  const {
    firstName,
    lastName,
    nickName,
    email,
    birthDate,
    country,
    postalCode,
    newsLetterButtonText,
    newsLetterTermsText,
    newsLetterTermsUrl,
    newsLetterSubscribeText,
    newsLetterConfigureText,
    spotifyLinkText,
    spotifyLinkedText,
    spotifyUnLinkText,
  } = fields;
  const {
    firstNameInfo,
    lastNameInfo,
    nickNameInfo,
    emailInfo,
    birthDateInfo,
    countryInfo,
    postalCodeInfo,
    spotifyLinkInfo,
    spotifyLinkedInfo,
    newsletterInfo,
    newsletterSettingsInfo,
  } = infoFields;
  const {
    inValidName,
    longName,
    longEmail,
    inValidEmail,
    invalidPostalCode,
    countryError,
  } = validation;

  const enableButton = () => {
    dispatch({ type: 'canSubmit' });
  };

  const disableButton = () => {
    dispatch({ type: 'cannotSubmit' });
  };

  const handleDate = (name, date) => {
    dispatch({ type: 'setDateOfBirth', dateOfBirth: date });
  };

  const { push } = useHistory();

  const handleLogout = () => {
    actions.logout().then(() => {
      if (typeof localStorage !== 'undefined') {
        localStorage.removeItem('auth-token');
      }
      push(`/${language}`);
    });
  };

  const deleteAccount = () => {
    const userConfirmation = window.confirm(deleteAlertButtonText[language]);
    if (userConfirmation) {
      return userDeleteAccount({ email: user.email })
        .then((response) => {
          if (response && response.isDeleted) {
            handleLogout();
          } else {
            console.warn('account deletion failed', response && response.error);
          }
        });
    }
  };

  return (
    <>
      <div className="w-full bg-white py-12 px-8 border-b-1 border-dotted border-black font-americaMonoBold uppercase">
        {translateObj(title, language)}
      </div>
      <Formsy
        onValidSubmit={(formData) => submitForm(formData, user.dateOfBirth, language, dispatch, loadTokenForLS, state.dateOfBirth, setDidSubmit, history, sourceWeb, (t) => {
          actions.refresh({
            token: t,
          });
        })}
        onValid={enableButton}
        onInvalid={disableButton}
        className="flex focus:border-blue-500 bg-white sm:flex-col md:flex-col flex-wrap w-full p-4 py-12 font-americaMonoBold"
      >
        <FormInput
          name="userName"
          title={translateObj(firstName, language)}
          validations={{ isSpecialWords: true, maxLength: 50 }}
          validationErrors={{
            isSpecialWords: translateObj(inValidName, language),
            maxLength: translateObj(longName, language),
          }}
          titleClassName="flex uppercase"
          className="w-1/2 sm:w-full md:w-full"
          doNotChangeValidColor
          required
          value={user.name}
          infoText={translateObj(firstNameInfo, language)}
        />
        <FormInput
          name="userLastName"
          title={translateObj(lastName, language)}
          validations={{ isSpecialWords: true, maxLength: 50 }}
          validationErrors={{
            isSpecialWords: translateObj(inValidName, language),
            maxLength: translateObj(longName, language),
          }}
          required
          titleClassName="flex uppercase"
          className="w-1/2 sm:w-full md:w-full"
          doNotChangeValidColor
          value={user.lastname}
          infoText={translateObj(lastNameInfo, language)}
        />
        <FormInput
          name="email"
          title={translateObj(email, language)}
          disabled
          validations={{ isEmail: true, maxLength: 50 }}
          validationErrors={{
            isEmail: translateObj(inValidEmail, language),
            maxLength: translateObj(longEmail, language),
          }}
          required
          titleClassName="flex uppercase"
          className="w-1/2 sm:w-full md:w-full"
          doNotChangeValidColor
          value={user.email}
          infoText={translateObj(emailInfo, language)}
        />
        <FormInput
          name="userNickName"
          title={translateObj(nickName, language)}
          validations={{ maxLength: 15 }}
          validationErrors={{
            maxLength: translateObj(longName, language),
          }}
          titleClassName="flex uppercase"
          className="w-1/2 sm:w-full md:w-full"
          doNotChangeValidColor
          required
          value={user.nickname}
          infoText={translateObj(nickNameInfo, language)}
        />
        <FormInput
          name="userPostalCode"
          title={translateObj(postalCode, language)}
          validations={{ maxLength: 10 }}
          validationError={
        translateObj(invalidPostalCode, language)
      }
          titleClassName="flex uppercase"
          className="w-1/2 sm:w-full md:w-full"
          doNotChangeValidColor
          value={user.postalCode}
          infoText={translateObj(postalCodeInfo, language)}
        />
        <DatePickerFormsy
          title={birthDate}
          name="birthDate"
          handleDate={handleDate}
          existingDate={user.dateOfBirth}
          language={language}
          bolder
          required
          infoText={translateObj(birthDateInfo, language)}
        />

        <FormSelectAutoComplete
          titleClassName="uppercase flex"
          name="inputCountry"
          title={translateObj(country, language)}
          value={getCountryFromCode(user.inputCountry, language) || 'Spain'}
          options={getCountryNamesByCode(language)}
          message={translateObj(countryError, language)}
          required
          infoText={translateObj(countryInfo, language)}
        />
        <div className="px-3 mb-4 sm:w-full w-1/2 sm:w-full md:w-full">
          <label className="flex flex-col" htmlFor="spotifyLink">
            <span className="flex">
              <span className="uppercase">
                <span>{translateObj(user.isSpotifyLinked ? spotifyLinkedText : spotifyLinkText, language)}</span>
              </span>
              <InfoPopover classNames="ml-4" text={translateObj(user.isSpotifyLinked ? spotifyLinkedInfo : spotifyLinkInfo, language)} />
            </span>
            {/* TODO: LINK / UNLINK SPOTIFY */}
            {/* <div>
              <button
                type="button"
                className="rounded-full bg-inherit bg-spotify uppercase text-white text-16 xs:text-14 sm:text-14 font-americaMonoBold sm:static sm:mx-0 sm:w-full cursor-pointer text-center md:w-full mt-4 px-12 lg:px-16 xl:px-16 py-4 flex items-center justify-center"
                onClick={() => actions.spotifyLink(user).then(console.log)}
              >
                <div className="font-icon icon-spotify text-white  text-18 -mt-px mx-0" />
                <span className="mx-2">{translateObj(isLinkedWithSpotify ? spotifyUnLinkText : spotifyLinkText, language)}</span>
              </button>
            </div> */}
            <SpotifyLogin
              isLinkAccountButton
              buttonText={translateObj(user.isSpotifyLinked ? spotifyUnLinkText : spotifyLinkText, language)}
              revamp
            />
          </label>
        </div>
        {/* TODO: NEWSLETTER */}
        {displayNewsLetterFormButton({
          isUserInNewsLetter: user.newsletter, userEmail: user.email, isUserVerified: user.verified, language: user.language, newsLetterTermsUrl, newsLetterTermsText, newsletterInfo, newsLetterButtonText, newsLetterConfigureText, newsletterSettingsInfo, newsLetterSubscribeText, isAddingToNewsLetter, isUserInSplio: user.isInSplio, dispatch, loadTokenForLS,
        })}

        {/* TODO: EDIT USER ?? */}
        <div className="px-3 my-4 w-full flex justify-end">
          <button
            type="submit"
            disabled={!canSubmit}
            className={`rounded-full bg-inherit ${canSubmit ? 'bg-black' : 'bg-grey'} text-white font-americaMonoBold sm:static sm:mt-6 sm:mx-0 sm:w-full cursor-pointer  text-center xl:w-s15 lg:w-s15 sm:w-full xl:max-w-xs md:w-s23 lg:max-w-xs md:mt-4 `}
            style={{
              alignSelf: 'flex-end',
              minHeight: '3rem',
            }}
          >
            {translateObj(updateButtonText, language)}
          </button>

        </div>
        <div className="px-2 my-4 pt-6 flex">
          <DeleteIcon />
          <button
            className="text-red pl-2"
            onClick={deleteAccount}
            type="button"
          >
            {translateObj(deleteButtonText, language)}
          </button>
        </div>
        {errorMsg && <div className="text-red px-3 uppercase">{translateObj(validation[errorMsg], language)}</div>}
      </Formsy>
    </>
  );
};

ProfileFormsy.propTypes = {
  history: PropTypes.shape().isRequired,
  title: PropTypes.shape().isRequired,
  fields: PropTypes.shape().isRequired,
  validation: PropTypes.shape().isRequired,
  infoFields: PropTypes.shape().isRequired,
  setDidSubmit: PropTypes.func.isRequired,
  language: PropTypes.string,
  sourceWeb: PropTypes.string,
  // menu: PropTypes.shape(),
  // conditions: PropTypes.shape().isRequired,
  // submitButtonText: PropTypes.shape().isRequired,
  // resetButtonText: PropTypes.shape().isRequired,
  // submitMessageButton: PropTypes.shape().isRequired,
};

ProfileFormsy.defaultProps = {
  // menu: null,
  language: 'en',
  sourceWeb: '',
};

export default ProfileFormsy;
