import React, { useState, useEffect, useRef } from 'react';
import Swiper from 'react-id-swiper';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import ComponentIterator from '../../containers/ComponentIterator';
import PSResponsiveUtility from './LineUp/PSResponsiveUtility';

const paramsMobile = {
  slidesPerView: 1,
  spaceBetween: 30,
  loop: true,
  pagination: {
    el: '.swiper-pagination-mobile',
    clickable: true,
  },
  runCallbacksOnInit: true,
  rebuildOnUpdate: true,
};
const params = {
  slidesPerView: 1,
  spaceBetween: 30,
  loop: true,
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  rebuildOnUpdate: true,
};
const Carousel = ({
  components, match, areSlidesShort, isFooterSlider, showOpacity,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [device, setDevice] = useState(null);
  const responsiveUtility = useRef();
  // TODO: disable arrows and pagination dots on keyboard navigation, or disable tab focus on non-visible slides (tabIndex="-1")
  // const paginationFocusables = document.querySelectorAll('div.swiper-button-next, div.swiper-button-prev, span.swiper-pagination-bullet');

  useEffect(() => {
    setIsLoading(false);
  }, []);

  const getDeviceScreen = () => {
    const deviceScreen = responsiveUtility.current.deviceScreen(true);
    setDevice(deviceScreen);
  };

  const slidesRenderer = () => {
    if (areSlidesShort) {
      components.forEach((c) => {
        if (c.type === 'SliderBannerHome' || c.type === 'SliderBannerTickets') {
          c.areSlidesShort = true;
          c.isFooterSlider = isFooterSlider;
        }
      });
    }
    if (isLoading) {
      return (
        <div className={`${areSlidesShort ? 'h-screen40 md:h-carouselMD sm:h-carouselSM' : 'h-screen80 md:h-carouselMD sm:hidden'} ${showOpacity ? 'sliderComponent' : ''} w-full full-width`} />
      );
    }
    if (components && components.length > 1) {
      if (device === 'sm') {
        return (
          <div className="h-620 sm:h-carouselSM w-full full-width">
            <Swiper {...paramsMobile} className="relative">
              {components.map(component => (
                <div key={component.compId}>
                  <ComponentIterator components={[component]} />
                </div>
              ))}
            </Swiper>
          </div>
        );
      }
      return (
        <div className={`${!areSlidesShort ? 'h-screen80' : 'h-screen40'}  md:h-carouselMD w-full ${isFooterSlider ? '-mb-16 sm:px-0' : 'full-width'}`}>
          <Swiper
            {...params}
            className="relative"
            on={{
              // TODO: NOT WORKING!
              // beforeInit: () => {
              //   paginationFocusables.forEach((item) => { item.setAttribute('tabIndex', '-1'); return item; });
              // },
            }}
          >
            {components.map(component => (
              <div key={component.compId}>
                <ComponentIterator components={[component]} />
              </div>
            ))}
          </Swiper>
        </div>
      );
    }

    return (
      <div className={`${areSlidesShort ? 'h-screen40' : 'h-screen80'} md:h-carouselMD sm:h-carouselSM w-full ${isFooterSlider ? '-mb-16 sm:px-0' : 'full-width'}`}>
        <ComponentIterator components={[components[0]]} />
      </div>
    );
  };

  return (
    <>
      <PSResponsiveUtility ref={responsiveUtility} deviceScreenCallback={getDeviceScreen} />
      {slidesRenderer()}
    </>
  );
};

Carousel.propTypes = {
  components: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
  classNames: PropTypes.string,
  areSlidesShort: PropTypes.bool,
  isFooterSlider: PropTypes.bool,
  showOpacity: PropTypes.bool.isRequired,
};

Carousel.defaultProps = {
  classNames: '',
  areSlidesShort: false,
  isFooterSlider: false,
};

export default withRouter(Carousel);
