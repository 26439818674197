import React from 'react';

const PaginationComponent = ({
  itemsPerPage, totalItems, handleClick, category, page,
}) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }


  return (
    <div className="w-full mt-4">
      <ul className="text-xs leading-normal font-americaMonoRegular flex justify-center items center flex-wrap list-reset rounded w-auto">
        <li>
          <a
            className={`${page === 1 ? 'text-grey' : `text-black hover:text-${category}`} block px-3 py-2 no-underline`}
            onClick={(e) => { e.preventDefault(); handleClick(page === 1 ? page : page - 1); }}
            href="!#"
          >
            {'<'}
          </a>
        </li>
        <li>
          <span
            className="text-black block px-3 py-2"
          >
            {`${page} / ${pageNumbers.pop()}`}
          </span>
        </li>
        <li>
          {pageNumbers.pop() === page - 1
            ? (
              <span
                className="text-grey block px-3 py-2 no-underline"
              >
                {'>'}
              </span>
            )
            : (
              <a
                className={`text-black block hover:text-${category} px-3 py-2 no-underline`}
                onClick={(e) => { e.preventDefault(); handleClick(page + 1); }}
                href="!#"
              >
                {'>'}

              </a>
            )
        }
        </li>
      </ul>
    </div>
  );
};

export default PaginationComponent;
