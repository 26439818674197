import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import ComponentIterator from '../../containers/ComponentIterator';
import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';
import translateObj from '../../util/translateObj';

const Instructions = ({
  components, imageUrl, background, name, title, match,
}) => (
  <RoutesContext.Consumer>
    {({ language: contextLanguage, enteredFrom }) => {
      const imgSize = background === 'white' ? '6rem' : '8rem';
      const localization = getLocalization(contextLanguage, enteredFrom, match);
      return (
        <div className={`md:flex lg:flex xl:flex mb-8 font-tiemposRegular flex-none sm:mt-0 ${background === 'white' && 'bg-white py-8 px-12 rounded-lg'} ${!imageUrl && 'md:flex-none lg:flex-none'}`}>
          <div className="md:w-1/5 lg:w-1/5 xl:w-1/5 self-start sm:mt-6">
            {imageUrl && <img src={imageUrl} alt={name} style={{ maxWidth: imgSize }} />}
            {name === 'instructionText' && (
              <p className="font-americaMonoBold text-14 uppercase mt-0" style={{ marginBottom: '4rem' }}>
                {translateObj(title, localization.language)}
              </p>
            )}
          </div>
          <div className={`${background === 'transparent' ? ' md:w-4/5 lg:w-4/5 xl:w-4/5' : 'sm:w-full sm:pl-0 sm:mt-6 w-4/5 pl-12 self-center'} ${!imageUrl && !title && 'mt-6 xl:ml-48 md:w-full lg:w-full'}`}>
            <ComponentIterator components={components} />
          </div>
        </div>
      );
    }}
  </RoutesContext.Consumer>
);

Instructions.propTypes = {
  match: PropTypes.shape().isRequired,
  components: PropTypes.arrayOf(PropTypes.shape({})),
  imageUrl: PropTypes.string,
  background: PropTypes.oneOf(['white', 'transparent']),
  name: PropTypes.string,
  title: PropTypes.shape({
    es: PropTypes.string,
    en: PropTypes.string,
    ca: PropTypes.string,
  }),
};

Instructions.defaultProps = {
  background: 'transparent',
  name: '',
  imageUrl: '',
  title: {},
  components: [],
};

export default withRouter(Instructions);
