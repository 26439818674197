import React from 'react';
import { PropTypes } from 'prop-types';

import { withRouter } from 'react-router-dom';
import moment from 'moment';
import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';
import ComponentIterator from '../../containers/ComponentIterator';
import SimpleVideoPlayer from './SimpleVideoPlayer';
import LinkButtonRounded from './Buttons/LinkButtonRounded';
import Carousel75 from './Carousel75';

class RadioVideoOpen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
  }

  render() {
    let { startDate, endDate } = this.props;
    const {
      prevUrl, nextUrl, title_multilang, text1, text2, text3, tags, icon1, icon2, icon3, components, video, linkButtonRounded, match,
    } = this.props;
    return (
      <RoutesContext.Consumer>
        {
        ({ language: contextLanguage, enteredFrom }) => {
          const localization = getLocalization(contextLanguage, enteredFrom, match);

          startDate = moment(startDate);
          endDate = moment(endDate);
          const isLive = moment().isBetween(startDate, endDate);
          const duration = moment.duration(endDate.diff(startDate)).asMinutes();

          const TagsAndDuration = () => (
            <div className="flex justify-between">
              <div>
                <ComponentIterator components={tags} />
                {isLive ? <span className="ml-4 text-white text-sm uppercase font-americaMonoBold sm:hidden">{text1[localization.language]}</span> : null}
              </div>
              <div className="flex items-center text-sm">
                <span className={`w-5 mr-3 ${icon1} `} />
                <span className="font-americaMonoRegular uppercase text-white">{`${duration} ${text2[localization.language]}`}</span>
              </div>
            </div>
          );

          return (
            <div className="w-full text-white font-americaMonoRegular bg-radio">
              <div className="relative p-8 sm:p-4">
                <div className="w-2/3 sm:w-full m-auto">
                  <div className="sm:hidden"><TagsAndDuration /></div>
                </div>
                <div className="relative">
                  <a href={prevUrl[localization.language]}>
                    <span className={`${icon2} absolute pin-ht pin-l sm:-ml-2 h-8 -mt-4 z-20`} />
                  </a>
                  <a href={nextUrl[localization.language]}>
                    <span className={`${icon3} absolute pin-ht pin-r sm:-mr-2 h-8 -mt-4 z-20`} />
                  </a>
                  <div className="w-2/3 sm:w-full m-auto">
                    <div className="my-8 sm:mt-0 sm:mb-4">
                      <SimpleVideoPlayer {...video} />
                    </div>
                  </div>
                </div>
                <div className="w-2/3 sm:w-full m-auto">
                  <div className="flex items-center justify-between sm:block">
                    <span className="text-sm">{!title_multilang[localization.language] ? title_multilang.en : title_multilang[localization.language]}</span>
                    <div className="hidden sm:block sm:my-4"><TagsAndDuration /></div>
                    <div className="sm:hidden"><LinkButtonRounded {...linkButtonRounded} /></div>
                  </div>
                  <div className="hidden sm:flex justify-center"><LinkButtonRounded {...linkButtonRounded} /></div>
                </div>
              </div>
              <div className="py-4 bg-black">
                <h3 className="font-americaMonoBold uppercase pl-4">{text3[localization.language]}</h3>
                <div className="hidden sm:block my-4">
                  <Carousel75 components={components} />
                </div>
              </div>
              <div className="pb-4 bg-black">
                <div className="flex flex-wrap sm:overflow-x-scroll sm:hidden px-2">
                  {components.map((component, i) => (
                    // TODO: use id if available in components
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={i} className="w-1/4 md:w-1/3 mb-4">
                      <div className="px-2">
                        <ComponentIterator components={[component]} />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          );
        }
      }
      </RoutesContext.Consumer>
    );
  }
}

RadioVideoOpen.propTypes = {
  match: PropTypes.shape().isRequired,
  title_multilang: PropTypes.shape().isRequired,
  text1: PropTypes.shape().isRequired,
  text2: PropTypes.shape().isRequired,
  text3: PropTypes.shape().isRequired,
  icon1: PropTypes.string.isRequired,
  icon2: PropTypes.string.isRequired,
  icon3: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  video: PropTypes.shape().isRequired,
  linkButtonRounded: PropTypes.shape().isRequired,
  prevUrl: PropTypes.shape().isRequired,
  nextUrl: PropTypes.shape().isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  tags: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  components: PropTypes.array.isRequired,
};

export default withRouter(RadioVideoOpen);
