import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';
import imageModifier from './helpers/imageModifier';
import ParagraphLineSM from './Text/ParagraphLineSM';
import translateObj from '../../util/translateObj';

const GalleryItem = ({
  title, slug, url, match, onClick, tags, type,
}) => (

  <RoutesContext.Consumer>
    {
      ({ language: contextLanguage, enteredFrom }) => {
        const localization = getLocalization(contextLanguage, enteredFrom, match);
        return (
          <div className="sm:w-full md:w-1/2 lg:w-1/3 xl:w-1/4 py-2 px-2 sm:px-0">
            <div className="cursor-pointer" onClick={() => onClick(slug, type)}>
              <div className="relative">
                <img className="block" src={imageModifier(url, '354x210')} alt={title.en} />
              </div>
              <p className="font-americaBlack text-20 flex items-center my-0" style={{ minHeight: '60px' }}>{translateObj(title, localization.language)}</p>
            </div>
          </div>
        );
      }
    }
  </RoutesContext.Consumer>
);

GalleryItem.propTypes = {
  title: PropTypes.shape({}),
  slug: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  match: PropTypes.shape({}).isRequired,
  url: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
  type: PropTypes.string,
};

GalleryItem.defaultProps = {
  title: {},
  tags: [],
  type: 'GalleryItem',
};

export default withRouter(GalleryItem);
