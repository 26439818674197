// eslint-disable-next-line import/prefer-default-export
export const translations = {
  mainScreen: {
    onDemandMainText: {
      es: '',
      en: '',
      ca: '',
      pt: '',
    },
    watchNowButtonText: {
      en: 'go to event',
      es: 'ir al evento',
      ca: "anar a l'esdeveniment",
      pt: 'go to event',
    },
    shareButtonText2: {
      es: 'compartir',
      en: 'share',
      ca: 'compartir',
      pt: 'compartilhar',
    },
    priceText: {
      es: 'accede por',
      en: 'access paying',
      ca: 'accedeix per',
      pt: 'access paying',
    },
  },
  registerModal: {
    name: {
      es: 'Nombre',
      en: 'First name',
      ca: 'Nom',
      pt: 'nome',
    },
    lastname: {
      es: 'Apellidos',
      en: 'Last name',
      ca: 'Cognoms',
      pt: 'apelido',
    },
    selectDateMessage: {
      es: 'Selecciona la fecha',
      en: 'Select date',
      ca: 'Selecciona la data',
      pt: 'Seleccione data',
    },
    email: {
      es: 'Correo electrónico',
      en: 'Email',
      ca: 'Correu electrònic',
      pt: 'e-mail',
    },
    phone: {
      es: 'Teléfono',
      en: 'Phone',
      ca: 'Telefon',
      pt: 'telefone',
    },
    choose: {
      es: 'Eligir',
      en: 'Choose',
      ca: 'Escullir',
      pt: 'escolher',
    },
    country: {
      es: 'PAÍS',
      en: 'COUNTRY',
      ca: 'PAIS',
      pt: 'PAÍS',
    },
    countryError: {
      es: 'EL PAÍS NO EXISTE',
      en: 'COUNTRY DOES NOT EXIST',
      ca: 'EL PAIS NO EXISTEIX',
      pt: 'COUNTRY DOES NOT EXIST',
    },
    city: {
      es: 'ciudad',
      en: 'CITY',
      ca: 'ciutat',
      pt: 'cidade',
    },
    postalcode: {
      es: 'CÓDIGO POSTAL',
      en: 'POSTAL CODE',
      ca: 'CODI POSTAL',
      pt: 'código postal',
    },
    password: {
      es: 'CREAR CONTRASEÑA',
      en: 'CREATE PASSWORD',
      ca: 'CREA CONTRASENYA',
      pt: 'criar senha',
    },
    repeatPasswordError: {
      es: 'Por favor, vuelve a intentarlo las contraseñas no coinciden',
      en: 'Please double check, your passwords do not match',
      ca: 'Torna a intentar-ho, les contrasenyes no coincideixen',
      pt: 'volte a tentar, as palavras passe não coincidem ',
    },
    inValidName: {
      es: 'El nombre no es válido',
      en: 'This is not a valid name',
      ca: 'El nom no és vàlid',
      pt: 'O nome não é válido',
    },
    longName: {
      es: 'El nombre es demasiado largo',
      en: 'This name is too long',
      ca: 'El nom és massa llarg',
      pt: 'O nome é demasiado longo',
    },
    inValidEmail: {
      es: 'El correo electrónico no és válido',
      en: 'This is not a valid email',
      ca: 'El correu electrònic no és vàlid',
      pt: 'O e-mail não é válido',
    },
    longEmail: {
      es: 'El correo electrónico és demasiado largo',
      en: 'This email is too long',
      ca: 'El correu electrònic és massa llarg',
      pt: 'o e-mail é demasiado longo',
    },
    inValidPhone: {
      es: 'This is not a valid phone number',
      en: 'This is not a valid phone number',
      ca: 'This is not a valid phone number',
      pt: 'o número não é valido',
    },
    longPhone: {
      es: 'This phone number is too long',
      en: 'This phone number is too long',
      ca: 'This phone number is too long',
      pt: 'o número é demasiado longo',
    },
    invalidpostalcode: {
      es: 'El código postal es incorrecto',
      en: 'This is not a valid postal code',
      ca: 'El codi postal es incorrecte',
      pt: 'o código postal não é válido',
    },
    shortPassword: {
      es: 'Las contraseña es demasiado corta',
      en: 'This password is too short',
      ca: 'La contrasenya es massa curta',
      pt: 'a palavra passe é demasiado curta',
    },
    repeatPassword: {
      es: 'Vuelve a introducir tu password:',
      en: 'Re-enter your password:',
      ca: 'Torna a introduir el teu password:',
      pt: 'voltar a introduzir a palavra passe',
    },
    text: {
      es: 'Te informamos que los datos personales que facilites pasarán a formar parte de un fichero responsabilidad de PRIMAVERA SOUND S.L. para gestionar tu petición. Puedes ejercer los derechos de acceso, rectificación, cancelación u oposición al tratamiento de tus datos en el e-mail señalado lopd@primaverasound.com o en el domicilio sito en C/ Roc Boronat 142, 3ª planta, 08018 de Barcelona.',
      en: 'We inform you que los datos personales que facilites pasarán a formar parte de un fichero responsabilidad de PRIMAVERA SOUND S.L. para gestionar tu petición. Puedes ejercer los derechos de acceso, rectificación, cancelación u oposición al tratamiento de tus datos en el e-mail señalado lopd@primaverasound.com o en el domicilio sito en C/ Roc Boronat 142, 3ª planta, 08018 de Barcelona.',
      ca: 'Te informamos que los datos personales que facilites pasarán a formar parte de un fichero responsabilidad de PRIMAVERA SOUND S.L. para gestionar tu petición. Puedes ejercer los derechos de acceso, rectificación, cancelación u oposición al tratamiento de tus datos en el e-mail señalado lopd@primaverasound.com o en el domicilio sito en C/ Roc Boronat 142, 3ª planta, 08018 de Barcelona.',
      pt: 'informamos que os dados pessoais introduzidos passam a fazer parte de um ficheiro, da responsabilidade da PRIMAVERA SOUND S.L., para que possa ser feita a gestão da inscrição. É possível exercer os direitos de acesso, rectificação, cancelamento ou oposição ao tratamentos dos dados através do email lopd@primaverasound.com ou na sede, situada em C/ Roc Boronat 142, 3ª planta, 08018 Barcelona.',
    },
    conditions: {
      acceptText: {
        es: 'Acepto las ',
        en: 'I accept the ',
        ca: 'Accepto les ',
        pt: 'I accept the ',
      },
      conditionsText: {
        es: 'CONDICIONES DE ACCESO',
        en: 'ACCESS CONDITIONS',
        ca: 'CONDICIONS D’ACCÉS',
        pt: 'ACCESS CONDITIONS',
      },
      conditionsLink: {
        es: 'https://www.primaverasound.com/es/user-register-conditions-for-primavera-sound-barcelona',
        en: 'https://www.primaverasound.com/en/user-register-conditions-for-primavera-sound-barcelona',
        ca: 'https://www.primaverasound.com/ca/user-register-conditions-for-primavera-sound-barcelona',
        pt: 'https://www.primaverasound.com/pt/user-register-conditions-for-primavera-sound-barcelona',
      },
      dataTreatmentText: {
        es: ' y el tratamiento de datos y la elaboración de perfiles según la ',
        en: ' and data processing and profiling according to the ',
        ca: " i el tractament de dades i l'elaboracio de perfils segons la ",
        pt: ' and data processing and profiling according to the ',
      },
      privacyPolicyText: {
        es: 'POLÍTICA DE PRIVACIDAD',
        en: 'PRIVACY POLICY',
        ca: 'POLÍTICA DE PRIVACITAT',
        pt: 'PRIVACY POLICY',
      },
      privacyPolicyUrl: {
        es: 'https://www.primaverasound.com/es/privacy-policy',
        en: 'https://www.primaverasound.com/en/privacy-policy',
        ca: 'https://www.primaverasound.com/ca/privacy-policy',
        pt: 'https://www.primaverasound.com/pt/privacy-policy',
      },
    },
    newsletter: {
      es: 'Deseo suscribirme a la newsletter de conformidad con la',
      en: 'I want to subscribe to the newsletter based on your',
      ca: 'Desitjo suscriure’m a la newsletter de conformitat amb la',
      pt: 'Subscrever a nossa newsletter aceitando nossa',
    },
    newsletterLinkText: {
      es: 'Política de Privacidad',
      en: 'Privacy policy',
      ca: 'Política de Privacitat',
      pt: 'Política de Privacidade',
    },
    newsletterLinkUrl: {
      es: 'https://www.primaverasound.com/es/privacy-policy',
      en: 'https://www.primaverasound.com/en/privacy-policy',
      ca: 'https://www.primaverasound.com/ca/privacy-policy',
      pt: 'https://www.primaverasound.com/pt/privacy-policy',
    },
    submitButtonText: {
      es: 'ENVIAR',
      en: 'SUBMIT',
      ca: 'ENVIAR',
      pt: 'ENVIAR',
    },
    resetButtonText: {
      es: 'BORRAR',
      en: 'RESET',
      ca: 'BORRAR',
      pt: 'APAGAR',
    },
    submitMessage: {
      es: 'GRACIAS, SE HA CREADO TU CUENTA',
      en: 'THANKS, YOUR ACCOUNT HAS BEEN CREATED',
      ca: "GRÀCIES, S'HA CREAT EL TEU COMPTE",
      pt: 'OBRIGADO, A CONTA FOI ACTUALIZADA',
    },
    birthDateText: {
      es: 'fecha de nacimiento',
      en: 'date of birth',
      ca: 'data de naixement',
      pt: 'data de nascimento',
    },
    passwordInfo: {
      es: 'la contraseña debe contener al menos 8 caracteres',
      en: 'your password has to be at least 8 characters long',
      ca: 'La contrasenya ha de contenir un mínim de 8 caràcters',
      pt: 'your password has to be at least 8 characters long',
    },
  },
  loggedModal: {
    subscribeTitle: {
      es: 'Suscríbete por 2€ al Mes',
      en: 'Subscribe for 2€/Month',
      ca: 'Subscriu-te per 2€ al Mes',
      pt: 'Subscribe for 2€/Month',
    },
    subscribeDescription: {
      es: 'Esta suscripción da acceso a todo el catálogo de conciertos y directos en streaming.',
      en: 'This subscription provides access to all our concerts and live streaming feeds',
      ca: 'Aquesta subscripció dona accés a tot el catàleg de concerts i directes en streaming',
      pt: 'This subscription provides access to all our concerts and live streaming feeds',
    },
    subscribeConditions: {
      es: 'Podrás cancelar la renovación de tu suscripción en cualquier momento.',
      en: 'You will be able to cancel the renewal of your subscription at any time',
      ca: 'Podràs cancel·lar la renovació de la teva subscripció en qualsevol moment',
      pt: 'You will be able to cancel your subscription at any time',
    },
    subscribeConfigurePayment: {
      es: 'CONFIGURA TU MÉTODO DE PAGO',
      en: 'CONFIGURE YOUR PAYMENT METHOD',
      ca: 'CONFIGURE EL TEU MÈTODE DE PAGAMENT',
      pt: 'CONFIGURE YOUR PAYMENT METHOD',
    },
    subscribeAddCard: {
      es: 'AÑADIR TU TARJETA',
      en: 'ADD CREDIT CARD',
      ca: 'AFEGEIX LA TEVA TARJETA',
      pt: 'ADD CREDIT CARD',
    },
    payTitle: {
      es: 'Suscríbete por 2€ al Mes',
      en: 'Subscribe for 2€/Month',
      ca: 'Subscriu-te per 2€ al Mes',
      pt: 'Subscribe for 2€/Month',
    },
    payDescription: {
      es: 'Esta suscripción da acceso a todo el catálogo de conciertos y directos en streaming.',
      en: 'This subscription provides access to all our concerts and live streaming feeds',
      ca: 'Aquesta subscripció dona accés a tot el catàleg de concerts i directes en streaming',
      pt: 'This subscription provides access to all our concerts and live streaming feeds',
    },
    payConditions: {
      es: 'Podrás cancelar la renovación de tu suscripción en cualquier momento.',
      en: 'You will be able to cancel the renewal of your subscription at any time',
      ca: 'Podràs cancel·lar la renovació de la teva subscripció en qualsevol moment',
      pt: 'You will be able to cancel your subscription at any time',
    },
    payConfirmationText1: {
      es: 'conftext1',
      en: 'conftext1',
      ca: 'conftext1',
      pt: 'conftext1',
    },
    payConfirmationText2: {
      es: 'conftext2',
      en: 'conftext2',
      ca: 'conftext2',
      pt: 'conftext2',
    },
    trialTitle: {
      es: 'Free X months trial',
      en: 'Free X months trial',
      ca: 'Free X months trial',
      pt: 'Free X months trial',
    },
    trialDescription: {
      es: 'Esta suscripción da acceso a todo el catálogo de conciertos y directos en streaming.',
      en: 'This subscription provides access to all our concerts and live streaming feeds',
      ca: 'Aquesta subscripció dona accés a tot el catàleg de concerts i directes en streaming',
      pt: 'This subscription provides access to all our concerts and live streaming feeds',
    },
    trialConditions: {
      es: 'Una vez acabada la prueba te podrás de alta para seguir disfrutando de nuestro contenido',
      en: 'Once the trial finishes you will be able to subscribe in order to continue enjoying our content',
      ca: "Un cop finalitzada la prova et podràs donar d'alta per seguir gauding del nostre contingut",
      pt: 'Once the trial finishes you will be able to subscribe in order to continue enjoying our content',
    },
    trialConfirmationText1: {
      es: 'conftext1',
      en: 'conftext1',
      ca: 'conftext1',
      pt: 'conftext1',
    },
    trialConfirmationText2: {
      es: 'conftext2',
      en: 'conftext2',
      ca: 'conftext2',
      pt: 'conftext2',
    },
  },
  notLoggedModal: {
    passwordButtonTitle: {
      es: 'ACCEDE O REGISTRATE CON TU EMAIL',
      en: 'ACCESS OR REGISTER WITH YOUR EMAIL',
      ca: "ACCEDEIX O REGISTRA'T AMB EL TEU EMAIL",
      pt: 'ACCESS OR REGISTER ',
    },
    userTitle: {
      es: 'CORREO ELECTRONICO',
      en: 'E-MAIL',
      ca: 'CORREU ELECTRÒNIC',
      pt: 'CORREIO ELETRÔNICO',
    },
    passwordTitle: {
      es: 'CONTRASEÑA',
      en: 'PASSWORD',
      ca: 'CONTRASENYA',
      pt: 'PASSWORD',
    },
    submitTitle: {
      es: 'ACCEDE O REGISTRATE',
      en: 'SIGN IN OR REGISTER',
      ca: "ACCEDEIX O REGISTRA'T",
      pt: 'SIGN IN OR REGISTER',
    },
    forgotPassword: {
      es: 'Has olvidado tu contraseña? ',
      en: 'Did you forgot your password? ',
      ca: 'Has oblidat la teva contrasenya? ',
      pt: 'Did you forgot your password? ',
    },
    recoverPassword: {
      es: 'Recuperarla aquí',
      en: 'Recover it here',
      ca: 'Recupera-la aquí',
      pt: 'Recover it here',
    },
    passwordInfo: {
      es: 'la contraseña debe tener al menos 8 caracteres',
      en: 'your password has to be at least 8 characters long',
      ca: 'La contrasenya ha de contenir un mínim de 8 caràcters',
      pt: 'your password has to be at least 8 characters long',
    },
    createdWithSocial: {
      en: 'Please go back, user was created through a social network',
      es: 'Por favor retrocede, el usuario fue creado con una red social',
      ca: "Retrocedeix si us plau, l'usuari va ser creat amb una red social",
      pt: 'Please go back, user was created through a social network',
    },
    notFoundOrWrongPassword: {
      en: 'Make sure that your user exists and has not been created using a social network. You can also recover your password',
      es: 'Asegúrate que tu usuario existe y que no fue creado con una red social. También puedes recuperar la contraseña',
      ca: "Assegura't que el teu usuari existeix i que no va ser creat amb una xarxa social. També pots recuperar la contrasenya",
      pt: 'Make sure that your user exists and has not been created using a social network. You can also recover your password',
    },
    userIncorrect: {
      es: 'Usuario o contraseña incorrectos',
      en: 'Incorrect user or password',
      ca: 'Usuari o contrasenya incorrectes',
      pt: 'Incorrect user or password',
    },
    createAccount: {
      es: 'CREAR CUENTA',
      en: 'CREATE ACCOUNT',
      ca: 'CREAR COMPTE',
      pt: 'CREATE ACCOUNT',
    },
    accessAccount: {
      es: 'ACCEDE',
      en: 'ACCESS',
      ca: 'ACCEDEIX',
      pt: 'ACCESS',
    },
    accessPrimavera: {
      es: 'Entra o Registrate en Primavera Sound',
      en: 'Access or Register Primavera Sound',
      ca: "Accedeix o Registra't al Primavera Sound",
      pt: 'Access or RegisterPrimavera Sound',
    },
    notAUserMain: {
      es: '¿No eres usuario? ',
      en: 'Do not have a user yet? ',
      ca: 'No tens usuari? ',
      pt: 'Do not have a user yet? ',
    },
    notAUserLink: {
      es: 'Crear cuenta',
      en: 'Create account',
      ca: 'Crea compte',
      pt: 'Create account',
    },
    goBackText: {
      es: 'Retroceder',
      en: 'Go back',
      ca: 'Enrrera',
      pt: 'Go back',
    },
    registerPrimavera: {
      es: 'Regístrate',
      en: 'Register',
      ca: 'Registrat',
      pt: 'Register',
    },
    emailAlreadyInUse: {
      es: 'Ya existe una cuenta con este mismo correo',
      en: 'There is already an account with this same email',
      ca: 'Ja existeix un compte amb aquest correu',
      pt: 'There is already an account with this same email',
    },
    conditions: {
      acceptText: {
        es: 'Acepto las ',
        en: 'I accept the ',
        ca: 'Accepto les ',
        pt: 'I accept the ',
      },
      conditionsText: {
        es: 'CONDICIONES DE ACCESO',
        en: 'ACCESS CONDITIONS',
        ca: 'CONDICIONS D’ACCÉS',
        pt: 'ACCESS CONDITIONS',
      },
      conditionsLink: {
        es: 'https://www.primaverasound.com/es/user-register-conditions-for-primavera-sound-barcelona',
        en: 'https://www.primaverasound.com/en/user-register-conditions-for-primavera-sound-barcelona',
        ca: 'https://www.primaverasound.com/ca/user-register-conditions-for-primavera-sound-barcelona',
        pt: 'https://www.primaverasound.com/pt/user-register-conditions-for-primavera-sound-barcelona',
      },
      dataTreatmentText: {
        es: ' y el tratamiento de datos y la elaboración de perfiles según la ',
        en: ' and data processing and profiling according to the ',
        ca: " i el tractament de dades i l'elaboracio de perfils segons la ",
        pt: ' and data processing and profiling according to the ',
      },
      privacyPolicyText: {
        es: 'POLÍTICA DE PRIVACIDAD',
        en: 'PRIVACY POLICY',
        ca: 'POLÍTICA DE PRIVACITAT',
        pt: 'PRIVACY POLICY',
      },
      privacyPolicyUrl: {
        es: 'https://www.primaverasound.com/es/privacy-policy',
        en: 'https://www.primaverasound.com/en/privacy-policy',
        ca: 'https://www.primaverasound.com/ca/privacy-policy',
        pt: 'https://www.primaverasound.com/pt/privacy-policy',
      },
    },
  },
};
