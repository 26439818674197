import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Panel from '@primaveralabs/ui-panel';
import CircleItem from '@primaveralabs/ui-circle-item';
import 'Primavera/ui-panel/dist/primavera.css';
import 'Primavera/ui-circle-item/dist/primavera.css';
import 'Primavera/ui-themes/dist/primavera/index.css';
import getPostsBySlugNameQuery from '../../../api/graphql/queries/getPostsBySlugNameQuery';
import LoadIcon from '../LoadIcon';
import translateObj from '../../../util/translateObj';
import { useRoutes } from '../../../context/routesContext';
import imageModifier from '../helpers/imageModifier';
import translation from './translations/common';
import userTranslation from './translations/UserPanel';
import useResponsiveUtility from '../../../util/responsiveUtility';
import { LinkWrapper } from '../../../util/link';

const FavoritesBoard = ({
  favouriteArtists,
  title,
  favouritesURL,
  noFavourites,
  viewAll,
}) => {
  const { language, path } = useRoutes();
  const [loading, setLoading] = useState(false);
  const [favourites, setFavourites] = useState([]);
  const [columns, setColumns] = useState(3);
  const { deviceScreen } = useResponsiveUtility();

  const loadData = () => {
    setLoading(true);
    getPostsBySlugNameQuery(favouriteArtists)
      .then((res) => {
        if (res) {
          setFavourites(res);
        }
      })
      .catch((err) => console.log(err));
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    switch (deviceScreen) {
      case 'sm':
        setColumns(3);
        break;
      case 'md':
      case 'xxl':
        setColumns(5);
        break;
      default:
        setColumns(4);
    }
  }, [deviceScreen]);

  const gridStyle = useMemo(
    () => ({
      display: 'grid',
      gap: '1rem',
      gridTemplateRows: 'repeat(2, 1fr)',
      gridTemplateColumns: `repeat(${columns}, 1fr)`,
    }),
    [columns],
  );

  return (
    <div style={{ gridArea: 'favorites' }}>
      <Panel
        display={loading ? 'center' : 'auto'}
        title={translateObj(title, language)}
        ctaLabel={translateObj(viewAll, language)}
        ctaLink={`/${language}/user-panel/favourites`}
        noChildrenMessage={translateObj(noFavourites, language)}
        scroll
      >
        {loading && <LoadIcon />}
        <div style={gridStyle}>
          {!loading &&
            favourites?.length > 0 &&
            favourites
              .slice(0, columns * 2 - 1)
              .map((item) => (
                <CircleItem
                  key={`${item.slugName}-${item.createdAt}`}
                  image={imageModifier(
                    translateObj(item.postDescription?.image, language),
                    '200x200',
                  )}
                  label={translateObj(item.postDescription?.title, language)}
                  link={item.postDescription?.url}
                />
              ))}
          <LinkWrapper url={`/${favouritesURL}`} path={path}>
            <div
              className="cursor-pointer rounded-full bg-grey-darker flex items-center justify-center"
              style={{ width: '86px', height: '86px' }}
            >
              <div className="text-white text-4xl">+</div>
            </div>
          </LinkWrapper>
        </div>
      </Panel>
    </div>
  );
};

FavoritesBoard.propTypes = {
  favouriteArtists: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.shape({}),
  favouritesURL: PropTypes.string,
  noFavourites: PropTypes.shape({}),
  viewAll: PropTypes.shape({}),
};

FavoritesBoard.defaultProps = {
  favouriteArtists: [],
  title: userTranslation.favouritesTitle,
  favouritesURL: '',
  noFavourites: translation.noFavourites,
  viewAll: translation.viewAll,
};

export default FavoritesBoard;
