import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';

import Link from '../../../../../util/link';
import translateObj from '../../../../../util/translateObj';
import InfoPopover from '../../../Form/InfoPopover';
import LoadIcon from '../../../LoadIcon';
import StandardFormCheckboxConditions from '../../../Form/StandardFormCheckboxConditions';

import signupUserForm from '../../../../../api/graphql/mutations/signupUserForm';
import userWebLogin from '../../../../../api/graphql/mutations/userWebLogin';
import { useUser } from '../../../../../context/userProfileContext';

const notUserDefault = {
  notAUserMain: {
    es: '¿No eres usuario de Primavera Sound?',
    en: 'Not a Primavera Sound user yet?',
    ca: 'No ets usuari de Primavera Sound?',
    pt: 'Not a Primavera Sound user yet?',
  },
  notAUserLink: {
    es: 'Crear Cuenta',
    en: 'Create Account',
    ca: 'Crear Compte',
    pt: 'Create Account',
  },
};

const defaultConditionsText = {
  acceptText: {
    es: 'Acepto las ',
    en: 'I accept the ',
    ca: 'Accepto les ',
    pt: 'I accept the ',
  },
  conditionsText: {
    es: 'CONDICIONES DE ACCESO',
    en: 'ACCESS CONDITIONS',
    ca: 'CONDICIONS D’ACCÉS',
    pt: 'ACCESS CONDITIONS',
  },
  conditionsLink: {
    es: 'https://www.primaverasound.com/es/user-register-conditions-for-primavera-sound-barcelona',
    en: 'https://www.primaverasound.com/en/user-register-conditions-for-primavera-sound-barcelona',
    ca: 'https://www.primaverasound.com/ca/user-register-conditions-for-primavera-sound-barcelona',
    pt: 'https://www.primaverasound.com/pt/user-register-conditions-for-primavera-sound-barcelona',
  },
  dataTreatmentText: {
    es: ' y el tratamiento de datos y la elaboración de perfiles según la ',
    en: ' and data processing and profiling according to the ',
    ca: " i el tractament de dades i l'elaboracio de perfils segons la ",
    pt: ' and data processing and profiling according to the ',
  },
  privacyPolicyText: {
    es: 'POLÍTICA DE PRIVACIDAD',
    en: 'PRIVACY POLICY',
    ca: 'POLÍTICA DE PRIVACITAT',
    pt: 'PRIVACY POLICY',
  },
  privacyPolicyUrl: {
    es: 'https://www.primaverasound.com/es/privacy-policy',
    en: 'https://www.primaverasound.com/en/privacy-policy',
    ca: 'https://www.primaverasound.com/ca/privacy-policy',
    pt: 'https://www.primaverasound.com/pt/privacy-policy',
  },
};

const SectionPasswordOnDemand = ({
  language, loadTokenForLS, handleOnDemandScreenStatus, handleModalNotLoggedStatus, handleRegisteringStatus, translations: { notLoggedModal: translations = {} } = {}, sourceWeb, QRcodeCallback,
}) => {
  const emailRef = useRef('');
  const passwordRef = useRef('');
  const recaptchaRef = useRef();

  const userLoginDetailsInitial = {
    name: '', password: '',
  };
  const [isLoading, setIsLoading] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [isReCaptchaDone, setIsReCaptchaDone] = useState(false);
  const [loginStatus, setLoginStatus] = useState(null);
  const [loginDetails, setLoginDetails] = useState(userLoginDetailsInitial);
  const [showPassword, setShowPassword] = useState(false);
  const user = useUser();

  useEffect(() => {
    const loginNames = Object.keys(loginDetails);
    if (loginNames.length) {
      const { name = '', password = '' } = loginDetails;
      if (name.length > 4 && password.length > 6 && name.includes('@') && name.includes('.')) {
        setIsFilled(true);
      } else {
        setIsFilled(false);
      }
    }
  }, [loginDetails]);

  const handleChange = (e) => {
    const userObj = {
      ...loginDetails,
      [e.target.name]: e.target.value,
    };
    setLoginDetails(userObj);
  };


  const handleSubmit = (e, loadTokenForLSFn, screenStatus) => {
    e.preventDefault();
    setLoginStatus(null);
    setIsReCaptchaDone(false);
    const { name, password } = loginDetails;

    if (screenStatus === 'notFound') {
      setIsLoading(true);
      setIsFilled(false);

      // * create account with email and password, set up a random nickname
      const nameToLowerCase = name.toLocaleLowerCase();
      const userNameFromEmail = nameToLowerCase.split('@')[0];
      const userNameFromEmailNoNumbers = userNameFromEmail.replace('+', '').replace(/[0-9]+/g, '').replace('.', '').replace('-', '')
        .substring(0, 8);
      const userName = userNameFromEmailNoNumbers.length > 4 ? userNameFromEmailNoNumbers : `${userNameFromEmailNoNumbers}PrimaveraS`;
      const formDataUpdated = {
        email: nameToLowerCase, password, name: userName,
      };
      signupUserForm({ ...formDataUpdated }).then((response) => {
        if (response) {
          if (response.isValid) {
            // this.onReset();
            setIsFilled(true);
            user.setNewAuthToken(response.token);
            setIsLoading(false);
            // !!! need to check this no need to handle ondemandscreenstatus as userEmail in context is being updated already
            // handleOnDemandScreenStatus('logged');

            // Redirect to payment page
            // history.push(`/${language}`);
          } else if (response.error) {
            formData.email = '';
            console.log('server-response error:', 'response.error');
            setIsLoading(false);
          } else {
            console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Error - submitForm validated, but returned isValid = false!`);
          }
        } else {
          console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Error - submitForm failed to submit!`);
        }
      });
    }

    const loginObj = {};
    loginObj.email = name;
    loginObj.password = password;
    userWebLogin({ ...loginObj }).then((res) => {
      if (res) {
        if (res.isValid) {
          setLoginStatus('logged');
          emailRef.current.value = '';
          passwordRef.current.value = '';
          user.setNewAuthToken(res.token);
          if (QRcodeCallback) {
            QRcodeCallback();
          }
        } else if (res.token) {
          setLoginStatus(res.token);
        } else {
          console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Error - loginForm validated, but returned isValid = false!`);
        }
      } else {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Error - loginForm failed to submit!`);
      }
    });
  };

  const handleCheckBoxValue = (isChecked) => {
    if (isChecked) {
      setIsFilled(true);
    } else {
      setIsFilled(false);
    }
  };

  useEffect(() => {
    if (loginStatus === 'notFound' || loginStatus === 'notFound') {
      setIsFilled(false);
    }
  }, [loginStatus]);

  const renderNotAbleToAccessOptions = (passwordLoginStatus) => {
    const getRecoverPasswordUrlBasedOnSourceWeb = (source) => {
      switch (source) {
        case 'pslife':
          return `https://www.onps.life/${language || 'en'}/recover-password-pslife`;
        case 'pro-online':
          return `https://www.onps.pro/${language || 'en'}/recover-password-pro-online`;
        default:
          return '/recover-password';
      }
    };

    if (passwordLoginStatus === 'notFound') {
      return (

        <StandardFormCheckboxConditions
          getCheckBoxValue={handleCheckBoxValue}
          required
          name="conditions"
          acceptText={translateObj(translations.hasOwnProperty('conditions') ? translations.conditions.acceptText : defaultConditionsText.acceptText, language)}
          conditionsText={translateObj(translations.hasOwnProperty('conditions') ? translations.conditions.conditionsText : defaultConditionsText.conditionsText, language)}
          conditionsLink={translateObj(translations.hasOwnProperty('conditions') ? translations.conditions.conditionsLink : defaultConditionsText.conditionsLink, language)}
          dataTreatmentText={translateObj(translations.hasOwnProperty('conditions') ? translations.conditions.dataTreatmentText : defaultConditionsText.dataTreatmentText, language)}
          privacyPolicyText={translateObj(translations.hasOwnProperty('conditions') ? translations.conditions.privacyPolicyText : defaultConditionsText.privacyPolicyText, language)}
          privacyPolicyUrl={translateObj(translations.hasOwnProperty('conditions') ? translations.conditions.privacyPolicyUrl : defaultConditionsText.privacyPolicyUrl, language)}
          validationError=""
        />

      // <div className="sm:mt-0 font-tiemposRegular text-14 cursor-default text-center">
      //   {translateObj(translations.notAUserMain, language) || translateObj(notUserDefault.notAUserMain, language)}
      //   <span className="font-bold font-underlined cursor-pointer">
      //     <span onClick={() => handleRegisteringStatus(true)} className="text-black underline">
      //       {translateObj(translations.notAUserLink, language) || translateObj(notUserDefault.notAUserLink, language) }
      //     </span>
      //   </span>
      // </div>
      );
    } if (passwordLoginStatus === 'wrongPassword') {
      const toSlug = getRecoverPasswordUrlBasedOnSourceWeb(sourceWeb);
      return (
        <div className="sm:mt-0 font-tiemposRegular text-14 cursor-default text-center">
          {translateObj(translations.forgotPassword, language)}
          <Link to={toSlug}>
            <span className="font-bold font-underlined cursor-pointer">
              <span className="text-black underline">
                {translateObj(translations.recoverPassword, language)}
              </span>
            </span>
          </Link>
        </div>
      );
    }
    if (passwordLoginStatus === 'tooManyPasswordAttempts') {
      const getRecoverPasswordUrlBasedOnSourceWeb = (source) => {
        switch (source) {
          case 'pslife':
            return `https://www.onps.life/${language || 'en'}/recover-password-pslife`;
          case 'pro-online':
            return `https://www.onps.pro/${language || 'en'}/recover-password-pro-online`;
          case '':
            return '/recover-password';
          default:
            return '/recover-password';
        }
      };
      const toSlug = getRecoverPasswordUrlBasedOnSourceWeb(sourceWeb);
      return (
        <div className="sm:mt-0 font-tiemposRegular text-14 cursor-default text-center">
          {translateObj(translations.forgotPassword, language)}
          <Link to={toSlug}>
            <span className="font-bold font-underlined cursor-pointer">
              <span className="text-black underline">
                {translateObj(translations.recoverPassword, language)}
              </span>
            </span>
          </Link>
        </div>
      );
    }


    if (passwordLoginStatus === 'createdWithSocial') {
      return (
        <div className="sm:mt-0 font-tiemposRegular text-14 cursor-default text-center text-red">
          {translateObj(translations.createdWithSocial, language)}
        </div>
      );
    }
    if (passwordLoginStatus === 'notFoundOrWrongPassword') {
      const toSlug = getRecoverPasswordUrlBasedOnSourceWeb(sourceWeb);
      return (
        <div className="sm:mt-0 font-tiemposRegular text-14 cursor-default text-center text-red">
          <div>{translateObj(translations.notFoundOrWrongPassword, language)}</div>
          <Link to={toSlug}>
            <span className="font-bold font-underlined cursor-pointer">
              <span className="text-black underline">
                {translateObj(translations.recoverPassword, language)}
              </span>
            </span>
          </Link>
        </div>
      );
    }

    return null;
  };

  const _loginForm = () => {
    const handleButtonValue = (status) => {
      if (status === 'notFound') {
        if (isLoading) {
          return (
            <div className="w-full flex items-center">
              <div
                className={`mt-4 rounded-full border-1 bg-inherit ${isFilled ? 'bg-white text-black border-1 border-white cursor-pointer ' : 'bg-white text-gray200 border-grey cursor-not-allowed'}  text-12 font-americaMonoBold  w-full    text-center `}
                style={{
                  alignSelf: 'flex-end',
                  minHeight: '2.5rem',
                }}
              >
                <LoadIcon height={15} width={15} color="black" type="ThreeDots" />

              </div>
            </div>
          );
        }
        return (
          <div className="w-full flex items-center">
            <input
              disabled={!isFilled}
              type="submit"
              value={translateObj(translations.createAccount, language)}
              className={`mt-4 rounded-full border-1 bg-inherit ${isFilled ? 'bg-white text-black border-1 border-white cursor-pointer ' : 'bg-white text-gray200 border-grey cursor-not-allowed'}  text-12 font-americaMonoBold  w-full    text-center `}
              style={{
                alignSelf: 'flex-end',
                minHeight: '2.5rem',
              }}
            />
          </div>
        );
      }
      if (status === 'wrongPassword') {
        return (
          <div className="w-full flex items-center">
            <input
              disabled={!isFilled}
              type="submit"
              value={translateObj(translations.accessAccount, language)}
              className={`mt-4 rounded-full border-1 bg-inherit ${isFilled ? 'bg-white text-black border-1 border-white cursor-pointer ' : 'bg-white text-gray200 border-grey cursor-not-allowed'}  text-12 font-americaMonoBold  w-full    text-center `}
              style={{
                alignSelf: 'flex-end',
                minHeight: '2.5rem',
              }}
            />
          </div>
        );
      }
      if (status === 'tooManyPasswordAttempts') {
        const enableButtonIfCaptchaIsMissingOrNotFilled = isReCaptchaDone && isFilled;
        return (
          <div className="w-full flex flex-col items-center">
            <input
              disabled={!enableButtonIfCaptchaIsMissingOrNotFilled}
              type="submit"
              value={translateObj(translations.accessAccount, language)}
              className={`mt-4 rounded-full border-1 bg-inherit ${enableButtonIfCaptchaIsMissingOrNotFilled ? 'bg-white text-black border-1 border-white cursor-pointer ' : 'bg-white text-gray200 border-grey cursor-not-allowed'}  text-12 font-americaMonoBold  w-full    text-center `}
              style={{
                alignSelf: 'flex-end',
                minHeight: '2.5rem',
              }}
            />
            <div className="mt-4">
              <ReCAPTCHA
                ref={recaptchaRef}
                onChange={() => setIsReCaptchaDone(true)}
                sitekey="6LeVsbsUAAAAAI8Crd2wQ8eNINyoLzAT0uM01KKe"
              />

            </div>
          </div>
        );
      }


      return (
        <div className="w-full flex items-center">
          <input
            disabled={!isFilled}
            type="submit"
            value={translateObj(translations.submitTitle, language)}
            className={`mt-4 rounded-full border-1 bg-inherit ${isFilled ? 'bg-white text-black border-1 border-white cursor-pointer ' : 'bg-white text-gray200 border-grey cursor-not-allowed'}  text-12 font-americaMonoBold  w-full    text-center `}
            style={{
              alignSelf: 'flex-end',
              minHeight: '2.5rem',
            }}
          />
        </div>
      );
    };
    const fullWidth = true;
    return (
      <>
        <button
          type="button"
          className="bg-psworld-tab-grey text-white py-1 px-4 rounded-lg w-1/5 sm:w-full flex justify-center"
          onClick={() => handleModalNotLoggedStatus('social')}
        >
          <span className="font-americaMonoRegular uppercase text-12 leading-1">{translateObj(translations.goBackText) || 'GO BACK'}</span>
        </button>
        <div className={`${'w-full py-4'}`} />
        <form className={`flex flex-col w-full ${!fullWidth && 'sm:mx-8'} py-8 flex-wrap sm:pb-4 pt-0 `} onSubmit={e => handleSubmit(e, loadTokenForLS, loginStatus)}>
          <label className="flex flex-col font-americaMonoRegular text-12 w-full ">
            {translateObj(translations.userTitle, language)}
            <input ref={emailRef} type="text" name="name" onChange={handleChange} className="my-4 rounded-full border-1 p-4 py-3.5 bg-inherit border-black text-black text-12 bg-white w-full" />
          </label>
          <label className="flex flex-col font-americaMonoRegular sw-full  text-12 relative">
            <div className="flex">
              {translateObj(translations.passwordTitle, language)}
              <div className="ml-4"><InfoPopover text={translateObj(translations.passwordInfo, language)} /></div>
            </div>
            <input ref={passwordRef} type={showPassword ? 'text' : 'password'} name="password" onChange={handleChange} className="my-4 rounded-full border-1 p-4 py-3.5 bg-inherit border-black text-black text-12 w-full bg-white" />
            <button type="button" onClick={() => setShowPassword(prevState => !prevState)} className=" px-1 text-black rounded-xl absolute pin-r pin-b mr-4 mb-6" style={{ bottom: '2px' }}>
              <span className={`font-icon1 text-18 text-black ${showPassword ? 'icon-visibility-4' : 'icon-visibility-3'} `} />
            </button>
          </label>

          {renderNotAbleToAccessOptions(loginStatus)}

          <div className="w-full flex items-center">
            {handleButtonValue(loginStatus)}
          </div>
        </form>

        {/* <div className="sm:mt-0 font-tiemposRegular text-14 cursor-default text-center">
            {translateObj(translations.notAUserMain, language) || translateObj(notUserDefault.notAUserMain, language)}
            {' '}
            <span className="font-bold font-underlined cursor-pointer">
              <span onClick={() => handleRegisteringStatus(true)} className="text-black underline">
                {translateObj(translations.notAUserLink, language) || translateObj(notUserDefault.notAUserLink, language) }
              </span>
            </span>
          </div>
          <div className="sm:mt-0 font-tiemposRegular text-14 cursor-default text-center">
            {translateObj(translations.forgotPassword, language)}
            {' '}
            <Link to="/recover-password">
              <span className="font-bold font-underlined cursor-pointer">
                <span className="text-black underline">
                  {translateObj(translations.recoverPassword, language)}
                </span>
              </span>
            </Link>
          </div> */}

        {/* {(loginStatus === 'notFound' || loginStatus === 'wrongPassword')
            && <div className="font-americaMonoRegular text-12 text-red mt-4">{translateObj(translations.userIncorrect, language)}</div>} */}

        <div />
      </>
    );
  };

  return (
    <>
      <div className="flex flex-row pt-4 xl:pb-0 lg:pb-0 flex-wrap  md:flex-col py-4">
        {_loginForm()}
      </div>

    </>
  );
};

SectionPasswordOnDemand.propTypes = {
  language: PropTypes.string,
  loadTokenForLS: PropTypes.func.isRequired,
  handleOnDemandScreenStatus: PropTypes.func.isRequired,
  handleModalNotLoggedStatus: PropTypes.func.isRequired,
  handleRegisteringStatus: PropTypes.func.isRequired,
  translations: PropTypes.shape().isRequired,
  sourceWeb: PropTypes.string,
  QRcodeCallback: PropTypes.func,
};

SectionPasswordOnDemand.defaultProps = {
  language: 'en',
  sourceWeb: '',
  QRcodeCallback: null,
};

export default SectionPasswordOnDemand;
