import React from 'react';
import { PropTypes } from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
// import ImageWrapper from '@primaveralabs/ui-image-wrapper';
// import 'Primavera/ui-themes/dist/primavera/index.css';
// import 'Primavera/ui-image-wrapper/dist/primavera.css';
import translateObj from '../../util/translateObj';
import { useRoutes } from '../../context/routesContext';
import ComponentIterator from '../../containers/ComponentIterator';
import useResponsiveUtility from '../../util/responsiveUtility';

// NOTE: Temporaty component until the one from ps-universe is ready
const ImageWrapper = ({ image, children, repeat = true, paddingTop = 16, paddingBottom = 16, paddingLeft = 16, paddingRight = 16 }) => {
  const style = {
    display: 'flex',
    justifyContent: 'center',
    backgroundRepeat: repeat ? 'repeat' : 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: repeat ? 'contain' : 'cover',
    backgroundImage: `url(${image})`,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
  };

  return (
    <div style={style}>
      {children}
    </div>
  );
};

const ImageWrapperComp = ({ image, components, desktop, mobile }) => {
  const { language } = useRoutes();
  const { deviceScreen } = useResponsiveUtility();

  const isMobile = ['sm', 'md', 'lg'].includes(deviceScreen);

  const padding = {
    paddingTop: isMobile ? mobile.paddingTop : desktop.paddingTop,
    paddingBottom: isMobile ? mobile.paddingBottom : desktop.paddingBottom,
    paddingLeft: isMobile ? mobile.paddingLeft : desktop.paddingLeft,
    paddingRight: isMobile ? mobile.paddingRight : desktop.paddingRight,
  };

  return (
    <div className="full-width w-full">
      <ImageWrapper
        image={translateObj(image, language)}
        repeat={false}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...{ ...padding }}
      >
        <ComponentIterator components={components} />
      </ImageWrapper>
    </div>
  );
};

ImageWrapperComp.propTypes = {
  image: PropTypes.shape({}),
  components: PropTypes.arrayOf(PropTypes.shape({})),
  desktop: PropTypes.shape({}),
  mobile: PropTypes.shape({}),
};

ImageWrapperComp.defaultProps = {
  image: { en: '' },
  components: [],
  desktop: {
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 16,
    paddingRight: 16,
  },
  mobile: {
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 8,
    paddingRight: 8,
  },
};

export default ImageWrapperComp;
