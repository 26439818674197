import React from 'react';
import PropTypes from 'prop-types';
import { useRoutes } from '../../../../context/routesContext';
import NewsletterSubscribeWidgetReusable from '../../NewsletterSubscribeWidgetReusable';
import translateObj from '../../../../util/translateObj';
import imageModifier from '../../helpers/imageModifier';
import Link from '../../../../util/link';

const NEWSLESTTER_DISCLAIMER = {
  en: '*By signing up, you are agreeing to receive information from Primavera Sound based on your interests, with news regarding their products and in accordance with the {{{Privacy Policy}}}.',
  es: '*Registrándote estás aceptando recibir de Primavera Sound información elaborada a partir de tus intereses, con noticias relativas a sus productos y de conformidad con la {{{Política de Privacidad}}}.',
  ca: '*Registrant-te estàs acceptant rebre de Primavera Sound informació elaborada a partir dels teus interessos, amb notícies relatives als teus productes i de conformitat amb la {{{Política de Privacitat}}}.',
};

function createParagraphWithLink(text, url, path, classes) {
  const parts = text.split('{{{');
  const beforeLink = parts[0];
  const [linkText, afterLink] = parts[1].split('}}}');

  return (
    <>
      {beforeLink}
      <Link to={url} localizationpath={path} className={classes}>{linkText}</Link>
      {afterLink}
    </>
  );
}

const Newsletter = ({ itemLayout, isBanner, isNews, isHalf, image, backgroundStyle, title, subtitle, imageSize, tags }) => {
  const { language, path } = useRoutes();

  return (
    <div className={`relative flex justify-center items-center border-t border-transparent launcherItemData ${itemLayout} ${isNews && !isHalf ? 'single-news' : ''} ${isBanner ? 'sliderComponent' : ''}`}>
      {isBanner
        ? (
          <div
            key={image.en}
            className="sliderBackground w-full sliderComponent overflow-hidden sm:h-launcherSingle-sm md:h-launcherSingle-md lg:h-launcherSingle-lg llg:h-launcherSingle-llg"
            style={backgroundStyle}
          />
        ) : (
          <div className="launcherItemImg relative">
            <img className="min-w-full" src={imageModifier(translateObj(image, language), imageSize)} alt={translateObj(title, language)} />
          </div>
        )}
      <div className="absolute text-white sm:p-5 w-full z-50 no-underline flex flex-col items-center font-americaRegular  text-center" style={{ maxWidth: '700px' }}>
        {title && (
          <h2 className="mb-5 sm:text-base md:text-2xl text-3xl text-center color-white">
            {translateObj(title, language)}
          </h2>)}
        <p tabIndex="0" className="mb-8 sm:mb-3 md:mb-5 sm:text-xs text-sm whitespace-pre-line mb-1 text-white cursor-default text-center">
          {translateObj(subtitle, language)}
        </p>
        <div className={`w-full mb-16 sm:mb-3 md:mb-5 ${isBanner ? 'md:w-2/3 lg:w-2/3 xl:w-2/3' : ''}`}>
          <NewsletterSubscribeWidgetReusable
            scopes={{
              en: tags,
            }}
            isLauncherItem
          />
        </div>
        <div>
          <p className="sm:text-xxs text-xs text-center">
            {createParagraphWithLink(translateObj(NEWSLESTTER_DISCLAIMER, language), '/privacy-policy', path, 'text-white underline decoration-white')}
          </p>
        </div>
      </div>
    </div>
  );
};

Newsletter.propTypes = {
  itemLayout: PropTypes.string.isRequired,
  title: PropTypes.shape().isRequired,
  isBanner: PropTypes.bool,
  image: PropTypes.shape({
    en: PropTypes.string.isRequired,
  }).isRequired,
  tags: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};

Newsletter.defaultProps = {
  isBanner: false,
};

export default Newsletter;
