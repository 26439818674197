/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import FilePlayer from 'react-player';

const StreamingVideoPlayerSmallPlayer = ({
  smallVideo,
  autoPlay,
  handleSwitchingVideos,
  shouldHide,
}) => (
  <div className={`${'w-1/4'} cursor-pointer mx-2 max-w-xssm ${shouldHide && 'hidden'}`} onClick={() => handleSwitchingVideos(smallVideo.id)}>
    <FilePlayer
      url={smallVideo.urlSmall}
                // className="absolute pin-t pin-l-0"
      playing
      loop
      height="100%"
      width="100%"
      muted
      config={{
        autoPlay,
        controls: false,
        file: {
          forceDASH: true,
        },
      }}
    />
  </div>
);

StreamingVideoPlayerSmallPlayer.propTypes = {
  smallVideo: PropTypes.shape().isRequired,
  autoPlay: PropTypes.bool,
  shouldHide: PropTypes.bool,
  handleSwitchingVideos: PropTypes.func.isRequired,
};

StreamingVideoPlayerSmallPlayer.defaultProps = {
  autoPlay: true,
  shouldHide: false,
  smallVideo: {
    urlBig: '',
    urlSmall: '',
    id: '1',
  },
};

export default StreamingVideoPlayerSmallPlayer;
