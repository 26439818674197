import React from 'react';
import ComponentIterator from '../../../containers/ComponentIterator';

import home from './mock-data/home';
import recoverPassword from './mock-data/recoverPassword';
import agenda from './mock-data/agenda';
import members from './mock-data/members';
import memberDetail from './mock-data/memberDetail';
import mentoringSessions from './mock-data/mentoringSessions';
import mentoringSessionDetail from './mock-data/mentoringSessionDetail';
import reunions from './mock-data/reunions';
import reunionInvitationSent from './mock-data/reunionInvitationSent';
import forum from './mock-data/forum';
import profile from './mock-data/profile';
import newReunion from './mock-data/newReunion';
import QRCodeResponse from '../LandingQRCodes/QRCodeResponse';

const testComponents = ({ ...props }) => {
  console.log('props', props);
  return (
    <QRCodeResponse />
  // <ComponentIterator components={[
  //   // ...home,
  //   // ...recoverPassword,
  //   // ...agenda,
  //   // ...members,
  //   // ...memberDetail,
  //   // ...mentoringSessions,
  //   // ...mentoringSessionDetail,
  //   // ...reunions,
  //   // ...reunionInvitationSent,
  //   // ...forum,
  //   // ...profile,
  //   // ...newReunion,
  // ]}
  // />
  );
};

export default testComponents;
