import React from 'react';

const LegalBasis = () => (
  <div>
    <strong>PRIMAVERA QR - BASES LEGALES</strong>
    <br />
    <br />
    <strong>¿CÓMO FUNCIONA?</strong>
    <br />
    <br />
      La organización del festival Primavera Sound 2022 ha distribuido cientos de códigos QR en la zona
      del vial conector entre dos zonas principales del recinto del festival.
      Estos códigos QR redirigirán al público del festival, de forma aleatoria, a 3 posibles landings donde
      podrán conseguir distintos premios:
    <br />
    <br />
      ● Landing 1: el asistente ganará un abono para Primavera Sound 2023 Barcelona y Madrid.
      El asistente tendrá que registrarse y rellenar el formulario de login existente de Primavera
      Sound.
    <br />
      ● Landing 2: previo registro, el asistente recibe un código. Con este código podrá recibir una
      camiseta en la tienda oficial de merchandising del festival.
    <br />
      ● Landing 3: el QR no tiene asociado ningún premio.
    <br />
    <br />
    <strong>¿CUÁNTOS PREMIOS SE ENTREGAN?</strong>
    <br />
    <br />
      Cada día del festival (2, 3, 4, 9, 10, 11 de junio) se regalarán un total de 25 abonos y 25 camisetas.
      En total se regalarán 150 abonos y 150 camisetas.
    <br />
    <br />
    <strong>¿BAJO QUÉ CONDICIONES SE ENTREGAN?</strong>
    <br />
    <br />
      Los premios se otorgarán de forma totalmente aleatoria entre todos los usuarios que escaneen los
      QR.
    <br />
    <br />
    <strong>¿SE PUEDEN ACUMULAR LOS PREMIOS?</strong>
    <br />
    <br />
      Cada día, un asistente podrá ganar:
    <br />
    <br />
      ● Una o más camisetas.
    <br />
      ● Una o más camisetas y un abono.
    <br />
      ● Un solo abono
    <br />
      En el caso que un mismo usuario encuentre 2 o más QR ganadores de abono, solo recibirá un
      premio.
  </div>
);

export default LegalBasis;
