import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Panel from '@primaveralabs/ui-panel';
import 'Primavera/ui-panel/dist/primavera.css';
import 'Primavera/ui-badge/dist/primavera.css';
import 'Primavera/ui-themes/dist/primavera/index.css';
import { useRoutes } from '../../../context/routesContext';
import getPostsBySlugNameQuery from '../../../api/graphql/queries/getPostsBySlugNameQuery';
import getPostsListWithTotal from '../../../api/graphql/queries/getPostsListWithTotal';
import translateObj from '../../../util/translateObj';
import LoadIcon from '../LoadIcon';
import commonTranslation from './translations/common';
import translation from './translations/UserPanel';
import AchievementsList from './AchievementsList';

const AchievementsBoard = ({
  loyalty,
  title,
  noAchievements,
  pointsLabel,
  achievedOn,
  limit,
  viewAll,
}) => {
  const { language } = useRoutes();
  const [loading, setLoading] = useState(false);
  const [achievements, setAchievements] = useState([]);
  const [myAchievements, setMyAchievements] = useState([]);

  const loadAchievements = () => {
    setLoading(true);
    getPostsListWithTotal({
      category: ['loyalty-achievement'],
      from: 0,
      to: limit,
      showNoIndex: true,
    })
      .then((res) => {
        if (res?.posts) {
          if (limit) {
            setAchievements(res.posts.slice(0, limit - 1));
          } else {
            setAchievements(res.posts);
          }
        }
      })
      .catch((err) => console.log(err));
    setLoading(false);
  };

  const loadData = () => {
    setLoading(true);
    const slugNames = loyalty?.achievements?.map((i) => i.slugName);
    getPostsBySlugNameQuery(slugNames)
      .then((res) => {
        if (res) {
          const formattedAchievements = res.map((i) => {
            const achieved = loyalty.achievements.find((a) => a.slugName === i.slugName).createdAt;
            return { ...i, achievedOn: achieved };
          });
          if (limit) {
            setMyAchievements(formattedAchievements.slice(0, limit - 1));
          } else {
            setMyAchievements(formattedAchievements);
          }
        }
      })
      .catch((err) => console.log(err));
    setLoading(false);
  };

  useEffect(() => {
    loadAchievements();
    loadData();
  }, []);

  return (
    <div style={{ gridArea: 'achievements' }} className="h-full">
      <Panel
        display={loading ? 'center' : 'wrap'}
        title={translateObj(title, language)}
        ctaLabel={limit ? translateObj(viewAll, language) : null}
        ctaLink={limit ? `/${language}/user-panel/rewards` : null}
        noChildrenMessage={translateObj(noAchievements, language)}
        scroll
      >
        {loading ? <LoadIcon />
          : (
            <AchievementsList
              achievements={achievements}
              myAchievements={myAchievements}
              pointsLabel={pointsLabel}
              achievedOn={achievedOn}
              limit={limit}
            />
          )}
      </Panel>
    </div>
  );
};

AchievementsBoard.propTypes = {
  loyalty: PropTypes.shape({
    achievements: PropTypes.arrayOf(PropTypes.shape()),
    loyaltyPoints: PropTypes.number,
    prizes: PropTypes.arrayOf(PropTypes.shape()),
  }),
  title: PropTypes.shape({}),
  noAchievements: PropTypes.shape({}),
  pointsLabel: PropTypes.shape({}),
  achievedOn: PropTypes.shape({}),
  viewAll: PropTypes.shape({}),
  limit: PropTypes.number,
};

AchievementsBoard.defaultProps = {
  loyalty: {
    achievements: [],
    loyaltyPoints: 0,
    prizes: [],
  },
  title: translation.myAchievements,
  noAchievements: commonTranslation.noAchievements,
  pointsLabel: commonTranslation.points,
  achievedOn: commonTranslation.achieved,
  viewAll: commonTranslation.viewAll,
  limit: 10,
};

export default AchievementsBoard;
