/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';
import Link from '../../../util/link';

import TitleLineH1 from '../Text/TitleLineH1';
import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';
import imageModifier from '../helpers/imageModifier';
import translateObj from '../../../util/translateObj';
import LinkButtonRounded from '../Buttons/LinkButtonRounded';
import ImageLoader from './ImageLoader';

const TOURS_DEFAULT_INTERNAL_LINK = '/tickets?e=tours';

const LauncherItemConcert = ({
  image,
  url,
  title,
  subtitle,
  match,
  cta,
  isBanner,
  isHalf,
  device,
}) => (
  <RoutesContext.Consumer>
    {({ language: contextLanguage, enteredFrom }) => {
      const localization = getLocalization(contextLanguage, enteredFrom, match);
      const imageSize = isHalf && (device !== 'small' && device !== 'medium') ? '1440x620' : '1000x1000';

      const urlWithSlash = url[0] === '/' ? url : `/${url}`;
      const button = {
        text: {
          en: 'See more',
          es: 'Saber más',
          ca: 'SABER MÉS',
          pt: 'SABER MAIS',
        },
        url: localization.path + urlWithSlash,
      };
      const defaultBuyTicketsButton = {
        en: 'BUY TICKETS',
        es: 'COMPRAR ENTRADAS',
        ca: 'COMPRAR ENTRADES',
        pt: 'COMPRAR BILHETES',
      };
      const backgroundStyle = {
        backgroundImage: `url(${imageModifier(translateObj(image, localization.language), '1440x620')})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      };
      const paragraphs = translateObj(subtitle, localization.language)
        .split('\n')
        .map(paragraph => <span>{paragraph}</span>);
      const titleLength = JSON.stringify(
        translateObj(title, localization.language),
      ).length;
      return (
        <div className={`relative border-t border-transparent launcherItemData concert ${isBanner ? 'sliderComponent' : ''}`}>
          {isBanner
            ? (
              <div
                key={image.en}
                className="sliderBackground w-full sliderComponent overflow-hidden sm:h-launcherSingle-sm md:h-launcherSingle-md lg:h-launcherSingle-lg llg:h-launcherSingle-llg"
                style={backgroundStyle}
              />
            ) : (
              <div className="bg-black launcherItemImg relative">
                <img className="opacity-75 noBackgroundImgGray" src={imageModifier(translateObj(image, localization.language), imageSize)} alt={translateObj(title, localization.language)} />
              </div>
            )}
          <div className="absolute z-50 pin-l pin-b p-12 text-white sm:px-5 sm:pb-5 md:pb-5 lg:pb-4 smlg:pb-6">
            <Link to={urlWithSlash} localizationpath={localization.path}>
              {title && (
                <TitleLineH1
                  text={title}
                  textColor="#FFFFFF"
                  margin={`sm:mb-2 md:pb-3 hover:underline ${titleLength > 15
                    && 'lgsm:text-2rem'}`}
                  tabIndex="-1"
                />
              )}
            </Link>
            {subtitle && (
              <div className="min-h-35 sm:min-h-full">
                {paragraphs.map((paragraph, i) => (
                  <p
                    tabIndex="0"
                    className="font-americaMonoRegular text-14 md:text-12 uppercase whitespace-pre-line mb-1 text-white cursor-default"
                    key={paragraph + i}
                  >
                    {paragraph}
                  </p>
                ))}
              </div>
            )}
            {
              <div className="pt-8 pb-6 md:pt-6 md:pb-4 lg:pb-4 lg:pt-7">
                <LinkButtonRounded url={cta?.url?.length > 0 ? cta.url : TOURS_DEFAULT_INTERNAL_LINK} text={translateObj(cta.text, contextLanguage)} isDisabled={cta && cta.isDisabled ? cta.isDisabled : false} />
              </div>
            }
            {/*
             NOTE: removed on Booking petition, they find it redundant as the h1 goes to the same place
              <Link to={urlWithSlash} localizationpath={localization.path}>
              {
                <span
                  className="uppercase text-white text-sm sm:text-12 md:text-12 py-3 font-americaMonoBold"
                >
                  <span aria-hidden>{'> '}</span>
                  {translateObj(button.text, localization.language)}
                </span>
              }
            </Link> */}
          </div>
        </div>
      );
    }}
  </RoutesContext.Consumer>
);
LauncherItemConcert.propTypes = {
  title: PropTypes.shape({
    es: PropTypes.string.isRequired,
    en: PropTypes.string.isRequired,
    ca: PropTypes.string.isRequired,
  }).isRequired,
  subtitle: PropTypes.shape({
    es: PropTypes.string.isRequired,
    en: PropTypes.string.isRequired,
    ca: PropTypes.string.isRequired,
  }),
  url: PropTypes.string.isRequired,
  cta: PropTypes.shape({
    url: PropTypes.string,
    isDisabled: PropTypes.bool,
    text: {
      es: PropTypes.string,
      en: PropTypes.string,
      ca: PropTypes.string,
      pt: PropTypes.string,
    },
  }),
  image: PropTypes.shape({
    es: PropTypes.string.isRequired,
    en: PropTypes.string.isRequired,
    ca: PropTypes.string.isRequired,
  }).isRequired,
};

LauncherItemConcert.defaultProps = {
  cta: {
    subtitle: null,
    url: 'tickets?e=tours',
    text: {
      en: 'BUY TICKETS',
      es: 'COMPRAR ENTRADAS',
      ca: 'COMPRAR ENTRADES',
      pt: 'COMPRAR BILHETES',
    },
    isDisabled: false,
  },
};

export default withRouter(LauncherItemConcert);
