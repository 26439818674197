/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';

import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useRoutes } from '../../../context/routesContext';
import validateReunionForm from './util/formValidations/validateReunionForm';
import useQuery from './util/hooks/useQuery';
import editReunion from '../../../api/graphql/mutations/editReunion';

import translateObj from '../../../util/translateObj';
import getTranslation from './util/translations/_getTranslation';
import localTranslation from './util/translations/ReunionForm';
import { VENUES_OPTIONS } from './util/optionValues';

import createReunion from '../../../api/graphql/mutations/createReunion';
import getReunion from '../../../api/graphql/queries/getReunion';
import getProMusicMeetsUser from '../../../api/graphql/mutations/getProMusicMeetsUser';

import HeadTitle from './HeadTitle';
import CollapsibleSection from './CollapsibleSection';
import FormField from './FormField';
import FormSelect from './FormSelect';
import FormCheckbox from './FormCheckbox';
import Button from './Button';
import ReunionFormParticipantsSelector from './ReunionFormParticipantsSelector';

function errorHandler(errors, literals) {
  if (errors.participants === 'MAX_PARTICIPANTS_EXEEDED') return literals.errorReunionMaxParticipantsExeeded;
  return literals.errorMessage;
}

const ReunionForm = ({
  translation,
}) => {
  const { language, path } = useRoutes();
  const history = useHistory();
  const literals = getTranslation(translation, localTranslation);
  const query = useQuery();
  const [queryId, setQueryId] = useState(null);
  const [queryAction, setQueryAction] = useState(null);
  const [currentParticipants, setCurrentParticipants] = useState([]);
  const [inviteParticipant, setInviteParticipant] = useState([]);
  const [reunionData, setReunionData] = useState({
    title: '',
    date: '',
    hour: '',
    location: '',
    online: false,
    description: '',
    participants: [],
  });

  const submitReunionData = (values) => {
    const dateTime = `${values.date}T${values.hour}:00`.replace(/-/g, '/').replace('T', ' ');
    const ISODate = new Date(dateTime).toISOString();

    delete values.hour;
    values.date = ISODate;
    delete values.confirmedParticipants;
    delete values.rejectedParticipants;

    if (queryAction === 'edit') {
      delete values._id;
      editReunion({ reunionId: queryId, payload: values }).then((res) => {
        if (res) {
          console.log('Reunion edited successfully');
          history.goBack();
        }
      }).catch((err) => console.log(err));
    } else {
      createReunion(values).then((res) => {
        if (res) {
          console.log('Reunion created successfully');
          history.push(`/${language}/pro-reunion-invitation-sent/?id=${res._id}`);
        }
      }).catch((err) => console.log(err));
    }
  };

  const getReunionData = (id) => {
    getReunion(id).then((res) => {
      if (res) {
        const data = res;
        const day = moment(res.date).format('YYYY-MM-DD');
        const hour = moment(res.date).format('HH:mm');
        data.date = day;
        data.hour = hour;
        setCurrentParticipants(data.participants);
        data.participants = data.participants.map((i) => i._id);
        setReunionData(data);
      }
    }).catch((err) => console.log(err));
  };

  const getUserData = (id) => {
    getProMusicMeetsUser(id).then((res) => {
      if (res) {
        const data = reunionData;
        setInviteParticipant([res]);
        data.participants = [res._id];
        setReunionData(data);
      }
    }).catch((err) => console.log(err));
  };

  useEffect(() => {
    const action = query.get('action');
    const id = query.get('id');

    if (id) setQueryId(id);
    if (action) setQueryAction(action);
  }, []);

  useEffect(() => {
    if (queryAction === 'edit' && queryId) {
      getReunionData(queryId);
    }
    if (queryAction === 'invite' && queryId) {
      getUserData(queryId);
    }
  }, [queryId, queryAction]);

  return (
    <div className="w-full">
      <HeadTitle title={translateObj(literals.title, language)} className="w-full sm:w-2/3 pt-10 pb-10 sm:pb-4" />
      <Formik
        enableReinitialize
        initialValues={reunionData}
        validate={(values) => validateReunionForm(values)}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(values) => submitReunionData(values)}
      >
        {(props) => {
          // eslint-disable-next-line react/prop-types
          const { values, errors } = props;

          return (
            <Form noValidate>
              <div className="flex flex-row sm:flex-col sm:justify-start justify-between">
                <CollapsibleSection
                  className="w-1/2 sm:w-full"
                  title={literals.titleReunion}
                  collapsible={false}
                  paddingBottom="pb-2"
                >
                  <FormField
                    name="title"
                    label={literals.labelName}
                    errors={errors}
                    required
                    value={reunionData?.title || ''}
                  />
                  <div className="flex pt-2 sm:pt-0">
                    <FormField
                      name="date"
                      type="date"
                      label={literals.labelDate}
                      className="w-1/2 mr-3 sm:mr-2"
                      required
                      errors={errors}
                    />
                    <FormField
                      name="hour"
                      type="time"
                      label={literals.labelHour}
                      className="w-1/2 ml-3 sm:ml-2"
                      required
                      errors={errors}
                    />
                  </div>
                  <FormSelect
                    name="location"
                    label={literals.labelLocation}
                    errors={errors}
                    options={VENUES_OPTIONS}
                    placeholder={literals.placeholderLocation}
                  />
                  <FormCheckbox
                    name="online"
                    type="checkbox"
                    label={literals.labelOnline}
                    checked={values.online}
                    className="w-full pt-4"
                    labelClassName="font-americaMonoRegular text-12 uppercase tracking-px"
                    errors={errors}
                  />
                  {(errors.location || errors.online) && (
                  <div
                    className="mt-4 p-5 border border-pro-online rounded-lg text-pro-online font-timesRegular text-12"
                  >
                      {translateObj(literals.errorMessageLocationOrOnline, language)}
                  </div>
                  )}
                  <FormField
                    name="description"
                    type="textarea"
                    textareaHeight="h-150px sm:h-120px"
                    label={literals.labelDescription}
                    className="w-full mt-4"
                    errors={errors}
                  />
                </CollapsibleSection>
                <div className="w-5/12 sm:w-full">
                  <ReunionFormParticipantsSelector
                    literals={literals}
                    errors={errors}
                    currentParticipants={currentParticipants}
                    inviteParticipant={inviteParticipant}
                  />
                </div>
              </div>
              {errors && Object.keys(errors).length > 0 && (
              <div className="mt-8 w-full border border-pro-online font-timesRegular text-12 p-5 rounded-lg sm:mb-8 text-pro-online">
                {translateObj(errorHandler(errors, literals), language)}
              </div>
              )}
              <div className="flex items-start sm:justify-center justify-end w-full border-t border-black mt-10 sm:mt-0 sm:pt-8 pt-6 mb-24">
                <Button text={translateObj(literals.buttonCancel, language)} color="outlineBlack" className="sm:mr-2 sm:w-2/5" parentPath={path} onClickCallback={() => history.goBack()} />
                <Button type="submit" text={translateObj(literals.buttonSubmit, language)} color="black" className="ml-4 sm:ml-2 sm:w-3/5" />
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

ReunionForm.propTypes = {
  translation: PropTypes.shape(),
};

ReunionForm.defaultProps = {
  translation: null,
};

export default ReunionForm;
