
import React from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import Filter from './Collapsible/Filter';
import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';
import ComponentIterator from '../../containers/ComponentIterator';

class GalleryContainer extends React.Component {
  state = {
    params: [],
    filtered: [],
    menu: {},
    isLoading: true,
  }

  componentDidMount() {
    const {
      params, menu, location: { search },
    } = this.props;
    this.setState({
      filtered: params,
      params,
      menu,
      isLoading: false,
    });
  }

  onHandleCollapsibleMenuSelect = (filters) => {
    const { params } = this.state;
    // this.setState({
    //   filtered,
    // });
  }

  getQueryVariable = (variable) => {
    const { location: { search } } = this.props;
    const vars = search.split('&');
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split('=');
      if (pair[0] === `?${variable}`) { return pair[1]; }
    }
    return (false);
  }

  render() {
    const {
      match,
      location: { pathname },
    } = this.props;
    const {
      filtered, isLoading, menu,
    } = this.state;
    return (
      <RoutesContext.Consumer>
        {
        ({ language: contextLanguage, enteredFrom }) => {
          const localization = getLocalization(contextLanguage, enteredFrom, match);
          return (
            <React.Fragment>
              {!isLoading
              && (
                <Filter {...menu} onHandleCollapsibleMenuSelect={this.onHandleCollapsibleMenuSelect} slugname={pathname.substring(1)} />
              )
              }
              <div className="flex flex-wrap md:-mx-2 lg:-mx-2 xl:-mx-2 mt-2">
                {filtered.length ? <ComponentIterator components={filtered} /> : <p className="w-full px-2">No content matched your criteria</p>}
              </div>
            </React.Fragment>
          );
        }
      }
      </RoutesContext.Consumer>
    );
  }
}

GalleryContainer.propTypes = {
  hasFilters: PropTypes.bool,
  slugname: PropTypes.string.isRequired,
  history: PropTypes.shape({}).isRequired,
  menu: PropTypes.shape({}),
  location: PropTypes.shape({}).isRequired,
};

GalleryContainer.defaultProps = {
  hasFilters: false,
  menu: {},
};

export default withRouter(GalleryContainer);
