import React from 'react';
import PropTypes from 'prop-types';

const ImageBlock = ({ name, url }) => (
  <img className="w-full" src={url.es} alt={name} />
);

ImageBlock.propTypes = {
  name: PropTypes.string.isRequired,
  url: PropTypes.shape().isRequired,
};
export default ImageBlock;
