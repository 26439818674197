import React from 'react';
import { PropTypes } from 'prop-types';

import { withRouter } from 'react-router-dom';
import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';
import imageModifier from './helpers/imageModifier';
import translateObj from '../../util/translateObj';

const PressSingleImage = ({
  match,
  url,
  url2,
  title,
  imageUrl,
  text1,
  text2,
  icon1Url,
  icon2Url,
}) => (
  <RoutesContext.Consumer>
    {
      ({ language: contextLanguage, enteredFrom }) => {
        const localization = getLocalization(contextLanguage, enteredFrom, match);

        const IconLink = ({ href, text, iconUrl }) => (
          <a href={href} className="font-americaMonoBold sm:text-10 lg:text-12 xl:text-12 black uppercase sm:mr-2 md:mr-2 items-center flex">
            <span className="mr-2">{text}</span>
            <img src={imageModifier(iconUrl, '450x260')} alt={text} />
          </a>
        );

        return (
          <div>
            <h2 className="black sm:text-18 md:text-20 lg:text-24 xl:text-24 font-americaBlack mb-4">{translateObj(title, localization.language)}</h2>
            <img className="w-full mb-6" src={imageUrl} alt={title[localization.language]} />
            <div className="flex justify-between sm:justify-start md:justify-start">
              <IconLink
                href={url[localization.language]}
                text={text1[localization.language]}
                iconUrl={icon1Url}
              />
              <IconLink
                href={url2[localization.language]}
                text={text2[localization.language]}
                iconUrl={icon2Url}
              />
            </div>
          </div>
        );
      }
    }
  </RoutesContext.Consumer>
);

PressSingleImage.propTypes = {
  url: PropTypes.shape().isRequired,
  url2: PropTypes.shape().isRequired,
  title: PropTypes.shape().isRequired,
  imageUrl: PropTypes.string.isRequired,
  text1: PropTypes.shape().isRequired,
  text2: PropTypes.shape().isRequired,
  icon1Url: PropTypes.string.isRequired,
  icon2Url: PropTypes.string.isRequired,
};

export default withRouter(PressSingleImage);
