import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import Link from '../../../util/link';
import getLocalization from '../../../util/localization';

import RoutesContext from '../../../context/routesContext';
import { UserProfileContextConsumer } from '../../../context/userProfileContext';
import withAllContexts from '../../../context/withAllContexts';
import translateObj from '../../../util/translateObj';

const ARIA_LABEL_NAVIGATE_HOME = {
  en: 'Primavera Sound: go to Home page',
  es: 'Primavera Sound: ir a la página de Inicio',
  ca: 'Primavera Sound: aneu a la pàgina d\'Inici',
  pt: 'Primavera Sound: vá para a página Inicial',
};


const TopLogoContainer = ({
  image, match, linkTo, ...props
}) => (
  <UserProfileContextConsumer>
    {({
      isActivationBarOpen, isConditionBarOpen,
    }) => (
      <RoutesContext.Consumer>
        {({ language: contextLanguage, enteredFrom }) => {
          const { path, language } = getLocalization(contextLanguage, enteredFrom, match);
          return (
            <Link
              tabIndex={props.headerContext.megaMenuIsOpenDesktop ? '-1' : '0'}
              to={linkTo}
              localizationpath={path}
              className="h-full z-50 mr-8 tablet:mr-10"
              aria-label={translateObj(ARIA_LABEL_NAVIGATE_HOME, language)}
            >
              <img className="h-full noBackgroundImgGray" src={image.src} alt={image.alt} />
            </Link>
          );
        }}
      </RoutesContext.Consumer>
    )}
  </UserProfileContextConsumer>
);

TopLogoContainer.propTypes = {
  linkTo: PropTypes.string,
  image: PropTypes.shape().isRequired,
  match: PropTypes.shape().isRequired,
  headerContext: PropTypes.shape().isRequired,
};

TopLogoContainer.defaultProps = {
  linkTo: '',
};

export default withRouter(withAllContexts(TopLogoContainer));
