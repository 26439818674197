const translation = {
  title: {
    en: 'Upcoming meetings',
    es: 'Próximas reuniones',
    ca: 'Properes reunions',
    pt: 'Próximas reuniões',
  },
  seeAll: {
    en: 'See all',
    es: 'Ver todas',
    ca: 'Veure totes',
    pt: 'Ver tudo',
  },
};

export default translation;
