import React from 'react';
import { PropTypes } from 'prop-types';

import { withRouter } from 'react-router-dom';
import Link from '../../util/link';
import RoutesContext from '../../context/routesContext';
import translateObj from '../../util/translateObj';
import getLocalization from '../../util/localization';
import imageModifier from './helpers/imageModifier';

const Media = ({
  image, text, slug, cta, match,
}) => (
  <RoutesContext.Consumer>
    {({ language: contextLanguage, enteredFrom }) => {
      const localization = getLocalization(contextLanguage, enteredFrom, match);
      return (
        <Link to={`${localization.path}${slug}`} className="no-underline px-2 sm:w-full w-1/3 text-black">
          <div className="rounded relative">
            <div className="w-full relative">
              <img src={imageModifier(translateObj(image, localization.language), '465x309')} alt={translateObj(text, localization.language)} />
              <div className="sm:absolute sm:pin-l sm:pin-b sm:pt-16">{/* <ButtonTag tag={tag} /> */}</div>
            </div>
            <div className="mt-6 mx-1 font-americaBlack text-left sm:text-sm md:text-base text-2xl">
              {!text[localization.language] ? text.en : text[localization.language]}
            </div>
            {cta ? (
              <div className="my-4 mx-1 font-americaMonoBold cursor-pointer font-black sm:text-xs md:text-xs text-sm">
                <div className="text-left uppercase">
                  <span className="font-icon icon-arrow-right text-black text-xxs mr-2 " />
                  {translateObj(cta, localization.language)}
                </div>
              </div>
            ) : null }
          </div>
        </Link>
      );
    }}
  </RoutesContext.Consumer>
);


export default withRouter(Media);

Media.propTypes = {
  image: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape(),
  ]).isRequired,
  match: PropTypes.shape().isRequired,
  slug: PropTypes.string.isRequired,
  text: PropTypes.shape().isRequired,
  cta: PropTypes.shape(),
};

Media.defaultProps = {
  cta: null,
};
