import React from 'react';
import PropTypes from 'prop-types';

import parse from 'html-react-parser';
import Button from './Button';
import CtaUnderline from './CtaUnderline';

import { LinkWrapper } from '../../../util/link';
import { useRoutes } from '../../../context/routesContext';
import translateObj from '../../../util/translateObj';

const Forum = ({
  title,
  intro,
  subtitle,
  text,
  labelJoin,
  labelJoinNow,
  terms,
  termsUrl,
  url,
  labelTerms,
}) => {
  const { language, path } = useRoutes();

  const contentJoinDiscord = (
    <div className="w-full bg-white flex flex-col justify-center items-center rounded-lg p-8">
      DISCORD ICON
      <Button text={translateObj(labelJoin, language)} link={url} color="black" className="mb-8 mt-4" />
      <div className="font-timesRegular text-12">
        <span className="mr-1">{translateObj(terms, language)}</span>
        <LinkWrapper url={termsUrl} path={path}>
          <span className="underline text-black">
            {translateObj(labelTerms, language)}
          </span>
        </LinkWrapper>
      </div>
    </div>
  );

  return (
    <div className="w-full pt-28 pb-32 sm:pt-10 sm:pb-24 md:pt-20 md:pb-24">
      <div className="px-1/12 w-full sm:px-0 md:px-0">
        <div className="w-full sm:mb-6 mb-12">
          <h3 className="w-1/2 lg:w-2/5 xl:w-1/4 text-50 sm:text-24 font-americaBlack leading-none">{translateObj(title, language)}</h3>
        </div>
        <div className="flex">
          <div className="w-1/2 sm:w-full md:w-full">
            <p className="font-timesRegular text-base">{translateObj(intro, language)}</p>
            <div className="hidden sm:block md:block my-10">
              {contentJoinDiscord}
            </div>
            <h4 className="font-americaMonoRegular text-base uppercase font-light my-8 sm:mt-0">{translateObj(subtitle, language)}</h4>
            <p className="font-timesRegular text-base">{translateObj(text, language)}</p>
            <CtaUnderline text={translateObj(labelJoinNow, language)} link={url} className="mt-8 sm:mt-6" />
          </div>
          <div className="sm:hidden md:hidden w-1/2 pl-1/12">
            {contentJoinDiscord}
          </div>
        </div>
      </div>
    </div>
  );
};

Forum.propTypes = {
  title: PropTypes.shape(),
  intro: PropTypes.shape(),
  subtitle: PropTypes.shape(),
  text: PropTypes.shape(),
  labelJoin: PropTypes.shape(),
  terms: PropTypes.shape(),
  termsUrl: PropTypes.string,
  url: PropTypes.string,
  labelTerms: PropTypes.shape(),
  labelJoinNow: PropTypes.shape(),
};

Forum.defaultProps = {
  title: {},
  intro: {},
  subtitle: {},
  text: {},
  labelJoin: {},
  terms: {},
  termsUrl: '',
  url: '',
  labelTerms: PropTypes.shape(),
  labelJoinNow: PropTypes.shape(),
};

export default Forum;
