import gql from 'graphql-tag';

import { clientWeb, persistor } from '../graphqlAPI';

const GET_SUB_EVENTS = gql`
  query getSubEvents($eventSlugName: String!, $from: Int, $to: Int, $roles:[String], $search: String, $onlyMySessions: Boolean, $sort: EventSort, $sortDirection: String ) {
    getSubEvents(eventSlugName: $eventSlugName, from: $from, to: $to, roles: $roles, search: $search, onlyMySessions: $onlyMySessions, sort: $sort, sortDirection: $sortDirection) {
      eventSlugName
      eventReadableName {
        en
        es
        ca
        pt
      }
      description {
        en
        es
        ca
        pt
      }
      pretitle {
        en
        es
        ca
        pt
      }
      subtitle {
        en
        es
        ca
        pt
      }
      image {
        en
        es
        ca
        pt
      }
      mentorsData {
        _id
        name
        lastname
        occupation
        business
      }
      artistSets {
        dateTimeStartHuman
        dateTimeStartReal
        dateTimeEndReal
        venue {
          venueSlugName
          venueReadableName {
            en
            es
            ca
            pt
          }
        }
        status
      }
      hasReminder
    }
  }
`;

const getSubEventsBySlugName = async ({ eventSlugName, from = 0, to, roles = ['pro-mentor-2022'], search, onlyMySessions = false, sort = 'DATE', sortDirection = 'asc' }) => {
  try {
    const result = await clientWeb
      .query({
        query: GET_SUB_EVENTS,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
        variables: { eventSlugName, from, to, roles, search, onlyMySessions, sort, sortDirection },
        fetchResults: true,
      })
      .then(queryResult => queryResult)
      .catch((queryError) => {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error: ${queryError}`);
        return null;
      });
    const {
      data: { getSubEvents },
    } = result;

    if (getSubEvents) {
      return getSubEvents;
    }
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: GraphQL Query Error: getTicketsByTicketSlugs`);
    return null;
  } catch (error) {
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: getTickets Error: ${error}`);
    return null;
  }
};

export default getSubEventsBySlugName;
