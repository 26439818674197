import React from 'react';
import PropTypes from 'prop-types';

const AppDownloadBadges = ({ name, badges, moduleTitle }) => (
  <div
    data-name="app-badges"
    id={name}
    key={name}
    className="mb-4 sm:mb-0 sm:w-full w-2/3 md:w-1/2 sm:order-2"
  >
    <p className="font-americaMonoRegular text-xs mb-0 sm:text-center sm:mt-4 text-left lg:pt-3">
      {moduleTitle}
    </p>
    <div className="sm:flex sm:justify-center flex items-center -ml-3 xl:pt-2">
      {badges.map(badge => (
        <a
          href={badge.link}
          key={badge.link}
          target="_blank"
          rel="noopener noreferrer"
          className="mr-4 md:mr-3 sm:mx-0"
        >
          <img
            src={badge.src}
            alt={badge.name}
            className={`${
              badge.name === 'Google Play'
                ? 'max-w-175 md:max-w-140 sm:max-w-145'
                : 'max-w-140 md:max-w-110 sm:max-w-110'
              }`}
          />
        </a>
      ))}
    </div>
  </div>
);

AppDownloadBadges.propTypes = {
  name: PropTypes.string.isRequired,
  moduleTitle: PropTypes.string,
  badges: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
};

AppDownloadBadges.defaultProps = {
  moduleTitle: 'PRIMAVERA SOUND APP',
};

export default AppDownloadBadges;
