import React from 'react';
import PropTypes from 'prop-types';

import withRadioPlayerContext from '../../../context/withRadioPlayerContext';
import withAllContexts from '../../../context/withAllContexts';
import useQueryString from '../../../hooks/useQueryString';

import HeaderComponent from './HeaderRedesignHeaderComponent';

const Header = ({ children, showVerifyAccountBanner, translation }) => {
  const hideHeader = useQueryString('showBodyOnly');

  if (hideHeader) return null;
  return (
    <HeaderComponent
      showVerifyAccountBanner={showVerifyAccountBanner}
      translation={translation}
    >
      {children}
    </HeaderComponent>
  );
};

Header.propTypes = {
  children: PropTypes.node.isRequired,
  showVerifyAccountBanner: PropTypes.bool,
  translation: PropTypes.shape(),
};

Header.defaultProps = {
  showVerifyAccountBanner: false,
  translation: null,
};

export default withAllContexts(withRadioPlayerContext(Header));
