import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import Tickets from '@primaveralabs/ui-tickets';
import { transformTabs } from '../../util/transformTickets';
import { useRoutes } from '../../context/routesContext';
import 'Primavera/ui-themes/dist/primavera/index.css';
import 'Primavera/ui-tickets/dist/primavera.css';
import LoadIcon from './LoadIcon';

function TicketsTabs({ tabs, match, hasPadding }) {
  const [tabsWithInfo, setTabsWithInfo] = useState([]);
  const [loading, setLoading] = useState(false);

  const routes = useRoutes(match);

  useEffect(() => {
    const fn = async () => {
      setLoading(true);
      const newTabs = await transformTabs(tabs, routes.language);
      setTabsWithInfo(newTabs);
      setLoading(false);
    };
    fn();
  }, []);

  return (
    <div className="full-width w-full">
      <div className={`w-full ${hasPadding ? 'px-7' : ''}`}>
        {loading ? (
          <div className="flex items-center justify-center h-full w-full my-12">
            <LoadIcon />
          </div>
        ) : (tabsWithInfo && tabsWithInfo.length > 0 && <Tickets categories={tabsWithInfo} />)}
      </div>
    </div>
  );
}

TicketsTabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  match: PropTypes.shape().isRequired,
  hasPadding: PropTypes.bool,
};

TicketsTabs.defaultProps = {
  hasPadding: false,
};

export default withRouter(TicketsTabs);
