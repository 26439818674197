import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useRoutes } from '../../../context/routesContext';
import { getCodeFromCountry } from '../../../util/getCountryNamesByCode';
import useResponsiveUtility from '../../../util/responsiveUtility';
import getUsersListCountries from '../../../api/graphql/queries/getUsersListCountries';

import { CURRENT_EDITION } from './Header/HeaderMusicMeets';

const SelectMultipleCountries = ({ placeholder, className, onChangeCallback, labelCleanSelection, labelNoResults, onlyFriends }) => {
  const { deviceScreen } = useResponsiveUtility();
  const { language } = useRoutes();
  const [options, setOptions] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [selection, setSelection] = useState([]);
  const [showOptions, setShowOptions] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState([]);

  const loadOptions = () => {
    getUsersListCountries([CURRENT_EDITION], onlyFriends, language).then((res) => {
      if (res) {
        const countries = res.map((i) => i.label);
        setOptions(countries);
      }
    }).catch((err) => console.log(err));
  };

  const handleSearchOption = (value) => {
    const filtered = options.filter((o) => o.toLowerCase().includes(value.toLowerCase()));
    setFilteredOptions(filtered);
  };

  const handleSelection = (item) => {
    const value = getCodeFromCountry(item, language);
    const selected = [...selection];
    const alreadySelected = selected.find((i) => i.name === item);

    if (typeof alreadySelected !== 'undefined') {
      const indexOf = selected.indexOf(alreadySelected);
      selected.splice(indexOf, 1);
    } else {
      selected.push({ name: item, value });
    }

    setSelection(selected);
  };

  const handleCleanSelection = () => {
    setSelection([]);
  };

  useEffect(() => {
    const values = selection.map((i) => i.value);
    if (onChangeCallback) {
      onChangeCallback(values);
    }
  }, [selection]);

  useEffect(() => {
    if (searchValue.length > 0 && !showOptions) {
      setShowOptions(true);
    }
    handleSearchOption(searchValue);
  }, [searchValue]);

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  useEffect(() => {
    loadOptions();
  }, [onlyFriends]);

  return (
    <div className={`${className || 'w-full'} relative`}>
      {selection.length > 0 && (
        <div className="-mt-3 absolute pin-t pin-r bg-pro-online h-5 w-5 rounded-full text-white text-xs flex items-center justify-center leading-none">
          {selection.length}
        </div>
      )}
      <div className="w-full rounded-full border border-black">
        <button
          type="button"
          className="flex items-center justify-between w-full px-4 py-3 outline-none bg-transparent text-12 cursor-pointer text-black"
          onClick={() => setShowOptions(!showOptions)}
          aria-label={placeholder}
        >
          <input
            className="font-americaMonoRegular w-full"
            placeholder={selection.length > 0
              ? `${selection.map((i) => i.name)
                .join(', ')
                .substring(0, deviceScreen === 'sm' ? 30 : 15)}${(deviceScreen !== 'sm' && selection.length > 1) || (deviceScreen === 'sm' && selection.length > 2) ? '...' : ''}`
              : placeholder}
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <div
            className="font-icon icon-arrow-up"
            style={{
              transform: !showOptions ? 'rotate(-180deg)' : '',
              transition: 'transform .1s ease-in',
            }}
          />
        </button>
        <div className="relative">
          {showOptions && (
            <div className="z-50 absolute w-full rounded-xl border border-black mt-2 px-4 py-3 bg-white max-h-260px overflow-x-hidden overflow-y-scroll">
              {filteredOptions.length === 0 ? (
                <div className="my-2 opacity-50 text-center">
                  {labelNoResults}
                </div>
              ) : (
                <>
                  {filteredOptions.map((item) => (
                    <div
                      key={item}
                      className="flex items-start my-2 cursor-pointer"
                      onClick={() => handleSelection(item)}
                    >
                      <div className="flex-none w-4 h-4 border border-black rounded-sm mr-2 flex items-center justify-center">
                        {selection.find((i) => i.name === item) && <div className="font-icon icon-checkbox" />}
                      </div>
                      <span>{item}</span>
                    </div>
                  ))}
                </>
              )}
            </div>
          )}
        </div>
      </div>
      {selection.length > 0 && (
        <div className="absolute sm:relative mt-1 sm:mt-2 px-4">
          <button type="button" onClick={() => handleCleanSelection()} className="text-xs text-pro-online underline">{labelCleanSelection}</button>
        </div>
      )}
    </div>
  );
};

SelectMultipleCountries.propTypes = {
  placeholder: PropTypes.string,
  className: PropTypes.string,
  onChangeCallback: PropTypes.func,
  labelCleanSelection: PropTypes.string,
  labelNoResults: PropTypes.string,
  onlyFriends: PropTypes.bool,
};

SelectMultipleCountries.defaultProps = {
  className: '',
  placeholder: '',
  onChangeCallback: null,
  labelCleanSelection: '',
  labelNoResults: '',
  onlyFriends: false,
};

export default SelectMultipleCountries;
