export const MAX_PARTICIPANTS = 10;

const validateReunionForm = (values) => {
  const { title, date, hour, location, online, description, participants } = values;

  const errors = {};
  if (!title) {
    errors.title = 'Required';
  }
  if (!date) {
    errors.date = 'Required';
  }
  if (!hour) {
    errors.hour = 'Required';
  }
  if (!location && !online) {
    errors.location = 'Required';
    errors.online = 'Required';
  }
  if (location && online) {
    errors.location = 'Choose one';
    errors.online = 'Choose one';
  }
  if (!description) {
    errors.description = 'Required';
  }
  if (participants.length === 0) {
    errors.participants = 'Required';
  }
  if (participants.length > MAX_PARTICIPANTS) {
    errors.participants = 'MAX_PARTICIPANTS_EXEEDED';
  }
  return errors;
};

export default validateReunionForm;
