import gql from 'graphql-tag';

import { clientWeb, persistor } from '../graphqlAPI';

const GET_MY_REUNIONS = gql`
  query Get($from: Int, $to: Int, $fetchDate: String, $sortBy: ReunionSort, $sortDirection: String, $pastReunions: Boolean, $cancelledReunions: Boolean, $filter: ReunionFilter, $venueFilter: String, $search: String) {
    getMyReunions(from: $from, to: $to, fetchDate: $fetchDate, sortBy: $sortBy, sortDirection: $sortDirection, pastReunions: $pastReunions, cancelledReunions: $cancelledReunions, filter: $filter, venueFilter: $venueFilter, search: $search) {
      _id
      title
      date
      participants {
        _id
        nickname
        lastname
        name
        imageUrl
        occupation
        company
        inputCountry
        areFriends
      }
      confirmedParticipants {
        _id
        name
        lastname
        company
      }
      rejectedParticipants {
        _id
      }
      hiddenParticipants {
        _id
      }
      createdByUser {
        _id
        name
        lastname
        company
      }
      location
      description
      online
      link
      createdBy
      roles
      cancelled
      confirmed
    }
  }
`;

const executeQuery = async ({ type = 'network', from = 0, to = 20, sortBy = 'date', sortDirection = 'asc', pastReunions = true, cancelledReunions = false, filter, venueFilter, search }) => {
  const result = await clientWeb.query({
    query: GET_MY_REUNIONS,
    variables: { fetchDate: new Date().getTime().toString(), from, to, sortBy, sortDirection, pastReunions, cancelledReunions, filter, venueFilter, search },
    fetchPolicy: type === 'network' ? 'network-only' : 'cache-only',
  })
    .then(queryResult => queryResult)
    .catch((queryError) => {
      console.log('queryError', queryError);
      const errorMessage = type === 'network'
        ? `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error: ${queryError}`
        : `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error after Cache Restore: ${queryError}`;
      console.log('\x1b[31m%s\x1b[0m', errorMessage);

      return null;
    });

  if (result.data && result.data.getMyReunions) {
    const { data: { getMyReunions: getMyReunionsData } } = result;
    return getMyReunionsData;
  }

  return [];
};

const getMyReunions = async (args) => {
  try {
    const JSONObject = await executeQuery({ ...args });

    if (persistor) {
      persistor.persist();
    }

    return JSONObject;
  } catch (jsonError) {
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: JSON Object Error: ${jsonError}`);

    if (persistor) {
      const persistorStatus = await persistor.restore().then(() => 'OK');
      console.log(`${new Date().toLocaleTimeString()} ~ Client: Apollo Cache Restored - ${persistorStatus}`);

      try {
        const JSONObject = await executeQuery('cache');

        return JSONObject;
      } catch (jsonErrorAfterCacheRestore) {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: JSON Object Error after Cache Restore, sending 404: ${jsonErrorAfterCacheRestore}`);
        return null;
      }
    }
    return null;
  }
};

export default getMyReunions;
