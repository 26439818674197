/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';

import moment from 'moment';

import Modal from 'react-responsive-modal';

import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';
import SellerButton from './Buttons/SellerButton';
import translateObj from '../../util/translateObj';
import { AmountWithCurrency } from './TicketConcert';

const multipleSellersText = {
  en: 'Choose your seller',
  es: 'Escoge tu punto de venta',
  pt: 'Escolher o ponto de venda',
};

const Sellers = ({ sellers }) => (sellers.length ? (
  sellers.map(seller => seller && <SellerButton active={seller.active} margin="max-w-xssm my-1 text-center opacity-100" key={seller.sellerTicketUrl} text={{ en: seller.sellerName }} url={seller.sellerTicketUrl} category="black" />)
) : (
  <SellerButton margin="my-1 sm:w-auto text-center" soldOut text={{ en: 'Coming soon', es: 'Próximamente', ca: 'Pròximament' }} url="/" />
));


const TicketMain = ({
  ticketSlugName, match, showFee, ticketName, ticketReadableName, description, image, price, sellers, beginDateTime, endDateTime, padding, subtitle, showSoldOut, show, amount, currency, priceString, titleClickable, titleNotClickable, category,
}) => {
  const [open, setOpen] = useState(false);

  const onOpenModal = () => {
    setOpen(true);
  };

  const onCloseModal = () => {
    setOpen(false);
  };


  const modalStyles = {
    modal: {
      background: 'rgba(0, 0, 0, 0)',
      boxShadow: 'none',
      width: '100vw',
      // padding: '2rem',
    },
    overlay: {
      // background: 'rgba(0, 0, 0, 0.5)',

    },
  };


  return (
    <RoutesContext.Consumer>
      {({ language: contextLanguage, enteredFrom }) => {
        const localization = getLocalization(contextLanguage, enteredFrom, match);
        // const priceText = {
        //   en: 'Price',
        //   es: 'Precio',
        //   ca: 'Preu',
        //   pt: 'Preco',
        // };
        // const soldOutText = { en: 'Sold out', es: 'Agotado', ca: 'Esgotat' };
        // const defaultAvailableText = { en: 'Sold out', es: 'Agotado', ca: 'Esgotat' };
        moment.locale(localization.language);
        return (
          <div className={`px-5 lg:px-9 ${show ? 'bg-white' : 'bg-red-light'} rounded w-ticket-lg sm:w-ticket-sm md:w-ticket-md border-box`}>
            {
              sellers.length === 1 && !showSoldOut ? (
                <a
                  href={sellers[0].sellerTicketUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-black cursor-pointer"
                >
                  <div className="flex pt-6 min-h-ticket-lg sm:min-h-ticket-sm md:min-h-ticket-md">
                    <div className="w-1/5 pt-3 sm:pt-1 md:pt-1">
                      <span className="font-icon icon-ticket text-5xl md:text-4xl sm:text-4xl -ml-2 md:-ml-2 sm:-ml-1" />
                    </div>
                    <div className="w-4/5 pt-3 sm:pt-1 md:pt-1 flex flex-col justify-between">
                      <div className="">
                        {ticketReadableName && <h3 className="font-americaBlack text-2xl sm:text-lg md:text-lg pb-5">{translateObj(ticketReadableName, localization.language)}</h3>}
                        <div className="font-americaMonoRegular sm:text-xs md:text-xs text-sm pb-5 uppercase">{subtitle ? translateObj(subtitle, localization.language) : null}</div>
                        <div className="border-t border-black py-5">
                          <p className="font-timesRegular text-sm sm:text-xs md:text-xs mb-5 leading-normal whitespace-pre-wrap">{translateObj(description, localization.language).substring(0, 243)}</p>
                          {/* {image.en ? <img src={translateObj(image, localization.language)} alt={ticketName} /> : <img src="https://assets.primaverasound.com/web/assets/white_card.png" alt={ticketName} />} */}
                        </div>
                      </div>
                      <div className="pb-6">
                        <p className="font-americaMonoRegular sm:text-xs md:text-xs text-sm mt-2 mb-5 border-t border-black pt-5 uppercase">{translateObj(priceString, localization.language)}</p>
                        <p className={`text-2xl font-americaBlack pb-5 ${(amount === 0 || amount === null) && 'invisible'}`}>
                          <AmountWithCurrency number={amount} curr={currency} />
                          {/* {price.eu === 0 || price.eu === null ? 'X' : price.eu} */}
                          {showFee && <span className="text-xs">{amount !== 0 ? '+ Fees' : ' '}</span>}
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              ) : (
                <div tabIndex="0" role="link" className="flex pt-6 min-h-ticket-lg sm:min-h-ticket-sm md:min-h-ticket-md" onClick={() => { if (sellers.length > 1) onOpenModal(); }}>
                  <div className="w-1/5 pt-3 sm:pt-1 md:pt-1">
                    <span className="font-icon icon-ticket text-5xl md:text-4xl sm:text-4xl -ml-2 md:-ml-2 sm:-ml-1" />
                  </div>
                  <div className="w-4/5 pt-3 sm:pt-1 md:pt-1 flex flex-col justify-between">
                    <div className="">
                      {ticketReadableName && <h3 className="font-americaBlack text-2xl sm:text-lg md:text-lg pb-5">{translateObj(ticketReadableName, localization.language)}</h3>}
                      <div className="font-americaMonoRegular sm:text-xs md:text-xs text-sm pb-5 uppercase">{subtitle ? translateObj(subtitle, localization.language) : null}</div>
                      <div className="border-t border-black py-5">
                        <p className="font-timesRegular text-sm sm:text-xs md:text-xs mb-5 leading-normal">{translateObj(description, localization.language).substring(0, 243)}</p>
                        {/* {image.en ? <img src={translateObj(image, localization.language)} alt={ticketName} /> : <img src="https://assets.primaverasound.com/web/assets/white_card.png" alt={ticketName} />} */}
                      </div>
                    </div>
                    <div className="pb-6">
                      <p className="font-americaMonoRegular sm:text-xs md:text-xs text-sm mt-2 mb-5 border-t border-black pt-5 uppercase">{translateObj(priceString, localization.language)}</p>
                      <p className={`text-2xl font-americaBlack pb-5 ${(amount === 0 || amount === null) && 'invisible'}`}>
                        <AmountWithCurrency number={amount} curr={currency} />
                        {/* {price.eu === 0 || price.eu === null ? 'X' : price.eu} */}
                        {showFee && <span className="text-xs">{amount !== 0 ? '+ Fees' : ' '}</span>}
                      </p>
                    </div>
                  </div>
                </div>
              )
            }

            <div className="border-t border-white-dark py-2 sm:text-center sm:px-0">
              {showSoldOut || sellers.length === 0 || (sellers.length && !sellers[0].sellerTicketUrl) ? (
                <SellerButton margin="my-1 text-center bg-gray200 border-none cursor-not-allowed" soldOut text={titleNotClickable} url="/" />
              ) : (
                <>
                  {sellers.length > 1 ? (
                    <div>
                      <button type="button" className={`text-white flex flex-col justify-center items-center w-full flex rounded-full uppercase text-base sm:text-12 md:text-12 text-center font-americaMonoBold flex-col justify-center block my-1 text-center xl:h-12 lg:h-12 h-38px bg-${category || 'porto'}`} onClick={onOpenModal}>{translateObj(titleClickable, localization.language)}</button>
                      <Modal open={open} onClose={onCloseModal} center styles={modalStyles} closeOnOverlayClick showCloseIcon={false}>
                        <div className="flex justify-center flex-col items-center">
                          <div className="mb-4 ">
                            <span className="text-center rounded-full uppercase text-base sm:text-12 md:text-12 text-center text-white font-americaMonoBold">{translateObj(multipleSellersText, localization.language)}</span>
                          </div>
                          <Sellers sellers={sellers} />
                        </div>

                      </Modal>
                    </div>
                  )
                    : <SellerButton active={sellers[0].active} margin="my-1 text-center" key={sellers[0].sellerTicketUrl} text={titleClickable} url={sellers[0].sellerTicketUrl} category={category} />}
                </>
              )}
            </div>
          </div>
        );
      }}
    </RoutesContext.Consumer>
  );
};

TicketMain.propTypes = {
  padding: PropTypes.bool,
  show: PropTypes.bool,
  image: PropTypes.shape({}),
  category: PropTypes.string,
  subtitle: PropTypes.string,
};

TicketMain.defaultProps = {
  image: null,
  padding: true,
  show: true,
  category: 'black',
  subtitle: '',
};

export default withRouter(TicketMain);
