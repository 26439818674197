import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import RoutesContext from '../../../context/routesContext';

import getLocalization from '../../../util/localization';
import translateObj from '../../../util/translateObj';
import Link from '../../../util/link';

const HeaderRedesignButton = ({ url, text }) => (
  <div className="w-full tablet:w-auto">
    <RoutesContext.Consumer>
      {({ language, enteredFrom }) => {
        const localization = getLocalization(language, enteredFrom);
        return (
          <Link to={url} localizationpath={localization.path} aria-label={translateObj(text, localization.language)} role="button">
            <span className="px-3 py-1 flex items-center justify-center rounded-full text-sm font-americaMonoMedium uppercase text-black border border-black">
              {translateObj(text, localization.language)}
            </span>
          </Link>
        );
      }}
    </RoutesContext.Consumer>
  </div>
);

HeaderRedesignButton.propTypes = {
  url: PropTypes.string.isRequired,
  text: PropTypes.shape().isRequired,
};

export default withRouter(HeaderRedesignButton);
