import React from 'react';
import PropTypes from 'prop-types';

const Spacer = ({ className, margin }) => {
  // NOTE: hotfix to chage the bg, remove when the entire body changes bg color

  let style = {};
  if (margin !== null) {
    style = { marginTop: margin };
  }
  return (
    <div className={`w-full ${className ?? ''}`} style={style}>
    </div>
  );
};

Spacer.propTypes = {
  className: PropTypes.string,
  margin: PropTypes.string,
};

Spacer.defaultProps = {
  className: null,
  margin: null,
};

export default Spacer;
