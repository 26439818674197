import React from 'react';

let updateLineup;
let clearLineup;

const lineup = {
  lineupData: {},
  updateLineup: lineupData => updateLineup(lineupData),
  clearLineup: () => clearLineup(),
};

updateLineup = (lineupData) => {
  lineup.lineupData = lineupData;
};

clearLineup = () => {
  lineup.lineupData = null;
};

const LineupContext = React.createContext(lineup);

export default LineupContext;
