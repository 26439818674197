/* eslint-disable camelcase */
import React, { useRef, useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import FilePlayer from 'react-player';

const StreamingVideoPlayerOneForAllPlayerOriginal = ({
  videoObject,
  shouldSmallVideoStart,
  handleSwitchingVideos,
  handlePlayingSmallScreens,
  shouldHide,
  length,
  // muted,
  index,
}) => {
  const { url, active, id } = videoObject;
  const [availableBitRates, setAvailableBitRates] = useState([]);
  const playerRef = useRef(null);

  const identifyVideoQualities = (dash) => {
    if (dash) {
      const currTrack = dash.getCurrentTrackFor('video');
      if (currTrack) {
        const currBitRates = currTrack.bitrateList;
        setAvailableBitRates(currBitRates || []);
      }
      console.log('!!!: handleVideoQualities -> currTrack', currTrack);
    }
  };

  const handleQualityChange = (index = 0) => {
    const dash = playerRef.current.getInternalPlayer('dash');
    if (dash) {
      dash.setQualityFor('video', index);
      dash.setAutoSwitchQualityFor('video', false);
      console.log('!!!: checkCurrentVideoQuality -> dash curr id and quality1', active, id, dash.getQualityFor('video'));
    }
  };

  const handleQualityAuto = () => {
    const dash = playerRef.current.getInternalPlayer('dash');
    if (dash) {
      dash.setAutoSwitchQualityFor('video', true);
    }
  };

  // ? validate if onReady method works in safari  and if a different alternative should be considered
  const onReady = () => {
    const dash = playerRef.current.getInternalPlayer('dash');
    if (!active) {
      dash.setInitialBitrateFor('video', 0);
      dash.setQualityFor('video', 0);
      dash.setAutoSwitchQualityFor('video', false);
    } else {
      dash.setInitialBitrateFor('video', 0);
      dash.setQualityFor('video', 0);
    }

    identifyVideoQualities(dash);

    // console.log('!!!: onReady -> dash curr track, id', active, id, dash.getCurrentTrackFor('video'));
    // console.log('!!!: onReady -> dash curr quality1, id', active, id, dash.getQualityFor('video'));
  };

  useEffect(() => {
    const dash = playerRef.current.getInternalPlayer('dash');
    if (!active) {
      if (dash) {
        dash.setQualityFor('video', 0);
        dash.setAutoSwitchQualityFor('video', false);
      }
    } else {
      if (dash) dash.setAutoSwitchQualityFor('video', true);
    }
  }, [active]);

  // const checkCurrentVideoQuality = () => {
  //   const dash = playerRef.current.getInternalPlayer('dash');
  //   console.log('!!!: checkCurrentVideoQuality -> dash curr id and track', active, id, dash.getCurrentTrackFor('video'));
  //   console.log('!!!: checkCurrentVideoQuality -> dash curr id and quality1', active, id, dash.getQualityFor('video'));
  // };

  return (
    <div
      className={` cursor-pointer mx-2 absolute pin-t ${active ? 'w-full z-0' : `z-5 h-24 w-1/${length - 1}`}
      ${(!active && index === 1) ? 'pin-r' : 'pin-l'}
        ${shouldHide && 'hidden'} ${id}`}
      onClick={() => {
        if (!active) handleSwitchingVideos(id);
      }}
    >
      <FilePlayer
        ref={playerRef}
        url={url}
        controls={active}
        onStart={() => { if (active) handlePlayingSmallScreens(true, id); }}
        onPlay={() => { if (active) handlePlayingSmallScreens(true, id); }}
        onPause={() => { if (active) handlePlayingSmallScreens(false, id); }}
        onReady={onReady}
        playing={shouldSmallVideoStart}
        height="100%"
        width="100%"
        muted={!active}
        config={{
          file: {
            forceDASH: true,
          },
        }}
      />
      {/* <button onClick={checkCurrentVideoQuality} className="z-top bg-pink w-full text-black py-4 px-4">CHECK VIDEO QUALITY</button> */}

      {active && (
      <div>
        change video quality
        <>
          {availableBitRates.length > 0 && (
          <>
            {availableBitRates.map((bitRate, i) => (
              <button key={bitRate.height + i} className="z-top bg-pink text-black py-4 px-4" onClick={() => handleQualityChange(i)}>{bitRate.height}</button>
            ))}
          </>
          )}
        </>
        <button className="z-top bg-pink text-black py-4 px-4" onClick={handleQualityAuto}>AUTO</button>
;
      </div>
      )}


    </div>
  );
};

StreamingVideoPlayerOneForAllPlayerOriginal.propTypes = {
  videoObject: PropTypes.shape().isRequired,
  shouldHide: PropTypes.bool,
  index: PropTypes.number,
  length: PropTypes.number,
  handleSwitchingVideos: PropTypes.func.isRequired,
  handlePlayingSmallScreens: PropTypes.func.isRequired,
  // muted: PropTypes.bool,
  shouldSmallVideoStart: PropTypes.bool,
};

StreamingVideoPlayerOneForAllPlayerOriginal.defaultProps = {
  shouldHide: false,
  // muted: true,
  index: 1,
  length: 2,
  shouldSmallVideoStart: null,
  // videoObject: {
  //   urlBig: '',
  //   url: '',
  //   id: '1',
  // },
};

export default StreamingVideoPlayerOneForAllPlayerOriginal;
