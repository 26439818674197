import React, { Component } from 'react';
import ParagraphLine from './Text/ParagraphLine';
import Ticket from './Ticket';

import SocialLogosContainer from './LogosContainer/SocialLogosContainer';
import ComponentIterator from '../../containers/ComponentIterator';

const ArtistPageContainer = ({
  tickets,
  title,
  components,
}) => (
  <div>
    <div className="mt-8 font text-americaMonoRegular flex justify-end items-center">
        COMPARTIR
      <SocialLogosContainer classNames="w-80 flex justify-around" />
    </div>
    <div className=" mt-4">
      <div className="p-8 lg:absolute xl:absolute border-t-2 border-black ">
        <ParagraphLine text={{ es: 'PRÓXIMAS FECHAS', en: 'PRÓXIMAS FECHAS' }} />
        <div className="sm:flex md:flex justify-between">
          {tickets.map(ticket => <Ticket {...ticket} />)}
        </div>
        <div style={{ marginRight: 33 }}>
          <iframe
            src="https://embed.music.apple.com/es/album/pristine/1355422978?i=1355422986&app=music&at=1010ltPc"
            width="100%"
            height="140px"
            frameBorder="0"
            allowTransparency="true"
            title="artist"
          />
        </div>
      </div>
      <div className="lg:ml-96 xl:ml-96">
        <ComponentIterator components={components} />
      </div>

    </div>

  </div>
);

export default ArtistPageContainer;
