import React from 'react';
import { withRouter } from 'react-router-dom';
import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';
import SearchNumberOfResultsFound from './SearchNumberOfResultsFound';
import SearchBasicLayout from './SearchBasicLayout';
import SearchRepeat from './SearchRepeat';
import getSearchPosts from '../../api/graphql/queries/searchPosts';
import SearchOverlay from './SearchOverlay';

class SearchPage extends React.Component {
  state = {
    query: '',
    numberOfResults: 0,
    results: [],
    isLoading: true,
    isOpenOverlay: false,
  }

  _isMounted = false;

  componentDidMount() {
    const query = this.props.location.search;
    this._isMounted = true;
    if (query) {
      const queryArr = this.getQueryVariable(query);
      this.doSearch(queryArr);
    } else {
      this.setState({
        isOpenOverlay: true,
        results: [],
      });
    }
  }

  componentDidUpdate(prevProps) {
    const query = this.props.location.search;
    const prevQuery = prevProps.location.search;
    const prevQueryString = this.getQueryVariable(prevQuery);
    const queryString = this.getQueryVariable(query);
    if (prevQueryString[0] !== queryString[0]) {
      if (!query) {
        this.setState({
          isOpenOverlay: true,
          isLoading: true,
        });
      } else {
        this.doSearch(queryString);
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.setState({
      isLoading: false,
      isOpenOverlay: false,
    });
  }

  doSearch = (queryArr) => {
    this.setState({ query: queryArr[0] });
    getSearchPosts(queryArr[0], queryArr[1])
      .then((results) => {
        if (results) {
          if (this._isMounted) {
            this.setState({
              results,
              numberOfResults: results.length,
              isLoading: false,
            });
          }
        }
      });
  }

  handleEnter = (query, lang) => {
    this.props.history.push(`/${lang}/search?query=${query}`);
  }

  handleClose = () => {
    const { history: { goBack } } = this.props;
    goBack();
  }

  getQueryVariable = (query) => {
    const vars = query.split('&');
    const queryvars = [];
    for (let i = 0; i < vars.length; i++) {
      const index = vars[i].indexOf('=');
      const item = vars[i].substring(index + 1);
      queryvars.push(item);
    }
    if (queryvars.length) {
      return queryvars;
    }
    return false;
  }

  render() {
    const {
      query, numberOfResults, results, isLoading, isOpenOverlay,
    } = this.state;
    const { match } = this.props;
    return (
      <RoutesContext.Consumer>
        {({ language: contextLanguage, enteredFrom }) => {
          const localization = getLocalization(contextLanguage, enteredFrom, match);
          return (
            <>
              {!isLoading && (
              <div className="w-full">
                {query ? <SearchNumberOfResultsFound numberOfResults={numberOfResults} searchQuery={query} /> : null}
                <SearchBasicLayout left={[]} right={results} />
                <SearchRepeat handleEnter={this.handleEnter} />
              </div>
              )}
              {isOpenOverlay && isLoading && this._isMounted
                ? <SearchOverlay closeCallback={this.handleClose} searchCallback={this.handleEnter} />
                : <div />
              }
            </>
          );
        }}
      </RoutesContext.Consumer>
    );
  }
}

SearchPage.propTypes = {

};

export default withRouter(SearchPage);
