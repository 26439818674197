const createCustomer = (paymentMethod, email, productSlugName) => window
  .fetch(`${process.env.PS_REST_SERVER}/payments/create-customer`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('auth-token')}`,
    },
    body: JSON.stringify({
      email,
      payment_method: paymentMethod,
      productSlugName,
    }),
  })
  .then((res) => {
    if (res.status === 200) {
      return res.json();
    }
    return null;
  })
  .then((data) => {
    if (!data) {
      console.log('API error:', { data });
      throw new Error('Create Customer API Error');
    } else if (data.error) {
      console.log('API error:', { data });
      throw new Error(`Error: ${data.error.message}`);
    } else {
      return data;
    }
  });

const getOrCreateCustomer = email => window
  .fetch(`${process.env.PS_REST_SERVER}/payments/get-create-customer`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('auth-token')}`,
    },
    body: JSON.stringify({
      email,
    }),
  })
  .then((res) => {
    if (res.status === 200) {
      return res.json();
    }
    return null;
  })
  .then((data) => {
    if (!data) {
      console.log('API error:', { data });
      throw new Error('Get Create Customer API Error');
    } else if (data.error) {
      console.log('API error:', { data });
      throw new Error(`Error: ${data.error.message}`);
    } else {
      return data;
    }
  });

const getCustomerSetupIntent = customerId => window
  .fetch(`${process.env.PS_REST_SERVER}/payments/get-customer-setup-intent`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('auth-token')}`,
    },
    body: JSON.stringify({
      customerId,
    }),
  })
  .then((res) => {
    if (res.status === 200) {
      return res.json();
    }
    return null;
  })
  .then((data) => {
    if (!data) {
      console.log('API error:', { data });
      throw new Error('Get Customer Payments API Error');
    } else if (data.error) {
      console.log('API error:', { data });
      throw new Error(`Error: ${data.error.message}`);
    } else {
      return data;
    }
  });

const getCustomerPaymentMethods = customerId => window
  .fetch(`${process.env.PS_REST_SERVER}/payments/get-customer-payment-methods`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('auth-token')}`,
    },
    body: JSON.stringify({
      customerId,
    }),
  })
  .then((res) => {
    if (res.status === 200) {
      return res.json();
    }
    return null;
  })
  .then((data) => {
    if (!data) {
      console.log('API error:', { data });
      throw new Error('Get Customer Payment Methods API Error');
    } else if (data.error) {
      console.log('API error:', { data });
      throw new Error(`Error: ${data.error.message}`);
    } else {
      return data;
    }
  });

const createSetupIntent = options => window
  .fetch(`${process.env.PS_REST_SERVER}/payments/create-setup-intent`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('auth-token')}`,
    },
    body: JSON.stringify(options),
  })
  .then((res) => {
    if (res.status === 200) {
      return res.json();
    }
    return null;
  })
  .then((data) => {
    if (!data) {
      console.log('API error:', { data });
      throw new Error('SetupIntent API Error');
    } else if (data.error) {
      console.log('API error:', { data });
      throw new Error(`Error: ${data.error.message}`);
    } else {
      return data;
    }
  });

// Not used!
const createPaymentIntent = options => window
  .fetch(`${process.env.PS_REST_SERVER}/payments/create-payment-intent`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('auth-token')}`,
    },
    body: JSON.stringify(options),
  })
  .then((res) => {
    if (res.status === 200) {
      return res.json();
    }
    return null;
  })
  .then((data) => {
    if (!data) {
      console.log('API error:', { data });
      throw new Error('PaymentIntent API Error');
    } else if (data.error) {
      console.log('API error:', { data });
      throw new Error(`Error: ${data.error.message}`);
    } else {
      return data.client_secret;
    }
  });

const createPaymentIntentWithStoredCard = options => window
  .fetch(`${process.env.PS_REST_SERVER}/payments/create-payment-intent-with-stored-card`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('auth-token')}`,
    },
    body: JSON.stringify(options),
  })
  .then((res) => {
    if (res.status === 200) {
      return res.json();
    }
    return null;
  })
  .then((data) => {
    if (!data) {
      console.log('API error:', { data });
      throw new Error('PaymentIntentWithStoredCard API Error');
    } else if (data.error) {
      console.log('API error:', { data });
      throw new Error(`Error: ${data.error.message}`);
    } else {
      return data;
    }
  });

const getProductDetails = productSlugName => window
  .fetch(`${process.env.PS_REST_SERVER}/payments/product-details?productSlugName=${productSlugName}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
  .then((res) => {
    if (res.status === 200) {
      return res.json();
    }
    return null;
  })
  .then((data) => {
    if (!data || data.error) {
      console.log('API error:', { data });
      throw Error('API Error: Could not get product details');
    } else {
      return data;
    }
  });

const getPublicStripeKey = options => window
  .fetch('/payments/public-key', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
  .then((res) => {
    if (res.status === 200) {
      return res.json();
    }
    return null;
  })
  .then((data) => {
    if (!data || data.error) {
      console.log('API error:', { data });
      throw Error('API Error getting public key');
    } else {
      return data.publicKey;
    }
  });

const api = {
  createCustomer,
  getOrCreateCustomer,
  getCustomerSetupIntent,
  getCustomerPaymentMethods,
  createSetupIntent,
  createPaymentIntent,
  createPaymentIntentWithStoredCard,
  getPublicStripeKey,
  getProductDetails,
};

export default api;
