import React, { useState, useEffect } from 'react';
import OnDemandModalNotLogged from './OnDemandModalNotLogged';
import OnDemandModalIsLogged from './OnDemandModalIsLogged';
import LoadIcon from '../../LoadIcon';

const OnDemandModalScreen = ({
  userName, userLastName, userEmail, token, loadTokenForLS, isUserVerified, language, translations, image, closeModal, postVideoUrl, otherOptionsButtonUrl, userTrialWasUsed, userSubscriptionDetails,
}) => {
  const [screenStatus, setScreenStatus] = useState('loading');

  useEffect(() => {
    if (!userEmail) {
      setScreenStatus('socialLogin');
    } else {
      setScreenStatus('logged');
    }
  }, [userEmail]);

  const handleOnDemandScreenStatus = (screenStatusFromFn, email = '') => {
    setScreenStatus(screenStatusFromFn);
  };
  const renderLogic = () => {
    switch (screenStatus) {
      case 'loading':
        return (
          <div
            className="bg-gray200 p-8 sm:p-2 w-full"
          >
            <LoadIcon color="black" type="ThreeDots" />
          </div>
        );
      case 'socialLogin':
        return (
          <OnDemandModalNotLogged language={language} loadTokenForLS={loadTokenForLS} handleOnDemandScreenStatus={handleOnDemandScreenStatus} translations={translations} />
        );
      case 'logged':
        return (
          <OnDemandModalIsLogged userEmail={userEmail} language={language} userSubscriptionDetails={userSubscriptionDetails} loadTokenForLS={loadTokenForLS} userTrialWasUsed={userTrialWasUsed} handleOnDemandScreenStatus={handleOnDemandScreenStatus} translations={translations} image={image} closeModal={closeModal} postVideoUrl={postVideoUrl} otherOptionsButtonUrl={otherOptionsButtonUrl} />
        );

      default:
        return (
          <div
            className="bg-gray200 p-8 sm:p-2 w-full"
          >
            <div>please review...</div>
          </div>
        );
    }
  };
  return (
    <div className="bg-white-dark">
      {renderLogic()}
    </div>
  );
};

export default OnDemandModalScreen;
