import gql from 'graphql-tag';

import { clientWeb, persistor } from '../graphqlAPI';

const GET_NEWSLETTER_SETTINGS_QUERY = gql`
  query Get($email: String!) {
    getNewsletterSettings(email: $email) {
      message
      result {
        lastname
        firstname
        email
        language
        custom_fields {
          name
          value
        }
      }
    }
  }
`;

const getNewsletterSettings = async (email) => {
  try {
    const result = await clientWeb
      .query({
        query: GET_NEWSLETTER_SETTINGS_QUERY,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
        variables: {
          email,
          fetchDate: new Date().getTime().toString(),
        },
        fetchResults: true,
        // pollInterva: 300000,
      })
      .then(queryResult => queryResult)
      .catch((queryError) => {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error: ${queryError}`);
        return null;
      });
    const {
      data: { getNewsletterSettings: getNewsletterSettingsData },
    } = result;

    if (getNewsletterSettingsData) {
      return getNewsletterSettingsData;
    }
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: GraphQL Query Error: getNewsLetterSettings`);
    return null;
  } catch (error) {
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: getNewsLetterSettings Error: ${error}`);
    return null;
  }
};

export default getNewsletterSettings;
