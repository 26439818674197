import React, { useState } from 'react';
import PropTypes from 'prop-types';

import CtaUnderline from './CtaUnderline';

import { useRoutes } from '../../../context/routesContext';
import translateObj from '../../../util/translateObj';
import useResponsiveUtility from '../../../util/responsiveUtility';


const CollapsibleSection = ({
  title,
  children,
  className,
  collapsible,
  titleBig,
  rightButtonLabel,
  rightButtonCallback,
  paddingBottom,
}) => {
  const { language } = useRoutes();
  const { deviceScreen } = useResponsiveUtility();
  const [open, setOpen] = useState(true);

  const isCollapsed = deviceScreen === 'sm' && collapsible && !open;
  const hasRightButton = rightButtonLabel && rightButtonCallback;

  return (
    <div className={className}>
      <div
        onClick={collapsible ? () => setOpen(!open) : null}
        className={`border-black md:border-b lg:border-b xl:border-b flex flex-row justify-start items-center mb-4
        ${titleBig ? 'pt-6 pb-4 sm:py-6' : 'py-6'}
        ${collapsible ? 'sm:justify-between sm:pointer-events-auto sm:cursor-pointer sm:border-t sm:mb-0' : 'sm:border-b sm:mb-2'}
        ${hasRightButton ? 'justify-between pointer-events-auto' : 'pointer-events-none'}`}
      >
        <div
          className={`font-americaMonoRegular ${titleBig ? 'text-30' : 'text-base'} sm:text-14 uppercase tracking-px sm:tracking-wide text-black`}
        >
          {translateObj(title, language)}
        </div>
        {collapsible && (
        <div
          className="hidden sm:block font-icon icon-arrow-up text-black"
          style={{
            transform: !open ? 'rotate(-180deg)' : '',
            transition: 'transform .1s ease-in',
          }}
        />
        )}
        {hasRightButton && (
          <CtaUnderline text={translateObj(rightButtonLabel, language)} onClickCallback={rightButtonCallback} className="sm:hidden" />
        )}
      </div>
      <div
        style={{
          height: isCollapsed ? '0' : 'auto',
          pointerEvents: isCollapsed ? 'none' : 'auto',
        }}
      >
        <div
          className={paddingBottom}
          style={{
            display: isCollapsed ? 'none' : 'block',
          }}
        >
          {hasRightButton && (
          <CtaUnderline text={translateObj(rightButtonLabel, language)} onClickCallback={rightButtonCallback} className="mb-6 hidden sm:block" />
          )}
          {children}
        </div>
      </div>
    </div>
  );
};

CollapsibleSection.propTypes = {
  title: PropTypes.shape(),
  children: PropTypes.node,
  className: PropTypes.string,
  collapsible: PropTypes.bool,
  titleBig: PropTypes.bool,
  rightButtonLabel: PropTypes.shape(),
  rightButtonCallback: PropTypes.func,
  paddingBottom: PropTypes.string,
};

CollapsibleSection.defaultProps = {
  title: {},
  children: null,
  className: 'w-full',
  collapsible: true,
  titleBig: false,
  rightButtonLabel: null,
  rightButtonCallback: null,
  paddingBottom: 'pb-8',
};

export default CollapsibleSection;
