import gql from 'graphql-tag';

import { clientRadio, persistor } from '../graphqlAPI';

const GET_RADIO_SHOWS = gql`
query radioProgramsAll($slug: String, $channel: Int, $tags: [String!], $tagsAND: [String!], $highlight: Boolean, $limit: Int, $search: String, $offset: Int, $type: String) {
  radioProgramsAll(slug: $slug, channel: $channel, tags: $tags, tagsAND: $tagsAND, highlight: $highlight, limit: $limit, search: $search, offset: $offset, type: $type) {
      id
      slug
      parentSlug
      channel
      duration
      image
      youtube
      spotify
      mixcloud
      dateAir
      title_multilang {
        ca
        en
        es
      }
      text_multilang {
        ca
        en
        es
      }
      subtitle_multilang {
        ca
        en
        es
      }
      tags_multilang {
        ca
        en
        es
      }
      categories_multilang {
        ca
        en
        es
      }
      program_title_multilang {
        ca
        en
        es
      }
      status
      isPlaylist
      lang
      appleCategory
      appleSubcategory
      tags_array_multilang {
        ca
        es
        en
      }
    }
  }
`;

const executeQuery = async ({
  slug,
  tags,
  tagsAND,
  search,
  channel,
  highlight,
  limit,
  offset,
  type,
}, typeOfFetch = 'network') => {
  if (!process.env.RADIO_GRAPHQL_SERVER_URL) {
    return [];
  }

  const result = await clientRadio
    .query({
      query: GET_RADIO_SHOWS,
      variables: {
        slug,
        tags,
        tagsAND,
        search,
        channel,
        highlight,
        limit,
        offset,
        type,
      },
      errorPolicy: 'all',
      fetchResults: true,
      fetchPolicy: typeOfFetch === 'network' ? 'network-only' : 'cache-only',
      // pollInterva: 300000,
    })
    .then(queryResult => queryResult)
    .catch((queryError) => {
      const errorMessage = typeOfFetch === 'network'
        ? `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error: ${queryError}`
        : `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error after Cache Restore: ${queryError}`;
      console.log('\x1b[31m%s\x1b[0m', errorMessage);

      return null;
    });

  const { data: { radioProgramsAll: radioProgramsAllData } } = result;

  if (radioProgramsAllData) {
    return radioProgramsAllData;
  }
  console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: GraphQL Query Error: radioProgramsAllData = null`);
  return null;
};

const getRadioShows = async (vars) => {
  try {
    const radioProgramsAllData = await executeQuery(vars);

    if (persistor) {
      persistor.persist();
    }

    return radioProgramsAllData;
  } catch (jsonError) {
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: JSON Object Error: ${jsonError}`);

    if (persistor) {
      const persistorStatus = await persistor.restore().then(() => 'OK');
      console.log(`${new Date().toLocaleTimeString()} ~ Client: Apollo Cache Restored - ${persistorStatus}`);

      try {
        const radioProgramsAllData = await executeQuery(vars, 'cache');

        return radioProgramsAllData;
      } catch (jsonErrorAfterCacheRestore) {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: JSON Object Error after Cache Restore, sending 404: ${jsonErrorAfterCacheRestore}`);
        return null;
      }
    }
    return null;
  }
};

export default getRadioShows;
