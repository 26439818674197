import React, { Component } from 'react';
import { withGoogleMap, withScriptjs, GoogleMap } from 'react-google-maps';
import PropTypes from 'prop-types';
import { makePromise } from 'apollo-link';
import map from '../../util/map';
import MapMarkers from './MapMakers';

const InitMap = withScriptjs(
  withGoogleMap(({ markers, children }) => (
    <GoogleMap
      // eslint-disable-next-line no-use-before-define
      ref={mapObj => setZoomAndCenter(markers, mapObj)}
      options={{
        styles: map.style,
        disableDefaultUI: true,
      }}
      defaultZoom={map.zoom}
      defaultCenter={markers.length && markers[0].position}
    >
      {children}
    </GoogleMap>
  )),
);

const setZoomAndCenter = (markers, mapObj) => {
  if (mapObj && markers.length > 1) {
    const bounds = new window.google.maps.LatLngBounds();
    markers.map((marker) => {
      bounds.extend(new window.google.maps.LatLng(marker.position));
      return mapObj.fitBounds(bounds, 90);
    });
  }
};

class Map extends Component {
  state = {
    updatedMarkers: [],
  };

  componentDidMount() {
    const { markers } = this.props;
    const updatedMarkers = markers.filter((marker) => {
      if ((marker && marker.hasOwnProperty('position') && marker.position.lng !== null) || (marker && marker.hasOwnProperty('position') && marker.position.lat !== null)) {
        return marker;
      }
    });
    this.setState({ updatedMarkers });
  }

  render() {
    const { updatedMarkers } = this.state;
    return (
      <div className="map py-12 w-full">
        <InitMap markers={updatedMarkers} isMarkerShown googleMapURL={map.url} loadingElement={<div style={{ height: '100%' }} />} containerElement={<div style={{ height: '500px', width: '100%' }} />} mapElement={<div style={{ height: '100%' }} />}>
          {updatedMarkers.length && <MapMarkers markers={updatedMarkers} />}
        </InitMap>
      </div>
    );
  }
}

export default Map;

Map.propTypes = {
  markers: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
};
