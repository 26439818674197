import React from 'react';
import { PropTypes } from 'prop-types';
import parse from 'html-react-parser';
import { withRouter } from 'react-router-dom';
import { useRoutes } from '../../context/routesContext';
import translateObj from '../../util/translateObj';
import useResponsiveUtility from '../../util/responsiveUtility';
import imageModifier from './helpers/imageModifier';

const MediaDescription = ({ imageUrl, imageUrlMobile, description, classes, imgClass, isDisplayDescriptionOn }) => {
  const { deviceScreen } = useResponsiveUtility();
  const { language } = useRoutes();
  const isMobile = ['sm'].includes(deviceScreen);
  const currentImage = (isMobile && imageUrlMobile?.en) ? imageUrlMobile : imageUrl;
  const imageSize = isMobile ? '800x0' : '2000x0';
  const formattedImage = translateObj(currentImage, language);
  if (!deviceScreen || !currentImage.en) return null; // TODO: add loader
  return (
    <div className={` ${classes} relative w-full full-width`}>
      <div className="text-center">
        <img src={imageModifier(formattedImage, imageSize)} className={`w-full block noBackgroundImgGray ${imgClass}`} alt={formattedImage} />
        {' '}
        {isDisplayDescriptionOn && description && <div className="mt-4 text-left font-tiemposRegular text-xs">{parse(translateObj(description, language))}</div>}
      </div>
    </div>
  );
};

MediaDescription.propTypes = {
  description: PropTypes.shape({}),
  imageUrl: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]).isRequired,
  classes: PropTypes.string,
  imgClass: PropTypes.string,
  isDisplayDescriptionOn: PropTypes.bool,
  imageUrlMobile: PropTypes.shape({
    en: PropTypes.string,
  }),
};

MediaDescription.defaultProps = {
  description: null,
  classes: null,
  imgClass: '',
  isDisplayDescriptionOn: true,
  imageUrlMobile: null,
};

export default withRouter(MediaDescription);
