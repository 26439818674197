import gql from 'graphql-tag';

import { clientWeb } from '../graphqlAPI';

const USER_PASSWORD_RECOVER_USER_SENDS_EMAIL = gql`
  mutation Post($email: String!, $sourceWeb: String) {
    userPasswordRecoverUserSendsEmail(
      email: $email,
      sourceWeb: $sourceWeb,
      ){
        isValid
        email
        error
    }
}`;

const userPasswordRecoverUserSendsEmail = async ({
  email, sourceWeb = '',
}) => {
  try {
    const result = await clientWeb.mutate({
      mutation: USER_PASSWORD_RECOVER_USER_SENDS_EMAIL,
      variables: {
        email,
        sourceWeb,
      },
      fetchPolicy: 'no-cache',
    })
      .then(queryResult => queryResult)
      .catch((queryError) => {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error: ${queryError}`);
        return null;
      });

    const { data: { userPasswordRecoverUserSendsEmail: validatedFormData } } = result;

    if (validatedFormData) {
      return validatedFormData;
    }
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: GraphQL Query Error: validatedFormData = null`);
    return null;
  } catch (error) {
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: userPasswordRecoverUserSendsEmail Error: ${error}`);
    return null;
  }
};

export default userPasswordRecoverUserSendsEmail;
