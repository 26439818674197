/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  TimelineMax,
  Power1,
} from 'gsap';
import withRadioPlayerContext from '../../../context/withRadioPlayerContext';
import RadioPlayerSmall from './RadioPlayerSmall';
import PSResponsiveUtility from '../LineUp/PSResponsiveUtility';
import withAllContexts from '../../../context/withAllContexts';
import translateObj from '../../../util/translateObj';

const ARIA_LABEL_CLOSE_RADIO = {
  en: 'Close Radio Primavera Sound',
  es: 'Cerrar Radio Primavera Sound',
  ca: 'Tancar Radio Primavera Sound',
  pt: 'Fechar Radio Primavera Sound',
};

class RadioPlayerWrapper extends React.Component {
  responsiveUtility = React.createRef();

  body = document.querySelector('body');

  state = {
    isOpen: false,
    isVisiblePlayer: true,
    isOpenPreroll: true,
    deviceScreen: null,
  }

  componentDidMount() {
    this.setState({ isVisiblePlayer: true });
  }

  componentDidUpdate(prevProps, prevState) {
    const { deviceScreen } = this.state;
    if (deviceScreen !== prevState.deviceScreen) {
      if (deviceScreen !== 'sm') {
        this.body.setAttribute('style', 'overflow-y: auto');
      }
    }
  }

  handleClosePreroll = () => {
    this.setState({
      isOpenPreroll: false,
    });
    setTimeout(() => {
      this.setState({ isOpenPreroll: true });
    }, 5000);
  }

  toggleOpen = () => {
    const { isOpen } = this.state;
    const { toggleOpen } = this.props;
    toggleOpen();
    this.setState({
      isOpen: !isOpen,
    });
    if (isOpen) {
      this.animateOverlay('hide');
    } else {
      this.animateOverlay('show');
    }
  }

  getWidth = () => {
    // actions when window is resized
    const deviceScreen = this.responsiveUtility.current.deviceScreen(true);
    this.setState({ deviceScreen });
  };

  hidePlayer = () => {
    const { hidePlayer, closePlayer } = this.props;
    closePlayer();
    this.body.setAttribute('style', 'overflow-y: auto');
    hidePlayer();
  }

  animateOverlay = (type = 'show') => {
    const { playerPosition } = this.props;
    const { deviceScreen } = this.state;
    if (deviceScreen === 'sm') {
      this.body.setAttribute('style', 'overflow: hidden');
    }

    if (type === 'show' && playerPosition === 'pin-b') {
      const ease = Power1.easeOut;
      const tl = new TimelineMax();

      tl.fromTo('[data-name="radioPlayerWrapper"] [data-name="primary"]', 0.3, {
        autoAlpha: 0,
        y: '20%',
      }, {
        autoAlpha: 1,
        y: '0%',
        ease,
      });
    }

    if (type === 'hide' && playerPosition === 'pin-b') {
      const tl = new TimelineMax();
      if (deviceScreen === 'sm') {
        this.body.setAttribute('style', 'overflow-y: auto');
      }

      tl.fromTo('[data-name="radioPlayerWrapper"]', 0.3, {
        y: '0',
      }, {
        y: '0%',
      });
    }
  }

  skipPreroll = () => {
    const { skipPreroll } = this.props;
    this.handleClosePreroll();
    skipPreroll();
  }

  render() {
    const {
      radioActive,
      playing,
      ch2,
      ch1,
      playerPosition,
      showRadioPlayer,
      isVisiblePlayer,
      headerContext,
      routesContext,
    } = this.props;
    const { language } = routesContext;
    const {
      isOpen, deviceScreen, isOpenPreroll,
    } = this.state;
    const ch = this.props[radioActive];
    return (
      <div>
        <PSResponsiveUtility ref={this.responsiveUtility} deviceScreenCallback={this.getWidth} />
        {(ch && showRadioPlayer && isVisiblePlayer) && (
          <div data-name={`${deviceScreen === 'sm' ? 'radioPlayerWrapper' : ''}`} className={`md:flex lg:flex xl:flex w-full ${isOpen ? 'fixed w-full sm:pin-t z-top sm:pin-l bg-black-radio sm:h-screen' : ''}`}>
            <button
              tabIndex={headerContext.megaMenuIsOpenDesktop ? '-1' : '3'}
              type="button"
              onClick={this.hidePlayer}
              className="flex items-center flex-col justify-center sm:h-radio cursor-pointer z-radioClose absolute pin-b pin-r pin-t h-100 mr-1 sm:mr-2"
              aria-label={translateObj(ARIA_LABEL_CLOSE_RADIO, language)}
            >
              <span className="font-icon icon-close font-black text-white text-xs" />
            </button>
            <div className="w-7/8 md:w-3/4 flex sm:w-full md:w-full">
              {ch && ch1
              && (
              <div data-name="primary" className={`w-full ${isOpen ? 'sm:fixed sm:pin-t sm:pin-l w-full' : ''} ${ch1 && ch.channel === ch1.channel && playing ? '' : ''} border-radio-light border-r sm:border-none`}>
                <RadioPlayerSmall deviceScreen={deviceScreen} ch={ch1 && ch1} isPrimary isOpen={isOpen} bg={playing ? 'black-radio' : 'radio'} toggleOpen={this.toggleOpen} channelUrl="/radio/rps" />
              </div>
              )
              }
              {/* {(isOpen || deviceScreen !== 'sm') && playerPosition === 'pin-b' && (ch2 && ch2.hasOwnProperty('channel') && ch2 && ch2.channel !== null)
                ? (
                  <div className={`lg:border-radio-light lg:border-r sm:fixed sm:pin-b sm:pin-l md:pin-b md:pin-l w-full ${ch2 && ch.channel === ch2.channel && playing ? 'sm:w-full' : ''}`}>
                    <RadioPlayerSmall deviceScreen={deviceScreen} ch={ch2 && ch2} bg={ch2 && ch2.channel === ch.channel && playing ? 'black-radio' : 'radio'} isOpen={isOpen} channelUrl="/radio/primavera-sounds" />
                  </div>
                ) : null
              } */}
            </div>
            <div className={`w-1/8 md:w-1/4 ${playing ? 'bg-black-radio' : 'bg-radio'} xs:hidden sm:hidden`}>
              <div className="flex flex-row items-center xs:flex-col justify-around xl:justify-center h-radio px-2">
                <span className={`font-newicons icon-rps-cupra xl:text-40 xlg:text-38 smlg:text-38 lgsm:text-3xl md:text-36 text-white xs:leading-under xl:mr-8 mr-1 lg2:text-2rem ${(deviceScreen === 'lg') && 'text-2rem'} ${((deviceScreen === 'xl') ? 'text-36' : 'text-40')} `} />
              </div>
            </div>
          </div>
        )}
        {/* {ch && radioActive === 'preroll' && isOpenPreroll
          && (
            <div className="p-4 pin-b pin-l h-fullcalc w-full fixed flex flex-col justify-center align-center bg-mid-transparent" style={{ zIndex: 1001 }}>
              <div onClick={this.handleClosePreroll} className="flex items-center flex-col cursor-pointer z-radioClose absolute pin-b pin-r pin-t h-100 mr-12 mt-12 sm:mt-4 sm:mr-4">
                <span className="font-icon icon-close font-black text-white text-xl" />
              </div>
              <div className="w-full h-100">
                <AssetImage url={ch.image} title={ch.program_title_multilang} />
                <div className="sm:w-full xl:w-1/4 lg:w-1/4 md:w-1/2 mx-auto text-center relative mt-2">
                  <OnclickButtonRounded text={{ en: 'skip' }} handleClick={this.skipPreroll} />
                </div>
              </div>
            </div>
          )} */}
      </div>
    );
  }
}
RadioPlayerWrapper.propTypes = {
  radioActive: PropTypes.string.isRequired,
  ch1: PropTypes.shape(),
  ch2: PropTypes.shape(),
  playerPosition: PropTypes.string,
  showRadioPlayer: PropTypes.bool,
  headerContext: PropTypes.shape({}).isRequired,
};
RadioPlayerWrapper.defaultProps = {
  ch1: null,
  ch2: null,
  playerPosition: 'pin-t',
  showRadioPlayer: false,
};
export default withRouter(withRadioPlayerContext(withAllContexts(RadioPlayerWrapper)));
