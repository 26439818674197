import gql from 'graphql-tag';

import { clientWeb, persistor } from '../graphqlAPI';

// TODO: add 'business'
const GET_USERS_LIST_FRONT = gql`
  query Get($query: String, $roles: [String], $from: Int!, $to: Int!, $sortDirection: String, $onlyFriends: Boolean, $letter: String, $countryFilter: [String], $userLang: String, $fetchDate: String) {
    getUsersListFront(query: $query, roles: $roles, from: $from, to: $to, sortDirection: $sortDirection, onlyFriends: $onlyFriends, letter: $letter, countryFilter: $countryFilter, userLang: $userLang, fetchDate: $fetchDate){
      _id
      nickname
      lastname
      name
      imageUrl
      occupation
      business
      company
      inputCountry
      areFriends
      description
      url
      social {
        name
        link
      }
    }
  }
`;

const executeQuery = async (query, roles, from, to, sortDirection, onlyFriends, letter, countryFilter, userLang, type = 'network') => {
  const result = await clientWeb.query({
    query: GET_USERS_LIST_FRONT,
    variables: { query, roles, from, to, sortDirection, onlyFriends, letter, countryFilter, userLang, fetchDate: new Date().getTime().toString() },
    fetchPolicy: type === 'network' ? 'network-only' : 'cache-only',
  })
    .then((queryResult) => queryResult)
    .catch((queryError) => {
      const errorMessage = type === 'network'
        ? `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error: ${queryError}`
        : `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error after Cache Restore: ${queryError}`;
      console.log('\x1b[31m%s\x1b[0m', errorMessage);

      return null;
    });

  if (result.data && result.data.getUsersListFront) {
    const { data: { getUsersListFront: getUsersListFrontData } } = result;
    return getUsersListFrontData;
  }

  return [];
};

const getUsersListFront = async (query, roles, from = 0, to = 50, sortDirection = 'asc', onlyFriends, letter, countryFilter, userLang) => {
  try {
    const JSONObject = await executeQuery(query, roles, from, to, sortDirection, onlyFriends, letter, countryFilter, userLang);

    if (persistor) {
      persistor.persist();
    }

    return JSONObject;
  } catch (jsonError) {
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: JSON Object Error: ${jsonError}`);

    if (persistor) {
      const persistorStatus = await persistor.restore().then(() => 'OK');
      console.log(`${new Date().toLocaleTimeString()} ~ Client: Apollo Cache Restored - ${persistorStatus}`);

      try {
        const JSONObject = await executeQuery(query, roles, from, to, sortDirection, onlyFriends, letter, countryFilter, userLang, 'cache');

        return JSONObject;
      } catch (jsonErrorAfterCacheRestore) {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: JSON Object Error after Cache Restore, sending 404: ${jsonErrorAfterCacheRestore}`);
        return null;
      }
    }
    return null;
  }
};

export default getUsersListFront;
