import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import { useAuth } from '@primaveralabs/auth';
import Link from '../../util/link';
import translateObj from '../../util/translateObj';
import MenuPopUpLoginButton from './Login/MenuPopUpLoginButton';
import { useRoutes } from '../../context/routesContext';
import { useHistory } from 'react-router-dom';

const styles = (theme) => ({
  paper: {
    // right: 0,
    // left: "auto !important",
  },
});
const ARIA_LABEL_LOGOUT = {
  en: 'Log out',
  es: 'Cerrar sesión',
  ca: 'Tancar sessió',
  pt: 'Sair',
};
const ARIA_LABEL_PROFILE = {
  en: 'Go to your profile',
  es: 'Ir a tu perfil',
  ca: 'Vés al teu perfil',
  pt: 'Vá para o seu perfil',
};
const ARIA_LABEL_PROFILE_OPTIONS = {
  en: 'Open user options',
  es: 'Abrir opciones de usuario',
  ca: 'Obrir opcions d\'usuari',
  pt: 'Abrir opções do usuário',
};
const formTextInComponent = {
  submitTitle: {
    en: 'sign in',
    es: 'iniciar sesión',
    ca: 'iniciar sessió',
    pt: 'iniciar sessāo',
  },
  registerPreTitle: {
    es: '¿No tienes cuenta?',
    en: 'Don\'t have an account?',
    ca: 'No tens compte?',
    pt: 'Não tem conta?',
  },
  registerTitle: {
    es: 'Registrate',
    en: 'Register',
    ca: 'Registra"t',
    pt: 'Registe-se',
  },

  faqsTitle: {
    es: 'AYUDA',
    en: 'HELP',
    ca: 'AJUDA',
    pt: 'AJUDA',
  },
  logoutTitle: {
    es: 'DESCONECTATE',
    en: 'LOG OUT',
    ca: 'DESCONECTA"T',
    pt: 'LOG OUT',
  },
  favoritesTitle: {
    es: 'FAVORITOS',
    en: 'FAVORITES',
    ca: 'FAVORITS',
    pt: 'FAVORITES',
  },
  userProfileTitle: {
    es: 'PERFIL DE USUARIO',
    en: 'USER PROFILE',
    ca: 'PERFIL D"USUARI',
    pt: 'USER PROFILE',
  },
};

const UserMenuPopup = ({
  classes,
  ButtonLink,
  invert,
  link,
  localization,
  language,
  loadTokenForLS,
  border,
  formText,
  sourceWeb,
  userName,
  token,
  history,
  redesign,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [tokenState, setTokenState] = useState(null);
  const { actions } = useAuth();
  const { path } = useRoutes();
  const historyObject = useHistory();

  useEffect(() => {
    setTokenState(token);
  }, [token, userName]);

  // popover user logic

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = (lang) => {
    actions.logout().then(() => {
      if (typeof localStorage !== 'undefined') {
        localStorage.removeItem('auth-token');
        handleClose();
        // history?.push(`/${lang}${sourceWeb && `/${sourceWeb}`}`);
      }
      historyObject?.push(`/${lang}`);
    });
  };

  const userMenuPopUpRenderer = () => {
    // popover logic in render
    const open = Boolean(anchorEl);

    if (tokenState) {
      return (
        <>
          <button type="button" className={`${token && 'hidden'} sm:block md:block`}>
            <Link to={`${link.link}`} localizationpath={path} className="no-underline flex">
              <ButtonLink icon={link.icon} key={link.key} border={border} localization={localization} invert={invert} />
            </Link>
          </button>
          <div
            className="cursor-pointer sm:hidden md:hidden"
            aria-owns={open ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
            onMouseEnter={handleClick}
            onClick={handleClose}
          >
            <button type="button" aria-label={translateObj(ARIA_LABEL_PROFILE_OPTIONS, language)}>
              <ButtonLink icon={link.icon} key={link.key} localization={localization} border={border} invert={invert} />
            </button>
            <Popover
              id="mouse-over-popover"
              open={open}
              anchorEl={anchorEl}
              styles={{
                pointerEvents: 'auto',
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              classes={{ paper: classes.paper }}
              PaperProps={{ onMouseLeave: handleClose }}
              disableRestoreFocus
            >

              <div className="bg-black text-white p-8 max-w-xs">
                <div className={`${!sourceWeb && 'border-b-1 border-white border-dotted'} -mx-8`}>
                  <div className=" border-white border-b-1 border-dotted py-4 mx-8">
                    <Link to='/user-panel' localizationpath={path} aria-label={translateObj(ARIA_LABEL_PROFILE, language)}>
                      <div className="text-white font-americaMonoRegular uppercase text-12 cursor-pointer flex justify-between">
                        <span className="pr-20" onClick={handleClose}>{translateObj(formText.userProfileTitle, language)}</span>
                        <span>{'>'}</span>
                      </div>
                    </Link>
                  </div>
                  {/* {!sourceWeb && (
                  <div className=" py-4 mx-8">
                    <Link to="/user-favorites" localizationpath={`${localization.path}`}>
                      <div className="text-white font-americaMonoRegular uppercase text-12 cursor-pointer flex justify-between">
                        <span className="pr-20" onClick={handleClose}>{translateObj(formText.favoritesTitle, language)}</span>
                        <span>></span>
                      </div>
                    </Link>
                  </div>
                  )} */}
                </div>
                <div className="pt-4">
                  <button
                    type="button"
                    className="font-americaMonoRegular uppercase text-black bg-red w-full text-center px-4 py-2 rounded-full tracking-submenu text-12 cursor-pointer"
                    onClick={() => {
                      handleLogout(language);
                    }}
                    aria-label={translateObj(ARIA_LABEL_LOGOUT, language)}
                  >
                    {translateObj(formText.logoutTitle, language)}
                  </button>
                </div>
              </div>
            </Popover>
          </div>
        </>
      );
    }
    return (
      <MenuPopUpLoginButton
        textColor={!sourceWeb ? 'white' : ''}
        sourceWeb={sourceWeb}
        language={language}
        icon={link.icon}
        redesign={redesign}
        showText={!redesign}
      />
    );
  };

  return (
    <>
      {userMenuPopUpRenderer()}
    </>
  );
};

UserMenuPopup.propTypes = {
  classes: PropTypes.shape(),
  border: PropTypes.string,
  sourceWeb: PropTypes.string,
  link: PropTypes.shape(),
  localization: PropTypes.shape(),
  userName: PropTypes.string,
  redesign: PropTypes.bool,
  token: PropTypes.string,
  language: PropTypes.string,
  loadTokenForLS: PropTypes.func.isRequired,
  ButtonLink: PropTypes.func.isRequired,
  history: PropTypes.shape().isRequired,
  formText: PropTypes.shape(),
  invert: PropTypes.bool,
};

UserMenuPopup.defaultProps = {
  classes: { paper: '' },
  border: '',
  sourceWeb: '',
  link: {},
  localization: {},
  userName: '',
  token: '',
  language: 'en',
  formText: formTextInComponent,
  redesign: false,
  invert: true,
};

export default withStyles(styles)(UserMenuPopup);
