import React from 'react';
import { PropTypes } from 'prop-types';

import StreamingVideoPlayerMainYoutube from '../StreamingVideoPlayerMainYoutube';
import LoadIcon from '../../LoadIcon';

const YoutubeVideoScreen = ({
  isLoading,
  mainVideo,
  PLAYER_RATIO,
  activeSlide,
}) => (
  <>
    <div className={`bg-black ${isLoading && 'sm:min-h-10 md:min-h-20 min-h-256'}  flex flex-col justify-center`}>
      {isLoading && <LoadIcon color="black" type="ThreeDots" />}
      {mainVideo && (
        <div className={`${isLoading ? 'hidden' : 'relative w-full bg-black'} `} style={{ paddingTop: PLAYER_RATIO }}>
          <div className="absolute pin-t pin-l w-full h-full">
            <StreamingVideoPlayerMainYoutube
              mainVideo={mainVideo}
              activeSlide={activeSlide}

            />
          </div>
        </div>
      )}
    </div>
  </>
);

YoutubeVideoScreen.propTypes = {
  mainVideo: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  PLAYER_RATIO: PropTypes.string,
  activeSlide: PropTypes.number,
};

YoutubeVideoScreen.defaultProps = {
  isLoading: true,
  PLAYER_RATIO: '56.25%',
  activeSlide: 0,
};

export default YoutubeVideoScreen;
