import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';

class LauncherInstagram extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      image: {},
      date: '',
      text: '',
      url: '',
      user: '',
    };
  }

  componentDidMount() {
    this.fetchInstagram();
  }

  fetchInstagram() {
    axios.get('https://assets.primaverasound.com/app/psb/prod/8.6/instagram.json')
      .then((res) => {
        this.setState({
          isLoading: false,
          image: res.data[0].images.standard_resolution.url,
          date: res.data[0].created_time,
          text: res.data[0].caption.text,
          user: res.data[0].caption.from.username,
          url: res.data[0].link,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    const {
      isLoading,
      user,
      image,
      url,
      text,
      date,
    } = this.state;
    const postDate = moment.unix(date).format('D/MM/YYYY').replace(/\//g, '-');
    let postText = text.substring(0, 100);
    postText = postText.substr(0, Math.min(postText.length, postText.lastIndexOf(' ')));
    if (isLoading) return (<div />);
    return (
      <a href={url} target="_blank" rel="noopener noreferrer">
        <div className="textOnHoverWrapper relative border-t border-transparent launcherItemData single">
          <div className="w-full launcherItemImg relative">
            <img src={image} alt="insta" className="w-full" />
          </div>
          <div className="textOnHover flex flex-col w-full xl:invisible xl:opacity-0 absolute pin-l pin-b p-12 lg:py-8 lg:px-8 text-white sm:p-6">
            <div className="xs:p-0 lg:pb-4">
              <span className="font-icon text-white icon-instagram-logo text-28 pb-6 sm:p-0" />
              <p className="font-americaMonoRegular text-white text-14 m-0 pt-8 hidden xl:block">{postDate}</p>
              <p className="leading-tight text-white text-left text-20 font-americaBlack sm:text-20 m-0 hidden xl:block">
                {user}
              </p>
            </div>
            <h2 className="absolute sm:p-6 leading-tight pin-l pin-b p-12 mb-6 text-white text-left xl:text-22 md:pb-6 font-americaBlack sm:text-20 md:text-18 lg:text-20 lg:w-full">
              {postText}
            </h2>
          </div>
        </div>
      </a>
    );
  }
}

export default LauncherInstagram;
