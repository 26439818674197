import React from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import SocialLogosContainer from './LogosContainer/SocialLogosContainer';
import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';
import translateObj from '../../util/translateObj';

const ShareMedia = ({
  match, black, assetSlugName, description, location, location: { search },
}) => {
  const translations = {
    es: 'compartir',
    en: 'share',
    ca: 'compartir',
  };

  return (
    <RoutesContext.Consumer>
      {({ language: contextLanguage, enteredFrom }) => {
        const localization = getLocalization(contextLanguage, enteredFrom, match);

        return (
          <div className={`${black && 'bg-black'} ${black && 'text-white'} font text-americaMonoRegular flex justify-end items-center `}>
            <span className={`${black && 'text-white'} font-americaMonoRegular text-xs uppercase pr-2`}>{translateObj(translations, localization.language)}</span>
            <SocialLogosContainer localization={localization} classNames={`${black && 'text-white'} flex justify-around`} imageClassNames={`${black && 'text-white'}`} description={translateObj(description, localization.language)} assetSlugName={assetSlugName} currentUrl={location.pathname + search} />
          </div>
        );
      }}
    </RoutesContext.Consumer>
  );
};

ShareMedia.propTypes = {
  match: PropTypes.shape().isRequired,
  black: PropTypes.bool,
  assetSlugName: PropTypes.string,
  description: PropTypes.shape({}),
};

ShareMedia.defaultProps = {
  black: false,
  assetSlugName: null,
  description: null,
};

export default withRouter(ShareMedia);
