import React from 'react';
import parse from 'html-react-parser';
import { withRouter } from 'react-router-dom';
import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';
import translateObj from '../../../util/translateObj';

const SideBodyTextLeft = ({
  text, cursive, bold, anchorTag, firstLetterBig, classNames, match,
}) => (
  <RoutesContext.Consumer>
    {({ language: contextLanguage, enteredFrom }) => {
      const localization = getLocalization(contextLanguage, enteredFrom, match);

      let className = `${classNames}`;
      if (cursive) className += ' italic';
      if (bold) className += ' font-bold';
      let firstLetter = '';

      const paragraphs = translateObj(text, localization.language)
        .split('\n')
        .filter(n => n.length)
        .map((paragraph, i) => {
          const StrippedString = paragraph.replace(/(<([^>]+)>)/gi, '');

          if (i === 0 && firstLetterBig) {
            firstLetter = StrippedString[0];
            paragraph = StrippedString.substr(1);
          }
          return paragraph;
        });

      return (
        <div className={`${className} px-4 sm:px-0 md:px-0 xl:flex lg:flex`} id={anchorTag}>
          {firstLetter ? (
            <div style={{ fontSize: 180 }} className=" px-0 pt-12 text-right font-tiemposRegular sm:float-left md:float-left">
              {firstLetter || <span className="invisible">X</span>}
            </div>
          ) : (
            <div className="sm:hidden md:hidden xl:w-1/4 lg:w-1/4">
              <span className="invisible">X</span>
            </div>
          )}
          <div className={`xl:py-4 lg:py-4 sm:pt-2 md:pt-2 w-full ${!firstLetter && 'xl:w-3/4 lg:w-3/4'}`}>
            {paragraphs.map((paragraph, i) => (
              <div className="mt-4 leading-normal font-tiemposRegular" key={paragraph + i}>
                {parse(paragraph)}
              </div>
            ))}
          </div>
        </div>
      );
    }}
  </RoutesContext.Consumer>
);

export default withRouter(SideBodyTextLeft);
