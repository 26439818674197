/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SectionSocialOnDemand from './OnDemandModalsSubComponents/SectionSocialOnDemand';
import SectionPasswordOnDemand from './OnDemandModalsSubComponents/SectionPasswordOnDemand';
import { buttonClasses } from '../OnDemandUtils/onDemandUtils';
import translateObj from '../../../../util/translateObj';

const ARIA_LABEL_MODAL = {
  en: 'Log in or register',
  es: 'Inicia sesión o regístrate',
  ca: 'Inicieu sessió o registreu-vos',
  pt: 'Faça login ou cadastre-se',
};

const circleStyle = {
  width: '60px',
  height: '60px',
  borderRadius: '50%',
};

const OnDemandModalLogin = (props) => {
  const { translations: { notLoggedModal: translations = {} }, language, handleRegisteringStatus, QRcodeCallback } = props;
  const [notLoggedStatus, setNotLoggedStatus] = useState('social');
  const [firstFocusableEl, setFirstFocusableEl] = useState();
  const [lastFocusableEl, setLastFocusableEl] = useState();

  // TODO: tab focus inside modal
  const handleFocusableItems = () => {
    // get all focusable elements
    const element = document.getElementById('onDemandModalLogin');
    const focusableEls = element.querySelectorAll(
      `a[href]:not([disabled], [tabIndex = "-1"]),
      button:not([disabled], [tabIndex = "-1"]),
      textarea:not([disabled], [tabIndex = "-1"]),
      input[type="text"]:not([disabled], [tabIndex = "-1"]),
      input[type="radio"]:not([disabled], [tabIndex = "-1"]),
      input[type="checkbox"]:not([disabled], [tabIndex = "-1"]),
      select:not([disabled], [tabIndex = "-1"])`,
    );
      // set first and last focusable items inside onDemandModalLogin
    if (focusableEls.length > 0) {
      setFirstFocusableEl(focusableEls[0]);
      setLastFocusableEl(focusableEls[focusableEls.length - 1]);
    }
    element.focus();
  };

  const keydown = (event) => {
    // trap focus
    if (event.key === 'Tab' || event.keyCode === 9) {
      if (event.shiftKey) /* shift + tab */ {
        if (document.activeElement === firstFocusableEl) {
          lastFocusableEl.focus();
          event.preventDefault();
        }
      } else /* tab */ {
        if (document.activeElement === lastFocusableEl) {
          firstFocusableEl.focus();
          event.preventDefault();
        }
      }
    }
  };

  useEffect(() => {
    handleFocusableItems();
  }, []);

  useEffect(() => {
    if (document) {
      document.addEventListener('keydown', keydown, false);
    }
    return () => document.removeEventListener('keydown', keydown, false);
  }, [firstFocusableEl, lastFocusableEl]);

  const handleModalNotLoggedStatus = (loggedStatus) => {
    setNotLoggedStatus(loggedStatus);
  };

  const notLoggedRender = () => {
    switch (notLoggedStatus) {
      case 'social':
        return (
          <SectionSocialOnDemand {...props} handleModalNotLoggedStatus={handleModalNotLoggedStatus} QRcodeCallback={QRcodeCallback} />
        );
      case 'password':
        return (
          <SectionPasswordOnDemand {...props} handleModalNotLoggedStatus={handleModalNotLoggedStatus} handleRegisteringStatus={handleRegisteringStatus} QRcodeCallback={QRcodeCallback} />
        );
      default:
        return (
          <SectionSocialOnDemand {...props} handleModalNotLoggedStatus={handleModalNotLoggedStatus} QRcodeCallback={QRcodeCallback} />
        );
    }
  };

  return (
    <div id="onDemandModalLogin" tabIndex="0" role="dialog" aria-label={translateObj(ARIA_LABEL_MODAL, language)} className="flex sm:flex-col-reverse">
      {/* <div className="w-1/2 sm:w-full sm:py-12 font-americaBold text-center p-8">
        <div className="p-8 sm:p-1 flex justify-center"><div className="p-2 rounded-full bg-gray200 border-1 border-psworld-grey" style={circleStyle} /></div>
        <div className="pt-8 pb-3">{translateObj(translations.notAUser, language)}</div>
        <button
          type="button"
          className={buttonClasses({ backgroundColor: 'gray200', textColor: 'black' })}
          onClick={() => handleRegisteringStatus(true)}
        >
          {translateObj(translations.createAccount, language)}
        </button>
      </div> */}
      <div className="w-full sm:w-full sm:py-12 bg-gray200 font-americaBold p-8">
        {translateObj(translations.accessPrimavera, language)}
        {notLoggedRender()}
      </div>
    </div>
  );
};

OnDemandModalLogin.propTypes = {
  language: PropTypes.string,
  loadTokenForLS: PropTypes.func.isRequired,
  handleOnDemandScreenStatus: PropTypes.func.isRequired,
  handleRegisteringStatus: PropTypes.func.isRequired,
  translations: PropTypes.shape().isRequired,
  QRcodeCallback: PropTypes.func,
};

OnDemandModalLogin.defaultProps = {
  language: 'en',
  QRcodeCallback: null,
};

export default OnDemandModalLogin;
