import gql from 'graphql-tag';

import { clientWeb } from '../graphqlAPI';

const POST_NEWSLETTER_FORM_MUTATION = gql`
  mutation Post($email: String!, $language: String, $scopes: [String], $origin: String, $isSubscribing: Boolean, $isOriginSettings: Boolean, $unsubscribe: Boolean) {
    postNewsletterForm(email: $email, language: $language, scopes: $scopes, origin: $origin, isSubscribing: $isSubscribing, isOriginSettings: $isOriginSettings, unsubscribe: $unsubscribe) {
      email
      isValid
      language
      scopes {
        name
        value
      }
      emailVerified
    }
  }
`;

const postNewsletterForm = async ({
  email, language, scopes = ['scope_general'], origin = 'web', isSubscribing = true, isOriginSettings = false, unsubscribe = false,
}) => {
  // console.log('email', email, 'language', language, 'scopes', scopes, 'origin', origin, 'isSubscribing', isSubscribing, 'isOriginSettings', isOriginSettings);
  try {
    const result = await clientWeb
      .mutate({
        mutation: POST_NEWSLETTER_FORM_MUTATION,
        variables: {
          email, language, scopes, origin, isSubscribing, isOriginSettings, unsubscribe,
        },
        fetchPolicy: 'no-cache',
      })
      .then(queryResult => queryResult)
      .catch((queryError) => {
        console.log(
          '\x1b[31m%s\x1b[0m',
          `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error: ${queryError}`,
        );
        return null;
      });
    const {
      data: { postNewsletterForm: validatedFormData },
    } = result;

    if (validatedFormData) {
      return validatedFormData;
    }
    console.log(
      '\x1b[31m%s\x1b[0m',
      `${new Date().toLocaleTimeString()} ~ Client: GraphQL Query Error: validatedFormData = null`,
    );
    return null;
  } catch (error) {
    console.log(
      '\x1b[31m%s\x1b[0m',
      `${new Date().toLocaleTimeString()} ~ Client: postNewsletterForm Error: ${error}`,
    );
    return null;
  }
};

export default postNewsletterForm;
