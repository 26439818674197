/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable react/no-multi-comp */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Formsy from 'formsy-react';
import { TweenMax, TimelineMax, Power3, Power1 } from 'gsap';

import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';
import translateObj from '../../../util/translateObj';

import postNewsletterForm from '../../../api/graphql/mutations/postNewsletterForm';

import FormNewsletterInput from './FooterRedesignInput';

const ARIA_LABEL_SEND = {
  en: 'Send',
  es: 'Enviar',
  ca: 'Enviar',
  pt: 'Mandar',
};

class FooterRedesignNewsletter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canSubmit: false,
      didSubmit: false,
      error: false,
      isValidStatus: '',
    };

    this.childrenComponent = null;
    this.buttonTween = new TimelineMax();
  }

  componentWillMount = () => {
    const {
      children: {
        props: { components: childrenComponents },
      },
    } = this.props;

    const extractedComponent = childrenComponents[0];

    this.childrenComponent = extractedComponent;
  }

  submitForm = (formData) => {
    const { location, origin, scopes, parent } = this.props;
    const language = location.pathname.split('/')[1] || 'en';
    const formDataWithLang = {
      ...formData,
      language,
      origin,
      scopes,
      parent,
    };

    this.setState({
      canSubmit: false,
    });

    postNewsletterForm({ ...formDataWithLang }).then((response) => {
      if (!response.isValid) {
        const emailErrorsArray = ['invalid', 'short', 'long'];
        let isValidStatus = 'error';
        if (emailErrorsArray.includes(response.email)) {
          isValidStatus = 'emailError';
        }
        this.setState({
          error: true,
          isValidStatus,
        });
      } else {
        this.setState({
          isValidStatus: 'valid',
        });
      }
      this.setState({ didSubmit: true });
      this.animateButton();
    });
  }

  animateButton = () => {
    this.buttonTween
      .to(this.submitButton, 0.35, {
        x: -80,
        opacity: 0.3,
        ease: Power3.easeOut,
      })
      .to(this.submitButton, 0.35, {
        x: -40,
        opacity: 0.0,
        ease: Power3.easeIn,
      })
      .play();
  }

  enableButton = () => {
    this.setState({ canSubmit: true });
  }

  disableButton = () => {
    this.setState({ canSubmit: false });
  }

  resetForm = () => {
    this.setState(
      {
        canSubmit: false,
        didSubmit: false,
        error: false,
        isValidStatus: '',
      },
      () => {
        TweenMax.to(this.submitButton, 0, { x: 0, opacity: 1 });
      },
    );
  }

  render() {
    const { match, name, parent } = this.props;

    const { text = null, placeholder, title } = this.childrenComponent.params;

    const { canSubmit, didSubmit, error, isValidStatus } = this.state;

    return (
      <RoutesContext.Consumer>
        {({ language: contextLanguage, enteredFrom }) => {
          const localization = getLocalization(
            contextLanguage,
            enteredFrom,
            match,
          );
          const definedLangPlaceholder = translateObj(
            placeholder,
            localization.language,
          );
          return (
            <Formsy
              ref={(c) => {
                this.form = c;
                return this.form;
              }}
              name={name}
              onValidSubmit={this.submitForm}
              onValid={this.enableButton}
              onInvalid={this.disableButton}
              className={`w-full max-w-13 ${parent === 'FooterRedesignBottom' ? 'hidden tablet:block' : 'block tablet:hidden mb-8'}`}
            >
              <div data-name="input-text-button">
                {title && (
                  <h4 tabIndex="0" className="text-white text-sm uppercase font-sans mb-4 font-medium">
                    {translateObj(title, localization.language)}
                  </h4>
                )}
                <div className="relative">
                  <FormNewsletterInput
                    ref={(c) => {
                      this.email = c;
                      return this.email;
                    }}
                    name="email"
                    placeholder={!didSubmit ? definedLangPlaceholder : ''}
                    didSubmit={didSubmit}
                    validations={{ isEmail: true, maxLength: 50 }}
                    validationErrors=""
                    error={error}
                    isValidStatus={isValidStatus}
                    isLauncheritem={false}
                    language="es"
                    required
                    resetForm={this.resetForm}
                  />
                  <button
                    type="submit"
                    ref={(c) => {
                      this.submitButton = c;
                      return this.submitButton;
                    }}
                    disabled={!canSubmit}
                    label={translateObj(text, localization.language)}
                    className="absolute pin-r pin-t pin-b w-5 flex items-center justify-center -mr-1"
                    tabIndex="0"
                    aria-label={translateObj(ARIA_LABEL_SEND, localization.language)}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                      <g>
                        <path fill="none" d="M0 0h20v20H0z" />
                        <path d="M13.396 9.226a1 1 0 0 1 0 1.736l-5.9 3.371a1 1 0 0 1-1.5-.868V6.723a1 1 0 0 1 1.5-.868Z" fill="#fff" opacity=".2" />
                      </g>
                    </svg>
                  </button>
                </div>
              </div>
            </Formsy>
          );
        }}
      </RoutesContext.Consumer>
    );
  }
}

FooterRedesignNewsletter.propTypes = {
  match: PropTypes.shape().isRequired,
  children: PropTypes.shape().isRequired,
  name: PropTypes.string,
  origin: PropTypes.string,
  scopes: PropTypes.arrayOf(PropTypes.string),
  parent: PropTypes.oneOf(['FooterRedesignTop', 'FooterRedesignBottom']).isRequired,
};

FooterRedesignNewsletter.defaultProps = {
  name: '',
  origin: 'Footer Web',
  scopes: ['scope_general'],
};

export default withRouter(FooterRedesignNewsletter);
