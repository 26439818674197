import React from "react";
import PropTypes from 'prop-types';

const ProgressBar = ({value}) => {
    const realValue = value > 100 ? 100 : value;
    const widthNum = ((realValue)+"%");
    
    return (
      <div className="bg-grey-light">
        <div className={`${value > 80 ? 'bg-red-coral' : 'bg-selected'} ${(value < 0) && ''}`} style={{height: "10px", width:widthNum}}></div>
      </div>
    )
}

ProgressBar.PropTypes = {
    value: PropTypes.number.isRequired,
}

export default ProgressBar;