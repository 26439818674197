import React from 'react';

const exportIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.35636 7.04266L10.5355 8.221L12.8922 5.86433C13.5173 5.23919 13.8685 4.39132 13.8685 3.50725C13.8685 2.62317 13.5173 1.7753 12.8922 1.15016C12.5827 0.840627 12.2152 0.59509 11.8108 0.42757C11.4063 0.26005 10.9729 0.173828 10.5351 0.173828C9.65103 0.173828 8.80317 0.525027 8.17803 1.15016L5.82136 3.50683L6.9997 4.686L9.35636 2.3285C9.66899 2.01587 10.093 1.84024 10.5351 1.84024C10.9772 1.84024 11.4012 2.01587 11.7139 2.3285C12.0265 2.64112 12.2021 3.06513 12.2021 3.50725C12.2021 3.94936 12.0265 4.37337 11.7139 4.686L9.35636 7.04266ZM6.9997 9.40016L8.17803 10.5785L5.82136 12.9352C5.19623 13.5603 4.34836 13.9115 3.46428 13.9115C2.5802 13.9115 1.73233 13.5603 1.1072 12.9352C0.482058 12.31 0.130859 11.4622 0.130859 10.5781C0.130859 9.694 0.482058 8.84613 1.1072 8.221L3.46386 5.86433L4.64303 7.04266L2.28553 9.40016C1.97302 9.71279 1.79749 10.1368 1.79757 10.5788C1.79765 11.0208 1.97332 11.4447 2.28595 11.7572C2.59857 12.0698 3.02253 12.2453 3.46457 12.2452C3.90661 12.2451 4.33052 12.0695 4.64303 11.7568L6.9997 9.39933V9.40016Z"
      fill="white"
    />
  </svg>
);

export default exportIcon;
