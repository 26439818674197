import { useState, createContext } from "react";

export function useHeaderContext() {
  const [megaMenuIsOpen, setMegaMenuIsOpen] = useState(false);
  const [megaMenuIsOpenDesktop, setMegaMenuIsOpenDesktop] = useState(false);
  const [authOpen, setAuthOpen] = useState(undefined);

  const toggleMegaMenu = () => {
    setMegaMenuIsOpen(!megaMenuIsOpen);
  };

  return {
    megaMenuIsOpen,
    setMegaMenuIsOpen,
    megaMenuIsOpenDesktop,
    setMegaMenuIsOpenDesktop,
    toggleMegaMenu,
    authOpen,
    setAuthOpen,
  };
}

export const HeaderContext = createContext(undefined);
