import React from 'react';

let updateLauncher;
let clearLauncher;

const launcher = {
  highlights: [],
  updateLauncher: highlights => updateLauncher(highlights),
  clearLauncher: () => clearLauncher(),
};

updateLauncher = (highlights) => {
  launcher.highlights = highlights;
};

clearLauncher = () => {
  launcher.highlights = [];
};

const LauncherContext = React.createContext(launcher);

export default LauncherContext;
