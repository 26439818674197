import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';
import { useRoutes } from '../../../context/routesContext';
import { getCountryFromCode } from '../../../util/getCountryNamesByCode';
import useQuery from './util/hooks/useQuery';

import getProMusicMeetsUser from '../../../api/graphql/mutations/getProMusicMeetsUser';

import { BUSINESS_OPTIONS } from './util/optionValues';
import translateObj from '../../../util/translateObj';
import getTranslation from './util/translations/_getTranslation';
import localTranslation from './util/translations/MemberDetail';

import SquareImage from './SquareImage';
import Button from './Button';
import SocialLogos from './SocialLogos';
import IconButton from './IconButton';
import CtaUnderline from './CtaUnderline';

const MemberDetail = ({
  userType,
  translation,
}) => {
  const { language } = useRoutes();
  const history = useHistory();
  const literals = getTranslation(translation, localTranslation);
  const query = useQuery();
  const [userData, setUserData] = useState();
  const [country, setCountry] = useState('');
  const [business, setBusiness] = useState('');

  const loadUserData = (id) => {
    getProMusicMeetsUser(id).then((res) => {
      if (res) {
        setUserData(res);
      }
    }).catch((err) => console.log(err));
  };

  useEffect(() => {
    if (userData) {
      const countryISOcode = getCountryFromCode(userData?.inputCountry, language);
      const businessTranslations = BUSINESS_OPTIONS.find((o) => o.value === userData?.business)?.name;
      setCountry(countryISOcode);
      setBusiness(translateObj(businessTranslations, language));
    }
  }, [userData]);

  useEffect(() => {
    const id = query.get('id');
    loadUserData(id);
  }, []);

  return (
    <div className="w-full bg-black text-white pt-10 sm:pb-16 md:pb-16 pb-32">
      <IconButton
        color="white"
        icon="font-icon icon-arrow-left"
        className="mb-8"
        onClickCallback={() => history.goBack()}
      />
      <div className="flex sm:flex-col md:flex-col justify-around items-start w-full">
        <h4 className="font-americaMonoRegular text-12 uppercase hidden md:block sm:block mb-3">{translateObj(userType, language)}</h4>
        <div className="w-1/2 sm:w-3/4 sm:mb-4 md:mb-4 text-white">
          <SquareImage
            image={userData?.imageUrl || null}
            alt={`${userData?.name}${userData?.lastname}` || ''}
            gradient
            dimensions="1200x1200"
          />
        </div>
        <div className="w-1/2 sm:w-full md:w-full sm:px-0 md:px-0 px-1/12">
          <h4 className="font-americaMonoRegular text-12 uppercase sm:hidden md:hidden">{translateObj(userType, language)}</h4>
          <div className="font-americaBlack sm:text-30 text-50 mb-8 sm:mb-4 mt-4 flex flex-wrap leading-none">
            <h4 className="mr-2">{`${userData?.name || ''} ${userData?.lastname || ''}`}</h4>
          </div>
          <div className="font-timesRegular text-14 flex flex-wrap mb-8">
            <span>{business}</span>
            <div className="mx-1">|</div>
            <span>{userData?.company || ''}</span>
            <div className="mx-1">|</div>
            <span>{country}</span>
          </div>
          {!userData?.isMentor && (
          <div className="flex sm:flex-col mb-8 mt-4 sm:items-start items-stretch">
            <Button text={translateObj(literals.buttonInvite, language)} color="orange" plusIcon />
            <Button text={translateObj(literals.buttonChat, language)} color="outlineWhite" className="h-full ml-4 sm:ml-0 sm:mt-2" verticalPaddings="py-3" />
          </div>
          )}
          <div className="w-full my-4">
            <p className="font-timesRegular text-base sm:text-14">
              {translateObj(userData?.description, language)}
            </p>
          </div>
          <CtaUnderline text={userData?.url || ''} link={userData?.url || ''} color="orange" />
          <SocialLogos social={userData?.social || []} textColor="text-white" className="-ml-2 sm:-ml-1 md:-ml-1 mt-2 sm:mt-1" />
        </div>
      </div>
    </div>
  );
};

MemberDetail.propTypes = {
  userType: PropTypes.string,
  translation: PropTypes.shape(),
};

MemberDetail.defaultProps = {
  userType: '',
  translation: null,
};

export default MemberDetail;
