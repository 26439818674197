import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const useQueryString = (query) => {
  const { search } = useLocation();

  const urlSearchParams = useMemo(() => new URLSearchParams(search), [search]);

  return urlSearchParams.get(query);
};

export default useQueryString;
