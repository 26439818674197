import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Link from '../../../util/link';

import Tag from '../Buttons/Tag';
import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';
import imageModifier from '../helpers/imageModifier';
import translateObj from '../../../util/translateObj';

const LauncherItemComunicados = ({
  image, date, title, tags, url, match, category,
}) => (
  <RoutesContext.Consumer>
    {({ language: contextLanguage, enteredFrom }) => {
      const localization = getLocalization(contextLanguage, enteredFrom, match);
      const urlWithSlash = url[0] === '/' ? url : `/${url}`;

      return (
        <Link to={localization.path + urlWithSlash} className="sm:w-full w-1/2 sm:px-0 px-4">
          <div className="flex launcherItemData items-center border-b border-black">
            <div className="w-1/3">
              <img className="block" src={imageModifier(image.src, '400x400')} alt={title.en} />
            </div>
            <div className="w-2/3 px-10 items-start justify-center text-black sm:px-5 md:pr-2">
              {date && <p className="font-americaMonoRegular text-xs sm:text-xxs">{date}</p>}
              {title && <h2 className="text-24 lg:text-20 text-black font-americaBlack sm:my-2 my-3 sm:text-10 md:text-12">{translateObj(title, localization.language).substring(0, 40)}</h2>}
              <Tag text={translateObj(tags[0], localization.langugae)} category={category} key={`${tags[0]}`} margin="mb-2" />
              {/* {tags && tags.map(tag => <Tag text={tag} category={category} key={`${tag}`} margin="mb-2" />)} */}
            </div>
          </div>
        </Link>
      );
    }}
  </RoutesContext.Consumer>
);

LauncherItemComunicados.propTypes = {
  itemLayout: PropTypes.string.isRequired,
  match: PropTypes.string.isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    es: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  date: PropTypes.string.isRequired,
  title: PropTypes.shape({
    es: PropTypes.string.isRequired,
    en: PropTypes.string.isRequired,
    ca: PropTypes.string.isRequired,
  }).isRequired,
  tags: PropTypes.arrayOf(PropTypes.string.isRequired),
  url: PropTypes.string.isRequired,
}.isRequired;

export default withRouter(LauncherItemComunicados);
