/* eslint-disable max-len */
/* eslint-disable quotes */
const translation = {
  title: {
    en: 'Profile details',
    es: 'Detalles del perfil',
    ca: 'Detalls del perfil',
    pt: 'Detalhes de perfil',
  },
  titleLogin: {
    en: 'Login data',
    es: 'Datos de inicio de sesión',
    ca: 'Dades d\'inici de sessió',
    pt: 'Detalhes de login',
  },
  labelName: {
    en: 'Name',
    es: 'Nombre',
    ca: 'Nom',
    pt: 'Nome',
  },
  labelBusiness: {
    en: 'Business',
    es: 'Actividad',
    ca: 'Activitat',
    pt: 'Atividade',
  },
  labelLastname: {
    en: 'Last name',
    es: 'Apellido',
    ca: 'Cognom',
    pt: 'Sobrenome',
  },
  labelEmail: {
    en: 'Email',
    es: 'Email',
    ca: 'Email',
    pt: 'Email',
  },
  labelCity: {
    en: 'City',
    es: 'Ciudad',
    ca: 'Ciutat',
    pt: 'Cidade',
  },
  labelCountry: {
    en: 'Country',
    es: 'País',
    ca: 'País',
    pt: 'País',
  },
  placeholderCountry: {
    en: 'Select country',
    es: 'Selecciona un país',
    ca: 'Selecciona un país',
    pt: 'Selecione um pais',
  },
  placeholderOccupation: {
    en: 'Select your occupation',
    es: 'Selecciona tu profesión',
    ca: 'Selecciona la teva professió',
    pt: 'Seleciona a tua profissão',
  },
  placeholderBusiness: {
    en: 'Select a business',
    es: 'Selecciona la actividad',
    ca: 'Selecciona la activitat',
    pt: 'Selecciona a atividade',
  },
  labelLanguage: {
    en: 'Language',
    es: 'Idioma',
    ca: 'Idioma',
    pt: 'Idioma',
  },
  placeholderLanguage: {
    en: 'Select language',
    es: 'Selecciona un idioma',
    ca: 'Selecciona un idioma',
    pt: 'Selecione um idioma',
  },
  titlePassword: {
    en: 'Change password',
    es: 'Cambio de contraseña',
    ca: 'Canvi de contrasenya',
    pt: 'Mudança de senha',
  },
  labelPassword: {
    en: 'Password',
    es: 'Contraseña',
    ca: 'Contrasenya',
    pt: 'Senha',
  },
  labelRepeatPassword: {
    en: 'Repeat password',
    es: 'Repetir contraseña',
    ca: 'Repetir contrasenya',
    pt: 'Repetir a senha',
  },
  textPassword: {
    en: 'It must be at least 8 digits long, contain a capital letter and a symbol.',
    es: 'Debe ser de mínimo 8 dígitos, contener una mayúscula y un símbolo.',
    ca: 'Ha de ser de mínim 8 dígits, contenir una majúscula i un símbol.',
    pt: 'Deve ter pelo menos 8 dígitos, conter uma letra maiúscula e um símbolo.',
  },
  titleProfile: {
    en: 'Your public profile',
    es: 'Tu perfil público',
    ca: 'El teu perfil públic',
    pt: 'Seu perfil público',
  },
  buttonPreview: {
    en: 'Preview',
    es: 'Vista previa',
    ca: 'Vista prèvia',
    pt: 'Visualizar',
  },
  buttonChangeImage: {
    en: 'Change photo',
    es: 'Cambiar foto',
    ca: 'Canviar foto',
    pt: 'Mudar foto',
  },
  textImage: {
    en: 'Must not exceed 2 MB.',
    es: 'No debe exceder los 2 MB.',
    ca: 'No ha d\'excedir els 2 MB.',
    pt: 'Não deve exceder 2 MB.',
  },
  labelOccupation: {
    en: 'Occupation',
    es: 'Profesión',
    ca: 'Professió',
    pt: 'Profissão',
  },
  labelCompany: {
    en: 'Company',
    es: 'Empresa',
    ca: 'Empresa',
    pt: 'Companhia',
  },
  labelUrl: {
    en: 'Website',
    es: 'Página web',
    ca: 'Pàgina web',
    pt: 'Pagina web',
  },
  labelDescription: {
    en: 'Add a short summary of who you are',
    es: 'Agrega un pequeño resumen de quien eres',
    ca: 'Afegeix un petit resum de qui ets',
    pt: 'Adicione um breve resumo de quem você é',
  },
  labelSocial: {
    en: 'Add other contact links',
    es: 'Agregar otros links de contacto',
    ca: 'Afegir altres links de contacte',
    pt: 'Adicionar outros links de contato',
  },
  optionsSocial: [
    {
      name: 'twitter',
      placeholder: {
        en: 'Add Twitter',
        es: 'Agregar Twitter',
        ca: 'Afegir Twitter',
        pt: 'Adicionar Twitter',
      },
    },
    {
      name: 'facebook',
      placeholder: {
        en: 'Add Facebook',
        es: 'Agregar Facebook',
        ca: 'Afegir Facebook',
        pt: 'Adicionar Facebook',
      },
    },
    {
      name: 'instagram',
      placeholder: {
        en: 'Add Instagram',
        es: 'Agregar Instagram',
        ca: 'Afegir Instagram',
        pt: 'Adicionar Instagram',
      },
    },
    {
      name: 'linkedin',
      placeholder: {
        en: 'Add LinkedIn',
        es: 'Agregar LinkedIn',
        ca: 'Afegir LinkedIn',
        pt: 'Adicionar LinkedIn',
      },
    },
  ],
  errorMessage: {
    en: 'There are empty required fields. Please fix them before continuing.',
    es: 'Hay campos obligatorios vacíos en este formulario. Corrígelos antes de continuar.',
    ca: 'Hi ha camps obligatoris buits al formulari. Corregeix-los abans de continuar.',
    pt: 'Existem campos obrigatórios vazios no formulário. Corrija-os antes de continuar.',
  },
  acceptedConditions: {
    en: 'I have read and accept the terms and conditions and the Data Processing Act.',
    es: 'He leído y acepto las condiciones y la Ley de Tratamiento de Datos.',
    ca: 'He llegit i acepto les condiciones i la Llei de Tractament de Dades.',
    pt: 'li e aceito as condições e a Lei de Tratamento dos Dados.',
  },
  acceptedConditionsMusicMeets: {
    en: `Primavera Sound, S.L. is responsible for the processing of the data you provide us with on this form in order to be included in a professional directory of Primavera Pro. The legal basis for the processing of the personal data you provide is the consent you have given us. The personal data you provide will not be shared with third parties and, therefore, will not be transferred outside the European Economic Area. No automated decisions will be made and no profiling will take place. You may at any time exercise your data protection rights (access, rectification or deletion, limitation or opposition to their processing, as well as portability) by sending an email to privacy@primaverasound.es and, in particular, you may withdraw your consent at any time, without prejudice to the processing carried out prior to its withdrawal. You also have the right to lodge a complaint with the competent supervisory authority (in the case of Spain, the AEPD)`,
    es: 'Primavera Sound, S.L. es el responsable del tratamiento de los datos que nos facilites a través del presente formulario con la finalidad de integrarte en un directorio profesional del Primavera Pro. La base legal para el tratamiento de los datos personales que nos facilitas es el consentimiento que nos has otorgado. Los datos personales que nos facilites no serán compartidos con terceros y, por tanto, no serán transferidos fuera del Espacio Económico Europeo. Tampoco se tomarán decisiones automatizadas ni se procederá a la elaboración de un perfilado. Podrás ejercer en todo momento tus derechos en materia de protección de datos (acceso, rectificación o supresión, limitación u oposición a su tratamiento, así como portabilidad) dirigiéndote por correo electrónico a privacy@primaverasound.es y, en particular, podrás retirar tu consentimiento en cualquier momento, sin que ello perjudique a los tratamientos llevados a cabo con anterioridad a su retirada. Asimismo, tienes derecho a presentar una reclamación ante la autoridad de control competente (en el caso de España, la AEPD)',
    ca: `Primavera Sound, S.L. és el responsable del tractament de les dades que ens facilitis a través del present formulari amb la finalitat d'integrar-te en un directori professional del Primavera Pro. La base legal per al tractament de les dades personals que ens facilites és el consentiment que ens has atorgat. Les dades personals que ens facilitis no seran compartides amb tercers i, per tant, no seran transferides fora de l'Espai Econòmic Europeu. Tampoc es prendran decisions automatitzades ni es procedirà a l'elaboració d'un perfilat. Podràs exercir en tot moment els teus drets en matèria de protecció de dades (accés, rectificació o supressió, limitació o oposició al seu tractament, així com portabilitat) dirigint-te per correu electrònic a privacy@primaverasound.es i, en particular, podràs retirar el teu consentiment en qualsevol moment, sense que això perjudiqui els tractaments duts a terme amb anterioritat a la seva retirada. Així mateix, tens dret a presentar una reclamació davant l'autoritat de control competent (en el cas d'Espanya, l'AEPD)`,
  },
  buttonCancel: {
    en: 'Cancel',
    es: 'Cancelar',
    ca: 'Cancel·lar',
    pt: 'Cancelar',
  },
  buttonSubmit: {
    en: 'Save changes',
    es: 'Guardar cambios',
    ca: 'Desar canvis',
    pt: 'Guardar mudanças',
  },
  uploadingFile: {
    en: 'Uploading file...',
    es: 'Cargando archivo...',
    ca: 'Carregant arxiu...',
    pt: 'Carregamento de ficheiro...',
  },
  buttonLogout: {
    en: 'Log out',
    es: 'Cerrar sesión',
    ca: 'Tancar sessió',
    pt: 'Log out',
  },
  profileSaved: {
    en: 'Saved changes',
    es: 'Cambios guardados',
    ca: 'Canvis guardats',
    pt: 'Mudanças salvas',
  },
  linkSpotify: {
    en: 'Link your Spotify account',
    es: 'Vincula tu cuenta de Spotify',
    ca: 'Enllaça el teu compte de Spotify',
    pt: 'Vincule sua conta do Spotify',
  },
  unlinkSpotify: {
    en: 'Unlink your Spotify account',
    es: 'Desvincular tu cuenta de Spotify',
    ca: 'Desenllaça el teu compte de Spotify',
    pt: 'Desvincular sua conta do Spotify',
  },
};

export default translation;
