import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { useAuth } from '@primaveralabs/auth';
import { useRoutes } from '../../../../context/routesContext';
import { LinkWrapper } from '../../../../util/link';

import translateObj from '../../../../util/translateObj';

import Button from '../Button';
import { HeaderContext } from '../../../../context/headerContext';

const TopHeaderMainMenuItemsContainerMusicMeets = ({ name, params }) => {
  const { setAuthOpen } = useContext(HeaderContext);

  const { language, path } = useRoutes();
  const { token } = useAuth();

  if (name === 'loginMenuItems') {
    return (
      <>
        {params.map((item) => (
          <div key={item.key}>
            {!token && item.key === 'login' ? <Button text={translateObj(item.text, language)} headerButton color="outlineWhite" icon="font-icon1 icon-userlogin" onClickCallback={() => setAuthOpen('login')} /> : null}
            {token && item.key === 'profile' ? <Button text={translateObj(item.text, language)} headerButton color="outlineOrange" icon="font-icon1 icon-userlogin" link={item.link} parentPath={path} /> : null}
          </div>
        ))}
      </>
    );
  }

  return (
    <>
      {
        params.map((item) => (
          <div key={item.key} className="flex items-center sm:hidden pr-6">
            <LinkWrapper url={item.link} path={path}>
              <span className="text-white uppercase mx-2 text-16 tracking-px">{translateObj(item.text, language)}</span>
            </LinkWrapper>
          </div>
        ))
      }
    </>

  );
};

TopHeaderMainMenuItemsContainerMusicMeets.propTypes = {
  name: PropTypes.string,
  params: PropTypes.arrayOf(PropTypes.shape()),
};

TopHeaderMainMenuItemsContainerMusicMeets.defaultProps = {
  name: '',
  params: [],
};

export default TopHeaderMainMenuItemsContainerMusicMeets;
