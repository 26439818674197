import React, { useState } from 'react';
import PropTypes from 'prop-types';

// import LoadIcon from '../../../LoadIcon';
import SectionAddCardPreScreen from './OnDemandModalsSubComponents/SectionAddCardPreScreen';
import SectionAddCardFillForm from './OnDemandModalsSubComponents/SectionAddCardFillForm';

const OnDemandModalAddCard = (props) => {
  const {
    loadTokenForLS, handleOnDemandScreenStatus, translations, translations: { loggedModal: formText = {} } = {}, language, userSocialInfo: { profileObj: preFilledValues = {} } = {}, closeModal, handleModalLoggedStep,
  } = props;

  const [isInCardForm, setIsInCardForm] = useState(false);

  const handleAddCardScreen = (bool) => {
    setIsInCardForm(bool);
  };


  return (
    <>
      {
      isInCardForm
        ? <SectionAddCardFillForm handleModalLoggedStep={handleModalLoggedStep} />
        : <SectionAddCardPreScreen {...props} handleAddCardScreen={handleAddCardScreen} /> }
    </>

  );
};

OnDemandModalAddCard.propTypes = {
  language: PropTypes.string,
  loadTokenForLS: PropTypes.func.isRequired,
  handleOnDemandScreenStatus: PropTypes.func.isRequired,
  translations: PropTypes.shape().isRequired,
};

OnDemandModalAddCard.defaultProps = {
  language: 'en',
};

export default OnDemandModalAddCard;
