import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import translateObj from '../../../../util/translateObj';
import Link from '../../../../util/link';

// import { buttonClasses } from '../OnDemandUtils/onDemandUtils';
// import LoadIcon from '../../../LoadIcon';

const yellowBackground = { backgroundColor: 'rgba(245, 229, 27, 0.7)' };
const confirmButtonText = {
  ca: 'CONFIRMAR',
  es: 'CONFIRMAR',
  en: 'CONFIRM',
  pt: 'CONFIRM',
};
const otherOptionsButtonText = {
  ca: 'veure altres opcions',
  en: 'see other options',
  es: 'ver otras opciones',
  pt: 'see other options',
};

const OnDemandModalPay = (props) => {
  const {
    loadTokenForLS, history, imageUrl, otherOptionsButtonUrl, postVideoUrl, handleOnDemandScreenStatus, translations, translations: { loggedModal: formText = {} } = {}, language, userSocialInfo: { profileObj: preFilledValues = {} } = {}, closeModal,
  } = props;

  const backgroundStyle = {
    backgroundImage: imageUrl,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  };

  const payAndRedirectToVideo = () => {
    history.push(`/${language}${postVideoUrl[0] === '/' ? '' : '/'}${postVideoUrl}`);
  };

  return (
    <div className="backgroundImgGray">
      <div>
        <div
          className={`${'h-80'} w-full  sliderBackground`}
          style={backgroundStyle}
        />
      </div>
      <div className="w-full bg-white-dark py-2 ">
        <div className="py-2 px-4">
          <div>{translateObj(formText.payTitle, language)}</div>
          <div>{translateObj(formText.payDescription, language)}</div>
          <div>{translateObj(formText.payConditions, language)}</div>
        </div>
        <div className=" py-2 px-4" style={yellowBackground}>
          <div>{translateObj(formText.payConfirmationText1, language)}</div>
          <div>{translateObj(formText.payConfirmationText2, language)}</div>
        </div>
        <div className="flex justify-end px-4 pt-6 pb-4">
          <Link to={otherOptionsButtonUrl}>
            <div className="uppercase rounded-full bg-white-dark text-black font-americaMonoBold sm:w-full cursor-pointer  text-center px-12 py-4 md:mt-4 sm:mt-4 ">{translateObj(otherOptionsButtonText, language)}</div>
          </Link>
          <button type="button" onClick={payAndRedirectToVideo} className="uppercase rounded-full bg-gray200 text-black font-americaMonoBold sm:w-full cursor-pointer  text-center px-12 py-4 md:mt-4 sm:mt-4">{translateObj(confirmButtonText, language)}</button>
        </div>
      </div>
    </div>
  );
};

OnDemandModalPay.propTypes = {
  language: PropTypes.string,
  loadTokenForLS: PropTypes.func.isRequired,
  handleOnDemandScreenStatus: PropTypes.func.isRequired,
  translations: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
  imageUrl: PropTypes.string,
  otherOptionsButtonUrl: PropTypes.string,
  postVideoUrl: PropTypes.string,
};

OnDemandModalPay.defaultProps = {
  language: 'en',
  imageUrl: '',
  otherOptionsButtonUrl: '',
  postVideoUrl: '',
};

export default withRouter(OnDemandModalPay);
