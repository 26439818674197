import React, { useRef, useEffect } from 'react';

function useInterval(callback) {
  const intervalRef = useRef(null);
  const callbackRef = useRef();

  const start = (ms) => {
    if (intervalRef.current !== null) {
      return;
    }
    intervalRef.current = setInterval(() => {
      callbackRef.current();
    }, ms);
  };

  const stop = () => {
    if (intervalRef.current === null) {
      return;
    }
    clearInterval(intervalRef.current);
    intervalRef.current = null;
  };

  useEffect(() => {
    callbackRef.current = callback;
  }, []);

  return { start, stop };
}

export default useInterval;
