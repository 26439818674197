import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-responsive-modal';
import { withRouter } from 'react-router-dom';

import { useUser } from '../../context/userProfileContext';
import { translations } from './Login/LoginUtils/onDemandLoginSimpleTexts';

import SimpleOnDemandModalScreen from './OnDemand/SimplifiedOnDemandModals/SimpleOnDemandModalScreen';

const buttonStyle = { minHeight: '30px' };

const modalStyles = {
  modal: {
    background: 'rgba(0, 0, 0, 0)',
    boxShadow: 'none',
    width: '100vw',
    zIndex: 1005,
    // padding: '2rem',
  },
  overlay: {
    // background: 'rgba(0, 0, 0, 0.5)',

  },
  closeButton: {
    top: '18px',
    right: '18px',
  },
};

const FloatingArtistCardLoginButton = ({ buttonText, language, closeArtistCard }) => {
  const userProps = useUser();
  const [isModalOpen, setModalOpen] = useState(false);


  const onOpenModal = () => {
    // window.scrollTo(0, 0);
    setModalOpen(true);
  };

  const onCloseModal = () => {
    setModalOpen(false);
    closeArtistCard();
  };

  const handleOpenModal = () => {
    onOpenModal();
  };

  return (
    <div
      className="mt-4 rounded-full mx-4 py-4 flex cursor-pointer items-center font-americaMonoBold justify-center uppercase border-1 border-solid border-white text-white z-top"
      onClick={handleOpenModal}
      style={buttonStyle}
    >
      {<span className="font-icon icon-star text-white text-lg mr-2" />}
      <span className=" text-12 ">
        {buttonText}
      </span>
      <Modal open={isModalOpen} onClose={onCloseModal} center styles={modalStyles} closeOnOverlayClick focusTrapped={false}>
        <SimpleOnDemandModalScreen {...userProps} language={language} translations={translations} closeModal={onCloseModal} postVideoUrl="" postVideoRole="" sourceWeb="" />
      </Modal>
    </div>
  );
};

FloatingArtistCardLoginButton.propTypes = {
  buttonText: PropTypes.string,
  language: PropTypes.string,
  closeArtistCard: PropTypes.func.isRequired,
};

FloatingArtistCardLoginButton.defaultProps = {
  buttonText: 'add to favorites',
  language: 'en',
};

export default withRouter(FloatingArtistCardLoginButton);
