import React from 'react';
import PropTypes from 'prop-types';

import { useLocation, useHistory } from 'react-router-dom';

import { useRoutes } from '../../../context/routesContext';


const LanguageSelector = ({ languages }) => {
  const { language, path } = useRoutes();
  const { pathname } = useLocation();
  const history = useHistory();

  const handleChageLanguage = (lang) => {
    const newPathname = pathname.replace(path, lang);
    history.push(newPathname);
  };

  return (
    <div className="w-full flex items-center justify-end sm:justify-center">
      {languages.map((lang, index) => (
        <div key={lang.name} className="flex items-center justify-center">
          {index !== 0 && <div>|</div>}
          <div onClick={() => handleChageLanguage(lang.url)} className="cursor-pointer">
            <span className={`px-2 py-2 text-black uppercase ${lang.name === language ? 'font-americaMonoBold border-b-1 border-black' : 'font-americaMonoRegular'} text-12`}>{lang.name}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

LanguageSelector.propTypes = {
  languages: PropTypes.arrayOf(PropTypes.shape()),
};

LanguageSelector.defaultProps = {
  languages: {},
};

export default LanguageSelector;
