import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import useResponsiveUtility from '../../../../util/responsiveUtility';

const BurgerMenuButton = ({ onClickCallback }) => {
  const { deviceScreen } = useResponsiveUtility();
  const [open, setOpen] = useState(false);

  const topEl = useRef();
  const midEl = useRef();
  const bottomEl = useRef();
  const crossElTop = useRef();
  const crossElBottom = useRef();

  const top = topEl.current;
  const mid = midEl.current;
  const bottom = bottomEl.current;
  const crossTop = crossElTop.current;
  const crossBottom = crossElBottom.current;

  const openAnimation = () => {
    setOpen(!open);
    top.style.width = '0%';
    top.style.transition = 'width .2s ease-in';
    mid.style.width = '0%';
    mid.style.transition = 'width .2s ease-in .2s';
    bottom.style.width = '0%';
    bottom.style.transition = 'width .2s ease-in .4s';
    setTimeout(() => {
      crossTop.style.backgroundColor = '#ffffff';
      crossTop.style.transform = 'rotate(45deg) translateY(2px)';
      crossTop.style.transition = 'background-color .05s ease-in, transform .2s ease-in .05s';
      crossBottom.style.backgroundColor = '#ffffff';
      crossBottom.style.transform = 'rotate(-45deg) translateY(-2px)';
      crossBottom.style.transition = 'background-color .05s ease-in, transform .2s ease-in .05s';
    }, 400);
    onClickCallback();
  };

  const closeAnimation = () => {
    setOpen(!open);
    crossTop.style.backgroundColor = 'transparent';
    crossTop.style.transform = 'rotate(0deg) translateY(2px)';
    crossTop.style.transition = 'transform .2s ease-in, background-color .1s ease-in .2s';
    crossBottom.style.backgroundColor = 'transparent';
    crossBottom.style.transform = 'rotate(0deg) translateY(-2px)';
    crossBottom.style.transition = 'transform .2s ease-in, background-color .1s ease-in .2s';
    setTimeout(() => {
      top.style.width = '100%';
      top.style.transition = 'width .2s ease-in';
      mid.style.width = '24px';
      mid.style.transition = 'width .2s ease-in .2s';
      bottom.style.width = '20px';
      bottom.style.transition = 'width .2s ease-in .4s';
    }, 400);
    onClickCallback();
  };

  useEffect(() => {
    if (open) {
      closeAnimation();
    }
    setOpen(false);
  }, [deviceScreen]);

  return (
    <div
      className="flex flex-col justify-center w-30px h-full ml-4 relative cursor-pointer"
      onClick={open ? () => closeAnimation() : () => openAnimation()}
    >
      <div
        ref={topEl}
        className="h-3px bg-white rounded-sm mb-2"
        style={{
          width: '100%',
        }}
      />
      <div
        ref={midEl}
        className="h-3px bg-white rounded-sm"
        style={{
          width: '24px',
        }}
      />
      <div
        ref={bottomEl}
        className="h-3px bg-white rounded-sm mt-2"
        style={{
          width: '20px',
        }}
      />
      <div className="absolute pin w-full h-full flex flex-col justify-center">
        <div
          ref={crossElTop}
          className="h-3px rounded-sm w-full"
          style={{
            backgroundColor: 'transparent',
            transform: 'translateY(2px)',
          }}
        />
        <div
          ref={crossElBottom}
          className="h-3px rounded-sm w-full"
          style={{
            backgroundColor: 'transparent',
            transform: 'translateY(-2px)',
          }}
        />
      </div>
    </div>
  );
};

BurgerMenuButton.propTypes = {
  onClickCallback: PropTypes.func,
};

BurgerMenuButton.defaultProps = {
  onClickCallback: null,
};

export default BurgerMenuButton;
