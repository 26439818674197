import gql from 'graphql-tag';

import { clientWeb } from '../graphqlAPI';

const CREATE_NOTIFICATION = gql`
  mutation Post($type: String!, $relatedId: String!, $sender: String! $recipients: [String]!, $reminder: String) {
    createNotification(type: $type, relatedId: $relatedId, sender: $sender recipients: $recipients, reminder: $reminder ) {
      result
      error
      operation
    }
}`;

const createNotification = async (formData) => {
  try {
    const result = await clientWeb.mutate({
      mutation: CREATE_NOTIFICATION,
      variables: {
        ...formData,
      },
      fetchPolicy: 'no-cache',
    })
      .then(queryResult => queryResult)
      .catch((queryError) => {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error: ${queryError}`);
        return null;
      });

    const { data: { createNotification: validatedFormData } } = result;

    if (validatedFormData) {
      return validatedFormData;
    }
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: GraphQL Query Error: validatedFormData = null`);
    return null;
  } catch (error) {
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: createNotification Error: ${error}`);
    return null;
  }
};

export default createNotification;
