
import React from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import FilterNew from './Collapsible/FilterNew';
import ComponentIterator from '../../containers/ComponentIterator';

class FilterContainer extends React.Component {
  state = {
    values: [],
    activeValue: '',
    isLoading: true,
  }

  componentDidMount() {
    const {
      location: { pathname }, history, match: { params }, components, compId,
    } = this.props;
    let { activeValue } = this.state;
    const values = components.map(comp => comp.name.value);
    const query = this.getQueryVariable();
    if (!query || (query && !values.find(val => query.includes(val)))) {
      if (components.length) {
        activeValue = components[0].name.value;
      }
    } else {
      activeValue = values.find(val => query.includes(val));
    }
    if (components.length) {
      activeValue = components[0].name.value;
    }
    this.setState({ values, activeValue, isLoading: false });
  }

  componentDidUpdate(prevProps, prevState) {
    const { activeValue } = this.state;
    const { location: { search } } = this.props;
    // if (activeValue === prevState.activeValue && search !== prevProps.location.search) {
    //   this.updatePath();
    // }
  }

  componentWillUnmount() {
    // this.cleanPath();
  }

  onHandleCollapsibleMenuSelect = (clickedEvent) => {
    const { activeValue } = this.state;
    if (activeValue !== clickedEvent) {
      this.setState({ activeValue: clickedEvent });
    }
    // this.pushToPath(clickedEvent);
  };

  pushToPath = (clickedEvent) => {
    const { values } = this.state;
    const query = this.getQueryVariable();
    const {
      location: { pathname }, history,
    } = this.props;
    const filters = query;
    if (!query && clickedEvent) {
      history.push(`${pathname}?e=${clickedEvent}`);
    }
    if (query && clickedEvent && !query.includes(clickedEvent)) {
      const lastValue = values.find(val => query.includes(val));
      const lastIndex = query.indexOf(lastValue);
      if (lastIndex > -1) {
        filters[lastIndex] = clickedEvent;
      } else {
        filters.push(clickedEvent);
      }
      history.push(`${pathname}?e=${filters.join(',')}`);
    }
  }

  updatePath = () => {
    const { values } = this.state;
    const query = this.getQueryVariable();
    if (query) {
      const activeValue = values.find(val => query.includes(val));
      if (activeValue) {
        this.setState({ activeValue });
      }
    }
  }

  cleanPath = () => {
    const { values } = this.state;
    const query = this.getQueryVariable();
    const {
      location: { pathname }, history,
    } = this.props;
    const filters = query;
    if (query) {
      const activeValue = values.find(val => query.includes(val));
      const activeIndex = query.indexOf(activeValue);
      if (activeIndex > -1) {
        filters.splice(activeIndex, 1);
        history.push(`${pathname}?e=${filters.join(',')}`);
      }
    }
  }

  getQueryVariable = () => {
    const { location: { search } } = this.props;
    if (search) {
      const filters = search.split('=');
      if (filters.length > 1 && filters[1]) {
        const vars = filters[1].split(',');
        return vars;
      }
    }
    return null;
  }

  handleFilter = () => {
    const { activeValue } = this.state;
    const { components } = this.props;
    let compsWithFilterFlag = [];
    const filtered = components.filter(comp => comp.name.value === activeValue);
    if (filtered && filtered.length) {
      compsWithFilterFlag = filtered[0].components.map((comp) => {
        const compWithFlag = { ...comp };
        compWithFlag.hasFilter = true;
        return compWithFlag;
      });
    }
    if (compsWithFilterFlag.length) {
      return compsWithFilterFlag;
    }
    return [];
  }

  render() {
    const {
      isLoading, activeValue,
    } = this.state;
    const {
      compId, components, filterText, filterHeading,
    } = this.props;
    return (
      <React.Fragment>
        {!isLoading
        && (
        <>
          <FilterNew noCollapse checkedItem={activeValue} filterHeading={filterHeading} filterText={filterText} filterTextcheckedItem={activeValue} compId={compId} components={components} onHandleCollapsibleMenuSelect={this.onHandleCollapsibleMenuSelect} radio />
          <ComponentIterator components={this.handleFilter()} />
        </>
        )
        }
      </React.Fragment>
    );
  }
}

FilterContainer.propTypes = {
  components: PropTypes.arrayOf(PropTypes.shape({})),
  filterText: PropTypes.shape({}),
  filterHeading: PropTypes.shape({}),
};

FilterContainer.defaultProps = {
  components: [],
  filterText: null,
  filterHeading: null,
};

export default withRouter(FilterContainer);
