import React from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import stripTags from '../helpers/stripTags';
import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';
import imageModifier from '../helpers/imageModifier';
import RadioHeader1 from './RadioHeader1';
import translateObj from '../../../util/translateObj';

const SidebarChannelCard = ({
  match, program_title_multilang: programTitle, text_multilang: textMultilang, dateAir, image, header, channel,
}) => (
  <RoutesContext.Consumer>
    {({ language: contextLanguage, enteredFrom }) => {
      const localization = getLocalization(contextLanguage, enteredFrom, match);
      moment.locale(localization.language);

      return (
        <div className="w-full bg-black rounded-lg mb-4">
          <RadioHeader1 {...header} channel={`ch${channel}`} />
          <div className="relative text-white">
            <div className="relative launcherGradientContainer">
              <div
                className="relative launcherGradientContainer h-64"
                style={{
                  backgroundImage: channel !== 3 ? `url(${imageModifier(image, '445x260')})` : `url(${image})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center center',
                }}
              />
            </div>

            <div className="p-4 pb-6">
              <span className="font-americaMonoRegular text-12">{moment(dateAir).format('DD / MM / YY')}</span>
              <h2 className="font-americaBlack text-white text-xl mt-1 mb-2">
                {translateObj(programTitle, localization.language)}
              </h2>
              <p className="font-tiemposRegular mb-8 block text-sm leading-normal">{stripTags(translateObj(textMultilang, localization.language)).substring(0, 190)}</p>
            </div>
          </div>
        </div>
      );
    }}
  </RoutesContext.Consumer>
);

SidebarChannelCard.propTypes = {
  match: PropTypes.shape({}).isRequired,
  title_multilang: PropTypes.shape({}),
  program_title_multilang: PropTypes.shape({}),
  subtitle_multilang: PropTypes.shape({}),
  text_multilang: PropTypes.shape({}),
  dateAir: PropTypes.string,
  header: PropTypes.shape({}).isRequired,
  image: PropTypes.string.isRequired,
  slug: PropTypes.string,
  tags_array_multilang: PropTypes.arrayOf(PropTypes.shape({})),
  channel: PropTypes.number.isRequired,
};

SidebarChannelCard.defaultProps = {
  dateAir: null,
  slug: '',
  text_multilang: {
    en: '',
    es: '',
    ca: '',
  },
  program_title_multilang: {
    en: '',
    es: '',
    ca: '',
  },
  title_multilang: {
    en: '',
    es: '',
    ca: '',
  },
  subtitle_multilang: {
    en: '',
    es: '',
    ca: '',
  },
  tags_array_multilang: [],
};

export default withRouter(SidebarChannelCard);
