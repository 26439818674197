import React, { PureComponent } from 'react';

import RadioTunerCard from './RadioTunerCard';
import withRadioPlayerContext from '../../../context/withRadioPlayerContext';

class ChannelTuner extends PureComponent {
  render() {
    const { ch1, ch2 } = this.props;
    return (
      <div className="flex flex-wrap bg-black py-7 sm:py-0 sm:px-0 px-5 -mx-2 w-full">
        {ch1 && <RadioTunerCard {...ch1} url="/radio/rps" />}
        {ch2 && <RadioTunerCard {...ch2} url="/radio/primavera-sounds" />}
      </div>
    );
  }
}

ChannelTuner.propTypes = {};

export default withRadioPlayerContext(ChannelTuner);
