import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';
import imageModifier from './helpers/imageModifier';
import ParagraphLineSM from './Text/ParagraphLineSM';
import translateObj from '../../util/translateObj';
import downloadImg from '../../util/downloadImg';

const handleTitle = title => (title.length > 45 ? `${title.substring(0, 45)}...` : title);

const Image = ({
  fileReadableName, url, match, handleClick, tags, i, isInGrid, description, hideDownloadButton,
}) => (
  <RoutesContext.Consumer>
    {
      ({ language: contextLanguage, enteredFrom }) => {
        const localization = getLocalization(contextLanguage, enteredFrom, match);
        const backgroundStyle = {
          height: '260px',
          background: '#a8a8a8',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center 2rem',
          backgroundImage: `url(${url})`,
          backgroundSize: '80%',
        };
        const zoomText = {
          en: 'Zoom',
          es: 'ampliar',
          ca: 'ampliar',
        };
        const downloadText = {
          en: 'Download',
          es: 'Descargar',
          ca: 'descarregar',
        };
        return (
          <div className={`${isInGrid ? 'w-full md:w-1/2 lg:w-1/3 xl:w-1/4 py-2 px-2 sm:px-0' : ''}`}>
            <div
              className="cursor-pointer mb-2"
              tabIndex="0"
              onClick={() => handleClick(i)}
              role="link"
              aria-label={translateObj(fileReadableName, localization.language)}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  handleClick(i);
                }
              }}
            >
              <p className="font-americaBlack text-xs flex items-end pb-2 h-12">{translateObj(fileReadableName, localization.language) ? handleTitle(translateObj(fileReadableName, localization.language)) : ''}</p>
              {tags.includes('material')
                ? (
                  <div
                    className="relative pt-8 overflow-hidden"
                    style={backgroundStyle}
                  />
                )
                : (
                  <div className="relative">
                    <img className="block w-full" src={imageModifier(url, '502x376')} alt={fileReadableName.en} />
                  </div>
                )
              }
            </div>
            <div className="flex justify-between">
              <div
                onClick={() => handleClick(i)}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    handleClick(i);
                  }
                }}
                role="link"
                tabIndex="0"
                aria-label={translateObj(zoomText, localization.language)}
              >
                <ParagraphLineSM classes="cursor-pointer uppercase font-bold text-xxs" iconClass="font-icon icon-zoom" text={zoomText} tabIndex="-1" />
              </div>
              {hideDownloadButton ? null : (
                <a
                  onClick={() => downloadImg(url, translateObj(description, localization.language) || translateObj(fileReadableName, localization.language))}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      downloadImg(url, translateObj(description, localization.language) || translateObj(fileReadableName, localization.language));
                    }
                  }}
                  className="text-black"
                  role="link"
                  tabIndex="0"
                  aria-label={translateObj(downloadText, localization.language)}
                >
                  <ParagraphLineSM classes="cursor-pointer uppercase font-bold text-xxs" iconClass="font-icon icon-download1" text={downloadText} tabIndex="-1" />
                </a>
              )}
            </div>
          </div>
        );
      }
    }
  </RoutesContext.Consumer>
);

Image.propTypes = {
  fileReadableName: PropTypes.shape({}),
  description: PropTypes.shape({}),
  handleClick: PropTypes.func.isRequired,
  match: PropTypes.shape({}).isRequired,
  url: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
  isInGrid: PropTypes.bool,
  i: PropTypes.number,
  hideDownloadButton: PropTypes.bool,
};

Image.defaultProps = {
  fileReadableName: {},
  description: {},
  tags: [],
  isInGrid: true,
  i: 0,
  hideDownloadButton: false,
};

export default withRouter(Image);
