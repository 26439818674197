import React from 'react';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import { Link } from 'react-scroll';
import { withRouter } from 'react-router-dom';
import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';
import ComponentIterator from '../../../containers/ComponentIterator';
import PSResponsiveUtility from '../LineUp/PSResponsiveUtility';
import translateObj from '../../../util/translateObj';

const ExpandLess = () => <span className="font-icon icon-arrow-up text-white text-sm" />;
const ExpandMore = () => <span className="font-icon icon-arrow-down text-white text-sm" />;
const ExpandMoreB = () => <span className="font-icon icon-arrow-down text-black text-sm" />;

const style = {
  list: {
    padding: 0,
    marginBottom: 30,
  },
};

const getStyleContainer = (selected, listItemStyle) => {
  if (selected) {
    return {
      backgroundColor: 'black',
    };
  }
  if (listItemStyle) {
    return listItemStyle;
  }
};

const getStyleText = (selected, listItemStyle) => {
  if (selected) {
    return {
      color: 'white',
    };
  }
  return listItemStyle;
};

const stickyStyle = (sticky, offsetLeft, desktop, sidebarWidth, stickToBottom) => {
  if (!desktop) return {};

  if ((sticky || stickToBottom)) {
    return {
      left: offsetLeft,
      width: `${sidebarWidth}px`,
      transition: 'top 0,3s',
    };
  }

  return {
    width: `${sidebarWidth}px`,
    paddingRight: '2rem',
    marginRight: '16px',
  };
};

const ANCHOR_PARAM = 'e';
const FOOTER_ID = 'footer-main';

const scrollToAnchor = (id) => {
  const isMobile = window.innerWidth < 993;

  const anchor = `${id}${isMobile ? '-mobile' : ''}`;
  const element = document.getElementById(anchor);

  if (!element) return;

  const headerOffset = isMobile ? 0 : -200;
  const elementPosition = element.getBoundingClientRect().top;
  const offsetPosition = elementPosition - headerOffset;

  window.scrollTo({
    top: offsetPosition,
    behavior: 'smooth',
  });
};

class CollapsibleListPage extends React.Component {
  state = {
    sticky: false,
    stickToBottom: false,
    offsetLeft: 0,
    isOpen: true,
    currentSection: '',
    sidebarWidth: 0,
    dropDown: '',
    deviceWidth: null,
    isMobile: false,
    collapsiblePositionY: 0,
  };

  offsetRef = React.createRef();

  responsiveUtility = React.createRef();

  componentDidMount() {
    const { params } = this.props;
    window.addEventListener('scroll', this.handleScroll);
    this.handleResize();
    const currentSection = params.length ? params[0][0].anchor : '';
    this.setState({
      currentSection,
      sidebarWidth: this.offsetRef && this.offsetRef.current ? this.offsetRef.current.clientWidth : 0,
    });
    this.setSidebarPosition();

    const urlParams = typeof window === 'undefined' ? null : new URLSearchParams(window.location.search);
    const id = urlParams?.get(ANCHOR_PARAM);

    if (id) {
      this.handleClick(id);
      this.handleCollapse(id);
      scrollToAnchor(id);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  setSidebarPosition = () => {
    if (this.offsetRef && this.offsetRef.current && this.offsetRef.current.offsetParent) {
      const currentOffsetLeft = this.offsetRef.current.offsetLeft;
      const currentParentOffsetLeft = this.offsetRef.current.offsetParent.offsetLeft;
      const offsetLeft = currentOffsetLeft + currentParentOffsetLeft;
      this.setState({
        offsetLeft,
      });
    }
  };

  // Update state to match window's scroll position whenever it changes.
  handleScroll = () => {
    // NOTE: this ugly hack prevents only on mobile the mutiple mount/unmounts that happens when scrolling
    const deviceScreen = this.responsiveUtility.current.deviceScreen();
    if (['md', 'sm'].includes(deviceScreen)) return;
    const wScroll = window.scrollY;
    const { params } = this.props;
    const { collapsiblePositionY } = this.state;

    const list = this.offsetRef.current?.firstChild?.getBoundingClientRect();

    const footer = document.getElementById(FOOTER_ID)?.getBoundingClientRect();

    if (!list || !footer) return;

    const listBottom = list.bottom;
    const footerTop = footer.top;

    const isFooterVisible = footerTop < window.innerHeight;

    if (params.length > 1) {
      if (wScroll < collapsiblePositionY) {
        this.setState({ sticky: false, stickToBottom: false });
      } else if (params.length > 3 && listBottom >= footerTop) {
        this.setState({ stickToBottom: true, sticky: false });
      } else if (!isFooterVisible) {
        this.setState({ sticky: true, stickToBottom: false });
      }
    }
  };

  handleResize = () => {
    this.setSidebarPosition();

    const collapsiblePositionY = this.offsetRef?.current.getBoundingClientRect().top + window.scrollY;

    const deviceWidth = this.responsiveUtility.current.deviceWidth(true);
    if (deviceWidth > 1143) {
      this.setState({
        sidebarWidth: this.offsetRef.current.clientWidth,
      });
    }
    this.setState({
      deviceWidth,
      collapsiblePositionY,
    });
  };

  handleDeviceScreen = () => {
    const deviceScreen = this.responsiveUtility.current.deviceScreen();
    const isMobile = deviceScreen === 'sm';
    this.setState({
      isMobile,
    });
  }

  handleCollapse = (slugName) => {
    const { currentSection, isOpen } = this.state;
    if (currentSection) {
      if (currentSection === slugName) {
        this.setState({
          isOpen: !isOpen,
        });
      } else {
        this.setState({
          currentSection: slugName,
          isOpen: true,
        });
      }
    } else {
      this.setState({
        currentSection: slugName,
      });
    }
  };

  handleClick = (option) => {
    this.setState({
      dropDown: option,
    });
  };

  handleMobileParamsToRemoveMediaTitle = (param = []) => {
    const { isMobile } = this.state;
    const paramAdjusted = [...param];
    if (isMobile && param[0].anchor && param[1].type === 'MediaTitle') {
      paramAdjusted.splice(1, 1);
    }
    return paramAdjusted;
  }

  render() {
    const {
      params, classes, color, match,
    } = this.props;
    const listItemStyle = color === 'white'
      ? {
        backgroundColor: 'rgba(255,255,255,1)',
      }
      : {};
    const {
      sticky, isOpen, offsetLeft, sidebarWidth, currentSection, dropDown, deviceWidth, stickToBottom,
    } = this.state;
    const deviceScreen = this.responsiveUtility?.current?.deviceScreen() || '';
    return (
      <RoutesContext.Consumer>
        {({ language: contextLanguage, enteredFrom }) => {
          const localization = getLocalization(contextLanguage, enteredFrom, match);
          return (
            <div role="navigation" className="w-full xl:pr-8 lg:pr-8" ref={this.offsetRef}>
              <PSResponsiveUtility ref={this.responsiveUtility} deviceWidthCallback={this.handleResize} deviceScreenCallback={this.handleDeviceScreen} />
              <List classes={{ root: classes.list }} className={`collapsible-sidebar ${sticky && deviceWidth > 993 ? 'sticky-sidebar sticky-top w-ticket-lg pr-8' : ''} ${stickToBottom && deviceWidth > 993 ? 'sticky-sidebar sticky-bottom w-ticket-lg pr-8' : ''} `} style={{ ...stickyStyle(sticky, offsetLeft, deviceWidth > 993, sidebarWidth, stickToBottom) }}>
                {params.map((param, i) => (
                  <React.Fragment key={param[0].anchor || `key${i}`}>
                    <Link
                      activeClass="active"
                      to={param[0].anchor || `key${i}`}
                      spy
                      smooth
                      offset={-120}
                      duration={500}
                      onClick={() => {
                        this.handleClick(param[0].anchor || `key${i}`);
                        this.handleCollapse(param[0].anchor || `key${i}`);
                      }}
                    >
                      <ListItem
                        id={`${param[0].anchor}-mobile`}
                        style={{
                          ...getStyleContainer((deviceWidth < 993 && dropDown === param[0].anchor) || (deviceWidth < 993 && param[0].anchor === currentSection), listItemStyle),
                          marginBottom: 10,
                          padding: 15,
                        }}
                        value={param[0].anchor}
                        button
                      >
                        <ListItemText
                          primary={(
                            <div className="font-americaMonoBold uppercase" style={getStyleText((deviceWidth < 993 && dropDown === param[0].anchor) || (deviceWidth < 993 && param[0].anchor === currentSection), null)}>
                              { param[0].type !== 'Separator'
                                ? (
                                  <div className="flex">
                                    <span aria-hidden className="mr-2">\</span>
                                    <span className="pr-4">{translateObj(param[0].primaryText, localization.language)}</span>
                                  </div>
                                )
                                : (
                                  <div className="flex">
                                    <span aria-hidden className="mr-2">\</span>
                                    {param[1] && param[1].primaryText && <span className="pr-4">{translateObj(param[1].primaryText, localization.language)}</span>}
                                  </div>
                                )}
                              {' '}

                            </div>
)}
                        />
                        <span className="mr-4 sm:mr-0 lg:hidden xl:hidden">{dropDown === param[0].anchor ? isOpen ? <ExpandLess /> : <ExpandMore /> : param[0].anchor === currentSection ? <ExpandLess /> : <ExpandMoreB />}</span>
                      </ListItem>
                    </Link>

                    <Collapse in={(dropDown === param[0].anchor && isOpen) || (param[0].anchor === currentSection && isOpen)} timeout="auto">
                      <div className="hidden sm:block md:block">
                        {
                          (!['md', 'sm'].includes(deviceScreen)) ? null : <ComponentIterator components={this.handleMobileParamsToRemoveMediaTitle(param)} />
                        }
                      </div>
                    </Collapse>
                  </React.Fragment>
                ))}
              </List>
            </div>
          );
        }}
      </RoutesContext.Consumer>
    );
  }
}

CollapsibleListPage.propTypes = {
  classes: PropTypes.shape({}),
  color: PropTypes.string,
  params: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({}))).isRequired,
};

CollapsibleListPage.defaultProps = {
  classes: {},
  color: '',
};

export default withRouter(withStyles(style)(CollapsibleListPage));
