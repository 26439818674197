import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { usePost, useRoutes } from '../../../context/routesContext';
import { LinkWrapper } from '../../../util/link';

import translateObj from '../../../util/translateObj';

import translation from './translations/UserPanel';
import 'Primavera/ui-themes/dist/primavera/index.css';
import { withRouter } from 'react-router-dom';
import { useAuth } from '@primaveralabs/auth';

export const REDIRECT_URL_SLUGNAME = 'my-primavera'

const UserPanel = ({
  history,
  children = {},
  title,
  favourites,
  profile,
  rewards,
  notifications,
}) => {
  const [mobileMenu, setMobileMenu] = useState(false);
  const { user, actions } = useAuth();
  const { language, path } = useRoutes();

  const separator = (
    <div className="mx-6 w-px h-3 bg-white sm:bg-transparent" />
  );
  const anchorClass =
    'text-white cursor-pointer hover:border-b hover:border-white uppercase';

  useEffect(() => {
    if (!user) history.push(`/${language}/${REDIRECT_URL_SLUGNAME}`)
  }, [user])

  if (!user) return null

  return (
    <div
      id="userPanel"
      className="bg-panel-black flex flex-col w-full relative h-screen sm:h-full md:h-full full-width"
    >
      <div className="m-12 sm:m-30px" style={{ paddingBottom: 120 }}>
        <div className="flex justify-between items-center text-white mb-2">
          <h1 className="font-americaBold text-2rem sm:text-3xl">
            {translateObj(title, language)}
          </h1>
          <div
            className="sm:block hidden font-icon p-5 icon-arrow-down text-xxs text-white bold"
            style={{
              transform: mobileMenu ? 'rotate(-180deg)' : '',
              transition: 'transform 200ms ease-in',
            }}
            onClick={() => setMobileMenu(!mobileMenu)}
          />
          <div className="sm:hidden">
            <button
              className="text-white border-white border-1 rounded-full text-xs uppercase text-center py-1 px-4"
              onClick={() => {
                actions.logout().then(() => {
                  localStorage.removeItem('auth-token');
                  history?.push(`/${language}`);
                });
              }}
            >
              {translateObj(translation.logOut, language)}
            </button>
          </div>
        </div>
        <div
          className={`${
            mobileMenu ? 'sm:hidden' : ''
          } font-americaMonoBold text-white uppercase flex items-center sm:flex-col sm:items-start`}
        >
          <LinkWrapper url={`/user-panel/favourites`} path={path}>
            <span className={anchorClass}>
              {translateObj(favourites, language)}
            </span>
          </LinkWrapper>
          {separator}
          <LinkWrapper url={`/user-profile`} path={path}>
            <span className={anchorClass}>
              {translateObj(profile, language)}
            </span>
          </LinkWrapper>
          {separator}
          <LinkWrapper url={`/user-panel/rewards`} path={path}>
            <span className={anchorClass}>
              {translateObj(rewards, language)}
            </span>
          </LinkWrapper>
          {/* TODO: notifications page temporary hidden */}
          {/* {separator}
          <LinkWrapper url={`/user-panel/notifications`} path={path}>
            <span className={anchorClass}>
              {translateObj(notifications, language)}
            </span>
          </LinkWrapper> */}
        </div>
        <div className="sm:mt-8 sm:block md:hidden lg:hidden xl:hidden xxl:hidden">
          <button
            className="text-white border-white border-1 rounded-full text-xs uppercase text-center py-1 px-4"
            onClick={() => {
              return actions.logout().then(() => {
                localStorage.removeItem('auth-token');
                history?.push(`/${language}`);
              });
            }}
          >
            {translateObj(translation.logOut, language)}
          </button>
        </div>
      </div>
      {children}
    </div>
  );
};

UserPanel.propTypes = {
  children: PropTypes.shape().isRequired,
  title: PropTypes.shape(),
};

UserPanel.defaultProps = {
  title: translation.title,
  favourites: translation.favourites,
  profile: translation.profile,
  rewards: translation.rewards,
  notifications: translation.notifications,
};

export default withRouter(UserPanel);
