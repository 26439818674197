import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useRoutes } from '../../../context/routesContext';
import useQuery from './util/hooks/useQuery';

import translateObj from '../../../util/translateObj';
import getTranslation from './util/translations/_getTranslation';
import localTranslation from './util/translations/ReunionInvitationSent';

import getReunion from '../../../api/graphql/queries/getReunion';

import Button from './Button';
import ReunionItem from './ReunionItem';
import HeadTitle from './HeadTitle';


const ReunionInvitationSent = ({
  translation,
}) => {
  const literals = getTranslation(translation, localTranslation);
  const { language } = useRoutes();
  const query = useQuery();
  const [reunion, setReunion] = useState(null);

  const loadReunionData = (id) => {
    getReunion(id).then((res) => {
      setReunion(res);
    }).catch(err => console.log(err));
  };

  useEffect(() => {
    const id = query.get('id');
    if (id) {
      loadReunionData(id);
    }
  }, []);

  return (
    <>
      <HeadTitle title={translateObj(literals.title, language)} />
      <div className="w-full flex sm:flex-col pt-12 pb-12 md:pt-8 sm:pt-0 sm:pb-8">
        <div className="w-4/12 sm:w-full md:w-full">
          <div className="w-pro-reunion sm:w-auto md:w-auto xl:w-auto">
            {reunion && <ReunionItem {...reunion} invitation />}
          </div>
        </div>
        <div className="w-full pl-1/12 sm:pl-0">
          <div className="text-14 font-timesRegular border-b border-black pb-8 sm:pb-6 sm:mt-3">
            <div className="w-1/2 md:w-full sm:w-full">{translateObj(literals.text, language)}</div>
          </div>
          <div className="text-14 font-timesRegular mt-8">
            {translateObj(literals.labelNewReunion, language)}
          </div>
          <div className="flex justify-start items-strech mt-4">
            <Button text={translateObj(literals.buttonNewReunion, language)} plusIcon color="black" link="/pro-create-reunion" />
          </div>
        </div>
      </div>
    </>
  );
};

ReunionInvitationSent.propTypes = {
  translation: PropTypes.shape(),
};

ReunionInvitationSent.defaultProps = {
  translation: {},
};

export default ReunionInvitationSent;
