import gql from 'graphql-tag';

import { clientRadio, persistor } from '../graphqlAPI';

// TODO: need to change parentTitle_multilang for program_title_multilang (Mirko to change this when available)

const GET_RADIO_SHOWS_EPISODES_FOR_EPISODE = gql`
  query radioPrograms($parentSlug: String, $limit: Int) {
    radioPrograms(parentSlug: $parentSlug, limit: $limit) {
      channel
      id
      dateAir
      parentSlug
      slug
      mixcloud
      duration
      title_multilang {
        ca
        en
        es
      }
      text_multilang {
        ca
        en
        es
      }
      subtitle_multilang {
        ca
        en
        es
      }
      tags_array_multilang {
        ca
        en
        es
      }
      parentTitle_multilang {
        ca 
        en 
        es
      }
      image
      mp3
      fileSize
    }
  }
`;

const executeQuery = async ({ parentSlug, limit }, type = 'network') => {
  if (!process.env.RADIO_GRAPHQL_SERVER_URL) {
    return [];
  }

  const result = await clientRadio.query({
    query: GET_RADIO_SHOWS_EPISODES_FOR_EPISODE,
    variables: { parentSlug, limit },
    errorPolicy: 'all',
    fetchResults: true,
    fetchPolicy: type === 'network' ? 'network-only' : 'cache-only',
  })
    .then(queryResult => queryResult)
    .catch((queryError) => {
      const errorMessage = type === 'network'
        ? `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error: ${queryError}`
        : `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error after Cache Restore: ${queryError}`;
      console.log('\x1b[31m%s\x1b[0m', errorMessage);

      return null;
    });

  const { data: { radioPrograms: episodes } } = result;

  if (episodes) {
    return episodes;
  }
  console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: GraphQL Query Error: episodes = null`);
  return null;
};

const getRadioShowsEpisodesForEpisode = async (vars) => {
  try {
    const episodes = await executeQuery(vars);

    if (persistor) {
      persistor.persist();
    }

    return episodes;
  } catch (jsonError) {
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: JSON Object Error: ${jsonError}`);

    if (persistor) {
      const persistorStatus = await persistor.restore().then(() => 'OK');
      console.log(`${new Date().toLocaleTimeString()} ~ Client: Apollo Cache Restored - ${persistorStatus}`);

      try {
        const episodes = await executeQuery(vars, 'cache');

        return episodes;
      } catch (jsonErrorAfterCacheRestore) {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: JSON Object Error after Cache Restore, sending 404: ${jsonErrorAfterCacheRestore}`);
        return null;
      }
    }
    return null;
  }
};

export default getRadioShowsEpisodesForEpisode;
