import React from 'react';
import PropTypes from 'prop-types';

import { useRoutes } from '../../../../context/routesContext';
import { LinkWrapper } from '../../../../util/link';

import translateObj from '../../../../util/translateObj';

const TopHeaderSubmenuItemsContainerMusicMeets = ({ params }) => {
  const { language, path } = useRoutes();

  return (
    <div className="pr-4 flex items-center">
      {
        params.map((item, index) => {
          if (item.key === 'search') {
            return (
              <LinkWrapper key={item.key} url={item.link} path={path}>
                <div className="text-black uppercase text-12 flex items-center sm:py-1 py-2">
                  <div className="font-icon icon-search sm:mr-0 mr-2 sm:text-18 text-14 tracking-px" />
                  <div className="sm:hidden">{translateObj(item.text, language)}</div>
                </div>
              </LinkWrapper>
            );
          }
          return (
            <div key={item.key} className="sm:hidden flex items-center py-2">
              {index !== 0 && <div className="text-black uppercase mx-1 text-12 tracking-px">|</div>}
              <LinkWrapper url={item.link} path={path}>
                <span className="text-black uppercase mx-1 text-12 tracking-px">{translateObj(item.text, language)}</span>
              </LinkWrapper>
            </div>
          );
        })
      }
    </div>

  );
};

TopHeaderSubmenuItemsContainerMusicMeets.propTypes = {
  params: PropTypes.arrayOf(PropTypes.shape()),
};

TopHeaderSubmenuItemsContainerMusicMeets.defaultProps = {
  params: [],
};

export default TopHeaderSubmenuItemsContainerMusicMeets;
