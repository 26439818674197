import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { useTheme } from '../context/themeContext';
import { useChat } from '../context/chatContext';
import { hashCode, inviteToPrivateCall, updatePendingPrivateCall } from '../utils/helpers';
import { usePrivatePlusZoneContext } from '../context/privatePlusOneZoneContext';

const ViewerCard = ({
  messageOwner, nicknameColor, handleSendInvitation, closeWhisperModal, buttonText, isAuth, handleMute, handleUnMute,
}) => {
  const { theme } = useTheme();
  const {
    mutedUsers, activeVideoCall, showVideoRoomButton, userData,
  } = useChat();
  const [isMuted, setIsMuted] = useState(false);
  const { openPrivateChat } = usePrivatePlusZoneContext();
  const [inActiveVideoCall, setCallStatus] = useState(false);
  const [inPendingVideoCall, setPendingCallStatus] = useState(false);

  useEffect(() => {
    const iAmMuted = mutedUsers.some(id => id === messageOwner.userId);
    setIsMuted(iAmMuted);
  }, [mutedUsers]);

  useEffect(() => {
    if (activeVideoCall) {
      const messageOwnerIsInCall = activeVideoCall.participants.some(participant => participant === messageOwner.userId);
      const messageOwnerIsPendingInCall = activeVideoCall.pending.some(participant => participant === messageOwner.userId);
      setCallStatus(messageOwnerIsInCall);
      setPendingCallStatus(messageOwnerIsPendingInCall);
    }
  }, [activeVideoCall]);

  const handleSendCallInvitation = async () => {
    let activeRoom;
    let url;
    const roomNickname = `#userInfo.displayName="${userData.nickname}"`;
    if (!activeVideoCall) {
      activeRoom = hashCode(userData.nickname);
      url = `https://plusonezone.primaverasound.com/${activeRoom}${roomNickname}`;
      await inviteToPrivateCall({ room: activeRoom, inviter: userData, userId: messageOwner.userId });
      setCallStatus(true);
      openPrivateChat(url);
      closeWhisperModal();
    } else {
      activeRoom = activeVideoCall.room;
      if (activeRoom) {
        url = `https://plusonezone.primaverasound.com/${activeRoom}${roomNickname}`;
        await updatePendingPrivateCall({ room: activeRoom, userId: messageOwner.userId, type: 'add' });
        setCallStatus(true);
        closeWhisperModal();
      }
    }
  };

  return (
    <div className={`text-base bg-${theme}-box absolute z-10 pin-t pin-l w-full`}>
      <div
        className="absolute pin-t pin-r color-white font-icon icon-close cursor-pointer mr-2 mt-1"
        onClick={closeWhisperModal}
      />
      <div className="flex items-center p-4 border-b-4 border-black">
        <div className="avatar w-16 h-16 rounded-full flex items-center text-center justify-center mr-4" style={{ background: nicknameColor }}>
          <span
            className="font-icon1 icon-userlogin font-semibold text-xl"
          />
        </div>
        <h2>{messageOwner.nickname}</h2>
      </div>
      <div className="p-2 flex">
        <button
          className="font-americaRegular h-8 text-xs p-2 bg-grey-darker rounded text-white bg-grey-darker mr-2"
          onClick={handleSendInvitation}
          type="button"
        >
          {buttonText}
        </button>
        {showVideoRoomButton && (
          <button
            className={`font-americaRegular h-8 text-xs p-2 ${!inActiveVideoCall && !inPendingVideoCall ? 'bg-grey-darker' : 'bg-psworld-orange cursor-default'} rounded text-white bg-grey-darker mr-2`}
            onClick={handleSendCallInvitation}
            type="button"
            disabled={inActiveVideoCall}
          >
            {!inActiveVideoCall && !inPendingVideoCall ? '+ Video Call' : inPendingVideoCall ? 'Calling...' : 'In call' }
          </button>
        )}
        {isAuth
        && (
        <button
          className={`font-americaRegular h-8 text-xs w-16 p-2 ${!isMuted ? 'bg-grey-darker' : 'bg-psworld-orange'} rounded text-white bg-grey-darker`}
          onClick={!isMuted ? () => { handleMute(); setIsMuted(true); } : () => { handleUnMute(); setIsMuted(false); }}
          type="button"
        >
          {!isMuted ? 'Mute' : 'Unmute'}
        </button>
        )
}
      </div>
    </div>
  );
};

ViewerCard.propTypes = {
  nicknameColor: PropTypes.string,
  closeWhisperModal: PropTypes.func,
  handleSendInvitation: PropTypes.func,
  buttonText: PropTypes.string,
};
ViewerCard.defaultProps = {
  nicknameColor: '',
  closeWhisperModal: () => {},
  handleSendInvitation: () => {},
  buttonText: 'Private message',
};

export default ViewerCard;
