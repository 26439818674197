/**
 * This component is based on https://codesandbox.io/s/react-stripe-official-q1loc?fontsize=14&hidenavigation=1&theme=dark&file=/src/components/demos/SplitForm.js
 * , referenced at: https://github.com/stripe/react-stripe-js
 * Update: now it's based more at this: https://stripe.com/docs/payments/integration-builder
 */


// Example post component:
// "components" : [
//   {
//       "type" : "BodyChildContainer", 
//       "components" : [
//           {
//               "type" : "PsWorldStripePayment", 
//               "title" : {
//                   "es" : "perfil del usuario", 
//                   "en" : "Stripe Test", 
//                   "ca" : "perfil de l'usuari", 
//                   "pt" : "perfil de usuário"
//               },
//               "productSlugName": "donation",
//               "donationAmounts": [
//                 1,5,10,20
//               ],
//           }
//       ]
//   }
// ]

import React, { useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';
import jwt from 'jsonwebtoken';
import api from './psWorldPaymentsApi';
import translateObj from '../../../util/translateObj';
import getProducts from '../../../api/graphql/queries/getProducts';
import FormInput from '../Form/FormInput';


//  import useResponsiveFontSize from "../../useResponsiveFontSize";

const useOptions = () => {
  const fontSize = '18px'; // useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: '#424770',
          letterSpacing: '0.025em',
          fontFamily: 'Source Code Pro, monospace',
          '::placeholder': {
            color: '#aab7c4',
          },
          width: '400px',
        },
        invalid: {
          color: '#9e2146',
        },
      },
    }),
  );

  return options;
};

const stripePromise = loadStripe('pk_test_EO9crXBrcexhiFUJBMsTeii4');

const CheckoutForm = ({
  productSlugName,
  donationAmounts,
}
) => {
  const [product, setProduct] = useState(0);
  const [productType, setProductType] = useState('');
  const [amount, setAmount] = useState('');
  const [currency, setCurrency] = useState('');
  const [clientSecret, setClientSecret] = useState(null);
  const [error, setError] = useState(null);
  const [resultInfo, setResultInfo] = useState(null);
  const [metadata, setMetadata] = useState(null);
  const [succeeded, setSucceeded] = useState(false);
  const [processing, setProcessing] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const inputClass = 'mt-4 rounded-full border-1 p-4 bg-inherit border-black text-black border-black text-black';

  // const productSlugName = 'subscription-1-month';
  // const productSlugName = 'ppv-la-zowi-apolo-2020-06-22';
  // const productSlugName = 'donation';

  useEffect(() => {
    if (productSlugName) {
      console.log('productSlugName', productSlugName);
      // Step 1: Fetch product details such as amount and currency from API
      api.getProductDetails(productSlugName).then((productDetails) => {
        setProduct(productDetails);
        setProductType(productDetails.productType);
        if (productDetails.amount) {
          setAmount(productDetails.amount);
        }
        setCurrency(productDetails.currency);
      });
    }

    // Step 2: Create PaymentIntent over Stripe API
    // api
    //   .createPaymentIntent({
    //     payment_method_types: ['card'],
    //     productSlugName,
    //   })
    //   .then((clientSecretReceived) => {
    //     setClientSecret(clientSecretReceived);
    //   })
    //   .catch((err) => {
    //     setError(err.message);
    //   });
  }, [productSlugName]);

  /* Shows a success / error message when the payment is complete */
  const orderComplete = (subscription) => {
    setProcessing(false);
    setSucceeded(true);
    setResultInfo(`Subscription Status: ${subscription.status}`);
  };

  const confirmSubscription = (subscriptionId) => {
    console.log('confirmSubscription', subscriptionId);
    return fetch('/subscription', {
      method: 'post',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify({
        subscriptionId,
      }),
    })
      .then(response => response.json())
      .then((subscription) => {
        orderComplete(subscription);
      });
  };

  function handleSubscription(subscription) {
    const { latest_invoice: latestInvoice } = subscription;
    const { payment_intent: paymentIntent } = latestInvoice;

    if (paymentIntent) {
      const { status } = paymentIntent;

      if (status === 'requires_action') {
        stripe.confirmCardPayment(clientSecret).then((result) => {
          if (result.error) {
            // Display error message in your UI.
            // The card was declined (i.e. insufficient funds, card has expired, etc)
            setError(`Payment failed: ${result.error}`);
            setProcessing(false);
          } else {
            // Show a success message to your customer
            confirmSubscription(subscription.id);
          }
        });
      } else {
        // No additional information was needed
        // Show a success message to your customer
        orderComplete(subscription);
      }
    } else {
      orderComplete(subscription);
    }
  }

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setProcessing(true);
    setError(false);

    const { productType, stripeSubscriptionId } = product;
    if (productType === 'subscription' && stripeSubscriptionId) {
      // Subscription
      if (typeof localStorage === 'undefined') return;
      const token = localStorage.getItem(process.env.AUTH_TOKEN);
      if (!token || token === 'undefined') {
        setError('No user session. Please login');
        return;
      }

      const { email } = jwt.decode(token);

      stripe
        .createPaymentMethod('card', elements.getElement(CardNumberElement), {
          billing_details: {
            email,
          },
        })
        .then((result) => {
          if (result.error) {
            setError(result.error);
          } else {
            api.createCustomer(result.paymentMethod.id, email, product.slugName)
              .then((subscription) => {
                handleSubscription(subscription);
              })
              .catch((err) => {
                setError(err.message);
                setProcessing(false);
              });
          }
        });
    } else {
      // Pay Per View PPV

      // Step 2: Create PaymentIntent over Stripe API
      api
        .createPaymentIntent({
          payment_method_types: ['card'],
          productSlugName,
          donationAmount: amount,
        })
        .then(async (clientSecretReceived) => {
          // setClientSecret(clientSecretReceived);
          // Step 3: Use clientSecret from PaymentIntent and the CardElement
          // to confirm payment with stripe.confirmCardPayment()
          const payload = await stripe.confirmCardPayment(clientSecretReceived, {
            // receipt_email: email,
            payment_method: {
              card: elements.getElement(CardNumberElement),
              // billing_details: {
              //   name: ev.target.name.value,
              // },
            },
          });

          if (payload.error) {
            setError(`Payment failed: ${payload.error.message}`);
            setProcessing(false);
            console.log('[error]', payload.error);
          } else {
            setError(null);
            setSucceeded(true);
            setProcessing(false);
            setMetadata(payload.paymentIntent);
            console.log('[PaymentIntent]', payload.paymentIntent);
          }
        })
        .catch((err) => {
          setError(err.message);
          setProcessing(false);
        });
    }
  };

  const donationTitle = `How much do you want to donate? (${currency.toLocaleUpperCase()})`;

  return (
    <form onSubmit={handleSubmit} style={{ width: '400px' }}>
      <p>
        {product && product.title ? product.title.en : ''}
      </p>

      {productType === 'donation' && (
        <>
          <label className="flex flex-col" htmlFor="donationAmount">
            {donationTitle}
            <input name="donationAmount" type="text" className="mt-4 rounded-full border-1 p-4 bg-inherit border-black text-black false border-black text-black" value={amount} onChange={newValue => setAmount(newValue.currentTarget.value)} />
          </label>
        </>
      )}

      {productType !== 'donation' && (
      <h2>
        {currency.toLocaleUpperCase()}
        {' '}
        {amount.toLocaleString('en', {
          // TODO: set language dynamically
          minimumFractionDigits: 2,
        })}
        {' '}
      </h2>
      )}

      <label className="flex flex-col" htmlFor="cardnumber">
        CARD NUMBER
        <CardNumberElement
          options={options}
          className={inputClass}
          onReady={() => {
            console.log('CardNumberElement [ready]');
          }}
          onChange={(event) => {
            console.log('CardNumberElement [change]', event);
          }}
          onBlur={() => {
            console.log('CardNumberElement [blur]');
          }}
          onFocus={() => {
            console.log('CardNumberElement [focus]');
          }}
        />
      </label>
      <label>
        EXPIRATION DATE
        <CardExpiryElement
          options={options}
          className={inputClass}
          onReady={() => {
            console.log('CardNumberElement [ready]');
          }}
          onChange={(event) => {
            console.log('CardNumberElement [change]', event);
          }}
          onBlur={() => {
            console.log('CardNumberElement [blur]');
          }}
          onFocus={() => {
            console.log('CardNumberElement [focus]');
          }}
        />
      </label>
      <label>
        CVC
        <CardCvcElement
          options={options}
          className={inputClass}
          onReady={() => {
            console.log('CardNumberElement [ready]');
          }}
          onChange={(event) => {
            console.log('CardNumberElement [change]', event);
          }}
          onBlur={() => {
            console.log('CardNumberElement [blur]');
          }}
          onFocus={() => {
            console.log('CardNumberElement [focus]');
          }}
        />
      </label>
      <div className="flex justify-end mt-8 sm:mt-0">
        <div className="flex flex-col w-1/2 sm:hidden">
          <button type="button" className="bg-grey h-12 text-white rounded-full mx-4 py-2 font-americaMonoBold">
            BACK
          </button>
        </div>
        <div className="flex flex-col w-1/2 sm:hidden">
          {/* <button type="submit" className="bg-grey h-12 text-white rounded-full mx-4 py-2 font-americaMonoBold" disabled={processing || !clientSecret || !stripe}> */}
          <button type="submit" className="bg-grey h-12 text-white rounded-full mx-4 py-2 font-americaMonoBold" disabled={processing || !stripe}>
            {processing ? 'PROCESSING…' : 'CONTINUE'}
          </button>
        </div>
      </div>
      <div className="flex justify-end mt-8 sm:mt-0">
        {/* Show any error that happens when processing the payment */}
        {error && (
          <div className="w-full card-error" role="alert">
            {error}
          </div>
        )}
        {/* Show a success message upon completion */}
        <p className={succeeded ? 'w-full result-message' : 'w-full result-message hidden'}>
          Payment succeeded, see the result in your
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://dashboard.stripe.com/test/payments"
          >
            {' '}
            Stripe dashboard.
          </a>
          {resultInfo && (
            <p>
              {resultInfo}
            </p>
          )}
          Refresh the page to pay again.
        </p>
      </div>
    </form>
  );
};

const PsWorldStripePayment = (props) => {
  console.log('props', props);
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm {...props} />
    </Elements>
  );
};

PsWorldStripePayment.propTypes = {
  productSlugName: PropTypes.string.isRequired,
  donationAmounts: PropTypes.arrayOf(PropTypes.number),
};

PsWorldStripePayment.defaultProps = {
  donationAmounts: [],
};

CheckoutForm.propTypes = {
  productSlugName: PropTypes.string.isRequired,
  donationAmounts: PropTypes.arrayOf(PropTypes.number),
};

CheckoutForm.defaultProps = {
  donationAmounts: [],
};

export default PsWorldStripePayment;
