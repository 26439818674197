import React from 'react';
import PropTypes from 'prop-types';
import Link from '../../../util/link';
import { useRoutes } from '../../../context/routesContext';
import LanguageSelector from '../LanguageSelector';

const FooterRedesignSocialMedia = ({ name, logos, parent, languages }) => {
  const { path } = useRoutes();

  return (
    <div className="flex flex-col items-center">
      <nav className="self-center">
        <LanguageSelector languages={languages} />
      </nav>
      <nav className="flex flex-row self-start border-1 border-white rounded-full sm:text-64 tablet:text-4xl leading-none px-2" style={{ borderColor: 'rgba(255, 255 ,255, 0.25)' }}>
        {logos.map((logo, index) => (
          <div key={logo.name} className="flex items-center">
            <Link to={logo.link} localizationpath={path} aria-label={logo.name}>
              <div className="w-14 flex-no-shrink tablet:w-8 h-14 tablet:h-8 rounded-full overflow-hidden flex items-center justify-center">
                {logo.icon && <span className={`${logo.icon} ${logo.class} block`} />}
                {logo.src && <img className="w-full h-full" src={logo.src} alt={logo.name} style={{ objectFit: 'contain' }} />}
              </div>
            </Link>
            {index + 1 < logos.length && <div className="h-14 tablet:h-8 w-px bg-white opacity-25" />}
          </div>
        ))}
      </nav>
    </div>
  );
};

FooterRedesignSocialMedia.propTypes = {
  name: PropTypes.string.isRequired,
  logos: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      icon: PropTypes.string,
      src: PropTypes.string,
      link: PropTypes.string.isRequired,
      class: PropTypes.string.isRequired,
    }),
  ).isRequired,
  parent: PropTypes.oneOf(['FooterRedesignTop', 'FooterRedesignBottom']).isRequired,
  languages: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
};

export default FooterRedesignSocialMedia;
