/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';
import translateObj from '../../../util/translateObj';

const STYLES = {
  default: 'font-americaBlack',
  americaRegular: 'font-americaRegular tracking-normal w-full leading-tight text-30 md:text-40 lg:text-56',
};

const TitleLineH1 = ({ text, align, textColor, margin, match, slider, tabIndex, layout, style, backgroundColor }) => {
  const styles = {};
  if (textColor) styles.color = textColor;
  if (backgroundColor) styles.backgroundColor = slider ? 'transparent' : backgroundColor;

  return (
    <RoutesContext.Consumer>
      {({ language: contextLanguage, enteredFrom }) => {
        const localization = getLocalization(contextLanguage, enteredFrom, match);
        return (
          <h1
            className={`${align} ${margin} ${
              slider ? (layout === 'tickets'
                ? 'text-5xl sm:text-3xl mt-5 mb-3 sm:mb-4 leading-none'
                : 'sm:text-xl md:text-2xl lg:text-2rem text-4xl my-6 pb-3 sm:pb-0 sm:mb-3 sm:mt-0'
              )
              : 'pb-4'
            } w-full ${STYLES[style]}`}
            tabIndex={tabIndex}
            style={styles}
          >
            {translateObj(text, localization.language)} 
          </h1>
        );
      }}
    </RoutesContext.Consumer>
  );
};

export default withRouter(TitleLineH1);

TitleLineH1.propTypes = {
  textColor: PropTypes.string,
  align: PropTypes.string,
  margin: PropTypes.string,
  text: PropTypes.shape({
    en: PropTypes.string,
    es: PropTypes.string,
    ca: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape().isRequired,
  slider: PropTypes.bool,
  tabIndex: PropTypes.string,
  layout: PropTypes.string,
  style: PropTypes.string,
  backgroundColor: PropTypes.string,
};

TitleLineH1.defaultProps = {
  margin: '',
  textColor: '#000000',
  align: 'align-left',
  slider: false,
  tabIndex: '0',
  layout: null,
  backgroundColor: 'transparent',
  style: 'default',
};
