import React from 'react';
import PropTypes from 'prop-types';

const Separator = ({ borderWidth, color, marginTop, backgroundColor }) => {
  // NOTE: hotfix to chage the bg, remove when the entire body changes bg color
  return (
    <div className="w-full" style={{ backgroundColor: backgroundColor }}>
      <div className={`w-full border-t-${borderWidth} border-${color} ${marginTop ? 'my-4' : 'mb-4'}`} />
    </div>
  );
};

Separator.propTypes = {
  borderWidth: PropTypes.number,
  color: PropTypes.string,
  marginTop: PropTypes.bool,
  backgroundColor: PropTypes.string,
};

Separator.defaultProps = {
  borderWidth: 1,
  color: 'black',
  marginTop: true,
  backgroundColor: 'transparent',
};

export default Separator;
