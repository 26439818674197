const translation = {
  title: {
    en: 'My Primavera',
    es: 'My Primavera',
    ca: 'My Primavera',
    pt: 'My Primavera',
  },
  favourites: {
    en: 'My Favourites',
    es: 'Mis favoritos',
    ca: 'Els meus favorits',
    pt: 'Meus favoritos',
  },
  profile: {
    en: 'My data',
    es: 'Mis datos',
    ca: 'Les meves dades',
    pt: 'Meus dados',
  },
  rewards: {
    en: 'Rewards',
    es: 'Premios',
    ca: 'Premis',
    pt: 'Rewards',
  },
  notifications: {
    en: 'Notifications',
    es: 'Notificaciones',
    ca: 'Notificacions',
    pt: 'Notificações',
  },
  logOut: {
    en: 'Log out',
    es: 'Cerrar sesión',
    ca: 'Tancar sessió',
    pt: 'Sair',
  },
  loyaltyTitle: {
    en: 'Rewards',
    es: 'Premios',
    ca: 'Premis',
    pt: 'Rewards',
  },
  favouritesTitle: {
    en: 'My favourite artists',
    es: 'Mis artistas favoritos',
    ca: 'Els meus artistes favorits',
    pt: 'Meus artistas favoritos',
  },
  notificationsTitle: {
    en: 'Notifications',
    es: 'Notificaciones',
    ca: 'Notificacions',
    pt: 'Notificações',
  },
  scoreTitle: {
    en: 'My points',
    es: 'Mis puntos',
    ca: 'Els meus punts',
    pt: 'Meus pontos',
  },
  festivalsTitle: {
    en: 'Favourite festivals',
    es: 'Festivales favoritos',
    ca: 'Festivals favorits',
    pt: 'Festivais favoritos',
  },
  genresTitle: {
    en: 'Musical interests',
    es: 'Intereses musicales',
    ca: 'Interesos musicals',
    pt: 'Interesses musicais',
  },
  radioTitle: {
    en: 'Radio Primavera Sound',
    es: 'Radio Primavera Sound',
    ca: 'Radio Primavera Sound',
    pt: 'Radio Primavera Sound',
  },
  myAchievements: {
    en: 'My achievements',
    es: 'Mis logros',
    ca: 'Els meus assoliments',
  },
  linkSpotifyDescription: {
    en: 'Link your Spotify account and personalize more your Primavera Sound experience with things like better recommendations and suggestions',
    es: 'Vincula tu cuenta en Spotify y personaliza más tu experiencia en Primavera Sound con recomendaciones y sugerencias',
    ca: 'Vincula el teu compte amb Spotify i personalitza més la teva experiència amb Primavera Sound amb recomanacions i suggeriments',
  },
};

export default translation;
