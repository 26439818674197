import React from 'react';

const exportIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.666 0.708984H7.33268C6.41352 0.708984 5.66602 1.45648 5.66602 2.37565V5.70898H2.33268C1.41352 5.70898 0.666016 6.45648 0.666016 7.37565V15.709C0.666016 16.6282 1.41352 17.3757 2.33268 17.3757H10.666C11.5852 17.3757 12.3327 16.6282 12.3327 15.709V12.3757H15.666C16.5852 12.3757 17.3327 11.6282 17.3327 10.709V2.37565C17.3327 1.45648 16.5852 0.708984 15.666 0.708984ZM2.33268 15.709V7.37565H10.666L10.6677 15.709H2.33268ZM15.666 10.709H12.3327V7.37565C12.3327 6.45648 11.5852 5.70898 10.666 5.70898H7.33268V2.37565H15.666V10.709Z"
      fill="white"
    />
  </svg>
);

export default exportIcon;
