// HSL to RGB formula adapted from:
//  https://gist.github.com/mjackson/5311256
//  (skipping to else{} since s will always be 100%)

const HSLtoRGB = (h, s, l) => {
  const rd = a => Math.floor(Math.max(Math.min(a * 256, 255), 0));

  const hueToRGB = (m, n, o) => {
    if (o < 0) o += 1;
    if (o > 1) o -= 1;
    if (o < 1 / 6) return m + (n - m) * 6 * o;
    if (o < 1 / 2) return n;
    if (o < 2 / 3) return m + (n - m) * (2 / 3 - o) * 6;
    return m;
  };

  const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
  const p = 2 * l - q;

  const r = hueToRGB(p, q, h + 1 / 3);
  const g = hueToRGB(p, q, h);
  const b = hueToRGB(p, q, h - 1 / 3);

  return [rd(r), rd(g), rd(b)];
};

const RGBtoHex = (r, g, b) => `#${r.toString(16)}${g.toString(16)}${b.toString(16)}`;

const getRandomColor = () => {
  const hBase = Math.random();
  const newH = Math.floor(hBase * 360);
  const newL = Math.floor(Math.random() * 16) + 75;

  const [r, g, b] = HSLtoRGB(hBase, 1, newL * 0.01);

  const color = `${RGBtoHex(r, g, b)}`; // #ff8080
  return color;
};

export default getRandomColor;
