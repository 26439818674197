/* eslint-disable camelcase */
import React, { useRef } from 'react';
import { PropTypes } from 'prop-types';
import FilePlayer from 'react-player';

const StreamingVideoPlayerMainPlayer = ({
  mainVideo,
  controls,
  autoPlay,
  muted,
  shouldMainVideoStart,
  handlePlayingSmallScreens,
  handleOnReadyTrueForAll,
  isLoading,
}) => {
  const playerRef = useRef(null);

  const onReady = () => {
    const dash = playerRef.current.getInternalPlayer('dash');
    dash.setInitialBitrateFor('video', 0);
    dash.setQualityFor('video', 0);
    if (isLoading) {
      handleOnReadyTrueForAll();
    }
  };

  return (
    <>
      <FilePlayer
        ref={playerRef}
        url={mainVideo}
        playing={shouldMainVideoStart}
        onStart={() => { handlePlayingSmallScreens(true); }}
        onPlay={() => { handlePlayingSmallScreens(true); }}
        onPause={() => { handlePlayingSmallScreens(false); }}
        onReady={onReady}
        controls={controls}
        height="100%"
        width="100%"
        muted={muted}
        config={{
          autoPlay,
          file: {
            forceDASH: true,
          },
        }}
      />

    </>
  );
};

StreamingVideoPlayerMainPlayer.propTypes = {
  mainVideo: PropTypes.string.isRequired,
  controls: PropTypes.bool,
  isLoading: PropTypes.bool,
  muted: PropTypes.bool,
  autoPlay: PropTypes.bool,
  handlePlayingSmallScreens: PropTypes.func.isRequired,
  shouldMainVideoStart: PropTypes.bool,
  handleOnReadyTrueForAll: PropTypes.func.isRequired,

};

StreamingVideoPlayerMainPlayer.defaultProps = {
  controls: true,
  muted: false,
  autoPlay: true,
  isLoading: true,
  shouldMainVideoStart: null,

};

export default StreamingVideoPlayerMainPlayer;
