import React from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import imageModifier from '../helpers/imageModifier';
import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';
import translateObj from '../../../util/translateObj';
import Tag from '../Buttons/Tag';
import Navigator from '../Navigator';
import useResponsiveUtility from '../../../util/responsiveUtility';

const SliderBannerPD = ({
  image, title, tags, match, url,
}) => {
  const { deviceScreen } = useResponsiveUtility();

  return (
    <RoutesContext.Consumer>
      {({ language: contextLanguage, enteredFrom }) => {
        const localization = getLocalization(contextLanguage, enteredFrom, match);
        return (
          <>
            { deviceScreen === 'sm' ? null : <Navigator title={title} /> }
            <div
              className="h-620 md:h-carouselMD sm:h-carouselSM w-full sliderComponent relative full-width"
              style={{
                backgroundImage: `url(${imageModifier(translateObj(image, localization.language), '1440x620')})`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
              }}
            >
              <div className="text-white flex items-end h-full relative" style={{ zIndex: 2 }}>
                <div className="flex justify-around sm:flex-col sm:justify-end w-full px-36 md:px-20 sm:px-5 h-full items-end pb-20 sm:pb-13">
                  <div className="w-2/3 sm:w-full h-full sm:h-auto flex flex-col justify-end mr-12 sm:mr-0 sm:mb-4 ">
                    <div className="div">
                      {/* <TitleLineH1 text={title} /> */}
                    </div>
                  </div>
                  <div className="w-1/3 sm:w-full">
                    {/* {tags && tags[0] !== null ? tags.map(tag => <Tag text={tag} key={tag} />) : null} */}
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      }
  }
    </RoutesContext.Consumer>
  );
};
SliderBannerPD.propTypes = {
  match: PropTypes.shape().isRequired,
  image: PropTypes.shape({}),
  title: PropTypes.shape({}),
  tags: PropTypes.arrayOf(PropTypes.string),
};

SliderBannerPD.defaultProps = {
  title: null,
  tags: null,
  image: null,
};

export default withRouter(SliderBannerPD);
