/* eslint-disable no-nested-ternary */
import React, { Component, Fragment } from 'react';
import { PropTypes } from 'prop-types';
import TicketMain from './TicketMain';
import TicketMainV2 from './TicketMainV2';
import TicketConcert from './TicketConcert';
import Ticket from './Ticket';
import getTicketsByEventSlugName from '../../api/graphql/queries/getTicketsByEventSlugName';
import getTicketsBySlugName from '../../api/graphql/queries/getTicketsBySlugName';
import PSResponsiveUtility from './LineUp/PSResponsiveUtility';

export default class CardsTickets extends Component {
  state = {
    tickets: null,
    isLoading: true,
    deviceScreen: null,
    parentWidth: 0,
  };

  responsiveUtility = React.createRef();

  parentWidth = React.createRef();

  componentDidMount() {
    this.processTickets();
    this.handleResize();
  }

  componentDidUpdate(prevProps) {
    const { eventSlugnames, data, ticketData } = this.props;
    if (prevProps.eventSlugnames !== eventSlugnames || prevProps.data !== data || prevProps.ticketData !== ticketData) {
      this.processTickets();
    }
  }

  handleResize = () => {
    const deviceScreen = this.responsiveUtility.current.deviceScreen(true);
    this.setState({
      deviceScreen,
    });
  };

  processTickets = () => {
    const { eventSlugnames, data, ticketData } = this.props;
    console.log({ eventSlugnames, data, ticketData });
    if (eventSlugnames && eventSlugnames.length) {
      getTicketsByEventSlugName(eventSlugnames).then((tickets) => {
        this.setState({ tickets, isLoading: false });
      });
    } else if (data && data.length) {
      const ticketsSlugNamesArray = [];
      data.forEach((ticket) => ticketsSlugNamesArray.push(ticket.ticketSlugName));
      getTicketsBySlugName(ticketsSlugNamesArray).then((res) => {
        const ticketMockeado0 = { ...res[0] };
        ticketMockeado0.price = 333;
        ticketMockeado0.status = 'SALE';
        ticketMockeado0.tags = ['VIP'];
        const ticketMockeado1 = { ...res[1] };
        ticketMockeado1.price = 333;
        ticketMockeado1.status = 'SOON';
        ticketMockeado1.tags = ['VIP'];
        const ticketMockeado2 = { ...res[2] };
        ticketMockeado2.price = 666;
        ticketMockeado2.status = 'SOLDOUT';
        this.setState({
          tickets: [
            ticketMockeado0,
            ticketMockeado1,
            ticketMockeado2,
          ],
          isLoading: false,
        });
        // this.setState({
        //   tickets: res,
        //   isLoading: false,
        // });
      });
    } else if (ticketData) {
      this.setState({ tickets: ticketData, isLoading: false });
    }
  }

  render() {
    const {
      tickets, isLoading, deviceScreen,
    } = this.state;
    const { category } = this.props;
    return (
      <>
        <PSResponsiveUtility ref={this.responsiveUtility} deviceWidthCallback={this.handleResize} />
        <div ref={this.parentWidth} className="w-full" />
        {!isLoading ? (
          tickets && (
            <section className="w-full sm:mb-4" tabIndex="0">
              <div className="c-tickets">
                {tickets.map((ticket, i) => (ticket.ticketSlugName ? (
                  // eslint-disable-next-line react/no-array-index-key
                  <div role="listitem" className="w-auto mb-8" key={i}>
                    <TicketMainV2 {...ticket} category={category} />
                  </div>
                ) : (
                  // eslint-disable-next-line react/no-array-index-key
                  <div role="listitem" className="sm:pb-8" key={i}>
                    <Ticket {...ticket} />
                  </div>
                )))}
              </div>
            </section>
          )
        ) : (
          'loading'
        )}
      </>
    );
  }
}

CardsTickets.propTypes = {
  eventSlugnames: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.arrayOf(PropTypes.shape({})),
  ticketData: PropTypes.arrayOf(PropTypes.shape({})),
  category: PropTypes.string,
};

CardsTickets.defaultProps = {
  eventSlugnames: null,
  data: null,
  ticketData: null,
  category: 'black',
};
