/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';
import translateObj from '../../../util/translateObj';
import Link from '../../../util/link';

const SimpleTag = ({
  text, margin, textColor, borderColor, category, match, slider, noLink, tabIndex,
}) => (
  <RoutesContext.Consumer>
    {({ language: contextLanguage, enteredFrom }) => {
      const localization = getLocalization(contextLanguage, enteredFrom, match);
      const linkToOwnCategory = category ? category.replace(' ', '-') : 'home';

      return (
        !noLink ? (
          <Link localizationpath={localization.path} to={`/${linkToOwnCategory}`} aria-label={translateObj(text, localization.language)}>
            <div
              className={`${slider ? 'mt-6' : ''} mr-1 inline-block ml-0 cursor-pointer ${textColor ? `text-${textColor}` : 'text-white'} ${
                borderColor ? `border border-solid border-${borderColor}` : ''
              } align rounded px-2 leading-normal hover:bg-white trans hover:text-black bg-${linkToOwnCategory} ${margin || 'py-3px font-americaMonoMedium mb-1'}`}
            >
              <span className="uppercase text-xxs">{translateObj(text, localization.language)}</span>
            </div>
          </Link>
        ) : (
          <div
            tabIndex={tabIndex}
            aria-label={translateObj(text, localization.language)}
            className={`${slider ? 'mt-6' : ''} mr-1 inline-block ml-0 cursor-pointer ${textColor ? `text-${textColor}` : 'text-white'} ${
              borderColor ? `border border-solid border-${borderColor}` : ''
            } align rounded px-2 leading-normal hover:bg-white trans hover:text-black bg-${linkToOwnCategory} ${margin || 'py-3px font-americaMonoMedium mb-1'}`}
          >
            <span className="uppercase text-xxs">{translateObj(text, localization.language)}</span>
          </div>
        )

      );
    }}
  </RoutesContext.Consumer>
);

SimpleTag.propTypes = {
  text: PropTypes.shape({}),
  match: PropTypes.shape({}).isRequired,
  margin: PropTypes.string,
  textColor: PropTypes.string,
  borderColor: PropTypes.string,
  category: PropTypes.string,
  slider: PropTypes.bool,
  noLink: PropTypes.bool,
  tabIndex: PropTypes.string,
};

SimpleTag.defaultProps = {
  margin: null,
  textColor: 'white',
  category: 'white',
  text: {},
  borderColor: '',
  slider: false,
  noLink: false,
  tabIndex: '0',
};

export default withRouter(SimpleTag);
