import gql from 'graphql-tag';

import { clientRadio, persistor } from '../graphqlAPI';

const GET_RADIO_SHOWS_NOW = gql`
  {
    radioProgramsNow {
      id
      channel
      dateAir
      parent
      slug
      youtube
      title_multilang {
        ca
        en
        es
      }
      text_multilang {
        ca
        en
        es
      }
      subtitle_multilang {
        ca
        en
        es
      }
      program_title_multilang {
        ca
        es
        en
      }
      tags_multilang{
        ca
        en
        es
      }
      categories_multilang{
        ca
        en
        es
      }
      status
      image
      isPlaylist
      duration
    }
  }
`;

const executeQuery = async (type = 'network') => {
  if (!process.env.RADIO_GRAPHQL_SERVER_URL) {
    return [];
  }

  const result = await clientRadio.query({
    query: GET_RADIO_SHOWS_NOW,
    errorPolicy: 'all',
    fetchResults: true,
    fetchPolicy: type === 'network' ? 'network-only' : 'cache-only',
    // pollInterval: 3000,
  })
    .then(queryResult => queryResult)
    .catch((queryError) => {
      const errorMessage = type === 'network'
        ? `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error: ${queryError}`
        : `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error after Cache Restore: ${queryError}`;
      console.log('\x1b[31m%s\x1b[0m', errorMessage);

      return null;
    });

  const { data: { radioProgramsNow: radioProgramsNowData } } = result;

  if (radioProgramsNowData) {
    return radioProgramsNowData;
  }
  console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: GraphQL Query Error: radioProgramsNowData = null`);
  return null;
};

const getRadioShowsNOW = async () => {
  try {
    const radioProgramsNowData = await executeQuery();

    if (persistor) {
      persistor.persist();
    }

    return radioProgramsNowData;
  } catch (jsonError) {
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: JSON Object Error: ${jsonError}`);

    if (persistor) {
      const persistorStatus = await persistor.restore().then(() => 'OK');
      console.log(`${new Date().toLocaleTimeString()} ~ Client: Apollo Cache Restored - ${persistorStatus}`);

      try {
        const radioProgramsNowData = await executeQuery('cache');

        return radioProgramsNowData;
      } catch (jsonErrorAfterCacheRestore) {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: JSON Object Error after Cache Restore, sending 404: ${jsonErrorAfterCacheRestore}`);
        return null;
      }
    }
    return null;
  }
};

export default getRadioShowsNOW;
