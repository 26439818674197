// This module is based on https://dev.to/cubejs/react-data-table-with-material-ui-and-a-spark-of-joy-50o1

import React, { useState } from 'react';
import PropTypes from 'prop-types';
// import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Typography,
  // TablePagination,
} from '@material-ui/core';
import ProgressBar from "../ProgressBar";
// import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
// import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';


const useStyles = makeStyles(theme => ({
  root: {
    padding: 0,
  },
  content: {
    padding: 0,
  },
  inner: {
    overflowX: 'scroll',
    minWidth: '600px',
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'baseline',
  },
  status: {
    marginRight: 15,
  },
  actions: {
    justifyContent: 'flex-end',
  },
  head: {
    fontWeight: 'bold',
    cursor: 'ns-resize',
  },
}));

const TagView = ({value, setGlobal}) => (
  <div class="mr-1 inline-block ml-0 text-xxs cursor-pointer text-white align
  rounded px-2 leading-normal hover:bg-white trans
  hover:text-black bg-black py-3px font-americaMonoMedium mb-1"
    onClick={() => setGlobal(value)}
  >
    <span class="uppercase">{value}</span>
  </div>
)

const TableListTable = (props) => {
  const {
    tableData,
    columns,
    total,
    page,
    setPage,
    rowsPerPage,
    sorting,
    setSorting,
    hasSort,
    setGlobal,
  } = props;

  // const [page, setPage] = React.useState(1);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const isEmail = string => string.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
  // const isUrl = string => string.match(/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi);
  const isUrl = string => string.match(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gi);
  const correctUrl = (url) => {
    if (!/^https?:\/\//i.test(url)) {
      return `http://${url}`;
    }

    return url;
  };
  const isLinkTag = string => string.match(/#a/gi);
  const isATag = string => string.match(/\$TAG/gi);
  const isArtTable = string => string.match(/art.table/);
  const isArtProgress = string => string.match(/art.progress/);

  const formatValue = (value) => {
    if (isEmail(value)) {
      return <a href={`mailto:${value}`} className="text-pro-online" rel="noopener noreferrer" target="_blank">{value}</a>;
    }
    if (isLinkTag(value)) {
      const values = value.split("|");
      return <a href={correctUrl(values[1])} className="text-pro-online" rel="noopener noreferrer" target="_blank">{values[2]}</a>;
    }
    if (isATag(value)) {
      const values = value.split("|");
      return <TagView value={values[1]} setGlobal={setGlobal} />;
    }
    if (isUrl(value)) {
      return <a href={correctUrl(value)} className="text-pro-online" rel="noopener noreferrer" target="_blank">{value}</a>;
    }
    if(isArtTable(value)){
      const newValue = value.replace("art.table", "")
      const values = newValue.split("|");
      
      return <div>{values.map(value => <p>{value}</p>)}</div>;
    }
    if(isArtProgress(value)){
      const newValue = Number(value.replace("art.progress", ""));
    
      return  <ProgressBar value={newValue} />;
    }
    return value;
  };

  const handleSetSorting = (index) => {
    if (!hasSort) {
      return;
    }

    setSorting([index, sorting[1] === -1 ? 1 : -1]);
  };

  const classes = useStyles();

  if (tableData) {
    return (
      <Card
        padding="0"
        style={{ overflowX: 'scroll' }}
      >
        <CardContent className={classes.content}>
          {/* <PerfectScrollbar> */}
          {/* <TablePagination
            component="div"
            count={total}
            page={page}
            onChangePage={handleChangePage}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[50, 100, 200, 500, 1000]}
          /> */}
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((column, index) => (
                    <TableCell
                      key={Math.random()}
                      className={classes.head}
                      title={column}
                      onClick={() => {
                        handleSetSorting(index);
                      }}
                    >
                      <span>{column}</span>
                      <Typography
                        className={classes.arrow}
                        variant="body2"
                        component="span"
                      >
                        {/* {hasSort && ((sorting && (sorting[0] === index)) ? (sorting[1] === -1 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />) : null)} */}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map(row => (
                  <TableRow
                    key={Math.random()}
                    className={classes.tableRow}
                    hover
                  >
                    {row.map(value => (
                      <TableCell key={Math.random()}>
                        {formatValue(value)}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
          {/* </PerfectScrollbar> */}
        </CardContent>
        <CardActions className={classes.actions}>
          {/* <TablePagination
            component="div"
            count={orders.length}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
          /> */}
        </CardActions>
      </Card>
    );
  }
  return null;
};

TableListTable.propTypes = {
  tableData: PropTypes.arrayOf(PropTypes.array).isRequired,
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  total: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  sorting: PropTypes.arrayOf().isRequired,
  setSorting: PropTypes.func.isRequired,
  hasSort: PropTypes.bool,
  // handleClick: PropTypes.func.isRequired,
};

TableListTable.defaultProps = {
  hasSort: true,
};

export default TableListTable;
