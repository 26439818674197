import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import userUpdateRoles from '../../../../api/graphql/mutations/userUpdateRoles';
import OnDemandModalNotLogged from '../OnDemandModals/OnDemandModalNotLogged';
// import OnDemandModalIsLogged from '../OnDemandModals/OnDemandModalIsLogged';
import LoadIcon from '../../LoadIcon';
import { useUser } from '../../../../context/userProfileContext';

const SimpleOnDemandModalScreen = ({
  userName, userLastName, userEmail, token, loadTokenForLS, isUserVerified, language, translations, image, postVideoUrl, otherOptionsButtonUrl, userTrialWasUsed, userSubscriptionDetails, history, postVideoRole, sourceWeb,
}) => {
  const closeModal = () => console.log('out');
  const [screenStatus, setScreenStatus] = useState('loading');
  const [error, setError] = useState(null);
  const user = useUser();

  const handleUserRedirectToVideoPage = () => {
    history.push(`/${language || 'en'}${postVideoUrl && postVideoUrl[0] === '/' ? postVideoUrl : `/${postVideoUrl}`}`);
  };
  const handleUpdatingUserRoles = async (emailFromFn) => {
    setScreenStatus('loading');
    const email = emailFromFn || userEmail;
    const resultFromUpdatingUserRoles = await userUpdateRoles({ email, roles: [postVideoRole || 'noRoleProvided'] });
    const { token: tokenFromUserUpdateRolesQuery = '', isValid = false, error: errorFromUserUpdateRolesQuery = '' } = resultFromUpdatingUserRoles;
    if (isValid && tokenFromUserUpdateRolesQuery) {
      user.setNewAuthToken(tokenFromUserUpdateRolesQuery);
      if (postVideoUrl) { handleUserRedirectToVideoPage(); } else {
        closeModal();
        if (window && window.location) {
          window.location.reload();
        }
      }
    } else if (errorFromUserUpdateRolesQuery) {
      setError(errorFromUserUpdateRolesQuery);
    } else {
      closeModal();
    }
  };

  useEffect(() => {
    if (!userEmail) {
      setScreenStatus('socialLogin');
    } else {
      const noFinalVideoUrlProvided = !postVideoUrl;
      const noFinalVideoRoleProvided = !postVideoRole;
      // setScreenStatus('logged');
      if (noFinalVideoUrlProvided && noFinalVideoRoleProvided) {
        closeModal();
      } else {
        // * update user (add role) and send to final video url
        handleUpdatingUserRoles(userEmail);
      }
    }
  }, [userEmail]);

  const handleOnDemandScreenStatus = (screenStatusFromFn, email = '') => {
    if (screenStatusFromFn === 'logged') {
      // * update user (add role) and send to final video url
      // handleUpdatingUserRoles(email);
      // console.log('!!!: handleOnDemandScreenStatus -> screenStatusFromFn', screenStatusFromFn);
    } else {
      setScreenStatus(screenStatusFromFn);
    }
  };


  const renderLogic = () => {
    switch (screenStatus) {
      case 'loading':
        return (
          <div
            className="bg-gray200 p-8 sm:p-2 w-full"
          >
            <LoadIcon color="black" type="ThreeDots" />
            {error && <div className="text-red font-americaBold">Incorrect User, please try again later</div>}
          </div>
        );
      case 'socialLogin':
        return (
          <>
            <OnDemandModalNotLogged language={language} loadTokenForLS={loadTokenForLS} handleOnDemandScreenStatus={handleOnDemandScreenStatus} translations={translations} sourceWeb={sourceWeb} />
          </>
        );
        // case 'logged':
        //   return (
        //     <OnDemandModalIsLogged userEmail={userEmail} language={language} userSubscriptionDetails={userSubscriptionDetails} loadTokenForLS={loadTokenForLS} userTrialWasUsed={userTrialWasUsed} handleOnDemandScreenStatus={handleOnDemandScreenStatus} translations={translations} image={image} closeModal={closeModal} postVideoUrl={postVideoUrl} otherOptionsButtonUrl={otherOptionsButtonUrl} />
        //   );

      default:
        return (
          <div
            className="bg-gray200 p-8 sm:p-2 w-full"
          >
            <div>please review...</div>
          </div>
        );
    }
  };
  return (
    <div className="bg-white-dark">
      {renderLogic()}
    </div>
  );
};

export default withRouter(SimpleOnDemandModalScreen);
