import React from 'react';
import PropTypes from 'prop-types';

const EmptySlot = ({ layout, hasEvent, index }) => {
  const layoutClassNames = [];
  if (layout === 'columns') {
    if (index > 0) {
      layoutClassNames.push('border-t-1');
    }
    layoutClassNames.push('w-lineupHour');
    if (hasEvent) {
      layoutClassNames.push('h-lineupHour');
    } else {
      layoutClassNames.push('h-lineupEmptyHour');
    }
  } else if (layout === 'rows') {
    layoutClassNames.push('flex-no-shrink');
    layoutClassNames.push('h-lineupHour border-r-1');
    if (hasEvent) {
      layoutClassNames.push('w-lineupHour');
    } else {
      layoutClassNames.push('w-lineupEmptyHour');
    }
  }
  return (
    <div className={`${layoutClassNames.join(' ')} border-solid border-white`} />
  );
};

EmptySlot.propTypes = {
  layout: PropTypes.string.isRequired,
  hasEvent: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
};
export default EmptySlot;
