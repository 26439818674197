import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useAuth } from '@primaveralabs/auth';

import PopupWindow from './PopupWindow';
import userLinkSpotify from '../../../../api/graphql/mutations/userLinkSpotify';
import userUnLinkSpotify from '../../../../api/graphql/mutations/userUnLinkSpotify';
import userSocialLogin from '../../../../api/graphql/mutations/userSocialLogin';
import { useUser } from '../../../../context/userProfileContext';
import { checkExpiredToken } from '../../../../util/userProfile';
import { AUTH_CLIENT } from '../../../../containers/App';

const SPOTIFY_CLIENT_ID = '76a75784cbb541e69bc57569652757e2';

// NOTE: we add the path to override the browser locale in the PopupWindow otherwise the auth will fail
const getDomainName = () => `${window?.location.origin}/en`;

const parseSpotifyUserDetails = (userDetails) => {
  if (!userDetails) return {};

  const [name, surname] = userDetails.display_name?.split(' ');

  return {
    profileObj: {
      spotifyToken: userDetails.spotifyToken,
      email: userDetails.email,
      givenName: name,
      familyName: surname,
      spotifyId: userDetails.userID,
      isSocialLogin: 'spotify',
    },
  };
};

export const getSpotifyResponse = async (code, redirectUri) => {
  const res = await userSocialLogin(code, 'spotify', redirectUri);

  if (!res) return {};

  // NOTE: if there is no token the user needs to register.
  const { userDetails } = res;
  return { ...res, userPayload: parseSpotifyUserDetails(userDetails) };
};

const Button = ({ className, text, icon, onClick }) => (
  <button className={className} onClick={onClick} type="button">
    <div className={`${icon && 'font-icon icon-spotify'} text-white  text-18 -mt-px mx-0`} />
    {text ? <span className="mx-2 text-ellipsis">{text}</span> : null}
  </button>
);

Button.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  icon: PropTypes.bool,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  className: '',
  icon: false,
  onClick: null,
};

const SpotifyLogin = ({ buttonText, className, icon, isLinkAccountButton, onFailure, onSuccess, scope, isModal, revamp }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { isLinkedWithSpotify, userEmail, setNewAuthToken } = useUser();
  const { user, actions, token } = useAuth();
  const redirectUri = getDomainName();

  const handleLinkSpotify = (code) => {
    setIsLoading(true);
    userLinkSpotify(code, userEmail, redirectUri)
      .then((res) => {
        onSuccess(res);
        setNewAuthToken(res.token);
      })
      .catch(onFailure)
      .finally(() => setIsLoading(false));
  };

  const handleUnlinkSpotify = () => {
    setIsLoading(true);
    userUnLinkSpotify(userEmail, 'spotify', redirectUri)
      .then((res) => {
        onSuccess(res);
        setNewAuthToken(res.token);
      })
      .catch(onFailure)
      .finally(() => setIsLoading(false));
  };

  const handleLinkSpotifyRevamp = async () => {
    setIsLoading(true);
    try {
      AUTH_CLIENT.setHeader("authorization", `Bearer ${token}`)
      const res = await actions.spotifyLink({ email: user.email });
      if (res?.token) {
        onSuccess(res);
        const expired = checkExpiredToken(user.tokenExpiration);
        if (expired) {
          actions.refresh(res.token);
        }
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleUnlinkSpotifyRevamp = async () => {
    setIsLoading(true);
    try {
      AUTH_CLIENT.setHeader("authorization", `Bearer ${token}`)
      const res = await actions.spotifyUnlink({ email: user.email });
      if (res?.token) {
        onSuccess(res);
        const expired = checkExpiredToken(user.tokenExpiration);
        if (expired) {
          actions.refresh(res.token);
        }
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSignWithSpotify = async (code) => {
    setIsLoading(true);
    const res = await getSpotifyResponse(code, redirectUri);
    const { error, token, userPayload } = res;

    if (token) {
      setNewAuthToken(token);
      return onSuccess(res);
    }

    setIsLoading(false);
    return onSuccess({ error, isValid: false, needToRegister: true, userPayload });
  };

  const handleAuth = isLinkAccountButton ? handleLinkSpotify : handleSignWithSpotify;

  const search = {
    client_id: SPOTIFY_CLIENT_ID,
    response_type: 'code',
    redirect_uri: getDomainName(),
    scope,
  };

  const params = new URLSearchParams(search).toString();

  const text = isLoading ? 'Loading...' : buttonText;

  if (((user && user?.isSpotifyLinked) || isLinkedWithSpotify) && isLinkAccountButton) {
    return <Button {...{ className, text, icon, onClick: revamp ? handleUnlinkSpotifyRevamp : handleUnlinkSpotify }} />;
  }

  if (revamp) {
    return <Button {...{ className, text, icon }} onClick={() => handleLinkSpotifyRevamp()} />;
  }

  return (
    <PopupWindow
      url={`https://accounts.spotify.com/authorize?${params}`}
      onAuth={handleAuth}
      title="spotify-authorization"
      cancelNewWindow={isLinkAccountButton}
    >
      {
        isModal ? (
          <div className="h-10 w-10 bg-spotify rounded-full">
            <Button {...{ className, icon }} />
          </div>
        ) : <Button {...{ className, text, icon }} />
      }
    </PopupWindow>
  );
};

export default SpotifyLogin;

SpotifyLogin.propTypes = {
  buttonText: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  icon: PropTypes.bool,
  isLinkAccountButton: PropTypes.bool,
  onFailure: PropTypes.func,
  onSuccess: PropTypes.func,
  scope: PropTypes.string,
  isModal: PropTypes.bool,
  revamp: PropTypes.bool,
};

SpotifyLogin.defaultProps = {
  buttonText: 'Access with Spotify',
  children: null,
  className: 'rounded-full bg-inherit bg-spotify uppercase text-white text-16 xs:text-14 sm:text-14 font-americaMonoBold sm:static sm:mx-0 sm:w-full cursor-pointer text-center md:w-full mt-4 px-12 lg:px-16 xl:px-16 py-4 flex items-center justify-center',
  scope: 'user-read-private user-read-email user-top-read playlist-read-private user-follow-read',
  isLinkAccountButton: false,
  icon: true,
  onFailure: () => { },
  onSuccess: () => { },
  isModal: false,
  revamp: false,
};
