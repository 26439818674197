import React, { Component } from 'react';
import Collapse from '@material-ui/core/Collapse';

export default class FaqQuestion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }

    open = () => {
      this.setState({ open: !this.state.open });
    }

    render() {
      const {
        question,
        response,
      } = this.props;

      const { open } = this.state;
      return (
        <div>
          <div onClick={this.open} className="cursor-pointer border-b flex items-center border-black h-16 font-tiemposBold justify-between ">
            {question.es}
            <button type="button" className="text-2xl mr-8" onClick={this.open}>{open ? '-' : '+'}</button>
          </div>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <div className="ml-48 mb-12 mt-6 leading-normal font-tiemposRegular  ">
              {response.es}
            </div>
          </Collapse>
        </div>
      );
    }
}
