/* eslint-disable no-nested-ternary */
import React, { useState, useRef, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import parse from 'html-react-parser';
import { withRouter } from 'react-router-dom';
import { useUser } from '../../../context/userProfileContext';
import { usePrivatePlusZoneContext } from '../Chat/context/privatePlusOneZoneContext';

import translateObj from '../../../util/translateObj';
import { useRoutes } from '../../../context/routesContext';

import ZoneCardIframeWithinStreamModule from './ZoneCardIframeWithinStreamModule';
import ZoneCardButtonWithinStreamModule from './ZoneCardButtonWithinStreamModule';

let tokenQuery = '';

const ZoneCard = ({
  language, title, url, text, tokenQuery, index, userNickName = 'Fellow PSsounder',
}) => {
  const nickNameStringParam = `#userInfo.displayName="${userNickName}"`;
  const queryString = tokenQuery ? tokenQuery + nickNameStringParam : nickNameStringParam;
  const cardTitle = translateObj(title, language);
  const cardText = translateObj(text, language);

  return (
    <div className="bg-psworld-zone-red p-2 hover:bg-psworld-zone-red-light trans sm:mx-0 m-psworld-box">
      <a href={url + queryString} className="text-psworld-text-grey flex flex-col h-full justify-between w-full" target="_blank" rel="noopener noreferrer">
        <div>
          <h3 className=" font-bold mb-1 font-americaBold lg:text-base md:text-base text-2xl">{`R#${index + 1}${' '}${cardTitle}`}</h3>
          <p className="text-xs font-americaBold leading-regular">{cardText}</p>
        </div>
      </a>
    </div>
  );
};

const ZoneSectionWithinStreamModule = ({
  match, zones, intro, handleWithIframe = true, privatePlusOneZoneUrl
  ,
}) => {
  const [isIframeOpen, setIsIframeOpen] = useState(false);
  const [iFrameUrl, setIFrameUrl] = useState('');

  const { userRoles, userNickName } = useUser();
  const { language } = useRoutes(match);
  const { openPrivateChat } = usePrivatePlusZoneContext();

  const isModerator = userRoles && userRoles.includes('moderator');
  if (isModerator) tokenQuery = '?jwt=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiYWRtaW4iOnRydWV9.8nKmrvTqPmUD88mtb8grpNzbVGzEE2MBM8z83Yfc844';
  const introText = translateObj(intro.text, language);


  const handleOpenStreamModule = (url) => {
    setIFrameUrl(url);
    setIsIframeOpen(true);
  };
  const handleCloseStreamModule = () => {
    setIsIframeOpen(false);
    openPrivateChat('');
    setIFrameUrl('');
  };

  useEffect(() => {
    if (privatePlusOneZoneUrl) {
      if (iFrameUrl && iFrameUrl !== privatePlusOneZoneUrl) {
        handleCloseStreamModule();
        setTimeout(() => {
          handleOpenStreamModule(privatePlusOneZoneUrl);
        }, 500);
      }
      handleOpenStreamModule(privatePlusOneZoneUrl);
    }
  }, [privatePlusOneZoneUrl]);


  const zoneSectionWithinStreamRender = () => {
    if (isIframeOpen) {
      return <ZoneCardIframeWithinStreamModule url={iFrameUrl} handleCloseStreamModule={handleCloseStreamModule} />;
    }
    return (
      <div className="mx-2">
        {intro && (
          <div className="w-full p-2 pl-0 ps-zone">
            <div className="bg-psworld-zone-pink px-2 py-4 lg:text-xl md:text-lg  text-psworld-text-grey">
              <h2 className="font-americaBold">{intro.title}</h2>
              <div className="text-base md:text-sm leading-regular">
                {parse(introText)}
              </div>
            </div>
          </div>
        )}
        {zones && zones.length > 0 && (
          <div className={`w-full mb-2 overflow-y-auto scrollbar__streampage ${zones && zones.length > 2 ? 'lgsm:h-72 h-auto' : 'h-auto'}`}>
            {zones.map((zone, i) => (
              <div className="mr-2" key={i}>
                { handleWithIframe ? (
                  <ZoneCardButtonWithinStreamModule language={language} {...zone} index={i} tokenQuery={tokenQuery} key={zone.id} userNickName={userNickName} handleOpenStreamModule={handleOpenStreamModule} />
                ) : (
                  <ZoneCard language={language} {...zone} index={i} tokenQuery={tokenQuery} key={zone.id} userNickName={userNickName} />
                )}
              </div>
            ))}
          </div>
        )}

      </div>
    );
  };

  return (
    <>
      { zoneSectionWithinStreamRender()}
    </>
  );
};

ZoneSectionWithinStreamModule.propTypes = {
  zones: PropTypes.arrayOf(PropTypes.shape({})),
  intro: PropTypes.shape({}),
  banner: PropTypes.shape({}),
  match: PropTypes.shape().isRequired,
  privatePlusOneZoneUrl: PropTypes.string,
};
ZoneSectionWithinStreamModule.defaultProps = {
  zones: [],
  intro: null,
  banner: null,
  privatePlusOneZoneUrl: '',
};

export default withRouter(ZoneSectionWithinStreamModule);
