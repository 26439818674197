import React, { createContext, useContext } from 'react';
import { PropTypes } from 'prop-types';

const ChatVisibilityContext = createContext(null);

export const useChatVisibility = () => {
  const {
    isChatOpen,
    isFullscreen,
    setIsFullscreen,
    handleExitFullscreen,
    setChatVisibility,
  } = useContext(ChatVisibilityContext);
  const data = {};
  data.isChatOpen = isChatOpen;
  data.isFullscreen = isFullscreen;
  data.setIsFullscreen = setIsFullscreen;
  data.handleExitFullscreen = handleExitFullscreen;
  data.setChatVisibility = setChatVisibility;
  return data;
};

export const ChatVisibilityProvider = ({
  handleExitFullscreen, isFullscreen, setIsFullscreen, isChatOpen, setChatVisibility, children,
}) => (
  <ChatVisibilityContext.Provider value={{
    isChatOpen,
    setChatVisibility,
    isFullscreen,
    setIsFullscreen,
    handleExitFullscreen,
  }}
  >
    {children}
  </ChatVisibilityContext.Provider>
);

ChatVisibilityProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  isChatOpen: PropTypes.bool,
  isFullscreen: PropTypes.bool,
  setIsFullscreen: PropTypes.func,
  handleExitFullscreen: PropTypes.func,
  setChatVisibility: PropTypes.func,
};

ChatVisibilityProvider.defaultProps = {
  isChatOpen: true,
  isFullscreen: false,
  setIsFullscreen: null,
  handleExitFullscreen: null,
  setChatVisibility: null,
};
