import translation from './__translationHelper';

const data = [
  {
    type: 'MemberItem',
    key: 'member-1',
    _id: '6177d42c7c0cec000901a776',
    roles: ['web'],
    slugName: 'ana-quiroga',
    name: 'Ana',
    lastname: 'Quiroga',
    email: 'anaquiroga@hotmail.com',
    postalCode: '01234',
    language: 'es',
    dateOfBirth: '1991-05-02T10:00:00.000',
    inputCountry: 'fr',
    userType: 'Speaker', // ???
    description: 'Ana Quiroga is a London-based musician and sound designer. During the last 11 years, she has produced electronic music and designed sound for film, audiovisual performances, installations, video games and VR projects. With a prolific career performing live, Ana has played at renowned events and festivals such as Barbican London, Berlin Atonal, Sónar Barcelona, Sónar Hong Kong, MUTEK Montreal, MUTEK Argentina, Espacio Fundación Telefónica Lima and CCD México. She was half of the duo LCC who released two albums on the prestigious label Editions Mego. She has also released music under the alias NWRMNTC. Nowadays, she continues her music career under her own name.',
    occupation: 'Artist',
    company: 'Mutek',
    url: 'www.anaquiroga.com',
    image: translation('https://assets.primaverasound.com/psweb/5ii4686h44mt00d2t8is_1620385196307.jpg'),
    social: [
      {
        key: 'twitter',
        name: 'Twitter',
        link: 'https://twitter.com/primaverapro',
      },
      {
        key: 'facebook',
        name: 'Facebook',
        link: 'https://www.facebook.com/PrimaveraPro',
      },
      {
        key: 'instagram',
        name: 'Instagram',
        link: 'https://www.instagram.com/primaverapro/',
      },
    ],
  },
  {
    type: 'MemberItem',
    key: 'member-2',
    _id: '6177d42c7c0cec000901a777',
    roles: ['web'],
    slugName: 'beatriz-perez',
    name: 'Beatriz',
    lastname: 'Perez',
    email: 'beatrizperez@hotmail.com',
    postalCode: '01234',
    language: 'es',
    dateOfBirth: '1991-05-02T10:00:00.000',
    inputCountry: 'de',
    userType: 'Speaker', // ???
    description: 'Ana Quiroga is a London-based musician and sound designer. During the last 11 years, she has produced electronic music and designed sound for film, audiovisual performances, installations, video games and VR projects. With a prolific career performing live, Ana has played at renowned events and festivals such as Barbican London, Berlin Atonal, Sónar Barcelona, Sónar Hong Kong, MUTEK Montreal, MUTEK Argentina, Espacio Fundación Telefónica Lima and CCD México. She was half of the duo LCC who released two albums on the prestigious label Editions Mego. She has also released music under the alias NWRMNTC. Nowadays, she continues her music career under her own name.',
    occupation: 'Producer',
    company: 'Spotify',
    url: 'www.beatrizperez.com',
    image: translation('https://assets.primaverasound.com/psweb/5ii4686h44mt00d2t8is_1620385196307.jpg'),
    social: [
      {
        key: 'twitter',
        name: 'Twitter',
        link: 'https://twitter.com/primaverapro',
      },
      {
        key: 'facebook',
        name: 'Facebook',
        link: 'https://www.facebook.com/PrimaveraPro',
      },
      {
        key: 'instagram',
        name: 'Instagram',
        link: 'https://www.instagram.com/primaverapro/',
      },
    ],
  },
  {
    type: 'MemberItem',
    key: 'member-3',
    _id: '6177d42c7c0cec000901a778',
    roles: ['web'],
    slugName: 'Carlos-Mesquita',
    name: 'Carlos',
    lastname: 'Mesquita',
    email: 'carlosmesquita@hotmail.com',
    postalCode: '01234',
    language: 'es',
    dateOfBirth: '1991-05-02T10:00:00.000',
    inputCountry: 'pt',
    userType: 'Speaker', // ???
    description: 'Ana Quiroga is a London-based musician and sound designer. During the last 11 years, she has produced electronic music and designed sound for film, audiovisual performances, installations, video games and VR projects. With a prolific career performing live, Ana has played at renowned events and festivals such as Barbican London, Berlin Atonal, Sónar Barcelona, Sónar Hong Kong, MUTEK Montreal, MUTEK Argentina, Espacio Fundación Telefónica Lima and CCD México. She was half of the duo LCC who released two albums on the prestigious label Editions Mego. She has also released music under the alias NWRMNTC. Nowadays, she continues her music career under her own name.',
    occupation: 'Music',
    company: 'Sony Records',
    url: 'www.carlosmesquita.com',
    image: translation('https://assets.primaverasound.com/psweb/5ii4686h44mt00d2t8is_1620385196307.jpg'),
    social: [
      {
        key: 'twitter',
        name: 'Twitter',
        link: 'https://twitter.com/primaverapro',
      },
      {
        key: 'facebook',
        name: 'Facebook',
        link: 'https://www.facebook.com/PrimaveraPro',
      },
      {
        key: 'instagram',
        name: 'Instagram',
        link: 'https://www.instagram.com/primaverapro/',
      },
    ],
  },
];

export default data;
