import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import LineupListItem from './LineupListItem';
import PSResponsiveUtility from './LineUp/PSResponsiveUtility';
import getArtistSetsByArtistSetSlugs from '../../api/graphql/queries/getArtistSetsByArtistSetSlugs';

import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';
import translateObj from '../../util/translateObj';
import Link from '../../util/link';


class LineupList extends Component {
  state = {
    cardPosition: 0,
    width: null,
    activeArtist: null,
    lineupArrayInList: [],
    category: '',
    image: {},
  };

  responsiveUtility = React.createRef();


  componentDidMount() {
    const image = {
      background: `url(${this.props.backgroundImage})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    };
    this.setState({ image });

    this.prepareArtists().then((res) => {
      this.setState(() => ({ lineupArrayInList: res }));
    });
    // If we are on SSR we don't have document so we will set dummy code
    this.body = { setAttribute: () => { } };

    // We will check if document is present, then we will assing
    if (document && document.querySelector('body')) {
      this.body = document.querySelector('body');
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { artists } = this.props;


    if (prevProps.artists !== artists) {
      this.prepareArtists().then((res) => {
        this.setState(() => ({ lineupArrayInList: res }));
      });
    }
  }

  prepareArtists = async () => {
    const {
      artists, artistSets, match: { params }, userFavorites: favorites,
    } = this.props;
    this.setState({
      category: params.section,
    });
    const artistsArray = [];
    const artistArrayObject = [];
    const artistArrayFlattened = [];

    // constants to handle artists with multiple artistsets
    const aritstSetSlugNamesObj = {};
    const aritstSetSlugNames = {};
    const artistsWithMultipleConcertsAll = [];
    let artistsWithMultipleConcerts = [];

    if (artistSets) {
      artistSets.forEach(set => artistsArray.push(set.artistSetSlugName));
      await getArtistSetsByArtistSetSlugs(artistsArray)
        .then(res => res.forEach(art => artistArrayObject.push(art)))
        .catch(err => console.log(err));
      Object.keys(artistArrayObject).forEach((artist) => {
        artistArrayFlattened.push(artistArrayObject[artist]);
      });
    } else {
      Object.keys(artists).forEach(day => Object.keys(artists[day]).forEach(venue => artists[day][venue].forEach((concert) => {
        artistsArray[concert.artistSlugName] = concert;
        if (aritstSetSlugNamesObj[concert.artistSlugName] && aritstSetSlugNamesObj[concert.artistSlugName].length > 0) {
          aritstSetSlugNamesObj[concert.artistSlugName].push(concert);
          aritstSetSlugNames[concert.artistSlugName].push(concert.artistSetSlugName);
          artistsWithMultipleConcertsAll.push(concert.artistSlugName);
          artistsWithMultipleConcerts = [...new Set(artistsWithMultipleConcertsAll)];
        } else {
          aritstSetSlugNamesObj[concert.artistSlugName] = [concert];
          aritstSetSlugNames[concert.artistSlugName] = [concert.artistSetSlugName];
        }
      })));
      Object.keys(artistsArray).forEach((artist) => {
        artistArrayFlattened.push(artistsArray[artist]);
      });
    }
    // sort lineuplist alphabetically
    artistArrayFlattened.sort((a, b) => {
      if (a.artistSetSlugName < b.artistSetSlugName) {
        return -1;
      }
      if (a.artistSetSlugName > b.artistSetSlugName) {
        return 1;
      }
      return 0;
    });

    artistsWithMultipleConcerts.forEach((a) => {
      const index = artistArrayFlattened.findIndex(e => e.artistSlugName === a);
      if (index > -1) {
        artistArrayFlattened[index].artistSetArr = aritstSetSlugNames[a];

        favorites.map((i) => {
          const [artist, artistSet] = i.split('|');
          const artistSetMultipleConcertsThatIsFavorite = Object.keys(aritstSetSlugNamesObj).length && aritstSetSlugNamesObj[artist] && aritstSetSlugNamesObj[artist].filter(as => as.artistSetSlugName === artistSet);
          if (artistArrayFlattened[index].artistSlugName === artist && artistSetMultipleConcertsThatIsFavorite.length) {
            artistArrayFlattened[index] = { ...artistSetMultipleConcertsThatIsFavorite[0] };
            artistArrayFlattened[index].artistSetArr = aritstSetSlugNames[a];
          }

          // if (artistArrayFlattened[index].artistSlugName === artist) {
          //   artistArrayFlattened[index].artistSetIsFavorite = true;
          //   console.log('artistArrayFlattenedXXX!!!!', artistArrayFlattened[index]);
          // }
        });
      }
    });

    return artistArrayFlattened;
  }

  getWidth = () => {
    // actions when window is resized

    const device = this.responsiveUtility.current.deviceWidth(true);
    this.getCardPosition();
    this.setState({ width: device });
    if (device > 768) {
      this.body.setAttribute('style', 'overflow-y: auto');
      this.setState({ activeArtist: null });
      const elementActive = document.getElementsByClassName('artistCardActive')[0];
      if (elementActive) elementActive.classList.remove('artistCardActive');
    }
  };

  getCardPosition = (position) => {
    // get the artis card position and update it when resized
    if (!position) {
      const elementActive = document.getElementsByClassName('artistCardActive')[0];

      if (elementActive) {
        // check if a card is opened and get the position
        const containerPosition = document.getElementById('artistList').getBoundingClientRect().top;
        const elementActiveHeight = elementActive.childNodes[0].getBoundingClientRect();

        const cardPosition = elementActiveHeight.top + elementActiveHeight.height - containerPosition;

        this.setState({ cardPosition });
        // elementActive.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' });
      }
    }
  };

  setArtistCard = (artist) => {
    if (artist !== this.state.activeArtist) return this.setState({ activeArtist: artist });
    this.setState({ activeArtist: null });
  };

  hideCard = () => {
    this.setState({ activeArtist: null });
  };

  render() {
    const {
      underline, finalMessage, match, showArtistCard, eventSlug, linkTo, isUserLoggedIn, handleSetArtistSetFavorite,
    } = this.props;
    const {
      cardPosition, width, window, activeArtist, parentPosition, lineupArrayInList, category, image,
    } = this.state;
    return (
      <RoutesContext.Consumer>
        {({ language: contextLanguage, enteredFrom }) => {
          const localization = getLocalization(contextLanguage, enteredFrom, match);
          return (
            <div onMouseLeave={width > 768 ? this.hideCard : undefined} className="w-full" style={image}>
              <PSResponsiveUtility ref={this.responsiveUtility} deviceWidthCallback={this.getWidth} />
              <div className="flex m-auto justify-center flex-wrap relative" ref={this.getContainerPosition} style={{ lineHeight: '1.69' }} id="artistList">
                {lineupArrayInList.length > 0 && lineupArrayInList.map((artist, i) => (
                  <LineupListItem
                    // eslint-disable-next-line react/no-array-index-key
                    key={artist.postUri}
                    width={width}
                    artist={artist}
                    i={i}
                    activeArtist={activeArtist}
                    setArtistCard={this.setArtistCard}
                    window={window}
                    cardPosition={cardPosition}
                    length={lineupArrayInList.length}
                    getCardPosition={this.getCardPosition}
                    parentPosition={parentPosition}
                    underline={underline}
                    category={category || 'barcelona'}
                    eventSlug={eventSlug}
                    localization={localization}
                    favorite={artist.artistSetIsFavorite}
                    isUserLoggedIn={isUserLoggedIn}
                    handleSetArtistSetFavorite={handleSetArtistSetFavorite}
                  />
                ))}
              </div>
              {lineupArrayInList && finalMessage && (
                <div className="font-americaBlack text-3xl my-1 pointer w-full flex justify-center">
                  {' '}
                  <Link to={linkTo || '/'} className="text-black">
                    {translateObj(finalMessage, localization.language)}
                  </Link>
                  {' '}
                </div>
              )}
            </div>
          );
        }}
      </RoutesContext.Consumer>
    );
  }
}

export default withRouter(LineupList);

LineupList.propTypes = {
  artistSets: PropTypes.arrayOf(PropTypes.shape({})),
  artists: PropTypes.arrayOf(PropTypes.shape({})),
  underline: PropTypes.bool,
  finalMessage: PropTypes.shape({}),
  eventSlug: PropTypes.string,
};

LineupList.defaultProps = {
  underline: false,
  finalMessage: null,
  artistSets: null,
  artists: [],
  eventSlug: '',
};
