import React from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import Link from '../../util/link';
import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';
import imageModifier from './helpers/imageModifier';
import translateObj from '../../util/translateObj';
import LinkButtonRounded from './Buttons/LinkButtonRounded';

const bannersRowItem = ({
  color, bgColor, bgImage, preTitle, title, text, link, match, bannerClick, overlayImage,
}) => (
  <RoutesContext.Consumer>
    {({ language: contextLanguage, enteredFrom }) => {
      const localization = getLocalization(contextLanguage, enteredFrom, match);

      const hasVideo = (getFormat = false) => {
        const localsrc = translateObj(bgImage, localization.language);
        const arr = localsrc.split('.');
        const lastEl = arr[arr.length - 1];

        if (['mp4', 'ogg', 'webm'].includes(lastEl)) {
          if (getFormat) return lastEl;
          return true;
        }

        return false;
      };

      const premiumLogo = (
        <div className="absolute pin-t pin-r mt-2 mr-2">
          <img src={imageModifier(overlayImage, '45x45')} alt="Premium content" />
        </div>
      );

      const itemContent = (
        <div className="">
          {
              hasVideo() ? (
                <div className="relative h-full w-full overflow-hidden">
                  <video
                    autoPlay
                    muted
                    loop
                    className="w-full h-full"
                    src={`${translateObj(bgImage, localization.language)}`}
                    type={`video/${hasVideo(true)}`}
                    style={{ objectFit: 'cover' }}
                  />
                  <div className="absolute pin-b pin-r p-8">
                    <div
                      className="cursor-pointer"
                      onClick={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                      }}
                    >
                      <svg className="w-12 h-12" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.736 56.736">
                        <g data-name="g3204" fill="none" stroke="#fff">
                          <path data-name="l114" d="M28.368 53.736a25.368 25.368 0 0025.368-25.368A25.368 25.368 0 0028.368 3 25.368 25.368 0 003 28.368a25.368 25.368 0 0025.368 25.368z" strokeWidth="6" />
                          <path data-name="l116" d="M23.311 19.098v18.539" strokeLinecap="round" strokeWidth="4" />
                          <path data-name="l117" d="M33.425 19.098v18.539" strokeLinecap="round" strokeWidth="4" />
                        </g>
                      </svg>
                    </div>
                  </div>
                  {overlayImage && premiumLogo}
                </div>
              ) : (
                <div className="relative">
                  <div
                    className="h-full"
                  >
                    <img src={imageModifier(translateObj(bgImage, localization.language), '1024x0')} alt={translateObj(title, localization.language)} />
                  </div>
                  <div
                    className={`absolute pin-t pin-b pin-r pin-l z-10 px-5 py-4 sm:py-8 sm:px-8 font-stratos font-bold text-${color} flex flex-col justify-stretch items-start h-full`}
                    style={{
                      // backgroundImage: `url("${translateObj(bgImage, localization.language)}")`,
                      // backgroundColor: `${bgColor}`,
                      // backgroundPosition: 'center',
                      // backgroundRepeat: 'no-repeat',
                      // backgroundSize: 'cover',
                    }}
                  >
                    <span className="mb-2 laptop:font-sm">{translateObj(preTitle, localization.language)}</span>
                    <h3 className="max-w-xs mb-4 leading-none text-24 sm:text-50 sm:max-w-sm sm:mb-6">
                      {translateObj(title, localization.language)}
                    </h3>
                    <p className="flex-1 max-w-xs pr-4 text-base font-normal leading-tight sm:text-20 sm:max-w-sm">
                      {translateObj(text, localization.language)}
                    </p>
                    {link.url !== '' && (
                    <div className="flex items-center justify-center w-full sm:justify-end mt-6 cursor-pointer">
                      <LinkButtonRounded
                        text={link.text}
                        url={link.url}
                        match={match}
                        color={color}
                        isLowercase
                        isDisabled
                      />
                    </div>
                    )}
                  </div>
                  {overlayImage && premiumLogo}
                </div>
              )
            }
        </div>
      );

      if (bannerClick && bannerClick !== '') {
        const isExternalLink = bannerClick?.includes('http://') || bannerClick?.includes('https://');
        return (
          <>
            {isExternalLink ? (
              <a href={bannerClick} target="_blank" rel="noopener noreferrer">
                {itemContent}
              </a>
            ) : (
              <Link to={bannerClick} localizationpath={localization.path}>
                {itemContent}
              </Link>
            )}
          </>
        );
      }

      return (
        <Link to={`${link.url}`} localizationpath={localization.path} className="cursor-default">
          {itemContent}
        </Link>
      );
    }}
  </RoutesContext.Consumer>
);

export default withRouter(bannersRowItem);

bannersRowItem.propTypes = {
  color: PropTypes.string,
  bgColor: PropTypes.string,
  bgImage: PropTypes.shape({
    en: PropTypes.string,
    es: PropTypes.string,
  }),
  preTitle: PropTypes.shape({
    en: PropTypes.string,
    es: PropTypes.string,
  }),
  title: PropTypes.shape({
    en: PropTypes.string,
    es: PropTypes.string,
  }),
  text: PropTypes.shape({
    en: PropTypes.string,
    es: PropTypes.string,
  }),
  link: PropTypes.shape({
    text: PropTypes.shape({
      en: PropTypes.string,
      es: PropTypes.string,
    }),
    url: PropTypes.string,
  }),
  match: PropTypes.shape().isRequired,
  bannerClick: PropTypes.string,
  overlayImage: PropTypes.string,
};

bannersRowItem.defaultProps = {
  color: 'white',
  bgColor: '#D8D8DA',
  preTitle: { en: '', es: '' },
  bgImage: { en: '', es: '' },
  title: { en: '', es: '' },
  text: { en: '', es: '' },
  link: { text: { en: '', es: '' }, url: '' },
  bannerClick: '',
  overlayImage: null,
};
