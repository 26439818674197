import gql from 'graphql-tag';

import { clientWeb } from '../graphqlAPI';

const SET_FRIEND = gql`
  mutation Post($artist: String!) {
    setUserFavourites(artist: $artist){
      success
    }
}`;

const setUserFavourite = async ({ artist }) => {
  try {
    const result = await clientWeb.mutate({
      mutation: SET_FRIEND,
      variables: {
        artist,
      },
      fetchPolicy: 'no-cache',
    })
      .then((queryResult) => queryResult)
      .catch((queryError) => {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error: ${queryError}`);
        return null;
      });

    const { data: { setUserFavourite: validatedFormData } } = result;

    if (validatedFormData) {
      return validatedFormData;
    }
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: GraphQL Query Error: validatedFormData = null`);
    return null;
  } catch (error) {
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: setUserFavourite Error: ${error}`);
    return null;
  }
};

export default setUserFavourite;
