import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useRoutes } from '../../../context/routesContext';

import translateObj from '../../../util/translateObj';
import getTranslation from './util/translations/_getTranslation';
import localTranslation from './util/translations/MembersList';
import useDebounce from '../../../util/useDebounce';

import HeadTitle from './HeadTitle';
import CtaUnderline from './CtaUnderline';
import MemberItem from './MemberItem';
import Filters from './Filters';
import useUsersList from './util/hooks/useUsersList';

const ABC = ['#', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];

const normalizeString = (string) => {
  const normalizedString = string.toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  return normalizedString;
};

const MemberList = ({
  translation,
}) => {
  const { language } = useRoutes();
  const literals = getTranslation(translation, localTranslation);
  const [expandedItem, setExpandedItem] = useState(null);
  const [qty, setQty] = useState(50);
  const [filterByFavorites, setFilterByFavorites] = useState(false);
  const [filterByLetter, setFilterByLetter] = useState(null);
  const [filterByCountry, setFilterByCountry] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, 500);

  const { usersList, hasMoreItems, updateList } = useUsersList({
    query: debouncedSearchValue,
    to: qty,
    onlyFriends: filterByFavorites,
    letter: filterByLetter,
    country: filterByCountry,
  });

  const handleFilterByLetter = (letter) => {
    if (letter === filterByLetter) {
      setFilterByLetter(null);
    } else {
      setFilterByLetter(letter);
    }
  };

  const handleExpandItem = (id) => {
    if (expandedItem === id) {
      setExpandedItem(null);
    } else {
      setExpandedItem(id);
    }
  };

  useEffect(() => {
    if (qty > 50) {
      setQty(50);
    }
  }, [debouncedSearchValue, filterByFavorites, filterByLetter]);

  return (
    <div className="w-full full-width pb-24">
      <Filters
        literals={literals}
        onlyFriends={filterByFavorites}
        radioButtonsOptions={[
          { name: translateObj(literals.filtersAll, language), value: 'all', defaultValue: true },
          { name: translateObj(literals.filtersFavorites, language), value: 'favorites' },
        ]}
        radioButtonsValueCallback={(value) => (value === 'favorites' ? setFilterByFavorites(true) : setFilterByFavorites(false))}
        searchInputEventCallback={(event) => setSearchValue(event.target.value)}
        countrySelectValueCallback={(value) => (value?.length > 0 ? setFilterByCountry(value) : setFilterByCountry(null))}
      >
        {/* filter by letter desktop */}
        <div className="sm:hidden mt-8 flex items-center justify-start">
          {
            ABC.map((letter, index) => {
              const selected = filterByLetter === letter;
              return (
                <div key={letter} className="flex items-center">
                  {index !== 0 && <div className="px-2">·</div>}
                  <div className={`rounded-full cursor-pointer h-6 w-6 flex items-center justify-center ${selected ? 'bg-black' : 'bg-transparent'}`} onClick={() => handleFilterByLetter(letter)}>
                    <span className={`uppercase text-14 ${selected ? 'text-pro-online font-americaMonoBold' : 'text-black font-americaMonoRegular'}`}>{letter}</span>
                  </div>
                </div>
              );
            })
          }
        </div>
        {/**/}
      </Filters>
      <div className="w-full px-4">
        <HeadTitle title={translateObj(literals.title, language)} className="w-full sm:pb-6 pb-12 sm:pt-4 pt-8" />
      </div>
      <div className="sm:hidden mx-4 border-b border-black flex items-center flex-start py-4 font-americaMonoRegular uppercase text-12">
        <div className="pl-1/12 w-4/12">{translateObj(literals.labelMembers, language)}</div>
        <div className="w-2/12">{translateObj(literals.labelCompany, language)}</div>
        <div className="w-2/12">{translateObj(literals.labelBusiness, language)}</div>
        <div className="w-4/12 pr-2/12">{translateObj(literals.labelCountry, language)}</div>
      </div>
      <div className="relative pb-12 sm:min-h-screen">
        {/* filter by letter mobile */}
        <div className="hidden sm:block absolute h-full">
          <div className="relative h-full">
            <div className="sticky-directory-letters px-4 py-2 h-screen overflow-scroll">
              {ABC.map((letter) => {
                const selected = filterByLetter === letter;
                return (
                  <div key={letter} className="flex flex-col">
                    <div className={`rounded-full cursor-pointer h-4 w-4 flex items-center justify-center ${selected ? 'bg-black' : 'bg-transparent'}`} onClick={() => handleFilterByLetter(letter)}>
                      <span className={`uppercase text-12 ${selected ? 'text-pro-online font-americaMonoBold' : 'text-black font-americaMonoRegular'}`}>{letter}</span>
                    </div>
                    <div className="text-center py-1">·</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {/**/}
        {usersList.length > 0 ? (
          <>
            {
            usersList.map(((member, index) => {
              const previousLetter = index === 0 ? 'none' : normalizeString(usersList[index - 1].name.substring(0, 1));
              let currentLetter = normalizeString(member.name.substring(0, 1));
              if (!currentLetter.match(/[A-Za-zÀ-ÿ]/)) {
                currentLetter = '#';
              }
              return (
                <div key={member._id}>
                  {currentLetter !== previousLetter && <div className={`uppercase text-40 sm:text-20 px-4 font-tiemposBold pb-4 sm:pb-2 pt-12 ${index === 0 ? 'sm:pt-0' : 'sm:pt-4'} sm:ml-8`}>{currentLetter}</div>}
                  <MemberItem
                    {...member}
                    favoriteCallback={() => updateList()}
                    expandCallback={() => handleExpandItem(member._id)}
                    isActive={expandedItem === member._id}
                  />
                </div>
              );
            }))
}
          </>
        ) : <div className="font-tiemposRegular sm:text-12 text-14 px-4 sm:ml-8 sm:py-2 py-12 text-center">{translateObj(literals.noItemsFoundMessage, language)}</div>}
      </div>
      {hasMoreItems && (
        <div className="px-4">
          <CtaUnderline text={translateObj(literals.loadMore, language)} onClickCallback={() => setQty(qty + 50)} />
        </div>
      )}
    </div>
  );
};

MemberList.propTypes = {
  translation: PropTypes.shape(),
};

MemberList.defaultProps = {
  translation: null,
};

export default MemberList;
