import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Modal from 'react-responsive-modal';
import { useRoutes } from '../../../../context/routesContext';
import useResponsiveUtility from '../../../../util/responsiveUtility';
import translateObj from '../../../../util/translateObj';

import { activeChannelInitialState } from '../../Chat/hooks/useMessages';
import { removeChatRoom } from '../../Chat/utils/helpers';

import Button from '../Button';

const getModalStyles = (mobileScreen) => ({
  root: {
    width: '100vw',
    height: '100%',
    padding: 0,
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
    width: '100vw',
    height: '100%',
    padding: 0,
  },
  modalContainer: {
    width: '100vw',
    height: '100%',
    padding: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modal: {
    background: 'rgba(255, 255, 255, 1)',
    boxShadow: 'none',
    width: mobileScreen ? '90vw' : '35vw',
    maxWidth: mobileScreen ? '90vw' : '35vw',
    height: 'auto',
    padding: 0,
    margin: 'auto',
    borderRadius: '0.5rem',
  },
});

const DeleteChatModal = ({
  channel,
  open,
  onClose,
  activeChannelCallback,
  literals,
}) => {
  const { language } = useRoutes();
  const { deviceScreen } = useResponsiveUtility();
  const [loading, setLoading] = useState(false);

  const modalStyles = getModalStyles(['sm', 'md'].includes(deviceScreen));

  const removeChannel = async () => {
    setLoading(true);
    const response = await removeChatRoom({ channel });
    if (response?.result) {
      activeChannelCallback(activeChannelInitialState);
    } else {
      console.log(response?.error);
    }
    setLoading(false);
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      showCloseIcon
      styles={modalStyles}
      closeOnOverlayClick
      focusTrapped={false}
    >
      <div className="text-black relative pt-20 pb-10 px-6 h-full w-full">
        <h3 className="text-20 font-americaBlack mb-4 text-center">
          {translateObj(literals.deleteTitle, language)}
        </h3>
        <p className="font-americaMonoRegular text-base mb-8 text-center">{translateObj(literals.deleteText, language)}</p>
        <div className="flex items-center justify-between">
          <Button
            onClickCallback={() => onClose()}
            text={translateObj(literals.buttonCancel, language)}
            className="flex-1 mr-2"
          />
          <Button
            onClickCallback={() => removeChannel()}
            text={translateObj(literals.buttonDelete, language)}
            className="flex-1 ml-2"
            color="black"
            loading={loading}
          />
        </div>
      </div>
    </Modal>
  );
};

DeleteChatModal.propTypes = {
  channel: PropTypes.shape().isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  activeChannelCallback: PropTypes.func.isRequired,
  literals: PropTypes.shape().isRequired,
};

DeleteChatModal.defaultProps = {
};

export default DeleteChatModal;
