import translation from './__translationHelper';

const data = {
  type: 'Footer',
  // provisional logo
  logo: translation('https://s3-eu-west-1.amazonaws.com/assets.primaverasound.com/ps/images/logos/ps_logo_web.svg'),
  links: [
    {
      key: 'legal',
      text: translation('Aviso legal'),
      link: '/primavera-sound-festival-terms-and-conditions',
    },
    {
      key: 'cookies',
      text: translation('Política de cookies'),
      link: '/cookies-policy',
    },
    {
      key: 'terms',
      text: translation('Términos & condiciones'),
      link: '/primavera-sound-festival-terms-and-conditions',
    },
    {
      key: 'register-conditions',
      text: translation('Condiciones de entradas'),
      link: '/user-register-conditions-for-primavera-sound',
    },
  ],
};

export default data;
