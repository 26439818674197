import React from 'react';
import { PropTypes } from 'prop-types';

import { withRouter } from 'react-router-dom';
import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';
import InputTextButton from './InputTextButton';
import translateObj from '../../util/translateObj';

const SearchRepeat = ({
  match, handleEnter,
}) => (
  <RoutesContext.Consumer>
    {
      ({ language: contextLanguage, enteredFrom }) => {
        const localization = getLocalization(contextLanguage, enteredFrom, match);
        const text1 = {
          en: 'REPEAT THE SEARCH',
          ca: 'REPETIR LA CERCA',
          es: 'Repetir la búsqueda',
          pt: 'REPETIR A PESQUISA',
        };

        return (
          <div className="p-8 bg-white mt-8 w-full">
            <span className="font-americaMonoBold block text-sm text-black uppercase pb-8 mb-8 border-dashed border-black border-b-1">{translateObj(text1, localization.language)}</span>
            <InputTextButton
              name="searchQuery"
              params={{
                text: {
                  es: 'buscar', en: 'search', ca: 'cerca', pt: 'buscar',
                },
                placeholder: { es: '', en: '', ca: '' },
                searchIcon: 'font-icon icon-search',
              }}
              searchCallback={handleEnter}
            />
          </div>
        );
      }
    }
  </RoutesContext.Consumer>
);

SearchRepeat.propTypes = {
  match: PropTypes.shape().isRequired,
  handleEnter: PropTypes.func.isRequired,
};

export default withRouter(SearchRepeat);
