import gql from 'graphql-tag';

import { clientWeb } from '../graphqlAPI';

const USER_WEB_LOGIN = gql`
  mutation Post($email: String!, $redirectUri: String, $password: String!) {
    userWebLogin(
      email: $email,
      redirectUri: $redirectUri,
      password: $password,
      ){
        isValid
        isVerified
        token
        error
    }
}`;

const userWebLogin = async ({
  email, redirectUri, password,
}) => {
  try {
    const result = await clientWeb.mutate({
      mutation: USER_WEB_LOGIN,
      variables: {
        email,
        redirectUri,
        password,
      },
      fetchPolicy: 'no-cache',
    })
      .then(queryResult => queryResult)
      .catch((queryError) => {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error: ${queryError}`);
        return null;
      });

    const { data: { userWebLogin: validatedFormData } } = result;

    if (validatedFormData) {
      return validatedFormData;
    }
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: GraphQL Query Error: validatedFormData = null`);
    return null;
  } catch (error) {
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: userWebLogin Error: ${error}`);
    return null;
  }
};

export default userWebLogin;
