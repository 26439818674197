/* eslint-disable eol-last */
/* eslint-disable semi */
/* eslint-disable comma-dangle */
/* eslint-disable quote-props */
/* eslint-disable quotes */
/* eslint-disable indent */
const error401Page = (appName = 'Primavera Sound') => (
  {
    "slugName": "401",
    "name": "401",
    "postCategory": [
      "401"
    ],
    "title": "401",
    "type": "route",
    "postDescription": {
      "title": {
        "en": `${appName} - Error 401`
      },
      "description": {
        "en": `${appName} - Error 401`
      },
      "url": ""
    },
    "components": [
      {
        "name": "header",
        "type": "Header",
        "removeRadio": true,
        "backgroundColor": "black",
        "components": [
      //     {
      //   "type": "TopLogoContainer",
      //   "name": "logo",
      //   "image": {
      //     "name": "Primavera Sound",
      //     "size": {
      //       "sm": "https://assets.primaverasound.com/ps/images/assets/logo_sm.svg",
      //       "md": "https://assets.primaverasound.com/ps/images/logos/ps_logo_web.svg"
      //     }
      //   }
      // }
      // {
      //   "type": "TopHeaderMenuContainer",
      //   "name": "menuContainer",
      //   "components": [
      //     {
      //       "key": "top-header-submenu-with-login"
      //     },
      //     {
      //       "key": "top-header-menu-container"
      //     }
      //   ]
      // }
    ]
  },
      {
        "type": "Body",
        "slugName": "error401",
        "components": [
          {
            "type": "BodyChildContainer",
            "slugName": "homeBodyContainer",
            "components": [
              {
                "type": "Error401",
                "params": [
                  {
                    "name": "garfield",
                    "title": {
                      "en": "Meow! looks like it is not so bad...",
                      "es": "Miauu! Parece que no es para tanto..",
                      "ca": "Miauu! Sembla que no és tant dolent..."
                    },
                    "text": {
                      "en": "The site exists, but you do not seem to have the required access",
                      "es": "La página existe pero pareces no tener los permisos necesarios para acceder",
                      "ca": "La pàgina existeix però et falten els permissos necessaris per accedir"
                    },
                    "image": "https://media.giphy.com/media/MuJDYvbYKzMwONKvdO/source.gif"
                  },
                  {
                    "name": "tv1",
                    "title": {
                      "en": "Hey! looks like it is not so bad...",
                      "es": "Parece que no es para tanto..",
                      "ca": "Sembla que no és tant dolent..."
                    },
                    "text": {
                      "en": "The site exists, but you do not seem to have the required access",
                      "es": "La página existe pero pareces no tener los permisos necesarios para acceder",
                      "ca": "La pàgina existeix però et falten els permissos necessaris per accedir"
                    },
                    "image": "https://media.giphy.com/media/Ph0oIVQeuvh0k/source.gif"
                  },
                  {
                    "name": "tv2",
                    "title": {
                      "en": "Hey! looks like it is not so bad...",
                      "es": "Parece que no es para tanto..",
                      "ca": "Sembla que no és tant dolent..."
                    },
                    "text": {
                      "en": "The site exists, but you do not seem to have the required access",
                      "es": "La página existe pero pareces no tener los permisos necesarios para acceder",
                      "ca": "La pàgina existeix però et falten els permissos necessaris per accedir"
                    },
                    "image": "https://media.giphy.com/media/OVlFjmEDhx9rG/source.gif"
                  }
                ],
                "linkButtonRounded": {
                  "name": "error401",
                  "is404": true,
                  "text": {
                    "es": "Llévame a la home",
                    "en": "Take me home",
                    "ca": "Porta'm a la home"
                  },
                  "className": "rounded-full uppercase text-base sm:text-14 md:text-14 py-2 md:px-8 px-16 sm:px-8 font-americaMonoBold text-black border-2 border-black sm:whitespace-pre",
                  "url": "/"
                }
              }
            ]
          }
        ]
      }
    ]
  }
)

export default error401Page;
