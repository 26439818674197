import React from 'react';
import PropTypes from 'prop-types';
import { buttonClasses } from '../OnDemand/OnDemandUtils/onDemandUtils';

import translateObj from '../../../util/translateObj';
import Link from '../../../util/link';

// import LoadIcon from '../LoadIcon';

const cancelButtonText = {
  ca: 'cancel·la',
  es: 'CANCELA',
  en: 'CANCEL',
  pt: 'CANCELA',
};
const otherOptionsButtonText = {
  ca: 'veure altres opcions',
  en: 'see other options',
  es: 'ver otras opciones',
  pt: 'see other options',
};

const StripeSelectCardScreen = ({
  backgroundImage, otherOptionsButtonUrl, handleOnDemandScreenStatus, donationTranslations: { selectCard: formText = {} } = {}, language, closeModal, handleDonationModalStatus, paymentMethods,
}) => {
  const backgroundStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  };

  return (
    <div className="backgroundImgGray w-full">
      <div>
        <div
          className={`${'h-80'} w-full  sliderBackground`}
          style={backgroundStyle}
        />
      </div>
      <div className="w-full bg-white-dark py-2 ">
        <div className="py-2 px-4">
          <div>{translateObj(formText.donationTitle, language)}</div>
          <div>{translateObj(formText.donationDescription, language)}</div>
          {/* <div>{translateObj(formText.subscribeConditions, language)}</div> */}
        </div>
        <div className=" py-2 px-4 bg-gray200">
          <div>{translateObj(formText.donationConfigurePayment, language)}</div>
          <div>
            {paymentMethods && paymentMethods.length && paymentMethods.map(method => (
              <div className="bg-white text-black my-2 flex space-between">
                <div>{`${method.card.brand}`}</div>
                <div>{`${method.card.last4}`}</div>
                <div>{`${method.card.exp_month}/${method.card.exp_year}`}</div>
              </div>
            ))}
          </div>
          <div className="w-3/4">
            <button
              onClick={() => { handleDonationModalStatus('addCard'); }}
              className={` ${buttonClasses({ backgroundColor: 'white', textColor: 'black' })}`}
            >
              {translateObj(formText.donationAddAnotherCard, language)}
            </button>
          </div>
        </div>
        <div className="flex justify-end px-4 pt-6 pb-4">
          <button onClick={() => closeModal()} className="uppercase rounded-full bg-white-dark text-black font-americaMonoBold sm:w-full cursor-pointer  text-center px-12 py-4 md:mt-4 sm:mt-4 ">{translateObj(cancelButtonText, language)}</button>
          { otherOptionsButtonUrl && (
          <Link to={otherOptionsButtonUrl}>
            <button className="uppercase rounded-full bg-gray200 text-black font-americaMonoBold sm:w-full cursor-pointer  text-center px-12 py-4 md:mt-4 sm:mt-4">{translateObj(otherOptionsButtonText, language)}</button>
          </Link>
          )}
        </div>
      </div>
    </div>
  );
};

StripeSelectCardScreen.propTypes = {
  backgroundImage: PropTypes.string,
  otherOptionsButtonUrl: PropTypes.string,
  language: PropTypes.string,
  handleOnDemandScreenStatus: PropTypes.func.isRequired,
  translations: PropTypes.shape().isRequired,
  closeModal: PropTypes.func.isRequired,
  handleDonationModalStatus: PropTypes.func.isRequired,
  paymentMethods: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

StripeSelectCardScreen.defaultProps = {
  language: 'en',
  backgroundImage: 'https://assets.primaverasound.com/psweb/7bypjwp68fgluagjhgcn_1589804072572.png',
  otherOptionsButtonUrl: '',
};

export default StripeSelectCardScreen;
