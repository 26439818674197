const translateObj = (obj, language = 'en') => {
  let rObj = '';
  if (!obj) rObj = '';
  // if obj is not an array so we will return default value
  // * This means that something is wrong in the object
  else if (!Array.isArray(obj) && typeof obj === 'string') rObj = obj;
  // if obj has no current language but default go for default
  else if (!obj[language] && obj.en) rObj = obj.en;
  // if obj has current language go for it
  else if (obj[language]) rObj = obj[language];

  return rObj;
};

export default translateObj;
