import gql from 'graphql-tag';

import { clientWeb, persistor } from '../graphqlAPI';


const GET_REUNION = gql`
  query Get($reunionId: String!, $fetchDate: String) {
    getReunion(reunionId: $reunionId, fetchDate: $fetchDate) {
      _id
      title
      date
      participants {
        _id
        nickname
        lastname
        name
        imageUrl
        occupation
        company
        inputCountry
        areFriends
      }
      confirmedParticipants {
        _id
        name
        lastname
        company
      }
      rejectedParticipants {
        _id
        name
        lastname
        company
      }
      createdByUser {
        _id
        name
        lastname
        company
      }
      location
      description
      online
      link
      createdBy
      roles
      cancelled
      confirmed
    }
  }
`;

const executeQuery = async (reunionId, type = 'network') => {
  const result = await clientWeb.query({
    query: GET_REUNION,
    variables: { reunionId, fetchDate: new Date().getTime().toString() },
    fetchPolicy: type === 'network' ? 'network-only' : 'cache-only',
  })
    .then(queryResult => queryResult)
    .catch((queryError) => {
      const errorMessage = type === 'network'
        ? `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error: ${queryError}`
        : `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error after Cache Restore: ${queryError}`;
      console.log('\x1b[31m%s\x1b[0m', errorMessage);

      return null;
    });

  if (result.data && result.data.getReunion) {
    const { data: { getReunion: getReunionData } } = result;
    return getReunionData;
  }

  return [];
};

const getReunion = async (reunionId) => {
  try {
    const JSONObject = await executeQuery(reunionId);

    if (persistor) {
      persistor.persist();
    }

    return JSONObject;
  } catch (jsonError) {
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: JSON Object Error: ${jsonError}`);

    if (persistor) {
      const persistorStatus = await persistor.restore().then(() => 'OK');
      console.log(`${new Date().toLocaleTimeString()} ~ Client: Apollo Cache Restored - ${persistorStatus}`);

      try {
        const JSONObject = await executeQuery(reunionId, 'cache');

        return JSONObject;
      } catch (jsonErrorAfterCacheRestore) {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: JSON Object Error after Cache Restore, sending 404: ${jsonErrorAfterCacheRestore}`);
        return null;
      }
    }
    return null;
  }
};

export default getReunion;
