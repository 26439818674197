import translation from './__translationHelper';
import header from './_header';
import footer from './_footer';
import sessions from './_mentoringSessionItem_array';

const data = [
  { ...header },
  {
    type: 'MentoringSessionDetail',
    eventSlugName: 'the-aestheticization-of-sw-in-music-pro2021',
  },
  {
    type: 'RelatedSessions',
    title: translation('Sesiones relacionadas'),
    loadMore: translation('Load more'),
    components: sessions, // related to session in detail
  },
  { ...footer },
];

export default data;
