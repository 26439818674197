import api from '../psWorldPaymentsApi';

const getPaymentDetails = async (userEmail) => {
  const result = {
    paymentDetailsStatus: 'loading',
    customerInfo: null,
    paymentMethods: null,
    userEmail,
  };
  if (!userEmail) {
    result.paymentDetailsStatus = 'notLogged';
    return result;
  }
  const customerInfo = await api.getOrCreateCustomer(userEmail);
  result.customerInfo = customerInfo;
  if (!customerInfo || !Object.keys(customerInfo).length) {
    result.paymentDetailsStatus = 'notCreated';
    return result;
  }
  const paymentMethods = await api.getCustomerPaymentMethods(customerInfo.id);
  result.paymentMethods = paymentMethods;
  if (!paymentMethods || !paymentMethods.length) {
    result.paymentDetailsStatus = 'noPaymentMethods';
    return result;
  }
  if (paymentMethods.length > 1) {
    result.paymentDetailsStatus = 'hasMultiplePaymentMethods';
  } else {
    result.paymentDetailsStatus = 'hasAPaymentMethod';
  }

  return result;
};

const getCustomerPaymentMethods = async (userEmail, customerInfo) => {
  const result = {
    getCustomerPaymentStatus: 'loading',
    customerPaymentMethods: null,
  };
  if (!userEmail) {
    result.getCustomerPaymentStatus = 'notLogged';
    return result;
  }
  if (!customerInfo || !Object.keys(customerInfo).length) {
    result.getCustomerPaymentStatus = 'notCreated';
    return result;
  }
  const paymentMethods = await api.getCustomerPaymentMethods(customerInfo.id);
  result.customerPaymentMethods = paymentMethods;
  if (!paymentMethods || !paymentMethods.length) {
    result.getCustomerPaymentStatus = 'noPaymentMethods';
    return result;
  }

  if (paymentMethods.length > 1) {
    result.getCustomerPaymentStatus = 'hasMultiplePaymentMethods';
  } else {
    result.getCustomerPaymentStatus = 'hasAPaymentMethod';
  }
  return result;
};

const getProductDetails = async (productSlugName) => {
  const result = {
    productFromQuery: null,
    productTypeFromQuery: null,
    amountFromQuery: 0,
    currencyFromQuery: null,
  };


  const productDetails = await api.getProductDetails(productSlugName);
  if (productDetails) {
    result.productFromQuery = productDetails;
    result.productTypeFromQuery = productDetails.productType;
    if (productDetails.amount) {
      result.amountFromQuery = productDetails.amount;
    }
    result.currencyFromQuery = productDetails.currency;
    return result;
  }
  return result;
};


export { getPaymentDetails, getCustomerPaymentMethods, getProductDetails };
