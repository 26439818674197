import React from 'react';
import { PropTypes } from 'prop-types';
import parse from 'html-react-parser';
import { withRouter } from 'react-router-dom';
import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';
import translateObj from '../../../util/translateObj';

const NormalBodyText = ({
  text,
  match,
  classes,
  enhanced,
  paddingLeft,
  paddingBottom,
  anchorTag,
  firstLetterBig,
  paddingTop,
  quote,
}) => (
  <RoutesContext.Consumer>
    {({ language: contextLanguage, enteredFrom }) => {
      const localization = getLocalization(contextLanguage, enteredFrom, match);
      let firstLetter = '';
      const multiLangText = translateObj(text, localization.language);

      const paragraphs = multiLangText
        .split('\n')
        .filter(n => n.length && n !== '<p></p>')
        .map((paragraph, i) => {
          const StrippedString = paragraph.replace(/(<([^>]+)>)/gi, '');
          if (i === 0 && firstLetterBig) {
            firstLetter = StrippedString[0];
            const re = new RegExp(`>${firstLetter}`, 'i');
            paragraph = paragraph.replace(re, '>');
          }
          return paragraph;
        });

      return (
        <div
          className={`w-full pr-4 md:px-0 xl:flex lg:flex ${(enhanced || quote) ? 'py-6 sm:pt-2 md:pt-2' : ''}
          ${paddingBottom && !enhanced ? 'pb-8' : ''}
          ${paddingTop && !enhanced ? 'pt-8' : ''}`}
          id={anchorTag}
        >
          {(paddingLeft || quote) && !firstLetterBig && (
            <div className="sm:hidden md:hidden xl:w-1/5 lg:w-1/5">
              <span className="invisible">X</span>
            </div>
          )}
          {firstLetter && (
            <div
              style={{ fontSize: 180 }}
              className=" xl:w-1/5 lg:w-1/5 pr-3 -mt-3 sm:-mt-2 md:-mt-2 text-right font-tiemposRegular sm:float-left md:float-left flex items-start justify-end"
            >
              <span className="-mt-10 sm:-mt-14 md:-mt-14">{firstLetter}</span>
            </div>
          )}
          {quote ? (
            <div className="w-full md:py-6 sm:py-4 lg:w-3/4 xl:w-3/4 flex">
              <div className=" xl:w-1/6 lg:w-1/6 pr-2  mt-4 sm:mt-0 md:mt-0 text-right font-tiemposRegular sm:float-left md:float-left flex items-center justify-center">
                <span className=" -mt-10 sm:-mt-14 md:-mt-14">
                  <span className="font-icon icon-quotes xl:-ml-6 lg:-ml-6 text-52 " />
                </span>
              </div>
              <div className={`${classes} w-full lg:w-5/6 xl:w-5/6`}>
                {paragraphs.map((paragraph, i) => (
                  parse(paragraph, { replace: (domNode) => {
                    if (domNode.name === 'p') {
                      Object.assign(domNode.attribs, { className: 'mt-4 leading-normal text-18 italic font-tiemposBold font-black' });
                      Object.assign(domNode.attribs, { key: `paragraph-${i + 1}` });
                      if (domNode.children && (domNode.children[0]?.data !== ' ') && (domNode.children[0]?.name !== 'br')) {
                        Object.assign(domNode.attribs, { tabIndex: '0' });
                      }
                    }
                  } })
                ))}
              </div>
            </div>
          ) : (
            <div
              className={`${classes} w-full  ${(paddingLeft || firstLetterBig) ? 'lg:w-3/4 xl:w-3/4' : ''}`}
            >
              {paragraphs.map((paragraph, i) => (
                parse(paragraph, { replace: (domNode) => {
                  if (domNode.name === 'p') {
                    Object.assign(domNode.attribs, { className: `mt-4 leading-normal font-tiemposRegular ${enhanced ? 'text-24 sm:text-20 font-tiemposBold font-black' : ''}` });
                    Object.assign(domNode.attribs, { key: `paragraph-${i + 1}` });
                    if (domNode.children && (domNode.children[0]?.data !== ' ') && (domNode.children[0]?.name !== 'br')) {
                      Object.assign(domNode.attribs, { tabIndex: '0' });
                    }
                  }
                } })
              ))}
            </div>
          )}
        </div>
      );
    }}
  </RoutesContext.Consumer>
);

NormalBodyText.propTypes = {
  text: PropTypes.shape({}),
  match: PropTypes.shape().isRequired,
  classes: PropTypes.string,
  enhanced: PropTypes.bool,
  paddingLeft: PropTypes.bool,
  paddingTop: PropTypes.bool,
  paddingBottom: PropTypes.bool,
  anchorTag: PropTypes.string,
  firstLetterBig: PropTypes.bool,
  quote: PropTypes.bool,
};

NormalBodyText.defaultProps = {
  classes: '',
  text: null,
  enhanced: false,
  paddingLeft: false,
  paddingTop: false,
  paddingBottom: false,
  anchorTag: '',
  firstLetterBig: false,
  quote: false,
};

export default withRouter(NormalBodyText);
