import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import Link from '../../../util/link';
import getLocalization from '../../../util/localization';
import translateObj from '../../../util/translateObj';

import RoutesContext from '../../../context/routesContext';
import { UserProfileContextConsumer } from '../../../context/userProfileContext';

import SearchOverlay from '../SearchOverlay';
import UserMenuPopup from '../UserMenuPopup';
import withAllContexts from '../../../context/withAllContexts';

class TopHeaderSubmenuItemsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isActiveSearch: false,
    };
  }

  handleSearchOverlay = () => {
    const { isActiveSearch } = this.state;
    this.setState({
      isActiveSearch: !isActiveSearch,
    });
  }

  handleSearch = (query, lang) => {
    this.props.history.push(`/search?query=${query}&lang=${lang}`);
    this.handleSearchOverlay();
  }

  render() {
    const { name, mainParent, params, match, textColor, headerContext } = this.props;
    const { isActiveSearch } = this.state;

    return (
      <UserProfileContextConsumer>
        {({ userName, userLastName, token, loadTokenForLS }) => (
          <RoutesContext.Consumer>
            {({ language: contextLanguage, enteredFrom }) => {
              const localization = getLocalization(
                contextLanguage,
                enteredFrom,
                match,
              );

              const stylesContainer = (parent) => {
                let styles = '';
                if (parent === 'subMenu') {
                  styles = 'flex sm:hidden md:hidden';
                } else if (parent === 'loginMenu') {
                  styles = 'md:w-full flex ml-12 md:mr-0 md:ml-0 sm:mr-0 sm:ml-0 sm:flex-wrap md:flex-wrap justify-center md:justify-end sm:justify-end';
                } else if (parent === 'mobileMenu') {
                  styles = 'hidden sm:flex md:flex sm:pb-8 md:pb-8 flex-wrap sm:justify-center md:justify-center';
                } else {
                  styles = 'flex sm:flex md:flex flex-wrap sm:justify-center md:justify-center';
                }
                return styles;
              };

              const stylesButton = (parent) => {
                if (parent === 'footerBottomSubmenu') return `text-xxs sm:border-${textColor}`;
                if (parent === 'mobileMenu') return 'sm:border-black text-sm sm:text-black md:text-black';
                return 'text-xs md:text-xxs md:border-transparent sm:border-transparent';
              };

              const ButtonLink = ({ localization, key, icon, text }) => (
                <span
                  className={`
                    ${mainParent}
                    ${stylesButton(mainParent)}
                    ${key === 'tickets' ? 'hidden md:block sm:block' : ''}
                    font-americaRegular uppercase text-${textColor} tracking-submenu leading-none
                    px-2 sm:my-1 md:my-1 flex justify-center items-center
                  `}
                >
                  {icon && (
                    <span className={`${icon} text-base mr-2 sm:text-xl md:text-xl`} />
                  )}
                  {text && (
                    <span
                      className={name === 'loginMenu'
                        ? 'sm:hidden md:hidden align-text-top mb-px'
                        : ''
                      }
                    >
                      {translateObj(text, localization.language)}
                    </span>
                  )}
                </span>
              );

              ButtonLink.propTypes = {
                localization: PropTypes.shape().isRequired,
                icon: PropTypes.string.isRequired,
                key: PropTypes.string.isRequired,
                text: PropTypes.string.isRequired,
              };

              return (
                <>
                  <nav
                    id={name}
                    className={`sm:w-full items-center ${stylesContainer(mainParent)}`}
                  >
                    {params.map((link) => {
                      if (link.key === 'login') {
                        // First we will check we have a correct login at local storage
                        const fullName = userName
                          ? `${userName || ''} ${userLastName || ''}`
                          : null;

                        const currentUserNameProfile = fullName && fullName.length
                          ? {
                            en: `${
                              fullName.length < 20
                                ? fullName
                                : `${fullName.substring(0, 20)}...`
                            }`,
                          }
                          : {
                            en: 'LOG IN',
                            es: 'INICIA SESIÓN',
                            ca: 'INICIA SESSIÓ',
                            pt: 'INICIAR SESSÃO',
                          };

                        link.text = currentUserNameProfile;
                        link.link = userName
                          ? `/user-profile${(link.sourceWeb && `-${link.sourceWeb}`) || ''}`
                          : `/login-screen${(link.sourceWeb && `-${link.sourceWeb}`) || ''}?e=sign-in`;

                        // if not will show current button for login
                        // if we have a login we will change icon, text, and link for a user-profile page.
                      }
                      return (
                        <React.Fragment key={link.key}>
                          {link.key !== 'login' ? (
                            <>
                              {!userName && link.key === 'favoritesMobile'
                                ? null
                                : (
                                  <Link
                                    tabIndex={headerContext.megaMenuIsOpenDesktop ? '-1' : '0'}
                                    to={`${link.link}`}
                                    localizationpath={`${localization.path}`}
                                    className={`no-underline ${link.key} ${
                                      link.key === 'favoritesMobile'
                                        ? 'hidden sm:block md:block'
                                        : 'flex'
                                    }`}
                                    aria-label={translateObj(link.text, localization.language)}
                                  >
                                    <ButtonLink
                                      localization={localization}
                                      icon={link.icon}
                                      key={link.key}
                                      text={link.text}
                                    />
                                  </Link>
                                )
                              }
                            </>
                          ) : (
                            <UserMenuPopup
                              ButtonLink={ButtonLink}
                              link={link}
                              localization={localization}
                              userName={userName}
                              userLastName={userLastName}
                              token={token}
                              language={localization.language}
                              loadTokenForLS={loadTokenForLS}
                              history={this.props.history}
                              formText={link.popUpFormText}
                              sourceWeb={link.sourceWeb}
                            />
                          )}
                        </React.Fragment>
                      );
                    })}
                  </nav>
                  {isActiveSearch ? (
                    <SearchOverlay
                      closeCallback={this.handleSearchOverlay}
                      searchCallback={this.handleSearch}
                    />
                  ) : null}
                </>
              );
            }}
          </RoutesContext.Consumer>
        )}
      </UserProfileContextConsumer>
    );
  }
}

TopHeaderSubmenuItemsContainer.propTypes = {
  name: PropTypes.string.isRequired,
  match: PropTypes.shape().isRequired,
  mainParent: PropTypes.string,
  params: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
  textColor: PropTypes.string,
  headerContext: PropTypes.shape({}).isRequired,
};

TopHeaderSubmenuItemsContainer.defaultProps = {
  mainParent: null,
  textColor: 'black',
};

export default withRouter(withAllContexts(TopHeaderSubmenuItemsContainer));
