import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import imageModifier from './util/imageModifier';
import translateObj from '../../../util/translateObj';
import { useRoutes } from '../../../context/routesContext';

const SquareImage = ({ image, alt, className, objectFit, gradient, dimensions }) => {
  const { language } = useRoutes();
  const [imageUrl, setImageUrl] = useState(null);

  const placeholder = `https://assets-img.primaverasound.com/${dimensions}/psweb/70l5rj0swjfgrztwgx6w_1652638440365.png`;

  useEffect(() => {
    if (typeof image === 'string') {
      setImageUrl(imageModifier(image, dimensions));
    } else if (typeof image === 'object') {
      setImageUrl(imageModifier(translateObj(image, language), dimensions));
    } else {
      setImageUrl(placeholder);
    }
  }, [image]);

  return (
    <div
      className="relative w-full"
      style={{ paddingBottom: '100%' }}
    >
      <div className="absolute pin">
        <img
          src={imageUrl}
          alt={alt || ''}
          className={`w-full h-full ${objectFit} ${className}`}
          onError={() => setImageUrl(placeholder)}
        />
      </div>
      {gradient && image && <div className="absolute pin h-full w-full" style={{ backgroundImage: 'linear-gradient(to bottom,rgba(0, 0, 0, 0) 0%, #000000 95%' }} />}
    </div>
  );
};

SquareImage.propTypes = {
  image: PropTypes.string || PropTypes.shape(),
  alt: PropTypes.string,
  className: PropTypes.string,
  objectFit: PropTypes.string,
  gradient: PropTypes.bool,
  dimensions: PropTypes.string,
};

SquareImage.defaultProps = {
  image: null,
  alt: '',
  className: '',
  objectFit: 'object-cover',
  gradient: false,
  dimensions: '200x200',
};

export default SquareImage;
