import React from 'react';

const SingleSkeleton = () => (
  <>
    {Array(6)
      .fill()
      .map((item, index) => (
        <div key={index} className="p-8px launcherItem llg:w-1/3 lg:w-1/2 md:w-1/2 sm:w-full sm:px-0 sm:h-launcherSingle-sm md:h-launcherSingle-md lg:h-launcherSingle-lg llg:h-launcherSingle-llg">
          <div className="skeleton-bg w-full h-full" />
        </div>
      ))}
  </>
);

export default SingleSkeleton;
