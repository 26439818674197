export const LANG_OPTIONS = [
  {
    name: {
      en: 'Spanish',
      es: 'Español',
      ca: 'Espanyol',
      pt: 'Espanhol',
    },
    value: 'es',
  },
  {
    name: {
      en: 'English',
      es: 'Inglés',
      ca: 'Anglès',
      pt: 'Inglês',
    },
    value: 'en',
  },
  {
    name: {
      en: 'Catalan',
      es: 'Catalán',
      ca: 'Català',
      pt: 'Catalão',
    },
    value: 'ca',
  },
  {
    name: {
      en: 'Portuguese',
      es: 'Portugués',
      ca: 'Portuguès',
      pt: 'Português',
    },
    value: 'pt',
  },
];

export const VENUES_OPTIONS = [
  {
    name: {
      en: 'Hall CCCB - Working Area',
      es: 'Hall CCCB - Working Area',
      ca: 'Hall CCCB - Working Area',
    },
    value: 'hall-cccb-working',
  },
  {
    name: {
      en: 'Hall CCCB - Ideas Showroom',
      es: 'Hall CCCB - Ideas Showroom',
      ca: 'Hall CCCB - Ideas Showroom',
    },
    value: 'hall-cccb-working-ideas',
  },
  {
    name: {
      en: 'Networking Bar CCCB',
      es: 'Networking Bar CCCB',
      ca: 'Networking Bar CCCB',
    },
    value: 'networking-bar-cccb',
  },
  {
    name: {
      en: 'Primavera Pro VIP Area Fòrum',
      es: 'Primavera Pro VIP Area Fòrum',
      ca: 'Primavera Pro VIP Area Fòrum',
    },
    value: 'vip-area-forum',
  },
];

export const BUSINESS_OPTIONS = [
  {
    name: {
      en: 'Public Administration',
      es: 'Administración pública',
      ca: 'Administració pública',
    },
    value: 'publicAdministration',
  },
  {
    name: {
      en: 'Agent / Representative',
      es: 'Agente / Representante',
      ca: 'Agent / Representant',
    },
    value: 'agent',
  },
  {
    name: {
      en: 'Mobile applications',
      es: 'Aplicaciones móviles',
      ca: 'Aplicacions mòbils',
    },
    value: 'mobileApps',
  },
  {
    name: {
      en: 'Artist',
      es: 'Artista',
      ca: 'Artista',
    },
    value: 'artist',
  },
  {
    name: {
      en: 'Association',
      es: 'Asociación',
      ca: 'Associació',
    },
    value: 'association',
  },
  {
    name: {
      en: 'Audio-visual Creation',
      es: 'Creación audiovisual',
      ca: 'Creació audiovisual',
    },
    value: 'audiovisualCreation',
  },
  {
    name: {
      en: 'Technological Development',
      es: 'Desarrollador tecnológico',
      ca: 'Desenvolupador tecnològic',
    },
    value: 'technologicalDevelopment',
  },
  {
    name: {
      en: 'Graphic design / Illustration / Animation',
      es: 'Diseño gráfico / Ilustración / Animación',
      ca: 'Disseny gràfic / Il·lustració / Animació',
    },
    value: 'graphicDesign',
  },
  {
    name: {
      en: 'Digital Distributor / Aggregator',
      es: 'Distribuidora / Agregador digital',
      ca: 'Distribuïdora / Agregador digital',
    },
    value: 'distributor',
  },
  {
    name: {
      en: 'Festival / Event / Convention',
      es: 'Festival / Evento / Convención',
      ca: 'Festival / Esdeveniment / Convenció',
    },
    value: 'festival',
  },
  {
    name: {
      en: 'Training / Research',
      es: 'Formación / Investigación',
      ca: 'Formació / Recerca',
    },
    value: 'research',
  },
  {
    name: {
      en: 'Cultural Management',
      es: 'Gestión Cultural',
      ca: 'Gestió Cultural',
    },
    value: 'culturalManagement',
  },
  {
    name: {
      en: 'Investment / Start Up',
      es: 'Inversión / Startup',
      ca: 'Inversió / Startup',
    },
    value: 'investment',
  },
  {
    name: {
      en: 'Legal',
      es: 'Legal',
      ca: 'Legal',
    },
    value: 'legal',
  },
  {
    name: {
      en: 'Marketing / Advertising',
      es: 'Marketing / Publicidad',
      ca: 'Màrqueting / Publicitat',
    },
    value: 'advertising',
  },
  {
    name: {
      en: 'Online Platform',
      es: 'Plataforma Online',
      ca: 'Plataforma Online',
    },
    value: 'onlinePlatform',
  },
  {
    name: {
      en: 'Press / Communication',
      es: 'Prensa / Comunicación',
      ca: 'Premsa / Comunicació',
    },
    value: 'press',
  },
  {
    name: {
      en: 'Production Company',
      es: 'Productora',
      ca: 'Productora',
    },
    value: 'producer',
  },
  {
    name: {
      en: 'Promotion / Social Networks',
      es: 'Promoción / Redes sociales',
      ca: 'Promoció / Xarxes socials',
    },
    value: 'promotion',
  },
  {
    name: {
      en: 'Music Promoter',
      es: 'Promotora musical',
      ca: 'Promotora musical',
    },
    value: 'promoter',
  },
  {
    name: {
      en: 'Concert venue',
      es: 'Sala de conciertos',
      ca: 'Sala de concerts',
    },
    value: 'concertHall',
  },
  {
    name: {
      en: 'Record name',
      es: 'Sello discográfico',
      ca: 'Segell discogràfic',
    },
    value: 'record',
  },
  {
    name: {
      en: 'Record shop',
      es: 'Tienda de música',
      ca: 'Tenda de música',
    },
    value: 'musicStore',
  },
  {
    name: {
      en: 'Other...',
      es: 'Otros',
      ca: 'Altres',
    },
    value: 'others',
  },
];
