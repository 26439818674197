
import React from 'react';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';

import { withStyles } from '@material-ui/core/styles';


import ParagraphLineSM from '../Text/ParagraphLineSM';
import ControlledButton from '../Buttons/ControlledButton';
import DropDownMenu from './DropDownMenu';

const ExpandLess = () => <span className="font-icon icon-arrow-up text-white text-sm" />;
const ExpandMore = () => <span className="font-icon icon-arrow-down text-white text-sm font-black" />;

const styles = theme => ({
  wrapperInner: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
});

const getStyle = (selected, listItemStyle) => {
  if (selected) {
    return {
      color: 'white',
      backgroundColor: 'black',
    };
  }

  if (listItemStyle.backgroundColor === 'black') return { color: 'white' };

  return {};
};


class CollapsibleMenu extends React.Component {
  state = {
    dropDown: '',
    filters: {},
  }

  handleClick = (type) => {
    let dropDown = type;
    this.setState((state) => {
      if (dropDown === state.dropDown) dropDown = '';
      return { dropDown };
    });
  };

  onHandleDropDownMenuSelect = (value, filterType) => {
    const { filters } = this.state;
    const { onHandleCollapsibleMenuSelect } = this.props;

    this.setState({
      filters: {
        ...filters,
        [filterType]: value,
      },
    }, () => {
      onHandleCollapsibleMenuSelect(this.state.filters, value);
    });
  }

  onHandleControlledButtonSelect = (value, filterType) => {
    const { filters } = this.state;
    const { onHandleCollapsibleMenuSelect } = this.props;

    if (!filters[filterType]) {
      filters[filterType] = [value];
    } else {
      if (filters[filterType].includes(value)) {
        const index = filters[filterType].findIndex(item => item === value);
        filters[filterType].splice(index, 1);
      } else {
        filters[filterType].push(value);
      }
    }

    this.setState({
      filters,
    }, () => onHandleCollapsibleMenuSelect(filters));
  }

  render() {
    const {
      filter, text, classes,
    } = this.props;
    const { dropDown } = this.state;
    const listItemStyle = {
      borderBottom: dropDown.length > 0 ? 'dashed 1px white' : '',
      backgroundColor: 'black',
      color: 'white',
    };


    return (
      <div className="font-americaMonoRegular font-light w-full">
        <Divider />
        <React.Fragment>

          <ListItem
            style={{
              ...listItemStyle,
              ...getStyle(dropDown === text.en, listItemStyle),
              padding: 18,
            }}
            value={text.en}
            button
            onClick={() => this.handleClick(text.en)}
          >
            <ListItemText
              primary={(
                <span
                  className="text-base sm:text-xs md:text-sm mb-4 sm:pl-0 md:pl-0 pl-2 font-americaMonoBold"
                  style={
                    getStyle(dropDown === text.en, listItemStyle)
                  }
                >
                  {text.en.toUpperCase()}
                </span>
              )}
            />

            {dropDown === text.en ? <ExpandLess /> : <ExpandMore />}

          </ListItem>


          <Collapse
            in={dropDown === text.en}
            timeout="auto"
            unmountOnExit
            classes={{ wrapperInner: classes.wrapperInner }}
          >
            {filter.map(menuItem => (
              <div className="px-7 sm:mr-0 w-1/4 sm:w-full" key={menuItem.filterType}>
                <ParagraphLineSM classes="text-white m-0 pt-7 " text={menuItem.filterHeading} />
                {/* <div className="sm:hidden md:hidden">
                  {menuItem.filterItems.map(item => (
                    <ControlledButton
                      key={item.category}
                      params={item}
                      filterType={menuItem.filterType}
                      onHandleControlledButtonSelect={this.onHandleControlledButtonSelect}
                    />
                  ))}
                </div> */}
                <div className="pt-7 pb-16 font-americaMonoRegular relative">
                  <DropDownMenu
                    menuTitle={menuItem.menuTitle}
                    filterType={menuItem.filterType}
                    menuItems={menuItem.filterItems}
                    onHandleChange={this.onHandleDropDownMenuSelect}
                  />
                </div>
              </div>
            ))}
          </Collapse>


        </React.Fragment>


        <Divider />

      </div>
    );
  }
}

CollapsibleMenu.defaultProps = {
  onHandleCollapsibleMenuSelect: () => {},
};

CollapsibleMenu.propTypes = {
  classes: PropTypes.shape({}),
  listItemStyle: PropTypes.shape({}),
  onHandleCollapsibleMenuSelect: PropTypes.func,
  filter: PropTypes.arrayOf(PropTypes.shape({
    filterHeading: PropTypes.shape({}).isRequired,
    filterType: PropTypes.string.isRequired,
    menuTitle: PropTypes.shape({
      text: PropTypes.shape({
        es: PropTypes.string,
        en: PropTypes.string,
      }),
    }),
    filterItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  })).isRequired,
};

CollapsibleMenu.defaultProps = {
  classes: {},
  listItemStyle: {},
};

export default withStyles(styles)(CollapsibleMenu);
