import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';
import Tag from './Buttons/Tag';
import imageModifier from './helpers/imageModifier';
import translateObj from '../../util/translateObj';

const Video = ({
  image, title, slug, match, tags, onClick, getQueryVariable, type,
}) => (
  <RoutesContext.Consumer>
    {
      ({ language: contextLanguage, enteredFrom }) => {
        const localization = getLocalization(contextLanguage, enteredFrom, match);
        const activeSlug = getQueryVariable(type);
        const activeStyle = slug === activeSlug ? { animation: 'imageFadeOut 0.1s linear forwards' } : {};
        let reducedTags = tags;
        if (tags.length > 2) {
          reducedTags = tags.slice(0, 2);
        }
        return (
          <div className="cursor-pointer sm:w-full md:w-1/2 lg:w-1/3 xl:w-1/4 py-2 px-2 sm:px-0" onClick={() => onClick(slug, type)}>
            <div className="relative">
              <div className="absolute z-10" style={{ left: '1rem', top: '1rem' }}>
                {reducedTags.slice(0, 3).map(tag => (
                  <Tag key={tag} text={`${tag}`} />
                ))}
              </div>
              <img className="block" src={imageModifier(image, '447x260')} alt={title.en} style={activeStyle} />
              <div className="absolute z-10" style={{ left: '1rem', bottom: '1rem' }}>
                <span className="font-icon icon-player text-white text-5xl" />
              </div>
            </div>
            <p className="font-americaBlack text-20">{translateObj(title, localization.language)}</p>
          </div>
        );
      }
    }
  </RoutesContext.Consumer>
);

Video.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.shape({}),
  slug: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
  onClick: PropTypes.func.isRequired,
  getQueryVariable: PropTypes.func.isRequired,
  match: PropTypes.shape({}).isRequired,
  type: PropTypes.string.isRequired,
};

Video.defaultProps = {
  title: {},
  tags: [],
};

export default withRouter(Video);
