import React, { Component, Fragment } from 'react';
import Swiper from 'react-id-swiper';
import { PropTypes } from 'prop-types';
import getRadioShows from '../../api/graphql/queries/getRadioShows';
import withAllContexts from '../../context/withAllContexts';
import PSResponsiveUtility from './LineUp/PSResponsiveUtility';
import ComponentIterator from '../../containers/ComponentIterator';
import SliderBannerRadio from './Sliders/SliderBannerRadio';

const sliderSettings = {
  slidesPerView: 1,
  spaceBetween: 30,
  loop: true,
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
};

class CarouselRadio extends Component {
  responsiveUtility = React.createRef();

  state={
    highlights: null,
  }

  // componentWillMount = () => {
  //   const { radioShowsContext } = this.props;

  //   if (radioShowsContext.radioShowsHighlightsData) {
  //     this.processRadioShows(radioShowsContext.radioShowsHighlightsData);
  //     radioShowsContext.clear('radioShowsHighlightsData');
  //   }
  // }

  componentDidMount() {
    getRadioShows({ highlight: true, limit: 5 }).then((radioPrograms) => {
      this.processRadioShows(radioPrograms);
    });
  }

  processRadioShows = (radioPrograms) => {
    this.setState({ highlights: radioPrograms });
  }

  getWidth = () => {
    // actions when window is resized
    const deviceWidth = this.responsiveUtility.current.deviceWidth(true);
    this.setState({ deviceWidth });
  };

  render() {
    const { highlights, deviceWidth } = this.state;

    if (deviceWidth > 639) {
      sliderSettings.pagination.el = '.swiper-pagination';
      sliderSettings.navigation.nextEl = '.swiper-button-next';
      sliderSettings.navigation.prevEl = '.swiper-button-prev';
    } else {
      sliderSettings.pagination.el = '.swiper-pagination-mobile';
      sliderSettings.navigation.nextEl = '';
      sliderSettings.navigation.prevEl = '';
    }

    return (
      <Fragment>
        <PSResponsiveUtility ref={this.responsiveUtility} deviceWidthCallback={this.getWidth} />
        <div className="h-620 sm:h-carouselSM  md:h-carouselMD w-full full-width">
          {
            highlights && (
              <Swiper {...sliderSettings} className="relative">
                {
                  highlights.map(highlight => (
                    <div key={`id${highlight.id}`}>
                      <SliderBannerRadio {...highlight} />
                    </div>
                  ))
                }
              </Swiper>
            )
          }
        </div>
      </Fragment>
    );
  }
}

CarouselRadio.propTypes = {
  classNames: PropTypes.string,
};

CarouselRadio.defaultProps = {
  classNames: '',
};

export default CarouselRadio;
