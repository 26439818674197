import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import imageModifier from '../../helpers/imageModifier';
import translateObj from '../../../../util/translateObj';
import ProVoucherOnDemandModal from './ProVoucherOnDemandModal';

const ProOnDemandModalIsLogged = ({ image, language, ...props }) => {
  const imageurl = `url(${imageModifier(translateObj(image, language), '768x510')})`;

  return (
    <>
      <ProVoucherOnDemandModal {...props} imageUrl={imageurl} />
    </>
  );
};

ProOnDemandModalIsLogged.propTypes = {
  language: PropTypes.string,
  userEmail: PropTypes.string,
  loadTokenForLS: PropTypes.func.isRequired,
  handleOnDemandScreenStatus: PropTypes.func.isRequired,
  translations: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
  postVideoUrl: PropTypes.string.isRequired,
  postVideoRole: PropTypes.string.isRequired,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  image: PropTypes.shape(),
  closeModal: PropTypes.func.isRequired,
};

ProOnDemandModalIsLogged.defaultProps = {
  language: 'en',
  userEmail: '',
  image: null,
};

export default withRouter(ProOnDemandModalIsLogged);
