import React from 'react';
import ArtistListItem from './ArtistListItem';

const ArtistsList = ({ artists }) => (
  <div className="flex m-auto justify-center flex-wrap">
    {artists && artists.map((artist, i) => <ArtistListItem {...artist} key={`${artist.name}_${i}`} />)}
  </div>
);

export default ArtistsList;
