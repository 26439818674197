import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import AssetImage from './AssetImage';
import AssetDescription from './AssetDescription';
import getMamObjectsBySlugNameQuery from '../../api/graphql/queries/getMamObjectsBySlugname';
import imageModifier from './helpers/imageModifier';

class ShareAsset extends Component {
  state = {
    mamObject: {},
    isLoading: true,
  }

  componentDidMount() {
    const { match: { params } } = this.props;
    if (params.assetSlugName) {
      getMamObjectsBySlugNameQuery([params.assetSlugName])
        .then((res) => {
          if (res.length) {
            this.setState({
              mamObject: res[0],
              isLoading: false,
            });
          }
        })
        .catch(err => console.log(err));
    }
  }

  render() {
    const { mamObject, isLoading } = this.state;
    return (
      <>
        {!isLoading
          ? (
            <div className="w-full relative full-width sm:px-4 min-h-screen-70 flex flex-col justify-center">
              <AssetImage {...mamObject} />
              <div className="sm:ml-4 md:ml-4 relative">
                <AssetDescription black={false} {...mamObject} />
              </div>
            </div>
          )
          : (
            <div className="sm:w-full xl:w-1/2 lg:w-1/2 mx-auto text-center relative my-auto">
              <img src={imageModifier('https://assets.primaverasound.com/web/assets/default_image_placeholder_light_grey.png', '500x500', true)} className="colorImg block mx-auto" alt="default_image_placeholder_light_grey.png" />
            </div>
          )
      }
      </>
    );
  }
}


ShareAsset.propTypes = {
  match: PropTypes.shape({}).isRequired,
};

export default withRouter(ShareAsset);
