import React from 'react';
import { PropTypes } from 'prop-types';
import { useToasts } from 'react-toast-notifications';
import {
  updatePendingPrivateCall, addParticipantToCall, changeVideoRoom,
} from '../utils/helpers';
import { useChat } from '../context/chatContext';
import { usePrivatePlusZoneContext } from '../context/privatePlusOneZoneContext';

const InviteCallButton = ({
  room, inviter,
}) => {
  const { removeToast } = useToasts();
  const { userData, activeVideoCall } = useChat();
  const { openPrivateChat } = usePrivatePlusZoneContext();

  const handleDeclineInvitation = async () => {
    if (room) await updatePendingPrivateCall({ room, userId: userData.userId, type: 'remove' });
    removeToast(room);
  };

  const handleAcceptInvitation = async () => {
    if (room) {
      const roomNickname = `#userInfo.displayName="${userData.nickname}"`;
      const url = `https://plusonezone.primaverasound.com/${room}${roomNickname}`;

      if (activeVideoCall) {
        changeVideoRoom({ previousRoom: activeVideoCall.room, newRoom: room, userId: userData.userId });
        openPrivateChat(url);
        removeToast(room);
      } else {
        await updatePendingPrivateCall({ room, userId: userData.userId, type: 'remove' });
        await addParticipantToCall({ room, userId: userData.userId });
        openPrivateChat(url);
        removeToast(room);
      }
    }
  };

  return (
    <div className="flex flex-wrap justify-between text-sm normal-case w-full">
      <div className="font-americaRegular">
        {userData.nickname !== inviter
          ? (
            <>
              <span className="font-americaBold">{inviter}</span>
              {' '}
  started a call
            </>
          ) : 'Join your room'
      }
      </div>
      <div>
        <button className="text-base text-white hover:text-psworld-grey trans" onClick={handleAcceptInvitation} type="button">
          <span className="font-icon1 icon-verified" />
        </button>
        <button className="text-base pl-4 text-white hover:text-psworld-grey trans" onClick={handleDeclineInvitation} type="button">
          <span className="font-icon1 icon-deny" />
        </button>
      </div>
    </div>
  );
};

export default InviteCallButton;
