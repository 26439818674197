import React from 'react';
import PropTypes from 'prop-types';
import stickybits from 'stickybits';
import ResizeObserver from 'resize-observer-polyfill';
import { TweenMax } from 'gsap';

import LayoutColumn from './LayoutColumn';
import LayoutRow from './LayoutRow';

class MainLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dayId: props.day.replace(new RegExp('/', 'g'), '-'),
    };
  }

  componentDidMount() {
    this.initSticky();
    window.addEventListener('scroll', this.handleScroll);
  }

  componentDidUpdate(prevProps) {
    const { layout } = this.props;
    if (prevProps.layout !== layout) {
      this.initSticky();
    }
  }

  componentWillUnmount() {
    this.stopSticky();
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const header = document.querySelector('#header');
    if (!header) return;
    const { top, height } = header.getBoundingClientRect();
    const t = top + height;
    const el = document.querySelector('.lineUpFilterWrapper');
    const {
      dayId,
    } = this.state;

    TweenMax.set(`.header-${dayId}`, {
      top: t > 0 ? t + el.offsetHeight : el.offsetHeight,
    });
  }

  initSticky() {
    this.stopSticky();

    const {
      dayId,
    } = this.state;

    const el = document.querySelector('.lineUpFilterWrapper');

    this.headerStickyInstance = stickybits(`.header-${dayId}`, {
      stickyBitStickyOffset: el.offsetHeight,
    });

    this.lineUpFilterWrapperObserver = new ResizeObserver((entries) => {
      if (entries.length > 0) {
        if (this.headerStickyInstance) {
          this.forceStickyUpdate(entries[0].target.offsetHeight);
        }
      }
    });

    this.lineUpFilterWrapperObserver.observe(el);
  }

  forceStickyUpdate(offsetHeight) {
    const {
      dayId,
    } = this.state;
    document.querySelector(`.header-${dayId}`).style.top = `${offsetHeight - 1}px`;
    this.headerStickyInstance.update({
      stickyBitStickyOffset: offsetHeight - 1,
    });
  }

  stopSticky() {
    if (this.lineupFilterStickyInstance) {
      this.lineupFilterStickyInstance.cleanup();
    }
    if (this.lineUpFilterWrapperObserver) {
      this.lineUpFilterWrapperObserver.disconnect();
    }
  }

  render() {
    const { layout } = this.props;
    const { dayId } = this.state;
    return layout === 'columns' ? <LayoutColumn dayId={dayId} {...this.props} /> : <LayoutRow dayId={dayId} {...this.props} />;
  }
}

MainLayout.propTypes = {
  layout: PropTypes.string.isRequired,
  day: PropTypes.string.isRequired,
};
export default MainLayout;
