/* eslint-disable react/forbid-prop-types */

import React from 'react';
import PropTypes from 'prop-types';
import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
} from '@stripe/react-stripe-js';
import PsWorldStripeCardForm from './PsWorldStripeCardForm';

const stripePromise = loadStripe('pk_test_EO9crXBrcexhiFUJBMsTeii4');

const PsWorldStripeCardFormWrapper = props => (
  <Elements stripe={stripePromise}>
    <PsWorldStripeCardForm {...props} />
  </Elements>
);

PsWorldStripeCardFormWrapper.propTypes = {
  customerInfo: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  clientSecret: PropTypes.string,
  publicKey: PropTypes.string,
};

PsWorldStripeCardFormWrapper.defaultProps = {
  clientSecret: null,
  publicKey: null,
};
export default PsWorldStripeCardFormWrapper;
