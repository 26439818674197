/* eslint-disable react/forbid-prop-types */

import React from 'react';
import PropTypes from 'prop-types';
import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
} from '@stripe/react-stripe-js';
import PayWithStoredCard from './PayWithStoredCard';

const stripePromise = loadStripe('pk_test_EO9crXBrcexhiFUJBMsTeii4');

const PsWorldStripeCardFormWrapper = (props) => {
  console.log('props', props);
  return (
    <Elements stripe={stripePromise}>
      <PayWithStoredCard {...props} />
    </Elements>
  );
};

// PsWorldStripeCardFormWrapper.propTypes = {
//   customerInfo: PropTypes.object.isRequired,
//   product: PropTypes.object.isRequired,
// };

export default PsWorldStripeCardFormWrapper;
