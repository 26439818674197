import gql from 'graphql-tag';

import { clientWeb } from '../graphqlAPI';

const GET_PRO_MUSIC_MEETS_USER_MAILTO = gql`
  query Get($userId: String!) {
    getProMusicMeetsUserMailto(userId: $userId) {
      mailto
    }
  }
`;

const getProMusicMeetsUser = async (userId) => {
  try {
    const result = await clientWeb
      .query({
        query: GET_PRO_MUSIC_MEETS_USER_MAILTO,
        variables: { userId },
        fetchPolicy: 'cache-first',
      })
      .then((queryResult) => queryResult)
      .catch((queryError) => {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error: ${queryError}`);
        return null;
      });

    if (result.data && result.data.getProMusicMeetsUserMailto) {
      const { data: { getProMusicMeetsUserMailto: getMailto } } = result;
      return getMailto;
    }

    return [];
  } catch (error) {
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: getTicketCode Error: ${error}`);
    return null;
  }
};

export default getProMusicMeetsUser;
