import React, { PureComponent } from 'react';
import { PropTypes } from 'prop-types';

import Navigator from './Navigator';
import ComponentIterator from '../../containers/ComponentIterator';

export default class Media extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      imageUrl, location, componentsLeft, componentsRight, navigatorPosition,
    } = this.props;
    return (
      <div className="relative w-full full-width">
        <div
          className="h-128"
          style={{
            background: `url(${imageUrl})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        >
          {/* <Navigator /> */}
          <div className="white flex items-end h-full ">
            <div className="flex sm:flex-col justify-around w-full sm:pr-16 h-1/2 items-end p-16 sm:pl-8 sm:pt-4 md:px-4">
              <div className="w-1/3 sm:w-full h-full font-black flex flex-col justify-between mr-12 sm:mr-0 sm:text-20 md:text-24">
                <div>
                  <ComponentIterator components={componentsLeft} />
                </div>
              </div>

              <div className="sm:w-full xl:w-48 lg:w-48 flex flex-col">
                <div>
                  <ComponentIterator components={componentsRight} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Media.propTypes = {};
