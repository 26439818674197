import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Panel from '@primaveralabs/ui-panel';
import 'Primavera/ui-themes/dist/primavera/index.css';
import 'Primavera/ui-panel/dist/primavera.css';
import 'Primavera/ui-banner/dist/primavera.css';
import 'Primavera/ui-scoreboard/dist/primavera.css';
import { useAuth } from '@primaveralabs/auth';
import LoyaltyPointsScore from './LoyaltyPointsScore';
import FavoritesBoard from './FavoritesBoard';
import RewardsBoard from './RewardsBoard';
import NotificationsBoard from './NotificationsBoard';
import RadioPlayer from '../Radio/RadioPlayer';
import getUserData from '../../../api/graphql/queries/getUserData';
import LoadIcon from '../LoadIcon';
import translateObj from '../../../util/translateObj';
import { useRoutes } from '../../../context/routesContext';
import commonTranslation from './translations/common';
import translation from './translations/UserPanel';
import AdBanner from './AdBanner';
import PreferencesBoards from './PreferencesBoards';
import SpotifyLogin from '../Login/SpotifyLogin/SpotifyLogin';
import getUserPanelGrid from './getUserPanelGrid';
import AchievementsBoard from './AchievementsBoard';

const UserPanelPage = ({
  loyaltyTitle,
  favouritesTitle,
  notificationsTitle,
  scoreTitle,
  genresTitle,
  festivalsTitle,
  radioTitle,
  publicidad,
  interests,
  festivals,
  favouritesURL,
  noGenres,
  noFestivals,
  viewAll,
  noNotifications,
  viewMore,
  noPrizes,
  redeemed,
  noFavourites,
  spotifyTitle,
  spotifyDescription,
  spotifyButtonLabel,
  achievementsTitle,
  achievedOn,
  pointsLabel,
  noAchievements,
  achievementsLimit,
}) => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({});
  const { language } = useRoutes();

  const Grid = getUserPanelGrid(user.isSpotifyLinked);

  const loadUserData = () => {
    setLoading(true);
    getUserData()
      .then((res) => {
        if (res) {
          setUserData(res);
        }
      })
      .catch((err) => console.log(err));
    setLoading(false);
  };

  useEffect(() => {
    loadUserData();
  }, []);

  return (
    <div className={`bg-panel-gray ${loading ? 'h-full' : ''}`}>
      {loading ? (
        <div className="flex items-center justify-center h-full">
          <LoadIcon />
        </div>
      ) : (
        <Grid>
          <LoyaltyPointsScore
            loyaltyPoints={userData?.loyalty?.loyaltyPoints}
            loading={loading}
            title={scoreTitle}
          />
          <FavoritesBoard
            favouriteArtists={userData?.favourites?.favouriteArtists}
            title={favouritesTitle}
            favouritesURL={favouritesURL}
            viewAll={viewAll}
            noFavourites={noFavourites}
          />
          <RewardsBoard
            loyalty={userData?.loyalty}
            title={loyaltyTitle}
            viewMore={viewMore}
            redeemedPrize={redeemed}
            noPrizes={noPrizes}
          />
          <AchievementsBoard
            loyalty={userData?.loyalty}
            title={achievementsTitle}
            achievedOn={achievedOn}
            pointsLabel={pointsLabel}
            noAchievements={noAchievements}
            limit={achievementsLimit}
            viewAll={viewAll}
          />
          <PreferencesBoards
            genresTitle={genresTitle}
            interests={interests}
            noGenres={noGenres}
            festivalsTitle={festivalsTitle}
            festivals={festivals}
            noFestivals={noFestivals}
          />
          {!user.isSpotifyLinked && (
          <div style={{
            gridArea: 'spotify',
          }}
          >
            <Panel
              display="center"
              title={translateObj(spotifyTitle, language)}
            >
              {spotifyDescription && (
              <p className="font-americaRegular text-14 text-white pb-4">
                {translateObj(spotifyDescription, language)}
              </p>
              )}
              <SpotifyLogin
                isLinkAccountButton
                revamp
                buttonText={translateObj(spotifyButtonLabel, language)}
                className="rounded-10 bg-inherit bg-spotify uppercase text-white text-base xs:text-14 sm:text-14 font-americaMonoBold sm:static sm:mx-0 sm:w-full cursor-pointer text-center md:w-full px-12 lg:px-16 xl:px-16 py-6 flex items-center justify-center"
              />
            </Panel>
          </div>
          )}
          <div
            style={{
              gridArea: 'publi',
              gridColumns: '1 / span 2',
              minHeight: 170,
            }}
          >
            <AdBanner publicidad={publicidad} />
          </div>
          <div style={{ gridArea: 'radio' }}>
            <Panel
              title={translateObj(radioTitle, language)}
              ctaLabel={translateObj(commonTranslation.goNow, language)}
              ctaLink="https://www.primaverasound.com/radio"
              display="center"
            >
              <RadioPlayer userPanel />
            </Panel>
          </div>
          <NotificationsBoard
            title={notificationsTitle}
            noNotifications={noNotifications}
            viewAll={viewAll}
          />
        </Grid>
      )}
    </div>
  );
};

UserPanelPage.propTypes = {
  loyaltyTitle: PropTypes.shape({}),
  favouritesTitle: PropTypes.shape({}),
  notificationsTitle: PropTypes.shape({}),
  scoreTitle: PropTypes.shape({}),
  genresTitle: PropTypes.shape({}),
  festivalsTitle: PropTypes.shape({}),
  radioTitle: PropTypes.shape({}),
  publicidad: PropTypes.shape({
    image: PropTypes.shape({}),
    link: PropTypes.string,
  }),
  interests: PropTypes.shape({
    genres: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  festivals: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  favouritesURL: PropTypes.string,
  noGenres: PropTypes.shape({}),
  noFestivals: PropTypes.shape({}),
  viewAll: PropTypes.shape({}),
  noNotifications: PropTypes.shape({}),
  viewMore: PropTypes.shape({}),
  noPrizes: PropTypes.shape({}),
  redeemed: PropTypes.shape({}),
  noFavourites: PropTypes.shape({}),
  spotifyTitle: PropTypes.shape({}),
  spotifyDescription: PropTypes.shape({}),
  spotifyButtonLabel: PropTypes.shape({}),
  achievementsTitle: PropTypes.shape({}),
  noAchievements: PropTypes.shape({}),
  achievedOn: PropTypes.shape({}),
  pointsLabel: PropTypes.shape({}),
  achievementsLimit: PropTypes.number,
};

UserPanelPage.defaultProps = {
  loyaltyTitle: translation.loyaltyTitle,
  favouritesTitle: translation.favouritesTitle,
  notificationsTitle: translation.notificationsTitle,
  scoreTitle: translation.scoreTitle,
  festivalsTitle: translation.festivalsTitle,
  genresTitle: translation.genresTitle,
  radioTitle: translation.radioTitle,
  publicidad: {
    image: { en: '' },
    link: '',
  },
  favouritesURL: 'primavera-sound-barcelona-2023-programme',
  noGenres: commonTranslation.noGenres,
  noFestivals: commonTranslation.noFestivals,
  viewAll: commonTranslation.viewAll,
  noNotifications: commonTranslation.noNotifications,
  viewMore: commonTranslation.viewMore,
  noPrizes: commonTranslation.noPrizes,
  redeemed: commonTranslation.redeemed,
  noFavourites: commonTranslation.noFavourites,
  spotifyTitle: commonTranslation.linkSpotify,
  spotifyDescription: translation.linkSpotifyDescription,
  spotifyButtonLabel: commonTranslation.linkSpotify,
  achievementsTitle: translation.myAchievements,
  noAchievements: commonTranslation.noAchievements,
  achievedOn: commonTranslation.achieved,
  pointsLabel: commonTranslation.points,
  achievementsLimit: 5,
};

export default UserPanelPage;
