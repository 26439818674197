import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import RadioHeader2 from './RadioHeader2';
import RadioChannelImage from './RadioChannelImage';
import FavoritesShare from '../FavoritesShare';
import withRadioPlayerContext from '../../../context/withRadioPlayerContext';

const isFavoritesActive = process.env.isFavoritesActive;

class RadioChannelCard extends PureComponent {
  render() {
    const { activeChannel } = this.props;
    return (
      <>
        <RadioHeader2 theme="radio" channel={activeChannel.channel} title={activeChannel.title_multilang} icon1Url="font-icon icon-clock text-24" icon2Url="http://static.fragment.agency/ps/radio-arrow-down.svg" />
        <RadioChannelImage {...activeChannel} />
        { isFavoritesActive && <FavoritesShare black /> }
      </>
    );
  }
}

RadioChannelCard.propTypes = {
  activeChannel: PropTypes.shape({}),
};

export default withRadioPlayerContext(RadioChannelCard);
