/* eslint-disable brace-style */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-array-index-key */

import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import getSection from '../../api/graphql/queries/getSection';
import Media from './Media';

let serverRendered = true;

class PostCover extends React.Component {
  state = {
    section: {},
    isLoading: true,
  };

  componentDidMount = () => {
    const { sectionSlugName } = this.props;
    /*
    After mount and before we fetch the highlights data for the CSR and update the state with the fetched data, we set serverRendered = false to clear the PostContext
    data immediately after initial SSR, to make sure the CSR will use the new data from the state. This also guarantees that all subsequent CSRs will use
    the data from the state only.
    */
    serverRendered = false;
    this.fetchSection(sectionSlugName);
  };

  // For performance, re-renders are strictly limited to state changes only.
  shouldComponentUpdate = (nextProps, nextState) => {
    if (nextState !== this.state) {
      return true;
    }
    return false;
  };

  // ----------------------------------------------------------------------------------------------------------------------------------------

  fetchSection = (sectionSlugName) => {
    getSection(sectionSlugName).then((section) => {
      this.setState({ section, isLoading: false });
    });
  };

  // ----------------------------------------------------------------------------------------------------------------------------------------


  render() {
    const { section: { postDescription, slugName }, isLoading } = this.state;
    const { cta } = this.props;
    return (
      <>
        {!isLoading
          ? (
            <Media
              text={postDescription.title}
              image={postDescription.image}
              slug={slugName}
              cta={cta}
            />
          )
          : <div />
      }
      </>
    );
  }
}

PostCover.propTypes = {
  sectionSlugName: PropTypes.string.isRequired,
  cta: PropTypes.shape({}),
};

PostCover.defaultProps = {
  cta: null,
};

export default withRouter(PostCover);
