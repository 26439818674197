import gql from 'graphql-tag';

import { clientWeb, persistor } from '../graphqlAPI';

const GET_TICKETS_BY_SLUGNAME = gql`
  query getTicketsByTicketSlugs($ticketSlugNames: [String!]) {
    getTicketsByTicketSlugs(ticketSlugNames: $ticketSlugNames) {
      _id
      ticketSlugName
      ticketName
      ticketReadableName {
        es
        en
        ca
        pt
      }
      description {
        es
        en
        ca
        pt
      }
      subtitle {
        es
        en
        ca
        pt
      }
      status
      tags
      price
      sellers {
        sellerTicketUrl
        sellerName
        active
        priority
      }
      purchaseUrl
      show
      showFee
      currency
      createdAt
      updatedAt
    }
  }
`;

const getTicketsBySlugName = async (slugs) => {
  try {
    const result = await clientWeb
      .query({
        query: GET_TICKETS_BY_SLUGNAME,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
        variables: { ticketSlugNames: slugs },
        fetchResults: true,
        pollInterva: 300000,
      })
      .then((queryResult) => queryResult)
      .catch((queryError) => {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error: ${queryError}`);
        return null;
      });
    const {
      data: { getTicketsByTicketSlugs },
    } = result;

    if (getTicketsByTicketSlugs) {
      return getTicketsByTicketSlugs;
    }
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: GraphQL Query Error: getTicketsByTicketSlugs`);
    return null;
  } catch (error) {
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: getTickets Error: ${error}`);
    return null;
  }
};

export default getTicketsBySlugName;
