import React from 'react';
import { PropTypes } from 'prop-types';

import { withRouter } from 'react-router-dom';
import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';

const SearchNumberOfResultsFound = ({
  match,
  numberOfResults,
  searchQuery,
}) => (
  <RoutesContext.Consumer>
    {
      ({ language: contextLanguage, enteredFrom }) => {
        const localization = getLocalization(contextLanguage, enteredFrom, match);
        const textPart1 = {
          en: '',
          ca: "S'han trobat",
          es: 'Se han encontrado',
          pt: '',
        };
        const textPart2 = {
          en: 'results found that match your search',
          ca: 'resultats que coincideixen amb la cerca',
          es: 'resultados que coinciden con la búsqueda',
          pt: 'resultados encontrados que correspondem à sua pesquisa',
        };

        return (
          <div className="pt-24 pb-12 w-full border-black border-b-2 border-solid">
            <span className="font-tiemposRegular text-sm text-left">
              {textPart1[localization.language]}
              {' '}
              <span className="font-bold italic">{numberOfResults}</span>
              {' '}
              {textPart2[localization.language]}
              {' '}
              <span className="font-bold italic">
                {`"${searchQuery}"`}
              </span>
            </span>
          </div>
        );
      }
    }
  </RoutesContext.Consumer>
);

SearchNumberOfResultsFound.propTypes = {
  match: PropTypes.shape().isRequired,
  numberOfResults: PropTypes.number.isRequired,
  searchQuery: PropTypes.string.isRequired,
};

export default withRouter(SearchNumberOfResultsFound);
