import React from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import parse from 'html-react-parser';
import translateObj from '../../../util/translateObj';

import { useRoutes } from '../../../context/routesContext';

const BannerModule = ({
  url, title, text, match, hideUrl,
}) => {
  const { language } = useRoutes(match);
  const bannerText = translateObj(text, language);

  return (
    <div className="w-full mb-4">
      <div className="bg-psworld-zone-yellow hover:bg-psworld-zone-yellow-light p-2 text-psworld-text-grey md:h-auto sm:h-auto h-psworld-box trans">
        <a href={url || '#'} className="text-psworld-text-grey flex flex-col h-full" target="_blank" rel="noopener noreferrer">
          <h2 className="uppercase font-americaBold my-2 flex-1">{title}</h2>
          <div className="flex-2 flex flex-col justify-center pb-4">
            <div className="text-lg leading-regular">
              {parse(bannerText)}
            </div>
            {!hideUrl && <p className="font-americaBold">{url}</p>
}
          </div>
        </a>
      </div>
    </div>
  );
};
BannerModule.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.shape().isRequired,
  match: PropTypes.shape().isRequired,
  hideUrl: PropTypes.bool,
};
BannerModule.defaultProps = {
  hideUrl: false,
};


export default withRouter(BannerModule);
