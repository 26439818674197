/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';
import translateObj from '../../../util/translateObj';

const marginBottomStyle = {
  marginBottom: '-7px',
};
const marginBottomStyleMobile = {
  marginBottom: '7px',
};

const PretitleLineH2 = ({
  text, align, color, margin, match, slider, layout
}) => (
  <RoutesContext.Consumer>
    {
        ({ language: contextLanguage, enteredFrom }) => {
          const localization = getLocalization(contextLanguage, enteredFrom, match);
          return (
            <>
              <h2 tabIndex="0" style={marginBottomStyle} className={`${align} ${margin} ${slider && 'mt-6 leading-regular'} sm:hidden text-${color} leading-regular uppercase ${layout === "tickets" ? "font-americaMonoRegular text-base tracking-px" : "font-americaMonoMedium text-sm"}`}>
                {translateObj(text, localization.language)}
              </h2>
              <h2 tabIndex="0" style={marginBottomStyleMobile} className={`${align} ${margin} ${slider && 'mt-6 leading-regular'} hidden sm:block text-${color} leading-regular max-w-sm uppercase ${layout === "tickets" ? "font-americaMonoRegular text-sm tracking-px" : "font-americaMonoMedium text-xs"}`}>
                {translateObj(text, localization.language)}
              </h2>
            </>
          );
        }
      }
  </RoutesContext.Consumer>
);

export default withRouter(PretitleLineH2);

PretitleLineH2.propTypes = {
  color: PropTypes.string,
  align: PropTypes.string,
  margin: PropTypes.string,
  text: PropTypes.shape({
    en: PropTypes.string.isRequired,
    es: PropTypes.string.isRequired,
  }).isRequired,
  match: PropTypes.shape().isRequired,
  slider: PropTypes.bool,
  layout: PropTypes.string,
};

PretitleLineH2.defaultProps = {
  margin: '',
  color: 'white',
  align: 'align-left',
  slider: false,
  layout: null
};
