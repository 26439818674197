import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';

import moment from 'moment';
import DatePicker from 'react-datepicker';

import InfoPopover from '../Form/InfoPopover';
import translateObj from '../../../util/translateObj';


const LoginDatePicker = ({
  existingDate, handleDate, title, name, language, selectDateMessage, bolder, style, infoText, required,
}) => {
  const [startDate, setStartDate] = useState(new Date());

  useEffect(() => {
    if (existingDate) { setStartDate(new Date(moment(existingDate, 'MMM Do YYYY', true).format())); }
  }, [existingDate]);

  const handleChange = (date) => {
    setStartDate(date);
    handleDate(name, moment(date).format('MMM Do YYYY'));
  };

  return (
    <div className={`${bolder ? '' : (style || 'py-4 ')}  mb-4 w-full lg:w-1/2 px-3`}>

      <div>
        <span className={`${bolder ? 'font-americaMonoBold' : 'font-americaMonoRegular text-xs'}  uppercase text-black flex`}>
          {translateObj(title, language)}
          {required && <span className="text-12 align-top text-red-light">*</span>}
          {infoText && <div className="ml-4"><InfoPopover text={infoText} /></div>}
        </span>
      </div>
      <DatePicker
        selected={Date.parse(startDate)}
        placeholderText={translateObj(selectDateMessage, language)}
        onChange={handleChange}
        showPopperArrow={false}
        dateFormat="MMMM d, yyyy"
        className={`px-6  outline-none rounded-full mt-2 w-full mr-4 font-americaMonoBold ${bolder ? 'py-4' : 'text-sm py-0.9'} text-center mt-4 rounded-full border-black text-black border-1 bg-inherit cursor-pointer`}
        showYearDropdown
        dateFormatCalendar="MMMM"
        yearDropdownItemNumber={15}
        scrollableYearDropdown
        dropdownMode="select"
        formatWeekDay={nameOfDay => ''}
        maxDate={new Date()}
        // withPortal
        fixedHeight
      />
    </div>
  );
};


LoginDatePicker.propTypes = {
  title: PropTypes.shape({}).isRequired,
  name: PropTypes.string.isRequired,
  existingDate: PropTypes.string,
  language: PropTypes.string,
  selectDateMessage: PropTypes.shape(),
  handleDate: PropTypes.func.isRequired,
  bolder: PropTypes.bool,
  style: PropTypes.string,
  infoText: PropTypes.string,
  required: PropTypes.bool,
};

LoginDatePicker.defaultProps = {
  existingDate: '',
  language: 'en',
  selectDateMessage: {},
  bolder: false,
  style: '',
  infoText: '',
  required: false,
};

export default LoginDatePicker;
