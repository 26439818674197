import gql from 'graphql-tag';

import { clientWeb } from '../graphqlAPI';

const ASSIGN_TICKET_CODE = gql`
  mutation Post($scope: String!) {
    assignTicketCode(scope: $scope) {
      code
      message
    } 
  }
`;

const assignTicketCode = async (scope) => {
  try {
    const result = await clientWeb.mutate({
      mutation: ASSIGN_TICKET_CODE,
      variables: {
        scope,
      },
      fetchPolicy: 'no-cache',
    })
      .then(queryResult => queryResult)
      .catch((queryError) => {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error: ${queryError}`);
        return null;
      });

    const { data: { assignTicketCode: assignTicketCodeData } } = result;

    return assignTicketCodeData;
  } catch (error) {
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: assignTicketCode Error: ${error}`);
    return null;
  }
};

export default assignTicketCode;
