import React from 'react';
import { PropTypes } from 'prop-types';

import { withRouter } from 'react-router-dom';
import { Radio } from '@material-ui/core';
import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';
import translateObj from '../../util/translateObj';

const RadioItemType = ({
  match, title, category, paddingLeft, paddingY, className,
}) => (
  <RoutesContext.Consumer>
    {({ language: contextLanguage, enteredFrom }) => {
      const localization = getLocalization(contextLanguage, enteredFrom, match);

      return (
        <div className={`flex ${paddingY ? 'pt-12 pb-16' : ''} ${className} `}>
          {paddingLeft && <div className="sm:hidden md:hidden xl:w-1/4 lg:w-1/4 invisible">X</div>}
          <span className={`text-${category} font-americaMonoRegular text-12 uppercase`}>{translateObj(title, localization.language)}</span>
        </div>
      );
    }}
  </RoutesContext.Consumer>
);

RadioItemType.propTypes = {
  title: PropTypes.shape().isRequired,
  match: PropTypes.shape().isRequired,
  category: PropTypes.string,
  paddingLeft: PropTypes.bool,
  paddingY: PropTypes.bool,
  className: PropTypes.string,
};

RadioItemType.defaultProps = {
  paddingLeft: false,
  paddingY: false,
  className: '',
  category: 'radio',
};

export default withRouter(RadioItemType);
