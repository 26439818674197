import React from 'react';

const PrivacyPolicy = ({ lang }) => {
  if (lang === 'es') {
    return (
      <div>
        <h2>POLÍTICA DE PRIVACIDAD</h2>
        <br />
        <strong>1. ¿Quién es el responsable del tratamiento?</strong>
        <br />
              El responsable del tratamiento es la empresa española PRIMAVERA SOUND S.L., con domicilio
              social en C/ Roc Boronat, 142, 3º planta, 08018, Barcelona (España) ("
        <strong>Primavera Sound</strong>
              ").
        <br />
        <br />
        <strong>2. ¿Quién es el delegado de protección de datos?</strong>
        <br />
            El delegado de protección de datos actúa como enlace entre Primavera Sound y tú, para asegurar el
            cumplimiento por parte de Primavera Sound de la legislación en materia de protección de datos y
            garantizar tus derechos en virtud de dicha legislación. Puedes ponerte en contacto con el delegado de
            protección de datos de Primavera Sound en
        {' '}
        <a target="blank" rel="noopener noreferrer" href="mailto:privacy@primaverasound.com">privacy@primaverasound.com</a>
.
        <br />
        <br />
        <strong>3. ¿Para qué fin trata Primavera Sound tus datos personales?</strong>
        <br />
            Durante la celebración de la edición 2022 del festival Primavera Sound (el "
        <strong>Festival</strong>
            "), Primavera
            Sound pondrá a disposición de los asistentes una serie de QRs, que estarán ubicados en diferentes
            puntos del Festival. Tras escanear el QR podrás convertirte en el ganador de, entre otros, una entrada
            para la próxima edición del Festival (en concreto, una entrada para la edición 2023 del Primavera
            Sound que se celebrará en Barcelona y Madrid) o de una camiseta (el "
        <strong>Premio</strong>
            ").
            Para obtener efectivamente el Premio, deberás registrarte y rellenar el formulario al que te redirigirá el
            QR ganador. De este modo, Primavera Sound tratará los datos personales que nos facilites, en el
            caso de que hayas resultado ganador, para entregarte el Premio.
        <br />
        <br />
        <strong>4. ¿Cuál es la base legal para tratar tus datos personales?</strong>
        <br />
            La base legal de Primavera Sound para el tratamiento de tus datos personales es el consentimiento
            que nos has prestado al registrarte y rellenar el formulario correspondiente, para obtener tu Premio.
        <br />
        <br />
        <strong>5. ¿Cuánto tiempo conservará Primavera Sound tus datos personales?</strong>
        <br />
            Primavera Sound conservará tus datos personales durante el tiempo necesario para gestionar la
            entrega   del   Premio.   En   todo   caso,   Primavera   Sound   podrá   conservar   tus   datos   personales
            debidamente bloqueados hasta la finalización de los plazos de prescripción de las responsabilidades
            que pudieran derivarse y durante el plazo necesario para el cumplimiento de cualquier obligación legal
            aplicable.
        <br />
        <br />
        <strong>6. ¿Con quién comparte Primavera Sound tus datos personales?</strong>
        <br />
            Primavera Sound no compartirá tus datos personales con terceros a menos que la ley aplicable lo
            exija.
        <br />
        <br />
        <strong>7. ¿Cuáles son tus derechos cuando nos facilitas tus datos personales?</strong>
        <br />
            Puedes ejercer los siguientes derechos de protección de datos:
            Acceso: podrás acceder a tus datos incluidos en los ficheros del Primavera Sound.
            Rectificación/Modificación: podrás modificar tus datos personales si éstos son inexactos.
            Borrado: podrás solicitar el borrado de tus datos personales.
            Oposición: puedes solicitar que no se traten tus datos personales.
            Portabilidad: podrás recibir, en un archivo electrónico, los datos personales que hayas
            facilitado a Primavera Sound, así como enviarlos a diferentes entidades.
            Limitación del tratamiento: podrás solicitar la limitación del tratamiento de tus datos cuando (i)
            se verifique la exactitud de tus datos personales después de que hayas impugnado su
            exactitud, (ii) el tratamiento de tus datos sea ilegal y te opongas al borrado de los mismos; (iii)
            Primavera Sound ya no necesita los datos personales para los fines del tratamiento, pero tú
            los necesitas para el establecimiento, el ejercicio o la defensa de reclamaciones legales, (iv)
            te has opuesto al tratamiento para la realización de una tarea llevada a cabo en interés
            público o necesaria para los fines de un interés legítimo, mientras se verifica si los motivos
            legítimos de Primavera Sound prevalecen sobre los tuyos.
            Además, como se ha indicado, puedes retirar tu consentimiento en cualquier momento sin que ello
            afecte al tratamiento de datos realizado antes de dicha retirada. Para ejercer tus derechos y retirar tu
            consentimiento, puedes enviar tu solicitud a
        {' '}
        <a target="blank" rel="noopener noreferrer" href="mailto:privacy@primaverasound.com">privacy@primaverasound.com</a>
        {' '}
, junto con una copia de tu
            DNI o pasaporte o cualquier otro documento válido que acredite tu identidad. En cualquier caso,
            también tienes derecho a presentar una reclamación ante la Autoridad Española de Protección de
            Datos (
        <a target="blank" href="www.aepd.es">www.aepd.es</a>
) o la autoridad de protección de datos de tu país de residencia.
      </div>
    );
  }

  return (
    <div>
      <h2>PRIVACY POLICY</h2>
      <br />
      <strong>1. Who is the data controller?</strong>
      <br />
              The data controller is the Spanish company PRIMAVERA SOUND, S.L., with corporate address at C/ Roc
              Boronat, 142, 3º planta, 0818, Barcelona (Spain) (“
      <stong>Primavera Sound</stong>
              ”).
      <br />
      <br />
      <strong>2. Who is the data protection officer?</strong>
      <br />
              The data protection officer acts as a liaison between Primavera Sound and you, to ensure Primavera
              Sound's compliance with the data protection legislation and guarantee your rights under such legislation.
              You can contact the data protection officer of Primavera Sound at
      {' '}
      <a target="blank" rel="noopener noreferrer" href="mailto:privacy@primaverasound.com">privacy@primaverasound.com</a>
.
      <br />
      <br />
      <strong>3. For which purposes does Primavera Sound process your personal data?</strong>
      <br />
              During the celebration of the edition 2022 of Primavera Sound festival (the “
      <strong>Festival</strong>
  ”), Primavera Sound
              will make available to the festivalgoers a number of QRs, which will be located in different parts of the
              Festival. After scanning the QR you may become the winner of, among others, a ticket (in particular, a
              ticket for the 2023 edition of the Primavera Sound to be held in Barcelona and Madrid) or a t-shirt (the
              “
      <strong>Prize</strong>
  ”).
              To effectively obtain the Prize, you must register and fill in the form to which the winning QR will redirect
              you. Thus, Primavera Sound will process the personal data you provide us, in the event you have become
              a winner, to give you the Prize.
      <br />
      <br />
      <strong>4. Which are the lawful bases to process your personal data?</strong>
      <br />
              The legal basis of Primavera Sound for the processing of your personal data is the consent you given us
              when you register and fill in the corresponding form, to obtain your Prize.
      <br />
      <br />
      <strong>5. How long Primavera Sound Will retain your personal data?</strong>
      <br />
              Primavera Sound will retain your personal data during the time necessary to manage the delivery of the
              Prize. In any case, Primavera Sound may retain your personal data duly blocked until the end of the
              statutes of limitation of any liabilities that may arise and during the term required to comply with any
              applicable legal obligation.
      <br />
      <br />
      <strong>6. With whom does Primavera Sound share your personal data?</strong>
      <br />
              Primavera Sound will not share your personal data with third parties unless it is required to do so by
              applicable law.
      <br />
      <br />
      <strong>7. Which are your rights when you provide us with your personal data?</strong>
      <br />
              You may exercise the following data protection rights:
              - Access: you may access your data included in Primavera Sound files.
              - Rectification/Modification: you may modify your personal data if these are inaccurate.
              - Erasure: you may request that your personal data are erased.
              - Objection: you may request that your personal data are not processed.
              - Portability: you may receive, in an electronic file, the personal data that you provided Primavera
              Sound with, as well as send it to different entities.
              - Restriction of processing: you may request a restriction on how your data is processed when: (i)
              the accuracy of your personal data is being verified after you have contested its accuracy, (ii) the
              processing of your data is illegal and you oppose to the erasure of your data; (iii) Primavera
              Sound does no longer need the personal data for the purposes of the processing, but they are
              required by you for the establishment, exercise or defense of legal claims, (iv) you have objected to the processing for the performance of a task carried out in the public interest or necessary for
              the purposes of a legitimate interest, whilst it is being verified whether the legitimate grounds of
              Primavera Sound override yours.
              Additionally, as indicated, you may withdraw your consent at any time without affecting the data
              processing carried out before such withdrawal. In order to exercise your rights and withdraw your
              consent, you can send your request to
      {' '}
      <a target="blank" rel="noopener noreferrer" href="mailto:privacy@primaverasound.com">privacy@primaverasound.com</a>
, together with a copy of your ID or
              passport or any other valid document evidencing your identity. In any case, you also have the right to
              lodge a complaint before the Spanish Data Protection Authority (
      <a target="blank" href="www.aepd.es">www.aepd.es</a>
) or the data protection
              authority in your country of residence.
    </div>
  );
};

export default PrivacyPolicy;
