import React from 'react';
import PropTypes from 'prop-types';

import { LinkWrapper } from '../../../../util/link';

import { useRoutes } from '../../../../context/routesContext';
import translateObj from '../../../../util/translateObj';


const HeaderMenuMobile = ({ items }) => {
  const { language, path } = useRoutes();

  const topItems = items.find(item => item.type === 'TopHeaderMainMenuMusicMeets').components.find(component => component.name === 'headerMenuItems').params;
  const bottomItems = items.find(item => item.type === 'TopHeaderSubmenuMusicMeets').components.find(component => component.name === 'subMenuItems').params;

  return (
    <div className="w-full h-screen bg-black p-4">
      {topItems.map(item => (
        <div key={item.key} className="py-6 border-b border-white">
          <LinkWrapper url={item.link} path={path}>
            <span className="text-white font-americaMonoBold text-14 uppercase tracking-px">{translateObj(item.text, language)}</span>
          </LinkWrapper>
        </div>
      ))}
      <div className="flex flex-wrap items-center justify-center pt-6">
        {bottomItems.map(item => (
          <div key={item.key} className="pb-2 px-4">
            <LinkWrapper url={item.link} path={path}>
              <span className="text-white font-americaMonoRegular text-14 uppercase tracking-px">{translateObj(item.text, language)}</span>
            </LinkWrapper>
          </div>
        ))}
      </div>
    </div>
  );
};

HeaderMenuMobile.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()),
};

HeaderMenuMobile.defaultProps = {
  items: [],
};

export default HeaderMenuMobile;
