import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import CollapsibleListPage from './Collapsible/CollapsibleListPage';
import ComponentIterator from '../../containers/ComponentIterator';
import getPostsBySlugNameQuery from '../../api/graphql/queries/getPostsBySlugNameQuery';
import SliderBannerPD from './Sliders/SliderBannerPD';
import Navigator from './Navigator';
import PSResponsiveUtility from './LineUp/PSResponsiveUtility';

const getParamsByAnchor = (arrOfComponents) => {
  const paramsByAnchor = [];
  arrOfComponents.forEach((component) => {
    if ((component.type === 'DoubleTitle' || component.type === 'MediaTitle' || component.type === 'Separator') && component.anchor) {
      paramsByAnchor.push([]);
      paramsByAnchor[paramsByAnchor.length - 1].push(component);
    } else {
      if (component.type !== 'Separator' || !component.anchor) {
        if (paramsByAnchor.length) paramsByAnchor[paramsByAnchor.length - 1].push(component);
      }
    }
  });
  return paramsByAnchor;
};

class CollapsiblePage extends Component {
  state = {
    isLoading: true,
    postDescription: {},
    deviceScreen: null,
  };

  responsiveUtility = React.createRef();

  _mounted = false;

  componentDidMount() {
    this._mounted = true;
    const {
      match: { params },
    } = this.props;
    let pageSlug;
    if (params.post) {
      pageSlug = params.post;
    } else {
      pageSlug = params.section;
    }
    this.fetchPostDrescriptionsBySlugnames([pageSlug]);
  }

  componentDidUpdate(prevProps) {
    const {
      match: { params },
    } = this.props;
    const prevParams = prevProps.match.params;

    if (prevParams.section !== params.section || prevParams.post !== params.post) {
      let pageSlug;
      if (params.post) {
        pageSlug = params.post;
      } else {
        pageSlug = params.section;
      }
      if (this._mounted) {
        this.fetchPostDrescriptionsBySlugnames([pageSlug]);
      }
    }
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  fetchPostDrescriptionsBySlugnames = (slugnames) => {
    if (slugnames.length && slugnames[0]) {
      getPostsBySlugNameQuery(slugnames)
        .then((res) => {
          if (res) {
            const { postDescription } = res[0];
            if (this._mounted) {
              this.setState({
                postDescription,
                isLoading: false,
              });
            }
          }
        })
        .catch((err) => {
          console.log('Some error happened on fetch of fetchPostDescription:', err);
        });
    }
  };

  /*
  create array of components detecting each anchor
  when an anchor is found, it creates a nested array
  that will be filled with params until the next anchor is found
  */

  handleResize = () => {
    const deviceScreen = this.responsiveUtility.current.deviceScreen(true);
    this.setState({
      deviceScreen,
      isLoading: false,
    });
  };

  render() {
    const { components, withSlider, leaveOpen } = this.props;
    const { postDescription, isLoading, deviceScreen } = this.state;

    return (
      <React.Fragment>
        <PSResponsiveUtility ref={this.responsiveUtility} deviceScreenCallback={this.handleResize} />
        {!isLoading ? (
          <>
            {components.length && (components[0].type === 'Carousel' || components[0].type === 'OnDemandBanner' || components[0].type === 'SimpleOnDemandBanner' || components[0].type === 'NativeVideoPlayer')
              ? (
                <>
                  {deviceScreen === 'sm' ? null : (
                    <Navigator />
                  )
                }
                  <ComponentIterator components={[components[0]]} />
                </>
              )
              : <SliderBannerPD {...postDescription} />
            }
            {components && components.length ? (
              <div className="flex mt-12 w-full collapsible-page justify-center">

                <div className={`relative w-ticket-lg md:w-full sm:w-full pt-4 ${leaveOpen ? 'sm:hidden md:hidden' : ''}`}>
                  <CollapsibleListPage color="white" params={getParamsByAnchor(components)} withSlider={withSlider} />
                </div>

                <div className={`w-2/3 ${!leaveOpen ? 'sm:hidden md:hidden' : 'sm:w-full md:w-full'}`}>
                  {getParamsByAnchor(components).map((param, i) => (
                    <div id={param[0].anchor} key={param[0].anchor} className="mt-4">
                      <ComponentIterator components={param} />
                    </div>
                  ))}
                </div>
              </div>
            ) : null}
          </>
        ) : null}
      </React.Fragment>
    );
  }
}

CollapsiblePage.propTypes = {
  components: PropTypes.arrayOf(PropTypes.shape()),
  withSlider: PropTypes.bool,
  leaveOpen: PropTypes.bool,
};

CollapsiblePage.defaultProps = {
  withSlider: true,
  leaveOpen: false,
  components: null,
};

export default withRouter(CollapsiblePage);
