import jwt from 'jsonwebtoken';
import moment from 'moment';
import refetchUserToken from '../api/graphql/mutations/refetchUserToken';

export const initialUserProfileState = {
  isActivationBarOpen: false,
  isConditionBarOpen: false,
  isLinkedWithSpotify: false,
  isSpotifyLinked: null,
  isUserInNewsLetter: null,
  isUserInSplio: null,
  isUserUpToDateWithTermsAndConditions: true,
  isUserVerified: null,
  language: 'en',
  loggedFromSocialNetwork: '',
  userId: '',
  userName: '',
  userLastName: '',
  userNickName: '',
  userDateOfbirth: '',
  userEmail: '',
  userCountry: '',
  userGender: '',
  userRoles: [],
  userPostalCode: '',
  token: null,
  tokenExpiration: null,
  userSubscriptionDetails: {},
  userTrialWasUsed: {},
  description: '',
  occupation: '',
  company: '',
  url: '',
  imageUrl: '',
  social: [],
  userType: '',
  isPrivate: false,
  business: '',
  acceptedConditionsMusicMeets: false,
  verified: false,
  city: '',
  addressName: '',
  addressLastname: '',
  addressStreet: '',
  addressStreetNumber: '',
  addressApartment: '',
  addressPostalCode: '',
  addressProvince: '',
  addressCity: '',
  addressCountry: '',
  phoneNumber: '',
};

export const parseToken = (token) => {
  if (!token) return initialUserProfileState;

  const decodedToken = jwt.decode(token);

  return {
    isLinkedWithSpotify: decodedToken.isSpotifyLinked,
    isUserInNewsLetter: decodedToken.isUserInNewsLetter,
    isUserInSplio: decodedToken.isInSplio,
    isUserUpToDateWithTermsAndConditions: decodedToken.acceptedConditions,
    isUserVerified: decodedToken.verified,
    language: decodedToken.language,
    loggedFromSocialNetwork: decodedToken.socialnetwork,
    tokenExpiration: decodedToken.tokenExpiration,
    userCountry: decodedToken.inputCountry,
    userDateOfbirth: decodedToken.dateOfBirth,
    userEmail: decodedToken.email,
    userGender: decodedToken.gender,
    userId: decodedToken.userId,
    userLastName: decodedToken.lastname,
    userName: decodedToken.name,
    userNickName: decodedToken.nickname,
    userPostalCode: decodedToken.postalCode,
    userRoles: decodedToken.roles,
    userSubscriptionDetails: decodedToken.subscriptionDetails,
    userTrialWasUsed: decodedToken.trialUsed,
    description: decodedToken.description,
    occupation: decodedToken.occupation,
    company: decodedToken.company,
    url: decodedToken.url,
    imageUrl: decodedToken.imageUrl,
    social: decodedToken.social,
    userType: decodedToken.userType,
    isPrivate: decodedToken.isPrivate,
    business: decodedToken.business,
    acceptedConditionsMusicMeets: decodedToken.acceptedConditionsMusicMeets,
    verified: decodedToken.verified,
    city: decodedToken.city,
    province: decodedToken.province,
    apartment: decodedToken.apartment,
    streetNumber: decodedToken.streetNumber,
    street: decodedToken.street,
    addressName: decodedToken.addressName,
    addressLastname: decodedToken.addressLastname,
    addressStreet: decodedToken.addressStreet,
    addressStreetNumber: decodedToken.addressStreetNumber,
    addressApartment: decodedToken.addressApartment,
    addressPostalCode: decodedToken.addressPostalCode,
    addressProvince: decodedToken.addressProvince,
    addressCity: decodedToken.addressCity,
    addressCountry: decodedToken.addressCountry,
    userPhoneNumber: decodedToken.phoneNumber,
  };
};

export const checkExpiredToken = (tokenExpiration) => {
  // Validate if token is expired
  const difference = moment.utc(tokenExpiration).diff(moment().utc(), 'minutes');

  if (difference < 0) {
    return refetchUserToken()
      .then(response => response?.token)
      .catch((err) => { console.log('Something happened while refetching token:', err); });
  }
  return null;
};
