/* eslint-disable eol-last */
/* eslint-disable semi */
/* eslint-disable comma-dangle */
/* eslint-disable quote-props */
/* eslint-disable quotes */
/* eslint-disable indent */

const error404Page = (appName = 'Primavera Sound') => (
  {
    "slugName": "404",
    "name": "404",
    "postCategory": [
      "404"
    ],
    "title": "404",
    "type": "route",
    "postDescription": {
      "title": {
        "en": `${appName} - Error 404`,
      },
      "description": {
        "en": `${appName} - Error 404`,
      },
      "url": ""
    },
    "components": [
      {
        "type": "Body",
        "slugName": "error404",
        "components": [
          {
            "type": "BodyChildContainer",
            "slugName": "homeBodyContainer",
            "components": [
              {
                "type": "Error404",
                "params": [
                  {
                    "name": "newgif",
                    "title": {
                      "en": "Oops! something went wrong",
                      "es": "¡Ups! Algo salió mal",
                      "ca": "Ups! Alguna cosa ha anat malament",
                      "pt": "Oops! Algo deu errado"
                    },
                    "text": {
                      "en": "Seems like you’ve got lost, get back to the main stage.",
                      "es": "Parece que te has perdido, vuelve al escenario principal.",
                      "ca": "Sembla que t'has perdut, torna a l'escenari principal.",
                      "pt": "Parece que você se perdeu, volte ao palco principal"
                    },
                    "image": "https://assets.primaverasound.com/psweb/lvdtcpkol4jza0q3frtk_1707136274747.gif"
                  }
                ],
                "linkButtonRounded": {
                  "name": "error404",
                  "is404": true,
                  "text": {
                    "es": "Llévame a la home",
                    "en": "Take me home",
                    "ca": "Porta'm a la home",
                    "pt": "Regressar à homepage"
                  },
                  "className": "rounded-full uppercase text-base sm:text-14 md:text-10 py-2 md:px-4 px-8 sm:px-8 font-americaMonoBold text-black border-2 border-black sm:whitespace-pre",
                  "url": "/"
                }
              }
            ]
          }
        ]
      }
    ],
    "rolesAllowed": []
  }
)

export default error404Page;
