import React from 'react';
import { PropTypes } from 'prop-types';

const Toast = ({ nickname, message }) => (
  <div>
    {nickname && (
    <span className="font-americaBold">
      {nickname}
      {' '}
    </span>
    )}
    {message}
  </div>
);

Toast.propTypes = {
  nickname: PropTypes.string,
  message: PropTypes.string,
};

Toast.defaultProps = {
  nickname: '',
  message: '',
};

export default Toast;
