import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { TimelineMax, TweenMax } from 'gsap';
import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';
import PSResponsiveUtility from '../LineUp/PSResponsiveUtility';
import HeaderRedesignTopHeaderMegamenu from './HeaderRedesignTopHeaderMegamenu';
import translateObj from '../../../util/translateObj';
import withAllContexts from '../../../context/withAllContexts';
import Link from '../../../util/link';

const burgerAnimationDuration = 0.2;

class TopHeaderMainMenuItemsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      section: false,
      nextSection: false,
    };
    this.responsiveUtility = React.createRef();
    this.componentExists = React.createRef(false);
  }

  componentDidMount() {
    this.componentExists.current = true;
    window.addEventListener('scroll', this.handleScroll);

    const { history } = this.props;
    this.unlisten = history.listen(() => {
      const { open } = this.state;
      if (open) {
        this.toggle();
      }
    });
  }

  componentDidUpdate() {
    const { section } = this.state;

    const deviceScreen = this.responsiveUtility.current.deviceScreen(true);
    const isTabletOrSmaller = ['sm', 'md'].indexOf(deviceScreen) > -1;
    document.querySelector('body').style.overflowY = !isTabletOrSmaller && section ? 'hidden' : 'scroll';
  }

  componentWillUnmount() {
    this.componentExists.current = false;
    if (this.unlisten) this.unlisten();
    window.removeEventListener('scroll', this.handleScroll);
    document.querySelector('body').style.overflowY = 'scroll';
  }

  handleScroll = () => {
    const deviceScreen = this.responsiveUtility.current.deviceScreen();
    if (['sm', 'md'].indexOf(deviceScreen) === -1) {
      return;
    }

    const { section } = this.state;
    const y = document.documentElement.scrollTop || document.body.scrollTop;
    if (section) {
      const el = document.querySelector(`#menuItem_${section.key}`);
      if (el) {
        const link = el.querySelector('[data-link]');
        const linkHeight = link.getBoundingClientRect().height;
        const top = y + el.getBoundingClientRect().top;
        const bottom = y + el.getBoundingClientRect().bottom - linkHeight;
        if (y > top) {
          this.originalPaddingTop = parseFloat(window.getComputedStyle(el.querySelector('[data-menu-type]')).getPropertyValue('padding-left'));
          el.querySelector('[data-menu-type]').style.paddingTop = `${linkHeight + this.originalPaddingTop}px`;
          this.setState({ floatingSection: y > bottom ? 'bottom' : 'floating' });
        } else {
          el.querySelector('[data-menu-type]').style.paddingTop = `${this.originalPaddingTop}px`;
          this.setState({ floatingSection: false });
        }
      }
    }
  };

  toggle = () => {
    const { open } = this.state;
    const { headerContext } = this.props;
    headerContext.toggleMegaMenu();
    if (this.tl) this.tl.kill();

    this.tl = new TimelineMax();

    if (!open) {
      this.tl.to('[data-type="open"]', 0, {
        className: '-=hidden',
      });
      this.tl.staggerTo(
        '[data-type="open"] span',
        burgerAnimationDuration,
        {
          x: '-100%',
        },
        0.1,
        '+=0',
      );
      this.tl.to(
        '[data-type="open"]',
        0,
        {
          className: '+=hidden',
        },
        '+=0',
      );
      this.tl.to(
        '[data-type="close"]',
        0,
        {
          className: '-=hidden',
        },
        '+=0',
      );
      this.tl.fromTo(
        '[data-type="close"] span:nth-child(1)',
        burgerAnimationDuration,
        {
          rotation: 0,
        },
        {
          rotation: '-45deg',
        },
        '+=0',
      );
      this.tl.fromTo(
        '[data-type="close"] span:nth-child(2)',
        burgerAnimationDuration,
        {
          rotation: 0,
        },
        {
          rotation: '45deg',
        },
        `-=${burgerAnimationDuration}`,
      );
    } else {
      this.tl.fromTo(
        '[data-type="close"] span:nth-child(1)',
        burgerAnimationDuration,
        {
          rotation: '-45deg',
        },
        {
          rotation: 0,
        },
        '+=0',
      );
      this.tl.fromTo(
        '[data-type="close"] span:nth-child(2)',
        burgerAnimationDuration,
        {
          rotation: '45deg',
        },
        {
          rotation: 0,
        },
        `-=${burgerAnimationDuration}`,
      );
      this.tl.to(
        '[data-type="close"]',
        0,
        {
          className: '+=hidden',
        },
        '+=0',
      );
      this.tl.to(
        '[data-type="open"]',
        0,
        {
          className: '-=hidden',
        },
        '+=0',
      );
      this.tl.staggerTo(
        '[data-type="open"] span',
        burgerAnimationDuration,
        {
          x: '0%',
        },
        0.1,
        '+=0',
      );
    }

    this.tl.eventCallback(
      'onStart',
      (that, o) => {
        that.setState({ open: !o });
      },
      [this, open],
    );
    this.tl.play();
  };

  toggleItem = (e, link, localizationpath) => {
    // temporary code to link to external porto side
    // if (link && link.key === 'porto' && link.link.includes('http')) return null;

    const { section, nextSection } = this.state;

    if (!link || !link.menu) {
      this.rotateArrow(section.key);
      if (link) {
        this.goTo(link.link, localizationpath);
      }
      if (this.componentExists.current) {
        this.setState({ section: false, nextSection: false });
      }
      return;
    }

    if (e) e.preventDefault();
    const deviceScreen = this.responsiveUtility.current.deviceScreen(true);
    const isTabletOrSmaller = ['sm', 'md'].indexOf(deviceScreen) > -1;

    let theNewSection = false;
    let theNewNextSection = false;
    if (!isTabletOrSmaller) {
      if (nextSection && section.key === link.key) {
        theNewSection = section;
        theNewNextSection = link;
      } else if (section === false) {
        theNewSection = link;
      } else if (section.key === link.key && nextSection.key !== link.key) {
        theNewSection = false;
      } else if (section.key) {
        theNewSection = section;
        theNewNextSection = link;
      }
    } else {
      if (section.key !== link.key) {
        theNewSection = link;
      }
    }

    this.rotateArrow(section.key, '0deg');
    this.rotateArrow(theNewSection.key, '180deg');

    this.setState({ section: theNewSection, nextSection: theNewNextSection }, () => {
      if (!theNewSection && isTabletOrSmaller) {
        window.scrollTo(0, 0);
      }
    });
  };

  goTo = (url, localizationpath = 'en') => {
    const { history } = this.props;
    history.push(`${localizationpath}${url}`);
  };

  getScreen = () => {
    const deviceScreen = this.responsiveUtility.current.deviceScreen(true);
    return deviceScreen;
  };

  // eslint-disable-next-line class-methods-use-this
  rotateArrow(key, deg = '0deg') {
    TweenMax.to(`#menuItem_${key} [data-name="caret"]`, burgerAnimationDuration, {
      rotation: deg,
    });
  }

  render() {
    const {
      name, params, children, match, textColor, festivalsText, location, headerContext,
    } = this.props;

    const burgerMenu = (
      <button
        type="button"
        id="menuMobileButton"
        className="absolute hidden w-6 h-6 sm:block md:block pin-t pin-r mt-5 mr-6 tablet:mr-3 tablet:mt-6 pointer-events-auto"
        onClick={this.toggle}
      >
        <div data-type="open" className="absolute overflow-hidden pin">
          <span style={{ height: 3 }} className={`block rounded-full w-full bg-${textColor || 'black'} mb-1`} />
          <span style={{ height: 3 }} className={`block rounded-full w-full bg-${textColor || 'black'} mb-1`} />
          <span style={{ height: 3 }} className={`block rounded-full w-full bg-${textColor || 'black'} mb-1 `} />
        </div>
        <div data-type="close" className="absolute hidden pin" style={{ top: 8 }}>
          <span style={{ height: 3, transform: 'rotate(-45deg)' }} className={`block rounded-full w-full bg-${textColor || 'black'}`} />
          <span
            style={{
              height: 3,
              transform: 'rotate(45deg)',
              left: 0,
              top: -3,
            }}
            className={`block relative rounded-full w-full bg-${textColor || 'black'} -mb-1`}
          />
        </div>
      </button>
    );

    return (
      <RoutesContext.Consumer>
        {({ language: contextLanguage, enteredFrom }) => {
          const localization = getLocalization(contextLanguage, enteredFrom, match);

          const {
            section, open, floatingSection, nextSection,
          } = this.state;

          const linkFloatingClasses = (key) => {
            if (section && section.key === key) {
              if (floatingSection === 'floating') {
                return 'sm:fixed md:fixed sm:pin-x sm:pin-t md:pin-x md:pin-t z-10';
              }
              if (floatingSection === 'bottom') {
                return 'sm:absolute md:absolute sm:pin-x sm:pin-b-1 md:pin-x md:pin-b-1 z-10';
              }
            }
            return '';
          };

          return (
            <>
              <PSResponsiveUtility ref={this.responsiveUtility} />
              <div
                role="navigation"
                id={name}
                className={
                  `flex items-center sm:items-end sm:flex-col md:items-end md:flex-col pointer-events-none
                  ${!open ? 'h-68px' : `bg-${textColor === 'black' ? 'white' : 'black'}`}
                `
}
              >
                {params && params.length > 0 && burgerMenu}
                <nav
                  className={`
                    w-full z-header flex items-center sm:items-start sm:flex-col md:items-start md:flex-col pointer-events-auto
                    sm:bg-${textColor === 'black' ? 'white' : 'black'} md:bg-${textColor === 'black' ? 'white' : 'black'}
                    ${open ? 'mt-18 tablet:mt-16 lg:mt-0 menu-width-transition' : 'lg:mt-0 sm:hidden md:hidden'}
                  `}
                >
                  {params && params.length && params.map((link, index) => (
                    <div key={link.key} className={`relative cursor-pointer w-full ${link.menuText ? '' : 'desktop:w-0'}`}>
                      <div id={`menuItem_${link.key}`}>
                        <span className={`bg-${textColor === 'black' ? 'white' : 'black'} no-underline text-base text-${textColor} ${linkFloatingClasses(link.key)}`}>
                          <span
                            onClick={(e) => (this.getScreen() === 'sm' || this.getScreen() === 'md') && this.toggleItem(e, link, localization.path)}
                            className="block w-auto px-2 font-bold tracking-wide uppercase whitespace-no-wrap sm:py-6 font-americaMonoBold text-14 sm:flex sm:items-center sm:justify-between md:flex md:items-center md:justify-between sm:px-4 md:px-4 md:py-4"
                          >
                            <Link
                              tabIndex={headerContext.megaMenuIsOpenDesktop || !link.menuText ? '-1' : '0'}
                              to={link.link}
                              localizationpath={localization.path}
                              data-link={link.key}
                              onClick={(e) => {
                                if (link.menu && !headerContext.megaMenuIsOpenDesktop) {
                                  headerContext.setMegaMenuIsOpenDesktop(true);
                                }

                                if (index > 0) return this.toggleItem(e, link, localization.path);

                                const [, slash] = location.pathname.split(localization.path);
                                if (!slash) return this.toggleItem(e, params[0], localization.path);

                                const menuData = params.find((param) => slash.includes(param.link));
                                if (!menuData || !('menu' in menuData)) return this.toggleItem(e, params[0], localization.path);

                                this.toggleItem(e, menuData, localization.path);
                              }}
                              aria-label={link.menuText ? translateObj(link.menuText, localization.language) : translateObj(link.text, localization.language)}
                            >
                              <span>
                                <span className={`desktop:hidden text-${textColor} relative py-4 cursor-pointer`} data-link={link.key}>
                                  {translateObj(link.text, localization.language)}
                                </span>
                                {link.menuText && (
                                  <span className={`hidden desktop:inline text-${textColor} relative py-4 cursor-pointer relative`} data-link={link.key}>
                                    {translateObj(link.menuText, localization.language)}
                                    {(`/${match.params.section}` === link.link || (link.showOnHome && location.pathname === localization.path)) && (
                                    <div className="absolute pin-l pin-r pin-b -mb-5">
                                      <div className="w-6 h-6 bg-white mx-auto" style={{ transform: 'rotate(45deg)' }} />
                                    </div>
                                    )}
                                  </span>
                                )}
                              </span>
                            </Link>
                            {link.menu && (
                              <span
                                data-name="caret"
                                className={`hidden sm:block md:block font-icon icon-arrow-down text-${textColor} text-sm cursor-pointer`}
                                data-link={link.key}
                                onClick={(e) => this.toggleItem(e, link, localization.path)}
                              />
                            )}
                          </span>
                        </span>
                        {section && section.key === link.key && link.menu && (
                          <HeaderRedesignTopHeaderMegamenu
                            nextSection={nextSection}
                            localization={localization}
                            links={params}
                            activeLink={link}
                            toggleItemCallback={this.toggleItem}
                            goTo={this.goTo}
                            festivalsText={festivalsText}
                          />
                        )}
                      </div>
                      <div className={`h-px bg-${textColor === 'black' ? 'black' : 'white'} mx-2 sm:mx-4 md:mx-4 hidden sm:block md:block`} />
                    </div>
                  ))}
                  <div className="w-full pl-2 mr-4 sm:px-4 md:pl-4 md:flex md:items-center md:flex-col sm:flex sm:items-center sm:flex-col sm:pt-6 md:pt-6">{children}</div>
                </nav>
              </div>

            </>
          );
        }}
      </RoutesContext.Consumer>
    );
  }
}

TopHeaderMainMenuItemsContainer.propTypes = {
  // eslint-disable-next-line react/require-default-props
  name: PropTypes.string,
  match: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
  festivalsText: PropTypes.shape().isRequired,
  params: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
  children: PropTypes.shape().isRequired,
  textColor: PropTypes.string,
  headerContext: PropTypes.shape({}).isRequired,
};

TopHeaderMainMenuItemsContainer.defaultProps = {
  textColor: 'black',
};

export default withRouter(withAllContexts(TopHeaderMainMenuItemsContainer));
