import React, { useState } from 'react';

const useWhisperModalManagement = () => {
  const [isWhisperModalOpened, setWhisperModalVisibility] = useState(false);

  const openWhisperModal = () => {
    setWhisperModalVisibility(true);
  };

  const closeWhisperModal = () => {
    setWhisperModalVisibility(false);
  };

  return {
    isWhisperModalOpened,
    openWhisperModal,
    closeWhisperModal,
  };
};

export default useWhisperModalManagement;
