import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, useFormikContext } from 'formik';

import { useRoutes } from '../../../context/routesContext';
import translateObj from '../../../util/translateObj';
import getCountryNamesByCode, { getCodeFromCountry, getCountryFromCode } from '../../../util/getCountryNamesByCode';

const getError = (errors, name) => {
  if (Object.prototype.hasOwnProperty.call(errors, name)) {
    return errors[name];
  }
  return undefined;
};

const FormSelectCountry = ({ name, label, placeholder, required, className, errors }) => {
  const { language } = useRoutes();
  const { setFieldValue, getFieldProps } = useFormikContext();
  const field = getFieldProps(name);
  const [error, setError] = useState(undefined);
  const [selection, setSelection] = useState({ name: placeholder, value: '' });
  const [showOptions, setShowOptions] = useState(false);
  const options = getCountryNamesByCode(language);

  const handleSelection = (item) => {
    if (selection.value === item.value) {
      setSelection({ name: placeholder, value: '' });
    } else {
      setSelection(item);
    }
    setShowOptions(false);
  };

  useEffect(() => {
    setFieldValue(name, selection.value);
  }, [selection]);

  useEffect(() => {
    const fieldError = getError(errors, name);
    setError(fieldError);
  }, [errors]);

  useEffect(() => {
    if (field.value !== '') {
      setSelection({ name: getCountryFromCode(field.value), value: field.value });
    } else {
      setSelection({ name: placeholder, value: '' });
    }
  }, []);

  return (
    <div className={`${className || 'w-full'}`}>
      {label && (
      <div className={`text-12 font-americaMonoRegular my-4 uppercase ${error ? 'text-pro-online' : 'text-black'}`}>
        {`${translateObj(label, language)}${required ? '*' : ''}`}
      </div>
      )}
      <div className={`w-full rounded-full border ${error ? 'border-pro-online ' : 'border-black'}`}>
        <Field
          name={name}
          as="select"
          required={required}
          className="hidden"
        />
        <button
          type="button"
          className="flex items-center justify-between w-full p-4 outline-none bg-transparent text-12 cursor-pointer text-black"
          onClick={() => setShowOptions(!showOptions)}
          aria-label={placeholder}
        >
          <div className="pr-4">
            <span className={error ? 'text-pro-online' : 'text-black'}>
              {selection.name}
            </span>
          </div>
          <div
            className="font-icon icon-arrow-up"
            style={{
              transform: !showOptions ? 'rotate(-180deg)' : '',
              transition: 'transform .1s ease-in',
            }}
          />
        </button>
        <div className="relative">
          {showOptions && (
            <div className="z-50 absolute w-full rounded-xl border border-black mt-2 px-4 py-3 bg-white-dark max-h-260px overflow-x-hidden overflow-y-scroll">
              <div
                key={placeholder}
                className={`py-2 cursor-pointer ${selection.value === '' ? 'text-pro-online' : ''}`}
                onClick={() => handleSelection({ name: placeholder, value: '' })}
              >
                <span>{placeholder}</span>
              </div>
                {options.map((item) => (
                  <div
                    key={item}
                    className={`py-2 cursor-pointer ${selection.name === item ? 'text-pro-online' : ''}`}
                    onClick={() => handleSelection({ name: item, value: getCodeFromCountry(item, language) })}
                  >
                    <span>{item}</span>
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

FormSelectCountry.propTypes = {
  name: PropTypes.string,
  label: PropTypes.shape(),
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  className: PropTypes.string,
  errors: PropTypes.shape(),
};

FormSelectCountry.defaultProps = {
  className: '',
  name: '',
  required: false,
  label: null,
  placeholder: '',
  errors: {},
};

export default FormSelectCountry;
