/* eslint-disable camelcase */
import React, { useRef, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import FilePlayer from 'react-player';

const StreamingVideoPlayerMainPlayerAll = ({
  mainVideo,
  controls,
  autoPlay,
  muted,
  shouldMainVideoStart,
  handlePlayingSmallScreens,
  handleOnReadyTrueForAll,
  isLoading,
  currentMainVideo,
  activeSlide,
  // componentShouldGetQuality,
  // getQualityFn,
}) => {
  const playerRef = useRef(null);

  // ! for testing remove
  // const getQualityInComponent = () => {
  //   const dash = playerRef.current.getInternalPlayer('dash');
  //   const quality = dash.getQualityFor('video');
  //   const currTrack = dash.getCurrentTrackFor('video');
  //   console.log('!!!: getQualityInComponent -> quality', quality, mainVideo);
  //   getQualityFn(quality, mainVideo);
  // };
  // useEffect(() => {
  //   if (componentShouldGetQuality) {
  //     getQualityInComponent();
  //   }
  // }, [componentShouldGetQuality]);
  // !--------------------

  const onReady = () => {
    const dash = playerRef.current.getInternalPlayer('dash');
    if (dash) {
      dash.setInitialBitrateFor('video', 0);
      dash.setQualityFor('video', 0);
    }

    if (isLoading) {
      handleOnReadyTrueForAll();
    }
  };

  useEffect(() => {
    const dash = playerRef.current.getInternalPlayer('dash');
    if (!isLoading && dash) {
      if (!currentMainVideo) {
        dash.setQualityFor('video', 0);
        dash.setAutoSwitchQualityFor('video', false);
      } else {
        dash.setQualityFor('video', 0);
        setTimeout(() => {
          dash.setAutoSwitchQualityFor('video', true);
        }, 500);
      }
    }
  }, [currentMainVideo]);

  return (
    <div className={`${!currentMainVideo && 'hidden'}`}>
      <FilePlayer
        ref={playerRef}
        url={mainVideo}
        pip={Boolean(activeSlide)}
        playing={shouldMainVideoStart}
        onStart={() => { handlePlayingSmallScreens(true); }}
        onPlay={() => { handlePlayingSmallScreens(true); }}
        onPause={() => { handlePlayingSmallScreens(false); }}
        onReady={onReady}
        controls={controls}
        height="100%"
        width="100%"
        muted={!currentMainVideo}
        config={{
          autoPlay,
          file: {
            forceDASH: true,
          },
        }}
      />

    </div>
  );
};

StreamingVideoPlayerMainPlayerAll.propTypes = {
  mainVideo: PropTypes.string.isRequired,
  controls: PropTypes.bool,
  isLoading: PropTypes.bool,
  currentMainVideo: PropTypes.bool,
  muted: PropTypes.bool,
  autoPlay: PropTypes.bool,
  handlePlayingSmallScreens: PropTypes.func.isRequired,
  shouldMainVideoStart: PropTypes.bool,
  handleOnReadyTrueForAll: PropTypes.func.isRequired,
  activeSlide: PropTypes.number,
};

StreamingVideoPlayerMainPlayerAll.defaultProps = {
  controls: true,
  muted: false,
  autoPlay: true,
  isLoading: true,
  currentMainVideo: false,
  shouldMainVideoStart: null,
  activeSlide: 0,
};

export default StreamingVideoPlayerMainPlayerAll;
