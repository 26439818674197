import React, { useState, useEffect } from 'react';
import { useRoutes } from '../../../context/routesContext';
import imageModifier from '../helpers/imageModifier';
import translateObj from '../../../util/translateObj';
import useQuery from './useQuery';
import PrivacyPolicy from './PrivacyPolicy';
import LegalBasis from './LegalBasis';

const AVAILABLE = {
  en: 'There are {number} prizes left!',
  es: '¡Quedan {number} premios!',
  ca: '¡Queden {number} premis!',
};
const AVAILABLE_ONE = {
  en: 'There is 1 prize left!',
  es: '¡Queda 1 premio!',
  ca: '¡Queda 1 premi!',
};
const PRIVACY_POLICY = {
  en: 'Privacy Policy',
  es: 'Política de Privacidad',
  ca: 'Política de Privacitat',
};
const LEGAL_BASIS = {
  en: 'Legal basis',
  es: 'Bases legales',
  ca: 'Bases legals',
};

const noPrize = {
  en: 'https://assets.primaverasound.com/psweb/mlrp4ybk77tucz6e7t0a_1654086792946.png',
  es: 'https://assets.primaverasound.com/psweb/8b91borjvtiq8u7ffce5_1654086792946.png',
  ca: 'https://assets.primaverasound.com/psweb/urgvy8ndgnzm8r4j5188_1654086792946.png',
};

const prizeTicket = {
  en: 'https://assets.primaverasound.com/psweb/uhwkzbq15mohs8qazz55_1654184178350.png',
  es: 'https://assets.primaverasound.com/psweb/e9zle10qxpc1k42q3ytx_1654184178351.png',
  ca: 'https://assets.primaverasound.com/psweb/uhwkzbq15mohs8qazz55_1654184178350.png',
};

const prizeTshirt = {
  en: 'https://assets.primaverasound.com/psweb/4df882ha7oct7qqxjmkm_1654184263303.png',
  es: 'https://assets.primaverasound.com/psweb/26f3g5plfqj06v0rzrww_1654184263303.png',
  ca: 'https://assets.primaverasound.com/psweb/873pmrl0e2dolahsyj9j_1654184263302.png',
};

const QRCodeResponse = () => {
  const { language } = useRoutes();
  const [prize, setPrize] = useState(noPrize);
  const [availablePrizes, setAvailablePrizes] = useState();
  const query = useQuery();
  const [openModalPolicy, setOpenPolicy] = useState(false);
  const [openModalLegal, setOpenLegal] = useState(false);

  useEffect(() => {
    const award = query.get('award');
    const left = query.get('left');

    if (left) {
      setAvailablePrizes(left);
    }

    switch (award) {
      case 'ticket':
        setPrize(prizeTicket);
        break;
      case 'tshirt':
        setPrize(prizeTshirt);
        break;
      default:
        setPrize(noPrize);
    }
  }, []);

  return (
    <div className="w-full full-width">
      <div className="w-full h-full flex justify-center mx-auto relative">
        <img src={imageModifier(translateObj(prize, language), '506x900')} alt={translateObj(prize, language)} />
        {availablePrizes && (
        <div className="absolute text-center w-full py-4 pin-b bg-white" style={{ marginBottom: '25vw' }}>
          <div className="font-americaRegular text-black">{availablePrizes <= 1 ? translateObj(AVAILABLE_ONE, language) : translateObj(AVAILABLE, language).replace('{number}', availablePrizes)}</div>
        </div>
        )}
        <div className="w-full pin-b absolute flex items-center justify-center" style={{ marginBottom: '4vw' }}>
          <div className="bg-white p-4 text-black w-max uppercase text-center font-americaBold tracking-wide border-black border-2 mr-4" onClick={() => setOpenLegal(true)}>{translateObj(LEGAL_BASIS, language)}</div>
          <div className="bg-white p-4 text-black w-max uppercase text-center font-americaBold tracking-wide border-black border-2" onClick={() => setOpenPolicy(true)}>{translateObj(PRIVACY_POLICY, language)}</div>
        </div>
        {openModalLegal && (
        <div className="absolute inset-0 m-6 z-50">
          <div className="border-black border bg-white overflow-y-scroll" style={{ width: '90vw', height: '80vh' }}>
            <div className="w-full flex items-center justify-end p-4 text-16" onClick={() => setOpenLegal(false)}>X</div>
            <div className="p-4"><LegalBasis lang={language} /></div>
          </div>
        </div>
        )}
        {openModalPolicy && (
        <div className="absolute inset-0 m-6 z-50">
          <div className="border-black border bg-white overflow-y-scroll" style={{ width: '90vw', height: '80vh' }}>
            <div className="w-full flex items-center justify-end p-4 text-16" onClick={() => setOpenPolicy(false)}>X</div>
            <div className="p-4"><PrivacyPolicy lang={language} /></div>
          </div>
        </div>
        )}
      </div>
    </div>
  );
};

export default QRCodeResponse;
