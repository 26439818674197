/* eslint-disable camelcase */
import React, { useEffect, useState, useRef } from "react";
import { PropTypes } from "prop-types";
import FilePlayer from "react-player";

import TitleLineH1 from "./Text/TitleLineH1";
import LinkButtonRounded from "./Buttons/LinkButtonRounded";
import { useRoutes } from '../../context/routesContext';

import PSResponsiveUtility from "./LineUp/PSResponsiveUtility";

const NativeVideoPlayer = ({
  desktopUrl,
  mobileUrl,
  controls,
  autoPlay,
  ctaText,
  ctaUrl,
  headline
}) => {
  const [desktopUrlState, setDesktopUrl] = useState("");
  const [mobileUrlState, setMobileUrl] = useState("");
  const [urlState, setUrlState] = useState("");
  const [loading, setLoading] = useState(true);
  const [muted, setMuted] = useState(true);
  const responsiveUtility = useRef();
  const { language } = useRoutes();

  useEffect(() => {
    if (desktopUrl && !mobileUrl) {
      setMobileUrl(desktopUrl);
      setDesktopUrl(desktopUrl);
    } else if (mobileUrl && !desktopUrl) {
      setDesktopUrl(mobileUrl);
      setMobileUrl(mobileUrl);
    } else {
      setDesktopUrl(desktopUrl);
      setMobileUrl(mobileUrl);
    }
  }, []);

  useEffect(() => {
    if (responsiveUtility && responsiveUtility.current) {
      const deviceScreen = responsiveUtility.current.deviceScreen();
      const url = ['sm', 'md'].includes(deviceScreen) ? desktopUrlState || desktopUrl : mobileUrlState || mobileUrl;
      setUrlState(url);
      setLoading(false);
    }
  }, [responsiveUtility.current]);

  const hasText = !!(headline?.[language] || ctaText?.[language]);

  return (
    <>
      <PSResponsiveUtility ref={responsiveUtility} />
      <div className="full-width w-full bg-black relative">
        {!loading && (
          <>
            <div
              className={` pointer-events-none relative ${(hasText ? 'sliderComponent' : '')}
                ctaText) &&
                "sliderComponent"} h-screen80 `}
              style={{ paddingTop: '56.25%', height: 'auto', width: 'auto' }}
            >
              <FilePlayer
                url={urlState}
                // className="absolute pin-t pin-l-0"
                playing
                style={{ position: 'absolute', top: '0', left: '0' }}
                loop
                height="100%"
                width="100%"
                muted={muted}
                config={{
                  autoPlay,
                  controls,
                  youtube: {
                    playerVars: { controls: 0 },
                  },
                }}
              />
            </div>
            {(hasText) && (
              <div
                className=" h-full flex flex-col justify-end xl:px-36 xl:pb-36 md:px-26 md:pb-26 lg:px-26 lg:pb-26 sm:px-7 text-white pb-36 sm:pb-13 absolute pin-b pin-l sm:text-xl md:text-2xl lg:text-2rem text-4xl"
                style={{ zIndex: 2 }}
              >
                {headline && (
                  <TitleLineH1
                    text={headline}
                    textColor="#FFFFFF"
                    margin="mb-4 sm:mb-6"
                    slider
                  />
                )}
                {ctaText && ctaText.en && (
                  <LinkButtonRounded text={ctaText} url={ctaUrl} />
                )}
              </div>
            )}
            <div
              onClick={() => setMuted(!muted)}
              className="cursor-pointer flex justify-end xl:px-11 md:px-6 lg:px-8 sm:p-4 xl:pb-15 md:pb-10 lg:pb-12 text-white absolute pin-b pin-r sm:text-xl md:text-2xl lg:text-2rem text-4xl sm:pb-8"
              style={{ zIndex: 2 }}
            >
              <span
                className={`font-icon1 font-bold ${
                  muted ? "icon-volum_off" : "icon-volum_on"
                } `}
              />
            </div>
            {/* <div className="flex bg-radio text-black justify-end xl:px-36 md:px-26 lg:px-26 sm:px-7 text-white pb-36 sm:pb-13 absolute pin-t pin-r sm:text-xl md:text-2xl lg:text-2rem text-4xl" style={{ zIndex: 2 }}>COUNTER</div> */}
          </>
        )}
      </div>
    </>
  );
};

NativeVideoPlayer.propTypes = {
  desktopUrl: PropTypes.string.isRequired,
  mobileUrl: PropTypes.string.isRequired,
  ctaText: PropTypes.shape(),
  ctaUrl: PropTypes.string,
  headline: PropTypes.shape(),
  controls: PropTypes.bool,
  muted: PropTypes.bool,
  autoPlay: PropTypes.bool
};

NativeVideoPlayer.defaultProps = {
  controls: false,
  muted: true,
  autoPlay: true,
  ctaText: "",
  ctaUrl: "",
  headline: ""
};

export default NativeVideoPlayer;
