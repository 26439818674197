import { createPersistedQueryLink } from 'apollo-link-persisted-queries';
import { ApolloLink } from 'apollo-link';
import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { CachePersistor } from 'apollo-cache-persist';
import { setContext } from 'apollo-link-context';

import fetch from 'node-fetch';

const cache = new InMemoryCache();

// Use 'classic' graphql server if in browser or if GRAPHQL_SERVER_URL_FROM_SERVER is not defined
const graphqlServerUrl = (__isBrowser__ || !process.env.GRAPHQL_SERVER_URL_FROM_SERVER) ? process.env.GRAPHQL_SERVER_URL : process.env.GRAPHQL_SERVER_URL_FROM_SERVER;

// eslint-disable-next-line import/no-mutable-exports
let persistor;

const authLink = setContext((_, { headers }) => {
  if (typeof localStorage === 'undefined') return {};

  const token = localStorage.getItem(process.env.AUTH_TOKEN);
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

// NOTE: using POST to bypass CDN cache requesting as Preview from admins
let isFromPreview = false;

/* global __isBrowser__:true */
if (__isBrowser__) {
  const urlSearchParams = new URLSearchParams(window?.location.search);

  if (urlSearchParams?.entries) {
    const { isPreviewFromAdmin } = Object.fromEntries(urlSearchParams.entries());
    if (isPreviewFromAdmin) isFromPreview = true;
  }

  try {
    persistor = new CachePersistor({
      cache,
      storage: window.localStorage,
      trigger: false,
      debug: false,
    });

    /*
    After the initial SSR, we restore all the GraphQL query data which was previously fetched and persisted (stored in localStorage) back into the Apollo
    InMemoryCache. This enables the app to retrieve previously fetched GraphQL query data from memory cache, prevents a reset of the GraphQL query data
    in localStorage and allows for additional GraphQL query data to be added to the localStorage. The goal is to grow the GraphQL query data collection
    for the PWA, to be able to access as much of the app's content in offline mode as possible.
    */
    persistor.restore();
  } catch (error) {
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Error restoring Apollo cache: ${error}`);
  }
}
if (!__isBrowser__) {
  // console.log("TCL: process.env.GRAPHQL_SERVER_URL", process.env.GRAPHQL_SERVER_URL)
  console.log('graphqlServerUrl', graphqlServerUrl);
}

const httpLink = ApolloLink.from([
  // createPersistedQueryLink({ useGETForHashedQueries: true }),
  createHttpLink({
    uri: graphqlServerUrl,
    useGETForQueries: !isFromPreview,
    fetch,
    onError: ({ graphQLErrors, networkError }) => {
      if (graphQLErrors) graphQLErrors.map(({ message, locations, path }) => console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Apollo Client GraphQL Error: Message: ${message}, Location: ${locations}, Path: ${path}`));
      if (networkError) console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Network Error: ${networkError}`);
    },
  }),
]);

// const logLink = new ApolloLink((operation, forward) => {
//   console.log('forward', forward);
//   console.log('operation', operation);
//   console.time(operation.operationName);
//   return forward(operation).map((result) => {
//     console.timeEnd(operation.operationName);
//     return result;
//   });
// });

const clientWeb = new ApolloClient({
  // link: authLink.concat(logLink).concat(httpLink),
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    addTypename: false,
  }),
});

const clientRadio = new ApolloClient({
  link: createHttpLink({
    uri: process.env.RADIO_GRAPHQL_SERVER_URL,
    useGETForQueries: true,
    fetch,
    onError: ({ graphQLErrors, networkError }) => {
      if (graphQLErrors) graphQLErrors.map(({ message, locations, path }) => console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Apollo Radio Client GraphQL Error: Message: ${message}, Location: ${locations}, Path: ${path}`));
      if (networkError) console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Apollo Radio Client Network Error: ${networkError}`);
    },
  }),
  cache,
});

export {
  clientWeb,
  clientRadio,
  persistor,
};
