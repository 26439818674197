import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Notification from '@primaveralabs/ui-notification';
import LoadIcon from '../LoadIcon';
import 'Primavera/ui-themes/dist/primavera/index.css';
import imageModifier from '../helpers/imageModifier';
import { useBraze } from '../../../context/useBraze';
import 'Primavera/ui-notification/dist/primavera.css';
import translation from './translations/common';
import translateObj from '../../../util/translateObj';

const UserFavouritesPage = () => {
  const { braze } = useBraze();
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);

  const loadData = () => {
    if (!braze) return;
    setLoading(true);
    braze.requestContentCardsRefresh();
    braze.subscribeToContentCardsUpdates(({ cards }) => {
      setItems(cards);
    });
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div className="bg-panel-gray h-full">
      {loading ? (
        <div className="flex items-center justify-center h-full">
          <LoadIcon />
        </div>
      ) : (
        <div
          style={{ gap: 8 }}
          className="px-12 py-8 h-full"
        >
          {items && items?.length > 0 ? (
            <div className="flex flex-wrap" style={{ gap: 16 }}>
              {items.map((item) => (
                <div key={`${item.createdAt}-${item.title}`} className="sm:w-full md:w-full w-1/3">
                  <Notification
                    title={item?.title}
                    text={item?.description}
                    image={imageModifier(item?.imageUrl)}
                    cta={{ label: item?.linkText, link: item?.url }}
                  />
                </div>
              ))}
            </div>
          ) : (
            <div className="flex items-center justify-center text-white h-full font-americaRegular">
              {translateObj(translation.noNotifications)}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

UserFavouritesPage.propTypes = {};

export default UserFavouritesPage;
