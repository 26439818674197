import gql from 'graphql-tag';

import { clientWeb, persistor } from '../graphqlAPI';

const GET_TICKETS_BY_EVENT_SLUG_NAME = gql`
  query getTickets($eventSlugs: [String!]) {
    getTickets(eventSlugs: $eventSlugs) {
      _id
      description {
        es 
        en
        ca
        pt
      }
      status
      tags
      currency
      sellers {
        sellerTicketUrl
        sellerName
      }
      show
      showFee
      ticketName
      ticketReadableName {
        es 
        en
        ca
        pt
      }
      ticketSlugName
      createdAt
      updatedAt
      price
    }
  }
`;

const getTicketsByEventSlugName = async (slugs) => {
  try {
    const result = await clientWeb
      .query({
        query: GET_TICKETS_BY_EVENT_SLUG_NAME,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
        variables: { eventSlugs: slugs },
        fetchResults: true,
        pollInterva: 300000,
      })
      .then((queryResult) => queryResult)
      .catch((queryError) => {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error: ${queryError}`);
        return null;
      });
    const {
      data: { getTickets },
    } = result;

    if (getTickets) {
      return getTickets;
    }
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: GraphQL Query Error: getTickets`);
    return null;
  } catch (error) {
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: getTickets Error: ${error}`);
    return null;
  }
};

export default getTicketsByEventSlugName;
