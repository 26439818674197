import React from 'react';
import PropTypes from 'prop-types';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import translateObj from '../../../util/translateObj';


class ListItemBlock extends React.PureComponent {
  render() {
    const {
      language, loginTitle, loginTitleLink, open, handleClick,
    } = this.props;

    return (
      <ListItem
        style={{
          backgroundColor: 'white',
          color: 'black',
          padding: '2rem',
          marginTop: '1rem',
        }}
        value="press"
        button
        onClick={handleClick}
        disableRipple
        disableTouchRipple
      >
        <ListItemText>
          <div className="flex justify-between border-b-1 border-black border-dotted pb-6">
            <p className="font-americaMonoBold m-0">
              {translateObj(loginTitle, language)}
              {' '}
              { loginTitleLink
                && (
                <span style={{ cursor: 'pointer', color: 'red' }}>
                  {translateObj(loginTitleLink, language)}
                </span>
                )
              }
            </p>
            <span className={`font-icon text-14 ${open ? 'icon-arrow-up' : 'icon-arrow-down'}`} />
          </div>
        </ListItemText>
      </ListItem>
    );
  }
}

// ListItemBlock.propTypes = {
//   language: PropTypes.string,
//   loginTitle: PropTypes.shape({}),
//   loginTitleLink: PropTypes.shape({}),
//   open: PropTypes.bool(),
//   handleClick: PropTypes.func(),
// };

// ListItemBlock.defaultProps = {
//   language: 'en',
//   loginTitle: { en: 'Log in' },
//   loginTitleLink: { en: 'register' },
//   open: true,
//   handleClick: () => {},
// };

export default ListItemBlock;
