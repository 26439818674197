import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';
import translateObj from '../../util/translateObj';

const ARIA_LABEL_SEARCH = {
  en: 'Search',
  es: 'Búsqueda',
  ca: 'Cerca',
  pt: 'Pesquisa',
};

class InputTextButtonSimple extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: props.query || '',
    };
    this.input = React.createRef();
  }

  componentDidMount() {
    this.shouldFocus();
  }

  componentDidUpdate() {
    this.shouldFocus();
  }

  shouldFocus = () => {
    const { mobile } = this.props;
    if (!mobile) {
      this.input.current.focus();
    }
  }

  handleOnChange = (e, lang) => {
    const { handleOnChange, mobile } = this.props;
    this.setState({
      query: e.target.value,
    }, () => {
      if (handleOnChange && !mobile) {
        const { query } = this.state;
        handleOnChange(query, lang);
      }
    });
  }

  handleSearchClick = (lang) => {
    const { handleOnChange, mobile } = this.props;
    if (handleOnChange && mobile) {
      const { query } = this.state;
      handleOnChange(query, lang);
    }
  }

  handleClick = () => {
    const { handleClick } = this.props;
    const { query } = this.state;
    if (handleClick) {
      handleClick(query);
    }
  }

  render() {
    const {
      name,
      params: {
        searchIcon,
      },
      match,
      mobile,
    } = this.props;
    const { query } = this.state;

    return (
      <RoutesContext.Consumer>
        {
          ({ language: contextLanguage, enteredFrom }) => {
            const localization = getLocalization(contextLanguage, enteredFrom, match);

            return (
              <div data-name="sm:flex-1 input-text-button">
                <div className="relative w-full">
                  {searchIcon && (
                    <div
                      onClick={() => mobile && this.handleSearchClick(localization.language)}
                      className="absolute text-white h-full w-8 block flex items-center"
                      style={{ top: '50%', right: 0, transform: 'translateY(-50%)' }}
                      aria-label={translateObj(ARIA_LABEL_SEARCH, localization.language)}
                    >
                      <span style={{ top: -1 }} className={`${searchIcon}  ${query.length && 'font-bold cursor-pointer'}`} />
                    </div>
                  )}
                  <input
                    ref={this.input}
                    onKeyDown={(e) => {
                      if (mobile) {
                        if (e.key === 'Enter') this.handleSearchClick();
                      }
                    }}
                    value={query}
                    onChange={e => this.handleOnChange(e, localization.language)}
                    type="text"
                    name={name}
                    className="border-1 border-white outline-none w-full text-white bg-black bg-transparent leading-none font-timesRegular text-left text-base sm:text-xs rounded-full px-4 py-1 sm:h-12"
                    aria-label={translateObj(ARIA_LABEL_SEARCH, localization.language)}
                  />
                </div>
              </div>
            );
          }}
      </RoutesContext.Consumer>
    );
  }
}

InputTextButtonSimple.propTypes = {
  name: PropTypes.string.isRequired,
  match: PropTypes.shape().isRequired,
  // eslint-disable-next-line react/require-default-props
  query: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  handleClick: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  handleOnChange: PropTypes.func,
  // eslint-disable-next-line react/no-unused-prop-types
  params: PropTypes.shape().isRequired,
  mobile: PropTypes.bool,
};

InputTextButtonSimple.defaultProps = {
  mobile: false,
};

export default withRouter(InputTextButtonSimple);
