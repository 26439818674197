/* eslint-disable jsx-a11y/label-has-for */
import React, { Component } from 'react';
import { propTypes, withFormsy } from 'formsy-react';
import InfoPopover from './InfoPopover';

class FormInput extends Component {
  state = {
    value: '',
    isError: false,
  };

  componentDidMount() {
    const { isError = false } = this.props;
    this.setState({
      isError,
    });
  }


  componentDidUpdate = (prevProps, prevState) => {
    const { getValue, isError } = this.props;
    if (prevProps.isError !== isError) {
      this.setState({
        isError,
      });
    }
    if (prevProps !== this.props && getValue() !== undefined) {
      if (prevState.value !== getValue()) {
        this.setState({
          value: getValue(),
        });
      }
    }
  };

  changeValue = (e) => {
    this.setState({
      value: e.currentTarget.value,
    });
  };

  validate = (e) => {
    const { setValue } = this.props;
    setValue(e.currentTarget.value);
  };

  handleIsErrorClick = () => {
    const { isError } = this.state;
    if (isError) {
      setTimeout(() => {
        this.setState({
          isError: false,
        });
      }, 500);
    }
  };

  render() {
    const {
      name,
      type,
      title,
      showError,
      getErrorMessage,
      isValid,
      required,
      doNotChangeValidColor,
      // isError = false,
      titleClassName,
      className,
      hidden = false,
      disabled = false,
      infoText = '',
      backgroundColor = 'inherit',
    } = this.props;

    const { value, isError = false } = this.state;

    const errorMessage = getErrorMessage();


    return (
      <div onClick={this.handleIsErrorClick} className={`px-3 mb-4 sm:w-full ${className || 'w-1/2'} ${hidden && 'hidden'}`}>
        <label className="flex flex-col" htmlFor={name}>
          <span className={titleClassName}>
            {title}
            {required && <span className="text-12 align-top text-red-light">*</span>}
            {infoText && <div className="ml-4"><InfoPopover text={infoText} /></div>}
          </span>
          <input
            disabled={disabled}
            onChange={this.changeValue}
            onBlur={this.validate}
            name={name}
            type={type || 'text'}
            value={value}
            autoComplete={name}
            hidden={hidden}
            className={`mt-4 rounded-full border-1 p-4 bg-${backgroundColor} ${showError() || isError ? 'border-red text-red' : 'border-black text-black'} ${disabled && 'bg-gray200'} ${isValid() && value !== '' && !doNotChangeValidColor ? 'border-green text-green' : 'border-black text-black'}`}
            aria-label={title}
          />
        </label>
        <span className="flex flex-col text-red mt-4">{errorMessage}</span>
      </div>
    );
  }
}

FormInput.propTypes = {
  ...propTypes,
};

export default withFormsy(FormInput);
