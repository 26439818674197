import React from 'react';
import { PropTypes } from 'prop-types';

// const { isFavoritesActive } = process.env;
const isFavoritesActive = true;
class FavouriteStar extends React.Component {
  state = {
    favourite: this.props.favourite,
  };

  toggleFavourite = () => {
    const { favourite } = this.state;
    this.setState({ favourite: !favourite });
  };

  render() {
    // FRAGMENT we dont have the filled star, for now, active means color bg
    if (!isFavoritesActive) return (<span />);
    const { favourite } = this.state;
    return (
      <div onClick={this.toggleFavourite} className={`${favourite ? 'text-barcelona' : ''}`}>
        {favourite ? <span className="font-icon icon-star-solid cursor-pointer" /> : <span className="font-icon icon-star cursor-pointer" />}
      </div>
    );
  }
}

FavouriteStar.propTypes = {
  favourite: PropTypes.bool,
};

FavouriteStar.defaultProps = {
  favourite: false,
};

export default FavouriteStar;
