import React, { createContext, useContext, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { useUser } from './userProfileContext';

const BrazeContext = createContext();

export const useBraze = () => useContext(BrazeContext);

// NOTE: Initializing braze this way due to npm package @braze/web-sdk not working, probale because of our node version
const createBrazeScript = () => {
  if (!window.braze) {
    window.braze = {};
    window.brazeQueue = [];

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://js.appboycdn.com/web-sdk/5.1/braze.min.js';

    const firstScript = document.getElementsByTagName('script')[0];
    firstScript.parentNode.insertBefore(script, firstScript);
  }
};

const BrazeProvider = ({ children }) => {
  const [braze, setBraze] = useState();
  const [numberOfContentCards, setNumberOfContentCards] = useState(0);
  const { userEmail } = useUser();

  useEffect(() => {
    // NOTE: this check if gtag manager is disabled ?no-cookies=true, if so then just return
    if (!window.dataLayer) return;
    createBrazeScript();
    // NOTE: only initialize Braze if users gives mkt consent
    window.addEventListener('CookiebotOnAccept', () => {
      // Execute code that sets marketing cookies
      window.braze?.initialize(process.env.BRAZE_API_KEY, {
        baseUrl: process.env.BRAZE_ENDPOINT,
        allowUserSuppliedJavascript: true,
      });
      setBraze(window?.braze);
    }, false);

    window.addEventListener('CookiebotOnDecline', () => {
      window.braze?.initialize(process.env.BRAZE_API_KEY, {
        baseUrl: process.env.BRAZE_ENDPOINT,
        allowUserSuppliedJavascript: true,
        noCookies: true,
      });
      setBraze(window.braze);
    }, false);
  }, []);

  useEffect(() => {
    // When a user first uses Braze on a device they are considered "anonymous". This method to identifies a user with the email
    if (userEmail && braze) braze.changeUser(userEmail);
  }, [userEmail, braze]);

  useEffect(() => {
    // NOTE: hidding on mobile until we decide how to show it there
    if (!braze) return;
    braze.requestContentCardsRefresh(
      braze.subscribeToContentCardsUpdates(({ cards }) => {
        if (cards?.length) setNumberOfContentCards(cards.length);
      }),
    );
    braze.openSession();
  }, [braze]);

  const logCustomAttributes = useCallback((key, value) => {
    // NOTE: sending null as value, removes the attribute from user
    if (!braze || !key) return;
    braze.getUser().setCustomUserAttribute(key, value);
    braze.requestImmediateDataFlush();
  }, [braze]);

  const logCustomEvent = useCallback((eventName, property) => {
    if (!braze) return;
    // NOTE: property is used to create an object
    if (property) braze.logCustomEvent(eventName, { [property]: true });
    else braze.logCustomEvent(eventName);
    braze.requestImmediateDataFlush();
  }, [braze]);

  const value = {
    // @deprecated
    appboy: braze,
    braze,
    numberOfContentCards,
    logCustomEvent,
    logCustomAttributes,
  };

  return (
    <BrazeContext.Provider value={value}>
      {children}
    </BrazeContext.Provider>
  );
};

BrazeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BrazeProvider;
