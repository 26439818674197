import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Panel from '@primaveralabs/ui-panel';
import Tag from '@primaveralabs/ui-tag';
import 'Primavera/ui-panel/dist/primavera.css';
import 'Primavera/ui-tag/dist/primavera.css';
import 'Primavera/ui-themes/dist/primavera/index.css';
import translateObj from '../../../util/translateObj';
import { useRoutes } from '../../../context/routesContext';
import translation from './translations/common';

const PreferencesBoard = ({
  gridAreaName,
  title,
  previousSelection,
  selected,
  allItems,
  noItems,
  onChange,
  saveCallback,
}) => {
  const { language } = useRoutes();

  const [isHover, setIsHover] = useState(undefined);

  const selectionHasChanged = selected.filter((item) => !previousSelection.includes(item)).length > 0
  || previousSelection.filter((item) => !selected.includes(item)).length > 0;

  const handleTagClick = (value) => {
    let currentSelection = [...selected];

    if (currentSelection.includes(value)) {
      const newSelection = currentSelection.filter((v) => v !== value);
      currentSelection = newSelection;
    } else {
      currentSelection.push(value);
    }
    onChange(currentSelection);
  };

  return (
    <div style={{ gridArea: gridAreaName }}>
      <Panel
        display="wrap"
        title={translateObj(title, language)}
        noChildrenMessage={translateObj(noItems, language)}
        scroll
      >
        {allItems?.length > 0
          && allItems.map((item) => (
            <button
              key={item.value}
              type="button"
              aria-label={translateObj(item.label, language)}
              onMouseEnter={() => setIsHover(item.value)}
              onMouseLeave={() => setIsHover(undefined)}
              onClick={() => handleTagClick(item.value)}
            >
              <Tag
                title={translateObj(item.label, language).toUpperCase()}
                textColor={isHover === item.value ? `${selected.includes(item.value) ? '#F04061' : '#ffffff'}` : `${selected.includes(item.value) ? '#ffffff' : '#F04061'}`}
                color={isHover === item.value ? `${selected.includes(item.value) ? '#ffffff' : '#F04061'}` : `${selected.includes(item.value) ? '#F04061' : '#ffffff'}`}
                wide
              />
            </button>
          ))}
        {selectionHasChanged && (
        <button type="button" onClick={() => saveCallback()}>
          <Tag
            title={translateObj(translation.save).toUpperCase()}
            textColor="#ffffff"
            color="#7DD6AD"
            wide
          />
        </button>
        )}
      </Panel>
    </div>
  );
};

PreferencesBoard.propTypes = {
  gridAreaName: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.shape({}).isRequired,
  previousSelection: PropTypes.arrayOf(PropTypes.string),
  selected: PropTypes.arrayOf(PropTypes.string),
  allItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  noItems: PropTypes.shape({}).isRequired,
  saveCallback: PropTypes.func.isRequired,
};

PreferencesBoard.defaultProps = {
  previousSelection: [],
  selected: [],
};

export default PreferencesBoard;
