import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

import { useRoutes } from '../../../context/routesContext';
import translateObj from '../../../util/translateObj';

import useUsersList from './util/hooks/useUsersList';
import useDebounce from '../../../util/useDebounce';

import ReunionFormParticipantItem from './ReunionFormParticipantItem';
import CollapsibleSection from './CollapsibleSection';
import FiltersRadioGroup from './FiltersRadioGroup';

const ReunionFormParticipantsSelector = ({
  literals,
  errors,
  currentParticipants,
  inviteParticipant,
}) => {
  const { setFieldValue } = useFormikContext();
  const [filterByFavorites, setFilterByFavorites] = useState(false);
  const [qty, setQty] = useState(50);
  const [inputValue, setInputValue] = useState('');
  const debouncedInputValue = useDebounce(inputValue, 500);
  const { language } = useRoutes();
  const { usersList, hasMoreItems } = useUsersList({
    query: debouncedInputValue,
    to: qty,
    onlyFriends: filterByFavorites,
  });
  const hasError = errors.participants;
  const [selectedParticipants, setSelectedParticipants] = useState([]);

  const handleCheck = (item) => {
    const checkedItems = [...selectedParticipants];
    const alreadyChecked = checkedItems.find(member => member._id === item._id);
    if (alreadyChecked) {
      const notChecked = checkedItems.filter(i => i._id !== item._id);
      setSelectedParticipants(notChecked);

      const notCheckedIds = notChecked.map(i => i._id);
      setFieldValue('participants', notCheckedIds, false);
    } else {
      checkedItems.push(item);
      setSelectedParticipants(checkedItems);

      const checkedItemsIds = checkedItems.map(i => i._id);
      setFieldValue('participants', checkedItemsIds, false);
    }
  };

  useEffect(() => {
    if (qty > 50) {
      setQty(50);
    }
  }, [debouncedInputValue, filterByFavorites]);

  useEffect(() => {
    if (currentParticipants?.length > 0) {
      setSelectedParticipants(currentParticipants);
    } else if (inviteParticipant?.length > 0) {
      setSelectedParticipants(inviteParticipant);
    }
  }, [currentParticipants, inviteParticipant]);

  return (
    <>
      {selectedParticipants.length > 0 && (
        <CollapsibleSection
          title={literals.titleParticipants}
          collapsible={false}
          paddingBottom="pb-2"
        >
          {selectedParticipants.map(item => (
            <div key={item._id}>
              <ReunionFormParticipantItem
                onClickCallback={() => handleCheck(item)}
                selected
                {...item}
                disabled={currentParticipants?.find(p => p._id === item._id)}
              />
            </div>
          ))}
        </CollapsibleSection>
      )}
      <CollapsibleSection
        title={selectedParticipants.length > 0 ? literals.titleAddOther : literals.titleAdd}
        collapsible={false}
      >
        <div className="w-full">
          <FiltersRadioGroup
            options={[
              {
                name: translateObj(literals.filtersAll, language),
                value: 'all',
                defaultValue: true,
              },
              {
                name: translateObj(literals.filtersFavorites, language),
                value: 'favorites',
              },
            ]}
            getValueCallback={value => (value === 'favorites' ? setFilterByFavorites(true) : setFilterByFavorites(false))}
          />
          <div className={`font-americaMonoRegular uppercase text-12 mb-4 ${hasError ? 'text-pro-online' : 'text-black'}`}>
            {translateObj(literals.labelAdd, language)}
          </div>
          <div className={`rounded-lg p-3 border ${hasError ? 'border-pro-online' : 'border-black'} w-full`}>
            <input
              type="text"
              name="text"
              className="focus:outline-none bg-transparent w-full font-americaMonoRegular text-12"
              onChange={event => setInputValue(event.target.value)}
            />
          </div>
          <div className="overflow-y-auto overflow-x-hidden rounded-lg max-h-375px sm:max-h-260px">
            {usersList.length > 0 ? (
              <>
                {usersList.map(item => (
                  <ReunionFormParticipantItem
                    key={item._id}
                    onClickCallback={() => handleCheck(item)}
                    checked={selectedParticipants.find(p => p._id === item._id)}
                    {...item}
                    disabled={currentParticipants?.find(p => p._id === item._id)}
                  />
                ))}
                {hasMoreItems && <div onClick={() => setQty(qty + 20)} className="p-4 bg-grayE2DDDB cursor-pointer font-americaMonoBold uppercase text-12">{translateObj(literals.showMore, language)}</div>}
              </>
            ) : (
              <div className="w-full flex items-center justify-center p-10">
                <span className="font-tiemposRegular text-12">{translateObj(literals.noItemsFoundMessage, language)}</span>
              </div>
            )
            }
          </div>
        </div>
      </CollapsibleSection>
    </>
  );
};

ReunionFormParticipantsSelector.propTypes = {
  literals: PropTypes.shape(),
  errors: PropTypes.shape().isRequired,
  currentParticipants: PropTypes.arrayOf(PropTypes.shape()),
  inviteParticipant: PropTypes.arrayOf(PropTypes.shape()),
};

ReunionFormParticipantsSelector.defaultProps = {
  literals: {},
  currentParticipants: [],
  inviteParticipant: [],
};

export default ReunionFormParticipantsSelector;
