import header from './_header';
import footer from './_footer';
import members from './_memberItem_array';

const data = [
  { ...header },
  {
    type: 'MembersList',
    components: members,
  },
  { ...footer },
];

export default data;
