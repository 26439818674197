import gql from 'graphql-tag';

import { clientWeb } from '../graphqlAPI';

const CREATE_REUNION = gql`
  mutation Post($date: String!, $location: String!, $title: String!, $online: Boolean, $description: String, $participants: [String]) {
    createReunion(
      date: $date,
      location: $location,
      title: $title,
      online: $online,
      description: $description,
      participants: $participants
      ){
        _id
    }
}`;

const createReunion = async (formData) => {
  try {
    const result = await clientWeb.mutate({
      mutation: CREATE_REUNION,
      variables: {
        ...formData,
      },
      fetchPolicy: 'no-cache',
    })
      .then(queryResult => queryResult)
      .catch((queryError) => {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error: ${queryError}`);
        return null;
      });

    const { data: { createReunion: validatedFormData } } = result;

    if (validatedFormData) {
      return validatedFormData;
    }
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: GraphQL Query Error: validatedFormData = null`);
    return null;
  } catch (error) {
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: createReunion Error: ${error}`);
    return null;
  }
};

export default createReunion;
