/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useAuth } from '@primaveralabs/auth';
import { useRoutes } from '../../../context/routesContext';

import translateObj from '../../../util/translateObj';
import getTranslation from './util/translations/_getTranslation';
import localTranslation from './util/translations/Agenda';

import getMyAgenda from '../../../api/graphql/queries/getMyAgenda';

import HeadTitle from './HeadTitle';
import AgendaSection from './AgendaSection';
import MentoringSessionItem from './MentoringSessionItem';
import ReunionItem from './ReunionItem';
import NotificationItem from './NotificationItem';
import Button from './Button';
import { HeaderContext } from '../../../context/headerContext';
import { CURRENT_EDITION } from './Header/HeaderMusicMeets';
import NormalBodyText from '../Text/NormalBodyText'

const Agenda = ({ translation }) => {
  const { language } = useRoutes();
  const { user, token } = useAuth();

  const literals = getTranslation(translation, localTranslation);

  const headTitle = translateObj(literals.title, language).replace('{userName}', user?.name);

  const sessionsPerPage = 4;
  const reunionsPerPage = 4;
  const notificationsPerPage = 10;

  const [loading, setLoading] = useState(true);
  const [userSessions, setUserSessions] = useState([]);
  const [userReunions, setUserReunions] = useState([]);
  const [userNotifications, setUserNotifications] = useState([]);
  const [sessionsPage, setSessionsPage] = useState(0);
  const [reunionsPage, setReunionsPage] = useState(0);
  const [notificationsPage, setNotificationsPage] = useState(0);
  const [moreSessions, setMoreSessions] = useState(true);
  const [moreReunions, setMoreReunions] = useState(true);
  const [moreNotifications, setMoreNotifications] = useState(true);
  const [unread, setUnread] = useState(0);

  const loadAgenda = () => {
    setLoading(true);
    getMyAgenda(
      reunionsPage,
      notificationsPage,
      sessionsPage,
      true,
    ).then((res) => {
      if (res) {
        const { unreadNotifications, sessions, reunions, notifications } = res;
        if (unreadNotifications) {
          setUnread(unreadNotifications);
        }
        if (sessions?.length < (sessionsPerPage * sessionsPage)) {
          setMoreSessions(false);
        }
        if (reunions?.length < (reunionsPerPage * reunionsPage)) {
          setMoreReunions(false);
        }
        if (notifications?.length < (notificationsPerPage * notificationsPage)) {
          setMoreNotifications(false);
        }

        const newNotifications = notifications?.filter((n) => !userNotifications.some((i) => i._id === n._id));
        const notificationSenderConfirmation = newNotifications.filter((n) => n.sender !== null);

        const newUserReunions = [...userReunions];

        reunions?.forEach((reunion) => {
          const index = newUserReunions.findIndex((item) => item._id === reunion._id);
          if (index !== -1) {
            newUserReunions[index] = reunion;
          } else {
            newUserReunions.push(reunion);
          }
        });

        setUserReunions(newUserReunions);
        setUserSessions(sessions);
        setUserNotifications([...userNotifications, ...notificationSenderConfirmation]);
        setLoading(false);
      }
    }).catch((err) => {
      setLoading(false);
      console.log(err);
    });
  };

  useEffect(() => {
    loadAgenda();
  }, [sessionsPage, reunionsPage, notificationsPage]);

  return (
    <>
      {user && token ? (
        <>
          {user.roles?.includes(CURRENT_EDITION) ? (
            <>
              <HeadTitle title={headTitle} />
              <div className="w-full flex items-stretch justify-between sm:flex-col-reverse sm:justify-start sm:pb-12 md:flex-col-reverse md:justify-start md:pb-12 mdlg:flex-col-reverse mdlg:justify-start mdlg:pb-12">
                <AgendaSection
                  sectionType="sessions"
                  title={literals.titleMentoringSessions}
                  buttonLoadMore={literals.buttonLoadMore}
                  loadMoreCallback={() => setSessionsPage(sessionsPage + 1)}
                  showLoadMore={(userSessions.length >= sessionsPerPage) && moreSessions}
                  loading={loading}
                  loaderClassName="sm:mb-6 md:mb-6 mdlg:mb-6"
                >
                  {userSessions.length === 0
                    ? (
                      <div className="pb-6 pt-6 sm:pt-0 md:pt-0 mdlg:pt-0 font-americaBlack text-20 w-1/2">
                        {translateObj(literals.noItemsMentoringSessions, language)}
                      </div>
                    )
                    : userSessions?.map((session) => <MentoringSessionItem key={session.eventSlugName} {...session} translation={translation} isListItem={false} callback={() => loadAgenda()} />)}
                </AgendaSection>
                <AgendaSection
                  sectionType="reunions"
                  title={literals.titleReunions}
                  buttonNewReunion={literals.buttonNewReunion}
                  buttonLoadMore={literals.buttonLoadMore}
                  loadMoreCallback={() => setReunionsPage(reunionsPage + 1)}
                  showLoadMore={(userReunions.length >= reunionsPerPage) && moreReunions}
                  loading={loading}
                  childrenContianerClassName="md:flex md:flex-wrap mdlg:flex mdlg:flex-wrap"
                  loaderClassName="sm:mb-6 md:mb-6 mdlg:mb-6"
                >
                  {userReunions.length === 0 ? (
                    <div className="pt-6 sm:pt-0 md:pt-0 mdlg:pt-0">
                      <div className="mb-8 font-americaBlack text-20">{translateObj(literals.noItemsReunions, language)}</div>
                    </div>
                  ) : userReunions?.map((reunion, index) => <ReunionItem key={reunion._id} {...reunion} callback={() => loadAgenda()} isAgendaItem index={index} />)}
                </AgendaSection>
                <AgendaSection
                  sectionType="notifications"
                  title={literals.titleNotifications}
                  buttonLoadMore={literals.buttonLoadMore}
                  pendingNotifications={unread}
                  loadMoreCallback={() => setNotificationsPage(notificationsPage + 1)}
                  showLoadMore={(userNotifications.length >= notificationsPerPage) && moreNotifications}
                  loading={loading}
                  loaderClassName="sm:mt-6 md:mt-6 mdlg:mt-6"
                >
                  {userNotifications.length === 0
                    ? <div className="text-black font-tiemposRegular text-base pb-6 sm:pt-4 md:pt-4 mdlg:pt-4">{translateObj(literals.noItemsNotifications, language)}</div>
                    : userNotifications?.map((notification) => <NotificationItem key={notification._id} {...notification} />)}
                </AgendaSection>
              </div>
            </>
          ) : (
            <div className="w-full flex flex-col items-center justify-center py-24">
              <p className="text-16 font-tiemposRegular w-1/2 mb-8 text-center">
                {translateObj(literals.noAccessMessage, language)}
              </p>
              <Button
                text={translateObj(literals.purchaseConferencePass, language)}
                href="https://dice.fm/event/ne9w9-primavera-pro-2023-31st-may-cccb-parc-del-frum-barcelona-tickets"
                color="black"
                className="w-auto h-auto mb-4"
                target="_blank"
              />
              <Button
                text={translateObj(literals.backHome, language)}
                href={`https://www.primaverasound.com/${language}/primavera-pro`}
              />
            </div>
          )}
        </>
      ) : <LoginButton translation={translation} />}
    </>
  );
};

export const LoginButton = ({ translation }) => {
  const { setAuthOpen } = useContext(HeaderContext);
  const { language } = useRoutes();

  const literals = getTranslation(translation, localTranslation);

  return (
    <>
      <div className="w-full flex flex-col items-center justify-center py-24">
        <NormalBodyText classes="font-americaRegular text-xl sm:text-lg tracking-tight" text={literals.textLogin} />
        <div className="mt-16">
          <Button
            text={translateObj(literals.buttonLogin, language)}
            headerButton
            color="black"
            icon="font-icon1 icon-userlogin"
            onClickCallback={() => setAuthOpen('login')}
          />
        </div>
      </div>
    </>
  );
};

LoginButton.propTypes = {
  translation: PropTypes.shape(),
};

LoginButton.defaultProps = {
  translation: null,
};

Agenda.propTypes = {
  translation: PropTypes.shape(),
};

Agenda.defaultProps = {
  translation: null,
};

export default Agenda;
