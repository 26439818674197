import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useRoutes } from '../../../context/routesContext';
import useResponsiveUtility from '../../../util/responsiveUtility';
import translateObj from '../../../util/translateObj';
import getSubEventsBySlugName from '../../../api/graphql/queries/getSubEventsBySlugName';

import CtaUnderline from './CtaUnderline';
import MentoringSessionItem from './MentoringSessionItem';

const getColumnsTemplate = (deviceWidth) => {
  if (deviceWidth >= 640 && deviceWidth < 1070) {
    return ('repeat(auto-fill, minmax(300px, 1fr))');
  } if (deviceWidth >= 1070 && deviceWidth < 1400) {
    return ('repeat(auto-fill, minmax(330px, 1fr))');
  }
  return ('repeat(auto-fill, minmax(440px, 1fr))');
};

const RelatedSessions = ({
  title,
  loadMore,
  eventSlugName,
  translation,
  sessionPageSlugname,
}) => {
  const { language } = useRoutes();
  const { deviceScreen, deviceWidth } = useResponsiveUtility();
  const [columnsTemplate, setColumnsTemplate] = useState();
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const [items, setItems] = useState([]);
  const [qty, setQty] = useState(20);

  const getSessions = () => {
    const params = {
      eventSlugName,
      to: qty,
    };
    getSubEventsBySlugName(params).then((res) => {
      if (res) {
        if (res.length < qty) {
          setHasMoreItems(false);
        } else {
          if (!hasMoreItems) {
            setHasMoreItems(true);
          }
        }
        const response = res?.length > 0 ? res.map((i) => Object.assign(i, { isListItem: true })) : res;
        setItems(response);
      }
    }).catch((err) => {
      console.log(err);
    });
  };

  const getRandomItems = (array, numItems) => {
    const randomItems = [];
    for (let i = 0; i < numItems; i++) {
      const randomIndex = Math.floor(Math.random() * array.length);
      const item = array.splice(randomIndex, 1)[0];
      randomItems.push(item);
    }
    return randomItems;
  };

  const threeRandomItems = deviceScreen === 'xxl' ? getRandomItems(items, 4) : getRandomItems(items, 3);

  useEffect(() => {
    if (qty > 30 && !hasMoreItems) {
      setQty(30);
    }
    getSessions();
  }, [qty]);

  useEffect(() => {
    const columns = getColumnsTemplate(deviceWidth);
    setColumnsTemplate(columns);
  }, [deviceWidth]);

  return (
    <div className="w-full pt-12 sm:pt-8 pb-32 sm:pb-12">
      <div className="w-full md:w-full font-americaMonoRegular text-30 text-black sm:text-base uppercase pb-3 border-b border-black">
        {translateObj(title, language)}
      </div>
      <div
        className="flex flex-wrap sm:flex-no-wrap sm:flex-col mt-12"
        style={{
          display: deviceScreen === 'sm' ? 'flex' : 'grid',
          gridTemplateColumns: columnsTemplate,
          gridColumnGap: 20,
          gridRowGap: deviceScreen === 'sm' ? 10 : 32,
        }}
      >
        {threeRandomItems.map((session) => {
          if (session !== undefined) {
            return (
              <MentoringSessionItem
                sessionPageSlugname={sessionPageSlugname}
                translation={translation}
                key={session.eventSlugName}
                {...session}
              />
            );
          }
        })}
      </div>
      <div className="sm:block hidden">
        <CtaUnderline text={translateObj(loadMore, language)} />
      </div>
    </div>
  );
};

RelatedSessions.propTypes = {
  title: PropTypes.shape(),
  loadMore: PropTypes.shape(),
  eventSlugName: PropTypes.string,
  sessionPageSlugname: PropTypes.string,
  translation: PropTypes.shape(),
};

RelatedSessions.defaultProps = {
  translation: null,
  title: {},
  loadMore: {},
  eventSlugName: '',
  sessionPageSlugname: 'pro-mentoring-sessions-detail',
};

export default RelatedSessions;
