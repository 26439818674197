/* eslint-disable camelcase */
import React, { useRef } from 'react';
import { PropTypes } from 'prop-types';
import FilePlayer from 'react-player';

const StreamingVideoPlayerSmall = ({
  handleSwitchingVideos,
  mainVideo,
  videoObject,
  shouldSmallVideoStart,
  handleOnReadyTrueForAll,
  isLoading,
  // componentShouldGetQuality,
  // getQualityFn,
}) => {
  const { title = 'main room', url } = videoObject;
  const playerRef = useRef(null);
  // const smallDisplayHeight = { height: '7.5rem' };

  // ! for testing remove
  // const getQualityInComponent = () => {
  //   const dash = playerRef.current.getInternalPlayer('dash');
  //   const quality = dash.getQualityFor('video');
  //   getQualityFn(quality, url);
  // };
  // useEffect(() => {
  //   if (componentShouldGetQuality) {
  //     getQualityInComponent();
  //   }
  // }, [componentShouldGetQuality]);
  // !--------------------

  // ? validate if onReady method works in safari  and if a different alternative should be considered
  const onReady = () => {
    const dash = playerRef.current.getInternalPlayer('dash');
    dash.setInitialBitrateFor('video', 0);
    dash.setQualityFor('video', 0);
    dash.setAutoSwitchQualityFor('video', false);
    if (isLoading) {
      handleOnReadyTrueForAll();
    }
  };

  // useEffect(() => {
  //   const dash = playerRef.current.getInternalPlayer('dash');
  //   if (!active) {
  //     if (dash) {
  //       dash.setQualityFor('video', 0);
  //       dash.setAutoSwitchQualityFor('video', false);
  //     }
  //   } else {
  //     if (dash) dash.setAutoSwitchQualityFor('video', true);
  //   }
  // }, [active]);

  const active = mainVideo === url;

  return (
    <div className="mx-2">
      <div
        className={`${active ? 'border-psworld-orange' : 'border-psworld-box'} border-4 cursor-pointer w-psworld-box`}
        // style={smallDisplayHeight}
        onClick={() => {
          if (!active) handleSwitchingVideos(url);
        }}
      >
        <FilePlayer
          style={{ display: 'flex' }}
          ref={playerRef}
          url={url}
          controls={false}
          onReady={onReady}
          playing={shouldSmallVideoStart}
          height="100%"
          width="100%"
          muted
          config={{
            file: {
              forceDASH: true,
            },
          }}
        />
      </div>
      <h3 className="mt-3 font-americaMonoRegular text-xs uppercase">{title}</h3>
    </div>
  );
};

StreamingVideoPlayerSmall.propTypes = {
  handleSwitchingVideos: PropTypes.func.isRequired,
  handleOnReadyTrueForAll: PropTypes.func.isRequired,
  mainVideo: PropTypes.string.isRequired,
  shouldSmallVideoStart: PropTypes.bool,
  isLoading: PropTypes.bool,
  videoObject: PropTypes.shape().isRequired,
};

StreamingVideoPlayerSmall.defaultProps = {
  shouldSmallVideoStart: false,
  isLoading: true,

};

export default StreamingVideoPlayerSmall;
