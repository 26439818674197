import header from './_header';
import footer from './_footer';

const data = [
  { ...header },
  {
    type: 'ProfileForm',
  },
  { ...footer },
];

export default data;
