// @ts-check

import React from 'react';
import PropTypes from 'prop-types';

import useQueryString from '../../../hooks/useQueryString';

const FooterRedesign = ({ children }) => {
  const hideFooter = useQueryString('showBodyOnly');

  if (hideFooter) return null;

  return (
    <footer className="flex flex-col llg:flex-row w-full z-10" id="footer-main">
      {children}
    </footer>
  );
};

FooterRedesign.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FooterRedesign;
