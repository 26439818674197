import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Poster from './Poster';

import getPostsBySlugNameQuery from '../../api/graphql/queries/getPostsBySlugNameQuery';

class PosterRow extends PureComponent {
  state = {
    linksToShow: [],
    isLoading: true,
  };

  componentDidMount = () => {
    const { slugnames } = this.props;
    this.fetchPostDrescriptionsBySlugnames(slugnames);
  };

  fetchPostDrescriptionsBySlugnames = (slugnames) => {
    getPostsBySlugNameQuery(slugnames)
      .then((response) => {
        if (response) {
          this.setState({
            linksToShow: response,
            isLoading: false,
          });
        } else {
          console.log('PosterRow no active posts were returned');
        }
      })
      .catch((err) => {
        console.log('Some error happened on fetch of fetchPostDescription:', err);
      });
  };

  _insertElements = () => {
    const { linksToShow } = this.state;
    const { slugnames } = this.props;
    const mediaComponents = [];
    const {
      match: { params },
    } = this.props;
    if (linksToShow && linksToShow.length > 0) {
      let width = '1/3';
      if (linksToShow.length !== 3) width = '1/2';
      linksToShow.map((obj, index) => {
        const { postDescription } = obj;
        const { image, title, url } = postDescription || { image: { en: 'https://assets.primaverasound.com/web/assets/default_image_placeholder_light_grey.png', title: { en: '' } } };
        const validatedUrl = url.includes('http') ? url : url[0] === '/' ? url : `/${url}`;
        mediaComponents.push(<Poster width={width} key={`slugName_${index}`} image={image} text={title} slug={validatedUrl} />);
      });
    } else {
      // This is for when normally is loading the elements or when something nasty happend on fetch
      // TODO : create some fade animations
      // TODO : Replace by a default image grey image
      slugnames.map((obj, i) => {
        mediaComponents.push(
          <Poster
            key={obj}
            image={{ en: 'https://assets.primaverasound.com/web/assets/default_image_placeholder_light_grey.png' }} // TO-DO : This needs to be an Language Object
            text={{ en: '' }}
            slug={params.section === 'editions' ? `${params.section}/${slugnames[i]}` : slugnames[i]}
          />,
        );
      });
    }
    return mediaComponents;
  };

  render() {
    const { column, color } = this.props;
    const { isLoading } = this.state;

    return (
      <div className="w-full">
        <div className="flex justify-center flex-wrap -mx-2">{!isLoading && this._insertElements()}</div>
      </div>
    );
  }
}

PosterRow.propTypes = {
  slugnames: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string])),
  column: PropTypes.bool,
  color: PropTypes.string,
};

PosterRow.defaultProps = {
  slugnames: ['home'],
  column: false,
  color: '',
};

export default withRouter(PosterRow);
