import * as countries from 'i18n-iso-countries';

countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
countries.registerLocale(require('i18n-iso-countries/langs/es.json'));
countries.registerLocale(require('i18n-iso-countries/langs/ca.json'));
countries.registerLocale(require('i18n-iso-countries/langs/pt.json'));

const acceptedLanguages = ['en', 'es', 'ca', 'pt'];
const validateAcceptedLanguage = (lang) => {
  if (acceptedLanguages.includes(lang)) {
    return lang;
  }
  return 'en';
};

const getCountryNamesByCode = (code) => Object.values(countries.getNames(code)).sort();

export const getCodeFromCountry = (countryName = 'Spain', lang = 'en') => {
  const language = validateAcceptedLanguage(lang);
  return countries.getAlpha2Code(countryName, language) || 'ES';
};
export const getCountryFromCode = (countryCode = 'ES', lang = 'en') => {
  const language = validateAcceptedLanguage(lang);
  return countries.getName(countryCode, language) || 'Spain';
};

export default getCountryNamesByCode;
