/* eslint-disable no-nested-ternary */
import React from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import ListItem from '@material-ui/core/ListItem';
import Collapse from '@material-ui/core/Collapse';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import ParagraphLineSM from '../Text/ParagraphLineSM';

import getLocalization from '../../../util/localization';
import RoutesContext from '../../../context/routesContext';
import translateObj from '../../../util/translateObj';
import ComponentIterator from '../../../containers/ComponentIterator';

const debounce = (func, delay) => {
  let inDebounce;
  return (...args) => {
    const context = this;
    clearTimeout(inDebounce);
    inDebounce = setTimeout(() => func.apply(context, args), delay);
  };
};

const styles = theme => ({
  root: {
    width: '100%',
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 0,
    overflow: 'visible',
  },
  list: {
    padding: 0,
    marginBottom: 0,
    marginTop: '8px',
  },
  listNoMargin: {
    padding: 0,
    marginBottom: 0,
  },
});

const ExpandLess = () => <span className="font-icon icon-arrow-up text-white text-sm" />;
const ExpandMore = () => <span className="font-icon icon-arrow-down text-white text-sm font-black" />;
class FilterNew extends React.Component {
  state = {
    open: true,
    checkedItems: [],
  }

  componentDidMount() {
    const { checkedItem } = this.props;
    if (checkedItem) {
      this.onHandleChange(checkedItem);
    }
  }

  componentDidUpdate(prevProps) {
    const { checkedItem } = this.props;
    if (checkedItem !== prevProps.checkedItem) {
      return this.setState({ checkedItems: [checkedItem] });
    }
    return false;
  }

  onHandleChange = (value) => {
    const { onHandleCollapsibleMenuSelect, radio } = this.props;
    onHandleCollapsibleMenuSelect(value);
    return this.setState({ checkedItems: [value] });
  }

  updateWithCheckedIems = (components) => {
    const { checkedItems } = this.state;
    const compsWithCheched = components.map((comp) => {
      const c = { ...comp };
      c.checkedItems = checkedItems;
      c.onHandleButtonSelect = this.onHandleChange;
      return c;
    });
    return compsWithCheched;
  }

  render() {
    const {
      match, filterHeading, components, noCollapse, classes, filterText, noMargin,
    } = this.props;
    const {
      open,
    } = this.state;
    return (
      <RoutesContext.Consumer>
        {
          ({ language: contextLanguage, enteredFrom }) => {
            const localization = getLocalization(contextLanguage, enteredFrom, match);
            return (
              <div className="w-full">
                <List className="pb-0" classes={{ root: !noMargin ? classes.list : classes.listNoMargin }}>
                  <div className="w-full text-white font-americaMonoRegular bg-black p-4">
                    {
                  !noCollapse
                    ? (
                      <ListItem
                        className="flex pb-6 mb-6 border-dotted border-white border-b-1 justify-between"
                        onClick={() => {
                          this.setState({ open: !open });
                        }}
                      >
                        {filterHeading
                        && <ListItemText primary={<span className="font-americaMonoBold uppercase text-white">{translateObj(filterHeading, localization.language)}</span>} />
                      }
                        <span className="sm:mr-0">{open ? <ExpandLess /> : <ExpandMore />}</span>
                      </ListItem>
                    )
                    : filterHeading && (
                      <ListItem
                        className="flex pb-6 mb-6 border-dotted border-white border-b-1 justify-between"
                      >
                        <ListItemText primary={<span className="font-americaMonoBold uppercase text-white">{translateObj(filterHeading, localization.language)}</span>} />
                      </ListItem>
                    )
        }
                    {translateObj(filterText, localization.language) && components.length > 0 && <ParagraphLineSM classes="text-white mb-4 uppercase" text={filterText} />}
                    <Collapse in={open} timeout="auto" unmountOnExit>
                      <div className="flex flex-wrap">
                        <ComponentIterator components={this.updateWithCheckedIems(components)} />
                      </div>
                    </Collapse>
                  </div>
                </List>
              </div>
            );
          }
      }
      </RoutesContext.Consumer>
    );
  }
}

FilterNew.propTypes = {
  onHandleCollapsibleMenuSelect: PropTypes.func.isRequired,
  checkedItem: PropTypes.string,
};

FilterNew.defaultProps = {
  checkedItem: '',
};

export default withRouter(withStyles(styles)(FilterNew));
