import React from 'react';
import { PropTypes } from 'prop-types';
import ComponentIterator from '../../containers/ComponentIterator';

const CarouselContent = ({ imageUrl, left, right }) => (
  <div
    className="h-620 md:h-carouselMD sm:h-carouselSM w-full"
    style={{
      background: `url(${imageUrl})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',

    }}
  >
    <div className="text-white flex items-end h-full">
      <div className="flex justify-around sm:flex-col sm:justify-end w-full px-36 md:px-20 sm:px-5 h-1/2 sm:h-full items-end pb-20 sm:pb-5">
        <div className="w-2/3 sm:w-full h-full sm:h-auto flex flex-col justify-end mr-12 sm:mr-0">

          <ComponentIterator components={left} />
        </div>

        <div className="w-1/3 sm:w-full flex flex-col justify-start">
          <ComponentIterator components={right} />
        </div>
      </div>

    </div>

  </div>

);
CarouselContent.propTypes = {

  imageUrl: PropTypes.string.isRequired,
  right: PropTypes.shape({

  }).isRequired,
  left: PropTypes.shape({

  }).isRequired,

};

export default CarouselContent;
