import React from 'react';
import PropTypes from 'prop-types';
import imageModifier from './helpers/imageModifier';

const AssetImage = ({
  url, title,
}) => (
  <React.Fragment>
    <div className="sm:w-full xl:w-1/2 lg:w-1/3 mx-auto text-center relative my-auto">
      <img src={imageModifier(url, '500x500', true)} className="colorImg block mx-auto sm:w-full" alt={title.en} />
    </div>
  </React.Fragment>
);
AssetImage.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.shape({}),
};

AssetImage.defaultProps = {
  title: { en: 'Primavera Sound' },
};

export default AssetImage;
