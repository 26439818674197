import React from 'react';
import { PropTypes } from 'prop-types';

import { withRouter } from 'react-router-dom';
import Link from '../../util/link';

import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';
import translateObj from '../../util/translateObj';
import imageModifier from './helpers/imageModifier';

const MediaAlternative = ({
  image, text, slug, cta, match, width, height, textColor, isUniversePage,
}) => (
  <RoutesContext.Consumer>
    {({ language: contextLanguage, enteredFrom }) => {
      // const { language, path } = getLocalization(contextLanguage, enteredFrom, match).localization || { language: 'en', path: '/' };
      const localization = getLocalization(contextLanguage, enteredFrom, match);

      const isPdf = (slug && slug.slice(-3).toLowerCase()) === 'pdf';
      const ext = translateObj(image, localization.language)
        .split('.')
        .pop();
      let src;
      if (ext !== 'svg') {
        src = imageModifier(translateObj(image, localization.language), `${width && height ? `${width}x${height}` : '502x376'}`);
      } else {
        src = translateObj(image, localization.language);
      }

      const defaultStyles = 'sm:px-0 sm:pr-2 sm:w-1/3 md:w-1/3 lg:w-1/4 xl:w-1/5 ';
      const styles = isUniversePage ? 'pr-2' : defaultStyles;

      return (
        <Link aria-label={translateObj(text, localization.language)} to={slug} localizationpath={localization.path} className={`${styles} p-2 text-${textColor} flex flex-col media-row ${width && height ? 'justify-center' : 'justify-between'} no-underline`}>
          <div className="rounded relative sm:text-center">
            <div className="w-full relative">
              {ext !== 'svg' ? <img src={src} alt={translateObj(text, localization.language)} /> : <img src={src} alt={translateObj(text, localization.language)} width={width && width} height={height && height} />}
              {/* <div className="sm:absolute sm:pin-l sm:pin-b sm:pt-16"><ButtonTag tag={tag} /></div> */}
            </div>
            {isPdf && (
            <div className="text-right items-center py-2 text-xs font-americaMonoRegular tracking-wide cursor-pointer uppercase font-bold sm:text-xxs">
                  download
              <span className="font-icon icon-download1 pl-1" />
            </div>
            )}
            {!isPdf && translateObj(text, localization.language) ? <div className="my-6 mx-1 font-americaBlack text-left sm:text-sm md:text-base text-lg">{translateObj(text, localization.language)}</div> : null}
            {cta ? (
              <div className={`my-4 mx-1 font-americaMonoBold cursor-pointer font-${textColor} sm:text-xs md:text-xs text-sm`}>
                <div className="text-left uppercase">
                  <span className={`font-icon icon-arrow-right text-${textColor} text-xxs mr-2`} />
                  {translateObj(cta, localization.language)}
                </div>
              </div>
            ) : null}
          </div>
        </Link>
      );
    }}
  </RoutesContext.Consumer>
);

MediaAlternative.propTypes = {
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]).isRequired,
  match: PropTypes.shape().isRequired,
  slug: PropTypes.string.isRequired,
  text: PropTypes.shape(),
  cta: PropTypes.shape(),
  width: PropTypes.string,
  height: PropTypes.string,
  textColor: PropTypes.string,
  isUniversePage: PropTypes.bool,
};

MediaAlternative.defaultProps = {
  cta: null,
  width: '',
  height: '',
  text: '',
  textColor: 'black',
  isUniversePage: false,
};

export default withRouter(MediaAlternative);
