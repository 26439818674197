import React from 'react';

const OnlineIcon = () => (
  <svg width="100%" height="100%" viewBox="0 0 16 15" xmlns="http://www.w3.org/2000/svg">
    <g fill="currentColor" fillRule="evenodd">
      <path d="m16 4.32333333c0 1.18-.4933333 2.27333334-1.3933333 3.1l-1.4333334 1.30666667-1.1466666 1.04666667c-.2666667.24000003-.5533334.44666663-.86.62000003-.7333334.4066666-1.56000003.6133333-2.39333337.6133333-.32 0-.64-.0333333-.95333333-.0933333-.49333333-.0933334-.97333333-.2666667-1.42-.5133334-.31333333-.1733333-.60666667-.38-.88-.62666663-.052-.04733334-.10266667-.096-.152-.14533334-.39533333-.39533333-.37666667-1.04466666.03666667-1.422.00733333-.00666666.01533333-.014.02266666-.02066666.392-.35733334.98866667-.33333334 1.36333334.04133333.02466666.02533333.05.04933333.076.07333333.33333333.3.72666666.51333334 1.15333333.62.55333333.14666667 1.15333333.13333334 1.7-.06.3533333-.11333333.68-.30666666.96-.56l1.1066667-1.01333333 1.1066666-1.01333333.3666667-.33333334c.4733333-.43333333.74-1.01333333.74-1.62 0-.6-.2666667-1.18-.74-1.61333333-.5266667-.48-1.22-.72-1.9066667-.72-.6933333 0-1.37999997.24-1.90666663.72l-.1.09333333c-.66666667-.24-1.38666667-.36666666-2.12-.36666666-.16 0-.31333334.00666666-.46666667.02l1.34-1.22666667c1.79333333-1.64 4.7133333-1.64 6.5066667 0 .9.82 1.3933333 1.92 1.3933333 3.09333333" />
      <path d="m10.5953333 6.57066667c-.0073333.00666666-.0153333.014-.0226666.02066666-.392.35733334-.9886667.33333334-1.36333337-.04133333-.02466666-.02533333-.05-.04933333-.076-.07333333-.33333333-.3-.73333333-.51333334-1.15333333-.62-.24666667-.06666667-.5-.1-.75333333-.1-.32 0-.64666667.05333333-.94666667.16-.35333333.11333333-.68.30666666-.96.56l-1.10666667 1.01333333-1.10666666 1.01333333-.36666667.33333334c-.47333333.43333333-.74 1.01333333-.74 1.62000003 0 1.176 1.02466667 2.2753333 2.622 2.3433333.71533333.0306667 1.41266667-.246 1.936-.7346667l.09533333-.0886666c.66666667.24 1.38666667.3666666 2.12.3666666.16 0 .31333334-.0066666.46666667-.02l-1.34 1.2266667c-.89333333.82-2.07333333 1.2266667-3.25333333 1.2266667s-2.35333334-.4066667-3.25333334-1.2266667-1.39333333-1.92-1.39333333-3.0933333c0-1.18000003.49333333-2.27333337 1.39333333-3.10000003l1.43333334-1.30666667 1.14666666-1.04666667c.26666667-.24.55333334-.44666666.86-.61333333 1.01333334-.56666667 2.22-.74 3.34666667-.52666667.49333333.1.97333333.26666667 1.41333333.51333334.32.16666666.61333337.38.88666667.62666666.052.04733334.1026667.096.152.14533334.3953333.39533333.3766667 1.04466666-.0366667 1.422" />
    </g>
  </svg>
);

export default OnlineIcon;
