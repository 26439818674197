import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ComponentIterator from '../../containers/ComponentIterator';

const order = ['A', 'B', 'C', 'D'];
const setRandomNumber = (min, max) => Math.floor(Math.random() * (+max - +min)) + +min;

class TestAB extends Component {
  state = {
    components: [],
    isLoading: true,
  }

  componentDidMount() {
    const { components } = this.props;
    if (components.length) {
      this.preparePostDescription();
    }
  }

  preparePostDescription = () => {
    const { components } = this.props;
    const num = setRandomNumber(0, components.length);
    const type = order[num];
    document.title += type;

    this.setState({
      components: [components[num]],
      isLoading: false,
    });
  }

  render() {
    const { isLoading, components } = this.state;
    return (
      <>
        { !isLoading && (
          <ComponentIterator components={components} />
        )}
      </>
    );
  }
}

TestAB.propTypes = {
  components: PropTypes.arrayOf(PropTypes.shape({})),
};

TestAB.defaultProps = {
  components: [],
};

export default TestAB;
