import React, { Component } from 'react';
import PropTypes from 'prop-types';

import withRadioPlayerContext from '../../../context/withRadioPlayerContext';
import { UserProfileContext } from '../../../context/userProfileContext';

import VerificationBanner from '../HeaderPopUpBanners/VerificationBanner';
import withAllContexts from '../../../context/withAllContexts';

class Header extends Component {
  static contextType = UserProfileContext;

  headerRef = React.createRef();

  componentExists = React.createRef(false);

  state = {
    scrolledFromTop: 0,
    showHeaderOnScroll: false,
    headerRelative: true,
    isUserVerified: null,
    userEmail: '',
  }

  componentDidMount() {
    this.componentExists.current = true;
    const { removeRadio } = this.props;
    if (removeRadio) {
      const { hideRadio, hidePlayer } = this.props;
      hidePlayer();
      hideRadio(true);
    }
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    this.componentExists.current = false;
    const { removeRadio } = this.props;
    if (removeRadio) {
      const { hideRadio, showPlayer } = this.props;
      hideRadio(false);
      showPlayer();
    }
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const { scrolledFromTop } = this.state;
    const newScrolledFromTop = this.headerRef.current.getBoundingClientRect().top;
    const { playerPosition, setPlayerPosition, headerContext } = this.props;
    const body = document.querySelector('body');
    const lineUpContainer = document.getElementById('line-up-container');
    // check if we are on line up page and make header always scroll up
    if (lineUpContainer) {
      return this.setState(() => ({ showHeaderOnScroll: false, headerRelative: true }));
    }
    if (!headerContext.megaMenuIsOpen) {
      if (newScrolledFromTop > scrolledFromTop) {
        this.setState(() => ({ showHeaderOnScroll: true, headerRelative: false }));
        if (!this.isBottom(body)) {
          if (playerPosition.includes('pin-t')) { setPlayerPosition('pin-t'); } else { setPlayerPosition('pin-b'); }
        }
      } else {
        this.setState(() => ({ showHeaderOnScroll: false }));
        if (scrolledFromTop < -150) {
          this.setState(() => ({ headerRelative: false }));
          if (playerPosition === 'pin-b') {
            setPlayerPosition('pin-b -mb-16');
          } else if (playerPosition === 'pin-t') {
            setPlayerPosition('pin-t -mt-16');
          }
        } else {
          this.setState(() => ({ headerRelative: true }));
        }
      }
      if (this.componentExists.current) {
        this.setState(() => ({ scrolledFromTop: newScrolledFromTop }));
      }
    } else {
      this.setState(() => ({ showHeaderOnScroll: false, headerRelative: true }));
    }
  }

  isBottom = el => el.getBoundingClientRect().bottom <= window.innerHeight + 315

  recieveInfoFromContext = (
    isUserVerified,
    userEmail,
    isUserUpToDateWithTermsAndConditions,
  ) => {
    this.setState({
      isUserVerified,
      userEmail,
      isUserUpToDateWithTermsAndConditions,
    });
  }

  render() {
    const {
      children,
      showRadioPlayer,
      playerPosition,
      backgroundColor,
      showVerifyAccountBanner,
      translation,
    } = this.props;

    const {
      showHeaderOnScroll,
      headerRelative,
      userEmail,
      isUserVerified,
      isUserUpToDateWithTermsAndConditions,
    } = this.state;

    return (
      <>
        <header
          ref={this.headerRef}
          className={`bg-${backgroundColor} flex w-full z-header relative h-24 tablet:h-16 desktop:h-18`}
        >
          <div
            className={`flex flex-col  w-full 
              ${showHeaderOnScroll ? 'fixedHeaderShow' : ''}
              ${(playerPosition === 'pin-t' && showRadioPlayer) ? 'mt-14' : ''}
              ${(userEmail && (isUserVerified === false || isUserUpToDateWithTermsAndConditions === false)) ? '' : ''}
              ${headerRelative
              ? 'relative'
              : 'fixedHeader'}
            `}
          >
            <div className={`bg-${backgroundColor} flex flex-row  w-full py-3 px-5 h-24 tablet:h-16 desktop:h-18`}>
              {children}
            </div>
            {showVerifyAccountBanner ? <VerificationBanner translation={translation} /> : null }
          </div>
        </header>
      </>
    );
  }
}

Header.propTypes = {
  children: PropTypes.shape().isRequired,
  showRadioPlayer: PropTypes.bool.isRequired,
  playerPosition: PropTypes.string.isRequired,
  setPlayerPosition: PropTypes.func.isRequired,
  headerContext: PropTypes.shape({}).isRequired,
  removeRadio: PropTypes.bool,
  hideRadio: PropTypes.func.isRequired,
  hidePlayer: PropTypes.func.isRequired,
  showPlayer: PropTypes.func.isRequired,
  backgroundColor: PropTypes.string,
  showVerifyAccountBanner: PropTypes.bool,
  translation: PropTypes.shape({}),
};

Header.defaultProps = {
  removeRadio: false,
  backgroundColor: 'white',
  showVerifyAccountBanner: false,
  translation: null,
};

export default withRadioPlayerContext(withAllContexts(Header));
