import React, { useState, useEffect } from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import translateObj from '../../util/translateObj';

const trimName = name => name.split('_').slice(1).join(' ');
const CheckboxesGroup = ({ items, cb, lang }) => {
  const [state, setState] = useState(items);
  const handleChange = name => (event) => {
    cb(name, event.target.checked ? '1' : '');
  };
  useEffect(() => {
    setState(items);
  }, [items]);
  return (
    <div>
      <FormControl component="fieldset">
        <FormGroup>
          {state.map((item, index) => (
            <FormControlLabel
              key={item.name}
              control={<Checkbox checked={item.value === '1'} onChange={handleChange(item.name, index)} value={item.name} />}
              label={translateObj(item.nameMultilang, lang).toUpperCase() || item.name}
            />
          ))}

        </FormGroup>
      </FormControl>
    </div>
  );
};

export default CheckboxesGroup;
