import React from 'react';
import PropTypes from 'prop-types';

import RoutesContext from './routesContext';
import LauncherContext from './launcherContext';
import LineupContext from './lineupContext';
import { useHeaderContext, HeaderContext } from './headerContext';
import { OpenChatContext, useInitOpenChatContext } from '../components/ps/MusicMeets/Chat/context/OpenChatContext';

import RadioShowsEpisodesContext from './radioShowsEpisodesContext';
import RadioShowsContext from './radioShowsContext';

import AllContexts from './allContexts';

const ProvideCombinedContext = (props) => {
  const headerContextValue = useHeaderContext();
  const openChatContextInitValue = useInitOpenChatContext();
  return (
    <RoutesContext.Consumer>
      {(routesContext) => (
        <LauncherContext.Consumer>
          {(launcherContext) => (
            <LineupContext.Consumer>
              {(lineupContext) => (
                <RadioShowsContext.Consumer>
                  {(radioShowsContext) => (
                    <RadioShowsEpisodesContext.Consumer>
                      {(radioShowsEpisodesContext) => (
                        <HeaderContext.Provider value={headerContextValue}>
                          <OpenChatContext.Provider value={openChatContextInitValue}>
                            <AllContexts.Provider value={{
                              routesContext,
                              launcherContext,
                              lineupContext,
                              radioShowsContext,
                              radioShowsEpisodesContext,
                              headerContext: headerContextValue,
                            }}
                            >
                              {props.children}
                            </AllContexts.Provider>
                          </OpenChatContext.Provider>
                        </HeaderContext.Provider>
                      )}
                    </RadioShowsEpisodesContext.Consumer>
                  )}
                </RadioShowsContext.Consumer>
              )}
            </LineupContext.Consumer>
          )}
        </LauncherContext.Consumer>
      )}
    </RoutesContext.Consumer>
  );
};

ProvideCombinedContext.propTypes = {
  children: PropTypes.shape().isRequired,
};

export default ProvideCombinedContext;
