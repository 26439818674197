const setCategory = (event) => {
  const eventToLower = event.toLowerCase();
  if (eventToLower.includes('weekender') || eventToLower.includes('benidorm')) return 'weekender';
  if (eventToLower.includes('oporto') || eventToLower.includes('nos') || eventToLower.includes('porto')) return 'porto';
  if (eventToLower.includes('angeles')) return 'los-angeles';
  if (eventToLower.includes('pro') || eventToLower.includes('pro')) return 'barcelona';
  if (eventToLower.includes('primavera')) return 'barcelona';
  if (eventToLower.includes('tour') || eventToLower.includes('tour')) return 'tours';

  return 'tours';
};

export default setCategory;
