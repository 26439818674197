/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react';
import { PropTypes } from 'prop-types';

import { withRouter } from 'react-router-dom';
import moment from 'moment';
import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';

const NewsDate = ({ match, createdAt, margin }) => (
  <RoutesContext.Consumer>
    {({ language: contextLanguage, enteredFrom }) => {
      const localization = getLocalization(contextLanguage, enteredFrom, match);
      moment.locale(localization.language);
      return (
        <p
          tabIndex="0"
          className={`text-xs text-left font-tiemposRegular ${margin
            || 'mt-4'}`}
        >
          {createdAt && moment(parseInt(createdAt)).format('MMMM D, YYYY')}
        </p>
      );
    }}
  </RoutesContext.Consumer>
);

NewsDate.propTypes = {
  match: PropTypes.shape().isRequired,
  createdAt: PropTypes.string,
  margin: PropTypes.string,
};

NewsDate.defaultProps = {
  createdAt: null,
  margin: '',
};

export default withRouter(NewsDate);
