import React from 'react';
import PropTypes from 'prop-types';
import RelatedItem from './RelatedItem';

const RelatedList = ({ items }) => (
  <div className="border-t-1 border-black">
    {items.map(data => (
      <RelatedItem linkTo={data.linkTo} text={data.text} date={data.date} key={data.date + data.text} />
    ))}
  </div>
);

RelatedList.propTypes = {
  news: PropTypes.array.isRequired,
};

export default RelatedList;
