import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useAuth } from '@primaveralabs/auth';

import translateObj from '../../../../util/translateObj';

import { useRoutes } from '../../../../context/routesContext';

import ProfileLogOutButton from './components/ProfileLogOutButton';
import ProfileFormsy from './components/ProfileFormsy';

const ProfileForm = ({
  history,
  match,
  title,
  fields,
  infoFields,
  validation,
  submitMessage,
  sourceWeb,
}) => {
  const { token } = useAuth();
  const { language } = useRoutes();
  const [didSubmit, setDidSubmit] = useState(false);

  useEffect(() => {
    // * if user is not logged in redirect to login-screen
    if (!token) {
      // history.push(`/${language}/login-screen${sourceWeb && `-${sourceWeb}`}?e=sign-in`);
      setTimeout(() => {
        history.push(`/${language}`);
      }, 100);
    } else {
      const body = document.getElementById('bodyChildContainer');
      body.childNodes[0].setAttribute('style', 'border-top: none');
    }
  }, [token]);

  // ! delete account commented out, do not remove !
  // const handleAccountDeletion = (email, language = 'en', loadTokenForLS) => {
  //   userDeleteAccount({ email }).then((response) => {
  //     if (response.isDeleted) {
  //       _logout(language, loadTokenForLS);
  //     } else {
  //       console.log('error when trying to delete user', response.error);
  //     }
  //   });
  // }

  if (!token) return null;

  return (
    <>
      {!didSubmit && (
      <>
        {token && <ProfileLogOutButton />}
        <ProfileFormsy {... {
          title,
          fields,
          infoFields,
          validation,
          language,
          setDidSubmit,
          history,
          sourceWeb,
        }}
        />
      </>
      )}

      {didSubmit && (
      <div className="flex flex-col w-full py-12 justify-center font-americaMonoRegular items-center">
        <h3>{translateObj(submitMessage, language)}</h3>
      </div>
      )}
    </>
  );
};

ProfileForm.propTypes = {
  match: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
  title: PropTypes.shape().isRequired,
  fields: PropTypes.shape().isRequired,
  validation: PropTypes.shape().isRequired,
  infoFields: PropTypes.shape().isRequired,
  submitMessage: PropTypes.shape().isRequired,
  sourceWeb: PropTypes.string,
  // menu: PropTypes.shape(),
  // conditions: PropTypes.shape().isRequired,
  // submitButtonText: PropTypes.shape().isRequired,
  // resetButtonText: PropTypes.shape().isRequired,
  // submitMessageButton: PropTypes.shape().isRequired,
};

ProfileForm.defaultProps = {
  // menu: null,
  sourceWeb: '',
};

export default withRouter(ProfileForm);
