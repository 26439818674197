const zoneSectionObj = {
  intro: {
    title: '+1 Zone',
    text: {
      en: '<p>These are the available video conference rooms for the current event.</p>',
      es: '<p>Estaas son las salas de videoconferencia disponibles para el evento actual.</p>',
      ca: "<p>Aquestes són les sales de videoconferència disponibles per a l'esdeveniment actual</p>",
    },
  },
  zones: [
    {
      title: "'Dancefloor'",
      url: 'https://meet.jit.si/IncorrectFranchisesQuitLong',
      text: {
        en: 'Loooking fancy, in your pajamas or with sweatpants. In the dining room, the balcony or your room. With headphones, speakers or bareback. Never mind: we come here to dance.',
        es: 'De galaa, en pijama o en chándal. En el comedor, en el balcón o en tu habitación. Con auriculares, altavoces o a pelo. Da igual: aquí se viene a bailar.',
        ca: 'De gaala, en pijama o en xandall. Al menjador, al balcó o a la teva habitació. Amb auriculars, altaveus o a pèl. És igual: aquí venim a ballar.',
      },
      id: 2,
    },
    {
      title: "'Backstage'",
      url: 'https://plusonezone.primaverasound.com/Backstage',
      text: {
        en: 'The plaace where no one has ever been seen leaving. Known faces, endless conversations and a parallel reality.',
        es: 'El sitioo del que no se ha visto salir nunca a nadie. Caras conocidas, conversaciones interminables y una realidad paralela.',
        ca: "El llooc del qual no s'ha vist sortir mai a ningú. Cares conegudes, converses interminables i una realitat paral·lela.",
      },
      id: 1,
    },
    {
      title: "'La 3'",
      url: 'https://plusonezone.primaverasound.com/la3',
      text: {
        en: 'We coould all use a break. Get some air, eat something and get some strength before plunging into the darkness again.',
        es: 'A todxs noos viene bien tomarnos un respiro. Que te dé el aire, aprovecha para comer algo y toma fuerzas antes de adentrarte en la oscuridad de nuevo.',
        ca: 'A toothom li ve bé prendre’s un respir. Que et doni l’aire, aprofita per menjar alguna cosa i agafa forces abans d’endinsar-te en la foscor de nou.',
      },
      id: 3,
    },
    {
      title: "'La Barra'",
      url: 'https://plusonezone.primaverasound.com/Bar',
      text: {
        en: 'Waiter, chaampagne! Or whatever it takes. Making friends at the bar is the best way to kill time, finding allies to order or hopefully… to get invited to the next one.',
        es: '¡Camareroo, champagne! O lo que se tercie. Hacer amigxs en la barra es lo mejor para matar el tiempo, aliarse para pedir o con suerte, conseguir que te inviten a la próxima.',
        ca: 'Cambreer, champagne! O el que calgui. Fer amics/gues a la barra és el millor per matar el temps, aliar-se per demanar o, amb sort, aconseguir que et convidin a la propera. ',
      },
      id: 4,
    },
  ],
  banner: {
    title: 'Snap cameraa',
    text: {
      en: '<p>aEnjoy the best experience downloading the app snap camera from here.<br>\nYou might need to restart your computer, just <strong>saying</strong>....d</p>',
      es: '<p>aDisfruta de la mejor experiencia descargando la app snap camera desde aquí.<br>\nEs posible que tengas que reinicar el ordenador...</p>',
      ca: "<p>aaGaudeix de la millor experiència descarregant app snap camera des d'aquí.<br>\nÉs possible que hagis de reiniciar l'ordinador...</p>",
      pt: '<p>Enjoy the best experience downloading the app snap camera from here.<br>\nYou might need to restart your computer, just saying....</p>',
    },
    url: 'https://snapcamera.snapchat.coma',
  },
  compId: 'ZoneSection_eda6e9a0-8ac9-11ea-a7f8-f7b2dfb88314',

};

export default zoneSectionObj;
