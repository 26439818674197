import React, { useEffect, useState, useRef } from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import ShareMedia from './ShareMedia';
import ComponentIterator from '../../containers/ComponentIterator';
import getTicketsByEventSlugName from '../../api/graphql/queries/getTicketsByEventSlugName';
import getEventsByPostSlug from '../../api/graphql/queries/getEventsByPostSlug';
import getPostsBySlugNameQuery from '../../api/graphql/queries/getPostsBySlugNameQuery';
import PSResponsiveUtility from './LineUp/PSResponsiveUtility';
import Separator from './Separator';
import SliderBannerArtistPD from './Sliders/SliderBannerArtistPD';
import LoadIcon from './LoadIcon';

const SHOW_TICKETS_ON_THESE_EVENTS = ['tours', 'primavera-labels'];

const SingleConcertPage = ({
  components, match: { params }, width, classesLeft, classesRight,
}) => {
  const [pd, setPostDescription] = useState();
  const [updatedComponents, setUpdatedComponents] = useState();
  const [deviceScreen, setDeviceScreen] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [slugName, setSlugName] = useState(null);
  const [eventSlugName, setEventSlugName] = useState(null);
  const [prevArtist, setPrevArtist] = useState();
  const [nextArtist, setNextArtist] = useState();
  const [currentArtistSlug, setCurrentArtistSlug] = useState();
  const [topComponents, setTopComponents] = useState();
  const responsiveUtility = useRef();

  const displayDate = (data) => {
    const updatedComps = [...components];
    if (updatedComps.length && updatedComps[0].type === 'TitleLineH1') {
      updatedComps[0].margin = 'sm:text-3xl md:text-3xl xl:text-38 lg:text-38 leading-tight';
    }
    if (components.length && components[0].type === 'TitleLineH1') {
      if (data.date) {
        updatedComps.splice(1, 0, {
          type: 'NewsDate',
          color: 'black',
          createdAt: data.date,
          margin: 'mt-1',
        });
      }
    } else {
      if (data.date) {
        updatedComps.unshift({
          type: 'NewsDate',
          color: 'black',
          createdAt: data.date,
          margin: 'mt-1',
        });
      }
    }
    setUpdatedComponents(updatedComps);
    setIsLoading(false);
  };

  const fetchPostDrescriptionsBySlugnames = (slugnames) => {
    getPostsBySlugNameQuery(slugnames)
      .then((res) => {
        if (res && res.length) {
          const { postDescription, next, prev } = res[0];
          setPostDescription(postDescription);
          setPrevArtist(prev);
          setNextArtist(next);
          displayDate(postDescription);
        }
      })
      .catch((err) => {
        console.log(
          'Some error happened on fetch of fetchPostDescription:',
          err,
        );
      });
  };

  const getEventTickets = async (postSlugName, eventSlug = 'tours') => {
    const asociatedEvents = await getEventsByPostSlug(postSlugName);
    if (!asociatedEvents.length) return;
    setCurrentArtistSlug(asociatedEvents[0].artistSlugName);
    const eventSlugs = asociatedEvents.map((item) => item.eventSlugName);
    const tickets = await getTicketsByEventSlugName(eventSlugs);
     if (tickets?.length) {
      setTopComponents([
        {
          type: 'Separator',
          borderWidth: 3,
          marginTop: false,
        },
        {
          type: 'SidebarNextDates',
          isConcert: SHOW_TICKETS_ON_THESE_EVENTS.includes(eventSlug),
          headLine: {
            es: 'Tickets',
            en: 'Entradas',
            ca: 'Entradas',
          },
          data: tickets,
        },
      ]);
    } else {
      setTopComponents(null);
    }
  };

  const handleResize = () => {
    const screen = responsiveUtility.current.deviceScreen(true);
    setDeviceScreen(screen);
  };

  const init = async (concertSlug, eventSlug) => {
    await getEventTickets(concertSlug, eventSlug);
    await fetchPostDrescriptionsBySlugnames([concertSlug]);
  };

  useEffect(() => {
    let shouldFetch = true;
    if (slugName !== params.post && slugName !== params.section && shouldFetch) {
      setIsLoading(true);
      let concertSlug;
      let eventSlug = 'tours';
      if (params.post) {
        concertSlug = params.post;
        if (params.section !== 'tours' || params.section !== 'primavera-labels') {
          eventSlug = params.section;
        }
      } else {
        concertSlug = params.section;
      }
      setSlugName(concertSlug);
      setEventSlugName(eventSlug);
      init(concertSlug, eventSlug);
    }

    return () => shouldFetch = false;
  }, [components]);

  const spotifyPlayer = components.filter(
    (comp) => comp.type === 'SpotifyPlayer',
  );

  return (
    <>
      <PSResponsiveUtility
        ref={responsiveUtility}
        deviceWidthCallback={handleResize}
      />
      {pd ? (
        <SliderBannerArtistPD
          eventSlugName={eventSlugName || 'tours'}
          {...pd}
          prevArtist={prevArtist}
          nextArtist={nextArtist}
          artist={currentArtistSlug}
        />
      ) : (
        <div className="flex items-center justify-center h-620 md:h-carouselMD sm:h-carouselSM w-full sliderComponent relative full-width">
          <LoadIcon color="black" />
        </div>
      )}
      {!isLoading
        ? (
          <>
            <div className="py-4 w-full flex justify-end">
              {pd ? <ShareMedia description={pd.title} /> : null}
            </div>
            <div className="mt-5 w-full flex md:flex-wrap sm:flex-wrap mb-12 sm:mb-0 justify-center">
              <div
                className={`'pt-8 sm:w-full md:w-full lg:w-ticket-lg xl:w-ticket-xl border-box ${classesLeft}'`}
              >
                {topComponents && (
                <ComponentIterator components={topComponents} />
                )}
                {deviceScreen !== 'sm' && deviceScreen !== 'md' ? (
                  <div className="mt-6">
                    {spotifyPlayer.length ? (
                      <>
                        <Separator borderWidth={2} />
                        <ComponentIterator components={spotifyPlayer} />
                      </>
                    ) : null}
                  </div>
                ) : null}
              </div>
              <div
                className={`xl:w-1200 lg:w-1028 sm:w-full pl-6 sm:pl-0 md:pl-0 sm:mb-4 md:w-full mt-7px ${classesRight} `}
              >
                {deviceScreen === 'md' || deviceScreen === 'sm' ? (
                  <Separator borderWidth={2} />
                ) : null}
                <div className="md:my-was 4">
                  {deviceScreen === 'md' || deviceScreen === 'sm' ? (
                    spotifyPlayer.length ? (
                      <>
                        <ComponentIterator components={spotifyPlayer} />
                        <Separator borderWidth={2} />
                      </>
                    ) : null
                  ) : null}
                  <ComponentIterator
                    components={updatedComponents.filter(
                      (comp) => comp.type !== 'SpotifyPlayer',
                    )}
                  />
                </div>
              </div>
            </div>
          </>
        ) : <div className="my-12"><LoadIcon color="barcelona" /></div> }
    </>
  );
};

SingleConcertPage.propTypes = {
  match: PropTypes.shape({}).isRequired,
  components: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  width: PropTypes.number,
  classesLeft: PropTypes.string,
  classesRight: PropTypes.string,
};

SingleConcertPage.defaultProps = {
  width: 4,
  classesLeft: '',
  classesRight: '',
};

export default withRouter(SingleConcertPage);
