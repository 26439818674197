import gql from 'graphql-tag';

import { clientWeb, persistor } from '../graphqlAPI';

const GET_POSTS_LIST_QUERY = gql`
  query Get(
    $category: [String]
    $findCategoryOperator: String
    $to: Int!
    $from: Int!
    $postSlugName: String
    $sortAscending: Boolean
    $showNoIndex: Boolean
    $sortByPostLauncherDate: Boolean
    $overrideTotal: Boolean
  ) {
    getPostsListWithTotal(
      category: $category
      findCategoryOperator: $findCategoryOperator
      to: $to
      from: $from
      postSlugName: $postSlugName
      sortAscending: $sortAscending
      showNoIndex: $showNoIndex
      sortByPostLauncherDate: $sortByPostLauncherDate
      overrideTotal: $overrideTotal
    ) {
      total
      posts {
        slugName
        postCategory
        active
        updatedAt
        noIndex
        rolesAllowed
        postDescription {
          description {
            en
            es
            ca
            pt
          }
          title {
            en
            es
            ca
            pt
          }
          subtitle {
            en
            es
            ca
            pt
          }
          image {
            en
            es
            ca
            pt
          }
          authors {
            name
            slug
          }
          relatedArtists {
            name
            slug
          }
          relatedMagazine {
            title
            slugName
          }
          tags
          itemLayout
          position
          url
          overlayImage
          date
          expirationDate
          cta {
            url
            text {
              en
              ca
              es
              pt
            }
            isDisabled
          }
          loyaltyPoints
        }
      }
    }
  }
`;

const executeQuery = async (args) => {
  const {
    category,
    findCategoryOperator,
    from,
    to,
    postSlugName,
    sortAscending = false,
    showNoIndex = false,
    type = 'network',
    sortByPostLauncherDate,
    overrideTotal = false,
  } = args;
  const result = await clientWeb
    .query({
      query: GET_POSTS_LIST_QUERY,
      variables: {
        category,
        findCategoryOperator,
        from,
        to,
        postSlugName,
        sortAscending,
        showNoIndex,
        sortByPostLauncherDate,
        overrideTotal,
      },
      fetchPolicy: type === 'network' ? 'network-only' : 'cache-only',
    })
    .then((queryResult) => queryResult)
    .catch((queryError) => {
      const errorMessage =
        type === 'network'
          ? `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error: ${queryError}`
          : `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error after Cache Restore: ${queryError}`;
      console.log('\x1b[31m%s\x1b[0m', errorMessage);

      return null;
    });

  if (result.data && result.data.getPostsListWithTotal) {
    const {
      data: { getPostsListWithTotal: getPostsListData },
    } = result;
    return getPostsListData;
  }

  return [];
};

const getPostsListWithTotal = async (args) => {
  try {
    const JSONObject = await executeQuery(args);

    if (persistor) {
      persistor.persist();
    }

    return JSONObject;
  } catch (jsonError) {
    console.log(
      '\x1b[31m%s\x1b[0m',
      `${new Date().toLocaleTimeString()} ~ Client: JSON Object Error: ${jsonError}`
    );

    if (persistor) {
      const persistorStatus = await persistor.restore().then(() => 'OK');
      console.log(
        `${new Date().toLocaleTimeString()} ~ Client: Apollo Cache Restored - ${persistorStatus}`
      );

      try {
        const JSONObject = await executeQuery({ ...args, type: 'cache' });
        return JSONObject;
      } catch (jsonErrorAfterCacheRestore) {
        console.log(
          '\x1b[31m%s\x1b[0m',
          `${new Date().toLocaleTimeString()} ~ Client: JSON Object Error after Cache Restore, sending 404: ${jsonErrorAfterCacheRestore}`
        );
        return null;
      }
    }
    return null;
  }
};

export default getPostsListWithTotal;
