import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';
import translateObj from '../../../util/translateObj';

const GenreTag = ({
  text, category, margin, textColor, borderColor, match,
}) => (
  // color of the tag is determined by the tag category
  <RoutesContext.Consumer>
    {
      ({ language: contextLanguage, enteredFrom }) => {
        const localization = getLocalization(contextLanguage, enteredFrom, match);
        return (
          <span
            className={`${margin} block ml-0 font-americaMonoMedium text-sm ${textColor ? `text-${textColor}` : 'text-white'} align 
            rounded px-2 py-1 sm:text-xxs md:text-xxs bg-${category} ${margin || ''} border-solid border ${borderColor ? `border-${borderColor}` : `border-${category}`}`}
            style={{
              width: 'fit-content',
            }}
          >
            <span className="uppercase">
              {translateObj(text, localization.language)}
            </span>
          </span>
        );
      }
    }
  </RoutesContext.Consumer>
);

GenreTag.propTypes = {
  match: PropTypes.shape().isRequired,
  category: PropTypes.string.isRequired,
  text: PropTypes.shape({
    en: PropTypes.string.isRequired,
    es: PropTypes.string,
    ca: PropTypes.string,
  }).isRequired,
  margin: PropTypes.string,
  textColor: PropTypes.string,
  borderColor: PropTypes.string,
};

GenreTag.defaultProps = {
  margin: '',
  textColor: 'white',
  borderColor: '',
};

export default withRouter(GenreTag);
