
import React from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import Filter from './Collapsible/Filter';
import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';
import ComponentIterator from '../../containers/ComponentIterator';
import Modal from './Modal';
import handleFilter from './Collapsible/handleFilter';
import getMamObjectsBySlugName from '../../api/graphql/queries/getMamObjectsBySlugname';
import setComponentTypeFromExtension from '../../util/setComponentTypeFromExtension';

class AssetsContainer extends React.Component {
  state = {
    params: [],
    filtered: [],
    menu: {},
    isLoading: true,
    isModal: false,
  }

  componentDidMount() {
    const { slugnames, menu } = this.props;
    if (slugnames) {
      this.getMamObjectsFromSlugnames(slugnames);
    } else {
      this.setState({
        menu,
        isLoading: false,
      });
    }
  }

  getMamObjectsFromSlugnames = (slugnames) => {
    getMamObjectsBySlugName(slugnames)
      .then((params) => {
        this.processParams(params);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  processParams = (params) => {
    const { menu } = this.props;
    let paramsWithHandler = [];
    this.body = document.querySelector('body');
    if (params && params.length) {
      paramsWithHandler = this.addHandler(params);
      const { type } = paramsWithHandler[0];
      const slugName = this.getQueryVariable(type);
      if (slugName) {
        const active = paramsWithHandler.find(item => item.slug || item.assetSlugname === slugName);
        return this.setState({
          isModal: true,
          filtered: [active],
          params: paramsWithHandler,
          menu,
          isLoading: false,
        });
      }
    }
    return this.setState({
      filtered: paramsWithHandler,
      params: paramsWithHandler,
      menu,
      isLoading: false,
    });
  }

  handleClose = () => {
    const { location: { pathname } } = this.props;
    this.body.setAttribute('style', 'overflow: auto');
    const { history } = this.props;
    history.push(`${pathname}`);
    this.setState({ isModal: false });
  }

  onHandleCollapsibleMenuSelect = (filters) => {
    if (filters.byMaterial && filters.byMaterial.length) {
      const { location: { pathname }, history } = this.props;
      this.setState({ filters });
      history.push(`${pathname}?type=${filters.byMaterial[0]}`);
    }
    const { params } = this.state;
    const filtered = handleFilter(filters, params);
    this.setState({
      filtered,
    });
  }

  addHandler = params => (
    params.map((param, index) => {
      const newParam = { ...param };
      newParam.index = index;
      newParam.onClick = this.onClick;
      newParam.getQueryVariable = this.getQueryVariable;
      newParam.type = setComponentTypeFromExtension(param.fileExtension);
      return newParam;
    })
  )

  handleSearchInput = (query, lang, filters) => {
    const { location: { pathname } } = this.props;
    const { params } = this.state;
    let title;
    let description;
    let filteredWithSearch;
    if (pathname === 'press-area') {
      filteredWithSearch = params.filter((item) => {
        if (item.title) { title = item.title[lang].toLowerCase(); } else { title = ''; }
        if (item.description) { description = item.description[lang].toLowerCase(); } else { description = ''; }
        return ((title.indexOf(query.toLowerCase()) !== -1) || (description.indexOf(query.toLowerCase()) !== -1)) && item.tags.includes(filters.byMaterial[0]);
      });
    } else {
      filteredWithSearch = params.filter((item) => {
        if (item.title) { title = item.title[lang].toLowerCase(); } else { title = ''; }
        if (item.description) { description = item.description[lang].toLowerCase(); } else { description = ''; }
        return (title.indexOf(query.toLowerCase()) !== -1) || (description.indexOf(query.toLowerCase()) !== -1);
      });
    }
    if (query) {
      this.setState({ filtered: filteredWithSearch });
    } else {
      this.onHandleCollapsibleMenuSelect(filters);
    }
  }

  onClick = (slug, type) => {
    const { location: { pathname }, history } = this.props;
    history.push(`${pathname}?${type}=${slug}`);
    this.setState({ isModal: true });
  }

  getQueryVariable = (variable) => {
    const { location: { search } } = this.props;
    const vars = search.split('&');
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split('=');
      if (pair[0] === `?${variable}`) { return pair[1]; }
    }
    return (false);
  }

  render() {
    const {
      match,
      location: { pathname },
    } = this.props;
    const {
      filtered, isLoading, menu, isModal,
    } = this.state;
    return (
      <RoutesContext.Consumer>
        {
        ({ language: contextLanguage, enteredFrom }) => {
          const localization = getLocalization(contextLanguage, enteredFrom, match);
          return (
            <React.Fragment>
              {!isLoading && Object.keys(menu).length > 0
              && (
                <Filter {...menu} onHandleCollapsibleMenuSelect={this.onHandleCollapsibleMenuSelect} handleSearchInput={this.handleSearchInput} slugname={pathname.substring(1)} />
              )
              }
              <div className="flex flex-wrap md:-mx-2 lg:-mx-2 xl:-mx-2 mt-2">
                {filtered.length ? <ComponentIterator components={filtered} /> : <p className="w-full px-2">No content matched your criteria</p>}
              </div>
              {isModal ? (
                <Modal
                  handleClose={this.handleClose}
                  filtered={filtered}
                  match={match}
                  addHandler={this.addHandler}
                  slugname={pathname}
                  onClick={this.onClick}
                  type={filtered[0].type}
                />
              ) : null}
            </React.Fragment>
          );
        }
      }
      </RoutesContext.Consumer>
    );
  }
}

AssetsContainer.propTypes = {
  hasFilters: PropTypes.bool,
  showFilterAs: PropTypes.string,
  type: PropTypes.string.isRequired,
  history: PropTypes.shape({}).isRequired,
  menu: PropTypes.shape({}),
  location: PropTypes.shape({}).isRequired,
  slugnames: PropTypes.arrayOf(PropTypes.string),
};

AssetsContainer.defaultProps = {
  hasFilters: false,
  showFilterAs: 'dropdown',
  menu: {},
  slugnames: [],
};

export default withRouter(AssetsContainer);
