import gql from 'graphql-tag';

import { clientWeb } from '../graphqlAPI';

const GET_USER_COUNTRY = gql`
  query {
    getUserCountry {
      country
    }
  }
`;

const getUserCountry = async () => {
  try {
    const result = await clientWeb
      .query({
        query: GET_USER_COUNTRY,
        fetchPolicy: 'no-cache',
      })
      .then(queryResult => queryResult)
      .catch((queryError) => {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error: ${queryError}`);
        return null;
      });

    const { data: { getUserCountry: country } } = result;

    if (country) return country;
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: GraphQL Query Error: getComments`);
    return null;
  } catch (error) {
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: getComments Error: ${error}`);
    return null;
  }
};

export default getUserCountry;
