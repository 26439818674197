import gql from 'graphql-tag';

import { clientWeb, persistor } from '../graphqlAPI';


const GET_POSTS_LIST_QUERY = gql`
  query Get($category: [String], $to: Int!, $from: Int!) {
    getPostsList(category: $category, to: $to, from: $from){
      slugName
      postCategory
      active
      updatedAt
      noIndex
        postDescription{
          description{
            en es ca pt
          }
          title{
            en es ca pt
          }
          image{
            en es ca pt
          }
          tags
          itemLayout
          position
          url
          overlayImage
          date
          expirationDate
          cta {
            url
            text {
              en
              ca
              es
              pt
            }
            isDisabled
          }
        }
    }
  }
`;

const executeQuery = async (category, from, to, type = 'network') => {
  const result = await clientWeb.query({
    query: GET_POSTS_LIST_QUERY,
    variables: { category, from, to },
    fetchPolicy: type === 'network' ? 'network-only' : 'cache-only',
  })
    .then(queryResult => queryResult)
    .catch((queryError) => {
      const errorMessage = type === 'network'
        ? `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error: ${queryError}`
        : `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error after Cache Restore: ${queryError}`;
      console.log('\x1b[31m%s\x1b[0m', errorMessage);

      return null;
    });

  if (result.data && result.data.getPostsList) {
    const { data: { getPostsList: getPostsListData } } = result;
    return getPostsListData;
  }

  return [];
};

const getPostsList = async (category, from, to) => {
  try {
    const JSONObject = await executeQuery(category, from, to);

    if (persistor) {
      persistor.persist();
    }

    return JSONObject;
  } catch (jsonError) {
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: JSON Object Error: ${jsonError}`);

    if (persistor) {
      const persistorStatus = await persistor.restore().then(() => 'OK');
      console.log(`${new Date().toLocaleTimeString()} ~ Client: Apollo Cache Restored - ${persistorStatus}`);

      try {
        const JSONObject = await executeQuery(category, from, to, 'cache');

        return JSONObject;
      } catch (jsonErrorAfterCacheRestore) {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: JSON Object Error after Cache Restore, sending 404: ${jsonErrorAfterCacheRestore}`);
        return null;
      }
    }
    return null;
  }
};

export default getPostsList;
