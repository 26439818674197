import React from 'react';
import { PropTypes } from 'prop-types';
import {
  TimelineMax,
  Power1,
  TweenMax,
} from 'gsap';
import { withRouter } from 'react-router-dom';
import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';
import BodyChildContainer from '../../containers/BodyChildContainer';
import translateObj from '../../util/translateObj';

const coincidences = [
  {
    label: {
      en: 'Conciertos',
      ca: 'Conciertos',
      es: 'Conciertos',
    },
    url: {
      en: 'conciertos',
      ca: 'conciertos',
      es: 'conciertos',
    },
  },
  {
    label: {
      en: 'Barcelona',
      ca: 'Barcelona',
      es: 'Barcelona',
    },
    url: {
      en: 'barcelona',
      ca: 'barcelona',
      es: 'barcelona',
    },
  },
  {
    label: {
      en: 'Oporto',
      ca: 'Oporto',
      es: 'Oporto',
    },
    url: {
      en: 'oporto',
      ca: 'oporto',
      es: 'oporto',
    },
  },
];

function capitalizeFirstLetter(str) {
  if (!str) {
    return str;
  }
  return str.charAt(0).toUpperCase() + str.toLowerCase().slice(1);
}

class SearchOverlay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
      coincidence: false,
      selectedCoincidence: false,
    };
    this.input = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown, false);
    this.body = document.querySelector('body');
    this.body.setAttribute('style', 'overflow-y: hidden');
    this.animateOverlay('showOverlay');
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown, false);
    this.body.setAttribute('style', 'overflow-y: auto');
  }

  handleKeyDown = (e) => {
    if (e.keyCode === 27) {
      const { closeCallback } = this.props;
      closeCallback();
    }
  }

  handleChange = (localization, e) => {
    const query = e.target.value;
    let coincidence = false;
    let selectedCoincidence = false;
    if (query.length > 0) {
      this.setState({ query });
      coincidence = '';
      coincidences.forEach((option) => {
        if (option.label[localization.language]) {
          const optionLabel = option.label[localization.language];
          if (optionLabel.toLowerCase().startsWith(query.toLowerCase())) {
            coincidence = optionLabel;
            selectedCoincidence = option;
          }
        }
      });
    }

    this.setState({ query, coincidence, selectedCoincidence });
  }

  handleKeyPress = (localization, e) => {
    const { selectedCoincidence, query } = this.state;
    if (e.key === 'Enter' && selectedCoincidence) {
      const { searchCallback } = this.props;
      searchCallback(selectedCoincidence.url[localization.language], localization.language);
    } else {
      if (e.key === 'Enter') {
        const { searchCallback } = this.props;
        searchCallback(query, localization.language);
      }
    }
  }

  rotateClose = (entering = true) => {
    TweenMax.to('[data-name="searchoverlay"] [data-name="close"]', 0.4, {
      rotation: entering ? 180 : 0,
    });
  }

  animateOverlay(type = 'showOverlay') {
    if (type === 'showOverlay') {
      const ease = Power1.easeOut;
      const tl = new TimelineMax();

      tl.fromTo('[data-name="searchoverlay"] > div', 0.5, {
        autoAlpha: 0,
      }, {
        autoAlpha: 1,
        ease,
      });

      tl.fromTo('[data-name="searchoverlay"] [data-name="title"]', 0.7, {
        y: '20%',
      }, {
        y: '0%',
        ease,
      }, '-=0.2');
      tl.fromTo('[data-name="searchoverlay"] [data-name="title"]', 1, {
        autoAlpha: 0,
      }, {
        autoAlpha: 1,
        ease,
      }, '-=0.7');

      tl.fromTo('[data-name="searchoverlay"] [data-name="close"]', 0.7, {
        autoAlpha: 0,
        y: '20%',
      }, {
        autoAlpha: 1,
        y: '0%',
        ease,
      }, '-=0.4');

      tl.eventCallback('onComplete', () => {
        if (this.input.current) {
          this.input.current.focus();
        }
      });
    }

    if (type === 'hideOverlay') {
      const {
        closeCallback,
      } = this.props;
      const tl = new TimelineMax();

      tl.fromTo('[data-name="searchoverlay"]', 0.5, {
        autoAlpha: 1,
      }, {
        autoAlpha: 0,
      });

      tl.eventCallback('onComplete', () => {
        closeCallback();
      });
    }
  }

  render() {
    const {
      match, closeCallback,
    } = this.props;
    const title = {
      en: 'WRITE WHAT YOU WANT TO SEARCH AND PRESS ENTER',
      ca: 'ESCRIU EL QUE VOLS BUSCAR I PRESSIONA ENTER',
      es: 'ESCRIBE LO QUE QUIERES BUSCAR Y PRESIONA ENTER',
      pt: 'ESCREVA O QUE VOCÊ QUER PROCURAR E PRESSIONAR ENTRAR',
    };
    const text2 = {
      en: 'Empieza a escribir',
      ca: 'Empieza a escribir',
      es: 'Empieza a escribir',
    };
    return (
      <RoutesContext.Consumer>
        {
        ({ language: contextLanguage, enteredFrom }) => {
          const localization = getLocalization(contextLanguage, enteredFrom, match);

          const { query, coincidence } = this.state;
          return (
            <div className="fixed pin bg-black z-top transition-background-04s" data-name="searchoverlay">

              <div className="w-full flex flex-column items-center relative max-w-6xl mx-auto">
                <button
                  type="button"
                  data-name="close"
                  className="cursor-pointer absolute pin-t pin-r mr-4 mt-4 z-10"
                  onClick={closeCallback}
                  onMouseEnter={() => this.rotateClose(true)}
                  onMouseLeave={() => this.rotateClose(false)}
                >
                  <span className="font-icon icon-close text-white text-lg" />
                </button>
                <BodyChildContainer>
                  <div className="mt-48" data-name="title">
                    <span className="font-americaMonoBold uppercase text-white mb-8 inline-block">{translateObj(title, localization.language)}</span>
                    <div role="search" className="relative">
                      <span
                        className="text-110 sm:text-40 text-grey font-americaBlack select-none"
                      >
                        {coincidence ? capitalizeFirstLetter(coincidence) : ''}
                      </span>
                      <input
                        ref={this.input}
                        type="search"
                        className="outline-none font-americaBlack font-white text-110 sm:text-40 bg-transparent text-white absolute pin-t pin-l pin-r"
                        value={capitalizeFirstLetter(query)}
                        onChange={e => this.handleChange(localization, e)}
                        onKeyPress={e => this.handleKeyPress(localization, e)}
                      />
                    </div>
                  </div>
                </BodyChildContainer>
              </div>
            </div>
          );
        }
      }
      </RoutesContext.Consumer>
    );
  }
}

SearchOverlay.propTypes = {
  match: PropTypes.shape().isRequired,
  closeCallback: PropTypes.func.isRequired,
  // searchCallack: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  // coincidences: PropTypes.array.isRequired,
};

export default withRouter(SearchOverlay);
