import React from 'react';
import PropTypes from 'prop-types';

import HeaderRedesignButton from './HeaderRedesignButton';

const HeaderRedesignButtons = ({
  children,
  cta,
}) => (
  <section role="navigation" className="flex tablet:flex-1 desktop:flex-none flex-col items-end justify-end desktop:justify-start tablet:inline-flex tablet:flex-row tablet:flex-start tablet:mr-8 desktop:mr-0 tablet:pr-5 desktop:pr-0 desktop:ml-1">
    <nav className="mb-2 tablet:mb-0 pr-8 tablet:pr-0">
      {children}
    </nav>
    <HeaderRedesignButton text={cta.text} url={cta.url} />
  </section>
);

HeaderRedesignButtons.propTypes = {
  children: PropTypes.shape().isRequired,
  cta: PropTypes.shape({
    text: PropTypes.shape().isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default HeaderRedesignButtons;
