import gql from 'graphql-tag';

import { clientWeb } from '../graphqlAPI';

const EDIT_REUNION = gql`
  mutation Post($reunionId: String!, $payload: ReunionInput!) {
    editReunion(
      reunionId: $reunionId
      payload: $payload
      ){
        result
    }
}`;

const editReunion = async (formData) => {
  try {
    const result = await clientWeb.mutate({
      mutation: EDIT_REUNION,
      variables: {
        ...formData,
      },
      fetchPolicy: 'no-cache',
    })
      .then(queryResult => queryResult)
      .catch((queryError) => {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error: ${queryError}`);
        return null;
      });

    const { data: { editReunion: validatedFormData } } = result;

    if (validatedFormData) {
      return validatedFormData;
    }
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: GraphQL Query Error: validatedFormData = null`);
    return null;
  } catch (error) {
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: editReunion Error: ${error}`);
    return null;
  }
};

export default editReunion;
