import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useAuth } from '@primaveralabs/auth';
import getNewsletterSettings from '../../../api/graphql/queries/getNewsletterSettings';
import postNewsletterForm from '../../../api/graphql/mutations/postNewsletterForm';
import profileUserEdit from '../../../api/graphql/mutations/profileUserEdit';
import PreferencesBoard from './PreferencesBoard';

const PreferencesBoards = ({
  genresTitle,
  interests,
  noGenres,
  festivalsTitle,
  festivals,
  noFestivals,
}) => {
  const { user } = useAuth();

  // Genres
  const [brazeGenres, setBrazeGenres] = useState([]);
  const [selectedGenres, setSelectedGenres] = useState([]);

  // Festivals
  const [brazeFestivals, setBrazeFestivals] = useState([]);
  const [selectedFestivals, setSelectedFestivals] = useState([]);

  const loadBrazeData = () => {
    getNewsletterSettings(user.email).then((res) => {
      if (res) {
        // Festivals
        const festivalsValues = festivals.map((f) => f.value);
        const brazeFestivalsValues = res.result.custom_fields.filter((i) => festivalsValues.includes(i.name) && i.value === '1').map((f) => f.name) || [];
        setBrazeFestivals(brazeFestivalsValues);
        setSelectedFestivals(brazeFestivalsValues);

        // Genres
        const brazeGenresValues = res.result.custom_fields.find((i) => i.name === 'registration_interest_genres')?.value.split(',') || [];
        setBrazeGenres(brazeGenresValues);
        setSelectedGenres(brazeGenresValues);
      }
    });
  };

  const unsubscribeScopesToBraze = (scopes) => {
    postNewsletterForm({
      email: user.email,
      scopes,
      isSubscribing: false,
      // unsubscribe: true, // Scopes should not unsubscribe completely from braze, just toggle the scope
    }).then(() => {
      // update braze scopes state
      setBrazeFestivals(selectedFestivals);
    });
  };

  const subscribeScopesToBraze = () => {
    // Get values to subscribe / unsubscribe
    const subscribeScopes = selectedFestivals.filter((value) => !brazeFestivals.includes(value));
    const unsubscribeScopes = brazeFestivals.filter((value) => !selectedFestivals.includes(value));

    if (subscribeScopes.length > 0) {
      // subscribe scopes first if any
      postNewsletterForm({
        email: user.email,
        scopes: subscribeScopes,
        isSubscribing: true,
      }).then(() => {
        if (unsubscribeScopes.length > 0) {
          // then unsubscribe scopes if any
          unsubscribeScopesToBraze(unsubscribeScopes);
        } else {
          // update braze scopes state (if no scopes to unsubscribe)
          setBrazeFestivals(selectedFestivals);
        }
      });
    } else if (subscribeScopes.length === 0 && unsubscribeScopes.length > 0) {
      // unsubscribe scopes first if any (if no scopes tu subscribe)
      unsubscribeScopesToBraze(unsubscribeScopes);
    }
  };

  const editCustomFields = (type) => {
    const values = {
      userPreferences: { musicalInterests: selectedGenres },
      userFestivals: selectedFestivals,
    };
      // send festivals scopes to Braze
    if (type === 'festivals') {
      subscribeScopesToBraze();
    }

    // edit user preferences
    profileUserEdit({ email: user.email, customFields: values }).then(() => {
      if (type === 'genres') {
        setBrazeGenres(selectedGenres);
      }
    }).catch((err) => console.log(err));
  };

  useEffect(() => {
    loadBrazeData();
  }, []);

  return (
    <>
      {/* Genres board */}
      <PreferencesBoard
        gridAreaName="genres"
        title={genresTitle}
        previousSelection={brazeGenres}
        selected={selectedGenres}
        allItems={interests.genres}
        noItems={noGenres}
        onChange={(values) => setSelectedGenres(values)}
        saveCallback={() => editCustomFields('genres')}
      />
      {/* Festivals board */}
      <PreferencesBoard
        gridAreaName="festivals"
        title={festivalsTitle}
        previousSelection={brazeFestivals}
        selected={selectedFestivals}
        allItems={festivals}
        noItems={noFestivals}
        onChange={(values) => setSelectedFestivals(values)}
        saveCallback={() => editCustomFields('festivals')}
      />
    </>
  );
};

PreferencesBoards.propTypes = {
  genresTitle: PropTypes.shape({}).isRequired,
  interests: PropTypes.shape({
    genres: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  noGenres: PropTypes.shape({}).isRequired,
  festivalsTitle: PropTypes.shape({}).isRequired,
  festivals: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  noFestivals: PropTypes.shape({}).isRequired,
};

export default PreferencesBoards;
