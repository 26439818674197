import getSection from '../api/graphql/queries/getSection';
import getPostsList from '../api/graphql/queries/getPostsList';
import getLineup from '../api/graphql/queries/getLineup';

import getRadioShowsNow from '../api/graphql/queries/getRadioShowsNow';

import getRadioShows from '../api/graphql/queries/getRadioShows';
import getMamObjectsBySlugNameQuery from '../api/graphql/queries/getMamObjectsBySlugname';
import getRadioShowsEpisodes from '../api/graphql/queries/getRadioShowsEpisodes';
import getRadioShowsEpisodesForEpisode from '../api/graphql/queries/getRadioShowsEpisodesForEpisode';

import ComponentQuery from '../containers/ComponentQuery';

//const languageParameter = `/:lang(${(process.env.ACCEPTED_LANGUAGES.replace(",", "|") || 'en|es|ca|pt')})` // -> "/:lang(en|es|ca|pt)"
const languageParameter = `/:lang(en|es|ca|pt)` // -> "/:lang(en|es|ca|pt)"

// React-Router routes for rootLanguage
const routesLanguageAccess = [
  {
    path: '/',
    exact: true,
    component: ComponentQuery,
    initGetSection: ({ cookies }) => getSection('home', cookies),
    initGetPostsList: () => getPostsList('home', 0, 9),
    initGetRadioShowsNow: () => getRadioShowsNow(),
  },
  {
    path: languageParameter,
    exact: true,
    component: ComponentQuery,
    initGetSection: ({ cookies }) => getSection('home', cookies),
    initGetPostsList: () => getPostsList('home', 0, 9),
    initGetRadioShowsNow: () => getRadioShowsNow(),
  },
  {
    path: `${languageParameter}/radio`,
    exact: true,
    component: ComponentQuery,
    initGetSection: ({ cookies }) => getSection('radio', cookies),
    initGetRadioShowsNow: () => getRadioShowsNow(),
    initGetRadioShowsHighlights: () => getRadioShows({ highlight: true, limit: 2 }),
    initGetRadioShowsEpisodes: () => getRadioShows({ limit: 8, type: 'episodes' }),
    initGetRadioShows: () => getRadioShows({ limit: 8, type: 'shows' }),
  },
  {
    path: `${languageParameter}/radio/shows`,
    exact: true,
    component: ComponentQuery,
    initGetSection: ({ cookies }) => getSection('radio-shows', cookies),
    initGetRadioShowsNow: () => getRadioShowsNow(),
    initGetRadioShows: () => getRadioShows({ limit: 20, type: 'shows' }),
  },
  {
    path: `${languageParameter}/radio/explore`,
    exact: true,
    component: ComponentQuery,
    initGetSection: ({ cookies }) => getSection('radio-explore', cookies),
    initGetRadioShowsNow: () => getRadioShowsNow(),
  },
  {
    path: `${languageParameter}/radio/podcasts`,
    exact: true,
    component: ComponentQuery,
    initGetSection: ({ cookies }) => getSection('podcasts', cookies),
    initGetRadioShowsNow: () => getRadioShowsNow(),
  },
  {
    path: `${languageParameter}/radio/explore/:tag`,
    exact: true,
    component: ComponentQuery,
    initGetSection: ({ cookies }) => getSection('radio-explore', cookies),
    initGetRadioShowsNow: () => getRadioShowsNow(),
  },
  {
    path: `${languageParameter}/radio/:channel`,
    exact: true,
    component: ComponentQuery,
    initGetSection: ({ cookies }) => getSection('radio-channel', cookies),
    initGetRadioShowsNow: () => getRadioShowsNow(),
  },
  {
    path: `${languageParameter}/radio/shows/:post`,
    exact: true,
    component: ComponentQuery,
    initGetSection: ({ cookies }) => getSection('radio-show', cookies),
    initGetRadioShowsNow: () => getRadioShowsNow(),
    initGetSingleRadioShow: (path = '') => getRadioShows({ slug: path.split('/').pop() }),
    initGetSingleRadioShowRelated: () => getRadioShows({ limit: 3 }),
    initGetSingleRadioShowEpisodes: (path = '') => getRadioShowsEpisodesForEpisode({ parentSlug: path.split('/').pop() }),
  },
  {
    path: `${languageParameter}/radio/show/:post`,
    redirectTo: { from: '/radio/show/', to: '/radio/shows/' },
  },
  {
    path: `${languageParameter}/radio/shows/:post/:episode`,
    exact: true,
    component: ComponentQuery,
    initGetSection: ({ cookies }) => getSection('radio-episode', cookies),
    initGetRadioShowsNow: () => getRadioShowsNow(),
    initGetSingleRadioEpisode: (path = '') => getRadioShows({ slug: path.split('/').pop() }),
    initGetSingleRadioEpisodeRelated: (parentSlug = '') => getRadioShowsEpisodesForEpisode({ parentSlug, limit: 3 }),
    initGetSingleRadioShowTitle: (slug = '') => getRadioShows({ slug }),
    initGetFeaturedRadioShows: () => getRadioShows({ limit: 4 }),
  },
  {
    path: `${languageParameter}/radio/show/:post/:episode`,
    redirectTo: { from: '/radio/show/', to: '/radio/shows/' },
  },
  {
    path: `${languageParameter}/share/:assetSlugName`,
    exact: true,
    component: ComponentQuery,
    initGetSection: ({ cookies }) => getSection('share-asset', cookies),
    initGetSharedAsset: slugnames => getMamObjectsBySlugNameQuery(slugnames),
    initGetRadioShowsNow: () => getRadioShowsNow(),
  },
  {
    path: `${languageParameter}/:section`,
    exact: true,
    component: ComponentQuery,
    initGetSection: ({ path, cookies }) => getSection(path.split('/').pop(), cookies),
    initGetPostsList: path => getPostsList(path.split('/').pop(), 0, 9),
    initGetLineup: () => getLineup('primavera-sound-2019-barcelona'),
    initGetRadioShowsNow: () => getRadioShowsNow(),
  },
  {
    path: `${languageParameter}/:section/:post`,
    exact: true,
    component: ComponentQuery,
    initGetSection: ({ path, cookies }) => getSection(path.split('/').pop(), cookies),
    initGetRadioShowsNow: () => getRadioShowsNow(),
  },
  {
    path: '/:section',
    exact: true,
    component: ComponentQuery,
    initGetSection: ({ path, cookies }) => getSection(path, cookies),
    //initGetPostsList: () => getPostsList('home', 0, 9),
    //initGetRadioShowsNow: () => getRadioShowsNow(),
  },
  {
    path: '/:section/:post',
    exact: true,
    component: ComponentQuery,
    initGetSection: ({ path, cookies }) => getSection(path, cookies),
    //initGetPostsList: () => getPostsList('home', 0, 9),
    //initGetRadioShowsNow: () => getRadioShowsNow(),
  },
  // {
  //   path: '/:lang/:section/:post/',
  //   exact: false,
  //   component: ComponentQuery,
  //   initGetSection: ({ path, cookies }) => getSection(path.split('/').pop(), cookies),
  // },
];

export default routesLanguageAccess;
