/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react';
import { PropTypes } from 'prop-types';
// import parse from 'html-react-parser';
import { withRouter, Link } from 'react-router-dom';
import moment from 'moment';
// import LazyLoad from 'react-lazy-load';
import ImageLoader from '../Launcher/ImageLoader';
import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';
import imageModifier from '../helpers/imageModifier';
import stripTags from '../helpers/stripTags';
import RadioTag from '../Buttons/RadioTag';
import RadioItemType from '../RadioItemType';
import FavouriteStar from '../FavouriteStar';
import RadioPlayButton from './RadioPlayButton';
import translateObj from '../../../util/translateObj';

const ARIA_LABEL_SPOTIFY = {
  en: 'Open in Spotify',
  es: 'Abrir en Spotify',
  ca: 'Obrir a Spotify',
  pt: 'Abrir no Spotify',
};

const RadioEpisodeCard = ({
  match, title_multilang, program_title_multilang, parentTitle_multilang, radioItemType, slug, parentSlug, tags, dateAir, image, favouriteStar, text_multilang, mixcloud, tags_array_multilang, id, lang,
}) => (
  <RoutesContext.Consumer>
    {({ language: contextLanguage, enteredFrom }) => {
      const localization = getLocalization(contextLanguage, enteredFrom, match);
      moment.locale(localization.language);
      const strippedText = text_multilang && Object.keys(text_multilang).length ? stripTags(translateObj(text_multilang, localization.language)).substring(0, 80) : '';
      let tagsArray = [];
      if (Array.isArray(tags_array_multilang)) {
        tagsArray = tags_array_multilang;
      } else {
        tagsArray.push(tags_array_multilang);
      }
      return (
        <div className="w-full bg-white rounded-lg">
          <div className="relative">
            <div className="flex flex-col justify-center relative h-cardlg overflow-hidden bg-grey sliderComponent">
              {image && (
              // <LazyLoad debounce={false}>
              <ImageLoader className="min-w-full noBackgroundImgGray object-cover" src={imageModifier(image, '666x500')} alt={translateObj(title_multilang, localization.language)} />
              // </LazyLoad>
              )}
              <div role="button" tabIndex="0" aria-label={translateObj(ARIA_LABEL_SPOTIFY, localization.language)} className="absolute pin-b pin-l mb-4 ml-4 z-10 cursor-pointer">{mixcloud && <RadioPlayButton channel={mixcloud} />}</div>
            </div>
            <div className="p-4 md:p-2 lg:p-2 text-black flex flex-col justify-between sm:h-auto h-48 md:h-56 lg:h-56">
              <div tabIndex="0" className="flex justify-between mt-1">
                {dateAir && <span className="font-americaMonoRegular text-12">{moment(dateAir).format('DD / MM / YY')}</span>}
                {lang ? (
                  <span className="font-americaMonoRegular text-12">
                      Audio:
                    {' '}
                    {lang}
                  </span>
                ) : null}
              </div>
              <div className="mb-2">
                <Link className="text-black" to={`${localization.path}/radio/shows/${parentSlug}/${slug}`}>
                  <h2 className="font-americaBlack text-xl lg:text-lg mt-1 mb-2 h-12 sm:mt-2">
                    {translateObj(program_title_multilang || parentTitle_multilang, localization.language)}
                    {' '}
                  </h2>
                  <p style={{ minHeight: '60px' }}>
                    {translateObj(title_multilang, localization.language)}
                  </p>
                </Link>
              </div>
              {tags_array_multilang && <div className="flex flex-wrap sm:mb-2">{tagsArray.map((tag, i) => tag[localization.language] && <Link key={tag.en + id} to={`${localization.path}/radio/explore/${tag.en}`}><RadioTag category="transparent" text={tag} textColor="black" borderColor="black" margin="py-0 font-americaMonoRegular" /></Link>)}</div>}
              <div className="flex justify-between items-center absolute pin-b w-full pin-l px-4 pb-1">
                {radioItemType ? <RadioItemType title={radioItemType} /> : null}
                {/* <FavouriteStar favourite={favouriteStar} /> */}
              </div>
            </div>
          </div>
        </div>
      );
    }}
  </RoutesContext.Consumer>
);

RadioEpisodeCard.propTypes = {
  match: PropTypes.shape().isRequired,
  title_multilang: PropTypes.shape().isRequired,
  dateAir: PropTypes.string,
  image: PropTypes.string.isRequired,
  favouriteStar: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  tags: PropTypes.array,
  // eslint-disable-next-line react/require-default-props
  radioItemType: PropTypes.shape(),
  program_title_multilang: PropTypes.shape().isRequired,
  parentTitle_multilang: PropTypes.shape().isRequired,
};

RadioEpisodeCard.defaultProps = {
  favouriteStar: false,
  tags: [],
  dateAir: null,
};

export default withRouter(RadioEpisodeCard);
