import React, { useState, useEffect } from 'react';
import LoadIcon from '../LoadIcon';
import { useGetPaymentDetails } from './paymentsUtils/paymentHooks';
import { getCustomerPaymentMethods, getProductDetails } from './paymentsUtils/paymentFunctions';
import StripeAddCardScreen from './StripeAddCardScreen';
import StripeSelectCardScreen from './StripeSelectCardScreen';
import PsWorldStripeCardFormWrapper from './PsWorldStripeCardFormWrapper';
import PayWithStoredCardStripeWrapper from './PayWithStoredCardStripeWrapper';

const productSlugName = 'donation';

const StripeDonationModalScreen = (props) => {
  const [product, setProduct] = useState(0);
  const [productType, setProductType] = useState('');
  const [amount, setAmount] = useState('');
  const [currency, setCurrency] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [currentStatus, setcurrentStatus] = useState('loading');
  const [currentPaymentMethods, setPaymentMethods] = useState(null);
  const [customerEmail, setCustomerEmail] = useState(null);
  const [currentCustomerInfo, setCustomerInfo] = useState(null);
  const {
    paymentDetailsStatus = 'loading', paymentMethods = null, customerInfo = null, userEmail = null,
  } = useGetPaymentDetails();

  useEffect(() => {
    setCustomerEmail(userEmail);
    setCustomerInfo(customerInfo);
    setPaymentMethods(paymentMethods);
    setcurrentStatus(paymentDetailsStatus);
  }, [paymentDetailsStatus]);

  const obtainProductDetails = async () => {
    const {
      productFromQuery,
      productTypeFromQuery,
      amountFromQuery,
      currencyFromQuery,
    } = await getProductDetails(productSlugName);
    setProduct(productFromQuery);
    setProductType(productTypeFromQuery);
    setAmount(amountFromQuery);
    setCurrency(currencyFromQuery);
  };

  useEffect(() => {
    if (productSlugName) {
      console.log('productSlugName', productSlugName);
      // Step 1: Fetch product details such as amount and currency from API
      obtainProductDetails();
    }
  }, [productSlugName]);

  const handleDonationModalStatus = (status) => {
    setcurrentStatus(status);
  };

  const storeNewCardResult = async (result) => {
    const { status } = result;

    if (status === 'succeeded') {
      const { getCustomerPaymentStatus, customerPaymentMethods } = await getCustomerPaymentMethods(customerEmail, currentCustomerInfo);
      setPaymentMethods(customerPaymentMethods);
      setcurrentStatus(getCustomerPaymentStatus);
    } else {
      setErrorMessage('unable to add card');
    }
  };

  const payWithStoredCardResult = (result) => {
    const { status } = result;

    if (status === 'succeeded') {
      setcurrentStatus('paid');
    } else {
      setErrorMessage('Payment failed');
    }
  };

  const renderModalScreen = () => {
    switch (currentStatus) {
      case 'loading':
        return (
          <LoadIcon color="black" type="ThreeDots" />
        );
      case 'noPaymentMethods':
        return (
          <StripeAddCardScreen {...props} handleDonationModalStatus={handleDonationModalStatus} />
        );
      case 'addCard':
        return (
          <div className="p-8 sm:p-2 w-full">
            <PsWorldStripeCardFormWrapper customerInfo={customerInfo} product={product} resultCallback={storeNewCardResult} />
          </div>
        );
      case 'hasAPaymentMethod':
        return (
          <PayWithStoredCardStripeWrapper {...props} customerInfo={customerInfo} paymentMethodLast4={currentPaymentMethods.length > 0 && currentPaymentMethods[0].card.last4} paymentMethodId={currentPaymentMethods.length > 0 && currentPaymentMethods[0].id} product={product} resultCallback={payWithStoredCardResult} handleDonationModalStatus={handleDonationModalStatus} />
        );
      case 'hasMultiplePaymentMethods':
        return (
          <StripeSelectCardScreen {...props} handleDonationModalStatus={handleDonationModalStatus} paymentMethods={paymentMethods} />
        );
      case 'paid':
        return (
          <div>Thanks for your donation</div>
        );
      default:
        return (
          <LoadIcon color="black" type="ThreeDots" />
        );
    }
  };

  return (
    <div
      className="bg-gray200 w-full min-h-screen-40 flex-col flex items-center justify-center"
    >
      {renderModalScreen()}
      <p className="text-red">{errorMessage}</p>
    </div>

  );
};

export default StripeDonationModalScreen;
