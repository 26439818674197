/* eslint-disable camelcase */
import React from 'react';
import { PropTypes } from 'prop-types';
import FilePlayer from 'react-player';

const StreamingVideoPlayerMainVimeo = ({
  mainVideo, activeSlide,
}) => (
  <FilePlayer
    url={mainVideo}
    playing={false}
    controls
    loop
    pip={Boolean(activeSlide)}
    height="100%"
    width="100%"
    muted={false}
    config={{
      autoPlay: false,
    }}
  />
);

StreamingVideoPlayerMainVimeo.propTypes = {
  mainVideo: PropTypes.string.isRequired,
  activeSlide: PropTypes.number,
};

StreamingVideoPlayerMainVimeo.defaultProps = {
  activeSlide: 0,
};

export default StreamingVideoPlayerMainVimeo;
