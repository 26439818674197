
import React from 'react';

import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';

import ParagraphLineSM from '../Text/ParagraphLineSM';
import ControlledButton from '../Buttons/ControlledButton';
import DropDownMenu from './DropDownMenu';

import SelectableOptions from '../SelectableOptions';
import InputTextButton from '../InputTextButton';

const ExpandLess = () => <span className="font-icon icon-arrow-up text-white text-xs" />;

const ExpandMore = () => <span className="font-icon icon-arrow-down font-black text-white text-xs" />;
// const { isFavoritesActive } = process.env;
const isFavoritesActive = true;

const visualizationTypeOptions = [
  {
    action: 'alphabetical',
    text: {
      es: 'a-z',
      en: 'a-z',
      ca: 'a-z',
    },
  },
  {
    action: 'list',
    text: {
      es: 'listado',
      en: 'list',
      ca: 'llistat',
    },
  },
  {
    action: 'grid',
    text: {
      es: 'parrilla',
      en: 'grid',
      ca: 'graella',
    },
  },
];

const visualizationOptions = [];

if (isFavoritesActive) {
  visualizationOptions.push([{ text: 'VER TODOS' }, { text: 'VER FAVORITOS' }, { text: 'PARRILLA' }]);
} else {
  visualizationOptions.push([{ text: 'VER TODOS' }, { text: 'PARRILLA' }]);
}


const getStyle = (selected, listItemStyle) => {
  if (selected) {
    return {
      color: 'white',
      backgroundColor: 'black',
    };
  }

  if (listItemStyle.backgroundColor === 'black') return { color: 'white' };

  return {};
};


class CollapsibleMenu extends React.Component {
  state = {
    dropDown: '',
  };

  handleClick = (type) => {
    let dropDown = type;
    this.setState((state) => {
      if (dropDown === state.dropDown) dropDown = '';
      return { dropDown };
    });
  };


  render() {
    const {
      title,
      filters: { byDate, byOtherDate, byStage },
      options: {
        view, all, grid, list, favorites,
      },
      bottom: { text, inputParams },
      filterItems,
    } = this.props;
    const { dropDown } = this.state;

    const listItemStyle = {
      borderBottom: '1px white',
      backgroundColor: 'black',
      color: 'white',
    };
    return (
      <div className="font-americaMonoRegular font-light w-full p-8">


        <Divider />

        <React.Fragment key={title.en}>


          <ListItem
            style={{
              ...listItemStyle,
              ...getStyle(dropDown === title.en, listItemStyle),
              padding: 30,
              height: 74,
              borderBottom: dropDown.length > 0 ? 'dashed 1px white' : '',
            }}
            value={title.en}
            button
            onClick={() => this.handleClick(title.en)}
          >
            <ListItemText
              primary={(
                <span
                  className="font-americaMonoBold text-base"
                  style={
                    getStyle(dropDown === title.en, listItemStyle)
                  }
                >
                  {title.en.toUpperCase()}
                </span>
              )}
            />
            <span className="mr-4">
              {dropDown === title.en ? <ExpandLess /> : <ExpandMore />}
            </span>
          </ListItem>


          <Collapse in={dropDown === title.en} timeout="auto" unmountOnExit>
            <div className="p-8 bg-black">
              <div className="mb-6">
                <ParagraphLineSM
                  classes="text-white uppercase"
                  text={byDate}
                />
              </div>
              <div className="flex">
                {filterItems.map(item => (
                  <ControlledButton
                    params={item}
                  />
                ))}
                <DropDownMenu
                  className="h-14"
                  menuTitle={byOtherDate.en}
                  menuItems={
                    filterItems
                  }
                />
              </div>
              <div className="mt-16 flex">
                <div className="flex flex-col justify-between pb-2  md:text-xs sm:text-xxs ">
                  <ParagraphLineSM
                    classes="text-white uppercase"
                    text={byStage}
                  />
                  <DropDownMenu
                    menuTitle={byStage.en}
                    menuItems={
                      filterItems
                    }
                  />
                </div>
                <div className="flex ml-16">
                  <SelectableOptions options={visualizationTypeOptions} hasBorder title={view} />
                  <SelectableOptions options={visualizationOptions} />
                </div>
              </div>
            </div>
            <div className="flex items-center bg-white h-32 ">
              <div className=" mx-10 font-americaMonoMedium  md:text-xs sm:text-xxs ">{text.en}</div>
              <div className="flex-1 mx-10 md:text-xs sm:text-xxs ">
                <InputTextButton params={inputParams} />
              </div>
            </div>
          </Collapse>

        </React.Fragment>
        <Divider />

      </div>
    );
  }
}

CollapsibleMenu.propTypes = {
  classes: PropTypes.shape({}),
  listItemStyle: PropTypes.object,
  params: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.shape({
      en: PropTypes.string.isRequired,
      es: PropTypes.string.isRequired,
      content: PropTypes.array,
    }).isRequired,
  })),
  children: PropTypes.arrayOf(PropTypes.shape({})),
};

export default CollapsibleMenu;
