import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Formsy from 'formsy-react';
import { buttonClasses } from '../OnDemandUtils/onDemandUtils';
import translateObj from '../../../../util/translateObj';
import getCountryNamesByCode, { getCodeFromCountry } from '../../../../util/getCountryNamesByCode';

import signupUserForm from '../../../../api/graphql/mutations/signupUserForm';

import FormInput from '../../Form/FormInput';
import FormCheckbox from '../../Form/FormCheckbox';
import FormCheckboxConditions from '../../Form/FormCheckboxConditions';
import FormSelectAutoComplete from '../../Form/FormSelectAutoComplete';
import DatePickerFormsy from '../../Login/DatePickerFormsy';
import LoadIcon from '../../LoadIcon';
import { useUser } from '../../../../context/userProfileContext';

export const defaultConditionsText = {
  acceptText: {
    es: 'Acepto las ',
    en: 'I accept the ',
    ca: 'Accepto les ',
    pt: 'I accept the ',
  },
  conditionsText: {
    es: 'CONDICIONES DE ACCESO',
    en: 'ACCESS CONDITIONS',
    ca: 'CONDICIONS D’ACCÉS',
    pt: 'ACCESS CONDITIONS',
  },
  conditionsLink: {
    es: 'https://www.primaverasound.com/es/user-register-conditions-for-primavera-sound-barcelona',
    en: 'https://www.primaverasound.com/en/user-register-conditions-for-primavera-sound-barcelona',
    ca: 'https://www.primaverasound.com/ca/user-register-conditions-for-primavera-sound-barcelona',
    pt: 'https://www.primaverasound.com/pt/user-register-conditions-for-primavera-sound-barcelona',
  },
  dataTreatmentText: {
    es: ' y el tratamiento de datos y la elaboración de perfiles según la ',
    en: ' and data processing and profiling according to the ',
    ca: " i el tractament de dades i l'elaboracio de perfils segons la ",
    pt: ' and data processing and profiling according to the ',
  },
  privacyPolicyText: {
    es: 'POLÍTICA DE PRIVACIDAD',
    en: 'PRIVACY POLICY',
    ca: 'POLÍTICA DE PRIVACITAT',
    pt: 'PRIVACY POLICY',
  },
  privacyPolicyUrl: {
    es: 'https://www.primaverasound.com/es/privacy-policy',
    en: 'https://www.primaverasound.com/en/privacy-policy',
    ca: 'https://www.primaverasound.com/ca/privacy-policy',
    pt: 'https://www.primaverasound.com/pt/privacy-policy',
  },
};

const OnDemandModalRegister = (props) => {
  const {
    loadTokenForLS, handleOnDemandScreenStatus, translations, translations: { registerModal: formText = {} } = {}, language, userSocialInfo: { profileObj: preFilledValues = {} } = {},
  } = props;

  const refForm = useRef(null);
  const refName = useRef(null);
  const refLastName = useRef(null);
  const refEmail = useRef(null);
  const refPostalCode = useRef(null);
  const refCountry = useRef(null);
  const refPassword = useRef(null);
  const refRepeatPassword = useRef(null);

  const [canSubmit, setCanSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [currentValues, setCurrentValues] = useState(null);
  const user = useUser();

  useEffect(() => {
    if (Object.keys(preFilledValues).length) {
      const {
        isSocialLogin, givenName, familyName, email, googleId, facebookId, spotifyId,
      } = preFilledValues;
      const currentValuesObj = {};

      // TODO: unify values instead of using a switch
      switch (isSocialLogin) {
        case 'google':
          currentValuesObj.name = givenName;
          currentValuesObj.lastname = familyName;
          currentValuesObj.email = email;
          currentValuesObj.isSocialLogin = isSocialLogin;
          currentValuesObj.oauthId = googleId;
          break;
        case 'facebook':
          currentValuesObj.name = givenName;
          currentValuesObj.lastname = familyName;
          currentValuesObj.email = email;
          currentValuesObj.isSocialLogin = isSocialLogin;
          currentValuesObj.oauthId = facebookId;
          break;
        case 'spotify':
          currentValuesObj.name = givenName;
          currentValuesObj.lastname = familyName;
          currentValuesObj.email = email;
          currentValuesObj.isSocialLogin = isSocialLogin;
          currentValuesObj.oauthId = spotifyId;
          break;
        default:
          break;
      }
      setCurrentValues(currentValuesObj);
    }
  }, [preFilledValues]);

  const enableButton = () => {
    setCanSubmit(true);
  };
  const disableButton = () => {
    setCanSubmit(false);
  };

  const onReset = () => {
    refForm.reset();
    refName.setValue('');
    refLastName.setValue('');
    refEmail.setValue('');
    refPostalCode.setValue('');
    refCountry.setValue('');
    refPassword.setValue('');
    refRepeatPassword.setValue('');
  };

  const handleDate = (name, date) => {
    setDateOfBirth(date);
  };

  const submitForm = (formData, lang, socialnetwork) => {
    const { spotifyToken } = preFilledValues;
    setCanSubmit(false);
    setIsLoading(true);

    const formDataUpdated = {
      ...formData, language, dateOfBirth, socialnetwork,
    };

    // * add country ISO code instead of country name
    let countryISOCode = '';
    if (formData && formData.inputCountry) {
      countryISOCode = getCodeFromCountry(formData.inputCountry, language) || 'ES';
    }

    if (countryISOCode) {
      formDataUpdated.inputCountry = countryISOCode;
    }

    // add spotify initial code in order to sign in user
    if (socialnetwork === 'spotify') {
      formDataUpdated.spotifyToken = spotifyToken;
    }

    signupUserForm({ ...formDataUpdated }).then((response) => {
      if (response) {
        if (response.isValid) {
          // this.onReset();
          setCanSubmit(true);
          user.setNewAuthToken(response.token);
          setIsLoading(false);
          // !!! need to check this no need to handle ondemandscreenstatus as userEmail in context is being updated already
          // handleOnDemandScreenStatus('logged');

          // Redirect to payment page
          // history.push(`/${language}`);
        } else if (response.error) {
          formData.email = '';
          setCurrentValues(formData);
          setErrorMsg(response.error);
          setIsLoading(false);
        } else {
          console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Error - submitForm validated, but returned isValid = false!`);
        }
      } else {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Error - submitForm failed to submit!`);
      }
    });
  };

  const registerFormRender = () => {
    if (isLoading) return (<LoadIcon height={15} width={15} color="black" type="ThreeDots" />);

    return (
      <>
        <div className="w-full font-americaMonoRegular text-12 uppercase bg-transparent pt-8">
          <Formsy
            ref={refForm}
            onValidSubmit={e => submitForm(e, language, currentValues && currentValues.isSocialLogin)}
            onValid={enableButton}
            onInvalid={disableButton}
            className="flex sm:flex-col md:flex-col flex-wrap w-full"
          >
            <div className="w-full flex sm:block ">
              <FormInput
                ref={refName}
                backgroundColor="white"
                name="name"
                doNotChangeValidColor
                title={translateObj(formText.name, language)}
                validations={{ isSpecialWords: true, maxLength: 50 }}
                validationErrors={{ isSpecialWords: translateObj(formText.inValidName, language), maxLength: translateObj(formText.longName, language) }}
                value={(currentValues && currentValues.name) || ''}
                required
                className="sm:w-full w-1/2"
              />
              <FormInput
                ref={refLastName}
                backgroundColor="white"
                name="lastname"
                doNotChangeValidColor
                title={translateObj(formText.lastname, language)}
                validations={{ isSpecialWords: true, maxLength: 50 }}
                validationErrors={{ isSpecialWords: translateObj(formText.inValidName, language), maxLength: translateObj(formText.longName, language) }}
                value={(currentValues && currentValues.lastname) || ''}
                required
                className="sm:w-full w-1/2"
              />
            </div>

            <div className="w-full flex sm:block ">
              <FormInput
                ref={refEmail}
                backgroundColor="white"
                name="email"
                doNotChangeValidColor
                isError={Boolean(errorMsg)}
                title={translateObj(formText.email, language)}
                validations={{ isEmail: true, maxLength: 100 }}
                validationErrors={{ isEmail: translateObj(formText.inValidEmail, language), maxLength: translateObj(formText.longEmail, language) }}
                disabled={(currentValues && currentValues.email)}
                value={(currentValues && currentValues.email) || ''}
                required
                className="sm:w-full w-full"
                // className="sm:w-full w-1/2"
              />
            </div>

            <div className="w-full flex sm:block ">
              {/* <DatePickerFormsy
                title={formText.birthDateText}
                name="birthDate"
                value={(currentValues && currentValues.birthDate) || ''}
                handleDate={handleDate}
                existingDate=""
                language={language}
                backgroundColor="white"
                selectDateMessage={formText.selectDateMessage}
                required
                style="sm:w-full w-1/2 pb-4"
              /> */}
              <FormInput
                ref={refPostalCode}
                backgroundColor="white"
                name="postalCode"
                value={currentValues && currentValues.postalCode || ''}
                doNotChangeValidColor
                title={translateObj(formText.postalcode, language)}
                validations={{ maxLength: 10 }}
                validationError={translateObj(formText.invalidpostalcode, language)}
                className="sm:w-full w-1/2"
                required
              />
              <FormSelectAutoComplete
                backgroundColor="white"
                ref={refCountry}
                name="inputCountry"
                value={(currentValues && currentValues.inputCountry) || ''}
                title={translateObj(formText.country, language)}
                validations="isSpecialWords"
                validationError=""
                options={getCountryNamesByCode(language)}
                message={translateObj(formText.countryError, language)}
                required
              />
            </div>

            {!currentValues || !currentValues.isSocialLogin
              ? (
                <div className="w-full flex sm:block">
                  <FormInput
                    ref={refPassword}
                    backgroundColor="white"
                    name="password"
                    title={translateObj(formText.password, language)}
                    validations={{ minLength: 8 }}
                    validationError={formText.shortPassword[language]}
                    type="password"
                    required
                    className="sm:w-full w-1/2"
                    titleClassName="flex uppercase"
                    infoText={translateObj(formText.passwordInfo, language)}
                  />
                  <FormInput
                    ref={refRepeatPassword}
                    backgroundColor="white"
                    name="repeatPassword"
                    title={translateObj(formText.repeatPassword, language)}
                    validations="equalsField:password"
                    validationError={translateObj(formText.repeatPasswordError, language)}
                    type="password"
                    required
                    className="sm:w-full w-1/2"
                  />
                </div>
              ) : (
                <FormInput
                  name="oauthId"
                  backgroundColor="white"
                  title="Social Id Code"
                  value={currentValues && currentValues.oauthId || ''}
                  hidden
                />
              )
            }

            {errorMsg && <span className="pl-3 text-red mt-4">{errorMsg}</span>}

            <div className="flex justify-between mt-8 sm:mt-0 w-full">
              <div className="w-1/2 sm:w-full md:w-full sm:px-3 md:pl-3 font-tiemposRegular normal-case text-12">
                <div>
                  <FormCheckboxConditions
                    required
                    name="conditions"
                    acceptText={translateObj(formText.hasOwnProperty('conditions') ? formText.conditions.acceptText : defaultConditionsText.acceptText, language)}
                    conditionsText={translateObj(formText.hasOwnProperty('conditions') ? formText.conditions.conditionsText : defaultConditionsText.conditionsText, language)}
                    conditionsLink={translateObj(formText.hasOwnProperty('conditions') ? formText.conditions.conditionsLink : defaultConditionsText.conditionsLink, language)}
                    dataTreatmentText={translateObj(formText.hasOwnProperty('conditions') ? formText.conditions.dataTreatmentText : defaultConditionsText.dataTreatmentText, language)}
                    privacyPolicyText={translateObj(formText.hasOwnProperty('conditions') ? formText.conditions.privacyPolicyText : defaultConditionsText.privacyPolicyText, language)}
                    privacyPolicyUrl={translateObj(formText.hasOwnProperty('conditions') ? formText.conditions.privacyPolicyUrl : defaultConditionsText.privacyPolicyUrl, language)}
                    validations="isTrue"
                    validationError=""
                  />
                </div>
                <div>
                  <FormCheckbox name="newsletter" title={translateObj(formText.newsletter, language)} validationError="" linkText={translateObj(formText.newsletterLinkText, language)} linkUrl={translateObj(formText.newsletterLinkUrl, language)} />
                </div>
                <div className="hidden sm:block sm:w-full mt-6">
                  <button type="submit" disabled={!canSubmit} className={`${canSubmit ? 'bg-black text-white' : 'bg-white text-gray200'} h-12  rounded-full px-3 w-full py-2 font-americaMonoBold`}>{translateObj(formText.submitButtonText, language)}</button>
                </div>
              </div>
              <div className="flex flex-col w-1/4 sm:hidden">
                <button type="submit" disabled={!canSubmit} className={`${canSubmit ? 'bg-black text-white' : 'bg-white text-gray200'} h-12 text-white rounded-full mx-4 py-2 font-americaMonoBold`}>{translateObj(formText.submitButtonText, language)}</button>
              </div>
            </div>
            <div className="m-4 flex items-center ml-auto" />
          </Formsy>
        </div>
      </>
    );
  };


  return (
    <div className="w-full bg-gray200 p-8 sm:p-2">
      {translateObj(translations.registerPrimavera, language)}
      {registerFormRender()}
    </div>
  );
};

OnDemandModalRegister.propTypes = {
  language: PropTypes.string,
  loadTokenForLS: PropTypes.func.isRequired,
  handleOnDemandScreenStatus: PropTypes.func.isRequired,
  translations: PropTypes.shape().isRequired,
};

OnDemandModalRegister.defaultProps = {
  language: 'en',
};

export default OnDemandModalRegister;
