/* eslint-disable camelcase */
/**
 * sections post component is like this:
                 {
                    "type" : "BodyChildContainer",
                    "components" : [
                        {
                            "type" : "PsWorldStripeTester",
                            "title" : {
                                "es" : "Donación",
                                "en" : "Donation",
                                "ca" : "Donació"
                            },
                            "productSlugName" : "donation",
                            "donationAmounts" : [
                                1.0,
                                5.0,
                                10.0,
                                20.0
                            ]
                        }
                    ]
                }
 */
import React, { useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import jwt from 'jsonwebtoken';
import api from './psWorldPaymentsApi';
import PsWorldStripeCardFormWrapper from './PsWorldStripeCardFormWrapper';
import PayWithStoredCardStripeWrapper from './PayWithStoredCardStripeWrapper';

const productSlugName = 'donation';

const PsWorldStripeTester = () => {
  const [product, setProduct] = useState(0);
  const [productType, setProductType] = useState('');
  const [amount, setAmount] = useState('');
  const [currency, setCurrency] = useState('');
  const [customerEmail, setCustomerEmail] = useState(null);
  const [customerInfo, setCustomerInfo] = useState(null);
  const [customerPaymentMethods, setCustomerPaymentMethods] = useState(null);
  const [customerPaymentMethodsSelectorOptions, setCustomerPaymentMethodsSelectorOptions] = useState([]);
  const [customerPaymentInfo, setCustomerPaymentInfo] = useState(null);
  const [publicKey, setPublicKey] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);
  const [showGetCardInfo, setShowGetCardInfo] = useState(false);
  const [showPayWithStoredCard, setShowPayWithStoredCard] = useState(false);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [paymentMethodId, setPaymentMethodId] = useState('');

  useEffect(() => {
    if (productSlugName) {
      console.log('productSlugName', productSlugName);
      // Step 1: Fetch product details such as amount and currency from API
      api.getProductDetails(productSlugName).then((productDetails) => {
        setProduct(productDetails);
        setProductType(productDetails.productType);
        if (productDetails.amount) {
          setAmount(productDetails.amount);
        }
        setCurrency(productDetails.currency);
      });
    }
  }, [productSlugName]);

  useEffect(() => {
    if (customerPaymentMethods) {
      const options = customerPaymentMethods.map((paymentOption) => {
        const {
          id, card: { last4 }, card: { exp_month }, card: { exp_year },
        } = paymentOption;
        // return { value: id, label: last4 };
        // return `<option value="${id}">**** **** **** ${last4}</option>`;
        return (
          <option key={id} value={id}>
            **** **** ****
            {' '}
            {last4}
            {' '}
            {exp_month}
            /
            {exp_year}
          </option>
        );
      });
      setCustomerPaymentMethodsSelectorOptions(options);
      setPaymentMethodId(customerPaymentMethods[0].id); // Set first item as default payment method
    }
  }, [customerPaymentMethods]);

  useEffect(() => {
    if (process.env.AUTH_TOKEN) {
      if (typeof localStorage === 'undefined') return;
      const token = localStorage.getItem(process.env.AUTH_TOKEN);
      if (!token || token === 'undefined') {
        setError('No user session. Please login');
        return;
      }

      const { email } = jwt.decode(token);
      setCustomerEmail(email);
    }
  }, [process.env.AUTH_TOKEN]);

  const getOrCreateCustomer = async (ev) => {
    ev.preventDefault();
    setError('');
    setMessage('');
    if (!customerEmail) {
      setError('Please log in to get customer info');
      return;
    }
    api.getOrCreateCustomer(customerEmail).then((customerData) => {
      setCustomerInfo(customerData);
      if (!customerData || !Object.keys(customerData).length) {
        setMessage(`Customer ${customerEmail} could not be retrieved nor created`);
        return;
      }

      setMessage('Customer OK');
    });
  };

  const getCustomerSetupIntents = async (ev) => {
    ev.preventDefault();
    setError('');
    setMessage('');
    if (!customerEmail) {
      setError('Please log in to get payment methods');
      return;
    }
    if (!customerInfo || !customerInfo.id) {
      setError('Please get customer info first');
      return;
    }
    api.getCustomerSetupIntent(customerInfo.id).then((setupIntent) => {
      // setCustomerPaymentInfo(setupIntent);
      if (!setupIntent || !setupIntent.length) {
        setMessage(`No Customer setup intent info for ${customerEmail}`);
        return;
      }

      setMessage(JSON.stringify(setupIntent));
    });
  };

  const getCustomerPaymentMethods = async () => {
    setError('');
    setMessage('');
    if (!customerEmail) {
      setError('Please log in to get payment methods');
      return;
    }
    if (!customerInfo || !customerInfo.id) {
      setError('Please get customer info first');
      return;
    }
    api.getCustomerPaymentMethods(customerInfo.id).then((paymentMethods) => {
      setCustomerPaymentMethods(paymentMethods);
      // if (!paymentMethods || !paymentMethods.length) {
      //   setMessage(`No Customer payent methods info for ${customerEmail}`);
      // }
    });
  };

  const getCustomerCardInfo = (ev) => {
    ev.preventDefault();
    setError('');
    setMessage('');
    setShowGetCardInfo(true);
  };

  const payWithStoredCard = (ev) => {
    ev.preventDefault();
    setError('');
    setMessage('');
    setShowPayWithStoredCard(true);
  };

  const payWithStoredCardResult = (result) => {
    const { status } = result;

    if (status === 'succeeded') {
      setShowPayWithStoredCard(false);
      setMessage('Thank you, payment finished');
      return;
    }

    setMessage('Payment failed');
  };

  const storeNewCardResult = (result) => {
    const { status } = result;

    if (status === 'succeeded') {
      setShowGetCardInfo(false);
      getCustomerPaymentMethods();
      setMessage('Card stored. Select it to pay');
      return;
    }

    setMessage('Payment failed');
  };

  // const handleSubmit = async (ev) => {
  //   ev.preventDefault();
  // };

  return (
    <div style={{ width: '400px' }}>
      <p>
        Stripe tester
      </p>

      <div className="flex mt-8 sm:mt-0">
        <div className="flex flex-col w-1/2">
          <button type="button" className="bg-grey h-12 text-white rounded-full mx-4 py-2 font-americaMonoBold" onClick={getOrCreateCustomer}>
            Get or create customer
          </button>
        </div>
      </div>
      <div className="flex mt-8 sm:mt-0">
        <div className="flex flex-col w-1/2">
          <button type="button" className="bg-grey h-12 text-white rounded-full mx-4 py-2 font-americaMonoBold" onClick={getCustomerPaymentMethods}>
            Get customer existing payment methods
          </button>
        </div>
      </div>
      <div className="flex mt-8 sm:mt-0">
        <div className="flex flex-col w-1/2">
          Select one of your stored cards:
          <select onChange={newValue => setPaymentMethodId(newValue.currentTarget.value)}>
            {customerPaymentMethodsSelectorOptions}
          </select>
        </div>
      </div>
      <div className="flex mt-8 sm:mt-0">
        <div className="flex flex-col w-1/2">
          <button type="button" className="bg-grey h-12 text-white rounded-full mx-4 py-2 font-americaMonoBold" onClick={getCustomerCardInfo}>
            Get customer card
          </button>
        </div>
      </div>
      <div className="flex mt-8 sm:mt-0">
        <div className="flex flex-col w-1/2">
          <button type="button" className="bg-grey h-12 text-white rounded-full mx-4 py-2 font-americaMonoBold" onClick={payWithStoredCard}>
            Pay with stored card
          </button>
        </div>
      </div>
      <div className="flex mt-8 sm:mt-0">
        <div className="flex flex-col w-1/2">
          {showGetCardInfo && (
            <PsWorldStripeCardFormWrapper customerInfo={customerInfo} product={product} resultCallback={storeNewCardResult} />
          )}
        </div>
      </div>
      <div className="flex mt-8 sm:mt-0">
        <div className="flex flex-col w-1/2">
          {showPayWithStoredCard && (
            <PayWithStoredCardStripeWrapper customerInfo={customerInfo} paymentMethodId={paymentMethodId} product={product} resultCallback={payWithStoredCardResult} />
          )}
        </div>
      </div>
      <div className="flex mt-8 sm:mt-0">
        {/* Show any error that happens when processing the payment */}
        {error && (
          <div className="w-full card-error" role="alert">
            Error:
            {' '}
            {error}
          </div>
        )}
        {message && (
          <div className="w-full">
            {message}
          </div>
        )}
      </div>
    </div>
  );
};

PsWorldStripeTester.propTypes = {
};

PsWorldStripeTester.defaultProps = {
};

export default PsWorldStripeTester;
