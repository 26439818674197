import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Panel from '@primaveralabs/ui-panel';
import Notification from '@primaveralabs/ui-notification';
import 'Primavera/ui-panel/dist/primavera.css';
import 'Primavera/ui-notification/dist/primavera.css';
import 'Primavera/ui-themes/dist/primavera/index.css';
import { useBraze } from '../../../context/useBraze';
import imageModifier from '../helpers/imageModifier';
import { useRoutes } from '../../../context/routesContext';
import translateObj from '../../../util/translateObj';
import LoadIcon from '../LoadIcon';
import commonTranslation from './translations/common';
import translation from './translations/UserPanel';

const NotificationsBoard = ({ title, viewAll, noNotifications }) => {
  const { braze } = useBraze();
  const { language } = useRoutes();
  const [loading, setLoading] = useState(false);
  const [height, setHeight] = useState(undefined);
  const [items, setItems] = useState([]);
  const nRef = useRef(null);

  const showNotifications = () => {
    if (!braze) return;
    braze.requestFeedRefresh();
    braze.showContentCards();
    braze.logCustomEvent('clicks-on-card-menu');
  };

  const loadData = () => {
    if (!braze) return;
    setLoading(true);
    braze.requestContentCardsRefresh();
    braze.subscribeToContentCardsUpdates(({ cards }) => {
      setItems(cards);
    });
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  const resizeHeight = () => {
    if (nRef.current && nRef.current.offsetHeight) {
      if (window.innerWidth < 980) setHeight(600);
      else setHeight(300);

      setHeight(nRef.current.offsetHeight - 1);
    }
  };

  useEffect(() => {
    if (nRef) {
      setTimeout(() => {
        resizeHeight();
      }, 1000);
      window.addEventListener('resize', resizeHeight);
    }
    return () => window.removeEventListener('resize', resizeHeight);
  }, [nRef]);

  return (
    <div ref={nRef} style={{ gridArea: 'cards', gridRows: '1 / span 3' }}>
      <Panel
        title={translateObj(title, language)}
        ctaCallback={() => showNotifications()}
        ctaLabel={translateObj(viewAll, language)}
                // TODO: notifications page temporary hidden
        // ctaLink={`/${language}/user-panel/notifications`}
        noChildrenMessage={translateObj(noNotifications, language)}
        display="stretch"
        scroll
      >
        {loading ? (
          <div className="flex items-center justify-center h-full">
            <LoadIcon />
          </div>
        ) : (
          items
          && items?.length > 0
          && nRef.current && (
            <div
              className="notification-cards"
              style={{
                maxHeight: `${height - 80}px`,
              }}
            >
              {items.map((item) => (
                <Notification
                  key={`${item.createdAt}-${item.title}`}
                  title={item.title}
                  text={item.description}
                  image={item.imageUrl ? imageModifier(item.imageUrl) : null}
                  cta={{ label: item.linkText, link: item.url }}
                />
              ))}
            </div>
          )
        )}
      </Panel>
    </div>
  );
};

NotificationsBoard.propTypes = {
  title: PropTypes.shape({}),
  viewAll: PropTypes.shape({}),
  noNotifications: PropTypes.shape({}),
};

NotificationsBoard.defaultProps = {
  title: translation.notifications,
  viewAll: commonTranslation.viewAll,
  noNotifications: commonTranslation.noNotifications,
};

export default NotificationsBoard;
