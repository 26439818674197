import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useRoutes } from '../../../context/routesContext';
import translateObj from '../../../util/translateObj';


const FormSelect = ({ options, placeholder, className, onChange }) => {
  const { language } = useRoutes();

  const [selection, setSelection] = useState({ name: placeholder, value: null });
  const [showOptions, setShowOptions] = useState(false);

  const handleSelection = (item) => {
    if (selection.value === item.value) {
      setSelection({ name: placeholder, value: null });
    } else {
      setSelection(item);
    }
    setShowOptions(false);
  };

  useEffect(() => {
    onChange(selection.value);
  }, [selection]);

  return (
    <div className={`${className || 'w-full'}`}>
      <div className="w-full rounded-full bg-white-dark sm:min-w-full min-w-200">
        <button
          type="button"
          className="flex items-center justify-between w-full px-4 py-3 outline-none bg-transparent text-12 cursor-pointer text-black"
          onClick={() => setShowOptions(!showOptions)}
          aria-label={translateObj(placeholder, language)}
        >
          <div className="pr-4">
            <span className="text-black uppercase font-americaMonoRegular text-xs tracking-px">
              {translateObj(selection.name, language)}
            </span>
          </div>
          <div
            className="font-icon icon-arrow-up"
            style={{
              transform: !showOptions ? 'rotate(-180deg)' : '',
              transition: 'transform .1s ease-in',
            }}
          />
        </button>
        <div className="relative">
          {showOptions && (
            <div className="z-50 absolute w-full rounded-xl border border-white mt-2 px-4 py-3 bg-white-dark max-h-260px overflow-x-hidden overflow-y-scroll">
              <div
                key={translateObj(placeholder, language)}
                className={`py-2 cursor-pointer ${!selection.value ? 'text-pro-online' : ''}`}
                onClick={() => handleSelection({ name: placeholder, value: null })}
              >
                <span>{translateObj(placeholder, language)}</span>
              </div>
                {options.map(item => (
                  <div
                    key={item.value}
                    className={`py-2 cursor-pointer ${selection.value === item.value ? 'text-pro-online' : ''}`}
                    onClick={() => handleSelection(item)}
                  >
                    <span>{translateObj(item.name, language)}</span>
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

FormSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape()),
  placeholder: PropTypes.shape(),
  className: PropTypes.string,
  onChange: PropTypes.func,
};

FormSelect.defaultProps = {
  className: '',
  options: [],
  placeholder: {},
  onChange: () => {},
};

export default FormSelect;
