/* eslint-disable no-nested-ternary */
import React, { useEffect, useReducer } from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import ListItem from '@material-ui/core/ListItem';
import Collapse from '@material-ui/core/Collapse';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import Checkbox from '@material-ui/core/Checkbox';
import ParagraphLineSM from '../Text/ParagraphLineSM';
import DropDownMenu from './DropDownMenu';
import ActiveFilters from './ActiveFilters';
import FilterButton from '../Buttons/FilterButton';
import InputTextButton from '../InputTextButton';
import { useRoutes } from '../../../context/routesContext';
import translateObj from '../../../util/translateObj';
import useQueryString from '../../../hooks/useQueryString';

const debounce = (func, delay) => {
  let inDebounce;
  return (...args) => {
    const context = this;
    clearTimeout(inDebounce);
    inDebounce = setTimeout(() => func.apply(context, args), delay);
  };
};

const FilterCheckbox = withStyles({
  root: {
    color: 'white',
    padding: 0,
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

const styles = theme => ({
  root: {
    width: '100%',
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 0,
    overflow: 'visible',
  },
});

const ExpandLess = () => <span className="font-icon icon-arrow-up text-white text-sm" />;
const ExpandMore = () => <span className="font-icon icon-arrow-down text-white text-sm font-black" />;

const INITIAL_STATE = {
  open: true,
  filters: {},
  checkedItems: [],
  radioItem: '',
  hasActiveFilter: false,
  activeFilterText: {},
  isLoading: true,
  queryTag: '',
};

const SET_FILTER = 'SET_FILTER';
const SET_VALUE = 'SET_VALUE';

const setFilter = dispatch => (name, value) => dispatch({ type: SET_FILTER, name, value });
const setValue = dispatch => (name, value) => dispatch({ type: SET_VALUE, name, value });

const reducer = (state, action) => {
  switch (action.type) {
    case SET_FILTER: return ({ ...state,
      filters: {
        ...state.filters,
        [action.name]: action.value,
      },
      checkedItems: action.value,
      queryTag: action.value,
      isLoading: false,
    });
    default: return ({ ...state, [action.name]: action.value });
  }
};

function Filter({ handleSearchInput, filter, onHandleCollapsibleMenuSelect, radio, text, hasSearchBar, slugname, key, isMultipleSelect }) {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const { filters, checkedItems } = state;

  const { language, params } = useRoutes();

  const queryFilter = useQueryString('filter');

  const handleOnChange = debounce((query, lang) => {
    handleSearchInput(query, lang, filters);
  }, 300);

  const checkActiveFilter = () => {
    const hasItems = Object.values(filters).reduce((accum, current) => accum.concat(current), []);
    setValue(dispatch)('hasActiveFilter', !!hasItems.length);
  };

  useEffect(() => {
    if (Object.keys(filters)?.length) onHandleCollapsibleMenuSelect(filters);
  }, [filters]);


  useEffect(() => {
    const selectedTag = params?.tag || queryFilter;
    if (selectedTag) {
      setFilter(dispatch)('byEvent', [selectedTag]);
    }
  }, []);

  useEffect(() => {
    // NOTE: not sure what this does, will give issues for sure
    const selectedTag = params?.tag;
    const { queryTag } = state;
    if (queryTag && selectedTag && (selectedTag !== queryTag)) {
      setFilter(dispatch)('byTag', [selectedTag]);
      checkActiveFilter();
    }
  }, []);
  const updateFilters = (value, filterType) => {
    const newFilter = filters[filterType];

    if (!newFilter?.length || filterType === 'byMaterial' || filterType === 'byName' || !isMultipleSelect) return setFilter(dispatch)(filterType, [value]);

    const index = newFilter.findIndex(item => item === value);

    if (index === -1) newFilter.push(value);
    else newFilter.splice(index, 1);

    return setFilter(dispatch)(filterType, newFilter);
  };

  const onHandleChange = (value, filterType) => {
    const index = checkedItems.findIndex((item => item === value));
    const updatedCheckedItems = [...checkedItems];

    // this case is in press home filter to switch between content
    if (radio) {
      if (updatedCheckedItems?.length) updatedCheckedItems.pop();
      else updatedCheckedItems.push(value);
      setValue(dispatch)('radioItem', value);
      setValue(dispatch)('checkedItems', updatedCheckedItems);
    }

    // this case is in press-area filter to accept only one value
    if (index >= 0 && filterType !== 'byMaterial') {
      updatedCheckedItems.splice(index, 1);
      updateFilters(value, filterType);
      return setValue(dispatch)('checkedItems', updatedCheckedItems);
    }
    if ((filterType === 'byMaterial')) {
      const checkedMaterialIndex = checkedItems.findIndex((item => item === filters.byMaterial[0]));
      updatedCheckedItems.splice(checkedMaterialIndex, 1);
    }
    if (filterType === 'byName') {
      const checkedNameIndex = checkedItems.findIndex((item => item === filters.byName[0]));
      updatedCheckedItems.splice(checkedNameIndex, 1);
      filters.byTag = [];
    }
    updatedCheckedItems.push(value);
    updateFilters(value, filterType);
    return setValue(dispatch)('checkedItems', updatedCheckedItems);
  };

  return (
    <div className="w-full">
      <List key={`list-${filter.filterKey || 'xxxx'}`}>
        <div className="w-full text-white font-americaMonoRegular bg-black p-8 ">
          <ListItem
            className="flex pb-6 mb-6 border-dotted border-white border-b-1 justify-between "
            onClick={() => setValue(dispatch)('open', !state.open)}
          >
            <ListItemText primary={<span className="font-americaMonoBold uppercase text-white">{translateObj(text, language)}</span>} />
            <span className="mr-4">{state.open ? <ExpandLess /> : <ExpandMore />}</span>
          </ListItem>
          <Collapse in={state.open} timeout="auto" unmountOnExit key={`collapse-${filter.filterKey || 'xxx'}`}>
            <div className="flex flex-wrap ">
              {filter.map((menuItem, i) => (
                <React.Fragment key={menuItem.filterType + i}>
                  { menuItem.filterType === 'byEvent' || menuItem.showAs === 'button'
                    ? (
                      <React.Fragment>
                        <div className="w-full">
                          {menuItem.filterHeading && <ParagraphLineSM classes="text-white m-0 pt-7 mb-6" text={menuItem.filterHeading} />}
                          {menuItem.filterItems.map(item => (
                            <FilterButton
                              key={item.value}
                              params={item}
                              filterType={menuItem.filterType}
                              filters={state.filters}
                              radioItem={state.radioItem}
                              onHandleButtonSelect={onHandleChange}
                            />
                          ))}
                        </div>
                        <div className="w-full sm:block md:hidden lg:hidden xl:hidden">
                          {!state.isLoading && (
                          <DropDownMenu
                            menuTitle={menuItem.menuTitle}
                            filterType={menuItem.filterType}
                            menuItems={menuItem.filterItems}
                            text={menuItem.filterHeading}
                            checkedItems={state.checkedItems}
                            filters={state.filters}
                            onHandleChange={onHandleChange}
                            slugname={slugname}
                            selected={state.activeFilterText?.[language]}
                          />
                          )}
                        </div>
                      </React.Fragment>
                    )
                    : menuItem.showAs === 'checkbox'
                      ? (
                        <div className="w-full sm:hidden">
                          {menuItem.filterHeading && <ParagraphLineSM classes="text-white m-0 pt-7 mb-6" text={menuItem.filterHeading} />}

                          {menuItem.filterItems.map(item => (
                            <React.Fragment key={item.value}>
                              <FilterCheckbox checked={state.checkedItems.includes(item.value)} onChange={() => onHandleChange(item.value, menuItem.filterType)} value="checkedA" />
                              <span className="uppercase text-xs pr-4 pl-1" onClick={() => onHandleChange(item.value, menuItem.filterType)}>{item.text.en}</span>
                            </React.Fragment>
                          ))}
                        </div>
                      )
                      : !state.isLoading && (
                      <DropDownMenu
                        menuTitle={menuItem.menuTitle}
                        filterType={menuItem.filterType}
                        menuItems={menuItem.filterItems}
                        text={menuItem.filterHeading}
                        checkedItems={state.checkedItems}
                        filters={state.filters}
                        onHandleChange={onHandleChange}
                        slugname={slugname}
                        selected={state.activeFilterText[language]}
                      />
                      ) }
                </React.Fragment>
              ))}
            </div>
          </Collapse>
        </div>
      </List>
      {hasSearchBar
              && (
              <div className="-mt-2 bg-white p-8 flex items-center sm:block md:block items-center rounded-bl-lg rounded-br-lg">
                <div className="bg-white flex-1">
                  <InputTextButton
                    name="searchQuery"
                    params={{ text: { es: 'buscar', en: 'search', ca: 'cerca' }, placeholder: { es: '', en: '', ca: '' }, searchIcon: 'font-icon icon-search' }}
                    handleOnChange={handleOnChange}
                  />
                </div>
              </div>
              )
              }
      {
        isMultipleSelect ? (
          <ActiveFilters
            filters={state.filters}
            hasActiveFilter={state.hasActiveFilter}
            radioItem={state.radioItem}
            onHandleChange={onHandleChange}
          />
        ) : null
      }
    </div>
  );
}

Filter.propTypes = {
  hasSearchBar: PropTypes.bool,
  handleSearchInput: PropTypes.func,
  slugname: PropTypes.string,
  onHandleCollapsibleMenuSelect: PropTypes.func.isRequired,
  filter: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

Filter.defaultProps = {
  hasSearchBar: true,
  slugname: '',
  handleSearchInput: () => {},
};

export default withStyles(styles)(Filter);
