/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react';
import { PropTypes } from 'prop-types';

const TitleArtistLineH1 = ({
  artist, country,
}) => (
  <h1 tabIndex="0" className="font-black font-americaBlack text-white text-40 sm:text-20 md:text-26">
    {`${artist}`}
    {country
      ? (
        <span className="uppercase">
(
          {country}
 )
        </span>
      ) : null}
  </h1>
);


export default TitleArtistLineH1;

TitleArtistLineH1.propTypes = {
  artist: PropTypes.string.isRequired,
  country: PropTypes.string,
};

TitleArtistLineH1.defaultProps = {
  country: null,
};
