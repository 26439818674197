import gql from 'graphql-tag';

import { clientWeb } from '../graphqlAPI';

const USER_LINK_SPOTIFY = gql`
  mutation Post($redirectUri: String!, $tokenId: String!, $userEmail: String!, ) {
    userLinkSpotify(
      redirectUri: $redirectUri,
      tokenId: $tokenId,
      userEmail: $userEmail,
      ){
        isAuth
        isEdited
        token
        error
    }
}`;

const userLinkSpotify = async (tokenId, userEmail, redirectUri) => {
  try {
    const result = await clientWeb.mutate({
      mutation: USER_LINK_SPOTIFY,
      variables: {
        redirectUri,
        tokenId,
        userEmail,
      },
      fetchPolicy: 'no-cache',
    })
      .then(queryResult => queryResult)
      .catch((queryError) => {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error: ${queryError}`);
        return null;
      });
    const { data: { userLinkSpotify: validatedFormData } } = result;

    if (validatedFormData) {
      return validatedFormData;
    }
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: GraphQL Query Error: validatedFormData = null`);
    return null;
  } catch (error) {
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: userLinkSpotify Error: ${error}`);
    return null;
  }
};

export default userLinkSpotify;
