import React from 'react';
import { PropTypes } from 'prop-types';
import ComponentIterator from '../../containers/ComponentIterator';
import SearchResult from './SearchResult';

class SearchBasicLayout extends React.Component {
  state = {}

  render() {
    const { left, right } = this.props;
    return (
      <div>
        <div className="flex flex-row sm:flex-col-reverse md:flex-col-reverse pt-4">
          <div className="w-1/4 sm:w-full md:w-full">
            {/* <ComponentIterator components={left} /> */}
          </div>
          <div className="w-3/4 sm:w-full md:w-full">
            {
              right.map(searchResult => (
                <SearchResult key={searchResult.slugName} {...searchResult} />
              ))
            }
          </div>
        </div>
      </div>
    );
  }
}

SearchBasicLayout.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  left: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  right: PropTypes.array.isRequired,
};

export default SearchBasicLayout;
