const translation = {
  titleNewsletter: {
    en: 'Subscribe to our newsletter',
    es: 'Suscríbete a nuestra newsletter',
    ca: 'Subscriu-te a la nostra newsletter',
    pt: 'Assine a nossa newsletter',
  },
  placeholderNewsletter: {
    en: 'Your email',
    es: 'Tu email',
    ca: 'El teu email',
    pt: 'Seu email',
  },
  buttonSubmitNewsletter: {
    en: 'Send',
    es: 'Enviar',
    ca: 'Enviar',
    pt: 'Mandar',
  },
  languages: [
    {
      name: 'en',
      url: '/en',
    },
    {
      name: 'es',
      url: '/es',
    },
    {
      name: 'ca',
      url: '/ca',
    },
    {
      name: 'pt',
      url: '/pt',
    },
  ],
  social: [
    {
      key: 'twitter',
      name: 'Twitter',
      link: 'https://twitter.com/primaverapro',
    },
    {
      key: 'facebook',
      name: 'Facebook',
      link: 'https://www.facebook.com/PrimaveraPro',
    },
    {
      key: 'instagram',
      name: 'Instagram',
      link: 'https://www.instagram.com/primaverapro/',
    },
    {
      key: 'youtube',
      name: 'YouTube',
      link: 'https://www.youtube.com/c/PrimaveraPro',
    },
    {
      key: 'spotify',
      name: 'Spotify',
      link: 'https://open.spotify.com/user/primaverasoundfestival?si=Gz9-CxONSESlF_art5K4rg',
    },
  ],
  copyright: {
    en: '©Primavera sound Barcelona S.L., All rights reserved',
    es: '©Primavera sound Barcelona S.L., Todos los derechos reservados',
    ca: '©Primavera sound Barcelona S.L., Tots els drets reservats',
    pt: '©Primavera sound Barcelona S.L., Todos os direitos reservados',
  },
};

export default translation;
