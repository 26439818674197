import gql from 'graphql-tag';

import { clientWeb } from '../graphqlAPI';

const USER_UPDATE_SUBSCRIPTION_DETAILS = gql`
  mutation Post($email: String!, $lengthInMonths: Int, $isTrial: Boolean, $cancel: Boolean) {
    userUpdateSubscriptionDetails(
      email: $email,
      lengthInMonths: $lengthInMonths,
      isTrial: $isTrial,
      cancel: $cancel,
      ){
        token
        isValid
        error
    }
}`;

const userUpdateSubscriptionDetails = async ({
  email, lengthInMonths = 1, isTrial = false, cancel = false,
}) => {
  try {
    const result = await clientWeb.mutate({
      mutation: USER_UPDATE_SUBSCRIPTION_DETAILS,
      variables: {
        email,
        lengthInMonths,
        isTrial,
        cancel,
      },
      fetchPolicy: 'no-cache',
    })
      .then(queryResult => queryResult)
      .catch((queryError) => {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error: ${queryError}`);
        return null;
      });

    const { data: { userUpdateSubscriptionDetails: validatedFormData } } = result;

    if (validatedFormData) {
      return validatedFormData;
    }
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: GraphQL Query Error: validatedFormData = null`);
    return null;
  } catch (error) {
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: userUpdateSubscriptionDetails Error: ${error}`);
    return null;
  }
};

export default userUpdateSubscriptionDetails;
