import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '../context/themeContext';

const Tabs = ({ children, activeIndex, setActiveIndex }) => {
  const { theme, color } = useTheme();

  const chatTabs = React.Children.map(children, (child, index) => {
    if (child) {
      return (
        <span className={`relative w-1/2 tab text-center h-8 flex items-center ${index === 2 ? 'justify-end pr-2 hover:text-psworld-orange' : 'justify-center hover:bg-psworld-tab-grey hover:border-psworld-grey'} border-psworld-grey border-t uppercase cursor-pointer text-sm font-americaMonoRegular trans cursor-pointer ${activeIndex === index ? 'bg-psworld-tab-grey' : 'border-psworld-grey border-b'} ${index !== 2 ? 'border-r border-psworld-grey' : ''} ${activeIndex === 2 && index === 2 ? 'text-psworld-orange' : ''}`} onClick={() => setActiveIndex(index)}>
          {child.props.label === 'user-icon' ? (
            <span
              className="font-icon1 icon-userlogin font-semibold text-sm"
            />
          ) : child.props.label}
        </span>
      );
    }
  });

  const renderContent = () => React.Children.map(children, (item, i) => (
    <div className={`tab-content overflow-hidden h-full ${i !== activeIndex ? 'hidden' : ''}`}>{item}</div>
  ));

  return (
    <div className="tabs-container h-full sm:overflow-hidden">
      <div className={`${chatTabs.length === 1 ? 'hidden ' : 'flex'} h-8 items-center text-white bg-${theme}-box text-${color} w-full`}>
        {chatTabs}
      </div>
      {renderContent()}
    </div>
  );
};

export default Tabs;
