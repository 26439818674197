import React, { Component } from 'react';
import Slider from 'react-slick';
import { PropTypes } from 'prop-types';
import ComponentIterator from '../../containers/ComponentIterator';


const Arrow = (props) => {
  const { onClick, src, right } = props;
  const className = right ? 'pin-r' : 'pin-l';
  return (
    <div
      onClick={onClick}
      style={{ backgroundImage: `url(${src})`, top: '50%' }}
      className={`absolute ${className} h-8 w-4 z-50 cursor-pointer mr-4`}
    />
  );
};

const settings = {
  dots: true,
  infinite: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  appendDots: dots => (
    <div
      style={{
        bottom: 10,
      }}
    >
      <ul style={{ margin: '0px' }}>

        {dots}

      </ul>
    </div>
  ),
  nextArrow: <Arrow

    src="https://assets.primaverasound.com/ps/images/arrow_left_white.svg"
  />,

  prevArrow: <Arrow
    right
    src="https://assets.primaverasound.com/ps/images/arrow_right_white.svg"
  />,
};

export default class Carousel extends Component {
  state = {

  };

  render() {
    const { items } = this.props;

    return (
      <div className="h-620 md:h-carouselMD sm:h-carouselSM w-full">

        <Slider {...settings} className="relative">
          {
            items.map(item => (
              <div key={item.imageUrl}>
                <div
                  className="h-620 md:h-carouselMD sm:h-carouselSM w-full"
                  style={{
                    background: `url(${item.imageUrl})`,
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',

                  }}
                >
                  <div className="text-white flex items-end h-full">
                    <div className="flex justify-around w-full px-36 md:px-26 sm:px-5 h-1/2 items-end pb-20 ">
                      <div className="w-2/3 sm:w-full h-full flex flex-col justify-end mr-12">

                        <ComponentIterator components={item.left} />
                      </div>

                      <div className="w-1/3 sm:w-full">
                        <ComponentIterator components={item.right} />
                      </div>
                    </div>

                  </div>

                </div>
              </div>
            ))
          }

        </Slider>
      </div>
    );
  }
}

Carousel.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(
    {
      imageUrl: PropTypes.string.isRequired,
      components: PropTypes.array.isRequired,
    },
  ).isRequired),
  classNames: PropTypes.string,
};
