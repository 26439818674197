import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

const ModalWithChildren = ({ children, handleClose }) => (
  <div id="WithChildren" className="w-full text-white font-americaMonoRegular fixed h-full pin-l pin-t bg-black" style={{ zIndex: 1001 }}>
    <div className="min-h-screen">
      <div
        className="fixed top-0 right-0 color-white font-icon icon-close cursor-pointer"
        onClick={handleClose}
        style={{ right: '20px', top: '20px', zIndex: 101 }}
      />
      {children}
    </div>
  </div>
);

ModalWithChildren.propTypes = {
  children: PropTypes.shape({}),
  handleClose: PropTypes.func.isRequired,
};

ModalWithChildren.defaultProps = {
  children: null,
};

export default withRouter(ModalWithChildren);
