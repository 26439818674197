import React from 'react';

const InstagramIcon = () => (
  <svg width="100%" height="100%" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs><path id="a" d="M0 0h24v24H0z" /></defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <mask id="b" fill="#fff"><use xlinkHref="#a" /></mask>
        <path d="M12 0H7.077c-1.006.006-2 .217-2.923.615A6.024 6.024 0 0 0 .615 4.154 12.01 12.01 0 0 0 0 7.077v9.846a7.52 7.52 0 0 0 .615 2.923 6.032 6.032 0 0 0 3.539 3.539A12.01 12.01 0 0 0 7.077 24h9.846a7.52 7.52 0 0 0 2.923-.615 6.036 6.036 0 0 0 3.539-3.539c.326-.944.532-1.927.615-2.923v-9.8a7.52 7.52 0 0 0-.615-2.923A6.032 6.032 0 0 0 19.846.662a11.953 11.953 0 0 0-2.923-.616L12 0Zm0 2.154h4.923a5.863 5.863 0 0 1 2.308.461c.545.159 1.03.48 1.385.923.392.399.707.868.923 1.385.232.75.386 1.525.461 2.308v9.846a5.889 5.889 0 0 1-.461 2.308 2.775 2.775 0 0 1-.923 1.384 4.375 4.375 0 0 1-1.385.923c-.752.231-1.525.387-2.308.462H7.077a5.883 5.883 0 0 1-2.308-.462 2.758 2.758 0 0 1-1.384-.923 4.35 4.35 0 0 1-.923-1.384A11.581 11.581 0 0 1 2 17.077V7.231a5.883 5.883 0 0 1 .462-2.308c.158-.545.48-1.03.923-1.385a4.35 4.35 0 0 1 1.384-.923 11.589 11.589 0 0 1 2.308-.461H12Z" fill="#000" fillRule="nonzero" mask="url(#b)" />

      </g>
      <path d="M12 16a4 4 0 1 1 4-4 3.877 3.877 0 0 1-4 4m0-10.154a6.154 6.154 0 1 0 0 12.308 6.154 6.154 0 0 0 0-12.308M18.46 4.153a1.385 1.385 0 1 1 0 2.77 1.385 1.385 0 0 1 0-2.77" fill="#000" fillRule="nonzero" />
    </g>
  </svg>
);

export default InstagramIcon;
