import { MAX_PARTICIPANTS } from '../formValidations/validateReunionForm';

const translation = {
  title: {
    en: 'Call a meeting',
    es: 'Convoca a una reunión',
    ca: 'Convoca una reunió',
    pt: 'Convoque uma reunião',
  },
  titleReunion: {
    en: 'Meeting details',
    es: 'Datos de la reunión',
    ca: 'Dades de la reunió',
    pt: 'Detalhes da reunião',
  },
  labelName: {
    en: 'Meeting name',
    es: 'Nombre de la reunión',
    ca: 'Nom de la reunió',
    pt: 'Nome da reunião',
  },
  labelDate: {
    en: 'Date',
    es: 'Fecha',
    ca: 'Data',
    pt: 'Encontro',
  },
  labelHour: {
    en: 'Hour',
    es: 'Hora',
    ca: 'Hora',
    pt: 'Hora',
  },
  labelLocation: {
    en: 'Place',
    es: 'Lugar',
    ca: 'Lloc',
    pt: 'Lugar',
  },
  placeholderLocation: {
    en: 'Select place',
    es: 'Selecciona lugar',
    ca: 'Selecciona lloc',
    pt: 'Selecione o lugar',
  },
  labelOnline: {
    en: 'The meeting is online',
    es: 'La reunión es online',
    ca: 'La reunió és online',
    pt: 'A reunião é online',
  },
  labelDescription: {
    en: 'Add a message or description',
    es: 'Agrega un mensaje o una descripción',
    ca: 'Afegeix un missatge o una descripció',
    pt: 'Adicionar uma mensagem ou descrição',
  },
  titleParticipants: {
    en: 'Guests',
    es: 'Invitados',
    ca: 'Convidats',
    pt: 'Convidados',
  },
  titleAdd: {
    en: 'Invite accredited',
    es: 'Invita acreditados',
    ca: 'Convida acreditats',
    pt: 'Convites credenciados',
  },
  titleAddOther: {
    en: 'Invite other accredited',
    es: 'Invita otros acreditados',
    ca: 'Convida altres acreditats',
    pt: 'Convide outros credenciados',
  },
  labelAdd: {
    en: 'Who do you want to invite?',
    es: '¿A quién quieres invitar?',
    ca: 'A qui vols convidar?',
    pt: 'Quem você quer convidar?',
  },
  filtersAll: {
    en: 'See all',
    es: 'Ver todos',
    ca: 'Veure tots',
    pt: 'Ver todos',
  },
  filtersFavorites: {
    en: 'Only favorites',
    es: 'Solo favoritos',
    ca: 'Només preferits',
    pt: 'Apenas favoritos',
  },
  noItemsFoundMessage: {
    en: 'No results found',
    es: 'No se han encontrado resultados',
    ca: 'No s\'han trobat resultats',
    pt: 'Não foram encontrados resultados',
  },
  showMore: {
    en: 'Show more',
    es: 'Mostrar más',
    ca: 'Mostrar més',
    pt: 'Mostrar mais',
  },
  errorMessage: {
    en: 'There are empty required fields. Please fix them before continuing.',
    es: 'Hay campos obligatorios vacíos en este formulario. Corrígelos antes de continuar.',
    ca: 'Hi ha camps obligatoris buits al formulari. Corregeix-los abans de continuar.',
    pt: 'Existem campos obrigatórios vazios no formulário. Corrija-os antes de continuar.',
  },
  errorMessageLocationOrOnline: {
    en: 'You must choose an option: PLACE or THE MEETING IS ONLINE.',
    es: 'Debes elegir una opción: LUGAR o LA REUNION ES ONLINE.',
    ca: 'Heu de triar una opció: LLOC o LA REUNIÓ ÉS ONLINE.',
    pt: 'Você deve escolher uma opção: LUGAR ou LA REUNIÃO É ONLINE.',
  },
  buttonCancel: {
    en: 'Cancel',
    es: 'Cancelar',
    ca: 'Cancel·lar',
    pt: 'Cancelar',
  },
  buttonSubmit: {
    en: 'Send invitation',
    es: 'Enviar invitación',
    ca: 'Enviar invitació',
    pt: 'Enviar convite',
  },
  errorReunionMaxParticipantsExeeded: {
    es: `Puedes invitar a un máximo de ${MAX_PARTICIPANTS} personas`,
    ca: `Pots convidar un màxim de ${MAX_PARTICIPANTS} persones`,
    pt: `Podes convidar um máximo de ${MAX_PARTICIPANTS} pessoas`,
    en: `You can invite a maximum of ${MAX_PARTICIPANTS} people`,
  },
};

export default translation;
