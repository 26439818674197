import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import userUpdateSubscriptionDetails from '../../../../api/graphql/mutations/userUpdateSubscriptionDetails';
import translateObj from '../../../../util/translateObj';
import Link from '../../../../util/link';
import LoadIcon from '../../LoadIcon';
import { useUser } from '../../../../context/userProfileContext';

// import { buttonClasses } from '../OnDemandUtils/onDemandUtils';

const trialLength = 1;
const yellowBackground = { backgroundColor: 'rgba(245, 229, 27, 0.7)' };
const confirmButtonText = {
  ca: 'CONTINUAR',
  es: 'CONTINUAR',
  en: 'PROCEED',
  pt: 'PROCEED',
};
const otherOptionsButtonText = {
  ca: 'veure altres opcions',
  en: 'see other options',
  es: 'ver otras opciones',
  pt: 'see other options',
};

const OnDemandModalTrial = (props) => {
  const {
    loadTokenForLS, history, imageUrl, otherOptionsButtonUrl, postVideoUrl, handleOnDemandScreenStatus, translations, translations: { loggedModal: formText = {} } = {}, language, userSocialInfo: { profileObj: preFilledValues = {} } = {}, closeModal, userEmail,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const user = useUser();

  const backgroundStyle = {
    backgroundImage: imageUrl,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  };

  const startUserSubscription = () => {
    setIsLoading(true);
    const startSubscriptionObj = {
      email: userEmail,
      lengthInMonths: trialLength,
      isTrial: true,
    };
    userUpdateSubscriptionDetails({ ...startSubscriptionObj }).then((response) => {
      if (response) {
        if (response.isValid) {
          // this.onReset();
          user.setNewAuthToken(response.token);
          setIsLoading(false);
          // Redirect to video
          history.push(`/${language}${postVideoUrl[0] === '/' ? '' : '/'}${postVideoUrl}`);
        } else if (response.error) {
          setErrorMsg(response.error);
          setIsLoading(false);
        } else {
          console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Error - submitForm validated, but returned isValid = false!`);
        }
      } else {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Error - submitForm failed to submit!`);
      }
    });
  };

  return (
    <div className="backgroundImgGray">
      <div>
        <div
          className={`${'h-80'} w-full  sliderBackground`}
          style={backgroundStyle}
        />
      </div>
      <div className="w-full bg-white-dark py-2 ">
        <div className="py-2 px-4">
          <div>{translateObj(formText.trialTitle, language)}</div>
          <div>{translateObj(formText.trialDescription, language)}</div>
          <div>{translateObj(formText.trialConditions, language)}</div>
        </div>
        <div className=" py-2 px-4" style={yellowBackground}>
          <div>{translateObj(formText.trialConfirmationText1, language)}</div>
          <div>{translateObj(formText.trialConfirmationText2, language)}</div>
        </div>
        <div className="flex justify-end px-4 pt-6 pb-4">
          <Link to={otherOptionsButtonUrl}>
            <div className="uppercase rounded-full bg-white-dark text-black font-americaMonoBold sm:w-full cursor-pointer  text-center px-12 py-4 md:mt-4 sm:mt-4 ">{translateObj(otherOptionsButtonText, language)}</div>
          </Link>
          <button type="button" onClick={startUserSubscription} className="uppercase rounded-full bg-gray200 text-black font-americaMonoBold sm:w-full cursor-pointer  text-center px-12 py-4 md:mt-4 sm:mt-4">
            {
          isLoading ? <LoadIcon height={15} width={15} color="black" type="ThreeDots" />
            : translateObj(confirmButtonText, language)
          }

          </button>
        </div>
      </div>
    </div>
  );
};

OnDemandModalTrial.propTypes = {
  language: PropTypes.string,
  imageUrl: PropTypes.string,
  otherOptionsButtonUrl: PropTypes.string,
  postVideoUrl: PropTypes.string,
  loadTokenForLS: PropTypes.func.isRequired,
  handleOnDemandScreenStatus: PropTypes.func.isRequired,
  translations: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
};

OnDemandModalTrial.defaultProps = {
  language: 'en',
  imageUrl: '',
  otherOptionsButtonUrl: '',
  postVideoUrl: '',
};

export default withRouter(OnDemandModalTrial);
