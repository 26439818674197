import gql from 'graphql-tag';

import { clientWeb } from '../graphqlAPI';

const SET_FRIEND = gql`
  mutation Post($friendId: String!) {
    setFriend(
      friendId: $friendId,
      ){
        result
        operation
        error
    }
}`;

const setFriend = async (formData) => {
  try {
    const result = await clientWeb.mutate({
      mutation: SET_FRIEND,
      variables: {
        ...formData,
      },
      fetchPolicy: 'no-cache',
    })
      .then(queryResult => queryResult)
      .catch((queryError) => {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error: ${queryError}`);
        return null;
      });

    const { data: { setFriend: validatedFormData } } = result;

    if (validatedFormData) {
      return validatedFormData;
    }
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: GraphQL Query Error: validatedFormData = null`);
    return null;
  } catch (error) {
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: setFriend Error: ${error}`);
    return null;
  }
};

export default setFriend;
