import React from 'react';
import { withStyles } from '@material-ui/core';
import ArtistCard from './ArtistCard';


const style = {
  artist: {
    position: 'relative',
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    '&:hover > div:nth-of-type(1)': {
      visibility: 'visible',
    },
  },
  artistCard: {
    visibility: 'hidden',
  },
};
class ArtistListItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = { active: false };
  }

  render() {
    const { name, classes } = this.props;
    const { active } = this.state;
    return (
      <div className={classes.artist}>
        <a className="underline">
          <h2 className="font-americaBlack py-1">
            {name}
            <span>•</span>
          </h2>


        </a>
        {/* ON HOVER SHOW */}
        {/* <div className={classes.artistCard}>
          <ArtistCard {...this.props} />
        </div> */}

      </div>
    );
  }
}

export default withStyles(style)(ArtistListItem);
