import React, { useState, useRef, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';

import moment from 'moment';

import Modal from 'react-responsive-modal';

import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';
import SellerButton from './Buttons/SellerButton';
import translateObj from '../../util/translateObj';
import { AmountWithCurrency } from './TicketConcert';

const multipleSellersText = {
  en: 'Choose your seller',
  es: 'Escoge tu punto de venta',
  pt: 'Escolher o ponto de venda',
};

function Sellers({ sellers }) {
  return sellers.length ? (
    sellers.map((seller) => seller && <SellerButton active={seller.active} margin="max-w-xssm my-1 text-center opacity-100 h-28" key={seller.sellerTicketUrl} text={{ en: seller.sellerName }} url={seller.sellerTicketUrl} category="black" />)
  ) : (
    <SellerButton margin="my-1 sm:w-auto text-center h-28" soldOut text={{ en: 'Coming soon', es: 'Próximamente', ca: 'Pròximament' }} url="/" />
  );
}

const priceString = {
  en: 'FROM',
  es: 'DESDE',
  ca: 'DES DE',
  pt: '',
};

const titleClickable = {
  en: 'Buy',
  es: 'Comprar',
  ca: 'Comprar',
  pt: 'Comprar',
};

const titleNotClickable = {
  en: 'Sould Out',
  es: 'Agotado',
  ca: 'Esgotat',
  pt: 'Esgotado',
};

function TicketMainV2({
  _id,
  beginDateTime,
  description,
  status,
  tags,
  price,
  currency,
  sellers,
  purchaseUrl,
  show,
  showPastEndDate,
  showFee,
  ticketName,
  ticketReadableName,
  ticketSlugName,
  match,
  padding,
  category,
}) {
  // TO DO: Add to props ticketType
  console.log({
    _id,
    beginDateTime,
    description,
    status,
    tags,
    price,
    currency,
    sellers,
    purchaseUrl,
    show,
    showPastEndDate,
    showFee,
    ticketName,
    ticketReadableName,
    ticketSlugName,
    match,
    padding,
    category,
  });
  const [open, setOpen] = useState(false);
  const [isAvailableToBuy, setIsAvailableToBuy] = useState({ status: true, label: '' });
  const containerRef = useRef(null);
  const childRef = useRef(null);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [childHeight, setChildHeight] = useState(0);

  const onOpenModal = () => {
    setOpen(true);
  };

  const onCloseModal = () => {
    setOpen(false);
  };

  const modalStyles = {
    modal: {
      background: 'rgba(0, 0, 0, 0)',
      boxShadow: 'none',
      width: '100vw',
      // padding: '2rem',
    },
    overlay: {
      // background: 'rgba(0, 0, 0, 0.5)',
    },
  };

  useEffect(() => {
    if (status) {
      const isAvailableObj = { ...isAvailableToBuy };
      if (status && status !== 'SALE') {
        console.log('isStatus', status);
        isAvailableObj.status = false;
        isAvailableObj.label = status !== 'SOON' ? 'COMMING SOON' : 'SOLD OUT';
      }
      console.log('isAvailableToBuy', isAvailableToBuy);
      setIsAvailableToBuy(isAvailableObj);
    }
    const card = containerRef.current;
    const child = childRef.current;
    // const childHeightRaw = child.clientHeight;
    // setChildHeight(childHeightRaw);
    const handleCollapse = () => {
      console.log('open?');
      setIsCollapsed((prevState) => !prevState);
    };
    card.addEventListener('click', handleCollapse);
    return () => card.removeEventListener('click', handleCollapse);
  }, []);

  // eslint-disable-next-line class-methods-use-this
  // rotateArrow(key, deg = '0deg') {
  //   TweenMax.to(`#menuItem_${key} [data-name="caret"]`, burgerAnimationDuration, {
  //     rotation: deg,
  //   });
  // }

  return (
    <RoutesContext.Consumer>
      {({ language: contextLanguage, enteredFrom }) => {
        const localization = getLocalization(contextLanguage, enteredFrom, match);
        // const priceText = {
        //   en: 'Price',
        //   es: 'Precio',
        //   ca: 'Preu',
        //   pt: 'Preco',
        // };
        // const soldOutText = { en: 'Sold out', es: 'Agotado', ca: 'Esgotat' };
        // const defaultAvailableText = { en: 'Sold out', es: 'Agotado', ca: 'Esgotat' };
        moment.locale(localization.language);
        return (
          <div ref={containerRef} className={`${isAvailableToBuy.status ? 'available' : ''} bg-white rounded w-full border-box cursor-pointer`}>
            <div className="p-6 flex sm:flex-col justify-between" style={{ flexFlow: 'row wrap', alignItems: 'stretch' }}>
              <div className="flex flex-wrap items-center">
                <div className="flex sm:justify-between md:justify-start items-center sm:pb-2 sm:w-full">
                  <span
                    className="font-icon icon-close font-black text-red text-xs sm:order-1 md:order-last"
                    style={{
                      transform: `${isCollapsed ? 'rotate(45deg)' : 'rotate(0deg)'}`,
                      transition: 'transform 300ms',
                    }}
                  />
                  {ticketReadableName && <h3 className="font-americaBlack text-2xl pr-10 pl-10 sm:pl-0 sm:text-lg md:text-lg">{translateObj(ticketReadableName, localization.language)}</h3>}
                </div>
                <div className="font-americaMonoRegular sm:text-xs md:text-xs text-sm sm:pb-5 uppercase">
                  <span>FULL FESTIVAL</span>
                  <span>{tags && (` | ${tags[0]}`)}</span>
                  <span>{!isAvailableToBuy.status && (` | ${isAvailableToBuy.label}`)}</span>
                </div>
                {' '}
                {/* {ticketTypeText} */}
              </div>
              <div
                ref={childRef}
                className="w-full py-6 pl-13 sm:pb-2 sm:pl-0 sm:pt-6 sm:pt-0 pr-6 overflow-hidden"
                style={{
                  height: isCollapsed ? 0 : 'auto',
                  opacity: isCollapsed ? 0 : 1,
                  transition: 'height 500ms ease-in',
                  order: 3,
                }}
              >
                <p className="font-timesRegular text-sm sm:text-xs md:text-xs mb-5 leading-normal whitespace-pre-wrap">{description && translateObj(description, localization.language).substring(0, 243)}</p>
              </div>
              <div className="flex justify-between items-center sm:border-t border-grey-medium sm:pt-5 sm:w-full">
                <div className="flex items-center sm:mr-0 mr-8">
                  <p className="font-americaMonoRegular sm:text-xs md:text-xs text-sm uppercase pr-2">{`${translateObj(priceString, localization.language)}:`}</p>
                  <p className={`text-2xl font-americaBlack ${(price === 0 || price === null) && 'invisible'}`}>
                    <AmountWithCurrency number={price} curr={currency} />
                    {showFee && <span className="text-xs">{price !== 0 ? '+ Fees' : ' '}</span>}
                  </p>
                </div>
                <div className="sm:w-24 w-48">
                  {!isAvailableToBuy.status || sellers.length === 0 || (sellers.length && !sellers[0].sellerTicketUrl) ? (
                    <SellerButton margin="my-1 text-center bg-gray200 border-none cursor-not-allowed" notAvailable text={titleClickable} url="/" />
                  ) : (
                    <>
                      {sellers ? (
                        <div>
                          <button type="button" className={`text-white flex flex-col justify-center items-center w-full flex rounded-full uppercase text-base sm:text-12 md:text-12 text-center font-americaMonoBold flex-col justify-center block my-1 text-center h-8 bg-${category || 'porto'}`} onClick={onOpenModal}>{translateObj(titleClickable, localization.language)}</button>
                          <Modal open={open} onClose={onCloseModal} center styles={modalStyles} closeOnOverlayClick showCloseIcon={false}>
                            <div className="flex justify-center flex-col items-center">
                              <div className="mb-4 ">
                                <span className="text-center rounded-full uppercase text-base sm:text-12 md:text-12 text-center text-white font-americaMonoBold">{translateObj(multipleSellersText, localization.language)}</span>
                              </div>
                              <Sellers sellers={sellers} />
                            </div>

                          </Modal>
                        </div>
                      )
                        : <SellerButton active={sellers[0].active} margin="my-1 text-center" key={sellers[0].sellerTicketUrl} text={titleClickable} url={sellers[0].sellerTicketUrl} category={category} />}
                    </>
                  )}
                </div>
              </div>
            </div>
            {/* <div className="border-t border-white-dark py-2 sm:text-center sm:px-0">
              {!show || sellers.length === 0 || (sellers.length && !sellers[0].sellerTicketUrl) ? (
                <SellerButton margin="my-1 text-center bg-gray200 border-none cursor-not-allowed" soldOut text={titleNotClickable} url="/" />
              ) : (
                <>
                  {sellers.length > 1 ? (
                    <div>
                      <button type="button" className={`text-white flex flex-col justify-center items-center w-full flex rounded-full uppercase text-base sm:text-12 md:text-12 text-center font-americaMonoBold flex-col justify-center block my-1 text-center xl:h-12 lg:h-12 h-38px bg-${category || 'porto'}`} onClick={onOpenModal}>{translateObj(titleClickable, localization.language)}</button>
                      <Modal open={open} onClose={onCloseModal} center styles={modalStyles} closeOnOverlayClick showCloseIcon={false}>
                        <div className="flex justify-center flex-col items-center">
                          <div className="mb-4 ">
                            <span className="text-center rounded-full uppercase text-base sm:text-12 md:text-12 text-center text-white font-americaMonoBold">{translateObj(multipleSellersText, localization.language)}</span>
                          </div>
                          <Sellers sellers={sellers} />
                        </div>

                      </Modal>
                    </div>
                  )
                    : <SellerButton active={sellers[0].active} margin="my-1 text-center" key={sellers[0].sellerTicketUrl} text={titleClickable} url={sellers[0].sellerTicketUrl} category={category} />}
                </>
              )}
            </div> */}
          </div>
        );
      }}
    </RoutesContext.Consumer>
  );
}

TicketMainV2.propTypes = {
  padding: PropTypes.bool,
  show: PropTypes.bool,
  category: PropTypes.string,
};

TicketMainV2.defaultProps = {
  padding: true,
  show: true,
  category: 'black',
};

export default withRouter(TicketMainV2);
