import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';

import Formsy from 'formsy-react';

import RoutesContext from '../../../../../context/routesContext';
import { UserProfileContextConsumer } from '../../../../../context/userProfileContext';

import getLocalization from '../../../../../util/localization';
import Link from '../../../../../util/link';
import getCountryNamesByCode, { getCodeFromCountry } from '../../../../../util/getCountryNamesByCode';

import profileUserEdit from '../../../../../api/graphql/mutations/profileUserEdit';
import userLinkSpotify from '../../../../../api/graphql/mutations/userLinkSpotify';
// import userDeleteAccount from '../api/graphql/mutations/userDeleteAccount';
import userUnLinkSpotify from '../../../../../api/graphql/mutations/userUnLinkSpotify';
import addExistingUserToNewsLetter from '../../../../../api/graphql/mutations/addExistingUserToNewsLetter';


import Filter from '../../../Collapsible/Filter';
import DatePickerFormsy from '../../DatePickerFormsy';
import FormInput from '../../../Form/FormInput';
import FormCheckbox from '../../../Form/FormCheckbox';
import FormSelectStyle from '../../../Form/FormSelectStyle';
import FormSelectAutoComplete from '../../../Form/FormSelectAutoComplete';
import translateObj from '../../../../../util/translateObj';
import SpotifyLogin from '../../SpotifyLogin/SpotifyLogin';
import LoadIcon from '../../../LoadIcon';
import InfoPopover from '../../../Form/InfoPopover';
import { ORIGIN, SCOPES, updateButtonText } from '../ProfileFormUtils';

// import { getNameList } from "country-list";

const responseSpotify = async (payload, userEmail) => {
  console.log('payload spotify + userEmail', payload, userEmail);

  try {
    const result = await userLinkSpotify(payload.code, userEmail);
    return result;
  } catch (err) {
    console.log('Some error happened on fetch of fetchPostDescription:', err);
  }

  // const result = await userSocialLogin(payload.code, 'spotify').then((response) => {
  //   if (response && response.token) {
  //     localStorage.setItem(process.env.AUTH_TOKEN, response.token);
  //     document.cookie = `${process.env.AUTH_TOKEN}=${response.token}`;
  //     return response;
  //   }
  //   let userPayload = {};

  //   if (response && response.userDetails && Object.keys(response.userDetails).length) {
  //     const [name, surname] = response.userDetails.display_name.split(' ');
  //     userPayload = {
  //       profileObj: {
  //         email: response.userDetails.email,
  //         givenName: name,
  //         familyName: surname,
  //         spotifyId: response.userDetails.userID,
  //       },
  //     };
  //   }


  //   return { isValid: false, needToRegister: true, userPayload };
  // }).catch((err) => {
  //   console.log('Some error happened on fetch of fetchPostDescription:', err);
  // });
  // return result;
};

class ProfileForm extends Component {
  state = {
    canSubmit: false,
    didSubmit: false,
    events: [],
    areYouSure: false,
    dateOfBirth: '',
    isAddingToNewsLetter: false,
    errorMsg: '',
  };

  componentDidMount = () => {
    const body = document.getElementById('bodyChildContainer');
    body.childNodes[0].setAttribute('style', 'border-top: none');
  }

  onHandleCollapsibleMenuSelect = ({ byEvent }) => {
    this.setState({ events: byEvent });
  }

  submitForm = (formData, loadTokenForLS, userDateOfBirth, lang) => {
    const { dateOfBirth } = this.state;
    const dateOfBirthFinal = dateOfBirth || userDateOfBirth;

    this.setState({ canSubmit: false });

    const profileFormAdj = {};
    profileFormAdj.email = formData.email;
    profileFormAdj.name = formData.userName;
    profileFormAdj.lastname = formData.userLastName;
    profileFormAdj.nickname = formData.userNickName;
    profileFormAdj.postalCode = formData.userPostalCode;
    profileFormAdj.dateOfBirth = dateOfBirthFinal;
    profileFormAdj.inputCountry = getCodeFromCountry(formData.inputCountry, lang);
    // profileFormAdj.lastname = formData.userLastName;

    profileUserEdit({ ...profileFormAdj }).then((res) => {
      if (res) {
        if (res.isEdited) {
          this.setState({
            didSubmit: true,
          }, () => {
            localStorage.setItem(process.env.AUTH_TOKEN, res.token);
            document.cookie = `${process.env.AUTH_TOKEN}=${res.token}; Secure; HttpOnly`;
            loadTokenForLS();
            scroll.scrollToTop({ smooth: 'linear' });
            setTimeout(() => {
              this.props.history.push(`/${lang}`);
            }, 3000);
          });
        } else if (res.error) {
          this.setState({
            errorMsg: res.error,
          }, () => {
            setTimeout(() => {
              this.setState({
                errorMsg: '',
              });
            }, 5000);
          });
        } else {
          console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Error - submitForm validated, but returned isValid = false!`);
        }
      } else {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Error - submitForm failed to submit!`);
      }
    });
  };

  enableButton = () => {
    this.setState({ canSubmit: true });
  };

  disableButton = () => {
    this.setState({ canSubmit: false });
  };

  _logout = (lang, loadTokenForLS) => {
    document.cookie = `${process.env.AUTH_TOKEN}=; expires=Thu, 01 Jan 1970 00:00:01 GMT; Secure; HttpOnly`;
    if (typeof localStorage !== 'undefined') {
      localStorage.removeItem(process.env.AUTH_TOKEN);
      this.props.history.push(`/${lang}`);
      loadTokenForLS();
    }
  }

  handleDate = (name, date) => {
    this.setState({ dateOfBirth: date });
  };

  // componentDidUpdate(prevProps, prevState) {
  //   const { dateOfBirth, canSubmit } = this.state;
  //   if (prevState.dateOfBirth !== dateOfBirth || prevState.canSubmit !== canSubmit) {
  //     if (!dateOfBirth) {
  //       this.setState({ canSubmit: false });
  //     } else if (dateOfBirth && canSubmit) {
  //       this.setState({ canSubmit: true });
  //     }
  //   }
  // }

  _displayNewsLetterFormButton = ({
    isUserInNewsLetter, isUserInSplio, userEmail, isUserVerified, currentLanguage, newsLetterTermsUrl, newsLetterTermsText, newsletterInfo, newsLetterButtonText, newsLetterConfigureText, newsletterSettingsInfo, newsLetterSubscribeText, isAddingToNewsLetter, loadTokenForLS,
  }) => {
    if (!userEmail || (!isUserVerified && isUserInNewsLetter)) return null;


    if (!isUserInSplio) {
      return (
        <div className="px-3 mb-4 sm:w-full w-1/2 sm:w-full md:w-full">
          <label className="flex flex-col" htmlFor="newsletterLink">
            <span className="flex">
              <span className="uppercase">
                <span>{translateObj(newsLetterSubscribeText, currentLanguage)}</span>
                {(newsLetterTermsText && newsLetterTermsText.en && newsLetterTermsUrl && newsLetterTermsUrl.en) && (
                <Link className="text-black underline uppercase cursor-pointer" to={translateObj(newsLetterTermsUrl, currentLanguage)}>{translateObj(newsLetterTermsText, currentLanguage)}</Link>
                )}
              </span>
              {newsletterInfo && newsletterInfo.en && (
              <div className="ml-4">
                <InfoPopover
                  text={translateObj(newsletterInfo, currentLanguage)}
                />
              </div>
              )}

            </span>

            <div className="cursor-pointer rounded-full bg-inherit bg-black uppercase text-white text-16 xs:text-14 sm:text-14 font-americaMonoBold sm:static sm:mx-0 sm:w-full  text-center md:w-full mt-4 px-12 lg:px-16 xl:px-16 py-4 flex items-center  justify-center " onClick={() => this.addUserToNewsLetter(userEmail, currentLanguage, loadTokenForLS)}>
              { isAddingToNewsLetter ? <LoadIcon color="white" type="ThreeDots" height={15} width={15} />
              //  <div className="font-icon text-white icon-email text-18 -mt-px mr-6" />
                : <div className="uppercase">{translateObj(newsLetterButtonText, currentLanguage) || 'accept'}</div>
            }
            </div>
          </label>
        </div>
      );
    }

    // if (isUserInSplio) {
    //   return (
    //     <div className="px-3 mb-4 sm:w-full w-1/2 sm:w-full md:w-full">
    //       <label className="flex flex-col" htmlFor="newsletterLink">
    //         <span className="flex">
    //           <span className="uppercase">
    //             <span>{translateObj(newsLetterConfigureText, currentLanguage)}</span>
    //           </span>
    //           {newsletterSettingsInfo && newsletterSettingsInfo.en && (
    //           <div className="ml-4">
    //             <InfoPopover
    //               text={translateObj(newsletterSettingsInfo, currentLanguage)}
    //             />
    //           </div>
    //           )}
    //         </span>
    //         <Link
    //           to={`/user-settings/newsletter-preferences?code=${userEmail}`}
    //           localizationpath={`/${currentLanguage}`}
    //         >
    //           <div className="cursor-pointer rounded-full bg-inherit bg-black uppercase text-white text-16 xs:text-14 sm:text-14 font-americaMonoBold sm:static sm:mx-0 sm:w-full  text-center md:w-full mt-4 px-12 lg:px-16 xl:px-16 py-4 flex items-center  justify-center ">
    //             <div className="font-icon text-white icon-email text-18 -mt-px mr-6" />
    //           </div>
    //         </Link>
    //       </label>
    //     </div>
    //   );
    // }

    return null;
  }


  addUserToNewsLetter = (email, language = 'en', loadTokenForLS) => {
    const newsletterFormData = {
      email,
      language,
      origin: ORIGIN,
      scopes: SCOPES,
    };
    if (email && language) {
      this.setState({
        isAddingToNewsLetter: true,
      }, () => {
        addExistingUserToNewsLetter({ ...newsletterFormData }).then((response) => {
          if (!response.isValid) {
            // handle error (not able to add user to newsletter)
            console.log('error when trying to add user', response.error);
          } else {
            // update token in localstorage and cookies
            this.setState({ isAddingToNewsLetter: false });
            localStorage.setItem(process.env.AUTH_TOKEN, response.token);
            document.cookie = `${process.env.AUTH_TOKEN}=${response.token}; Secure; HttpOnly`;
            loadTokenForLS();
          }
        });
      });
    }
  }

  // handleAccountDeletion = (email, language = 'en', loadTokenForLS) => {
  //   userDeleteAccount({ email }).then((response) => {
  //     if (response.isDeleted) {
  //       this._logout(language, loadTokenForLS);
  //     } else {
  //       console.log('error when trying to delete user', response.error);
  //     }
  //   });
  // }

  unlinkSpotifyAccount = (userEmail, loadTokenForLS) => {
    userUnLinkSpotify(userEmail).then((res) => {
      if (res) {
        if (res.isEdited && res.token) {
          localStorage.setItem(process.env.AUTH_TOKEN, res.token);
          document.cookie = `${process.env.AUTH_TOKEN}=${res.token}; Secure; HttpOnly`;
          loadTokenForLS();
        } else if (res.error) {
          this.setState({
            errorMsg: res.error,
          }, () => {
            setTimeout(() => {
              this.setState({
                errorMsg: '',
              });
            }, 5000);
          });
        } else {
          console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Error - submitForm validated, but returned isValid = false!`);
        }
      } else {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Error - submitForm failed to submit!`);
      }
    });
  }

  render() {
    const {
      match,
      menu,
      title,
      fields,
      infoFields,
      validation,
      text,
      conditions,
      submitButtonText,
      resetButtonText,
      submitMessage,
      submitMessageButton,
    } = this.props;

    const {
      canSubmit, didSubmit, areYouSure, isAddingToNewsLetter, errorMsg,
    } = this.state;
    // console.log( getNameList() );

    return (
      <UserProfileContextConsumer>
        {({
          userName, userLastName, userEmail, userNickName, userPostalCode, userDateOfbirth, userCountry, loggedFromSocialNetwork, isLinkedWithSpotify, isUserInNewsLetter, isUserInSplio, token, loadTokenForLS, isUserVerified,
        }) => (
          <RoutesContext.Consumer>
            {
                ({ language: contextLanguage, enteredFrom }) => {
                  const localization = getLocalization(contextLanguage, enteredFrom, match);
                  const { language: currentLanguage } = localization;

                  const {
                    firstName,
                    lastName,
                    nickName,
                    email,
                    gender,
                    birthDate,
                    choose,
                    phone,
                    country,
                    province,
                    city,
                    postalCode,
                    newsLetterButtonText,
                    newsLetterTermsText,
                    newsLetterTermsUrl,
                    newsLetterSubscribeText,
                    newsLetterConfigureText,
                    spotifyLinkText,
                    spotifyLinkedText,
                    spotifyUnLinkText,
                    destroyBannerText,
                    destroyMainButtonText,
                    destroyGoButtonText,
                    destroyBackButtonText,
                  } = fields;
                  const {
                    firstNameInfo,
                    lastNameInfo,
                    nickNameInfo,
                    emailInfo,
                    birthDateInfo,
                    countryInfo,
                    postalCodeInfo,
                    spotifyLinkInfo,
                    spotifyLinkedInfo,
                    newsletterInfo,
                    newsletterSettingsInfo,
                  } = infoFields;

                  const {
                    inValidName,
                    longName,
                    longEmail,
                    inValidEmail,
                    incorrectDate,
                    minorBirhtDate,
                    inValidPhone,
                    longPhone,
                    invalidPostalCode,
                    shortPassword,
                    repeatPassword,
                    countryError,
                  } = validation;

                  // const currentLanguage = 'en'
                  // const countryOptions = [
                  //   {
                  //     title:
                  //   {
                  //     en: 'Choose',
                  //     es: 'Choose',
                  //     ca: 'Choose',
                  //   },
                  //     value: fields.choose[localization.language],
                  //   },
                  //   {
                  //     title:
                  //     {
                  //       en: 'Spain',
                  //       es: 'España',
                  //       ca: 'Espanya',
                  //     },
                  //     value: 'Spain',
                  //   },
                  //   {
                  //     title:
                  //     {
                  //       en: 'Germany',
                  //       es: 'Alemania',
                  //       ca: 'Alemanya',
                  //     },
                  //     value: 'Germany',
                  //   },
                  //   {
                  //     title:
                  //     {
                  //       en: 'France',
                  //       es: 'Francia',
                  //       ca: 'France',
                  //     },
                  //     value: 'France',
                  //   },
                  // ];
                  const genderOptions = [
                    {
                      title:
                      {
                        en: 'Choose',
                      },
                      value: null,
                    },
                    {
                      title:
                      {
                        en: 'Male',
                      },
                      value: 'Male',
                    },
                    {
                      title:
                      {
                        en: 'Female',
                      },
                      value: 'Female',
                    },
                    {
                      title:
                      {
                        en: 'Other',
                      },
                      value: 'Other',
                    },
                    {
                      title:
                      {
                        en: 'Not your bussiness',
                      },
                      value: 'Not your bussines',
                    },
                  ];


                  // const cityOptions = [
                  //   {
                  //     title:
                  //     {
                  //       en: 'Choose',
                  //       es: 'Choose',
                  //       ca: 'Choose',
                  //     },
                  //     value: fields.choose[localization.language],
                  //   },
                  //   {
                  //     title:
                  //     {
                  //       en: 'Barcelona',
                  //       es: 'Barcelona',
                  //       ca: 'Barcelona',
                  //     },
                  //     value: 'Barcelona',
                  //   },
                  //   {
                  //     title:
                  //     {
                  //       en: 'Paris',
                  //       es: 'Paris',
                  //       ca: 'Paris',
                  //     },
                  //     value: 'Paris',
                  //   },
                  //   {
                  //     title:
                  //     {
                  //       en: 'Berlin',
                  //       es: 'Berlin',
                  //       ca: 'Berlin',
                  //     },
                  //     value: 'Berlin',
                  //   },
                  // ];

                  return (
                    <>
                      {!didSubmit && (
                        <>
                          {token
                            && (
                              <div className="w-full bg-white py-6 px-8 mb-4">
                                <div className="flex flex-row justify-end self-center items-center border-0 border-black">
                                  <div className="sm:hidden text-black px-8 font-americaMonoRegular uppercase">
                                    {translateObj({
                                      en: 'Close your session with Primavera Sound »',
                                      es: 'Cierra tu sesión en Primavera Sound »',
                                      ca: 'Tanca la teva sessió amb Primavera Sound »',
                                      pt: 'Close your session with Primavera Sound »',
                                    }, currentLanguage)}
                                  </div>
                                  <button
                                    type="logout"
                                    onClick={() => this._logout(currentLanguage, loadTokenForLS)}
                                    className="bg-red h-12 w-1/4 sm:w-full md:w-1/2 text-white rounded-full py-2 font-americaMonoBold uppercase"
                                  >
                                    LOGOUT
                                  </button>
                                </div>
                              </div>
                            )
                          }

                          <div className="w-full bg-white py-12 px-8 border-b-1 border-dotted border-black font-americaMonoBold uppercase">
                            {translateObj(title, currentLanguage)}
                          </div>

                          <Formsy
                            ref={(c) => { this.form = c; return this.form; }}
                            onValidSubmit={formData => this.submitForm(formData, loadTokenForLS, userDateOfbirth, currentLanguage)}
                            onValid={this.enableButton}
                            onInvalid={this.disableButton}
                            className="flex focus:border-blue-500 bg-white sm:flex-col md:flex-col flex-wrap w-full p-4 py-12 font-americaMonoBold"
                          >
                            <FormInput
                              ref={(c) => { this.userName = c; return this.userName; }}
                              name="userName"
                              title={translateObj(firstName, currentLanguage)}
                              validations={{ isSpecialWords: true, maxLength: 50 }}
                              validationErrors={{
                                isSpecialWords: translateObj(inValidName, currentLanguage),
                                maxLength: translateObj(longName, currentLanguage),
                              }}
                              titleClassName="flex uppercase"
                              className="w-1/2 sm:w-full md:w-full"
                              doNotChangeValidColor
                              required
                              value={userName}
                              infoText={translateObj(firstNameInfo, currentLanguage)}
                            />
                            <FormInput
                              ref={(c) => { this.userLastName = c; return this.userLastName; }}
                              name="userLastName"
                              title={translateObj(lastName, currentLanguage)}
                              validations={{ isSpecialWords: true, maxLength: 50 }}
                              validationErrors={{
                                isSpecialWords: translateObj(inValidName, currentLanguage),
                                maxLength: translateObj(longName, currentLanguage),
                              }}
                              required
                              titleClassName="flex uppercase"
                              className="w-1/2 sm:w-full md:w-full"
                              doNotChangeValidColor
                              value={userLastName}
                              infoText={translateObj(lastNameInfo, currentLanguage)}

                            />
                            <FormInput
                              ref={(c) => { this.email = c; return this.email; }}
                              name="email"
                              title={translateObj(email, currentLanguage)}
                              disabled
                              validations={{ isEmail: true, maxLength: 50 }}
                              validationErrors={{
                                isEmail: translateObj(inValidEmail, currentLanguage),
                                maxLength: translateObj(longEmail, currentLanguage),
                              }}
                              required
                              titleClassName="flex uppercase"
                              className="w-1/2 sm:w-full md:w-full"
                              doNotChangeValidColor
                              value={userEmail}
                              infoText={translateObj(emailInfo, currentLanguage)}
                            />
                            <FormInput
                              ref={(c) => { this.userNickName = c; return this.userNickName; }}
                              name="userNickName"
                              title={translateObj(nickName, currentLanguage)}
                              validations={{ maxLength: 15 }}
                              validationErrors={{
                                maxLength: translateObj(longName, currentLanguage),
                              }}
                              titleClassName="flex uppercase"
                              className="w-1/2 sm:w-full md:w-full"
                              doNotChangeValidColor
                              required
                              value={userNickName}
                              infoText={translateObj(nickNameInfo, currentLanguage)}
                            />
                            <FormInput
                              ref={(c) => { this.userPostalCode = c; return this.userPostalCode; }}
                              name="userPostalCode"
                              title={translateObj(postalCode, currentLanguage)}
                              validations={{ isNumeric: true, maxLength: 6 }}
                              validationError={
                                translateObj(invalidPostalCode, currentLanguage)
                              }
                              titleClassName="flex uppercase"
                              className="w-1/2 sm:w-full md:w-full"
                              doNotChangeValidColor
                              value={userPostalCode}
                              infoText={translateObj(postalCodeInfo, currentLanguage)}

                            />
                            <DatePickerFormsy
                              title={birthDate}
                              name="birthDate"
                              handleDate={this.handleDate}
                              existingDate={userDateOfbirth}
                              language={currentLanguage}
                              bolder
                              required
                              infoText={translateObj(birthDateInfo, currentLanguage)}
                            />

                            <FormSelectAutoComplete
                              ref={(c) => { this.inputCountry = c; return this.inputCountry; }}
                              titleClassName="uppercase flex"
                              name="inputCountry"
                              title={translateObj(country, currentLanguage)}
                              value={userCountry}
                              options={getCountryNamesByCode(currentLanguage)}
                              message={translateObj(countryError, currentLanguage)}
                              required
                              infoText={translateObj(countryInfo, currentLanguage)}

                            />
                            {loggedFromSocialNetwork !== 'spotify' && (isLinkedWithSpotify ? (
                              <div className="px-3 mb-4 sm:w-full w-1/2 sm:w-full md:w-full">
                                <label className="flex flex-col" htmlFor="spotifyLink">
                                  <span className="flex">
                                    <span className="uppercase">
                                      <span>{translateObj(spotifyLinkedText, currentLanguage)}</span>
                                    </span>
                                    {(spotifyLinkedInfo && spotifyLinkedInfo.en) && <InfoPopover classNames="ml-4" text={translateObj(spotifyLinkedInfo, currentLanguage)} />}
                                  </span>
                                  <div onClick={() => this.unlinkSpotifyAccount(userEmail, loadTokenForLS)} className="rounded-full bg-inherit bg-spotify-lighter uppercase text-white text-16 xs:text-14 sm:text-14 font-americaMonoBold sm:static sm:mx-0 sm:w-full  text-center md:w-full mt-4 px-12 lg:px-16 xl:px-16 py-4 flex items-center  justify-center cursor-pointer">
                                    {/* <div className="font-icon text-white icon-spotify text-18 -mt-px mr-6" /> */}
                                    {translateObj(spotifyUnLinkText, currentLanguage)}
                                  </div>
                                </label>
                              </div>

                            ) : (
                              <div className="px-3 mb-4 sm:w-full w-1/2 sm:w-full md:w-full">
                                <label className="flex flex-col" htmlFor="spotifyLink">
                                  <span className="flex">
                                    <span className="uppercase">
                                      <span>{translateObj(spotifyLinkText, currentLanguage)}</span>
                                    </span>
                                    <InfoPopover classNames="ml-4" text={translateObj(spotifyLinkInfo, currentLanguage)} />
                                  </span>
                                  <SpotifyLogin
                                    buttonText=""
                                    clientId="76a75784cbb541e69bc57569652757e2"
                                    redirectUri={`${process.env.FRONT_URL}/en/`}
                                    className="rounded-full bg-inherit bg-spotify uppercase text-white text-16 xs:text-14 sm:text-14 font-americaMonoBold sm:static sm:mx-0 sm:w-full cursor-pointer text-center md:w-full mt-4 px-12 lg:px-16 xl:px-16 py-4 flex items-center justify-center "
                                    onSuccess={async (data) => {
                                      const res = await responseSpotify(data, userEmail);
                                      if (res) {
                                        if (res.isEdited) {
                                          localStorage.setItem(process.env.AUTH_TOKEN, res.token);
                                          document.cookie = `${process.env.AUTH_TOKEN}=${res.token}; Secure; HttpOnly`;
                                          loadTokenForLS();
                                        } else {
                                          console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Error - spotifylinkededit validated, but returned isValid = false!`);
                                        }
                                      } else {
                                        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Error - spotifylinkededit failed to submit!`);
                                      }
                                    }}
                                      // onSuccess={async (data) => { const response = await responseSpotify(data); }}

                                    onFailure={(res) => { console.log('spotify login failed: ', res); }}
                                  />
                                </label>
                              </div>
                            ))}

                            {this._displayNewsLetterFormButton({
                              isUserInNewsLetter, userEmail, isUserVerified, currentLanguage, newsLetterTermsUrl, newsLetterTermsText, newsletterInfo, newsLetterButtonText, newsLetterConfigureText, newsletterSettingsInfo, newsLetterSubscribeText, isAddingToNewsLetter, isUserInSplio, loadTokenForLS,
                            })}


                            <div className="px-3 my-4 w-full flex justify-end">
                              <button
                                type="submit"
                                disabled={!canSubmit}
                                className={`rounded-full bg-inherit ${canSubmit ? 'bg-black' : 'bg-grey'} text-white font-americaMonoBold sm:static sm:mt-6 sm:mx-0 sm:w-full cursor-pointer  text-center xl:w-s15 lg:w-s15 sm:w-full xl:max-w-xs md:w-s23 lg:max-w-xs md:mt-4 `}
                                style={{
                                  alignSelf: 'flex-end',
                                  minHeight: '3rem',
                                }}
                              >
                                {translateObj(updateButtonText, currentLanguage)}
                              </button>

                            </div>
                            {errorMsg && <div className="text-red px-3 uppercase">{translateObj(validation[errorMsg], currentLanguage)}</div>}

                          </Formsy>

                          {/* {token
                            && (
                              <div className="w-full bg-red-dark py-6 px-8 my-4 -mb-16">
                                <div className="flex flex-row justify-end self-center items-center border-0 border-black">
                                  <div className="sm:hidden text-white px-8 font-americaMonoRegular uppercase">
                                    {translateObj(destroyBannerText, currentLanguage) || 'Delete permanently your account »'}
                                  </div>
                                  {!areYouSure
                                    ? (
                                      <button
                                        type="logout"
                                        onClick={() => this.setState({ areYouSure: true })}
                                        className="bg-red h-12 w-1/4 sm:w-full md:w-1/2 text-white rounded-full py-2 font-americaMonoBold uppercase"
                                      >
                                        {translateObj(destroyMainButtonText, currentLanguage) || 'DESTROY ACCOUNT'}
                                      </button>
                                    )
                                    : (
                                      <div className="w-1/2 md:w-full sm:w-full flex">
                                        <button
                                          type="logout"
                                          onClick={() => this.handleAccountDeletion(userEmail, currentLanguage, loadTokenForLS)}
                                          className="bg-white h-12 w-full text-red rounded-full py-2 mx-1 font-americaMonoBold uppercase"
                                        >
                                          {translateObj(destroyGoButtonText, currentLanguage) || 'I AM SURE'}
                                        </button>
                                        <button
                                          type="logout"
                                          onClick={() => this.setState({ areYouSure: false })}
                                          className="bg-red h-12 w-full text-white rounded-full py-2 mx-1 font-americaMonoBold uppercase"
                                        >
                                          {translateObj(destroyBackButtonText, currentLanguage) || ' I WILL THINK ABOUT IT'}
                                        </button>
                                      </div>
                                    )
                                  }
                                </div>
                              </div>
                            )
                          } */}

                        </>
                      )}

                      {didSubmit && (
                        <div className="flex flex-col w-full py-12 justify-center font-americaMonoRegular items-center">
                          <h3>{translateObj(submitMessage, localization.language)}</h3>
                          {/* <Link
                            to="/"
                            localizationpath={localization.path}
                            className="w-full flex justify-center items-center"
                          >
                            <button
                              className="bg-gray200 h-12 w-1/4 sm:w-full md:w-1/2 text-black rounded-full py-2 font-americaMonoBold uppercase mt-4"
                            >
                              {translateObj(submitMessageButton, localization.language)}
                            </button>
                          </Link> */}

                        </div>
                      )}
                    </>
                  );
                }
              }
          </RoutesContext.Consumer>
        )}
      </UserProfileContextConsumer>
    );
  }
}

ProfileForm.propTypes = {
  match: PropTypes.shape().isRequired,
  menu: PropTypes.shape(),
  title: PropTypes.shape().isRequired,
  fields: PropTypes.shape().isRequired,
  validation: PropTypes.shape().isRequired,
  text: PropTypes.shape().isRequired,
  conditions: PropTypes.shape().isRequired,
  submitButtonText: PropTypes.shape().isRequired,
  resetButtonText: PropTypes.shape().isRequired,
  submitMessage: PropTypes.shape().isRequired,
  submitMessageButton: PropTypes.shape().isRequired,
};

ProfileForm.defaultProps = {
  menu: null,
};

export default withRouter(ProfileForm);
