/* eslint-disable react/no-multi-comp */
import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import Slider from 'react-slick';
import parse from 'html-react-parser';
import { withRouter } from 'react-router-dom';
import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';
import translateObj from '../../util/translateObj';

import getMamObjectsBySlugNameQuery from '../../api/graphql/queries/getMamObjectsBySlugname';

class Arrow extends Component {
  state = {
    hover: false,

  };

  handleOnOut = () => {
    const { hover } = this.state;
    this.setState({ hover: !hover });
  };

  render() {
    const { onClick, right, direction } = this.props;
    const { hover } = this.state;
    const className = right ? 'ml-32' : 'pin-l';
    const onHover = hover ? `icon-arrow-${direction}-solid` : `icon-arrow-${direction}-round`;
    return <div onClick={onClick} style={{ bottom: -9 }} onMouseOver={this.handleOnOut} onFocus={this.handleOnOut} onMouseOut={this.handleOnOut} onBlur={this.handleOnOut} className={`absolute ${className} h-8  w-10 z-40 cursor-pointer mr-3 font-icon text-2xl cursor-pointer ${onHover}`} />;
  }
}

// const Arrow = (props) => {
//   const { onClick, right, direction } = props;

//   const className = right ? 'ml-32' : 'pin-l';

//   return (
//     <div
//       onClick={onClick}
//       style={{ bottom: -40 }}
//       onMouseOver={() => {
//         console.log('mouse over');
//       }}
//       className={`absolute ${className} h-8  w-10 z-40 cursor-pointer mr-3 font-icon text-2xl icon-arrow-${direction}-round cursor-pointer`}
//     />
//   );
// };

const settings = {
  dots: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  speed: 500,

  prevArrow: <Arrow direction="left" />,

  nextArrow: <Arrow right direction="right" />,
};

class CarouselWithDescription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currSlide: 1,
      imageObjects: [],
      isLoading: true,
    };
  }

  componentDidMount() {
    const { objects } = this.props;
    this.body = document.querySelector('body');
    if (objects.length) {
      this.fetchMamObjectsBySlugnames(objects);
    }
  }

  fetchMamObjectsBySlugnames = (slugnames) => {
    getMamObjectsBySlugNameQuery(slugnames)
      .then((res) => {
        // const publicImg = res.filter(i => i !== null);
        if (res) {
          this.setState({
            imageObjects: res,
            isLoading: false,
          });
        }
      })
      .catch((err) => {
        console.log('Some error happened on fetch of fetchMamObjects:', err);
      });
  };


  setCurrent = (curr) => {
    const currSlide = curr + 1;
    this.setState({
      currSlide,
    });
  };

  handleDots = (dots) => {
    const { currSlide } = this.state;
    return (
      <div
        style={{
          width: 'auto',
          bottom: '1px',
          padding: '0 4.2rem',
          backgroundColor: '#EFEAE7',
        }}
      >
        <p style={{ margin: '0px' }} className="text-xs">{`${currSlide}/${dots.length}`}</p>
      </div>
    );
  };

  render() {
    const { images, description, match } = this.props;
    const { isLoading, imageObjects } = this.state;
    return (
      <RoutesContext.Consumer>
        {({ language: contextLanguage, enteredFrom }) => {
          const localization = getLocalization(contextLanguage, enteredFrom, match);
          return (
            <>
              {!isLoading
                && (
                  <div className="w-full">
                    <div>
                      <div className="h-620 mb-16 sm:hidden">
                        <Slider {...settings} afterChange={this.setCurrent} appendDots={this.handleDots} className="relative">
                          {imageObjects.map((image, i) => (
                            <div key={(image.url, i)}>
                              <div
                                className="h-620"
                                style={{
                                  background: `url(${translateObj(image.url, localization.language)})`,
                                  backgroundPosition: 'center',
                                  backgroundRepeat: 'no-repeat',
                                  backgroundSize: 'cover',
                                }}
                              />
                              <div className="ml-32 pl-12 text-xs mt-4">{(image.description.en !== '' ? parse(translateObj(image.description, localization.language)) : parse(translateObj(image.fileReadableName, localization.language)))}</div>
                            </div>
                          ))}
                        </Slider>
                      </div>
                    </div>
                  </div>
                )
              }

            </>
          );
        }}
      </RoutesContext.Consumer>
    );
  }
}

CarouselWithDescription.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string),
  classNames: PropTypes.string,
  description: PropTypes.shape({}),
};

CarouselWithDescription.defaultProps = {
  classNames: '',
  images: [],
  description: {},
};

export default withRouter(CarouselWithDescription);
