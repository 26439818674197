import React from 'react';

const NewsSkeleton = () => (
  <>
    {Array(1)
      .fill()
      .map((item, index) => (
        <div key={index} className="p-8px launcherItem llg:w-1/3 lg:w-1/2 md:w-1/2 sm:w-full sm:px-0 mb-4 sm:h-launcherSingle-sm md:h-launcherSingle-md lg:h-launcherSingle-lg llg:h-launcherSingle-llg">
          <div className="skeleton-bg w-full h-full" />
        </div>
      ))}

    <div className="launcherItem llg:w-1/3 lg:w-1/2 md:w-1/2 sm:w-full px-2 sm:px-0 my-2">
      {Array(3)
        .fill()
        .map((item, index) => (
          <div className={`flex launcherItemData items-center ${index === 2 ? 'mb-0' : 'mb-2'}`} key={index}>
            <div className="w-1/3 overflow-hidden sm:h-launcherTriple-md md:h-launcherTriple-md lg:h-launcherTriple-lg llg:h-launcherTriple-llg">
              <div className="skeleton-bg w-full h-full" />
            </div>
            <div className="w-2/3 px-4 lg:px-2 items-start justify-center text-black sm:px-2 md:px-2">
              <div className="skeleton-bg w-full" style={{ height: '20px' }} />
              <div className="sm:my-2 my-3 flex">
                {Array(2)
                  .fill()
                  .map((item, index) => (
                    <div className="mr-2 skeleton-bg w-1/5" style={{ height: '20px' }} key={index} />
                  ))}
              </div>
            </div>
          </div>
        ))}
    </div>
    <div className="launcherItem llg:w-1/3 lg:w-1/2 md:w-1/2 sm:w-full px-2 sm:px-0 my-2 md:hidden">
      {Array(3)
        .fill()
        .map((item, index) => (
          <div className={`flex launcherItemData items-center ${index === 2 ? 'mb-0' : 'mb-2'}`} key={index}>
            <div className="w-1/3 overflow-hidden sm:h-launcherTriple-md md:h-launcherTriple-md lg:h-launcherTriple-lg llg:h-launcherTriple-llg">
              <div className="skeleton-bg w-full h-full" />
            </div>
            <div className="w-2/3 px-4 lg:px-2 items-start justify-center text-black sm:px-2 md:px-2">
              <div className="skeleton-bg w-full" style={{ height: '20px' }} />
              <div className="sm:my-2 my-3 flex">
                {Array(2)
                  .fill()
                  .map((item, index) => (
                    <div className="mr-2 skeleton-bg w-1/5" style={{ height: '20px' }} key={index} />
                  ))}
              </div>
            </div>
          </div>
        ))}
    </div>
  </>
);

export default NewsSkeleton;
