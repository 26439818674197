import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';
import Link from '../../../util/link';
import getLocalization from '../../../util/localization';

import RoutesContext from '../../../context/routesContext';
import { UserProfileContextConsumer } from '../../../context/userProfileContext';


const TopLogoContainer = ({
  name, image, match, linkTo,
}) => (
  <UserProfileContextConsumer>
    {({
      isActivationBarOpen, isConditionBarOpen,
    }) => (
      <RoutesContext.Consumer>
        {
              ({ language: contextLanguage, enteredFrom }) => {
                const localization = getLocalization(contextLanguage, enteredFrom, match);
                return (
                  <LogoComponent localization={localization} isActivationBarOpen={isActivationBarOpen || isConditionBarOpen} linkTo={linkTo} image={image} name={name} />
                );
              }
            }
      </RoutesContext.Consumer>
    )}
  </UserProfileContextConsumer>
);

const LogoComponent = ({
  name, image, match, linkTo, isActivationBarOpen, localization,
}) => {
  const [isActivationBarOpenState, setIsActivationBarOpenState] = useState(false);

  useEffect(() => {
    setIsActivationBarOpenState(isActivationBarOpen);
  }, [isActivationBarOpen]);

  return (
    <div id={name} className={`w-1/4 ${(!isActivationBarOpenState) && 'h-full xs:h-80px xs:mt-4 '} flex absolute lg:items-center xl:items-center max-w-12 ml-12 z-50 sm:ml-6  items-center `} style={(isActivationBarOpenState) ? { height: '145px' } : {}}>
      <div className="w-full" style={(isActivationBarOpenState) ? { paddingTop: '42px' } : {}}>
        <Link to={linkTo} localizationpath={localization.path} className="w-full hidden sm:block sm:w-2/3 xssm:h-80px">
          <img className="h-full noBackgroundImgGray" src={image.size.sm} alt={name} />
        </Link>
        <Link to={linkTo} localizationpath={localization.path} className="w-full sm:hidden">
          <img className="xl:w-7/8 lg:w-7/8 w-full noBackgroundImgGray" src={image.size.md} alt={name} />
        </Link>
      </div>
    </div>
  );
};

TopLogoContainer.propTypes = {
  name: PropTypes.string.isRequired,
  linkTo: PropTypes.string,
  image: PropTypes.shape().isRequired,
  match: PropTypes.shape().isRequired,
};

TopLogoContainer.defaultProps = {
  linkTo: '',
};

export default withRouter(TopLogoContainer);
