import React from 'react';
import PropTypes from 'prop-types';

const TopHeaderMainMenu = ({ children, name }) => (
  <section role="navigation" id={name} className="absolute pin-t pin-l pin-r flex items-center pointer-events-none justify-start desktop:flex-1 sm:block md:block desktop:relative lg:h-full xl:h-full sm:text-right md:text-right mmd:text-right">
    {children}
  </section>
);

TopHeaderMainMenu.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.shape().isRequired,
};


export default TopHeaderMainMenu;
