import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useHistory, useParams } from 'react-router-dom';
import { useRoutes } from '../../../context/routesContext';
import useMentoringSession from './util/hooks/useMentoringSession';
import useQuery from './util/hooks/useQuery';

import translateObj from '../../../util/translateObj';
import getTranslation from './util/translations/_getTranslation';
import localTranslation from './util/translations/MentoringSessionDetail';

import SquareImage from './SquareImage';
import Button from './Button';
import IconButton from './IconButton';
import MentoringSessionMentorsList from './MentoringSessionMentorsList';
import SessionDateTitle from './SessionDateTitle';
import RelatedSessions from './RelatedSessions';

const MentoringSessionDetail = ({
  translation,
  parentSlugName,
  showRelatedContent,
}) => {
  const { language } = useRoutes();
  const literals = getTranslation(translation, localTranslation);
  const history = useHistory();
  const query = useQuery();
  const { section } = useParams();

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(null);
  const [alreadyReserved, setAlreadyReserved] = useState(false);
  const [eventSlugName, setEventSlugName] = useState('');

  const { event, schedule, concurrent, reserved, reserveSession } = useMentoringSession(eventSlugName, true);
  const { description, eventReadableName, image, pretitle, subtitle, mentorsData } = event;

  const handleSelected = (item) => {
    if (selected === item) {
      setSelected(null);
    } else {
      setSelected(item);
    }
  };

  useEffect(() => {
    if (reserved) {
      setSelected(reserved);
      setAlreadyReserved(true);
    } else {
      setSelected(null);
      setAlreadyReserved(false);
    }
  }, [reserved]);

  useEffect(() => {
    const id = query.get('id');
    setEventSlugName(id);
  }, [query]);

  return (
    <>
      <div className="w-full bg-black text-white pt-10 sm:pb-10 md:pb-16 pb-32">
        <IconButton
          color="white"
          icon="font-icon icon-arrow-left"
          className="mb-8"
          onClickCallback={() => history.goBack()}
        />
        <div className="flex sm:flex-col md:flex-col">
          <div className="w-1/2 sm:w-3/4">
            <div className="relative inline-block w-full md:mb-8 sm:mb-8 overflow-hidden">
              <SquareImage image={image} alt={translateObj(literals.imageLabel, language)} gradient dimensions="1200x1200" />
              <div className="absolute pin-l pin-t h-full w-1/4">
                <div
                  className="text-white text-30 sm:text-18 xl:text-5xl font-americaMonoRegular uppercase tracking-1.5px sm:tracking-auto h-1/2 pt-8 pl-4 sm:pt-3 sm:pl-1"
                  style={{ writingMode: 'vertical-rl', maxHeight: '30%' }}
                >
                  {translateObj(literals.imageLabel, language)}
                </div>
              </div>
            </div>
          </div>
          <div className="w-1/2 pl-1/12 sm:w-full sm:pl-0 md:w-full md:pl-0">
            <div className="text-14 font-americamonoRegular mb-4 uppercase">
              {event && event?.artistSets && <SessionDateTitle tickets={event.artistSets} />}
              {pretitle && translateObj(pretitle, language)}
            </div>
            <h4 className="text-40 font-americaBlack leading-tight w-5/6 md:w-3/4 sm:text-30 sm:mt-4">{translateObj(eventReadableName, language)}</h4>
            <div className="w-4/5 sm:w-full md:w-full">
              <p className="font-timesRegular text-base mt-6 mb-10 sm:text-12 sm:mt-4">{translateObj(subtitle, language)}</p>
              {mentorsData && <MentoringSessionMentorsList items={mentorsData} className="mb-10 mt-6 sm:mt-4" textStyle="text-white font-timesRegular text-base sm:text-12" />}
              <p className="font-timesRegular text-14 sm:mb-12 hidden sm:block">{translateObj(description, language)}</p>
              <div className="cursor-pointer flex justify-between items-end border-b border-white pb-5 sm:pb-4" onClick={() => setOpen(!open)}>
                <div className="font-americaMonoRegular text-14 uppercase">{schedule.length > 1 ? translateObj(literals.labelDateTime, language) : translateObj(literals.labelTime, language)}</div>
                <div
                  className="font-icon text-white icon-arrow-up"
                  style={{
                    transform: !open ? 'rotate(-180deg)' : '',
                    transition: 'transform .1s ease-in',
                  }}
                />
              </div>
              {open && schedule.length > 0 && (
              <div className="border-b border-white">
                {schedule.map((session) => {
                  const { hours } = session;
                  const sessionIds = hours.map(h => h.slugName);
                  return (
                    <div key={session.day} className={`my-6 pl-4 font-americaMonoRegular uppercase text-12 border-l-8 ${sessionIds.includes(selected) ? 'border-pro-online' : 'border-white'}`}>
                      <div className="pb-1 sm:pb-0">{session.day}</div>
                      <div className="flex justify-start items-center flex-wrap sm:flex-col sm:flex-no-wrap sm:items-start sm:justify-center mb-3">
                        {hours.length > 0 && hours.map(h => (
                          <div key={h.slugName} className={`flex items-center pr-4 mt-3 ${['UNAVAILABLE', 'RESERVED'].includes(h.status) ? 'pointer-events-none opacity-50' : 'cursor-pointer'}`} onClick={() => handleSelected(h.slugName)}>
                            <div className="w-6 h-6 rounded-full border border-white p-1">
                              <div className={`w-full h-full rounded-full ${selected === h.slugName ? 'bg-pro-online' : 'bg-transparent'}`} />
                            </div>
                            <div className="ml-2">{h.time}</div>
                          </div>
                        ))}
                      </div>
                    </div>
                  );
                })}
              </div>
              )}
              {concurrent && <div className="text-pro-online mt-4 text-12 font-americaMonoRegular uppercase">{translateObj(literals.messageConcurrent, language)}</div>}
              {alreadyReserved && <div className="text-pro-online mt-4 text-12 font-americaMonoRegular uppercase">{translateObj(literals.messageReserved, language)}</div>}
              <Button
                text={alreadyReserved ? translateObj(literals.buttonCancel, language) : translateObj(literals.buttonReserve, language)}
                color={alreadyReserved ? 'disabledOrange' : `${selected ? 'orange' : 'disabledWhite'}`}
                className={alreadyReserved ? 'mt-4' : 'mt-8'}
                disabled={!alreadyReserved && !selected}
                onClickCallback={() => reserveSession(selected)}
              />
              <p className="font-timesRegular text-base mt-8 sm:hidden">{translateObj(description, language)}</p>
            </div>
          </div>
        </div>
      </div>
      {showRelatedContent && (
        <RelatedSessions title={literals.relatedSessions} loadMore={literals.loadMore} eventSlugName={parentSlugName} translation={translation} sessionPageSlugname={section} />
      )}
    </>
  );
};

MentoringSessionDetail.propTypes = {
  translation: PropTypes.shape(),
  showRelatedContent: PropTypes.bool,
  parentSlugName: PropTypes.string,
};

MentoringSessionDetail.defaultProps = {
  translation: null,
  showRelatedContent: false,
  parentSlugName: 'pro-mentoring-sessions-2022',
};

export default MentoringSessionDetail;
