import React, { Component } from 'react';
import { hydrate } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { loadableReady } from '@loadable/component';

import TagManager from 'react-gtm-module';

import JssProvider from 'react-jss/lib/JssProvider';
import { MuiThemeProvider, createMuiTheme, createGenerateClassName } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import ProvideCombinedContext from '../context/provideCombinedContexts';
import App from '../containers/App';

import useQueryString from '../hooks/useQueryString';

import 'StylesPath/index.scss';
import 'StylesPath/tailwind.css';

// TODO: move this to ENVS
const SENTRY_DSN = {
  ps: 'https://5d9c373e43804fcdb0255231b99fc756@o1406492.ingest.sentry.io/6781571',
  rdl: 'https://bb0ca5ca31c74806bc285abed977fca0@o1406492.ingest.sentry.io/4503918596521984',
};

try {
  Sentry.init({
    dsn: SENTRY_DSN[process.env.APP_NAME],
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
} catch (error) {
  console.log('Sentry error: ', error);
}

const tagManagerArgs = {
  gtmId: process.env.GOOGLE_TAG_MANAGER,
  // gtmId: 'GTM-TG8GLLX', // PS
  // gtmId: 'GTM-TZ2K6N7', // RDL
  // events: {
  //   subscriptionFinished: 'Subscription Finished',
  // },
};

// MUI generated class names are configured here to be identical in SSR and CSR.
const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true,
});

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiListItem: {
      gutters: { paddingLeft: 0, paddingRight: 0 },
    },
  },
});

const GoogleTagManager = () => {
  const renderCookies = !!useQueryString('no-cookies');
  if (!renderCookies) TagManager.initialize(tagManagerArgs);
  return null;
};

class Main extends Component {
  componentDidMount() {
    // The server-side MUI generated class names are removed from the HTML document and replaced by the client-side generated version via JssProvider and generateClassName.
    const jssStyles = document.getElementById('jss-server-side');
    if (jssStyles && jssStyles.parentNode) {
      jssStyles.parentNode.removeChild(jssStyles);
    }
  }

  render() {
    return <App />;
  }
}

loadableReady(() => {
  // Hydrate containers whose HTML contents were rendered by ReactDOMServer (SSR). React will attempt to attach event listeners to the existing markup.
  hydrate(
    <BrowserRouter>
      <JssProvider generateClassName={generateClassName}>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <ProvideCombinedContext>
            <div>
              <GoogleTagManager />
              <Main />
            </div>
          </ProvideCombinedContext>
        </MuiThemeProvider>
      </JssProvider>
    </BrowserRouter>,
    document.getElementById('app'),
  );
});
