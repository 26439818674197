import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';


import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';

import { GoogleLogin } from 'react-google-login';

import ListItemBlock from './Login/ListItemBlock';
import userSocialLogin from '../../api/graphql/mutations/userSocialLogin';
import userWebLogin from '../../api/graphql/mutations/userWebLogin';

import NormalBodyText from './Text/NormalBodyText';
import getLocalization from '../../util/localization';
import RoutesContext from '../../context/routesContext';
import { UserProfileContextConsumer } from '../../context/userProfileContext';
import translateObj from '../../util/translateObj';
import Link from '../../util/link';
import LoginForm from './LoginForm';
import GoogleSSO from './GoogleSSO';

const style = {
  list: {
    padding: 0,
    marginBottom: 30,
  },
  root: {
    backgroundColor: 'white',
    height: '2px',
    padding: 0,
  },
  item: {
    padding: 0,
  },
};

const userTitle = {
  es: 'CORREO ELECTRONICO',
  en: 'E-MAIL',
  ca: 'CORREU ELECTRÒNIC',
  pt: 'CORREIO ELETRÔNICO',
};

const passwordTitle = {
  es: 'CONTRASEÑA',
  en: 'PASSWORD',
  ca: 'CONTRASENYA',
  pt: 'password',
};

const submitTitle = {
  es: 'ACCEDER',
  en: 'SIGN IN',
  ca: 'ACCEDIR',
  pt: 'Entrar',
};

const forgotPassword = {
  es: 'He olvidado mi contraseña.',
  en: 'I forgot my password.',
  ca: 'He oblidat la meva contrasenya.',
  pt: 'Esqueci a minha senha.',
};

const recoverPassword = {
  es: 'Recuperar contraseña',
  en: 'Recover password',
  ca: 'Recuperar contrasenya',
  pt: 'Recuperar senha',
};

class Login extends Component {
  state = {
    open: true,
    isFilled: false,
    loginStatus: 'notLogged',
    name: '',
    password: '',
    currentData: {},
  };

  handleClick = () => {
    const { open } = this.state;
    this.setState({
      open: !open,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    const { name, password } = this.state;
    if (prevState.name !== name || prevState.password !== password) {
      if (name.length && password.length) {
        this.setState({ isFilled: true });
      } else {
        this.setState({ isFilled: false });
      }
    }
  }


  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = (e, tokenFn, lang) => {
    e.preventDefault();
    const { name, password } = this.state;
    const loginObj = {};
    loginObj.email = name;
    loginObj.password = password;
    userWebLogin({ ...loginObj }).then((res) => {
      if (res) {
        if (res.isValid) {
          this.setState({
            loginStatus: 'logged',
            name: '',
            password: '',
          });
          localStorage.setItem(process.env.AUTH_TOKEN, res.token);
          document.cookie = `${process.env.AUTH_TOKEN}=${res.token}; Secure; HttpOnly;`;
          tokenFn();
          this.props.history.push(`/${lang}/user-profile`);
        } else if (res.token) {
          let loginStatusQuery = 'notLogged';
          switch (res.token) {
            case 'notActive':
              loginStatusQuery = 'notActive';
              break;

            case 'notFound':
              loginStatusQuery = 'notFound';
              break;

            case 'wrongPassword':
              loginStatusQuery = 'wrongPassword';
              break;

            default:
              break;
          }

          this.setState({
            loginStatus: loginStatusQuery,
          });
        } else {
          console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Error - loginForm validated, but returned isValid = false!`);
        }
      } else {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Error - loginForm failed to submit!`);
      }
    });
    console.log('form submited');
  };

  // NOTE: hack, added _ to unifiy this with the OnDemandModal method
  registerUserFromSocial = (_, userPayload) => {
    const { profileObj } = userPayload;
    this.setState({
      open: false,
      currentData: {
        ...profileObj,
      },
    });
  }


  _loginForm = (language, loadTokenForLS) => {
    const {
      text, fullWidth, socialLogin,
    } = this.props;
    const { open, isFilled } = this.state;

    return (
      <List>
        <ListItemBlock
          {...this.props}
          open={open}
          handleClick={() => this.handleClick()}
        />
        <Collapse in={open} timeout="auto" unmountOnExit classes={{ entered: 'none' }}>
          <div className="flex flex-wrap bg-white">
            <div className={`w-1/4 sm:hidden ml-8 -mr-8 ${fullWidth && 'hidden'}`}>
              <span className="invisible">X</span>
            </div>
            <div className={`${fullWidth ? 'w-full p-8 pt-0' : 'w-3/4 pr-8 md:pr-24 sm:w-full sm:pr-0'} `}>
              {text && (
                <div className={`pb-8 border-black border-dotted ${!fullWidth && 'sm:mx-8'} border-b-1`}>
                  <NormalBodyText text={text} />
                </div>
              )}
              <form className={`flex flex-row ${!fullWidth && 'sm:mx-8'} py-8 flex-wrap sm:pb-4 ${!text && 'pt-0'} `} onSubmit={e => this.handleSubmit(e, loadTokenForLS, language)}>
                <label className="flex flex-col pr-6 font-americaMonoRegular text-12 sm:pr-0 sm:w-full">
                  {translateObj(userTitle, language)}
                  <input type="text" name="name" onChange={this.handleChange} className="mt-4 rounded-full border-1 p-4 bg-inherit border-black text-black text-12 sm:w-92 sm:w-full xl:w-s23 lg:w-s23 xl:max-w-xs lg:max-w-xs md:w-s23" />
                </label>
                <label className="flex flex-col pr-6 font-americaMonoRegular sm:pt-6 sm:pr-0  sm:w-full text-12">
                  {translateObj(passwordTitle, language)}
                  <input type="password" name="password" onChange={this.handleChange} className="mt-4 rounded-full border-1 p-4 bg-inherit border-black text-black w-auto text-12 sm:w-full xl:w-s23 lg:w-s23 xl:max-w-xs lg:max-w-xs md:w-s23" />
                </label>
                <input
                  disabled={!isFilled}
                  type="submit"
                  value={translateObj(submitTitle, language)}
                  className={`rounded-full border-1 bg-inherit ${isFilled ? 'bg-black text-white border-1 border-black' : 'bg-gray200 text-black border-grey'}  text-12 font-americaMonoBold sm:static sm:mt-6 sm:mx-0 sm:w-full cursor-pointer   text-center xl:w-s15 lg:w-s15 sm:w-full xl:max-w-xs md:w-s23 lg:max-w-xs md:mt-4`}
                  style={{
                    alignSelf: 'flex-end',
                    minHeight: '3rem',
                  }}
                />
                {!fullWidth && (
                  <div className="font-tiemposRegular text-14 mt-4 sm:w-full md:w-s23 md:ml-6 md:pb-0 pb-4 cursor-default">
                    {translateObj(forgotPassword, language)}
                    {' '}
                    <Link to="/recover-password">
                      <span className="text-black underlinefont-bold font-underlined cursor-pointer">
                        {translateObj(recoverPassword, language)}
                      </span>
                    </Link>
                  </div>
                )}
              </form>
              {fullWidth && (
                <div className="sm:mt-0 font-tiemposRegular text-14 cursor-default">
                  {translateObj(forgotPassword, language)}
                  {' '}
                  <Link to="/recover-password">
                    <span className="font-bold font-underlined cursor-pointer">
                      <span className="text-black underline">
                        {translateObj(recoverPassword, language)}
                      </span>
                    </span>
                  </Link>
                </div>
              )}
              {socialLogin && (
                <div className={`mt-4 border-black border-dotted border-t-1 flex flex-col ${!fullWidth && 'sm:mx-8 py-8 sm:pb-4'} pt-8 sm:pb-0 sm:pt-8 flex-wrap  ${!text && 'pt-0'} `}>
                  <label className="flex flex-col pr-6 font-americaMonoRegular text-12 sm:pr-0 sm:w-full">
                    {translateObj(socialLogin.headline, language)}
                  </label>
                  <div className={`flex flex-row pt-4 xl:pb-0 lg:pb-0 flex-wrap ${!fullWidth && 'md:flex-col py-4'}`}>
                    {socialLogin.facebook && this._socialButton('facebook', language, loadTokenForLS)}
                    {socialLogin.google && this._socialButton('google', language, loadTokenForLS)}
                    {socialLogin.twitter && this._socialButton('twitter', language, loadTokenForLS)}
                  </div>
                </div>
              )}
              <div />
            </div>
          </div>
        </Collapse>
      </List>
    );
  }

  _socialButton = (type, lang, loadTokenForLS) => {
    let jsxReturned = (<span />);
    const fullWidth = false;

    switch (type) {
      case 'twitter':
        jsxReturned = (
          <button
            type="button"
            className={`rounded-full bg-inherit bg-twitter text-white text-12 font-americaMonoBold sm:static sm:mx-0 sm:w-full cursor-pointer text-center sm:w-full md:mt-4 px-12 lg:px-16 xl:px-16  py-2 ml-4 flex items-center sm:ml-0 justify-center ${fullWidth && 'md:px-8 sm:h-10 sm:mt-4'} ${!fullWidth && 'md:ml-0 md:px-16 sm:mt-4 md:h-10 sm:h-10 '}`}
          >
            <div className="font-icon text-white icon-twitter-simple text-20" />
            <div className="mx-4">TWITTER CONNECT</div>
          </button>
        );
        break;
      case 'google':
        jsxReturned = (
          <GoogleSSO handleRegisteringStatus={this.registerUserFromSocial} type="post" />
        );
        break;
      case 'facebook':
        jsxReturned = (
          <button type="button" className={`rounded-full bg-inherit bg-facebook text-white text-12 font-americaMonoBold sm:static sm:mx-0 sm:w-full cursor-pointer text-center sm:w-full md:mt-4 px-12 lg:px-16 xl:px-16 py-2 flex items-center justify-center ${fullWidth && 'md:px-8 sm:h-10 sm:mt-2'} ${!fullWidth && 'md:px-16 md:h-10 sm:h-10 sm:mt-2'}`}>
            <div className="font-icon text-white icon-facebook-simple text-16" />
            <div className="mx-4">FACEBOOK CONNECT</div>
          </button>
        );
        break;
      default:
        break;
    }
    return jsxReturned;
  }

  render() {
    const {
      match,
    } = this.props;

    const { currentData } = this.state;

    return (
      <UserProfileContextConsumer>
        {({ loadTokenForLS, token }) => (
          <RoutesContext.Consumer>
            {({ language: contextLanguage, enteredFrom }) => {
              const localization = getLocalization(contextLanguage, enteredFrom, match);
              return (
                <div className="w-full">
                  {!token && this._loginForm(localization.language, loadTokenForLS)}
                  <LoginForm preFilledValues={currentData} loadTokenForLS={() => loadTokenForLS()} />
                </div>
              );
            }}
          </RoutesContext.Consumer>
        )}
      </UserProfileContextConsumer>
    );
  }
}

Login.propTypes = {
  match: PropTypes.shape().isRequired,
  text: PropTypes.shape({}),
  loginTitle: PropTypes.shape({}),
  loginTitleLink: PropTypes.shape({}),
  fullWidth: PropTypes.bool,
  socialLogin: PropTypes.shape({}),
};

Login.defaultProps = {
  text: null,
  loginTitle: null,
  loginTitleLink: null,
  fullWidth: false,
  socialLogin: null,
};


export default withRouter(Login);
