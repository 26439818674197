import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Badge from '@primaveralabs/ui-badge';
import 'Primavera/ui-panel/dist/primavera.css';
import 'Primavera/ui-badge/dist/primavera.css';
import 'Primavera/ui-themes/dist/primavera/index.css';
import { useRoutes } from '../../../context/routesContext';
import imageModifier from '../helpers/imageModifier';
import translateObj from '../../../util/translateObj';
import commonTranslation from './translations/common';
import translation from './translations/UserPanel';
import SpotifyLogin from '../Login/SpotifyLogin/SpotifyLogin';
import { useAuth } from '@primaveralabs/auth';

const AchievementsList = ({
  achievements,
  myAchievements,
  pointsLabel,
  achievedOn,
}) => {
  const { user } = useAuth();
  const { language } = useRoutes();

  return (
    <>
      {myAchievements?.length > 0 &&
        myAchievements?.map((badge) => (
          <Badge
            key={badge.slugName}
            image={imageModifier(
              translateObj(badge.postDescription?.image, language)
            )}
            title={translateObj(badge.postDescription?.title, language)}
            description={translateObj(
              badge.postDescription?.description,
              language
            )}
            points={`${
              badge.postDescription?.loyaltyPoints
            } ${translateObj(pointsLabel, language)}`}
            intent="achievement"
            maskImage="https://assets.primaverasound.com/psweb/dgxbu8t6ztuzw1p4pfx1_1681669022673.png" // TODO: local image?
            achieved={{
              label: translateObj(achievedOn, language),
              date: moment(Number(badge.achievedOn)).format('DD/MM/YY'),
            }}
          />
        ))}
      {achievements?.length > 0 &&
        achievements?.map((badge) => {
          const achieved = myAchievements.some(
            (a) => a.slugName === badge.slugName
          );
          if (achieved) return null;

          return (
            <Badge
              key={badge.slugName}
              image={imageModifier(
                translateObj(badge.postDescription?.image, language)
              )}
              title={translateObj(
                badge.postDescription?.title,
                language
              )}
              description={translateObj(
                badge.postDescription?.description,
                language
              )}
              points={
                badge.postDescription.loyaltyPoints
                  ? `${
                      badge.postDescription?.loyaltyPoints
                    } ${translateObj(pointsLabel, language)}`
                  : null
              }
              intent="achievement"
              maskImage="https://assets.primaverasound.com/psweb/dgxbu8t6ztuzw1p4pfx1_1681669022673.png" // TODO: local image?
              inactive={!achieved}
              children={badge.slugName === 'has-created-account-with-spotify' && !user.isSpotifyLinked && !achieved && (
                <div className="pt-5">
                  <SpotifyLogin
                    isLinkAccountButton
                    revamp
                    buttonText={translateObj(commonTranslation.linkSpotify, language)}
                  />
                </div>
              )}
            />
          );
        })}
    </>
  )
  }

AchievementsList.propTypes = {
  loyalty: PropTypes.shape({
    achievements: PropTypes.arrayOf(PropTypes.shape()),
    loyaltyPoints: PropTypes.number,
    prizes: PropTypes.arrayOf(PropTypes.shape()),
  }),
  title: PropTypes.shape({}),
  noAchievements: PropTypes.shape({}),
  pointsLabel: PropTypes.shape({}),
  achievedOn: PropTypes.shape({}),
};

AchievementsList.defaultProps = {
  loyalty: {
    achievements: [],
    loyaltyPoints: 0,
    prizes: [],
  },
  title: translation.myAchievements,
  noAchievements: commonTranslation.noAchievements,
  pointsLabel: commonTranslation.points,
  achievedOn: commonTranslation.achieved,
};

export default AchievementsList;
