import React from 'react';
import PropTypes from 'prop-types';
import { useRoutes } from '../../../context/routesContext';
import translateObj from '../../../util/translateObj';

const FiltersSortButton = ({ text, selected, direction, onClickCallback, className }) => {
  const { language } = useRoutes();
  return (
    <button
      type="button"
      onClick={onClickCallback}
      className={`${className} flex items-center justify-between w-max rounded-full px-4 py-3 text-xs tracking-px text-black uppercase font-americaMonoRegular bg-white-dark border ${selected ? 'border-pro-online' : 'border-white-dark'}`}
    >
      {translateObj(text, language)}
      <span className={`pl-4 font-icon ${direction === 'asc' ? 'icon-arrow-up' : 'icon-arrow-down'}`} />
    </button>
  );
};

FiltersSortButton.propTypes = {
  text: PropTypes.shape(),
  selected: PropTypes.bool,
  direction: PropTypes.oneOf(['asc', 'desc']),
  onClickCallback: PropTypes.func,
  className: PropTypes.string,
};

FiltersSortButton.defaultProps = {
  text: { en: '', es: '', ca: '' },
  selected: false,
  direction: 'asc',
  onClickCallback: () => {},
  className: '',
};

export default FiltersSortButton;
