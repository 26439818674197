const translation = {
  boughtOn: {
    en: 'Achieved on',
    es: 'Conseguido el',
    ca: 'Aconseguit el',
    pt: 'Comprado em',
  },
  redeemButton: {
    en: 'Redeem',
    es: 'Canjear',
    ca: 'Bescanviar',
    pt: 'Regatar',
  },
  exportButton: {
    en: 'Export to Wallet',
    es: 'Exportar a Wallet',
    ca: 'Exportar a Wallet',
    pt: 'Exportar para Wallet',
  },
  descriptionTitle: {
    en: 'Description',
    es: 'Descripción',
    ca: 'Descripció',
    pt: 'Descrição',
  },
  sponsorTitle: {
    en: 'Sponsor',
    es: 'Entidad',
    ca: 'Entitat',
    pt: 'Entidade',
  },
  userLinkButton: {
    en: 'use it now',
    es: 'usar ahora',
    ca: 'fer-ho servir ara',
  },
};

export default translation;
