import React, { useReducer } from 'react';
import PropTypes from 'prop-types';

import Link from '../../../util/link';
import { useRoutes } from '../../../context/routesContext';
import sendEmailPresale from '../../../api/graphql/mutations/sendEmailPresale';
import reducer from './store/reducers';
import * as actions from './store/actions';

const defaultConditionsText = {
  en: 'I have read and accept the',
  es: 'He leído y acepto la',
  ca: 'Va llegir i acceptar les',
  pt: 'Li e aceito as',
};

const defaultConditionsNewsletterText = {
  es: 'Deseo suscribirme a la newsletter de conformidad con la',
  en: 'I want to subscribe to the newsletter based on your',
  ca: 'Desitjo suscriure’m a la newsletter de conformitat amb la',
  pt: 'Subscrever a nossa newsletter aceitando nossa',
};

const defaultSubmitButtonText = {
  en: 'Get pre-sale code',
  es: 'Recibir código de preventa',
  ca: 'Rebre codi de prevenda',
  pt: 'Receba o código de pré-venda',
};

const conditionsLink = {
  es: 'Política de Privacidad',
  en: 'Privacy policy',
  ca: 'Política de Privacitat',
  pt: 'Política de Privacidade',
};

const initialState = {
  acceptConditions: false,
  acceptConditionsNewsletter: false,
  error: null,
  loading: false,
  message: null,
  email: null,
};

const Presale = ({ conditionsText, conditionsTextNewsletter, productSlugName, submitButtonText, termsLink }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { language } = useRoutes();

  const { acceptConditions, acceptConditionsNewsletter, error, loading, message, email } = state;

  const disableButton = !email || email.length < 5 || loading || message || !acceptConditions;

  const handleOnChange = e => actions.setInputValue(dispatch)(e.target.name, e.target.value);
  const handleConditions = e => actions.toggleConditions(dispatch)(e.target.name, e.target.checked);

  const submit = () => {
    actions.submitData(dispatch)();
    sendEmailPresale({ productSlugName, email, acceptConditions, acceptConditionsNewsletter, language })
      .then((res) => {
        const { error: resError, isValid, message: resMessage } = res;
        if (!isValid && resError) return actions.submitError(dispatch)(resError);
        return actions.submitSuccess(dispatch)(resMessage?.[language]);
      })
      .catch(e => actions.submitError(dispatch)(e));
  };

  return (
    <div className="mt-8 p-8 w-full llg:w-3/4 flex flex-col h-2/4 text-black font-americaMonoRegular">
      <div className="flex sm:flex-col flex-wrap">
        <div className="px-3 mb-4 w-full">
          <label className="flex flex-col font-americaMonoBold uppercase" htmlFor="email">
            Email
            <input aria-label="Enter email" onChange={handleOnChange} className="mt-4 w-full rounded-full border-1 p-4 bg-inherit false border-black text-black" name="email" />
          </label>
        </div>
        <div className="px-3 my-4 w-full">
          <div className="flex items-center font-americaMonoBold uppercase">
            <input type="checkbox" className="mr-4" name="acceptConditionsNewsletter" onChange={handleConditions} />
            <p>
              {conditionsTextNewsletter[language]}
              {' '}
              <Link className="underline text-black" to={termsLink}>{conditionsLink[language]}</Link>
            </p>
          </div>
        </div>
        <div className="px-3 my-4 w-full">
          <div className="flex items-center font-americaMonoBold uppercase">
            <input type="checkbox" className="mr-4" name="acceptConditions" onChange={handleConditions} required />
            <p>
              {conditionsText[language]}
              {' '}
              <Link className="underline text-black" to={termsLink}>{conditionsLink[language]}</Link>
            </p>
          </div>
        </div>
        <div className="px-3 w-full">
          <button aria-label="Submit" id="btn-pre" className="w-full p-5 my-5 bg-ps-destination-blue rounded-full text-white" type="submit" onClick={submit} disabled={disableButton}>{message || submitButtonText[language]}</button>
          {error ? <p className="text-red">{error}</p> : null}
        </div>
      </div>
    </div>
  );
};

Presale.propTypes = {
  conditionsText: PropTypes.shape(),
  conditionsTextNewsletter: PropTypes.shape(),
  productSlugName: PropTypes.string.isRequired,
  submitButtonText: PropTypes.shape(),
  termsLink: PropTypes.string.isRequired,
};

Presale.defaultProps = {
  conditionsText: defaultConditionsText,
  conditionsTextNewsletter: defaultConditionsNewsletterText,
  submitButtonText: defaultSubmitButtonText,
};


export default Presale;
