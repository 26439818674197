import gql from 'graphql-tag';

import { clientWeb, persistor } from '../graphqlAPI';

const SEARCH_POSTS_QUERY = gql`
  query searchPosts($search: String!, $language: String!) {
    searchPosts(search: $search, language: $language) {
      slugName
      active
      postCategory
      postDescription {
        description {
          en
          es
          ca
          pt
        }
        title {
          en
          es
          ca
          pt
        }
        image {
          en
          es
          ca
          pt
        }
        tags
        url
        overlayImage
        date
        authors {
          name
          slug
        }
        relatedArtists {
          name
          slug
        }
      }
    }
  }
`;

const getSearchPosts = async (search, language = 'en') => {
  try {
    const result = await clientWeb
      .query({
        query: SEARCH_POSTS_QUERY,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
        variables: { search, language },
        fetchResults: true,
        pollInterva: 300000,
      })
      .then(queryResult => queryResult)
      .catch((queryError) => {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error: ${queryError}`);
        return null;
      });
    const {
      data: { searchPosts },
    } = result;

    if (searchPosts) {
      return searchPosts;
    }
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: GraphQL Query Error: searchPosts`);
    return null;
  } catch (error) {
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: searchPosts Error: ${error}`);
    return null;
  }
};

export default getSearchPosts;
