import translation from './__translationHelper';
import header from './_header';
import footer from './_footer';
import reunions from './_reunionItem_array';
import notifications from './_notificationItem_array';

const data = [
  { ...header },
  {
    type: 'Agenda',
    mentoringSessions: [ // provisional
      {
        type: 'MentoringSessionItem',
        eventSlugName: 'the-impact-of-streaming-on-latin-music',
        pretitle: translation('JUNE 1 & 2, 2022 | AUDITORI PARC DEL FORUM'),
        eventReadableName: translation('The impact of streaming on Latin music.'),
        subtitle: translation('Una conversación con Rocio Guerrero, (Latin music division Amazon Music). Entrevistada por: Oisin Lunny (Periodista).'),
        location: translation('Auditori parc del forum'),
        image: translation('https://assets.primaverasound.com/psweb/cm7a2vi61s5jut32qy1v_1638447365141.jpg'),
        artistSets: [
          {
            dateTimeEndReal: '1646648400000',
            dateTimeStartHuman: '1646648400000',
            dateTimeStartReal: '1646648400000',
            status: 'RESERVED',
            venue: { venueSlugName: 'coliseum', venueReadableName: { en: 'Coliseum', es: null, ca: null, pt: null } },
          },
        ],
        isListItem: false, // set to "true" when item is inside "ItemsList" or "RelatedSessions" component
      },
    ],
    reunions, // provisional
    notifications, // provisional
  },
  // news (pending)
  { ...footer },
];

export default data;
