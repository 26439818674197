import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  typography: {
    padding: theme.spacing.unit * 2,
  },
});

const InfoPopover = ({ classes, text, classNames }) => {
  // const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div className={classNames}>
      <span className="font-icon icon-share2 text-black cursor-pointer" aria-describedby={id} variant="outlined" size="small" onClick={handleClick} />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div className="p-4 sm:p-2 rounded-lg font-americaBlack bg-gray200 text-14 sm:text-12">
          <span>{text}</span>
        </div>
        {/* <Typography className={classes.typography}>{text}</Typography> */}
      </Popover>
    </div>
  );
};

InfoPopover.propTypes = {
  text: PropTypes.string,
  classNames: PropTypes.string,
};

InfoPopover.defaultProps = {
  text: '',
  classNames: '',
};

export default withStyles(styles)(InfoPopover);
