import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';
import translateObj from '../../util/translateObj';

const linkWrapper = Component => props => (
  <a href={props.link}>
    <Component {...props} />
  </a>
);

const DoubleTitle = ({
  primaryText, secondaryText, category, match,
}) => (
  <RoutesContext.Consumer>
    {({ language: contextLanguage, enteredFrom }) => {
      const localization = getLocalization(contextLanguage, enteredFrom, match);
      const blueText = () => (
        <span>
          {'   |   '}
          <span className={`text-${category} underline cursor-pointer leading-normal`}>{translateObj(secondaryText, localization.language)}</span>
        </span>
      );

      const SecondaryLabel = Object.keys(secondaryText).length ? linkWrapper(blueText) : null;
      return (
        <div className="w-full my-4 text-base sm:text-md md:text-md font-americaMonoBold leading-normal font-black lg:pt-4 xl:pt-4 uppercase">
          {translateObj(primaryText, localization.language)}
          { !!SecondaryLabel && <SecondaryLabel />}
        </div>
      );
    }}
  </RoutesContext.Consumer>
);

DoubleTitle.propTypes = {
  primaryText: PropTypes.shape({}).isRequired,
  secondaryText: PropTypes.shape({}),
  category: PropTypes.string,
};

DoubleTitle.defaultProps = {
  secondaryText: {},
  category: 'black',
};

export default withRouter(DoubleTitle);
