import React, { useState, useEffect } from 'react';
import { propTypes, withFormsy } from 'formsy-react';

import moment from 'moment';
import DatePicker from 'react-datepicker';

import InfoPopover from '../Form/InfoPopover';
import translateObj from '../../../util/translateObj';


const DatePickerFormsy = (props) => {
  const {
    existingDate, handleDate, title, name, language, selectDateMessage, bolder, style, infoText, required, setValue, getValue, backgroundColor = 'inherit',
  } = props;
  const [startDate, setStartDate] = useState();

  useEffect(() => {
    if (existingDate) {
      setStartDate(existingDate);
      setValue(existingDate);
    }
  }, [existingDate]);

  useEffect(() => {
    if (getValue() !== undefined) {
      getValue();
    }
  }, [props, startDate, existingDate]);

  const handleChange = (date) => {
    const dateWithoutTime = new Date(new Date(date.toDateString()).setHours(12)); // setHours to avoid UTC conversion to previous day
    setStartDate(dateWithoutTime);
    handleDate(name, dateWithoutTime);
    setValue(dateWithoutTime);
  };

  return (
    <div className={`${bolder ? '' : (style || 'py-4 ')}  mb-4 w-full lg:w-1/2 px-3`}>

      <div>
        <span className={`${bolder ? 'font-americaMonoBold' : 'font-americaMonoRegular text-xs'}  uppercase text-black flex`}>
          {translateObj(title, language)}
          {required && <span className="text-12 align-top text-red-light">*</span>}
          {infoText && <div className="ml-4"><InfoPopover text={infoText} /></div>}
        </span>
      </div>
      <DatePicker
        id={name}
        selected={Date.parse(startDate)}
        placeholderText={translateObj(selectDateMessage, language)}
        onChange={handleChange}
        showPopperArrow={false}
        dateFormat="MMMM d, yyyy"
        className={`px-6  outline-none rounded-full mt-2 w-full mr-4 font-americaMonoBold ${bolder ? 'py-4' : 'text-sm py-0.9'} text-center mt-4 rounded-full border-black text-black border-1 bg-${backgroundColor} cursor-pointer`}
        showYearDropdown
        dateFormatCalendar="MMMM"
        yearDropdownItemNumber={15}
        scrollableYearDropdown
        dropdownMode="select"
        formatWeekDay={nameOfDay => ''}
        maxDate={new Date()}
        // withPortal
        fixedHeight
      />
    </div>
  );
};


DatePickerFormsy.propTypes = {
  ...propTypes,
};

export default withFormsy(DatePickerFormsy);
