import gql from 'graphql-tag';

import { clientWeb } from '../graphqlAPI';

const GET_PRODUCTS_QUERY = gql`
  query Get($slugName: [String], $productGroup: String) {
    getProducts(slugName: $slugName, productGroup: $productGroup) {
      slugName
      productGroup
      subscriptionLength
      subscriptionDays
      title{ca en es}
      description{ca en es}
      amount
      subscriptionRenewalAmount
      currency
      productType
      productPresent
      paypal {
        clientId
        planId
      }
      hideCouponField
      extraFormFields
    }
  }
`;

const getProducts = async (slugName, productGroup) => {
  try {
    const result = await clientWeb
      .query({
        query: GET_PRODUCTS_QUERY,
        fetchPolicy: 'no-cache',
        variables: { slugName, productGroup },
      })
      .then(queryResult => queryResult)
      .catch((queryError) => {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error: ${queryError}`);
        return null;
      });
    const {
      data: { getProducts: getProductsData },
    } = result;

    if (getProductsData) {
      return getProductsData;
    }
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: GraphQL Query Error: getProducts`);
    return null;
  } catch (error) {
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: getProducts Error: ${error}`);
    return null;
  }
};

export default getProducts;
