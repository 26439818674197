/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React from 'react';
import { PropTypes } from 'prop-types';

import { withRouter, Link } from 'react-router-dom';
import moment from 'moment';
import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';
import imageModifier from '../helpers/imageModifier';
import stripTags from '../helpers/stripTags';
import RadioTag from '../Buttons/RadioTag';
import translateObj from '../../../util/translateObj';

function RadioChannelImage({
  match, program_title_multilang, subtitle_multilang, tags, image, dateAir, channel,
}) {
  return (
    <RoutesContext.Consumer>
      {({ language: contextLanguage, enteredFrom }) => {
        const localization = getLocalization(contextLanguage, enteredFrom, match);
        let tagsArray = [];
        if (tags !== undefined) {
          if (Array.isArray(tags)) {
            tagsArray = tags;
          } else {
            tagsArray.push(tags);
          }
        }
        const hasParentheses = image.includes(')');

        return (
          <div className="w-full">
            <div className="relative">
              {hasParentheses
                ? (
                  <div className="relative launcherGradientContainer h-112 md:h-96 sm:h-64 overflow-hidden bg-black flex justify-center">
                    {channel !== 3
                      ? <img src={imageModifier(image, '840x500', true)} alt={translateObj(program_title_multilang, localization.language)} />

                      : <img src={image} alt={translateObj(program_title_multilang, localization.language)} />

                    }
                  </div>
                )
                : (
                  <div
                    className="relative launcherGradientContainer h-112 md:h-96 sm:h-64"
                    style={{
                      backgroundImage: channel !== 3 ? `url(${imageModifier(image, '890x520')})` : `url(${image})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center center',
                    }}
                  />
                )

              }

              <div className="absolute pin-b pin-x p-4 sm:pb-0">
                {program_title_multilang && (
                <h4 className="font-americaBlack text-24 sm:text-18 text-white mb-0">
                  {translateObj(program_title_multilang, localization.language)}
                </h4>
                )}
                {subtitle_multilang && (
                  <h3 className="font-americaBlack text-40 sm:text-24 mb-4 text-white">
                      {stripTags(translateObj(subtitle_multilang, localization.language))}
                  </h3>
                )}
                {dateAir && <p className="font-americaMonoRegular text-xs uppercase mt-4 text-white my-0">{moment(dateAir).format('YY-MM-DD')}</p>}
                <div className="mb-6">
                  {tagsArray.map((tag, i) => tag[localization.language] && <Link key={tag.en + id} to={`${localization.path}/radio/explore/${tag.en}`}><RadioTag key={tag + i} category="white" text={tag} textColor="black" borderColor="white" margin="py-0 font-americaMonoRegular" /></Link>)}
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </RoutesContext.Consumer>
  );
}

// eslint-disable-next-line react/no-typos
RadioChannelImage.proptypes = {
  match: PropTypes.shape().isRequired,
  program_title_multilang: PropTypes.shape(),
  subtitle_multilang: PropTypes.shape(),
  image: PropTypes.string.isRequired,
};

RadioChannelImage.defaultProps = {
  presubtitle: null,
  program_title_multilang: null,
  subtitle_multilang: null,
};

export default withRouter(RadioChannelImage);
