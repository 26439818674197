import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useAuth } from '@primaveralabs/auth';
import { useRoutes } from '../../../context/routesContext';
import translateObj from '../../../util/translateObj';
import getTranslation from './util/translations/_getTranslation';
import LocalTranslation from './util/translations/MentoringSessionItem';
import setSessionReservation from '../../../api/graphql/mutations/setSessionReservation';
import createNotification from '../../../api/graphql/mutations/createNotification';

import SquareImage from './SquareImage';
import IconButton from './IconButton';
import Button from './Button';
import MentoringSessionMentorsList from './MentoringSessionMentorsList';
import { LinkWrapper } from '../../../util/link';
import SessionDateTitle from './SessionDateTitle';

const CANCEL_CONFIRM_MESSAGE = {
  en: 'Do you want to cancel this reservation?',
  es: '¿Quieres cancelar esta reserva?',
  ca: 'Vols cancel·lar la reserva?',
  pt: 'Quer cancelar esta reserva?',
};

const getGlobalStatus = (artistSets) => {
  const sessionIsReserved = artistSets.find((s) => s.status === 'RESERVED');
  const unavailableSessions = artistSets.filter((s) => s.status === 'UNAVAILABLE');
  if (sessionIsReserved) {
    const timestamp = Number(sessionIsReserved.dateTimeStartReal);
    const sessionDay = new Date(timestamp);
    const sessionDayBefore = sessionDay.setDate(sessionDay.getDate() - 1);
    return { status: 'RESERVED', alarmDate: `${sessionDayBefore}` };
  }
  if (unavailableSessions.length === artistSets.length) return { status: 'UNAVAILABLE', alarmDate: null };
  return { status: 'AVAILABLE', alarmDate: null };
};

const MentoringSessionItem = ({
  eventSlugName,
  eventReadableName,
  pretitle,
  subtitle,
  image,
  artistSets,
  mentorsData,
  callback,
  isListItem,
  translation,
  hasReminder,
  sessionPageSlugname,
}) => {
  const { language, path } = useRoutes();
  const literals = getTranslation(translation, LocalTranslation);
  const [globalStatus, setGlobalStatus] = useState({ status: 'AVAILABLE', alarmDate: null });
  const { user } = useAuth();
  const [showOptimisticReminder, setShowOptimisticReminder] = useState(hasReminder);

  const handleCancelSession = () => {
    const slugname = artistSets.find((artist) => artist.status === 'RESERVED').artistSetSlugName;
    // eslint-disable-next-line no-alert
    if (window.confirm(translateObj(CANCEL_CONFIRM_MESSAGE, language))) {
      setSessionReservation({
        artistSetSlugName: slugname,
      }).then((res) => {
        if (res?.payed === false) {
          console.log('Session reservation cancelled');
          if (callback) {
            callback();
          }
        }
      }).catch((err) => console.log(err));
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(`${window.location.origin}${path}/${sessionPageSlugname}/?id=${eventSlugName}`);
    document.getElementById('clipboard-tooltip').style.display = 'inline';
    setTimeout(() => {
      document.getElementById('clipboard-tooltip').style.display = 'none';
    }, 1000);
  };

  const handleSetAlarm = () => {
    createNotification({
      type: 'sessionReminder',
      relatedId: eventSlugName,
      sender: user.userId,
      recipients: [user.userId],
      reminder: globalStatus.alarmDate,
    }).then((res) => {
      if (res.result) {
        if (res.operation === 'created') {
          console.log('Reminder created');
          setShowOptimisticReminder(true);
        } else if (res.operation === 'deleted') {
          console.log('Reminder deleted');
          setShowOptimisticReminder(false);
        }
        if (callback) {
          callback();
        }
      }
    }).catch((err) => console.log(err));
  };

  useEffect(() => {
    const status = getGlobalStatus(artistSets);
    setGlobalStatus(status);
  }, []);

  return (
    <div className={isListItem ? 'mb-0' : 'mb-8 sm:mb-0'}>
      <div className={`w-full flex items-stretch ${isListItem ? 'flex-col justify-start sm:flex-row sm:justify-between' : 'justify-between'}`}>
        <div className={`relative overflow-hidden cursor-pointer ${isListItem ? 'w-full mb-8 sm:w-1/2 sm:mb-0' : 'w-1/2'}`}>
          <LinkWrapper url={`/${sessionPageSlugname}/?id=${eventSlugName}`} path={path}>
            <SquareImage image={typeof image === 'string' ? image : translateObj(image, language)} alt={translateObj(eventSlugName, language)} gradient dimensions="600x600" />
            <div className={`absolute pin h-full ${isListItem ? 'w-1/4 sm:w-1/3' : 'w-1/3'}`}>
              <div
                style={{ writingMode: 'vertical-rl', maxHeight: '30%' }}
                className={`text-white uppercase font-americaMonoRegular sm:tracking-auto tracking-1.5px pt-8 pl-4 sm:pt-3 md:pt-4 md:pl-2 sm:pl-1 sm:text-14 text-1.75vw sm:text-14 ${isListItem ? 'md:text-20' : ''}`}
              >
                {translateObj(literals.imageLabel, language)}
              </div>
            </div>
          </LinkWrapper>
        </div>
        <div className={`${isListItem ? 'w-full sm:w-1/2 sm:justify-between sm:flex sm:flex-col sm:ml-4' : 'w-1/2 justify-between flex flex-col ml-4'}`}>
          <div>
            <div className="mb-6 font-americaMonoRegular text-12 tracking-px uppercase w-full">
              {artistSets && <SessionDateTitle tickets={artistSets} isReserved={globalStatus.status === 'RESERVED'} />}
              {pretitle && translateObj(pretitle, language)}
            </div>
            <div className={`sm:hidden font-americaBlack mb-6 leading-tight cursor-pointer ${isListItem ? 'text-30' : 'text-24'}`}>
              <LinkWrapper url={`/${sessionPageSlugname}/?id=${eventSlugName}`} path={path}>
                <span className="text-black">{translateObj(eventReadableName, language)}</span>
              </LinkWrapper>
            </div>
            <div className="sm:hidden font-tiemposRegular text-14 mb-6">{translateObj(subtitle, language)}</div>
            {mentorsData && <MentoringSessionMentorsList items={mentorsData} className="mb-6" />}
          </div>
          {globalStatus.status === 'AVAILABLE' && (
            <Button
              text={translateObj(literals.buttonReserve, language)}
              color="black"
              className={isListItem ? 'w-1/3 sm:w-auto' : 'w-1/2 sm:w-auto'}
              href={`/${sessionPageSlugname}/?id=${eventSlugName}`}
            />
          )}
          {globalStatus.status === 'UNAVAILABLE' && (
            <Button
              text={translateObj(literals.buttonSoldOut, language)}
              color="disabledOrange"
              disabled
              className={isListItem ? 'w-1/3 sm:w-auto' : 'w-1/2 sm:w-auto'}
            />
          )}
          {globalStatus.status === 'RESERVED' && (
            <div className="flex">
              <IconButton className="mr-3" onClickCallback={() => handleSetAlarm()} icon="notification" color={showOptimisticReminder ? 'orange' : 'black'} />
              <div className="relative">
                <IconButton className="mr-3" onClickCallback={() => copyToClipboard()} icon="share" />
                <span id="clipboard-tooltip" className="absolute hidden p-1 bg-white text-pro-online text-xxs rounded whitespace-no-wrap">{translateObj(literals.shareTooltip, language)}</span>
              </div>
              <IconButton onClickCallback={() => handleCancelSession()} icon="cancel" />
            </div>
          )}
        </div>
      </div>
      <div className="hidden sm:block w-full mt-6">
        <div className="font-americaBlack text-20 mb-4 leading-tight">
          <LinkWrapper url={`/${sessionPageSlugname}/?id=${eventSlugName}`} path={path}>
            <span className="text-black">{translateObj(eventReadableName, language)}</span>
          </LinkWrapper>
        </div>
        <div className="font-tiemposRegular text-12 mb-6">{translateObj(subtitle, language)}</div>
      </div>
    </div>
  );
};

MentoringSessionItem.propTypes = {
  eventSlugName: PropTypes.string.isRequired,
  eventReadableName: PropTypes.shape(),
  pretitle: PropTypes.shape(),
  subtitle: PropTypes.shape(),
  image: PropTypes.shape(),
  isListItem: PropTypes.bool,
  mentorsData: PropTypes.arrayOf(PropTypes.shape()),
  callback: PropTypes.func,
  artistSets: PropTypes.arrayOf(PropTypes.shape()),
  translation: PropTypes.shape(),
  hasReminder: PropTypes.bool,
  sessionPageSlugname: PropTypes.string,
};

MentoringSessionItem.defaultProps = {
  eventReadableName: {},
  pretitle: {},
  subtitle: {},
  image: {},
  isListItem: false,
  artistSets: [],
  callback: null,
  translation: null,
  mentorsData: [],
  hasReminder: false,
  sessionPageSlugname: 'pro-mentoring-sessions-detail',
};

export default MentoringSessionItem;
