import React from 'react';

const RescheduleIcon = () => (
  <svg width="100%" height="100%" viewBox="0 0 16 17" xmlns="http://www.w3.org/2000/svg">
    <g fillRule="evenodd" transform="translate(0 .6365)">
      <path d="m15 12h-2.9193333l.2933333-.2926667c.39-.3906666.39-1.024 0-1.4146666-.3906667-.39000003-1.024-.39000003-1.4146667 0l-1.99999997 2c-.08666666.0866666-.148.1893333-.19666666.2966666-.01466667.0333334-.024.066-.03533334.1-.02666666.08-.04266666.1613334-.048.246-.002.0346667-.00666666.068-.00533333.1026667.004.106.02266667.212.06133333.3146667.00333334.0086666.00333334.018.00666667.0266666.00066667.0013334.00066667.0026667.00066667.0033334.04666666.112.11666666.2073333.19666666.2933333.00866667.0093333.01133334.022.02.0313333l1.99999997 2c.1953334.1953334.4513334.2926667.7073334.2926667s.512-.0973333.7073333-.2926667c.39-.3906666.39-1.024 0-1.4146666l-.2933333-.2926667h2.9193333c.552 0 1-.448 1-1s-.448-1-1-1" />
      <path d="m5.59333333 10.4866667c-.19333333.1933333-.44666666.2933333-.70666666.2933333-.25333334 0-.50666667-.1-.70666667-.2933333-.38666667-.3933334-.38666667-1.0266667 0-1.41333337l2.15333333-2.15333333v-3.98c0-.55333333.44666667-1 1-1 .55333334 0 1 .44666667 1 1v4.39333333c0 .26666667-.10666666.52-.29333333.70666667zm1.91333334 1.6333333c.06-.14.12666666-.2733333.21333333-.4.08666667-.1333333.18666667-.2533333.3-.3666667l2-1.99999997c.44-.44666666 1.0266667-.68666666 1.6466667-.68666666s1.2066666.24 1.6466666.68666666c.3266667.32.5466667.71999997.6333334 1.15999997.46-.95999997.72-2.03999997.72-3.17999997 0-4.04666666-3.2866667-7.33333333-7.33333337-7.33333333-4.04666666 0-7.33333333 3.28666667-7.33333333 7.33333333 0 4.04666667 3.28666667 7.33333337 7.33333333 7.33333337.22666667 0 .44666667-.0133334.67333334-.0333334-.13333334-.1333333-.24666667-.28-.34-.44-.06-.0933333-.11333334-.1933333-.15333334-.3-.03333333-.0733333-.06-.1466666-.08-.22-.04666666-.1466666-.07333333-.3-.08666666-.4466666-.01333334-.1533334-.01333334-.3 0-.4533334.02-.22.07333333-.44.16-.6533333z" />
    </g>
  </svg>
);

export default RescheduleIcon;
