import React from 'react';
import { PropTypes } from 'prop-types';

import StreamingVideoPlayerMainPlayerAll from '../StreamingVideoPlayerMainPlayerAll';
import StreamingVideoPlayerSmall from '../StreamingVideoPlayerSmall';
import LoadIcon from '../../LoadIcon';

const DashVideoScreen = ({
  isLoading,
  mainVideo,
  arrayOfVideos,
  handlePlayingSmallScreens,
  shouldMainVideoStart,
  handleOnReadyTrueForAll,
  PLAYER_RATIO,
  handleSwitchingVideos,
  shouldSmallVideoStart,
  activeSlide,
}) => (
  <>
    <div className={`bg-black ${isLoading && 'sm:min-h-10 md:min-h-20 min-h-256'}  flex flex-col justify-center`}>
      {isLoading && <LoadIcon color="black" type="ThreeDots" />}
      {mainVideo && (
      <div className={`${isLoading ? 'hidden' : 'relative w-full bg-black'} `} style={{ paddingTop: PLAYER_RATIO }}>
        <div className="absolute pin-t pin-l w-full">
          {arrayOfVideos.map(videoObj => (
            <StreamingVideoPlayerMainPlayerAll
              key={videoObj.title}
              activeSlide={activeSlide}
              mainVideo={videoObj.url}
              currentMainVideo={mainVideo === videoObj.url}
              autoPlay={false}
              muted={false}
              controls
              handlePlayingSmallScreens={handlePlayingSmallScreens}
              shouldMainVideoStart={shouldMainVideoStart}
              handleOnReadyTrueForAll={handleOnReadyTrueForAll}
              isLoading={isLoading}
            />

          ))}
        </div>
        {arrayOfVideos && arrayOfVideos.length > 1 && (
        <div className="bg-psworld-box p-4">
          <h3 className="mb-4 font-americaMonoBold text-xs uppercase">Choose Live Stream</h3>
          <div className="flex -mx-2">
            {arrayOfVideos.map((videoObject, index) => (
              <StreamingVideoPlayerSmall {...{
                key: videoObject.url,
                handleSwitchingVideos,
                shouldSmallVideoStart,
                index,
                length: arrayOfVideos.length,
                mainVideo,
                videoObject,
                handleOnReadyTrueForAll,
                isLoading,
                // componentShouldGetQuality,
                // getQualityFn,
              }}
              />
            )) }
          </div>
        </div>
        ) }
        {/* <button onClick={() => setComponentShouldGetQuality(true)} className="w-full bg-pink">GET QUALITY</button> */}
      </div>
      )}
    </div>
  </>
);

DashVideoScreen.propTypes = {
  arrayOfVideos: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  mainVideo: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  handlePlayingSmallScreens: PropTypes.func.isRequired,
  shouldMainVideoStart: PropTypes.bool,
  handleOnReadyTrueForAll: PropTypes.func.isRequired,
  handleSwitchingVideos: PropTypes.func.isRequired,
  shouldSmallVideoStart: PropTypes.bool,
  PLAYER_RATIO: PropTypes.string,
  activeSlide: PropTypes.number,
};

DashVideoScreen.defaultProps = {
  isLoading: true,
  shouldMainVideoStart: null,
  shouldSmallVideoStart: false,
  PLAYER_RATIO: '56.25%',
  activeSlide: 0,
};

export default DashVideoScreen;
