import React from 'react';
import { PropTypes } from 'prop-types';

import { withRouter } from 'react-router-dom';
import moment from 'moment';
import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';
import ComponentIterator from '../../containers/ComponentIterator';
import RadioPlayButton from './RadioPlayButton';
import imageModifier from './helpers/imageModifier';

class Player extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.open || false,
    };
  }

  toggle() {
    const { open } = this.state;
    this.setState({ open: !open }, () => {
      if (this.props.toggleCallback) {
        this.props.toggleCallback(this.state.open);
      }
    });
  }

  render() {
    let {
      match,
      title,
      startDate,
      endDate,
      tags,
      durationText,
      text1,
      icon1Url,
      icon2Url,
      icon3Url,
      icon4Url,
      icon5Url,
      radioPlayButton,
      imageUrl,
    } = this.props;

    const { open } = this.state;

    return (
      <RoutesContext.Consumer>
        {
        ({ language: contextLanguage, enteredFrom }) => {
          const localization = getLocalization(contextLanguage, enteredFrom, match);

          startDate = moment(startDate);
          endDate = moment(endDate);
          const duration = moment.duration(endDate.diff(startDate)).asMinutes();

          return (
            <div className="bg-radio w-full flex text-12 text-white pr-5 sm:pl-5 sm:py-5">
              <img className="sm:hidden mr-4" src={imageModifier(imageUrl, '100x100')} alt={title[localization.language]} />
              <div className="flex items-center">
                <RadioPlayButton {...radioPlayButton} />
              </div>
              <div className="flex-1 flex flex-col px-5 justify-center">
                <h5 className="font-americaMonoBold uppercase">{title[localization.language]}</h5>
                {text1 ? <span className="mt-2 font-tiemposRegular ">{text1[localization.language]}</span> : null}
              </div>
              <div className="flex-1 px-5 flex items-center justify-end sm:hidden">
                <img className="w-5 mr-3" src={icon1Url} alt={durationText[localization.language]} />
                <span className="font-americaMonoBold uppercase mr-5">
                  {duration}
                  {' '}
                  {durationText[localization.language]}
                </span>
                <div className="">
                  <ComponentIterator components={tags} />
                </div>
              </div>
              <div className="sm:hidden mr-5 flex items-center">
                <img className="w-12" src={icon5Url} alt={title[localization.language]} />
              </div>
              <div className="flex items-center">
                <img className="sm:hidden" src={icon3Url} alt={title[localization.language]} />
                <img className="hidden sm:block mr-3" src={icon4Url} alt={title[localization.language]} />
              </div>
              <div className="hidden sm:flex items-center" onClick={this.toggle.bind(this)}>
                <img className={`w-4 ${open ? 'rotate-180' : ''}`} src={icon2Url} alt={title[localization.language]} />
              </div>
            </div>
          );
        }
      }
      </RoutesContext.Consumer>
    );
  }
}

Player.propTypes = {
  title: PropTypes.shape().isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  durationText: PropTypes.shape().isRequired,
  tags: PropTypes.array.isRequired,
  icon1Url: PropTypes.string.isRequired,
  icon2Url: PropTypes.string.isRequired,
  icon3Url: PropTypes.string.isRequired,
  icon4Url: PropTypes.string.isRequired,
  icon5Url: PropTypes.string.isRequired,
  open: PropTypes.bool,
  radioPlayButton: PropTypes.shape().isRequired,
};

export default withRouter(Player);
