import React, {
  useState, useEffect,
} from 'react';
import { PropTypes } from 'prop-types';
import ComponentIterator from '../../../containers/ComponentIterator';
import withRadioPlayerContext from '../../../context/withRadioPlayerContext';
import useFullscreenStatus from './useFullscreenStatus';
import { ChatVisibilityProvider } from '../Chat/context/chatVisibilityContext';
import { PrivatePlusOneZoneProvider } from '../Chat/context/privatePlusOneZoneContext';
import { checkIfStreamModuleIncludesChat, checkIfUserHasTheRequiredRolesToSeeTheChat } from './streamingUtils';
import { useUser } from '../../../context/userProfileContext';


const SingleStreamPage = ({
  components,
  hideRadio,
  hidePlayer,
  showPlayer,
  setPlayerPosition,
}) => {
  const { userRoles } = useUser();
  const maximizableElement = React.useRef(null);
  const [isVisible, setChatVisibility] = useState(false);
  const [isLayoutWithChat, setIsLayoutWithChat] = useState(false);
  const [comps, setComps] = useState(null);
  let isFullscreen;
  let setIsFullscreen;
  let errorMessage;
  try {
    [isFullscreen, setIsFullscreen] = useFullscreenStatus(maximizableElement);
  } catch (e) {
    setIsFullscreen = false;
    errorMessage = 'Fullscreen not supported';
  }
  const handleExitFullscreen = () => window.document.exitFullscreen();

  // * check if page does not include chat
  useEffect(() => {
    if (comps && comps.length > 0) {
      const doesStreamModuleIncludeChat = checkIfStreamModuleIncludesChat(comps);
      const doesUserHaveTheReqiuredRoles = checkIfUserHasTheRequiredRolesToSeeTheChat(comps, userRoles);
      if (!doesStreamModuleIncludeChat || !doesUserHaveTheReqiuredRoles) {
        setIsLayoutWithChat(false);
        setChatVisibility(false);
      } else if (doesStreamModuleIncludeChat) {
        setIsLayoutWithChat(true);
        setChatVisibility(true);
      }
    }
  }, [comps, userRoles]);

  useEffect(() => {
    const updatedComponents = [...components];
    if (components.length) {
      updatedComponents.splice(1, 0, {
        type: 'TheaterModeBtn',
        isOn: isFullscreen,
        onEnter: setIsFullscreen,
        onExit: handleExitFullscreen,
        classes: '',
        errorMessage,
      });
    }
    setComps(updatedComponents);
    // setIsLoading(false);
  }, [isFullscreen]);

  const toggleChat = () => {
    setChatVisibility(prevState => !prevState);
  };
  useEffect(() => {
    setPlayerPosition('pin-b -mb-16');
    hidePlayer();
    hideRadio(true);
    return () => { hideRadio(false); showPlayer(); };
  }, []);

  const onVisibilityChange = () => {
    if (!document.hasFocus()) {
      setChatVisibility(false);
    }
  };

  // useEffect(() => {
  //   document.addEventListener('visibilitychange', onVisibilityChange);
  //   return () => document.removeEventListener('visibilitychange', onVisibilityChange);
  // }, []);

  return (
    <div
      id="single-stream-page"
      ref={maximizableElement}
      className={`single-stream-page ${!isVisible ? 'hide-chat ' : ''} ${isFullscreen ? 'fullscreen ' : ''}bg-black h-fullcalc max-h-fullcalc text-white w-full md:pr-chat lg:pr-chat xl:pr-chat full-width self-start overflow-x-hidden scrollbar__streampage`}
    >
      {components && (
        <>
          {isLayoutWithChat && (
          <button
            type="button"
            className="bg-psworld-box flex items-center z-radio chat-toggler sm:hidden fixed text-white hover:text-psworld-orange flex flex-col justify-center h-8 py-5"
            onClick={toggleChat}
          >
            {isVisible ? <span className="font-icon icon-arrow-right-round" /> : <span className="font-americaMonoRegular uppercase text-xs font-bold">Chat</span>}
          </button>
          )}
          <PrivatePlusOneZoneProvider>
            <ChatVisibilityProvider handleExitFullscreen={handleExitFullscreen} isFullscreen={isFullscreen} setIsFullscreen={setIsFullscreen} setChatVisibility={setChatVisibility} isChatOpen={isVisible}>
              <ComponentIterator components={components} />
            </ChatVisibilityProvider>
          </PrivatePlusOneZoneProvider>
        </>
      )}
    </div>
  );
};

SingleStreamPage.propTypes = {
  components: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  hideRadio: PropTypes.func.isRequired,
  hidePlayer: PropTypes.func.isRequired,
  showPlayer: PropTypes.func.isRequired,
  setPlayerPosition: PropTypes.func.isRequired,
};

export default withRadioPlayerContext(SingleStreamPage);
