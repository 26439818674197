import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { PropTypes } from 'prop-types';
import withRadioPlayerContext from '../../../context/withRadioPlayerContext';

const styles = theme => ({
  radioPlayIcon: {
    position: 'relative',
    height: '16px',
    width: '16px',
  },
  circle: {
    display: 'block',
    height: '16px',
    width: '16px',
    borderRadius: '50%',
    position: 'absolute',
    zIndex: 1,
  },
  animatedCircle1: {
    animation: 'mui-waves 2400ms linear infinite',
  },
  animatedCircle2: {
    animation: 'mui-waves 2400ms linear 600ms infinite',
  },
  animatedCircle3: {
    animation: 'mui-waves 2400ms linear 1200ms infinite',
  },
  animatedCircle4: {
    animation: 'mui-waves 2400ms linear 1800ms infinite',
  },
  animatedCircle5: {
    animation: 'mui-waves 2400ms linear 2400ms infinite',
  },
  smallCircle: {
    height: '6px',
    width: '6px',
    zIndex: 2,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
  },
  circleOut1: {
    transform: 'scale(1.5)',
    opacity: '.3',
  },
  circleOut2: {
    transform: 'scale(2)',
    opacity: '.4',
  },
  '@keyframes mui-waves': {
    '0%': {
      transform: 'scale(0)',
      opacity: '1',
    },

    '100%': {
      transform: 'scale(3)',
      opacity: 0,
    },
  },
});

const RadioPlayIcon = (props) => {
  const {
    classes, isPlaying, bgCircle, bgWave, radioActive, top, right, playing, channel, classNames,
  } = props;
  return (
    <div className={`${classes.radioPlayIcon} ${classNames}`} style={{ top, right }}>
      <div className={isPlaying || (playing && channel === radioActive) ? `${classes.circle} bg-${bgWave}` : 'hidden'} />
      <div className={isPlaying || (playing && channel === radioActive) ? `${classes.animatedCircle2} ${classes.circle} border-solid border-${bgWave} border-1 opacity-0` : `${classes.circle} ${classes.circleOut2} border-${bgWave} border-1`} />
      <div className={isPlaying || (playing && channel === radioActive) ? `${classes.animatedCircle3} ${classes.circle} border-solid border-${bgWave} border-1 opacity-0` : `${classes.circle} ${classes.circleOut1} border-${bgWave} border-1`} />
      <div className={isPlaying || (playing && channel === radioActive) ? `${classes.animatedCircle4} ${classes.circle} border-solid border-${bgWave} border-1 opacity-0` : `${classes.circle} bg-${bgWave}`} />
      <div className={isPlaying || (playing && channel === radioActive) ? `${classes.animatedCircle5} ${classes.circle} border-solid border-${bgWave} border-1 opacity-0` : `${classes.circle} bg-${bgWave}`} />
      <div className={`${classes.circle} ${classes.smallCircle} bg-${bgCircle}`} />
    </div>
  );
};

RadioPlayIcon.propTypes = {
  classes: PropTypes.shape().isRequired,
  isPlaying: PropTypes.bool,
  bgCircle: PropTypes.string,
  bgWave: PropTypes.string,
  top: PropTypes.string,
  right: PropTypes.string,
  channel: PropTypes.string,
  radioActive: PropTypes.string,
  classNames: PropTypes.string,
};

RadioPlayIcon.defaultProps = {
  isPlaying: false,
  bgCircle: 'white',
  bgWave: 'radio',
  top: '0px',
  right: '0px',
  channel: '',
  radioActive: '',
  classNames: '',
};

export default withRadioPlayerContext(withStyles(styles)(RadioPlayIcon));
