import React from 'react';
import PropTypes from 'prop-types';
import {
  TimelineMax, Power1, Power0, TweenMax,
} from 'gsap';
import { withRouter } from 'react-router-dom';
import ComponentIterator from '../../../containers/ComponentIterator';
import BodyChildContainer from '../../../containers/BodyChildContainer';
import PSResponsiveUtility from '../LineUp/PSResponsiveUtility';
import translateObj from '../../../util/translateObj';
import Link from '../../../util/link';
import withAllContexts from '../../../context/withAllContexts';

const ARIA_LABEL_CLOSE_MENU = {
  en: 'Close menu',
  es: 'Cerrar menú',
  ca: 'Tancar menú',
  pt: 'Fechar menu',
};

function getOpticalAdjustment(character) {
  switch (character.toUpperCase()) {
    case 'B':
    case 'D':
    case 'E':
    case 'F':
    case 'H':
    case 'I':
    case 'K':
    case 'L':
    case 'M':
    case 'N':
    case 'P':
    case 'R':
    case 'T':
    case 'U':
    case 'Z':
      return '5px';
    case 'C':
    case 'G':
    case 'O':
    case 'Q':
    case 'S':
      return '7px';
    default: return '1px';
  }
}

class TopHeaderMegamenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeLink: props.activeLink,
      firstFocusableEl: null,
      lastFocusableEl: null,
    };
    this.responsiveUtility = React.createRef();
  }

  componentWillMount() { }

  componentDidMount() {
    this.handleFocusableItems();

    document.addEventListener('keydown', this.keydown, false);
    this.animate('show');

    const { history } = this.props;
    this.unlisten = history.listen(() => {
      this.animate('hide');
    });
  }

  componentDidUpdate(prevProps) {
    const { nextSection } = this.props;
    if (nextSection !== prevProps.nextSection) {
      this.animate('switch');
    }
  }

  componentWillUnmount() {
    if (this.unlisten) this.unlisten();
    document.removeEventListener('keydown', this.keydown, false);
  }

  keydown = (event) => {
    const { firstFocusableEl, lastFocusableEl } = this.state;
    if (event.keyCode === 27) {
      this.animate('hide');
    }
    // trap focus
    if (event.key === 'Tab' || event.keyCode === 9) {
      if (event.shiftKey) /* shift + tab */ {
        if (document.activeElement === firstFocusableEl) {
          lastFocusableEl.focus();
          event.preventDefault();
        }
      } else /* tab */ {
        if (document.activeElement === lastFocusableEl) {
          firstFocusableEl.focus();
          event.preventDefault();
        }
      }
    }
  };

  handleFocusableItems() {
    // get all focusable elements
    const element = document.getElementById('megaMenu');
    const focusableEls = element.querySelectorAll(
      `a[href]:not([disabled], [tabIndex = "-1"]),
        button:not([disabled],[tabIndex = "-1"]),
        textarea:not([disabled], [tabIndex = "-1"]),
        input[type="text"]:not([disabled], [tabIndex = "-1"]),
        input[type="radio"]:not([disabled], [tabIndex = "-1"]),
        input[type="checkbox"]:not([disabled], [tabIndex = "-1"]),
        select:not([disabled], [tabIndex = "-1"])`,
    );
    // set first and last focusable items inside MegaMenu
    if (focusableEls.length > 0) {
      this.setState({
        firstFocusableEl: focusableEls[0],
        lastFocusableEl: focusableEls[focusableEls.length - 1],
      });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  rotateClose(entering = true) {
    TweenMax.to('[data-name="megamenu"] [data-name="close"]', 0.4, {
      rotation: entering ? 180 : 0,
    });
  }

  handleClickOnLink(e, link) {
    // if (link && link.key === 'porto') return null;
    e.preventDefault();
    if (!link.menu) {
      const { goTo, localization } = this.props;
      goTo(link.link, localization.path);
    } else {
      const { toggleItemCallback, localization } = this.props;
      toggleItemCallback(e, link, localization.path);
    }
  }

  animate(type = 'show') {
    const deviceScreen = this.responsiveUtility.current.deviceScreen(true);
    const isTabletOrSmaller = ['sm', 'md'].indexOf(deviceScreen) > -1;

    if (type === 'show') {
      const ease = Power1.easeOut;
      const tl = new TimelineMax();

      tl.fromTo(
        '[data-name="megamenu"] > div',
        0.5,
        {
          autoAlpha: 0,
        },
        {
          autoAlpha: 1,
          ease,
        },
      );

      const submenus = document.querySelectorAll('[data-name="megamenu"] [data-name="submenu"]');
      let submenuItemsLength = 0;
      submenus.forEach((submenu) => {
        const submenuItems = submenu.querySelectorAll('[data-name="submenu-item"]');
        if (submenuItems.length > submenuItemsLength) submenuItemsLength = submenuItems.length;
        tl.staggerFromTo(
          submenuItems,
          0.35,
          {
            autoAlpha: 0,
            top: 20,
          },
          {
            autoAlpha: 1,
            top: 0,
            ease,
          },
          0.1,
          0.1,
        );
      });

      const submenuText = document.querySelector('[data-name="megamenu"] [data-name="submenu-text"]');
      if (submenuText) {
        tl.fromTo(
          submenuText,
          0.7,
          {
            autoAlpha: 0,
          },
          {
            autoAlpha: 1,
            ease,
          },
        );
      }

      const channels = document.querySelector('[data-name="megamenu"] [data-name="channels"]');
      if (channels) {
        const offset = isTabletOrSmaller ? '-=1.4' : '-=0.4';
        tl.fromTo(
          channels,
          0.7,
          {
            autoAlpha: 0,
            y: '20%',
          },
          {
            autoAlpha: 1,
            y: '0%',
            ease,
          },
          offset,
        );
      }

      tl.fromTo(
        '[data-name="megamenu"] [data-name="title"]',
        0.7,
        {
          y: '20%',
        },
        {
          y: '0%',
          ease,
        },
        '-=0.2',
      );
      tl.fromTo(
        '[data-name="megamenu"] [data-name="title"]',
        1,
        {
          autoAlpha: 0,
        },
        {
          autoAlpha: 1,
          ease,
        },
        '-=0.7',
      );

      tl.fromTo(
        '[data-name="megamenu"] [data-name="menu"], [data-name="megamenu"] [data-name="close"]',
        0.7,
        {
          autoAlpha: 0,
          y: '20%',
        },
        {
          autoAlpha: 1,
          y: '0%',
          ease,
        },
        '-=0.4',
      );

      tl.fromTo(
        '[data-name="megamenu"] [data-name="footer"]',
        0.6,
        {
          autoAlpha: 0,
        },
        {
          autoAlpha: 1,
        },
        '-=0.7',
      );
    }
    if (type === 'hide') {
      const { toggleItemCallback, headerContext } = this.props;
      const tl = new TimelineMax();

      tl.fromTo(
        '[data-name="megamenu"]',
        0.5,
        {
          autoAlpha: 1,
        },
        {
          autoAlpha: 0,
        },
      );

      tl.eventCallback('onComplete', () => {
        if (!isTabletOrSmaller && headerContext.megaMenuIsOpenDesktop) {
          headerContext.setMegaMenuIsOpenDesktop(false);
        }
        toggleItemCallback();
      });
    }
    if (type === 'switch') {
      const { nextSection } = this.props;
      const tl = new TimelineMax();
      tl.fromTo(
        '[data-name="megamenu"] [data-name="main"]',
        0.4,
        {
          autoAlpha: 1,
        },
        {
          autoAlpha: 0,
          y: 0.1,
          ease: Power0.easeNone,
          onComplete: () => {
            this.setState({ activeLink: nextSection }, () => {
              tl.fromTo(
                '[data-name="megamenu"] [data-name="main"]',
                0.9,
                {
                  autoAlpha: 0,
                  y: 30,
                },
                {
                  y: 0,
                  autoAlpha: 1,
                  delay: 0.4,
                  ease: Power0.easeNone,
                },
              );
              this.handleFocusableItems();
            });
          },
        },
      );
    }
  }

  render() {
    const { links, localization, festivalsText } = this.props;

    const { activeLink } = this.state;


    const {
      menu: {
        section, name, main, footer, text, link,
      },
      button,
    } = activeLink;

    const insideButton = button ? Object.assign({ mainParent: 'megamenu' }, button) : false;

    const ViewLandingLink = () => (
      <Link
        to={`${link}`}
        localizationpath={localization.path}
        className="text-black inline-block overflow-hidden border-b-1 border-solid border-black font-americaMonoBold font-black text-base md:mb-6 sm:mb-6 uppercase pb-2px"
        key={link}
      >
        <span className="inline-block font-icon icon-arrow-right text-xxs" style={{ transform: 'translateX(-2px)' }} />
        <span className="ml-2">{translateObj(text, localization.language)}</span>
      </Link>
    );

    return (
      <div
        id={name}
        data-name="megamenu"
        data-menu-type={section}
        className="w-full sm:block md:block fixed pin w-full sm:static md:static pin-l pin-t z-header" // overflow-y-scroll
      >
        <PSResponsiveUtility ref={this.responsiveUtility} />
        <div data-name="wrapper" className={`px-6 transition-background-04s absolute sm:static md:static pin bg-${section} flex justify-center items-center sm:block md:block sm:pb-2`}>
          <BodyChildContainer className="mt-0">
            <div className="w-full h-screen sm:h-auto md:h-auto pt-4 flex flex-col sm:block md:block">
              <div className="w-full sm:hidden md:hidden">
                <section role="navigation" className="flex justify-between">
                  <nav data-name="menu" className="flex overflow-hidden" style={{ height: '25px' }}>
                    {links.map((theLink) => {
                      /* These are jumping between the megamenu  */
                      let activeLinkStyle = '';
                      if (theLink.key === activeLink.key) {
                        activeLinkStyle = 'border-b-3 pb-4 border-black';
                      }

                      if (typeof theLink.showOnMegamenu === 'boolean' && !theLink.showOnMegamenu) {
                        return null;
                      }

                      return (
                        <button
                          type="button"
                          key={theLink.key}
                          onClick={e => this.handleClickOnLink(e, theLink)}
                          className={`${activeLinkStyle} ${typeof theLink.showOnMegamenu === 'boolean' && !theLink.showOnMegamenu ? 'desktop:hidden' : ''} cursor-pointer whitespace-no-wrap block font-americaMonoBold uppercase tracking-wide font-bold text-base w-auto sm:flex sm:items-center sm:justify-between md:flex md:items-center md:justify-between mr-8`}
                          tabIndex={typeof theLink.showOnMegamenu === 'boolean' && !theLink.showOnMegamenu ? '-1' : '0'}
                          aria-label={translateObj(theLink.text, localization.language)}
                        >
                          {translateObj(theLink.text, localization.language)}
                        </button>
                      );
                    })}
                  </nav>
                  <button
                    type="button"
                    className="cursor-pointer mb-4"
                    onClick={() => this.animate('hide')}
                    aria-label={translateObj(ARIA_LABEL_CLOSE_MENU, localization.language)}
                  >
                    <div
                      data-name="close"
                      onMouseEnter={() => this.rotateClose(true)}
                      onMouseLeave={() => this.rotateClose(false)}
                    >
                      <span className="font-icon icon-close text-black text-lg" />
                    </div>
                  </button>
                </section>
              </div>
              <div data-name="main" className="flex-1 sm:flex-auto md:flex-auto flex flex-col justify-center sm:block md:block md:mb-6 text-left">
                <nav>
                  <div data-name="title" className="flex items-center justify-between mt-6 mb-8 sm:hidden md:hidden">
                    <div>
                      <span className="whitespace-no-wrap block font-americaBlack">
                        <Link to={`${link}`} localizationpath={localization.path} className="text-black" key={link}>
                          <span className="flex flex-col">
                            <h2
                              className="leading-tight inline-block text-80"
                              style={{ transform: `translateX(-${getOpticalAdjustment(translateObj(activeLink.text, localization.language).charAt(0))})` }}
                            >
                              {translateObj(activeLink.text, localization.language)}
                            </h2>
                            {'caption' in activeLink.menu && (
                              <h3 className="uppercase font-americaMono text-20 mb-4">
                                {translateObj(activeLink.menu.caption, localization.language)}
                              </h3>
                            )}
                          </span>
                        </Link>
                      </span>
                      <ViewLandingLink />
                    </div>
                    {insideButton && (
                      <div data-name="button">
                        <ComponentIterator components={[insideButton]} />
                      </div>
                    )}
                  </div>
                  <div className="hidden sm:block md:block">
                    <ViewLandingLink />
                  </div>
                  <ComponentIterator components={main} />
                </nav>
              </div>
              <ComponentIterator components={footer} />
            </div>
          </BodyChildContainer>
        </div>
      </div>
    );
  }
}

TopHeaderMegamenu.propTypes = {
  localization: PropTypes.shape().isRequired,
  activeLink: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
  festivalsText: PropTypes.shape().isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  links: PropTypes.array.isRequired,
  toggleItemCallback: PropTypes.func.isRequired,
  goTo: PropTypes.func.isRequired,
  // eslint-disable-next-line react/require-default-props
  nextSection: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape()]),
  headerContext: PropTypes.shape({}).isRequired,
};

export default withRouter(withAllContexts(TopHeaderMegamenu));
