import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import RadioPlayerWrapper from './RadioPlayerWrapper';
import MixcloudPlayer from './MixcloudPlayer';

import withRadioPlayerContext from '../../../context/withRadioPlayerContext';

const RadioContainer = ({
  showRadioPlayer, playerPosition, podcast, ch2, ch1, pageHasPlayer,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isVisiblePlayer, setVisiblePlayer] = useState(true);
  useEffect(() => {
    setVisiblePlayer(showRadioPlayer);
  }, [showRadioPlayer]);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const closePlayer = () => {
    setVisiblePlayer(false);
  };
  return (
    <>
      {(ch2 || ch1 || podcast) && pageHasPlayer
      && (
      <div className={`w-full mt-0 trans-opacity fixed ${isOpen ? 'z-top' : 'z-radio'} h-radio ${showRadioPlayer && isVisiblePlayer ? 'bg-radio' : ''} ${playerPosition}`}>
        {
            // eslint-disable-next-line no-nested-ternary
            showRadioPlayer
              ? <RadioPlayerWrapper closePlayer={closePlayer} isVisiblePlayer={isVisiblePlayer} toggleOpen={toggleOpen} />
              : podcast ? <MixcloudPlayer /> : null
          }
      </div>
      )
    }
    </>
    // <></>
  );
};


export default withRadioPlayerContext(RadioContainer);
