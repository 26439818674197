import gql from 'graphql-tag';

import { clientWeb, persistor } from '../graphqlAPI';

const GET_EVENTS_BY_POST_SLUG_NAME = gql`
  query getEventsByPostSlugName($postSlugName: String!) {
    getEventsByPostSlugName(postSlugName: $postSlugName) {
      eventSlugName
      artistSlugName
    }
  }
`;

const getEventsByPostSlug = async (slug) => {
  try {
    const result = await clientWeb
      .query({
        query: GET_EVENTS_BY_POST_SLUG_NAME,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
        variables: { postSlugName: slug },
        fetchResults: true,
        pollInterva: 300000,
      })
      .then((queryResult) => queryResult)
      .catch((queryError) => {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error: ${queryError}`);
        return null;
      });
    const {
      data: { getEventsByPostSlugName },
    } = result;

    if (getEventsByPostSlugName) {
      return getEventsByPostSlugName;
    }
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: GraphQL Query Error: getEventsByPostSlugName`);
    return null;
  } catch (error) {
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: getEventsByPostSlugName Error: ${error}`);
    return null;
  }
};

export default getEventsByPostSlug;
