import React, { useRef, useState, useEffect } from 'react';
import uuid from 'react-uuid';
import { PropTypes } from 'prop-types';
import { useChat } from '../context/chatContext';
import View from './View';
import MessageInput from './MessageInput';
import UserList from './UserList';
import { useMessages } from '../hooks/useMessages';
import { sendMessage, enterPrivateChannel } from '../utils/helpers';

const PrivateChannel = ({
  activeChannel, setActiveChannel, channelName,
}) => {
  const { userData, showVideoRoomButton, getActiveUsers } = useChat();
  const chatViewRef = useRef(null);
  const [isAtBottom, setScrollBottomState] = useState(false);
  const [hasNewMessages, setNewMessagesState] = useState(false);
  const [isLoadedMessages, setLoadedMessagesState] = useState(false);
  const [hasScrollbar, setHasScrollbar] = useState(false);
  const [channelMessages, setChannelMessages] = useState([]);
  const [showUsers, setShowUsers] = useState(false);
  const { current } = chatViewRef;

  const {
    messages, setMessages, isLoadingMessages,
  } = useMessages({ channel: activeChannel, isQuestions: false });

  const toggleShowUserList = () => {
    setShowUsers(!showUsers);
  };

  const checkScrollbarState = () => {
    if (current.scrollHeight > current.clientHeight) {
      setHasScrollbar(true);
    }
  };

  const scrollToLastMessage = () => {
    if (current && activeChannel) {
      current.scrollTop = 10 ** 10;
      checkScrollbarState();
    }
  };

  const updateChat = (userMessage, customType = '') => {
    const messageId = uuid();
    const message = {
      messageId,
      message: userMessage,
      _sender: userData,
      customType,
    };
    setMessages([...messages, message]);
    sendMessage({ channelUrl: activeChannel, data: message });
    scrollToLastMessage();
  };

  const handlePlusZoneMessage = async () => {
    updateChat('Join my channel', 'plusZone');
  };

  const chatListener = (e) => {
    const { target } = e;
    if (target.scrollTop >= target.scrollHeight - target.offsetHeight - 10) {
      setScrollBottomState(true);
      setNewMessagesState(false);
    } else {
      setScrollBottomState(false);
    }
  };

  const handleNewMessages = () => {
    if (!isAtBottom) {
      setNewMessagesState(true);
    } else {
      scrollToLastMessage();
    }
  };

  const handleShowUsersList = async () => {
    toggleShowUserList();
    getActiveUsers();
  };

  useEffect(() => {
    if (messages && isLoadedMessages) {
      setChannelMessages(messages);
      handleNewMessages();
    }
  }, [messages, isLoadedMessages]);

  useEffect(() => {
    if (!isLoadingMessages) {
      scrollToLastMessage();
      setLoadedMessagesState(true);
    }
  }, [isLoadingMessages]);

  useEffect(() => {
    if (activeChannel) {
      enterPrivateChannel({ channel: activeChannel, userData }).catch(err => console.log(err));
    }
  }, [activeChannel]);

  return (
    <>
      <div className="relative uppercase text-sm justify-between font-americaMonoRegular px-2 h-8 py-5 flex items-center bg-psworld-box text-white">
        <span className="cursor-pointer hover:text-psworld-orange" onClick={() => setActiveChannel(null)}>{'<'}</span>
        <span>{!showUsers ? channelName : 'Online Users'}</span>
        <span className="cursor-pointer hover:text-psworld-orange" onClick={handleShowUsersList}>{showUsers ? '-' : '+'}</span>
      </div>
      {activeChannel && showUsers ? <UserList channel={activeChannel} />
        : (
          <>
            <div ref={chatViewRef} onScroll={chatListener} className="flex-2 chat-container overflow-y-auto overflow-x-hidden relative scrollbar__streampage pb-50 sm:pb-40 md:pb-40 min-h-screen-80">
              {hasNewMessages && !isAtBottom && hasScrollbar && (
              <div className="absolute pin-b pin-l w-full mb-64 text-center z-10">
                <button className="bg-psworld-grey opacity-85 rounded px-4 py-1 font-americaMonoRegular shadow-default hover:bg-white hover:opacity-1 trans text-xs uppercase" type="button" onClick={scrollToLastMessage}>Messages below</button>
              </div>
              )}
              {!isLoadingMessages && <View privateChat channel={activeChannel} messages={channelMessages} />}
            </div>
            <div>
              {showVideoRoomButton
                ? <button className="sm:hidden md:hidden w-chat text-white uppercase font-americaMonoRegular bg-psworld-box p-4 absolute pin-b pin-r mb-42 sm:mb-28" type="button" onClick={handlePlusZoneMessage}>Create room</button>
                : null
              }
              <MessageInput updateChat={updateChat} privateChat isQuestions={false} notLogged={false} channel={activeChannel} />
            </div>
          </>
        )}

    </>
  );
};

PrivateChannel.propTypes = {
  activeChannel: PropTypes.string.isRequired,
  setActiveChannel: PropTypes.func.isRequired,
};

export default PrivateChannel;
