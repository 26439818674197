import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';

const LineupTableSimpleItem = ({
  name, datetimeStartReal, venueName, match,
}) => (
  <RoutesContext.Consumer>
    {({ language: contextLanguage, enteredFrom }) => {
      const localization = getLocalization(contextLanguage, enteredFrom, match);
      // moment.locale(localization.language);
      return (
        <Fragment>
          <tr className="hidden sm:block sm:py-4 sm:w-5/6 sm:border-b sm:border-black" style={{ margin: '0 auto' }}>
            <td>
              <p>{name}</p>
              <p>{moment(datetimeStartReal).format('LT')}</p>
              <p>{!venueName[localization.language] ? venueName.en : venueName[localization.language]}</p>
            </td>
          </tr>
          <tr className="sm:hidden border-b  border-black">
            <td className="sm:py-5 py-4">
              <h3 className="font-tiemposBold text-16 sm:text-14 pl-8 sm:hidden">{name}</h3>
            </td>
            <td>
              <p className="font-tiemposRegular text-16 sm:hidden">{moment(datetimeStartReal).format('LT')}</p>
            </td>
            <td>
              <p className="font-tiemposRegular text-16 sm:hidden">{!venueName[localization.language] ? venueName.en : venueName[localization.language]}</p>
            </td>
          </tr>
        </Fragment>
      );
    }}
  </RoutesContext.Consumer>
);

LineupTableSimpleItem.propTypes = {
  name: PropTypes.string.isRequired,
  datetimeStartReal: PropTypes.string.isRequired,
  venueName: PropTypes.shape({}).isRequired,
  match: PropTypes.shape().isRequired,
};

export default withRouter(LineupTableSimpleItem);
