import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useBraze } from '../../context/useBraze';

const BrazeTrigger = ({ brazeEvent, eventProperty }) => {
  const { braze, logCustomEvent } = useBraze();

  useEffect(() => {
    if (!braze || !brazeEvent) return;
    logCustomEvent(brazeEvent, eventProperty);
    braze.subscribeToInAppMessage(inAppMessage => braze.showInAppMessage(inAppMessage));
    braze.openSession();
  }, [braze]);
  return null;
};

BrazeTrigger.propTypes = {
  brazeEvent: PropTypes.string,
  eventTypeArrayItem: PropTypes.string,
};

BrazeTrigger.defaultProps = {
  brazeEvent: null,
  eventTypeArrayItem: null,
};

export default BrazeTrigger;
