import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SocialOnDemandButton from './SocialOnDemandButton';
import translateObj from '../../../../../util/translateObj';
import { buttonClasses } from '../../OnDemandUtils/onDemandUtils';

const SOCIAL_LOGIN = {
  es: 'Accede o registrate con tus redes sociales',
  en: 'Access or register with your social network',
  ca: "Accedeix o registra't amb la teva xarxa social",
  pt: 'Aceda ou inscreva-se com sua rede social',
};

const SectionSocialOnDemand = ({
  language, loadTokenForLS, handleOnDemandScreenStatus, handleModalNotLoggedStatus, handleRegisteringStatus, translations: { notLoggedModal: translations = {} }, sourceWeb, QRcodeCallback,
}) => {
  const [error, setError] = useState('');
  return (
    <>
      <div className="pt-8">
        <button
          type="button"
          className={buttonClasses({ backgroundColor: 'black' })}
          onClick={() => handleModalNotLoggedStatus('password')}
        >
          {translateObj(translations.passwordButtonTitle, language) || 'ACCESS AND REGISTER'}
        </button>
      </div>
      <div className="pb-8 sm:pb-2" />
      <div className="border-t border-psworld-grey" />
      <div className="pb-8 sm:pb-5" />
      <div className="pb-8">
        {translateObj(SOCIAL_LOGIN, language)}
      </div>

      <div className="flex flex-row pb-4 xl:pb-0 lg:pb-0 flex-wrap  md:flex-col">
        <div className="flex flex-row w-full tablet:px-16 justify-between">
          <div className="sm:mx-2">
            <SocialOnDemandButton type="google" language={language} loadTokenForLS={loadTokenForLS} handleOnDemandScreenStatus={handleOnDemandScreenStatus} handleRegisteringStatus={handleRegisteringStatus} setError={setError} />
          </div>
          <div className="sm:x-2">
            <SocialOnDemandButton type="facebook" language={language} loadTokenForLS={loadTokenForLS} handleOnDemandScreenStatus={handleOnDemandScreenStatus} handleRegisteringStatus={handleRegisteringStatus} setError={setError} />
          </div>
          <div className="sm:x-2">
            <SocialOnDemandButton type="spotify" language={language} loadTokenForLS={loadTokenForLS} handleOnDemandScreenStatus={handleOnDemandScreenStatus} handleRegisteringStatus={handleRegisteringStatus} setError={setError} sourceWeb={sourceWeb} />
          </div>
        </div>
        {(error)
            && <div className="font-americaMonoRegular text-12 text-red mt-4">{translateObj(translations[error], language)}</div>}
      </div>
    </>
  );
};

SectionSocialOnDemand.propTypes = {
  language: PropTypes.string,
  loadTokenForLS: PropTypes.func.isRequired,
  handleOnDemandScreenStatus: PropTypes.func.isRequired,
  handleModalNotLoggedStatus: PropTypes.func.isRequired,
  handleRegisteringStatus: PropTypes.func.isRequired,
  sourceWeb: PropTypes.string,
  QRcodeCallback: PropTypes.func,
};

SectionSocialOnDemand.defaultTypes = {
  language: 'en',
  sourceWeb: '',
  QRcodeCallback: null,
};

export default SectionSocialOnDemand;
