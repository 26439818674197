import React from 'react';
import { PropTypes } from 'prop-types';

import { withRouter } from 'react-router-dom';
import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';
import ComponentIterator from '../../containers/ComponentIterator';
import imageModifier from './helpers/imageModifier';
import RadioHeader1 from './RadioHeader1';
import RadioItemType from './RadioItemType';

class RadioBox4 extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      open: props.header.open || false
    }
  }

  updateOpen(open) {
    this.setState({ open: open })
  }

  render() {

    let {
      match,
      title,
      text1,
      radioItemType,
      tags,
      tags2,
      date,
      imageUrl,
      header,
      url,
    } = this.props

    let {open} = this.state

    return (
      <RoutesContext.Consumer>
      {
        ({ language: contextLanguage, enteredFrom }) => {
          
          const localization = getLocalization(contextLanguage, enteredFrom, match);
          
          return (
            <div className="w-full bg-black rounded-lg">
              <RadioHeader1 {...header} toggleCallback={this.updateOpen.bind(this)} />
              <div className={`relative text-white ${open ? 'sm:block' : 'sm:hidden'}`}>
                <div className="relative launcherGradientContainer">
                  <img src={imageModifier(imageUrl, '330x330')} alt={title[localization.language]} />
                  <div className="absolute pin-t pin-x mt-4 ml-4 z-10">
                    <ComponentIterator components={tags} />
                  </div>
                </div>
                <div className="p-4 pb-6">
                  <span className="font-americaMonoRegular text-12">{date[localization.language]}</span>
                  <h2 className="font-americaBlack text-xl mt-1 mb-2">
                    <a href={url[localization.language]} className="text-white">{title[localization.language]}</a>
                  </h2>
                  <div className="mb-8">
                    <ComponentIterator components={tags2} />
                  </div>
                  <span className="font-tiemposRegular mb-8 block text-sm">{text1[localization.language]}</span>
                  {radioItemType ?
                  <div className="flex justify-between items-center">
                    <RadioItemType {...radioItemType} />
                  </div> : null}
                </div>
              </div>            
            </div>
          )
        }
      }
      </RoutesContext.Consumer>
    )
  }
}

RadioBox4.propTypes = {
  title: PropTypes.shape().isRequired,
  text1: PropTypes.shape().isRequired,
  tags: PropTypes.array.isRequired,
  tags2: PropTypes.array.isRequired,
  date: PropTypes.shape().isRequired,
  header: PropTypes.shape().isRequired,
  imageUrl: PropTypes.string.isRequired,
  url: PropTypes.shape().isRequired,
};

export default withRouter(RadioBox4);