import React from 'react';
import PropTypes from 'prop-types';
import { useRoutes } from '../../../context/routesContext';

import { LinkWrapper } from '../../../util/link';
import LoadIcon from '../LoadIcon';

const getColor = (color) => {
  switch (color) {
    case 'outlineWhite':
      return 'bg-transparent border-white text-white';
    case 'outlineOrange':
      return 'bg-transparent border-pro-online text-pro-online';
    case 'black':
      return 'bg-black border-black text-white';
    case 'orange':
      return 'bg-pro-online border-pro-online text-white';
    case 'disabledWhite':
      return 'bg-grayE2DDDB border-grayE2DDDB text-white';
    case 'disabledOrange':
      return 'bg-grayE2DDDB border-grayE2DDDB text-pro-online';
    case 'outlineBlack':
    default: return 'bg-transparent border-black text-black';
  }
};

const getDefaultVerticalPaddings = (headerButton, arrowRight, plusIcon) => {
  if (headerButton || arrowRight) return 'py-4 sm:py-3';
  if (plusIcon) return 'sm:py-0';
  return 'py-2';
};

const getDefaultHorizontalPaddings = (headerButton, plusIcon) => {
  if (headerButton) return 'px-5';
  if (plusIcon) return 'px-4';
  return 'px-4';
};

const Button = ({
  text,
  link,
  href,
  onClickCallback,
  color,
  icon,
  arrowRight,
  headerButton,
  type,
  className,
  verticalPaddings,
  horizontalPaddings,
  plusIcon,
  disabled,
  parentPath,
  target,
  loading,
}) => {
  const { path } = useRoutes();
  const buttonColor = getColor(color);
  const defaultVerticalPaddings = getDefaultVerticalPaddings(headerButton, arrowRight, plusIcon);
  const defaultHorizontalPaddings = getDefaultHorizontalPaddings(headerButton, plusIcon);

  const buttonContent = (
    <div className={`text-center rounded-full h-max w-full font-americaMonoBold tracking-px uppercase border flex items-center
      ${buttonColor}
      ${headerButton ? 'sm:text-14 text-base' : 'sm:text-12 text-14'}
      ${icon || arrowRight ? 'justify-between' : 'justify-center'}
      ${verticalPaddings || defaultVerticalPaddings}
      ${horizontalPaddings || defaultHorizontalPaddings}
      ${disabled ? 'opacity-25' : ''}
      `}
    >
      {loading ? (
        <div className="flex items-center justify-center">
          <LoadIcon height={13} width={13} />
        </div>
      ) : (
        <>
          {icon && <div className={`${icon} mr-4 sm:mr-2`} />}
          {plusIcon && <div className="text-30 mr-2">+</div>}
          {text}
          {arrowRight && <div className="ml-4 sm:ml-2">{'>'}</div>}
        </>
      )}
    </div>
  );

  if (href) {
    return (
      <div className={`${disabled ? 'pointer-events-none' : ''} ${className || 'w-auto h-auto'}`}>
        <a href={href} target={target || ''}>
          {buttonContent}
        </a>
      </div>
    );
  }

  if (link) {
    return (
      <div className={`${disabled ? 'pointer-events-none' : ''} ${className || 'w-auto h-auto'}`}>
        <LinkWrapper url={link} path={parentPath || path}>
          {buttonContent}
        </LinkWrapper>
      </div>
    );
  }

  if (type === 'submit') {
    return (
      <button type="submit" id="musicMeetsButton" className={`${disabled ? 'pointer-events-none' : ''} ${className || 'w-auto h-auto'}`}>
        {buttonContent}
      </button>
    );
  }

  return (
    <button type="button" onClick={onClickCallback} className={`${disabled ? 'pointer-events-none' : ''} ${className || 'w-auto h-auto'}`}>
      {buttonContent}
    </button>
  );
};

Button.propTypes = {
  text: PropTypes.string,
  link: PropTypes.string,
  href: PropTypes.string,
  onClickCallback: PropTypes.func,
  color: PropTypes.string,
  icon: PropTypes.string,
  arrowRight: PropTypes.bool,
  headerButton: PropTypes.bool,
  type: PropTypes.string,
  className: PropTypes.string,
  verticalPaddings: PropTypes.string,
  horizontalPaddings: PropTypes.string,
  plusIcon: PropTypes.bool,
  disabled: PropTypes.bool,
  parentPath: PropTypes.string,
  target: PropTypes.string,
  loading: PropTypes.bool,
};

Button.defaultProps = {
  text: '',
  link: null,
  href: null,
  onClickCallback: null,
  color: 'outlineBlack',
  icon: null,
  arrowRight: false,
  headerButton: false,
  type: null,
  className: '',
  verticalPaddings: '',
  horizontalPaddings: '',
  plusIcon: false,
  disabled: false,
  parentPath: null,
  target: null,
  loading: false,
};

export default Button;
