/* eslint-disable quotes */
/* eslint-disable curly */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';

import { useRoutes } from '../../context/routesContext';
import { useUser } from '../../context/userProfileContext';
import { useBraze } from '../../context/useBraze';

import assignTicketCode from '../../api/graphql/mutations/assignTicketCode';
import getTicketCode from '../../api/graphql/queries/getTicketCode';

import Link from '../../util/link';
import FormSelect from './MusicMeets/FormSelect';
import Button from './MusicMeets/Button';
import useQueryString from '../../hooks/useQueryString';
import translateObj from '../../util/translateObj';
import getTranslation from './MusicMeets/util/translations/_getTranslation';

const DEFAULT_TRANSLATIONS = {
  submitButton: {
    en: 'Get your code',
    es: 'Consigue tu código',
    ca: 'Aconsegueix el teu codi',
  },
  placeholderTicketSelect: {
    en: 'Select your accreditation',
    es: 'Selecciona tu acreditación',
    ca: 'Selecciona la teva acreditació',
  },
  ticketAlreadyExists: {
    en: 'Buy on dice',
    es: 'Compra en dice',
    ca: 'Compra a dice',
  },
  formIsMissing: {
    en: 'To continue with the purchase process, you need to fill the form first.',
    es: 'Para continuar con el proceso de compra, primero debes rellenar el formulario.',
    ca: `Per a continuar amb el procés de compra, primer has d'omplir el formulari.`,
  },
  userHasCodeAlready: {
    en: 'This user has already a code, here is your link:',
    es: 'Este usuario ya tiene un código, aqui tienes tu link:',
    ca: 'Aquest usuari ja té un codi de compra, aquí tens el teu link:',
  },
  hereIsYourCode: {
    en: 'Here you have the link to buy your accreditation from DICE:',
    es: 'Aquí tienes el link para comprar a través de DICE:',
    ca: 'Aquí tens el link per comprar a través de DICE:',
  },
  codeEmailSent: {
    en: 'Your code has been sent to your email address too.:',
    es: 'El código también ha sido enviado a tu email.',
    ca: `El codi també s'ha enviat al teu email.`,
  },
};

const USER_HAS_CODE = 'USER_HAS_ALREADY_A_CODE_FOR_THIS_TICKET';

const TicketCodeSelector = ({ reedemLink, options, translation, redirectLinktIfProfileMissing, brazeAttribute }) => {
  const [codeAvailable, setCodeAvailable] = useState();
  const [submited, setSubmited] = useState(false);
  const [hasCodeAlready, setHasCodeAlready] = useState(false);
  const { language, path } = useRoutes();
  const { acceptedConditionsMusicMeets, userEmail } = useUser();
  const { logCustomAttributes } = useBraze();

  const literals = getTranslation(translation, DEFAULT_TRANSLATIONS);
  const scopes = options.map(option => option.value);
  const ticketCodeParam = useQueryString('code');

  const url = `${reedemLink}${ticketCodeParam || codeAvailable}`;

  const handleSubmit = ({ ticket }) => {
    setSubmited(true);
    assignTicketCode(ticket).then((res) => {
      const { message, code } = res || {};
      if (brazeAttribute?.key && message === USER_HAS_CODE) setHasCodeAlready(true);
      else logCustomAttributes(brazeAttribute.key, brazeAttribute.value || ticket);
      setCodeAvailable(code);
    }).catch(err => console.error(err));
  };

  useEffect(() => {
    if (acceptedConditionsMusicMeets && userEmail && !ticketCodeParam) {
      getTicketCode(scopes).then((res) => {
        // NOTE: We always use the first value, as it can only be one ticket per user
        if (res.length > 0) setCodeAvailable(res[0]?.code);
      }).catch(err => console.error(err));
    }
  }, [acceptedConditionsMusicMeets, userEmail, ticketCodeParam, scopes]);

  if (!userEmail || !acceptedConditionsMusicMeets) return (
    <div className="mb-32 leading-normal font-tiemposRegular false">
      <Link to={redirectLinktIfProfileMissing} className="w-full" localizationpath={path}>{translateObj(literals.formIsMissing, language)}</Link>
    </div>
  );

  if (ticketCodeParam || codeAvailable) return (
    <div className="mb-32 leading-normal font-tiemposRegular false">
      <p className="mb-4">{translateObj(hasCodeAlready ? literals.userHasCodeAlready : literals.hereIsYourCode, language)}</p>
      <Button href={url} target="_blank" color="black" text={translateObj(literals.ticketAlreadyExists, language)} />
      <p className="mt-4">{translateObj(literals.codeEmailSent, language)}</p>
    </div>
  );

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{ ticket: '' }}
        onSubmit={(values, props) => handleSubmit(values, props)}
      >
        {() => (
          <Form noValidate className="flex flex-col mb-24 sm:mb-12">
            <FormSelect name="ticket" label={literals.labelLanguage} placeholder={literals.placeholderTicketSelect} options={options} />
            <Button disabled={submited} type="submit" text={translateObj(literals.submitButton, language)} color={`${submited ? 'disabledWhite' : 'black'}`} className="mt-4 self-end" />
          </Form>
        )}
      </Formik>
    </div>
  );
};

TicketCodeSelector.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape()),
  reedemLink: PropTypes.string,
  redirectLinktIfProfileMissing: PropTypes.string,
  translation: PropTypes.shape(),
  brazeAttribute: PropTypes.shape(),
};

TicketCodeSelector.defaultProps = {
  options: [],
  reedemLink: null,
  redirectLinktIfProfileMissing: null,
  translation: {},
  brazeAttribute: null,
};

export default TicketCodeSelector;
