import React from 'react';
import PropTypes from 'prop-types';
import HourLabel from './HourLabel';

const HoursColumn = ({
  day,
  fixedColumnRef,
  startAndEndHours,
  layout,
}) => (
  <div ref={fixedColumnRef} className="border-r-1 border-b-1 border-solid border-white bg-gray200 z-10">
    <div className="relative">
      <div>
        {startAndEndHours && (
          Object.keys(startAndEndHours[day].hours).map((hour, index) =>
          // TODO: at the moment the artist data seems to be invalid, creating repeated hours for days (is realDate not working?) added index as key a temp solution
          // eslint-disable-next-line react/no-array-index-key, implicit-arrow-linebreak
            <HourLabel hasEvent={startAndEndHours[day].hours[hour]} index={index} key={hour + index} hour={hour} layout={layout} />))}
      </div>
    </div>
  </div>
);


HoursColumn.propTypes = {
  day: PropTypes.string.isRequired,
  layout: PropTypes.string.isRequired,
  startAndEndHours: PropTypes.shape().isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  fixedColumnRef: PropTypes.object.isRequired,
};
export default HoursColumn;
