import React from 'react';
import PropTypes from 'prop-types';
import ComponentIterator from '../../../containers/ComponentIterator';

const style = (parent) => {
  if (parent === 'radio') {
    return 'sm:w-full w-1/2 col-count-2 md:col-count-2';
  }
  return 'w-full col-count-4';
};

const TopHeaderMegamenuListsContainer = ({ mainParent, components }) => (
  <nav className={`${style(mainParent)} sm:col-count-auto`}>
    <ComponentIterator components={components} />
  </nav>
);

TopHeaderMegamenuListsContainer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  components: PropTypes.array.isRequired,
  // eslint-disable-next-line react/require-default-props
  mainParent: PropTypes.string,
};

export default TopHeaderMegamenuListsContainer;
