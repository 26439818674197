import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';

import { useRoutes } from '../../../context/routesContext';
import translateObj from '../../../util/translateObj';
import FacebookIcon from './svg/FacebookIcon';
import TwitterIcon from './svg/TwitterIcon';
import InstagramIcon from './svg/InstagramIcon';
import LinkedinIcon from './svg/LinkedinIcon';

const getIcon = (icon) => {
  switch (icon) {
    case 'facebook':
      return <FacebookIcon />;
    case 'twitter':
      return <TwitterIcon />;
    case 'instagram':
      return <InstagramIcon />;
    case 'linkedin':
      return <LinkedinIcon />;
    default:
      return null;
  }
};

const getError = (errors, name) => {
  if (Object.prototype.hasOwnProperty.call(errors, name)) {
    return errors[name];
  }
  return undefined;
};

const FormField = ({
  name,
  type,
  label,
  placeholder,
  required,
  icon,
  className,
  labelClassName,
  textareaHeight,
  description,
  errors,
}) => {
  const { language } = useRoutes();
  const [error, setError] = useState(undefined);

  useEffect(() => {
    const fieldError = getError(errors, name);
    setError(fieldError);
  }, [errors]);

  return (
    <div className={`${className} pt-4`}>
      {label && (
      <div className={`text-12 font-americaMonoRegular mb-4 uppercase ${labelClassName} ${error ? 'text-pro-online' : 'text-black'}`}>
        {`${translateObj(label, language)}${required ? '*' : ''}`}
      </div>
      )}
      <div className={`w-full border ${error ? 'border-pro-online' : 'border-black'}
      ${type === 'textarea' ? `${textareaHeight} rounded-lg` : 'rounded-full'}
      ${icon ? 'flex items-center justify-between' : ''}`}
      >
        {icon && (
          <div className="ml-4 w-5 h-5">
            <div className="w-full h-full flex items-center justify-center">
              {getIcon(icon)}
            </div>
          </div>
        )}
        <Field
          name={name}
          type={type}
          as={type === 'textarea' ? type : null}
          required={required}
          placeholder={placeholder}
          className={`w-full p-4 font-americaMonoRegular outline-none bg-transparent text-12
          ${type === 'textarea' ? 'h-full formik-textarea' : ''}
          ${icon ? 'formik-social-pro' : ''} ${error ? 'text-pro-online' : 'text-black'}`}
        />
      </div>
      {description && <div className={`mt-4 text-12 font-tiemposRegular ${error ? 'text-pro-online' : 'text-black'}`}>{translateObj(description, language)}</div>}
    </div>
  );
};

FormField.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.shape(),
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  icon: PropTypes.string,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  textareaHeight: PropTypes.string,
  description: PropTypes.shape(),
  errors: PropTypes.shape(),
};

FormField.defaultProps = {
  name: '',
  type: 'text',
  label: null,
  placeholder: null,
  required: false,
  icon: null,
  className: 'w-full pt-4',
  labelClassName: 'w-full',
  textareaHeight: 'h-220px sm:h-100px',
  description: null,
  errors: {},
};

export default FormField;
