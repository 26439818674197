import translation from './__translationHelper';
import header from './_header';
import footer from './_footer';

const data = [
  { ...header },
  // slider (pending)
  // news (pending)
  { ...footer },
];

export default data;
