import React from 'react';
import { PropTypes } from 'prop-types';

const TermsCheckbox = ({ id, label, checked, onClick, error }) => (
  <div className="flex items-start relative cursor-pointer mb-3" onClick={onClick}>
    <button
      type="button"
      id={id}
      className={`w-4.5 h-4.5 ${error ? 'bg-red-lighter' : 'bg-grayF4F4F4'} rounded-2 p-1 flex items-center mt-1 justify-center mr-2`}
    >
      {checked && <div className="font-icon icon-checkbox text-black text-sm" />}
    </button>
    {label && (
    <div>
      {typeof label === 'string' ? (
        <label htmlFor={id} className={`cursor-pointer font-americaRegular text-xs sm:text-sm ${error ? 'text-red' : 'text-black'}`}>{label}</label>
      ) : (
        label
      )}
    </div>
    )}
  </div>
);

TermsCheckbox.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string || PropTypes.node,
  checked: PropTypes.bool,
  onClick: PropTypes.func,
  error: PropTypes.bool,
};

TermsCheckbox.defaultProps = {
  label: undefined,
  checked: false,
  onClick: null,
  error: false,
};

export default TermsCheckbox;
