

/* eslint-disable camelcase */
import React, {
  useEffect, useState, useRef,
} from 'react';
import { PropTypes } from 'prop-types';
import FilePlayer from 'react-player';
import StreamingVideoPlayer from '../MultiVideo/StreamingVideoPlayer';
import Chat from '../Chat/Chat';
import withRadioPlayerContext from '../../../context/withRadioPlayerContext';
import PSResponsiveUtility from '../LineUp/PSResponsiveUtility';

const StreamModule = ({
  chatChannelID,
  qaChannelID,
  hideRadio,
  hidePlayer,
  showPlayer,
  showRadioPlayer,
  setPlayerPosition,
  source,
  smalHlsVideoUrl,
  coverImageUrl,
  streamObjects,
}) => {
  const [loading, setLoading] = useState(false);
  const responsiveUtility = useRef();

  // useEffect(() => {
  //   if (responsiveUtility && responsiveUtility.current) {
  //     const deviceScreen = responsiveUtility.current.deviceScreen();
  //     const url = ['sm', 'md'].indexOf(deviceScreen) === -1
  //       ? desktopUrlState || desktopUrl
  //       : mobileUrlState || mobileUrl;
  //     setUrlState(url);
  //     setLoading(false);
  //   }
  // }, [responsiveUtility.current]);

  // Make sure radio player isnt shown on this page
  useEffect(() => {
    setPlayerPosition('pin-b -mb-16');
    hidePlayer();
    hideRadio(true);
    return () => { hideRadio(false); showPlayer(); };
  }, []);

  return (
    <>
      <PSResponsiveUtility ref={responsiveUtility} />
      <div className="w-full full-width">
        {!loading && (
          <div className="flex w-full relative sm:flex-wrap md:flex-wrap items-stretch">
            <div className="w-full relative bg-black">
              {streamObjects.length > 0
              && <StreamingVideoPlayer source={source} videos={streamObjects} smalHlsVideoUrl={smalHlsVideoUrl} coverImageUrl={coverImageUrl} />
              }
            </div>
            <Chat {...{
              chatChannelID, qaChannelID,
            }}
            />
          </div>
        )}
      </div>

    </>
  );
};
StreamModule.propTypes = {
  chatChannelID: PropTypes.string,
  qaChannelID: PropTypes.string,
  hasActiveChat: PropTypes.bool,
  hasActiveQa: PropTypes.bool,
  streamObjects: PropTypes.arrayOf(PropTypes.shape({})),
  source: PropTypes.string,
  smalHlsVideoUrl: PropTypes.string,
  coverImageUrl: PropTypes.string,
};
StreamModule.defaultProps = {
  chatChannelID: null,
  qaChannelID: null,
  hasActiveChat: false,
  hasActiveQa: false,
  streamObjects: [],
  source: 'dash',
  smalHlsVideoUrl: '',
  coverImageUrl: '',
};
export default withRadioPlayerContext(StreamModule);
