import React, { Component } from 'react';
import moment from 'moment';
import axios from 'axios';


class LauncherTwitter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      date: '',
      user: '',
      image: '',
      text: '',
      url: '',
    };
  }

  componentDidMount() {
    this.fetchTwitter();
  }

  fetchTwitter() {
    axios.get('https://assets.primaverasound.com/app/psb/prod/8.7/twitter.json')
      .then((res) => {
        let image = '';
        if (res.data[0].retweeted_status && res.data[0].retweeted_status.media) {
          image = res.data[0].retweeted_status.extended_entities.media[0].media_url_https;
        }
        if (res.data[0].extended_entities && res.data[0].extended_entities.media) {
          image = res.data[0].extended_entities.media[0].media_url_https;
        }
        this.setState({
          isLoading: false,
          date: res.data[0].created_at,
          user: res.data[0].user.screen_name,
          text: res.data[0].text,
          image,
          url: res.data[0].id_str,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    const { isLoading } = this.state;
    let postDate = this.state.date;
    postDate = moment(postDate, 'ddd MMM D HH:mm:ss ZZ YYYY').format('D/MM/YYYY').replace(/\//g, '-');
    let postText = this.state.text.substring(0, 100);
    postText = postText.substr(0, Math.min(postText.length, postText.lastIndexOf(' ')));
    const url = 'https://twitter.com/user/status/'.concat(this.state.url);
    if (isLoading) {
      return (<div />);
    }
    return (
      <div className={`self-center h-full items-center flex border-t-1 border-black ${!this.state.image && 'px-8'}`}>
        <a href={url} target="_blank" className="block h-full">
          <div className={`text-black flex flex-col h-full justify-between ${!this.state.image && 'h-full justify-around'}`}>
            <div className={`inline-flex justify-between items-center ${!this.state.image && 'flex-col items-baseline justify-center'}`}>
              <span className="font-icon text-black icon-twitter-simple text-4xl md:text-3xl sm:text-3xl" />
              <p className={`text-18 font-americaBlack sm:text-18 md:text-14 mt-0 ${!this.state.image && 'order-3'}`}>
                    @
                {this.state.user}
              </p>
              <p className={`font-americaMonoRegular text-14 hidden xl:block ${this.state.image && 'md:block'}`}>{postDate}</p>
            </div>
            {this.state.image && <img src={this.state.image} alt="tweet" className="hidden w-auto tweet xl:block" />}
            <h2 className={`text-20 pin-b pin-l px-0 mb-6 font-americaBlack lg:text-18 sm:text-18 md:text-14 md:pt-4 text-18 ${this.state.image && 'text-14'}`}>
              {postText}
...
            </h2>
          </div>
        </a>
      </div>
    );
  }
}

export default LauncherTwitter;
