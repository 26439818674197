const translation = {
  buttonInvite: {
    en: 'Invite to meeting',
    es: 'Invitar a reunión',
    ca: 'Convidar a reunió',
    pt: 'Convidar para reunião',
  },
  buttonChat: {
    en: 'Contact',
    es: 'Contactar',
    ca: 'Contactar',
    pt: 'Contato',
  },
};

export default translation;
