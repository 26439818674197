import translation from './__translationHelper';
import header from './_header';
import footer from './_footer';

const participantsArray = [
  {
    _id: '6177d42c7c0cec000901a771',
    name: 'Cinta',
    lastname: 'Mesquita',
    occupation: translation('Singer'),
    company: 'Vasava Records',
    slugName: 'cinta-mesquita', // to member detail page
    status: 'confirmed', // pending | confirmed
  },
  {
    _id: '6177d42c7c0cec000901a772',
    name: 'Edgar',
    lastname: 'Hosta',
    occupation: translation('Artist'),
    company: 'Apple Music',
    slugName: 'edgar-hosta', // to member detail page
    status: 'pending', // pending | confirmed
  },
  {
    _id: '6177d42c7c0cec000901a773',
    name: 'Dani',
    lastname: 'Sellés',
    occupation: translation('Producer'),
    company: 'Red Bull Music Academy',
    slugName: 'dani-selles', // to member detail page
    status: 'pending', // pending | confirmed
  },
  {
    _id: '6177d42c7c0cec000901a774',
    name: 'Enric',
    lastname: 'Castrillón',
    occupation: translation('Manager'),
    company: 'Colombian Records',
    slugName: 'enric-castrillon', // to member detail page
    status: 'confirmed', // pending | confirmed
  },
  {
    _id: '6177d42c7c0cec000901a775',
    name: 'Rita',
    lastname: 'Capella',
    occupation: translation('DJ'),
    company: 'Oporto Dancing session',
    slugName: 'rita-capella', // to member detail page
    status: 'pending', // pending | confirmed
  },
];

const data = [
  { ...header },
  {
    type: 'ItemsList',
    components: [
      {
        type: 'ReunionItem',
        key: 'reunion-1',
        id: '9876543210',
        title: translation('El Maresme Metal Sound 2021'),
        date: '1643376417',
        location: null, // if reunion is not online
        description: translation('Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam sed lorem dui. Duis in ipsum est. Proin ac ligula lorem. Duis quis luctus odio, semper congue orci. Suspendisse in posuere diam.'),
        url: '/', // url to online reunion
        online: true,
        status: 'confirmed', // invited | pending | confirmed
        createdBy: {
          _id: '6177d42c7c0cec000901a770',
          name: 'Bruno',
          lastname: 'Mesquita',
          occupation: translation('Artist'),
          company: 'Sony Music',
          slugName: 'bruno-capella', // to member detail page
        },
        participants: participantsArray,
        isListItem: true, // set to "true" when item is inside "ItemsList" component
      },
      {
        type: 'ReunionItem',
        key: 'reunion-2',
        id: '8876543210',
        title: translation('Smashing Pumpkings revival versiones 2021'),
        date: '1643376417',
        location: 'MACBA', // if reunion is not online
        description: translation('Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam sed lorem dui. Duis in ipsum est. Proin ac ligula lorem. Duis quis luctus odio, semper congue orci. Suspendisse in posuere diam.'),
        url: null, // url to online reunion
        online: false,
        status: 'pending', // invited | pending | confirmed
        createdBy: {
          _id: '6177d42c7c0cec000901a770',
          name: 'Bruno',
          lastname: 'Mesquita',
          occupation: translation('Artist'),
          company: 'Sony Music',
          slugName: 'bruno-capella', // to member detail page
        },
        participants: participantsArray,
        isListItem: true, // set to "true" when item is inside "ItemsList" component
      },
      {
        type: 'ReunionItem',
        key: 'reunion-3',
        id: '7876543210',
        title: translation('El Maresme Metal Sound 2021'),
        date: '1643376417',
        location: null, // if reunion is not online
        description: translation('Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam sed lorem dui. Duis in ipsum est. Proin ac ligula lorem. Duis quis luctus odio, semper congue orci. Suspendisse in posuere diam.'),
        url: '/', // url to online reunion
        online: true,
        status: 'invited', // invited | pending | confirmed
        createdBy: {
          _id: '6177d42c7c0cec000901a770',
          name: 'Bruno',
          lastname: 'Mesquita',
          occupation: translation('Artist'),
          company: 'Sony Music',
          slugName: 'bruno-capella', // to member detail page
        },
        participants: participantsArray,
        isListItem: true, // set to "true" when item is inside "ItemsList" component
      },
      {
        type: 'ReunionItem',
        key: 'reunion-4',
        id: '6876543210',
        title: translation('Smashing Pumpkings revival versiones 2021'),
        date: '1643376417',
        location: 'Forum Barcelona', // if reunion is not online
        description: translation('Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam sed lorem dui. Duis in ipsum est. Proin ac ligula lorem. Duis quis luctus odio, semper congue orci. Suspendisse in posuere diam.'),
        url: null, // url to online reunion
        online: false,
        status: 'invited', // invited | pending | confirmed
        createdBy: {
          _id: '6177d42c7c0cec000901a770',
          name: 'Bruno',
          lastname: 'Mesquita',
          occupation: translation('Artist'),
          company: 'Sony Music',
          slugName: 'bruno-capella', // to member detail page
        },
        participants: participantsArray,
        isListItem: true, // set to "true" when item is inside "ItemsList" component
      },
    ],
  },
  { ...footer },
];

export default data;
