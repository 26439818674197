import React from 'react';
import { PropTypes } from 'prop-types';

import Link from '../../../../util/link';
import imageModifier from '../../helpers/imageModifier';
import translateObj from '../../../../util/translateObj';
import { useRoutes } from '../../../../context/routesContext';
import { DEFAULT_PROPS_ITEMS_PER_ROW } from '../../GroupedItems';

const IMAGE_SIZE = '705x797';
const IMAGE_HIGHLIGHT_SIZE = '1140x1080';

const buyButton = {
  en: 'Buy',
  es: 'Comprar',
  ca: 'Comprar',
  pt: 'Comprar',
};

const seeAllButton = {
  en: 'See all',
  es: 'Ver todos',
  ca: 'Veure tots',
  pt: 'Ver todos',
};

export const Button = ({ language, translations, color }) => (
  <span className="py-1 px-10 rounded-full border-black border-2 text-base sm:text-12 md:text-12 text-center color-black font-americaMonoBold uppercase" style={{ borderColor: color, color }}>
    {translations[language]}
  </span>
);

Button.propTypes = {
  language: PropTypes.string,
  translations: PropTypes.shape().isRequired,
  color: PropTypes.string,
};

Button.defaultProps = {
  language: 'en',
  color: 'transparent',
};

const getVenuesArray = (subtitle, language) => translateObj(subtitle, language).split('\n');

const ItemHighlight = ({ image, subtitle, text, title, url, color }) => {
  const { language, path } = useRoutes();

  const venues = getVenuesArray(subtitle, language);

  return (
    <div className="launcherItem p-8px">
      <div className="mb-14 sm:mb-6 md:mb-10 desktop:pr-20 flex flex-col md:flex-row launcherItemImg">
        <div className="md:mr-4">
          <img className="mb-5" src={imageModifier(translateObj(image, language), IMAGE_HIGHLIGHT_SIZE)} alt={translateObj(title, language)} />
        </div>
        <div>
          <h2 className="font-americaRegular font-bold text-3xl sm:text-xl tracking-normal uppercase" style={{ color }}>{translateObj(title, language)}</h2>
          <h3 className="font-americaRegular font-medium text-3xl sm:text-sm md:text-2xl leading-none tracking-normal" style={{ color }}>{translateObj(text, language)}</h3>
          <p className="mt-4 mb-5 flex flex-col font-americaRegular text-xxs uppercase" style={{ color }}>{venues.map(venue => <span key={venue}>{venue}</span>)}</p>
          <Link to={url} localizationpath={path} aria-label={`${buyButton[language]}: ${title[language]} - ${venues.map(v => v)}`} role="button">
            <Button language={language} translations={buyButton} color={color} />
          </Link>
        </div>
      </div>
    </div>
  );
};

ItemHighlight.propTypes = {
  image: PropTypes.shape({
    en: PropTypes.string,
  }).isRequired,
  subtitle: PropTypes.shape().isRequired,
  title: PropTypes.shape().isRequired,
  text: PropTypes.shape(),
  url: PropTypes.string,
  color: PropTypes.string,
};

ItemHighlight.defaultProps = {
  url: null,
  text: null,
  color: 'transparent',
};

const Item = ({ image, text, title, subtitle, url, itemsPerRowPerMediaQuery, color }) => {
  const { language, path } = useRoutes();

  const venues = getVenuesArray(subtitle, language);

  const { mobile } = itemsPerRowPerMediaQuery;

  const sizeItemTablet = `w-1/${itemsPerRowPerMediaQuery.tablet}`;
  const sizeItemDesktop = `w-1/${itemsPerRowPerMediaQuery.desktop}`;
  const sizeItemMobile = mobile === 1 ? 'w-full' : 'w-1/2';

  return (
    <div className={`launcherItem p-8px w-1/3 tablet:${sizeItemTablet} desktop:${sizeItemDesktop} mobile:${sizeItemMobile}`}>
      <Link to={url} localizationpath={path} className="text-black">
        <div className="mb-14 sm:mb-6 md:mb-10 launcherItemImg relative">
          <img className="mb-5 min-w-full " src={imageModifier(translateObj(image, language), IMAGE_SIZE)} alt={translateObj(title, language)} />
          <h2 className="font-americaRegular text-18 sm:text-sm tracking-0025 uppercase" style={{ color }}>{translateObj(title, language)}</h2>
          <h3 className="mt-2 mb-4 font-americaRegular font-medium text-base sm:text-xs leading-tight tracking--0025">{translateObj(text, language)}</h3>
          <p className="mt-2 flex flex-col font-americaRegular text-xxs uppercase" style={{ color }}>{venues.map(venue => <span key={venue}>{venue}</span>)}</p>
        </div>
      </Link>
    </div>
  );
};

Item.propTypes = {
  image: PropTypes.shape({
    en: PropTypes.string,
  }).isRequired,
  subtitle: PropTypes.shape(),
  text: PropTypes.shape(),
  title: PropTypes.shape().isRequired,
  url: PropTypes.string,
  itemsPerRowPerMediaQuery: PropTypes.shape({}),
  color: PropTypes.string,
};

Item.defaultProps = {
  subtitle: null,
  text: null,
  url: null,
  itemsPerRowPerMediaQuery: DEFAULT_PROPS_ITEMS_PER_ROW,
  color: 'transparent',
};

const Tour = ({ items, itemsPerRowPerMediaQuery, color }) => {
  const { language, path } = useRoutes();

  if (!items?.length) return null;

  const highlight = items[0];

  return (
    <>
      <div className="flex flex-col desktop:flex-row -mx-2">
        <div className="flex-1">
          <ItemHighlight {...highlight} color={color} />
        </div>
        <div className="flex flex-wrap flex-1">
          {
            items.slice(1).map(item => <Item {...item} key={item.image.en || item.url} itemsPerRowPerMediaQuery={itemsPerRowPerMediaQuery} color={color} />)
          }
        </div>
      </div>
      <div className="mb-8 flex justify-center">
        <Link to="/tours" localizationpath={path} role="button">
          <Button language={language} translations={seeAllButton} color={color} />
        </Link>
      </div>
    </>
  );
};

Tour.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})),
  itemsPerRowPerMediaQuery: PropTypes.shape({}),
  color: PropTypes.string,
}.isRequired;

Tour.defaultProps = {
  items: null,
  itemsPerRowPerMediaQuery: DEFAULT_PROPS_ITEMS_PER_ROW,
  color: 'transparent',
};

export default Tour;
