/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import Collapse from '@material-ui/core/Collapse';
import ListItemText from '@material-ui/core/ListItemText';
import useDebounce from '../../../util/useDebounce';
import InputTextButtonSimple from '../InputTextButtonSimple';

const ExpandLess = () => <span className="font-icon icon-arrow-up text-white text-xs" />;
const ExpandMore = () => <span className="font-icon icon-arrow-down font-black text-white text-xs" />;

const ActiveFilter = ({ title, callback }) => (
  <span className="bg-gray200 py-2 px-3 mr-2 mb-2 font-americaMonoBold text-xxs rounded-lg flex items-center uppercase text-white">
    <div onClick={callback} className="w-2 mr-2">
      <span className="font-icon text-white text-xxs icon-close cursor-pointer" />
    </div>
    {title}
  </span>
);

ActiveFilter.propTypes = {
  title: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired,
};

const TableListToolbar = (props) => {
  const { setGlobal, searchLabel } = props;
  const el = useRef(null);

  const [open, setOpen] = useState(false);
  const [globalSearch, setGlobalSearch] = useState(null);
  const debouncedGlobal = useDebounce(globalSearch, 500);

  const handleOpenMenu = () => {
    if (window && window.scrollY > 100) {
      window.scrollTo(0, 100);
    }
    setOpen(v => !v);
  };

  const throttle = (func, limit) => {
    let inThrottle;
    return (...args) => {
      const context = this;
      if (!inThrottle) {
        func.apply(context, args);
        inThrottle = true;
        setTimeout(() => {
          inThrottle = false;
        }, limit);
      }
    };
  };

  const handleScroll = () => {
    const parentEl = el.current;
    if (parentEl !== null && parentEl.getBoundingClientRect().top === 0 && open) {
      setOpen(false);
    }
  };

  const throttleScroll = throttle(handleScroll, 50);

  useEffect(() => {
    setGlobal(globalSearch);
    window.addEventListener('scroll', throttleScroll);
    return () => window.removeEventListener('scroll', throttleScroll);
  }, [debouncedGlobal]);

  const PdfAndSearchInput = () => (
    <div className="flex">
      <div className="block sm:hidden">
        <InputTextButtonSimple
          mobile={false}
          query={globalSearch || ''}
          name="searchQuery"
          params={{ placeholder: searchLabel, text: searchLabel, searchIcon: 'font-icons icon-search' }}
          black
          handleOnChange={e => setGlobalSearch(e)}
        />
      </div>
      <div className="hidden sm:block w-full">
        <InputTextButtonSimple
          mobile
          query={globalSearch || ''}
          name="searchQuery"
          params={{ placeholder: searchLabel, text: searchLabel, searchIcon: 'font-icons icon-search' }}
          black
          handleOnChange={e => setGlobalSearch(e)}
        />
      </div>
    </div>
  );

  return (
    <div className="pb-12 sm:pb-4 w-full" ref={el}>
      <div className="w-full text-white font-americaMonoRegular bg-black px-8 sm:px-4 py-4 sm:py-2 rounded-b-lg">
        <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
          <div className="flex w-full justify-between items-center">
            <div
              className="flex sm:flex-1 cursor-pointer align-center sm:justify-between md:pointer-events-none lg:pointer-events-none"
              onClick={handleOpenMenu}
            >
              <ListItemText primary={<span className="font-americaMonoBold uppercase text-white">{searchLabel}</span>} />
              <span className="ml-4 md:hidden lg:hidden xl:hidden 2xl:hidden">{open ? <ExpandLess /> : <ExpandMore />}</span>
            </div>
            <div className="sm:hidden">
              <PdfAndSearchInput />
            </div>
          </div>
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <div className="pb-4 sm:pb-0 md:hidden lg:hidden">
            <div className="pt-6 sm:pt-4 mb-6 sm:mb-4 mt-4 sm:mt-2 border-dotted border-white border-t-1">
              <div className="hidden sm:block sm:pb-4">
                <PdfAndSearchInput />
              </div>
            </div>
          </div>
        </Collapse>
      </div>
      {globalSearch && (
        <div className="flex pt-4 pb-2 flex-wrap">
          <ActiveFilter
            key={globalSearch}
            title={globalSearch}
            callback={() => setGlobalSearch(null)}
          />
        </div>
      )}
    </div>
  );
};

TableListToolbar.propTypes = {
  setGlobal: PropTypes.func.isRequired,
  searchLabel: PropTypes.string.isRequired,
};

TableListToolbar.defaultProps = {
};

export default TableListToolbar;
