/* eslint-disable quotes */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import userWebVerification from '../../../api/graphql/mutations/userWebVerification';
import { useUser } from '../../../context/userProfileContext';
import { useRoutes } from '../../../context/routesContext';

import translateObj from '../../../util/translateObj';
import getTranslation from '../MusicMeets/util/translations/_getTranslation';

const DEFAULT_TRANSLATIONS = {
  checkYourEmailText: {
    es: 'Es necesario verificar tu cuenta, por favor comprueba tu correo electrónico',
    en: 'please check your email in order to verify your account',
    ca: 'si us plau comprova el teu correu electrònic',
    pt: 'please check your email in order to verify your account',
  },
  sendShortButton: {
    en: 'Resend',
    es: 'Reenviar',
    ca: 'Reenviar',
    pt: 'Reenviar',
  },
  emailSentShortButton: {
    en: 'Sent',
    es: 'Enviado',
    ca: 'Enviat',
    pt: 'Enviado',
  },
  verifyAccount: {
    en: 'You must verify your email to continue. If you have not received a verification email, you can resend it here.',
    es: 'Debes verificar tu correo electrónico para continuar. Si no has recibido ningún email de verificación, puedes reenviarlo aquí.',
    ca: 'Has de verificar el teu correu electrònic per a continuar. Si no has rebut cap email de verificació, pots reenviar-lo aquí.',
  },
  emailSent: {
    en: 'The verification email has been sent, check your email, activate your account and refresh this page',
    es: 'El email de verificación ha sido enviado, revisa tu correo, activa tu cuenta y refresca esta página',
    ca: `L'email de verificació ha estat enviat, revisa el teu correu, activa el teu compte i refresca aquesta pàgina`,
  },
};

const ANIMATION_CLASSES = 'hover:border-0 hover:border-transparent  md:hover:bg-white';
export const PROFILE_FORM_ALERT = 'PROFILE_FORM_ALERT';

const VerificationBanner = ({ type, translation }) => {
  const [emailSent, setEmailSent] = useState(false);
  const [sendingEmail, setSendingEmail] = useState(false);
  const { verified, userEmail } = useUser();
  const { language } = useRoutes();

  const literals = getTranslation(translation, DEFAULT_TRANSLATIONS);

  const sendVerificationEmail = () => {
    if (sendingEmail) return;
    setSendingEmail(true);
    userWebVerification({ email: userEmail }).then((res) => {
      if (res) {
        setEmailSent(true);
        setSendingEmail(false);
      } else {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Error - webVerification failed to submit!`);
      }
    });
  };

  if (verified || !userEmail) return null;

  // eslint-disable-next-line curly
  if (type === PROFILE_FORM_ALERT) return (
    <div className={`text-pro-online font-timesRegular text-12 text-black p-5 rounded-lg w-full ${emailSent ? 'bg-default' : 'bg-pro-online cursor-pointer'}`} onClick={sendVerificationEmail}>
      {translateObj(emailSent ? literals.emailSent : literals.verifyAccount, language)}
    </div>
  );

  return (
    <div className="sm:flex-col sm:pt-4 bg-orange w-full py-4 px-4 flex z-radio justify-between">
      <div className="py-1">
        <span className="text-14 uppercase font-americaMonoBold">{translateObj(literals.checkYourEmailText, language)}</span>
      </div>
      <button
        className={`flex justify-center trans self-center sm:mt-4 lg:hover:bg-white xl:hover:bg-white items-center font-americaMonoBold py-1 px-4 md:px-2 rounded-full text-14 sm:text-xs uppercase text-black w-165px mr-6 sm:mr-0 w-165px xs:w-auto xs:px-4 inline-block border-black border-1 border-black ${emailSent ? 'cursor-not-allowed' : `${ANIMATION_CLASSES} cursor-pointer`}`}
        onClick={sendVerificationEmail}
        type="button"
        disabled={emailSent}
      >
        <span className="block">{translateObj(emailSent ? literals.emailSentShortButton : literals.sendShortButton, language)}</span>
      </button>
    </div>
  );
};

VerificationBanner.propTypes = {
  type: PropTypes.string,
  translation: PropTypes.shape(),
};

VerificationBanner.defaultProps = {
  type: null,
  translation: null,
};


export default VerificationBanner;
