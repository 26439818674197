import React, { useEffect, useState, useRef } from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import ShareMedia from './ShareMedia';
import ComponentIterator from '../../containers/ComponentIterator';
import getPostsBySlugNameQuery from '../../api/graphql/queries/getPostsBySlugNameQuery';
import getPostsList from '../../api/graphql/queries/getPostsList';
import PSResponsiveUtility from './LineUp/PSResponsiveUtility';
import Separator from './Separator';
import SliderBannerPD from './Sliders/SliderBannerPD';
import LoadIcon from './LoadIcon';

const SinglePostPage = ({
  components, match: { params }, width, classesLeft, classesRight,
}) => {
  const [postDescription, setPostDescription] = useState();
  const [updatedComponents, setUpdatedComponents] = useState();
  const [bottomComponents, setBottomComponents] = useState();
  const [restOfComponents, setRestOfComponents] = useState();
  const [hasCarousel, setHasCarousel] = useState(false);
  const [deviceScreen, setDeviceScreen] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [slugName, setSlugName] = useState(null);
  const responsiveUtility = useRef();
  const shouldFetch = useRef();

  const displayDate = (data) => {
    const updatedComps = [...components];
    if (updatedComps.length && updatedComps[0].type === 'TitleLineH1') {
      updatedComps[0].margin = 'sm:text-3xl md:text-3xl xl:text-38 lg:text-38 leading-tight';
    }
    if (components.length && components[0].type === 'TitleLineH1') {
      if (data.date) {
        updatedComps.splice(1, 0, {
          type: 'NewsDate',
          color: 'black',
          createdAt: data.date,
          margin: 'mt-1',
        });
      }
    } else {
      if (data.date) {
        updatedComps.unshift({
          type: 'NewsDate',
          color: 'black',
          createdAt: data.date,
          margin: 'mt-1',
        });
      }
    }
    setUpdatedComponents(updatedComps);
  };

  const getRelatedNews = (slug, section) => {
    const postCategory = ['news'];
    if (slug !== section) {
      postCategory.push(section);
    }
    if (slug) {
      getPostsList(postCategory, 0, 3).then((postsList) => {
        if (shouldFetch.current) {
          if (postsList && postsList.length) {
            const relatedPosts = postsList.filter(post => post.slugName !== slug);
            setBottomComponents([
              {
                type: 'Separator',
                borderWidth: 3,
              },
              {
                type: 'SidebarRelatedNews',
                data: relatedPosts,
              },
            ]);
          }
          setIsLoading(false);
        }
      });
    }
  };

  const fetchPostDrescriptionsBySlugnames = (slugnames) => {
    getPostsBySlugNameQuery(slugnames)
      .then((res) => {
        if (res && res.length && shouldFetch.current) {
          const description = res[0].postDescription;
          setPostDescription(description);
          displayDate(description);
        }
      })
      .catch((err) => {
        console.log(
          'Some error happened on fetch of fetchPostDescription:',
          err,
        );
      });
  };

  const handleResize = () => {
    const screen = responsiveUtility.current.deviceScreen(true);
    setDeviceScreen(screen);
  };

  const init = async (newsSlug) => {
    await fetchPostDrescriptionsBySlugnames([newsSlug]);
    await getRelatedNews(newsSlug, params.section);
  };
  useEffect(() => {
    shouldFetch.current = true;
    if (slugName !== params.post && slugName !== params.section) {
      setIsLoading(true);
      const carousel = components.length && components[0].type === 'Carousel';
      setHasCarousel(carousel);
      if (carousel) {
        const rest = [...components];
        rest.splice(0, 1);
        setRestOfComponents(rest);
      }

      let newsSlug;
      if (params.post) {
        newsSlug = params.post;
      } else {
        newsSlug = params.section;
      }
      setSlugName(newsSlug);
      init(newsSlug);
    }

    return () => { shouldFetch.current = false; };
  }, [components]);

  return (
    <>
      <PSResponsiveUtility
        ref={responsiveUtility}
        deviceWidthCallback={handleResize}
      />
      {!isLoading && postDescription ? (
        <>
          {!hasCarousel ? (
            <SliderBannerPD {...postDescription} />
          ) : (
            <ComponentIterator components={updatedComponents && updatedComponents.length ? [updatedComponents[0]] : []} />
          )}
        </>
      ) : (
        <div
          className={`${
            hasCarousel
              ? 'h-620'
              : 'h-screen80'
          } h-620 md:h-carouselMD sm:h-carouselSM w-full sliderComponent relative full-width`}
        />
      )}
      {!isLoading
        ? (
          <>
            <div className="py-4 w-full flex justify-end">
              {postDescription ? <ShareMedia description={postDescription.title} /> : null}
            </div>
            <div className="mt-5 w-full flex mb-12 sm:mb-0 justify-center">
              <div
                className={`w-1/${width} sm:hidden md:hidden lg:w-ticket-lg xl:w-ticket-xl ${classesLeft}`}
              >
                {bottomComponents && (
                  <ComponentIterator components={bottomComponents} />
                )}
              </div>
              <div
                className={`xl:w-1200 lg:w-1028 sm:w-full pl-6 sm:pl-0 md:pl-0 sm:mb-4 md:w-full mt-7px ${classesRight} `}
              >
                {deviceScreen === 'md' || deviceScreen === 'sm' ? (
                  <Separator borderWidth={2} />
                ) : null}
                <div className="md:my-10">
                  {updatedComponents && updatedComponents.length && !hasCarousel
                    ? <ComponentIterator components={updatedComponents} /> : null}
                  {restOfComponents && restOfComponents.length
                    ? <ComponentIterator components={restOfComponents} /> : null}
                </div>
                {deviceScreen === 'md' || deviceScreen === 'sm'
                  ? bottomComponents && (
                  <div className="mt-6">
                    <ComponentIterator components={bottomComponents} />
                  </div>
                  )
                  : null}
              </div>
            </div>
          </>
        ) : <div className="my-12"><LoadIcon color="barcelona" /></div> }
    </>
  );
};

SinglePostPage.propTypes = {
  match: PropTypes.shape({}).isRequired,
  components: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  width: PropTypes.number,
  classesLeft: PropTypes.string,
  classesRight: PropTypes.string,
};

SinglePostPage.defaultProps = {
  width: 4,
  classesLeft: '',
  classesRight: '',
};

export default withRouter(SinglePostPage);
