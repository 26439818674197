import React from 'react';
import { PropTypes } from 'prop-types';
import { LinkWrapper } from '../../util/link';
import SimpleScheduleTableCardItem from './SimpleScheduleTableCardItem';
import { useRoutes } from '../../context/routesContext';
import imageModifier from './helpers/imageModifier';
import translateObj from '../../util/translateObj';

const SimpleScheduleTable = ({ schedules, timezone }) => {
  const { language, path } = useRoutes();

  return (
    <div className="flex sm:flex-col flex-row justify-center w-full">
      {schedules.map((schedule) => (
        <div className="p-5 sm:m-0 m-5 border-black llg:w-1/3 lg:w-1/2 md:w-1/2 sm:w-full bg-white" key={schedule.id}>
          <LinkWrapper className="" url={translateObj(schedule.link, language)} path={path}>
            <img className="w-full" src={imageModifier(translateObj(schedule.image, language))} alt={schedule.title?.en} />
          </LinkWrapper>
          <p className="font-americaBold pt-5 pb-2 text-2rem text-black">{translateObj(schedule.title, language)}</p>
          <SimpleScheduleTableCardItem data={schedule.data} timezone={timezone} />
        </div>
      ))}
    </div>
  );
};

SimpleScheduleTable.propTypes = {
  schedules: PropTypes.shape().isRequired,
  timezone: PropTypes.string.isRequired,
};

export default SimpleScheduleTable;
