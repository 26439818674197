import React from 'react';
import PropTypes from 'prop-types';
import style from 'styled-components';
import Panel from '@primaveralabs/ui-panel';
import Scoreboard from '@primaveralabs/ui-scoreboard';
import 'Primavera/ui-panel/dist/primavera.css';
import 'Primavera/ui-scoreboard/dist/primavera.css';
import 'Primavera/ui-themes/dist/primavera/index.css';
import translateObj from '../../../util/translateObj';
import { useRoutes } from '../../../context/routesContext';
import LoadIcon from '../LoadIcon';

const LoyaltyPointsScore = ({
  loyaltyPoints,
  loading,
  title,
  subtitle,
}) => {
  const { language } = useRoutes();
  const pointsPerLevel = 50;
  const progressPercentage = Math.round(
    ((loyaltyPoints % pointsPerLevel) / pointsPerLevel) * 100,
  );
  const userLevel =
    loyaltyPoints % pointsPerLevel === 0
      ? Math.ceil(loyaltyPoints / pointsPerLevel) + 1
      : Math.ceil(loyaltyPoints / pointsPerLevel);

  const PanelStyle = style.div`
  grid-area: score;
  max-height: 250px;
  @media (min-width: 980px) {
      max-height: 350px;
  }
  @media (min-width: 1380px) {
      max-height: 385;
  }
  `;

  return (
    <PanelStyle>
      <Panel title={translateObj(title, language)} display="center">
        {subtitle && translateObj(subtitle, language).length > 0 && (
          <p className="font-americaMedium text-12 lg:text-base xl:text-base text-white pb-8">
            {translateObj(subtitle, language)}
          </p>
        )}
        {loading ? (
          <div className="flex items-center justify-center h-full">
            <LoadIcon />
          </div>
        ) : (
          <Scoreboard
            level={userLevel}
            score={loyaltyPoints}
            progress={progressPercentage}
          />
        )}
      </Panel>
    </PanelStyle>
  );
};

LoyaltyPointsScore.propTypes = {
  loyaltyPoints: PropTypes.number,
  loading: PropTypes.bool,
  title: PropTypes.shape({}),
  subtitle: PropTypes.shape({}),
};

LoyaltyPointsScore.defaultProps = {
  loyaltyPoints: 0,
  loading: false,
  title: { en: 'My points', es: 'Mis puntos', ca: 'Els meus punts' },
  subtitle: null,
};

export default LoyaltyPointsScore;
