import gql from 'graphql-tag';

import { clientWeb, persistor } from '../graphqlAPI';

const GET_POSTS_BY_SLUG_NAME = gql`
  query GetPostsBySlugName($slugnames: [String]!, $sortByPostLauncherDate: Boolean, $quantity: Int) {
    getPostsBySlugName(slugnames: $slugnames, sortByPostLauncherDate: $sortByPostLauncherDate, quantity: $quantity){
      slugName
      active
      createdAt
      postCategory
      postDescription{
        description{
          en es ca pt
        }
        title{
          en es ca pt
        }
        subtitle{
          en es ca pt
        }
        image{
          en es ca pt
        }
        tags
        url
        overlayImage
        authors {
          name
          slug
        }
        relatedArtists {
          name,
          slug
        }
        relatedMagazine {
          title,
          slugName
        }
        date
        itemLayout
        overlayImage
        loyaltyPoints
      }
      rolesAllowed
      prev {
        slugName
        postCategory
        postDescription {
          image{
            en es ca pt
          }
          url
        }
      }
      next {
        slugName
        postCategory
        postDescription {
          image{
            en es ca pt
          }
          url
        }
      }
    }
  }
`;

const getPostsBySlugNameQuery = async (slugs, sortByPostLauncherDate, quantity) => {
  try {
    const result = await clientWeb
      .query({
        query: GET_POSTS_BY_SLUG_NAME,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
        variables: { slugnames: slugs, sortByPostLauncherDate, quantity },
        fetchResults: true,
        pollInterva: 300000,
      })
      .then(queryResult => queryResult)
      .catch((queryError) => {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error: ${queryError}`);
        return null;
      });
    const {
      data: { getPostsBySlugName },
    } = result;

    if (getPostsBySlugName) {
      return getPostsBySlugName;
    }
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: GraphQL Query Error: getPostsBySlugName`);
    return null;
  } catch (error) {
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: getPostsBySlugName Error: ${error}`);
    return null;
  }
};

export default getPostsBySlugNameQuery;
