import React from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import withRadioPlayerContext from '../../../context/withRadioPlayerContext';

import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';
import RadioPlayButton from './RadioPlayButton';
import RadioPlayIcon from './RadioPlayIcon';
import translateObj from '../../../util/translateObj';

const ExpandLess = () => <span className="font-icon icon-arrow-up text-white text-sm mr-2" />;
const ExpandMore = () => <span className="font-icon icon-arrow-down text-white text-sm font-black mr-2" />;
class RadioHeader1 extends React.Component {
  state = {
    open: this.props.open || false,
  }

  toggleOpen = () => {
    const { toggleCallback } = this.props;
    const { open } = this.state;
    this.setState({
      open: !open,
    }, () => {
      if (toggleCallback) toggleCallback(this.state.open);
    });
  }

  render() {
    const {
      match,
      title,
      mixcloud,
      category,
      channel,
      ch1,
      ch2,
    } = this.props;
    const { open } = this.state;
    let title_multilang;
    if (ch1 && channel === 'ch1') {
      title_multilang = ch1.title_multilang;
    }
    if (ch2 && channel === 'ch2') {
      title_multilang = ch2.title_multilang;
    }

    return (
      <RoutesContext.Consumer>
        {
        ({ language: contextLanguage, enteredFrom }) => {
          const localization = getLocalization(contextLanguage, enteredFrom, match);
          return (
            <div className={`w-full flex align-center justify-between p-2 ${category ? `bg-${category}` : 'bg-black'}`}>
              { mixcloud ? <RadioPlayButton size={36} channel={mixcloud} /> : <RadioPlayButton size={36} channel={channel} /> }
              <div className="flex-1 pl-5 flex flex-col text-white text-12 justify-center">
                <h5 className="font-americaBlack uppercase ">
                  {translateObj(title, localization.language)}
                </h5>
                <p className="leading-normal font-tiemposRegular">{translateObj(title_multilang, localization.language)}</p>
              </div>
              <RadioPlayIcon bgCircle="radio" bgWave="white" top="12px" right="10px" channel={channel} classNames="sm:hidden md:hidden" />
              <div className="hidden items-center" onClick={this.toggleOpen}>
                {open
                  ? <ExpandLess /> : <ExpandMore />
                }
              </div>
            </div>
          );
        }
      }
      </RoutesContext.Consumer>
    );
  }
}

RadioHeader1.propTypes = {
  match: PropTypes.shape().isRequired,
  title: PropTypes.shape().isRequired,
  // eslint-disable-next-line react/require-default-props
  open: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  toggleCallback: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  category: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  channel: PropTypes.string.isRequired,
  mixcloud: PropTypes.string,
  ch1: PropTypes.shape({}),
  ch2: PropTypes.shape({}),
};

RadioHeader1.defaultProps = {
  toggleCallback: null,
  category: '',
  mixcloud: null,
  ch1: null,
  ch2: null,
};

export default withRouter(withRadioPlayerContext(RadioHeader1));
