/* eslint-disable camelcase */
import React from 'react';
import { PropTypes } from 'prop-types';
import FilePlayer from 'react-player';

const StreamingVideoPlayerMainMobile = ({
  mainVideo, source,
}) => {
  const isYoutube = Boolean(source === 'youtube');
  return (
    <FilePlayer
      url={mainVideo}
      height="100%"
      width="100%"
      // playing={!isYoutube}
      controls={!isYoutube}
      loop={!isYoutube}
      playsinline
      // muted={!isYoutube}
    />
  );
};

StreamingVideoPlayerMainMobile.propTypes = {
  mainVideo: PropTypes.string.isRequired,
  source: PropTypes.string,
};

StreamingVideoPlayerMainMobile.defaultProps = {
  source: '',
};

export default StreamingVideoPlayerMainMobile;
