/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import React, { Fragment, memo, useContext } from 'react';
import PropTypes from 'prop-types';

import MyErrorComponent from '../components/MyErrorComponent';
import { componentIteratorContext } from '../context/ComponentIteratorContext';

let keyIndex = 0;

const ComponentIterator = ({ components }) => {
  const types = useContext(componentIteratorContext);
  return (
    <>
      {
        components.map((component) => {
          if (types[component.type]) {
            const ComponentType = types[component.type];

            return (
              <MyErrorComponent key={Math.random()}>
                <ComponentType key={component.compId || `${component.type}_${component.slugName}_${keyIndex++}`} {...component}>
                  { component.components && <ComponentIterator key={component.compId || `${component.type}_${component.slugName}_${keyIndex++}`} components={component.components} /> }
                </ComponentType>
              </MyErrorComponent>
            );
          }

          return null;
        })
      }
    </>
  );
};

ComponentIterator.propTypes = {
  components: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
};

ComponentIterator.defaultProps = {
};

export default memo(ComponentIterator);
