import { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export function useInitOpenChatContext() {
  const [chatIsOpen, setChatIsOpen] = useState(false);

  return {
    chatIsOpen,
    setChatIsOpen,
  };
}

const openChatContextValue = {
  chatIsOpen: false,
  setChatIsOpen: () => {},
};

export const OpenChatContext = createContext(openChatContextValue);

OpenChatContext.propTypes = {
  chatIsOpen: PropTypes.bool,
  setChatIsOpen: PropTypes.func,
};

OpenChatContext.defaultProps = {
  chatIsOpen: false,
  setChatIsOpen: () => {},
};
