import React from 'react';
import PropTypes from 'prop-types';
import { useRoutes } from '../../../context/routesContext';

import { LinkWrapper } from '../../../util/link';
import CancelIcon from './svg/CancelIcon';
import AddUserIcon from './svg/AddUserIcon';
import OnlineIcon from './svg/OnlineIcon';
import RescheduleIcon from './svg/RescheduleIcon';
import ShareIcon from './svg/ShareIcon';
import NotificationIcon from './svg/NotificationIcon';
import DeleteIcon from './svg/DeleteIcon';
import LoadIcon from '../LoadIcon';

const getColor = (color) => {
  switch (color) {
    case 'fill-black':
      return { text: 'text-white', border: 'border-black', icon: 'black' };
    case 'white':
      return { text: 'text-white', border: 'border-white', icon: 'white' };
    case 'orange':
      return { text: 'text-pro-online', border: 'border-pro-online', icon: 'pro' };
    case 'black':
    default:
      return { text: 'text-black', border: 'border-black', icon: 'black' };
  }
};

const getIcon = (icon) => {
  switch (icon) {
    case 'cancel':
      return <CancelIcon />;
    case 'online':
      return <OnlineIcon />;
    case 'reschedule':
      return <RescheduleIcon />;
    case 'addUser':
      return <AddUserIcon />;
    case 'share':
      return <ShareIcon />;
    case 'notification':
      return <NotificationIcon />;
    case 'delete':
      return <DeleteIcon />;
    default:
      return null;
  }
};

const IconButton = ({
  color,
  border,
  label,
  onClickCallback,
  link,
  className,
  icon,
  loading,
}) => {
  const { path } = useRoutes();

  const buttonColor = getColor(color);
  const iconType = getIcon(icon);

  const content = (
    <div className="flex items-center">
      <div className={`${color === 'fill-black' ? 'bg-black' : ''} w-10 h-10 sm:w-30px sm:h-30px ${border ? `border-2 ${buttonColor.border}` : ''} rounded-full flex items-center justify-center ${buttonColor.text} ${icon && !iconType ? icon : ''}`}>
        {loading
          ? <div className="flex items-center justify-center pt-3px"><LoadIcon height={15} width={15} color={buttonColor.icon} /></div>
          : (
            <>
              {iconType && <div className={`w-1/2 flex items-center justify-center ${buttonColor.text} fill-current`}>{iconType || ''}</div>}
            </>
          )}
      </div>
      {label && <div className={`pl-3 font-americaMonoBold text-12 uppercase tracking-px ${buttonColor.text}`}>{label}</div>}
    </div>
  );

  if (onClickCallback) {
    return (
      <button type="button" className={className} onClick={() => onClickCallback()} disabled={loading}>
        {content}
      </button>
    );
  }

  return (
    <div className={className}>
      <LinkWrapper path={path} url={link}>
        {content}
      </LinkWrapper>
    </div>
  );
};

IconButton.propTypes = {
  color: PropTypes.string,
  border: PropTypes.bool,
  label: PropTypes.string,
  link: PropTypes.string,
  onClickCallback: PropTypes.func,
  className: PropTypes.string,
  icon: PropTypes.string,
  loading: PropTypes.bool,
};

IconButton.defaultProps = {
  color: 'black',
  border: true,
  label: null,
  link: '',
  onClickCallback: null,
  className: '',
  icon: '',
  loading: false,
};

export default IconButton;
