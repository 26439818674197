import React from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';
import translateObj from '../../util/translateObj';

import ShareMedia from './ShareMedia';

// const { isFavoritesActive } = process.env;
const isFavoritesActive = false;

const FavoritesShare = ({ match, black }) => {
  const favorites = {
    es: 'AGREGAR A FAVORITOS',
    en: 'ADD TO FAVORITES',
    ca: 'AFEGIR A PREFERITS',
  };
  return (
    <RoutesContext.Consumer>
      {({ language: contextLanguage, enteredFrom }) => {
        const localization = getLocalization(contextLanguage, enteredFrom, match);
        return (
          <div className={`${black && 'bg-black p-4'} flex flex-row items-center justify-end`}>
            {isFavoritesActive
              && (
                <div className={`${black && 'text-white'} flex cursor-pointer items-center font-americaMonoBold text-12 justify-center`}>
                  <span className={`${black && 'text-white'} font-icon icon-star text-xl pr-2`} />
                  <span className={`${black && 'text-white'} sm:hidden`}>{translateObj(favorites, localization.language)}</span>
                </div>
              )
            }
            <ShareMedia black={black} />
          </div>
        );
      }}
    </RoutesContext.Consumer>
  );
};

FavoritesShare.propTypes = {
  match: PropTypes.shape().isRequired,
  black: PropTypes.bool,
};

FavoritesShare.defaultProps = {
  black: false,
};

export default withRouter(FavoritesShare);
