import React from 'react';
import { PropTypes } from 'prop-types';

import { withRouter } from 'react-router-dom';
import moment from 'moment';
import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';
import RadioPlayIcon from './RadioPlayIcon';
import translateObj from '../../../util/translateObj';

class RadioTodayGridTable extends React.Component {
  state = {
    pre: false,
    post: false,
    middles: [],
    currentIndex: -1,
  };

  componentDidMount() {
    this.processItems();
  }

  componentDidUpdate(prevProps) {
    const { match: { params }, items } = this.props;
    if (params.channel !== prevProps.match.params.channel) {
      this.processItems();
      this.setState({
        currentIndex: -1,
      });
    }
  }

  processItems = () => {
    const { items } = this.props;
    let { currentIndex } = this.state;
    if (currentIndex < 0) {
      currentIndex = this.findLiveIndex();
    }

    let pre = false;
    let post = false;
    const middles = [];

    if (typeof items[currentIndex - 1] !== 'undefined') {
      pre = items[currentIndex - 1];
    }
    for (let i = currentIndex; i < currentIndex + 4; i++) {
      if (typeof items[i] !== 'undefined') {
        middles.push(items[i]);
      }
    }

    if (typeof items[currentIndex + 4] !== 'undefined') {
      post = items[currentIndex + 4];
    }

    this.setState({
      currentIndex, pre, post, middles,
    });
  }

  handleArrowClick = (top, item) => {
    const { currentIndex } = this.state;
    const { items } = this.props;
    if (top) {
      if (currentIndex > 1) {
        this.setState({ currentIndex: currentIndex - 1 }, () => this.processItems());
      }
    } else {
      if (currentIndex < items.length - 1) {
        this.setState({ currentIndex: currentIndex + 1 }, () => this.processItems());
      }
    }
  }

  findLiveIndex() {
    const { items } = this.props;
    let { currentIndex } = this.state;
    items.forEach((item, index) => {
      if (index !== items.length - 1) {
        item.isLive = moment().isBetween(moment(item.dateAir), moment(items[index + 1].dateAir));
        item.minutesToStart = moment.duration(moment(item.dateAir).diff(moment())).asMinutes();
      }
      if (item.isLive) {
        currentIndex = index;
      }
    });

    if (currentIndex < 0) {
      let currentMinutesToStart = 60 * 24;
      items.forEach((item, index) => {
        if (item.minutesToStart > 0 && item.minutesToStart < currentMinutesToStart) {
          currentIndex = index;
          currentMinutesToStart = item.minutesToStart;
        }
      });
      if (currentIndex < 0) {
        currentIndex = 0;
      }
    }
    return currentIndex;
  }

  render() {
    const {
      text1, text2, text3, text4, icon1, icon2, category, match, isLoading,
    } = this.props;

    return (
      <RoutesContext.Consumer>
        {
        ({ language: contextLanguage, enteredFrom }) => {
          const localization = getLocalization(contextLanguage, enteredFrom, match);
          const TopBottomArrow = ({ top, item }) => {
            const { currentIndex, middles } = this.state;
            if (top && currentIndex === 1) {
              return null;
            } if (!top && currentIndex > middles.length) {
              return null;
            }
            return <span className={`h-8 absolute pin-t ${top ? icon1 : icon2}`} onClick={() => this.handleArrowClick(top, item)} />;
          };

          const PrePostRow = ({ item, top }) => (
            <div className={`flex py-4 border-solid border-black ${top ? 'border-b-1' : 'border-t-1'}`}>
              <div className="w-1/6 flex items-center justify-center sm:items-start relative h-8">
                <TopBottomArrow top={top} item={item} />
              </div>
              <div className="w-5/6 flex sm:block">
                <div className="w-1/5 sm:w-full flex items-center font-americaMonoRegular text-xs sm:mb-2">
                  {moment(item.dateAir).format('HH:mm')}
                </div>
                <div className="w-3/5 sm:w-full flex items-center font-tiemposRegular sm:mb-3">
                  <span className="pr-2">
                    {translateObj(item.title_multilang, localization.language)}
                  </span>
                </div>
                {/* <div className="w-1/5 sm:w-full flex items-center">
                  <ComponentIterator components={item.tags} />
                </div> */}
              </div>
            </div>
          );

          const Row = ({
            item, isFirst, isLast,
          }) => {
            const { isLive } = item;
            const pathStyle = {
              left: '50%',
              position: 'absolute',
              top: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 3,
            };
            return (
              <div className="flex">
                <div className="w-1/6 flex items-center justify-center py-5 relative">
                  <div className={`absolute bg-${category} w-px ${isFirst ? 'pin-ht' : 'pin-t'} ${isLast ? 'pin-hb' : 'pin-b'}`} />
                  {isLive
                    ? (
                      <div className="w-12 h-12 relative">
                        <div style={pathStyle}>
                          <RadioPlayIcon isLive />
                        </div>
                      </div>
                    )
                    : (
                      <div className="font-icon icon-current2 absolute pin-t w-12 h-12" style={pathStyle}>
                        <span className={`path1 absolute text-${category}`} style={pathStyle} />
                      </div>
                    )}
                </div>
                <div className={`w-5/6 flex sm:block py-7 ${isLast ? '' : 'border-solid border-black border-b-1'}`}>
                  <div className="w-1/5 sm:w-full flex items-center sm:block sm:mb-2 font-americaMonoRegular text-xs">
                    {isLive ? <span className={`text-${category} font-americaMonoBold uppercase `}>{text4[localization.language]}</span> : moment(item.dateAir).format('HH:mm')}
                  </div>
                  <div className="w-3/5 sm:w-full flex items-center font-tiemposBold sm:mb-3">
                    <span className="pr-2">
                      {translateObj(item.title_multilang, localization.language)}
                    </span>
                  </div>
                  {/* <div className="w-1/5 sm:w-full flex items-center">
                    <ComponentIterator components={item.tags} />
                  </div> */}
                </div>
              </div>
            );
          };

          const { pre, middles, post } = this.state;
          return (
            <div className="w-full text-black">
              {/* <InfoRow text1={text1} text2={text2} text3={text3} /> */}
              {pre ? <PrePostRow item={pre} top /> : null}
              <div>
                {middles.map((item, i) => (
                  <Row key={item.id + i} item={item} isFirst={i === 0} isLast={i === middles.length - 1} />
                ))}
              </div>
              {post ? <PrePostRow item={post} top={false} /> : null}
            </div>
          );
        }
      }
      </RoutesContext.Consumer>
    );
  }
}

RadioTodayGridTable.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    channel: PropTypes.number.isRequired,
  })).isRequired,
  text1: PropTypes.shape().isRequired,
  text2: PropTypes.shape().isRequired,
  text3: PropTypes.shape().isRequired,
  text4: PropTypes.shape().isRequired,
  icon1: PropTypes.string.isRequired,
  icon2: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
};

export default withRouter(RadioTodayGridTable);
