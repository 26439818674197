import React, { useState } from 'react';
import withRadioPlayerContext from '../../../context/withRadioPlayerContext';

const MixcloudPlayer = ({ podcast, hidePlayer, showPlayer }) => {
  let url;
  const [isHidden, setIsHidden] = useState(false);
  if (podcast) url = podcast.slice(podcast.indexOf('/Radio'), -1);
  const t = `https://www.mixcloud.com/widget/iframe/?hide_cover=1&mini=1&light=1&hide_artwork=1&autoplay=1&feed=/${url}/`;

  return (
    <>
      {!isHidden
       && (
       <div className="w-full text-12 text-white absolute pin-b pin-l z-10 bg-white" style={{ background: '#25292c' }}>
         <div onClick={() => { setIsHidden(true); showPlayer(); }} className="sm:h-radio cursor-pointer z-radioClose absolute pin-b pin-r pin-t mr-0 mt-1 h-100">
           <span className="font-icon icon-close font-black text-xs pt-1 pr-1" style={{ color: '#d1d1d1' }} />
         </div>
         { url && <iframe width="100%" title="mixcloud" height="60" src={`https://www.mixcloud.com/widget/iframe/?hide_cover=1&mini=1&autoplay=1&feed=${url}/`} frameBorder="0" allow="autoplay" /> }
       </div>
       )
      }
    </>
  );
};
export default withRadioPlayerContext(MixcloudPlayer);
