import gql from 'graphql-tag';

import { clientWeb, persistor } from '../graphqlAPI';

const SEARCH_ARTISTS = gql`
  query searchArtists($artist: String!, $retry: Boolean, $nextOffset: Int) {
    searchArtists(artist: $artist, retry: $retry, nextOffset: $nextOffset){
        nextOffset
        hasNextPage
        items {
          name
          images {
            height
            url
            width
          }
          id
          slug
        }
    }
  }
`;

const executeQuery = async ({ artist, retry = false, nextOffset, type = 'network' }) => {
  const result = await clientWeb.query({
    query: SEARCH_ARTISTS,
    variables: { artist, retry, nextOffset },
    fetchPolicy: type === 'network' ? 'network-only' : 'cache-only',
  })
    .then((queryResult) => queryResult)
    .catch((queryError) => {
      const errorMessage = type === 'network'
        ? `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error at searchArtists: ${queryError}`
        : `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error at searchArtists after Cache Restore: ${queryError}`;
      console.log('\x1b[31m%s\x1b[0m', errorMessage);

      return null;
    });

  if (result?.data?.searchArtists) {
    const { data: { searchArtists } } = result;
    return searchArtists;
  }

  return [];
};

const searchArtists = async ({ artist, retry, nextOffset }) => {
  try {
    const JSONObject = await executeQuery({ artist, retry, nextOffset });

    if (persistor) {
      persistor.persist();
    }

    return JSONObject;
  } catch (jsonError) {
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: JSON Object Error at searchArtists: ${jsonError}`);

    if (persistor) {
      const persistorStatus = await persistor.restore().then(() => 'OK');
      console.log(`${new Date().toLocaleTimeString()} ~ Client: Apollo Cache Restored - ${persistorStatus}`);

      try {
        const JSONObject = await executeQuery('cache');

        return JSONObject;
      } catch (jsonErrorAfterCacheRestore) {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: JSON Object Error at searchArtists after Cache Restore, sending 404: ${jsonErrorAfterCacheRestore}`);
        return null;
      }
    }
    return null;
  }
};

export default searchArtists;
