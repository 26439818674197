import gql from 'graphql-tag';

import { clientWeb } from '../graphqlAPI';

const POST_CONTACT_FORM_MUTATION = gql`
  mutation Post($events: String!, $firstName: String!, $lastName: String!, $email: String!, $subject: String!, $message: String!) {
    postContactForm(events: $events, firstName: $firstName, lastName: $lastName, email: $email, subject: $subject, message: $message) {
      events,
      firstName
      lastName
      email
      subject
      message
      isValid
    }
  }
`;

const postContactForm = async ({
  events,
  firstName,
  lastName,
  email,
  subject,
  message,
}) => {
  try {
    const result = await clientWeb.mutate({
      mutation: POST_CONTACT_FORM_MUTATION,
      variables: {
        events,
        firstName,
        lastName,
        email,
        subject,
        message,
      },
      fetchPolicy: 'no-cache',
    })
      .then(queryResult => queryResult)
      .catch((queryError) => {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error: ${queryError}`);
        return null;
      });

    const { data: { postContactForm: validatedFormData } } = result;

    if (validatedFormData) {
      return validatedFormData;
    }
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: GraphQL Query Error: validatedFormData = null`);
    return null;
  } catch (error) {
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: postContactForm Error: ${error}`);
    return null;
  }
};

export default postContactForm;
