import React, { createContext, useContext, useState } from 'react';
import { PropTypes } from 'prop-types';
import * as firebase from 'firebase/app';
import { hangUpPrivateCall } from '../utils/helpers';
import 'firebase/firestore';
import 'firebase/auth';

const privatePlusOneZoneContext = createContext(null);

export const usePrivatePlusZoneContext = () => useContext(privatePlusOneZoneContext);

export const PrivatePlusOneZoneProvider = ({ children }) => {
  const [privateChatUrl, setPrivateChatUrl] = useState(null);

  const openPrivateChat = (chatUrl) => {
    if (!chatUrl) {
      const currentFirebaseUser = firebase.auth().currentUser;
      if (currentFirebaseUser && currentFirebaseUser.uid) {
        hangUpPrivateCall(currentFirebaseUser.uid);
      }
    }
    setPrivateChatUrl(chatUrl);
  };

  return (
    <privatePlusOneZoneContext.Provider value={{
      openPrivateChat, privateChatUrl,
    }}
    >
      {children}
    </privatePlusOneZoneContext.Provider>
  );
};

PrivatePlusOneZoneProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

PrivatePlusOneZoneProvider.defaultProps = {
};
