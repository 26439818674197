import gql from 'graphql-tag';

import { clientWeb } from '../graphqlAPI';

const SEND_EMAIL_PRESALE = gql`
  mutation Post($productSlugName: String!, $email: String!, $acceptConditions: Boolean!, $acceptConditionsNewsletter: Boolean, $language: String) {
    userSendPresaleEmail(productSlugName: $productSlugName, email: $email, acceptConditions: $acceptConditions, acceptConditionsNewsletter: $acceptConditionsNewsletter, language: $language) {
      isValid
      message {
        en
        es
        ca
        pt
      }
      error
    }
}`;

const sendEmailPresale = async ({ productSlugName, email, acceptConditions, acceptConditionsNewsletter, language }) => {
  try {
    const result = await clientWeb.mutate({
      mutation: SEND_EMAIL_PRESALE,
      variables: {
        productSlugName,
        email,
        acceptConditions,
        acceptConditionsNewsletter,
        language,
      },
      fetchPolicy: 'no-cache',
    })
      .then(queryResult => queryResult)
      .catch((queryError) => {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error: ${queryError}`);
        return null;
      });

    const { data: { userSendPresaleEmail } } = result;

    if (userSendPresaleEmail) return userSendPresaleEmail;

    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: GraphQL Query Error: validatedFormData = null`);
    return null;
  } catch (error) {
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: userSocialLogin Error: ${error}`);
    return null;
  }
};


export default sendEmailPresale;
