const translation = {
  imageLabel: {
    en: 'MENTORING SESSION',
    es: 'MENTORING SESSION',
    ca: 'MENTORING SESSION',
    pt: 'MENTORING SESSION',
  },
  buttonReserve: {
    en: 'Sign up',
    es: 'Apúntate',
    ca: 'Apunta\'t',
    pt: 'Inscrever-se',
  },
  buttonSoldOut: {
    en: 'Sold out',
    es: 'Sold out',
    ca: 'Sold out',
    pt: 'Sold out',
  },
  shareTooltip: {
    en: 'Link copied to clipboard',
    es: 'Enlace copiado al portapapeles',
    ca: 'S\'ha copiat l\'enllaç al porta-retalls',
    pt: 'Link copiado para a área de transferência',
  },
};

export default translation;
