import { useState, useEffect, useMemo } from 'react';
import moment from 'moment';

const timezone = 'Europe/Madrid';

const calculateInitialTimes = (streamStartDateAndTime = 0, streamEndDateAndTime = 0) => {
  const currentDateUser = moment(new Date()).tz(timezone).format();
  const streamStartDate = streamStartDateAndTime ? moment(streamStartDateAndTime).tz(timezone).format() : null;
  const streamEndDate = streamEndDateAndTime ? moment(streamEndDateAndTime).tz(timezone).format() : null;

  const timeLeftTillShow = streamStartDate && moment(streamStartDate).diff(currentDateUser);
  const showTimeLength = (streamStartDate && streamEndDate) && moment(streamEndDate).diff(streamStartDate);

  return { timeLeftTillShow, showTimeLength };
};

const useDecideStreamingSourceTiming = (streamStartDateAndTime, streamEndDateAndTime, initialSource = 'cover', finalSource = 'vimeo', finalSourceCover = 'finalCover') => {
  const [streamingSource, setStreamingSource] = useState(initialSource);
  const { timeLeftTillShow, showTimeLength } = useMemo(() => calculateInitialTimes(streamStartDateAndTime, streamEndDateAndTime), [streamStartDateAndTime, streamEndDateAndTime]);

  let timeOutTillShow;
  let timeOutShowTimeLength;
  let totalTimeUntilEndOfShow = 0;

  const decideStreamingLogic = () => {
    // * user accessed before the show started
    if (timeLeftTillShow && timeLeftTillShow > 0) {
      timeOutTillShow = setTimeout(() => {
        setStreamingSource(finalSource);
      }, timeLeftTillShow);
      // * there is a end date time set up
      // * if we specified a showtime, the timeout to go back to cover is calculated
      if (showTimeLength) {
        totalTimeUntilEndOfShow = timeLeftTillShow + showTimeLength > 0 ? timeLeftTillShow + showTimeLength : 0;
        timeOutShowTimeLength = setTimeout(() => {
          setStreamingSource(finalSourceCover);
        }, totalTimeUntilEndOfShow);
      }
    } else {
      // * user accessed after the show started
      setStreamingSource(finalSource);
      // * if we specified a showtime, the timeout to go back to cover is calculated
      if (showTimeLength && showTimeLength > 0) {
        totalTimeUntilEndOfShow = timeLeftTillShow + showTimeLength > 0 ? timeLeftTillShow + showTimeLength : 0;
        timeOutShowTimeLength = setTimeout(() => {
          setStreamingSource(finalSourceCover);
        }, totalTimeUntilEndOfShow);
      }
    }
  };

  useEffect(() => {
    decideStreamingLogic();
    return () => {
      clearTimeout(timeOutTillShow);
      clearTimeout(timeOutShowTimeLength);
    };
  }, []);

  return streamingSource;
};

// const { timeLeftTillShow } = useMemo(() => calculateInitialTimes(streamStartDateAndTime), [streamStartDateAndTime]);
// return timeLeftTillShow;
const useCalculateTimeToShow = streamStartDateAndTime => useMemo(() => {
  const { timeLeftTillShow } = calculateInitialTimes(streamStartDateAndTime);
  return timeLeftTillShow;
}, [streamStartDateAndTime]);
export { useDecideStreamingSourceTiming, useCalculateTimeToShow };
