import React from 'react';
import PropTypes from 'prop-types';

import ChatActive from '../svg/ChatActive';
import ChatInactive from '../svg/ChatInactive';

const ChatButton = ({ label, onClick, active }) => (
  <button type="button" style={{ width: 180 }} onClick={onClick}>
    <div className="flex items-center justify-between p-3 bg-black rounded-xl">
      <span className="text-white uppercase font-americaMonoBold pl-2 text-base">{label}</span>
      <div className={`flex-none rounded-full p-3 ${active ? 'bg-pro-online' : 'bg-white'}`}>
        <div className="w-6 h-6">{active ? <ChatActive /> : <ChatInactive />}</div>
      </div>
    </div>
  </button>
);

export default ChatButton;

ChatButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool,
};

ChatButton.defaultProps = {
  active: false,
};
