import React from 'react';
import { RadioPlayerConsumer } from './RadioContextProvider';

const withRadioPlayerContext = Component => props => (
  <RadioPlayerConsumer>
    {value => <Component {...props} {...value} />}
  </RadioPlayerConsumer>
);

export default withRadioPlayerContext;
