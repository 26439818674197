import routesRootAccess from '../shared/routesRootAccess';
import routesLanguageAccess from '../shared/routesLanguageAccess';

// Determine from where the user has entered the app, by analysing Express's req.path
const getEnteredFromPath = (reqPath) => {
  const pathItems = reqPath.split('/');
  let enteredFrom;

  if (pathItems[1] === '') {
    console.log('\x1b[33m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Server: Entered at homepage root.`);
    enteredFrom = 'root';
  } else if (pathItems[1] !== ''
    && pathItems[1] !== 'en'
    && pathItems[1] !== 'es'
    && pathItems[1] !== 'ca'
    && pathItems[1] !== 'pt') {
    console.log('\x1b[33m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Server: Entered at section without language.`);
    enteredFrom = 'rootSection';
  } else {
    console.log('\x1b[33m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Server: Entered home or section with language.`);
    enteredFrom = 'rootLanguage';
  }

  return enteredFrom;
};

const getUserLanguage = (detectedLanguage, enteredFrom, reqPath) => {
  console.log('🚀 ~ file: routesLocalization.js ~ line 28 ~ getUserLanguage ~ detectedLanguage', detectedLanguage, 'enteredFrom', enteredFrom);
  const acceptedLanguages = (process.env.ACCEPTED_LANGUAGES || 'en,es,ca,pt').split(',');
  const defaultLanguage = process.env.DEFAULT_LANGUAGE || 'en';
  // Overwrite the auto-detected language if the user entered home or a section with a language param present in reqPath
  if (enteredFrom === 'rootLanguage') {
    const pathItems = reqPath.split('/');
    const overwriteLanguage = pathItems[1];
    if (acceptedLanguages.includes(overwriteLanguage)){
      console.log(`USER LANGUAGE -- ${overwriteLanguage} is an accepted language`);
      return overwriteLanguage;
    } else {
      console.log(`USER LANGUAGE -- ${overwriteLanguage} is not an accepted language`);
    }
  }

  // Else, check if the auto-detected language is valid and set it
  if (acceptedLanguages.includes(detectedLanguage)) {
    console.log(`USER LANGUAGE -- Detected language ${detectedLanguage} is included on accepted languages list (${acceptedLanguages})`);
    return detectedLanguage;
  }
  console.log(`USER LANGUAGE -- Using default language (${defaultLanguage}) because ${detectedLanguage} is not in accepted languages list (${acceptedLanguages})`);
  // Else, if the auto-detected langauge is not valid, set to the default language (en)
  return defaultLanguage;
};

const getRouteConfig = (enteredFrom) => {
  // Return the React-Router routes array, depending on how the user has entered the app (root, rootSection or rootLanguage)
  switch (enteredFrom) {
    case 'root':
      return routesRootAccess;
    case 'rootSection':
      return routesRootAccess;
    case 'rootLanguage':
      return routesLanguageAccess;
    default:
      return routesRootAccess;
  }
};

export {
  getEnteredFromPath,
  getUserLanguage,
  getRouteConfig,
};
