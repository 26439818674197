import gql from 'graphql-tag';

import { clientWeb, persistor } from '../graphqlAPI';

const GET_MAM_BY_SLUG_NAME = gql`
  query getMamObjectsBySlugName($slugnames: [String]!) {
    getMamObjectsBySlugName(slugnames: $slugnames){
      assetSlugName
      isPublic
      url
      fileExtension
      fileReadableName {
        en
        ca
        es
      }
      description {
        en
        ca
        es
      }
      fileReadableName {
        en
        ca
        es
      }
      tags
      filename
    }
  }
`;

const getMamObjectsBySlugNameQuery = async (slugnames) => {
  try {
    const result = await clientWeb
      .query({
        query: GET_MAM_BY_SLUG_NAME,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
        variables: { slugnames },
        fetchResults: true,
        pollInterva: 300000,
      })
      .then(queryResult => queryResult)
      .catch((queryError) => {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error: ${queryError}`);
        return null;
      });
    const {
      data: { getMamObjectsBySlugName },
    } = result;

    if (getMamObjectsBySlugName) {
      return getMamObjectsBySlugName;
    }
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: GraphQL Query Error: getMamObjects`);
    return null;
  } catch (error) {
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: getRadioTags Error: ${error}`);
    return null;
  }
};

export default getMamObjectsBySlugNameQuery;
