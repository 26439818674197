import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useAuth } from '@primaveralabs/auth';

import translateObj from '../../../../../util/translateObj';
import { useHistory } from 'react-router-dom';
import { useRoutes } from '../../../../../context/routesContext';

const ProfileLogOutButton = () => {
  const { actions } = useAuth();
  const history = useHistory();
  const { language } = useRoutes();

  return (
    <div className="w-full bg-white py-6 px-8 mb-4">
      <div className="flex flex-row justify-end self-center items-center border-0 border-black">
        <div className="sm:hidden text-black px-8 font-americaMonoRegular uppercase">
          {translateObj(
            {
              en: 'Close your session with Primavera Sound »',
              es: 'Cierra tu sesión en Primavera Sound »',
              ca: 'Tanca la teva sessió amb Primavera Sound »',
              pt: 'Close your session with Primavera Sound »',
            },
            language
          )}
        </div>
        <button
          type="button"
          onClick={() => {
            actions.logout().then(() => {
              localStorage.removeItem('auth-token');
              history?.push(`/${language}`);
            });
          }}
          className="bg-red h-12 w-1/4 sm:w-full md:w-1/2 text-white rounded-full py-2 font-americaMonoBold uppercase"
        >
          LOGOUT
        </button>
      </div>
    </div>
  );
};

ProfileLogOutButton.propTypes = {
  language: PropTypes.string,
  history: PropTypes.shape().isRequired,
  sourceWeb: PropTypes.string,
};

ProfileLogOutButton.defaultProps = {
  language: 'en',
  sourceWeb: '',
};
export default withRouter(ProfileLogOutButton);
