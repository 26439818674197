import React from 'react';
import PropTypes from 'prop-types';

import { useRoutes } from '../../../context/routesContext';
import { getCountryFromCode } from '../../../util/getCountryNamesByCode';
import { BUSINESS_OPTIONS } from './util/optionValues';

import SquareImage from './SquareImage';
import SocialLogos from './SocialLogos';
import CtaUnderline from './CtaUnderline';
import CloseButtonAnimated from './CloseButtonAnimated';
import translateObj from '../../../util/translateObj';

const formatSocialLogos = (twitter, facebook, instagram, linkedin) => {
  const social = [];
  if (twitter) social.push({ key: 'twitter', link: twitter });
  if (facebook) social.push({ key: 'facebook', link: facebook });
  if (instagram) social.push({ key: 'instagram', link: instagram });
  if (linkedin) social.push({ key: 'linkedin', link: linkedin });
  return social;
};

const ProfilePreview = ({
  description,
  name,
  lastname,
  nickname,
  country,
  business,
  company,
  website,
  twitter,
  facebook,
  instagram,
  linkedin,
  image,
  closeCallback,
}) => {
  const { language } = useRoutes();
  const countryISOCode = getCountryFromCode(country, language);
  const businessTranslations = BUSINESS_OPTIONS.find(o => o.value === business)?.name;

  const social = formatSocialLogos(twitter, facebook, instagram, linkedin);

  return (
    <div className="text-white w-screen h-screen relative">
      <div className="pin-t pin-r flex items-center justify-end">
        <CloseButtonAnimated closeCallback={() => closeCallback()} />
      </div>
      <div className="px-2/12 md:px-1/12 sm:px-4 items-start flex sm:flex-col py-32 md:py-24 sm:py-20">
        <div className="w-1/2 md:w-1/3 flex flex-col justify-start sm:w-full">
          <div className="flex flex-col sm:flex-row">
            <div className="w-1/2 md:w-full md:pr-12 sm:mb-2">
              <SquareImage
                image={image}
                alt={nickname}
                dimensions="600x600"
              />
            </div>
            <div className="w-3/4 sm:w-1/2 sm:flex sm:flex-col sm:justify-between sm:mb-8 sm:pl-1/12">
              <div className="flex justify-start items-center text-white sm:w-full my-5">
                <div className="font-americaBlack text-50 md:text-30 sm:text-20 leading-none">
                  {`${name} ${lastname}`}
                </div>
              </div>
              <div className="font-timesRegular text-14 flex flex-row text-white flex-wrap sm:w-full">
                {business && (
                  <>
                    <span>{translateObj(businessTranslations, language)}</span>
                    <div className="text-white mx-1">|</div>
                  </>
                )}
                {company && (
                  <>
                    <span>{company}</span>
                    <div className="text-white mx-1">|</div>
                  </>
                )}
                <span>{countryISOCode}</span>
              </div>
            </div>
          </div>
          {website && <CtaUnderline text={website} link={website} color="orange" />}
          {social.length > 0 && <SocialLogos social={social} textColor="text-white" className="-ml-2 sm:-ml-1" />}
        </div>
        {description && (
          <div className="w-1/2 md:w-2/3 sm:w-full sm:mt-6">
            <p className="sm:text-14 text-base font-timesRegular">
              {description}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

ProfilePreview.propTypes = {
  description: PropTypes.string,
  website: PropTypes.string,
  image: PropTypes.string,
  name: PropTypes.string,
  lastname: PropTypes.string,
  nickname: PropTypes.string,
  country: PropTypes.string,
  business: PropTypes.string,
  company: PropTypes.string,
  twitter: PropTypes.string,
  facebook: PropTypes.string,
  instagram: PropTypes.string,
  linkedin: PropTypes.string,
  closeCallback: PropTypes.func,
};

ProfilePreview.defaultProps = {
  description: '',
  website: '',
  image: null,
  name: '',
  lastname: '',
  nickname: '',
  country: '',
  business: '',
  company: '',
  twitter: '',
  facebook: '',
  instagram: '',
  linkedin: '',
  closeCallback: null,
};

export default ProfilePreview;
