import React from 'react';
import PropTypes from 'prop-types';

import ComponentIterator from '../../../../containers/ComponentIterator';

const TopHeaderMenuContainerMusicMeets = ({ components }) => (
  <div className="flex flex-col sm:w-3/5 w-full">
    <ComponentIterator components={components} />
  </div>
);

TopHeaderMenuContainerMusicMeets.propTypes = {
  components: PropTypes.arrayOf(PropTypes.shape()),
};

TopHeaderMenuContainerMusicMeets.defaultProps = {
  components: [],
};

export default TopHeaderMenuContainerMusicMeets;
