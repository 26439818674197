import React, { Fragment } from 'react';
import { PropTypes } from 'prop-types';
import Slider from 'react-slick';
import { withRouter } from 'react-router-dom';
import Filter from './Collapsible/Filter';
import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';
import handleFilter from './Collapsible/handleFilter';
import CarouselTickets from './CarouselTickets';

const settings = {
  dots: true,
  infinite: false,
  slidesToShow: 4,
  slidesToScroll: 1,
  appendDots: dots => (
    <div
      style={{
        bottom: 10,
      }}
    >
      <ul style={{ margin: '0px' }}>{dots}</ul>
    </div>
  ),
  arrows: false,
  responsive: [
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 2,
      },
    },
  ],
};
class FilterTickets extends React.Component {
  state = {
    params: [],
    filtered: [],
    menu: {},
    isLoading: true,
  };

  componentDidMount() {
    const { params, menu } = this.props;
    this.setState({
      params,
      filtered: params,
      menu,
      isLoading: false,
    });
  }

  onHandleCollapsibleMenuSelect = (filters) => {
    const { params } = this.state;
    const filtered = handleFilter(filters, params);
    this.setState({
      filtered,
    });
  };

  handleSearchInput = (query, lang, filters) => {
    const { slugname } = this.props;
    const { params } = this.state;
    let title;
    let description;
    let filteredWithSearch;
    if (slugname === 'press-area') {
      filteredWithSearch = params.filter((item) => {
        if (item.title) {
          title = item.title[lang].toLowerCase();
        } else {
          title = '';
        }
        if (item.description) {
          description = item.description[lang].toLowerCase();
        } else {
          description = '';
        }
        return (title.indexOf(query.toLowerCase()) !== -1 || description.indexOf(query.toLowerCase()) !== -1) && item.tags.includes(filters.byMaterial[0]);
      });
    } else {
      filteredWithSearch = params.filter((item) => {
        if (item.title) {
          title = item.title[lang].toLowerCase();
        } else {
          title = '';
        }
        if (item.description) {
          description = item.description[lang].toLowerCase();
        } else {
          description = '';
        }
        return title.indexOf(query.toLowerCase()) !== -1 || description.indexOf(query.toLowerCase()) !== -1;
      });
    }
    if (query) {
      this.setState({ filtered: filteredWithSearch });
    } else {
      this.onHandleCollapsibleMenuSelect(filters);
    }
  };

  render() {
    const { match, slugname } = this.props;
    const { filtered, isLoading, menu } = this.state;
    return (
      <RoutesContext.Consumer>
        {({ language: contextLanguage, enteredFrom }) => {
          const localization = getLocalization(contextLanguage, enteredFrom, match);
          return (
            <Fragment>
              {!isLoading && (
                <div className="mx-2 w-full">
                  <Filter {...menu} onHandleCollapsibleMenuSelect={this.onHandleCollapsibleMenuSelect} handleSearchInput={this.handleSearchInput} slugname={slugname} />
                </div>
              )}
              {filtered.length ? <CarouselTickets tickets={filtered} ticketsPerLine={4} /> : <p className="w-full px-2">No content matched your criteria</p>}
            </Fragment>
          );
        }}
      </RoutesContext.Consumer>
    );
  }
}

FilterTickets.propTypes = {
  // params: PropTypes.arrayOf(PropTypes.shape({
  //   title: PropTypes.shape({
  //     en: PropTypes.string.isRequired,
  //     es: PropTypes.string.isRequired,
  //   }).isRequired,
  // })),
  // hasFilters: PropTypes.bool,
  // showFilterAs: PropTypes.string,
  // type: PropTypes.string.isRequired,
  // slugname: PropTypes.string.isRequired,
  // history: PropTypes.shape({}).isRequired,
  // menu: PropTypes.shape({}),
};

FilterTickets.defaultProps = {
  // hasFilters: false,
  // showFilterAs: 'dropdown',
  // menu: {},
  // params: [],
};

export default withRouter(FilterTickets);
