import { useEffect, useState } from 'react';
import getUserCountry from '../../../../api/graphql/queries/getUserCountry';

const useCountry = (init = true) => {
  const [country, setCountry] = useState();
  useEffect(() => {
    if (init) getUserCountry().then(res => setCountry(res.country.toLowerCase()));
  }, [init]);
  return country;
};

export default useCountry;
