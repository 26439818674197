// @ts-check

import React from 'react';
import PropTypes from 'prop-types';

import ComponentIterator from '../../../containers/ComponentIterator';


const FooterRedesignTop = ({ components }) => {
  const leftComponents = components.slice(0, components.length - 1);
  const rightComponents = components.slice(components.length - 1, components.length);

  return (
    <nav className="flex-1 flex flew-row items-stretch justify-between sm:px-4 tablet:px-12 llg:px-10 pt-15 pb-15 tablet:pb-20" style={{ background: '#626262' }}>
      <section className="flex-1 tablet:flex-none max-w-205 desktop:max-w-auto">
        <ComponentIterator components={leftComponents} />
      </section>
      <div className="w-px mx-6 bg-white opacity-25 flex-no-shrink" />
      <section className="flex-none llg:flex-1">
        <ComponentIterator components={rightComponents} />
      </section>
    </nav>
  );
};


FooterRedesignTop.propTypes = {
  components: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default FooterRedesignTop;
