import React from 'react';
import PropTypes from 'prop-types';
import ComponentIterator from '../../../../containers/ComponentIterator';

const TopHeaderMainMenuMusicMeets = ({ components }) => (
  <div className="w-full h-full flex items-stretch justify-end py-4 sm:py-2">
    <div className="w-full flex items-center justify-end pr-4 sm:pr-16">
      <ComponentIterator components={components} />
    </div>
  </div>
);

TopHeaderMainMenuMusicMeets.propTypes = {
  components: PropTypes.arrayOf(PropTypes.shape()),
};

TopHeaderMainMenuMusicMeets.defaultProps = {
  components: [],
};

export default TopHeaderMainMenuMusicMeets;
