import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Panel from '@primaveralabs/ui-panel';
import Badge from '@primaveralabs/ui-badge';
import 'Primavera/ui-panel/dist/primavera.css';
import 'Primavera/ui-badge/dist/primavera.css';
import 'Primavera/ui-themes/dist/primavera/index.css';
import { useRoutes } from '../../../context/routesContext';
import getPostsBySlugNameQuery from '../../../api/graphql/queries/getPostsBySlugNameQuery';
import imageModifier from '../helpers/imageModifier';
import translateObj from '../../../util/translateObj';
import LoadIcon from '../LoadIcon';
import RewardDrawer from './RewardDrawer/RewardDrawer';
import translation from './translations/common';
import useResponsiveUtility from '../../../util/responsiveUtility';

const RewardsBoard = ({
  loyalty,
  title,
  viewMore,
  noPrizes,
  redeemedPrize,
  boughtOn,
  descriptionTitle,
  redeemButton,
}) => {
  const { language } = useRoutes();
  const { deviceScreen } = useResponsiveUtility()
  const [loading, setLoading] = useState(false);
  const [rewards, setRewards] = useState([]);
  const [reward, setReward] = useState(undefined);

  const loadData = () => {
    setLoading(true);
    const slugNames = loyalty?.prizes?.map((i) => i.slugName);
    getPostsBySlugNameQuery(slugNames)
      .then((res) => {
        if (res) {
          const prizes = res.map((prize) => {
            const item = loyalty?.prizes.find(
              (i) => i.slugName === prize.slugName,
            )
            const code = item?.code;
            const link = item?.link;
            const claimedAt = item?.claimedAt;
            return { ...prize, code, link, claimedAt };
          });
          setRewards(prizes);
        }
      })
      .catch((err) => console.log(err));
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  const gridStyle = {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '1fr',
    gap: '1.2rem 1rem',
    alignItems: 'center',
    justifyItems: 'center',
  };

  return (
    <>
      <div style={{ gridArea: 'rewards' }} className="h-full">
        <Panel
          display={loading ? 'center' : 'auto'}
          title={translateObj(title, language)}
          ctaLabel={translateObj(viewMore, language)}
          ctaLink={`/${language}/user-panel/rewards`}
          noChildrenMessage={translateObj(noPrizes, language)}
          scroll
        >
          {loading ? (
            <LoadIcon />
          ) : (
            rewards?.length > 0 && (
              <div style={gridStyle}>
                {rewards?.slice(0, 2).map((badge) => (
                  <Badge
                    key={`${badge.slugName}-${badge.createdAt}`}
                    image={imageModifier(
                      translateObj(badge.postDescription?.image, language),
                    )}
                    title={translateObj(badge.postDescription?.title, language)}
                    description={translateObj(
                      badge.postDescription?.description,
                      language,
                    )}
                    // REMOVED TEMPORARILY
                    // sponsor={{
                    //   name: translateObj(
                    //     badge.postDescription?.subtitle,
                    //     language
                    //   ),
                    //   logo:
                    //     'logo: https://assets.primaverasound.com/psweb/oaxqqdrdq6cpk04xrxj0_1685096047098.png',
                    // }}
                    intent="reward"
                    maskImage="https://assets.primaverasound.com/psweb/dgxbu8t6ztuzw1p4pfx1_1681669022673.png" // TODO: local image?
                    onClick={() => setReward(badge)}
                    redeemed={badge.claimedAt}
                    tagLabel={translateObj(redeemedPrize, language)}
                    width={deviceScreen === "xxl" ? "80%" : "100%"}
                  />
                ))}
              </div>
            )
          )}
        </Panel>
      </div>
      {reward && (
        <RewardDrawer
          reward={reward}
          setReward={setReward}
          redeemButton={redeemButton}
          redeemedPrize={redeemedPrize}
          boughtOn={boughtOn}
          descriptionTitle={descriptionTitle}
        />
      )}
    </>
  );
};

RewardsBoard.propTypes = {
  loyalty: PropTypes.shape({
    achievements: PropTypes.arrayOf(PropTypes.shape()),
    loyaltyPoints: PropTypes.number,
    prizes: PropTypes.arrayOf(PropTypes.shape()),
  }),
  title: PropTypes.shape({}),
  viewMore: PropTypes.shape({}),
  noPrizes: PropTypes.shape({}),
  redeemedPrize: PropTypes.shape({}),
  boughtOn: PropTypes.shape({}),
  descriptionTitle: PropTypes.shape({}),
  redeemButton: PropTypes.shape({}),
};

RewardsBoard.defaultProps = {
  loyalty: {
    achievements: [],
    loyaltyPoints: 0,
    prizes: [],
  },
  title: translation.myRewards,
  viewMore: translation.viewMore,
  noPrizes: translation.noPrizes,
  redeemedPrize: translation.redeemedPrize,
  boughtOn: translation.boughtOn,
  descriptionTitle: translation.descriptionTitle,
  redeemButton: translation.redeemButton,
};

export default RewardsBoard;
