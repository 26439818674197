import React from 'react';
import { PropTypes } from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import Filter from '../Collapsible/Filter';
import getRadioShowsEpisodes from '../../../api/graphql/queries/getRadioShowsEpisodes';
import getRadioShows from '../../../api/graphql/queries/getRadioShows';
import RadioEpisodeCard from './RadioEpisodeCard';
import RadioEpisodeListItem from './RadioEpisodeListItem';
import TitleLineH1 from '../Text/TitleLineH1';
import translateObj from '../../../util/translateObj';
import RadioTag from '../Buttons/RadioTag';
import RadioItemType from '../RadioItemType';
import ViewToggler from './ViewToggler';
import withAllContexts from '../../../context/withAllContexts';

class RadioEpisodesList extends React.Component {
  state = {
    params: [],
    filtered: [],
    menu: {},
    activeView: '',
    radioShowData: null,
    offset: 0,
    noLoadMore: false,
    status: 'loaded',
    isLoading: true,
  };

  componentWillMount = () => {
    const { radioShowsEpisodesContext } = this.props;
    if (radioShowsEpisodesContext.radioShowsEpisodesData) {
      this.processGetRadioShowsEpisodes(radioShowsEpisodesContext.radioShowsEpisodesData);
      radioShowsEpisodesContext.clear('radioShowsEpisodesData');
    } else {
      this.fetchRadioEpisodes();
    }
  }

  componentDidMount() {
    const { activeView } = this.props;
    this.setState({
      activeView,
    });
    this.initQueries();
  }

  initQueries = async () => {
    const {
      limit, match,
    } = this.props;
    const query = { limit };
    if (match.params.post) {
      query.parentSlug = match.params.post;
    }
    if (query.parentSlug !== undefined) {
      await getRadioShows(query)
        .then((radioShowData) => {
          this.setState({
            radioShowData: radioShowData[0],
          });
        });
    }
    // this.fetchRadioEpisodes();
  }

  fetchRadioEpisodes = () => {
    const { limit, channel, parentSlug } = this.props;
    const { offset } = this.state;
    const query = { limit, offset, type: 'episodes' };
    if (channel) query.channel = channel;
    if (parentSlug) query.parentSlug = parentSlug;
    getRadioShows(query)
      .then((episodes) => {
        if (episodes) {
          if (episodes.length) {
            this.processGetRadioShowsEpisodes(episodes);
          } else {
            this.setState({
              status: 'end',
            });
          }
        } else {
          this.setState({
            status: 'failed',
          });
        }
      });
  }

  onHandleClick = () => {
    const { offset } = this.state;
    const { limit } = this.props;
    const newOffset = offset + limit;

    this.setState({
      offset: newOffset,
    }, () => {
      this.fetchRadioEpisodes();
    });
  }

  updateView = (view, type) => {
    this.setState({ activeView: view });
  }

  processGetRadioShowsEpisodes = (episodes) => {
    const { menu } = this.props;

    this.setState(prevState => ({
      params: [...prevState.params, ...episodes],
      filtered: [...prevState.params, ...episodes],
      menu,
      status: 'loaded',
      isLoading: false,
    }));
  }

  onHandleCollapsibleMenuSelect = (filters) => {
    this.filterTag(filters);
  };

  filterTag = (filters) => {
    const { params } = this.state;
    let filterByTag = [];
    if (filters.byTag && filters.byTag.length) {
      params.forEach((param) => {
        if (filters.byTag.includes(param.tags_multilang.en)) {
          filterByTag.push(param);
        }
      });
    } else {
      filterByTag = params;
    }
    this.setState({
      filtered: filterByTag,
    });
  };

  handleSearchInput = (query, lang, filters) => {
    const { params } = this.state;
    let title;
    let description;
    const filteredWithSearch = params.filter((item) => {
      if (item.title_multilang[lang] && Object.keys(item.title_multilang).length) {
        title = item.title_multilang[lang].toLowerCase();
      } else {
        title = '';
      }
      if (item.text_multilang[lang] && Object.keys(item.text_multilang).length) {
        description = item.text_multilang[lang].toLowerCase();
      } else {
        description = '';
      }
      return title.indexOf(query.toLowerCase()) !== -1 || description.indexOf(query.toLowerCase()) !== -1;
    });
    if (query) {
      this.setState({ filtered: filteredWithSearch });
    } else {
      this.onHandleCollapsibleMenuSelect(filters);
    }
  };

  renderRadioEpisodes = () => {
    const { filtered, activeView } = this.state;
    if (activeView === 'grid') {
      return filtered.map(show => (
        <div key={show.id} className="sm:w-full md:w-1/2 smlg:w-1/4 lg:w-1/3 xl:w-1/4 py-2 px-2 sm:px-0">
          <RadioEpisodeCard
            image={show.image}
            title_multilang={show.title_multilang}
            tags_multilang={show.tags_multilang}
            playing={false}
            mixcloud={show.mixcloud}
            favouriteStar={false}
            text_multilang={show.text_multilang}
            categories_multilang={show.categories_multilang}
            isPlayList={show.isPlaylist}
            tags_array_multilang={show.tags_array_multilang}
            slug={show.slug}
            parentSlug={show.parentSlug}
            lang={show.lang}
            dateAir={show.dateAir}
            program_title_multilang={show.program_title_multilang}
          />
        </div>
      ));
    }
    return (
      <div className="pt-2 w-full">
        {filtered.map(show => (
          <RadioEpisodeListItem
            image={show.image}
            key={show.id}
            title_multilang={show.title_multilang}
            tags_multilang={show.tags_multilang}
            playing={false}
            mixcloud={show.mixcloud}
            favouriteStar={false}
            text_multilang={show.text_multilang}
            categories_multilang={show.categories_multilang}
            isPlayList={show.isPlaylist}
            tags_array_multilang={show.tags_array_multilang}
            slug={show.slug}
            parentSlug={show.parentSlug}
            lang={show.lang}
            dateAir={show.dateAir}
            program_title_multilang={show.program_title_multilang}
          />
        ))}
      </div>
    );
  };

  render() {
    const { hasFilters, showToggleView } = this.props;
    const {
      filtered, isLoading, menu, activeView, radioShowData, noLoadMore, status,
    } = this.state;

    return (
      <React.Fragment>
        {!isLoading && hasFilters && <Filter {...menu} onHandleCollapsibleMenuSelect={this.onHandleCollapsibleMenuSelect} handleSearchInput={this.handleSearchInput} />}
        {radioShowData && !isLoading
          ? (
            <>
              <RadioItemType title={radioShowData.categories_multilang} category="black" />
              <TitleLineH1 text={radioShowData.title_multilang} textColor="#000000" margin="mt-4 mb-2" />
              <div className="flex flex-wrap mb-6">{radioShowData.tags_array_multilang.map((tag, i) => tag.en && <RadioTag category="transparent" text={tag} textColor="black" borderColor="black" margin="py-0 font-americaMonoRegular" />)}</div>
            </>
          )
          : null}
        {showToggleView && <ViewToggler handleUpdateView={this.updateView} activeView={activeView} type="episode" />}
        <div className={`flex flex-wrap ${activeView === 'grid' ? 'md:-mx-2 lg:-mx-2 xl:-mx-2' : 'w-full'} mt-2`}>{filtered.length ? this.renderRadioEpisodes() : null}</div>
        {!noLoadMore
        && (
        <div className="py-2 w-full flex justify-center mt-2" onClick={this.onHandleClick}>
          <span className="font-americaMonoBold text-black text-center text-14 uppercase border-b-2 border-black py-1">
            {status === 'loading' && 'Loading...'}
            {status === 'loaded' && (
            <span>
              <span className="font-icon icon-arrow-down text-12 bold" />
              <span className="px-4 cursor-pointer">Load More</span>
              <span className="font-icon icon-arrow-down text-12 bold" />
            </span>
            )}
            {status === 'end' && 'No more items available'}
            {status === 'failed' && 'Internet connection required'}
          </span>
        </div>
        )
        }
      </React.Fragment>
    );
  }
}

RadioEpisodesList.propTypes = {
  hasFilters: PropTypes.bool,
  menu: PropTypes.shape({}),
  limit: PropTypes.number,
  parentSlug: PropTypes.string,
  radioShowsEpisodesContext: PropTypes.shape().isRequired,
  match: PropTypes.shape().isRequired,
  showToggleView: PropTypes.bool,
  activeView: PropTypes.string,
  channel: PropTypes.number,
};

RadioEpisodesList.defaultProps = {
  hasFilters: false,
  showToggleView: false,
  menu: {},
  limit: 20,
  parentSlug: null,
  activeView: 'grid',
  channel: null,
};

export default withRouter(withAllContexts(RadioEpisodesList));
