// @ts-check

import React from 'react';
import PropTypes from 'prop-types';
import Link from '../../../util/link';
import RoutesContext from '../../../context/routesContext';
import translateObj from '../../../util/translateObj';

const FooterRedesignLinks = ({ name, links, parent }) => (
  <RoutesContext.Consumer>
    {({ language }) => (
      <nav key={name} className={`flex-col text-xxs opacity-75 ${parent === 'FooterRedesignBottom' ? 'hidden tablet:flex' : 'flex tablet:hidden'}`}>
        {links && links.map((link, index) => (
          <span className={`${index + 1 === links.length ? '' : 'mb-2'}`} key={link.key}>
            <Link to={link.link} localizationpath={`/${language}`} className="text-white" aria-label={translateObj(link.text, language)}>
              {translateObj(link.text, language)}
            </Link>
          </span>
        ))}
      </nav>
    )}
  </RoutesContext.Consumer>
);

FooterRedesignLinks.propTypes = {
  name: PropTypes.string.isRequired,
  parent: PropTypes.oneOf(['FooterRedesignTop', 'FooterRedesignBottom']).isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      text: PropTypes.shape({
        es: PropTypes.string.isRequired,
        en: PropTypes.string.isRequired,
        ca: PropTypes.string.isRequired,
      }).isRequired,
    }),
  ).isRequired,
};

export default FooterRedesignLinks;
