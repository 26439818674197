import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import userPasswordRecoverUserSendsEmail from '../../api/graphql/mutations/userPasswordRecoverUserSendsEmail';

// import getLocalization from '../../util/localization';
// import RoutesContext from '../../context/routesContext';
import { useRoutes } from '../../context/routesContext';
import translateObj from '../../util/translateObj';

const RecoverPassword = ({
  text, userTitle, submitTitle, history, match, errorsText: { errorUserDoesNotExist, errorUserLoggedWithSocialNetwork }, afterSubmitedText, sourceWeb,
}) => {
  const { language = 'en' } = useRoutes(match);
  const [email, setEmail] = useState('');
  const [isPasswordRecoveryEmailSubmited, setIsPasswordRecoveryEmailSubmited] = useState(false);
  const [error, setError] = useState('');

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  useEffect(() => {
    if (!email && error) {
      setError('');
    }
  }, [email]);

  useEffect(() => {
    if (isPasswordRecoveryEmailSubmited) {
      const redirectTo = sourceWeb ? `/${language}/${sourceWeb}` : '/';
      setTimeout(() => {
        history.push(redirectTo);
      }, 2500);
    }
  }, [isPasswordRecoveryEmailSubmited]);

  const sendRecoveryEmail = (lang) => {
    if (email) {
      userPasswordRecoverUserSendsEmail({ email, sourceWeb }).then((res) => {
        if (res) {
          if (res.isValid) { setIsPasswordRecoveryEmailSubmited(true); } else if (res.error) {
            switch (res.error) {
              case 'noUserFound':
                setError(translateObj(errorUserDoesNotExist, lang));
                break;
              case 'userLoggedWithSocialNetwork':
                setError(translateObj(errorUserLoggedWithSocialNetwork, lang));
                break;
              case 'emailNotSent':
                setError('E-mail was not sent');
                break;
              case 'userCouldNotBeUpdatedinDB':
                setError('Please contact your administrator');
                break;

              default:
                setError('Please contact your administrator');
                break;
            }
          } else {
            console.log('no error was received and res is not valid, please contact your administrator');
          }
        } else {
          console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Error - recover password failed to submit!`);
        }
      });
    } else {
      setError('Incorrect Email');
    }
  };

  const validateIfEmailIsCorrect = () => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isEmailCorrect = re.test(String(email).toLowerCase());
    const err = !isEmailCorrect ? 'Please verify your email address' : '';
    setError(err);
  };

  return (
    <div>
      {!isPasswordRecoveryEmailSubmited ? (
        <div className="flex flex-wrap bg-white">

          <div className={`${'w-full p-8 pt-0'}`}>
            <div className="flex border-b-1 border-black border-dotted py-6">
              <p className="font-americaMonoBold m-0">
                {translateObj(text, language)}
              </p>
            </div>
            <div className="flex w-full py-8 sm:w-full flex-wrap flex-col">
              <label className="flex-col pr-6 font-americaMonoRegular text-12 sm:pr-0 sm:w-full">
                {translateObj(userTitle, language)}
              </label>
              <div className="flex-row justify-between mt-4">
                <div className="inline-block sm:w-full">
                  <div className="flex flex-col">
                    <input type="text" name="name" onChange={handleChange} className="px-6 mr-4 sm:mr-0 font-americaMonoRegular text-12 rounded-full border-1 p-4 bg-inherit border-black text-black text-12 sm:w-92 sm:w-full xl:w-92 lg:w-92 md:w-92" value={email} onBlur={validateIfEmailIsCorrect} />
                  </div>
                </div>
                <button
                  onClick={() => sendRecoveryEmail(language)}
                  type="submit"
                  disabled={error || !email}
                  className={` rounded-full border-1 bg-inherit ${error || !email ? 'bg-gray200 text-black border-transparent' : 'bg-black text-white border-1 border-black'}   text-12 font-americaMonoBold sm:static sm:mt-6 sm:mx-0 sm:w-full cursor-pointer  text-center xl:w-s15 lg:w-s15 sm:w-full xl:max-w-xs md:w-s15 lg:max-w-xs`}
                  style={{
                    minHeight: '3rem',
                  }}
                >
                  {translateObj(submitTitle, language)}
                </button>
              </div>
              <span className="text-red mt-4">{error}</span>

            </div>
            <div />
          </div>
        </div>
      )
        : <div className="font-americaMonoBold uppercase">{translateObj(afterSubmitedText, language) || 'please check your email in order to reset your password'}</div>}
    </div>
  );
};

RecoverPassword.propTypes = {
  match: PropTypes.shape().isRequired,
  text: PropTypes.shape({}),
  userTitle: PropTypes.shape({}),
  submitTitle: PropTypes.shape({}),
  sourceWeb: PropTypes.string,
};

RecoverPassword.defaultProps = {
  text: null,
  userTitle: null,
  submitTitle: null,
  sourceWeb: '',

};

export default withRouter(RecoverPassword);
