import React from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';
import ParagraphLineSM from '../Text/ParagraphLineSM';
import translateObj from '../../../util/translateObj';

const listText = {
  en: 'Listado',
  ca: 'Listado',
  es: 'Listado',
};
const gridText = {
  en: 'Parrilla',
  ca: 'Parrilla',
  es: 'Parrilla',
};
const ViewButton = ({
  title,
  icon,
  view,
  activeView,
  type,
  cb,
}) => (
  <div onClick={() => cb(view, type)} className={`cursor-pointer flex h-8 sm:h-10 items-center rounded-lg p-2 border-solid border-white border-1 ${activeView === view ? 'bg-white text-black' : ''}`}>
    <Checkbox1 view={view} activeView={activeView} />
    {icon ? <span className={`ml-1 text-sm font-icon ${icon} ${activeView === view ? 'text-black' : 'text-white '}`} /> : null}
    <span className={`pl-1 ${activeView === view ? 'text-black' : 'text-white '}`}>{title}</span>
  </div>
);

ViewButton.propTypes = {
  activeView: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
  view: PropTypes.string.isRequired,
  cb: PropTypes.func,
};
ViewButton.defaultProps = {
  type: null,
  cb: () => {},
};

const Checkbox1 = ({
  view, activeView,
}) => (
  <div className="cursor-pointer w-4 h-4 rounded-full bg-black border-solid border-white border-1 flex items-center justify-center">{activeView === view ? <div className="w-2 h-2 bg-radio rounded-full" /> : null}</div>
);
const ViewToggler = ({
  match, handleUpdateView, activeView, type,
}) => (
  <RoutesContext.Consumer>
    {({ language: contextLanguage, enteredFrom }) => {
      const localization = getLocalization(contextLanguage, enteredFrom, match);

      return (
        <div className="mb-4 w-full">
          <div className="bg-black w-full flex flex-wrap justify-between items-center p-4">
            <ParagraphLineSM classes="uppercase font-bold text-xxs text-white m-0 sm:mb-2" text={{ en: 'Visualization options', es: 'Opciones de visualización', ca: 'Opcions de visualització' }} />
            <div className="flex flex-wrap">
              <div className="mr-3">
                <ViewButton view="list" title={translateObj(listText, localization.language)} icon="icon-burger" cb={handleUpdateView} activeView={activeView} type={type} />
              </div>
              <div className="">
                <ViewButton view="grid" title={translateObj(gridText, localization.language)} icon="icon-grid" cb={handleUpdateView} activeView={activeView} type={type} />
              </div>
            </div>
          </div>
        </div>
      );
    }}
  </RoutesContext.Consumer>
);

ViewToggler.propTypes = {
  handleUpdateView: PropTypes.func.isRequired,
  activeView: PropTypes.string.isRequired,
  match: PropTypes.shape().isRequired,
  type: PropTypes.string,
};

ViewToggler.defaultProps = {
  type: null,
};

export default withRouter(ViewToggler);
