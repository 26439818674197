import translation from './__translationHelper';
import header from './_header';
import footer from './_footer';
import reunions from './_reunionItem_array';

const data = [
  { ...header },
  {
    type: 'ReunionInvitationSent',
    reunion: {
      type: 'ReunionItem',
      key: 'reunion-1',
      id: '9876543210',
      title: translation('El Maresme Metal Sound 2021'),
      date: '1643376417',
      location: null, // if reunion is not online
      description: translation('Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam sed lorem dui. Duis in ipsum est. Proin ac ligula lorem. Duis quis luctus odio, semper congue orci. Suspendisse in posuere diam.'),
      url: '/', // url to online reunion
      online: true,
      status: 'pending',
      createdBy: {
        _id: '6177d42c7c0cec000901a770',
        name: 'Bruno',
        lastname: 'Mesquita',
        occupation: translation('Artist'),
        company: 'Sony Music',
        slugName: 'bruno-capella', // url to member detail page ?
      },
      participants: [
        {
          _id: '6177d42c7c0cec000901a771',
          name: 'Cinta',
          lastname: 'Mesquita',
          occupation: translation('Singer'),
          company: 'Vasava Records',
          slugName: 'cinta-mesquita', // url to member detail page ?
          status: 'pending', // pending | confirmed
        },
        {
          _id: '6177d42c7c0cec000901a772',
          name: 'Edgar',
          lastname: 'Hosta',
          occupation: translation('Artist'),
          company: 'Apple Music',
          slugName: 'edgar-hosta', // url to member detail page ?
          status: 'pending', // pending | confirmed
        },
      ],
      isListItem: false, // set to "true" when item is inside "ItemsList" component
    },
  },
  {
    type: 'NextReunions',
    components: reunions,
  },
  { ...footer },
];

export default data;
