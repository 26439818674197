import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import { useHistory, useLocation } from 'react-router-dom';
import { withStyles } from '@material-ui/core';

import CollapsibleListItem from './CollapsibleListItem';
import slugify from '../../../util/slugify';

const style = {
  list: {
    padding: 0,
    marginBottom: 0,
  },
  root: {
    backgroundColor: 'black',
    height: '2px',
  },
};

const CollapsibleList = (props) => {
  const { firstClosed, components, classes, bgWhite } = props;
  const [dropDown, setDropdown] = useState('');
  const [values, setValues] = useState([]);
  const [titles, setTitles] = useState([]);
  const [categories, setCategories] = useState([]);

  const history = useHistory();
  const localization = useLocation();

  const { pathname, search } = localization;

  const getQueryVariable = () => {
    if (search) {
      const filters = search.split('=');
      if (filters.length > 1) {
        const vars = filters[1].split(',');
        return vars;
      }
    }
    return null;
  };

  const pushToPath = (clickedEvent) => {
    const eventIndex = titles.indexOf(clickedEvent);
    const hashedEvent = values[eventIndex];
    const currentCat = categories[eventIndex];
    const query = getQueryVariable();

    let filters = query || [];
    if (query && (query.includes(hashedEvent))) {
      filters = query.filter(val => val !== hashedEvent);
    }
    if (query && (query.includes(currentCat))) {
      filters = query.filter(val => val !== currentCat);
    }
    if (!query && clickedEvent) {
      filters.push(hashedEvent);
    }
    if (query && clickedEvent && !query.includes(hashedEvent) && !query.includes(currentCat)) {
      filters = [hashedEvent];
    }
    if (!filters.length) {
      history.replace(`${pathname}`);
    } else {
      history.replace(`${pathname}?e=${filters.join(',')}`);
    }
  };

  const handleClick = (type) => {
    setDropdown(type !== dropDown ? type : '');
    pushToPath(type);
  };

  const updatePath = () => {
    const query = getQueryVariable();
    if (query && values.find(val => query.includes(val))) {
      const activeValue = values.find(val => query.includes(val));
      const activeIndex = values.indexOf(activeValue);
      if (activeIndex > -1) {
        setDropdown(titles[activeIndex]);
      }
    } else if (query && categories.find(val => query.includes(val))) {
      const activeValue = categories.find(val => query.includes(val));
      const activeIndex = categories.indexOf(activeValue);
      if (activeIndex > -1) {
        setDropdown(titles[activeIndex]);
      }
    } else {
      setDropdown('');
    }
  };

  useEffect(() => {
    const newValues = components.map(comp => slugify(comp.text.en));
    const newTitles = components.map(comp => comp.text.en);
    const newCategories = components.map(comp => comp.button.category.replace(' ', '-'));
    let newDropDown = '';
    const query = getQueryVariable();
    if (!firstClosed) {
      newDropDown = components[0].text.en;
    } else {
      if (query && newValues.find(val => query.includes(val))) {
        const activeValue = newValues.find(val => query.includes(val));
        const activeIndex = newValues.indexOf(activeValue);
        if (activeIndex > -1) {
          newDropDown = newTitles[activeIndex];
        }
      } else if (query && newCategories.find(cat => query.includes(cat))) {
        const activeCat = newCategories.find(val => query.includes(val));
        const activeIndex = newCategories.indexOf(activeCat);
        if (activeIndex > -1) {
          newDropDown = newTitles[activeIndex];
        }
      }
    }
    setDropdown(newDropDown);
    setValues(newValues);
    setTitles(newTitles);
    setCategories(newCategories);
  }, []);

  useEffect(() => {
    if (dropDown) updatePath();
  }, [dropDown, search]);

  return (
    <div className="my-4 w-full">
      <List classes={{ root: classes.list }} style={{ background: `${bgWhite ? 'white' : ''}` }}>
        {components.map((comp, i) => (
          <CollapsibleListItem key={comp.text.en + i} {...props} comp={comp} dropDown={dropDown} handleClick={handleClick} />
        ))}
      </List>
    </div>
  );
};

CollapsibleList.propTypes = {
  classes: PropTypes.shape({}),
  components: PropTypes.arrayOf(PropTypes.shape({})),
  border: PropTypes.bool,
  lowercase: PropTypes.bool,
  firstClosed: PropTypes.bool,
  noLink: PropTypes.bool,
  hasFilter: PropTypes.bool,
};

CollapsibleList.defaultProps = {
  border: false,
  lowercase: false,
  firstClosed: true,
  noLink: true,
  components: null,
  classes: '',
  hasFilter: false,
};

export default withStyles(style)(CollapsibleList);
