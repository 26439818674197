import gql from 'graphql-tag';

import { clientWeb } from '../graphqlAPI';

const ADD_USER_IMAGE = gql`
  mutation Post($assetSlugName: String!, $fileReadableName: ObjectLocalizationInput, $filename: String, $fileExtension: String, $url: String, $description: ObjectLocalizationInput, $type: String, $tags: [String], $isPublic: Boolean) {
    addUserImage(
      assetSlugName: $assetSlugName,
      fileReadableName: $fileReadableName,
      filename: $filename,
      fileExtension: $fileExtension,
      url: $url,
      description: $description,
      type: $type,
      tags: $tags,
      isPublic: $isPublic
      ){
        filename
        fileReadableName {
          en
        }
        fileExtension
        tags
        type
        url
        assetSlugName
        description {
          en
        }
        isPublic
        createdAt
        updatedAt
      }
}`;

const addUserImage = async (formData) => {
  try {
    const result = await clientWeb.mutate({
      mutation: ADD_USER_IMAGE,
      variables: {
        ...formData,
      },
      fetchPolicy: 'no-cache',
    })
      .then(queryResult => queryResult)
      .catch((queryError) => {
        console.log('\x1b[31m%s\x1b[0m',
          `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error: ${queryError}`);
        return null;
      });

    const { data: { addUserImage: validatedFormData } } = result;

    if (validatedFormData) {
      return validatedFormData;
    }
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: GraphQL Query Error: validatedFormData = null`);
    return null;
  } catch (error) {
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: addUserImage Error: ${error}`);
    return null;
  }
};

export default addUserImage;
