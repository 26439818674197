import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import ShareMedia from './ShareMedia';
import ComponentIterator from '../../containers/ComponentIterator';
import getPostsBySlugNameQuery from '../../api/graphql/queries/getPostsBySlugNameQuery';
import getPostsList from '../../api/graphql/queries/getPostsList';
import PSResponsiveUtility from './LineUp/PSResponsiveUtility';
import Separator from './Separator';
import SliderBannerPD from './Sliders/SliderBannerPD';

class SinglePostPage extends Component {
  state = {
    topComponents: null,
    bottomComponents: null,
    deviceScreen: null,
    isLoading: true,
    postDescription: {},
    restOfComponents: [],
    updatedComponents: [],
  };

  responsiveUtility = React.createRef();

  _mounted = false;

  componentDidMount() {
    this._mounted = true;

    const { components } = this.props;
    const hasCarousel = components.length && components[0].type === 'Carousel';
    if (hasCarousel) {
      const restOfComponents = [...components];
      restOfComponents.splice(0, 1);
      this.setState({ restOfComponents });
    }

    const {
      match: { params },
    } = this.props;
    let newsSlug;
    if (params.post) {
      newsSlug = params.post;
    } else {
      newsSlug = params.section;
    }
    this.fetchPostDrescriptionsBySlugnames([newsSlug]);
    this.getRelatedNews(newsSlug, params.section);
  }

  componentDidUpdate(prevProps) {
    const {
      match: { params },
    } = this.props;
    const prevParams = prevProps.match.params;
    console.log(params.post, prevParams.post, 'post');
    console.log(params.section, prevParams.section, 'section');
    if (
      prevParams.section !== params.section
      || prevParams.post !== params.post
    ) {
      console.log('enter here postt page');
      //  this.getRelatedNews();
      let newsSlug = null;
      if (params.post) {
        newsSlug = params.post;
      } else {
        newsSlug = params.section;
      }
      console.log(newsSlug, 'newsslug');
      if (newsSlug && this._mounted) {
        this.getRelatedNews(newsSlug, params.section);
        this.fetchPostDrescriptionsBySlugnames([newsSlug]);
      }
    }
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  fetchPostDrescriptionsBySlugnames = (slugnames) => {
    getPostsBySlugNameQuery(slugnames)
      .then((res) => {
        if (res) {
          const { postDescription } = res[0];
          if (this._mounted) {
            this.setState({
              postDescription,
            });
            this.displayDate();
          }
        }
      })
      .catch((err) => {
        console.log(
          'Some error happened on fetch of fetchPostDescription:',
          err,
        );
      });
  };

  getRelatedNews = (slug, section) => {
    const {
      match: { url },
    } = this.props;
    const postCategory = ['news'];
    if (slug !== section) {
      postCategory.push(section);
    }
    if (slug) {
      getPostsList(postCategory, 0, 3).then((postsList) => {
        if (postsList && postsList.length) {
          const relatedPosts = postsList.filter(post => post.slugName !== slug);
          if (this._mounted) {
            this.setState({
              bottomComponents: [
                {
                  type: 'Separator',
                  borderWidth: 3,
                },
                {
                  type: 'SidebarRelatedNews',
                  data: relatedPosts,
                },
              ],
            });
          }
        }
      });
    }
  };

  handleResize = () => {
    const deviceScreen = this.responsiveUtility.current.deviceScreen(true);
    this.setState({
      deviceScreen,
      isLoading: false,
    });
  };

  displayDate = () => {
    const { components } = this.props;
    const { postDescription } = this.state;
    const updatedComponents = [...components];
    if (updatedComponents.length && updatedComponents[0].type === 'TitleLineH1') {
      updatedComponents[0].margin = 'sm:text-3xl md:text-3xl xl:text-38 lg:text-38 leading-tight';
    }
    if (components.length && components[0].type === 'TitleLineH1' && postDescription.date) {
      updatedComponents.splice(1, 0, {
        type: 'NewsDate',
        color: 'black',
        createdAt: postDescription.date,
        margin: 'mt-1',
      });
    }
    this.setState({ updatedComponents });
  };

  render() {
    const {
      components, width, classesLeft, classesRight,
    } = this.props;
    const {
      topComponents,
      bottomComponents,
      isLoading,
      deviceScreen,
      postDescription,
      restOfComponents,
      updatedComponents,
    } = this.state;
    return (
      <React.Fragment>
        <PSResponsiveUtility
          ref={this.responsiveUtility}
          deviceWidthCallback={this.handleResize}
        />
        {!isLoading ? (
          <>
            {components.length && components[0].type !== 'Carousel' ? (
              <SliderBannerPD {...postDescription} />
            ) : (
              <ComponentIterator components={updatedComponents.length ? [updatedComponents[0]] : []} />
            )}
          </>
        ) : (
          <div
            className={`${
              components.length && components[0].type !== 'Carousel'
                ? 'h-620'
                : 'h-screen80'
            } h-620 md:h-carouselMD sm:h-carouselSM w-full sliderComponent relative full-width`}
          />
        )}
        <>
          <div className="py-4 w-full flex justify-end">
            <ShareMedia description={postDescription.title} />
          </div>
          <div className="mt-5 w-full flex mb-12 sm:mb-0 justify-center">
            <div
              className={`w-1/${width} sm:hidden md:hidden lg:w-ticket-lg xl:w-ticket-xl ${classesLeft}`}
            >
              {topComponents && (
                <ComponentIterator components={topComponents} />
              )}
              {deviceScreen !== 'sm' || deviceScreen !== 'md'
                ? bottomComponents && (
                <ComponentIterator components={bottomComponents} />
                )
                : null}
            </div>
            <div
              className={`xl:w-1200 lg:w-1028 sm:w-full pl-6 sm:pl-0 md:pl-0 sm:mb-4 md:w-full mt-7px ${classesRight} `}
            >
              {deviceScreen === 'md' || deviceScreen === 'sm' ? (
                <Separator borderWidth={2} />
              ) : null}
              <div className="md:my-10">
                {/* <TitleLineH1 text={postDescription.title} /> */}
                <ComponentIterator
                  components={
                    components.length && components[0].type !== 'Carousel'
                      ? updatedComponents
                      : restOfComponents
                  }
                />
              </div>
              {/* Previous concerts change place depending on deviceWidth */}
              {deviceScreen === 'md' || deviceScreen === 'sm'
                ? bottomComponents && (
                <div className="mt-6">
                  <ComponentIterator components={bottomComponents} />
                </div>
                )
                : null}
            </div>
          </div>
        </>
      </React.Fragment>
    );
  }
}

SinglePostPage.propTypes = {
  basicPage: PropTypes.bool,
  components: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  shareMedia: PropTypes.bool,
  width: PropTypes.number,
  classesLeft: PropTypes.string,
  classesRight: PropTypes.string,
};

SinglePostPage.defaultProps = {
  basicPage: false,
  shareMedia: false,
  width: 4,
  classesLeft: '',
  classesRight: '',
};

export default withRouter(SinglePostPage);
