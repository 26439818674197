import React from 'react';
import { PropTypes } from 'prop-types';

import Link from '../../../../util/link';
import imageModifier from '../../helpers/imageModifier';
import translateObj from '../../../../util/translateObj';
import { useRoutes } from '../../../../context/routesContext';
import { DEFAULT_PROPS_ITEMS_PER_ROW } from '../../GroupedItems';

const IMAGE_SIZE = '705x797';

const Festival = ({ image, title, subtitle, url, itemsPerRowPerMediaQuery, color, text }) => {
  const { language, path } = useRoutes();

  const city = translateObj(title, language);

  const { mobile } = itemsPerRowPerMediaQuery;

  const sizeItemTablet = `w-1/${itemsPerRowPerMediaQuery.tablet}`;
  const sizeItemDesktop = `w-1/${itemsPerRowPerMediaQuery.desktop}`;
  const sizeItemMobile = mobile === 1 ? 'w-full' : 'w-1/2';

  return (
    <div className={`launcherItem w-1/2 tablet:${sizeItemTablet} desktop:${sizeItemDesktop} mobile:${sizeItemMobile} p-8px`}>
      <Link to={url} localizationpath={path} className="text-black">
        <div className="mb-14 sm:mb-6 md:mb-10 launcherItemImg relative" key={url}>
          <img className="mb-5 min-w-full" src={imageModifier(translateObj(image, language), IMAGE_SIZE)} alt={translateObj(title, language)} />
          {text && (
          <h3 className="font-americaRegular text-sm sm:text-xxs uppercase" style={{ color }}>{translateObj(text, language)}</h3>
          )}
          <h2 className="font-americaRegular text-2xl sm:text-xl tracking-0025 uppercase" style={{ color }}> {city}</h2>
          <p className="mt-2 font-americaRegular text-xxs uppercase" style={{ color }}>{ translateObj(subtitle, language)}</p>
        </div>
      </Link>
    </div>
  );
};

Festival.propTypes = {
  image: PropTypes.shape({
    en: PropTypes.string,
  }).isRequired,
  title: PropTypes.shape().isRequired,
  subtitle: PropTypes.shape().isRequired,
  url: PropTypes.string,
  itemsPerRowPerMediaQuery: PropTypes.shape({}),
  color: PropTypes.string,
  text: PropTypes.shape({
    en: PropTypes.string,
    es: PropTypes.string,
    ca: PropTypes.string,
    pt: PropTypes.string,
  }),
};

Festival.defaultProps = {
  url: null,
  itemsPerRowPerMediaQuery: DEFAULT_PROPS_ITEMS_PER_ROW,
  color: 'transparent',
  text: null,
};

export default Festival;
