import React from 'react';
import Proptypes from 'prop-types';

const AppleMusicSinglePlayer = ({ appleMusic, artist }) => (
  <React.Fragment>
    <div className="py-8 border-t-2 border-b-2 border-black sm:mb-10 md:mb-10">
      <iframe title={artist} src={appleMusic} frameBorder="0" width="100%" height="150px" />
    </div>
  </React.Fragment>
);

AppleMusicSinglePlayer.propTypes = {
  appleMusic: Proptypes.string.isRequired,
  artist: Proptypes.string.isRequired,
};

export default AppleMusicSinglePlayer;
