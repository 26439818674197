import header from './_header';
import footer from './_footer';

const data = [
  { ...header },
  {
    type: 'ItemsList',
    parentSlugName: 'pro-mentoring-sessions',
  },
  { ...footer },
];

export default data;
