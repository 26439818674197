import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { literals } from './literals';
import translateObj from '../../../util/translateObj';
import { useRoutes } from '../../../context/routesContext';
import getRegisterPreferencesData from '../../../api/graphql/queries/getRegisterPreferencesData';
import imageModifier from '../helpers/imageModifier';
import LoadIcon from '../LoadIcon';
import useDebounce from '../../../util/useDebounce';
import artistsList from './artists';

const iconDice = (
  <svg xmlns="http://www.w3.org/2000/svg" width="23" height="24" fill="none">
    {/* eslint-disable-next-line max-len */}
    <path fill="#9A9A9A" d="M19.291 5.136H8.234a3.465 3.465 0 0 0-3.456 3.456v11.057c0 1.9 1.555 3.456 3.456 3.456H19.29c1.901 0 3.456-1.555 3.456-3.456V8.592c0-1.9-1.555-3.456-3.456-3.456ZM9.616 20.34a2.074 2.074 0 1 1 .002-4.148 2.074 2.074 0 0 1-.002 4.148Zm0-8.293A2.074 2.074 0 1 1 9.617 7.9a2.074 2.074 0 0 1 0 4.148Zm4.147 4.147a2.075 2.075 0 1 1 .001-4.15 2.075 2.075 0 0 1-.001 4.15Zm4.146 4.146a2.074 2.074 0 1 1 .002-4.148 2.074 2.074 0 0 1-.002 4.148Zm0-8.293A2.074 2.074 0 1 1 17.91 7.9a2.074 2.074 0 0 1 0 4.148Zm.62-8.293A3.468 3.468 0 0 0 15.146.99H4.087A3.465 3.465 0 0 0 .632 4.445v11.058a3.467 3.467 0 0 0 2.764 3.385V5.136c0-.76.622-1.382 1.382-1.382H18.53Z" />
  </svg>
);

const UserPreferences = ({ selectionCallback, initArtists, genres }) => {
  const { language } = useRoutes();
  const [topArtists, setTopArtists] = useState([]);
  const [selectedTopArtists, setSelectedTopArtists] = useState([]);
  const [selectedMusicalInterests, setSelectedMusicalInterests] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [showSearchMobile, setShowSearchMobile] = useState(false);
  const debouncedSearchValue = useDebounce(searchValue, 500);

  const loadArtistsData = ({ search = '', random = false, randomList, artists }) => {
    setLoading(true);
    getRegisterPreferencesData({ search, random, randomList, artists }).then((res) => {
      if (res) {
        if (selectedTopArtists.length > 0) {
          const noDuplicates = res.topArtists.filter((i) => !selectedTopArtists.some((a) => a.slug === i.slug && a.spotifyId === i.spotifyId));
          const visible = [...selectedTopArtists, ...noDuplicates];
          const visibleSliced = [...visible].slice(0, 6);
          setTopArtists(visibleSliced);
        } else {
          setTopArtists(res.topArtists);
        }
        setLoading(false);
      }
    }).catch((err) => {
      setLoading(false);
      console.log(err);
    });
  };

  const handleRandom = () => {
    const artists = [...artistsList];
    const random = [];
    while (random.length < 6) {
      const randomIndex = Math.floor(Math.random() * artists.length);
      if (!random.includes(randomIndex)) {
        random.push(randomIndex);
      }
    }
    const randomArtists = artists.filter((a, index) => random.includes(index));
    loadArtistsData({ randomList: randomArtists });
  };

  const handleArtistsSelection = (artist) => {
    const selected = [...selectedTopArtists];
    const alreadyIn = selected.some((a) => artist.slug === a.slug && a.spotifyId === artist.spotifyId);
    if (alreadyIn) {
      const itemIndex = selected.indexOf(artist);
      selected.splice(itemIndex, 1);
    } else if (selected.length < 3) {
      selected.push(artist);
    }
    setSelectedTopArtists(selected);
  };

  const handleMusicalInterestsSelection = (genre) => {
    const selected = [...selectedMusicalInterests];
    const alreadyIn = selected.some((g) => genre.value === g.value);
    if (alreadyIn) {
      const itemIndex = selected.indexOf(genre);
      selected.splice(itemIndex, 1);
    } else {
      selected.push(genre);
    }
    setSelectedMusicalInterests(selected);
  };

  useEffect(() => {
    loadArtistsData({ artists: initArtists });
  }, [initArtists]);

  useEffect(() => {
    if (selectedTopArtists.length === 0 && debouncedSearchValue.length === 0) {
      loadArtistsData({ search: debouncedSearchValue, artists: initArtists });
    } else {
      loadArtistsData({ search: debouncedSearchValue });
    }
  }, [debouncedSearchValue]);

  useEffect(() => {
    selectionCallback({
      topArtists: selectedTopArtists.map((artist) => ({ slug: artist.slug, isSpotifyArtist: artist.isSpotifyArtist, spotifyId: artist.spotifyId })),
      musicalInterests: selectedMusicalInterests.map((genre) => genre.value),
    });
  }, [selectedTopArtists, selectedMusicalInterests]);

  const searchInput = (
    <div className="flex items-center border-b border-grayEFEFEF pb-1 pr-4 sm:pr-0">
      <div className="font-icon icon-search text-11px sm:text-xs text-gray58 mr-1" />
      <input
        type="text"
        placeholder={translateObj(literals.search_placeholder, language)}
        className="bg-transparent font-americaRegular text-11px sm:text-xs focus:rounded-sm sm:w-full"
        onChange={(e) => setSearchValue(e.target.value)}
      />
    </div>
  );

  return (
    <div className="pt-4">
      <div className="flex justify-between items-center">
        <h3 className="font-americaRegular uppercase text-11px sm:text-xs tracking-wide">
          {translateObj(literals.choose_favourite_artists, language)}
        </h3>
        {/* SEARCH */}
        <button type="button" className="hidden sm:block" onClick={() => setShowSearchMobile(!showSearchMobile)}>
          <div className="font-icon icon-search text-base text-black mr-1" />
        </button>
        <div className="sm:hidden">
          {searchInput}
        </div>
      </div>
      {showSearchMobile && <div className="mt-4">{searchInput}</div>}
      <div className="flex flex-wrap items-start mt-6 sm:justify-between">
        {loading ? (
          <>
            {[...Array(7)].map((n) => (
              <div key={n} className="mr-4 sm:mr-0 sm:mb-4 bg-grayF4F4F4 rounded-full w-90px h-90px sm:h-94px sm:w-94px flex items-center justify-center pt-1 mb-6">
                <LoadIcon width={20} height={20} />
              </div>
            ))}
          </>
        ) : (
          <>
            {topArtists.map((artist) => (
              <button
                type="button"
                key={artist.slug}
                onClick={() => handleArtistsSelection(artist)}
                className={`text-black flex flex-col items-center justify-start relative mr-4 sm:mr-0 sm:mb-4
                ${!selectedTopArtists.some((a) => a.slug === artist.slug && a.spotifyId === artist.spotifyId) && selectedTopArtists.length === 3 ? 'opacity-50' : ''}
                focus:rounded-sm`}
              >
                {selectedTopArtists.some((a) => a.slug === artist.slug && a.spotifyId === artist.spotifyId) && (
                <div className="absolute bg-magenta rounded-full h-5 w-5 flex items-center justify-center pin-t pin-r mt-2 z-20">
                  <div className="font-icon icon-checkbox text-white text-7px" />
                </div>
                )}
                <div
                  className={`overflow-hidden relative bg-grayF4F4F4 rounded-full w-90px h-90px sm:h-94px sm:w-94px
                  ${selectedTopArtists.some((a) => a.slug === artist.slug && a.spotifyId === artist.spotifyId)
                    ? 'border-2 border-magenta' : ''}`}
                >
                  <img
                    src={artist.isSpotifyArtist ? artist.image : imageModifier(artist.image)}
                    alt={artist.name}
                    className="absolute pin-t pin-b pin-l pin-r z-10 aspect-square object-cover rounded-full"
                    style={{ aspectRatio: '1/1', objectFit: 'cover' }}
                  />
                </div>
                <h4
                  className="font-americaMonoRegular uppercase text-9px mt-3 sm:text-11px text-black"
                  style={{
                    textOverflow: 'ellipsis',
                    maxWidth: 90,
                    overflow: 'hidden',
                    display: 'block',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {artist.name}
                </h4>
              </button>
            ))}
            {/* RANDOM */}
            <button
              type="button"
              className="w-90px h-90px sm:h-94px sm:w-94px bg-grayF4F4F4 rounded-full flex items-center justify-center focus:rounded-full"
              onClick={() => handleRandom()}
            >
              {iconDice}
            </button>
          </>
        )}
      </div>
      <h3 className="font-americaRegular uppercase text-11px sm:text-xs tracking-wide mt-8">
        {translateObj(literals.choose_musical_interests, language)}
      </h3>
      <div className="flex flex-wrap mt-3">
        {genres.map((genre) => (
          <button
            type="button"
            key={genre.value}
            onClick={() => handleMusicalInterestsSelection(genre)}
            className="mr-1 mb-1 sm:w-48% focus:rounded-lg"
          >
            <div className={`uppercase ${selectedMusicalInterests.some((g) => g.value === genre.value)
              ? 'text-white bg-magenta font-americaMonoMedium'
              : 'text-black bg-grayF4F4F4 font-americaMonoRegular'}
              rounded-lg flex items-center justify-center h-30px w-20 sm:w-full text-xxs sm:text-xs sm:h-34px`}
            >
              {translateObj(genre.label, language)}
            </div>
          </button>
        ))}

      </div>
    </div>
  );
};

UserPreferences.propTypes = {
  selectionCallback: PropTypes.func.isRequired,
  initArtists: PropTypes.arrayOf(PropTypes.string),
  genres: PropTypes.arrayOf(PropTypes.shape()),
};

UserPreferences.defaultProps = {
  initArtists: [],
  genres: [],
};

export default UserPreferences;
