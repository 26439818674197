import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useRouteMatch } from 'react-router-dom';
import Link from '../../../util/link';

import getPostsListWithTotal from '../../../api/graphql/queries/getPostsListWithTotal';
import PSResponsiveUtility from '../LineUp/PSResponsiveUtility';
import LauncherItemSingle from './LauncherItemSingle';
import LauncherItemDouble from './LauncherItemDouble';
import LauncherItemTriple from './LauncherItemTriple';
import LauncherItemConcert from './LauncherItemConcert';
import LauncherItemRadio from './LauncherItemRadio';
import LauncherInstagram from './LauncherInstagram';
import LauncherTwitter from './LauncherTwitter';
import NewsSkeleton from './NewsSkeleton';
import LauncherSkeleton from './LauncherSkeleton';
import SingleSkeleton from './SingleSkeleton';
import Filter from '../Collapsible/Filter';
import Festival from './Layouts/Festival';
import Tour, { Button } from './Layouts/Tour';
import News from './Layouts/News';

import getPostsBySlugNameQuery from '../../../api/graphql/queries/getPostsBySlugNameQuery';
import { DEFAULT_PROPS_ITEMS_PER_ROW } from '../GroupedItems';
import { useRoutes } from '../../../context/routesContext';

const menu = {
  text: {
    en: 'FILTER CONTENT',
    es: 'FILTRAR CONTENIDO',
    ca: 'FILTRAR CONTINGUT',
  },
  filter: [
    {
      filterHeading: {
        es: 'POR EVENTO',
        en: 'BY EVENT',
        ca: 'POR EVENTO',
      },
      menuTitle: {
        text: {
          es: 'TODOS LOS Eventos',
          en: 'All events',
        },
      },
      filterType: 'byEvent',
      radio: true,
      filterItems: [
        {
          value: 'barcelona',
          category: 'barcelona',
          text: {
            es: 'BARCELONA - PRIMAVERA SOUND',
            en: 'BARCELONA - PRIMAVERA SOUND',
            ca: 'BARCELONA - PRIMAVERA SOUND',
          },
        },
        {
          value: 'porto',
          category: 'oporto',
          text: {
            es: 'Porto - PRIMAVERA SOUND',
            en: 'Porto - PRIMAVERA SOUND',
            ca: 'Porto - PRIMAVERA SOUND',
          },
        },
        {
          value: 'benidorm',
          category: 'benidorm',
          text: {
            es: 'Benidorm - PRIMAVERA SOUND',
            en: 'Benidorm - PRIMAVERA SOUND',
            ca: 'Benidorm - PRIMAVERA SOUND',
          },
        },
        {
          value: 'los-angeles',
          category: 'los-angeles',
          text: {
            es: 'Los Angeles - PRIMAVERA SOUND',
            en: 'Los Angeles - PRIMAVERA SOUND',
            ca: 'Los Angeles - PRIMAVERA SOUND',
          },
        },
        {
          value: 'primavera-pro',
          category: 'pro',
          text: {
            es: 'Primavera Pro - PRIMAVERA SOUND',
            en: 'Primavera Pro - PRIMAVERA SOUND',
            ca: 'Primavera Pro - PRIMAVERA SOUND',
          },
        },
        {
          value: 'tours',
          category: 'tours',
          text: {
            es: 'tours',
            en: 'tours',
            ca: 'tours',
          },
        },
      ],
    },
  ],
};

let itemsCounterMedium = 0;
let itemsCounterBig = 0;
let itemsLastRow = 0;
const LAUNCHERSIZES = {
  firstRowLauncherBig: 4,
  rowLauncherBigOdd: 5,
  firstRowLauncherMedium: 3,
  rowLauncherBig: 9,
  rowLauncherMedium: 3,
  numofNewsBig: 7,
  numofNewsMedium: 4,
  rowLauncherNewsMedium: 6,
  rowMobile: 3,
  rowSingleBig: 3,
  rowSingleMedium: 2,
};

const FESTIVAL_LAYOUT = 'festival';
const NEWS_LAYOUT = 'news';
const TOURS_LAYOUT = 'tour';
const PS_DESTINATION_LAYOUT = [FESTIVAL_LAYOUT, NEWS_LAYOUT, TOURS_LAYOUT];
const NEWS_SEE_ALL_BUTTTON = {
  en: 'See all',
  es: 'Ver todos',
  ca: 'Veure tots',
  pt: 'Ver todos',
};

const Launcher2 = ({
  color, findCategory, hasViewAll, findCategoryOperator, slugNames, quantity, rows: itemRows, isNews, mustBeSingle, showLoadMore, hasFilters, isTours, showAll, layout, isFullWidth, itemsPerRowPerMediaQuery, sortByPostLauncherDate,
}) => {
  const [highlights, setHighlights] = useState([]);
  const [highlightsBig, setHighlightsBig] = useState([]);
  const [highlightsMedium, setHighlightsMedium] = useState([]);
  const [fetchResult, setFetchResult] = useState([]);
  const [status, setStatus] = useState('loading');
  const [from, setFrom] = useState(0);
  const [category, setCategory] = useState(findCategory);
  const [deviceWidth, setdeviceWidth] = useState(null);
  const [device, setDevice] = useState(null);
  const isCancelled = React.useRef(false);
  const responsiveUtility = useRef();
  const [loadMoreCount, setLoadMoreCount] = useState(0);
  const [isDeviceReady, setDeviceReady] = useState(false);
  const [rows, setRows] = useState(itemRows);
  const [totalItems, setTotalItems] = useState(slugNames && slugNames.length ? slugNames.length : null);
  const { language, path } = useRoutes();

  const isToursLayout = layout === TOURS_LAYOUT;
  const isFestivalLayout = layout === FESTIVAL_LAYOUT;
  const isNewsLayout = layout === NEWS_LAYOUT;

  const getWidth = () => {
    const dv = responsiveUtility.current.deviceWidth(true);
    setdeviceWidth(dv);
    if (dv > 640 && dv < 1280) {
      setDevice('medium');
    } else if (dv > 1279) {
      setDevice('big');
    } else if (dv > 0 && dv <= 640) {
      setDevice('small');
    }
  };

  const createHighlightsArrayItem = (index, l, item, size = 'small') => {
    const {
      postDescription, postCategory, slugName,
    } = item;
    let singleDouble = 'concert';
    if (mustBeSingle) {
      singleDouble = 'single';
    }

    if (!mustBeSingle && !isNews && !isTours) {
      if (size === 'medium') {
        if ((itemsCounterMedium === 0 && l < LAUNCHERSIZES.firstRowLauncherMedium)) {
          singleDouble = 'launcherBanner';
        } else {
          singleDouble = itemsCounterMedium === 0 || itemsCounterMedium === 5 ? 'single' : 'double';
          itemsCounterMedium++;
          if (itemsCounterMedium >= 6) itemsCounterMedium = 0;
        }
      } else if (size === 'big') {
        if ((itemsCounterBig === 0 && l < LAUNCHERSIZES.firstRowLauncherBig) || l === 1) {
          singleDouble = 'launcherBanner';
        } else {
          singleDouble = itemsCounterBig === 0 || itemsCounterBig === 2 ? 'single' : 'double';
          itemsCounterBig++;
          if (itemsCounterBig >= 3) itemsCounterBig = 0;
        }
      } else if (size === 'small') {
        singleDouble = 'single';
      }
    }
    if (isNews) {
      singleDouble = index === 0 ? 'single' : 'triple';
    }

    if (size !== 'small') {
      if (l === 1) {
        if (!isTours) {
          singleDouble = 'launcherBanner';
        } else {
          singleDouble = 'launcherBannerTours';
        }
      }
      if (l === 2) {
        if (!isTours) {
          singleDouble = 'launcherBannerHalf';
        } else {
          singleDouble = 'launcherBannerToursHalf';
        }
      }
    }

    if (typeof postDescription.image === 'string') {
      // TODO: change 'en' by current user language
      postDescription.image = { en: postDescription.image };
    }
    let layout = null;
    if (postDescription.itemLayout && (postDescription.itemLayout === 'twitter' || postDescription.itemLayout === 'instagram' || postDescription.itemLayout === 'newsletter')) {
      layout = postDescription.itemLayout;
    }
    const date = typeof postDescription.date === 'string' ? moment(postDescription.date, 'x') : null;
    const highlight = {
      date: date ? date.format('DD / MM / YYYY') : '',
      unformattedDate: Number(postDescription.date),
      image: postDescription.image,
      itemLayout: singleDouble,
      position: postDescription.position,
      tags: postDescription.tags,
      title: postDescription.title,
      subtitle: postDescription.description,
      text: postDescription.subtitle,
      url: postDescription.url || `/${postCategory[0]}/${slugName}`,
      cta: postDescription.cta ? postDescription.cta : {},
      layout,
    };

    return highlight;
  };

  const prepareGridGrid = (postsList) => {
    const hl = postsList.map((item, index) => createHighlightsArrayItem(index, postsList.length, item));
    const hlMedium = postsList.map((item, index) => createHighlightsArrayItem(index, postsList.length, item, 'medium'));
    const hlBig = postsList.map((item, index) => createHighlightsArrayItem(index, postsList.length, item, 'big'));
    if (isTours && !sortByPostLauncherDate) {
      hl.sort(
        (a, b) => a.unformattedDate - b.unformattedDate,
      );
      hlMedium.sort(
        (a, b) => a.unformattedDate - b.unformattedDate,
      );
      hlBig.sort(
        (a, b) => a.unformattedDate - b.unformattedDate,
      );
    }
    setHighlights(hl);
    setHighlightsMedium(hlMedium);
    setHighlightsBig(hlBig);
    setStatus('loaded');
  };

  useEffect(() => {
    getWidth();
    setCategory(findCategory);
  }, []);

  const fetchHighlights = (cat, reset = false) => {
    itemsCounterMedium = 0;
    itemsCounterBig = 0;
    let q = quantity;
    let fr = from;
    let lmCount = loadMoreCount;
    const sortAscending = isToursLayout;
    if (showAll) q = 1000;
    if (reset) { fr = 0; lmCount = 0; }
    if (!cat) return;
    if (fetchResult.length < totalItems || lmCount === 0) {
      const options = {
        category: cat, findCategoryOperator, from: fr, to: q, sortAscending, sortByPostLauncherDate,
      };
      getPostsListWithTotal(options).then((postsList) => {
        if (!isCancelled.current) { // To avoid warning: Can't perform a React state update on an unmounted component...
          if (postsList) {
            const { posts, total } = postsList;
            setTotalItems(total);
            if (posts && posts.length) {
              let allPosts = posts;
              if (!reset) {
                allPosts = [...fetchResult, ...posts];
              }
              if (allPosts.length === total);
              setFrom(fr + quantity);
              setFetchResult(allPosts);
              prepareGridGrid(allPosts);
            } else {
              setStatus('end');
            }
          } else {
            prepareGridGrid(fetchResult);
          }
        }
      }).catch(err => console.log(err));
    }
  };

  const getNumOfItems = (array) => {
    if (device) {
      let numOfItems = 0;
      if (device === 'big') {
        if (!mustBeSingle && !isTours) {
          for (let i = 0; i < rows; i++) {
            if (!isNews) {
              if (i % 2 === 0) {
                numOfItems += LAUNCHERSIZES.firstRowLauncherBig;
              } else {
                numOfItems += LAUNCHERSIZES.rowLauncherBigOdd;
              }
            } else {
              if (i === 0) {
                numOfItems += LAUNCHERSIZES.numofNewsBig;
              } else {
                numOfItems += LAUNCHERSIZES.rowLauncherBig;
                itemsLastRow = LAUNCHERSIZES.rowLauncherBig;
              }
            }
          }
        } else {
          numOfItems = LAUNCHERSIZES.rowMobile * rows;
          while (numOfItems > array.length) {
            numOfItems -= LAUNCHERSIZES.rowMobile;
            itemsLastRow = LAUNCHERSIZES.rowMobile;
          }
        }
        let i = rows % 2;
        while (numOfItems > array.length) {
          if (!isNews && !mustBeSingle && !isTours) {
            if (i % 2 === 0) {
              numOfItems -= LAUNCHERSIZES.rowLauncherBigOdd;
              itemsLastRow = LAUNCHERSIZES.rowLauncherBigOdd;
            } else {
              numOfItems -= LAUNCHERSIZES.firstRowLauncherBig;
              itemsLastRow = LAUNCHERSIZES.firstRowLauncherBig;
            }
          } else if (mustBeSingle || isTours) {
            numOfItems -= LAUNCHERSIZES.rowSingleBig;
            itemsLastRow = LAUNCHERSIZES.rowSingleBig;
          } else {
            numOfItems -= LAUNCHERSIZES.rowLauncherBig;
            itemsLastRow = LAUNCHERSIZES.rowLauncherBig;
          }
          i++;
        }
      }
      if (device === 'medium') {
        if (!isNews) {
          if (!mustBeSingle && !isTours) {
            numOfItems = LAUNCHERSIZES.rowLauncherMedium * rows;
          } else {
            numOfItems = LAUNCHERSIZES.rowSingleMedium * rows;
          }
        } else {
          for (let i = 0; i < rows; i++) {
            if (i === 0) {
              numOfItems += LAUNCHERSIZES.numofNewsMedium;
            } else {
              numOfItems += LAUNCHERSIZES.rowLauncherNewsMedium;
              itemsLastRow = LAUNCHERSIZES.rowLauncherNewsMedium;
            }
          }
        }
        while (numOfItems > array.length) {
          if (!isNews && !mustBeSingle && !isTours) {
            numOfItems -= LAUNCHERSIZES.rowLauncherMedium;
            itemsLastRow = LAUNCHERSIZES.rowLauncherMedium;
          } else if (mustBeSingle || isTours) {
            numOfItems -= LAUNCHERSIZES.rowSingleMedium;
            itemsLastRow = LAUNCHERSIZES.rowSingleMedium;
          } else {
            numOfItems -= LAUNCHERSIZES.rowLauncherNewsMedium;
            itemsLastRow = LAUNCHERSIZES.rowLauncherNewsMedium;
          }
        }
      }
      if (device === 'small') {
        itemsLastRow = 0;
        numOfItems = LAUNCHERSIZES.rowMobile * rows;
        while (numOfItems > array.length) {
          numOfItems -= 1;
        }
      }
      return numOfItems;
    }
  };

  const fetchPostsBySlugNames = (slugnames) => {
    itemsCounterMedium = 0;
    itemsCounterBig = 0;
    setFrom(slugnames.length);
    getPostsBySlugNameQuery(slugnames, sortByPostLauncherDate)
      .then((result) => {
        if (result && result.length) {
          const postsList = result.filter(x => !!x);
          setTotalItems(postsList.length);
          prepareGridGrid(postsList);
        } else {
          setStatus('end');
        }
      })
      .catch((err) => {
        setStatus('end');
        console.log('Some error happened on fetch of fetchPostDescription:', err);
      });
  };

  useEffect(() => {
    isCancelled.current = false;
    if (isDeviceReady) {
      if (slugNames && slugNames.length) {
        if (loadMoreCount === 0) fetchPostsBySlugNames(slugNames);
      } else if (!slugNames.length) {
        fetchHighlights(category);
      }
    }
    return () => { isCancelled.current = true; };
  }, [loadMoreCount, isDeviceReady]);

  useEffect(() => {
    if (deviceWidth) setDeviceReady(true);
    return () => {
      setDeviceReady(false);
    };
  }, [deviceWidth]);

  const onHandleClick = () => {
    itemsCounterMedium = 0;
    itemsCounterBig = 0;
    setRows(rows + 1);
    setLoadMoreCount(loadMoreCount + 1);
  };

  const { url } = useRouteMatch();

  const onHandleCollapsibleMenuSelect = (filters) => {
    const splitUrl = url.split('/').filter(Boolean);
    const initialFilters = [...filters.byEvent];
    const lastSplitUrlItem = splitUrl.pop();

    if (lastSplitUrlItem === 'news') initialFilters.push('news');
    const events = filters?.byEvent?.length ? initialFilters : ['news'];

    setStatus('loading');
    setLoadMoreCount(0);
    setHighlights([]);
    setHighlightsMedium([]);
    setHighlightsBig([]);
    setFetchResult([]);
    setCategory(events);
    fetchHighlights(events, true);
  };

  const launcherItemArrayBuilder = (array, itemsCount) => {
    if (PS_DESTINATION_LAYOUT.includes(layout)) return array;
    // helper to make arrays of items depending on how many items are contained in each LauncherItem
    let reducedItems = array;
    if (itemsCount && itemsCount > 0) {
      reducedItems = array.slice(0, itemsCount);
    }
    if (reducedItems.length) {
      const newArray = [];
      reducedItems.map((highlight) => {
        const lastNewArrayItem = newArray[newArray.length - 1];
        // check if last item in newArray is an array if so,
        // check if it accepts 2 o 3 children and fill them until completed
        // if last itemLayoutin newArray is not an Array just push the element

        if (Array.isArray(lastNewArrayItem)) {
          if (lastNewArrayItem[0].itemLayout === 'triple' && newArray[newArray.length - 1].length < 3) {
            newArray[newArray.length - 1].push(highlight);
          } else if (lastNewArrayItem[0].itemLayout === 'double' && newArray[newArray.length - 1].length < 2) {
            newArray[newArray.length - 1].push(highlight);
          } else {
            if (highlight.itemLayout !== 'triple' && highlight.itemLayout !== 'double') {
              newArray.push(highlight);
            } else {
              newArray.push(Array.of(highlight));
            }
          }
        } else {
          if (highlight.itemLayout !== 'triple' && highlight.itemLayout !== 'double') {
            newArray.push(highlight);
          } else {
            newArray.push(Array.of(highlight));
          }
        }
      });
      return newArray;
    }
  };

  const checkItemType = (highlight) => {
    const { itemLayout } = highlight[0];
    // eslint-disable-next-line max-len
    return itemLayout === 'double' ? highlight.map((content, index) => <LauncherItemDouble {...content} position={index} key={`launcherItem-${index}`} />) : highlight.map((content, index) => <LauncherItemTriple {...content} position={index} key={`launcherItem-${index}`} />);
  };

  const launcherItemBuilder = (highlight, index) => {
    if (isFestivalLayout) return <Festival {...highlight} key={highlight.url || highlight.image?.en} itemsPerRowPerMediaQuery={itemsPerRowPerMediaQuery} color={color} />;
    if (highlight.itemLayout === 'concert') {
      return (
        <div className="launcherItem llg:w-1/3 lg:w-1/2 md:w-1/2 sm:w-full py-8px px-8px sm:px-0" key={`launcherContainer-${index}`}>
          <LauncherItemConcert {...highlight} position={index} key={`launcherItem-${index}`} />
        </div>
      );
    }
    if (highlight.itemLayout === 'launcherBannerTours') {
      return (
        <div className="launcherItem w-full py-8px sm:px-0" key={`launcherContainer-${index}`}>
          <LauncherItemConcert isBanner {...highlight} position={index} key={`launcherItem-${index}`} />
        </div>
      );
    }
    if (highlight.itemLayout === 'launcherBannerToursHalf') {
      return (
        <div className="launcherItem llg:w-1/2 lg:w-1/2 md:w-1/2 sm:w-full p-8px sm:px-0" key={`launcherContainer-${index}`}>
          <LauncherItemConcert device={device} {...highlight} isHalf position={index} key={`launcherItem-${index}`} />
        </div>
      );
    }
    if (highlight.itemLayout === 'launcherBanner') {
      const classes = isFullWidth ? '' : 'sm:px-0';

      return (
        <div className={`launcherItem w-full ${classes}`} key={`launcherContainer-${index}`}>
          <LauncherItemSingle {...highlight} isFullWidth={isFullWidth} isBanner position={index} key={`launcherItem-${index}`} />
        </div>
      );
    }
    if (highlight.itemLayout === 'single') {
      return (
        <div className="launcherItem llg:w-1/3 lg:w-1/2 md:w-1/2 sm:w-full p-8px sm:p-0" key={`launcherContainer-${index}`}>
          <LauncherItemSingle isNews={isNews} {...highlight} position={index} key={`launcherItem-${index}`} />
        </div>
      );
    }
    if (highlight.itemLayout === 'launcherBannerHalf') {
      return (
        <div className="launcherItem llg:w-1/2 lg:w-1/2 md:w-1/2 sm:w-full p-8px sm:px-0" key={`launcherContainer-${index}`}>
          <LauncherItemSingle isHalf isNews={isNews} {...highlight} position={index} key={`launcherItem-${index}`} />
        </div>
      );
    }
    if (highlight.itemLayout === 'radio') {
      return (
        <div className="launcherItem llg:w-1/3 lg:w-1/2 md:w-1/2 sm:w-full p-8px sm:px-0" key={`launcherContainer-${index}`}>
          <LauncherItemRadio {...highlight} position={index} key={`launcherItem-${index}`} />
        </div>
      );
    } if (highlight.itemLayout === 'instagram') {
      return (
        <div className="launcherItem llg:w-1/3 lg:w-1/2 md:w-1/2 sm:w-full p-8px sm:px-0" key={`launcherContainer-${index}`}>
          <LauncherInstagram {...highlight} position={index} key={`launcherItem-${index}`} />
        </div>
      );
    } if (highlight.itemLayout === 'twitter') {
      return (
        <div className="launcherItem llg:w-1/3 lg:w-1/2 md:w-1/2 sm:w-full p-8px sm:px-0" key={`launcherContainer-${index}`}>
          <LauncherTwitter {...highlight} position={index} key={`launcherItem-${index}`} />
        </div>
      );
    }
    return (
      <div className={`${Array.isArray(highlight) && highlight.length > 0 && highlight[0].itemLayout === 'triple' ? `${highlight[0].itemLayout} py-0` : 'py-8px'} launcherItem llg:w-1/3 lg:w-1/2 md:w-1/2 sm:w-full px-8px sm:px-0`} key={`launcher-${index}`}>
        {checkItemType(highlight)}
      </div>
    );
  };

  let hls = [];
  if (device === 'small') {
    hls = highlights;
  } else if (device === 'medium') {
    hls = highlightsMedium;
  } else if (device === 'big') {
    hls = highlightsBig;
  }
  let itemsCount = hls.length;
  if (!showAll) itemsCount = getNumOfItems(hls);
  const highlightsArray = launcherItemArrayBuilder(hls, itemsCount);

  const DefaultItemsLayout = () => (
    <div className={`launcher full-width flex flex-wrap ${isFestivalLayout ? '' : 'sm:-mx-0'} ${(highlightsArray && highlightsArray.length === 1) ? 'w-full' : '-mx-2'} ${isFullWidth ? 'full-width' : ''}`}>
      {
        highlightsArray && highlightsArray.map((highlight, index) => launcherItemBuilder(highlight, index))
      }
    </div>
  );
  // TODO(Arantxa): abstract this component into a separate component
  const NewsLayout = () => (
    <>
      <div className="launcher flex flex-wrap">
        {highlightsArray && highlightsArray.map(highlight => <News {...highlight} key={highlight.url || highlight.image?.en} itemsPerRowPerMediaQuery={itemsPerRowPerMediaQuery} color={color} />)}
      </div>
      <div className="mb-8 flex justify-center ">
        <Link to={hasViewAll?.link || '/news'} localizationpath={path} role="button">
          <Button language={language} translations={NEWS_SEE_ALL_BUTTTON} color={color} />
        </Link>
      </div>
    </>
  );

  const renderLauncherItems = () => {
    if (isToursLayout) return <Tour items={highlightsArray} itemsPerRowPerMediaQuery={itemsPerRowPerMediaQuery} color={color} />;
    if (isNewsLayout) return <NewsLayout />;
    return <DefaultItemsLayout />;
  };
  return (
    <>
      <PSResponsiveUtility ref={responsiveUtility} deviceWidthCallback={getWidth} />
      {hasFilters && (
      <Filter
        {...menu}
        hasSearchBar={false}
        onHandleCollapsibleMenuSelect={onHandleCollapsibleMenuSelect}
      />
      )}
      {status === 'loaded' && device ? (
        <>
          {renderLauncherItems()}
          {showLoadMore && highlightsArray && highlightsArray.length && status === 'loaded' && (itemsCount !== totalItems) && (itemsCount + itemsLastRow <= totalItems) && !showAll && (
          <div className="py-8px w-full flex justify-center mt-2">
            <button type="button" onClick={onHandleClick} className="font-americaMonoBold text-black text-center text-14 uppercase border-b-2 border-black py-1">
              <span className="font-icon icon-arrow-down text-12 bold" />
              <span className="px-4 cursor-pointer">Load More</span>
              <span className="font-icon icon-arrow-down text-12 bold" />
            </button>
          </div>
          )
      }
        </>
      ) : (
        status === 'loading'
        && (
        <div className="launcher flex flex-wrap -mx-2 sm:-mx-0 w-full">
          {isNews && <NewsSkeleton />}
          {(!mustBeSingle && !isNews && !isTours) && <LauncherSkeleton deviceWidth={deviceWidth} />}
          {(mustBeSingle || isTours || (deviceWidth <= 640 && !isNews)) && <SingleSkeleton />}
        </div>
        )
      )}
      {totalItems !== null && status === 'end' && (
      <div className="py-8 w-full flex justify-center mt-2">
        <span className="font-americaMonoBold text-black text-center text-14 uppercase border-b-2 border-black py-1">
          <span>
            <span className="px-4 ">No items</span>
          </span>
        </span>
      </div>
      )
      }
    </>
  );
};

Launcher2.propTypes = {
  quantity: PropTypes.number,
  isFullWidth: PropTypes.bool,
  itemsPerRowPerMediaQuery: PropTypes.shape({}),
  color: PropTypes.string,
  sortByPostLauncherDate: PropTypes.bool,
  hasViewAll: PropTypes.shape({}),
};

Launcher2.defaultProps = {
  quantity: '9',
  isFullWidth: false,
  itemsPerRowPerMediaQuery: DEFAULT_PROPS_ITEMS_PER_ROW,
  color: 'transparent',
  sortByPostLauncherDate: false,
  hasViewAll: {},
};

export default Launcher2;
