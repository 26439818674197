import gql from 'graphql-tag';

import { clientWeb } from '../graphqlAPI';

const LOYALTY_CLAIM_PRIZE = gql`
  mutation Post($scope: String!) {
    loyaltyClaimPrize(scope: $scope){
        claimed {
          code
          link
        }
        message
    }
}`;

const loyaltyClaimPrize = async (formData) => {
  try {
    const result = await clientWeb.mutate({
      mutation: LOYALTY_CLAIM_PRIZE,
      variables: {
        ...formData,
      },
      fetchPolicy: 'no-cache',
    })
      .then((queryResult) => queryResult)
      .catch((queryError) => {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error: ${queryError}`);
        return null;
      });

    const { data: { loyaltyClaimPrize: validatedFormData } } = result;

    if (validatedFormData) {
      return validatedFormData;
    }
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: GraphQL Query Error: validatedFormData = null`);
    return null;
  } catch (error) {
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: loyaltyClaimPrize Error: ${error}`);
    return null;
  }
};

export default loyaltyClaimPrize;
