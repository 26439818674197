import React from 'react';
import PropTypes from 'prop-types';

import { LinkWrapper } from '../../../util/link';
import { useRoutes } from '../../../context/routesContext';
import translateObj from '../../../util/translateObj';

const AND = {
  en: 'and',
  es: 'y',
  ca: 'i',
  pt: 'e',
};

const ReunionItemParticipantsList = ({
  items,
  expanded,
}) => {
  const { language, path } = useRoutes();

  return (
    <div className="flex flex-wrap">
      {items.map((guest, index) => {
        if (items.length > 3 && !expanded && index > 2) {
          return null;
        }
        return (
          <div key={guest._id}>
            <LinkWrapper url={`/pro-member-detail/?id=${guest._id}`} path={path}>
              <span className="font-tiemposRegular text-14 text-black mr-1 underline">{`${guest.name} ${guest.lastname}`}</span>
            </LinkWrapper>
            <span
              className="font-tiemposRegular text-14 mr-1"
            >
              {`${guest.company ? `(${guest.company})` : ''}${index < items.length - 2 ? ',' : ''}`}
            </span>
            {index === items.length - 2 && <span className="font-tiemposRegular text-14 mr-1">{translateObj(AND, language)}</span>}
          </div>
        );
      })}
    </div>
  );
};

ReunionItemParticipantsList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()),
  expanded: PropTypes.bool,
};

ReunionItemParticipantsList.defaultProps = {
  items: [],
  expanded: false,
};

export default ReunionItemParticipantsList;
