import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { TweenMax, Sine } from 'gsap';

class ScrollToTop extends Component {
  componentDidMount = () => {
    require('gsap/ScrollToPlugin'); // eslint-disable-line global-require
  }

  componentDidUpdate(prevProps) {
    const { location, location: { search } } = this.props;
    if (location !== prevProps.location) {
      if (!search) window.scrollTo(0, 0);
    }
  }

  onClick = () => {
    TweenMax.to(window, 0.25, { scrollTo: 0, ease: Sine.easeOut });
  }

  render() {
    const { children } = this.props;
    return (
      <div>
        <div id="scrollToTop" className="scrollToTop-Button-Wrapper absolute">
          <div onClick={this.onClick} className="scrollToTop-Button absolute sticky rounded-full bg-white shadow-lg">
            <svg viewBox="0 0 48 48">
              <path className="scrollToTop-Button-Arrow" d="M14.83 30.83l9.17-9.17 9.17 9.17 2.83-2.83-12-12-12 12z" />
            </svg>
          </div>
        </div>
        {children}
      </div>
    );
  }
}

ScrollToTop.propTypes = {
  location: PropTypes.shape().isRequired,
  children: PropTypes.shape().isRequired,
};

export default withRouter(ScrollToTop);
