const getLocalization = (contextLanguage, enteredFrom, routeMatch) => {
  const localization = {};
  if (enteredFrom === 'root' || enteredFrom === 'rootSection') {
    localization.language = contextLanguage;
    localization.path = '';
    return localization;
  }
  if (enteredFrom === 'rootLanguage' && routeMatch !== undefined) {
    localization.language = routeMatch.params.lang;
    localization.path = `/${routeMatch.params.lang}`;
    return localization;
  }

  return false;
};

export default getLocalization;
