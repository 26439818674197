import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import BannersRowItem from './BannersRowItem';
import getPostsBySlugNameQuery from '../../api/graphql/queries/getPostsBySlugNameQuery';

const BannersRow = ({
  items,
}) => {
  const [overlayImage, setOverlayImage] = useState(null);

  const fetchPostsBySlugNames = (slugnames) => {
    getPostsBySlugNameQuery(slugnames)
      .then((result) => {
        setOverlayImage(result[0].postDescription.overlayImage);
      })
      .catch((err) => {
        console.log('Some error happened on fetch of fetchPostDescription:', err);
      });
  };

  useEffect(() => {
    if (items) {
      items.forEach((item) => {
        const slugName = !item.bannerClick.includes('http://') && !item.bannerClick.includes('https://');
        if (slugName) {
          fetchPostsBySlugNames(item.bannerClick);
        }
      });
    }
  }, []);

  return (
    <div className="flex flex-col items-start justify-stretch md:items-stretch md:justify-between md:px-42px full-width">
      <div className="mx-5">
        <div className="lg:-mx-2 xl:-m-2 flex flex-col md:flex-col lg:flex-row xl:flex-row">
          {items.map((item, index) => (
            <div
              key={`BannersRowItem-${index + 1}`}
              className="xl:px-2 lg:px-2 lg:w-1/2 xl:w-1/2 pb-3 sm:pb-42px"
            >
              <BannersRowItem
                color={item.color}
                bgImage={item.bgImage}
                preTitle={item.preTitle}
                title={item.title}
                text={item.text}
                link={item.link}
                bannerClick={item.bannerClick}
                overlayImage={overlayImage}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BannersRow;

BannersRow.propTypes = {
  rows: PropTypes.number,
  items: PropTypes.arrayOf(Object).isRequired,
};

BannersRow.defaultProps = {
  rows: 1,
};
