import gql from 'graphql-tag';

import { clientWeb, persistor } from '../graphqlAPI';
import error404Page from '../../../error_pages/404';
import error401Page from '../../../error_pages/401';

const GET_SECTION_QUERY = gql`
  query Get($section: String!) {
    getSection(name: $section){
      post
      responseCode
    }
  }
`;

const executeQuery = async (section, cookies, type = 'network') => {
  let context = {};
  if (cookies) {

    const token = cookies[process.env.AUTH_TOKEN];
    //const { 'auth-token': token } = cookies;

    if (token) {
      context = {
        headers: {
          authorizationToken: token ? `Bearer ${token}` : '',
        },
      };
    }
  }

  const result = await clientWeb.query({
    context,
    query: GET_SECTION_QUERY,
    variables: { section },
    fetchPolicy: type === 'network' ? 'network-only' : 'cache-only',
  })
    .then(queryResult => queryResult)
    .catch((queryError) => {
      const errorMessage = type === 'network'
        ? `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error (getSection): ${queryError}`
        : `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error after Cache Restore: ${queryError}`;
      console.log('\x1b[31m%s\x1b[0m', errorMessage);

      return null;
    });

  const { data: { getSection: getSectionData } } = result;
  let post = JSON.parse(getSectionData.post);
  const { responseCode } = getSectionData;
  const appName = process.env.APP_NAME === 'rdl' ? 'Rockdelux' : 'Primavera Sound';
  if (responseCode === 404) {
    post = error404Page(appName);
  }
  if (responseCode === 401) {
    post = error401Page(appName);
  }
  return { post, responseCode };
};

const getSection = async (section, cookies) => {
  try {
    const JSONObject = await executeQuery(section, cookies);
    /*
    We want to store the data from a GraphQL query, such as this one (getSection), into the Apollo CachePersistor, which stores the data into the
    localStorage. After an initial SSR, the stored GraphQL query data will be restored into the Apollo InMemoryCache. This enable the app to load
    previously fetched GraphQL query data from memory cache, which the PWA uses to access as much of the app's content in offline mode as possible.
    */
    if (persistor) {
      persistor.persist();
    }

    return JSONObject;
  } catch (jsonError) {
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: JSON Object Error: ${jsonError}`);

    /*
    If the Apollo Client network request failed, we can assume no network connection present. For the PWA to work in offline mode, we now try to restore
    the GraphQL query data, if it exists, from localStorage back into InMemoryCache. Then, another identical GraphQL query will try to fetch the data
    from the InMemoryCache.
    */
    if (persistor) {
      const persistorStatus = await persistor.restore().then(() => 'OK');
      console.log(`${new Date().toLocaleTimeString()} ~ Client: Apollo Cache Restored - ${persistorStatus}`);

      try {
        const JSONObject = await executeQuery(section, cookies, 'cache');

        return JSONObject;
      } catch (jsonErrorAfterCacheRestore) {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: JSON Object Error after Cache Restore, sending 404: ${jsonErrorAfterCacheRestore}`);
        return null;
      }
    }
    return null;
  }
};

export default getSection;
