const translation = {
  buttonInvite: {
    en: 'Invite to meeting',
    es: 'Invitar a reunión',
    ca: 'Convidar a reunió',
    pt: 'Convidar para reunião',
  },
  buttonChat: {
    en: 'Chat',
    es: 'Chat',
    ca: 'Chat',
    pt: 'Chat',
  },
  buttonContact: {
    en: 'Contact',
    es: 'Contactar',
    ca: 'Contactar',
    pt: 'Contactar',
  },
};

export default translation;
