import axios from 'axios';

const showObject = async (filename, extension) => {
  let url = null;

  await axios.get('/s3', {
    params: {
      fileName: `${filename}.${extension}`,
      getObject: true,
    },
  }).then((res) => {
    // get tokenized url to see image in private mode
    const { data } = res;
    url = data;
  }).catch(err => console.log(err));
  // console.log(url);
  return url;
};

export default showObject;
