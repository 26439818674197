/* eslint-disable jsx-a11y/label-has-for */
import React, { Component } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { propTypes, withFormsy } from 'formsy-react';
import Link from '../../../util/link';

class FormCheckBox extends Component {
  state = {
    value: false,
  };

  shouldComponentUpdate = (nextProps) => {
    const { value } = this.state;

    if (nextProps.getValue() !== value) {
      return true;
    }
    return false;
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { getValue } = this.props;

    if (prevProps !== this.props && getValue() !== undefined) {
      if (prevState.value !== getValue()) {
        this.setState({
          value: !prevState.value,
        });
      }
    }
  };

  changeValue = (e) => {
    const { setValue } = this.props;
    setValue(e.currentTarget.checked);

    this.setState(prevState => ({
      value: !prevState.value,
    }));
  };

  render() {
    const {
      name, title, linkText = '', linkUrl = '', isBold = false, required = false,
    } = this.props;
    const { value } = this.state;

    return (
      <div className={`flex items-center sm:mt-3 xl:whitespace-no-wrap ${isBold && 'font-bold'}`}>
        <Checkbox onChange={this.changeValue} id={name} checked={value} data-checked={value} style={{ color: `${value ? '#00d113' : 'black'}` }} aria-label={title} />
        <span className="ml-4 ">
          {required && <span className="text-12 align-top text-red-light">* </span>}
          {`${title} `}
          {linkText && linkUrl ? <Link className="text-black underline uppercase cursor-pointer" to={linkUrl}>{linkText}</Link> : '' }
        </span>
      </div>
    );
  }
}

FormCheckBox.propTypes = {
  ...propTypes,
};

export default withFormsy(FormCheckBox);
