import React from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import RoutesContext from '../../../context/routesContext';
import withRadioPlayerContext from '../../../context/withRadioPlayerContext';
import getLocalization from '../../../util/localization';
import ComponentIterator from '../../../containers/ComponentIterator';
import RadioPlayButton from './RadioPlayButton';
import imageModifier from '../helpers/imageModifier';
import RadioPlayIcon from './RadioPlayIcon';
import translateObj from '../../../util/translateObj';

class RadioPlayer extends React.Component {
  radioRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      open: props.open || false,
    };
  }

  toggle = () => {
    const { open } = this.state;
    const newOpen = !open;
    this.setState({ open: newOpen }, () => {
      const { toggleCallback } = this.props;
      if (toggleCallback) {
        toggleCallback(newOpen);
      }
    });
  }

  render() {
    const {
      match,
      radioActive,
      userPanel,
    } = this.props;

    const ch = this.props[radioActive];
    const tags = [
      {
        type: 'TagsWrapper',
        components: [
          {
            type: 'GenreTag',
            text: {
              en: 'VIDEO',
              ca: 'VIDEO',
              es: 'VIDEO',
            },
            category: 'white',
            textColor: 'black',
          },
        ],
      },
    ];

    const startDate = '2019-03-01 10:00:00';
    const endDate = '2019-03-01 13:00:00';
    const durationText = {
      en: 'MIN',
      ca: 'MIN',
      es: 'MIN',
    };

    const icon1Url = 'font-icon icon-clock';
    const icon2Url = 'font-icon icon-arrow-down';
    const icon3Url = 'font-icon icon-mixcloud text-white text-14';
    const icon4Url = 'font-icon icon-mixcloud-mini text-white';
    const icon5Url = 'http://static.fragment.agency/ps/current.svg';
    const open = false;

    return (
      <RoutesContext.Consumer>
        {
        ({ language: contextLanguage, enteredFrom }) => {
          const localization = getLocalization(contextLanguage, enteredFrom, match);

          const startDateM = moment(startDate);
          const endDateM = moment(endDate);
          const duration = moment.duration(endDateM.diff(startDateM)).asMinutes();

          return (
            <div className={`bg-radio w-full flex text-12 text-white sm:pl-5 sm:py-5 pin-b sm:pt-0 pr-5 ${userPanel ? 'rounded-10 pl-5' : 'fixed z-50'}`} style={{ height: userPanel ? '100%' : '100px' }}>
              { ch !== null && (
              <>
                {!userPanel && (
                <div
                  className="sm:hidden mr-4"
                  style={{
                    height: '100px',
                    width: '100px',
                    backgroundImage: (ch.channel === 3 || ch.isPlaylist) ? `url(${ch.image})` : `url(${imageModifier(ch.image, '100x100')})`,
                    backgroundSize: 'contain',
                    backgroundPosition: 'center center',
                    backgroundRepeat: 'no-repeat',
                  }}
                />
                )}
                {!userPanel && (
                <div className="flex items-center sm:pt-5">
                  <RadioPlayButton />
                </div>
                )}
                <div className={`flex ${userPanel ? 'flex-col-reverse' : ''}`}>
                  <div className={`flex-2 flex flex-col justify-center ${userPanel ? 'items-start text-left pr-4' : 'px-5'}`}>
                    <h5 className={`font-americaMonoBold uppercase sm:pt-5 ${userPanel ? 'pt-4' : ''}`}>{translateObj(ch.program_title_multilang, localization.language)}</h5>
                    <span className={`${userPanel ? 'mt-1' : 'mt-2'} font-tiemposRegular `}>{ ch.title_multilang && translateObj(ch.title_multilang, localization.language)}</span>
                  </div>

                  {!userPanel && (
                  <div className="flex-1 px-5 flex items-center justify-end sm:hidden">
                    <span className={`w-5 mr-3 text-xl ${icon1Url}`} />
                    <span className="font-americaMonoBold uppercase mr-5">
                      {duration}
                      {' '}
                      {durationText[localization.language]}
                    </span>
                    <div className="">
                      {/* <ComponentIterator components={tags} /> */}
                    </div>
                  </div>
                  )}
                  <div className={`sm:hidden mr-5 flex items-center ${userPanel ? 'pt-4 pl-1' : ''}`}>
                    <RadioPlayIcon bgCircle="radio" bgWave="white" right={userPanel ? '0px' : '10px'} channel={`ch${ch.channel}`} classNames={`${userPanel ? '' : 'sm:hidden md:hidden'}`} />
                  </div>
                </div>
                {userPanel && (
                <div className="flex items-center sm:pt-5 ml-auto">
                  <RadioPlayButton />
                </div>
                )}
                {!userPanel && (
                <div className="flex flex-row items-center xs:flex-col">
                  <span className="font-icon icon-radio-ps text-70 pl-1 sm:pt-3 xs:pl-0 xs:pt-0 xs:leading-under" />
                  <span className="font-icon icon-seat text-44 pl-4 sm:pt-3 xs:leading-none xs:text-36 xs:pl-0 xs:pt-0" />
                  {/* <img className="sm:hidden" src={icon3Url} alt={icon3Url} />
                  <img className="hidden sm:block mr-3" src={icon4Url} alt={icon4Url} /> */}
                </div>
                )}
                {/* <div className="hidden sm:flex items-center" onClick={this.toggle}>
                  <img className={`w-4 ${open ? 'rotate-180' : ''}`} src={icon2Url} alt={icon2Url} />
                </div> */}
              </>
              )}
            </div>
          );
        }
      }
      </RoutesContext.Consumer>
    );
  }
}

RadioPlayer.propTypes = {
  match: PropTypes.shape().isRequired,
  radioActive: PropTypes.string.isRequired,
  userPanel: PropTypes.bool,
};

RadioPlayer.defaultProps = {
  userPanel: false,
};

export default withRouter(withRadioPlayerContext(RadioPlayer));
