import React from 'react';
import PropTypes from 'prop-types';

const TopHeaderMegamenuBody = ({ children, name }) => (
  <div id={name} className="w-full mb-16 md:mb-4 sm:mb-12">
    { children }
  </div>
);

TopHeaderMegamenuBody.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.shape().isRequired,
};

export default TopHeaderMegamenuBody;
