import React, { useContext } from 'react';
import { PropTypes } from 'prop-types';

const ThemeContext = React.createContext(null);

export const useTheme = () => {
  const {
    theme, color,
  } = useContext(ThemeContext);
  const data = {};
  data.theme = theme;
  data.color = color;
  return data;
};

export const ThemeProvider = ({
  children, theme, color,
}) => (
  <ThemeContext.Provider value={{
    theme, color,
  }}
  >
    {children}
  </ThemeContext.Provider>
);

ThemeProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  theme: PropTypes.string,
  color: PropTypes.string,
};

ThemeProvider.defaultProps = {
  theme: 'ps-light',
  color: 'ps-black',
};
