import React from 'react';

const ChatInactive = () => (
  <svg width="100%" height="100%" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <path d="M24 10.5c0 5.79-5.37 10.49-12 10.49-1.322 0-2.588-.186-3.775-.522a.992.992 0 0 0-.755.082l-5.98 3.32c-.77.43-1.67-.27-1.46-1.12l1.828-5.981c.09-.296.03-.612-.146-.866C.62 14.327 0 12.48 0 10.5 0 4.7 5.37 0 12 0c6.63 0 12 4.7 12 10.5" fill="#F60" />
      <path d="M14 10.495a2 2 0 1 1-3.998.001 2 2 0 0 1 3.998 0M8 10.495a2 2 0 1 1-3.998.001 2 2 0 0 1 3.998 0M20 10.495a2 2 0 1 1-3.998.001 2 2 0 0 1 3.998 0" fill="#FFF" />
    </g>
  </svg>
);

export default ChatInactive;
