import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';
import translateObj from '../../../util/translateObj';

const RadioTag = ({
  text, margin, textColor, borderColor, category, match, slider,
}) => (
  <RoutesContext.Consumer>
    {({ language: contextLanguage, enteredFrom }) => {
      const localization = getLocalization(contextLanguage, enteredFrom, match);
      const link_to_own_category = category ? category.replace(' ', '-') : 'home';

      return (
        <div className={`${slider ? 'mt-6' : ''} mr-1 inline-block ml-0 cursor-pointer mb-1 ${textColor ? `text-${textColor}` : 'text-white'} ${borderColor ? `border border-solid border-${borderColor}` : ''} align rounded px-1 text-xxs leading-normal bg-${link_to_own_category} ${margin || 'py-1 font-americaMonoMedium'}`}>
          <span className="uppercase">{translateObj(text, localization.language)}</span>
        </div>
      );
    }}
  </RoutesContext.Consumer>
);

RadioTag.propTypes = {
  text: PropTypes.shape({}),
  match: PropTypes.shape({}).isRequired,
  margin: PropTypes.string,
  textColor: PropTypes.string,
  borderColor: PropTypes.string,
  category: PropTypes.string,
  slider: PropTypes.bool,
};

RadioTag.defaultProps = {
  margin: null,
  textColor: 'white',
  category: 'white',
  text: {},
  borderColor: '',
  slider: false,
};

export default withRouter(RadioTag);
