import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Tag from '@primaveralabs/ui-tag';
import Modal from 'react-responsive-modal';
import 'Primavera/ui-tag/dist/primavera.css';
import { useRoutes } from '../../../../context/routesContext';
import useRewardDrawer from './hooks/useRewardDrawer';
import DrawerButton from './DrawerButton';

import translateObj from '../../../../util/translateObj';
import imageModifier from '../../helpers/imageModifier';
import translation from '../translations/RewardDrawer';
import commonTranslation from '../translations/common';
import loyaltyClaimPrize from '../../../../api/graphql/mutations/loyaltyClaimPrize';

// import ExportIcon from './svg/ExportIcon';
import RedeemIcon from './svg/RedeemIcon';
import RewardImage from './RewardImage';
import RewardCodeLink from './RewardCodeLink';
import { useBraze } from '../../../../context/useBraze';

const RewardDrawer = ({
  reward,
  setReward,
  callback,
  redeemedPrize,
  boughtOn,
  descriptionTitle,
  redeemButton,
}) => {
  const { language } = useRoutes();
  const [loadingRedeemAction, setLoadingRedeemAction] = useState(false);
  const [code, setCode] = useState(null);
  const [responseMessage, setResponseMessage] = useState(null);
  const [rewardLink, setRewardLink] = useState(null);
  const { logCustomEvent } = useBraze();

  const dateString = moment(Number(reward.claimedAt)).format(
    'DD/MM/YY',
  );

  const ctaRedeemAction = () => {
    setLoadingRedeemAction(true);
    loyaltyClaimPrize({ scope: reward?.slugName })
      .then((res) => {
        if (res) {
          setCode(res.claimed?.code);
          setResponseMessage(res.message);
          setRewardLink(res.claimed?.link);
          logCustomEvent(`${reward?.slugName}_claimed`);

          if (callback) {
            callback();
          }
        }
      })
      .catch((err) => console.log(err));
    setLoadingRedeemAction(false);
  };

  // const ctaExportAction = () => null;

  const { closeDrawer } = useRewardDrawer({ reward, setReward });

  return (
    <Modal
      open={reward}
      focusTrapped
      onClose={closeDrawer}
      showCloseIcon={false}
      styles={{
        modal: {
          background: 'rgba(0, 0, 0, 0)',
          boxShadow: 'none',
          width: '100vw',
          padding: 0,
        },
        overlay: {
          background: 'rgba(0, 0, 0, 0.5)',
          zIndex: 999,
          padding: 0,
        },
        closeButton: {
          display: 'none',
        },
      }}
    >
      <div
        className="absolute pin-t pin-l pin-r pin-b drawer-bg-in"
        onClick={closeDrawer}
        id="reward-drawer-bg"
      />
      <div
        id="reward-drawer"
        className="overflow-scroll sm:pt-4 h-screen sm:h-auto max-w-sm sm:max-w-full bg-grey-super text-white w-2/5 z-40 open-drawer sm:w-screen pin-r pin-t fixed sm:absolute"
      >
        {reward && (
          <>
            {/* CLOSE BUTTON */}
            <div className="flex justify-end cursor-pointer">
              <button
                type="button"
                onClick={closeDrawer}
                className="p-8 sm:py-4"
              >
                <span className="font-icon icon-close font-black text-white" />
              </button>
            </div>
            <div className="flex flex-col py-8 px-10" style={{ gap: '2rem' }}>
              {/* IMAGE */}
              <div className="flex justify-center">
                <div className="w-1/2">
                  <RewardImage
                    src={imageModifier(
                      translateObj(reward.postDescription?.image, language),
                      '400x400',
                    )}
                    alt={translateObj(reward.postDescription?.title, language)}
                  />
                </div>
              </div>

              <div>
                {/* REDEEMED TAG */}
                {reward.claimedAt && (
                  <div className="mb-2 uppercase">
                    <Tag
                      title={translateObj(redeemedPrize, language)}
                      color="#A4DF79"
                    />
                  </div>
                )}

                {/* TITLE */}
                <div className="text-4xl font-bold leading-tight">
                  {translateObj(reward.postDescription?.title, language)}
                </div>

                {/* DATE */}
                <div className="text-xl text-grey-dark">
                  {reward.claimedAt
                    && `${translateObj(boughtOn, language)} ${dateString}`}
                </div>
              </div>

              {/* BUTTONS */}
              <div className="flex" style={{ gap: '1rem' }}>
                {reward.claimedAt || reward.code || code ? (
                  <RewardCodeLink
                    reward={reward}
                    code={code || reward.code}
                    rewardLink={rewardLink || reward.link}
                    linkLabel={translateObj(
                      translation.userLinkButton,
                      language,
                    )}
                  />
                ) : (
                  <div
                    className={`${
                      reward.claimedAt ? 'pointer-events-none' : ''
                    }`}
                  >
                    <DrawerButton
                      callback={ctaRedeemAction}
                      label={redeemButton}
                      icon={<RedeemIcon />}
                      loading={loadingRedeemAction}
                      disabled={reward.claimedAt}
                    />
                  </div>
                )}
                {/* <DrawerButton
                callback={ctaExportAction}
                label={exportButton}
                icon={<ExportIcon />}
              /> */}
              </div>

              {responseMessage && (
                <div style={{ color: '#A4DF79' }} className="text-xs">
                  {responseMessage}
                </div>
              )}

              {/* DESCRIPTION */}
              <div>
                <div className="uppercase text-xs pb-3">
                  {translateObj(descriptionTitle, language)}
                </div>
                <div className="text-base">
                  {translateObj(reward.postDescription?.description, language)}
                </div>
              </div>

              {/* SPONSOR */}
              {/* <div>
                <div className="uppercase text-xs pb-3">
                  {translateObj(sponsorTitle, language)}
                </div>
                <div className="text-base flex" style={{ gap: '0.75rem' }}>
                  <img
                    className="h-6 w-6 rounded-full border border-white"
                    src="https://play-lh.googleusercontent.com/3CyC0NofR30j3O_Ody1avJWr6s98wsRbiW63Zsb2MCE4CaWuODgPz-sMg31_gWOAHVM" // TODO: provisional image
                    alt={translateObj(
                      reward.postDescription?.subtitle,
                      language
                    )}
                  />
                  <span>
                    {translateObj(reward.postDescription?.subtitle, language)}
                  </span>
                </div>
              </div> */}
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

RewardDrawer.propTypes = {
  reward: PropTypes.shape().isRequired,
  setReward: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired,
  redeemedPrize: PropTypes.shape({}),
  boughtOn: PropTypes.shape({}),
  descriptionTitle: PropTypes.shape({}),
  redeemButton: PropTypes.shape({}),
};

RewardDrawer.defaultProps = {
  redeemedPrize: commonTranslation.redeemed,
  boughtOn: translation.boughtOn,
  descriptionTitle: translation.descriptionTitle,
  redeemButton: translation.redeemButton,
};

export default RewardDrawer;
