import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useRoutes } from '../../../context/routesContext';
import useResponsiveUtility from '../../../util/responsiveUtility';
import getSubEventsBySlugName from '../../../api/graphql/queries/getSubEventsBySlugName';
import getMyReunions from '../../../api/graphql/queries/getMyReunions';

import translateObj from '../../../util/translateObj';
import getTranslation from './util/translations/_getTranslation';
import localTranslation from './util/translations/ItemsList';
import useQuery from './util/hooks/useQuery';
import useDebounce from '../../../util/useDebounce';

import HeadTitle from './HeadTitle';
import MentoringSessionItem from './MentoringSessionItem';
import ReunionItem from './ReunionItem';
import CtaUnderline from './CtaUnderline';
import Loader from './Loader';
import Filters from './Filters';
import Button from './Button';
import FiltersSelect from './FiltersSelect';
import FiltersSortButton from './FiltersSortButton';
import { VENUES_OPTIONS } from './util/optionValues';

// sort = DATE | VENUE (sessions)
// sortBy = date | location (reunions)
// filter = confirmed | received (reunions)

const getColumnsTemplate = (parentSlugName, deviceWidth) => {
  switch (parentSlugName) {
    case 'pro-mentoring-sessions':
      if (deviceWidth >= 640 && deviceWidth < 1070) {
        return 'repeat(auto-fill, minmax(300px, 1fr))';
      } if (deviceWidth >= 1070 && deviceWidth < 1400) {
        return 'repeat(auto-fill, minmax(330px, 1fr))';
      }
      return 'repeat(auto-fill, minmax(440px, 1fr))';

    default: return 'repeat(auto-fill, minmax(330px, 1fr))';
  }
};

const ItemsList = ({
  parentSlugName,
  translation,
  sessionPageSlugname,
}) => {
  const { language } = useRoutes();
  const query = useQuery();
  const { deviceScreen, deviceWidth } = useResponsiveUtility();
  const literals = getTranslation(translation, localTranslation);
  const [columnsTemplate, setColumnsTemplate] = useState();
  const [items, setItems] = useState([]);
  const [qty, setQty] = useState(20);
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const [loading, setLoading] = useState(true);
  const [filterReunionsBy, setFilterReunionsBy] = useState(null);
  const [filterSessionsBy, setFilterSessionsBy] = useState(null);
  const [filterVenue, setFilterVenue] = useState(null);
  const [sortBy, setSortBy] = useState(parentSlugName ? 'DATE' : 'date');
  const [sortDirection, setSortDirection] = useState('desc');
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const queryId = query.get('id');

  const handleRadioButtonsValue = (value) => {
    const finalValue = value === 'all' ? null : value;
    if (parentSlugName) setFilterSessionsBy(finalValue);
    else setFilterReunionsBy(finalValue);
  };

  const handleSelectsValue = (value) => {
    if (sortBy === value) {
      if (sortDirection === 'asc') {
        setSortDirection('desc');
      } else {
        setSortDirection('asc');
      }
    } else {
      setSortBy(value);
      setSortDirection('asc');
    }
  };

  const getSessions = () => {
    const params = {
      eventSlugName: parentSlugName,
      to: qty,
      search: debouncedSearchValue,
      onlyMySessions: filterSessionsBy === 'reserved',
      sortDirection,
    };
    if (sortBy) params.sort = sortBy;
    getSubEventsBySlugName(params).then((res) => {
      if (res) {
        if (res.length < qty) {
          setHasMoreItems(false);
        } else {
          if (!hasMoreItems) {
            setHasMoreItems(true);
          }
        }
        const response = res?.length > 0 ? res.map(i => Object.assign(i, { isListItem: true })) : res;
        setItems(response);
        setLoading(false);
      }
    }).catch((err) => {
      setLoading(false);
      console.log(err);
    });
  };

  const getReunions = () => {
    const params = {
      to: qty,
      filter: filterReunionsBy,
      venueFilter: filterVenue,
      search: debouncedSearchValue,
      sortDirection,
      cancelledReunions: true,
    };
    if (sortBy) params.sortBy = sortBy;
    getMyReunions(params).then((res) => {
      if (res) {
        if (res.length < qty) {
          setHasMoreItems(false);
        } else {
          if (!hasMoreItems) {
            setHasMoreItems(true);
          }
        }
        const response = res?.length > 0 ? res.map(i => Object.assign(i, { isListItem: true })) : res;
        setItems(response);
        setLoading(false);
      }
    }).catch((err) => {
      setLoading(false);
      console.log(err);
    });
  };

  useEffect(() => {
    if (qty > 20 && !hasMoreItems) {
      setQty(20);
    }
    if (parentSlugName) {
      getSessions();
    } else {
      getReunions();
    }
  }, [qty, debouncedSearchValue, filterSessionsBy, filterReunionsBy, filterVenue, sortBy, sortDirection]);

  useEffect(() => {
    const columns = getColumnsTemplate(parentSlugName, deviceWidth);
    setColumnsTemplate(columns);
  }, [deviceWidth]);

  return (
    <div className="w-full full-width mb-24 sm:mb-16">
      <Filters
        literals={literals}
        radioButtonsOptions={parentSlugName ? [
          { name: translateObj(literals.filtersAllSessions, language), value: 'all', defaultValue: true },
          { name: translateObj(literals.filtersReservedSessions, language), value: 'reserved' },
        ] : [
          { name: translateObj(literals.filtersAllReunions, language), value: 'all', defaultValue: true },
          { name: translateObj(literals.filtersConfirmedReunions, language), value: 'confirmed' },
          { name: translateObj(literals.filtersInvitedReunions, language), value: 'received' },
        ]}
        radioButtonsValueCallback={value => handleRadioButtonsValue(value)}
        searchInputEventCallback={event => setSearchValue(event.target.value)}
      >
        <div className="w-full flex items-center justify-between pt-6 sm:flex-col sm:justify-start sm:items-start">
          <div className="flex items-center justify-between sm:w-full">
            <FiltersSortButton
              text={literals.filtersDate}
              selected={['date', 'DATE'].includes(sortBy)}
              direction={['date', 'DATE'].includes(sortBy) ? sortDirection : null}
              className="mr-1"
              onClickCallback={() => handleSelectsValue((parentSlugName ? 'DATE' : 'date'))}
            />
            {parentSlugName ? (
              <FiltersSortButton
                text={literals.filtersSortLocation}
                selected={['location', 'VENUE'].includes(sortBy)}
                direction={['location', 'VENUE'].includes(sortBy) ? sortDirection : null}
                className="ml-1"
                onClickCallback={() => handleSelectsValue((parentSlugName ? 'VENUE' : 'location'))}
              />
            ) : (
              <FiltersSelect
                name="location"
                options={VENUES_OPTIONS}
                placeholder={literals.filtersLocation}
                className="sm:w-full w-max flex-1 ml-1"
                onChange={value => setFilterVenue(value)}
              />
            )}
          </div>
          {!parentSlugName && (
          <Button
            text={translateObj(literals.buttonNewReunion, language)}
            color="black"
            plusIcon
            className="w-max sm:mt-4"
            link="/pro-create-reunion"
          />
          )}
        </div>
      </Filters>
      <div className="w-full px-4">
        <HeadTitle title={parentSlugName ? translateObj(literals.titleSessions, language) : translateObj(literals.titleReunions, language)} />
        <div
          className="flex flex-wrap sm:flex-no-wrap sm:flex-col"
          style={{
            display: deviceScreen === 'sm' ? 'flex' : 'grid',
            gridTemplateColumns: columnsTemplate,
            gridColumnGap: 24,
            gridRowGap: 32,
          }}
        >
          {parentSlugName ? (
            <>
              {loading
                ? <Loader label={literals.titleSessions} />
                : items?.map(item => (
                  <MentoringSessionItem
                    key={item.eventSlugName}
                    translation={translation}
                    sessionPageSlugname={sessionPageSlugname}
                    {...item}
                    callback={() => getSessions()}
                  />
                ))}
            </>
          ) : (
            <>
              {loading
                ? <Loader label={literals.titleReunions} />
                : items?.map(item => (
                  <ReunionItem
                    key={item._id}
                    {...item}
                    callback={() => getReunions()}
                    expand={queryId === item._id}
                  />
                ))}
            </>
          )}
        </div>
        {
          items?.length > 0 && hasMoreItems
          && (
          <div className="mt-16 sm:mt-8">
            <CtaUnderline text={translateObj(literals.buttonLoadMore, language)} onClickCallback={() => setQty(qty + 20)} />
          </div>
          )
        }
      </div>
    </div>
  );
};

ItemsList.propTypes = {
  parentSlugName: PropTypes.string,
  translation: PropTypes.shape(),
  sessionPageSlugname: PropTypes.string,
};

ItemsList.defaultProps = {
  parentSlugName: null,
  translation: null,
  sessionPageSlugname: 'pro-mentoring-sessions-detail',
};

export default ItemsList;
