import React from 'react';

const SimpleArrow = () => (
  <svg width="100%" height="100%" viewBox="0 0 10 17" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs><path id="a" d="M0 0h9.005v16H0z" /></defs>
    <g fill="none" fillRule="evenodd">
      <g transform="matrix(-1 0 0 1 9.75 .75)">
        <path d="M8.957 8.254c.021-.083.048-.164.048-.25 0-.066-.025-.13-.038-.196-.012-.06-.012-.122-.035-.18-.014-.034-.045-.061-.063-.094-.044-.082-.086-.166-.156-.236v-.001L1.706.293A1 1 0 1 0 .293 1.707L6.59 8.004.293 14.292a1 1 0 1 0 1.414 1.416L8.71 8.712h.001c.041-.042.06-.094.093-.14.044-.063.098-.122.127-.191.017-.04.014-.085.025-.127" fill="#000" mask="url(#b)" />
      </g>
    </g>
  </svg>
);

export default SimpleArrow;
