import { useState, useEffect } from 'react';
import getUsersListFront from '../../../../../api/graphql/queries/getUsersListFront';
import { useRoutes } from '../../../../../context/routesContext';

export default function useUsersList({
  query,
  roles,
  from,
  to,
  sortDirection,
  onlyFriends,
  letter,
  country,
}) {
  const { language } = useRoutes();
  const [usersList, setUsersList] = useState([]);
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const userLang = language;

  const loadUsers = () => {
    getUsersListFront(query, roles, from, to, sortDirection, onlyFriends, letter, country, userLang).then((res) => {
      if (res) {
        if (res.length < to) {
          setHasMoreItems(false);
        } else {
          if (!hasMoreItems) {
            setHasMoreItems(true);
          }
        }
        setUsersList(res);
      }
    }).catch((err) => console.log(err));
  };

  const updateList = () => {
    loadUsers();
  };

  useEffect(() => {
    loadUsers();
  }, [query, roles, from, to, sortDirection, onlyFriends, letter, country]);

  return { usersList, hasMoreItems, updateList };
}
