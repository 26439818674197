import React from 'react';
import PropTypes from 'prop-types';
import { buttonClasses } from '../../OnDemandUtils/onDemandUtils';


const SectionAddCardFillForm = ({ handleModalLoggedStep }) => {
  const handleAddCardFormCofirmation = () => {
    handleModalLoggedStep('paymentDetailsUpdated');
  };

  return (
    <div>
      <div>card form</div>
      <button type="button" className={buttonClasses} onClick={handleAddCardFormCofirmation}>on confirmation</button>
    </div>
  );
};

SectionAddCardFillForm.propTypes = {
  handleModalLoggedStep: PropTypes.func.isRequired,
};

export default SectionAddCardFillForm;
