import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';

import Filter from './Collapsible/Filter';
import CollapsibleList from './Collapsible/CollapsibleList';

class PressFilterHome extends Component {
  state = {
    event: '',
  };

  componentDidMount() {
    // this.setState({ event: 'barcelona' }, () => this.handleFilter());
    console.log(this.props);
  }

  onHandleCollapsibleMenuSelect = (event) => {
    this.setState({ event });
  };

  handleFilter = () => {
    const { event } = this.state;
    const { params } = this.props;

    if (!event) {
      return null;
    }
    return (
      <div className="w-full">
        <CollapsibleList components={params[event]} color="white" firstClosed />
      </div>
    );
  };

  render() {
    const { match, menu } = this.props;
    return (
      <RoutesContext.Consumer>
        {({ language: contextLanguage, enteredFrom }) => {
          const localization = getLocalization(contextLanguage, enteredFrom, match);
          return (
            <Fragment>
              <Filter {...menu} hasSearchBar={false} onHandleCollapsibleMenuSelect={this.onHandleCollapsibleMenuSelect} radio />
              {this.handleFilter()}
            </Fragment>
          );
        }}
      </RoutesContext.Consumer>
    );
  }
}

PressFilterHome.propTypes = {
  match: PropTypes.shape().isRequired,
  menu: PropTypes.shape({}).isRequired,
  params: PropTypes.shape({}).isRequired,
};

export default withRouter(PressFilterHome);
