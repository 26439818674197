import gql from 'graphql-tag';

import { clientWeb } from '../graphqlAPI';

const ADD_USER_TO_NEWSLETTER_FORM_MUTATION = gql`
  mutation Post($email: String!, $language: String, $scopes: [String], $origin: String, $isSubscribing: Boolean) {
    addExistingUserToNewsLetter(email: $email, language: $language, scopes: $scopes, origin: $origin, isSubscribing: $isSubscribing) {
    token
    isValid
    error
    }
  }
`;

const addExistingUserToNewsLetter = async ({
  email, language = 'en', scopes = ['scope_general'], origin = 'web', isSubscribing = true,
}) => {
  try {
    const result = await clientWeb
      .mutate({
        mutation: ADD_USER_TO_NEWSLETTER_FORM_MUTATION,
        variables: {
          email, language, scopes, origin, isSubscribing,
        },
        fetchPolicy: 'no-cache',
      })
      .then(queryResult => queryResult)
      .catch((queryError) => {
        console.log(
          '\x1b[31m%s\x1b[0m',
          `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error: ${queryError}`,
        );
        return null;
      });
    const {
      data: { addExistingUserToNewsLetter: validatedFormData },
    } = result;

    if (validatedFormData) {
      return validatedFormData;
    }
    console.log(
      '\x1b[31m%s\x1b[0m',
      `${new Date().toLocaleTimeString()} ~ Client: GraphQL Query Error: validatedFormData = null`,
    );
    return null;
  } catch (error) {
    console.log(
      '\x1b[31m%s\x1b[0m',
      `${new Date().toLocaleTimeString()} ~ Client: addExistingUserToNewsLetter Error: ${error}`,
    );
    return null;
  }
};

export default addExistingUserToNewsLetter;
