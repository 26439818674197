const translation = {
  title: {
    en: 'Welcome {userName}!',
    es: 'Welcome {userName}!',
    ca: 'Welcome {userName}!',
    pt: 'Welcome {userName}!',
  },
  titleMentoringSessions: {
    en: 'Mentoring sessions',
    es: 'Mentoring sessions',
    ca: 'Mentoring sessions',
    pt: 'Mentoring sessions',
  },
  titleReunions: {
    en: 'Your meetings',
    es: 'Tus reuniones',
    ca: 'Les teves reunions',
    pt: 'Suas reuniões',
  },
  titleNotifications: {
    en: 'Notifications',
    es: 'Notificaciones',
    ca: 'Notificacions',
    pt: 'Notificações',
  },
  noItemsMentoringSessions: {
    en: 'You haven\'t signed up for any session yet. What are you waiting for?',
    es: 'Aún no te has apuntado a ninguna sesión. ¿Qué esperas?',
    ca: 'Encara no t\'has apuntat a cap sessió. Què esperes?',
    pt: 'Você ainda não se inscreveu em nenhuma sessão. Você espera?',
  },
  noItemsReunions: {
    en: 'When you host or accept a meeting invitation, you\'ll see them here.',
    es: 'Cuando convoques o aceptes la invitación a una reunión, las verás aquí.',
    ca: 'Quan convoquis o acceptis la invitació a una reunió, les veuràs aquí.',
    pt: 'Ao hospedar ou aceitar um convite para uma reunião, você os verá aqui.',
  },
  noItemsNotifications: {
    en: 'You don\'t have notifications',
    es: 'No tienes notificaciones',
    ca: 'No tens notificacions',
    pt: 'Você não tem notificações',
  },
  buttonNewReunion: {
    en: 'New meeting',
    es: 'Nueva reunión',
    ca: 'Nova reunió',
    pt: 'Nova reunião',
  },
  buttonLoadMore: {
    en: 'Load more',
    es: 'Cargar más',
    ca: 'Carregar més',
    pt: 'Carregue mais',
  },
  buttonLogin: {
    en: 'LOG IN',
    es: 'INICIAR SESIÓN',
    ca: 'INICIAR SESSIÓ',
    pt: 'LOG IN',
  },
  textLogin: {
    en: 'You must be logged in to view this content.',
    es: 'Debes iniciar sesión para ver este contenido.',
    ca: 'Has d\'iniciar sessió per veure aquest contingut.',
    pt: 'É necessário iniciar sessão para ver este conteúdo.',
  },
  noAccessMessage: {
    en: 'Oops! You don\'t have access to this content of Primavera Pro - it is exclusively for Primavera Pro 2022 delegates. You can purchase your ticket or go back to the home page.',
    es: '¡Oops! No tienes acceso a este contenido de Primavera Pro: es exclusivo para los acreditados de Primavera Pro 2022. Puedes comprar tu entrada o volver a la página de inicio.',
    ca: 'Oops! No tens accés a aquest contingut de Primavera Pro: és exclusiu per als acreditats de Primavera Pro 2022. Pots comprar la teva entrada o tornar a la pàgina inicial de la plataforma online.',
    pt: 'Oops! You don\'t have access to this content of Primavera Pro - it is exclusively for Primavera Pro 2022 delegates. You can purchase your ticket or go back to the home page.',
  },
  purchaseConferencePass: {
    en: 'PURCHASE YOUR DAY PRO CONFERENCE PASS',
    es: 'COMPRA EL ABONO DAY PRO CONFERENCE',
    ca: 'COMPRA L\'ABONAMENT DAY PRO CONFERENCE',
    pt: 'PURCHASE YOUR DAY PRO CONFERENCE PASS',
  },
  backHome: {
    en: 'BACK TO HOME PAGE',
    es: 'VOLVER A LA HOME',
    ca: 'TORNAR A LA HOME',
    pt: 'BACK TO HOME PAGE',
  },
  loading: {
    en: 'Loading {items}...',
    es: 'Cargando {items}...',
    ca: 'Carregant {items}...',
    pt: 'Loading {items}...',
  },
};

export default translation;
