const setComponentTypeFromExtension = (ext) => {
  let type;
  switch (ext.toLowerCase()) {
    case 'jpg':
    case 'jpeg':
    case 'gif':
    case 'bmp':
    case 'png':
      type = 'Image';
      break;
    case 'm4v':
    case 'avi':
    case 'mpg':
    case 'mp4':
      type = 'Video';
      break;
    case 'mp3':
      type = 'Audio';
      break;
    case 'pdf':
      type = 'Pdf';
      break;
    default:
      type = 'text';
  }
  return type;
};

export default setComponentTypeFromExtension;
