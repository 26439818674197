import React from 'react';

const SideBodyText = ({
  text, cursive, bold, anchorTag, firstLetterBig,
}) => {
  let className = '';
  if (cursive) className += 'italic';
  if (bold) className += 'font-bold';
  let firstLetter = '';
  const paragraphs = text.es
    .split('\n')
    .filter(n => n.length)
    .map((paragraph, i) => {
      if (i === 0 && firstLetterBig) {
        firstLetter = paragraph[0];
        paragraph = paragraph.substr(1);
      }
      return paragraph;
    });

  return (
    <div className={`${className} py-4 xl:flex lg:flex`} id={anchorTag}>
      <div style={{ fontSize: 180, flex: 0.6 }} className={`pt-12 p-4 text-right font-tiemposRegular sm:float-left md:float-left ${!firstLetter && 'sm:p-0 md:p-0'}`}>
        {firstLetter && firstLetter}
      </div>
      <div className="p-4 md:pr-2 md:pl-0 sm:px-0" style={{ flex: 1 }}>
        {paragraphs.map(paragraph => (
          <p key={paragraph.substring(0, 10)} className="mt-4 leading-normal font-tiemposRegular">
            {paragraph}
          </p>
        ))}
      </div>
    </div>
  );
};

export default SideBodyText;
