import React from 'react';
import PropTypes from 'prop-types';

const VenueLabel = ({ title, layout }) => (
  <span className={`p-2 h-lineupHour flex items-center block font-americaMonoRegular text-xs uppercase text-black border-solid border-white ${layout === 'columns' ? 'bg-grayE2DDDB border-t-1 w-lineupHour' : 'bg-gray200 border-b-1'}`}>
    {title}
  </span>
);

VenueLabel.propTypes = {
  title: PropTypes.string.isRequired,
  layout: PropTypes.string.isRequired,
};

export default VenueLabel;
