/* eslint-disable jsx-a11y/tabindex-no-positive */
import React from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import {
  TimelineMax,
  Power1,
} from 'gsap';
import RoutesContext from '../../../context/routesContext';
import withRadioPlayerContext from '../../../context/withRadioPlayerContext';
import getLocalization from '../../../util/localization';
import RadioPlayButton from './RadioPlayButton';
import imageModifier from '../helpers/imageModifier';
import RadioPlayIcon from './RadioPlayIcon';
import Link from '../../../util/link';
import translateObj from '../../../util/translateObj';
import withAllContexts from '../../../context/withAllContexts';

const ExpandLess = () => <span className="font-icon icon-arrow-up text-white text-sm font-black" />;
const ExpandMore = () => <span className="font-icon icon-arrow-down text-white text-sm font-black" />;

class RadioPlayerSmall extends React.Component {
  radioRef = React.createRef();

  state = {
    isExpanded: false,
  }

  toggleExpand = () => {
    const { isExpanded } = this.state;
    this.setState({
      isExpanded: !isExpanded,
    });
    if (isExpanded) {
      this.animateOverlay('hide');
    } else {
      this.animateOverlay('show');
    }
  }

  animateOverlay = (type = 'show') => {
    if (type === 'show') {
      const ease = Power1.easeOut;
      const tl = new TimelineMax();

      tl.fromTo('[data-name="radioCaption"]', 0.3, {
        autoAlpha: 0,
        y: '20%',
      }, {
        autoAlpha: 1,
        y: '0%',
        ease,
      });
    }

    if (type === 'hide') {
      const tl = new TimelineMax();

      tl.fromTo('[data-name="radioCaption"]', 0.3, {
        y: '20%',
      }, {
        y: '0%',
      });
    }
  }

  render() {
    const {
      match,
      ch,
      bg,
      isPrimary,
      toggleOpen,
      isOpen,
      channelUrl,
      playing,
      radioActive,
      deviceScreen,
      headerContext,
    } = this.props;
    const { isExpanded } = this.state;
    const startDate = '2019-03-01 10:00:00';
    const endDate = '2019-03-01 13:00:00';


    return (
      <RoutesContext.Consumer>
        {
        ({ language: contextLanguage, enteredFrom }) => {
          const hasParentheses = ch.image.includes(')');
          return (
            <>
              <div className={`bg-${bg} w-full flex text-12 text-white sm:py-5 sm:pt-0 relative h-radio`}>
                { ch !== null && (
                <>
                  {`ch${ch.channel}` === radioActive && playing
                    ? (
                      <div
                        className="sm:hidden h-radio"
                        style={{
                          width: '50px',
                          backgroundImage: `url(${imageModifier(ch.image, '50x50')})`,
                          backgroundSize: 'contain',
                          backgroundPosition: 'center center',
                          backgroundRepeat: 'no-repeat',
                        }}
                      />
                    ) : null}
                  {isPrimary
                    ? (
                      <div className="sm:flex md:hidden lg:hidden xl:hidden items-center justify-center cursor-pointer z-radioClose absolute pin-b pin-l pin-t ml-2 h-100" onClick={toggleOpen}>
                        {isOpen
                          ? <ExpandMore /> : <ExpandLess />
                    }
                      </div>
                    ) : null
                  }
                  <div className="flex items-center sm:pt-5 sm:ml-10 ml-4">
                    <RadioPlayButton size={36} channel={`ch${ch.channel}`} />
                  </div>
                  <div className="flex flex-col px-5 justify-center h-radio">
                    <Link
                      tabIndex={headerContext.megaMenuIsOpenDesktop ? '-1' : '2'}
                      to={channelUrl}
                      localizationpath={`/${contextLanguage}`}
                      aria-label={`${translateObj(ch.header.title, contextLanguage)}: ${translateObj(ch.title_multilang, contextLanguage)}`}
                    >
                      <h5 className="font-americaMonoBold uppercase mb-1 text-white">
                        {translateObj(ch.header.title, contextLanguage)}
                        {translateObj(ch.trackMultilang, contextLanguage)}
                      </h5>
                      <span className="font-tiemposRegular text-white">{(deviceScreen !== 'xl') && translateObj(ch.title_multilang, contextLanguage).length > 45 ? `${translateObj(ch.title_multilang, contextLanguage).substring(0, 40)}...` : translateObj(ch.title_multilang, contextLanguage)}</span>
                    </Link>
                    {/* <span className="mt-2 font-tiemposRegular ">{ ch.title_multilang && ch.title_multilang[contextLanguage]}</span> */}
                  </div>
                  <div className="sm:hidden mr-5 flex items-center ml-auto">
                    <RadioPlayIcon bgCircle="radio" bgWave="white" right="10px" channel={`ch${ch.channel}`} classNames="sm:hidden" />
                  </div>
                  {!isPrimary && isOpen
                    ? (
                      <div onClick={this.toggleExpand} className="sm:flex hidden items-center justify-center cursor-pointer z-radioClose absolute pin-b pin-l pin-t ml-2 h-100">
                        {isExpanded
                          ? <ExpandMore /> : <ExpandLess />
              }
                      </div>
                    ) : null
              }
                </>
                )
              }
              </div>
              <div data-name={`${!isPrimary ? 'radioCaption' : ''}`} className={`relative ${(isOpen && isPrimary) || isExpanded ? 'sm:block hidden bg-black p-4' : 'hidden'}`}>
                {isPrimary
                  ? (
                    <div className="items-center bg-black hidden sm:flex pb-2 justify-end">
                      <span className="font-newicons icon-rps-cupra pl-4 text-36 text-white mr-4" />
                    </div>
                  ) : null
                }
                {hasParentheses
                  ? (
                    <div className="relative launcherGradientContainer h-48 md:h-32 overflow-hidden p-4">
                      {ch.channel !== 3
                        ? <img src={imageModifier(ch.image, '445x260')} alt={translateObj(ch.title_multilang, contextLanguage)} />

                        : <img src={ch.image} alt={translateObj(ch.title_multilang, contextLanguage)} />

              }
                    </div>
                  )
                  : (
                    <div
                      className="relative launcherGradientContainer h-48 md:h-32 p-4"
                      style={{
                        backgroundImage: ch.channel !== 3 ? `url(${imageModifier(ch.image, '445x260')})` : `url(${ch.image})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center center',
                      }}
                    />
                  )

        }
                <div className="absolute pin-b pin-x sm:p-5">
                  <h2 className="font-americaBlack text-xl text-white">
                    {ch.slug ? (
                      <a className="text-white" href={ch.slug[contextLanguage]}>
                        {translateObj(ch.program_title_multilang, contextLanguage)}
                      </a>
                    ) : (
                      translateObj(ch.program_title_multilang, contextLanguage)
                    )}
                  </h2>
                </div>
              </div>
            </>
          );
        }
      }
      </RoutesContext.Consumer>
    );
  }
}

RadioPlayerSmall.propTypes = {
  match: PropTypes.shape().isRequired,
  bg: PropTypes.string,
  isPrimary: PropTypes.bool,
  isOpen: PropTypes.bool,
  toggleOpen: PropTypes.func,
  ch: PropTypes.shape().isRequired,
  headerContext: PropTypes.shape({}).isRequired,
};

RadioPlayerSmall.defaultProps = {
  bg: 'radio',
  isPrimary: false,
  isOpen: false,
  toggleOpen: null,
};

export default withRadioPlayerContext(withAllContexts(withRouter(RadioPlayerSmall)));
