import React, { useState, useEffect } from 'react';
import fetch from 'node-fetch';
import { useHistory } from 'react-router-dom';
import { useRoutes } from '../../../context/routesContext';
import { useUser } from '../../../context/userProfileContext';
import useQuery from './useQuery';
import OnDemandModalLogin from '../OnDemand/OnDemandModals/OnDemandModalLogin';
import { translations } from '../Login/LoginUtils/onDemandLoginSimpleTexts';

const QRCodeScan = () => {
  const [loading, setLoading] = useState();
  const user = useUser();
  const { language } = useRoutes();
  const history = useHistory();
  const query = useQuery();

  const loginCallback = () => {
    const redirectUrl = localStorage.getItem('qrcode');
    history.push(redirectUrl);
  };

  const getCodeResult = async (qr) => {
    setLoading(true);
    try {
      const response = await fetch(`https://m1n0q9wf03.execute-api.eu-west-3.amazonaws.com/production/page?language=en&model=Page&id=home&qr=${qr}&token=${user.token}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          qr,
          token: user.token,
        }),
      });
      setLoading(false);
      if (response) {
        const processResponse = await response.json();
        if (processResponse?.components && processResponse?.components[0].props.award) {
          history.push(`/${language}/ps23-qr-codes-results/?award=${processResponse.components[0].props.award}`);
        } else {
          if (processResponse?.components && processResponse?.components[0].props.availableCodesLeft) {
            history.push(`/${language}/ps23-qr-codes-results/?left=${processResponse.components[0].props.availableCodesLeft}`);
          } else {
            history.push(`/${language}/ps23-qr-codes-results`);
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (user?.token && user.userEmail && user.userEmail !== '') {
      const code = query.get('code');
      getCodeResult(code);
    } else {
      localStorage.setItem('qrcode', `${history.location.pathname}${history.location.search}`);
    }
  }, [user]);

  return (
    <>
      {!user?.token ? (
        <div className="bg-gray200 w-full full-width" style={{ height: '70vh' }}>
          <OnDemandModalLogin
            language={language}
            loadTokenForLS={user.loadTokenForLS}
            translations={translations}
            sourceWeb={user.sourceWeb}
            QRcodeCallback={() => loginCallback()}
          />
        </div>
      ) : (
        <div className="w-full h-full flex items-center justify-center p-4">
          {loading ? 'Checking for prize...' : 'Loading prize...'}
        </div>
      ) }
    </>
  );
};

export default QRCodeScan;
