import React from 'react';
import { PropTypes } from 'prop-types';
//import parse from 'html-react-parser';
import { withRouter, Link } from 'react-router-dom';
import moment from 'moment';
import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';
import imageModifier from '../helpers/imageModifier';
import stripTags from '../helpers/stripTags';
import RadioTag from '../Buttons/RadioTag';
import RadioItemType from '../RadioItemType';
import FavouriteStar from '../FavouriteStar';
import RadioPlayButton from './RadioPlayButton';
import translateObj from '../../../util/translateObj';

const RadioEpisodeListItem = ({
  match, title_multilang, categories_multilang, slug, parentSlug, tags, dateAir, image, favouriteStar, text_multilang, mixcloud, tags_array_multilang, id, lang,
}) => (
  <RoutesContext.Consumer>
    {({ language: contextLanguage, enteredFrom }) => {
      const localization = getLocalization(contextLanguage, enteredFrom, match);
      moment.locale(localization.language);

      let tagsArray = [];
      if (Array.isArray(tags_array_multilang)) {
        tagsArray = tags_array_multilang;
      } else {
        tagsArray.push(tags_array_multilang);
      }
      return (
        <div
          className="w-full flex flex-wrap items-center relative sm:py-2"
          style={
          {
            boxShadow: '0 4px 3px -5px rgba(0, 0, 0, 0.2)',
          }
        }
        >
          <div className="flex items-center relative">
            {/*
            <div className="text-center w-80px sm:w-8">
              <FavouriteStar favourite={favouriteStar} />
            </div> */}
            <div
              className="mr-2"
              style={{
                height: '80px',
                width: '80px',
                backgroundImage: `url(${imageModifier(image, '80x80')})`,
                backgroundSize: 'contain',
                backgroundPosition: 'center center',
                backgroundRepeat: 'no-repeat',
              }}
            />
            {mixcloud ? <div className="sm:hidden"><RadioPlayButton color="black" channel={mixcloud} /></div> : <div className="sm:hidden" style={{ width: '52px' }} />}
          </div>
          <div className="flex sm:flex-col flex-2 justify-between sm:justify-center items-center sm:items-start sm:px-1 ml-2 sm:w-2/3">
            <Link className="text-black" to={`${localization.path}/radio/shows/${parentSlug}/${slug}`}>
              {dateAir && <span className="font-americaMonoRegular text-12 mr-12">{moment(dateAir).format('DD / MM / YY')}</span>}
              <p className="font-bold m-0 text-black sm:text-xs sm:mt-1">
                {translateObj(title_multilang, localization.language)}
              </p>
              {lang ? (
                <span className="font-americaMonoRegular text-12">
                    Audio:
                  {' '}
                  {lang}
                </span>
              ) : null}
              {/* {Object.keys(categories_multilang).length ? <RadioItemType title={categories_multilang} /> : null} */}
            </Link>
            {tags_array_multilang && <div className="flex flex-wrap self-end sm:self-start sm:mt-2 justify-end sm:justify-start">{tagsArray.map((tag, i) => tag[localization.language] && <Link key={tag.en + id} to={`${localization.path}/radio/explore/${tag.en}`}><RadioTag category="transparent" text={tag} textColor="black" borderColor="black" margin="font-americaMonoRegular py-0" /></Link>)}</div>}
          </div>
        </div>
      );
    }}
  </RoutesContext.Consumer>
);

RadioEpisodeListItem.propTypes = {
  match: PropTypes.shape().isRequired,
  title_multilang: PropTypes.shape().isRequired,
  dateAir: PropTypes.string,
  image: PropTypes.string.isRequired,
  favouriteStar: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  tags: PropTypes.array,
  // eslint-disable-next-line react/require-default-props
};

RadioEpisodeListItem.defaultProps = {
  favouriteStar: false,
  tags: [],
  dateAir: null,
};

export default withRouter(RadioEpisodeListItem);
