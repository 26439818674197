import React, { createContext } from 'react';
import PropTypes from 'prop-types';

// Base Components
import Header from '../containers/Header';
import Footer from '../containers/Footer';
import Body from '../containers/Body';
import Paywall from '../containers/Paywall';
import BodyChildContainer from '../containers/BodyChildContainer';

// load all components from components folder
const AllComponents = {};

function importAll(r) {
  r.keys().forEach((key) => {
    const filename = key.match(/([a-zA-Z0-9]+)\.jsx$/);
    if (filename) AllComponents[filename[1]] = r(key).default;
  });
}

// importAll(require.context('../components/', true));
// importAll(require.context('../components/rdl/', true));
// importAll(require.context('../components/', true, /rdl\/.*$/));
// const webpackImportFilter = new RegExp(`${process.env.APP_NAME}/.*$`);
// importAll(require.context('../components/', true, new RegExp('rdl\\/.*$')));
importAll(require.context('ComponentsPath'));

const types = {
  Header,
  Footer,
  Body,
  BodyChildContainer,
  Paywall,
  ...AllComponents,
};

export const componentIteratorContext = createContext(types);

export const ComponentIteratorContextProvider = ({ children }) => (
  <componentIteratorContext.Provider value={types}>
    {children}
  </componentIteratorContext.Provider>
);

ComponentIteratorContextProvider.propTypes = {
  children: PropTypes.shape().isRequired,
};
