import gql from 'graphql-tag';

import { clientWeb } from '../graphqlAPI';

const EDIT_NOTIFICATION = gql`
  mutation Post($notificationId: String!, $read: Boolean!) {
    editNotification(notificationId: $notificationId, read: $read) {
      result
      error
      operation
    }
}`;

const editNotification = async (formData) => {
  try {
    const result = await clientWeb.mutate({
      mutation: EDIT_NOTIFICATION,
      variables: {
        ...formData,
      },
      fetchPolicy: 'no-cache',
    })
      .then(queryResult => queryResult)
      .catch((queryError) => {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error: ${queryError}`);
        return null;
      });

    const { data: { editNotification: validatedFormData } } = result;

    if (validatedFormData) {
      return validatedFormData;
    }
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: GraphQL Query Error: validatedFormData = null`);
    return null;
  } catch (error) {
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: editNotification Error: ${error}`);
    return null;
  }
};

export default editNotification;
