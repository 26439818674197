const translation = {
  buttonChat: {
    en: 'Chat',
    es: 'Chat',
    ca: 'Xat',
    pt: 'Chat',
  },
  title: {
    en: 'Recent conversations',
    es: 'Conversaciones recientes',
    ca: 'Converses recents',
    pt: 'Recent conversations',
  },
  noItemsConversations: {
    en: 'You have no active chats',
    es: 'No tienes chats activos',
    ca: 'No tens xats actius',
    pt: 'You have no active chats',
  },
  noItemsMessages: {
    en: 'You have no messages',
    es: 'No tienes mensajes',
    ca: 'No tens missatges',
    pt: 'You have no messages',
  },
  newInvitationLabel: {
    en: 'New invitation',
    es: 'Nueva invitación',
    ca: 'Nova invitació',
    pt: 'Novo convite',
  },
  deleteTitle: {
    en: 'Delete conversation',
    es: 'Eliminar conversación',
    ca: 'Eliminar conversa',
    pt: 'Apagar conversa',
  },
  deleteText: {
    en: 'Do you want to delete this conversation?',
    es: '¿Quieres eliminar esta conversación?',
    ca: 'Vols suprimir aquesta conversa?',
    pt: 'Deseja excluir esta conversa?',
  },
  buttonCancel: {
    en: 'Cancel',
    es: 'Cancelar',
    ca: 'Cancel·lar',
    pt: 'Cancelar',
  },
  buttonDelete: {
    en: 'Delete',
    es: 'Eliminar',
    ca: 'Suprimir',
    pt: 'Excluir',
  },
};

export default translation;
