import React, { useEffect, useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Animated } from 'react-animated-css';
import translateObj from '../../../util/translateObj';
import Link from '../../../util/link';
import imageModifier from '../helpers/imageModifier';

import LoadIcon from '../LoadIcon';
// import LineupTableItem from '../LineupTableItem';

import userGetFavorites from '../../../api/graphql/mutations/userGetFavorites';
import userSetFavorites from '../../../api/graphql/mutations/userSetFavorites';
import getArtistSetsByArtistSetSlugs from '../../../api/graphql/queries/getArtistSetsByArtistSetSlugs';
import { UserProfileContext } from '../../../context/userProfileContext';

const FavoriteItem = ({
  match, language, isUserLoggedIn, history, handleSetArtistSetFavorite, artistSetIsFavorite, artist: {
    artistName, artistSetReadableName, image, artistSlugName, artistSetSlugName, shortTitle, event,
  },
}) => {
  const isFavoritesActive = true;

  const [starDisabled, setStarDisabled] = useState(false);
  const [itemVislbe, setItemVislbe] = useState(true);


  const handleFavoriteLogic = (artistSetSlugNameParam, lang) => {
    if (!starDisabled) {
      if (isUserLoggedIn) {
        setItemVislbe(false);
        setStarDisabled(true);
        // logic to update user favs
        setTimeout(() => {
          handleSetArtistSetFavorite([artistSetSlugNameParam]);
        }, 650);
        setTimeout(() => {
          setStarDisabled(false);
        }, 2000);
      } else {
        // please log in
        history.push(`/${lang}/login-screen?e=sign-in`);
      }
    }
  };

  return (
    <div className="w-full">
      <Animated
        animationIn="bounceInRight"
        animationOut="bounceOutRight"
        animationInDuration={1000}
        animationOutDuration={1000}
        isVisible={itemVislbe}
        style={{ width: '100%' }}
      >
        <div className="border-b border-black relative flex items-center">
          <div className="w-24 sm:w-12">
            <div className="">{starDisabled ? <LoadIcon height={15} width={15} color="black" /> : isFavoritesActive && <span className={`font-icon cursor-pointer ${artistSetIsFavorite ? 'icon-star-solid ' : 'icon-star'} text-black pl-4 sm:pl-0`} onClick={() => handleFavoriteLogic(`${artistSlugName}|${artistSetSlugName}`, language)} />}</div>
          </div>
          <div className="w-2/3 sm:w-full flex items-center">
            <div className="flex items-center sm:my-5 my-2 ">
              {translateObj(image, language) && <img className="sm:block hidden noBackgroundImgGray" src={imageModifier(translateObj(image, language), '70x60')} alt={translateObj(artistSetReadableName, language)} />}
              {translateObj(image, language) && <img className="sm:hidden block noBackgroundImgGray" src={imageModifier(translateObj(image, language), '97x50')} alt={translateObj(artistSetReadableName, language)} />}
              <div className="flex flex-col ml-5 relative w-full pr-4">
                <div>
                  <Link to={`/artist/${artistSlugName}`}>
                    <span className="font-tiemposBold text-16 xs:text-14 text-black">
                      {artistSetReadableName && translateObj(artistSetReadableName, language) ? translateObj(artistSetReadableName, language) : artistName}
                    </span>
                  </Link>
                  <span className="text-12 uppercase text-black font-americaMonoBold sm:hidden">{shortTitle ? translateObj(shortTitle, language) : ''}</span>
                  <p className="hidden sm:block font-tiemposRegular text-14 xs:text-12 uppercase">{event.eventName}</p>
                </div>
              </div>
            </div>

          </div>
          <div className="w-1/3 sm:hidden">
            <p className="font-tiemposRegular text-16 sm:text-12 uppercase">{event.eventName}</p>
          </div>
        </div>
      </Animated>
    </div>


  );
};

FavoriteItem.propTypes = {
  artistSetIsFavorite: PropTypes.bool,
  artist: PropTypes.shape().isRequired,
  language: PropTypes.string,
  isUserLoggedIn: PropTypes.bool.isRequired,
  history: PropTypes.shape().isRequired,
  handleSetArtistSetFavorite: PropTypes.func.isRequired,
};

FavoriteItem.defaultProps = {
  artistSetIsFavorite: true,
  language: 'en',
};

const UserFavorites = ({
  history, match, userFavoritesText,
}) => {
  const userContext = useContext(UserProfileContext);

  const [userFavorites, setUserFavorites] = useState([]);
  const [favoritesExist, setFavoritesExist] = useState(null);

  const language = (match && match.params && match.params.lang.toLowerCase()) || 'en';

  const getArtistSetsBySlugs = (favArtistSetsArray) => {
    getArtistSetsByArtistSetSlugs(favArtistSetsArray).then((resartistsets) => {
      if (resartistsets.length) {
        setUserFavorites(resartistsets);
        setFavoritesExist(true);
      } else {
        setFavoritesExist(false);
      }
    });
  };

  useEffect(() => {
    const { token } = userContext;
    if (token) {
      userGetFavorites({ token, category: 'Concerts' }).then((res) => {
        if (res) {
          if (res.favorites.length) {
            const favArtistSetsArray = res.favorites.map(f => f.split('|')[1]);

            getArtistSetsBySlugs(favArtistSetsArray);
          } else {
            setFavoritesExist(false);
          }
        } else {
          console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Error - userGetFavorites failed to submit!`);
        }
      });
    } else {
      history.push(`/${language}/login-screen?e=sign-in`);
    }
  }, []);


  const handleSetArtistSetFavorite = (artistSetSlugName) => {
    const { token } = userContext;

    if (artistSetSlugName && token) {
      userSetFavorites({ token, slugnamearr: artistSetSlugName, category: 'Concerts' }).then((res) => {
        if (res) {
          if (res.favorites.length) {
            // updateLineupWithFavs(eventSlug, res.favorites);
            const favArtistSetsArray = res.favorites.map(f => f.split('|')[1]);
            getArtistSetsBySlugs(favArtistSetsArray);
          } else {
            setFavoritesExist(false);
          }
        } else {
          console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Error - userGetFavorites failed to submit!`);
        }
      })
        .catch((err) => { console.log('error getting user favorites: ', err); });
    }
  };

  return (
    <div className="p-8 w-full">
      {favoritesExist === null && (
        <LoadIcon height={35} width={35} color="black" type="ThreeDots" />
      )}
      {favoritesExist === false && (
        <div className="uppercase font-americaMonoBold mb-8">
          <span className="uppercase font-americaMonoBold">{userFavoritesText.noFavorites[language]}</span>
          <div className="font-americaMonoRegular text-12 py-3">{translateObj(userFavoritesText.addMore, language)}</div>
        </div>
      )}
      {favoritesExist === true && (
        <div>
          <span className="uppercase font-americaMonoBold mb-8 block">{userFavoritesText.favoritesList[language]}</span>
          {
            userFavorites.length && (
              <div className="w-full sm:border-t border-black">
                <div className=" bg-grayE2DDDB flex items-center sm:hidden">
                  <div className="w-24" />
                  <div className="text-left font-americaMonoRegular text-12 py-3 w-2/3">Artist</div>
                  <div className="text-left font-americaMonoRegular text-12 py-3 w-1/3">Event</div>
                </div>
                <div>
                  {userFavorites.map(uf => (
                    // <div key={uf.artistSetSlugName}>{uf.artistName}</div>
                    <FavoriteItem key={uf.artistSetSlugName} artist={uf} match={match} language={language} isUserLoggedIn={Boolean(userContext.userName)} history={history} handleSetArtistSetFavorite={handleSetArtistSetFavorite} artistSetIsFavorite />
                  ))}
                </div>
                <div className="w-full text-center font-americaMonoRegular text-12 py-3">{translateObj(userFavoritesText.addMore, language)}</div>
              </div>
            )
          }
        </div>
      )}
    </div>
  );
};

UserFavorites.propTypes = {
  history: PropTypes.shape().isRequired,
  match: PropTypes.shape().isRequired,
  userFavoritesText: PropTypes.shape().isRequired,
};

UserFavorites.defaultProps = {

};

export default withRouter(UserFavorites);
