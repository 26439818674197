import gql from 'graphql-tag';

import { clientRadio } from '../graphqlAPI';

const GET_RADIO_SCHEDULE = gql`
query radioSchedule($dateStart: DateTime!){  
  radioSchedule(dateStart: $dateStart) {
    channel
    id
    episodeId
    dateAir
    parent
    parentSlug
    slug
    mixcloud
    youtube
    duration
    lang
    highlight
    program_title_multilang{ca en es}
    title_multilang{ca en es}
    text_multilang{ca en es}
    subtitle_multilang {ca en es}
    image
    status
    isPlaylist
    tags_multilang{
      ca
      en
      es
    }
    categories_multilang{
      ca
      en
      es
    }
  }
}
`;

const getRadioShowsEpisodes = async (dateStart) => {
  if (!process.env.RADIO_GRAPHQL_SERVER_URL) {
    return [];
  }

  try {
    const result = await clientRadio.query({
      query: GET_RADIO_SCHEDULE,
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      variables: { dateStart },
      fetchResults: true,
      pollInterva: 300000,
    })
      .then(queryResult => queryResult)
      .catch((queryError) => {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error: ${queryError}`);
        return null;
      });

    const { data: { radioSchedule } } = result;

    if (radioSchedule) {
      return radioSchedule;
    }
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: GraphQL Query Error: radioProgramsAll = null`);
    return null;
  } catch (error) {
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: getRadioShowsEpisodes Error: ${error}`);
    return null;
  }
};

export default getRadioShowsEpisodes;
