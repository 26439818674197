import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useRoutes } from '../../../context/routesContext';
import useResponsiveUtility from '../../../util/responsiveUtility';
import translateObj from '../../../util/translateObj';

import Button from './Button';
import CtaUnderline from './CtaUnderline';
import Loader from './Loader';

const getContainerStyle = (sectionType, open) => {
  switch (sectionType) {
    case 'sessions':
      return 'w-1/2 pr-3 sm:w-full sm:pr-0 sm:border-b sm:border-black md:w-full md:pr-0 md:border-b md:border-black mdlg:w-full mdlg:pr-0 mdlg:border-b mdlg:border-black';
    case 'reunions':
      return 'w-1/4 px-3 sm:w-full sm:px-0 sm:border-b sm:border-black md:w-full md:px-0 md:border-b md:border-black mdlg:w-full mdlg:px-0 mdlg:border-b mdlg:border-black';
    default: return `w-1/4 pl-3 sm:w-full sm:pl-0 md:w-full md:pl-0 mdlg:w-full mdlg:pl-0 ${open ? '' : 'sm:border-b sm:border-black md:border-b md:border-black mdlg:border-b mdlg:border-black'}`;
  }
};

const getHeadingStyle = (sectionType, open) => {
  switch (sectionType) {
    case 'notifications':
      return 'w-full mb-6 sm:mb-0 md:mb-0 mdlg:mb-0 pb-6 sm:pt-6 md:pt-6 mdlg:pt-6 flex items-start justify-between h-45px sm:h-auto md:h-auto mdlg:h-auto';
    default: return `w-full mb-6 ${open ? 'sm:mb-6 md:mb-6 mdlg:mb-6' : 'sm:mb-0 md:mb-0 mdlg:mb-0'} pb-6 sm:pt-6 md:pt-6 mdlg:pt-6 flex items-start justify-start sm:justify-between md:justify-between mdlg:justify-between h-45px sm:h-auto md:h-auto mdlg:h-auto`;
  }
};

const AgendaSection = ({
  sectionType,
  title,
  buttonNewReunion,
  pendingNotifications,
  children,
  childrenContianerClassName,
  buttonLoadMore,
  loadMoreCallback,
  showLoadMore,
  loading,
  loaderClassName,
}) => {
  const { language } = useRoutes();
  const { deviceScreen } = useResponsiveUtility();
  const [open, setOpen] = useState(true);

  const containerStyle = getContainerStyle(sectionType, open);
  const headingStyle = getHeadingStyle(sectionType, open);

  return (
    <div className={`${open ? 'sm:mb-6 md:mb-6 mdlg:mb-6' : ''} ${containerStyle}`}>
      <div
        className={`${headingStyle} ${['sm', 'md', 'mdlg'].includes(deviceScreen) && !open ? 'border-none' : 'border-b border-black'} sm:cursor-pointer md:cursor-pointer mdlg:cursor-pointer`}
        onClick={['sm', 'md', 'mdlg'].includes(deviceScreen) ? () => setOpen(!open) : null}
      >
        <div className="text-black font-americaMonoRegular tracking-px text-base uppercase">{translateObj(title, language)}</div>
        <div className="flex items-center justify-end">
          {sectionType === 'notifications' && pendingNotifications > 0 && <div className="sm:mr-4 md:mr-4 mdlg:mr-4 rounded-full bg-pro-online text-white h-30px w-30px flex items-center justify-center font-americaMonoBold text-base">{pendingNotifications}</div>}
          <div
            className="hidden sm:block md:block mdlg:block font-icon icon-arrow-up text-black"
            style={{
              transform: !open ? 'rotate(-180deg)' : '',
              transition: 'transform .1s ease-in',
            }}
          />
        </div>
      </div>
      <div
        style={{
          height: ['sm', 'md', 'mdlg'].includes(deviceScreen) && !open ? '0' : 'auto',
          pointerEvents: ['sm', 'md', 'mdlg'].includes(deviceScreen) && !open ? 'none' : 'auto',
        }}
      >
        <div
          style={{
            display: ['sm', 'md', 'mdlg'].includes(deviceScreen) && !open ? 'none' : 'block',
          }}
        >
          {loading ? <Loader label={title} className={loaderClassName} /> : (
            <div className={`${childrenContianerClassName} w-full`}>
              {children}
              {sectionType === 'reunions' && (
              <Button
                text={translateObj(buttonNewReunion, language)}
                color="black"
                plusIcon
                className="w-2/3 xl:w-1/2 sm:w-1/2 md:w-1/3 mdlg:w-1/3 pb-6 mt-8 md:mt-0"
                link="/pro-create-reunion"
              />
              )}
              {showLoadMore && children.length > 0 && (
              <CtaUnderline
                text={translateObj(buttonLoadMore, language)}
                onClickCallback={loadMoreCallback}
                className={`md:ml-4 ${sectionType === 'notifications' ? 'mt-6' : ''}`}
              />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

AgendaSection.propTypes = {
  sectionType: PropTypes.string,
  title: PropTypes.shape(),
  buttonNewReunion: PropTypes.shape(),
  pendingNotifications: PropTypes.number,
  children: PropTypes.node,
  childrenContianerClassName: PropTypes.string,
  buttonLoadMore: PropTypes.shape(),
  loadMoreCallback: PropTypes.func,
  showLoadMore: PropTypes.bool,
  loading: PropTypes.bool,
  loaderClassName: PropTypes.string,
};

AgendaSection.defaultProps = {
  sectionType: '',
  title: {},
  buttonNewReunion: {},
  pendingNotifications: 0,
  children: null,
  childrenContianerClassName: '',
  buttonLoadMore: {},
  loadMoreCallback: null,
  showLoadMore: true,
  loading: false,
  loaderClassName: '',
};

export default AgendaSection;
