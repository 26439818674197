import React from 'react';
import { withRouter } from 'react-router-dom';

import { PropTypes } from 'prop-types';
import { useTheme } from '../context/themeContext';
import useWhisperModalManagement from '../hooks/useWhisperModalManagement';
import ViewerCard from './ViewerCard';
import { useChat } from '../context/chatContext';
import { useRoutes } from '../../../../context/routesContext';
import { inviteToChannel, createPrivateChannel } from '../utils/helpers';

const UserListItem = ({
  message, cardOwner, channel, match, isNormalCard,
}) => {
  const {
    notLogged, userId, userData,
  } = useChat();

  const { color } = useTheme();
  const {
    isWhisperModalOpened,
    openWhisperModal,
    closeWhisperModal,
  } = useWhisperModalManagement();


  const handleSendInvitation = async () => {
    // console.log(channel);
    inviteToChannel({ channelUrl: channel, inviter: userData, invited: cardOwner }).then(res => console.log(res));
    closeWhisperModal();
  };

  const handleSendNormalInvitation = async () => {
    createPrivateChannel({ creator: userData, invited: cardOwner }).then(res => console.log(res)).catch(err => console.log(err));
    closeWhisperModal();
  };

  return (
    <div className={`relative px-4 break-words leading-normal text-${color}`}>
      <span
        onClick={!notLogged && cardOwner.userId !== userData.userId ? openWhisperModal : null}
        className={`font-americaBold break-all ${!notLogged && cardOwner.userId !== userData.userId ? 'cursor-pointer' : ''}`}
        style={{ color: cardOwner.nicknameColor }}
      >
        {cardOwner.nickname}
      </span>
      {isWhisperModalOpened && (
      <ViewerCard
        messageOwner={cardOwner}
        buttonText={!isNormalCard ? 'Invite' : 'Private message'}
        handleSendInvitation={!isNormalCard ? handleSendInvitation : handleSendNormalInvitation}
        closeWhisperModal={closeWhisperModal}
        nicknameColor={cardOwner.nicknameColor}
      />
      )}
    </div>
  );
};

UserListItem.propTypes = {
  message: PropTypes.string,
};
UserListItem.defaultProps = {
  message: '',
};

export default withRouter(UserListItem);
