import * as React from 'react';

const DeleteIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1.2em"
    height="1.2em"
    fill="none"
    {...props}
  >
    <path
      stroke="#E43531"
      d="m5.864 5.657 6.008 6.008m-6.465-.25 6.008-6.008m2.357-2.15A7.435 7.435 0 1 1 3.257 13.772 7.435 7.435 0 0 1 13.772 3.257Z"
    />
  </svg>
)
export default DeleteIcon;
