import React from 'react';

import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import { withRouter } from 'react-router-dom';
import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';
import translateObj from '../../../util/translateObj';

import ButtonTag from '../Buttons/ButtonTag';
import NormalBodyText from '../Text/NormalBodyText';

const ExpandLess = () => (
  <span className="font-icon icon-arrow-up text-white text-sm" />
);
const ExpandMore = () => (
  <span className="font-icon icon-arrow-down text-black text-sm font-black" />
);

const style = {
  list: {
    padding: 0,
    marginBottom: 30,

  },
  root: {
    backgroundColor: 'black',
    height: '2px',
    // border: 'solid 1px #000000',
  },
};

const getStyle = (selected, border, listItemStyle) => {
  if (selected && !border) {
    return {
      color: 'white',
      backgroundColor: 'black',
    };
  }
  if (border) {
    return null;
  }

  return listItemStyle;
};

const getTextStyle = (selected, border) => {
  if (selected && !border) {
    return {
      color: 'white',
    };
  }
};

const getBorder = (border, lowercase) => {
  if (border && lowercase === false) {
    return {
      paddingLeft: '2rem',
      height: 64,
    };
  }
  if (lowercase) {
    return {
      height: 'auto',
      minHeight: 74,
      paddingTop: '2rem',
      paddingBottom: '2rem',
    };
  }
  return {
    marginBottom: 10,
    padding: '2rem',
  };
};

class CollapsibleListSimplified extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropDown: '',
    };
  }

  componentDidMount() {
    const { params, lowercase, firstClosed } = this.props;
    !lowercase
      && !firstClosed
      && this.setState({
        dropDown: params[0].text.en,
      });
  }

  handleClick = (type) => {
    let dropDown = type;
    this.setState((state) => {
      if (dropDown === state.dropDown) dropDown = '';
      return { dropDown };
    });
  };

  render() {
    const {
      params,
      children,
      classes,
      color,
      border,
      lowercase,
      match,
      firstClosed,
    } = this.props;

    const listItemStyle = color === 'white'
      ? {
        backgroundColor: 'rgba(255,255,255,0.9)',
      }
      : {};
    const { dropDown } = this.state;
    return (
      <RoutesContext.Consumer>
        {({ language: contextLanguage, enteredFrom }) => {
          const localization = getLocalization(
            contextLanguage,
            enteredFrom,
            match,
          );
          return (
            <div className={`${color === 'white' && 'my-4'} w-full `}>
              {border && <Divider classes={{ root: classes.root }} />}
              <List classes={{ root: classes.list }}>
                {params.map(({
                  title, paragraph, button, i,
                }) => (
                  <React.Fragment key={title.en + i}>
                    <ListItem
                      style={{
                        ...getStyle(
                          dropDown === title.en,
                          border,
                          listItemStyle,
                        ),
                        ...getBorder(border, lowercase),
                        backgroundColor: 'transparent',
                      }}
                      value={title.en}
                      button
                      onClick={() => this.handleClick(title.en)}
                      disableRipple
                      disableTouchRipple
                    >
                      {button && (
                      <div className="sm:hidden w-48">
                        <ButtonTag {...button} />
                      </div>
                      )}

                      {lowercase ? (
                        <ListItemText
                          primary={(
                            <span
                              className={`${
                                border
                                  ? 'font-tiemposBold'
                                  : 'font-americaMonoBold'
                              }`}
                              style={getTextStyle(
                                dropDown === title.en,
                                border,
                              )}
                            >
                              {`\ ${translateObj(title, localization.language)}
`}
                            </span>
                            )}
                        />
                      ) : (
                        <ListItemText
                          primary={(
                            <span
                              className={`uppercase ${
                                border
                                  ? 'font-americaMonoMedium'
                                  : 'font-americaMonoBold'
                              }`}
                              style={getTextStyle(
                                dropDown === title.en,
                                border,
                              )}
                            >
                              {`\\${translateObj(
                                title,
                                localization.language,
                              )}`}
                            </span>
                              )}
                        />
                      )}

                      {border ? (
                        dropDown === title.en ? (
                          <span className="mr-4 font-icon text-10 icon-less" />
                        ) : (
                          <span className="mr-4 font-icon icon-plus text-10" />
                        )
                      ) : (
                        <span className="mr-4">
                          {dropDown === title.en ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )}
                        </span>
                      )}
                    </ListItem>
                    {border && dropDown !== title.en && (
                    <Divider classes={{ root: classes.root }} />
                    )}
                    <Collapse
                      in={dropDown === title.en}
                      timeout="auto"
                      unmountOnExit
                      classes={{ entered: 'none' }}
                    >
                      <div
                        className={`flex flex-wrap ${!border
                            && !firstClosed
                            && '-mx-2'} mb-4 ${firstClosed && 'flex-col'}`}
                      >
                        <NormalBodyText text={paragraph} classes="text-sm" />
                      </div>
                      {border && <Divider classes={{ root: classes.root }} />}
                    </Collapse>
                  </React.Fragment>
                ))}
              </List>
            </div>
          );
        }}
      </RoutesContext.Consumer>
    );
  }
}

CollapsibleListSimplified.propTypes = {
  classes: PropTypes.shape({}),
  params: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  border: PropTypes.bool,
  lowercase: PropTypes.bool,
  firstClosed: PropTypes.bool,
};

CollapsibleListSimplified.defaultProps = {
  border: false,
  lowercase: false,
  firstClosed: false,
  classes: '',
};

export default withRouter(withStyles(style)(CollapsibleListSimplified));
