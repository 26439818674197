/* eslint-disable no-nested-ternary */
import React from 'react';
import { PropTypes } from 'prop-types';

const ZoneCardIframeWithinStreamModule = ({
  url, handleCloseStreamModule,
}) => (
  <>
    <div className="full-width w-full">
      <div onClick={handleCloseStreamModule} className="absolute z-10 pin-r pin-t mr-4 mt-4 w-4 h-4 cursor-pointer">
        <span className="w-4 h-4 text-white font-icon icon-close cursor-pointer" />
      </div>
      <div className={`${'player-wrapper w-full bg-black'} `}>
        <iframe allow="camera; microphone" title="iframe" src={url} allowFullScreen frameBorder="0" className="absolute pin-t pin-l w-full h-full sliderBackground" />
      </div>
    </div>
  </>
);


ZoneCardIframeWithinStreamModule.propTypes = {
  url: PropTypes.string.isRequired,
  handleCloseStreamModule: PropTypes.func.isRequired,
};

ZoneCardIframeWithinStreamModule.defaultProps = {
};

export default ZoneCardIframeWithinStreamModule;
