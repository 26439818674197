import React from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';
import Link from '../../../util/link';

import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';
import translateObj from '../../../util/translateObj';

const LinkButtonRounded = ({
  iconUrl, text, url, category, margin, match, className, slider, isDisabled, is404, layout,
}) => (
  <RoutesContext.Consumer>
    {({ language: contextLanguage, enteredFrom }) => {
      const localization = getLocalization(contextLanguage, enteredFrom, match);
      let usableUrl = url;
      if (url.substring(0, 1) !== '/' && !/^https?:\/\//.test(url)) {
        usableUrl = `/${url}`;
      }
      if (url.length === 1 && url === '/') {
        usableUrl = '';
      }
      if (is404 && match.params && match.params.hasOwnProperty('post')) {
        usableUrl = `/${match.params.section || ''}`;
      }

      const UnderlinedLink = () => (
        <div
          className={
            className
              ? `${className}`
              : `${isDisabled ? 'hover:none text-disabled' : ''} text-white uppercase text-xs font-americaMonoBold ${margin}`
          }
        >
          <span className="mr-2">{">"}</span>
          <span className="underline hover:no-underline">{translateObj(text, localization.language)}</span>
        </div>
      );

      const Button = () => (
        <span
          className={
            className
              ? `${className}`
              : `${isDisabled ? 'hover:none text-disabled' : 'hover:bg-white hover:text-black text-white'} whitespace-pre trans rounded-full uppercase sm:text-12
          md:text-12 lg:text-12 py-3 md:px-8 px-12 font-americaMonoBold ${slider ? 'mt-6 text-sm' : 'text-base'}
          ${margin}
          bg-${category} border-2 ${category === 'transparent' ? (`${isDisabled ? 'border-disabled' : 'border-white'}`) : `border-${category}`}`
          }
        >
          {iconUrl && <img className="pr-4" src={iconUrl} alt={translateObj(text, localization.language)} />}
          {translateObj(text, localization.language)}
        </span>
      );

      return (
        <React.Fragment>
          {isDisabled ? (
            <>
              {layout === "tickets" ? <UnderlinedLink /> : <Button />}
            </>
          ) : (
            <Link to={`${usableUrl}`} localizationpath={`${localization.path}`} className={`${slider && 'mb-5 sm:mb-3 sm:pt-6'} block`} aria-label={translateObj(text, localization.language)} role="button">
              {layout === "tickets" ? <UnderlinedLink /> : <Button />}
            </Link>
          )}
        </React.Fragment>
      );
    }}
  </RoutesContext.Consumer>
);

LinkButtonRounded.propTypes = {
  iconUrl: PropTypes.string,
  category: PropTypes.string,
  margin: PropTypes.string,
  match: PropTypes.shape().isRequired,
  text: PropTypes.shape().isRequired,
  url: PropTypes.string,
  className: PropTypes.string,
  slider: PropTypes.bool,
  isDisabled: PropTypes.bool,
  is404: PropTypes.bool,
  layout: PropTypes.string,
};

LinkButtonRounded.defaultProps = {
  iconUrl: null,
  category: 'transparent',
  margin: '',
  className: '',
  url: null,
  slider: false,
  isDisabled: false,
  is404: false,
  layout: null,
};

export default withRouter(LinkButtonRounded);
