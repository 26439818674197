import style from 'styled-components';

const getUserPanelGrid = (isSpotifyLinked) => {
  const spotifyLinkedGrid = style.div`
  height: 100%;
  min-height: 100%;
  min-height: -webkit-fill-available;
  padding: 20px;
  margin-top: -120px;
  display: grid;
  grid-template-columns: 100%;
  grid-auto-rows: auto;
  grid-template-areas:
          "score"
          "rewards"
          "rewards"
          "achievements"
          "favorites"
          "favorites"
          "genres"
          "festivals"
          "publi"
          "cards"
          "cards"
          "cards"
          "radio";
  gap: 10px;
  @media (min-width: 980px) {
      grid-template-columns: 50% 50%;
      grid-template-areas:
          "score rewards"
          "favorites rewards"
          "genres achievements"
          "festivals cards"
          "publi cards"
          "radio cards";
  }
  @media (min-width: 1380px) {
      grid-template-columns: 33% 33% 33%;
      grid-template-areas:
          "score favorites cards"
          "rewards favorites cards"
          "achievements genres cards"
          "publi festivals radio";
  }
`;
  const noSpotifyLinkedGrid = style.div`
  height: 100%;
  min-height: 100%;
  min-height: -webkit-fill-available;
  padding: 20px;
  margin-top: -120px;
  display: grid;
  grid-template-columns: 100%;
  grid-auto-rows: auto;
  grid-template-areas:
          "score"
          "rewards"
          "rewards"
          "achievements"
          "favorites"
          "favorites"
          "genres"
          "festivals"
          "publi"
          "cards"
          "cards"
          "cards"
          "radio"
          "spotify";
  gap: 10px;
  @media (min-width: 980px) {
      grid-template-columns: 50% 50%;
      grid-template-areas:
          "score rewards"
          "favorites rewards"
          "genres achievements"
          "festivals cards"
          "publi cards"
          "radio cards"
          "spotify cards";
  }
  @media (min-width: 1380px) {
      grid-template-columns: 33% 33% 33%;
      grid-template-areas:
          "score favorites cards"
          "rewards favorites cards"
          "rewards genres cards"
          "achievements festivals cards"
          "publi spotify radio";
  }
`;
  return isSpotifyLinked ? spotifyLinkedGrid : noSpotifyLinkedGrid;
};

export default getUserPanelGrid;
