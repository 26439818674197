import React, { useState } from 'react';
import PropTypes from 'prop-types';
import OnDemandModalLogin from './OnDemandModalLogin';
import OnDemandModalRegister from './OnDemandModalRegister';
import IncompleteModalForm from './IncompleteProfile';
import { useUser } from '../../../../context/userProfileContext';

const OnDemandModalNotLogged = ({ isFromPro, ...props }) => {
  const [isRegistering, setIsRegistering] = useState(false);
  const [userSocialInfo, setUserSocialInfo] = useState(null);
  const { userCountry, userEmail, userPostalCode } = useUser();

  const needsToCompleteRegistration = userEmail && (!userCountry || !userPostalCode);

  const handleRegisteringStatus = (registeringStatus = false, userPayload, socialNetwork) => {
    setUserSocialInfo(userPayload || {});
    setIsRegistering(registeringStatus);
  };

  if (isFromPro && needsToCompleteRegistration) return <IncompleteModalForm {...props} />;

  return (
    <>
      {
        isRegistering
          ? <OnDemandModalRegister {...props} userSocialInfo={userSocialInfo} />
          : <OnDemandModalLogin {...props} handleRegisteringStatus={handleRegisteringStatus} />
      }
    </>
  );
};

OnDemandModalNotLogged.propTypes = {
  isFromPro: PropTypes.bool,
  language: PropTypes.string,
  loadTokenForLS: PropTypes.func.isRequired,
  handleOnDemandScreenStatus: PropTypes.func.isRequired,
  translations: PropTypes.shape().isRequired,
};

OnDemandModalNotLogged.defaultProps = {
  isFromPro: false,
  language: 'en',
};

export default OnDemandModalNotLogged;
