import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';

import Button from './Button';

import { useRoutes } from '../../../context/routesContext';
import translateObj from '../../../util/translateObj';
import validateEmail from './util/formValidations/validateEmail';
import postNewsletterForm from '../../../api/graphql/mutations/postNewsletterForm';

const Newsletter = ({ title, buttonLabel, placeholder }) => {
  const { language } = useRoutes();

  const handleSubmit = (formValues, formProps) => {
    postNewsletterForm({ email: formValues.email, language }).then((res) => {
      if (res && res.isValid) {
        console.log('Subscribed to newsletter successfully');
        formProps.resetForm();
      }
    }).catch(err => console.log(err));
  };

  return (
    <div className="w-full sm:flex sm:flex-col sm:items-center">
      <div className="uppercase text-12 mb-6 sm:mb-4">{translateObj(title, language)}</div>
      <Formik
        initialValues={{
          email: '',
        }}
        validate={values => validateEmail(values)}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(values, props) => handleSubmit(values, props)}
      >
        {(props) => {
          // eslint-disable-next-line react/prop-types
          const { values, errors, setErrors } = props;
          if (Object.keys(errors).length > 0 && values.email === '') {
            setErrors({});
          }
          return (
            <Form className="relative">
              <div className="border-2 border-black rounded-full pl-6 flex justify-between items-center">
                <Field
                  name="email"
                  placeholder={translateObj(placeholder, language)}
                  required
                  className="w-2/3 pr-2 lg:w-full lg:pr-6 llg:w-full llg:pr-6 sm:w-full sm:pr-6"
                />
                <Button type="submit" text={translateObj(buttonLabel, language)} arrowRight className="h-full" />
              </div>
              {values.email && errors.email && <div className="font-americaMonoRegular text-10 -mb-5 text-pro-online absolute pin-b pin-l">{errors.email}</div>}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

Newsletter.propTypes = {
  title: PropTypes.shape(),
  buttonLabel: PropTypes.shape(),
  placeholder: PropTypes.shape(),
};

Newsletter.defaultProps = {
  title: {},
  buttonLabel: {},
  placeholder: {},
};

export default Newsletter;
