import gql from 'graphql-tag';

import { clientWeb } from '../graphqlAPI';

const SUGGEST_ARTISTS = gql`
  mutation Post($suggestion: [Suggestion]!, $eventSlugname: String!) {
    suggestLineupArtists(
      suggestion: $suggestion
      eventSlugname: $eventSlugname
      ) {
        success
        message
    }
  }`;

const suggestLineupArtists = async (formData) => {
  try {
    const result = await clientWeb.mutate({
      mutation: SUGGEST_ARTISTS,
      variables: {
        ...formData,
      },
      fetchPolicy: 'no-cache',
    })
      .then(queryResult => queryResult)
      .catch((queryError) => {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error: ${queryError}`);
        return null;
      });

    const { data: { suggestLineupArtists: suggestionResponse } } = result;

    if (suggestionResponse) return suggestionResponse;
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: GraphQL Query Error: suggestLineupArtists = null`);
    return null;
  } catch (error) {
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: suggestLineupArtists Error: ${error}`);
    return null;
  }
};

export default suggestLineupArtists;
