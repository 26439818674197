import React from 'react';
import { PropTypes } from 'prop-types';

import { withRouter } from 'react-router-dom';
import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';
import translateObj from '../../util/translateObj';

const Suggestion = ({ title, href }) => (
  <li className="mb-2"><a className="text-black hover:underline" href={href}>{title}</a></li>
);

const SearchSuggestions = ({
  match,
  text1,
  suggestions,
}) => (
  <RoutesContext.Consumer>
    {
      ({ language: contextLanguage, enteredFrom }) => {
        const localization = getLocalization(contextLanguage, enteredFrom, match);

        return (
          <div className="md:sm-12 md:mt-12 pd:sm-12 md:pt-12 md:border-solid md:border-black md:border-t-2 sm:sm-12 sm:mt-12 pd:sm-12 sm:pt-12 sm:border-solid sm:border-black sm:border-t-2">
            <span className="font-americaMonoBold uppercase pb-8 block">{translateObj(text1, localization.language)}</span>
            <ol className="font-americaBlack text-lg pl-6">
              {suggestions.map((suggestion, index) => (
                // TODO: user id instead of index if available
                // eslint-disable-next-line react/no-array-index-key
                <Suggestion key={index} title={suggestion.title} href={suggestion.href} />
              ))}
            </ol>
          </div>
        );
      }
    }
  </RoutesContext.Consumer>
);

SearchSuggestions.propTypes = {
  match: PropTypes.shape().isRequired,
  text1: PropTypes.shape().isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  suggestions: PropTypes.array.isRequired,
};

Suggestion.propTypes = {
  title: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
};

export default withRouter(SearchSuggestions);
