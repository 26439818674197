/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';

import StreamingVideoPlayerOneForAllPlayerOriginal from './StreamingVideoPlayerOneForAllPlayerOriginal';

const StreamingVideoPlayerOriginal = ({
  videos,
  controls,
  autoPlay,
  muted,
}) => {
  const [arrayOfVideos, setArrayOfVideos] = useState({}); // TODO: include default main video in case
  const [shouldSmallVideoStart, setShouldSmallVideoStart] = useState(false);

  // useEffect(() => {
  //   if (window) { console.log('window navigator vendor!!!', window.navigator.vendor); }
  // }, []);

  useEffect(() => {
    if (videos && videos.length) {
      setArrayOfVideos(videos);
    }
  }, [videos]);


  const handleSwitchingVideos = (videoId) => {
    setTimeout(() => {
      const currentVideos = [...arrayOfVideos];
      const newArrOfVideos = currentVideos.map((video) => {
        if (video.id === videoId) {
          video.active = true;
        } else {
          video.active = false;
        }
        return video;
      });
      newArrOfVideos.sort((a, b) => ((a.id > b.id) ? 1 : -1)).sort((a, b) => ((a.active < b.active) ? 1 : -1));
      setArrayOfVideos(newArrOfVideos);
    }, 500);
  };

  const handlePlayingSmallScreens = (shouldStart, id) => {
    setShouldSmallVideoStart(shouldStart);
  };

  return (
    <>
      <div
        className="relative w-full"
        style={{ paddingTop: '56.25%' }}
      >
        {arrayOfVideos.length >= 0 && (
          <>
            {arrayOfVideos.map((videoObject, index) => (
              <StreamingVideoPlayerOneForAllPlayerOriginal videoObject={videoObject} handleSwitchingVideos={handleSwitchingVideos} key={videoObject.id} index={index} handlePlayingSmallScreens={handlePlayingSmallScreens} shouldSmallVideoStart={shouldSmallVideoStart} length={arrayOfVideos.length} />
            )) }
          </>
        )}
      </div>
    </>
  );
};

StreamingVideoPlayerOriginal.propTypes = {
  videos: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  controls: PropTypes.bool,
  muted: PropTypes.bool,
  autoPlay: PropTypes.bool,
};

StreamingVideoPlayerOriginal.defaultProps = {
  controls: false,
  muted: true,
  autoPlay: true,
};

export default StreamingVideoPlayerOriginal;

// shouldHide={smallVideo.active}
