import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
// import Hammer from 'hammerjs';
import ComponentIterator from '../../containers/ComponentIterator';
import PSResponsiveUtility from './LineUp/PSResponsiveUtility';

const settings = {
  sensitivity: 25,
  animatingClass: 'carousel75-is-animating',
};

export default class Carousel75 extends Component {
  responsiveUtility = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      slideCount: props.components.length,
      activeSlide: 0,
      deviceWidth: null,
    };
    this.slider = React.createRef();
    this.timer;
  }

  componentDidMount() {
    require('hammerjs');
    this.getWidth();
  }

  getWidth = () => {
    // actions when window is resized
    const deviceWidth = this.responsiveUtility.current.deviceWidth(true);
    this.setState({ deviceWidth }, () => this.initHammer());
  };

  initHammer() {
    const that = this;
    const { slideCount, deviceWidth } = this.state;
    const sliderManager = new Hammer.Manager(this.slider.current);
    sliderManager.add(new Hammer.Pan({ threshold: 0, pointers: 0, direction: Hammer.DIRECTION_HORIZONTAL }));
    sliderManager.on('pan', (e) => {
      const { activeSlide } = that.state;
      const percentage = ((100 / slideCount) * e.deltaX) / deviceWidth;
      const transformPercentage = percentage - (100 / slideCount) * activeSlide;
      that.slider.current.style.transform = `translateX( ${transformPercentage}% )`;
      if (e.isFinal) {
        if (e.velocityX > 1) {
          that.goTo(activeSlide - 1);
        } else if (e.velocityX < -1) {
          that.goTo(activeSlide + 1);
        } else {
          if (percentage <= -(settings.sensitivity / slideCount)) {
            that.goTo(activeSlide + 1);
          } else if (percentage >= settings.sensitivity / slideCount) {
            that.goTo(activeSlide - 1);
          } else {
            that.goTo(activeSlide);
          }
        }
      }
    });
  }

  goTo(number) {
    let { slideCount, activeSlide } = this.state;

    if (number < 0) {
      activeSlide = 0;
    } else if (number > slideCount - 1) {
      activeSlide = slideCount - 1;
    } else {
      activeSlide = number;
    }

    this.slider.current.classList.add(settings.animatingClass);
    const percentage = -(100 / slideCount) * activeSlide;
    this.slider.current.style.transform = `translateX(${percentage}%)`;
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.slider.current.classList.remove(settings.animatingClass);
    }, 400);

    this.setState({ activeSlide });
  }

  render() {
    const { components } = this.props;
    const { slideCount, activeSlide, deviceWidth } = this.state;

    return (
      <div className="">
        <PSResponsiveUtility ref={this.responsiveUtility} deviceWidthCallback={this.getWidth} />
        <div className="overflow-hidden w-full">
          <div ref={this.slider} className="relative flex flex-no-wrap overflow-x-visible overflow-y-hidden" style={{ width: `${80 * slideCount}%` }}>
            {components.map((component, index) => (
              <div key={index} className="w-full">
                <div className="mr-4">
                  <ComponentIterator components={[component]} />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="flex mt-4">
          {components.map((component, index) => (
            <div key={index} className={`mr-1 border-solid border-black border-1 h-2 w-2 rounded ${index == activeSlide ? 'bg-black' : ''}`} />
          ))}
        </div>
      </div>
    );
  }
}

Carousel75.propTypes = {
  components: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
  classNames: PropTypes.string,
};

Carousel75.defaultProps = {
  classNames: '',
};
