import React from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import Link from '../../util/link';
import RoutesContext from '../../context/routesContext';
import translateObj from '../../util/translateObj';
import getLocalization from '../../util/localization';
import imageModifier from './helpers/imageModifier';
import ImageLoader from './Launcher/ImageLoader';

const Poster = ({
  image, slug, match, width, text,
}) => (
  <RoutesContext.Consumer>
    {({ language: contextLanguage, enteredFrom }) => {
      const localization = getLocalization(contextLanguage, enteredFrom, match);
      return (
        <div className={`no-underline px-2 py-8px sm:w-full md:w-1/2 w-${width} text-black`}>
          <Link to={slug} className="w-full" localizationpath={localization.path}>
            <ImageLoader className="noBackgroundImgGray min-w-full" src={imageModifier(translateObj(image, localization.language), '1000x1400', true)} alt={translateObj(text, localization.language)} />
          </Link>
        </div>
      );
    }}
  </RoutesContext.Consumer>
);


export default withRouter(Poster);

Poster.propTypes = {
  image: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape(),
  ]).isRequired,
  match: PropTypes.shape().isRequired,
  slug: PropTypes.string.isRequired,
  text: PropTypes.shape().isRequired,
  width: PropTypes.string,
};

Poster.defaultProps = {
  width: '1/2',
};
