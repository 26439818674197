import React, { useEffect, useState, useRef } from 'react';
import searchArtists from '../../api/graphql/queries/searchArtists';
import useDebounce from '../../util/useDebounce';
import SpotifyLogin from './Login/SpotifyLogin/SpotifyLogin';
import { useRoutes } from '../../context/routesContext';
import translateObj from '../../util/translateObj';
import { useAuth } from '@primaveralabs/auth';
import getUserData from '../../api/graphql/queries/getUserData';
import LoadIcon from './LoadIcon';
import Spacer from './Spacer';
import useResponsiveUtility from '../../util/responsiveUtility';
import Button from './MusicMeets/Button';
import suggestLineupArtists from '../../api/graphql/mutations/suggestLineupArtists';
import localTranslations from '../../util/translations';

const SEARCH_RESULT_PLACEHOLDER = "https://assets-img.primaverasound.com/100x100/psweb/egouhwvyxs4eczvdfmzp_1721114794086.png";
const STEP_PLACEHOLDER = "https://assets-img.primaverasound.com/200x200/psweb/mlrkzlhw0zz3sx3jrsaz_1721206018025.png";

const checkIfArtistsIsInOtherSteps = ({ item, steps, currentStep }) => {
  return steps.some((step, index) => index !== currentStep && step.answer?.slug === item.slug);
};

const Results = ({ results, query, handleInputChange, language, handleSelect, handleViewMore, steps, currentStep, translations, resultsRef }) => (
  <div className="relative w-full max-w-md" ref={resultsRef}>
    <div className="flex items-center rounded-md bg-muted py-2 px-4 border border-black rounded-full">
      <span className="font-icon icon-search text-base" />
      <input
        type="text"
        placeholder={translateObj(translations.searchBarPlaceholder, language)}
        value={query}
        onChange={handleInputChange}
        className="ml-2 w-full bg-transparent text-foreground focus:outline-none"
      />
    </div>
    <div className="relative">
      {results?.length > 0 && (
        <div className="flex flex-col absolute pin-t pin-l pin-r z-top">
          <div className="flex flex-col">
            <ul className="flex flex-col items-center list-reset justify-between p-0 bg-grey-d9 border-grey border rounded-b-lg">
              {results?.map((result) => {
                const isAnswerInOtherSteps = checkIfArtistsIsInOtherSteps({item: result, steps, currentStep});
                return (
                  <li key={result.id} className={`pl-10 py-2 flex items-center w-full border-b border-grey ${isAnswerInOtherSteps ? 'opacity-40' : 'cursor-pointer'}`} onClick={() => !isAnswerInOtherSteps && handleSelect(result)}>
                    <div className="relative w-12 h-12 overflow-hidden rounded-full">
                      <img
                        src={result.images[1]?.url || SEARCH_RESULT_PLACEHOLDER}
                        alt="Item image"
                        className="absolute top-1/2 left-1/2 w-auto h-auto min-w-full min-h-full transform -translate-x-1/2 -translate-y-1/2"
                      />
                    </div>
                    <div className="flex-1 px-2">
                      <p className="text-base font-americaRegular">{result.name}</p>
                    </div> 
                  </li>
              )})}
              <li className="w-full px-4 py-2 hover:bg-muted transition-colors bg-white cursor-pointer rounded-b-lg" onClick={handleViewMore}>
                View More
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
    </div>
);

const Step = ({ language, handleBack, handleNext, steps, currentStep, isMobile, handleStep, translations, isLastStep, hasAnsweredAllSteps }) => (
  <div className="w-full table:px-10 desktop:px-16 flex flex-col items-center">
    <p className="font-americaRegular font-bold text-sm text-center">{translateObj(translations.stepsInstructionsText, language)}</p>
    <Spacer className="mt-4" />
    <div className="flex w-full tablet:w-7/8 desktop:w-800 sm:px-0 px-7 m-auto">
      <div className="flex sm:flex-col sm:h-24 items-start px-4 py-2 bg-black rounded-full flex-1 items-center text-white">
        <span className="text-sm font-americaMonoRegular">{`${currentStep + 1}/${steps.length}`}</span>
        <div className="ml-6 sm:ml-0 flex flex-1 justify-center items-center">
          <h1 className='sm:text-center xs:text-base xssm:text-lg md:text-lg text-xl font-americaRegular'>{translateObj(steps[currentStep].question, language)}</h1>
        </div>
      </div>
      </div>
      <Spacer className="mt-10" />
      <div className={`flex w-full max-w-lg ${isMobile ? 'justify-center' : 'justify-between'}`}>
        <button className="font-icon icon-arrow-left text-2xl sm:h-32 md:h-20 h-24" onClick={handleBack} disabled={currentStep === 0} />
        {isMobile ? (
          <div className="flex flex-col items-center">
            <div className="relative w-32 h-32 mx-8 overflow-hidden rounded-full">
              <img
                src={steps[currentStep].answer?.images?.[1]?.url || STEP_PLACEHOLDER}
                alt={steps[currentStep].answer?.name || 'select artist'}
                className="min-w-full min-h-full"
              />
            </div>
            <div className="mt-3 w-32 h-8">
              <p className="font-americaRegular text-base text-center" style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{steps[currentStep].answer?.name}</p>
            </div>
          </div>
        ) : steps.map((step, index) => (
            <div className="flex flex-col items-center" key={step.id}>
              <button className={`relative md:w-20 md:h-20 w-24 h-24 overflow-hidden rounded-full ${currentStep === index ? "" : "opacity-40"}`} onClick={() => handleStep(index)}>
                <img
                  src={step.answer?.images?.[1]?.url || STEP_PLACEHOLDER}
                  alt={step.answer?.name || 'select artist'}
                  className="w-auto h-auto min-w-full min-h-full"
                />
              </button>
              <div className="mt-3 md:w-20 w-32 h-8">
                <p className="font-americaRegular text-base text-center" style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{step.answer?.name}</p>
              </div>
            </div>
          ))}
        <button className="font-icon icon-arrow-right text-2xl sm:h-32 md:h-20 h-24" disabled={isLastStep} onClick={handleNext} />
      </div>
      <div className="sm:mt-4 sm:mt-0 mt-6 h-9 flex justify-center">
        {
          isLastStep ? <Button text={translateObj(translations.allStepsAnweredContinue, language)} color="black" onClickCallback={handleNext} className="w-auto h-auto" disabled={!hasAnsweredAllSteps}/>
          : null
        }
      </div>
  </div>
)

const SuggestedArtists = ({ step }) => (
  <div key={step.id} className="flex flex-col items-center mb-8 w-32 h-40">
    <div className="relative sm:w-28 sm:h-28 w-32 h-32 overflow-hidden rounded-full">
      <img
        src={step.answer?.images?.[1]?.url || step.images?.[1]?.url || SEARCH_RESULT_PLACEHOLDER}
        alt={step.answer?.name || step.name}
        className="absolute top-1/2 left-1/2 w-auto h-auto min-w-full min-h-full transform -translate-x-1/2 -translate-y-1/2"
      />
    </div>
    <div style={{ width: '100%' }}>
      <p className="mt-3 font-americaRegular text-base text-center" style={{ overflow: 'hidden', textOverflow: 'ellipsis',  whiteSpace: 'nowrap' }}>{step.answer?.name || step.name}</p>
    </div>
  </div>
);

const Confirmation = ({ steps, handleBack, handleSave, translations, language, isSubmitting }) => (
  <div className="w-full">
    <p className="font-americaRegular text-black text-2xl text-center">{translateObj(translations.finalStepText, language)}</p>
    <Spacer className="mt-14" />
    <div className="w-full flex sm:justify-center justify-around flex-wrap">
      {
        steps.map((step) => <SuggestedArtists step={step} />)
      }
    </div>
    <Spacer className="mt-12" />
    <div className="flex flex-col w-full items-center">
      <Button headerButton text={translateObj(translations.finalStepSubmit, language)} color="black" onClickCallback={handleSave} className="w-auto h-auto" />
      <Spacer className="mt-6" />
      <button onClick={handleBack} className="font-americaRegular text-black underline" disabled={isSubmitting}>{translateObj(translations.finalStepGoBack, language)}</button>
    </div>
  </div>
)

const Summary = ({ translations, language, suggestion }) => {
  return (
    <>
      <p className="font-americaRegular text-xl sm:text-lg tracking-tight text-center">
          {translateObj(translations.alreadyParticipated, language)}
      </p>
      <Spacer className="mt-14" />
      <div className="w-full flex sm:justify-center justify-around flex-wrap">
          {suggestion.suggestion.map((step) => (
              <SuggestedArtists key={step.slug} step={step} />
          ))}
      </div>
      <Spacer className="mt-6" />
      <Button headerButton text={translateObj(translations.thanksPageGoToHome, language)} color="black" link="/" />
    </>
  );
};

const Container = ({ children, image, gradient }) => {
  return (
    <div style={gradient} className="w-full flex flex-col items-center -mx-4 sm:px-0 px-12">
      <Spacer className="mt-10" />
      <img src={image} className="max-w-xssm" />
      <Spacer className="mt-10" />
      <div className='w-full flex flex-col items-center bg-white rounded-xl px-4' style={{ maxWidth: '1100px' }}>
        <Spacer className="mt-10" />
        {children}
        <Spacer className="mt-10" />
      </div>
      <Spacer className="mt-10" />
    </div>
  );
};

const LinkSpotify = ({ translations, language, setSkipSpotify }) => (
  <>
    <p className="sm:px-0 px-6 font-americaRegular text-xl sm:text-lg tracking-tight text-center">{translateObj(translations.spotifyDescription, language)}</p>
    <div className="mt-16">
      <SpotifyLogin
        isLinkAccountButton
        buttonText={translateObj(translations.spotifyLinkText, language)}
        revamp
      />
    </div>
    <Spacer className="mt-6" />
    <button className="text-black underline" onClick={() => setSkipSpotify(true)}>{translateObj(translations.spotifySkipButton, language)}</button>
  </>
)

const Wishlist = ({ translation: newTranslations, steps: availableSteps, headerImage, eventSlugname, background }) => {
  const translations = { ...localTranslations.wishlist, newTranslations };
  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(true);
  const [topArtists, setTopArtists] = useState();
  const [suggestion, setSuggestion] = useState();
  const [currentStep, setCurrentStep] = useState(0);
  const [steps, setSteps] = useState(availableSteps);
  const [data, setData] = useState();
  const [skipSpotify, setSkipSpotify] = useState(false);
  const [submitState, setSubmitState] = useState({
    loading: false,
    success: null
  });
  const { language } = useRoutes();
  const { user } = useAuth();
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 5;
  const { isMobile } = useResponsiveUtility();
  const resultsRef = useRef(null);

  const debouncedSearchTerm = useDebounce(query, 1000);

  const image = translateObj(headerImage, language);
  const hasAlreadyParticipated = !!(suggestion?.createdAt);
  const isLastStep = currentStep === availableSteps.length - 1;
  const isConfirmationStep = currentStep === availableSteps.length
  const hasAnsweredAllSteps = validateAnswers();
  const gradient = {
    flex: 1,
    background: `linear-gradient(135deg, ${background.start}, ${background.end})`,
  };

  useEffect(() => {
    if (debouncedSearchTerm) {
      searchArtists({ artist: query }).then((res) => {
        if (res) setData(res);
      }).catch((err) => {
        console.log(err);
      });
    }
  }, [debouncedSearchTerm]);

  const handleInputChange = (e) => {
    setQuery(e.target.value);
    if (e.target.value === '') setData()
  };

  useEffect(() => {
    if (user.userId) {
      getUserData({ getFavourites: false, getLoyalty: false, getSpotifyTopArtists: true, suggestionEventSlugname: eventSlugname }).then((res) => {
        setLoading(false)
        if (res) {
          setTopArtists(res.topArtists)
          setSuggestion(res.suggestion)
        } else {
          console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Error - getUserData failed to submit!`);
        }
    }).catch((err) => { console.log('error getting user favorites: ', err); });
    }
  },[user])

  const handleViewMore = () => {
    searchArtists({ artist: query, retry: !data?.hasNextPage, nextOffset: data?.nextOffset }).then((res) => {
      if (res) setData(res);
    }).catch((err) => {
      console.log(err);
    });
  };

  const resetSearch = () => {
    setQuery('');
    setData();
  }

  const handleNext = () => {
    setCurrentStep(currentStep + 1);
    resetSearch()
  }

  const handleSelect = (item) => {
    // Check if the answer is already selected in previous steps
    const isAnswerInOtherSteps = checkIfArtistsIsInOtherSteps({ item, steps, currentStep })
    // If the answer is in previous steps, do not allow selection
    if (isAnswerInOtherSteps) return;
    const newSteps = [...steps];
    newSteps[currentStep].answer = item;
    setSteps(newSteps);
    if (!isMobile && !isLastStep) handleNext()
  };

  const handleBack = () => {
    setCurrentStep(currentStep - 1);
    resetSearch()
  }

  const handleStep = (index) => {
    setCurrentStep(index);
    resetSearch()
  }

  const handleSave = () => {
    const payload = steps.map(step => ({
      questionId: step.id,
      answer: step.answer.slug,
    }));
    setSubmitState((prevState) => ({...prevState, loading: true }))
    suggestLineupArtists({ eventSlugname, suggestion: payload }).then((res) => {
      setSubmitState({ ...res, loading: false })
    }).catch((err) => console.error(err)).finally(() => setIsSubmitting((prevState) => ({ ...prevState, loading: false }) ))
  }

  function validateAnswers () {
    const allAnswered = steps.every(step => step.answer);
    const isLastStep = currentStep === steps.length - 1;
    return allAnswered && isLastStep;
  };
  
  useEffect(() => {
    function handleClickOutside(event) {
      if (!resultsRef?.current?.contains(event.target)) {
        setQuery('');
        setData();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [resultsRef]);

  if (hasAlreadyParticipated) return (
    <Container image={image} gradient={gradient}>
      <Summary translations={translations} language={language} suggestion={suggestion} />
    </Container>
  )

  if (!skipSpotify && (!user?.isSpotifyLinked || !user?.socialnetwork === 'spotify')) return (
    <Container image={image} gradient={gradient}>
      <LinkSpotify translations={translations} language={language} suggestion={suggestion} setSkipSpotify={setSkipSpotify} />
    </Container>
  )

  if (loading) return (
    <Container image={image} gradient={gradient}>
      <LoadIcon />
    </Container>
  )

  if (submitState.success) return (
    <Container image={image} gradient={gradient}>
      <div>
        <p className="sm:px-0 px-6 font-americaRegular text-xl sm:text-lg tracking-tight text-center">{translateObj(translations.thanksPageText, language)}</p>
      </div>
      <Spacer className="mt-6" />
      <Button text={translateObj(translations.thanksPageTicketsButton, language)} link="/tickets" color="black"/>
      <Spacer className="mt-6" />
      <Button text={translateObj(translations.thanksPageGoToHome, language)} link="/" />
    </Container>
  )

  if (isConfirmationStep) return (
    <Container image={image} gradient={gradient}>
      <Confirmation steps={steps} handleSave={handleSave} handleBack={handleBack} translations={translations} language={language} />
    </Container>
  )

  return (
    <Container image={image} gradient={gradient}>
      <Step isMobile={isMobile} language={language} handleBack={handleBack} handleNext={handleNext} steps={steps} currentStep={currentStep} handleStep={handleStep} translations={translations} hasAnsweredAllSteps={hasAnsweredAllSteps} isLastStep={isLastStep} />
      <Spacer className="mt-8" />
      <Results results={data?.items} handleViewMore={handleViewMore} handleSelect={handleSelect} query={query} handleInputChange={handleInputChange} steps={steps} currentStep={currentStep} translations={translations} resultsRef={resultsRef} /> 
      <Spacer className="mt-5" />
      {
        topArtists?.length > 0 ? (
          <div className={`${isMobile ? 'w-full' : ''}`}>
            <p className='w-full'>{translateObj(translations.spotifySelectTopArtists, language)}</p>
            <div className={`mt-4 pt-5 flex w-full  ${isMobile ? 'overflow-x-scroll ' : ''}`}>
              {!isMobile && <button className="font-icon icon-arrow-left text-base h-16" onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 0} />}
              <div className="flex justify-between mx-4">
              {(isMobile ? topArtists : topArtists.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage)).map((item) => {
                  const isAnswerInOtherSteps = checkIfArtistsIsInOtherSteps({ item, steps, currentStep });
                  return (
                    <div key={item.slug} className="w-full flex flex-col items-center" style={{ width: '115px', height: '150px' }}>
                      <button onClick={() => handleSelect(item)} className={`relative w-16 h-16 overflow-hidden rounded-full ${isAnswerInOtherSteps ? "opacity-40 cursor-default" : "cursor-pointer"}`}>
                        <img
                          src={item.images[1]?.url}
                          alt={item.name}
                          className=" w-auto h-auto min-w-full min-h-full"
                        />
                      </button>
                      <p className="mt-3 font-americaRegular text-base text-center" style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.name}</p>
                    </div>
                  )
                })}
              </div>
              {!isMobile && <button className="font-icon icon-arrow-right text-base h-16" onClick={() => setCurrentPage(currentPage + 1)} disabled={(currentPage + 1) * itemsPerPage >= topArtists.length} />}
            </div>
          </div>
        ) : null
      }
    </Container>
  );
};

export default Wishlist;
