import React from 'react';
import { PropTypes } from 'prop-types';

import { withRouter } from 'react-router-dom';
import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';
import RadioTodayGridHeader from './RadioTodayGridHeader'
import RadioTodayGridTable from './RadioTodayGridTable'

class RadioTodayGrid extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount() {
  }

  render() {
    let {filters, list, match} = this.props
    return (
      <RoutesContext.Consumer>
      {
        ({ language: contextLanguage, enteredFrom }) => {
          
          const localization = getLocalization(contextLanguage, enteredFrom, match);
          
          return (
            <div className="w-full">
              <RadioTodayGridHeader {...filters} />
              <RadioTodayGridTable {...list} />
            </div>
          )
        }
      }
      </RoutesContext.Consumer>
    )
  }
}

RadioTodayGrid.propTypes = {
  filters : PropTypes.shape().isRequired,
  list : PropTypes.shape().isRequired,
};

export default withRouter(RadioTodayGrid);