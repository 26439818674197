/* eslint-disable jsx-a11y/label-has-for */
import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';

const TagsInput = ({
  toggleTag,
  color,
  classes,
  title,
}) => {
  const [tag, setTag] = useState('');

  const inputHandler = (e) => {
    const { value } = e.target;
    setTag(value);
    if (e.keyCode === 13) {
      toggleTag(tag);
      setTag('');
    }
  };

  return (
    <label className={`font-americaMonoRegular text-xs uppercase my-2 text-${color} ${classes && 'mt-2 mb-6px'}`} htmlFor="tag">
      {title}
      <input
        className={`font-americaMonoRegular p-2 bg-white outline-none w-full rounded-full ${classes || 'mt-4'}`}
        onChange={inputHandler}
        onKeyDown={inputHandler}
        name="tag"
        type="text"
        value={tag}
        placeholder={`Write ${title} and press enter`}
      />
    </label>
  );
};

TagsInput.propTypes = {
  toggleTag: PropTypes.func,
  color: PropTypes.string,
  classes: PropTypes.string,
  title: PropTypes.string,
};

TagsInput.defaultProps = {
  toggleTag: null,
  color: 'white',
  classes: '',
  title: 'tags',
};

export default TagsInput;
