import * as TYPES from './types';

const reducer = (state, action) => {
  switch (action.type) {
    case TYPES.SUBMIT: return ({ ...state, loading: true });
    case TYPES.SUBMIT_SUCCESS: return ({ ...state, loading: false, message: action.message });
    case TYPES.SUBMIT_ERROR: return ({ ...state, loading: false, error: action.error });
    case TYPES.TOGGLE_CHECKBOX: return ({ ...state, [action.name]: action.checked });
    case TYPES.SET_INPUT_VALUE: return ({ ...state, [action.name]: action.value });
    default: return state;
  }
};

export default reducer;
