import getSection from '../api/graphql/queries/getSection';
import getPostsList from '../api/graphql/queries/getPostsList';
import getLineup from '../api/graphql/queries/getLineup';

import getRadioShowsNow from '../api/graphql/queries/getRadioShowsNow';

import getRadioShows from '../api/graphql/queries/getRadioShows';
import getRadioShowsEpisodes from '../api/graphql/queries/getRadioShowsEpisodes';
import getRadioShowsEpisodesForEpisode from '../api/graphql/queries/getRadioShowsEpisodesForEpisode';

import ComponentQuery from '../containers/ComponentQuery';

// React-Router routes for root and rootSection
const routesRootAccess = [
  {
    path: '/',
    exact: true,
    component: ComponentQuery,
    initGetSection: ({ cookies }) => getSection('home', cookies),
    initGetPostsList: () => getPostsList('home', 0, 9),
    initGetRadioShowsNow: () => getRadioShowsNow(),
  },
  {
    path: '/radio',
    exact: true,
    component: ComponentQuery,
    initGetSection: ({ cookies }) => getSection('radio', cookies),
    initGetRadioShowsNow: () => getRadioShowsNow(),
    initGetRadioShowsHighlights: () => getRadioShows({ highlight: true, limit: 2 }),
    initGetRadioShowsEpisodes: () => getRadioShows({ limit: 8, type: 'episodes' }),
    initGetRadioShows: () => getRadioShows({ limit: 8, type: 'shows' }),
  },
  {
    path: '/radio/explore',
    exact: true,
    component: ComponentQuery,
    initGetSection: ({ cookies }) => getSection('radio-explore', cookies),
    initGetRadioShowsNow: () => getRadioShowsNow(),
  },
  {
    path: '/radio/podcasts',
    exact: true,
    component: ComponentQuery,
    initGetSection: ({ cookies }) => getSection('podcasts', cookies),
    initGetRadioShowsNow: () => getRadioShowsNow(),
  },
  {
    path: '/radio/shows',
    exact: true,
    component: ComponentQuery,
    initGetSection: ({ cookies }) => getSection('radio-shows', cookies),
    initGetRadioShowsNow: () => getRadioShowsNow(),
    initGetRadioShows: () => getRadioShows({ limit: 20, type: 'shows' }),
  },
  {
    path: '/radio/:channel',
    exact: true,
    component: ComponentQuery,
    initGetSection: ({ cookies }) => getSection('radio-channel', cookies),
    initGetRadioShowsNow: () => getRadioShowsNow(),
  },
  {
    path: '/radio/shows/:post',
    exact: true,
    component: ComponentQuery,
    initGetSection: ({ cookies }) => getSection('radio-show', cookies),
    initGetRadioShowsNow: () => getRadioShowsNow(),
    initGetSingleRadioShow: (path = '') => getRadioShows({ slug: path.split('/').pop() }),
    initGetSingleRadioShowRelated: () => getRadioShows({ limit: 3 }),
    initGetSingleRadioShowEpisodes: (path = '') => getRadioShowsEpisodesForEpisode({ parentSlug: path.split('/').pop() }),
  },
  {
    path: '/radio/shows/:post/:episode',
    exact: true,
    component: ComponentQuery,
    initGetSection: ({ cookies }) => getSection('radio-episode', cookies),
    initGetRadioShowsNow: () => getRadioShowsNow(),
    initGetSingleRadioEpisode: (path = '') => getRadioShows({ slug: path.split('/').pop() }),
    initGetSingleRadioEpisodeRelated: (parentSlug = '') => getRadioShowsEpisodesForEpisode({ parentSlug, limit: 3 }),
    initGetSingleRadioShowTitle: (slug = '') => getRadioShows({ slug }),
    initGetFeaturedRadioShows: () => getRadioShows({ limit: 4 }),
  },
  {
    path: '/:section',
    exact: true,
    component: ComponentQuery,
    initGetSection: ({ path, cookies }) => getSection(path.split('/').pop(), cookies),
    initGetPostsList: (path = '') => getPostsList(path.split('/').pop(), 0, 9),
    initGetLineup: () => {}, // getLineup('primavera-sound-2019-barcelona'),
    initGetRadioShowsNow: () => getRadioShowsNow(),
  },
  {
    path: '/:section/:post',
    exact: true,
    component: ComponentQuery,
    initGetSection: ({ path, cookies }) => getSection(path.split('/').pop(), cookies),
    initGetRadioShowsNow: () => getRadioShowsNow(),
  },
];

export default routesRootAccess;
