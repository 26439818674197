import TagManager from 'react-gtm-module';

const triggerGaUserRegistration = (email, originPost) => {
  console.log('triggerUserRegistration', email);
  const tagManagerArgs = {
    dataLayer: {
      event: 'registrationFinished',
      email,
      pageUrl: originPost || 'registrarse-fin',
      path: '/registrarse-fin',
    },
  };
  TagManager.dataLayer(tagManagerArgs);
  console.log('triggerUserRegistration', email, tagManagerArgs);
};

export default triggerGaUserRegistration;
