const translation = {
  buttonSearch: {
    en: 'Search',
    es: 'Búsqueda',
    ca: 'Cerca',
    pt: 'Procurar',
  },
  buttonLogin: {
    en: 'Log in',
    es: 'Log in',
    ca: 'Log in',
    pt: 'Log in',
  },
  buttonProfile: {
    en: 'Profile',
    es: 'Perfil',
    ca: 'Perfil',
    pt: 'Perfil',
  },
};

export default translation;
