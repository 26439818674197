import React from 'react';
import PropTypes from 'prop-types';

const ButtonLinkWithBadge = ({ number, onClick }) => (
  <button type="button" className="relative cursor-pointer" onClick={onClick}>
    {number === 0 ? null
      : (
        <div className="absolute w-4 h-4 bg-red-bright rounded-full" style={{ top: '-7px', right: '24px' }}>
          <span className="flex pt-2px text-white justify-center items-center text-11px font-normal">
            {number}
          </span>
        </div>
      )}
    <span className="font-newicons icon-notification text-base text-black sm:text-2xl md:text-2xl lg:text-xl xl:text-xl mr-1 flex justify-center items-center w-8 h-8 rounded-full border border-black" />
  </button>
);

ButtonLinkWithBadge.propTypes = {
  number: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ButtonLinkWithBadge;
