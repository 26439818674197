import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { propTypes, withFormsy } from 'formsy-react';
import { withStyles } from '@material-ui/core/styles';

import InfoPopover from './InfoPopover';

const style = theme => ({
  menu: {
    marginTop: '3rem',
    zIndex: 100000,
  },
  button: {
    color: 'black',
    width: '100%',
    backgroundColor: 'inherit',
    border: 'solid 1px black',
    padding: '1rem',
    marginTop: '1rem',
    borderRadius: 999,
    fontSize: '100%',
    lineHeight: 1.15,
    fontFamily: 'GT-America-Mono-Regular',
    justifyContent: 'flex-start',
    textTransform: 'none',
  },
});

class FormSelectAutoComplete extends Component {
  state = {
    // The active selection's index
    activeSuggestion: 0,
    // The suggestions that match the user's input
    filteredSuggestions: [],
    // Whether or not the suggestion list is shown
    showSuggestions: false,
    // What the user has entered
    userInput: '',
  };

  componentDidMount() {
    const { value } = this.props;
    if (value) {
      this.setState({
        userInput: value,
      });
    }
    return null;
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { getValue } = this.props;
    if (prevProps !== this.props && getValue() !== undefined) {
      if (prevState.value !== getValue()) {
        this.setState({
          userInput: getValue(),
        });
      }
    }
  };

  handleClick = (value) => {
    const { setValue } = this.props;
    this.setState({ userInput: value, showSuggestions: false }, () => { setValue(value); });
  }

  handleActiveField = () => {
    this.setState({ userInput: '' });
  }

  onChange = (e) => {
    const {
      options,
      // setValue,
    } = this.props;
    const userInput = e.currentTarget.value;

    // Filter our suggestions that don't contain the user's input
    const filteredSuggestions = options.filter(suggestion => suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1);
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions,
      showSuggestions: true,
      userInput,
    });
    // setValue(userInput);
  };

  onKeyDown = (e) => {
    const { activeSuggestion, filteredSuggestions } = this.state;

    // User pressed the enter key
    if (e.keyCode === 13) {
      this.setState({
        activeSuggestion: 0,
        showSuggestions: false,
        userInput: filteredSuggestions[activeSuggestion],
      });
    } else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }
      this.setState({ activeSuggestion: activeSuggestion - 1 });
    } else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }
      this.setState({ activeSuggestion: activeSuggestion + 1 });
    }
  };

  render() {
    const {
      onChange,
      onClick,
      onKeyDown,
      state: {
        activeSuggestion, filteredSuggestions, showSuggestions, userInput,
      },
    } = this;

    const {
      message,
      classes,
      name,
      title,
      value,
      required,
      titleClassName,
      doNotChangeValidColor,
      infoText = '',
      backgroundColor = 'inherit',
    } = this.props;

    let suggestionsListComponent;

    if (showSuggestions && userInput) {
      if (filteredSuggestions.length) {
        suggestionsListComponent = (
          <ul className="list-none pl-0 bg-black py-2 country-list absolute pin-t z-top w-full scrollbar__black" style={{ top: '-20px' }}>
            {filteredSuggestions.map(suggestion => (
              <li className="px-2 py-1 hover:text-black" key={suggestion}>
                <span className="w-full block text-white" onClick={() => this.handleClick(suggestion)}>{suggestion}</span>
              </li>
            ))}
          </ul>
        );
      } else {
        suggestionsListComponent = (
          <div className="mt-4 text-red no-suggestions">
            <em>{message}</em>
          </div>
        );
      }
    }

    return (
      <div className="px-3 mb-4 sm:w-full w-full lg:w-1/2">
        <label className="flex flex-col relative" htmlFor={name} id="country-label">
          <span className={titleClassName}>
            {title}
            {required && <span className="text-12 align-top text-red-light">*</span>}
            {infoText && <div className="ml-4"><InfoPopover text={infoText} /></div>}
          </span>
          <input id="country" type="text" onChange={onChange} onKeyDown={onKeyDown} value={userInput} className={`mt-4 rounded-full border-1 p-4 bg-${backgroundColor} border-black text-black`} name={name} />
          {suggestionsListComponent}
        </label>
      </div>
    );
  }
}

FormSelectAutoComplete.propTypes = {
  ...propTypes,
};

FormSelectAutoComplete.defaultProps = {
  value: '',
  getValue: () => {},
};

export const FormSelectAutoCompleteNoFormsy = FormSelectAutoComplete;

export default withFormsy(withStyles(style)(FormSelectAutoComplete));
