import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import Modal from 'react-responsive-modal';
import translateObj from '../../../util/translateObj';
import { useRoutes } from '../../../context/routesContext';
import StripeDonationModalScreen from './StripeDonationModalScreen';


const modalStyles = {
  modal: {
    background: 'rgba(0, 0, 0, 0)',
    boxShadow: 'none',
    width: '100vw',
    // padding: '2rem',
  },
  overlay: {
    // background: 'rgba(0, 0, 0, 0.5)',

  },
  closeButton: {
    top: '18px',
    right: '18px',
  },
};

const StripeDonationStreamButton = (props) => {
  const { buttonCharacteristics: { buttonText, buttonBgColor, buttonTextColor }, match } = props;
  const { language } = useRoutes(match);
  const [isModalOpen, setModalOpen] = useState(false);

  const onOpenModal = () => {
    setModalOpen(true);
  };

  const onCloseModal = () => {
    setModalOpen(false);
  };


  return (
    <div>
      <button
        type="button"
        className={`rounded-full bg-${buttonBgColor}  text-${buttonTextColor} font-americaMonoBold cursor-pointer  text-center my-4 px-16 uppercase py-4  w-full`}
        onClick={onOpenModal}
      >
        {translateObj(buttonText, language)}
      </button>
      <Modal open={isModalOpen} onClose={onCloseModal} center styles={modalStyles} closeOnOverlayClick focusTrapped={false}>
        <StripeDonationModalScreen {...props} language={language} closeModal={onCloseModal} />
      </Modal>
    </div>
  );
};

StripeDonationStreamButton.propTypes = {
  match: PropTypes.shape().isRequired,
  buttonCharacteristics: PropTypes.shape(),
};
StripeDonationStreamButton.defaultProps = {
  buttonCharacteristics: {
    buttonText: {
      es: 'donar',
      en: 'donate',
      ca: 'donar',
    },
    buttonBgColor: 'gray200',
    buttonTextColor: 'black',

  },
};

export default withRouter(StripeDonationStreamButton);
