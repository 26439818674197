import React, { useRef, useState, useEffect } from 'react';
import uuid from 'react-uuid';

import { PropTypes } from 'prop-types';
import { useToasts } from 'react-toast-notifications';
// import { messageRef } from '../../../../services/firestore';
import { useChat } from '../context/chatContext';
import { useChatVisibility } from '../context/chatVisibilityContext';
import { useMessages } from '../hooks/useMessages';

import View from './View';
import MessageInput from './MessageInput';
import LoadIcon from './LoadIcon';
import LoginButton from './LoginButton';
import Toast from './Toast';
import { sendMessage } from '../utils/helpers';
import {
  CHAT, QUESTIONS,
} from '../utils/constants';
import { fieldValue } from '../context/FirebaseAuthContext';
import { useTheme } from '../context/themeContext';

// import {
//   getMessages, getMessagesFiltered, sendMessage, sendMessageWithData,
// } from '../utils/helpers';

const Container = ({
  channel, isQuestions, setActive, shouldToggle, stringSet, active,
}) => {
  const {
    notLogged, userData, userIsMuted,
  } = useChat();

  const { isChatOpen } = useChatVisibility();
  const chatViewRef = useRef(null);
  const [isAtBottom, setScrollBottomState] = useState(false);
  const [hasNewMessages, setNewMessagesState] = useState(false);
  const [isLoadedMessages, setLoadedMessagesState] = useState(false);
  const [hasScrollbar, setHasScrollbar] = useState(false);
  const { theme } = useTheme();
  const { current } = chatViewRef;
  const { addToast, removeToast } = useToasts();

  const {
    messages, setMessages, isLoadingMessages, qaMessagesAdmin,
  } = useMessages({ channel, isQuestions });

  const checkScrollbarState = () => {
    if (current.scrollHeight > current.clientHeight) {
      setHasScrollbar(true);
    }
  };

  const scrollToLastMessage = () => {
    if (current) {
      current.scrollTop = 10 ** 10;
      checkScrollbarState();
    }
  };

  const chatListener = (e) => {
    const { target } = e;
    if (target.scrollTop >= target.scrollHeight - target.offsetHeight - 10) {
      setScrollBottomState(true);
      setNewMessagesState(false);
    } else {
      setScrollBottomState(false);
    }
  };

  const handleToggle = (VIEW) => {
    setActive(VIEW);
    setTimeout(() => {
      scrollToLastMessage();
    }, 500);
  };

  const handleMessageSent = (success, message, sendAsAdmin) => {
    if (!success) {
      addToast(<Toast message={stringSet.MUTED_MESSAGE} />, { appearance: 'info', id: channel });
    } else {
      if (!isQuestions || sendAsAdmin) {
        setMessages([...messages, message]);
      } else {
        addToast(<Toast message={stringSet.QA_ADMIN_MESSAGE} />, { appearance: 'info', id: message.messageId });
      }
    }
    scrollToLastMessage();
  };

  const handleNewMessages = () => {
    if (!isAtBottom) {
      setNewMessagesState(true);
    } else {
      scrollToLastMessage();
    }
  };

  const updateChat = (userMessage, sendAsAdmin) => {
    let message = {};
    const messageId = uuid();
    if (userIsMuted) {
      handleMessageSent(false, null);
    } else {
      if (!sendAsAdmin) {
        if (!isQuestions) {
          message = {
            messageId,
            message: userMessage,
            _sender: userData,
            customType: '',
          };
        } else {
          message = {
            messageId,
            message: userMessage,
            _sender: userData,
            customType: '',
            isPending: true,
            updatedAt: fieldValue.serverTimestamp(),
          };
        }
      } else {
        if (!isQuestions) {
          message = {
            messageId,
            message: userMessage,
            isVisible: true,
            customType: 'admin',
            _sender: { nickname: 'Admin' },
          };
        } else {
          message = {
            messageId,
            message: userMessage,
            isVisible: true,
            isPending: false,
            customType: 'admin',
            updatedAt: fieldValue.serverTimestamp(),
            _sender: { nickname: 'Admin' },
          };
        }
      }
      handleMessageSent(true, message, sendAsAdmin);
      sendMessage({ channelUrl: channel, data: message });
    }
  };

  useEffect(() => {
    if (messages && isLoadedMessages) {
      handleNewMessages();
    }
  }, [messages]);

  useEffect(() => {
    if (!isLoadingMessages) {
      scrollToLastMessage();
      setLoadedMessagesState(true);
    }
  }, [isLoadingMessages]);

  useEffect(() => {
    if (userIsMuted) {
      addToast(<Toast message={stringSet.MUTED_MESSAGE} />, { appearance: 'info', id: channel });
    } else {
      removeToast(channel);
    }
  }, [userIsMuted]);

  useEffect(() => {
    scrollToLastMessage();
  }, []);

  return (
    <>
      {hasNewMessages && !isAtBottom && hasScrollbar && (
      <div className="absolute pin-b pin-l w-full mb-40 text-center z-10">
        <button className="bg-psworld-grey opacity-85 rounded px-4 py-1 font-americaMonoRegular shadow-default hover:bg-white hover:opacity-1 trans text-xs uppercase" type="button" onClick={scrollToLastMessage}>Messages below</button>
      </div>
      )}
      <div ref={chatViewRef} onScroll={chatListener} className={`flex-2 chat-container overflow-y-auto overflow-x-hidden relative scrollbar__streampage ${!shouldToggle ? 'pb-36' : 'pb-42'}`}>
        {!isLoadingMessages ? <View qaMessagesAdmin={qaMessagesAdmin} channel={channel} shouldToggle={shouldToggle} {...{ messages }} isQuestions={isQuestions} /> : (
          <div className="h-full flex flex-col justify-center text-center">
            <LoadIcon />
          </div>
        )}
      </div>
      {
            notLogged
              ? <LoginButton {...{ isQuestions, shouldToggle }} />
              : (
                <MessageInput {...{
                  isQuestions, notLogged, shouldToggle, handleMessageSent, channel, updateChat,
                }}
                />
              )
          }
      {shouldToggle && isChatOpen && (
      <div className={`bg-${theme}-box px-4 pb-4 fixed pin-b w-full flex`}>
        <button type="button" className={`font-americaMonoRegular text-center bg-${theme}-green rounded px-2 py-1 text-xs text-black uppercase cursor-pointer trans hover:opacity-85`}>
          {!isQuestions
            ? <span onClick={() => handleToggle(QUESTIONS)}>Questions</span>
            : <span onClick={() => handleToggle(CHAT)}>Chat</span>}
        </button>
      </div>
      )}
    </>
  );
};

Container.propTypes = {
  channel: PropTypes.string.isRequired,
  isQuestions: PropTypes.bool,
  shouldToggle: PropTypes.bool,
  setActive: PropTypes.func,
};

Container.defaultProps = {
  isQuestions: false,
  setActive: null,
  shouldToggle: false,
};

export default Container;
