/* eslint-disable jsx-a11y/label-has-for */
import React, { useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';
import Link from '../../../util/link';

const StandardFormCheckboxConditions = ({
  acceptText, conditionsText, conditionsLink, dataTreatmentText, required, privacyPolicyText, name, privacyPolicyUrl, getCheckBoxValue,
}) => {
  const [value, setValue] = useState(false);

  const changeValue = (e) => {
    getCheckBoxValue(e.currentTarget.checked);
    setValue(e.currentTarget.checked);
  };

  return (
    <div className="flex items-center sm:mt-3 xl:whitespace-no-wrap font-bold">
      <Checkbox onChange={changeValue} id={name} checked={value} data-checked={value} style={{ color: `${value ? '#00d113' : 'black'}` }} />
      <span className="ml-4 text-12">
        {required && <span className="text-12 align-top text-red-light">* </span>}
        {`${acceptText} `}
        {conditionsText && conditionsLink ? <Link className="text-black underline cursor-pointer" to={conditionsLink}>{conditionsText}</Link> : '' }
        {`${dataTreatmentText} `}
        {privacyPolicyText && privacyPolicyUrl ? <Link className="text-black underline cursor-pointer" to={privacyPolicyUrl}>{privacyPolicyText}</Link>
          : '' }
      </span>
    </div>
  );
};

StandardFormCheckboxConditions.propTypes = {
  getCheckBoxValue: PropTypes.func.isRequired,
  acceptText: PropTypes.string.isRequired,
  conditionsText: PropTypes.string.isRequired,
  dataTreatmentText: PropTypes.string.isRequired,
  privacyPolicyText: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  conditionsLink: PropTypes.string,
  privacyPolicyUrl: PropTypes.string,
};

StandardFormCheckboxConditions.defaultProps = {
  conditionsLink: '',
  required: false,
  privacyPolicyUrl: '',
};

export default StandardFormCheckboxConditions;
