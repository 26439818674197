import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import imageModifier from '../../helpers/imageModifier';
import translateObj from '../../../../util/translateObj';
import OnDemandModalAddCard from './OnDemandModalAddCard';
import OnDemandModalPay from './OnDemandModalPay';
import OnDemandModalTrial from './OnDemandModalTrial';


const OnDemandModalIsLogged = (props) => {
  const {
    image, language, userEmail, userTrialWasUsed, handleOnDemandScreenStatus, history, postVideoUrl, userSubscriptionDetails: { isActive: userHasActiveSubscription = null, expirationDate },
  } = props;

  // ! payment details info need to come from tue user profile
  const userHasAvailablePaymentDetails = false;

  const [modalLoggedStep, setModalLoggedStep] = useState('addCard');

  useEffect(() => {
    if (userHasActiveSubscription) {
      const currDateToIso = new Date().toISOString();
      const dateDifference = new Date(expirationDate) - new Date(currDateToIso);
      if (dateDifference) {
        // * having active subscription (either trial or has paid)
        history.push(`/${language}${postVideoUrl[0] === '/' ? '' : '/'}${postVideoUrl}`);
      }
    }
  }, [userHasActiveSubscription]);

  const handleModalLoggedStep = (step) => {
    setModalLoggedStep(step);
  };

  const imageurl = `url(${imageModifier(translateObj(image, language), '768x510')})`;

  const onDemandModalLoggedRender = () => {
    if (!userEmail) handleOnDemandScreenStatus('socialLogin');
    // * user has not used the trial period yet
    if (!userTrialWasUsed) {
      return (<OnDemandModalTrial {...props} imageUrl={imageurl} />);
    }
    // * user wants to pay and can do it (has details)
    if (userHasAvailablePaymentDetails || modalLoggedStep === 'paymentDetailsUpdated') {
      return (<OnDemandModalPay {...props} imageUrl={imageurl} />);
    }
    // * user has to add payment card
    // ? consider adding further logic when credit card Expires !!!
    return (<OnDemandModalAddCard {...props} imageUrl={imageurl} handleModalLoggedStep={handleModalLoggedStep} />);
  };

  return (
    <>
      {onDemandModalLoggedRender()}
    </>
  );
};

OnDemandModalIsLogged.propTypes = {
  language: PropTypes.string,
  userEmail: PropTypes.string,
  loadTokenForLS: PropTypes.func.isRequired,
  handleOnDemandScreenStatus: PropTypes.func.isRequired,
  translations: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
  postVideoUrl: PropTypes.string.isRequired,
  otherOptionsButtonUrl: PropTypes.string.isRequired,
  userTrialWasUsed: PropTypes.bool,
  userSubscriptionDetails: PropTypes.shape(),
};

OnDemandModalIsLogged.defaultProps = {
  language: 'en',
  userEmail: '',
  userTrialWasUsed: null,
  userSubscriptionDetails: {},
};

export default withRouter(OnDemandModalIsLogged);
