/* eslint-disable camelcase */
import React from 'react';
import {
  TwitchEmbed, TwitchChat, TwitchClip, TwitchPlayer,
} from 'react-twitch-embed';
import parse from 'html-react-parser';
import { PropTypes } from 'prop-types';
import ReactPlayer from 'react-player';
import { withRouter } from 'react-router-dom';
import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';
import translateObj from '../../util/translateObj';

const VideoPlayer = ({
  image, url, youtube, vimeo, twitchChannel, match, description, title_multilang,
}) => (
  <RoutesContext.Consumer>
    {({ language: contextLanguage, enteredFrom }) => {
      const localization = getLocalization(contextLanguage, enteredFrom, match);
      let videoUrl;
      if (url) {
        videoUrl = url;
      } else if (youtube) {
        videoUrl = `https://youtu.be/${youtube}`;
      } else if (vimeo) {
        videoUrl = `https://player.vimeo.com/video/${vimeo}`;
      }

      
      return (
        <div className="w-full">
          <div>
            <div className="player-wrapper mt-2">
              {videoUrl && (
                <ReactPlayer
                  config={{
                    youtube: {
                      playerVars: { modestbranding: 1 },
                    },
                  }}
                  controls
                  className="react-player"
                  url={videoUrl}
                  width="100%"
                  height="100%"
                  light={image}
                />
              )
              }
              {
                twitchChannel && (
                <>
                  <TwitchPlayer
                    channel={twitchChannel}
                    theme="dark"
                    // onVideoPause={() => console.log(':(')}
                  />
                  {/* <TwitchChat channel="chess" theme="dark" /> */}
                </>
                )
              }
            </div>
            {description ? <div className="text-xxs font-tiemposRegular mt-4 pb-4 sm:h-4">{parse(translateObj(description, localization.language))}</div> : null}
            {title_multilang ? <div className="text-xxs font-tiemposRegular mt-4 pb-4 sm:h-4">{parse(translateObj(title_multilang, localization.language))}</div> : null}
          </div>
        </div>
      );
    }}
  </RoutesContext.Consumer>
);

VideoPlayer.propTypes = {
  image: PropTypes.string.isRequired,
  url: PropTypes.string,
  youtube: PropTypes.string,
  vimeo: PropTypes.string,
  twitchChannel: PropTypes.string,
  description: PropTypes.shape({}),
  title_multilang: PropTypes.shape({}),
  match: PropTypes.shape().isRequired,
};

VideoPlayer.defaultProps = {
  url: null,
  youtube: null,
  vimeo: null,
  twitchChannel: null,
  description: null,
  title_multilang: null,
};

export default withRouter(VideoPlayer);
