import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useAuth } from '@primaveralabs/auth';
import { LoginButton } from '../MusicMeets/Agenda';
import { LinkWrapper } from '../../../util/link';
import { useRoutes } from '../../../context/routesContext';
import translateObj from '../../../util/translateObj';
import Spacer from '../Spacer';

const DEFAULT_TRANSLATIONS = {
  description: {
    en: 'You need to be logged in or create an account to view this content.',
    es: 'Necesitas estar logeado o crear una cuenta para ver este contenido.',
    ca: 'Necessites estar registrat o crear un compte per veure aquest contingut.',
    pt: 'Você precisa estar logado ou criar uma conta para ver este conteúdo',
  },
};

const LoginPaywall = ({ image, terms, termsLink, description }) => {
  const { language, path } = useRoutes();
  const { user } = useAuth();
  if (user) return null;

  return (
    <div className='sm:w-full w-2/3 mt-16 flex flex-col items-center'>
      {
        Object.keys(image).length > 0 ? (
          <div>
            <img src={translateObj(image, language)} />
          </div>
        ) : null
      }
      <div>
        <LoginButton translation={{ textLogin: description }} />
      </div>
      {
        terms?.en
          ? <LinkWrapper url={termsLink} path={path} className="text-black underline">
              {translateObj(terms.text, language)}
            </LinkWrapper>
          : null
      }
      <Spacer className="mt-20" />
    </div>
  );
};

LoginPaywall.propTypes = {
  image: PropTypes.string,
  terms: PropTypes.string,
  termsLink: PropTypes.string,
  description: PropTypes.string, // Add validation for description
};

LoginPaywall.defaultProps = {
  image: null, // or any default value you prefer
  terms: null, // or any default value you prefer
  termsLink: null, // or any default value you prefer
  description: DEFAULT_TRANSLATIONS.description,
};

export default LoginPaywall;
