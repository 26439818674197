import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useRoutes } from '../../../../context/routesContext';
import { getCountryFromCode } from '../../../../util/getCountryNamesByCode';

import SimpleArrow from '../svg/SimpleArrow';
import SquareImage from '../SquareImage';
import IconButton from '../IconButton';
import getProMusicMeetsUser from '../../../../api/graphql/mutations/getProMusicMeetsUser';
import Loader from '../Loader';

const ConversationItem = ({ recipientId, onClick, isActive, isListItem }) => {
  const { language } = useRoutes();
  const [recipient, setRecipient] = useState();
  const [countryISOcode, setCountryISOcode] = useState();

  const getRecipientUserData = () => {
    getProMusicMeetsUser(recipientId).then((res) => {
      if (res) {
        setRecipient(res);
        const country = getCountryFromCode(res.inputCountry, language);
        setCountryISOcode(country);
      }
    }).catch((err) => console.log(err));
  };

  useEffect(() => {
    getRecipientUserData();
  }, [recipientId]);

  return (
    <button
      type="button"
      className={`${isActive || !isListItem ? 'text-black bg-white' : 'text-white'} ${isListItem && 'p-4 justify-between border border-white rounded-lg'} block mb-4 flex items-center w-full`}
      onClick={onClick}
    >
      {!isListItem && (
      <div className="mr-4 h-4" style={{ width: '10%' }}>
        <SimpleArrow />
      </div>
      )}
      {recipient ? (
        <div className="flex items-center" style={{ width: '90%' }}>
          <div className="w-50px mr-4">
            <SquareImage image={recipient?.imageUrl} alt={`${recipient?.name}-${recipient?.lastname}`} />
          </div>
          <div className="text-14">
            <div className="flex font-tiemposBold mb-2">
              <span>{`${recipient?.name} ${recipient?.lastname}`}</span>
            </div>
            <div className="flex flex-wrap font-tiemposRegular">
              {recipient?.company}
              <span className="px-1">|</span>
              {recipient?.occupation}
              <span className="px-1">|</span>
              {countryISOcode}
            </div>
          </div>
        </div>
      ) : (
        <div className="flex items-center w-full">
          <Loader label={{ en: '.' }} />
        </div>
      )}
      {isListItem && (
      <div className="ml-4">
        <IconButton color="white" icon="font-icon icon-arrow-right" />
      </div>
      )}
    </button>
  );
};

export default ConversationItem;

ConversationItem.propTypes = {
  recipientId: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
  isListItem: PropTypes.bool,
};

ConversationItem.defaultProps = {
  onClick: null,
  isActive: false,
  isListItem: false,
};
