import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import RadioTunerCard from './RadioTunerCard';
import withRadioPlayerContext from '../../../context/withRadioPlayerContext';

class Channel extends PureComponent {
  render() {
    const { ch1, ch2, ch3 } = this.props;
    return (
      <div className="flex flex-wrap bg-black py-7 sm:py-0 sm:px-0 px-5 -mx-2 w-full">
        {ch1 && <RadioTunerCard {...ch1} />}
        {ch2 && <RadioTunerCard {...ch2} />}
        {ch3 && <RadioTunerCard {...ch3} />}
      </div>
    );
  }
}

Channel.propTypes = {};

export default withRadioPlayerContext(Channel);
