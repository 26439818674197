import gql from 'graphql-tag';

import { clientWeb } from '../graphqlAPI';

const DELETE_NEWSLETTER_DATA = gql`
  mutation Post($email: String!) {
    deleteNewsletterData(email: $email) {
      email
      isValid
      token
    }
  }
`;

const deleteNewsletterData = async ({
  email,
}) => {
  try {
    const result = await clientWeb
      .mutate({
        mutation: DELETE_NEWSLETTER_DATA,
        variables: {
          email,
        },
        fetchPolicy: 'no-cache',
      })
      .then(queryResult => queryResult)
      .catch((queryError) => {
        console.log(
          '\x1b[31m%s\x1b[0m',
          `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error: ${queryError}`,
        );
        return null;
      });
    const {
      data: { deleteNewsletterData: validatedFormData },
    } = result;

    if (validatedFormData) {
      return validatedFormData;
    }
    console.log(
      '\x1b[31m%s\x1b[0m',
      `${new Date().toLocaleTimeString()} ~ Client: GraphQL Query Error: validatedFormData = null`,
    );
    return null;
  } catch (error) {
    console.log(
      '\x1b[31m%s\x1b[0m',
      `${new Date().toLocaleTimeString()} ~ Client: deleteNewsletterData Error: ${error}`,
    );
    return null;
  }
};

export default deleteNewsletterData;
