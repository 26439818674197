const createImageBlob = url => fetch(url)
  .then(response => response.blob())
  .then(blob => URL.createObjectURL(blob))
  .catch(error => console.warn(error));

const downloadImg = async (url, description) => {
  const image = await createImageBlob(url);
  if (!image) return;
  const anchor = document.createElement('a');
  anchor.href = image;
  anchor.download = description;
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
};

export default downloadImg;
