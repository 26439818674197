import React from 'react';
import PropTypes from 'prop-types';

import { useRoutes } from '../../../context/routesContext';
import useResponsiveUtility from '../../../util/responsiveUtility';

import translateObj from '../../../util/translateObj';
import getTranslation from './util/translations/_getTranslation';
import localTranslation from './util/translations/NextReunions';

import CtaUnderline from './CtaUnderline';
import ComponentIterator from '../../../containers/ComponentIterator';

const NextReunions = ({
  components,
  translation,
}) => {
  const { language } = useRoutes();
  const { deviceScreen } = useResponsiveUtility();
  const literals = getTranslation(translation, localTranslation);

  return (
    <div className="w-full pt-12 pb-10">
      <div className="w-full pb-3 flex justify-between items-center border-b border-black">
        <span className="font-americaMonoRegular uppercase text-30 sm:text-base text-black">{translateObj(literals.title, language)}</span>
        <CtaUnderline text={translateObj(literals.seeAll, language)} className="block sm:hidden" />
      </div>
      <div
        className="flex flex-wrap sm:flex-no-wrap sm:flex-col mt-6"
        style={{
          display: deviceScreen === 'sm' ? 'flex' : 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(330px, 1fr))',
          gridColumnGap: 20,
          gridRowGap: 32,
        }}
      >
        <ComponentIterator components={components} />
      </div>
      <CtaUnderline text={translateObj(literals.seeAll, language)} className="sm:block hidden sm:mt-6" />
    </div>
  );
};

NextReunions.propTypes = {
  components: PropTypes.arrayOf(PropTypes.shape()),
  translation: PropTypes.shape(),
};

NextReunions.defaultProps = {
  components: [],
  translation: null,
};

export default NextReunions;
