import React from 'react';
import PropTypes from 'prop-types';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { buttonClasses2 } from '../../OnDemandUtils/onDemandUtils';
import SpotifyLogin from '../../../Login/SpotifyLogin/SpotifyLogin';
import { responseFacebook } from '../../../Login/LoginUtils/handleSocialNetworkResponses';
import GoogleSSO from '../../../GoogleSSO';


const SocialOnDemandButton = ({
  type, language, loadTokenForLS, handleOnDemandScreenStatus, handleRegisteringStatus, setError, sourceWeb, QRcodeCallback,
}) => {
  let jsxReturned = (<span />);

  // eslint-disable-next-line default-case
  switch (type) {
    case 'google':
      jsxReturned = (
        <GoogleSSO setError={setError} QRcodeCallback={QRcodeCallback} handleRegisteringStatus={handleRegisteringStatus} />
      );
      break;
    case 'spotify':
      jsxReturned = (
        <SpotifyLogin
          icon
          buttonText=""
          className={`${buttonClasses2({
            backgroundColor: 'spotify',
            text: 'white',
            textSize: '4xl',
            width: '10',
            height: '10',
          })}`}
          onSuccess={(res) => {
            const { needToRegister, userPayload, error } = res;
            if (QRcodeCallback) {
              QRcodeCallback();
            }
            if (error) return setError(error);
            if (needToRegister) return handleRegisteringStatus(true, userPayload, 'spotify');
          }}
          isModal
          onFailure={(res) => { console.log('spotify login failed: ', res); }}
        />
      );
      break;

    case 'facebook':
      jsxReturned = (
        <FacebookLogin
          appId="830365707464738"
          fields="name,email,picture"
          render={renderProps => (
            <button
              type="button"
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
              className={buttonClasses2({
                backgroundColor: 'facebook',
                text: 'white',
                textSize: '4xl',
                width: '10',
                height: '10',
              })}
            >
              <div className="font-icon icon-facebook" />
            </button>
          )}
          callback={async (data) => {
            const response = await responseFacebook(data);
            if (response) {
              const {
                isValid, needToRegister, userPayload, error = '',
              } = response;
              if (isValid) {
                loadTokenForLS();
                // !!! need to check this no need to handle ondemandscreenstatus as userEmail in context is being updated already
                // handleOnDemandScreenStatus('logged');
                if (QRcodeCallback) {
                  QRcodeCallback();
                }
              } else {
                if (error) {
                  setError(error);
                }
                if (needToRegister) {
                  const userPayloadWithSocialNetwork = {
                    ...userPayload,
                    profileObj:
                    { ...userPayload.profileObj, isSocialLogin: type },
                  };
                  // alert("usuario no registrado completa tu registro")
                  handleRegisteringStatus(true, userPayloadWithSocialNetwork, 'facebook');
                } else {
                  console.log('**** User Not Valid by Server, Sorry ****');
                }
              }
            }
          }}
          onFailure={() => { console.log('failed'); }}
        />

      );
      break;
  }
  return jsxReturned;
};


SocialOnDemandButton.propTypes = {
  language: PropTypes.string,
  loadTokenForLS: PropTypes.func.isRequired,
  handleOnDemandScreenStatus: PropTypes.func.isRequired,
  handleRegisteringStatus: PropTypes.func.isRequired,
  sourceWeb: PropTypes.string,
};

SocialOnDemandButton.defaultTypes = {
  language: 'en',
  sourceWeb: '',
};

export default SocialOnDemandButton;
