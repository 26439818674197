import React from 'react';
import { PropTypes } from 'prop-types';

import { withRouter } from 'react-router-dom';
import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';
import ComponentIterator from '../../containers/ComponentIterator';
import RadioHeader1 from './RadioHeader1';
import imageModifier from './helpers/imageModifier';

class RadioBox1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.header.open || false,
    };
  }

  updateOpen(open) {
    this.setState({ open });
  }

  render() {
    const {
      match,
      title,
      tags,
      header,
      imageUrl,
      url,
    } = this.props;

    const { open } = this.state;

    return (
      <RoutesContext.Consumer>
        {
        ({ language: contextLanguage, enteredFrom }) => {
          const localization = getLocalization(contextLanguage, enteredFrom, match);

          return (
            <div className="w-full">
              <RadioHeader1 {...header} toggleCallback={this.updateOpen.bind(this)} />
              <div className={`relative ${open ? 'sm:block' : 'sm:hidden'}`}>
                <div className="relative launcherGradientContainer">
                  <img src={imageModifier(imageUrl, '445x260')} alt={title[localization.language]} />
                </div>
                <div className="absolute pin-b pin-x p-4">
                  <h2 className="font-americaBlack text-xl mb-4">
                    <a className="text-white" href={url[localization.language]}>{title[localization.language]}</a>
                  </h2>
                  <div>
                    <ComponentIterator components={tags} />
                  </div>
                </div>
              </div>
            </div>
          );
        }
      }
      </RoutesContext.Consumer>
    );
  }
}

RadioBox1.propTypes = {
  title: PropTypes.shape().isRequired,
  tags: PropTypes.array.isRequired,
  header: PropTypes.shape().isRequired,
  imageUrl: PropTypes.string.isRequired,
  url: PropTypes.shape().isRequired,
};

export default withRouter(RadioBox1);
