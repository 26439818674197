import React, { useEffect, useState, useContext } from 'react';
import { PropTypes } from 'prop-types';
import moment from 'moment';
import md5 from 'crypto-js/md5';
import { useUser } from '../../../../context/userProfileContext';
import {
  enterChannel, getChannel, getOnlineUsers, hangUpPrivateCall, updateLastSeen,
} from '../utils/helpers';
import { useFirebaseAuth, firebaseauth } from './FirebaseAuthContext';
import { useMutedUsers, useVideoCalls, usePendingVideoCalls } from '../hooks/useMessages';
import useInterval from '../hooks/useInterval';

const ChatContext = React.createContext(null);

export const useChat = () => {
  const {
    loadMoreUsers, pendingVideoCalls, userIsMuted, mutedUsers, activeVideoCall, showVideoRoomButton, getActiveUsers, activeUsers, notLogged, user, id, channelGeneral, channelQuestions, isConnected,
  } = useContext(ChatContext);

  const data = {};
  data.userId = id;
  data.channelGeneral = channelGeneral;
  data.channelQuestions = channelQuestions;
  data.notLogged = notLogged;
  data.userData = user;
  data.isConnected = isConnected;
  data.activeUsers = activeUsers;
  data.getActiveUsers = getActiveUsers;
  data.showVideoRoomButton = showVideoRoomButton;
  data.mutedUsers = mutedUsers;
  data.userIsMuted = userIsMuted;
  data.activeVideoCall = activeVideoCall;
  data.pendingVideoCalls = pendingVideoCalls;
  data.loadMoreUsers = loadMoreUsers;
  return data;
};

export const ChatProvider = ({
  children, chatChannelID, qaChannelID, zones,
}) => {
  const { userEmail, userNickName } = useUser();
  const { firebaseUser, singInWithNewToken } = useFirebaseAuth();
  const id = md5(userEmail).toString();
  const [notLogged, setNotLogged] = useState(true);
  const [user, setUser] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [channelGeneral, setChannelGeneral] = useState(null);
  const [channelQuestions, setChannelQuestions] = useState(null);
  const [nickname, setNickName] = useState(userNickName);
  const [activeUsers, setActiveUsers] = useState(null);
  const [userPaginationFrom, setUserFrom] = useState(null);
  const showVideoRoomButton = zones && zones.length > 0;
  const { mutedUsers, userIsMuted } = useMutedUsers({ channel: chatChannelID });
  const { activeVideoCall } = useVideoCalls();
  const { pendingVideoCalls } = usePendingVideoCalls();
  const [chatChannelExists, setChatChannelExists] = useState(null);
  const [qaChannelExists, setQaChannelExists] = useState(null);
  const [loadMoreUsers, setLoadMoreUsers] = useState(false);

  const startUpdateUser = async () => {
    const { currentUser } = firebaseauth;
    if (!currentUser) {
      await singInWithNewToken();
      updateLastSeen({ channel: chatChannelID });
    } else {
      updateLastSeen({ channel: chatChannelID });
    }
  };

  const { start, stop } = useInterval(startUpdateUser);

  const setActiveUsersCallback = (snapshot) => {
    const { docs } = snapshot;
    if (docs && docs.length) {
      setUserFrom(docs[snapshot.docs.length - 1]);
      if (docs.length > 999) {
        setLoadMoreUsers(true);
      } else {
        setLoadMoreUsers(false);
      }
      const visibleActive = docs.filter((item) => item.id !== user.userId).map((item) => item.data()).sort((a, b) => a.nickname.localeCompare(b.nickname));
      setActiveUsers(visibleActive);
    }
  };

  const loadActiveUsersCallback = (snapshot) => {
    const { docs } = snapshot;
    if (docs && docs.length) {
      setUserFrom(docs[snapshot.docs.length - 1]);
      const visibleActive = docs.filter((item) => item.id !== user.userId).map((item) => item.data()).sort((a, b) => a.nickname.localeCompare(b.nickname));
      for (let i = 0; i < visibleActive.length; i++) {
        if (visibleActive[i].userId && activeUsers.some((item) => item.userId === visibleActive[i].userId)) {
          visibleActive.splice(i, 1);
        }
      }
      setActiveUsers([...activeUsers, ...visibleActive]);
      if (docs.length > 999) {
        setLoadMoreUsers(true);
      }
    } else {
      setLoadMoreUsers(false);
    }
  };

  const getActiveUsers = (shouldLoadNew = false) => {
    if (!shouldLoadNew) {
      getOnlineUsers({ channelUrl: chatChannelID, limit: 1000 }, setActiveUsersCallback);
    } else {
      getOnlineUsers({ channelUrl: chatChannelID, limit: 1000, from: userPaginationFrom }, loadActiveUsersCallback);
    }
  };

  const init = (channelUrl, type) => {
    const data = {
      channel: channelUrl,
      userData: firebaseUser,
    };
    enterChannel(data).then((res) => {
      const { error } = res;
      if (!error) {
        if (type === 'chat') {
          setChannelGeneral(chatChannelID);
          setChatChannelExists(true);
        }
        if ((type === 'qa')) {
          setChannelQuestions(qaChannelID);
          setQaChannelExists(true);
        }
      } else {
        if (type === 'chat') {
          setChatChannelExists(false);
          stop();
        }
        if ((type === 'qa')) {
          setChannelQuestions(qaChannelID);
          setQaChannelExists(false);
        }
      }
    });
  };

  const checkChannels = () => {
    if (chatChannelID) {
      getChannel({ channelUrl: chatChannelID }).then((doc) => {
        if (doc.exists) {
          setChannelGeneral(chatChannelID);
          setChatChannelExists(true);
        } else {
          setChatChannelExists(false);
        }
      });
    }
    if (qaChannelID) {
      getChannel({ channelUrl: qaChannelID }).then((doc) => {
        if (doc.exists) {
          setChannelQuestions(qaChannelID);
          setQaChannelExists(true);
        } else {
          setQaChannelExists(false);
        }
      });
    }
  };

  useEffect(() => {
    if (firebaseUser) {
      setUser(firebaseUser);
      setNotLogged(false);
      setIsConnected(true);
    } else {
      setUser(null);
    }

    if (firebaseUser && !user) {
      if (chatChannelID) init(chatChannelID, 'chat');
      if (qaChannelID) init(qaChannelID, 'qa');
      hangUpPrivateCall(firebaseUser.userId);
    }
  }, [firebaseUser]);

  useEffect(() => {
    if (!userEmail) {
      checkChannels();
    }
  }, [userEmail]);

  useEffect(() => {
    if (userNickName) {
      setNickName(userNickName);
    }
    if (user && userNickName !== user.nickname) {
      if (chatChannelID) init(chatChannelID);
      if (qaChannelID) init(qaChannelID);
    }
  }, [userNickName]);

  useEffect(() => {
    if (user && chatChannelExists) {
      start(300000);
    }
  }, [user, chatChannelExists]);

  useEffect(() => () => {
    stop();
  }, []);

  return (
    <ChatContext.Provider value={{
      loadMoreUsers, pendingVideoCalls, activeVideoCall, mutedUsers, userIsMuted, showVideoRoomButton, getActiveUsers, activeUsers, chatChannelID, qaChannelID, isConnected, id, nickname, notLogged, user, channelGeneral, channelQuestions,
    }}
    >
      {children}
    </ChatContext.Provider>
  );
};

ChatProvider.propTypes = {
  chatChannelID: PropTypes.string,
  qaChannelID: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  zones: PropTypes.arrayOf(PropTypes.string),
};

ChatProvider.defaultProps = {
  chatChannelID: '',
  qaChannelID: '',
  zones: [],
};
