import React from 'react';
import PropTypes from 'prop-types';

const TopHeaderMegamenuFooter = ({ children, name }) => (
  <div data-name="footer" id={name} className="flex relative flex-wrap border-t border-black">
    { children }
  </div>
);

TopHeaderMegamenuFooter.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.shape().isRequired,
};

export default TopHeaderMegamenuFooter;
