import React from 'react';
import { PropTypes } from 'prop-types';
import moment from 'moment-timezone';
import LineupGridDay from './LineupGridDay';

class LineupGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      artistsByDay: {},
    };
  }

  componentWillMount = () => {
    this.findStartAndEndHours();
  }

  componentDidUpdate(prevProps) {
    const { artistsByDay } = this.props;
    if (artistsByDay !== prevProps.artistsByDay) {
      this.findStartAndEndHours();
    }
  }

  findStartAndEndHours() {
    const { artistsByDay, timezone } = this.props;

    let localArtistsByDay = JSON.parse(JSON.stringify(artistsByDay));
    //let localVenues = JSON.parse(JSON.stringify(venues));

    // Detect if ArtistByDay has All channel, remove it
    Object.keys(localArtistsByDay).map(day => Object.keys(localArtistsByDay[day]).map(venue => {
      venue === "All" && delete localArtistsByDay[day][venue];
    }))

    const startAndEndHours = {};

    Object.keys(localArtistsByDay).forEach((day) => {
      let minDate;
      let maxDate;
      const usedHours = [];
      Object.keys(localArtistsByDay[day]).forEach((venue) => {
        localArtistsByDay[day][venue].forEach((artist, artistIndex) => {
          const artistStartDate = moment(artist.dateTimeStartReal / 1000, 'X');
          const artistEndDate = artistStartDate.clone();
          artistEndDate.add(artist.duration, 'minutes');

          usedHours.push({ start: artistStartDate.clone(), end: artistEndDate.clone(), artist: artist.artistName });

          if (!minDate || artistStartDate < minDate) {
            minDate = artistStartDate;
          }
          if (!maxDate || artistEndDate > maxDate) {
            maxDate = artistEndDate;
          }

          localArtistsByDay[day][venue][
            artistIndex
          ].datetimeStartRealParsed = artistStartDate.clone();
          localArtistsByDay[day][venue][
            artistIndex
          ].scheduleHuman = `${artistStartDate.tz(timezone).format('HH:mm')}–${artistEndDate.tz(timezone).format('HH:mm')}h`;
        });
      });

      let startAndHoursInfo = false;

      if (minDate && maxDate) {
        minDate.startOf('hour').subtract(2, 'h');
        maxDate.endOf('hour');

        startAndHoursInfo = {
          minDate: minDate.clone(),
          maxDate: maxDate.clone(),
        };

        const hours = {};
        for (const m = minDate; m.diff(maxDate, 'hours') <= 0; m.add(1, 'hours')) {
          let used = false;
          const concertDate = m.clone();
          const concertDateEnd = concertDate.clone().add(1, 'hour');
          usedHours.forEach((usedHour) => {
            if (
              usedHour.start.isBetween(concertDate, concertDateEnd, null, '()')
              || usedHour.end.isBetween(concertDate, concertDateEnd, null, '()')
              || (usedHour.start <= concertDate && usedHour.end >= concertDateEnd)
            ) {
              used = true;
            }
          });

          hours[m.format('YYYY-MM-DD HH:00')] = used;
        }
        startAndHoursInfo.hours = hours;
      }

      startAndEndHours[day] = startAndHoursInfo;
    });

    Object.keys(localArtistsByDay).forEach((day) => {
      Object.keys(localArtistsByDay[day]).forEach((venue) => {
        localArtistsByDay[day][venue].forEach((artist, artistIndex) => {
          localArtistsByDay[day][venue][
            artistIndex
          ].minutesFromStartDate = moment
            .duration(
              artist.datetimeStartRealParsed.diff(startAndEndHours[day].minDate),
            )
            .asMinutes();
          delete localArtistsByDay[day][venue][artistIndex].datetimeStartRealParsed;
        });
      });
    });

    this.setState({ startAndEndHours, artistsByDay: localArtistsByDay, ready: true });
  }

  render() {
    const {
      venues, eventSlug, hasConferences, isUserLoggedIn, handleSetArtistSetFavorite, category, timezone
    } = this.props;
    const { startAndEndHours, ready, artistsByDay } = this.state;

    return (
      <div className="w-full">
        {ready
          && Object.keys(artistsByDay).map(day => (startAndEndHours[day] !== false ? (
            <LineupGridDay
              key={day}
              day={day}
              artistsByDay={artistsByDay}
              startAndEndHours={startAndEndHours}
              venues={venues}
              eventSlug={eventSlug}
              hasConferences={hasConferences}
              isUserLoggedIn={isUserLoggedIn}
              handleSetArtistSetFavorite={handleSetArtistSetFavorite}
              category={category}
              timezone={timezone}
            />
          ) : null))}
      </div>
    );
  }
}

LineupGrid.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  artistsByDay: PropTypes.any.isRequired,
  venues: PropTypes.shape().isRequired,
};

export default LineupGrid;
