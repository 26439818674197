/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';

import { useRoutes } from '../../../context/routesContext';
import translateObj from '../../../util/translateObj';

const getError = (errors, name) => {
  if (Object.prototype.hasOwnProperty.call(errors, name)) {
    return errors[name];
  }
  return undefined;
};

const FormCheckbox = ({ name, label, checked, required, className, labelClassName, errors }) => {
  const { language } = useRoutes();
  const [error, setError] = useState(undefined);

  useEffect(() => {
    const fieldError = getError(errors, name);
    setError(fieldError);
  }, [errors]);

  return (
    <div className={className}>
      <label className={`${labelClassName} flex cursor-pointer ${error ? 'text-pro-online' : 'text-black'}`} id={name}>
        <div className="relative mr-4">
          <Field
            id={name}
            name={name}
            type="checkbox"
            required={required}
            className="absolute hidden"
            checked={checked}
          />
          <div className={`border ${error ? 'border-pro-online' : 'border-black'} rounded h-5 w-5 mt-px flex items-center justify-center`}>
            {checked && <div className="font-icon icon-checkbox text-green-checkbox text-base" />}
          </div>
        </div>
        {translateObj(label, language)}
      </label>
    </div>
  );
};

FormCheckbox.propTypes = {
  name: PropTypes.string,
  label: PropTypes.shape(),
  checked: PropTypes.bool,
  required: PropTypes.bool,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  errors: PropTypes.shape(),
};

FormCheckbox.defaultProps = {
  name: '',
  label: null,
  checked: false,
  required: false,
  className: 'w-full',
  labelClassName: 'text-12 font-tiemposRegular',
  errors: {},
};

export default FormCheckbox;
