import React, { useContext } from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import translateObj from '../../../util/translateObj';
import { HeaderContext } from '../../../context/headerContext';
import withAllContexts from '../../../context/withAllContexts';

const ARIA_LABEL_LOGIN = {
  en: 'Open login screen',
  es: 'Abrir pantalla de inicio de sesión',
  ca: 'Obre la pantalla d\'inici de sessió',
  pt: 'Abrir tela de login',
};

const MenuPopUpLoginButton = ({
  language,
  isCentered,
  buttonText,
  textColor,
  icon,
  sourceWeb,
  postVideoUrl,
  postVideoRole,
  iconClasses,
  showText,
  redesign,
}) => {
  const { setAuthOpen } = useContext(HeaderContext);
  return (
    <div role="button" className={`flex ${isCentered ? 'justify-center' : 'sm:justify-end md:justify-end justify-start'}`}>
      {redesign ? (
        <button
          type="button"
          className="w-8 h-8 rounded-full border border-black flex items-center justify-center"
          aria-label={translateObj(ARIA_LABEL_LOGIN, language)}
          onClick={() => setAuthOpen('login')}
        >
          {icon && <span className={`${iconClasses} ${icon}`} />}
        </button>
      ) : (
        <button
          type="button"
          className={`text-${textColor} font-americaMonoRegular cursor-pointer  text-center px-4  ${(!sourceWeb && showText) && `xl:border-r lg:border-r border-${textColor}`} uppercase text-12 flex justify-center items-center`}
          aria-label={translateObj(ARIA_LABEL_LOGIN, language)}
          onClick={() => setAuthOpen('login')}
        >
          {icon && <span className={`${iconClasses} ${icon}`} />}
          {showText && <span className="text-12 sm:hidden md:hidden align-text-top mb-px">{translateObj(buttonText, language)}</span> }
        </button>
      )}
    </div>
  );
};

MenuPopUpLoginButton.propTypes = {
  buttonText: PropTypes.shape(),
  icon: PropTypes.string,
  postVideoRole: PropTypes.string,
  postVideoUrl: PropTypes.string,
  isCentered: PropTypes.bool,
  sourceWeb: PropTypes.string,
  language: PropTypes.string,
  textColor: PropTypes.string,
  iconClasses: PropTypes.string,
  showText: PropTypes.bool,
  redesign: PropTypes.bool,
};

MenuPopUpLoginButton.defaultProps = {
  isCentered: false,
  sourceWeb: '',
  language: 'en',
  postVideoRole: '',
  postVideoUrl: '',
  textColor: 'black',
  icon: 'font-icon1 icon-userlogin font-semibold ',
  iconClasses: 'px-4 text-black sm:text-xl md:text-xl text-base',
  buttonText: {
    en: 'log in',
    es: 'iniciar sesión',
    ca: 'iniciar sessió',
    pt: 'iniciar sessāo',
  },
  showText: true,
  redesign: false,
};
export default withAllContexts(withRouter(MenuPopUpLoginButton));
