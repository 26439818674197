import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import { LinkWrapper } from '../../../../util/link';

import { useRoutes } from '../../../../context/routesContext';
import translateObj from '../../../../util/translateObj';


const MainMenuMusicMeets = ({ params }) => {
  const { language, path } = useRoutes();
  const { pathname } = useLocation();
  const [activeItem, setActiveItem] = useState();

  useEffect(() => {
    setActiveItem(pathname);
  }, []);

  return (
    <div className="w-full bg-black flex items-start justify-start overflow-x-scroll px-4 scrollbar__hidden">
      {params.map((link, index) => (
        <div key={link.key} className="flex items-start justify-start">
          {index !== 0 && <div className="px-4 pt-3 text-white font-americaMonoRegular text-14 tracking-px">|</div>}
          <LinkWrapper url={link.link} path={path}>
            <div
              className={`whitespace-no-wrap pt-3 sm:pb-4 pb-5 text-white uppercase border-b-5 text-14 tracking-px ${activeItem === `${path}${link.link}` ? 'font-americaMonoBold border-pro-online' : 'font-americaMonoRegular border-transparent'}`}
            >
              {translateObj(link.text, language)}
            </div>
          </LinkWrapper>
        </div>
      ))}
    </div>
  );
};

MainMenuMusicMeets.propTypes = {
  params: PropTypes.arrayOf(PropTypes.shape()),
};

MainMenuMusicMeets.defaultProps = {
  params: [],
};

export default MainMenuMusicMeets;
