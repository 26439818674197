import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import { PropTypes } from 'prop-types';
import addUserImage from '../../../api/graphql/mutations/addUserImage';
import checkFileType from './util/checkFileType';
import translateObj from '../../../util/translateObj';
import { useRoutes } from '../../../context/routesContext';


const Uploader = ({ literals, getImageCallback }) => {
  const initialState = {
    assetSlugName: '',
    filename: '',
    fileExtension: '',
    url: '',
    fileReadableName: {
      en: '',
      es: '',
      ca: '',
      pt: '',
    },
    description: {
      en: '',
      es: '',
      ca: '',
      pt: '',
    },
    type: '',
    tags: [],
    isPublic: false,
  };

  const { language } = useRoutes();
  const [uploading, setUploading] = useState(false);
  const [state, setState] = useState(initialState);

  const saveObjectBeforeClse = (obj) => {
    addUserImage(obj)
      .then((res) => {
        if (getImageCallback) {
          getImageCallback(res);
        }
        setState(initialState);
      })
      .catch(err => console.log(err));
  };

  const onDrop = useCallback((files) => {
    // route where file will be uploaded, the route will be the same fot all files
    files.forEach((file) => {
      // generate a random name for the file
      // eslint-disable-next-line no-bitwise
      const randomFileName = [...Array(20)].map(() => (~~(Math.random() * 36)).toString(36)).join('');
      const fileExtension = file.name.split('.').pop();
      const filename = `${randomFileName}_${Date.now().toString()}`;
      const filenameWithExtension = `${randomFileName}_${Date.now().toString()}.${fileExtension}`;
      const url = `https://assets.primaverasound.com/psweb/${filenameWithExtension}`;

      // if (fileExtension === 'jpeg') return setError('You can only images in .PNG or .JPG formats');

      axios
        .get('/s3', {
          params: {
            fileName: filenameWithExtension,
            fileType: file.type,
            type: 'upload',
          },
        })
        .then(async (result) => {
          const signedUrl = result.data;
          const options = {
            headers: {
              'Content-Type': file.type,
            },
            crossDomain: true,
          };
          setUploading(true);
          await axios.put(signedUrl, file, options);
          setUploading(false);
        })
        .then((res) => {
          // once uploaded to s3, the object is uploaded to the DB
          const assetObject = {
            ...state,
            assetSlugName: filename,
            fileReadableName: {
              en: file.name.substring(0, file.name.lastIndexOf('.')) || file.name,
            },
            filename,
            fileExtension,
            url,
            type: checkFileType(fileExtension),
          };

          setState(assetObject);
          saveObjectBeforeClse(assetObject);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  });

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div className="flex items-center justify-start">
      <div {...getRootProps()} className="mb-2 sm:-mb-2 cursor-pointer">
        <input {...getInputProps()} />
        <div className="my-4 text-base sm:text-14 uppercase tracking-px text-black font-americaMonoBold">
          <span className="border-b-2 border-black pb-1">{translateObj(literals.buttonChangeImage, language)}</span>
        </div>
      </div>
      {uploading && (
      <p className="font-americaMonoRegular uppercase mx-4">
        {translateObj(literals.uploadingFile, language)}
      </p>
      )}
    </div>
  );
};

Uploader.propTypes = {
  getImageCallback: PropTypes.func,
  literals: PropTypes.shape(),
  uploaded: PropTypes.bool,
};

Uploader.defaultProps = {
  getImageCallback: null,
  literals: {},
  uploaded: false,
};

export default Uploader;
