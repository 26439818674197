// @ts-check

import React, { Component, Fragment } from 'react';
import { propTypes, withFormsy } from 'formsy-react';
import { TimelineMax, Power3 } from 'gsap';

import translateObj from '../../../util/translateObj';

const defaultSuccessNow = {
  en: 'Thank you, check your email to confirm.',
  es: 'Gracias, revisa tu email para confirmar.',
  ca: 'Gràcies, revisa el teu correu per confirmar.',
};

const defaultError = {
  en: 'Something went wrong, please try again later',
  es: 'Algo salió mal, por favor inténtalo más tarde',
  ca: 'Alguna cosa ha sortit malament, si us plau intenta-ho més tard',
};

const defaultErrorEmail = {
  en: 'The email seems to be incorrect',
  es: 'Tu correo electrónico parece ser incorrecto',
  ca: 'El teu correu electrónic sembla ser incorrecte',
};

class FooterRedesignInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
    };

    this.inputFieldTextTween = new TimelineMax();
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { getValue, didSubmit, error, resetForm, setValue } = this.props;

    if (prevProps !== this.props && prevProps.didSubmit !== didSubmit) {
      this.inputFieldTextTween.stop();
      this.animateInputField();
      if (error) {
        setTimeout(() => {
          resetForm();
          setValue('');
          this.setState({ value: '' });
          this.inputFieldTextTween.stop();
        }, 3500);
      }
    }

    if (prevProps !== this.props && getValue() !== undefined) {
      if (prevState.value !== getValue()) {
        this.setState({
          value: getValue(),
        });
      }
    }
  }

  changeValue = (e) => {
    this.setState({
      value: e.currentTarget.value,
    });

    this.validate(e);
  }

  validate = (e) => {
    const { setValue } = this.props;
    setValue(e.currentTarget.value);
  }

  establishInputFieldMessage = () => {
    const { error, isValidStatus } = this.props;
    let defaultMessage;

    if (isValidStatus === 'emailError') {
      defaultMessage = { ...defaultErrorEmail };
    } else if (error) {
      defaultMessage = { ...defaultError };
    } else {
      defaultMessage = { ...defaultSuccessNow };
    }

    return defaultMessage;
  }

  animateInputField = () => {
    const { setValue, language, successNow } = this.props;
    const defaultMessage = this.establishInputFieldMessage();

    this.inputFieldTextTween
      .to(this.inputField, 0.0, { opacity: 1 })
      .to(this.inputField, 0.35, {
        opacity: 0,
        onComplete: () => {
          setValue(translateObj(successNow || defaultMessage, language));
        },
      })
      .to(this.inputField, 0.35, { paddingLeft: 0, opacity: 0 })
      .to(this.inputField, 0.5, {
        opacity: 1,
        ease: Power3.easeOut,
      })
      .play();
  }

  render() {
    const { name, placeholder, didSubmit, error } = this.props;

    const { value } = this.state;

    return (
      <Fragment>
        <input
          ref={(c) => {
            this.inputField = c;
            return this.inputField;
          }}
          onChange={this.changeValue}
          value={value}
          type="text"
          name={name}
          autoComplete="off"
          placeholder={placeholder}
          readOnly={didSubmit ? 'readonly' : ''}
          className={`
            w-full bg-transparent rounded-none border-b py-2 pr-5 ${error ? 'border-red' : 'border-white'}
            leading-none font-sans text-left text-white text-11px
          `}
          aria-label={placeholder}
        />
      </Fragment>
    );
  }
}

FooterRedesignInput.propTypes = {
  ...propTypes,
};

export default withFormsy(FooterRedesignInput);
