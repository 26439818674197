import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Modal from 'react-responsive-modal';
import { useRoutes } from '../../../context/routesContext';
import useResponsiveUtility from '../../../util/responsiveUtility';
import translateObj from '../../../util/translateObj';
import cancelReunion from '../../../api/graphql/mutations/cancelReunion';

import Button from './Button';

const getModalStyles = mobileScreen => ({
  root: {
    width: '100vw',
    height: '100%',
    padding: 0,
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
    width: '100vw',
    height: '100%',
    padding: 0,
  },
  modalContainer: {
    width: '100vw',
    height: '100%',
    padding: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modal: {
    background: 'rgba(255, 255, 255, 1)',
    boxShadow: 'none',
    width: mobileScreen ? '90vw' : '50vw',
    maxWidth: mobileScreen ? '90vw' : '50vw',
    height: 'auto',
    padding: 0,
    margin: 'auto',
    borderRadius: '0.5rem',
  },
});

const ReunionItemCancellationModal = ({
  open,
  onClose,
  reunionId,
  reunionTitle,
  callback,
  title,
  text,
  buttonLabel,
}) => {
  const { language } = useRoutes();
  const { deviceScreen } = useResponsiveUtility();
  const [message, setMessage] = useState();

  const modalStyles = getModalStyles(['sm', 'md'].includes(deviceScreen));

  const handleCancel = () => {
    cancelReunion({ reunionId, text: message }).then((res) => {
      if (res.result) {
        if (callback) {
          callback();
        }
        onClose();
      }
    }).catch(err => console.log(err));
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      showCloseIcon
      styles={modalStyles}
      closeOnOverlayClick
      focusTrapped={false}
    >
      <div className="text-black relative pt-20 pb-10 px-6 h-full w-full">
        <h3 className="text-20 font-americaBlack mb-4">
          {`${translateObj(title, language)}:`}
          <span className="text-pro-online mx-2">{reunionTitle}</span>
        </h3>
        <p className="font-americaMonoRegular text-base mb-4">{translateObj(text, language)}</p>
        <textarea
          style={{ resize: 'none', height: ['sm', 'md'].includes(deviceScreen) ? '30vh' : '15vw' }}
          name="cancellationMessage"
          className="w-full h-1/2 bg-grayE2DDDB rounded-lg p-4 mb-6 font-americaMonoRegular text-base"
          onChange={e => setMessage(e.target.value)}
          maxLength={280}
        />
        <Button
          onClickCallback={() => handleCancel()}
          text={translateObj(buttonLabel, language)}
          className="w-full"
        />
      </div>
    </Modal>
  );
};

ReunionItemCancellationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  reunionId: PropTypes.string.isRequired,
  reunionTitle: PropTypes.string,
  callback: PropTypes.func,
  title: PropTypes.shape(),
  text: PropTypes.shape(),
  buttonLabel: PropTypes.shape(),
};

ReunionItemCancellationModal.defaultProps = {
  callback: null,
  reunionTitle: '',
  title: { en: '', es: '', ca: '', pt: '' },
  text: { en: '', es: '', ca: '', pt: '' },
  buttonLabel: { en: '', es: '', ca: '', pt: '' },
};

export default ReunionItemCancellationModal;
