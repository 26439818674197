import { useState, useEffect } from 'react';
import { getPaymentDetails } from './paymentFunctions';
import { useUser } from '../../../../context/userProfileContext';

const useGetPaymentDetails = () => {
  const [result, setResult] = useState({ status: 'pending', paymentData: null });
  const { userEmail } = useUser();


  const getPaymentDetailsFn = async () => {
    const resultFromFn = await getPaymentDetails(userEmail);
    return resultFromFn;
  };

  const init = async () => {
    const resultFromFn = await getPaymentDetailsFn();
    setResult(resultFromFn);
  };

  useEffect(() => {
    if (userEmail) {
      init();
    }
  }, [userEmail]);

  if (result) return result;
  return null;
};

export { useGetPaymentDetails };
