import React from 'react';
import { PropTypes } from 'prop-types';
import ComponentIterator from '../../containers/ComponentIterator';
import TagWithLink from './Buttons/TagWithLink';

const TagsRow = ({ components, className }) => (
  <div className={`flex flex-wrap ${className}`}>
    {components.map((component, index) => (
      <div key={component.compId} className={`${index !== components.length - 1 ? 'mr-1 sm:mb-1' : ''}`}>
        <ComponentIterator components={[component]} />
      </div>
    ))}
  </div>
);

TagsRow.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  components: PropTypes.array.isRequired,
  className: PropTypes.string,
};

TagsRow.defaultProps = {
  className: '',
};

export default TagsRow;
