import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';

import { useRadioContext } from '../../context/RadioContextProvider';
import getRadioShows from '../../api/graphql/queries/getRadioShows';
import RadioShowCardListItem from './Radio/RadioShowCardListItem';
import ParagraphLineSM from './Text/ParagraphLineSM';
import RadioTodayGrid from './Radio/RadioTodayGrid';
import RadioEpisodesList from './Radio/RadioEpisodesList';
import SidebarChannelCard from './Radio/SidebarChannelCard';
import NavigatorRadio from './NavigatorRadio';

const highLightText = { en: 'Featured shows', es: 'Programas destacados', ca: 'Programes destacats' };

const RadioChannel = ({ showSidebarChannelCard, showHighlights, showRecentEpisodes, showNavigator }) => {
  const [highlights, setHighlights] = useState([]);
  const { ch1, channel, slug } = useRadioContext();

  useEffect(() => {
    if (!showHighlights) return;
    getRadioShows({ highlight: true, limit: 6 })
      .then((result) => {
        if (result) setHighlights(result);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  if (!ch1) return null;
  if (!Object.keys(ch1).length) return null;

  return (
    <div className="w-full">
      {showNavigator && <NavigatorRadio />}
      <div className="mt-4 sm:mt-3 md:mt-3 w-full flex flex-wrap">
        {showSidebarChannelCard && (
        <div className="w-1/4 sm:w-full md:w-full sm:mb-4 md:mb-4">
          <SidebarChannelCard {...ch1} />
        </div>
        )}
        <div className={`${showSidebarChannelCard ? 'w-3/4 pl-4' : 'w-full'} sm:w-full md:w-full sm:pl-0 md:pl-0`}>
          <div className="mb-16 relative">
            <RadioTodayGrid channel={channel} />
          </div>
          {showRecentEpisodes && (
          <div className="mb-4 relative mt-4 border-black border-t-4 pt-4">
            <>
              <div className="w-full pb-2">
                <ParagraphLineSM classes="uppercase font-bold text-xxs" text={{ en: 'Recently added episodes', es: 'EPISODIOS AÑADIDOS RECIENTEMENTE', ca: 'EPISODIS AFEGITS RECENTMENT' }} />
              </div>
              <RadioEpisodesList limit={4} channel={channel} parentSlug={slug} activeView="list" />
            </>
          </div>
          )}
          {highlights?.length
            ? (
              <div className="mb-4 relative mt-4 border-black border-t-4 pt-4">
                <div className="w-full pb-4">
                  <ParagraphLineSM classes="uppercase font-bold text-xxs" text={highLightText} />
                </div>
                {
                  highlights.map(show => <RadioShowCardListItem key={show.id} favouriteStar={false} {...show} />)
                }
              </div>
            )
            : null
          }
        </div>
      </div>
    </div>
  );
};

RadioChannel.propTypes = {
  showSidebarChannelCard: PropTypes.bool,
  showHighlights: PropTypes.bool,
  showRecentEpisodes: PropTypes.bool,
  showNavigator: PropTypes.bool,
};

RadioChannel.defaultProps = {
  showSidebarChannelCard: true,
  showHighlights: true,
  showRecentEpisodes: true,
  showNavigator: true,
};

export default RadioChannel;
