import React from 'react';
import PropTypes from 'prop-types';

const NavigationArrows = ({
  arrowRef,
  useArrowCallback,
}) => (
  <div ref={arrowRef} className="absolute z-10 py-1 pin-r mr-8 sm:mr-4" style={{ opacity: 0 }}>
    <div className="rounded-full bg-black flex items-center justify-center h-12 w-12 mb-2 pr-1 shadow cursor-pointer" onClick={() => useArrowCallback(false)}>
      <span className="font-icon text-white icon-arrow-left" />
    </div>
    <div className="rounded-full bg-black flex items-center justify-center h-12 w-12 mt-2 pl-1 shadow cursor-pointer" onClick={() => useArrowCallback(true)}>
      <span className="font-icon text-white icon-arrow-right" />
    </div>
  </div>
);

NavigationArrows.propTypes = {
  useArrowCallback: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  arrowRef: PropTypes.any.isRequired,
};
export default NavigationArrows;
