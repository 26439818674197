import React from 'react';
import PropTypes from 'prop-types';
import HourLabel from './HourLabel';

const HoursRow = ({
  day,
  layout,
  startAndEndHours,
  firstColumnRef,
}) => {
  let hasRef = false;
  return (
    <div className="border-r-1 border-solid border-white bg-grayE2DDDB z-10 flex">
      {startAndEndHours && (
        Object.keys(startAndEndHours[day].hours).map((hour, index) => {
          let ref = null;
          if (!hasRef && startAndEndHours[day].hours[hour]) {
            hasRef = true;
            ref = firstColumnRef;
          }
          return (
            <HourLabel index={index} hasEvent={startAndEndHours[day].hours[hour]} layout={layout} firstColumnRef={ref} key={hour} hour={hour} />
          );
        }))}
    </div>
  );
};

HoursRow.propTypes = {
  day: PropTypes.string.isRequired,
  layout: PropTypes.string.isRequired,
  startAndEndHours: PropTypes.shape().isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  firstColumnRef: PropTypes.object.isRequired,
};
export default HoursRow;
