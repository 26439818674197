import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAuth } from '@primaveralabs/auth';
import { useHistory } from 'react-router-dom';

import { useRoutes } from '../../../context/routesContext';
import { LinkWrapper } from '../../../util/link';

import translateObj from '../../../util/translateObj';

import 'Primavera/ui-themes/dist/primavera/index.css';
import translation from './translations/common';
import { REDIRECT_URL_SLUGNAME } from './UserPanel';

const UserPanelSection = ({ children = {}, title, subtitle }) => {
  const { language, path } = useRoutes();
  const { user } = useAuth();
  const history = useHistory();

  useEffect(() => {
    if (!user) history.push(`/${language}/${REDIRECT_URL_SLUGNAME}`);
  }, [user]);

  if (!user) return null;

  return (
    <div
      id="userPanelSection"
      className="bg-panel-black flex flex-col w-full relative h-full full-width"
    >
      <div className="m-12 sm:m-4 md:mb-24">
        <LinkWrapper url="/user-panel" path={path}>
          <div className="uppercase text-base font-americaMonoRegular text-white tracking-px flex items-center">
            <div className="w-1.5 mr-4">
              <svg
                width="100%"
                height="100%"
                viewBox="0 0 8 14"
                fill="transparent"
              >
                <path
                  d="M7 13L1 7L7 1"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            {translateObj(translation.goBack, language)}
          </div>
        </LinkWrapper>
        <div className="text-white mb-2 mt-8">
          <h1 className="font-americaBold text-5xl sm:text-30">
            {translateObj(title, language)}
          </h1>
          <h3 className="font-americaRegular text-2xl">
            {translateObj(subtitle, language)}
          </h3>
        </div>
      </div>
      {children}
    </div>
  );
};

UserPanelSection.propTypes = {
  children: PropTypes.shape().isRequired,
  title: PropTypes.shape(),
  subtitle: PropTypes.shape(),
};

UserPanelSection.defaultProps = {
  title: { en: '' },
  subtitle: { en: '' },
};

export default UserPanelSection;
