import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import Modal from 'react-responsive-modal';
import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';
import SellerButton from './Buttons/SellerButton';
import translateObj from '../../util/translateObj';
import Separator from './Separator';


const multipleSellersText = {
  en: 'Choose your seller',
  es: 'Escoge tu punto de venta',
  pt: 'Escolher o ponto de venda',
};

const Sellers = ({ sellers }) => (sellers.length ? (
  sellers.map(seller => seller && <SellerButton active={seller.active} margin="max-w-xssm my-1 text-center opacity-100" key={seller.sellerTicketUrl} text={{ en: seller.sellerName }} url={seller.sellerTicketUrl} category="black" />)
) : (
  <SellerButton margin="my-1 sm:w-auto text-center" soldOut text={{ en: 'Coming soon', es: 'Próximamente', ca: 'Pròximament' }} url="/" />
));

// const Sellers = ({ sellers }) => (sellers.length ? (
//   sellers.map(
//     seller => seller && (
//       <SellerButton
//         margin="my-1 sm:w-auto text-center"
//         key={seller.sellerTicketUrl}
//         text={{ en: seller.sellerName }}
//         url={seller.sellerTicketUrl}
//         category="barcelona"
//       />
//     ),
//   )
// ) : (
//     <SellerButton
//       margin="my-1 sm:w-auto text-center"
//       soldOut
//       text={{ en: 'Coming soon', es: 'Próximamente' }}
//       url="/"
//     />
//   ));

const getLocale = (currency) => {
  switch (currency.toUpperCase()) {
    case 'EUR': return 'de-DE';
    case 'USD': return 'en-US';
    case 'GBP': return 'en-GB';
    case 'ARS': return 'es-AR';
    case 'CLP': return 'es-CL';
    case 'UYU': return 'es-UY';
    case 'COP': return 'es-CO';
    case 'PEN': return 'es-PE';
    case 'MXN': return 'es-MX';
    case 'PYG': return 'es-PY';
    case 'BRL': return 'pt-BR';
    case 'CAD': return 'en-CA';
    case 'JPY': return 'ja';
    case 'LTC': return 'en-US';
    case 'BTC': return 'en-US';
    default: return 'en-US';
  }
};


export const AmountWithCurrency = ({ number = 0, curr = 'EUR' }) => {
  const amount = new Intl.NumberFormat(getLocale(curr), {
    style: 'currency',
    currency: curr ? curr.toUpperCase() : 'EUR',
    minimumFractionDigits: 0,
  })
    .format(number)
    .replace(/\s/g, '');
  return amount;
};

const TicketConcert = ({
  match,
  ticketReadableName,
  description,
  price,
  sellers,
  beginDateTime,
  venues,
  showSoldOut,
  amount,
  currency,
  priceString,
  titleClickable,
  titleNotClickable,
  showFee,
  category,
}) => {
  const [open, setOpen] = useState(false);

  const onOpenModal = () => {
    setOpen(true);
  };

  const onCloseModal = () => {
    setOpen(false);
  };

  const modalStyles = {
    modal: {
      background: 'rgba(0, 0, 0, 0)',
      boxShadow: 'none',
      width: '100vw',
      // padding: '2rem',
    },
    overlay: {
      // background: 'rgba(0, 0, 0, 0.5)',

    },
  };

  return (
    <RoutesContext.Consumer>
      {({ language: contextLanguage, enteredFrom }) => {
        const localization = getLocalization(contextLanguage, enteredFrom, match);
        const priceText = {
          en: 'Price',
          es: 'Precio',
          ca: 'Preu',
          pt: 'Preco',
        };
        const paragraphs = translateObj(description, localization.language)
          .split('\n')
          .map(paragraph => <span className="leading-normal">{paragraph}</span>);
        const { venueReadableName, location } = venues.length
          ? venues[0]
          : { venueReadableName: { en: '' }, location: '' };
        const soldOutText = { en: 'Sold out', es: 'Agotado', ca: 'Esgotat' };
        moment.locale(localization.language);
        return (
          <div className="px-5 md:px-2 sm:px-2 lg:px-7 bg-white rounded w-ticket-lg sm:w-ticket-sm md:w-ticket-md lg:w-ticket-lg xl:w-ticket-xl border-box">
            {sellers.length && !showSoldOut ? (
              <a
                href={sellers[0].sellerTicketUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="text-black cursor-pointer"
              >
                <div className="flex pt-6">
                  <div className="w-1/5 pt-3 sm:pt-1 md:pt-1 sm:w-1/6 sm:flex sm:justify-center md:flex md:justify-center align-end">
                    <span className="font-icon icon-ticket text-5xl md:text-4xl sm:text-4xl -ml-2 md:-ml-2 sm:-ml-1" />
                  </div>
                  <div className="w-4/5 sm:w-5/6 sm:pt-1 md:w-5/6 md:pt-1 pt-3 flex flex-col justify-between">
                    <div className="font-americaMonoRegular text-12">
                      <div
                        className="leading-normal uppercase md:flex lg:flex xl:flex md:flex-col lg:flex-col xl:flex-col"
                        style={{ minHeight: '54px' }}
                      >
                        {beginDateTime
                          && moment(parseInt(beginDateTime))
                            .subtract(7, 'h')
                            .format('dddd D MMMM')}
                        <br />
                        {venues.length
                          && translateObj(venueReadableName, localization.language)
                          ? translateObj(venueReadableName, localization.language)
                          : null}
                        <div className="hidden sm:inline"> | </div>
                        <strong>
                          {venues.length && location ? location : null}
                        </strong>
                      </div>
                      <Separator borderWidth={1} />
                      <div className="font-americaBlack text-18 min-h-150 sm:min-h-190 md:min-h-190">
                        <div className="mt-4 h-10 md:mb-2">
                          {translateObj(
                            ticketReadableName,
                            localization.language,
                          )}
                        </div>
                        {paragraphs.map((paragraph, i) => (
                          <div
                            key={i}
                            className="font-americaMonoRegular text-12 mb-2 uppercase"
                          >
                            {paragraph}
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="pb-6">
                      <p className="font-americaMonoRegular sm:text-xs md:text-xs text-sm mt-2 mb-5 border-t border-black pt-5 uppercase">
                        {translateObj(priceString, localization.language)}
                      </p>
                      <p className="text-2xl font-americaBlack">
                        <AmountWithCurrency number={amount} curr={currency} />
                        {showFee && <span className="text-xs">+ Fees</span>}
                      </p>
                    </div>
                  </div>
                </div>
              </a>
            ) : (
              <div className="flex pt-6">
                <div className="w-1/5 pt-3 sm:pt-1 md:pt-1 sm:w-1/6 sm:flex sm:justify-center md:flex md:justify-center align-end">
                  <span className="font-icon icon-ticket text-5xl md:text-4xl sm:text-4xl -ml-2 md:-ml-2 sm:-ml-1" />
                </div>
                <div className="w-4/5 pt-3 sm:pt-1 md:pt-1 sm:w-5/6 md:w-5/6 flex flex-col justify-between">
                  <div className="font-americaMonoRegular text-12">
                    <div
                      className="leading-normal uppercase md:flex lg:flex xl:flex md:flex-col lg:flex-col xl:flex-col"
                      style={{ minHeight: '54px' }}
                    >
                      {beginDateTime
                          && moment(parseInt(beginDateTime))
                            .subtract(7, 'h')
                            .format('dddd D MMMM')}
                      <br />
                      {venues.length
                          && translateObj(venueReadableName, localization.language)
                        ? translateObj(venueReadableName, localization.language)
                        : null}
                      <div className="hidden sm:inline"> | </div>
                      <strong>
                        {venues.length && location ? location : null}
                      </strong>
                    </div>
                    <Separator borderWidth={1} />
                    <div className="font-americaBlack text-18 min-h-150 sm:min-h-190 md:min-h-190">
                      <div className="mt-4 h-10 md:mb-2">
                        {translateObj(ticketReadableName, localization.language)}
                      </div>
                      {paragraphs.map((paragraph, i) => (
                        <div
                          key={i}
                          className="font-americaMonoRegular text-12 mb-2 uppercase"
                        >
                          {paragraph}
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="pb-6">
                    <p className="font-americaMonoRegular sm:text-xs md:text-xs text-sm mt-2 mb-5 border-t border-black pt-5 uppercase">
                      {translateObj(priceString, localization.language)}
                    </p>
                    <p className="text-2xl font-americaBlack">
                      <AmountWithCurrency number={amount} curr={currency} />
                      {showFee && <span className="text-xs">+ Fees</span>}
                    </p>
                  </div>
                </div>
              </div>
            )}

            <div className="border-t border-white-dark py-2 sm:text-center sm:px-0 flex flex-col justify-center">
              {showSoldOut
                || sellers.length === 0
                || (sellers.length && !sellers[0].sellerTicketUrl) ? (
                  <SellerButton
                    margin="my-1 sm:w-auto bg-gray200 border-none cursor-not-allowed text-center"
                    soldOut
                    text={titleNotClickable}
                    url="/"
                  />
                ) : (

                  sellers.length > 1 ? (
                    <div>
                      <button className={`text-white flex flex-col justify-center items-center w-full flex rounded-full uppercase text-base sm:text-12 md:text-12 text-center font-americaMonoBold flex-col justify-center block my-1 text-center xl:h-12 lg:h-12 h-38px bg-${category || 'tours'}`} onClick={onOpenModal}>{translateObj(titleClickable, localization.language)}</button>
                      <Modal open={open} onClose={onCloseModal} center styles={modalStyles} closeOnOverlayClick showCloseIcon={false}>
                        <div className="flex justify-center flex-col items-center">
                          <div className="mb-4 ">
                            <span className="text-center rounded-full uppercase text-base sm:text-12 md:text-12 text-center text-white font-americaMonoBold">{translateObj(multipleSellersText, localization.language)}</span>
                          </div>
                          <Sellers sellers={sellers} />
                        </div>

                      </Modal>
                    </div>
                  ) : (
                    <SellerButton
                      active={sellers[0].active}
                      margin="my-1 sm:w-auto text-center"
                      key={sellers[0].sellerTicketUrl}
                      text={titleClickable}
                      url={sellers[0].sellerTicketUrl}
                      category={category || 'tours'}
                    />
                  )
                )}
            </div>
          </div>
        );
      }}
    </RoutesContext.Consumer>
  );
};

TicketConcert.propTypes = {
  image: PropTypes.shape({}),
  category: PropTypes.string,
};

TicketConcert.defaultProps = {
  image: null,
  category: 'black',
};

export default withRouter(TicketConcert);
