import React from 'react';
import { AllContextsConsumer } from './allContexts';

const withAllContexts = Component => props => (
  <AllContextsConsumer>
    {value => <Component {...props} {...value} />}
  </AllContextsConsumer>
);

export default withAllContexts;
