import React from 'react';
import { PropTypes } from 'prop-types';

import { withRouter } from 'react-router-dom';
import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';
import RadioPlayButton from './RadioPlayButton';

class RadioHeader1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.open || false,
    };
  }

  toggle() {
    const { open } = this.state;
    const { toggleCallback } = this.props;
    this.setState({ open: !open }, () => {
      if (toggleCallback) {
        toggleCallback(open);
      }
    });
  }

  render() {
    const {
      match,
      title,
      text1,
      icon1Url,
      icon2Url,
      category,
      radioPlayButton,
    } = this.props;

    const { open } = this.state;

    return (
      <RoutesContext.Consumer>
        {
        ({ language: contextLanguage, enteredFrom }) => {
          const localization = getLocalization(contextLanguage, enteredFrom, match);

          return (
            <div className={`w-full flex align-center justify-center p-5 ${category ? `bg-${category}` : 'bg-black'}`}>
              <RadioPlayButton {...radioPlayButton} />
              <div className="flex-1 px-5 flex flex-col text-white text-12 justify-center">
                <h5 className="font-americaBlack uppercase">{title[localization.language]}</h5>
                {text1 ? <span className="mt-2 font-tiemposRegular ">{text1[localization.language]}</span> : null}
              </div>
              <div className="sm:hidden">
                <img className="w-12" src={icon1Url} alt={title[localization.language]} />
              </div>
              <div className="hidden sm:flex items-center" onClick={this.toggle.bind(this)}>
                <img className={`w-4 ${open ? 'rotate-180' : ''}`} src={icon2Url} alt={title[localization.language]} />
              </div>
            </div>
          );
        }
      }
      </RoutesContext.Consumer>
    );
  }
}

RadioHeader1.propTypes = {
  match: PropTypes.shape().isRequired,
  text1: PropTypes.shape().isRequired,
  title: PropTypes.shape().isRequired,
  radioPlayButton: PropTypes.shape().isRequired,
  icon1Url: PropTypes.string.isRequired,
  icon2Url: PropTypes.string.isRequired,
  // eslint-disable-next-line react/require-default-props
  open: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  toggleCallback: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  category: PropTypes.string,
};

export default withRouter(RadioHeader1);
