import React, { Component } from 'react';

const _loaded = {};

class ImageLoader extends Component {
  // initial state: image loaded stage
  state = {
    loaded: _loaded[this.props.src],
  };

  // define our loading and loaded image classes
  static defaultProps = {
    className: '',
    loadingClassName: 'img-loading',
    loadedClassName: 'img-loaded',
  };

  // image onLoad handler to update state to loaded
  onLoad = () => {
    const { src } = this.props;
    _loaded[src] = true;
    this.setState(() => ({ loaded: true }));
  };


  render() {
    let {
      className, loadedClassName, loadingClassName, src, alt,
    } = this.props;
    const { loaded } = this.state;
    className = `${className} ${loaded
      ? loadedClassName
      : loadingClassName}`;

    return (
      <img
        alt={alt}
        src={src}
        className={className}
        onLoad={this.onLoad}
      />
    );
  }
}

export default ImageLoader;
