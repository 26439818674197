// @ts-check

import React from 'react';
import PropTypes from 'prop-types';

import ComponentIterator from '../../../containers/ComponentIterator';

const FooterRedesignBottom = ({ components }) => (
  <div className="sm:p-4 tablet:px-12 llg:px-10 tablet:py-15 flex flex-row items-stretch lg:items-start sm:justify-center tablet:justify-between lg:justify-start" style={{ background: '#525252' }}>
    {components.map((component, index) => (
      <>
        <ComponentIterator components={[component]} />
        {index + 1 < components.length && <div className="w-px mx-6 bg-white opacity-25 flex-no-shrink hidden tablet:block" />}
      </>
    ))}
  </div>
);

FooterRedesignBottom.propTypes = {
  components: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default FooterRedesignBottom;
