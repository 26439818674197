import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import refetchUserToken from '../../api/graphql/mutations/refetchUserToken';
import { UserProfileContext } from '../../context/userProfileContext';

class HandleUserToken extends Component {
  static contextType = UserProfileContext;

  componentDidMount() {
    const getQueryVariable = (query) => {
      const vars = query.split('&');
      const queryvars = [];
      for (let i = 0; i < vars.length; i++) {
        const index = vars[i].indexOf('=');
        const item = vars[i].substring(index + 1);
        queryvars.push(item);
      }
      if (queryvars.length) {
        return queryvars;
      }
      return false;
    };
    const { location: { search } } = this.props;
    const queryValues = getQueryVariable(search);
    const [emailFromUrl] = queryValues;

    if (emailFromUrl) {
      refetchUserToken({ email: emailFromUrl }).then((res) => {
        if (res) {
          const { loadTokenForLS } = this.context;
          if (res.isValid && res.token) {
            localStorage.setItem(process.env.AUTH_TOKEN, res.token);
            document.cookie = `${process.env.AUTH_TOKEN}=${res.token}; Secure; HttpOnly`;
            loadTokenForLS();
            return res;
          }
        }
      }).catch((err) => {
        console.log('Some error happened on fetch of fetchPostDescription:', err);
      });
    }

    // loadTokenForLS(null, true);
  }


  render() {
    return (
      <div />
    );
  }
}

export default withRouter(HandleUserToken);
