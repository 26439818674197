import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { ChatProvider } from '../../Chat/context/chatContext';
import { ThemeProvider } from '../../Chat/context/themeContext';
import { FirebaseAuthProvider } from '../../Chat/context/FirebaseAuthContext';
import { THEME, COLOR } from '../../Chat/utils/constants';

import ChatStarter from './ChatStarter';

const Chat = ({ translation, match }) => {
  const { params: { section, post } } = match;

  return (
    <FirebaseAuthProvider postSlugName={post || section}>
      <ThemeProvider {...{ theme: THEME, color: COLOR }}>
        <ChatProvider>
          <ChatStarter translation={translation} />
        </ChatProvider>
      </ThemeProvider>
    </FirebaseAuthProvider>
  );
};

export default withRouter(Chat);

Chat.propTypes = {
  translation: PropTypes.shape(),
  match: PropTypes.shape().isRequired,
};

Chat.defaultProps = {
  translation: null,
};
