import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import MediaTitle from './MediaTitle';

export default class MediaContainer extends PureComponent {
  render() {
    const {
      primaryText, secondaryText, children, slug, category, column, color, border, arrow,
    } = this.props;
    return (
      <div className={`${border && 'border-black border-t-2'} w-full ${!column && 'sm:mt-6 md:mt-6 mt-8'}`}>
        {(primaryText || secondaryText) && <MediaTitle primaryText={primaryText} secondaryText={secondaryText} internalLink={slug} category={category} arrow={arrow} />}
        <div className={`${column && 'flex-col'} bg-${color || 'transparent'} flex sm:flex-wrap md:flex-wrap ${!column && '-mx-2'}`}>{children}</div>
      </div>
    );
  }
}

MediaContainer.propTypes = {
  primaryText: PropTypes.shape().isRequired,
  category: PropTypes.string,
  column: PropTypes.bool,
  border: PropTypes.bool,
  color: PropTypes.string,
  slug: PropTypes.string.isRequired,
  secondaryText: PropTypes.shape().isRequired,
  children: PropTypes.shape().isRequired,
  arrow: PropTypes.bool,
};

MediaContainer.defaultProps = {
  category: 'barcelona',
  column: false,
  border: false,
  color: '',
  arrow: false,
};
