export const ORIGIN = 'user-profile';
export const SCOPES = ['scope_general'];
export const updateButtonText = {
  en: 'UPDATE',
  es: 'ACTUALIZAR',
  ca: 'ACTUALITZAR',
  pt: 'ACTUALIZAR',
};
export const deleteButtonText = {
  en: 'Delete Account',
  es: 'Eliminar Cuenta',
  ca: 'Eliminar Compte',
  pt: 'Eliminar Conta',
};
export const deleteAlertButtonText = {
  en: 'If you delete your account, all your profile data will be lost: favourites, rewards, and achievements on My Primavera.',
  es: 'Si eliminas tu usuario se perderá todo tu perfil: favoritos, premios y logros de My Primavera.',
  ca: 'Si elimines el teu usuari, es perdrà tot el teu perfil: preferits, premis i assoliments a My Primavera.',
  pt: 'Se você excluir sua conta, todos os seus dados de perfil serão perdidos: favoritos, prêmios e conquistas no My Primavera.',
};
export const initialState = {
  canSubmit: false,
  didSubmit: false,
  areYouSure: false,
  dateOfBirth: null,
  isAddingToNewsLetter: false,
  errorMsg: '',
};
