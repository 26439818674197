const getTranslation = (apiObject, localObject) => {
  const keys = Object.keys(localObject);

  const translation = {};

  keys.forEach((k) => {
    if (apiObject?.[k]) {
      Object.assign(translation, { [k]: apiObject[k] });
    } else {
      Object.assign(translation, { [k]: localObject[k] });
    }
  });

  return translation;
};

export default getTranslation;
