import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import AppBar from '@material-ui/core/AppBar';
// import Toolbar from '@material-ui/core/Toolbar';
import withRadioPlayerContext from '../../../context/withRadioPlayerContext';
import withAllContexts from '../../../context/withAllContexts';
import { UserProfileContext } from '../../../context/userProfileContext';

import VerificationBanner from '../HeaderPopUpBanners/VerificationBanner';
import TermsAndConditionsBanner from '../HeaderPopUpBanners/TermsAndConditionsBanner';

class Header extends Component {
  static contextType = UserProfileContext;

  headerRef = React.createRef();

  componentExists = React.createRef(false);

  state = {
    scrolledFromTop: 0,
    showHeaderOnScroll: false,
    headerRelative: true,
    // userActivationRequestSent: false,
    isUserVerified: null,
    userEmail: '',
    headerHeight: '105px',
  }

  // setBannerState = () => {
  //   let userVerifiedStatus = null;
  //   const { isUserVerified, userEmail } = this.context;
  //   userVerifiedStatus = isUserVerified;
  //   console.log('TCL: render -> userEmail', userEmail);
  //   console.log('TCL: Header -> render -> isUserVerified', isUserVerified);
  //   console.log('TCL: render -> userVerifiedStatus', userVerifiedStatus);
  //   console.log('userEmail === false', !userEmail);
  //   console.log('isUserVerifiedStatus', !isUserVerified, isUserVerified === false);
  //   const heightAdj = (userEmail && userVerifiedStatus === false) ? '150px' : '104px';
  //   console.log('TCL: render -> heightAdj', heightAdj);
  //   this.setState({
  //     userVerifiedStatus, userEmail, headerHeight: heightAdj,
  //   });
  // }

  componentDidMount() {
    this.componentExists.current = true;
    const { removeRadio } = this.props;
    if (removeRadio) {
      const { hideRadio, hidePlayer } = this.props;
      hidePlayer();
      hideRadio(true);
    }
    window.addEventListener('scroll', this.handleScroll);
    // this.setBannerState();
  }

  // componentDidUpdate(prevProps, prevState) {
  //   const { isUserVerified, userEmail } = this.context;
  //   console.log('cdup header out', isUserVerified, this.state.isUserVerified);
  //   if (this.state.isUserVerified !== null) {
  //     if (isUserVerified !== this.state.isUserVerified) {
  //       console.log('cdup header in', isUserVerified, this.state.isUserVerified);
  //       this.setBannerState();
  //     }
  //   }
  // }

  componentWillUnmount() {
    this.componentExists.current = false;
    const { removeRadio } = this.props;
    if (removeRadio) {
      const { hideRadio, showPlayer } = this.props;
      hideRadio(false);
      showPlayer();
    }
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const { scrolledFromTop } = this.state;
    const newScrolledFromTop = this.headerRef.current.getBoundingClientRect().top;
    const { playerPosition, setPlayerPosition, headerContext } = this.props;
    const body = document.querySelector('body');
    const lineUpContainer = document.getElementById('line-up-container');
    // check if we are on line up page and make header always scroll up
    if (lineUpContainer) {
      return this.setState(() => ({ showHeaderOnScroll: false, headerRelative: true }));
    }
    if (!headerContext.megaMenuIsOpen) {
      if (newScrolledFromTop > scrolledFromTop) {
        this.setState(() => ({ showHeaderOnScroll: true, headerRelative: false }));
        if (!this.isBottom(body)) {
          if (playerPosition.includes('pin-t')) { setPlayerPosition('pin-t'); } else { setPlayerPosition('pin-b'); }
        }
      } else {
        this.setState(() => ({ showHeaderOnScroll: false }));
        if (scrolledFromTop < -150) {
          this.setState(() => ({ headerRelative: false }));
          if (playerPosition === 'pin-b') {
            setPlayerPosition('pin-b -mb-16');
          } else if (playerPosition === 'pin-t') {
            setPlayerPosition('pin-t -mt-16');
          }
        } else {
          this.setState(() => ({ headerRelative: true }));
        }
      }
      if (this.componentExists.current) {
        this.setState(() => ({ scrolledFromTop: newScrolledFromTop }));
      }
    } else {
      this.setState(() => ({ showHeaderOnScroll: false, headerRelative: true }));
    }
  }

  isBottom = el => el.getBoundingClientRect().bottom <= window.innerHeight + 315

  recieveInfoFromContext = (isUserVerified, userEmail, headerHeight, isUserUpToDateWithTermsAndConditions) => {
    this.setState({
      isUserVerified, userEmail, headerHeight, isUserUpToDateWithTermsAndConditions,
    });
  }

  render() {
    const {
      children, showRadioPlayer, playerPosition, podcast, backgroundColor,
    } = this.props;
    const {
      showHeaderOnScroll, headerRelative, menuIsOpen, headerHeight, userEmail, isUserVerified, isUserUpToDateWithTermsAndConditions,
    } = this.state;

    const { isActivationBarOpen } = this.context;
    // style={{ height: '104px' }}

    return (
      <>
        <div className={`flex w-full bg-${backgroundColor} relative`} style={{ height: headerHeight }} ref={this.headerRef}>
          <div
            className={`flex w-full z-header bg-${backgroundColor} ${showHeaderOnScroll ? 'fixedHeaderShow z-header' : ''} ${headerRelative ? 'relative' : `${isActivationBarOpen ? 'fixedHeaderActivationBar' : 'fixedHeader'}`} ${(playerPosition === 'pin-t' && showRadioPlayer) && 'mt-14'} ${(userEmail && (isUserVerified === false || isUserUpToDateWithTermsAndConditions === false)) && 'flex-col'}`}
            style={{ height: headerHeight }}
          >
            <VerificationBanner context={this.context} sendUserInfoBack={this.recieveInfoFromContext} />
            <TermsAndConditionsBanner context={this.context} sendUserInfoBack={this.recieveInfoFromContext} />
            {children}
          </div>
        </div>
      </>
    );
  }
}

Header.propTypes = {
  children: PropTypes.shape().isRequired,
  showRadioPlayer: PropTypes.bool.isRequired,
  playerPosition: PropTypes.string.isRequired,
  setPlayerPosition: PropTypes.func.isRequired,
  headerContext: PropTypes.shape({}).isRequired,
  removeRadio: PropTypes.bool,
  hideRadio: PropTypes.func.isRequired,
  hidePlayer: PropTypes.func.isRequired,
  showPlayer: PropTypes.func.isRequired,
  backgroundColor: PropTypes.string,
};

Header.defaultProps = {
  removeRadio: false,
  backgroundColor: 'white',
};

export default withAllContexts(withRadioPlayerContext(Header));
