import React from 'react';
import PropTypes from 'prop-types';
import { useRoutes } from '../../../../context/routesContext';

import translateObj from '../../../../util/translateObj';
import LoadIcon from '../../LoadIcon';

const DrawerButton = ({ callback, label, loading, icon, disabled }) => {
  const { language } = useRoutes();

  return (
    <button
      type="button"
      onClick={callback}
      className={`p-3 flex items-center text-white bg-grey-darkest rounded ${disabled ? 'opacity-25' : ''}`}
      style={{ gap: '0.75rem', minWidth: 120 }}
      disabled={disabled}
    >
      {loading ? (
        <div className="flex items-center justify-center w-full">
          <LoadIcon width={12} height={12} />
        </div>
      ) : (
        <>
          {icon}
          <span className="uppercase text-xs">
            {translateObj(label, language)}
          </span>
        </>
      )}
    </button>
  );
};

DrawerButton.propTypes = {
  label: PropTypes.shape().isRequired,
  callback: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  icon: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
};

DrawerButton.defaultProps = {
  loading: false,
  disabled: false,
};

export default DrawerButton;
