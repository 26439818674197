import React from 'react';
// import ScrollableAnchor from 'react-scrollable-anchor';
// import { configureAnchors } from 'react-scrollable-anchor';

// configureAnchors({ scrollDuration: 800 });

// NOTE this component is breaking the app afer updating to webpack 5, check if its being used or not

const linkWrapper = Component => props => (
  <a href={props.link}>
    <Component {...props} />
  </a>
);


const DoubleTitle = ({ primaryText, secondaryText, anchorLinkLabel }) => {
  const pinkText = () => (
    <span>
      {'   |   ' }
      <span className="text-primavera-pink underline cursor-pointer leading-normal">{secondaryText.en}</span>
    </span>
  );

  const SecondaryLabel = secondaryText
    ? linkWrapper(pinkText) : null;

  return (
    // <ScrollableAnchor id={anchorLinkLabel}>

      <div className="p-8 text-base sm:text-sm md:text-sm font-americaMonoBold leading-normal font-black w-full align-left " id={anchorLinkLabel}>
        {primaryText.en.toUpperCase()}
        {!!SecondaryLabel && <SecondaryLabel />}
      </div>
    // </ScrollableAnchor>

  );
};


export default DoubleTitle;
