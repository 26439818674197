// chat
export const THEME = 'psworld';
export const COLOR = 'white';
export const LOGIN_URL = 'login-screen?e=sign-in';
export const PRIVATE_CHANNELHANDLER_ID = 'V7RGW3FP2D';

// helpers
export const MESSAGE_LIST_LIMIT = 100;
export const MESSAGE_TYPE = 'admin';

export const OPEN = 'open';
export const PRIVATE = 'private';
export const PARTICIPANTS = 'participants';
export const WAITINGLIST = 'waitingList';
export const PRIVATE_CHANNELS = 'privateChannels';
export const PENDING_PRIVATE_CHANNELS = 'pendingPrivateChannels';
export const CHANNELS = 'channels';
export const MESSAGES = 'messages';
export const VIDEO_CALLS = 'videoCalls';
export const USERS = 'users';
export const MUTED_USERS = 'mutedUsers';

// const CUSTOM_TYPE = '';

// toggler
export const CHAT = 'chat';
export const QUESTIONS = 'questions';

// view
export const DEFAULT_COLOR = '#f25b29';

export const getStringSet = (lang) => {
  const stringSet = {
    en: {
      PRIVATE_TAB: 'Private',
      MAIN_TAB: 'Main',
      CHAT_BUTTON: 'Subscribers Chat',
      QUESTIONS_BUTTON: 'Questions Chat',
      LOGIN_BUTTON: 'Log in to be part of the live conversation.',
      PRIVATE_BUTTON: 'Private Chat',
      USER_DECLINED: 'declined your invitation',
      YOU_LEFT: 'You left the chat',
      USER_LEFT: 'left the chat',
      CHANNEL_DELETED: 'Channel deleted',
      USER_ACCEPTED: 'joined channel',
      INVITATION_SENT: 'Your invitation was sent to',
      NO_PRIVATE_CHANNELS: 'You have no private channels yet',
      INPUT_PLACEHOLDER: 'Type your message',
      CONNECTED_USERS: 'Online Users',
      MUTED_MESSAGE: "⚠️ You've been temporary muted",
      UNMUTED_MESSAGE: '🌈 You are unmuted 🌈',
      NO_PERMISION: 'You are not subscribed',
      INVITE: 'Invite',
      QA_ADMIN_MESSAGE: 'Thank you, your question will we reviewed by admin before published.',
    },
    es: {
      PRIVATE_TAB: 'Privado',
      MAIN_TAB: 'Principal',
      CHAT_BUTTON: 'Chat de suscriptores',
      QUESTIONS_BUTTON: 'Chat de preguntas',
      LOGIN_BUTTON: 'Inicia sesión para ser parte de la conversación',
      PRIVATE_BUTTON: 'Chat privado',
      USER_DECLINED: 'ha rechazado tu invitación',
      YOU_LEFT: 'Tu dejaste la conversación',
      USER_LEFT: 'dejó la conversación',
      CHANNEL_DELETED: 'Canal eliminado',
      USER_ACCEPTED: 'ha entrado',
      INVITATION_SENT: 'Su invitación ha sido enviada a',
      NO_PRIVATE_CHANNELS: 'Aún no tienes canales privados',
      INPUT_PLACEHOLDER: 'Escribe tu mensaje',
      CONNECTED_USERS: 'Usuarios conectados',
      MUTED_MESSAGE: '⚠️ Has sido temporalmente silenciad@',
      UNMUTED_MESSAGE: '🌈 Ya no estás silenciad@ 🌈',
      INVITE: 'Invitar',
      QA_ADMIN_MESSAGE: 'Gracias, tu pregunta será revisada por el administrador antes de ser publicada.',
    },
    ca: {
      PRIVATE_TAB: 'Privat',
      MAIN_TAB: 'Principal',
      CHAT_BUTTON: 'Xat de subscriptors',
      QUESTIONS_BUTTON: 'Xat de preguntes',
      LOGIN_BUTTON: 'Inicia sessió per a formar part de la conversa',
      PRIVATE_BUTTON: 'Xat privat',
      USER_DECLINED: 'Ha rebutjat la teva invitació',
      YOU_LEFT: 'Tu dejaste la conversación',
      USER_LEFT: 'Ha deixat la conversa',
      CHANNEL_DELETED: 'Canal eliminat',
      USER_ACCEPTED: 'ha entrado',
      INVITATION_SENT: 'La teva invitació ha estat enviada a',
      NO_PRIVATE_CHANNELS: 'Encara no tens canals privats',
      INPUT_PLACEHOLDER: 'Escriu el teu missatge',
      CONNECTED_USERS: 'Usuarios conectados',
      MUTED_MESSAGE: '⚠️ Has sigut silenciat/ada temporalment',
      UNMUTED_MESSAGE: '🌈 Ja no estàs silenciat/ada 🌈',
      INVITE: 'Convidar',
      QA_ADMIN_MESSAGE: "Gràcies, l'administrador revisarà la teva pregunta abans de publicar-la.",
    },
  };
  return stringSet && stringSet[lang] ? stringSet[lang] : stringSet.en;
};
