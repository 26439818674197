import React, { useEffect, useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import translateObj from '../../../util/translateObj';

import LoadIcon from '../LoadIcon';
// import LineupTableItem from '../LineupTableItem';
import PostDescriptionRow from '../PostDescriptionRow';

import userGetFavorites from '../../../api/graphql/mutations/userGetFavorites';
import userSetFavorites from '../../../api/graphql/mutations/userSetFavorites';
import getArtistSetsByArtistSetSlugs from '../../../api/graphql/queries/getArtistSetsByArtistSetSlugs';
import { UserProfileContext } from '../../../context/userProfileContext';

const UserFavorites = ({
  history, match, userFavoritesText, eventToLineupUrlTranslation,
}) => {
  const userContext = useContext(UserProfileContext);

  // const [userFavorites, setUserFavorites] = useState([]);
  const [lineupSlugNamesArray, setLineupSlugNamesArray] = useState([]);
  const [favoritesExist, setFavoritesExist] = useState(null);

  const language = (match && match.params && match.params.lang.toLowerCase()) || 'en';

  const identifyArtistSetLocations = (resArtistSets) => {
    const artistSetLocationsFnArr = [];
    resArtistSets.forEach((artistSet) => {
      const location = artistSet && artistSet.event && artistSet.event.eventName;
      if (location) artistSetLocationsFnArr.push(location);
    });
    return artistSetLocationsFnArr.length ? [...new Set(artistSetLocationsFnArr)] : [];
  };

  const convertArtistSetLocationsToLineUpSlugNames = (artistSetLocationsArray) => {
    const lineUpSlugNamesArrayInFn = [];
    artistSetLocationsArray.forEach((artistSetLocation) => {
      if (eventToLineupUrlTranslation && eventToLineupUrlTranslation[artistSetLocation]) lineUpSlugNamesArrayInFn.push(eventToLineupUrlTranslation[artistSetLocation]);
    });
    return lineUpSlugNamesArrayInFn.length ? [...new Set(lineUpSlugNamesArrayInFn)] : [];
  };

  const getArtistSetsBySlugs = (favArtistSetsArray) => {
    getArtistSetsByArtistSetSlugs(favArtistSetsArray).then((resArtistSets) => {
      if (resArtistSets.length) {
        const artistSetLocationsArray = identifyArtistSetLocations(resArtistSets);
        const lineupSlugNamesFromLocationsArray = artistSetLocationsArray.length ? convertArtistSetLocationsToLineUpSlugNames(artistSetLocationsArray) : [];
        setLineupSlugNamesArray(lineupSlugNamesFromLocationsArray);
        // setUserFavorites(resArtistSets);
        setFavoritesExist(true);
      } else {
        setFavoritesExist(false);
      }
    });
  };

  useEffect(() => {
    const { token } = userContext;
    if (token) {
      userGetFavorites({ token, category: 'Concerts' }).then((res) => {
        if (res) {
          if (res.favorites.length) {
            const favArtistSetsArray = res.favorites.map(f => f.split('|')[1]);

            getArtistSetsBySlugs(favArtistSetsArray);
          } else {
            setFavoritesExist(false);
          }
        } else {
          console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Error - userGetFavorites failed to submit!`);
        }
      });
    } else {
      history.push(`/${language}/login-screen?e=sign-in`);
    }
  }, []);

  return (
    <div className="p-8 w-full">
      {favoritesExist === null && (
        <LoadIcon height={35} width={35} color="black" type="ThreeDots" />
      )}
      {favoritesExist === false && (
        <div className="uppercase font-americaMonoBold mb-8">
          <span className="uppercase font-americaMonoBold">{userFavoritesText.noFavorites[language]}</span>
          <div className="font-americaMonoRegular text-12 py-3">{translateObj(userFavoritesText.addMore, language)}</div>
        </div>
      )}
      {favoritesExist === true && (
        <div>
          <span className="uppercase font-americaMonoBold mb-8 block">{userFavoritesText.favoritesList[language]}</span>
          {
            lineupSlugNamesArray.length > 0 && (
              <PostDescriptionRow
                slugnames={lineupSlugNamesArray}
              />
            )
          }
        </div>
      )}
    </div>
  );
};

UserFavorites.propTypes = {
  history: PropTypes.shape().isRequired,
  match: PropTypes.shape().isRequired,
  userFavoritesText: PropTypes.shape().isRequired,
};

UserFavorites.defaultProps = {

};

export default withRouter(UserFavorites);

// {
//   "type" : "PostDescriptionRow",
//   "name" : "",
//   "arrow" : true,
//   "slugnames" : [
//       "insumisas",
//       "speakers",
//       "participants"
//   ],
//   "compId" : "PostDescriptionRow_4675d082-226c-11ea-9bc5-4f8f340018f5"
// },
