import React from 'react';
import PropTypes from 'prop-types';

const DayLabel = ({
  day,
}) => (
  <div className="h-10">
    <span className="block font-americaMonoBold flex">
      <span className="inline-block overflow-hidden pr-12 relative">
        <span className="h-10 relative py-2 block z-10 bg-grayE2DDDB px-4">{day}</span>
        <span className="h-10 block skew45 bg-grayE2DDDB w-12 h-full absolute pin-b pin-r" />
      </span>
    </span>
  </div>
);

DayLabel.propTypes = {
  day: PropTypes.string.isRequired,
};
export default DayLabel;
