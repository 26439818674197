import React, { Component } from 'react';
import { Marker, InfoWindow } from 'react-google-maps';
import PropTypes from 'prop-types';
import map from '../../util/map';
import Link from '../../util/link';

class MapMarkers extends Component {
  state = {
    isOpen: false,
  };

  toggleInfoWindow = (name) => {
    this.setState(prevState => ({
      isOpen: !prevState,
      markerName: name,
    }));
  };

  render() {
    const { markers } = this.props;
    const { isOpen, markerName } = this.state;
    return (
      <div className="map">
        {markers.map(marker => (
          <Marker name={marker.name} key={marker.name} position={marker.position} map={map} icon={map.icon} onClick={() => this.toggleInfoWindow(marker.name)}>
            <div className="overflow-hidden whitespace-no-wrap">
              {!isOpen && markerName === marker.name && (
                <InfoWindow onCloseClick={this.toggleInfoWindow} defaultOptions={map.options} key={marker.name}>
                  <div className="overflow-hidden whitespace-no-wrap w-64">
                    <Link to={marker.web} className="text-base font-semibold leading-normal text-barcelona hover:text-black no-underline outline-none">
                      <h3 className="text-lg leading-loose">{marker.name}</h3>
                    </Link>

                    <p className="text-base">{marker.address}</p>
                  </div>
                </InfoWindow>
              )}
            </div>
          </Marker>
        ))}
      </div>
    );
  }
}

MapMarkers.propTypes = {
  markers: PropTypes.arrayOf(
    PropTypes.shape({
      // name: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      address: PropTypes.string.isRequired,
      web: PropTypes.string.isRequired,
      position: PropTypes.shape(
        {
          lat: PropTypes.number.isRequired,
          lng: PropTypes.number.isRequired,
        }.isRequired,
      ),
    }),
  ).isRequired,
};

export default MapMarkers;
