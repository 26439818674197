import React, { Component } from 'react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  // For this example we'll just use componentDidCatch, this is only
  // here to show you what this method would look like.
  // static getDerivedStateFromProps(error){
  // return { error: true }
  // }

  componentDidCatch(error, info) {
    this.setState({ error, errorInfo: info });
  }

  render() {
    const { errorInfo, error } = this.state;
    const { componentStack } = errorInfo || {};

    if (errorInfo) {
      return (
        <div className="error-boundary">
          <summary>Ooops, something went wrong here</summary>
          <details style={{ whiteSpace: 'pre-wrap' }}>
            { error && error.toString() }
            { componentStack }
          </details>
        </div>
      );
    }
    // eslint-disable-next-line react/destructuring-assignment
    return this.props.children;
  }
}

export default ErrorBoundary;
