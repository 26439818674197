import React from 'react';
import PropTypes from 'prop-types';

class ControlledButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      active: false,
    };
  }

  handleClick(category) {
    const { onHandleControlledButtonSelect, filterType } = this.props;

    onHandleControlledButtonSelect(category, filterType);
    this.setState(state => ({ active: !state.active }));
  }

  render() {
    const { iconUrl, params: { text, category } } = this.props;
    let classNames = 'text-white uppercase text-sm font-americaMonoRegular rounded-lg mr-4 shadow-white py-2 px-5';
    const { active } = this.state;
    if (active) {
      classNames += ' bg-selected shadow-none';
    }
    return (
      <button
        onClick={() => this.handleClick(category)}
        type="button"
        className={`${classNames}  cursor-pointer`}
      >
        {
            iconUrl
          && <img className="pr-4" src={iconUrl} alt="buy ticket" />
        }
        {text.en}
      </button>
    );
  }
}

ControlledButton.propTypes = {
  iconUrl: PropTypes.string,
  params: PropTypes.shape({
    text: PropTypes.shape({
      en: PropTypes.string.isRequired,
      es: PropTypes.string.isRequired,
    }).isRequired,

  }).isRequired,
  onHandleControlledButtonSelect: PropTypes.func.isRequired,
  filterType: PropTypes.string.isRequired,
};

ControlledButton.defaultProps = {
  iconUrl: null,
};

export default ControlledButton;
