import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import SocialLogos from './SocialLogos';
import Button from './Button';
import CtaUnderline from './CtaUnderline';

import { useRoutes } from '../../../context/routesContext';
import translateObj from '../../../util/translateObj';
import { createPrivateChannel } from '../Chat/utils/helpers';
import { useUser } from '../../../context/userProfileContext';
import { OpenChatContext } from './Chat/context/OpenChatContext';
import getProMusicMeetsUserMailto from '../../../api/graphql/queries/getProMusicMeetsUserMailto';

const MemberItemDetail = ({
  id,
  name,
  lastname,
  url,
  social,
  description,
  buttonChat,
}) => {
  const { language } = useRoutes();
  const user = useUser();
  const { setChatIsOpen } = useContext(OpenChatContext);

  // NOTE: Hide Chat for 2024 edition and instead send email
  const inviteToPrivateChat = () => {
    const creator = {
      nickname: user.userId,
      nicknameColor: '#FF6600',
      userId: user.userId,
    };
    const invited = {
      nickname: id,
      nicknameColor: '#FF6600',
      userId: id,
    };
    createPrivateChannel({ creator, invited });
    setChatIsOpen(true);
  };

  const sendEmailToUser = () => {
    getProMusicMeetsUserMailto(id).then((res) => {
      if (res?.mailto) window.open(`mailto:${res.mailto}`, '_blank');
    }).catch((err) => console.log(err));
  };

  return (
    <div className="bg-black w-full">
      <div className="w-full px-1/12 sm:pl-8 sm:pr-0 flex sm:flex-col py-12 sm:pb-12 sm:pt-0">
        <div className="w-3/5">
          <div className="sm:hidden font-americaBlack leading-none text-50 text-white w-1/3 flex flex-wrap mb-6">
            <span>{`${name} ${lastname}`}</span>
          </div>
          <CtaUnderline text={url} link={/^www?/.test(url) ? `https://${url}` : url} color="orange" breakWords />
          {social && <SocialLogos social={social} textColor="text-white" className="-ml-2 sm:-ml-1 mt-2" />}
        </div>
        <div className="w-2/5 sm:w-full sm:pt-6">
          <div className="font-tiemposRegular text-white text-base sm:text-14">{description}</div>
          <Button className="pt-8 w-1/3" text={translateObj(buttonChat, language)} color="outlineWhite" onClickCallback={() => sendEmailToUser()} />
        </div>
      </div>
    </div>
  );
};

MemberItemDetail.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  lastname: PropTypes.string,
  url: PropTypes.string,
  social: PropTypes.arrayOf(PropTypes.shape()),
  description: PropTypes.string,
  buttonChat: PropTypes.shape(),
};

MemberItemDetail.defaultProps = {
  id: '',
  name: '',
  lastname: '',
  url: '',
  social: [],
  description: '',
  buttonChat: {},
};

export default MemberItemDetail;
