import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import style from 'styled-components';
import Badge from '@primaveralabs/ui-badge';
import getUserData from '../../../api/graphql/queries/getUserData';
import LoadIcon from '../LoadIcon';
import 'Primavera/ui-themes/dist/primavera/index.css';
import 'Primavera/ui-panel/dist/primavera.css';
import 'Primavera/ui-banner/dist/primavera.css';
import 'Primavera/ui-scoreboard/dist/primavera.css';
import 'Primavera/ui-badge/dist/primavera.css';
import LoyaltyPointsScore from './LoyaltyPointsScore';
import imageModifier from '../helpers/imageModifier';
import translateObj from '../../../util/translateObj';
import { useRoutes } from '../../../context/routesContext';
import getPostsBySlugNameQuery from '../../../api/graphql/queries/getPostsBySlugNameQuery';
import RewardDrawer from './RewardDrawer/RewardDrawer';
import commonTranslation from './translations/common';
import userTranslation from './translations/UserPanel';
import AchievementsBoard from './AchievementsBoard';

const UserRewardsPage = ({
  rewardsTitle,
  achievementsTitle,
  scoreTitle,
  redeemedPrize,
  noAchievements,
  achievedOn,
  pointsLabel,
  rewardsSubTitle,
  scoreSubTitle,
  achievementListLimit,
}) => {
  const { language } = useRoutes();
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({});
  const [rewards, setRewards] = useState([]);
  const [reward, setReward] = useState(undefined);

  const Grid = style.div`
    height: 100%;
    min-height: 100%;
    min-height: -webkit-fill-available;
    padding: 20px;
    display: grid;
    grid-template-columns: 100%;
    grid-auto-rows: auto;
    grid-template-areas:
            "score"
            "rewards";
    gap: 10px;
    @media (min-width: 768px) {
        grid-template-columns: 50% 50%;
        grid-template-areas:
            "rewards score"
            "rewards score"
            "rewards score"
            "rewards score";
    }
    @media (min-width: 1380px) {
        grid-template-columns: 70% 30%;
        grid-template-areas:
            "rewards score"
            "rewards score"
            "rewards score"
            "rewards score";
    }
  `;

  const loadRewardsData = (items) => {
    setLoading(true);
    const slugNames = items.map((i) => i.slugName);
    getPostsBySlugNameQuery(slugNames)
      .then((res) => {
        if (res) {
          const prizes = res.map((prize) => {
            const item = items.find((i) => i.slugName === prize.slugName);
            const code = item?.code;
            const link = item?.link;
            const claimedAt = item?.claimedAt;
            return { ...prize, code, link, claimedAt };
          });
          setRewards(prizes);
        }
      })
      .catch((err) => console.log(err));
    setLoading(false);
  };

  const loadUserData = () => {
    setLoading(true);
    getUserData()
      .then((res) => {
        if (res) {
          setUserData(res);
          if (res.loyalty?.prizes?.length > 0) {
            loadRewardsData(res.loyalty.prizes);
          }
        }
      })
      .catch((err) => console.log(err));
    setLoading(false);
  };

  useEffect(() => {
    loadUserData();
  }, []);

  return (
    <>
      <div className="bg-panel-gray h-full">
        {loading ? (
          <div className="flex items-center justify-center h-full">
            <LoadIcon />
          </div>
        ) : (
          <Grid>
            <div
              className="flex flex-col h-full sm:mt-0 -mt-120"
              style={{
                gridArea: 'score',
                gap: 16,
              }}
            >
              <LoyaltyPointsScore
                loyaltyPoints={userData?.loyalty?.loyaltyPoints}
                loading={loading}
                title={scoreTitle}
                subtitle={scoreSubTitle}
              />
              <AchievementsBoard
                loyalty={userData?.loyalty}
                title={achievementsTitle}
                achievedOn={achievedOn}
                pointsLabel={pointsLabel}
                noAchievements={noAchievements}
                limit={achievementListLimit}
              />
            </div>
            <div
              style={{ gridArea: 'rewards', gap: 8 }}
              className="px-28px sm:px-0"
            >
              <h3 className="font-americaMedium text-30 sm:text-24 text-white pt-10 pb-4">
                {translateObj(rewardsTitle, language)}
              </h3>
              {rewardsSubTitle && (
              <p className="font-americaMedium text-base sm:text-12 text-white pb-8">
                {translateObj(rewardsSubTitle, language)}
              </p>
              )}

              <div className="flex flex-wrap" style={{ gap: 16 }}>
                {rewards?.map((item) => (
                  <Badge
                    key={item.slugName}
                    image={imageModifier(
                      translateObj(item.postDescription?.image, language),
                    )}
                    title={translateObj(item.postDescription?.title, language)}
                    description={translateObj(
                      item.postDescription?.description,
                      language,
                    )}
                    // sponsor={{
                    //   name: translateObj(
                    //     item.postDescription?.subtitle,
                    //     language
                    //   ),
                    //   logo:
                    //     'https://play-lh.googleusercontent.com/3CyC0NofR30j3O_Ody1avJWr6s98wsRbiW63Zsb2MCE4CaWuODgPz-sMg31_gWOAHVM', // TODO: provisional image
                    // }}
                    intent="reward"
                    maskImage="https://assets.primaverasound.com/psweb/dgxbu8t6ztuzw1p4pfx1_1681669022673.png" // TODO: local image?
                    onClick={() => setReward(item)}
                    redeemed={item.claimedAt}
                    tagLabel={translateObj(redeemedPrize)}
                  />
                ))}
              </div>
            </div>
          </Grid>
        )}
      </div>
      {reward && (
        <RewardDrawer
          reward={reward}
          setReward={setReward}
          callback={loadUserData}
          redeemedPrize={redeemedPrize}
        />
      )}
    </>
  );
};

UserRewardsPage.propTypes = {
  achievementsTitle: PropTypes.shape({}),
  rewardsTitle: PropTypes.shape({}),
  scoreTitle: PropTypes.shape({}),
  redeemedPrize: PropTypes.shape({}),
  noAchievements: PropTypes.shape({}),
  achievedOn: PropTypes.shape({}),
  pointsLabel: PropTypes.shape({}),
  scoreSubTitle: PropTypes.shape({}),
  rewardsSubTitle: PropTypes.shape({}),
  achievementListLimit: PropTypes.number,
};

UserRewardsPage.defaultProps = {
  achievementsTitle: userTranslation.myAchievements,
  rewardsTitle: commonTranslation.myCollection,
  scoreTitle: userTranslation.scoreTitle,
  redeemedPrize: commonTranslation.redeemedPrize,
  noAchievements: commonTranslation.noAchievements,
  achievedOn: commonTranslation.achieved,
  pointsLabel: commonTranslation.points,
  scoreSubTitle: { en: '' },
  rewardsSubTitle: { en: '' },
  achievementListLimit: 10,
};

export default UserRewardsPage;
