import React from 'react';

let update;
let clear;
let clearAll;

const radioShows = {
  radioShowsData: null,
  radioShowsHighlightsData: null,
  singleRadioShowData: null,
  singleRadioShowRelatedData: null,
  singleRadioShowEpisodesData: null,
  singleRadioEpisodeData: null,
  singleRadioShowTitleData: null,
  featuredRadioShowsData: null,
  update: (key, data) => update(key, data),
  clear: key => clear(key),
  clearAll: () => clearAll(),
};

clearAll = () => {
  Object.entries(radioShows).forEach((item) => {
    const [key, value] = item;

    if (typeof value !== 'function') {
      radioShows[key] = null;
    }
  });
};

update = (key, data) => {
  radioShows[key] = data;
};

clear = (key) => {
  radioShows[key] = null;
};

const RadioShowsContext = React.createContext(radioShows);

export default RadioShowsContext;
