import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import Loader from 'react-loader-spinner';

const colorTranslate = {
  barcelona: '#67A0CE',
  porto: '#7DD6AD',
  oporto: '#7DD6AD',
  concerts: '#FFAF52',
  tours: '#FFAF52',
  radio: '#FF7BA0',
  la: '#FFE65B',
  los_angeles: '#FFE65B',
  'los-angeles': '#FFE65B',
  black: '#717171',
  white: '#fff',
  pro: '#FF6600',
};

const LoadIcon = ({
  color, height, width, type, classes,
}) => {
  const [colorState, setColorState] = useState('#717171');

  useEffect(() => {
    if (color) { setColorState(colorTranslate[color]); }
  }, [color]);

  return (
    <div className={`${classes || 'flex justify-center w-full'} `}>
      <Loader type={type} color={colorState} height={height} width={width} />
    </div>
  );
};

LoadIcon.propTypes = {
  color: PropTypes.string,
  type: PropTypes.string,
  classes: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};
LoadIcon.defaultProps = {
  color: 'black',
  type: 'Oval',
  height: 30,
  width: 30,
  classes: '',
};

export default LoadIcon;
