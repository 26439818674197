import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { useChat } from '../../Chat/context/chatContext';
import usePrivateChannels from '../../Chat/hooks/usePrivateChannels';

import { useRoutes } from '../../../../context/routesContext';

import translateObj from '../../../../util/translateObj';
import getTranslation from '../util/translations/_getTranslation';
import localTranslation from '../util/translations/Chat';

import ChatButton from './ChatButton';
import ChatsList from './ChatsList';
import { OpenChatContext } from './context/OpenChatContext';

const ChatStarter = ({ translation }) => {
  const { chatIsOpen, setChatIsOpen } = useContext(OpenChatContext);

  const { language } = useRoutes();
  const literals = getTranslation(translation, localTranslation);

  const { notLogged } = useChat();
  const { pendingChannels } = usePrivateChannels();

  if (notLogged) {
    return null;
  }

  return (
    <div
      className="flex flex-col items-end justify-end fixed bg-black rounded-xl mx-4 sm:mx-2 pin-r z-top"
      style={{ bottom: 60 }}
    >
      {chatIsOpen && (
        <ChatsList
          literals={literals}
        />
      )}
      <div className="relative">
        <ChatButton
          label={translateObj(literals.buttonChat, language)}
          onClick={() => setChatIsOpen(!chatIsOpen)}
          active={chatIsOpen}
        />
        {pendingChannels?.length > 0
        && !chatIsOpen
        && (
        <div className="font-americaMonoBold text-sm -mt-1 -mr-1 absolute pin-t pin-r bg-pro-online rounded-full h-6 w-6 flex items-center justify-center text-white">
          {pendingChannels?.length}
        </div>
        )}
      </div>
    </div>
  );
};

export default ChatStarter;

ChatStarter.propTypes = {
  translation: PropTypes.shape(),
};

ChatStarter.defaultProps = {
  translation: null,
};
