import React from 'react';
import { PropTypes } from 'prop-types';

const CollapsibleContainer = ({ children }) => (
  <div className="p-7 bg-black">
    { children }
  </div>
);

CollapsibleContainer.propTypes = {
  children: PropTypes.shape().isRequired,
};

export default CollapsibleContainer;
