import gql from 'graphql-tag';

import { clientWeb, persistor } from '../graphqlAPI';

const GET_USER_DATA = gql`
  query Get($getFavourites: Boolean, $getLoyalty: Boolean, $getSpotifyTopArtists: Boolean, $fetchDate: String, $suggestionEventSlugname: String) {
      getUserData(getFavourites: $getFavourites, getLoyalty: $getLoyalty, fetchDate: $fetchDate, getSpotifyTopArtists: $getSpotifyTopArtists, suggestionEventSlugname: $suggestionEventSlugname){
        favourites {
            favouriteArtists
            favouriteArtistSets
        }
        loyalty {
          prizes {
              slugName
              code
              link
              claimedAt
          }
          achievements {
              slugName
              createdAt
          }
          loyaltyPoints
        }
        preferences {
            musicalInterests
            userFestivals
        }
        topArtists {
          slug
          name
          images {
              url
          }
        }
        suggestion {
          suggestionEventSlugname
          suggestion {
            slug
            name
            images {
                url
            }
          }
          createdAt 
        }
    }
  }
`;

const executeQuery = async ({ getFavourites, getLoyalty, getSpotifyTopArtists, suggestionEventSlugname }, type = 'network') => {
  const result = await clientWeb.query({
    query: GET_USER_DATA,
    variables: {
      getFavourites,
      getLoyalty,
      getSpotifyTopArtists,
      suggestionEventSlugname,
      fetchDate: new Date().getTime().toString(),
    },
    fetchPolicy: type === 'network' ? 'network-only' : 'cache-only',
  })
    .then((queryResult) => queryResult)
    .catch((queryError) => {
      const errorMessage = type === 'network'
        ? `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error: ${queryError}`
        : `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error after Cache Restore: ${queryError}`;
      console.log('\x1b[31m%s\x1b[0m', errorMessage);

      return null;
    });

  if (result.data && result.data.getUserData) {
    const { data: { getUserData } } = result;
    return getUserData;
  }

  return [];
};

const getUserData = async ({ getFavourites = true, getLoyalty = true, getSpotifyTopArtists = false, suggestionEventSlugname } = {}) => {
  try {

    const JSONObject = await executeQuery({ getFavourites, getLoyalty, getSpotifyTopArtists, suggestionEventSlugname });

    if (persistor) {
      persistor.persist();
    }

    return JSONObject;
  } catch (jsonError) {
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: JSON Object Error: ${jsonError}`);

    if (persistor) {
      const persistorStatus = await persistor.restore().then(() => 'OK');
      console.log(`${new Date().toLocaleTimeString()} ~ Client: Apollo Cache Restored - ${persistorStatus}`);

      try {
        const JSONObject = await executeQuery({ getFavourites, getLoyalty }, 'cache');

        return JSONObject;
      } catch (jsonErrorAfterCacheRestore) {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: JSON Object Error after Cache Restore, sending 404: ${jsonErrorAfterCacheRestore}`);
        return null;
      }
    }
    return null;
  }
};

export default getUserData;
