import React, { Component, Fragment } from 'react';
import Slider from 'react-slick';
import { PropTypes } from 'prop-types';
import TicketMain from './TicketMain';

const settings = {
  dots: true,
  infinite: false,
  slidesToShow: 4,
  slidesToScroll: 1,
  appendDots: dots => (
    <div
      style={{
        bottom: 10,
      }}
    >
      <ul style={{ margin: '0px' }}>{dots}</ul>
    </div>
  ),
  arrows: false,
  responsive: [
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 2,
      },
    },
  ],
};

export default class CarouselTickets extends Component {
  state = {};

  render() {
    const { tickets, ticketsPerLine } = this.props;
    return (
      <Fragment>
        <div className="lg:hidden xl:hidden w-full mb-8">
          <Slider {...settings}>
            {tickets.map((ticket, i) => (
              <TicketMain params={ticket.params} key={ticket.params.title.en + i} />
            ))}
          </Slider>
        </div>
        <div className="sm:hidden md:hidden w-full flex flex-wrap">
          {tickets.map((ticket, i) => (
            <div className={`w-1/${ticketsPerLine} mb-8`} key={ticket.params.title.en + i}>
              <TicketMain params={ticket.params} key={ticket.params.title.en} />
            </div>
          ))}
        </div>
      </Fragment>
    );
  }
}

CarouselTickets.propTypes = {
  tickets: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
  ticketsPerLine: PropTypes.number,
};

CarouselTickets.defaultProps = {
  ticketsPerLine: 4,
};
