const translation = (text) => {
  const textObject = {
    en: text,
    es: text,
    ca: text,
    pt: text,
  };

  return textObject;
};

export default translation;
