/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import Emoji from 'react-emoji-render';
import MessageItem from './MessageItem';
import { DEFAULT_COLOR } from '../utils/constants';
import { useFirebaseAuth } from '../context/FirebaseAuthContext';
import { useChat } from '../context/chatContext';
import { usePrivatePlusZoneContext } from '../context/privatePlusOneZoneContext';
import { hashCode, deleteMessage, acceptQaMessage } from '../utils/helpers';
import { useMessages } from '../hooks/useMessages';

const View = ({
  messages, channel, privateChat, isQuestions, qaMessagesAdmin,
}) => {
  const { setMessages } = useMessages({ channel, isQuestions, isPrivate: false });
  const { isModerator } = useFirebaseAuth();

  const handleDelete = async (message) => {
    const updated = messages.filter(msg => msg.messageId !== message.messageId);
    setMessages(updated);
    const deletedMessage = await deleteMessage({ channelUrl: channel, id: message.messageId });
  };

  const renderAdminQuestionsView = () => {
    const { pendingMessages, acceptedMessages } = qaMessagesAdmin;
    return (
      <>
        <h3 className="text-white uppercase text-sm font-americaMonoRegular px-4 mt-4 mb-2">Pending questions</h3>
        {qaMessagesAdmin && pendingMessages && pendingMessages.map(message => (
          <UserMessage isAuth={isModerator} isPendingMessage isQuestions={isQuestions} privateChat={privateChat} handleDelete={handleDelete} channel={channel} key={message.messageId} message={message} />
        ))}
        <h3 className="text-white uppercase text-sm font-americaMonoRegular px-4 mt-4 mb-2">Accepted questions</h3>
        {qaMessagesAdmin && acceptedMessages && acceptedMessages.map(message => (
          message.messageType !== 'admin'
            ? <UserMessage isAuth={isModerator} isQuestions={isQuestions} privateChat={privateChat} handleDelete={handleDelete} channel={channel} key={message.messageId} message={message} />
            : <AdminMessage handleDelete={handleDelete} channel={channel} key={message.messageId} message={message} />
        ))}
      </>
    );
  };

  return (
    <div className="py-2 text-base bg-black">
      {
        isQuestions && isModerator
          ? renderAdminQuestionsView()
          : messages && messages.map(message => (
            message.messageType !== 'admin'
              ? <UserMessage isAuth={isModerator} isQuestions={isQuestions} privateChat={privateChat} handleDelete={handleDelete} channel={channel} key={message.messageId} message={message} />
              : <AdminMessage handleDelete={handleDelete} channel={channel} key={message.messageId} message={message} />
          ))}
    </div>
  );
};

View.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  privateChat: PropTypes.bool,
  channel: PropTypes.string.isRequired,
};

View.defaultProps = {
  privateChat: false,
};

const AdminMessage = ({
  message, handleDelete,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const { isModerator } = useFirebaseAuth();

  return (
    <div className="message-item relative" onMouseEnter={isModerator ? () => setIsHovered(true) : null} onMouseLeave={isModerator ? () => setIsHovered(false) : null}>
      <div className="px-4 break-words leading-normal text-psworld-orange">
        <span className="font-americaRegular"><Emoji text={message.message} onlyEmojiClassName="text-xl" /></span>
      </div>
      {isHovered ? (
        <button className="text-white uppercase text-xs absolute pin-l pin-t h-25px flex items-center cursor-pointer" type="button" onClick={() => { handleDelete(message); setIsHovered(false); }}>
          <span className="font-icon1 icon-deny" />
        </button>
      ) : null}
    </div>
  );
};
const AdminMessageWithNick = ({
  message, userColor,
}) => (
  <div className="w-full relative px-4 break-words leading-normal text-white">

    <span
      className="font-americaBold break-all"
      style={{ color: userColor }}
    >
        Admin
    </span>
    <span className="font-americaRegular">:</span>
    <span className="font-americaRegular pl-1"><Emoji text={message} /></span>
  </div>
);

const UserMessage = ({
  message, handleDelete, privateChat, isQuestions, channel, isPendingMessage,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const { isModerator } = useFirebaseAuth();

  const { _sender: sender, message: usermessage, customType } = message;
  let userColor = DEFAULT_COLOR;
  if (sender) {
    userColor = sender.nicknameColor;

    const handleAcceptQuestion = async () => {
      const acceptedMessage = await acceptQaMessage({ channelUrl: channel, id: message.messageId });
    };

    return (
      <div className="message-item w-full flex justify-between relative" onMouseEnter={isModerator && !privateChat ? () => setIsHovered(true) : null} onMouseLeave={isModerator && !privateChat ? () => setIsHovered(false) : null}>
        {customType !== 'plusZone'
          ? customType !== 'admin'
            ? <MessageItem isAuth={isModerator} customType={customType} privateChat={privateChat} message={usermessage} messageOwner={sender} userColor={userColor} />
            : <AdminMessageWithNick message={usermessage} userColor={DEFAULT_COLOR} />
          : <PlusZoneMessage nickname={sender.nickname} userColor={userColor} />
      }
        {isHovered ? (
          <>
            <button className="text-white uppercase text-xs absolute pin-l pin-t h-25px flex items-center cursor-pointer hover:text-psworld-orange" type="button" onClick={() => { handleDelete(message); setIsHovered(false); }}>
              <span className="font-icon1 icon-deny" />
            </button>
            { isQuestions && isPendingMessage
              ? (
                <button className="mr-1 text-white hover:text-psworld-orange uppercase text-xs absolute pin-r pin-t h-full flex items-center cursor-pointer" type="button" onClick={() => { handleAcceptQuestion(message); }}>
                  <span className="font-icon1 icon-verified" />
                </button>
              )
              : null}
          </>
        ) : null}
      </div>
    );
  }
};

const PlusZoneMessage = ({ nickname, userColor }) => {
  const { userData } = useChat();
  const { openPrivateChat } = usePrivatePlusZoneContext();

  const myRoom = hashCode(nickname);
  const roomNickname = `#userInfo.displayName="${userData.nickname}"`;
  const url = `https://plusonezone.primaverasound.com/${myRoom}${roomNickname}`;

  const handleOpeningPrivateZoneOneRoom = (zoneOneUrl) => {
    openPrivateChat(zoneOneUrl);
  };

  return (
    <div className="px-4 w-full sm:hidden md:hidden">
      <div className="my-2 px-2 text-center rounded hover:opacity-85 uppercase w-full font-americaMonoRegular py-5 cursor-pointer" style={{ background: userColor }}>
        <div className="" onClick={() => handleOpeningPrivateZoneOneRoom(url)}>
          <span className="text-psworld-text-grey hover:text-black trans">{`${nickname} created a room`}</span>
        </div>
      </div>
    </div>
  );
};

UserMessage.propTypes = {
  isPendingMessage: PropTypes.bool,
};

UserMessage.defaultProps = {
  isPendingMessage: false,
};

AdminMessage.propTypes = {
  message: PropTypes.shape(),
};

AdminMessage.defaultProps = {
  message: { message: '' },
};

View.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.shape({})),
  isQuestions: PropTypes.bool,
};
View.defaultProps = {
  messages: null,
  isQuestions: false,
};

export default View;
