import React, { PureComponent, useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import moment from 'moment-timezone';
import Link from '../../util/link';
import LinkButtonRounded from './Buttons/LinkButtonRounded';
import LoadIcon from './LoadIcon';
import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';
import translateObj from '../../util/translateObj';
import Separator from './Separator';
import setCategoryFromEvent from '../../util/setCategoryFromEvent';
import { UserProfileContext } from '../../context/userProfileContext';
import userSetFavorites from '../../api/graphql/mutations/userSetFavorites';

// TODO: import this from LineupContainer
const TIMEZONE_DEFAULT = 'Europe/Madrid';

const TopSection = ({
  artistSetIsFavorite,
  artistSetSlugName,
  artistSlugName,
  dateTimeStartHuman,
  dateTimeStartReal,
  category,
  context,
  event: {
    postSlugNames, eventReadableName, description, showDateTime, showDate,
  },
  match,
  history,
  eventTimezone,
  venue: { venueReadableName },
}) => {
  const [starDisabled, setStarDisabled] = useState(false);
  const [isArtistSetFav, setIsArtistSetFav] = useState(false);

  const knowMore = {
    es: 'SABER MÁS',
    en: 'LEARN MORE',
    ca: 'SABER MÉS',
  };
  const artistStartDate = moment(dateTimeStartReal / 1000, 'X');

  useEffect(() => {
    setIsArtistSetFav(artistSetIsFavorite);
  }, [artistSetIsFavorite]);


  const handleSetArtistSetFavorite = (artistsetslugname) => {
    const { token } = context;

    if (artistsetslugname && token) {
      userSetFavorites({ token, slugnamearr: artistsetslugname, category: 'Concerts' }).then((res) => {
        if (res) {
          setIsArtistSetFav(!isArtistSetFav);
        } else {
          console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Error - userGetFavorites failed to submit!`);
        }
      })
        .catch((err) => { console.log('error getting user favorites: ', err); });
    }
  };

  const handleFavoriteLogic = (artistSetSlugNameFromFn, lang) => {
    const { token, userEmail } = context;

    if (!starDisabled) {
      if (token && userEmail) {
        setStarDisabled(true);
        // logic to update user favs
        handleSetArtistSetFavorite([artistSetSlugNameFromFn]);
        setTimeout(() => {
          setStarDisabled(false);
        }, 2000);
      } else {
        // please log in
        history.push(`/${lang}/login-screen?e=sign-in`);
      }
    }
  };


  return (
    <RoutesContext.Consumer>
      {({ language: contextLanguage, enteredFrom }) => {
        const localization = getLocalization(
          contextLanguage,
          enteredFrom,
          match,
        );
        moment.locale(localization.language);
        const linkToEvent = postSlugNames && postSlugNames[0] ? `${postSlugNames[0]}` : 'tickets';
        return (
          <div className="flex py-6 px-5 lg:px-9 bg-white rounded rounded-b-none h-event-ticket lg:w-ticket-lg xl:w-ticket-xl">
            <div className="w-1/5 pt-6">
              <span className="font-icon icon-ticket text-5xl md:text-4xl sm:text-4xl -ml-2 md:-ml-2 sm:-ml-1" />
            </div>
            <div className="w-4/5 pt-6 flex flex-col justify-between">
              <div className="font-americaMonoRegular text-12">
                <div className="leading-normal uppercase">
                  {translateObj(description, localization.language)}
                </div>
                <Separator borderWidth={1} />
                <div className="font-americaBlack text-18 mb-4">
                  <div className="mt-4">
                    {translateObj(eventReadableName, localization.language)}
                  </div>
                </div>
                <div className="font-americaMonoRegular text-12 flex justify-between">
                  <div className="leading-normal uppercase">
                    <span>
                      {dateTimeStartHuman && showDate
                        && moment(dateTimeStartHuman / 1000, 'X').tz(eventTimezone).format('dddd D MMMM')}
                    </span>
                    <br />
                    {dateTimeStartReal && showDateTime && (
                      <>
                        <span>
                          {artistStartDate.tz(eventTimezone).format('HH:mm')}
                        </span>
                        <span> | </span>
                      </>
                    )}
                    <span>
                      {translateObj(venueReadableName, localization.language)
                        ? translateObj(venueReadableName, localization.language)
                        : null}
                    </span>
                  </div>
                  <div>
                    {starDisabled ? <LoadIcon height={15} width={15} color={category} /> : <span className={`font-icon cursor-pointer ${isArtistSetFav ? 'icon-star-solid' : 'icon-star'} text-${category} pl-4 sm:pl-0`} onClick={() => handleFavoriteLogic(`${artistSlugName}|${artistSetSlugName}`, localization.language)} />}
                  </div>
                </div>
              </div>
              {/* <div className="mt-12 cursor-pointer font-americaMonoBold text-12">
                  {linkToEvent !== 'tickets'
                    ? (
                      <Link to={`/tours/${linkToEvent}`} localzationpath={localization.path}>
                        <span className="font-icon icon-arrow-right mr-2 text-black" />
                        <span className="text-black">{translateObj(knowMore, localization.language)}</span>
                      </Link>
                    )
                    : null}
                </div> */}
            </div>
          </div>
        );
      }}
    </RoutesContext.Consumer>
  );
};

TopSection.propTypes = {
  event: PropTypes.shape(),
  artistSetIsFavorite: PropTypes.bool,
  category: PropTypes.string,
};

TopSection.defaultProps = {
  event: null,
  artistSetIsFavoritev: false,
  category: 'black',
};

class Ticket extends PureComponent {
  // static contextType = UserProfileContext;

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { event } = this.props;

    if (!event) return null;

    const { postSlugNames, eventReadableName, eventSlugName, timezone } = event;
    const category = setCategoryFromEvent(eventSlugName);
    const linkToEvent = postSlugNames && postSlugNames[0]
      ? postSlugNames[0]
      : `tickets?e=${category}`;
    const cta = {
      text: {
        es: 'Entradas',
        en: 'Tickets',
        ca: 'Entrades',
        pt: 'Bilhetes',
      },
      category,
      url: `/${linkToEvent}`,
    };

    const eventTimezone = timezone || TIMEZONE_DEFAULT;

    return (
      <div className="w-ticket-lg sm:w-ticket-sm lg:w-ticket-lg xl:w-ticket-xl md:w-ticket-md lg:w-ticket-lg xl:w-ticket-xl border-box">
        <TopSection {...this.props} context={this.context} category={cta.category || 'black'} eventTimezone={eventTimezone} />
        <div className="py-8 mt-1 bg-white rounded rounded-t-none">
          <div className="text-center">
            <LinkButtonRounded {...cta} category={cta.category} />
          </div>
        </div>
      </div>
    );
  }
}

Ticket.propTypes = {
  match: PropTypes.shape().isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})),
};

Ticket.defaultProps = {
  data: null,
};

export default withRouter(Ticket);

Ticket.contextType = UserProfileContext;
