/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';
import stripTags from '../helpers/stripTags';
import translateObj from '../../../util/translateObj';

const TitleLineH2 = ({
  text, align, color, margin, match, tabIndex,
}) => (
  <RoutesContext.Consumer>
    {({ language: contextLanguage, enteredFrom }) => {
      const localization = getLocalization(contextLanguage, enteredFrom, match);
      return <h2 tabIndex={tabIndex} className={`text-${color} text${align} ${margin} text-28 font-americaBlack sm:text-20 md:text-18 lg:text-26`}>{stripTags(translateObj(text, localization.language))}</h2>;
    }}
  </RoutesContext.Consumer>
);

export default withRouter(TitleLineH2);

TitleLineH2.propTypes = {
  color: PropTypes.string,
  align: PropTypes.string,
  margin: PropTypes.string,
  match: PropTypes.shape().isRequired,
  text: PropTypes.shape({}),
  tabIndex: PropTypes.string,
};

TitleLineH2.defaultProps = {
  color: 'black',
  align: 'left',
  margin: 'my-6',
  text: null,
  tabIndex: '0',
};
