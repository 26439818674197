import gql from 'graphql-tag';

import { clientWeb } from '../graphqlAPI';

const USER_WEB_LOGIN = gql`
  mutation Post($email: String!) {
    userWebVerification(
      email: $email,
      ){
        isValid
        error
    }
}`;

const userWebVerification = async ({
  email,
}) => {
  try {
    const result = await clientWeb.mutate({
      mutation: USER_WEB_LOGIN,
      variables: {
        email,
      },
      fetchPolicy: 'no-cache',
    })
      .then(queryResult => queryResult)
      .catch((queryError) => {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error: ${queryError}`);
        return null;
      });

    const { data: { userWebVerification: validatedFormData } } = result;

    if (validatedFormData) {
      return validatedFormData;
    }
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: GraphQL Query Error: validatedFormData = null`);
    return null;
  } catch (error) {
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: userWebVerification Error: ${error}`);
    return null;
  }
};

export default userWebVerification;
