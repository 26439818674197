import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';
import translateObj from '../../util/translateObj';

import LineupTableSimple from './LineupTableSimple';
import CollapsibleListSimplified from './Collapsible/CollapsibleListSimplified';

const LineupContainerSimple = ({
  params, filterBy, title, match, lowercase,
}) => (
  <RoutesContext.Consumer>
    {({ language: contextLanguage, enteredFrom }) => {
      const localization = getLocalization(contextLanguage, enteredFrom, match);
      return (
        <div className="mt-8">
          {params.length === undefined && (
            <div className="w-full flex ">
              <h3>Apologies this information is not available at the moment</h3>
            </div>
          )}
          {/* {params.length === 1 && (
            <div className="w-full flex ">
              <div className={`${!title && 'hidden'} w-1/4 sm:hidden md:hidden flex-wrap`}>
                <p className="font-americaMonoBold text-14 uppercase sm:hidden">{title && translateObj(title, localization.language)}</p>
              </div>
              <div className={`${title ? 'w-3/4' : 'w-full'} sm:w-full md:w-full pb-12`}>
                <p className="lg:hidden xl:hidden w-full uppercase mb-8 font-americaMonoBold text-14">{title && translateObj(title, localization.language)}</p>
                <LineupTableSimple artists={params[0].content[0].artists} />
              </div>
            </div>
          )} */}
          {params.length && (
            <div className="w-full flex ">
              <div className={`${!title && 'hidden'} w-1/4 sm:hidden md:hidden flex-wrap`}>
                <p className="font-americaMonoBold text-14 uppercase mt-0">{title && translateObj(title, localization.language)}</p>
              </div>
              <div className={`${title ? 'w-3/4' : 'w-full'} sm:w-full md:w-full`}>
                <p className="lg:hidden xl:hidden w-full uppercase mb-8 font-americaMonoBold text-14">{title && translateObj(title, localization.language)}</p>
                <CollapsibleListSimplified params={params} border lowercase={lowercase} />
              </div>
            </div>
          )}
        </div>
      );
    }}
  </RoutesContext.Consumer>
);

export default withRouter(LineupContainerSimple);

LineupContainerSimple.propTypes = {
  params: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  filterBy: PropTypes.string,
  title: PropTypes.shape({}),
  match: PropTypes.shape().isRequired,
  lowercase: PropTypes.bool,
};

LineupContainerSimple.defaultProps = {
  filterBy: '',
  lowercase: false,
  title: null,
};
