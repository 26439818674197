import React from 'react';
import PropTypes from 'prop-types';
import ComponentIterator from '../../../containers/ComponentIterator';

const TopHeaderRow = ({ components }) => (
  <div className="flex sm:block">
    <ComponentIterator components={components} />
  </div>
);

TopHeaderRow.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  components: PropTypes.array.isRequired,
};

export default TopHeaderRow;
