/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';

import Formsy from 'formsy-react';

import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';

import postContactForm from '../../api/graphql/mutations/postContactForm';
import Filter from './Collapsible/Filter';
import FormInput from './Form/FormInput';
import FormCheckbox from './Form/FormCheckbox';
import FormTextarea from './Form/FormTextarea';
import translateObj from '../../util/translateObj';

const recaptchaRef = React.createRef();

class ContactForm extends Component {
  state = {
    canSubmit: false,
    didSubmit: false,
    events: '',
    captchaValue: false,
    showForm: false,
  };

  componentDidMount = () => {
  }

  onHandleCollapsibleMenuSelect = (value) => {
    this.setState({ events: value, showForm: true });
  }

  submitForm = (formData) => {
    // recaptchaRef.current.execute();
    // const recaptchaValue = recaptchaRef.current.getValue();
    // console.log(recaptchaValue, 'recaptcha value');

    const { events: { byEvent } } = this.state;
    this.setState({ canSubmit: false });
    const data = { ...formData, events: byEvent[0] };
    postContactForm({ ...data }).then((response) => {
      if (response) {
        if (response.isValid) {
          this.onReset();
          this.setState({
            didSubmit: true,
          });
        } else {
          console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Error - submitForm validated, but returned isValid = false!`);
        }
      } else {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Error - submitForm failed to submit!`);
      }
    });
  };

  onChange = (captchaValue) => {
    this.setState({ captchaValue: true });
  }

  onReset = () => {
    this.form.reset();
    this.firstName.setValue('');
    this.lastName.setValue('');
    this.email.setValue('');
    this.subject.setValue('');
    this.message.setValue('');
    this.conditions.setValue(false);
  };

  enableButton = () => {
    this.setState({ canSubmit: true });
  };

  disableButton = () => {
    this.setState({ canSubmit: false });
  };

  render() {
    const {
      match,
      menu,
      title,
      fields,
      validation,
      text,
      conditions,
      submitButtonText,
      resetButtonText,
      submitMessage,
    } = this.props;

    const {
      canSubmit, didSubmit, captchaValue, showForm,
    } = this.state;

    return (
      <RoutesContext.Consumer>
        {
          ({ language: contextLanguage, enteredFrom }) => {
            const localization = getLocalization(contextLanguage, enteredFrom, match);
            return (
              <Fragment>
                {menu
                    && <Filter {...menu} hasSearchBar={false} radio onHandleCollapsibleMenuSelect={this.onHandleCollapsibleMenuSelect} />}
                {showForm && (
                <div className={`w-full ${menu && 'py-4'} font-americaMonoRegular `}>
                  <div className="my-8" tabIndex="0">{translateObj(title, localization.language)}</div>
                  <Formsy ref={(c) => { this.form = c; return this.form; }} onValidSubmit={this.submitForm} onValid={this.enableButton} onInvalid={this.disableButton} className="flex sm:flex-col flex-wrap -mx-3">
                    <FormInput
                      ref={(c) => { this.firstName = c; return this.firstName; }}
                      name="firstName"
                      title={translateObj(fields.firstName, localization.language)}
                      validations={{ isSpecialWords: true, maxLength: 50 }}
                      validationErrors={{ isSpecialWords: translateObj(validation.inValidName, localization.language), maxLength: translateObj(validation.longName, localization.language) }}
                      required
                    />
                    <FormInput
                      ref={(c) => { this.lastName = c; return this.lastName; }}
                      name="lastName"
                      title={translateObj(fields.lastName, localization.language)}
                      validations={{ isSpecialWords: true, maxLength: 50 }}
                      validationErrors={{ isSpecialWords: translateObj(validation.inValidName, localization.language), maxLength: translateObj(validation.longName, localization.language) }}
                      required
                    />
                    <FormInput
                      ref={(c) => { this.email = c; return this.email; }}
                      name="email"
                      title={translateObj(fields.email, localization.language)}
                      validations={{ isEmail: true, maxLength: 50 }}
                      validationErrors={{ isEmail: translateObj(validation.inValidEmail, localization.language), maxLength: translateObj(validation.longEmail, localization.language) }}
                      required
                    />
                    {/* <FormInput
                        ref={(c) => { this.phone = c; return this.phone; }}
                        name="phone"
                        title={!fields.phone[localization.language] ? fields.phone.en : fields.phone[localization.language]}
                        validations={{ isNumeric: true, maxLength: 25 }}
                        validationErrors={{ isNumeric: translateObj(validation.inValidPhone, localization.language), maxLength: translateObj(validation.longPhone, localization.language) }}
                      />
                      <FormSelectAutoComplete
                        name="country"
                        title={translateObj(fields.country, localization.language)}
                        validations="isSpecialWords"
                        validationError=""
                        options={getCountryNamesByCode(localization.language)}
                        message="No match"
                        required
                      /> */}
                    {/* <FormSelect name="city" title={fields.city[localization.language]} validations="isSpecialWords" validationError="" options={cityOptions} required /> */}
                    <FormInput
                      ref={(c) => { this.subject = c; return this.subject; }}
                      name="subject"
                      title={translateObj(fields.subject, localization.language)}
                      validations={{ maxLength: 250 }}
                      validationError={validation.longSubject[localization.language]}
                      required
                    />
                    <FormTextarea
                      ref={(c) => { this.message = c; return this.message; }}
                      name="message"
                      title={translateObj(fields.message, localization.language)}
                      validations={{ maxLength: 500 }}
                      validationError={validation.longMessage[localization.language]}
                      required
                    />
                    { !didSubmit && (
                    <>
                      <div className="flex justify-end mt-8 sm:mt-0">
                        <div className="w-1/2 sm:w-full md:w-full sm:px-3 md:pl-3 font-tiemposRegular text-12">
                          <p style={{ marginTop: 0 }} tabIndex="0">
                            {translateObj(text, localization.language)}
                          </p>
                          <div>
                            <FormCheckbox
                              ref={(c) => { this.conditions = c; return this.conditions; }}
                              name="conditions"
                              title={translateObj(conditions, localization.language)}
                              validations="isTrue"
                              validationError=""
                            />
                          </div>
                          <ReCAPTCHA
                            ref={recaptchaRef}
                            onChange={this.onChange}
                            sitekey="6LeVsbsUAAAAAI8Crd2wQ8eNINyoLzAT0uM01KKe"
                          />
                          <div className="hidden sm:block sm:w-full mt-12">
                            {/* <button type="button" onClick={this.onReset} className="bg-black h-12 text-white rounded-full mx-4 py-2 mb-4">{resetButtonText[localization.language]}</button> */}
                            <button type="submit" disabled={!canSubmit && captchaValue} className={`${canSubmit && captchaValue ? 'bg-black' : 'bg-grey'} h-12 text-white rounded-full px-3 w-full py-2 font-americaMonoBold`}>{translateObj(submitButtonText, localization.language)}</button>
                          </div>
                        </div>
                        <div className="flex flex-col w-1/4 sm:hidden">
                          {/* <button type="button" onClick={this.onReset} className="bg-black h-12 text-white rounded-full mx-4 py-2 mb-4">{resetButtonText[localization.language]}</button> */}
                          <button type="submit" disabled={!canSubmit && captchaValue} className={`${canSubmit && captchaValue ? 'bg-black' : 'bg-grey'} h-12 text-white rounded-full mx-4 py-2 font-americaMonoBold`}>{translateObj(submitButtonText, localization.language)}</button>
                        </div>
                      </div>
                    </>
                    )}
                    { didSubmit && (
                    <div className="font-americaMonoRegular ml-4">
                      <h3 className="text-green text-sm">{translateObj(submitMessage, localization.language)}</h3>
                    </div>
                    ) }
                    <div className="m-4 flex items-center ml-auto" />
                  </Formsy>
                </div>
                ) }
              </Fragment>
            );
          }
        }
      </RoutesContext.Consumer>
    );
  }
}

ContactForm.propTypes = {
  match: PropTypes.shape().isRequired,
  menu: PropTypes.shape(),
  title: PropTypes.shape().isRequired,
  fields: PropTypes.shape().isRequired,
  validation: PropTypes.shape().isRequired,
  text: PropTypes.shape().isRequired,
  conditions: PropTypes.shape().isRequired,
  submitButtonText: PropTypes.shape().isRequired,
  resetButtonText: PropTypes.shape().isRequired,
  submitMessage: PropTypes.shape().isRequired,
};

ContactForm.defaultProps = {
  menu: null,
};

export default withRouter(ContactForm);
