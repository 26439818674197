/* eslint-disable no-nested-ternary */
import React from 'react';
import { PropTypes } from 'prop-types';
import parse from 'html-react-parser';
import { withRouter } from 'react-router-dom';
import { useUser } from '../../../context/userProfileContext';
import translateObj from '../../../util/translateObj';
import { useRoutes } from '../../../context/routesContext';
import ZoneCardIframe from './ZoneCardIframe';

let tokenQuery = '';

const ZoneCard = ({
  language, title, url, text, tokenQuery, index, userNickName = 'Fellow PSsounder',
}) => {
  const nickNameStringParam = `#userInfo.displayName="${userNickName}"`;
  const queryString = tokenQuery ? tokenQuery + nickNameStringParam : nickNameStringParam;
  const cardTitle = translateObj(title, language);
  const cardText = translateObj(text, language);

  return (
    <div className="bg-psworld-zone-red p-2 sm:w-full w-psworld-box h-psworld-box hover:bg-psworld-zone-red-light trans sm:mx-0 m-psworld-box">
      <a href={url + queryString} className="text-psworld-text-grey flex flex-col h-full justify-between w-full" target="_blank" rel="noopener noreferrer">
        <div>
          <h2 className="mb-1 font-americaBold">{`R#${index + 1}${' '}${cardTitle}`}</h2>
          <p className="text-xxs font-americaBold leading-regular">{cardText}</p>
        </div>
      </a>
    </div>
  );
};

const ZoneSection = ({
  match, zones, intro, banner, handleWithIframe = true,
}) => {
  const { userRoles, userNickName } = useUser();
  const { language } = useRoutes(match);
  const isModerator = userRoles && userRoles.includes('moderator');
  if (isModerator) tokenQuery = '?jwt=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiYWRtaW4iOnRydWV9.8nKmrvTqPmUD88mtb8grpNzbVGzEE2MBM8z83Yfc844';
  const introText = translateObj(intro.text, language);
  // const bannerText = banner ? translateObj(banner.text, language) : '';

  return (
    <>
      {intro && (
      <div className="w-full p-2 ps-zone">
        <div className="bg-psworld-zone-pink px-2 py-4 text-psworld-text-grey">
          <h2 className="mb-6 font-americaBold">{intro.title}</h2>
          <div className="text-lg leading-regular">
            {parse(introText)}
          </div>
        </div>
      </div>
      )}
      {zones && zones.length > 0 && (
      <div className="md:-mx-psworld-box lg:-mx-psworld-box xl:-mx-psworld-box flex flex-wrap mb-2">
        {zones.map((zone, i) => (
          handleWithIframe ? (
            <ZoneCardIframe language={language} {...zone} index={i} tokenQuery={tokenQuery} key={zone.id} userNickName={userNickName} />
          ) : (
            <ZoneCard language={language} {...zone} index={i} tokenQuery={tokenQuery} key={zone.id} userNickName={userNickName} />
          )
        ))}
      </div>
      )}
      {/* {banner && (
      <div className="w-full mb-4">
        <div className="bg-psworld-zone-yellow hover:bg-psworld-zone-yellow-light p-2 text-psworld-text-grey md:h-auto sm:h-auto h-psworld-box trans">
          <a href={banner.url || '#'} className="text-psworld-text-grey flex flex-col h-full" target="_blank" rel="noopener noreferrer">
            <h2 className="uppercase font-americaBold my-2 flex-1">{banner.title}</h2>
            <div className="flex-2 flex flex-col justify-center pb-4">
              <div className="text-lg leading-regular">
                {parse(bannerText)}
              </div>
              <p className="font-americaBold">{banner.url}</p>
            </div>
          </a>
        </div>
      </div>
      )} */}
    </>
  );
};

ZoneSection.propTypes = {
  zones: PropTypes.arrayOf(PropTypes.shape({})),
  intro: PropTypes.shape({}),
  banner: PropTypes.shape({}),
  match: PropTypes.shape().isRequired,
};
ZoneSection.defaultProps = {
  zones: [],
  intro: null,
  banner: null,
};

export default withRouter(ZoneSection);
