/* eslint-disable camelcase */
import React from 'react';
import { PropTypes } from 'prop-types';

const StreamingPlayerPictureSmall = ({
  handleSwitchingVideos,
  mainVideo,
  videoObject,
}) => {
  const { title = 'main room', url, miniatureImage = '' } = videoObject;
  // const maxHeightPictureStyle = { maxHeight: '15rem' };
  const active = mainVideo === url;

  const backgroundStyle = {
    backgroundImage: `url(${miniatureImage})`,
    height: '7.5rem',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  };

  return (
    <div className="mx-2">
      <div
        className={`${active ? 'border-psworld-orange' : 'border-psworld-box'} border-4 cursor-pointer w-psworld-box`}
        style={backgroundStyle}
        onClick={() => {
          if (!active) handleSwitchingVideos(url);
        }}
      >
        {/* <img
          src={miniatureImage}
          alt="miniature-video"
          style={maxHeightPictureStyle}
        /> */}
      </div>
      <h3 className="mt-3 font-americaMonoRegular text-xs uppercase">{title}</h3>
    </div>
  );
};

StreamingPlayerPictureSmall.propTypes = {
  handleSwitchingVideos: PropTypes.func.isRequired,
  mainVideo: PropTypes.string.isRequired,
  videoObject: PropTypes.shape(),
};

StreamingPlayerPictureSmall.defaultProps = {
  videoObject: {
    title: 'title here',
    url: '',
    miniatureImage: 'https://unsplash.com/photos/xXc7zUKIhRw',
  },

};

export default StreamingPlayerPictureSmall;
