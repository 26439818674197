import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import ConversationItem from './ConversationItem';
import ChatConversation from './ChatConversation';
import usePrivateChannels from '../../Chat/hooks/usePrivateChannels';
import { useMessages } from '../../Chat/hooks/useMessages';
import { useChat } from '../../Chat/context/chatContext';
import { useRoutes } from '../../../../context/routesContext';
import Loader from '../Loader';
import { acceptInvitation } from '../../Chat/utils/helpers';
import DeleteChatModal from './DeleteChatModal';
import translateObj from '../../../../util/translateObj';
import { OpenChatContext } from './context/OpenChatContext';

const getChatUsersButMe = (users, myId) => users.filter((user) => user !== myId).join(',');

const ChatsList = ({ literals }) => {
  const { language } = useRoutes();
  const { userData } = useChat();
  const { channelList, isLoadingChannels, pendingChannels } = usePrivateChannels();
  const { messages, isLoadingMessages, setActiveChannel, sendNewMessage, activeChannel } = useMessages({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { chatIsOpen, setChatIsOpen } = useContext(OpenChatContext);

  const handleAcceptInvitation = (id, inviter) => {
    acceptInvitation({ channelUrl: id, userData, inviter });
  };

  return (
    <>
      <button className="absolute pin-t pin-r cursor-pointer z-top" type="button" onClick={() => setChatIsOpen(!chatIsOpen)}>
        <div className={` ${activeChannel.id === null ? 'p-4' : 'p-6'} font-icon icon-close-solid text-pro-online text-xl`} />
      </button>
      <div className="flex">
        <div className={`px-4 pb-6 sm:w-screen-95 md:w-screen-95 lg:w-450px sm:h-screen75 md:h-screen80 h-screen50 text-white ${activeChannel.id !== null ? 'sm:hidden md:hidden' : ''} overflow-hidden`}>
          <div className="py-4 border-b border-white">
            <h3 className="uppercase text-base font-normal font-americaMonoRegular tracking-px">{translateObj(literals.title, language)}</h3>
          </div>
          <div className="flex flex-col items-stretch overflow-y-scroll h-full pb-8 pt-4">
            {isLoadingChannels ? (
              <div className="w-full h-full flex items-center justify-center">
                <Loader label={{ en: null }} className="justify-center" textColor="text-white" />
              </div>
            )
              : (
                <div>
                  {channelList?.length === 0 && pendingChannels?.length === 0 ? <div>{translateObj(literals.noItemsConversations, language)}</div> : (
                    <>
                      {pendingChannels?.map((item) => (
                        <div className="relative w-full">
                          <ConversationItem
                            key={item.id}
                            recipientId={getChatUsersButMe(item.members, userData.userId)}
                            onClick={() => handleAcceptInvitation(item.url, item.inviter)}
                            isActive={activeChannel.id === item.id}
                            isListItem
                          />
                          <div className="mt-1 mr-1 absolute pin-r pin-t bg-black border-2 border-pro-online rounded-lg text-pro-online text-xs font-americaMonoBold uppercase tracking-px p-1">{translateObj(literals.newInvitationLabel, language)}</div>
                        </div>
                      ))}
                      {channelList?.map((item) => (
                        <ConversationItem
                          key={item.id}
                          recipientId={getChatUsersButMe(item.members, userData.userId)}
                          onClick={() => setActiveChannel(item)}
                          isActive={activeChannel.id === item.id}
                          isListItem
                        />
                      ))}
                    </>
                  )}
                </div>
              )}
          </div>
        </div>
        {activeChannel.id !== null && (
          <div className="px-4 pt-4 sm:w-screen-95 md:w-screen-95 lg:w-540px sm:h-screen75 md:h-screen80 h-screen50">
            <div className="bg-white text-black rounded-lg p-4 h-full flex flex-col items-stretch">
              {/* <div className="flex justify-between items-start"> */}
              <div style={{ height: '15%' }}>
                <ConversationItem recipientId={getChatUsersButMe(activeChannel.members, userData.userId)} onClick={() => setActiveChannel({ id: null })} />
              </div>
              {/* <IconButton onClickCallback={() => setShowDeleteModal(true)} icon="delete" className="flex-none" /> */}
              {/* </div> */}
              <div style={{ height: '85%' }}>
                <ChatConversation
                  noItemsMessages={translateObj(literals.noItemsMessages, language)}
                  messages={messages}
                  myId={userData.userId}
                  sendMessageCallback={sendNewMessage}
                  loadingMessages={isLoadingMessages}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <DeleteChatModal
        channel={activeChannel}
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        activeChannelCallback={(channel) => setActiveChannel(channel)}
        literals={literals}
      />
    </>
  );
};

export default ChatsList;

ChatsList.propTypes = {
  literals: PropTypes.shape().isRequired,
};

ChatsList.defaultProps = {
};
