import React from 'react';
import PropTypes from 'prop-types';
import LineupTableSimpleItem from './LineupTableSimpleItem';

const LineupTableSimple = ({ artists }) => (
  <table className="w-full sm:border-t border-black">
    <thead className="sm:hidden">
      <tr className="bg-grayE2DDDB ">
        <th className="text-left font-tiemposRegular uppercase text-12 py-3 pl-8">Artist</th>
        <th className="text-left font-tiemposRegular uppercase text-12 w-1/5">Time</th>
        <th className="text-left font-tiemposRegular uppercase text-12 w-1/5">Stage</th>
      </tr>
    </thead>
    <tbody>
      {artists.map((artist, i) => (
        <LineupTableSimpleItem {...artist} key={`${artist}+${i}`} />
      ))}
    </tbody>
  </table>
);

LineupTableSimple.propTypes = {
  artists: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default LineupTableSimple;
