import React, { useState, useEffect, useContext } from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { NoInfoTicket, Ticket } from '@primaveralabs/ui-tickets';
import moment from 'moment-timezone';
import RoutesContext from '../../context/routesContext';
import MediaTitle from './MediaTitle';
import translateObj from '../../util/translateObj';
import { transformTickets } from '../../util/transformTickets';
import isHourFormat12 from '../../util/isHourFormat12';

const literals = {
  tickets: {
    en: 'Tickets',
    es: 'Tickets',
    ca: 'Tickets',
  },
  from: {
    en: 'from',
    es: 'desde',
    ca: 'des de',
  },
};

function SidebarNextDates({ data, headLine, isConcert }) {
  const [tickets, setTickets] = useState([]);

  const {
    language,
  } = useContext(RoutesContext);
  const hours12 = isHourFormat12();

  useEffect(() => {
    const fn = async () => {
      if (isConcert) {
        const parsedData = await transformTickets(data, language);
        setTickets(parsedData);
      } else {
        const parsedData = data.map((i) => {
          const timeZone = i?.timezone || 'Europe/Madrid';
          const dateTime = i?.dateTimeStartHuman ? moment(i.dateTimeStartHuman / 1000, 'X') : '';
          const date = i.event.showDate && dateTime ? dateTime.tz(timeZone).locale(language).format('dddd DD MMM') : '';
          const time = i.event.showDateTime && dateTime ? dateTime.tz(timeZone).locale(language).format(hours12 ? '- hh:mm' : '- HH:mm') : '';

          return ({
            title: i?.event?.eventReadableName
              ? translateObj(i?.event?.eventReadableName, language)
              : '',
            subtitle: `${date}`,
            venue: i?.venue?.venueReadableName
              ? translateObj(i?.venue?.venueReadableName, language)
              : '',
            href: i?.event?.salePostSlugName
              ? `/${i?.event?.salePostSlugName}`
              : '',
            literals: Object.keys(literals).reduce((acc, key) => {
              acc[key] = translateObj(literals[key], language);
              return acc;
            }, {}),
          });
        });
        setTickets(parsedData);
      }
    };
    fn();
  }, []);

  return (
    <RoutesContext.Consumer>
      {({ language: contextLanguage, enteredFrom }) => (
        <>
          <MediaTitle
            primaryText={headLine}
            category="black"
            margin="mt-30px mb-4"
          />
          {tickets.length > 0
              && tickets.map((ticket) => (
                <div className="mb-4">
                  {isConcert ? (
                    <Ticket
                      ticketReadableName={ticket.ticketReadableName}
                      subtitle={ticket.subtitle}
                      price={ticket.price}
                      showFees={ticket.showFees}
                      currency={ticket.currency}
                      tags={ticket.tags}
                      literals={ticket.literals}
                      enabled={ticket.enabled}
                      description={ticket.description}
                      sellers={ticket.sellers}
                      show
                      intent="mobile"
                    />
                  ) : (
                    <NoInfoTicket
                      title={ticket.title}
                      subtitle={ticket.subtitle}
                      venue={ticket.venue}
                      href={ticket.href}
                      literals={ticket.literals}
                      intent="mobile"
                    />
                  )}
                </div>
              ))}
        </>
      )}
    </RoutesContext.Consumer>
  );
}

SidebarNextDates.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  isConcert: PropTypes.bool,
  headLine: PropTypes.string.isRequired,
};
SidebarNextDates.defaultProps = {
  data: null,
  isConcert: false,
};
export default withRouter(SidebarNextDates);
