import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { literals } from './literals';
import translateObj from '../../../util/translateObj';
import { useRoutes } from '../../../context/routesContext';

const UserFestivals = ({ selectionCallback, festivals }) => {
  const { language } = useRoutes();
  const [selectedFestivals, setSelectedFestivals] = useState([]);

  const handleFestivalsSelection = (festival) => {
    const selected = [...selectedFestivals];
    if (selected.includes(festival)) {
      const itemIndex = selected.indexOf(festival);
      selected.splice(itemIndex, 1);
    } else {
      selected.push(festival);
    }
    setSelectedFestivals(selected);
  };

  useEffect(() => {
    selectionCallback(selectedFestivals.map((festival) => festival.value));
  }, [selectedFestivals]);

  return (
    <div className="pt-2">
      <h3 className="font-americaRegular uppercase text-11px sm:text-xs tracking-wide">
        {translateObj(literals.choose_festival, language)}
      </h3>
      <div className="flex flex-wrap mt-3">
        {festivals.map((festival) => (
          <button
            type="button"
            key={festival.value}
            onClick={() => handleFestivalsSelection(festival)}
            className="mr-1 mb-1 sm:w-48% focus:rounded-lg"
          >
            <div className={`uppercase ${selectedFestivals.includes(festival)
              ? 'text-white bg-magenta font-americaMonoMedium'
              : 'text-black bg-grayF4F4F4 font-americaMonoRegular'}
      rounded-lg flex items-center justify-center h-30px w-20 sm:w-full text-xxs sm:text-xs sm:h-34px`}
            >
              {translateObj(festival.label, language)}
            </div>
          </button>
        ))}
      </div>
    </div>
  );
};

UserFestivals.propTypes = {
  selectionCallback: PropTypes.func.isRequired,
  festivals: PropTypes.arrayOf(PropTypes.shape()),
};

UserFestivals.defaultProps = {
  festivals: [],
};

export default UserFestivals;
