import React from 'react';

const SendIcon = () => (
  <svg width="100%" height="100%" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g fill="none" fillRule="evenodd">
      <g transform="translate(0 .022)">
        <path d="M22.667 14.017 1.75 23.877c-1.07.5-2.18-.62-1.58-1.59l4.594-7.363c.297-.476.78-.805 1.331-.907l8.253-1.528c.545-.101.545-.882 0-.983L6.137 9.98a2.001 2.001 0 0 1-1.343-.925L.309 1.698c-.6-.97.51-2.08 1.58-1.57l20.788 9.85c1.76.84 1.76 3.21-.01 4.04" fill="#F60" />
      </g>
    </g>
  </svg>
);

export default SendIcon;
