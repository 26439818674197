import React from 'react';
import { PropTypes } from 'prop-types';

import ComponentIterator from '../../containers/ComponentIterator';

const RadioItemsWrapper = ({ components, columns }) => (
  <div className="flex flex-wrap sm:overflow-x-scroll -mx-2">
    {components.map((component, i) => (
      <div key={i} className={`${columns === 1 ? 'w-full' : `w-1/${columns} md:w-1/${columns - 1} sm:w-full `} mb-4`}>
        <div className="px-2">
          <ComponentIterator components={[component]} />
        </div>
      </div>
    ))}
  </div>
);

RadioItemsWrapper.propTypes = {
  components: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  columns: PropTypes.number,
};

RadioItemsWrapper.defaultProps = {
  columns: 4,
};

export default RadioItemsWrapper;
