/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import Modal from 'react-responsive-modal';
import { useHistory } from 'react-router-dom';

import { useAuth } from '@primaveralabs/auth';
import VerificationBanner, { PROFILE_FORM_ALERT } from '../HeaderPopUpBanners/VerificationBanner';

import { useRoutes, usePost } from '../../../context/routesContext';
import useResponsiveUtility from '../../../util/responsiveUtility';
import validateProfileForm from './util/formValidations/validateProfileForm';
import translateObj from '../../../util/translateObj';
import getTranslation from './util/translations/_getTranslation';
import localTranslation from './util/translations/ProfileFormMusicMeets';
import { LANG_OPTIONS, BUSINESS_OPTIONS } from './util/optionValues';
import useQueryString from '../../../hooks/useQueryString';
import { useBraze } from '../../../context/useBraze';

import profileUserEdit from '../../../api/graphql/mutations/profileUserEdit';

import HeadTitle from './HeadTitle';
import CollapsibleSection from './CollapsibleSection';
import FormField from './FormField';
import FormSelect from './FormSelect';
import FormSelectCountry from './FormSelectCountry';
import Button from './Button';
import SquareImage from './SquareImage';
import ProfilePreview from './ProfilePreview';
import Uploader from './Uploader';
import FormCheckbox from './FormCheckbox';
import { LoginButton } from './Agenda';
import SpotifyLogin from '../Login/SpotifyLogin/SpotifyLogin';

const MODAL_STYLES = {
  root: {
    width: '100vw',
    height: '100%',
    padding: 0,
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.9)',
    width: '100vw',
    height: '100%',
    padding: 0,
  },
  modalContainer: {
    width: '100vw',
    height: '100%',
    padding: 0,
  },
  modal: {
    background: 'rgba(0, 0, 0, 0)',
    boxShadow: 'none',
    width: '100vw',
    maxWidth: '100vw',
    height: '100%',
    padding: 0,
  },
};

const createMapItems = (options) => options?.map((option) => ([option?.name, { required: option.required, message: option.message }]));

const renderSocialMediaFields = (language, literals, deviceScreen) => (
  <>
    <div className="text-12 font-americaMonoRegular mt-4 uppercase sm:w-1/2 sm:mb-2">{translateObj(literals.labelSocial, language)}</div>
    <div className="flex flex-wrap w-full">
      {literals.optionsSocial.map((option, index) => (
        <div key={option?.name} className={`${index % 2 ? 'pl-3 sm:pl-2' : 'pr-3 sm:pr-2'} w-1/2`}>
          <FormField
            name={option?.name}
            type="url"
            placeholder={['sm', 'md'].includes(deviceScreen) ? option?.name : translateObj(option.placeholder, language)}
            icon={option?.name}
          />
        </div>
      ))}
    </div>
  </>
);

const ProfileFormMusicMeets = ({
  forceFieldValidation,
  hideCancelButton,
  hidePreview,
  hidePasswordEdit,
  hideLogoutButton,
  showSpotify,
  relink,
  translation,
  brazeAttribute,
}) => {
  const { language } = useRoutes();
  const { currentRolesAllowed } = usePost();
  const { deviceScreen } = useResponsiveUtility();
  const history = useHistory();
  const literals = getTranslation(translation, localTranslation);
  const { user, actions } = useAuth();
  const [userSocial, setUserSocial] = useState({ twitter: '', facebook: '', instagram: '', linkedin: '' });
  const { logCustomAttributes } = useBraze();
  const [showPreview, setShowPreview] = useState(false);
  const [previewValues, setPreviewValues] = useState({});
  const [uploadedImage, setUploadedImage] = useState(null);
  const [saved, setSaved] = useState(false);
  const [validationMissing, setValidationMissing] = useState(false);
  const ticketCode = useQueryString('code');

  const fieldValidationMap = forceFieldValidation ? new Map(createMapItems(forceFieldValidation)) : null;

  const { requiresCompleteRegistration } = currentRolesAllowed || {};

  const needsToValidateAccount = !!(requiresCompleteRegistration && user?.email && !user?.verified);

  // NOTE: If user has accepted conditions we remove the field validation
  if (user?.acceptedConditionsMusicMeets && fieldValidationMap?.get('acceptedConditionsMusicMeets')?.required) fieldValidationMap.set('acceptedConditionsMusicMeets', { required: false });

  const TWITTER_URL = 'https://twitter.com/';
  const FACEBOOK_URL = 'https://www.facebook.com/';
  const INSTAGRAM_URL = 'https://www.instagram.com/';
  const LINKEDIN_URL = 'https://www.linkedin.com/in/';

  const generateSocialUrl = (hardcodedUrl, userUrl) => {
    if (userUrl && userUrl.includes(hardcodedUrl)) {
      return userUrl;
    }
    return `${hardcodedUrl}${userUrl}`;
  };

  const handlePreview = (formValues) => {
    setPreviewValues({
      image: (uploadedImage ? uploadedImage.url : user?.imageUrl) || null,
      name: formValues.name || user?.name,
      lastname: formValues.lastname || user?.lastname,
      nickname: formValues.nickname || user?.nickname,
      business: formValues.business || user?.business,
      occupation: formValues.occupation || user?.occupation,
      company: formValues.company || user?.company,
      country: formValues.inputCountry || user?.inputCountry,
      website: formValues.url || user?.url,
      description: formValues.description || user?.description,
      twitter: formValues.twitter || userSocial.twitter ? generateSocialUrl(TWITTER_URL, userSocial.twitter) : '',
      facebook: formValues.facebook || userSocial.facebook ? generateSocialUrl(FACEBOOK_URL, userSocial.facebook) : '',
      instagram: formValues.instagram || userSocial.instagram ? generateSocialUrl(INSTAGRAM_URL, userSocial.instagram) : '',
      linkedin: formValues.linkedin || userSocial.linkedin ? generateSocialUrl(LINKEDIN_URL, userSocial.linkedin) : '',
    });
    setShowPreview(true);
  };

  const handleSubmit = (formValues, formProps) => {
    const values = { ...formValues };
    const social = [
      { name: 'Twitter', link: formValues.twitter ? generateSocialUrl(TWITTER_URL, formValues.twitter) : '' },
      { name: 'Facebook', link: formValues.facebook ? generateSocialUrl(FACEBOOK_URL, formValues.facebook) : '' },
      { name: 'Instagram', link: formValues.instagram ? generateSocialUrl(INSTAGRAM_URL, formValues.instagram) : '' },
      { name: 'LinkedIn', link: formValues.linkedin ? generateSocialUrl(LINKEDIN_URL, formValues.linkedin) : '' },
    ];
    delete values.twitter;
    delete values.facebook;
    delete values.instagram;
    delete values.linkedin;
    values.social = social;
    if (uploadedImage) {
      values.imageUrl = uploadedImage.url;
    }

    profileUserEdit({ ...values }).then((res) => {
      if (res?.isEdited && res?.token) {
        setSaved(true);
        actions.refresh({ token: res.token });
        if (brazeAttribute?.key) logCustomAttributes(brazeAttribute.key, brazeAttribute.value);
        if (needsToValidateAccount) setValidationMissing(true);
        else {
          const redirectParams = {
            pathname: relink,
          };

          if (ticketCode) redirectParams.search = `code=${ticketCode}`;

          setTimeout(() => {
            formProps.resetForm();
            if (relink) history.push(redirectParams);
          }, 1000);
        }
      }
    }).catch((err) => console.log(err));
  };

  useEffect(() => {
    if (window && typeof window !== 'undefined') {
      if (showPreview) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'auto';
      }
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [showPreview]);

  useEffect(() => {
    if (user?.social) {
      const social = { ...userSocial };

      user?.social?.forEach((item) => {
        if (item?.name === 'Twitter') {
          social.twitter = item?.link;
        } else if (item?.name === 'Facebook') {
          social.facebook = item?.link;
        } else if (item?.name === 'Instagram') {
          social.instagram = item?.link;
        } else if (item?.name === 'LinkedIn') {
          social.linkedin = item?.link;
        }
      });

      setUserSocial(social);
    }
  }, [user]);

  useEffect(() => {
    if (saved) {
      setTimeout(() => {
        setSaved(false);
      }, 1000);
    }
  }, [saved]);

  if (!user?.email) return <LoginButton translation={translation} />;

  return (
    <>
      <div className="w-full">
        <div className="flex items-center space-between">
          {literals.title && (
            <div className="flex-1">
              <HeadTitle title={translateObj(literals.title, language)} />
            </div>
          )}
          {
            hideLogoutButton
              ? null
              : (
                <Button
                  text={translateObj(literals.buttonLogout, language)}
                  className="w-min h-auto"
                  onClickCallback={() => actions.logout().then(() => {
                    localStorage.removeItem('auth-token');
                    history.push(`/${language}/pro-online`);
                  })}
                />
              )
          }
        </div>
        <Formik
          enableReinitialize
          initialValues={{
            name: user?.name || '',
            lastname: user?.lastname || '',
            city: user?.city || '',
            email: user?.email || '',
            inputCountry: user?.inputCountry || '',
            language: user?.language || '',
            password: '',
            repeatPassword: '',
            imageUrl: user?.imageUrl || '',
            nickname: user?.nickname || '',
            business: user?.business || '',
            occupation: user?.occupation || '',
            company: user?.company || '',
            url: user?.url || '',
            description: user?.description || '',
            twitter: userSocial.twitter || '',
            facebook: userSocial.facebook || '',
            instagram: userSocial.instagram || '',
            linkedin: userSocial.linkedin || '',
            acceptedConditionsMusicMeets: user?.acceptedConditionsMusicMeets || false,
          }}
          validate={(values) => validateProfileForm(values, fieldValidationMap)}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={(values, props) => handleSubmit(values, props)}
        >
          {(props) => {
            const { values, errors } = props;
            const hasErrors = errors && Object.keys(errors).length > 0;

            return (
              <Form noValidate className="mb-24 sm:mb-12">
                <div className="flex flex-row sm:flex-col sm:justify-start justify-between">
                  <CollapsibleSection
                    className={`${hidePasswordEdit ? 'w-full' : 'w-1/2'} sm:w-full mr-3 sm:mr-0`}
                    title={literals.titleLogin}
                  >
                    <div className="flex sm:flex-col pt-2 sm:pt-0">
                      <FormField
                        name="name"
                        label={literals.labelName}
                        errors={errors}
                        required
                        className="w-1/2 sm:w-full mr-3 sm:mr-0"
                      />
                      <FormField
                        name="lastname"
                        label={literals.labelLastname}
                        errors={errors}
                        required
                        className="w-1/2 sm:w-full ml-3 sm:ml-0"
                      />
                    </div>
                    <div className="flex sm:flex-col">
                      <FormSelectCountry
                        name="inputCountry"
                        label={literals.labelCountry}
                        errors={errors}
                        placeholder={translateObj(literals.placeholderCountry, language)}
                        required
                        className="w-1/2 sm:w-full mr-3 sm:mr-0 font-americaMonoRegular"
                      />
                      <FormField
                        name="city"
                        label={literals.labelCity}
                        errors={errors}
                        required
                        className="w-1/2 sm:w-full ml-3 sm:ml-0 font-americaMonoRegular"
                      />
                    </div>
                    <div className={user ? 'flex sm:flex-col' : ''}>
                      <FormSelect
                        name="language"
                        label={literals.labelLanguage}
                        placeholder={literals.placeholderLanguage}
                        errors={errors}
                        options={LANG_OPTIONS}
                        required
                        className={`w-1/2 sm:w-full mr-3 sm:mr-0 font-americaMonoRegular ${user && showSpotify ? '' : 'pr-3 sm:pr-0'}`}
                      />
                      {user && showSpotify && (
                        <div
                          className="w-1/2 sm:w-full ml-3 sm:ml-0 flex items-end sm:pt-6"
                        >
                          <SpotifyLogin
                            isLinkAccountButton
                            buttonText={translateObj(user.isSpotifyLinked ? literals.unlinkSpotify : literals.linkSpotify, language)}
                            revamp
                            className="max-h-46px text-ellipsis w-full rounded-full bg-inherit bg-spotify flex items-center justify-center text-white p-4 font-americaMonoBold uppercase text-14 tracking-px"
                          />
                        </div>
                      )}
                    </div>
                  </CollapsibleSection>
                  {hidePasswordEdit ? null : (
                    <CollapsibleSection className="w-1/2 sm:w-full ml-3 sm:ml-0" title={literals.titlePassword}>
                      <div className="flex sm:flex-col pt-2 sm:pt-0">
                        <FormField
                          name="password"
                          type="password"
                          label={literals.labelPassword}
                          errors={errors}
                          className="w-1/2 sm:w-full mr-3 sm:mr-0"
                          description={deviceScreen !== 'sm' ? literals.textPassword : null}
                        />
                        <FormField
                          name="repeatPassword"
                          type="password"
                          label={literals.labelRepeatPassword}
                          errors={errors}
                          className="w-1/2 sm:w-full ml-3 sm:ml-0"
                          description={deviceScreen === 'sm' ? literals.textPassword : null}
                        />
                      </div>
                    </CollapsibleSection>
                  )}
                </div>
                <CollapsibleSection
                  title={literals.titleProfile}
                  titleBig
                  rightButtonLabel={hidePreview ? null : literals.buttonPreview}
                  rightButtonCallback={hidePreview ? null : () => handlePreview(values)}
                >
                  <div className="w-1/2 md:w-2/3 sm:w-full flex items-end pt-2 sm:pt-0 pb-8 sm:pb-4">
                    <div className="w-1/3 sm:w-1/2 flex items-center justify-center">
                      <SquareImage image={(uploadedImage ? uploadedImage.url : user?.imageUrl) || ''} alt={user?.nickname || ''} />
                    </div>
                    <div className="w-full sm:w-1/2 ml-6 sm:ml-4">
                      <Uploader literals={literals} getImageCallback={(obj) => setUploadedImage(obj)} />
                      <div className="sm:hidden font-tiemposRegular text-12">{translateObj(literals.textImage, language)}</div>
                    </div>
                  </div>
                  <div className="hidden sm:block w-full font-tiemposRegular text-12 mb-6">{translateObj(literals.textImage, language)}</div>
                  <div className="flex sm:flex-col w-full">
                    <div className="w-1/2 sm:w-full pt-2 sm:pt-0 mr-3 sm:mr-0">
                      <FormField
                        name="company"
                        label={literals.labelCompany}
                        errors={errors}
                        className="w-full"
                        required={fieldValidationMap?.get('company')?.required}
                      />
                    </div>
                    <div className="w-1/2 sm:w-full pt-2 sm:pt-0 ml-3 sm:ml-0">
                      <div className="flex sm:flex-col">
                        <FormSelect
                          name="business"
                          label={literals.labelBusiness}
                          placeholder={literals.placeholderBusiness}
                          errors={errors}
                          options={BUSINESS_OPTIONS}
                          required={fieldValidationMap?.get('business')?.required}
                          className="w-1/2 sm:w-full mr-3 sm:mr-0 font-americaMonoRegular"
                        />
                        <FormField
                          name="occupation"
                          label={literals.labelOccupation}
                          errors={errors}
                          className="w-1/2 sm:w-full ml-3 sm:ml-0"
                          required={fieldValidationMap?.get('occupation')?.required}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex sm:flex-col w-full items-stretch">
                    <div className="w-1/2 sm:w-full flex-1 flex flex-col justify-between pt-2 sm:pt-0 mr-3 sm:mr-0">
                      <FormField
                        name="url"
                        type="url"
                        label={literals.labelUrl}
                      />
                      <div className="sm:hidden">
                        {renderSocialMediaFields(language, literals, deviceScreen)}
                      </div>
                    </div>
                    <FormField
                      name="description"
                      type="textarea"
                      textareaHeight="h-220px sm:h-100px"
                      label={literals.labelDescription}
                      className="w-1/2 sm:w-full flex-1 pt-2 ml-3 sm:ml-0"
                      labelClassName="w-full sm:w-2/3"
                    />
                    <div className="hidden sm:block">
                      {renderSocialMediaFields(language, literals, deviceScreen)}
                    </div>
                  </div>
                </CollapsibleSection>
                {hasErrors && (
                  <div className={`${needsToValidateAccount ? 'mb-4' : ''} text-pro-online font-timesRegular text-12 border border-pro-online p-5 rounded-lg sm:mb-8 w-full`}>
                    {translateObj(literals.errorMessage, language)}
                  </div>
                )}
                <div className="flex flex-col border-t border-black mt-10 pt-6">
                  {validationMissing && (
                    <div className="mb-6">
                      <VerificationBanner type={PROFILE_FORM_ALERT} translation={translation} />
                    </div>
                  )}
                  <div className="flex items-start justify-between content-start w-full sm:mt-0 sm:flex-col md:flex-row">
                    {user?.acceptedConditionsMusicMeets ? null : (
                      <FormCheckbox
                        name="acceptedConditionsMusicMeets"
                        type="checkbox"
                        label={literals.acceptedConditionsMusicMeets}
                        checked={values.acceptedConditionsMusicMeets}
                        errors={errors}
                        className="w-full sm:mb-8 tablet:flex-3 tablet:width-auto"
                        required={fieldValidationMap?.get('acceptedConditionsMusicMeets')?.required}
                      />
                    )}
                    <div className="flex justify-end flex-1 sm:w-full sm:justify-end md:flex-3 desktop:flex-2 md:width-auto">
                      {saved && <span className="sm:hidden font-americaRegular text-16 text-pro-online pr-8">{translateObj(literals.profileSaved, language)}</span>}
                      {hideCancelButton ? null : <Button text={translateObj(literals.buttonCancel, language)} color="outlineBlack" className="sm:mr-2" />}
                      <Button type="submit" text={translateObj(literals.buttonSubmit, language)} color="black" className=" ml-4 sm:ml-2" />
                    </div>
                  </div>
                </div>
                {saved && <span className="hidden sm:block font-americaRegular text-16 text-pro-online pt-6">{translateObj(literals.profileSaved, language)}</span>}
              </Form>
            );
          }}
        </Formik>
      </div>
      <Modal open={showPreview} onClose={() => setShowPreview(false)} showCloseIcon={false} styles={MODAL_STYLES} closeOnOverlayClick focusTrapped={false}>
        <ProfilePreview
          {...previewValues}
          closeCallback={() => setShowPreview(false)}
        />
      </Modal>
    </>
  );
};

ProfileFormMusicMeets.propTypes = {
  forceFieldValidation: PropTypes.bool,
  hideLogoutButton: PropTypes.bool,
  hideCancelButton: PropTypes.bool,
  hidePasswordEdit: PropTypes.bool,
  showSpotify: PropTypes.bool,
  hidePreview: PropTypes.bool,
  relink: PropTypes.string,
  translation: PropTypes.shape(),
  brazeAttribute: PropTypes.shape(),
};

ProfileFormMusicMeets.defaultProps = {
  forceFieldValidation: false,
  hideLogoutButton: false,
  hidePasswordEdit: false,
  showSpotify: true,
  hidePreview: false,
  hideCancelButton: false,
  relink: null,
  translation: null,
  brazeAttribute: null,
};

export default ProfileFormMusicMeets;
