/* eslint-disable quotes */
const translations = {
  wishlist: {
    stepsInstructionsText: {
      en: 'Select an artist for each category, use navigation arrows or click on each circle to switch',
      es: 'Selecciona un artista para cada categoría, utiliza las flechas de navegación o haz click en cada círculo para cambiar',
      ca: 'Selecciona un artista per a cada categoria, utilitza les fletxes de navegació o fes click a cada cercle per canviar',
    },
    spotifyDescription: {
      en: 'Link your Spotify account to enhance this experience and be able to make more specific recommendations',
      es: 'Vincula tu cuenta de Spotify para potenciar esta experiencia y poder hacerte recomendaciones más específicas',
      ca: 'Vincula el teu compte de Spotify per a potenciar aquesta experiència i poder fer-te recomanacions més específiques.',
    },
    spotifyLinkText: {
      en: 'Link your Spotify account',
      es: 'Vincula tu cuenta con Spotify',
      ca: 'Vincula el teu compte amb Spotify',
    },
    spotifySkipButton: {
      en: 'Continue without your Spotify account',
      es: 'Continuar sin tu cuenta de Spotify',
      ca: 'Continuar sense el teu compte de Spotify',
    },
    spotifySelectTopArtists: {
      en: 'Add an artist from your most listened to on Spotify',
      es: 'Añade un artista de tus más escuchados de Spotify',
      ca: 'Afegeix a un artista dels teus més escoltats a Spotify',
    },
    allStepsAnweredContinue: {
      en: 'Continue',
      es: 'Continuar',
      ca: 'Continuar',
    },
    searchBarPlaceholder: {
      en: 'Search artist or group to select',
      es: 'Busca el artista o el grupo para seleccionarlo',
      ca: `Busca l'artista o el grup per a seleccionar-lo`,
    },
    finalStepText: {
      en: 'This is your selection of artists',
      es: 'Esta es tu selección de artistas',
      ca: 'Aquesta és la teva selecció d’artistes',
    },
    finalStepGoBack: {
      en: 'Oops! I have changed my mind',
      es: 'Ups, prefiero cambiar de opinión',
      ca: `Ups, prefereixo canviar d'opinió`,
    },
    finalStepSubmit: {
      en: 'Submit',
      es: 'Enviar',
      ca: 'Enviar',
    },
    errorAnswerMissing: {
      en: 'Please answer all questions to continue',
      es: 'Contesta todas las preguntas para continuar',
      ca: 'Respon totes les preguntes per a continuar',
    },
    thanksPageText: {
      en: `Thank you for recommending us your favourite artists, we will take them into account for the next Primavera Sound line-up. If you still don't have your ticket for the next edition, get it now!`,
      es: `Gracias por recomendarnos a tus artistas favoritos, lo tendremos en cuenta para elaborar el próximo cartel de Primavera Sound. Si aún no tienes tu entrada para nuestra próxima edición, consíguela ahora!`,
      ca: 'Gràcies per recomanar-nos els teus artistes preferits, els tindrem en compte per a elaborar el pròxim cartell de Primavera Sound. Si encara no tens entrada per a la pròxima edició, aconsegueix-la ara!',
    },
    thanksPageTicketsButton: {
      en: 'Buy Tickets',
      es: 'Comprar Entradas',
      ca: 'Comprar Entrades',
    },
    thanksPageGoToHome: {
      en: 'Go back to Home',
      es: 'Volver a la Home',
      ca: 'Tornar a la Home',
    },
    alreadyParticipated: {
      en: 'You have already participated in this experience. Remember that this is your selection of artists:',
      es: 'Ya has participado en esta experiencia, recuerda esta es tu selección de artistas:',
      ca: `Ja has participat en aquesta experiència. Recorda que aquesta és la teva selecció d'artistes:`,
    },
  },
};

export default translations;
