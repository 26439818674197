const data = {
  type: 'HeaderMusicMeets',
  components: [
    {
      type: 'TopLogoContainerMusicMeets',
      name: 'logo',
      linkTo: '/pro-online',
      image: {
        name: 'Primavera Pro - music meets',
        size: {
          sm: 'https://s3-eu-west-1.amazonaws.com/assets.primaverasound.com/ps/images/logos/PRO20_online_2-13.svg',
          md: 'https://s3-eu-west-1.amazonaws.com/assets.primaverasound.com/ps/images/logos/PSPRO_logo_negro_fondo_no_bg.svg',
        },
      },
    },
    {
      type: 'TopHeaderMenuContainerMusicMeets',
      name: 'menuContainer',
      components: [
        {
          type: 'TopHeaderSubmenuMusicMeets',
          name: 'headerSubmenu',
          components: [
            {
              type: 'TopHeaderSubmenuItemsContainerMusicMeets',
              name: 'subMenuItems',
              params: [
                {
                  text: {
                    en: 'ABOUT',
                    es: 'NOSOTROS',
                    ca: 'NOSALTRES',
                    pt: 'ABOUT',
                  },
                  link: '/pro-online/about-pro-online',
                  key: 'about',
                },
                {
                  text: {
                    en: 'FAQS',
                    es: 'FAQS',
                    ca: 'FAQS',
                    pt: 'FAQS',
                  },
                  link: '/pro-online/primavera-pro-faqs',
                  key: 'faqs',
                },
                {
                  text: {
                    en: 'FORUM',
                    es: 'FORO',
                    ca: 'FÒRUM',
                    pt: 'FÓRUM',
                  },
                  link: '/pro-online/networking-zone-pro-2021', // provisional link
                  key: 'forum',
                },
                {
                  text: {
                    en: 'ARCHIVE',
                    es: 'ARCHIVO',
                    ca: 'ARXIU',
                    pt: 'ARQUIVO',
                  },
                  link: '/pro-online/archive-all-year',
                  key: 'archive',
                },
                {
                  text: {
                    en: 'CONTACT',
                    es: 'CONTACTO',
                    ca: 'CONTACTO',
                    pt: 'CONTACTO',
                  },
                  link: '/pro-online/pro-online-contact',
                  key: 'contact',
                },
              ],
            },
            {
              type: 'TopHeaderSubmenuItemsContainerMusicMeets',
              name: 'search',
              params: [
                {
                  text: {
                    en: 'SEARCH',
                    es: 'BUSCAR',
                    ca: 'CERCA',
                    pt: 'SEARCH',
                  },
                  link: '/search',
                  key: 'search',
                },
              ],
            },
          ],
        },
        {
          type: 'TopHeaderMainMenuMusicMeets',
          name: 'headerMenu',
          components: [
            {
              type: 'TopHeaderMainMenuItemsContainerMusicMeets',
              name: 'headerMenuItems',
              params: [
                {
                  text: {
                    en: 'LIVE NOW',
                    es: 'LIVE NOW',
                    ca: 'LIVE NOW',
                    pt: 'LIVE NOW',
                  },
                  link: '/', // missing link
                  key: 'live',
                },
                {
                  text: {
                    en: 'FESTIVALS',
                    es: 'FESTIVALES',
                    ca: 'FESTIVALS',
                    pt: 'FESTAS',
                  },
                  link: '/', // missing link
                  key: 'festivals',
                },
              ],
            },
            {
              type: 'TopHeaderMainMenuItemsContainerMusicMeets',
              name: 'loginMenuItems',
              params: [
                {
                  text: {
                    en: 'LOG IN',
                    es: 'INICIAR SESIÓN',
                    ca: 'INICIAR SESSIÓ',
                    pt: 'LOG IN',
                  },
                  link: '/login-screen-pro-online',
                  key: 'login',
                },
                {
                  text: {
                    en: 'PROFILE',
                    es: 'PERFIL',
                    ca: 'PERFIL',
                    pt: 'PERFIL',
                  },
                  link: '/user-profile-pro-online',
                  key: 'profile',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      type: 'MainMenuMusicMeets',
      name: 'mainMenuItems',
      params: [
        {
          text: {
            en: 'AGENDA',
            es: 'AGENDA',
            ca: 'AGENDA',
            pt: 'AGENDA',
          },
          link: '/', // missing link
          key: 'agenda',
        },
        {
          text: {
            en: 'ACCREDITED',
            es: 'ACREDITADOS',
            ca: 'ACREDITATS',
            pt: 'ACREDITADO',
          },
          link: '/', // missing link
          key: 'members',
        },
        {
          text: {
            en: 'MENTORING SESSIONS',
            es: 'MENTORING SESSIONS',
            ca: 'MENTORING SESSIONS',
            pt: 'MENTORING SESSIONS',
          },
          link: '/primavera-pro/mentoring-sessions',
          key: 'sessions',
        },
        {
          text: {
            en: 'MEETINGS',
            es: 'REUNIONES',
            ca: 'REUNIONS',
            pt: '',
          },
          link: '/', // missing link
          key: 'reunions',
        },
        {
          text: {
            en: 'FORUM',
            es: 'FORO',
            ca: 'FÒRUM',
            pt: 'FÓRUM',
          },
          link: '/', // missing link
          key: 'forum',
        },
      ],
    },
  ],
};

export default data;
