import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';
import imageModifier from './helpers/imageModifier';


const Arrow = () => <img className="w-2" src="https://assets.primaverasound.com/web/assets/icons/view_more_white.svg" alt="view more" />;
// const { isFavoritesActive } = process.env;
const isFavoritesActive = true;

const ArtistCard = ({
  name,
  image,
  slug,
  subtitle,
  top,
  match,
  favourite,
}) => (
  <RoutesContext.Consumer>
    {
        ({ language: contextLanguage, enteredFrom }) => {
          const localization = getLocalization(contextLanguage, enteredFrom, match);
          const trans = {
            knowMore: {
              ca: 'SABER MÁS',
              es: 'SABER MÁS',
              en: 'SABER MÁS',
            },
            add_favourites: {
              ca: 'Agregar a favoritos',
              es: 'Agregar a favoritos',
              en: 'Agregar a favoritos',
            },
            remove_favourites: {
              ca: 'Eliminar de favoritos',
              es: 'Eliminar de favoritos',
              en: 'Eliminar de favoritos',
            },
          };
          return (
            <div className="bg-black h-112 w-74 rounded artistCard " style={{ zIndex: 1000000000, top }}>
              <div className="relative">
                <img src={imageModifier(image, '336x220')} className="colorImg rounded" alt={name} />
                <div className="filter" />
                <h3 className="p-7 font-americaBlack text-18 absolute pin-b text-white">
                  {name}
                </h3>
              </div>
              {
                subtitle && <div className="text-white px-7  font-tiemposRegular text-14">{!subtitle[localization.language] ? subtitle.en : subtitle[localization.language]}</div>
              }
              <div className="m-7 pb-5 text-sm text-white font-americaMonoBold flex items-center border-b-1 border-white border-dashed">
                <Arrow />
                <span className="ml-4">
                  SABER MÁS
                </span>
              </div>
              {isFavoritesActive
                && (
                  <div className="mt-8 flex cursor-pointer text-white  items-center font-americaMonoBold text-12 justify-center uppercase">
                    {/* <Star className="h-4 mr-2" /> */}
                    {!trans.add_favourites[localization.language] ? trans.add_favourites.en : trans.add_favourites[localization.language]}
                  </div>
                )
              }
            </div>
          );
        }
      }
  </RoutesContext.Consumer>
);

export default ArtistCard;

ArtistCard.propTypes = {
  name: PropTypes.string.isRequired,
  subtitle: PropTypes.shape(),
  slug: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  top: PropTypes.number.isRequired,
};

ArtistCard.defaultProps = {
  subtitle: null,
};
