import React from 'react';
import PropTypes from 'prop-types';
import Link from '../../util/link';
import RoutesContext from '../../context/routesContext';

const FooterLogosContainer = ({
  name, mainParent, align = {}, logos,
}) => {
  const stylesContainer = (parent) => {
    let styles = '';

    if (parent === 'footer') {
      styles = 'justify-end xl:pr-4';
    } else if (parent === 'header') {
      styles = 'xl:mb-0 mt-6';
    }
    return styles;
  };

  return (
    <RoutesContext.Consumer>
      {({ language: contextLanguage }) => (
        <nav key={name} className={`flex sm:justify-around sm:w-full items-start w-1/2 sm:mb-0 ${mainParent === 'footer' ? 'mb-8' : 'mb-0'} ${align} ${stylesContainer(mainParent)}`}>
          {logos
          && logos.map((logo, index) => {
            if (logo.icon) {
              return (
                <Link
                  key={logo.name}
                  to={logo.link}
                  localizationpath={`/${contextLanguage}`}
                  aria-label={logo.name}
                >
                  <div
                    className={`text-40 ${
                      index + 1 < logos.length ? 'pr-4 ' : ''
                    }`}
                  >
                    <span className={`${logo.icon} text-black block`} />
                  </div>
                </Link>
              );
            }

            if (logo.image) {
              return (
                <Link
                  key={logo.name}
                  to={logo.link}
                  localizationpath={`/${contextLanguage}`}
                  aria-label={logo.name}
                >
                  <div
                    className={`pt-8px ${index + 1 < logos.length ? 'pr-4 ' : ''}`}

                    style={{ maxWidth: '96px' }}
                  >
                    <img src={logo.image} alt={logo.name} />
                  </div>
                </Link>
              );
            }

            return null;
          })}
        </nav>
      )}
    </RoutesContext.Consumer>
  );
};

FooterLogosContainer.propTypes = {
  name: PropTypes.string.isRequired,
  mainParent: PropTypes.string,
  align: PropTypes.string,
  logos: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
};

FooterLogosContainer.defaultProps = {
  align: null,
  mainParent: null,
};

export default FooterLogosContainer;
