/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';
import Link from '../../../util/link';

import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';
import translateObj from '../../../util/translateObj';

const SellerButton = ({
  text,
  url,
  margin,
  match,
  className,
  soldOut,
  category,
  active,
}) => (
  <RoutesContext.Consumer>
    {({ language: contextLanguage, enteredFrom }) => {
      const localization = getLocalization(contextLanguage, enteredFrom, match);

      let iconClass = 'font-icon ';
      let cat = category;
      switch (text && text.en.toLowerCase()) {
        case 'redtkt':
          iconClass += 'icon-redtkt-logo';
          break;
        case 'dice':
          iconClass += 'icon-dice text-3xl text-black';
          cat = 'dice-color';
          break;
        case 'eventbrite':
          iconClass += 'icon-eventbrite';
          break;
        case 'ticketmaster':
          iconClass += 'icon-ticketmaster-logo';
          break;
        case 'bol':
          iconClass = 'font-icon1 icon-bol-logo';
          cat = 'bol-color';
          break;
        case 'via verde':
          iconClass = 'font-icon1 icon-via-verde-logo text-2xl';
          cat = 'via-verde-color';
          break;
        default:
          iconClass = null;
          break;
      }

      if (soldOut) cat = 'white-dark';

      const Button = () => (
        <div
          className={`text-white flex flex-col justify-center items-center w-full ${
            soldOut ? 'cursor-not-allowed' : 'cursor-pointer'
          }`}
        >
          {iconClass ? (
            <span className={iconClass} />
          ) : (
            translateObj(text, localization.language)
          )}
        </div>
      );

      return (
        <React.Fragment>
          {soldOut ? (
            <span
              style={{ width: '100%' }}
              className={
                  className
                    ? `${className}`
                    : `flex rounded-full uppercase text-base sm:text-12 md:text-12 text-center font-americaMonoBold flex-col justify-center block ${margin} bg-${cat} ${
                      !active ? 'bg-red' : ''
                    } border-2 w-full ${
                      cat === 'transparent' ? 'border-white' : `border-${cat}`
                    } xl:h-12 lg:h-12 h-38px`
                }
              tabIndex="0"
            >
              <Button />
            </span>
          ) : (
            <Link
              aria-label={translateObj(text, localization.language)}
              role="button"
              to={url}
              localizationpath={localization.path}
              className={
                    className
                      ? `${className}`
                      : `flex flex-col justify-center rounded-full uppercase text-base sm:text-12 md:text-12 text-center font-americaMonoBold block ${margin} bg-${cat} border-2 ${!active
                      && 'bg-red'} w-full ${
                        cat === 'transparent' ? 'border-white' : `border-${cat}`
                      } xl:h-12 lg:h-12 h-38px`
                  }
            >
              <Button />
            </Link>
          )}
        </React.Fragment>
      );
    }}
  </RoutesContext.Consumer>
);

SellerButton.propTypes = {
  category: PropTypes.string,
  margin: PropTypes.string,
  match: PropTypes.shape().isRequired,
  text: PropTypes.shape().isRequired,
  url: PropTypes.string,
  className: PropTypes.string,
  soldOut: PropTypes.bool,
  active: PropTypes.bool,
};

SellerButton.defaultProps = {
  category: 'black',
  margin: '',
  className: '',
  url: null,
  soldOut: false,
  active: true,
};

export default withRouter(SellerButton);
