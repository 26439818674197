import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ComponentIterator from '../../../containers/ComponentIterator';

class RecordsPostPage extends Component {
  componentDidMount = () => { }

  render() {
    const { children, components } = this.props;

    return (
      <ComponentIterator components={components} />
    );
  }
}

RecordsPostPage.propTypes = {
  children: PropTypes.shape().isRequired,
};

RecordsPostPage.defaultProps = {

};

export default RecordsPostPage;
