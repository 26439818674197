const translation = {
  labelPending: {
    en: 'Waiting confirmation',
    es: 'Esperando confirmación',
    ca: 'Esperant confirmació',
    pt: 'Aguardando confirmação',
  },
  labelConfirmed: {
    en: 'Confirmed',
    es: 'Confirmada',
    ca: 'Confirmada',
    pt: 'Confirmado',
  },
  labelCancelled: {
    en: 'Cancelled',
    es: 'Cancelada',
    ca: 'Cancel·lat',
    pt: 'Cancelada',
  },
  labelRejected: {
    en: 'Declined',
    es: 'Rechazada',
    ca: 'Rebutjada',
    pt: 'Recusada',
  },
  labelCreatedBy: {
    en: 'Invited by',
    es: 'Te invita',
    ca: 'Et convida',
    pt: 'Convida você',
  },
  labelParticipants: {
    en: 'Guests',
    es: 'Invitados',
    ca: 'Convidats',
    pt: 'Convidados',
  },
  labelOtherParticipants: {
    en: 'Other guests',
    es: 'Otros invitados',
    ca: 'Altres convidats',
    pt: 'Outros convidados',
  },
  labelConfirmedParticipants: {
    en: 'Attending',
    es: 'Asisten',
    ca: 'Assisteixen',
    pt: 'Atendem',
  },
  labelDescription: {
    en: 'Description',
    es: 'Descripción',
    ca: 'Descripció',
    pt: 'Descrição',
  },
  buttonLink: {
    en: 'Link to the meeting',
    es: 'Enlace a la reunión',
    ca: 'Enllaç a la reunió',
    pt: 'Link para a reunião',
  },
  buttonInvite: {
    en: 'Invite accredited',
    es: 'Invitar acreditados',
    ca: 'Convidar acreditats',
    pt: 'Convite credenciado',
  },
  buttonReprogram: {
    en: 'Reschedule',
    es: 'Reprogramar',
    ca: 'Reprogramar',
    pt: 'Reprogramar',
  },
  buttonCancel: {
    en: 'Cancel',
    es: 'Cancelar',
    ca: 'Cancel·lar',
    pt: 'Cancelar',
  },
  buttonDelete: {
    en: 'Delete',
    es: 'Eliminar',
    ca: 'Esborrar',
    pt: 'Excluir',
  },
  buttonAccept: {
    en: 'Accept',
    es: 'Aceptar',
    ca: 'Acceptar',
    pt: 'Aceitar',
  },
  buttonAcceptInvitation: {
    en: 'Accept invitation',
    es: 'Aceptar invitación',
    ca: 'Acceptar invitació',
    pt: 'Aceitar convite',
  },
  buttonDecline: {
    en: 'Decline',
    es: 'Rechazar',
    ca: 'Rebutjar',
    pt: 'Rejeitar',
  },
  buttonDeclineInvitation: {
    en: 'Decline invitation',
    es: 'Rechazar invitación',
    ca: 'Rebutjar invitació',
    pt: 'Rejeitar convite',
  },
  cancellationTitle: {
    en: 'Cancel meeting',
    es: 'Cancelar reunión',
    ca: 'Cancel·lar reunió',
    pt: 'Cancelar reunião',
  },
  cancellationText: {
    en: 'Do you want to cancel this meeting? Tell your guests what\'s the reason of cancellation:',
    es: '¿Quieres cancelar esta reunión? Dile a tus invitados cuál es el motivo de la cancelación:',
    ca: 'Vols cancel·lar la reunió? Expliqueu als vostres convidats quin és el motiu de la cancel·lació:',
    pt: 'Quer cancelar esta reunião? Diga aos seus convidados qual é o motivo do cancelamento:',
  },
  buttonSendMessageCancel: {
    en: 'Send message and cancel meeting',
    es: 'Enviar mensaje y cancelar reunión',
    ca: 'Enviar missatge i cancel·lar reunió',
    pt: 'Enviar mensagem e cancelar reunião',
  },
  acceptInvitationText: {
    en: 'Do you want to confirm your attendance at this meeting? The host will receive a notification email.',
    es: '¿Quieres confirmar tu asistencia a esta reunión? El anfitrión recibirá un email de notificación.',
    ca: 'Vols confirmar la teva assistència a aquesta reunió? L\'amfitrió rebrà un correu electrònic de notificació.',
    pt: 'Deseja confirmar sua presença nesta reunião? O host receberá um e-mail de notificação.',
  },
  rejectInvitationText: {
    en: 'Do you want to decline the invitation to this meeting? This action can not be undone.',
    es: '¿Quieres rechazar la invitación a esta reunión? Esta acción no se puede deshacer.',
    ca: 'Vols rebutjar la invitació a aquesta reunió? Aquesta acció no es pot desfer.',
    pt: 'Deseja recusar o convite para esta reunião? Essa ação não pode ser desfeita.',
  },
  deleteText: {
    en: 'Do you want to delete this meeting from your list? This action can not be undone.',
    es: '¿Quieres eliminar esta reunión de tu lista? Esta acción no se puede deshacer.',
    ca: 'Vols suprimir aquesta reunió de la teva llista? Aquesta acció no es pot desfer.',
    pt: 'Deseja remover esta reunião da sua lista? Essa ação não pode ser desfeita.',
  },
};

export default translation;
