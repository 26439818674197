import React from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';
import translateObj from '../../../util/translateObj';

const ParagraphLine = ({
  align, text, color, match,
}) => (
  <RoutesContext.Consumer>
    {({ language: contextLanguage, enteredFrom }) => {
      const localization = getLocalization(contextLanguage, enteredFrom, match);
      return (
        <p className={`${align} font-tiemposRegular text-base md:text-sm sm:text-xs mb-4 text-${color}`}>
          {translateObj(text, localization.language)}
        </p>
      );
    }}
  </RoutesContext.Consumer>
);


ParagraphLine.propTypes = {
  color: PropTypes.string,
  align: PropTypes.string,
  text: PropTypes.shape({
    es: PropTypes.string.isRequired,
    en: PropTypes.string.isRequired,
    ca: PropTypes.string.isRequired,
  }).isRequired,
};

ParagraphLine.defaultProps = {
  align: 'align-left',
  color: 'text-white',
};

export default ParagraphLine;
