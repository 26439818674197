import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';
import slugify from '../../../util/slugify';
import translateObj from '../../../util/translateObj';

const FilterButton = ({
  match, checkedItems, filterType, filters, onHandleButtonSelect, radioItem, params: { text, value, category },
}) => (
  <RoutesContext.Consumer>
    {({ language: contextLanguage, enteredFrom }) => {
      const localization = getLocalization(contextLanguage, enteredFrom, match);
      const categoryUpdated = slugify(category);
      let classNames = 'uppercase text-xs font-americaMonoRegular rounded-lg mr-2 mb-2 shadow-white py-2 px-4';
      if ((Object.keys(filters).length && filters.byEvent && filters.byEvent.includes(value)) || (Object.keys(filters).length && filters.byTag && filters.byTag.includes(value)) || (checkedItems && checkedItems.includes(value))) {
        classNames += ` text-black font-black bg-${categoryUpdated} shadow-none`;
      } else if (radioItem === category || radioItem === value) {
        classNames += ` text-black font-black bg-${categoryUpdated} shadow-none`;
      } else {
        classNames += ' text-white';
      }
      return (
        <button onClick={() => onHandleButtonSelect(value, filterType)} type="button" className={`${classNames} cursor-pointer`}>
          {translateObj(text, localization.language)}
        </button>
      );
    }
}
  </RoutesContext.Consumer>
);

FilterButton.propTypes = {
  params: PropTypes.shape({
    text: PropTypes.shape({
      en: PropTypes.string.isRequired,
    }).isRequired,
    category: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  onHandleButtonSelect: PropTypes.func.isRequired,
  filterType: PropTypes.string,
  filters: PropTypes.shape({}),
  radioItem: PropTypes.string,
  checkedItems: PropTypes.arrayOf(PropTypes.string),
};

FilterButton.defaultProps = {
  radioItem: '',
  filters: {},
  filterType: '',
  checkedItems: null,
};

export default withRouter(FilterButton);
