import React from 'react';
import PropTypes from 'prop-types';
import Banner from '@primaveralabs/ui-banner';
import 'Primavera/ui-themes/dist/primavera/index.css';
import 'Primavera/ui-panel/dist/primavera.css';
import 'Primavera/ui-banner/dist/primavera.css';
import 'Primavera/ui-scoreboard/dist/primavera.css';
import translateObj from '../../../util/translateObj';
import { useRoutes } from '../../../context/routesContext';
import { LinkWrapper } from '../../../util/link';

const AdBanner = ({
  publicidad,
}) => {
  const { language, path } = useRoutes();

  const isExternalUrl = publicidad.link.includes('http');

  return (
    isExternalUrl ? (
      <Banner
        image={translateObj(publicidad.image, language)}
        link={publicidad.link}
        linkTarget="_blank"
      />
    )
      : (
        <LinkWrapper url={publicidad.link} path={path}>
          <Banner
            image={translateObj(publicidad.image, language)}
          />
        </LinkWrapper>
      )
  );
};

AdBanner.propTypes = {
  publicidad: PropTypes.shape({
    image: PropTypes.shape({}),
    link: PropTypes.string,
  }),
};

AdBanner.defaultProps = {
  publicidad: {
    image: { en: '' },
    link: '',
  },
};

export default AdBanner;
