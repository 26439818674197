import React from 'react';

import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';

import ListItemText from '@material-ui/core/ListItemText';

import List from '@material-ui/core/List';
import ComponentIterator from '../../containers/ComponentIterator';


const getStyle = (selected) => {
  if (selected) {
    return {
      color: 'white', backgroundColor: 'black', fontSize: '0.75rem', marginBottom: '0.5rem', lineHeight: '1px',
    };
  }
  return {
    backgroundColor: 'white', marginBottom: '0.5rem', fontSize: '0.75rem', lineHeight: '1px',
  };
};


class SideMenu extends React.PureComponent {
  constructor(props) {
    super(props);
    const { params } = this.props;
    this.state = {
      selected: params[0],
    };
  }

  handleClick = (selected) => {
    this.setState({ selected });
  };


  render() {
    const { params } = this.props;
    const { selected } = this.state;


    return (
      <div className="flex">
        <div className="mx-4">
          <List disablePadding className="lg:w-48 xl:w-64 m-4">
            {params.map(param => (
              <React.Fragment key={param.text.en}>
                <ListItem

                  className="h-16 pb-4 my-4 mt-8"
                  style={getStyle(selected.text.en === param.text.en)}
                  value={param.text.en}
                  button
                  onClick={() => this.handleClick(param)}
                >
                  <ListItemText
                    primaryTypographyProps={{ style: { lineHeight: 1 } }}
                    primary={(
                      <span style={getStyle(selected.text.en === param.text.en)}>
                        {param.text.en.toUpperCase()}
                      </span>
                )}
                  />


                </ListItem>
              </React.Fragment>
            ))}
          </List>

        </div>
        <div>
          <ComponentIterator components={selected.content} />

        </div>
      </div>
    );
  }
}

SideMenu.propTypes = {
  classes: PropTypes.shape({}),
  params: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.shape({
      en: PropTypes.string.isRequired,
      es: PropTypes.string.isRequired,
      content: PropTypes.object,
    }).isRequired,
  })),

};

export default SideMenu;
