import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SendIcon from '../svg/SendIcon';
import Loader from '../Loader';

const ChatConversation = ({ messages, noItemsMessages, myId, sendMessageCallback, loadingMessages }) => {
  const [newMessage, setNewMessage] = useState('');

  const handleSendMessage = () => {
    sendMessageCallback(newMessage);
    setNewMessage('');
    const inputEl = document.getElementById('message-box');
    if (inputEl.value !== '') {
      inputEl.value = '';
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSendMessage();
    }
  };

  useEffect(() => {
    const containerEl = document.getElementById('chat-box');
    containerEl.scrollTop = containerEl.scrollHeight;
  }, [messages]);

  return (
    <div className="h-full flex flex-col items-stretch">
      <div id="chat-box" className="bg-grayE2DDDB rounded-lg mb-4 p-4 overflow-y-scroll h-full">
        {loadingMessages
          ? (
            <div className="w-full h-full flex items-center justify-center"><Loader label={false} className="justify-center" /></div>
          )
          : (
            <div>
              {messages.length === 0
                ? <span className="w-full text-center">{noItemsMessages}</span>
                : (
                  <div className="font-tiemposRegular">
                    {messages.map((message) => (
                      <div key={message.id} className={`w-full flex ${message._sender.userId === myId ? 'justify-end' : 'justify-start'} mb-3`}>
                        <span className={`px-4 py-3 rounded-lg ${message._sender.userId === myId ? 'bg-white-dark' : 'bg-white'}`} style={{ width: 'max-content' }}>
                          <p>{message.message}</p>
                        </span>
                      </div>
                    ))}
                  </div>
                )}
            </div>
          )}
      </div>
      <div className="bg-grayE2DDDB rounded-lg px-4 py-3 flex items-stretch justify-between flex-none">
        <input
          id="message-box"
          className="w-full bg-transparent font-tiemposRegular"
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyDown={(e) => handleKeyDown(e)}
        />
        <button
          type="button"
          className="ml-4 flex-none bg-black p-2 rounded-lg"
          onClick={() => handleSendMessage()}
        >
          <div className="h-6 w-6"><SendIcon /></div>
        </button>
      </div>
    </div>
  );
};

export default ChatConversation;

ChatConversation.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.shape()),
  noItemsMessages: PropTypes.string.isRequired,
  myId: PropTypes.string.isRequired,
  sendMessageCallback: PropTypes.func.isRequired,
  loadingMessages: PropTypes.bool,
};

ChatConversation.defaultProps = {
  messages: [],
  loadingMessages: false,
};
