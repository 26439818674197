import React from 'react';

const FacebookIcon = () => (
  <svg width="100%" height="100%" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m7.4464315 24v-12.0014051h3.3129126l.4390325-4.13575071h-3.7519451l.00562857-2.06998303c0-1.07866742.10248787-1.65664209 1.65176582-1.65664209h2.07109811v-4.13621907h-3.31338168c-3.97990431 0-5.38072746 2.00628403-5.38072746 5.38022289v2.48308969h-2.48081486v4.13575072h2.48081486v12.0009367z"
      fill="#000000"
      transform="translate(.265625 .75)"
    />
  </svg>
);

export default FacebookIcon;
