import React from 'react';
import { PropTypes } from 'prop-types';

const ChannelListItem = ({
  onChannelSelect, channel,
}) => (
  <div className="flex relative uppercase text-sm font-americaMonoRegular px-4 h-8  py-5 justify-between items-center bg-psworld-box text-white border-b border-black cursor-pointer hover:bg-black trans">
    {/* <button className="h-8 flex items-center text-sm text-white hover:text-psworld-grey trans" onClick={handleLeaveChannel} type="button">
        <span className="font-icon1 icon-deny" />
      </button> */}
    <div className="flex-1 flex justify-between" onClick={onChannelSelect}>
      <span className="text-center">{channel.name}</span>
      <span>{'>'}</span>
    </div>
  </div>
);

ChannelListItem.propTypes = {
  onChannelSelect: PropTypes.func,
  channel: PropTypes.shape({}).isRequired,
};

ChannelListItem.defaultProps = {
  onChannelSelect: () => {},
};

export default ChannelListItem;
