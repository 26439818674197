import gql from 'graphql-tag';

import { clientWeb, persistor } from '../graphqlAPI';

const GET_REGISTER_PREFERENCES_DATA = gql`
  query getRegisterPreferencesData($search: String, $from: Int, $to: Int, $random: Boolean, $randomList: [String], $artists: [String]) {
    getRegisterPreferencesData(search: $search, from: $from, to: $to, random: $random, randomList: $randomList, artists: $artists) {
      topArtists {
        name
        image
        isSpotifyArtist
        spotifyId
        slug
      }
    }
  }
`;

const executeQuery = async (search, from, to, random, randomList, artists) => {
  const result = await clientWeb.query({
    query: GET_REGISTER_PREFERENCES_DATA,
    variables: {
      search,
      from,
      to,
      random,
      randomList,
      artists,
      fetchDate: new Date().getTime().toString(),
    },
    fetchPolicy: 'no-cache',
  })
    .then((queryResult) => queryResult)
    .catch((queryError) => {
      const errorMessage = `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error: ${queryError}`;
      console.log('\x1b[31m%s\x1b[0m', errorMessage);

      return null;
    });

  if (result.data && result.data.getRegisterPreferencesData) {
    const { data: { getRegisterPreferencesData } } = result;
    return getRegisterPreferencesData;
  }

  return [];
};

const getRegisterPreferencesData = async ({ search, from, to, random, randomList, artists }) => {
  try {
    const JSONObject = await executeQuery(search, from, to, random, randomList, artists);

    if (persistor) {
      persistor.persist();
    }

    return JSONObject;
  } catch (jsonError) {
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: JSON Object Error: ${jsonError}`);

    if (persistor) {
      const persistorStatus = await persistor.restore().then(() => 'OK');
      console.log(`${new Date().toLocaleTimeString()} ~ Client: Apollo Cache Restored - ${persistorStatus}`);

      try {
        const JSONObject = await executeQuery('cache');

        return JSONObject;
      } catch (jsonErrorAfterCacheRestore) {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: JSON Object Error after Cache Restore, sending 404: ${jsonErrorAfterCacheRestore}`);
        return null;
      }
    }
    return null;
  }
};

export default getRegisterPreferencesData;
