import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';
import translateObj from '../../util/translateObj';

import Separator from './Separator';
import NormalBodyText from './Text/NormalBodyText';

const InstructionsSimpleUploadLogo = ({
  paragraph, includeSeparator, logoUrl, background, name, title, match,
}) => (
  <RoutesContext.Consumer>
    {({ language: contextLanguage, enteredFrom }) => {
      const imgSize = background === 'white' ? '6rem' : '8rem';
      const localization = getLocalization(contextLanguage, enteredFrom, match);
      return (
        <div className={`md:flex lg:flex xl:flex mb-8 font-tiemposRegular items-start flex-none sm:mt-0 ${background === 'white' && 'bg-white py-8 px-12 rounded-lg'}`}>
          <div className="md:w-1/5 lg:w-1/5 xl:w-1/5 self-start sm:mt-6 block pt-4 md:mr-4">
            {/* {icon && <span className={`font-icon text-110 text-black ${icon}`} style={{ maxWidth: imgSize }} />} */}
            {logoUrl && (
              // <div
              //   style={{
              //     backgroundImage: `url(${logoUrl})`,
              //     backgroundPosition: 'center',
              //     backgroundSize: 'cover',
              //     backgroundRepeat: 'no-repeat',
              //     width: '100%',
              //     maxWidth: imgSize,
              //   }}
              // />
              <img src={logoUrl} style={{ maxWidth: '125px', maxHeight: '125px' }} alt="icon-logo" />
            )}
            {name === 'instructionText' && (
              <p className="font-americaMonoBold text-14 uppercase mt-0" style={{ marginBottom: '4rem' }}>
                {translateObj(title, localization.language)}
              </p>
            )}
          </div>
          <div className={`${background === 'transparent' ? ' md:w-4/5 lg:w-4/5 xl:w-4/5' : 'sm:w-full sm:pl-0 sm:mt-6 w-4/5 pl-12'} ${!logoUrl && !title && 'mt-6 xl:ml-48 md:w-full lg:w-full'}`}>
            {includeSeparator && <Separator borderWidth={1.0} hideOnMobile={false} paddingBottom />}
            <NormalBodyText text={paragraph} classes="text-sm" />
          </div>
        </div>
      );
    }}
  </RoutesContext.Consumer>
);

InstructionsSimpleUploadLogo.propTypes = {
  match: PropTypes.shape().isRequired,
  paragraph: PropTypes.shape({}),
  logoUrl: PropTypes.string,
  background: PropTypes.oneOf(['white', 'transparent']),
  name: PropTypes.string,
  title: PropTypes.shape({
    es: PropTypes.string,
    en: PropTypes.string,
    ca: PropTypes.string,
  }),
  includeSeparator: PropTypes.bool,
};

InstructionsSimpleUploadLogo.defaultProps = {
  background: 'transparent',
  name: '',
  logoUrl: '',
  title: {},
  paragraph: {},
  includeSeparator: false,
};

export default withRouter(InstructionsSimpleUploadLogo);
