import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import userPasswordRecoverUserSendsNewPassword from '../../api/graphql/mutations/userPasswordRecoverUserSendsNewPassword';

// import getLocalization from '../../util/localization';
// import RoutesContext from '../../context/routesContext';
import { useRoutes } from '../../context/routesContext';
import { UserProfileContextConsumer, UserProfileContext, useUser } from '../../context/userProfileContext';

import translateObj from '../../util/translateObj';

// !!! pending improving design in all sizes...


const RecoverPasswordEnterNewPassword = ({
  title, sourceWeb, emailTitle, emailTitle2, submitTitle, passwordUpdated, history, match, location: { search }, errorsText: { errorTooShort, errorFirstPasswordFirst, errorPasswordsDoNotMatch },
}) => {
  const { language = 'en' } = useRoutes(match);
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [isNewPasswordSubmited, setIsNewPasswordSubmited] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [error1, setError1] = useState('');
  const [error2, setError2] = useState('');
  const [token, setToken] = useState('');
  const [email, setEmail] = useState('');
  const user = useUser();

  const handleChange = (e) => {
    if (e.target.name === 'password') {
      setPassword(e.target.value);
    } else {
      setPassword2(e.target.value);
    }
  };

  useEffect(() => {
    if (!password) {
      if (error1) {
        setError1('');
      }
    }
  }, [password]);
  useEffect(() => {
    if (!password2) {
      if (error2) {
        setError2('');
      }
    }
  }, [password2]);

  useEffect(() => {
    if (!error1 && !error2 && password && password2) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }
  }, [error1, error2, password2, password]);

  useEffect(() => {
    if (isNewPasswordSubmited) {
      const redirectTo = sourceWeb ? `/${language}/${sourceWeb}` : '/';
      setTimeout(() => {
        history.push(redirectTo);
      }, 2500);
    }
  }, [isNewPasswordSubmited]);


  const validateIfPasswordIsCorrect = (secondaryPassword, language) => {
    if (!secondaryPassword) {
      if (password.length < 6) {
        // const err1 = 'Your password is too short';
        const err1 = translateObj(errorTooShort, language);
        setError1(err1);
      } else {
        setError1('');
      }
    } else {
      let err2;
      if (!password) {
        // err2 = 'Please provide the main password first';
        err2 = translateObj(errorFirstPasswordFirst, language);
      } else if (password !== password2) {
        // err2 = 'Please double check, your passwords do not match';
        err2 = translateObj(errorPasswordsDoNotMatch, language);
      } else {
        err2 = '';
      }
      setError2(err2);
    }
  };

  const getQueryVariable = (query) => {
    const vars = query.split('&');
    const queryvars = [];
    for (let i = 0; i < vars.length; i++) {
      const index = vars[i].indexOf('=');
      const item = vars[i].substring(index + 1);
      queryvars.push(item);
    }
    if (queryvars.length) {
      return queryvars;
    }
    return false;
  };
  // prepare info from search query string on load
  useEffect(() => {
    const queryValues = getQueryVariable(search);
    const [code, emailFromUrl] = queryValues;
    setToken(code);
    setEmail(emailFromUrl);
  }, []);

  const sendNewPassword = () => {
    if (email && token) {
      userPasswordRecoverUserSendsNewPassword({ email, code: token, password }).then((res) => {
        if (res) {
          if (res.isValid) {
            user.setNewAuthToken(res.token);
            setIsNewPasswordSubmited(true);
          } else if (res.error) {
            switch (res.error) {
              case 'userCouldNotBeUpdatedinDB':
                setError2('user not update, please try again later');
                break;
              case 'noUserFound':
                setError2('User not found, please try again later');
                break;

              default:
                setError2('Something went wrong, please try again later');
                break;
            }
          }
        } else {
          console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Error - recover password send new password failed to submit!`);
          setError2('Something went wrong, please try again later');
        }
      });
    } else {
      setError2('Something went wrong with the query string, please try again later');
    }

    // setIsNewPasswordSubmited(true);
  };

  const enterDoublePasswordToUpdateUser = () => (
    <div>
      {!isNewPasswordSubmited ? (
        <div className="flex flex-wrap bg-white sm:justify-center">

          <div className={`${'w-full p-8'} `}>
            <div className="flex border-b-1 border-black border-dotted py-6">
              <p className="font-americaMonoBold m-0">
                {translateObj(title, language)}
              </p>
            </div>
            <div className="flex w-full py-8 sm:w-auto flex-wrap flex-col">
              <div className="flex-row justify-between mt-4">
                <div className="inline-block w-full">
                  <div className="flex flex-col">
                    <label className="flex-col pr-6 font-americaMonoRegular text-12 sm:pr-0 sm:w-full pb-4">
                      {translateObj(emailTitle, language)}
                    </label>
                    <input type="password" name="password" onChange={handleChange} className="px-6 mr-4 sm:mr-0 font-americaMonoRegular text-12 rounded-full border-1 p-4 bg-inherit border-black text-black text-12 sm:w-full w-120" value={password} onBlur={() => { validateIfPasswordIsCorrect(false, language); }} />
                    {error1 && <span className="text-red mt-4">{error1}</span>}
                    <label className="flex-col pr-6 font-americaMonoRegular text-12 sm:pr-0 sm:w-full py-4">
                      {translateObj(emailTitle2, language)}
                    </label>
                    <input type="password" name="password2" onChange={handleChange} className="px-6 mr-4 sm:mr-0 font-americaMonoRegular text-12 rounded-full border-1 p-4 bg-inherit border-black text-black text-12 sm:w-full w-120" value={password2} onBlur={() => { validateIfPasswordIsCorrect(true, language); }} />
                    {error2 && <span className="text-red mt-4">{error2}</span>}
                    <button
                      onClick={() => sendNewPassword()}
                      type="submit"
                      disabled={!canSubmit}
                      className={`rounded-full ${canSubmit ? 'bg-black text-white' : 'bg-gray200 text-black cursor-not-allowed	'} text-12 font-americaMonoBold sm:static mt-6 sm:mx-0 sm:w-full cursor-pointer text-center sm:w-full w-120`}
                      style={{
                        minHeight: '3rem',
                      }}
                    >
                      {translateObj(submitTitle, language)}
                    </button>
                  </div>
                </div>

              </div>

            </div>
            <div />
          </div>
        </div>
      )
        : (
          <div className="font-americaMonoBold uppercase">
            {translateObj(passwordUpdated, language) || 'your password has been updated'}
          </div>
        )
      }
    </div>
  );


  return (
    <UserProfileContextConsumer>
      {({ token }) => (
        <>
          {enterDoublePasswordToUpdateUser()}
        </>
      )}
    </UserProfileContextConsumer>
  );
};

RecoverPasswordEnterNewPassword.propTypes = {
  match: PropTypes.shape().isRequired,
  text: PropTypes.shape({}),
  userTitle: PropTypes.shape({}),
  submitTitle: PropTypes.shape({}),
  sourceWeb: PropTypes.string,
};

RecoverPasswordEnterNewPassword.defaultProps = {
  text: null,
  userTitle: null,
  submitTitle: null,
  sourceWeb: '',
};


export default withRouter(RecoverPasswordEnterNewPassword);
