import * as React from 'react';
import { PropTypes } from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

// eslint-disable-next-line react/jsx-filename-extension
export const LinkWrapper = ({ url, path, children, ...props }) => (url ? <Link to={url} localizationpath={path} {...{ ...props }}>{children}</Link> : children);

LinkWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  path: PropTypes.string,
  url: PropTypes.string,
};

LinkWrapper.defaultProps = {
  path: null,
  url: null,
};

class Link extends React.Component {
  render() {
    const { to, localizationpath = '/en', ...props } = this.props;
    return /^https?:\/\//.test(to)
      ? <a href={to} {...props} rel="noopener noreferrer" target="_blank" />
      : <RouterLink {...this.props} to={`${localizationpath}${to}`} />;
  }
}

export default Link;
