import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ComponentIterator from '../../../containers/ComponentIterator';

class IteratorOnly extends Component {
  state = {
  };

  componentDidMount() {
  }

  componentDidUpdate() {
  }

  render() {
    const { components } = this.props;

    return (
      <ComponentIterator components={components} />
    );
  }
}

IteratorOnly.propTypes = {
  components: PropTypes.arrayOf(PropTypes.object),
};

IteratorOnly.defaultProps = {
  components: null,
};

export default IteratorOnly;
