import React from 'react';
import { PropTypes } from 'prop-types';

// import StreamingVideoPlayerMainPlayerAll from '../StreamingVideoPlayerMainPlayerAll';
// import StreamingPlayerPictureSmall from '../StreamingPlayerPictureSmall';
import LoadIcon from '../../LoadIcon';

const MobileVimeoVideoScreen = ({
  isLoading,
  mainVideo,
  // arrayOfVideos,
  // handlePlayingSmallScreens,
  // shouldMainVideoStart,
  // handleOnReadyTrueForAll,
  PLAYER_RATIO,
  // handleSwitchingVideos,
  // shouldSmallVideoStart,
  // activeSlide,
}) => (
  <>
    <div className="bg-black">
      {isLoading && <LoadIcon color="black" type="ThreeDots" />}
      {mainVideo && (
        <div className={`${isLoading ? 'hidden' : 'relative w-full bg-black'} `} style={{ paddingTop: PLAYER_RATIO }}>
          <iframe
            title="vimeoVideo"
            src={mainVideo}
            width="100%"
            height="100%"
            frameBorder="0"
            allow="fullscreen"
            allowFullScreen
            className="absolute pin-t pin-l h-full w-full"
          />
        </div>
      )}
    </div>
  </>
);

MobileVimeoVideoScreen.propTypes = {
  // arrayOfVideos: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  mainVideo: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  // handlePlayingSmallScreens: PropTypes.func.isRequired,
  // shouldMainVideoStart: PropTypes.bool,
  // handleOnReadyTrueForAll: PropTypes.func.isRequired,
  // handleSwitchingVideos: PropTypes.func.isRequired,
  // shouldSmallVideoStart: PropTypes.bool,
  PLAYER_RATIO: PropTypes.string,
  // activeSlide: PropTypes.number,
};

MobileVimeoVideoScreen.defaultProps = {
  isLoading: true,
  // shouldMainVideoStart: null,
  // shouldSmallVideoStart: false,
  PLAYER_RATIO: '56.25%',
  // activeSlide: 0,
};

export default MobileVimeoVideoScreen;
