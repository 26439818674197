import translation from './__translationHelper';

const data = [
  {
    type: 'NotificationItem',
    key: '1',
    _id: '1',
    text: translation('Kanye Godes (Apple Music) ha aceptado tu propuesta de reunión'),
    read: false,
    notificationType: 'accept',
    url: '',
  },
  {
    type: 'NotificationItem',
    key: '2',
    _id: '2',
    text: translation('Bernardino Panivino (Tidal) te ha propuesto una reunión.'),
    read: false,
    notificationType: 'propose',
    url: '',
  },
  {
    type: 'NotificationItem',
    key: '3',
    _id: '3',
    text: translation('Cristina Aguilera (Maverick Records) te ha propuesto una reunión.'),
    read: true,
    notificationType: 'propose',
    url: '',
  },
  {
    type: 'NotificationItem',
    key: '4',
    _id: '4',
    text: translation('C.Tangana (Artista) te ha propuesto una reunión.'),
    read: true,
    notificationType: 'propose',
    url: '',
  },
  {
    type: 'NotificationItem',
    key: '5',
    _id: '5',
    text: translation('Toni Castrillón (Medellin Records) ha aceptado tu propuesta de reunión.'),
    read: true,
    notificationType: 'accept',
    url: '',
  },
];

export default data;
