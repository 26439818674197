import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Modal from 'react-responsive-modal';
import { useRoutes } from '../../../context/routesContext';
import useResponsiveUtility from '../../../util/responsiveUtility';
import translateObj from '../../../util/translateObj';
import setReunionConfirmation from '../../../api/graphql/mutations/setReunionConfirmation';

import Button from './Button';

const getModalStyles = mobileScreen => ({
  root: {
    width: '100vw',
    height: '100%',
    padding: 0,
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
    width: '100vw',
    height: '100%',
    padding: 0,
  },
  modalContainer: {
    width: '100vw',
    height: '100%',
    padding: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modal: {
    background: 'rgba(255, 255, 255, 1)',
    boxShadow: 'none',
    width: mobileScreen ? '90vw' : '35vw',
    maxWidth: mobileScreen ? '90vw' : '35vw',
    height: 'auto',
    padding: 0,
    margin: 'auto',
    borderRadius: '0.5rem',
  },
});

const getModalLiterals = (action, literals) => {
  const content = {
    title: literals.buttonAcceptInvitation,
    text: literals.acceptInvitationText,
  };
  switch (action) {
    case 'rejected':
      content.title = literals.buttonDeclineInvitation;
      content.text = literals.rejectInvitationText;
      break;
    case 'hidden':
      content.title = literals.buttonDelete;
      content.text = literals.deleteText;
      break;
    case 'confirmed':
    default:
      break;
  }
  return content;
};

const ReunionItemConfirmActionModal = ({
  open,
  onClose,
  reunionId,
  reunionTitle,
  action,
  callback,
  literals,
}) => {
  const { language } = useRoutes();
  const { deviceScreen } = useResponsiveUtility();
  const [message, setMessage] = useState();

  const modalStyles = getModalStyles(['sm', 'md'].includes(deviceScreen));
  const modalLiterals = getModalLiterals(action, literals);

  const handleConfirm = () => {
    const values = {
      reunionId,
      setTo: action,
      text: message && action === 'rejected' ? message : undefined,
    };

    setReunionConfirmation(values).then((res) => {
      if (res.result) {
        if (callback) {
          callback();
        }
        onClose();
      }
    }).catch(err => console.log(err));
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      showCloseIcon
      styles={modalStyles}
      closeOnOverlayClick
      focusTrapped={false}
    >
      <div className="text-black relative pt-20 pb-10 px-6 h-full w-full">
        <h3 className="text-20 font-americaBlack mb-4">
          {`${translateObj(modalLiterals.title, language)}:`}
          <span className="text-pro-online mx-2">{reunionTitle}</span>
        </h3>
        <p className="font-americaMonoRegular text-base mb-8">{translateObj(modalLiterals.text, language)}</p>
        {action === 'rejected' && (
          <textarea
            style={{ resize: 'none', height: ['sm', 'md'].includes(deviceScreen) ? '30vh' : '15vw' }}
            name="cancellationMessage"
            className="w-full h-1/2 bg-grayE2DDDB rounded-lg p-4 mb-6 font-americaMonoRegular text-base"
            onChange={e => setMessage(e.target.value)}
            maxLength={280}
          />
        )}
        <div className="flex items-center justify-between">
          <Button
            onClickCallback={() => onClose()}
            text={translateObj(literals.buttonCancel, language)}
            className="flex-1 mr-2"
          />
          <Button
            onClickCallback={() => handleConfirm()}
            text={translateObj(literals.buttonAccept, language)}
            className="flex-1 ml-2"
            color="black"
          />
        </div>
      </div>
    </Modal>
  );
};

ReunionItemConfirmActionModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  reunionId: PropTypes.string.isRequired,
  reunionTitle: PropTypes.string,
  callback: PropTypes.func,
  action: PropTypes.string.isRequired,
  literals: PropTypes.shape().isRequired,
};

ReunionItemConfirmActionModal.defaultProps = {
  callback: null,
  reunionTitle: '',
};

export default ReunionItemConfirmActionModal;
