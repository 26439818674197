import React from 'react';
import PropTypes from 'prop-types';
import { useRoutes } from '../../../context/routesContext';
import translateObj from '../../../util/translateObj';
import LoadIcon from '../LoadIcon';

const literals = {
  loading: {
    en: 'Loading {items}...',
    es: 'Cargando {items}...',
    ca: 'Carregant {items}...',
    pt: 'Loading {items}...',
  },
};

const Loader = ({ label, className }) => {
  const { language } = useRoutes();

  return (
    <div className={`w-full flex items-center ${className}`}>
      {/* Animated icon */}
      <div className="h-5 w-5">
        <LoadIcon width={20} height={20} />
      </div>
      {/* Label */}
      {label && <span className="ml-2">{translateObj(literals.loading, language).replace('{items}', translateObj(label, language).toLowerCase())}</span>}
    </div>

  );
};

Loader.propTypes = {
  label: PropTypes.shape(),
  className: PropTypes.string,
};

Loader.defaultProps = {
  label: { en: '', es: '', ca: '', pt: '' },
  className: '',
};

export default Loader;
