import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { useRoutes } from '../../context/routesContext';
import translateObj from '../../util/translateObj';

const PARSER_OPTIONS = {
  replace: (domNode) => {
    if (domNode?.name === 'script') {
      const script = document.createElement('script');
      script.src = domNode.attribs.src;
      document.head.appendChild(script);
    }
  },
};

const Embed = ({ code }) => {
  const { language } = useRoutes();
  const codeFromDb = translateObj(code, language);

  return (
    <div className="w-full">
      {parse(codeFromDb, PARSER_OPTIONS)}
    </div>
  );
};

export default Embed;

Embed.propTypes = {
  code: PropTypes.shape({
    en: PropTypes.string,
    es: PropTypes.string,
    ca: PropTypes.string,
    pt: PropTypes.string,
  }),
};

Embed.defaultProps = {
  code: {},
};
