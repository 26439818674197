import React from 'react';
import PropTypes from 'prop-types';
import VenueLabel from './VenueLabel';
import { sortVenues } from './LayoutColumn';

const VenuesColumn = ({
  day,
  artistsByDay,
  venues,
  localization,
  fixedColumnRef,
  layout,
}) => (
  <div ref={fixedColumnRef} className="border-r-1 border-solid border-white bg-gray200 z-10">
    <div className="pin-t relative w-48 sm:w-28">
      <div>
        {sortVenues(artistsByDay[day]).map(venue => (
          <VenueLabel key={`${venue}-${day}`} title={venues[venue][localization.language]} layout={layout} />
        ))}
      </div>
    </div>
  </div>
);

VenuesColumn.propTypes = {
  day: PropTypes.string.isRequired,
  layout: PropTypes.string.isRequired,
  artistsByDay: PropTypes.shape().isRequired,
  venues: PropTypes.shape().isRequired,
  localization: PropTypes.shape().isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  fixedColumnRef: PropTypes.any.isRequired,
};

export default VenuesColumn;
