/* eslint-disable curly */
import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import ReactPhoneInput from 'react-phone-input-2';
import axios from 'axios';

import Link from '../../../util/link';
import { useRoutes } from '../../../context/routesContext';
import useCountry from './hooks/useCountry';
import reducer from './store/reducers';
import * as actions from './store/actions';

const END_POINT = 'https://uicpnh8aed.execute-api.eu-central-1.amazonaws.com/production/send-deep-link-emails';

const defaultConditionsText = {
  en: 'I have read and accept the',
  es: 'He leído y acepto las',
  ca: 'Va llegir i acceptar les',
  pt: 'Li e aceito as',
};

const defaultSubmitButtonText = {
  en: 'Get pre-sale code',
  es: 'Recibir código de preventa',
  ca: 'Rebre codi de prevenda',
  pt: 'Receba o código de pré-venda',
};

const defaultSubmitButtonTextFallback = {
  en: 'Get pre-sale code',
  es: 'Recibir código de preventa',
  ca: 'Rebre codi de prevenda',
  pt: 'Receba o código de pré-venda',
};

const conditionsLink = {
  es: 'POLÍTICA DE PRIVACIDAD',
  en: 'PRIVACY POLICY',
  ca: 'POLÍTICA DE PRIVACITAT',
  pt: 'PRIVACY POLICY',
};

const responseText = {
  en: 'Sent',
  es: 'Enviado',
  ca: 'Enviat',
  pt: 'enviei',
};

const inputStyle = {
  width: '100%',
  height: 'auto',
  padding: '1rem 1rem 1rem 4rem',
  borderRadius: '9999px',
  background: 'inherit',
  borderColor: 'black',
  color: 'black',
};

const initialState = {
  acceptConditions: false,
  error: null,
  loading: false,
  message: null,
  phone: null,
};

const PresaleSms = ({ conditionsText, countries, publicId, fallbackLink, submitButtonText, submitButtonTextFallback, termsLink }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { language } = useRoutes();
  // NOTE: if !countries we wont trigger the getUserCountry req
  const country = useCountry(countries);

  const { acceptConditions, error, loading, message, phone } = state;

  const countriesArray = countries?.split(',') || [];
  const shouldShowFallback = countriesArray.length;
  const enableSearch = countriesArray.length === 0 || countriesArray.length > 5;
  const disableButton = !phone || phone.length < 5 || loading || message || !acceptConditions;

  const handleOnChange = value => actions.setInputValue(dispatch)('phone', value);
  const handleConditions = e => actions.toggleConditions(dispatch)(e.target.name, e.target.checked);

  const submit = () => {
    actions.submitData(dispatch)();
    const data = {
      users: [{ language, phone, publicId }],
    };

    const options = {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      data: JSON.stringify(data),
      url: END_POINT,
    };

    axios(options)
      .then(() => actions.submitSuccess(dispatch)(responseText[language]))
      .catch(e => actions.submitError(dispatch)(e.message));
  };

  if (shouldShowFallback && !country) return (
    <div className="p-8 w-full llg:w-3/4 flex flex-col h-2/4 text-black font-americaMonoRegular">
      ...
    </div>
  );

  if (shouldShowFallback) return (
    <div className="p-8 w-full llg:w-3/4 flex flex-col h-2/4 text-black font-americaMonoRegular">
      <div className="px-3 w-full">
        <Link to={fallbackLink} aria-label="Get code" className="w-full p-5 my-5 bg-ps-destination-blue rounded-full text-white font-americaMonoRegular text-center" type="button">
          {submitButtonTextFallback?.[language]}
        </Link>
      </div>
    </div>
  );

  return (
    <div className="p-8 w-full llg:w-3/4 flex flex-col h-2/4 text-black font-americaMonoRegular">
      <div className="flex sm:flex-col flex-wrap">
        <div className="px-3 my-4 w-full">
          <ReactPhoneInput
            country="us"
            onlyCountries={[]}
            value={phone}
            onChange={handleOnChange}
            inputStyle={inputStyle}
            enableSearch={enableSearch}
          />
        </div>
        <div className="px-3 my-4 w-full">
          <div className="flex items-center font-americaMonoBold uppercase">
            <input aria-label="Enter your phone" type="checkbox" className="mr-4" name="acceptConditions" onChange={handleConditions} required />
            <p>
              {conditionsText[language]}
              {' '}
              <Link className="underline text-black" to={termsLink}>{conditionsLink[language]}</Link>
            </p>
          </div>
        </div>
        <div className="px-3 w-full">
          <button id="btn-pre" aria-label="Submit phone" className="w-full p-5 my-5 bg-ps-destination-blue rounded-full text-white" type="submit" onClick={submit} disabled={disableButton}>{message || submitButtonText?.[language]}</button>
          {error ? <p className="text-red">{error}</p> : null}
        </div>
      </div>
    </div>
  );
};

PresaleSms.propTypes = {
  conditionsText: PropTypes.shape(),
  countries: PropTypes.string,
  fallbackLink: PropTypes.string,
  publicId: PropTypes.string.isRequired,
  submitButtonText: PropTypes.shape(),
  submitButtonTextFallback: PropTypes.shape(),
  termsLink: PropTypes.string.isRequired,
};

PresaleSms.defaultProps = {
  conditionsText: defaultConditionsText,
  countries: null,
  fallbackLink: null,
  submitButtonText: defaultSubmitButtonText,
  submitButtonTextFallback: defaultSubmitButtonTextFallback,
};

export default PresaleSms;
