import gql from 'graphql-tag';

import { clientWeb } from '../graphqlAPI';
import triggerGaUserRegistration from '../../../util/triggerGaTag/triggerGaUserRegistration';

const USER_SIGNUP_FORM = gql`
  mutation Post(
    $name: String,
    $lastname: String,
    $email: String!,
    $phone: String,
    $oauthId: String,
    $postalCode: String,
    $password: String
    $language: String
    $dateOfBirth: String
    $inputCountry: String
    $socialnetwork: String
    $spotifyToken: String
    $newsletter: Boolean
    $originPost: String
    $nickname: String
    $description: String
    $occupation: String
    $company: String
    $url: String
    $imageUrl: String
    $social: [SocialItemInput]
    $userType: String
    $isPrivate: Boolean
    $business: String,
    $city: String,
    ) {
    userWebSignup(
      name: $name,
      lastname: $lastname,
      email: $email,
      phone: $phone,
      oauthId: $oauthId,
      postalCode: $postalCode,
      password: $password
      language: $language
      dateOfBirth: $dateOfBirth
      inputCountry: $inputCountry
      socialnetwork: $socialnetwork
      spotifyToken: $spotifyToken
      newsletter: $newsletter
      originPost: $originPost
      nickname: $nickname
      description: $description
      occupation: $occupation
      company: $company
      url: $url
      imageUrl: $imageUrl
      social: $social
      userType: $userType
      isPrivate: $isPrivate
      city: $city,
      business: $business,
      ){
        isValid
        token
        error
    }
}`;

const signupUserForm = async (signupData) => {
  // Get from which post the registration comes from using querystring
  const getQueryVariable = (varName) => {
    try {
      const vars = window.location.search.replace(/\?/, '').split('&');
      const [[, value = null] = []] = vars.filter(group => group.split('=')[0] === varName).map(item => item.split('='));
      return value;
    } catch (error) {
      console.log('Error at getQueryVariable', error);
      return null;
    }
  };

  const getCurrentPost = () => {
    try {
      return window.location.href.split('/').pop();
    } catch (error) {
      return null;
    }
  };

  const originPost = getQueryVariable('originPost') || getCurrentPost();

  try {
    const result = await clientWeb.mutate({
      mutation: USER_SIGNUP_FORM,
      variables: { ...signupData, originPost },
      fetchPolicy: 'no-cache',
    })
      .then(queryResult => queryResult)
      .catch((queryError) => {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error: ${queryError}`);
        return null;
      });

    const { data: { userWebSignup: { isValid, token, error } } } = result;

    if (isValid) {
      triggerGaUserRegistration(signupData.email, originPost);
      return { isValid, token };
    }
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: GraphQL Query Error: validatedFormData = null`);
    return { isValid: false, error };
  } catch (error) {
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: userSocialLogin Error: ${error}`);
    return { isValid: false, error: 'Oops, that did not work, please try again later' };
  }
};

export default signupUserForm;
