import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ArtistCard from './ArtistCard';

const Star = props => <img {...props} src="https://assets.primaverasound.com/ps/images/star_black.svg" alt="buy tickets" />;


const styles = theme => ({
  root: {
    width: '100%',
    paddingLeft: 16,
    paddingRight: 16,
    overflow: 'visible',
  },
  table: {
    minWidth: 700,
    margin: 0,
  },
  tableHeader: {
    backgroundColor: '#e2dddb',
    height: 40,

  },
  tableRow: {
    cursor: 'pointer',
    backgroundColor: '#efeae7',
    height: 72,
    '&:hover': {
      backgroundColor: '#fe808b',
    },

  },
  artist: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    '&:hover > div:nth-of-type(2)': {
      visibility: 'visible',
    },
  },
  artistCard: {
    visibility: 'hidden',
  },
  tableCell: {
    borderBottom: 'solid 1px black',
  },
});

const rows = [
  'ARTISTA', 'HORARIO', 'ESCENARIO',
];
function SimpleTable(props) {
  const { classes, artistList } = props;

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow className={classes.tableHeader}>
            <TableCell padding="checkbox" />
            {rows.map(row => <TableCell className="font-americaMonoRegular" align={row === 'ARTISTA' ? 'left' : 'right'}>{row}</TableCell>)}
          </TableRow>
        </TableHead>
        <TableBody>
          {/* TODO: filter items */}
          {artistList.slice(0, 5).map(artist => (

            <TableRow className={`font-tiemposRegular ${classes.tableRow}`} key={artist.name}>

              <TableCell classes={{ root: classes.tableCell }} align="center" padding="checkbox"><Star /></TableCell>
              <TableCell className="font-tiemposBold" classes={{ root: classes.tableCell }} component="th" scope="row">
                <div className={classes.artist}>


                  <div style={{
                    backgroundImage: `url(${artist.imageUrl})`,
                    width: 97,
                    height: 50,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    marginRight: 10,
                  }}
                  />

                  <div className={classes.artistCard}>

                    <ArtistCard text={artist.text} imageUrl={artist.profileImage} />
                  </div>
                  {artist.name}
                </div>
              </TableCell>
              <TableCell classes={{ root: classes.tableCell }} align="right">{artist.time}</TableCell>
              <TableCell classes={{ root: classes.tableCell }} align="right">{artist.stage}</TableCell>
            </TableRow>


          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}

SimpleTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleTable);
