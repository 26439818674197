import React from "react";

const redeemIcon = () => (
  <svg
    className="h-4 w-4"
    viewBox="0 0 18 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 15H13M1 15L4.5 11.5M1 15L4.5 18.5M4 5H17M17 5L13.5 1.5M17 5L13.5 8.5"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default redeemIcon;
