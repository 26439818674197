import React, { useState, useEffect } from 'react';

import { usersRef } from '../utils/helpers';
import { useChat } from '../context/chatContext';
import { firebaseauth } from '../context/FirebaseAuthContext';

import { PRIVATE_CHANNELS, PENDING_PRIVATE_CHANNELS } from '../utils/constants';

const usePrivateChannels = () => {
  const { userData } = useChat();
  const [channelList, setPrivateChannels] = useState([]);
  const [pendingChannels, setPendingChannels] = useState([]);
  const [isLoadingChannels, setLoadingChannels] = useState(true);
  let unSubscribeJoined;
  let unSubscribePending;
  const { currentUser } = firebaseauth;

  useEffect(() => {
    if (userData && userData.nickname && currentUser) {
      unSubscribeJoined = usersRef.doc(userData.userId).collection(PRIVATE_CHANNELS).onSnapshot((snapshot) => {
        const { docs } = snapshot;
        if (docs && docs.length) {
          const myChannels = docs.map((doc) => {
            const { members } = doc.data();
            let name = '';
            if (members && members.length) {
              name = doc.data().members.filter(member => member !== userData.nickname).join(',');
            }
            const channelInfo = {
              id: doc.id,
              myMemberState: 'joined',
              url: doc.id,
              name,
              members: doc.data().members,
            };
            return channelInfo;
          });
          setPrivateChannels(myChannels);
        } else {
          setPrivateChannels([]);
        }
        setLoadingChannels(false);
      });

      unSubscribePending = usersRef.doc(userData.userId).collection(PENDING_PRIVATE_CHANNELS).onSnapshot((snapshot) => {
        const { docs } = snapshot;
        if (docs && docs.length) {
          const myPendingChannels = docs.map(doc => ({
            id: doc.id,
            myMemberState: 'invited',
            url: doc.id,
            ...doc.data(),
          }));
          setPendingChannels(myPendingChannels);
        } else {
          setPendingChannels([]);
        }
      });

      return () => { unSubscribeJoined(); unSubscribePending(); };
    }
  }, [userData]);

  return {
    channelList, isLoadingChannels, pendingChannels,
  };
};

export default usePrivateChannels;
