import React from 'react';
import PropTypes from 'prop-types';
import Link from '../../../util/link';
import RoutesContext from '../../../context/routesContext';
import translateObj from '../../../util/translateObj';

const FooterRedesignLogosContainer = ({ name, logos, parent }) => (
  <RoutesContext.Consumer>
    {({ language }) => (
      <div
        key={name}
        className="flex flex-col tablet:flex-row justify-end llg:justify-start"
      >
        {logos
          && logos.map((logo, index) => {
            if (logo.icon) {
              return (
                <div
                  className={`text-40 ${
                    index + 1 < logos.length ? 'pr-4 ' : ''
                  }`}
                  key={logo.name}
                >
                  <Link to={logo.link} localizationpath={`/${language}`} aria-label={translateObj(logo.name, language)}>
                    <span className={`${logo.icon} text-white block`} />
                  </Link>
                </div>
              );
            }

            if (logo.image) {
              return (
                <div
                  className={`pt-8px ${index + 1 < logos.length ? 'pr-4 ' : ''}`}
                  key={logo.name}
                  style={{ maxWidth: '96px' }}
                >
                  <Link to={logo.link} localizationpath={`/${language}`} aria-label={translateObj(logo.name, language)}>
                    <img src={logo.image} alt={logo.name} />
                  </Link>
                </div>
              );
            }

            return null;
          })}
      </div>
    )}
  </RoutesContext.Consumer>
);

FooterRedesignLogosContainer.propTypes = {
  name: PropTypes.string.isRequired,
  logos: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
    }),
  ).isRequired,
  parent: PropTypes.oneOf(['FooterRedesignTop', 'FooterRedesignBottom'])
    .isRequired,
};

export default FooterRedesignLogosContainer;
