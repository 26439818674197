import React from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';
import Link from '../../../util/link';

import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';
import translateObj from '../../../util/translateObj';

const TopHeaderMegamenuSubmenu = ({
  name, items, children, match, isFullHeight,
}) => (
  <RoutesContext.Consumer>
    {({ language: contextLanguage, enteredFrom }) => {
      const localization = getLocalization(contextLanguage, enteredFrom, match);
      return (
        <section role="navigation" data-name="submenu" className={`mb-8 sm:mb-8 sm:w-full break-avoid-column xl:basis-33 ${isFullHeight ? 'h-full' : ''}`}>
          <h4 className="block mb-5 sm:mb-3 font-americaMonoBold text-xs uppercase overflow-hidden">
            <span data-name="submenu-item" className="block relative" aria-label={translateObj(name, localization.language)}>
              {translateObj(name, localization.language)}
            </span>
          </h4>
          <nav aria-label={translateObj(name, localization.language)}>
            {items.map(item => (
              <Link
                to={`${item.link}`}
                localizationpath={`${localization.path}`}
                className="no-underline text-black block overflow-hidden mb-2 leading-loose"
                key={item.link}
                aria-label={translateObj(item.text, localization.language)}
              >
                <span data-name="submenu-item" className="relative block font-tiemposRegular text-base sm:pl-4 hover:font-bold">
                  {`/ ${translateObj(item.text, localization.language)}`}
                </span>
              </Link>
            ))}
          </nav>
          {children}
        </section>
      );
    }}
  </RoutesContext.Consumer>
);

TopHeaderMegamenuSubmenu.propTypes = {
  name: PropTypes.shape().isRequired,
  match: PropTypes.shape().isRequired,
  items: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
  children: PropTypes.arrayOf(PropTypes.shape().isRequired),
  isFullHeight: PropTypes.bool,
};

TopHeaderMegamenuSubmenu.defaultProps = {
  children: null,
  isFullHeight: false,
};

export default withRouter(TopHeaderMegamenuSubmenu);
