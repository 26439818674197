/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { Fragment } from 'react';
import { PropTypes } from 'prop-types';

import { withRouter } from 'react-router-dom';
import Link from '../../util/link';

import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';
import translateObj from '../../util/translateObj';

const MediaTitle = ({
  primaryText, secondaryText, anchor, internalLink, match, category, arrow, margin,
}) => {
  const LinkText = ({ localization }) => {
    const adjustedInternalLink = internalLink === '/' ? `/${internalLink}` : internalLink;
    return (
      <span>
        {internalLink ? (
          <Link to={adjustedInternalLink} localizationpath={localization.path} aria-label={translateObj(secondaryText, localization.language)}>
            <span className={`text-${category || 'black'} underline cursor-pointer leading-normal uppercase`}>
              {arrow && <span className="font-icon icon-arrow-right text-12" />}
              {translateObj(secondaryText, localization.language)}
            </span>
          </Link>
        ) : (
          <span className={`text-${category} leading-normal uppercase`}>
            {arrow && <span className="font-icon icon-arrow-right text-12" />}
            {translateObj(secondaryText, localization.language)}
          </span>
        )}
      </span>
    );
  };

  LinkText.propTypes = {
    localization: PropTypes.shape({}).isRequired,
  };

  return (
    <RoutesContext.Consumer>
      {({ language: contextLanguage, enteredFrom }) => {
        const localization = getLocalization(contextLanguage, enteredFrom, match);
        return (
          <>
            <h4
              tabIndex="0"
              className={`${margin || 'my-10'} text-base sm:hidden font-americaMonoBold
leading-normal font-black uppercase w-full`}
            >
              {translateObj(primaryText, localization.language)}
              {secondaryText && secondaryText.en && secondaryText.en !== '' && '   |   '}
              {secondaryText && secondaryText.en && secondaryText.en !== '' && <LinkText localization={localization} />}
            </h4>
            <h4
              tabIndex="0"
              className="text-base my-6 text-sm hidden sm:block font-americaMonoBold
leading-normal font-black w-full uppercase"
            >
              {translateObj(primaryText, localization.language)}
              {secondaryText && secondaryText.en && secondaryText.en !== '' && '   |   '}
              {secondaryText && secondaryText.en && secondaryText.en !== '' && <LinkText localization={localization} />}
              {/* <p className="mb-0 font-americaMonoBold uppercase">{translateObj(primaryText, localization.language)}</p>
              <p className="mt-0 font-americaMonoBold">{secondaryText && <LinkText localization={localization} />}</p> */}
            </h4>
          </>
        );
      }}
    </RoutesContext.Consumer>
  );
};

MediaTitle.propTypes = {
  match: PropTypes.shape().isRequired,
  anchor: PropTypes.string,
  internalLink: PropTypes.string,
  category: PropTypes.string,
  primaryText: PropTypes.shape().isRequired,
  secondaryText: PropTypes.shape(),
  arrow: PropTypes.bool,
  margin: PropTypes.string,
};
MediaTitle.defaultProps = {
  secondaryText: null,
  category: 'primavera-pink',
  anchor: null,
  internalLink: null,
  arrow: false,
  margin: '',
};
export default withRouter(MediaTitle);
