import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { TimelineMax, TweenMax } from 'gsap';
import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';
import PSResponsiveUtility from '../LineUp/PSResponsiveUtility';
import TopHeaderMegamenu from './TopHeaderMegamenu';
import translateObj from '../../../util/translateObj';
import withAllContexts from '../../../context/withAllContexts';
import Link from '../../../util/link';
import Button from '../Button';

const burgerAnimationDuration = 0.2;

const button = {
  type: 'Button',
  name: 'headerMenuButtonTickets',
  params: {
    text: {
      es: 'Entradas',
      en: 'Tickets',
      ca: 'entrades',
      pt: 'Bilhetes',
    },
    url: '/tickets',
    icon: 'font-icon icon-ticket',
    mainParent: 'mobilemenu',
  },
};

class TopHeaderMainMenuItemsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      section: false,
      nextSection: false,
    };
    this.responsiveUtility = React.createRef();
    this.componentExists = React.createRef(false);
  }

  componentDidMount() {
    this.componentExists.current = true;
    window.addEventListener('scroll', this.handleScroll);

    const { history } = this.props;
    this.unlisten = history.listen(() => {
      const { open } = this.state;
      if (open) {
        this.toggle();
      }
    });
  }

  componentWillUnmount() {
    this.componentExists.current = false;
    if (this.unlisten) this.unlisten();
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const deviceScreen = this.responsiveUtility.current.deviceScreen();
    if (['sm', 'md'].indexOf(deviceScreen) === -1) {
      return;
    }

    const { section } = this.state;
    const y = document.documentElement.scrollTop || document.body.scrollTop;
    if (section) {
      const el = document.querySelector(`#menuItem_${section.key}`);
      if (el) {
        const link = el.querySelector('[data-link]');
        const linkHeight = link.getBoundingClientRect().height;
        const top = y + el.getBoundingClientRect().top;
        const bottom = y + el.getBoundingClientRect().bottom - linkHeight;
        if (y > top) {
          this.originalPaddingTop = parseFloat(window.getComputedStyle(el.querySelector('[data-menu-type]')).getPropertyValue('padding-left'));
          el.querySelector('[data-menu-type]').style.paddingTop = `${linkHeight + this.originalPaddingTop}px`;
          this.setState({ floatingSection: y > bottom ? 'bottom' : 'floating' });
        } else {
          el.querySelector('[data-menu-type]').style.paddingTop = `${this.originalPaddingTop}px`;
          this.setState({ floatingSection: false });
        }
      }
    }
  };

  toggle = () => {
    const { open } = this.state;
    const { headerContext } = this.props;
    headerContext.toggleMegaMenu();
    if (this.tl) this.tl.kill();

    this.tl = new TimelineMax();

    if (!open) {
      this.tl.to('[data-type="open"]', 0, {
        className: '-=hidden',
      });
      this.tl.staggerTo(
        '[data-type="open"] span',
        burgerAnimationDuration,
        {
          x: '-100%',
        },
        0.1,
        '+=0',
      );
      this.tl.to(
        '[data-type="open"]',
        0,
        {
          className: '+=hidden',
        },
        '+=0',
      );
      this.tl.to(
        '[data-type="close"]',
        0,
        {
          className: '-=hidden',
        },
        '+=0',
      );
      this.tl.fromTo(
        '[data-type="close"] span:nth-child(1)',
        burgerAnimationDuration,
        {
          rotation: 0,
        },
        {
          rotation: '-45deg',
        },
        '+=0',
      );
      this.tl.fromTo(
        '[data-type="close"] span:nth-child(2)',
        burgerAnimationDuration,
        {
          rotation: 0,
        },
        {
          rotation: '45deg',
        },
        `-=${burgerAnimationDuration}`,
      );
    } else {
      this.tl.fromTo(
        '[data-type="close"] span:nth-child(1)',
        burgerAnimationDuration,
        {
          rotation: '-45deg',
        },
        {
          rotation: 0,
        },
        '+=0',
      );
      this.tl.fromTo(
        '[data-type="close"] span:nth-child(2)',
        burgerAnimationDuration,
        {
          rotation: '45deg',
        },
        {
          rotation: 0,
        },
        `-=${burgerAnimationDuration}`,
      );
      this.tl.to(
        '[data-type="close"]',
        0,
        {
          className: '+=hidden',
        },
        '+=0',
      );
      this.tl.to(
        '[data-type="open"]',
        0,
        {
          className: '-=hidden',
        },
        '+=0',
      );
      this.tl.staggerTo(
        '[data-type="open"] span',
        burgerAnimationDuration,
        {
          x: '0%',
        },
        0.1,
        '+=0',
      );
    }

    this.tl.eventCallback(
      'onStart',
      (that, o) => {
        that.setState({ open: !o });
      },
      [this, open],
    );
    this.tl.play();
  };

  toggleItem = (e, link, localizationpath) => {
    // temporary code to link to external porto side
    // if (link && link.key === 'porto' && link.link.includes('http')) return null;

    const { section, nextSection } = this.state;

    if (!link || !link.menu) {
      this.rotateArrow(section.key);
      if (link) {
        this.goTo(link.link, localizationpath);
      }
      if (this.componentExists.current) {
        this.setState({ section: false, nextSection: false }, () => {
          document.querySelector('body').style.overflowY = 'scroll';
        });
      }
      return;
    }

    if (e) e.preventDefault();
    const deviceScreen = this.responsiveUtility.current.deviceScreen(true);
    const isTablerOrSmaller = ['sm', 'md'].indexOf(deviceScreen) > -1;

    let theNewSection = false;
    let theNewNextSection = false;
    if (!isTablerOrSmaller) {
      if (nextSection && section.key === link.key) {
        theNewSection = section;
        theNewNextSection = link;
      } else if (section === false) {
        theNewSection = link;
      } else if (section.key === link.key && nextSection.key !== link.key) {
        theNewSection = false;
      } else if (section.key) {
        theNewSection = section;
        theNewNextSection = link;
      }
    } else {
      if (section.key !== link.key) {
        theNewSection = link;
      }
    }

    this.rotateArrow(section.key, '0deg');
    this.rotateArrow(theNewSection.key, '180deg');

    this.setState({ section: theNewSection, nextSection: theNewNextSection }, () => {
      document.querySelector('body').style.overflowY = theNewSection && !isTablerOrSmaller ? 'hidden' : 'scroll';
      if (!theNewSection && isTablerOrSmaller) {
        window.scrollTo(0, 0);
      }
    });
  };

  goTo = (url, localizationpath = 'en') => {
    const { history } = this.props;
    history.push(`${localizationpath}${url}`);
  };

  getScreen = () => {
    const deviceScreen = this.responsiveUtility.current.deviceScreen(true);
    return deviceScreen;
  };

  // eslint-disable-next-line class-methods-use-this
  rotateArrow(key, deg = '0deg') {
    TweenMax.to(`#menuItem_${key} [data-name="caret"]`, burgerAnimationDuration, {
      rotation: deg,
    });
  }

  render() {
    const {
      name, params, children, match, textColor, hideTicketsButton,
    } = this.props;
    // console.log('params', params);
    // console.log('match', match);
    // console.log('validation', match.url === params[0].link);
    // console.log('match.url', match.url);
    // console.log('params[0].link', params[0].link);
    const burgerMenu = (
      <div id="menuMobileButton" className="hidden sm:block md:block absolute pin-r w-6 h-6 mr-4" style={{ top: '24px' }} onClick={this.toggle}>
        <div data-type="open" className="overflow-hidden pin absolute">
          <span style={{ height: 3 }} className={`block rounded-full w-full bg-${textColor || 'black'} mb-1`} />
          <span style={{ height: 3 }} className={`block rounded-full w-5/6 bg-${textColor || 'black'} mb-1`} />
          <span style={{ height: 3 }} className={`block rounded-full w-2/3 bg-${textColor || 'black'} mb-1 `} />
        </div>
        <div data-type="close" className="hidden pin absolute" style={{ top: 8 }}>
          <span style={{ height: 3, transform: 'rotate(-45deg)' }} className={`block rounded-full w-full bg-${textColor || 'black'}`} />
          <span
            style={{
              height: 3,
              transform: 'rotate(45deg)',
              left: 0,
              top: -3,
            }}
            className={`block relative rounded-full w-full bg-${textColor || 'black'} -mb-1`}
          />
        </div>
      </div>
    );

    return (
      <RoutesContext.Consumer>
        {({ language: contextLanguage, enteredFrom }) => {
          const localization = getLocalization(contextLanguage, enteredFrom, match);

          const {
            section, open, floatingSection, nextSection,
          } = this.state;

          const linkFloatingClasses = (key) => {
            if (section && section.key === key) {
              if (floatingSection === 'floating') {
                return 'sm:fixed md:fixed sm:pin-x sm:pin-t md:pin-x md:pin-t z-10';
              }
              if (floatingSection === 'bottom') {
                return 'sm:absolute md:absolute sm:pin-x sm:pin-b-1 md:pin-x md:pin-b-1 z-10';
              }
            }
            return '';
          };

          return (
            <>
              <PSResponsiveUtility ref={this.responsiveUtility} />
              <div id={name} className={`flex items-center sm:items-end sm:flex-col md:items-end md:flex-col ${!open ? 'h-68px' : `bg-${textColor === 'black' ? 'white' : 'black'}`}`}>
                {!open && !hideTicketsButton && <Button params={button.params} name={button.name} mainParent={button.params.mainParent} />}
                {params && params.length > 0 && burgerMenu}
                <div className={`z-header flex items-center sm:items-start sm:flex-col md:items-start md:flex-col md:w-full sm:w-full sm:bg-${textColor === 'black' ? 'white' : 'black'} md:bg-${textColor === 'black' ? 'white' : 'black'} ${open ? 'sm: mt-16 md:mt-16 mmd:mt-16 lg:mt-0 menu-width-transition' : 'lg:mt-0 sm:hidden md:hidden'}`}>
                  {params && params.length && params.map(link => (
                    <div key={link.key} className="sm:w-full md:w-full w-auto relative cursor-pointer">
                      <div id={`menuItem_${link.key}`}>
                        <span className={`bg-${textColor === 'black' ? 'white' : 'black'} no-underline text-base text-${textColor} ${linkFloatingClasses(link.key)}`}>
                          <span
                            onClick={e => (this.getScreen() === 'sm' || this.getScreen() === 'md') && this.toggleItem(e, link, localization.path)}
                            className="whitespace-no-wrap block sm:py-6 font-americaMonoBold uppercase tracking-wide font-bold text-14 w-auto sm:flex sm:items-center sm:justify-between md:flex md:items-center md:justify-between px-2 sm:px-4 md:px-4 md:py-4"
                          >
                            <Link to={link.link} localizationpath={localization.path} data-link={link.key} onClick={e => this.toggleItem(e, link, localization.path)}>
                              <span className={`text-${textColor} relative py-4 cursor-pointer`} data-link={link.key}>
                                {translateObj(link.text, localization.language)}
                              </span>
                            </Link>
                            {`/${match.params.section}` === link.link && (
                            <div
                              className={`absolute px-4 bg-${link.key} w-full sm:hidden md:hidden`}
                              style={{
                                bottom: '-35px',
                                zIndex: 9,
                                right: 0,
                                height: '1rem',
                              }}
                            />
                            )}
                            {link.key === 'radio' && match.url.includes('radio') && match.params.section === undefined && (
                            <div
                              className={`absolute px-4 bg-${link.key} w-full sm:hidden md:hidden`}
                              style={{
                                bottom: '-35px',
                                zIndex: 9,
                                right: 0,
                                height: '1rem',
                              }}
                            />
                            )}
                            {link.menu && <span data-name="caret" className={`hidden sm:block md:block font-icon icon-arrow-down text-${textColor} text-sm cursor-pointer`} data-link={link.key} onClick={e => this.toggleItem(e, link, localization.path)} />}
                          </span>
                        </span>
                        {section && section.key === link.key && link.menu && <TopHeaderMegamenu nextSection={nextSection} localization={localization} links={params} activeLink={link} toggleItemCallback={this.toggleItem} goTo={this.goTo} />}
                      </div>
                      <div className={`h-px bg-${textColor === 'black' ? 'black' : 'white'} mx-2 sm:mx-4 md:mx-4 hidden sm:block md:block`} />
                    </div>
                  ))}
                  <div className="pl-2 sm:px-4 md:pl-4 w-full md:flex md:items-center md:flex-col sm:flex sm:items-center sm:flex-col mr-4">{children}</div>
                </div>
              </div>

            </>
          );
        }}
      </RoutesContext.Consumer>
    );
  }
}

TopHeaderMainMenuItemsContainer.propTypes = {
  // eslint-disable-next-line react/require-default-props
  name: PropTypes.string,
  match: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
  params: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
  children: PropTypes.shape().isRequired,
  textColor: PropTypes.string,
  hideTicketsButton: PropTypes.bool,
  headerContext: PropTypes.shape().isRequired,
};

TopHeaderMainMenuItemsContainer.defaultProps = {
  textColor: 'black',
  hideTicketsButton: false,
};

export default withRouter(withAllContexts(TopHeaderMainMenuItemsContainer));
