/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useContext } from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import translateObj from '../../util/translateObj';
import Link from '../../util/link';
import truncate from '../../util/truncate';
import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';
import isSectionWithSubPost from '../../util/isSectionWithSubPost';
import dictionary from './dictionaryCategories';

// const getWindowDimensions = () => {
//   if (typeof window === 'undefined') return { width: 0 };
//   const { innerWidth: width } = window;
//   return {
//     width,
//   };
// };

// const useWindowDimensions = () => {
//   const [windowDimensions, setWindowDimensions] = useState(
//     getWindowDimensions(),
//   );

//   useEffect(() => {
//     function handleResize() {
//       setWindowDimensions(getWindowDimensions());
//     }
//     if (typeof window !== 'undefined') {
//       window.addEventListener('resize', handleResize);
//       return () => window.removeEventListener('resize', handleResize);
//     }
//   }, []);

//   return windowDimensions;
// };

const checkInDictionary = (itemToCheck = '', language = 'en') => {
  if (dictionary.hasOwnProperty(itemToCheck)) { return translateObj(dictionary[itemToCheck], language); }
  return itemToCheck;
};

const hasUnderscore = (post = '') => post.split('_').length > 1;

const ItemLink = ({
  text = 'home',
  link = '/',
  language = '',
  separator = false,
  colorCategory = '',
  lastItem = false,
}) => (
  <div className="text-white text-sm sm:text-xs font-light flex items-center font-americaMonoRegular uppercase">
    {separator && <span className="px-2">></span>}
    {lastItem
      ? <span className={`text-${colorCategory}`}>{truncate(text, 25)}</span>
      : (
        <Link
          to={link}
          localizationpath={language ? `/${language}` : ''}
          className="text-white underline cursor-pointer"
        >
          {text}
        </Link>
      )
    }
  </div>
);

const Navigator = ({
  match,
  position,
}) => {
  const {
    currentPostDescription,
    language,
  } = useContext(RoutesContext);

  const [subPage, setSubPage] = useState(null);

  useEffect(() => {
    if (currentPostDescription && currentPostDescription.url) {
      if (currentPostDescription.url.includes('pslife')) {
        setSubPage('pslife');
      }
    }
  }, []);

  const urlPath = [];
  const { section, post } = match.params;
  const sectionTitle = checkInDictionary(section, language);

  if (post && section) {
    urlPath.push({ title: sectionTitle, link: section });

    hasUnderscore(post) && urlPath.push({
      title: checkInDictionary(post.split('_')[0], language),
      link: `${section}/${post.split('_')[0]}`,
    });

    urlPath.push({ title: translateObj(currentPostDescription.title, language), link: post });
  } else {
    hasUnderscore(section) && urlPath.push({
      title: checkInDictionary(section.split('_')[0], language),
      link: `${section.split('_')[0]}`,
    });

    urlPath.push({ title: translateObj(currentPostDescription.title, language), link: section });
  }

  const navigatorStyles = position === 'absolute' ? { top: '16px' } : {};
  const classes = position === 'static' ? 'pt-4' : '';

  return (
    <div className={`flex ${position} ${classes} z-radio w-full`} style={navigatorStyles}>
      <div className="flex px-4 py-1 bg-black">
        <ItemLink link={subPage ? `/${language}/${subPage}` : `/${language}`} />
        {urlPath.map((item, index) => (
          <ItemLink
            key={`${item.title}_${item.link}`}
            text={item.title}
            link={subPage ? `/${subPage}/${item.link}` : `/${item.link}`}
            language={language}
            separator
            colorCategory={section}
            lastItem={(urlPath.length === index + 1)}
          />
        ))}
      </div>
    </div>
  );
};

Navigator.propTypes = {
  position: PropTypes.string,
};

Navigator.defaultProps = {
  position: 'absolute',
};

ItemLink.propTypes = {
  text: PropTypes.string,
  link: PropTypes.string,
  language: PropTypes.string,
  separator: PropTypes.bool,
  colorCategory: PropTypes.string,
  lastItem: PropTypes.bool,
};

ItemLink.defaultProps = {
  text: 'home',
  link: '/',
  language: '',
  separator: false,
  colorCategory: '',
  lastItem: false,
};

export default withRouter(Navigator);
