import gql from 'graphql-tag';

import { clientWeb } from '../graphqlAPI';

const USER_SOCIAL_LOGIN = gql`
  mutation Post($tokenId: String!, $socialnetwork: String!, $redirectUri: String) {
    userSocialLogin(
      tokenId: $tokenId,
      socialnetwork: $socialnetwork,
      redirectUri: $redirectUri,
      ){
        isValid
        error
        token
        isVerified
        userDetails {
          email
          display_name
          userID
          country
          spotifyToken
        }
    }
}`;

const userSocialLogin = async (tokenId, socialnetwork, redirectUri) => {
  try {
    const result = await clientWeb.mutate({
      mutation: USER_SOCIAL_LOGIN,
      variables: {
        tokenId,
        socialnetwork,
        redirectUri,
      },
      fetchPolicy: 'no-cache',
    })
      .then(queryResult => queryResult)
      .catch((queryError) => {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error: ${queryError}`);
        return null;
      });
    const { data: { userSocialLogin: validatedFormData } } = result;

    if (validatedFormData) {
      return validatedFormData;
    }
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: GraphQL Query Error: validatedFormData = null`);
    return null;
  } catch (error) {
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: userSocialLogin Error: ${error}`);
    return null;
  }
};

export default userSocialLogin;
