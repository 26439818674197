const checkFileType = (ext) => {
  switch (ext && ext.toLowerCase()) {
    case 'jpg':
    case 'gif':
    case 'bmp':
    case 'png':
    case 'svg':
      return 'image';
    case 'm4v':
    case 'avi':
    case 'mpg':
    case 'mp4':
      return 'video';
    case 'mp3':
      return 'audio';
    default:
      return 'file';
  }
};

export default checkFileType;
