
import React from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import getMamObjectsBySlugNameQuery from '../../api/graphql/queries/getMamObjectsBySlugname';
import getPostsBySlugNameQuery from '../../api/graphql/queries/getPostsBySlugNameQuery';

import Filter from './Collapsible/Filter';
import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';
import ComponentIterator from '../../containers/ComponentIterator';
import handleFilter from './Collapsible/handleFilter';
import DoubleTitle from './DoubleTitle';

const menu = {
  text: {
    es: 'OPCIONES DE VISUALIZACIÓN | FILTRAR CONTENIDO',
    en: 'VISUALISATION OPTIONS | FILTER CONTENT',
    ca: 'OPCIONES DE VISUALIZACIÓN | FILTRAR CONTENIDO',
  },
  filter: [
    {
      filterHeading: {
        es: 'POR EVENTO',
        en: 'BY EVENT',
        ca: 'POR EVENTO',
      },
      filterType: 'byEvent',
      menuTitle: {
        text: {
          es: 'TODOS LOS EVENTOS',
          en: 'ALL events',
        },
      },
      filterItems: [
        {
          category: 'barcelona',
          value: 'barcelona',
          text: {
            es: 'BARCELONA - PRIMAVERA SOUND',
            en: 'BARCELONA - PRIMAVERA SOUND',
            ca: 'BARCELONA - PRIMAVERA SOUND',
          },
        },
        {
          category: 'oporto',
          value: 'oporto',
          text: {
            es: 'NOS - PRIMAVERA SOUND',
            en: 'NOS - PRIMAVERA SOUND',
            ca: 'NOS - PRIMAVERA SOUND',
          },
        },
      ],
    },
    {
      filterHeading: {
        es: 'POR AÑO',
        en: 'BY YEAR',
        ca: 'PER ANY',
      },
      filterType: 'byYear',
      menuTitle: {
        text: {
          es: 'TODOS LOS AÑOS',
          en: 'ALL YEARS',
        },
      },
      filterItems: [
        {
          category: '2019',
          value: '2019',
          text: {
            es: '2019',
            en: '2019',
            ca: '2019',
          },
        },
        {
          category: '2018',
          value: '2018',
          text: {
            es: '2018',
            en: '2018',
            ca: '2018',
          },
        },
        {
          category: '2017',
          value: '2017',
          text: {
            es: '2017',
            en: '2017',
            ca: '2017',
          },
        },
      ],
    },
    {
      filterHeading: {
        es: 'POR TIPO DE MATERIAL',
        en: 'BY TYPE OF MATERIAL',
        ca: 'PER TIPO DE MATERIAL',
      },
      menuTitle: {
        text: {
          es: 'TODOS LOS Materiales',
          en: 'ALL Materials',
        },
      },
      filterItems: [
        {
          category: 'logos',
          value: 'logos',
          text: {
            ca: 'Logotips i manuals',
            en: 'Logos and manuals',
            es: 'Logotipos y manuales',
          },
        },
        {
          category: 'photos',
          value: 'photos',
          text: {
            ca: 'Fotografia',
            en: 'Photography',
            es: 'Fotografía',
          },
        },
        {
          category: 'material',
          value: 'material',
          text: {
            ca: 'Material gràfic',
            en: 'Graphic material',
            es: 'Material gráfico',
          },
        },
        {
          category: 'press',
          value: 'press',
          text: {
            ca: 'Comunicats',
            en: 'Releases',
            es: 'Comunicados',
          },
        },
        {
          category: 'dossiers',
          value: 'dossiers',
          text: {
            ca: 'Dossiers de premsa',
            en: 'Press kit',
            es: 'Dossiers de prensa',
          },
        },
      ],
      filterType: 'byMaterial',
    },
    {
      filterHeading: {
        es: 'POR MOTIVO',
        en: 'By Reason',
        ca: 'PER MOTIU',
      },
      menuTitle: {
        text: {
          es: 'ARTISTAS',
          en: 'ARTISTS',
        },
      },
      filterItems: [
        {
          category: 'monday',
          value: 'monday',
          text: {
            ca: 'Dilluns',
            en: 'Monday',
            es: 'Lunes',
          },
        },
        {
          category: 'tuesday',
          value: 'tuesday',
          text: {
            ca: 'Dimarts',
            en: 'Tuesday',
            es: 'Martes',
          },
        },
        {
          category: 'wednesday',
          value: 'wednesday',
          text: {
            ca: 'Dimecres',
            en: 'Wednesday',
            es: 'Miércoles',
          },
        },
        {
          category: 'thursday',
          value: 'thursday',
          text: {
            ca: 'Dijous',
            en: 'Thursday',
            es: 'Jueves',
          },
        },
        {
          category: 'friday',
          value: 'friday',
          text: {
            ca: 'Divendres',
            en: 'Friday',
            es: 'Viernes',
          },
        },
        {
          category: 'saturday',
          value: 'saturday',
          text: {
            ca: 'Dissabte',
            en: 'Saturday',
            es: 'Sábado',
          },
        },
        {
          category: 'sunday',
          value: 'sunday',
          text: {
            ca: 'Diumenge',
            en: 'Sunday',
            es: 'Domingo',
          },
        },
      ],
      filterType: 'byDay',
    },
  ],
};
class PressArea extends React.Component {
  state = {
    params: [],
    filtered: [],
    activeGalleryItems: [],
    status: 'loading',
    isModal: false,
  }

  componentDidMount() {
    const { match: { params }, components } = this.props;
    if (params.category) {
      const filters = {
        byMaterial: [params.category],
      };
      this.onHandleCollapsibleMenuSelect(filters);
    }
    // getPostsBySlugNameQuery([])
  }

  handleactiveGallery = (activeGallery) => {
    this.fetchMamObjectsBySlugnames(activeGallery.objects);
  }

  fetchMamObjectsBySlugnames = (slugnames) => {
    getMamObjectsBySlugNameQuery(slugnames).then((res) => {
      if (res) {
        this.setState({ activeGalleryItems: res, status: 'loaded' });
      }
    }).catch((err) => {
      console.log('Some error happened on fetch of fetchPostDescription:', err);
    });
  }

  onHandleCollapsibleMenuSelect = (filters) => {
    const { isLoading } = this.state;
    const { match, history } = this.props;

    if (filters.byMaterial && filters.byMaterial.length) {
      this.setState({ filters });
      return history.push(`/${match.params.lang}/press/press-area/${filters.byMaterial[0]}`);
    }
    const { params } = this.state;
    const activeGallery = params.filter(gallery => gallery.tags.includes(filters.byMaterial[0]));
    if (activeGallery.length) {
      this.handleactiveGallery(activeGallery);
      this.setState({
        filtered: activeGallery,
        status: 'loaded',
      });
    } else {
      this.setState({ filtered: [], status: 'empty' });
    }
    const { activeGalleryItems } = this.state;
    const filtered = handleFilter(filters, activeGalleryItems);
    console.log(filtered, 'filtered');
  }

  handleSearchInput = (query, lang, filters) => {
    const { location: { pathname } } = this.props;
    const { activeGalleryItems } = this.state;
    let fileReadableName;
    let description;
    const filteredWithSearch = activeGalleryItems.filter((item) => {
      if (item.fileReadableName) { fileReadableName = item.fileReadableName.en.toLowerCase(); } else { fileReadableName = ''; }
      if (item.description) { description = item.description.en.toLowerCase(); } else { description = ''; }
      return ((fileReadableName.indexOf(query.toLowerCase()) !== -1) || (description.indexOf(query.toLowerCase()) !== -1));
    });
    if (query) {
      this.setState({ filtered: filteredWithSearch });
    } else {
      this.onHandleCollapsibleMenuSelect(filters);
    }
  }

  getQueryVariable = (variable) => {
    const { location: { search } } = this.props;
    const vars = search.split('&');
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split('=');
      if (pair[0] === `?${variable}`) { return pair[1]; }
    }
    return (false);
  }

  render() {
    const {
      match,
      location: { pathname },
    } = this.props;
    const {
      filtered, status,
    } = this.state;
    return (
      <RoutesContext.Consumer>
        {
        ({ language: contextLanguage, enteredFrom }) => {
          const localization = getLocalization(contextLanguage, enteredFrom, match);
          return (
            <React.Fragment>
              <div className="mt-4 w-full">
                <DoubleTitle primaryText={{ es: 'Area de prensa', en: 'Press area', ca: 'Area de prensa' }} />
              </div>
              {Object.keys(menu).length > 0
              && (
                <Filter {...menu} onHandleCollapsibleMenuSelect={this.onHandleCollapsibleMenuSelect} handleSearchInput={this.handleSearchInput} slugname="press-area" />
              )
              }
              <div className="flex flex-wrap md:-mx-2 lg:-mx-2 xl:-mx-2 mt-2 w-full">
                {filtered.length && status === 'loaded'
                  ? <ComponentIterator components={filtered} />
                  : (
                    <div className="py-2 w-full flex justify-center mt-2" onClick={this.onHandleClick}>
                      { status === 'empty'
                        && (
                          <span className="font-americaMonoBold text-black text-center text-14 uppercase border-b-2 border-black py-1">
                        No items available
                          </span>
                        )}
                      {status === 'loading' && (
                      <span className="font-americaMonoBold text-black text-center text-14 uppercase border-b-2 border-black py-1">
                    Loading
                      </span>
                      ) }
                    </div>
                  )}
              </div>
            </React.Fragment>
          );
        }
      }
      </RoutesContext.Consumer>
    );
  }
}

PressArea.propTypes = {
  params: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.shape({
      en: PropTypes.string.isRequired,
      es: PropTypes.string.isRequired,
    }).isRequired,
  })),
  hasFilters: PropTypes.bool,
  showFilterAs: PropTypes.string,
  type: PropTypes.string.isRequired,
  history: PropTypes.shape({}).isRequired,
  menu: PropTypes.shape({}),
  location: PropTypes.shape({}).isRequired,
};

PressArea.defaultProps = {
  hasFilters: false,
  showFilterAs: 'dropdown',
  menu: {},
  params: [],
};

export default withRouter(PressArea);
