import translation from './__translationHelper';
import header from './_header';
import footer from './_footer';

const data = [
  { ...header },
  {
    type: 'Forum',
    title: translation('¡Únete a la conversación!'),
    intro: translation('¿Quieres estar más al día de las novedades del Primavera sound y toda su comunidad? A partir de ahora puedes unirte a nuestro servidor de Discord y hacer tus preguntas sobre Artistas, conciertos, mentoring sessions, tecnología o simplemente compartir inquietudes sobre cualquier otro tema relacionado con el festival y/o la música.'),
    subtitle: translation('Bienvenido a la comunidad'),
    text: translation('Canales para realizar preguntas sobre #Acreditaciones, #artistas, #reuniones, #mentoringsessions, etc. O cualquier pregunta en #general. Por favor escribe toda tu pregunta en un solo mensaje y brindando todos los detalles que permiten a otros ayudarte. Estos son algunos de los canales activos: Canal para establecer una #conversacion con la comunidad de cualquier tema de interés general. Invitaciones a #reuniones. Dudas sobre #mentoringsessions. Ofertas de #empleo, contratos, postulaciones. Enlaces a tutoriales, #recursos y recomendaciones sobre artistas o el festival. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras dignissim justo ut lorem imperdiet pulvinar. Vestibulum nec enim eros. Phasellus vel odio quis odio aliquam sagittis. In sollicitudin mi in odio condimentum, eu consectetur tortor vulputate. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. No esperes más.'),
    labelJoin: translation('Únete al canal del PS PRO'),
    labelJoinNow: translation('¡Únete ahora!'),
    terms: translation('Antes debes leer nuestro'),
    labelTerms: translation('código de conducta.'),
    url: '/',
    termsUrl: '/',
  },
  { ...footer },
];

export default data;
