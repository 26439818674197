/* eslint-disable no-nested-ternary */
import React from 'react';
import { PropTypes } from 'prop-types';
import Modal from 'react-responsive-modal';
import translateObj from '../../../util/translateObj';

const modalStyles = {
  modal: {
    background: 'rgba(0, 0, 0, 0)',
    boxShadow: 'none',
    width: '100vw',
    maxWidth: 'none',
    padding: '5rem',
  },
  overlay: {
    // background: 'rgba(0, 0, 0, 0.5)',

  },
  closeButton: {
    top: '18px',
    right: '18px',
    background: '#F25B29',
  },
};

const ZoneCardButtonWithinStreamModule = ({
  language, title, url, text, tokenQuery, index, userNickName = 'Fellow PSsounder', handleOpenStreamModule,
}) => {
  const nickNameStringParam = `#userInfo.displayName="${userNickName}"`;
  const queryString = tokenQuery ? tokenQuery + nickNameStringParam : nickNameStringParam;
  const jitsiFullUrl = url + queryString;
  const cardTitle = translateObj(title, language);
  const cardText = translateObj(text, language);
  const handleOpenIframe = () => {
    handleOpenStreamModule(jitsiFullUrl);
  };


  return (
    <>
      <div className="ml-0 bg-psworld-zone-red p-2 w-full hover:bg-psworld-zone-red-light text-psworld-text-grey trans sm:mx-0 m-psworld-box cursor-pointer" onClick={handleOpenIframe}>
        <div className="pr-4">
          <h3 className="font-bold mb-1 font-americaBold lg:text-base md:text-base text-2xl">{`R#${index + 1}${' '}${cardTitle}`}</h3>
          <p className="text-xs font-americaBold leading-regular">{cardText}</p>
        </div>
      </div>
    </>
  );
};


ZoneCardButtonWithinStreamModule.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  text: PropTypes.shape().isRequired,
  tokenQuery: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  userNickName: PropTypes.string,
  handleOpenStreamModule: PropTypes.func.isRequired,

};
ZoneCardButtonWithinStreamModule.defaultProps = {
  userNickName: 'Fellow PSsounder',
};

export default ZoneCardButtonWithinStreamModule;
