import translation from './__translationHelper';
import header from './_header';
import footer from './_footer';

const data = [
  { ...header },
  {
    type: 'RecoverPasswordMusicMeets',
    title: translation('Te ayudamos a recuperar tu contraseña'),
    text: translation('Te enviamos un enlace a tu email para que puedas restablecer tu contraseña.'),
    labelEmail: translation('Escribe tu email'),
    labelSubmit: translation('Enviar'),
    labelCancel: translation('Cancelar'),
  },
  { ...footer },
];

export default data;
