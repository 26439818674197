const translation = {
  title: {
    en: 'Invitation sent!',
    es: '¡Invitación enviada!',
    ca: 'Invitació enviada!',
    pt: 'Convite enviado!',
  },
  text: {
    en: 'Your invitation has been sent successfully. We will notify you when the accredited confirm attendance.',
    es: 'Tu invitación ha sido enviada correctamente. Te notificaremos cuando los acreditados confirmen la asistencia.',
    ca: 'La teva invitació ha estat enviada correctament. Et notificarem quan els acreditats confirmin lassistència.',
    pt: 'Seu convite foi enviado com sucesso. Avisaremos quando os credenciados confirmarem presença.',
  },
  labelNewReunion: {
    en: 'Do you want to create another meeting?',
    es: '¿Quieres crear otra reunión?',
    ca: 'Vols crear una altra reunió?',
    pt: 'Deseja criar outra reunião?',
  },
  buttonNewReunion: {
    en: 'New meeting',
    es: 'Nueva reunión',
    ca: 'Nova reunió',
    pt: 'Nova reunião',
  },
};

export default translation;
