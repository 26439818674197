import React, {
  createContext, useContext, useState, useEffect,
} from 'react';
import md5 from 'crypto-js/md5';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import { PropTypes } from 'prop-types';
import chatFirebaseToken from '../../../../api/graphql/mutations/chatFirebaseToken';
import { useUser } from '../../../../context/userProfileContext';

import useInterval from '../hooks/useInterval';
import { generateUserDocument, disconnectUser } from '../utils/helpers';

const FirebaseAuthContext = createContext(null);
const firebaseConfig = {
  apiKey: 'AIzaSyD1AZqyGaQjneYMtZfI6tA2tXADAT_rdGY',
  authDomain: 'api-project-799824656825.firebaseapp.com',
  projectId: 'api-project-799824656825',
};

firebase.initializeApp(firebaseConfig);

export const firestore = firebase.firestore();
export const fieldValue = firebase.firestore.FieldValue;
export const { arrayUnion, arrayRemove } = fieldValue;
export const firebaseauth = firebase.auth();

export const useFirebaseAuth = () => {
  const {
    firebaseToken, setNewToken, isModerator, firebaseUser,
  } = useContext(FirebaseAuthContext);
  const data = {};
  data.isModerator = isModerator;
  data.firebaseToken = firebaseToken;
  data.setNewToken = setNewToken;
  data.firebaseUser = firebaseUser;
  return data;
};

export const FirebaseAuthProvider = ({
  moderators, channels, children, postSlugName, chatChannelID,
}) => {
  const { userEmail, userNickName } = useUser();
  const id = md5(userEmail).toString();
  const isModerator = moderators.includes(id);
  const [firebaseUser, setFirebaseUser] = useState(null);
  const listeners = [];

  const signOut = () => {
    firebase.auth().signOut().then(() => {
      setFirebaseUser(null);
      // console.log('logged out');
    }).catch((error) => {
      // An error happened.
    });
  };

  const setNewToken = async () => {
    if (userEmail) {
      signOut();
      chatFirebaseToken({ channels, postSlugName })
        .then((token) => {
          if (token) {
            firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
              .then(() => {
                // Existing and future Auth states are now persisted in the current
                // session only. Closing the window would clear any existing state even
                // if a user forgets to sign out.
                // ...
                // New sign-in will be persisted with session persistence.
                firebase.auth().signInWithCustomToken(token);
              })
              .catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage);
              });
          } else {
            console.log('chatFirebaseToken = null');
          }
        });
    }
  };

  useEffect(() => {
    const unSubscribeAuth = firebaseauth.onAuthStateChanged(async (userAuth) => {
      if (userAuth && !firebaseUser) {
        const userId = userAuth.uid;
        const data = {
          userId,
          nickname: userNickName,
        };
        const user = await generateUserDocument(data);
        setFirebaseUser(user);
      }
    });
    listeners.push(unSubscribeAuth);
  }, []);

  const singInWithNewToken = () => {
    // For an email/password user. Prompt the user for the password again.
    chatFirebaseToken({ channels, postSlugName })
      .then((token) => {
        if (token) {
          firebase.auth().signInWithCustomToken(token)
            .then((result) => {
              // User successfully reauthenticated. New ID tokens should be valid.
              // console.log(result, 'result in new ttoken');
            })
            .catch((error) => {
              // console.log(error, 'in new tokens');
              // An error occurred.
            });
        } else {
          console.log('chatFirebaseToken = null');
        }
      });
  };

  const { start, stop } = useInterval(singInWithNewToken);

  useEffect(() => {
    if (userEmail) {
      setNewToken();
      // get new token every hour (58min)
      start(3480000);
    }
  }, [userEmail]);

  useEffect(() => () => {
    if (chatChannelID) {
      disconnectUser({ channelUrl: chatChannelID });
    }
    signOut();
    stop();
    listeners.forEach((listener) => listener());
  }, []);

  return (
    <FirebaseAuthContext.Provider value={{
      singInWithNewToken, isModerator, firebaseUser,
    }}
    >
      {children}
    </FirebaseAuthContext.Provider>
  );
};

FirebaseAuthProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  channels: PropTypes.arrayOf(PropTypes.string),
  moderators: PropTypes.arrayOf(PropTypes.string),
  postSlugName: PropTypes.string,
  chatChannelID: PropTypes.string,
};
FirebaseAuthProvider.defaultProps = {
  channels: [],
  moderators: [],
  postSlugName: '',
  chatChannelID: '',
};
