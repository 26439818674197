import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';
import translateObj from '../../util/translateObj';


class InputTextButton extends React.Component {
  state = {
    query: '',
  };

  handleOnChange = (e, lang) => {
    const { handleOnChange } = this.props;
    this.setState({
      query: e.target.value,
    }, () => {
      const { query } = this.state;
      if (handleOnChange) {
        handleOnChange(query, lang);
      }
    });
  }

  handleKeyPress = (localization, e) => {
    const { query } = this.state;
    if (e.key === 'Enter') {
      const { searchCallback } = this.props;
      if (searchCallback) {
        searchCallback(query, localization.language);
      }
    }
  }

  handleClick = () => {
    const { handleClick } = this.props;
    const { query } = this.state;
    if (handleClick) {
      handleClick(query);
    }
  }

  render() {
    const {
      name,
      params: {
        text,
        placeholder,
        title,
        searchIcon,
      },
      match,
      black,
    } = this.props;
    const { query } = this.state;

    return (
      <RoutesContext.Consumer>
        {
        ({ language: contextLanguage, enteredFrom }) => {
          const localization = getLocalization(contextLanguage, enteredFrom, match);

          const inputStyle = searchIcon ? 'pl-12 py-2 pr-4' : 'py-2 px-4 md:py-1 md:px-2';

          return (
            <div data-name="input-text-button">
              {title && <span className="text-black text-xs sm:text-center text-right font-americaMonoRegular uppercase mb-4 w-full inline-block">{translateObj(title, localization.language)}</span>}
              <div className="relative">

                {searchIcon && <span className={`${searchIcon} absolute`} style={{ top: '50%', transform: 'translateY(-50%)', left: '1rem' }} />}
                <input
                  value={query}
                  onChange={e => this.handleOnChange(e, localization.language)}
                  onKeyPress={e => this.handleKeyPress(localization, e)}
                  type="text"
                  name={name}
                  placeholder={placeholder[localization.language]}
                  className={`outline-none w-full  bg-transparent leading-none font-timesRegular text-left border-black border-2 text-base sm:text-xs rounded-full ${inputStyle}`}
                />
                <button onClick={this.handleClick} type="button" className={`absolute pin-r pin-t pin-b leading-normal text-xxs rounded-full border-2  border-black uppercase ${black ? 'bg-black text-white' : ''} py-2 px-4 md:py-1 md:px-2`}>

                  {translateObj(text, localization.language)}

                  <span className="font-icon icon-arrow-right text-xxs pl-3 pr-1 sm:pr-0" />
                </button>
              </div>
            </div>
          );
        }}
      </RoutesContext.Consumer>
    );
  }
}

InputTextButton.propTypes = {
  name: PropTypes.string.isRequired,
  match: PropTypes.shape().isRequired,
  // eslint-disable-next-line react/require-default-props
  black: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  handleClick: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  handleOnChange: PropTypes.func,
  // eslint-disable-next-line react/no-unused-prop-types
  searchCallback: PropTypes.func,
  // eslint-disable-next-line react/no-unused-prop-types
  params: PropTypes.shape().isRequired,
};

InputTextButton.defaultProps = {
  black: false,
  searchCallback: null,
};

export default withRouter(InputTextButton);
