import React from 'react';
import { PropTypes } from 'prop-types';

// import StreamingVideoPlayerMainPlayerAll from '../StreamingVideoPlayerMainPlayerAll';
// import StreamingPlayerPictureSmall from '../StreamingPlayerPictureSmall';
import LoadIcon from '../../LoadIcon';

const VimeoVideoScreen = ({
  isLoading,
  mainVideo,
  isMobile,
  // arrayOfVideos,
  // handlePlayingSmallScreens,
  // shouldMainVideoStart,
  // handleOnReadyTrueForAll,
  PLAYER_RATIO,
  // handleSwitchingVideos,
  // shouldSmallVideoStart,
  // activeSlide,
}) => (
  <>
    <div className={`bg-black ${isLoading && 'sm:min-h-10 md:min-h-20 min-h-256'}  flex flex-col justify-center`}>
      {isLoading && <LoadIcon color="black" type="ThreeDots" />}
      {mainVideo && (
        <div className={`${isLoading ? 'hidden' : 'relative w-full bg-black'} `} style={{ paddingTop: PLAYER_RATIO }}>
          <iframe
            title="vimeoVideo"
            className="absolute pin-t pin-l w-full"
            src={mainVideo}
            width="100%"
            height="100%"
            frameBorder="0"
            allow="fullscreen"
            allowFullScreen
          />
        </div>
      )}
    </div>
  </>
);

VimeoVideoScreen.propTypes = {
  // arrayOfVideos: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  mainVideo: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  isMobile: PropTypes.bool,
  // handlePlayingSmallScreens: PropTypes.func.isRequired,
  // shouldMainVideoStart: PropTypes.bool,
  // handleOnReadyTrueForAll: PropTypes.func.isRequired,
  // handleSwitchingVideos: PropTypes.func.isRequired,
  // shouldSmallVideoStart: PropTypes.bool,
  PLAYER_RATIO: PropTypes.string,
  // activeSlide: PropTypes.number,
};

VimeoVideoScreen.defaultProps = {
  isLoading: true,
  isMobile: false,
  // shouldMainVideoStart: null,
  // shouldSmallVideoStart: false,
  PLAYER_RATIO: '56.25%',
  // activeSlide: 0,
};

export default VimeoVideoScreen;
