/* eslint-disable brace-style */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-array-index-key */

import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import moment from 'moment';
import { TweenMax, Sine } from 'gsap';
import LauncherItemSingle from './LauncherItemSingle';
import LauncherItemDouble from './LauncherItemDouble';
import LauncherItemTriple from './LauncherItemTriple';
import LauncherItemConcert from './LauncherItemConcert';
import LauncherItemRadio from './LauncherItemRadio';
import LauncherInstagram from './LauncherInstagram';
import LauncherTwitter from './LauncherTwitter';
import Filter from '../Collapsible/Filter';

import getPostsList from '../../../api/graphql/queries/getPostsList';

import withAllContexts from '../../../context/withAllContexts';

import handleFilter from '../Collapsible/handleFilter';
import PSResponsiveUtility from '../LineUp/PSResponsiveUtility';

let counterForSingleDouble = 0;
let counterForSingleDoubleBigger = 0;

let serverRendered = false;

// TODO : Recheck the logic of server side rendering after the launch
class Launcher extends React.Component {
  state = {
    highlights: [],
    highlightsForMobile: [],
    highlightsForBiggerScreen: [],
    // filteredHighLights: [],
    status: 'loaded',
    from: 0,
    quantity: 21,
    findCategory: ['home'],
    deviceWidth: null,
    ssrDone: false,
    filters: [],
  };

  responsiveUtility = React.createRef();

  // componentWillMount = () => {
  //   const { ssrDone } = this.state;
  //   if(!ssrDone){
  //     const { findCategory, quantity: qty } = this.props;
  //     this.fetchHighlights(findCategory, qty);
  //   }
  // }

  componentDidMount = () => {
    const { findCategory: findCategoryFromProps, quantity: qty } = this.props;
    // console.log("TCL: Launcher -> componentDidMount -> findCategory", findCategoryFromProps)
    const { quantity: quantityFromState } = this.state;

    this.getWidth();

    const quantity = qty || quantityFromState || 3;
    const category = findCategoryFromProps.length > 0 ? findCategoryFromProps : ['home'];

    this.setState(
      {
        quantity,
        findCategory: category,
        ssrDone: true,
      },
      () => {
        // console.log("componentDidMount", category);
        this.fetchHighlights(category, quantity);
      },
    );

    // this.changeCategory(category || 'home')
    // const category = findCategory || 'home';

    // if (!match.params.post) {
    //   if (match.params.section) {
    //     category = match.params.section;
    //   } else {
    //     category = 'home';
    //   }
    // }

    /*
    After mount and before we fetch the highlights data for the CSR and update the state with the fetched data, we set serverRendered = false to clear the LauncherContext
    data immediately after initial SSR, to make sure the CSR will use the new data from the state. This also guarantees that all subsequent CSRs will use
    the data from the state only.
    */
    // serverRendered = false;
    // this.fetchHighlights(category);
  };

  componentDidUpdate = (prevProps) => {
    const { findCategory, quantity: qty } = this.props;
    const { quantity: qtyFromState } = this.state;
    const quantity = qty || qtyFromState || 3;
    if (
      JSON.stringify(prevProps.findCategory) !== JSON.stringify(findCategory)
    ) {
      // console.log("This is on!", JSON.stringify(prevProps.findCategory) !== JSON.stringify(findCategory), prevProps.findCategory, findCategory)
      this.setState(
        {
          from: 0,
          highlights: [],
          highlightsForMobile: [],
          highlightsForBiggerScreen: [],
        },
        () => {
          this.fetchHighlights(findCategory, quantity);
        },
      );
    }
  };

  changeCategory = (newCategory) => {
    this.setState(
      {
        findCategory: newCategory,
        from: 0,
        highlights: [],
      },
      () => {
        TweenMax.to(window, 0.25, { scrollTo: 0, ease: Sine.easeOut });
      },
    );
  };

  // For performance, re-renders are strictly limited to state changes only.
  shouldComponentUpdate = (nextProps, nextState) => {
    const { findCategory } = this.props;
    const { highlights, deviceWidth } = this.state;
    return (
      JSON.stringify(nextProps.findCategory) !== JSON.stringify(findCategory)
      || JSON.stringify(nextState.highlights) !== JSON.stringify(highlights)
      || nextState.deviceWidth !== deviceWidth
      || nextState.highlights.length === highlights.length
    );

    // ) {
    //   // console.log(" >> Going to change", nextState.highlights !== this.state.highlights, this.state.highlights, nextState.highlights)
    //   // console.log(" >> Going to change", nextProps.findCategory !== this.props.findCategory, nextProps.findCategory, this.props.findCategory)
    //   return true;
    // }
    // // console.log("no change necessary", nextProps.findCategory, this.props.findCategory)
    // return false;
  };

  // ----------------------------------------------------------------------------------------------------------------------------------------

  fetchHighlights = (category, qty = null) => {
    if (!category) return;
    // console.log('TCL: Launcher -> fetchHighlights -> category', category);
    const { from, quantity: quantityFromState, ssrDone } = this.state;

    if (!ssrDone) return;

    const quantity = qty || quantityFromState;

    const to = from + quantity;

    getPostsList(category, from, quantity).then((postsList) => {
      const fetchedHighlights = [];
      const fetchedHighlightsForMobile = [];
      const fetchedHighlightsForBigScreen = [];
      if (from === 0) counterForSingleDouble = 0;
      if (from === 0) counterForSingleDoubleBigger = 0;

      if (postsList) {
        if (postsList.length && postsList.length > 0) {
          postsList.map((post) => {
            const { slugName } = post;
            if (slugName !== category[0]) {
              // will remove the posts that are home of the section
              fetchedHighlights.push(this.createHighlightsArrayItem(post));
              fetchedHighlightsForMobile.push(
                this.createHighlightsArrayItem(post, true),
              );
              fetchedHighlightsForBigScreen.push(
                this.createHighlightsArrayItem(post, 'bigger'),
              );
            }
          });

          if (category.includes('tours')) {
            fetchedHighlights.sort(
              (a, b) => a.unformattedDate - b.unformattedDate,
            );
            fetchedHighlightsForMobile.sort(
              (a, b) => a.unformattedDate - b.unformattedDate,
            );
          }

          this.setState(prevState => ({
            highlights: [...prevState.highlights, ...fetchedHighlights],
            highlightsForMobile: [
              ...prevState.highlights,
              ...fetchedHighlightsForMobile,
            ],
            highlightsForBiggerScreen: [
              ...prevState.highlights,
              ...fetchedHighlightsForBigScreen,
            ],
            launcherItems: [...prevState.highlights, ...fetchedHighlights],
            status: 'loaded',
            from: to,
          }));
        } else {
          this.setState({
            status: 'end',
          });
        }
      } else {
        this.setState({
          status: 'failed',
        });
      }
    });
  };

  createHighlightsArrayItem = (item, isMobile = false) => {
    const { mustBeSingle, isNews } = this.props;
    const { postDescription, postCategory, slugName } = item;

    const date = typeof postDescription.date === 'string'
      ? moment(postDescription.date, 'x')
      : null;

    if (typeof postDescription.image === 'string') {
      // TODO: change 'en' by current user language
      postDescription.image = { en: postDescription.image };
    }

    let singleDouble = postDescription.itemLayout; // 'single';
    if (!isNews) {
      if (isMobile) {
        if (isMobile === 'bigger' && !mustBeSingle) {
          // console.log("TCL: Launcher -> createHighlightsArrayItem -> isMobile", isMobile);
          singleDouble = counterForSingleDoubleBigger === 0
            || counterForSingleDoubleBigger === 2
            ? 'single'
            : 'double';
          counterForSingleDoubleBigger++;
          if (counterForSingleDoubleBigger >= 3) {
            counterForSingleDoubleBigger = 0;
          }
        } else {
          singleDouble = 'single';
        }
      }
    } else {
      singleDouble = counterForSingleDoubleBigger === 0 ? 'single' : 'triple';
      counterForSingleDoubleBigger++;
    }

    if (mustBeSingle && !isNews) {
      singleDouble = 'concert';
    } else if (!isMobile && !isNews) {
      singleDouble = counterForSingleDouble === 0 || counterForSingleDouble === 5
        ? 'single'
        : 'double';
      counterForSingleDouble++;
      if (counterForSingleDouble >= 6) counterForSingleDouble = 0;
    }

    // const cta = {
    //   url: '',
    //   isDisabled: false,
    //   text: {
    //     en: 'BUY TICKETS',
    //     es: 'COMPRAR ENTRADAS',
    //     ca: 'COMPRAR ENTRADES',
    //     pt: 'COMPRAR BILHETES',
    //   },
    // };

    const highlight = {
      date: date ? date.format('DD / MM / YYYY') : '',
      unformattedDate: Number(postDescription.date),
      image: postDescription.image,
      itemLayout: singleDouble,
      position: postDescription.position,
      tags: postDescription.tags,
      title: postDescription.title,
      subtitle: postDescription.description,
      url: postDescription.url || `/${postCategory[0]}/${slugName}`,
      cta: postDescription.cta ? postDescription.cta : {},
    };

    return highlight;
  };

  onHandleClick = () => {
    // const { match } = this.props;

    // let category = null;

    // if (!match.params.post) {
    //   if (match.params.section) {
    //     category = match.params.section;
    //   } else {
    //     category = 'home';
    //   }
    // }

    const { findCategory: category } = this.state;

    this.setState({ status: 'loading' }, this.fetchHighlights(category));
  };

  // ------------ Filter -------------- //

  onHandleCollapsibleMenuSelect = (filters) => {
    let events = ['news'];
    if (filters.byEvent && filters.byEvent.length) {
      events = [...filters.byEvent, ...events];
    }
    console.log(events);
    this.setState(
      {
        from: 0,
        highlights: [],
        highlightsForMobile: [],
        highlightsForBiggerScreen: [],
        findCategory: events,
        status: 'loading',
      },
      () => {
        this.fetchHighlights(events);
      },
    );
  };

  // ----------------------------------------------------------------------------------------------------------------------------------------

  checkItemType = (highlight) => {
    const { itemLayout } = highlight[0];
    // eslint-disable-next-line max-len
    return itemLayout === 'double'
      ? highlight.map((content, index) => (
        <LauncherItemDouble
          changeCategory={newCategory => this.changeCategory(newCategory)}
          {...content}
          position={index}
          key={`launcherItem-${index}`}
        />
      ))
      : highlight.map((content, index) => (
        <LauncherItemTriple
          changeCategory={this.changeCategory}
          {...content}
          position={index}
          key={`launcherItem-${index}`}
        />
      ));
  };

  launcherItemArrayBuilder = (array) => {
    // helper to make arrays of items depending on how many items are contained in each LauncherItem
    const newArray = [];

    array.map((highlight) => {
      const lastNewArrayItem = newArray[newArray.length - 1];
      // check if last item in newArray is an array if so,
      // check if it accepts 2 o 3 children and fill them until completed
      if (Array.isArray(lastNewArrayItem)) {
        if (
          lastNewArrayItem[0].itemLayout === 'triple'
          && newArray[newArray.length - 1].length < 3
        ) {
          newArray[newArray.length - 1].push(highlight);
        } else if (
          lastNewArrayItem[0].itemLayout === 'double'
          && newArray[newArray.length - 1].length < 2
        ) {
          newArray[newArray.length - 1].push(highlight);
        } else {
          highlight.itemLayout === 'single'
            ? newArray.push(highlight)
            : newArray.push(Array.of(highlight));
        }
      }
      // if last itemLayoutin newArray is not an Array just push the element
      else {
        highlight.itemLayout === 'single'
        || highlight.itemLayout === 'concert'
        || highlight.itemLayout === 'radio'
          ? newArray.push(highlight)
          : newArray.push(Array.of(highlight));
      }
    });
    return newArray;
  };

  launcherItemBuilder = (highlight, index, itemsPerLine) => {
    const { deviceWidth } = this.state;
    let classItemsPerLine;

    switch (itemsPerLine) {
      case 3:
        classItemsPerLine = 'w-1/3';
        break;
      // case 3:
      case 2:
        classItemsPerLine = 'w-1/2';
        break;
      default:
        classItemsPerLine = 'w-1/2';
    }

    if (highlight.itemLayout === 'concert') {
      return (
        <div
          className={`launcherItem ${classItemsPerLine} md:w-1/2 sm:w-full py-2 px-2 sm:px-0`}
          key={`launcherContainer-${index}`}
        >
          <LauncherItemConcert
            {...highlight}
            position={index}
            key={`launcherItem-${index}`}
          />
        </div>
      );
    }
    if (highlight.itemLayout === 'single') {
      // eslint-disable-next-line max-len
      return (
        <div
          className={`launcherItem ${classItemsPerLine} llg:w-1/3 lg:w-1/2 md:w-1/2 sm:w-full py-2 px-2 sm:px-0`}
          key={`launcherContainer-${index}`}
        >
          <LauncherItemSingle
            changeCategory={newCategory => this.changeCategory(newCategory)}
            {...highlight}
            position={index}
            key={`launcherItem-${index}`}
          />
        </div>
      );
    }
    if (highlight.itemLayout === 'radio') {
      return (
        <div
          className={`launcherItem ${classItemsPerLine} md:w-1/2 sm:w-full py-2 px-2 sm:px-0`}
          key={`launcherContainer-${index}`}
        >
          <LauncherItemRadio
            {...highlight}
            position={index}
            key={`launcherItem-${index}`}
          />
        </div>
      );
    }
    if (highlight.itemLayout === 'instagram') {
      return (
        <div
          className={`launcherItem ${classItemsPerLine} md:w-1/2 sm:w-full py-2 px-2 sm:px-0`}
          key={`launcherContainer-${index}`}
        >
          <LauncherInstagram
            {...highlight}
            position={index}
            key={`launcherItem-${index}`}
          />
        </div>
      );
    }
    if (highlight.itemLayout === 'twitter') {
      return (
        <div
          className={`launcherItem ${classItemsPerLine} md:w-1/2 sm:w-full py-2 px-2 sm:px-0`}
          key={`launcherContainer-${index}`}
        >
          <LauncherTwitter
            {...highlight}
            position={index}
            key={`launcherItem-${index}`}
          />
        </div>
      );
    }
    return (
      <div
        className={`${
          Array.isArray(highlight)
          && highlight.length > 0
          && highlight[0].itemLayout === 'triple'
            ? `${highlight[0].itemLayout} py-0`
            : 'py-2'
        } launcherItem ${classItemsPerLine} llg:w-1/3 lg:w-1/2 md:w-1/2 sm:w-full px-2 sm:px-0`}
        key={`launcher-${index}`}
      >
        {this.checkItemType(highlight)}
      </div>
    );
  };

  getWidth = () => {
    // actions when window is resized
    const { mustBeSingle, isNews } = this.props;
    const deviceWidth = this.responsiveUtility.current.deviceWidth(true);
    if (!mustBeSingle || !isNews) {
      this.setState({ deviceWidth });
    }
  };

  render() {
    // console.log("Launcher - Rendering", this.state.highlights, this.props.findCategory);
    const {
      highlights: highlightsState,
      highlightsForMobile: highlightsForMobileState,
      highlightsForBiggerScreen: highlightsForBiggerScreenState,
      status,
      deviceWidth,
    } = this.state;
    // eslint-disable-next-line no-unused-vars
    const {
      launcherContext: { highlights: highlightsContext, clearLauncher },
      itemsPerLine,
      noLoadMore,
      hasFilters,
      menu,
      mustBeSingle,
      isNews,
    } = this.props;

    if (!serverRendered) {
      clearLauncher();
      serverRendered = true;
    }

    let highlights = [];

    /*
    If a section with a Launcher is initially SSR, then the highlights data is already stored in the LauncherContext and we can populate the
    Launcher via the context API first. Then, after the CSR is hydrated, we set serverRendered = false in componentDidMount and fetch the
    highlights data again to store it in the PWA cache. Since we set serverRendered = false, the next render update will clear the LauncherContext
    and all subsequent CSRs will use the data from the state.
    */
    if (highlightsState && highlightsState.length > 0) {
      if (mustBeSingle || isNews) {
        highlights = highlightsState;
      } else {
        if (deviceWidth > 640 && deviceWidth < 1280) {
          highlights = highlightsState;
        } else if (deviceWidth > 1279) {
          highlights = highlightsForBiggerScreenState;
        } else {
          highlights = highlightsForMobileState;
        }
      }
    }
    // else if (highlightsContext && highlightsContext.length > 0) {
    //   highlightsContext.map((highlightContext) => {
    //     highlights.push(this.createHighlightsArrayItem(highlightContext));
    //   });
    // }

    // helper to make arrays of items depending on how many items are contained in each LauncherItem

    const highlightsArray = this.launcherItemArrayBuilder(highlights);
    // To add instagram or twitter to the launcher:
    // highlightsArray.push({
    //   itemLayout: 'instagram',
    // },
    // {
    //   itemLayout: 'twitter',
    // });
    return (
      <>
        {hasFilters && (
          <Filter
            {...menu}
            hasSearchBar={false}
            onHandleCollapsibleMenuSelect={this.onHandleCollapsibleMenuSelect}
          />
        )}
        <div className="launcher flex flex-wrap pt-2 -mx-2 sm:-mx-0">
          <PSResponsiveUtility
            ref={this.responsiveUtility}
            deviceWidthCallback={this.getWidth}
          />
          {highlightsArray
            && highlightsArray.map((highlight, index) => this.launcherItemBuilder(highlight, index, itemsPerLine))}
          {!noLoadMore ? (
            <div className="py-2 w-full flex justify-center mt-2">
              <span className="font-americaMonoBold text-black text-center text-14 uppercase border-b-2 border-black py-1">
                {status === 'loading' && 'Loading...'}
                {status === 'loaded' && (
                  <span onClick={this.onHandleClick}>
                    <span className="font-icon icon-arrow-down text-12 bold" />
                    <span className="px-4 cursor-pointer">Load More</span>
                    <span className="font-icon icon-arrow-down text-12 bold" />
                  </span>
                )}
                {status === 'end'
                  && highlightsArray.length === 0
                  && 'No items available'}
                {status === 'end'
                  && highlightsArray.length > 0
                  && 'No more items available'}
                {status === 'failed' && 'Internet connection required'}
              </span>
            </div>
          ) : (
            <div />
          )}
        </div>
      </>
    );
  }
}
Launcher.propTypes = {
  launcherContext: PropTypes.shape().isRequired,
  highlights: PropTypes.arrayOf(
    PropTypes.shape({
      itemLayout: PropTypes.string.isRequired,
      image: PropTypes.shape({
        src: PropTypes.string.isRequired,
      }).isRequired,
      date: PropTypes.string.isRequired,
      tags: PropTypes.arrayOf(PropTypes.shape().isRequired),
    }),
  ),
  highlightsForBiggerScreen: PropTypes.arrayOf(
    PropTypes.shape({
      itemLayout: PropTypes.string.isRequired,
      image: PropTypes.shape({
        src: PropTypes.string.isRequired,
      }).isRequired,
      date: PropTypes.string.isRequired,
      tags: PropTypes.arrayOf(PropTypes.shape().isRequired),
    }),
  ),
  highlightsForMobile: PropTypes.arrayOf(
    PropTypes.shape({
      itemLayout: PropTypes.string.isRequired,
      image: PropTypes.shape({
        src: PropTypes.string.isRequired,
      }).isRequired,
      date: PropTypes.string.isRequired,
      tags: PropTypes.arrayOf(PropTypes.shape().isRequired),
    }),
  ),
  url: PropTypes.string,
  match: PropTypes.shape().isRequired,
  itemsPerLine: PropTypes.number,
  findCategory: PropTypes.arrayOf(PropTypes.string),
  quantity: PropTypes.number,
  mustBeSingle: PropTypes.bool,
  noLoadMore: PropTypes.bool,
  hasFilters: PropTypes.bool,
  menu: PropTypes.shape({}),
};
Launcher.defaultProps = {
  url: '',
  highlights: [
    {
      itemLayout: '',
      image: {
        src: '',
      },
      date: '',
      tags: [],
    },
  ],
  highlightsForBiggerScreen: [
    {
      itemLayout: '',
      image: {
        src: '',
      },
      date: '',
      tags: [],
    },
  ],
  highlightsForMobile: [
    {
      itemLayout: '',
      image: {
        src: '',
      },
      date: '',
      tags: [],
    },
  ],
  itemsPerLine: 3,
  findCategory: [],
  quantity: 21,
  mustBeSingle: false,
  noLoadMore: false,
  hasFilters: false,
  menu: null,
};
export default withRouter(withAllContexts(Launcher));
