const translation = {
  myAccount: {
    en: 'My account',
    es: 'Mi cuenta',
    ca: 'El meu compte',
    pt: 'Minha conta',
  },
  goBack: {
    en: 'My personal area',
    es: 'Mi area personal',
    ca: 'La meva àrea personal',
    pt: 'Minha área pessoal',
  },
  goNow: {
    en: 'Go now',
    es: 'Ir ahora',
    ca: 'Anar ara',
    pt: 'Vá agora',
  },
  viewMore: {
    en: 'View more',
    es: 'Ver más',
    ca: 'Veure més',
    pt: 'Veja mais',
  },
  viewAll: {
    en: 'View all',
    es: 'Ver todo',
    ca: 'Veure tot',
    pt: 'Ver tudo',
  },
  save: {
    en: 'Save',
    es: 'Guardar',
    ca: 'Guardar',
    pt: 'Guardar',
  },
  noFavourites: {
    en: 'You have no favourite artists',
    es: 'No tienes artistas favoritos',
    ca: 'No tens artistes favorits',
    pt: 'Você não tem artistas favoritos',
  },
  noNotifications: {
    en: 'You have no notifications',
    es: 'No tienes notificacione',
    ca: 'No tens notificacions',
    pt: 'Você não tem notificações',
  },
  noPrizes: {
    en:
      'You don`t have any prize at the moment. Every action at Primavera Sound counts, so stay tuned.',
    es:
      'No tienes ningún premio de momento. Cada acción en Primavera Sound cuenta, así que estatx atentx.',
    ca:
      'No tens cap premi per ara. Cada acció a Primavera Sound compta, per tant estigues atentx!',
    pt: 'Você não tem prêmios',
  },
  noAchievements: {
    en: 'You have no achievements',
    es: 'No tienes logros',
    ca: 'No tens assoliments',
    pt: 'Você não tem conquistas',
  },
  noGenres: {
    en: 'We don’t know your music taste. Soon you will be able to edit them!',
    es: 'No nos constan tus intereses musicales. ¡Pronto los podrás editar!',
    ca: 'No ens consten els teus interessos musicals. Aviat els podràs editar!',
  },
  noFestivals: {
    en:
      'Soon you will be able to indicate the Primavera Sound festivals that interest you the most.',
    es:
      'Pronto podrás indicar los festivales Primavera Sound que te interesan más.',
    ca:
      'Aviat podràs indicar els festivals Primavera Sound que t’interessen més.',
  },
  redeemedPrize: {
    en: 'Redeemed',
    es: 'Canjeado',
    ca: 'Bescanviat',
    pt: 'Redimido',
  },
  code: {
    en: 'Code',
    es: 'Código',
    ca: 'Codi',
    pt: 'Código',
  },
  points: {
    en: 'points',
    es: 'puntos',
    ca: 'punts',
    pt: 'pontos',
  },
  achieved: {
    en: 'Achieved on',
    es: 'Conseguido el',
    ca: 'Aconseguit el',
    pt: 'Alcançado em',
  },
  festivals: {
    en: 'My Festivals',
    es: 'Mis festivales',
    ca: 'Els meus festivals',
  },
  myRewards: {
    en: 'My rewards',
    es: 'Mis premios',
    ca: 'Els meus premis',
  },
  myCollection: {
    en: 'My collection',
    es: 'Mi colección',
    ca: 'La meva col·lecció',
  },
  linkSpotify: {
    en: 'Link your Spotify account',
    es: 'Vincula tu cuenta de Spotify',
    ca: 'Enllaça el compte de Spotify',
  },
  unlinkSpotify: {
    en: 'Unlink your Spotify account',
    es: 'Desvincular tu cuenta de Spotify',
    ca: 'Desenllaça el compte de Spotify',
  },
  linkedSpotify: {
    en: 'Your Spotify account is linked',
    es: 'Tu cuenta de Spotify está vinculada',
    ca: 'El teu compte de Spotify està enllaçat',
  },
};

export default translation;
