import React, { useState, useEffect, useRef } from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';

import ComponentIterator from '../../../containers/ComponentIterator';
import imageModifier from '../helpers/imageModifier';
import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';
import translateObj from '../../../util/translateObj';
import PSResponsiveUtility from '../LineUp/PSResponsiveUtility';

const SliderBannerHome = ({
  image,
  left,
  right,
  match,
  single,
  areSlidesShort,
  noZoom = false,
  noShadow = false,
  showOpacity,
}) => {
  const [mobileArr, setMobileArr] = useState([...left, ...right]);
  const [deviceWidth, setDeviceWidth] = useState('lg');

  const responsiveUtility = useRef();
  const setMargin = (x) => {
    x.map(component => component.slider = true);
  };
  const getWidth = () => {
    // actions when window is resized
    setDeviceWidth(responsiveUtility.current.deviceScreen(true));
  };
  useEffect(() => {
    const linkButton = mobileArr.find(el => el.type === 'LinkButtonRounded');
    if (linkButton) {
      const arr = mobileArr;
      arr.push(arr.splice(arr.indexOf(linkButton), 1)[0]);
      setMobileArr(arr);
    }
    getWidth();
  }, []);
  return (
    <RoutesContext.Consumer>
      {({ language: contextLanguage, enteredFrom }) => {
        const localization = getLocalization(contextLanguage, enteredFrom, match);
        let imageurl = `url(${imageModifier(translateObj(image, localization.language), '1440x620')})`;
        if (deviceWidth === 'sm') imageurl = `url(${imageModifier(translateObj(image, localization.language), '414x420')})`;
        if (deviceWidth === 'md') imageurl = `url(${imageModifier(translateObj(image, localization.language), '768x510')})`;
        const backgroundStyle = {
          backgroundImage: imageurl,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        };
        return (
          <>
            <PSResponsiveUtility ref={responsiveUtility} deviceWidthCallback={getWidth} />
            <div
              key={image.en}
              className={`${!areSlidesShort ? 'h-screen80' : 'h-screen40'} ${showOpacity ? 'sliderComponent' : ''} md:h-carouselMD sm:h-carouselSM w-full relative flex flex-col justify-end overflow-hidden min-h-screen40`}
            >
              <div
                key={image.en}
                className={`${!areSlidesShort ? 'h-screen80' : 'h-screen40'} md:h-carouselMD sm:h-carouselSM w-full backgroundImgGray ${!noZoom && 'sliderBackground'} absolute pin-l pin-b`}
                style={backgroundStyle}
              />
              <div className="text-white flex items-end h-full relative" style={{ zIndex: 2 }}>
                <div className={`flex justify-around sm:flex-col sm:justify-end w-full xl:px-36 md:px-16 lg:px-26 sm:px-7 h-full items-end ${areSlidesShort ? 'pb-12' : 'pb-20'} sm:pb-13 md:pb-16`}>
                  {deviceWidth !== 'sm'
                    ? (
                      <>
                        {left && (
                          <div className="w-2/3 h-full flex flex-col justify-end mr-12 sm:mr-0 sm:hidden max-w-lg">
                            <div className="div">
                              <ComponentIterator components={left} slugName={image} {...setMargin(left)} />
                            </div>
                          </div>
                        )}
                        {right && (
                          <div className="w-1/3 sm:w-full sm:hidden" style={{ marginBottom: '3px' }}>
                            <ComponentIterator components={right} slugName={image} />
                          </div>
                        )}
                      </>
                    )
                    : (
                      <div className="w-full sm:block hidden">
                        <ComponentIterator components={mobileArr} slugName={image} />
                      </div>
                    )}
                </div>
              </div>
            </div>
          </>
        );
      }
      }
    </RoutesContext.Consumer>
  );
};

SliderBannerHome.propTypes = {
  image: PropTypes.shape({}),
  right: PropTypes.arrayOf(PropTypes.shape()),
  left: PropTypes.arrayOf(PropTypes.shape()),
  single: PropTypes.bool,
  areSlidesShort: PropTypes.bool,
  showOpacity: PropTypes.bool,
};

SliderBannerHome.defaultProps = {
  right: null,
  left: null,
  image: {},
  single: true,
  areSlidesShort: false,
  showOpacity: true,
};

export default withRouter(SliderBannerHome);
