import React from 'react';

const AddUserIcon = () => (
  <svg width="100%" height="100%" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <g fillRule="evenodd">
      <path d="m7.31200665 9.30619028c2.56983797 0 4.65309515-2.08325717 4.65309515-4.65309514s-2.08325718-4.65309514-4.65309515-4.65309514c-2.56983798 0-4.65309514 2.08325717-4.65309514 4.65309514s2.08325716 4.65309514 4.65309514 4.65309514" />
      <path d="m11.04113 13.5538014c-.9970918-.2658911-1.73493972-1.1765683-1.73493972-2.2534275 0-.3855422.0930619-.7444952.25924387-1.0635646-.69796427.285833-1.45575405.4453677-2.2534275.4453677-1.36269215 0-2.61902784-.4586623-3.62941421-1.22309931-.22600748-.17282925-.5317823-.21271292-.77108434-.05317823-1.76817615 1.18321564-2.9115081 2.89821354-2.9115081 4.20108014 0 2.2002493 3.27046115 2.3930204 7.31200665 2.3930204 2.58579144 0 4.85916075-.0797673 6.15538015-.7179061-.0598255.0066473-.1130038.0066473-.1728293.0066473-1.0768591 0-1.9875363-.737848-2.2534275-1.7349398" />
      <path d="m14.8965517 10.3032821h-.6049023v-.5982551c0-.54507685-.405484-1.03697548-.9505609-1.0635646-.2658912-.01329456-.5051932.07312007-.691317.24594932-.2193602.17282924-.3523058.44536767-.3523058.7511425v.19941836.46530952h-.6647279c-.5517241 0-.9970918.4453677-.9970918.9970918s.4453677.9970918.9970918.9970918h.6647279v.6647279c0 .5517241.4453677.9970918.9970918.9970918.5517242 0 .9970919-.4453677.9970919-.9970918v-.6647279h.0132945.6514333c.0864147 0 .166182-.0132945.2459493-.0332364.4786041-.119651.8109681-.5783132.7444953-1.1034482-.0731201-.5051932-.5450769-.857499-1.0502701-.857499" />
    </g>
  </svg>
);

export default AddUserIcon;
