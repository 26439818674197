import React, { useState, useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useRoutes } from '../../context/routesContext';
import getLineupSimplified from '../../api/graphql/queries/getLineupSimplified';
import LineupIsOnNowHeader from './LineupIsOnNowHeader';
import LineupIsOnNowTable from './LineupIsOnNowTable';
import LoadIcon from './LoadIcon';

const filters = {
  title: {
    en: '',
    ca: '',
    es: '',
  },
  today: {
    en: 'Today',
    ca: 'Today',
    es: 'Today',
  },
  filterOn: {
    en: 'Filtrar programación por fecha',
    ca: 'Filtrar programación por fecha',
    es: 'Filtrar programación por fecha',
  },
  text3: {
    en: 'Día',
    ca: 'Día',
    es: 'Día',
  },
  text4: {
    en: 'Mes',
    ca: 'Mes',
    es: 'Mes',
  },
  text5: {
    en: 'Año',
    ca: 'Año',
    es: 'Año',
  },
  dayBeforeText: {
    en: 'Day before',
    ca: 'Day before',
    es: 'Day before',
  },
  dayAfterText: {
    en: 'Day after',
    ca: 'Day after',
    es: 'Day after',
  },
  dateFormat: {
    en: 'DD/MM/YYYY',
    ca: 'DD/MM/YYYY',
    es: 'DD/MM/YYYY',
  },
  icon1: 'font-icon icon-arrow-left text-white text-xxs',
  icon2: 'font-icon icon-arrow-right text-white text-xxs',
  icon3: 'font-icon icon-arrow-down text-white text-xxs self-center pr-1',
};

const list = {
  category: 'black',
  text1: {
    en: 'Hora',
    ca: 'Hora',
    es: 'Hora',
  },
  text2: {
    en: 'Programación',
    ca: 'Programación',
    es: 'Programación',
  },
  text3: {
    en: 'Género',
    ca: 'Género',
    es: 'Género',
  },
  text4: {
    en: 'Live Now',
    ca: 'Live Now',
    es: 'Live Now',
  },
  icon1: 'font-icon icon-arrow-up-round text-white text-30',
  icon2: 'font-icon icon-arrow-down-round text-white text-30',
  icon3: 'font-icon icon-current2',
};

const LineupIsOnNow = ({
  match, event, venueSlugName, isLight, isClickable, category,
}) => {
  console.log("🚀 ~ file: LineupIsOnNow.jsx ~ line 92 ~ venueSlugName", venueSlugName)
  const { language } = useRoutes(match);
  const [simpleLineupItems, setSimpleLineupItems] = useState(null);
  const [currentVenueItems, setCurrentVenueItems] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [icon1, setIcon1] = useState('font-icon icon-arrow-up-round text-white text-30');
  const [icon2, setIcon2] = useState('font-icon icon-arrow-down-round text-white text-30');

  const getItemsForAGivenDay = (day = '', venueResult = null) => {
    const date = day ? moment(day).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
    const currentDaysArrayWithinVenue = venueResult && venueResult[0].days;
    const currentDayResult = currentDaysArrayWithinVenue ? currentDaysArrayWithinVenue.filter(item => item.day === date) : null;

    if (currentDayResult && currentDayResult.length > 0) {
      setSimpleLineupItems(currentDayResult[0].artistSets);
      setIsLoading(false);
    } else {
      setSimpleLineupItems([]);
      setIsLoading(false);
    }
  };

  const getSimpleLineup = async () => {
    const lineupResult = await getLineupSimplified(event);
    console.log("🚀 ~ file: LineupIsOnNow.jsx ~ line 116 ~ getSimpleLineup ~ lineupResult", lineupResult)
    const getCurrentVenueResult = (lineupResultFromFn = null) => {
      if (!lineupResultFromFn) return null;
      if (lineupResultFromFn.venues.length === 1) {
        return lineupResultFromFn.venues;
      }
      return lineupResultFromFn.venues//.filter(item => item.venueSlugName === venueSlugName);
    };
    const currentVenueResult = getCurrentVenueResult(lineupResult);
    console.log("🚀 ~ file: LineupIsOnNow.jsx ~ line 124 ~ getSimpleLineup ~ currentVenueResult", currentVenueResult)
    setCurrentVenueItems(currentVenueResult);
    getItemsForAGivenDay(null, currentVenueResult);
  };

  useEffect(() => {
    getSimpleLineup();
    const icon1Classes = isLight ? { ...list }.icon1.replace('white', 'black') : { ...list }.icon1;
    setIcon1(icon1Classes);
    const icon2Classes = isLight ? { ...list }.icon2.replace('white', 'black') : { ...list }.icon2;
    setIcon2(icon2Classes);
  }, []);

  const handleNewDate = (date) => {
    setIsLoading(true);
    getItemsForAGivenDay(date, currentVenueItems);
  };

  const tableViewRender = (simpleLineupItemsFn) => {
  console.log("🚀 ~ file: LineupIsOnNow.jsx ~ line 141 ~ tableViewRender ~ simpleLineupItemsFn", simpleLineupItemsFn)
    if (isLoading) {
      return <LoadIcon classes="w-full justify-center text-center" color={isLight ? 'black' : 'white'} type="ThreeDots" />;
    } if (simpleLineupItemsFn && simpleLineupItemsFn.length === 0) {
      return (
        <div className="text-center mt-4">
          No items available for this date
        </div>
      );
    }
    return (
      <LineupIsOnNowTable {...list} items={simpleLineupItemsFn} isLight={isLight} category={category} icon1={icon1} icon2={icon2} isClickable={isClickable} />
    );
  };


  return (
    <div className="w-full">
      <>
        <LineupIsOnNowHeader {...list} {...filters} language={language} isLight={isLight} handleNewDate={handleNewDate} />
        { tableViewRender(simpleLineupItems) }
      </>
    </div>
  );
};

LineupIsOnNow.propTypes = {
  match: PropTypes.shape().isRequired,
  event: PropTypes.string.isRequired,
  venueSlugName: PropTypes.string.isRequired,
  isLight: PropTypes.bool,
  isClickable: PropTypes.bool,
  category: PropTypes.string,
};

LineupIsOnNow.defaultProps = {
  isLight: false,
  isClickable: true,
  category: 'pro-online',
};

export default withRouter(LineupIsOnNow);
