import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Link from '../../../util/link';
import translateObj from '../../../util/translateObj';
import profileUserEdit from '../../../api/graphql/mutations/profileUserEdit';

const acceptTextShortVersion = {
  en: 'accept',
  es: 'aceptar',
  ca: 'acceptar',
  pt: 'accept',
};
const acceptTextLongVersion = {
  en: 'accept new conditions',
  es: 'aceptar nuevas condiciones',
  ca: 'acceptar noves condicions',
  pt: 'accept new conditions',
};
const reviewTextLongVersion = {
  en: 'review new conditions',
  es: 'validar nuevas condiciones',
  ca: 'valida noves condicions',
  pt: 'review new conditions',
};
const reviewTextShortVersion = {
  en: 'review',
  es: 'validar',
  ca: 'valida',
  pt: 'review',
};
const termsUpdatedText = {
  en: 'We\'ve updated our conditions, please take action',
  es: 'Hemos actualizado nuestras condiciones',
  ca: 'Hem actualizat les nostres condicions',
  pt: 'We\'ve updated our conditions, please take action',
};


class TermsAndConditionsBanner extends Component {
  state = {
    isUserVerified: null,
    userEmail: '',
    isBannerClosed: false,
    isUserUpToDateWithTermsAndConditions: true,
    bannerLanguage: 'en',
  }

  setBannerState = () => {
    const { sendUserInfoBack } = this.props;
    const {
      isUserVerified, userEmail, isConditionBarOpen, language, isUserUpToDateWithTermsAndConditions, isActivationBarOpen,
    } = this.props.context;
    const heightAdj = (userEmail && (isUserUpToDateWithTermsAndConditions === false && isConditionBarOpen) || isActivationBarOpen) ? '145px' : '105px';
    this.setState({
      isUserVerified, isUserUpToDateWithTermsAndConditions, userEmail, isConditionBarOpen, headerHeight: heightAdj, bannerLanguage: language || 'en',
    }, () => { sendUserInfoBack(isUserVerified, userEmail, heightAdj, isUserUpToDateWithTermsAndConditions); });
  }

  componentDidUpdate(prevProps, prevState) {
    const { context } = this.props;
    const { isUserUpToDateWithTermsAndConditions, isUserVerified } = context;
    if (context && prevProps.context !== context) {
      this.setBannerState();
    }
  }

  closeBanner = () => {
    const { handleConditionsBar } = this.props.context;
    handleConditionsBar(false);
    this.setState({ isBannerClosed: true });
  }

  acceptConditions = () => {
    const { userEmail } = this.props.context;

    if (userEmail) {
      // close popup and mark conditions as accepted in user profile (db).

      profileUserEdit({ email: userEmail, acceptedConditions: true }).then((res) => {
        if (res) {
          if (res.isEdited) {
            this.setState({ isBannerClosed: true });
            const { loadTokenForLS } = this.props.context;
            localStorage.setItem(process.env.AUTH_TOKEN, res.token);
            document.cookie = `${process.env.AUTH_TOKEN}=${res.token}; Secure; HttpOnly`;
            loadTokenForLS();
          } else {
            console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Error - acceptConditions not edited returned isValid = false!`);
          }
        } else {
          console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Error - acceptConditions failed to submit!`);
        }
      });
    }
  }

  render() {
    const {
      userEmail, isUserVerified, isUserUpToDateWithTermsAndConditions, isBannerClosed, bannerLanguage, isConditionBarOpen,
    } = this.state;
    return (
      <>
        {(isConditionBarOpen && !isBannerClosed && userEmail && isUserVerified === true && isUserUpToDateWithTermsAndConditions === false) ? (
          <div className="bg-weekender w-full py-1 sm:py-1 px-4 sm:px-1 flex justify-between z-radio">
            <div className="py-1">
              <button className="px-2 font-americaMonoBold " onClick={this.closeBanner}>
                <span className="font-icon icon-close font-black text-black sm:text-xxxs text-xs" />
              </button>
              <span className="text-12 uppercase font-americaMonoBold sm:hidden">
                {translateObj(termsUpdatedText, bannerLanguage)}
              </span>
            </div>
            {(userEmail && isUserVerified === true && isUserUpToDateWithTermsAndConditions === false) && (
              <div className="flex justify-center items-center sm:w-full">
                <button
                  type="button"
                  className="flex justify-center text-black lg:hover:bg-white xl:hover:bg-white md:hover:bg-white items-center font-americaMonoBold py-1 px-2 md:px-2 rounded-full text-12 sm:text-xxs uppercase text-black mr-6 sm:mr-0 xs:w-auto xs:px-4 inline-block border-black border-1 border-black hover:border-0 hover:border-transparent cursor-pointer sm:w-full"
                >
                  <Link to="/user-register-conditions-for-primavera-sound" localizationpath={bannerLanguage}>
                    <span className="sm:block hidden text-black">{translateObj(reviewTextLongVersion, bannerLanguage)}</span>
                    <span className="sm:hidden block text-black">{translateObj(reviewTextShortVersion, bannerLanguage)}</span>
                  </Link>
                </button>
                <button
                  type="button"
                  className="flex justify-center lg:hover:bg-white md:hover:bg-white xl:hover:bg-white items-center font-americaMonoBold py-1 px-2 md:px-2 rounded-full text-12 sm:text-xxs uppercase text-black mr-6 sm:mr-0 xs:w-auto xs:px-4 inline-block border-black border-1 border-black hover:border-0 hover:border-transparent cursor-pointer sm:ml-4 sm:w-full"
                  onClick={this.acceptConditions}
                >
                  <span className="sm:block hidden">{translateObj(acceptTextLongVersion, bannerLanguage)}</span>
                  <span className="sm:hidden block">{translateObj(acceptTextShortVersion, bannerLanguage)}</span>
                </button>
              </div>
            )}
          </div>
        ) : null}
      </>
    );
  }
}

TermsAndConditionsBanner.propTypes = {
  context: PropTypes.shape().isRequired,
  sendUserInfoBack: PropTypes.func.isRequired,
};

export default TermsAndConditionsBanner;
