import { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import getEventByEventSlugName from '../../../../../api/graphql/queries/getEventByEventSlugName';
import setSessionReservation from '../../../../../api/graphql/mutations/setSessionReservation';
import { useRoutes } from '../../../../../context/routesContext';
import translateObj from '../../../../../util/translateObj';

const CANCEL_CONFIRM_MESSAGE = {
  en: 'Do you want to cancel this reservation?',
  es: '¿Quieres cancelar esta reserva?',
  ca: 'Vols cancel·lar la reserva?',
  pt: 'Quer cancelar esta reserva?',
};

const formatSchedule = (artistSets) => {
  const format = [];
  artistSets.forEach((set) => {
    const { artistSetSlugName, dateTimeStartReal, status } = set;
    const timestamp = Number(dateTimeStartReal);
    const timezone = moment.tz.guess();

    const z = moment(timestamp).tz(timezone).format('Z');
    const splitZ = z.split(':')[0];
    const formatZ = splitZ[1] === '0' ? splitZ.replace('0', '') : splitZ;
    const formatDate = moment(timestamp).tz(timezone).format('dddd, MMMM Do YYYY');
    const formatHour = moment(timestamp).tz(timezone).format('HH.mm[H]');

    const hourTimeZone = `${formatHour} (GMT ${formatZ})`;

    const alreadyFormatted = format.find(item => item.day === formatDate);

    if (alreadyFormatted) {
      if (!alreadyFormatted.hours.includes({ slugName: artistSetSlugName, time: hourTimeZone, status })) {
        alreadyFormatted.hours.push({
          slugName: artistSetSlugName,
          time: hourTimeZone,
          status,
        });
      }
    } else {
      format.push({
        day: formatDate,
        hours: [{
          slugName: artistSetSlugName,
          time: hourTimeZone,
          status,
        }],
      });
    }
  });
  return format;
};

export default function useMentoringSession(eventSlugName, fetchDate = false) {
  const { language } = useRoutes();
  const [event, setEvent] = useState({});
  const [schedule, setSchedule] = useState([]);
  const [reserved, setReserved] = useState(null);
  const [concurrent, setConcurrent] = useState(false);

  const getEvent = () => {
    getEventByEventSlugName(eventSlugName, fetchDate).then((res) => {
      if (res) {
        const sessions = formatSchedule(res.artistSets);
        setSchedule(sessions);
        setEvent(res);
      }
    });
  };

  const reserveSession = (selectedSlugName) => {
    if (selectedSlugName) {
      if (reserved) {
        // eslint-disable-next-line no-alert
        if (window.confirm(translateObj(CANCEL_CONFIRM_MESSAGE, language))) {
          setSessionReservation({
            artistSetSlugName: selectedSlugName,
          }).then((res) => {
            if (res?.payed === false) {
              setReserved(null);
              console.log('*** session reservation cancelled ***');
            }
          }).catch(err => console.log(err));
        }
      } else {
        setSessionReservation({
          artistSetSlugName: selectedSlugName,
        }).then((res) => {
          if (res?.payed === true) {
            setReserved(selectedSlugName);
            if (concurrent) {
              setConcurrent(false);
            }
            console.log('*** session reservation succeded ***');
          } else {
            if (res.error?.message === 'user_session_matching_in_time') {
              setConcurrent(true);
            }
          }
        }).catch((err) => {
          console.log(err);
        });
      }
    }
  };

  useEffect(() => {
    if (eventSlugName) {
      getEvent();
    }
  }, [eventSlugName, reserved]);

  useEffect(() => {
    schedule.forEach((day) => {
      day.hours.forEach((hour) => {
        if (hour.status === 'RESERVED') {
          setReserved(hour.slugName);
        }
      });
    });
  }, [schedule]);

  return {
    event,
    schedule,
    concurrent,
    reserved,
    reserveSession,
  };
}
