import gql from 'graphql-tag';

import { clientWeb } from '../graphqlAPI';

const USER_VALIDATE_VOUCHER = gql`
  mutation Post($email: String!, $roles: [String], $voucherId: String!) {
    userValidateProVoucher(
      email: $email,
      roles: $roles,
      voucherId: $voucherId,
      ){
        token
        isValid
        error
    }
}`;

const userValidateProVoucher = async ({
  email, roles = [], voucherId,
}) => {
  try {
    const result = await clientWeb.mutate({
      mutation: USER_VALIDATE_VOUCHER,
      variables: {
        email,
        roles,
        voucherId,
      },
      fetchPolicy: 'no-cache',
    })
      .then(queryResult => queryResult)
      .catch((queryError) => {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error: ${queryError}`);
        return null;
      });

    const { data: { userValidateProVoucher: validatedFormData } } = result;

    if (validatedFormData) {
      return validatedFormData;
    }
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: GraphQL Query Error: validatedFormData = null`);
    return null;
  } catch (error) {
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: userValidateProVoucher Error: ${error}`);
    return null;
  }
};

export default userValidateProVoucher;
