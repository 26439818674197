import gql from 'graphql-tag';

import { clientWeb } from '../graphqlAPI';

const CANCEL_REUNION = gql`
  mutation Post($reunionId: String!, $text: String) {
    cancelReunion(
      reunionId: $reunionId,
      text: $text
      ){
        result
        error
    }
}`;

const cancelReunion = async (formData) => {
  try {
    const result = await clientWeb.mutate({
      mutation: CANCEL_REUNION,
      variables: {
        ...formData,
      },
      fetchPolicy: 'no-cache',
    })
      .then(queryResult => queryResult)
      .catch((queryError) => {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error: ${queryError}`);
        return null;
      });

    const { data: { cancelReunion: validatedFormData } } = result;

    if (validatedFormData) {
      return validatedFormData;
    }
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: GraphQL Query Error: validatedFormData = null`);
    return null;
  } catch (error) {
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: cancelReunion Error: ${error}`);
    return null;
  }
};

export default cancelReunion;
