import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';
import translateObj from '../../../util/translateObj';
import Link from '../../../util/link';

const ARIA_LABEL_NAVIGATE_HOME = {
  en: 'Primavera Sound: go to Home page',
  es: 'Primavera Sound: ir a la página de Inicio',
  ca: 'Primavera Sound: aneu a la pàgina d\'Inici',
  pt: 'Primavera Sound: vá para a página Inicial',
};

const FooterLogo = ({ url, name, match, parent }) => (
  <RoutesContext.Consumer>
    {
      ({ language: contextLanguage, enteredFrom }) => {
        const localization = getLocalization(contextLanguage, enteredFrom, match);
        return (
          <div id={name}>
            <Link
              to=""
              localizationpath={localization.path}
              aria-label={translateObj(ARIA_LABEL_NAVIGATE_HOME, localization.language)}
            >
              <img className="w-2/3 tablet:w-full h-auto mb-8 tablet:mb-0" src={translateObj(url, localization.language)} alt={name} />
            </Link>
          </div>
        );
      }
    }
  </RoutesContext.Consumer>
);

FooterLogo.propTypes = {
  url: PropTypes.shape().isRequired,
  name: PropTypes.string.isRequired,
  parent: PropTypes.oneOf(['FooterRedesignTop', 'FooterRedesignBottom']).isRequired,
};

export default withRouter(FooterLogo);
