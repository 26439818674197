import React from 'react';
import PropTypes from 'prop-types';
import { useRoutes } from '../../../../context/routesContext';
import { LinkWrapper } from '../../../../util/link';

const TopLogoContainerMusicMeets = ({ linkTo, image }) => {
  const { path } = useRoutes();

  return (
    <div className="sm:w-2/5 w-1/4 h-pro-menu sm:h-pro-menu-mobile flex items-center justify-center" style={{ maxWidth: 256 }}>
      <div className="sm:w-1/2 md:w-3/4 w-2/3 py-4">
        <LinkWrapper url={linkTo} path={path}>
          <div className="sm:hidden" style={{ maxWidth: 170 }}>
            <img src={image.size.md} alt={image.name} />
          </div>
          <div className="hidden sm:block">
            <img src={image.size.sm} alt={image.name} />
          </div>
        </LinkWrapper>
      </div>
    </div>
  );
};

TopLogoContainerMusicMeets.propTypes = {
  linkTo: PropTypes.string,
  image: PropTypes.shape(),
};

TopLogoContainerMusicMeets.defaultProps = {
  linkTo: '',
  image: {},
};

export default TopLogoContainerMusicMeets;
