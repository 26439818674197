/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import Modal from 'react-responsive-modal';
import translateObj from '../../../util/translateObj';

const modalStyles = {
  modal: {
    background: 'rgba(0, 0, 0, 0)',
    boxShadow: 'none',
    width: '100vw',
    maxWidth: 'none',
    padding: '5rem',
  },
  overlay: {
    // background: 'rgba(0, 0, 0, 0.5)',

  },
  closeButton: {
    top: '18px',
    right: '18px',
    background: '#F25B29',
  },
};

const ZoneCardIframe = ({
  language, title, url, text, tokenQuery, index, userNickName = 'Fellow PSsounder',
}) => {
  const [isIFrameOpen, setIsIFrameOpen] = useState(false);
  const nickNameStringParam = `#userInfo.displayName="${userNickName}"`;
  const queryString = tokenQuery ? tokenQuery + nickNameStringParam : nickNameStringParam;
  const jitsiFullUrl = url + queryString;
  const cardTitle = translateObj(title, language);
  const cardText = translateObj(text, language);
  const handleOpenIframe = () => {
    setIsIFrameOpen(true);
  };
  const handleCloseIframe = () => {
    setIsIFrameOpen(false);
  };

  return (
    <>
      <div className="bg-psworld-zone-red p-2 sm:w-full w-psworld-box h-psworld-box hover:bg-psworld-zone-red-light text-psworld-text-grey trans sm:mx-0 m-psworld-box cursor-pointer" onClick={handleOpenIframe}>
        <div>
          <h2 className="mb-1 font-americaBold">{`R#${index + 1}${' '}  ${cardTitle}`}</h2>
          <p className="text-xxs font-americaBold leading-regular">{cardText}</p>
        </div>
      </div>
      <Modal open={isIFrameOpen} onClose={handleCloseIframe} center styles={modalStyles} closeOnOverlayClick focusTrapped={false}>
        <div className="full-width w-full">
          <div className="iframe-container w-full">
            <iframe id="plus-zone-frame" allow="camera; microphone" title="iframe" src={jitsiFullUrl} allowFullScreen frameBorder="0" />
          </div>
        </div>
      </Modal>
    </>
  );
};


ZoneCardIframe.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  text: PropTypes.shape().isRequired,
  tokenQuery: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  userNickName: PropTypes.string,

};
ZoneCardIframe.defaultProps = {
  userNickName: 'Fellow PSsounder',
};

export default ZoneCardIframe;
