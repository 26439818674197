import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
// import { some } from 'async';
import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';
import Link from '../../../util/link';
import slugify from '../../../util/slugify';

const Tag = ({
  text, margin, textColor, borderColor, category, match, hidePound, changeCategory, url,
}) => (
  // color of the tag is determined by the tag category
  <RoutesContext.Consumer>
    {({ language: contextLanguage, enteredFrom }) => {
      const localization = getLocalization(contextLanguage, enteredFrom, match);

      let category_css_valid = category ? slugify(category) : 'default';
      const link_to_own_category = category ? slugify(category) : 'home';
      const validCategories = ['barcelona', 'primavera-pro', 'benidorm', 'weekender', 'los-angeles', 'tours', 'artist', 'porto', 'oporto', 'tickets', 'radio', 'labels', 'nits-del-forum'];
      if (!validCategories.some(item => item === category_css_valid)) {
        category_css_valid = 'default';
      }

      return (
        <div>
          {/* <a onClick={() => { changeCategory(category) }} */}
          <Link localizationpath={localization.path} to={`/${link_to_own_category}`}>
            <span
              className={`leading-normal trans hover:bg-white hover:text-black hover:border-white mr-1 inline-block ml-0 font-americaMonoMedium text-xxs cursor-pointer ${
                textColor ? (category_css_valid === 'los-angeles' ? 'text-black' : `text-${textColor}`) : category_css_valid === 'los-angeles' ? 'text-black' : 'text-white'
              } align
              rounded px-2 py-3px bg-${category_css_valid} ${margin} border-solid border ${borderColor ? `border-${borderColor}` : `border-${category_css_valid}`}`}
            >
              <span className="uppercase">{`${hidePound ? ' ' : '#'}${text}`}</span>
              {/* </a> */}
            </span>
          </Link>
        </div>
      );
    }}
  </RoutesContext.Consumer>
);

Tag.propTypes = {
  match: PropTypes.shape().isRequired,
  text: PropTypes.string,
  margin: PropTypes.string,
  textColor: PropTypes.string,
  category: PropTypes.string,
  hidePound: PropTypes.bool,
};

Tag.defaultProps = {
  margin: 'mb-1',
  textColor: 'white',
  category: 'barcelona',
  text: 'music',
  hidePound: true,
};

export default withRouter(Tag);
