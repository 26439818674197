import React, { Component } from 'react';
import PropTypes from 'prop-types';

class FooterBody extends Component {
  state= {
    childrenWidth: 'w-1/2 sm:w-full',
  }

  render() {
    // add width dinamically through the state

    const { components } = this.props;

    const { childrenWidth } = this.state;
    /* eslint-disable no-return-assign */
    // eslint-disable-next-line no-param-reassign
    components.map(component => component.width = childrenWidth);

    const { children } = this.props;

    return (
      <div className="w-full flex flex-wrap justify-between pt-10 px-28 md:px-5 sm:px-5 bb-black sm:flex-col bg-white">
        { children }
      </div>
    );
  }
}

FooterBody.propTypes = {
  components: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
  children: PropTypes.shape().isRequired,
};

export default FooterBody;
