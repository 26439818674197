import gql from 'graphql-tag';

import { clientWeb, persistor } from '../graphqlAPI';

const GET_ARTISTSETS_BY_ARTIST_SLUG_NAME = gql`
  query getArtistSetsByArtist($artist: String!, $eventSlugName: String, $maxPast: Int, $maxFuture: Int) {
    getArtistSetsByArtist(artist: $artist, eventSlugName: $eventSlugName, maxPast: $maxPast, maxFuture: $maxFuture) {
      prev {
        artistSlugName
        artistSetSlugName
        artistSetReadableName {en}
        image {en}
      }
      next {
        artistSlugName
        artistSetSlugName
        artistSetReadableName {en}
        image {en}
      }
      future {
        artistSlugName
        artistSetSlugName
        dateTimeStartReal
        dateTimeStartHuman
        artistSetReadableName {en es ca pt}
        timezone
        event {
          eventSlugName
          postSlugNames
          eventReadableName {en es ca pt}
          description {en es ca pt}
          postSlugNames
          showDateTime
          showDate
          showTicket
          tickets
          timezone
          salePostSlugName
        }
        venue{
          venueSlugName
          location
          venueName
          venueReadableName {
            es
            ca
            en
          }
        }
      }
      past {
        artistSlugName
        dateTimeStartReal
        artistSetSlugName
        artistSetReadableName{
          es
          ca
          en
        }
        venue{
          venueSlugName
          location
          venueName
          venueReadableName {
            es
            ca
            en
          }
        }
        event {
          postSlugNames
          eventReadableName {en es ca pt}
          description {en es ca pt}
          postSlugNames
          salePostSlugName
        }
      }
    }
  }
`;

const getArtistSetsByArtistSlugName = async ({
  artist, eventSlugName, maxPast, maxFuture,
}) => {
  try {
    const result = await clientWeb
      .query({
        query: GET_ARTISTSETS_BY_ARTIST_SLUG_NAME,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
        variables: {
          artist, eventSlugName, maxPast, maxFuture,
        },
        fetchResults: true,
        pollInterva: 300000,
      })
      .then(queryResult => queryResult)
      .catch((queryError) => {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error: ${queryError}`);
        return null;
      });
    const {
      data: { getArtistSetsByArtist },
    } = result;

    if (getArtistSetsByArtist) {
      return getArtistSetsByArtist;
    }
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: GraphQL Query Error: getArtistSetsByArtist`);
    return null;
  } catch (error) {
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: getArtistSetsByArtist Error: ${error}`);
    return null;
  }
};

export default getArtistSetsByArtistSlugName;
