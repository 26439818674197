import gql from 'graphql-tag';

import { clientWeb } from '../graphqlAPI';

const CHAT_FIREBASE_TOKEN = gql`
  mutation Post($channels: [String], $postSlugName: String) {
    chatToken(channels: $channels, postSlugName: $postSlugName) {
      token
        error
    }
  }
`;

const chatFirebaseToken = async ({ channels, postSlugName }) => {
  try {
    const result = await clientWeb.mutate({
      mutation: CHAT_FIREBASE_TOKEN,
      variables: {
        channels, postSlugName,
      },
      fetchPolicy: 'no-cache',
    })
      .then(queryResult => queryResult)
      .catch((queryError) => {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error: ${queryError}`);
        return null;
      });

    const { data: { chatToken: { token, error } } } = result;

    if (error) {
      console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: GraphQL Query Error: ${error}`);
      return null;
    }

    if (token) {
      return token;
    }

    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: GraphQL Query Error: response === null`);
    return null;
  } catch (error) {
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: chatFirebaseToken Error: ${error}`);
    return null;
  }
};

export default chatFirebaseToken;
