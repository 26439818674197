import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
// import LazyLoad from 'react-lazy-load';
import ImageLoader from '../Launcher/ImageLoader';
import stripTags from '../helpers/stripTags';
import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';
import imageModifier from '../helpers/imageModifier';
import translateObj from '../../../util/translateObj';

import RadioTag from '../Buttons/RadioTag';
import FavouriteStar from '../FavouriteStar';

import RadioItemType from '../RadioItemType';

const RadioShowCard = ({
  match, title_multilang, text_multilang, categories_multilang, tags_array_multilang, image, favouriteStar, slug, lang,
}) => (
  <RoutesContext.Consumer>
    {({ language: contextLanguage, enteredFrom }) => {
      const localization = getLocalization(contextLanguage, enteredFrom, match);
      const langText = translateObj(text_multilang, localization.language);
      const langTitle = translateObj(title_multilang, localization.language);
      const strippedText = text_multilang && Object.keys(text_multilang).length && langText ? stripTags(langText).substring(0, 80) : '';
      let tagsArray = [];
      if (Array.isArray(tags_array_multilang)) {
        tagsArray = tags_array_multilang;
      } else {
        tagsArray.push(tags_array_multilang);
      }
      return (
        <div className="w-full bg-white rounded-lg">
          <div className="relative">
            <div className="flex flex-col justify-center relative h-cardlg overflow-hidden bg-grey sliderComponent">
              {image && (
                // <LazyLoad debounce={false}>
                  <ImageLoader className="min-w-full noBackgroundImgGray" src={imageModifier(image, '666x500')} alt={translateObj(title_multilang, localization.language)} />
                // </LazyLoad>
              )}
            </div>
            <div className="p-4 text-black flex flex-col justify-between">
              {/* <span className="font-americaMonoRegular text-12">{date[localization.language]}</span> */}
              <div className="h-48 md:h-56 lg:h-56">
                <div className="mb-6">
                  <Link to={`${localization.path}/radio/shows/${slug}`}>
                    <h2 className="font-americaBlack text-xl mt-1 mb-2 md:h-12 lg:h-12 sm:h-auto">
                      {/* <a href={url[localization.language]} className="text-black">{title_multilang[localization.language]}</a> */}
                      <span className="text-black">{langTitle}</span>
                      {' '}
                    </h2>
                    <p className="text-black mb-2" style={{ minHeight: '60px' }}>
                      {strippedText}
                      {text_multilang && '...'}
                    </p>
                  </Link>
                </div>
                {tags_array_multilang && <div className="flex flex-wrap mb-6" style={{ minHeight: '55px' }}>{tagsArray.map((tag, i) => <Link key={tag.en + i} className="mb-1" to={`${localization.path}/radio/explore/${tag.en}`}><RadioTag category="transparent" text={tag} textColor="black" borderColor="black" margin="mr-2 py-0 font-americaMonoRegular md:text-11px" /></Link>)}</div>}
              </div>
              <div className="flex justify-between items-center absolute pin-b w-full pin-l px-4 pb-2">
                {categories_multilang ? <RadioItemType title={categories_multilang} /> : null}
                {lang ? (
                  <span className="text-black font-americaMonoRegular text-12">
                      Audio:
                    {' '}
                    {lang}
                  </span>
                ) : null}
                {/* <FavouriteStar favourite={favouriteStar} /> */}
              </div>
            </div>
          </div>
        </div>
      );
    }}
  </RoutesContext.Consumer>
);

RadioShowCard.propTypes = {
  match: PropTypes.shape().isRequired,
  categories_multilang: PropTypes.shape(),
  title_multilang: PropTypes.shape().isRequired,
  text_multilang: PropTypes.shape().isRequired,
  image: PropTypes.string.isRequired,
  favouriteStar: PropTypes.bool,
  slug: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  // tags: PropTypes.arrayOf(PropTypes.shape({})),
};

RadioShowCard.defaultProps = {
  favouriteStar: false,
  categories_multilang: '',
  slug: '#',
};

export default withRouter(RadioShowCard);
