import gql from 'graphql-tag';

import { clientWeb, persistor } from '../graphqlAPI';

const GET_USERS_LIST_COUNTRIES = gql`
  query Get($roles: [String], $onlyFriends: Boolean, $userLang: String) {
    getUsersListCountries(roles: $roles, onlyFriends: $onlyFriends, userLang: $userLang){
      value
      label
    }
  }
`;

const executeQuery = async (roles, onlyFriends, userLang, type = 'network') => {
  const result = await clientWeb.query({
    query: GET_USERS_LIST_COUNTRIES,
    variables: { roles, onlyFriends, userLang, fetchDate: new Date().getTime().toString() },
    fetchPolicy: type === 'network' ? 'network-only' : 'cache-only',
  })
    .then((queryResult) => queryResult)
    .catch((queryError) => {
      const errorMessage = type === 'network'
        ? `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error: ${queryError}`
        : `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error after Cache Restore: ${queryError}`;
      console.log('\x1b[31m%s\x1b[0m', errorMessage);

      return null;
    });

  if (result.data && result.data.getUsersListCountries) {
    const { data: { getUsersListCountries: getUsersListCountriesData } } = result;
    return getUsersListCountriesData;
  }

  return [];
};

const getUsersListCountries = async (roles, onlyFriends, userLang) => {
  try {
    const JSONObject = await executeQuery(roles, onlyFriends, userLang);

    if (persistor) {
      persistor.persist();
    }

    return JSONObject;
  } catch (jsonError) {
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: JSON Object Error: ${jsonError}`);

    if (persistor) {
      const persistorStatus = await persistor.restore().then(() => 'OK');
      console.log(`${new Date().toLocaleTimeString()} ~ Client: Apollo Cache Restored - ${persistorStatus}`);

      try {
        const JSONObject = await executeQuery(roles, onlyFriends, userLang, 'cache');

        return JSONObject;
      } catch (jsonErrorAfterCacheRestore) {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: JSON Object Error after Cache Restore, sending 404: ${jsonErrorAfterCacheRestore}`);
        return null;
      }
    }
    return null;
  }
};

export default getUsersListCountries;
