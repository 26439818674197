import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { buttonClasses } from '../../OnDemandUtils/onDemandUtils';

import translateObj from '../../../../../util/translateObj';
import Link from '../../../../../util/link';

import LoadIcon from '../../../LoadIcon';

const cancelButtonText = {
  ca: 'cancel·la',
  es: 'CANCELA',
  en: 'CANCEL',
  pt: 'CANCELA',
};
const otherOptionsButtonText = {
  ca: 'veure altres opcions',
  en: 'see other options',
  es: 'ver otras opciones',
  pt: 'see other options',
};

const SectionAddCardPreScreen = ({
  loadTokenForLS, imageUrl, otherOptionsButtonUrl, handleOnDemandScreenStatus, translations, translations: { loggedModal: formText = {} } = {}, language, userSocialInfo: { profileObj: preFilledValues = {} } = {}, closeModal, handleAddCardScreen,
}) => {
  const backgroundStyle = {
    backgroundImage: imageUrl,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  };

  return (
    <div className="backgroundImgGray">
      <div>
        <div
          className={`${'h-80'} w-full  sliderBackground`}
          style={backgroundStyle}
        />
      </div>
      <div className="w-full bg-white-dark py-2 ">
        <div className="py-2 px-4">
          <div>{translateObj(formText.subscribeTitle, language)}</div>
          <div>{translateObj(formText.subscribeDescription, language)}</div>
          <div>{translateObj(formText.subscribeConditions, language)}</div>
        </div>
        <div className=" py-2 px-4 bg-gray200">
          <div>{translateObj(formText.subscribeConfigurePayment, language)}</div>
          <div className="w-3/4">
            <button
              onClick={() => { handleAddCardScreen(true); }}
              className={` ${buttonClasses({ backgroundColor: 'white', textColor: 'black' })}`}
            >
              {translateObj(formText.subscribeAddCard, language)}
            </button>
          </div>
        </div>
        <div className="flex justify-end px-4 pt-6 pb-4">
          <button onClick={() => closeModal()} className="uppercase rounded-full bg-white-dark text-black font-americaMonoBold sm:w-full cursor-pointer  text-center px-12 py-4 md:mt-4 sm:mt-4 ">{translateObj(cancelButtonText, language)}</button>
          <Link to={otherOptionsButtonUrl}>
            <button className="uppercase rounded-full bg-gray200 text-black font-americaMonoBold sm:w-full cursor-pointer  text-center px-12 py-4 md:mt-4 sm:mt-4">{translateObj(otherOptionsButtonText, language)}</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

SectionAddCardPreScreen.propTypes = {
  language: PropTypes.string,
  loadTokenForLS: PropTypes.func.isRequired,
  handleOnDemandScreenStatus: PropTypes.func.isRequired,
  translations: PropTypes.shape().isRequired,
};

SectionAddCardPreScreen.defaultProps = {
  language: 'en',
};

export default SectionAddCardPreScreen;
