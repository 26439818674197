import gql from 'graphql-tag';

import { clientWeb, persistor } from '../graphqlAPI';


const GET_ARTISTSET_BY_ARTISTSETSLUGNAME = gql`
  query GetArtistSetsByArtistSetSlugs($artistSetSlugNames: [String]!){
    getArtistSetsByArtistSetSlugs(artistSetSlugNames: $artistSetSlugNames) {
      artistSlugName
      artistName
      artistSetSlugName
      artistSetName
      postUri
      artistSetReadableName {
        es
        ca
        en
        pt
      }
      shortTitle {
        es
        ca
        en
        pt
      }
      smallText {
        es
        ca
        en
        pt
      }
      countryCode
      image {
        es
        ca
        en
        pt
      }
      dateTimeStartReal
      dateTimeStartHuman
      venueSlugName
      event { 
        eventName
      }
    }
  }
`;


const artistSetsByArtistSetSlugs = async (slugs) => {
  try {
    const result = await clientWeb
      .query({
        query: GET_ARTISTSET_BY_ARTISTSETSLUGNAME,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
        variables: { artistSetSlugNames: slugs },
        fetchResults: true,
        pollInterva: 300000,
      })
      .then(queryResult => queryResult)
      .catch((queryError) => {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error: ${queryError}`);
        return null;
      });
    const {
      data: { getArtistSetsByArtistSetSlugs },
    } = result;

    if (getArtistSetsByArtistSetSlugs) {
      return getArtistSetsByArtistSetSlugs;
    }
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: GraphQL Query Error: artistSetsByArtistSetSlugs`);
    return null;
  } catch (error) {
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: getPostsBySlugName Error: ${error}`);
    return null;
  }
};

export default artistSetsByArtistSetSlugs;
