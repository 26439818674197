import React from 'react';

const NotificationIcon = () => (
  <svg height="100%" viewBox="0 0 16 16" width="100%" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <mask id="a" fill="#fff"><path d="m0 0h15.9998739v16h-15.9998739z" fill="#fff" fillRule="evenodd" /></mask>
    <path d="m15.4199309 11.6133151-1.123338-.7486698c-.1853341-.1233339-.2966668-.3320014-.2966668-.554669v-2.78401161c0-2.75934483-1.8000086-5.21268838-4.3440192-5.96535819-.05533356-.87000362-.77133655-1.56067317-1.6560069-1.56067317-.88333701 0-1.59867333.68933621-1.65534023 1.55867316-2.50734378.71933633-4.34468477 3.02334593-4.34468477 5.76202401v2.9893458c0 .2226676-.1113338.4313351-.2966679.554669l-1.12333802.7486698c-.48200201.3213346-.70800295.9353372-.50600211 1.520673.18533411.5353355.72733637.8660036 1.29333873.8660036h4.33335139c.16266734 1.129338 1.12467135 2.0000083 2.29934291 2.0000083s2.1366756-.8706703 2.3000096-2.0000083h4.3326847c.566669 0 1.1080046-.3306681 1.2933387-.8660036.2020009-.5853358-.0240001-1.1993384-.5060021-1.520673" fillRule="evenodd" mask="url(#a)" />
  </svg>
);

export default NotificationIcon;
