import React from 'react';
import PropTypes from 'prop-types';
import ComponentIterator from '../../../../containers/ComponentIterator';

const TopHeaderSubmenuMusicMeets = ({ components }) => (
  <div className="bg-pro-online flex items-center justify-start w-full h-pro-top-menu sm:z-top">
    <div
      className="w-pro-top-menu h-pro-top-menu"
      style={{
        background: 'linear-gradient(to top right, #000000 50%, #FF6600 50%) no-repeat, linear-gradient(to top right, #000000 0.1%, #FF6600 0.1%) no-repeat',
        backgroundSize: '100% 100%, 100% 50%',
        backgroundPosition: '0% 100%, 0% 0%',
      }}
    />
    <div className="flex items-center justify-between w-full">
      <ComponentIterator components={components} />
    </div>
  </div>

);

TopHeaderSubmenuMusicMeets.propTypes = {
  components: PropTypes.arrayOf(PropTypes.shape()),
};

TopHeaderSubmenuMusicMeets.defaultProps = {
  components: [],
};

export default TopHeaderSubmenuMusicMeets;
