const translation = {
  reunionInvitation: {
    en: 'has proposed a meeting.',
    es: 'te ha propuesto una reunión.',
    ca: 't\'ha proposat una reunió.',
    pt: 'propôs uma reunião.',
  },
  reunionInvitationConfirmation: {
    en: 'has accepted your meeting proposal.',
    es: 'ha aceptado tu propuesta de reunión.',
    ca: 'ha acceptat la teva proposta de reunió.',
    pt: 'aceitou sua proposta de reunião.',
  },
  reunionRejected: {
    en: 'has rejected your meeting proposal.',
    es: 'ha rechazado tu propuesta de reunión.',
    ca: 'ha rebutjat la teva proposta de reunió.',
    pt: 'rejeitou sua proposta de reunião.',
  },
  reunionCancelled: {
    en: 'has cancelled the meeting.',
    es: 'ha cancelado la reunión.',
    ca: 'ha cancel·lat la reunió.',
    pt: 'cancelou a reunião.',
  },
  reunionUpdated: {
    en: 'has updated the meeting.',
    es: 'ha actualizado la reunión.',
    ca: 'ha actualitzat la reunió.',
    pt: 'atualizou a reunião.',
  },
  reunionConfirmation: {
    en: 'has confirmed the meeting.',
    es: 'ha confirmado la reunión.',
    ca: 'ha confirmat la reunió.',
    pt: 'confirmou a reunião.',
  },
  sessionReminder: {
    en: 'will start in 24 hours.',
    es: 'empezará en 24 horas.',
    ca: 'començarà en 24 hores.',
    pt: 'começará em 24 horas.',
  },
};

export default translation;
