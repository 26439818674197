/* eslint-disable jsx-a11y/label-has-for */
import React, { Component } from 'react';
import { propTypes, withFormsy } from 'formsy-react';

class FormRadioGroup extends Component {
  state = {
    value: '',
  };

  componentDidMount = () => {
    const { value, setValue } = this.props;
    setValue({ value });
    this.setState({ value });
  };

  changeValue = (e, value) => {
    const { setValue } = this.props;
    setValue({ value });
    this.setState({ value });
  };

  render() {
    const {
      name,
      title,
      className,
      items,
    } = this.props;

    const { value } = this.state;

    const classNameInput = `form-group ${className || ''}`;

    return (
      <div className={classNameInput}>
        <label htmlFor={name}>{title}</label>
        {items.map(item => (
          <div key={name + item} style={{ paddingBottom: '5px' }}>
            <input
              type="radio"
              name={name}
              onChange={e => this.changeValue(e, item)}
              checked={value === item}
              style={{ verticalAlign: 'middle' }}
            />
            <span style={{ verticalAlign: 'middle', marginLeft: '0.5rem' }}>{item.toString()}</span>
          </div>
        ))}
      </div>
    );
  }
}

FormRadioGroup.propTypes = {
  ...propTypes,
};

export default withFormsy(FormRadioGroup);
