
import React, { useState, useRef, useContext, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useAuth } from '@primaveralabs/auth';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import imageModifier from '../../helpers/imageModifier';
import RoutesContext, { useRoutes } from '../../../../context/routesContext';
import { UserProfileContextConsumer } from '../../../../context/userProfileContext';
import getLocalization from '../../../../util/localization';
import translateObj from '../../../../util/translateObj';
import Link from '../../../../util/link';
import { HeaderContext } from '../../../../context/headerContext';

const styles = {
  lightYellowBackground: { backgroundColor: 'rgba(245, 229, 27, 0.5)' },
  lightYellowBackgroundChild: { backgroundColor: 'rgba(245, 229, 27, 0)' },
  yellowBackground: { backgroundColor: 'rgba(245, 229, 27, 0.7)' },
};

const SimpleOnDemandBanner = ({
  image, match, postVideoRole, price, translations, sourceWeb, urls: { postVideoUrl, shareButtonUrl, otherOptionsButtonUrl } = {},
}) => {
  const {
    mainScreen: {
      priceText, onDemandMainText, watchNowButtonText, shareButtonText,
    } = {},
  } = translations;
  const [deviceWidth] = useState('lg');
  const { setAuthOpen } = useContext(HeaderContext);
  const { user } = useAuth();
  const { language } = useRoutes();
  const history = useHistory();

  const redirect = () => history.push(`/${language}${postVideoUrl && postVideoUrl[0] === '/' ? postVideoUrl : `/${postVideoUrl}`}`);

  const onOpenModal = () => {
    if (!user) setAuthOpen('login');
    else if (postVideoUrl) redirect();
  };

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (!isFirstRender.current && user && postVideoUrl) {
      redirect();
    } else {
      isFirstRender.current = false;
    }
  }, [user, postVideoUrl]);

  let imageurl = `url(${imageModifier(translateObj(image, language), '1440x620')})`;
  if (deviceWidth === 'sm') imageurl = `url(${imageModifier(translateObj(image, language), '414x420')})`;
  if (deviceWidth === 'md') imageurl = `url(${imageModifier(translateObj(image, language), '768x510')})`;
  const backgroundStyle = {
    backgroundImage: imageurl,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  };

  return (
    <div
      key={image.en}
      className={`${'h-screen80  md:h-carouselMD sm:h-carouselSM md:h-carouselMD sm:h-carouselSM'} w-full sliderComponent relative flex flex-col justify-end overflow-hidden min-h-screen40`}
    >
      <div className=" h-full w-74 z-5 self-end relative" style={styles.lightYellowBackground}>
        <div className="py-8 px-4">
          <span className="font-americaMonoBold uppercase text-4xl">{translateObj(onDemandMainText, language)}</span>
        </div>
        <div className="border-t-2 border-black absolute pin-b mb-4 w-full my-0 mx-auto">
          <div className="font-americaMonoBold uppercase px-4" style={styles.yellowBackground}>
            <div>
              <button
                type="button"
                className="rounded-full bg-inherit bg-black  text-white font-americaMonoBold cursor-pointer  text-center my-4 px-16 uppercase py-4  w-full"
                onClick={onOpenModal}
              >
                {translateObj(watchNowButtonText, language)}
              </button>
            </div>
          </div>
          <div className="px-4" style={styles.lightYellowBackgroundChild}>
            {(shareButtonText && shareButtonUrl) && (
            <Link to={shareButtonUrl}>
              <button
                type="button"
                className="rounded-full bg-gray200 bg-black  text-white font-americaMonoBold cursor-pointer  text-center my-4 px-16 uppercase py-4 w-full"
              >
                {translateObj(shareButtonText, language)}
              </button>
            </Link>
            ) }
          </div>
        </div>
      </div>
      <div
        key={image.en}
        className={`${'h-screen80  md:h-carouselMD sm:h-carouselSM md:h-carouselMD sm:h-carouselSM'} w-full backgroundImgGray sliderBackground absolute pin-l pin-b`}
        style={backgroundStyle}
      />
    </div>
  );
};

SimpleOnDemandBanner.propTypes = {
  image: PropTypes.shape({}),
  postVideoRole: PropTypes.string.isRequired,
  price: PropTypes.number,
  translations: PropTypes.shape(),
  urls: PropTypes.shape(),
  sourceWeb: PropTypes.string,

};

SimpleOnDemandBanner.defaultProps = {
  image: null,
  sourceWeb: '',

  urls: {
    postVideoUrl: '',
    shareButtonUrl: 'https://www.primaverasound.com',
    otherOptionsButtonUrl: 'https://www.primaverasound.com',
  },
  translations: {
    mainScreen: {
      priceText: {
        es: 'precio',
        en: 'price',
        ca: 'preu',
        pt: 'precio',
      },
      shareButtonText: {
        es: 'compartir',
        en: 'share',
        ca: 'compartir',
        pt: 'compartilhar',
      },
      watchNowButtonText: {
        es: 'ver ahora',
        en: 'watch now',
        ca: 'veure ara',
        pt: 'watch now',
      },
    },
  },
  price: 0.0,

};

export default withRouter(SimpleOnDemandBanner);
