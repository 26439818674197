import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import moment from "moment";
import RoutesContext from "../../context/routesContext";
import getLocalization from "../../util/localization";
import translateObj from "../../util/translateObj";
import Link from "../../util/link";

const RelatedItem = ({ match, post }) => (
  <RoutesContext.Consumer>
    {({ language: contextLanguage, enteredFrom }) => {
      const localization = getLocalization(contextLanguage, enteredFrom, match);
      const urlWithSlash =
        post.postDescription.url[0] === "/"
          ? post.postDescription.url
          : `/${post.postDescription.url}`;

      moment.locale(localization.language);
      const linkTo = urlWithSlash || `/${post.slugName}`;
      return (
        <div className="py-4 px-6.5 border-b border-black flex flex-col">
          <div className="font-americaMonoRegular text-12 uppercase">
            {post.creationDate &&
              moment(post.creationDate).format("DD / MM / YY")}
          </div>
          <h2 className="font-americaBlack text-20 pb-ticket-gap pt-8px">
            <Link to={linkTo} localizationpath={localization.path}>
              <span className="text-black text-black active:text-black hover:text-black visited:text-black cursor-pointer hover:underline">
                {translateObj(
                  post.postDescription.title,
                  localization.language
                ) &&
                  translateObj(
                    post.postDescription.title,
                    localization.language
                  )}
              </span>
            </Link>
          </h2>
        </div>
      );
    }}
  </RoutesContext.Consumer>
);

RelatedItem.propTypes = {
  match: PropTypes.shape().isRequired,
  post: PropTypes.shape().isRequired
};

export default withRouter(RelatedItem);
