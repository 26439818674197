const translation = {
  title: {
    en: 'Accredited',
    es: 'Acreditados',
    ca: 'Acreditats',
    pt: 'Credenciados',
  },
  filtersTitle: {
    en: 'Filter',
    es: 'Filtrar',
    ca: 'Filtrar',
    pt: 'Filtrar',
  },
  filtersAll: {
    en: 'All accredited',
    es: 'Todos los acreditados',
    ca: 'Tots els acreditats',
    pt: 'Todos credenciados',
  },
  filtersFavorites: {
    en: 'Only favorites',
    es: 'Solo favoritos',
    ca: 'Només preferits',
    pt: 'Apenas favoritos',
  },
  filtersCountry: {
    en: 'Filter by country',
    es: 'Filtrar por país',
    ca: 'Filtrar per país',
    pt: 'Filtrar por país',
  },
  search: {
    en: 'Search',
    es: 'Buscar',
    ca: 'Cerca',
    pt: 'Procurar',
  },
  loadMore: {
    en: 'Load more',
    es: 'Cargar más',
    ca: 'Carregar més',
    pt: 'Carregue mais',
  },
  labelMembers: {
    en: 'Accredited',
    es: 'Acreditados',
    ca: 'Acreditats',
    pt: 'Credenciados',
  },
  labelCompany: {
    en: 'Company',
    es: 'Empresa',
    ca: 'Empresa',
    pt: 'Empresa',
  },
  labelBusiness: {
    en: 'Business',
    es: 'Actividad',
    ca: 'Activitat',
    pt: 'Atividade',
  },
  labelCountry: {
    en: 'Country',
    es: 'País',
    ca: 'País',
    pt: 'País',
  },
  noItemsFoundMessage: {
    en: 'No results found',
    es: 'No se han encontrado resultados',
    ca: 'No s\'han trobat resultats',
    pt: 'Não foram encontrados resultados',
  },
  labelCleanSelection: {
    en: 'Clean selection',
    es: 'Limpiar selección',
    ca: 'Netejar selecció',
    pt: 'Limpar seleção',
  },
};

export default translation;
