/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import { useAuth } from '@primaveralabs/auth';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { useRoutes } from '../../../context/routesContext';
import useResponsiveUtility from '../../../util/responsiveUtility';
import { LinkWrapper } from '../../../util/link';

import translateObj from '../../../util/translateObj';
import getTranslation from './util/translations/_getTranslation';
import localTranslation from './util/translations/ReunionItem';
import { VENUES_OPTIONS } from './util/optionValues';

import IconButton from './IconButton';
import Button from './Button';
import ReunionItemParticipantsList from './ReunionItemParticipantsList';
import ReunionItemCancellationModal from './ReunionItemCancellationModal';
import ReunionItemConfirmActionModal from './ReunionItemConfirmActionModal';

const getReunionDate = (date) => {
  const timezone = moment.tz.guess();

  const z = moment(date).tz(timezone).format('Z');
  const splitZ = z.split(':')[0];
  const formatZ = splitZ[1] === '0' ? splitZ.replace('0', '') : splitZ;
  const formatDate = moment(date).tz(timezone).format('MMMM D, YYYY  |  HH.mm[H]');

  return `${formatDate} (GMT ${formatZ})`;
};

const ReunionItem = ({
  cancelled,
  _id,
  title,
  date,
  location,
  description,
  online,
  link,
  createdBy,
  participants,
  confirmedParticipants,
  rejectedParticipants,
  isListItem,
  translation,
  createdByUser,
  confirmed,
  callback,
  expand,
  isAgendaItem,
  index,
  invitation,
}) => {
  const { language, path } = useRoutes();
  const { deviceScreen } = useResponsiveUtility();
  const { user } = useAuth();
  const literals = getTranslation(translation, localTranslation);
  const canExpand = isListItem && deviceScreen !== 'sm';
  const createdByMe = user.userId === createdBy;
  const confirmedAssistanceByMe = !createdByMe && confirmedParticipants?.find((p) => p._id === user.userId);
  const rejectedAssistanceByMe = !createdByMe && rejectedParticipants?.find((p) => p._id === user.userId);

  const [expanded, setExpanded] = useState(expand);
  const [openConfirmActionModal, setOpenConfirmActionModal] = useState('none');
  const [openCancellationModal, setOpenCancellationModal] = useState(false);

  const history = useHistory();

  moment.locale(language);

  const reunionDate = getReunionDate(date);

  const handleExpand = () => {
    if (canExpand) {
      setExpanded(!expanded);
    }
  };

  const expandZone = <div className={`flex-1 h-max ${!invitation ? 'cursor-pointer' : ''}`} onClick={() => (isAgendaItem ? history.push(`/${language}/pro-reunions?id=${_id}`) : handleExpand())} />;

  return (
    <>
      <div
        style={{
          aspectRatio: '1/1',
          gridColumn: canExpand ? `${expanded ? 'span 2' : 'span 1'}` : null,
          gridRow: canExpand ? `${expanded ? 'span 2' : 'span 1'}` : null,
        }}
        className={`
        ${(!confirmed && createdByMe) || cancelled ? 'bg-grayE2DDDB' : 'bg-white'}
        ${canExpand ? '' : 'mb-3'}
        ${isAgendaItem ? `${index % 2 === 0 ? 'md:mr-4 mdlg:mr-4' : ''} md:w-48% mdlg:w-48% md:mb-4 mdlg:mb-4` : ''}
        text-black rounded-lg p-6 flex flex-col justify-between items-start w-full
        `}
      >
        <div className="w-full">
          {/* DATA + STATUS LABEL */}
          <div className="flex items-stretch justify-between w-full">
            <div className={`h-max uppercase font-americaMonoRegular tracking-px text-12 flex-1 ${!invitation ? 'cursor-pointer' : ''}`} onClick={() => (isAgendaItem ? history.push(`/${language}/pro-reunions?id=${_id}`) : handleExpand())}>
              <span>{reunionDate}</span>
              {location && (
              <>
                <span className="px-2">|</span>
                <span>{translateObj(VENUES_OPTIONS.find((v) => v.value === location)?.name) || location}</span>
              </>
              )}
            </div>
            <div className="ml-4 w-max flex-none">
              <div className={`flex items-center ${!invitation ? 'cursor-pointer' : ''}`} onClick={() => (isAgendaItem ? history.push(`/${language}/pro-reunions?id=${_id}`) : handleExpand())}>
                <div className={`w-30px h-30px text-base rounded-lg ${confirmed && !cancelled ? 'bg-black font-icon icon-checkbox text-pro-online' : `bg-white-dark font-icon ${cancelled ? 'icon-close' : 'icon-clock'}`} flex items-center justify-center`} />
                {expanded && (
                <span className="sm:hidden ml-4 uppercase font-americaMonoBold text-12 tracking-px">
                  {confirmed && !cancelled
                    ? translateObj(literals.labelConfirmed, language)
                    : `${cancelled ? translateObj(literals.labelCancelled, language) : translateObj(literals.labelPending, language)}`}
                </span>
                )}
              </div>
              {rejectedAssistanceByMe && !cancelled && (
                <div className="relative w-max">
                  <div className={`absolute pt-6 w-full flex justify-end ${!invitation ? 'cursor-pointer' : ''}`} onClick={() => (isAgendaItem ? history.push(`/${language}/pro-reunions?id=${_id}`) : handleExpand())}>
                    <span className="uppercase font-americaMonoBold text-12 tracking-px py-2 px-4 bg-grayE2DDDB text-pro-online rounded-lg">
                      {translateObj(literals.labelRejected, language)}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* TITLE */}
          <div className={`font-americaBlack text-18 ${createdByMe ? 'pt-6' : 'py-6'} w-3/4 ${!invitation ? 'cursor-pointer' : ''}`} onClick={() => (isAgendaItem ? history.push(`/${language}/pro-reunions?id=${_id}`) : handleExpand())}>
            {translateObj(title, language)}
          </div>
          {/* CREATOR */}
          {!createdByMe && createdByUser && (
          <>
            <div
              className={`font-americaMonoRegular text-12 uppercase tracking-px pb-2 ${!invitation ? 'cursor-pointer' : ''}`}
              onClick={() => (isAgendaItem ? history.push(`/${language}/pro-reunions?id=${_id}`) : handleExpand())}
            >
              {translateObj(literals.labelCreatedBy, language)}
            </div>
            <div className="w-full flex items-stretch">
              <div className="font-tiemposRegular text-14 w-max">
                <LinkWrapper url={`/pro-member-detail/?id=${createdByUser._id}`} path={path}>
                  <span className="font-tiemposRegular text-14 text-black mr-1 underline">{`${createdByUser.name} ${createdByUser.lastname}`}</span>
                </LinkWrapper>
                {createdByUser?.company && `(${createdByUser.company})`}
              </div>
              {expandZone}
            </div>
          </>
          )}
          {/* CONFIRMED GUESTS */}
          {confirmed && confirmedParticipants.length > 0 && ((!createdByMe && expanded) || createdByMe) && (
          <>
            <div
              className={`font-americaMonoRegular text-12 uppercase tracking-px pb-2 pt-6 ${!invitation ? 'cursor-pointer' : ''}`}
              onClick={() => (isAgendaItem ? history.push(`/${language}/pro-reunions?id=${_id}`) : handleExpand())}
            >
              {translateObj(literals.labelConfirmedParticipants, language)}
            </div>
            <div className="w-full flex items-stretch">
              <ReunionItemParticipantsList
                items={confirmedParticipants}
                expanded={expanded}
              />
              {expandZone}
            </div>
          </>
          )}
          {/* GUESTS */}
          {((expanded && ((!confirmed && (confirmedParticipants.length > 0 || participants.length > 0)) || (confirmed && participants.length > 0)))
          || (!expanded && createdByMe && ((confirmed && confirmedParticipants.length === 0) || (!confirmed && (confirmedParticipants.length > 0 || participants.length > 0)))))
          && (
          <>
            <div
              className={`font-americaMonoRegular text-12 uppercase tracking-px pb-2 pt-6 ${!invitation ? 'cursor-pointer' : ''}`}
              onClick={() => (isAgendaItem ? history.push(`/${language}/pro-reunions?id=${_id}`) : handleExpand())}
            >
              {!createdByMe || (createdByMe && confirmed && confirmedParticipants.length > 0)
                ? translateObj(literals.labelOtherParticipants, language)
                : translateObj(literals.labelParticipants, language)}
            </div>
            <div className="w-full flex items-stretch">
              <ReunionItemParticipantsList
                items={confirmed ? participants : [...participants, ...confirmedParticipants]}
                expanded={expanded}
              />
              {expandZone}
            </div>
          </>
          )}
          {/* ONLINE BUTTON (EXPANDED) */}
          {expanded && online && link && !cancelled && (
            <div className="w-full relative flex items-stretch">
              <div className="pt-6">
                <IconButton
                  color="orange"
                  label={translateObj(literals.buttonLink, language)}
                  link={link}
                  icon="online"
                />
              </div>
              {expandZone}
            </div>
          )}
          {/* DESCRIPTION (EXPANDED) */}
          {expanded && (
          <div
            className={`w-full ${!invitation ? 'cursor-pointer' : ''}`}
            onClick={() => (isAgendaItem ? history.push(`/${language}/pro-reunions?id=${_id}`) : handleExpand())}
          >
            <div className="font-americaMonoRegular text-12 uppercase tracking-px pb-6 pt-8">{translateObj(literals.labelDescription, language)}</div>
            <div className="font-tiemposRegular text-14 text-black">
              {translateObj(description, language)}
            </div>
          </div>
          )}
        </div>
        {/* EXPAND CLICK ZONE */}
        <div className={`w-full flex-1 ${!invitation ? 'cursor-pointer' : ''}`} onClick={() => (isAgendaItem ? history.push(`/${language}/pro-reunions?id=${_id}`) : handleExpand())} />
        {/* ACTION BUTTONS */}
        {cancelled ? (
          <div className="w-full flex justify-end">
            <IconButton label={expanded ? translateObj(literals.buttonDelete, language) : null} onClickCallback={() => setOpenConfirmActionModal('hidden')} icon="font-icon icon-close" />
          </div>
        ) : (
          <div className="w-full flex items-end justify-between">
            <div className={`flex items-center justify-start ${createdByMe ? 'w-2/3' : 'w-1/3'}`}>
              {online && !expanded && link && (
                <IconButton
                  className="mr-3"
                  color={confirmed ? 'orange' : 'black'}
                  icon="online"
                  link={link}
                />
              )}
              {createdByMe && (
                <>
                  <IconButton
                    className={expanded ? 'mr-5' : 'mr-3'}
                    label={expanded ? translateObj(literals.buttonInvite, language) : null}
                    link={`/pro-create-reunion/?id=${_id}&action=edit`}
                    icon="addUser"
                  />
                  <IconButton
                    className={expanded ? 'mr-5' : 'mr-3'}
                    label={expanded ? translateObj(literals.buttonReprogram, language) : null}
                    link={`/pro-create-reunion/?id=${_id}&action=edit`}
                    icon="reschedule"
                  />
                </>
              )}
            </div>
            <div className={`${expanded && !createdByMe ? 'w-full' : 'w-max'} flex items-center justify-end`}>
              {!createdByMe && (
                <>
                  {rejectedAssistanceByMe ? (
                    <IconButton label={expanded ? translateObj(literals.buttonDelete, language) : null} onClickCallback={() => setOpenConfirmActionModal('hidden')} icon="font-icon icon-close" />
                  ) : (
                    <>
                      {(!confirmedAssistanceByMe && !rejectedAssistanceByMe) && (
                        <>
                          {!canExpand && deviceScreen === 'lg' ? (
                            <div className="flex items-center">
                              <IconButton
                                icon="cancel"
                                onClickCallback={() => setOpenConfirmActionModal('rejected')}
                                color="fill-black"
                                className="mr-2"
                              />
                              <IconButton
                                icon="font-icon icon-checkbox"
                                onClickCallback={() => setOpenConfirmActionModal('confirmed')}
                                color="fill-black"
                              />
                            </div>
                          ) : (
                            <div className="flex items-center">
                              <Button
                                text={expanded
                                  ? translateObj(literals.buttonDeclineInvitation, language)
                                  : translateObj(literals.buttonDecline, language)}
                                color="black"
                                className={expanded ? 'w-max mr-4' : 'w-full mr-2'}
                                onClickCallback={() => setOpenConfirmActionModal('rejected')}
                              />
                              <Button
                                text={expanded
                                  ? translateObj(literals.buttonAcceptInvitation, language)
                                  : translateObj(literals.buttonAccept, language)}
                                color="black"
                                className={expanded ? 'w-max' : 'w-full'}
                                onClickCallback={() => setOpenConfirmActionModal('confirmed')}
                              />
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
              {createdByMe && (
              <IconButton
                label={expanded ? translateObj(literals.buttonCancel, language) : null}
                onClickCallback={() => setOpenCancellationModal(true)}
                icon="cancel"
              />
              )}
            </div>
          </div>
        )}
      </div>
      <ReunionItemConfirmActionModal
        open={openConfirmActionModal !== 'none'}
        onClose={() => setOpenConfirmActionModal('none')}
        reunionId={_id}
        reunionTitle={title}
        callback={callback}
        action={openConfirmActionModal}
        literals={literals}
      />
      <ReunionItemCancellationModal
        open={openCancellationModal}
        onClose={() => setOpenCancellationModal(false)}
        reunionId={_id}
        reunionTitle={title}
        callback={callback}
        title={literals.cancellationTitle}
        text={literals.cancellationText}
        buttonLabel={literals.buttonSendMessageCancel}
      />
    </>
  );
};

ReunionItem.propTypes = {
  _id: PropTypes.string,
  title: PropTypes.string,
  date: PropTypes.string,
  location: PropTypes.string,
  description: PropTypes.string,
  online: PropTypes.bool,
  link: PropTypes.string,
  createdBy: PropTypes.string,
  participants: PropTypes.arrayOf(PropTypes.shape()),
  confirmedParticipants: PropTypes.arrayOf(PropTypes.shape()),
  rejectedParticipants: PropTypes.arrayOf(PropTypes.shape()),
  createdByUser: PropTypes.shape(),
  isListItem: PropTypes.bool,
  translation: PropTypes.shape(),
  confirmed: PropTypes.bool,
  callback: PropTypes.func,
  expand: PropTypes.bool,
  isAgendaItem: PropTypes.bool,
  index: PropTypes.number,
  invitation: PropTypes.bool,
  cancelled: PropTypes.bool,
};

ReunionItem.defaultProps = {
  _id: '',
  title: '',
  date: '',
  location: '',
  description: '',
  online: false,
  link: null,
  createdBy: '',
  createdByUser: null,
  participants: [],
  confirmedParticipants: [],
  rejectedParticipants: [],
  isListItem: false,
  translation: null,
  confirmed: false,
  callback: null,
  expand: false,
  isAgendaItem: false,
  index: null,
  invitation: false,
  cancelled: false,
};

export default ReunionItem;
