import gql from 'graphql-tag';

import { clientWeb, persistor } from '../graphqlAPI';

const GET_EVENT_BY_SLUGNAME = gql`
  query getEventBySlugName($eventSlugName: String!, $fetchDate: String) {
    getEventBySlugName(eventSlugName: $eventSlugName, fetchDate: $fetchDate) {
      eventReadableName {
        en
        es
        ca
        pt
      }
      description {
        en
        es
        ca
        pt
      }
      pretitle {
        en
        es
        ca
        pt
      }
      subtitle {
        en
        es
        ca
        pt
      }
      image {
        en
        es
        ca
        pt
      }
      mentors
      mentorsData {
        _id
        name
        lastname
        occupation
        business
      }
      eventReadableName {
        en
        es
        ca
        pt
      }
      artistSets {
        artistSetSlugName
        dateTimeStartReal
        dateTimeStartHuman
        dateTimeEndReal
        status
        venue {
          venueReadableName {
            en
            es
            ca
            pt
          }
        }
      }
    }
  }
`;

const getEventByEventSlugName = async (slug, fetchDate) => {
  const variables = {
    eventSlugName: slug,
  };
  if (fetchDate) variables.fetchDate = new Date().getTime().toString();
  try {
    const result = await clientWeb
      .query({
        query: GET_EVENT_BY_SLUGNAME,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
        variables,
        fetchResults: true,
      })
      .then(queryResult => queryResult)
      .catch((queryError) => {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error: ${queryError}`);
        return null;
      });
    const {
      data: { getEventBySlugName },
    } = result;

    if (getEventBySlugName) {
      return getEventBySlugName;
    }
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: GraphQL Query Error: getTicketsByTicketSlugs`);
    return null;
  } catch (error) {
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: getTickets Error: ${error}`);
    return null;
  }
};

export default getEventByEventSlugName;
