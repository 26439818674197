import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Link from '../../../util/link';

import Tag from '../Buttons/Tag';
import TitleLineH2 from '../Text/TitleLineH2';
import RadioHeader2 from '../Radio/RadioHeader2';

import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';
import imageModifier from '../helpers/imageModifier';
import translateObj from '../../../util/translateObj';

const LauncherItemSingle = ({
  itemLayout, url, image, date, title, duration, tags, match, category, mixcloud,
}) => (
  <RoutesContext.Consumer>
    {({ language: contextLanguage, enteredFrom }) => {
      const localization = getLocalization(contextLanguage, enteredFrom, match);
      const urlWithSlash = url[0] === '/' ? url : `/${url}`;

      return (
        <div className={`relative border-t border-transparent launcherItemData ${itemLayout}`}>
          <div className="launcherItemImg relative">
            <img src={imageModifier(translateObj(image.src, localization.language), '800x800')} alt={title.en} />
          </div>
          {
          mixcloud && (
          <div className="absolute pin-t pin-l w-full">
            <RadioHeader2 theme="radio" title={title} durationText={duration} icon1Url="font-icon icon-clock text-24" icon2Url="font-icon icon-arrow-down text-white" mixcloud={mixcloud} />
          </div>
          )
        }
          <div className="absolute pin-l pin-b p-12 text-white sm:p-5">
            {date && <p className="font-americaMonoRegular text-14">{date}</p>}
            <Link to={localization.path + urlWithSlash}>
              {title && <TitleLineH2 text={title} margin="mt-2" color="white" />}
            </Link>
            {tags && tags.map((tag, i) => <Tag text={translateObj(tag, localization.language)} category={category} key={`${translateObj(tag, localization.language)}-${i}`} margin="my-2" />)}
          </div>
        </div>
      );
    }}
  </RoutesContext.Consumer>
);

LauncherItemSingle.propTypes = {
  itemLayout: PropTypes.string.isRequired,
  match: PropTypes.string.isRequired,
  title: PropTypes.shape().isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
  }).isRequired,
  date: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string.isRequired),
}.isRequired;

export default withRouter(LauncherItemSingle);
