import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';

import { useRoutes } from '../../../context/routesContext';

import translateObj from '../../../util/translateObj';
import getTranslation from './util/translations/_getTranslation';
import localTranslation from './util/translations/RecoverPasswordMusicMeets';
import validateEmail from './util/formValidations/validateEmail';

import Button from './Button';
import FormField from './FormField';

const RecoverPassword = ({ translation }) => {
  const { language } = useRoutes();
  const literals = getTranslation(translation, localTranslation);

  return (
    <div className="w-full flex sm:flex-col md:flex-col sm:pt-16 md:pt-24 pt-48 pb-64 sm:pb-24 md:pb-32">
      <div className="w-1/2 md:w-full sm:w-full sm:pl-0 md:pl-0 pl-1/12">
        <h4 className="leading-none font-americaBlack sm:text-24 text-50 sm:w-full w-3/4">
          {translateObj(literals.title, language)}
        </h4>
      </div>
      <div className="sm:w-full md:w-full w-1/2 pr-1/12 sm:pr-0 md:pr-0">
        <p className="text-base sm:w-full w-3/5 font-timesRegular sm:mt-8 md:mt-8">
          {translateObj(literals.text, language)}
        </p>
        <Formik
          initialValues={{
            email: '',
          }}
          validate={(values) => validateEmail(values)}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={(values) => console.log('recover password', values)}
        >
          {(props) => {
            // eslint-disable-next-line react/prop-types
            const { handleReset, errors } = props;
            return (
              <Form>
                <div className="mt-12 sm:mt-4 md:mt-10">
                  <FormField
                    name="email"
                    label={literals.labelEmail}
                    errors={errors}
                    required
                  />
                  <div className="flex justify-start mt-6 sm:mt-5">
                    <Button text={translateObj(literals.buttonCancel, language)} color="outlineBlack" horizontalPaddings="px-8" onClickCallback={handleReset} />
                    <Button type="submit" text={translateObj(literals.buttonSubmit, language)} color="black" className="ml-3 w-auto" horizontalPaddings="px-8" />
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

RecoverPassword.propTypes = {
  translation: PropTypes.shape(),
};

RecoverPassword.defaultProps = {
  translation: null,
};

export default RecoverPassword;
