import React from 'react';
import { PropTypes } from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import Countdown from '@primaveralabs/ui-countdown';
import 'Primavera/ui-themes/dist/primavera/index.css';
import 'Primavera/ui-countdown/dist/primavera.css';
import translateObj from '../../util/translateObj';
import { useRoutes } from '../../context/routesContext';

const TRANSLATIONS = {
  days: {
    en: 'DAYS',
    es: 'DÍAS',
    ca: 'DIES',
    pt: 'DIAS',
  },
  hours: {
    en: 'HOURS',
    es: 'HORAS',
    ca: 'HORES',
    pt: 'HORAS',
  },
  minutes: {
    en: 'MINUTES',
    es: 'MINUTOS',
    ca: 'MINUTS',
    pt: 'MINUTOS',
  },
  seconds: {
    en: 'SECONDS',
    es: 'SEGUNDOS',
    ca: 'SEGONS',
    pt: 'SEGUNDOS',
  },
};

const CountdownComp = ({
  message,
  url,
  urlTarget,
  textColor,
  date,
  paddingTop,
  paddingBottom,
}) => {
  const { language } = useRoutes();
  return (
    <div className="full-width w-full">
      <Countdown
        message={translateObj(message, language)}
        url={url}
        urlTarget={urlTarget}
        textColor={textColor}
        date={date}
        labelDays={translateObj(TRANSLATIONS.days, language)}
        labelHours={translateObj(TRANSLATIONS.hours, language)}
        labelMinutes={translateObj(TRANSLATIONS.minutes, language)}
        labelSeconds={translateObj(TRANSLATIONS.seconds, language)}
        paddingTop={paddingTop}
        paddingBottom={paddingBottom}
      />
    </div>
  );
};

CountdownComp.propTypes = {
  message: PropTypes.shape({}),
  url: PropTypes.string,
  urlTarget: PropTypes.string,
  textColor: PropTypes.string,
  date: PropTypes.string,
  paddingTop: PropTypes.number,
  paddingBottom: PropTypes.number,
};

CountdownComp.defaultProps = {
  message: { en: '' },
  url: '',
  urlTarget: '',
  textColor: '',
  date: '',
  paddingTop: 0,
  paddingBottom: 0,
};

export default CountdownComp;
