/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';
import translateObj from '../../../util/translateObj';

const SubtitleLineH2 = ({
  text, align, color, margin, match, slider, layout,
}) => (
  <RoutesContext.Consumer>
    {
        ({ language: contextLanguage, enteredFrom }) => {
          const localization = getLocalization(contextLanguage, enteredFrom, match);
          return (
            <h2 tabIndex="0" className={`${align} ${margin} ${slider && `${layout === "tickets" ? "mb-12 sm:mb-6" : "mt-6 mb-5 leading-regular"}`} ${layout === "tickets" ? "font-americaRegular uppercase text-sm sm:text-xs" : "font-tiemposRegular text-base sm:text-xs md:text-sm"} text-${color} mb-6 font-medium leading-regular max-w-xs xl:max-w-sm`}>
              {translateObj(text, localization.language)}
            </h2>
          );
        }
      }
  </RoutesContext.Consumer>
);

export default withRouter(SubtitleLineH2);

SubtitleLineH2.propTypes = {
  color: PropTypes.string,
  align: PropTypes.string,
  margin: PropTypes.string,
  text: PropTypes.shape({
    en: PropTypes.string.isRequired,
    es: PropTypes.string.isRequired,
  }).isRequired,
  match: PropTypes.shape().isRequired,
  slider: PropTypes.bool,
  slider: PropTypes.string,
};

SubtitleLineH2.defaultProps = {
  margin: '',
  color: 'white',
  align: 'align-left',
  slider: false,
  layout: null,
};
