import React from 'react';
import PropTypes from 'prop-types';

// SOCIAL STRUCTURE:
// social = [
//   {
//     key: string,
//     name: string,
//     link: string,
//   }
// ]

const ICONS = {
  twitter: 'font-icon icon-twitter',
  facebook: 'font-icon icon-facebook',
  instagram: 'font-icon icon-instagram',
  youtube: 'font-icon icon-youtube',
  spotify: 'font-icon icon-spotify',
  // linkedin: 'font-icon icon-linkedin',
};

const SocialLogos = ({ social, textColor, justify, className }) => (
  <div className={`w-full flex items-center ${justify} ${className}`}>
    {social.map((logo) => (
      <div key={logo.key || logo.name} className={`px-2 md:px-1 sm:px-1 ${logo.link ? '' : 'hidden'}`}>
        <a href={logo.link} target="_blank" rel="noopener noreferrer">
          <div key={logo.key || logo.name} className={`${ICONS[logo.key || logo.name.toLowerCase()]} ${textColor} text-5xl md:text-36 sm:text-36`} />
        </a>
      </div>
    ))}
  </div>
);

SocialLogos.propTypes = {
  social: PropTypes.arrayOf(PropTypes.shape()),
  textColor: PropTypes.string,
  justify: PropTypes.string,
  className: PropTypes.string,
};

SocialLogos.defaultProps = {
  social: {},
  textColor: 'text-black',
  justify: 'justify-start',
  className: '',
};

export default SocialLogos;
