import React, { Component } from 'react';
import { PropTypes } from 'prop-types';

import getRadioShows from '../../../api/graphql/queries/getRadioShows';
import LauncherRadio from '../Launcher/LauncherRadio';

import withAllContexts from '../../../context/withAllContexts';

class RadioChannelHighlightsContainer extends Component {
  state={
    highlights: null,
  }

  componentWillMount = () => {
    const { radioShowsContext } = this.props;

    if (radioShowsContext.radioShowsHighlightsData) {
      this.processRadioShows(radioShowsContext.radioShowsHighlightsData);
      radioShowsContext.clear('radioShowsHighlightsData');
    }
  }

  componentDidMount() {
    getRadioShows({ highlight: true, limit: 2 }).then((radioPrograms) => {
      this.processRadioShows(radioPrograms);
    });
  }

  processRadioShows = (radioPrograms) => {
    this.setState({ highlights: radioPrograms });
  }

  render() {
    const { highlights } = this.state;

    const params = [];

    if (highlights) {
      highlights.map((show) => {
        // transforming api props to Launcher props
        const showParams = {};
        showParams.itemLayout = 'radio';
        showParams.image = { src: show.image };
        showParams.title = show.title_multilang;
        showParams.order = 1;
        showParams.url = show.slug;
        showParams.date = '10 / 12 / 2017';
        showParams.category = 'radio';
        showParams.tags = [];

        show.tags_array_multilang.map((tag) => {
          showParams.tags.push(tag.en);
        });

        params.push(showParams);
      });
    }

    return (
      <div className="flex">
        {
          (highlights) && <LauncherRadio params={params} />
        }
      </div>
    );
  }
}

RadioChannelHighlightsContainer.propTypes = {
  radioShowsContext: PropTypes.shape().isRequired,
};

export default withAllContexts(RadioChannelHighlightsContainer);
