import React from 'react';
import Proptypes from 'prop-types';
import { withRouter } from 'react-router-dom';

const PartnersLogosContainer = ({ partners }) => (
  <div className="w-full flex flex-wrap justify-start sm:flex-col py-12 sm:px-0 px-4 mb-8">
    {
          partners.map(partner => (
            <div key={partner.name} className="sm:w-full w-1/4 self-center sm:text-center text-center">
              <a href={partner.url}>
                <img src={partner.image} alt={partner.name.en} className="w-2/3 sm:w-4/5" />
              </a>
            </div>
          ))
        }
  </div>
);

PartnersLogosContainer.propTypes = {
  partners: Proptypes.arrayOf(Proptypes.shape()).isRequired,
};

export default withRouter(PartnersLogosContainer);
