/* eslint-disable react/jsx-filename-extension */
import React, { useContext, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { PropTypes } from 'prop-types';
import getLocalization from '../util/localization';

let updateUserRoute;

const userRoute = {
  language: 'en',
  enteredFrom: 'root',
  updateUserRoute: (language, enteredFrom) => updateUserRoute(language, enteredFrom),
};

updateUserRoute = (language, enteredFrom) => {
  userRoute.language = language;
  userRoute.enteredFrom = enteredFrom;
};

const RoutesContext = React.createContext(userRoute);

export const useRoutes = (matchFromComponent) => {
  // NOTE: sending matchFromComponent is @deprecated
  const match = matchFromComponent || useRouteMatch();
  const { language, enteredFrom } = useContext(RoutesContext);
  return getLocalization(language, enteredFrom, match);
};

export const usePost = () => useContext(RoutesContext);

const searchLoginPaywall = (components) => {
  if (!Array.isArray(components)) return null;
  // eslint-disable-next-line no-restricted-syntax
  for (const component of components) {
    if (component.type === 'LoginPaywall') {
      return component;
    }
    if (component.type === 'ReusableComponent' && Array.isArray(component.components)) {
      const found = searchLoginPaywall(component.components);
      if (found) return found;
    }
  }
  return null;
};

const findLoginPaywall = (data) => {
  // First check in body components
  const bodySection = data.find((item) => item.type === 'Body');
  if (bodySection?.components) {
    const found = searchLoginPaywall(bodySection.components);
    if (found) return found;
  }
  // If not found in body, check at root level
  return searchLoginPaywall(data);
};

export const RoutesProvider = ({ children }) => {
  const [post, setPost] = useState({});

  const { postDescription, slugName, rolesAllowed, postCategory, requiresCompleteRegistration } = post;
  const paywall = findLoginPaywall(post?.components || []);

  return (
    <RoutesContext.Provider value={{
      ...userRoute,
      currentPostDescription: postDescription || {},
      currentSlugName: slugName,
      currentRolesAllowed: {
        paywall,
        slugName,
        rolesAllowed,
        url: postDescription?.url,
        requiresCompleteRegistration,
      },
      currentPostCategory: postCategory,
      setPost,
    }}
    >
      {children}
    </RoutesContext.Provider>
  );
};

RoutesProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default RoutesContext;
