import React from 'react';
import { withRouter } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import { PropTypes } from 'prop-types';
// import { concatSeries } from 'async';
import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';
import translateObj from '../../../util/translateObj';

import ParagraphLineSM from '../Text/ParagraphLineSM';


const ExpandLessWhite = props => <span {...props} className="font-icon icon-arrow-up font-black text-white text-xxs ml-2" />;

const ExpandMoreWhite = props => <span {...props} className="font-icon icon-arrow-down font-black text-white text-xxs ml-2" />;

const ExpandLessBlack = props => <span {...props} className="font-icon icon-arrow-up font-black text-black text-xs ml-2" />;

const ExpandMoreBlack = props => <span {...props} className="font-icon icon-arrow-down font-black text-black text-xs ml-2" />;

const style = theme => ({
  menu: {
    marginTop: '3rem',
    zIndex: 100000,
  },
  button: {
    '&:hover': {
      background: 'rgba(120,120,120,0.9)',
    },
    justifyContent: 'space-between',
    paddingLeft: '1.25rem',
    paddingRight: '1.25rem',
    color: 'white',
    width: '100%',
    backgroundColor: 'black',
    border: 'solid 1px white',
    borderRadius: 999,
    fontSize: '.75rem',
    fontWeight: '700',
    fontFamily: 'GT-America-Mono-Regular',
  },
  buttonWhite: {
    '&:hover': {
      background: 'rgba(120,120,120,0.9)',
    },
    color: 'black',
    width: '100%',
    backgroundColor: 'white',
    border: 'solid 1px white',
    borderRadius: 999,
  },
});
class DropDownMenu extends React.Component {
  state = {
    anchorEl: null,
    filterType: '',
    selected: '',
    isLoading: true,
  };

  componentDidMount() {
    const { filterType, selected } = this.props;
    this.setState({
      filterType,
      selected,
      isLoading: false,
    });
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleChange = (value, selected) => () => {
    const { filterType } = this.state;
    this.setState({ selected });
    const { onHandleChange } = this.props;
    onHandleChange(value, filterType);
  };

  renderButton = (localization) => {
    let buttonClassName = 'button';
    let ExpandMore = ExpandMoreWhite;
    let ExpandLess = ExpandLessWhite;

    const {
      menuTitle, menuItems, classes, white, filterType, text, filters, checkedItems,
    } = this.props;
    const {
      anchorEl, selected, isLoading,
    } = this.state;
    if (white) {
      buttonClassName = 'buttonWhite';
      ExpandMore = ExpandMoreBlack;
      ExpandLess = ExpandLessBlack;
    }
    return (
      <div className="sm:w-full md:w-1/2 w-1/3 pr-4 sm:mr-0">
        {text && <ParagraphLineSM classes="text-white m-0 pt-7 mb-6" text={text} />}
        <Button classes={{ root: classes[buttonClassName] }} aria-owns={anchorEl ? { menuTitle } : undefined} aria-haspopup="true" onClick={this.handleClick}>
          {filters && !isLoading
            ? <span>{filters[filterType] && filters[filterType].length && selected ? selected : menuTitle.text[localization.language]}</span>
            : <span>{menuTitle.text[localization.language]}</span>
          }
          {anchorEl ? <ExpandLess color="black" className="p-2" /> : <ExpandMore className="p-2" />}
        </Button>
        <Menu classes={{ paper: classes.menu }} id="simple-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.handleClose}>
          {menuItems.map(item => (
            <MenuItem key={item.text.en}>
              <Checkbox checked={checkedItems.includes(item.value)} disabled={checkedItems.includes(item.value) && filterType === 'byMaterial'} onChange={this.handleChange(item.value, !item.text[localization.language] ? item.text.en : item.text[localization.language])} value="checkedA" />
              <span onClick={this.handleChange(item.value, translateObj(item.text, localization.language))}>{translateObj(item.text, localization.language)}</span>
            </MenuItem>
          ))}
        </Menu>
      </div>
    );
  }

  render() {
    const {
      filterType, checkedItems, slugname, match,
    } = this.props;
    return (
      <RoutesContext.Consumer>
        {
        ({ language: contextLanguage, enteredFrom }) => {
          const localization = getLocalization(contextLanguage, enteredFrom, match);
          return (
            (filterType !== 'byDay' && slugname === 'press-area') || checkedItems.includes('photos') || slugname !== 'press-area' ? this.renderButton(localization) : null
          );
        }
      }
      </RoutesContext.Consumer>
    );
  }
}

DropDownMenu.propTypes = {
  classes: PropTypes.shape({}),
  menuTitle: PropTypes.shape({
    text: PropTypes.shape({
      en: PropTypes.string,
      es: PropTypes.string,
    }),
  }),
  menuItems: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
  onHandleChange: PropTypes.func.isRequired,
  filterType: PropTypes.string.isRequired,
  checkedItems: PropTypes.arrayOf(PropTypes.string),
  white: PropTypes.bool,
  slugname: PropTypes.string,
  filters: PropTypes.shape({}),
  text: PropTypes.shape({}),
  activeFilterText: PropTypes.shape({}),
  selected: PropTypes.string,
};

DropDownMenu.defaultProps = {
  classes: {},
  checkedItems: [],
  menuTitle: {},
  white: false,
  slugname: '',
  filters: {},
  text: {},
  activeFilterText: null,
  selected: '',
};

export default withRouter(withStyles(style)(DropDownMenu));
