import React, { memo, useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';

import RoutesContext from '../context/routesContext';
import translateObj from '../util/translateObj';
import ComponentIterator from './ComponentIterator';

const ComponentQuery = ({ initGetSection, staticContext }) => {
  // NOTE: staticContext brings data for SSR
  const [components, setComponents] = useState(staticContext?.data?.components || []);
  const [isLoading, setIsLoading] = useState(true);
  const { language, setPost } = useContext(RoutesContext);

  const match = useRouteMatch();

  useEffect(() => {
    const { section = false, post = false } = match && match.params ? match.params : {};
    const currentSlugname = post || section;

    initGetSection({ path: currentSlugname }).then((response) => {
      const { post: thePost = false } = response;
      if (thePost) {
        const { components: sectionComponents, postDescription } = thePost;
        if (components) {
          setComponents(sectionComponents);
          setPost(thePost);
        }
        if (!translateObj(postDescription.title, language).includes('@@')
            && translateObj(postDescription.title, language) !== 'overwrite') {
          document.title = translateObj(postDescription.title, language);
        }
      }
      setIsLoading(false);
    });
  }, [match.url]);

  if (isLoading && !staticContext) return null;

  return <ComponentIterator components={components} />;
};

ComponentQuery.defaultProps = {
  staticContext: null,
};

ComponentQuery.propTypes = {
  staticContext: PropTypes.shape({}),
  initGetSection: PropTypes.func.isRequired,
};

export default memo(ComponentQuery);
