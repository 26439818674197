import React from 'react';
import PropTypes from 'prop-types';

import { LinkWrapper } from '../../../util/link';
import { useRoutes } from '../../../context/routesContext';
import translateObj from '../../../util/translateObj';
import { BUSINESS_OPTIONS } from './util/optionValues';

const AND = {
  en: 'and',
  es: 'y',
  ca: 'i',
  pt: 'e',
};

const MentoringSessionMentorsList = ({
  items,
  className,
  textStyle,
}) => {
  const { language, path } = useRoutes();

  return (
    <div className={`flex flex-wrap sm:hidden ${className} ${textStyle}`}>
      {items.map((mentor, index) => {
        const businessTranslations = BUSINESS_OPTIONS.find(o => o.value === mentor.business)?.name;
        return (
          <div key={mentor._id}>
            <LinkWrapper url={`/pro-member-detail/?id=${mentor._id}`} path={path}>
              <span className={`underline mr-1 ${textStyle}`}>{`${mentor.name} ${mentor.lastname}`}</span>
            </LinkWrapper>
            <span className="mr-1">
              {`${mentor.business ? `(${translateObj(businessTranslations, language)})` : ''}${index < items.length - 2 ? ',' : ''}`}
            </span>
            {index === items.length - 2 && <span className="mr-1">{translateObj(AND, language)}</span>}
          </div>
        );
      })}
    </div>
  );
};

MentoringSessionMentorsList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()),
  className: PropTypes.string,
  textStyle: PropTypes.string,
};

MentoringSessionMentorsList.defaultProps = {
  items: [],
  className: '',
  textStyle: 'font-tiemposRegular text-14 text-black',
};

export default MentoringSessionMentorsList;
