import React from 'react';
import { PropTypes } from 'prop-types';

import { withRouter } from 'react-router-dom';
import Link from '../../util/link';
import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';
import imageModifier from './helpers/imageModifier';
import translateObj from '../../util/translateObj';
import Tag from './Buttons/Tag';

const SearchResult = ({
  match, slugName, postCategory, postDescription: {
    title, image, tags, description, url,
  },
}) => (
  <RoutesContext.Consumer>
    {({ language: contextLanguage, enteredFrom }) => {
      const localization = getLocalization(contextLanguage, enteredFrom, match);
      const relink = url || `/${postCategory.length && `/${postCategory[0]}`}/${slugName}`;
      // const links = itemLocation[localization.language].split('/');
      return (
        <div className="py-4 border-solid border-black border-b-1">
          {/* <div className="flex text-xs text-black font-americaMonoRegular mb-4">
              {links.map((link, i) => (
                <span key={link}>
                  {i > 0 ? '> ' : null}
                  <a className="text-black mr-2" href={link}>{link.toUpperCase()}</a>
                </span>
              ))}
            </div> */}
          <div className="flex">
            {translateObj(image, localization.language) && (
              <div className="flex-1 mr-4">
                <img src={imageModifier(translateObj(image, localization.language), '220x220') || imageModifier('https://assets.primaverasound.com/web/assets/default_image_placeholder_light_grey.png', '220x220')} alt={title.en} />
              </div>
            )}
            <div className="flex-4">
              <h2 className="font-americaBlack text-xl mb-4">
                <Link to={relink} localizationpath={localization.path}>
                  <span className="text-black">{translateObj(title, localization.language)}</span>
                </Link>
              </h2>
              <p className="font-tiemposRegular mb-4">{translateObj(description, localization.language)}</p>
              <div className="flex flex-wrap">
                {postCategory && postCategory[0] !== null && postCategory.map((tag, i) => (tag !== 'home' ? <Tag text={`#${tag}`} category={tag} key={`${tag}-${i}`} margin="my-2" /> : null))}
              </div>
            </div>
          </div>
        </div>
      );
    }}
  </RoutesContext.Consumer>
);

SearchResult.propTypes = {
  match: PropTypes.shape().isRequired,
  slugName: PropTypes.string,
  postCategory: PropTypes.arrayOf(PropTypes.string),
  postDescription: PropTypes.shape(),
};

SearchResult.defaultProps = {
  slugName: '',
  postCategory: [],
  postDescription: {},
};

export default withRouter(SearchResult);
