import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';

import { FormSelectAutoCompleteNoFormsy } from '../../Form/FormSelectAutoComplete';
import translateObj from '../../../../util/translateObj';
import getCountryNamesByCode, { getCodeFromCountry, getCountryFromCode } from '../../../../util/getCountryNamesByCode';
import profileUserEdit from '../../../../api/graphql/mutations/profileUserEdit';

import LoadIcon from '../../LoadIcon';

import { useUser } from '../../../../context/userProfileContext';
import { useRoutes } from '../../../../context/routesContext';
import InfoPopover from '../../Form/InfoPopover';

const Input = ({ backgroundColor, className, disabled, errorMessage, hasError, hidden, infoText, name, required, title, translations, ...otherProps }) => (
  <div className={`px-3 mb-4 sm:w-full ${className || 'w-1/2'} ${hidden && 'hidden'}`}>
    <label className="flex flex-col" htmlFor={name}>
      <span>
        {title}
        {required && <span className="text-12 align-top text-red-light">*</span>}
        {infoText && <div className="ml-4"><InfoPopover text={infoText} /></div>}
      </span>
      <input
        name={name}
        disabled={disabled}
        required={required}
        {...otherProps}
        className={`mt-4 rounded-full border-1 p-4 bg-${backgroundColor} ${hasError ? 'border-red text-red' : 'border-black text-black'} ${disabled && 'bg-gray200'}`}
      />
    </label>
    <span className="flex flex-col text-red mt-4">{errorMessage}</span>
  </div>
);

Input.propTypes = {
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
  errorMessage: PropTypes.string,
  hasError: PropTypes.bool,
  infoText: PropTypes.string,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  title: PropTypes.string,
  translations: PropTypes.shape(),
};

Input.defaultProps = {
  backgroundColor: 'PropTypes.string',
  className: null,
  disabled: false,
  errorMessage: null,
  hasError: false,
  hidden: false,
  infoText: null,
  required: false,
  title: null,
  translations: {},
};

const initialState = {
  data: {},
  loading: false,
  error: '',
};

const INIT = 'INIT';
const RESET_ERROR = 'RESET_ERROR';
const SET_ERROR = 'SET_ERROR';
const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';

const setInitialFormData = dispatch => payload => dispatch({ type: INIT, payload });
const updateUserRequest = dispatch => () => dispatch({ type: UPDATE_USER_REQUEST });
const updateUserSuccess = dispatch => () => dispatch({ type: UPDATE_USER_SUCCESS });
const setError = dispatch => payload => dispatch({ type: SET_ERROR, payload });

export const reducer = (state, action) => {
  if (action.type === INIT) return { ...state, data: action.payload };
  if (action.type === UPDATE_USER_REQUEST) return { ...state, error: '', loading: true };
  if (action.type === UPDATE_USER_SUCCESS) return { ...state, loading: false };
  if (action.type === SET_ERROR) return { ...state, error: action.payload };
  if (action.type === RESET_ERROR) return { ...state, error: action.payload };
  return ({
    ...state,
    data: {
      ...state.data,
      [action.name]: action.value,
    },
    error: '',
  });
};

const IncompleteModalForm = ({ translations }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const user = useUser();
  const match = useRouteMatch();
  const { language } = useRoutes(match);

  const { setNewAuthToken } = user;
  const { registerModal: formText = {} } = translations;
  const { data, error, loading } = state;

  const canBeSubmitted = !Object.keys(data).some(key => data[key] === undefined || data[key]?.length < 1);

  useEffect(() => {
    const currentValuesObj = {
      email: user?.userEmail,
      inputCountry: user?.userCountry ? getCountryFromCode(user.userCountry, language) : undefined,
      lastname: user?.userLastName,
      name: user?.userName,
      postalCode: user?.userPostalCode,
    };

    setInitialFormData(dispatch)(currentValuesObj);
  }, [user]);


  const submitForm = () => {
    updateUserRequest(dispatch)();
    const parseDataBeforeSaving = {
      ...data,
      inputCountry: getCodeFromCountry(data.inputCountry, language),
    };
    profileUserEdit(parseDataBeforeSaving).then((response) => {
      updateUserSuccess(dispatch)();
      if (response?.isEdited) setNewAuthToken(response.token);
      else if (response.error) setError(dispatch)(response.error);
      updateUserSuccess(dispatch)();
    }).catch(err => console.log(err));
  };

  const handleInput = (event) => {
    const { name, value } = event.target;
    dispatch({ name, value });
  };

  const handleCountry = value => dispatch({ name: 'inputCountry', value });

  const registerFormRender = () => {
    if (loading) return (<LoadIcon height={15} width={15} color="black" type="ThreeDots" />);

    return (
      <>
        <div className="w-full font-americaMonoRegular text-12 uppercase bg-transparent pt-4">
          <div className="flex sm:flex-col md:flex-col flex-wrap w-full">
            <h3 className="px-3 mb-8">{translateObj(formText.modalTitle, language)}</h3>
            <div className="w-full flex sm:block ">
              <Input
                backgroundColor="white"
                name="name"
                onChange={handleInput}
                doNotChangeValidColor
                title={translateObj(formText.name, language)}
                validations={{ isSpecialWords: true, maxLength: 50 }}
                validationErrors={{ isSpecialWords: translateObj(formText.inValidName, language), maxLength: translateObj(formText.longName, language) }}
                value={data.name}
                required
                className="sm:w-full w-1/2"
              />
              <Input
                backgroundColor="white"
                name="lastname"
                onChange={handleInput}
                doNotChangeValidColor
                title={translateObj(formText.lastname, language)}
                validations={{ isSpecialWords: true, maxLength: 50 }}
                validationErrors={{ isSpecialWords: translateObj(formText.inValidName, language), maxLength: translateObj(formText.longName, language) }}
                value={data.lastname}
                required
                className="sm:w-full w-1/2"
              />
            </div>
            <div className="w-full flex sm:block ">
              <Input
                backgroundColor="white"
                name="postalCode"
                onChange={handleInput}
                value={data.postalCode}
                doNotChangeValidColor
                title={translateObj(formText.postalcode, language)}
                validations={{ maxLength: 10 }}
                validationError={translateObj(formText.invalidpostalcode, language)}
                className="sm:w-full w-1/2"
                required
              />
              <FormSelectAutoCompleteNoFormsy
                backgroundColor="white"
                name="inputCountry"
                value={data.inputCountry}
                getValue={() => data.inputCountry}
                title={translateObj(formText.country, language)}
                options={getCountryNamesByCode(language)}
                message={translateObj(formText.countryError, language)}
                setValue={handleCountry}
                required
              />
            </div>
            {error && <span className="pl-3 text-red mt-4">{error}</span>}
            <div className="flex justify-between mt-8 sm:mt-0 w-full">
              <div className="w-1/2 sm:w-full md:w-full sm:px-3 md:pl-3 font-tiemposRegular normal-case text-12">
                <div className="hidden sm:block sm:w-full mt-6">
                  <button type="submit" onClick={submitForm} disabled={!canBeSubmitted} className={`${canBeSubmitted ? 'bg-black text-white' : 'bg-white text-gray200'} h-12  rounded-full px-3 w-full py-2 font-americaMonoBold`}>{translateObj(formText.submitButtonText, language)}</button>
                </div>
              </div>
              <div className="flex flex-col w-1/4 sm:hidden">
                <button type="submit" onClick={submitForm} disabled={!canBeSubmitted} className={`${canBeSubmitted ? 'bg-black text-white' : 'bg-white text-gray200'} h-12 text-white rounded-full mx-4 py-2 font-americaMonoBold`}>{translateObj(formText.submitButtonText, language)}</button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };


  return (
    <div className="w-full bg-gray200 p-8 sm:p-2">
      {translateObj(translations.registerPrimavera, language)}
      {registerFormRender()}
    </div>
  );
};

IncompleteModalForm.propTypes = {
  translations: PropTypes.shape().isRequired,
};

export default IncompleteModalForm;
