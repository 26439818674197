import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withRadioPlayerContext from '../../../context/withRadioPlayerContext';

class FooterComponent extends Component {
  componentDidMount = () => { }

  render() {
    const { children, playerPosition, showRadioPlayer } = this.props;

    return (
      <div id="FooterComponent" className={`flex w-full mt-16 sm:mx-0 md:px-4 lg:px-4 xl:px-4 mb-4 sm:mb-0 bg-white-dark flex-col ${(showRadioPlayer && playerPosition === 'pin-b') && 'mb-14'}`}>
        { children}
      </div>
    );
  }
}

FooterComponent.propTypes = {
  children: PropTypes.shape().isRequired,
  playerPosition: PropTypes.string,
  showRadioPlayer: PropTypes.bool,
};

FooterComponent.defaultProps = {
  playerPosition: null,
  showRadioPlayer: null,
};

export default withRadioPlayerContext(FooterComponent);
