import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';
import translateObj from '../../../util/translateObj';
import LoadIcon from '../LoadIcon';

const OnClickButtonRounded = ({
  text, category, match, handleClick, isDisabled, textColor, border, hoverColor, isLoading,
}) => (
  <RoutesContext.Consumer>
    {({ language: contextLanguage, enteredFrom }) => {
      const localization = getLocalization(contextLanguage, enteredFrom, match);

      const Button = () => (
        <button
          type="button"
          onClick={handleClick}
          disabled={isDisabled}
          className={`trans rounded-full block uppercase text-${textColor} text-base sm:text-12
          md:text-12 py-3 w-full px-12 font-americaMonoBold hover:bg-${hoverColor} hover:text-black trans
          ${!isDisabled ? `bg-${category}` : 'bg-grey cursor-not-allowed'} border-1 ${isDisabled ? 'border-grey' : `border-${border}`}`}
        >
          {!isLoading ? <span>{translateObj(text, localization.language)}</span> : <LoadIcon color="white" height={10} type="ThreeDots" />}
        </button>
      );

      return (
        <Button />
      );
    }
    }
  </RoutesContext.Consumer>
);


OnClickButtonRounded.propTypes = {
  category: PropTypes.string,
  hoverColor: PropTypes.string,
  border: PropTypes.string,
  textColor: PropTypes.string,
  match: PropTypes.shape().isRequired,
  text: PropTypes.shape({
    en: PropTypes.string.isRequired,
  }).isRequired,
  handleClick: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
};

OnClickButtonRounded.defaultProps = {
  category: 'transparent',
  hoverColor: 'transparent',
  border: 'transparent',
  textColor: 'white',
  isDisabled: false,
  isLoading: false,
};

export default withRouter(OnClickButtonRounded);
