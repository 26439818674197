import gql from 'graphql-tag';

import { clientWeb } from '../graphqlAPI';

const CHAT_REMOVE_ROOM = gql`
  mutation Post($doc: String) {
    chatRemoveRoom(doc: $doc) {
      result
      error
    }
  }
`;

const chatRemoveRoom = async ({ doc }) => {
  try {
    const result = await clientWeb.mutate({
      mutation: CHAT_REMOVE_ROOM,
      variables: {
        doc,
      },
      fetchPolicy: 'no-cache',
    })
      .then(queryResult => queryResult)
      .catch((queryError) => {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error: ${queryError}`);
        return null;
      });

    const { data: { chatRemoveRoom } } = result;

    return chatRemoveRoom;

  } catch (error) {
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: chatRemoveRoom Error: ${error}`);
    return null;
  }
};

export default chatRemoveRoom;
