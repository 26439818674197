const translation = {
  imageLabel: {
    en: 'MENTORING SESSION',
    es: 'MENTORING SESSION',
    ca: 'MENTORING SESSION',
    pt: 'MENTORING SESSION',
  },
  labelTime: {
    en: 'Select time',
    es: 'Selecciona hora',
    ca: 'Selecciona hora',
    pt: 'Selecione o horário',
  },
  labelDateTime: {
    en: 'Select time and date',
    es: 'Selecciona hora y fecha',
    ca: 'Selecciona hora i data',
    pt: 'Selecione hora e data',
  },
  buttonReserve: {
    en: 'Select and sign up',
    es: 'Seleccionar y apuntarse',
    ca: 'Seleccionar i apuntar-se',
    pt: 'Selecione e inscreva-se',
  },
  buttonCancel: {
    en: 'Cancel booking',
    es: 'Cancelar reserva',
    ca: 'Cancel·lar reserva',
    pt: 'Cancelar reserva',
  },
  messageReserved: {
    en: 'You have already signed up',
    es: 'Ya te has apuntado',
    ca: 'Ja t\'hi has apuntat',
    pt: 'Você já se inscreveu',
  },
  messageConcurrent: {
    en: 'You already have a session on the same day and time',
    es: 'Ya tienes una sesión el mismo día y hora',
    ca: 'Ja tens una sessió el mateix dia i hora',
    pt: 'Você já tem uma sessão no mesmo dia e horário',
  },
  relatedSessions: {
    en: 'Related sessions',
    es: 'Sesiones relacionadas',
    ca: 'Sessions relacionades',
    pt: 'Sessões relacionadas',
  },
  loadMore: {
    en: 'Load more',
    es: 'Cargar más',
    ca: 'Carrega més',
    pt: 'Carregue mais',
  },
};

export default translation;
