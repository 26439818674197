import React, { Fragment } from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import Collapse from '@material-ui/core/Collapse';
import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';

import ComponentIterator from '../../../containers/ComponentIterator';
import RadioPlayButton from './RadioPlayButton';
import MediaDescription from '../MediaDescription';
import FavoritesShare from '../FavoritesShare';
import RadioChannelImage from './RadioChannelImage';
import Tag from '../Buttons/Tag';
import OnClickButtonRounded from '../Buttons/OnClickButtonRounded';
import translateObj from '../../../util/translateObj';

const isFavoritesActive = process.env.isFavoritesActive;

const themes = {
  radio: {
    text: 'white',
    bg: 'radio',
  },
  radioinverted: {
    text: 'radio',
    bg: 'white',
  },
  black: {
    text: 'white',
    bg: 'black',
  },
};

class RadioHeader2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.open || false,
      isPlaying: false,
    };
  }

  toggle = () => {
    const { open } = this.state;
    const newOpen = !open;
    this.setState({ open: newOpen }, () => {
      const { toggleCallback } = this.props;
      if (toggleCallback) {
        toggleCallback(newOpen);
      }
    });
  };

  togglePlay = (channel) => {
    const { isPlaying } = this.state;
    this.setState({
      isPlaying: !isPlaying,
    });
  }

  render() {
    const {
      isButtonBoxVisible, handleHover, match, title, startDate, endDate, dateAir, tags, icon1Url, icon2Url, theme, mixcloud, channel, duration, subtitle, mediaImage, black, youtube, withDescription, handleModal,
    } = this.props;
    const { open, isPlaying } = this.state;
    return (
      <RoutesContext.Consumer>
        {({ language: contextLanguage, enteredFrom }) => {
          const localization = getLocalization(contextLanguage, enteredFrom, match);
          const durationText = {
            en: 'MIN',
            ca: 'MIN',
            es: 'MIN',
          };
          const startDateM = moment(startDate);
          const endDateM = moment(endDate);
          // const duration = moment.duration(endDateM.diff(startDateM)).asMinutes();
          const buttonText = {
            en: 'Watch video',
            es: 'Ver video',
            ca: 'Ver video',
          };
          return (
            <Fragment>
              <div className={`bg-${themes[theme].bg} w-full relative flex align-center justify-center p-5 text-12 text-${themes[theme].text}`} onMouseEnter={handleHover} onMouseLeave={handleHover}>
                {mixcloud ? <RadioPlayButton channel={mixcloud} togglePlaying={this.togglePlay} playing={isPlaying} /> : channel ? <RadioPlayButton channel={`ch${channel}`} /> : null}
                <div className={`flex-2 flex ${channel || mixcloud ? 'px-5' : ''} items-center`}>
                  <h5 className="font-americaMonoBold uppercase">{translateObj(title, localization.language)}</h5>
                </div>
                <div className="flex-1 pl-5 flex items-center justify-end sm:hidden">
                  {tags && (
                    <div className="mr-5">
                      <ComponentIterator components={tags} />
                    </div>
                  )}
                  {youtube ? (
                    <>
                      <Tag category="white" text="#video" textColor="radio" borderColor="white" margin="mr-4 mb-0" />
                      <div className={`${!isButtonBoxVisible && 'hidden'} bg-black rounded w-64 absolute -mx-32 p-4 z-40`} style={{ top: '50px', left: '50%' }}>
                        <OnClickButtonRounded handleClick={handleModal} text={buttonText} />
                      </div>
                    </>
                  )
                    : null}
                  {/* <span className={`w-5 mr-3 ${icon1Url}`} />
                  <span className="font-americaMonoBold uppercase">
                    {duration}
                    {' '}
                    {durationText[localization.language]}
                  </span> */}
                </div>
                <div className="hidden sm:flex items-center" onClick={this.toggle.bind(this)}>
                  <span className={`w-4 ${open ? 'rotate-180 mr-1' : ''} ${icon2Url}`} />
                </div>
              </div>
              <Collapse in={!open} timeout="auto">
                {withDescription
                  ? (
                    <RadioChannelImage
                      program_title_multilang={title}
                      subtitle_multilang={subtitle}
                      tags={tags}
                      image={mediaImage}
                      dateAir={dateAir}
                      channel={channel}
                    />
                  )
                  : mediaImage && <MediaDescription imageUrl={mediaImage} imgClass="colorImg" withDescription />}
                { isFavoritesActive && <FavoritesShare black={black} /> }
              </Collapse>
            </Fragment>
          );
        }}
      </RoutesContext.Consumer>
    );
  }
}

RadioHeader2.propTypes = {
  match: PropTypes.shape().isRequired,
  title: PropTypes.shape().isRequired,
  dateAir: PropTypes.string,
  subtitle: PropTypes.shape(),
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  icon1Url: PropTypes.string.isRequired,
  icon2Url: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  tags: PropTypes.array,
  // eslint-disable-next-line react/require-default-props
  toggleCallback: PropTypes.func,
  handleHover: PropTypes.func,
  mixcloud: PropTypes.string,
  mediaImage: PropTypes.string,
  channel: PropTypes.number,
  theme(props, propName) {
    const listOfThemes = ['black', 'radio', 'radioinverted'];
    // eslint-disable-next-line react/destructuring-assignment
    if (listOfThemes.indexOf(props[propName]) === -1) {
      return new Error(`Theme must be one of: ${listOfThemes.join(', ')}`);
    }
    return null;
  },
  youtube: PropTypes.string,
  withDescription: PropTypes.bool,
  isButtonBoxVisible: PropTypes.bool,
};

RadioHeader2.defaultProps = {
  theme: 'black',
  tags: [],
  startDate: null,
  endDate: null,
  mixcloud: null,
  mediaImage: null,
  dateAir: null,
  channel: null,
  youtube: '',
  subtitle: null,
  withDescription: false,
  isButtonBoxVisible: false,
  handleHover: null,
};

export default withRouter(RadioHeader2);
