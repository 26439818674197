import React from 'react';
import { PropTypes } from 'prop-types';

const ActiveFilter = ({
  title,
  callback,
  filterType,
}) => (
  <span className="cursor-pointer bg-gray200 py-1 px-2 mr-2 mb-2 font-americaMonoBold text-xs rounded-lg flex items-center uppercase text-white">
    <div className="flex justify-center" onClick={() => (callback ? callback(title, filterType) : null)}>
      {callback ? 'X' : '' }
      {' '}
      {title}
    </div>

  </span>
);
const ActiveFilters = ({
  filters, hasActiveFilter, radioItem, onHandleChange,
}) => (
  <div className={(!radioItem && hasActiveFilter) ? 'h-12 py-4 flex flex-wrap' : 'flex flex-wrap'}>
    {filters.byMaterial && filters.byMaterial.length ? filters.byMaterial.map(material => (
      // eslint-disable-next-line react/jsx-no-bind
      <ActiveFilter key={material} filterType="byMaterial" title={material} />
    )) : null}
    { filters.byEvent && filters.byEvent.length ? filters.byEvent.map(event => (
      // eslint-disable-next-line react/jsx-no-bind
      <ActiveFilter key={event} filterType="byEvent" title={event} callback={onHandleChange} />
    )) : null}
    {filters.byYear && filters.byYear.length ? filters.byYear.map(year => (
      // eslint-disable-next-line react/jsx-no-bind
      <ActiveFilter key={year} filterType="byYear" title={year} callback={onHandleChange} />
    )) : null}
    {filters.byCategory && filters.byCategory.length ? filters.byCategory.map(category => (
      // eslint-disable-next-line react/jsx-no-bind
      <ActiveFilter key={category} filterType="byCategory" title={category} callback={onHandleChange} />
    )) : null}
    {filters.byTag && filters.byTag.length ? filters.byTag.map(tag => (
      // eslint-disable-next-line react/jsx-no-bind
      <ActiveFilter key={tag} filterType="byTag" title={tag} callback={onHandleChange} />
    )) : null}
    {filters.byName && filters.byName.length ? filters.byName.map(tag => (
      // eslint-disable-next-line react/jsx-no-bind
      <ActiveFilter key={tag} filterType="byName" title={tag} callback={onHandleChange} />
    )) : null}
    {filters.byDay && filters.byDay.length ? filters.byDay.map(tag => (
      // eslint-disable-next-line react/jsx-no-bind
      <ActiveFilter key={tag} filterType="byDay" title={tag} callback={onHandleChange} />
    )) : null}
    {filters.byDate && filters.byDate.length ? filters.byDate.map(tag => (
      // eslint-disable-next-line react/jsx-no-bind
      <ActiveFilter key={tag} filterType="byDate" title={tag} callback={onHandleChange} />
    )) : null}
    {filters.byArtist && filters.byArtist.length ? filters.byArtist.map(tag => (
      // eslint-disable-next-line react/jsx-no-bind
      <ActiveFilter key={tag} filterType="byArtist" title={tag} callback={onHandleChange} />
    )) : null}
    {filters.byCity && filters.byCity.length ? filters.byCity.map(tag => (
      // eslint-disable-next-line react/jsx-no-bind
      <ActiveFilter key={tag} filterType="byCity" title={tag} callback={onHandleChange} />
    )) : null}
    {filters.byScene && filters.byScene.length ? filters.byScene.map(tag => (
      // eslint-disable-next-line react/jsx-no-bind
      <ActiveFilter key={tag} filterType="byScene" title={tag} callback={onHandleChange} />
    )) : null}
  </div>
);

ActiveFilters.propTypes = {
  radioItem: PropTypes.string,
  hasActiveFilter: PropTypes.bool,
  filters: PropTypes.shape({}),
  onHandleChange: PropTypes.func.isRequired,
};

ActiveFilters.defaultProps = {
  radioItem: '',
  hasActiveFilter: false,
  filters: {
    byName: [],
    byYear: [],
    byArtis: [],
    byCity: [],
    byScene: [],
    byDate: [],
    byDay: [],
    byTag: [],
  },
};

export default ActiveFilters;
