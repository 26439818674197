import React, { Component, Fragment } from 'react';
import { propTypes, withFormsy } from 'formsy-react';
import { TimelineMax, Power3 } from 'gsap';
import translateObj from '../../../util/translateObj';

const styleOutline = { outline: 'none' };
const styleOutlineMobile = { outline: 'none', paddingBottom: '1.4px' };

const defaultSuccessNow = {
  en: 'Thank you, please confirm your email address in the email we have just sent you',
  es: 'Gracias, confirma tu correo en el email que te acabamos de enviar',
  ca: 'Gràcies, confirma el teu correu a l\’email que t\’acabem d\’enviar',
};
const defaultError = {
  en: 'Something went wrong, please try again later',
  es: 'Algo salió mal, por favor inténtalo más tarde',
  ca: 'Alguna cosa ha sortit malament, si us plau intenta-ho més tard',
};
const defaultErrorEmail = {
  en: 'The email seems to be incorrect',
  es: 'Tu correo electrónico parece ser incorrecto',
  ca: 'El teu correu electrònic sembla ser incorrecte',
};
const emailAlreadyVerified = {
  en: 'Thank you! We confirm that you have registered',
  es: '¡Gracias! Te confirmamos que te has registrado',
  ca: 'Gràcies! Et confirmem que t\’has registrat',
};

class FormNewsletterInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
    };

    this.inputFieldTextTween = new TimelineMax();
  }

  componentDidUpdate = (prevProps, prevState) => {
    const {
      getValue, didSubmit, error, resetForm, setValue, submitResolved
    } = this.props;

    if (prevProps !== this.props && prevProps.submitResolved !== submitResolved) {
      this.inputFieldTextTween.stop();
      this.animateInputField();
      if (error) {
        setTimeout(() => {
          resetForm();
          setValue('');
          this.setState({ value: '' });
          this.inputFieldTextTween.stop();
        }, 3500);
      }
    }

    if (prevProps !== this.props && getValue() !== undefined) {
      if (prevState.value !== getValue()) {
        this.setState({
          value: getValue(),
        });
      }
    }
  };

  changeValue = (e) => {
    this.setState({
      value: e.currentTarget.value,
    });

    this.validate(e);
  };

  validate = (e) => {
    const { setValue } = this.props;
    setValue(e.currentTarget.value);
  };

  establishInputFieldMessage = () => {
    const { error, isValidStatus, emailVerified } = this.props;
    let defaultMessage;

    if (isValidStatus === 'emailError') {
      defaultMessage = { ...defaultErrorEmail };
    } else if (error) {
      defaultMessage = { ...defaultError };
    } else {
      defaultMessage = emailVerified ? emailAlreadyVerified : { ...defaultSuccessNow };
    }

    return defaultMessage;
  }

  animateInputField = () => {
    const { setValue, language, successNow } = this.props;
    const defaultMessage = this.establishInputFieldMessage();

    this.inputFieldTextTween
      .to(this.inputField, 0.0, { opacity: 1 })
      .to(this.inputField, 0.35, {
        opacity: 0,
        onComplete: () => {
          setValue(translateObj(successNow || defaultMessage, language));
        },
      })
      .to(this.inputField, 0.35, { paddingLeft: 0, opacity: 0 })
      .to(this.inputField, 0.5, {
        // borderStyle: 'dashed',
        paddingLeft: '1rem',
        paddingRight: '0',
        opacity: 1,
        ease: Power3.easeOut,
      })
      .play();
  };

  render() {
    const {
      name, placeholder, didSubmit, error, isLauncherItem, tabIndex,
    } = this.props;

    const { value } = this.state;

    const inputStyle = `${isLauncherItem ? 'py-1' : 'py-2'} pl-4 md:py-1 sm:py-0 md:pl-2 md:h-8 md:text-xxs sm:text-xxs`;
    const styleGlobal = `w-full bg-transparent leading-none font-americaRegular text-left border-1 ${isLauncherItem ? 'border-white text-white h-10' : 'border-black text-black h-50px'} text-xxs`;
    const rightPadding = 'md:pr-24 sm:pr-28 lg:pr-28 pr-20';
    const styleSubmitted = `bg-${error ? 'red' : 'black'} text-white`;

    const mobilePlaceholder = `${placeholder.substring(0, 32)}...`;

    return (
      <Fragment>
        <input
          ref={(c) => {
            this.inputField = c;
            return this.inputField;
          }}
          onChange={this.changeValue}
          value={value}
          type="text"
          name={name}
          autoComplete="off"
          placeholder={placeholder}
          className={`${styleGlobal} ${inputStyle} ${didSubmit ? styleSubmitted : rightPadding} sm:hidden`}
          style={styleOutline}
          readOnly={didSubmit ? 'readonly' : ''}
          tabIndex={tabIndex}
          aria-label={placeholder}
        />
        <input
          ref={(c) => {
            this.inputField = c;
            return this.inputField;
          }}
          onChange={this.changeValue}
          value={value}
          type="text"
          name={name}
          autoComplete="off"
          placeholder={mobilePlaceholder}
          className={`${styleGlobal} ${inputStyle} ${didSubmit ? styleSubmitted : rightPadding} hidden sm:block`}
          style={styleOutlineMobile}
          readOnly={didSubmit ? 'readonly' : ''}
          tabIndex={tabIndex}
          aria-label={mobilePlaceholder}
        />
      </Fragment>
    );
  }
}

FormNewsletterInput.propTypes = {
  ...propTypes,
};

export default withFormsy(FormNewsletterInput);
