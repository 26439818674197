import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Media from './MediaAlternative';

import getPostsBySlugNameQuery from '../../api/graphql/queries/getPostsBySlugNameQuery';

class PostDescriptionRow extends PureComponent {
  state = {
    linksToShow: [],
    isLoading: true,
  };

  componentExists = React.createRef(false);

  componentDidMount = () => {
    this.componentExists.current = true;
    const { slugnames } = this.props;
    this.fetchPostDrescriptionsBySlugnames(slugnames);
  };

  componentWillUnmount() {
    this.componentExists.current = false;
  }

  fetchPostDrescriptionsBySlugnames = (slugnames) => {
    getPostsBySlugNameQuery(slugnames)
      .then((response) => {
        if (this.componentExists.current) {
          if (response) {
            this.setState({
              linksToShow: response,
              isLoading: false,
            });
          } else {
            console.log('PostDescriptionRow no active posts were returned');
          }
        }
      })
      .catch((err) => {
        console.log('Some error happened on fetch of fetchPostDescription:', err);
      });
  };

  _insertElements = () => {
    const { linksToShow } = this.state;

    const { slugnames, textColor } = this.props;

    const mediaComponents = [];
    const {
      match: { params },
    } = this.props;
    if (linksToShow && linksToShow.length > 0) {
      linksToShow.map((obj, index) => {
        const { slugName, postDescription } = obj;
        const { image, title, url } = postDescription || { image: { en: 'https://assets.primaverasound.com/web/assets/default_image_placeholder_light_grey.png', title: { en: '' } } };
        const validatedUrl = url.includes('http') ? url : url[0] === '/' ? url : `/${url}`;
        mediaComponents.push(<Media key={`slugName_${index}`} textColor={textColor} image={image} text={title} slug={validatedUrl} />);
      });
    } else {
      // This is for when normally is loading the elements or when something nasty happend on fetch
      // TODO : create some fade animations
      // TODO : Replace by a default image grey image
      slugnames.map((obj, i) => {
        mediaComponents.push(
          <Media
            textColor={textColor}
            key={obj}
            image={{ en: 'https://assets.primaverasound.com/web/assets/default_image_placeholder_light_grey.png' }} // TO-DO : This needs to be an Language Object
            text={{ en: '' }}
            slug={params.section === 'editions' ? `${params.section}/${slugnames[i]}` : slugnames[i]}
          />,
        );
      });
    }

    return mediaComponents;
  };

  render() {
    const { column, color } = this.props;
    const { isLoading } = this.state;

    return (
      <div className="w-full">
        <div className={`${column && 'flex-col'} bg-${color || 'transparent'} flex flex-wrap ${!column && '-mx-2 justify-center'}`}>{!isLoading && this._insertElements()}</div>
      </div>
    );
  }
}

PostDescriptionRow.propTypes = {
  slugnames: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string])),
  column: PropTypes.bool,
  color: PropTypes.string,
  textColor: PropTypes.string,
};

PostDescriptionRow.defaultProps = {
  slugnames: ['home'],
  column: false,
  color: '',
  textColor: 'black',
};

export default withRouter(PostDescriptionRow);
