
const stripTags = (input) => {
  if (input !== null) {
    let replaced = input.replace(/(<([^>]+)>)/ig, '');
    replaced = replaced.replace(/&nbsp;/gi, '');
    return replaced;
  }
  return input;
};

export default stripTags;
