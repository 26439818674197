import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import moment from 'moment';
import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';

import getRadioShowsEpisodesForEpisode from '../../../api/graphql/queries/getRadioShowsEpisodesForEpisode';
import getRadioShows from '../../../api/graphql/queries/getRadioShows';

import withAllContexts from '../../../context/withAllContexts';
import LoadIcon from '../LoadIcon';
import MediaTitle from '../MediaTitle';
import RadioEpisodeCard from './RadioEpisodeCard';
import RadioShowCard from './RadioShowCard';
import RadioShowCardListItem from './RadioShowCardListItem';
import RadioHeader2 from './RadioHeader2';
import RadioItemType from '../RadioItemType';
import TitleLineH1 from '../Text/TitleLineH1';
import NormalBodyText from '../Text/NormalBodyText';
import InnerLink from '../InnerLink';
import RadioTag from '../Buttons/RadioTag';
import Carousel75 from '../Carousel75';
import Modal from '../Modal';
import translateObj from '../../../util/translateObj';
import SpotifyPlayer from '../SpotifyPlayer';
import NavigatorRadio from '../NavigatorRadio';

class RadioEpisode extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      showSlugName: null,
      currEpisode: {},
      relatedEpisodes: [],
      showTitle: { en: '', es: '', ca: '' },
      featuredShows: [],
      isModal: false,
      isButtonBoxVisible: false,
    };

    this.currEpisode = null;
    this.show = null;
    this.relatedEpisodes = null;
    this.showTitle = { en: '', es: '', ca: '' };
    this.featuredShows = null;
    this._isMounted = false;
  }

  componentWillMount = () => {
    const {
      radioShowsContext,
      radioShowsEpisodesContext,
    } = this.props;

    if (radioShowsContext.singleRadioEpisodeData) {
      this.processSingleRadioEpisodeData(radioShowsContext.singleRadioEpisodeData);
      radioShowsContext.clear('singleRadioEpisodeData');
      if (radioShowsEpisodesContext.singleRadioEpisodeRelatedData) {
        this.processSingleRadioEpisodeRelatedData(radioShowsEpisodesContext.singleRadioEpisodeRelatedData);
        radioShowsEpisodesContext.clear('singleRadioEpisodeRelatedData');
      }

      if (radioShowsContext.singleRadioShowTitleData) {
        this.processSingleRadioShowTitleData(radioShowsContext.singleRadioShowTitleData);
        radioShowsContext.clear('singleRadioShowTitleData');
      }

      if (radioShowsContext.featuredRadioShowsData) {
        this.processFeaturedRadioShowsData(radioShowsContext.featuredRadioShowsData);
        radioShowsContext.clear('featuredRadioShowsData');
      }

      if (this.currEpisode
        && this.show
        && this.relatedEpisodes
        && this.showTitle
        && this.featuredShows) {
        this.updateState();
      }
    }
  }

  componentDidMount() {
    this._isMounted = true;
    this.initQueries();
  }

  componentDidUpdate = (prevProps) => {
    const { match } = this.props;

    const prev = prevProps.match.url;
    const current = match.url;
    if (prev !== current) {
      this.initQueries();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  initQueries = async () => {
    const { match } = this.props;

    let episodeSlugName = null;
    let documentTitle = null;

    this.body = document.querySelector('body');
    const slugName = this.getQueryVariable('Video');
    if (slugName) this.setState({ isModal: true });

    if (match.params.episode) {
      episodeSlugName = match.params.episode;
    }
    const episodeData = await getRadioShows({ slug: episodeSlugName });
    if (episodeData && episodeData.length) {
      documentTitle = `Radio Primavera Sound - ${episodeData[0].title_multilang.en}`;
      document.title = documentTitle;
      this.processSingleRadioEpisodeData(episodeData);
      if (this.show) {
        await getRadioShowsEpisodesForEpisode({ parentSlug: this.show, limit: 3 })
          .then((episodesData) => {
            if (episodesData) {
              this.processSingleRadioEpisodeRelatedData(episodesData);
            } else {
              console.log('episodesData = null');
            }
          });
      }
      await getRadioShows({ slug: this.show })
        .then((radioShowData) => {
          if (radioShowData && radioShowData.length) {
            this.processSingleRadioShowTitleData(radioShowData);
          } else {
            console.log('radioShowData = null');
          }
        });

      await getRadioShows({ limit: 4 })
        .then((radioShowsData) => {
          if (radioShowsData) {
            this.processFeaturedRadioShowsData(radioShowsData);
          } else {
            console.log('radioShowsData = null');
          }
        });
      this.updateState();
    } else {
      document.title = 'Radio Primavera Sound';
      this.sendtoShows();
      console.log('episodeData = null');
    }
  }

  processSingleRadioEpisodeData = (episodeData) => {
    const currEpisode = episodeData[0];
    this.currEpisode = currEpisode;
    this.show = currEpisode.parentSlug;
  }

  processSingleRadioEpisodeRelatedData = (episodesData) => {
    const { match } = this.props;

    let episodeSlugName = null;

    if (match.params.episode) {
      episodeSlugName = match.params.episode;
    }

    const otherEpisodes = episodesData
      .filter(el => el.slug !== episodeSlugName)
      .sort((a, b) => b.id - a.id);

    const relatedEpisodes = otherEpisodes.map((ep) => {
      const show = { ...ep, type: 'RadioEpisodeCard' };
      return show;
    });
    this.relatedEpisodes = relatedEpisodes;
  }

  processSingleRadioShowTitleData = (radioShowData) => {
    if (radioShowData && radioShowData.length) this.showTitle = radioShowData[0].title_multilang;
  }

  processFeaturedRadioShowsData = (radioShowsData) => {
    this.featuredShows = radioShowsData.filter(el => el.slug !== this.show).slice(0, 3).map((ep) => {
      const show = { ...ep, type: 'RadioShowCard' };
      return show;
    });
  }

  updateState = () => {
    if (this._isMounted) {
      this.setState({
        showSlugName: this.show,
        currEpisode: this.currEpisode,
        relatedEpisodes: this.relatedEpisodes,
        showTitle: this.showTitle,
        featuredShows: this.featuredShows,
        isLoading: false,
      });
    }
  }

  toggleButtonBox = () => {
    const { isButtonBoxVisible } = this.state;
    this.setState({
      isButtonBoxVisible: !isButtonBoxVisible,
    });
  }

  getQueryVariable = (variable) => {
    const query = this.props.location.search;
    const vars = query.split('&');
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split('=');
      if (pair[0] === `?${variable}`) { return pair[1]; }
    }
    return (false);
  }

  handleModal = () => {
    const { location: { pathname } } = this.props;
    const { currEpisode } = this.state;
    this.props.history.push(`${pathname}?Video=${currEpisode.slug}`);
    this.setState({ isModal: true });
  }

  handleClose = () => {
    const { location: { pathname } } = this.props;
    this.body.setAttribute('style', 'overflow: auto');
    const { history } = this.props;
    history.push(`${pathname}`);
    this.setState({ isModal: false, isButtonBoxVisible: false });
  }

  addHandler = params => (
    params.map((param, index) => {
      const newParam = { ...param };
      newParam.index = index;
      newParam.onClick = this.handleModal;
      newParam.getQueryVariable = this.getQueryVariable;
      return newParam;
    })
  )

  sendtoShows = () => {
    const { history, match: { params: { lang, post } } } = this.props;
    history.push(`/${lang}/radio/shows/${post}`);
  }

  render() {
    const {
      showSlugName, currEpisode, isLoading, relatedEpisodes, showTitle, featuredShows, isModal, isButtonBoxVisible,
    } = this.state;
    const { match, location: { pathname } } = this.props;

    const relatedText = {
      es: 'EPISODIOS RELACIONADOS',
      en: 'RELATED EPISODES',
      ca: 'EPÍSODES RELACIONATS',
    };
    const featuredText = {
      es: 'SHOWS DESTACADOS',
      en: 'FEATURED SHOWS',
      ca: 'SHOWS DESTACATS',
    };

    const fullLinkText = {
      en: `${showTitle.en}: ALL EPISODES`,
      es: `${showTitle.es}: TODOS LOS EPISODIOS`,
      ca: `${showTitle.ca}: TOTS ELS EPISODIS`,
    };
    return (
      <RoutesContext.Consumer>
        {({ language: contextLanguage, enteredFrom }) => {
          const localization = getLocalization(contextLanguage, enteredFrom, match);
          moment.locale(localization.language);
          return (
            <React.Fragment>
              {!isLoading ? (
                <>
                  <NavigatorRadio title={currEpisode.title_multilang} showTitle={showTitle} margin="self-start mt-9 mb-5 md:mt-3 sm:mt-3" />
                  <div className="sm:mt-2 md:mt-3 mt-4 w-full flex flex-wrap md:flex-col-reverse sm:flex-col-reverse">
                    <div className="border-t-4 border-black sm:w-full md:w-full lg:w-1/3 smlg:w-1/4 w-1/4">
                      {relatedEpisodes && relatedEpisodes.length > 0
                        ? (
                          <>
                            <MediaTitle category="radio" primaryText={relatedText} margin="my-7" />
                            <div className="sm:hidden md:flex md:-mx-2">
                              {relatedEpisodes.map(episode => (
                                <div key={episode.id} className="md:px-2 mb-4"><RadioEpisodeCard {...episode} /></div>
                              ))}
                            </div>
                            <div className="hidden sm:block">
                              <Carousel75 components={relatedEpisodes} />
                            </div>
                          </>
                        )
                        : null}
                    </div>

                    <div className="lg:w-2/3 smlg:w-3/4 w-3/4 sm:w-full md:w-full pl-6 sm:pl-0 sm:mb-4 md:pl-0 md:mb-4">
                      <RadioHeader2
                        handleModal={this.handleModal}
                        youtube={currEpisode.youtube}
                        dateAir={currEpisode.dateAir}
                        subtitle={currEpisode.subtitle_multilang}
                        theme="radio"
                        title={currEpisode.title_multilang}
                        durationText={currEpisode.duration}
                        icon1Url="font-icon icon-clock text-24"
                        icon2Url="font-icon icon-arrow-down text-white"
                        mixcloud={currEpisode.mixcloud}
                        mediaImage={currEpisode.image}
                        black
                        isButtonBoxVisible={isButtonBoxVisible}
                        handleHover={this.toggleButtonBox}
                      />
                      <div className="flex-col bg-white flex sm:flex-wrap md:flex-wrap pl-32 sm:px-4 md:px-4 md:pb-8 sm:pb-8">
                        {/* <RadioItemType title={currEpisode.categories_multilang} category="radio" className="mt-16 mb-0 " /> */}
                        <TitleLineH1 text={currEpisode.title_multilang} textColor="#000000" margin="mt-6 mb-4 " />
                        {currEpisode.tags_array_multilang && <div className="flex flex-wrap">{currEpisode.tags_array_multilang.map((tag, i) => tag.en && <Link key={tag.en + i} to={`${localization.path}/radio/explore/${tag.en}`}><RadioTag key={tag + i} category="transparent" text={tag} textColor="black" borderColor="black" margin="mr-2 py-0 font-americaMonoRegular" /></Link>)}</div>}
                        {currEpisode.dateAir && <div className="pt-8 sm:pt-4 font-americaMonoRegular text-12">{moment(currEpisode.dateAir).format('DD / MM / YY')}</div>}
                        <NormalBodyText classes="pt-8 sm:pt-4 pr-16 sm:pr-0 md:pr-0" text={currEpisode.text_multilang} />
                        {currEpisode.spotify
                          ? <SpotifyPlayer margin listId={currEpisode.spotify} width="380" height="380" />
                          : null
                        }
                        <InnerLink linkTo={showSlugName} text={fullLinkText} />
                      </div>
                      <MediaTitle category="radio" primaryText={featuredText} />
                      <div className="flex flex-wrap flex-col sm:hidden">
                        {featuredShows.map(show => (
                          <RadioShowCardListItem {...show} key={show.id} />
                        ))}
                      </div>
                      <div className="hidden sm:block">
                        <Carousel75 components={featuredShows} />
                      </div>
                    </div>
                  </div>
                </>
              ) : <div className="h-screen fixed pin-t pin-l w-full flex items-center justify-center"><LoadIcon color="radio" /></div>}
              {isModal ? (
                <Modal
                  background="radio"
                  handleClose={this.handleClose}
                  match={match}
                  addHandler={this.addHandler}
                  slugname={pathname}
                  type="Video"
                  filtered={[currEpisode]}
                  onClick={this.handleModal}
                />
              ) : null}
            </React.Fragment>
          );
        }}
      </RoutesContext.Consumer>
    );
  }
}

RadioEpisode.propTypes = {
  match: PropTypes.shape().isRequired,
  radioShowsContext: PropTypes.shape().isRequired,
  radioShowsEpisodesContext: PropTypes.shape().isRequired,
};

export default withRouter(withAllContexts(RadioEpisode));
