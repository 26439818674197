import gql from 'graphql-tag';

import { clientWeb, persistor } from '../graphqlAPI';

const GET_PRO_MUSIC_MEETS_USER = gql`
  mutation Post($userId: String!) {
    getProMusicMeetsUser(userId: $userId) {
      _id
      nickname
      lastname
      name
      imageUrl
      occupation
      business
      company
      inputCountry
      areFriends
      description
      url
      social {
        name
        link
      }
      isMentor
    }
  }
`;

const executeQuery = async (userId) => {
  const result = await clientWeb.mutate({
    mutation: GET_PRO_MUSIC_MEETS_USER,
    variables: {
      userId,
      fetchDate: new Date().getTime().toString(),
    },
    fetchPolicy: 'no-cache',
  })
    .then((queryResult) => queryResult)
    .catch((queryError) => {
      console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error: ${queryError}`);

      return null;
    });

  if (result.data && result.data.getProMusicMeetsUser) {
    const { data: { getProMusicMeetsUser: getUserData } } = result;
    return getUserData;
  }

  return [];
};

const getProMusicMeetsUser = async (userId) => {
  try {
    const JSONObject = await executeQuery(userId);

    if (persistor) {
      persistor.persist();
    }

    return JSONObject;
  } catch (jsonError) {
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: JSON Object Error: ${jsonError}`);

    if (persistor) {
      const persistorStatus = await persistor.restore().then(() => 'OK');
      console.log(`${new Date().toLocaleTimeString()} ~ Client: Apollo Cache Restored - ${persistorStatus}`);

      try {
        const JSONObject = await executeQuery(userId, 'cache');

        return JSONObject;
      } catch (jsonErrorAfterCacheRestore) {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: JSON Object Error after Cache Restore, sending 404: ${jsonErrorAfterCacheRestore}`);
        return null;
      }
    }
    return null;
  }
};

export default getProMusicMeetsUser;
