import React from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';
import { useRoutes } from '../../../context/routesContext';
import translateObj from '../../../util/translateObj';
import { LinkWrapper } from '../../../util/link';
import localTranslation from './util/translations/NotificationItem';
import editNotification from '../../../api/graphql/mutations/editNotification';

const NotificationItem = ({
  _id,
  type,
  sender,
  relatedId,
  event,
  read,
}) => {
  const { language, path } = useRoutes();
  const history = useHistory();

  const handleRead = () => {
    editNotification({
      notificationId: _id,
      read: true,
    }).then((res) => {
      if (res.result) {
        console.log('Notification read');
        if (type === 'sessionReminder') {
          history.push(`/${language}/pro-mentoring-sessions-detail/?id=${relatedId}`);
        } else {
          history.push(`/${language}/pro-reunions?id=${relatedId}`);
        }
      }
    }).catch((err) => console.log(err));
  };

  if (!relatedId) {
    return null;
  }

  return (
    <div>
      <button type="button" className="w-full flex items-center justify-end py-8 border-b border-black relative" onClick={() => handleRead()}>
        <div className={`${read ? 'font-tiemposRegular' : 'font-tiemposBold'} text-black text-14 w-2/3 absolute pin-t pin-b pin-l pin-r py-8 text-left`}>
          <LinkWrapper
            url={type === 'sessionReminder'
              ? `/${language}/pro-mentoring-sessions-detail/?id=${relatedId}`
              : `/${language}/pro-member-detail/?id=${sender._id}`}
            path={path}
          >
            <span className="text-black underline">
              {type === 'sessionReminder'
                ? translateObj(event.eventReadableName, language)
                : `${sender.name !== null && sender.name} ${sender.lastname !== null && sender.lastname}`}
            </span>
          </LinkWrapper>
          {sender.company !== null && ` (${sender.company}) `}
          {` ${translateObj(localTranslation[type], language)}`}
        </div>
        <div>
          {['reunionInvitationConfirmation', 'reunionConfirmation'].includes(type)
            ? <div className="w-30px h-30px text-base rounded-lg bg-black font-icon icon-checkbox text-pro-online flex items-center justify-center" />
            : <div className="w-10 h-10 border-1 border-black rounded-full flex items-center justify-center text-black font-icons icon-arrow-right" />}
        </div>
      </button>
    </div>
  );
};

NotificationItem.propTypes = {
  _id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  sender: PropTypes.shape().isRequired,
  relatedId: PropTypes.string.isRequired,
  event: PropTypes.shape(),
  read: PropTypes.bool,
};

NotificationItem.defaultProps = {
  read: false,
  event: null,
};

export default NotificationItem;
