import React from 'react';
import PropTypes from 'prop-types';

const SwitchLayoutButton = ({ toggleLayoutCallback, layout }) => (
  <div
    className="bg-gray200 block font-americaMonoRegular font-xs uppercase text-black cursor-pointer border-solid border-white border-b-1 border-r-1 border-t-1"
    onClick={toggleLayoutCallback}
  >
    <div className={`h-lineupHour flex items-center justify-center ${layout === 'rows' ? 'w-48 sm:w-28' : 'w-28 sm:w-16'}`}>
      <span className="p-2 font-icon icon-lineup-grid-switch text-black text-2xl" />
    </div>
  </div>
);

SwitchLayoutButton.propTypes = {
  toggleLayoutCallback: PropTypes.func.isRequired,
  layout: PropTypes.string.isRequired,
};
export default SwitchLayoutButton;
