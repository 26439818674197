import React from 'react';
import PropTypes from 'prop-types';

const SpotifyPlayer = ({
  listId, albumId, width, height, margin,
}) => (
  <>
    {listId
  && <iframe className={`${margin ? 'mt-12' : ''} max-w-full mt-4 mb-4`} title="spotify" src={`https://open.spotify.com/embed/playlist/${listId}`} width={width} height={height} frameBorder="0" allow="encrypted-media" />
  }
    {albumId
  && <iframe className={`${margin ? 'mt-12' : ''} max-w-full mt-4 mb-4`} title="spotify" src={`https://open.spotify.com/embed/album/${albumId}`} width={width} height={height} frameBorder="0" allow="encrypted-media" />
  }
  </>
);

SpotifyPlayer.propTypes = {
  listId: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  margin: PropTypes.bool,
};

SpotifyPlayer.defaultProps = {
  width: '100%',
  height: '235',
  margin: false,
};

export default SpotifyPlayer;
