const translation = {
  title: {
    en: 'We help you to recover your password',
    es: 'Te ayudamos a recuperar tu contraseña',
    ca: 'T\'ajudem a recuperar la contrasenya',
    pt: 'Ajudamos você a recuperar sua senha',
  },
  text: {
    en: 'We send you a link to your email so you can reset your password.',
    es: 'Te enviamos un enlace a tu email para que puedas restablecer tu contraseña.',
    ca: 'T\'enviem un enllaç al teu email perquè puguis restablir la teva contrasenya.',
    pt: 'Enviamos um link para seu e-mail para que você possa redefinir sua senha.',
  },
  labelEmail: {
    en: 'Write your email',
    es: 'Escribe tu email',
    ca: 'Escriu el teu email',
    pt: 'Escreva seu e-mail',
  },
  buttonSubmit: {
    en: 'Send',
    es: 'Enviar',
    ca: 'Enviar',
    pt: 'Mandar',
  },
  buttonCancel: {
    en: 'Cancel',
    es: 'Cancelar',
    ca: 'Cancel·lar',
    pt: 'Cancelar',
  },
};

export default translation;
