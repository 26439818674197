const imageModifier = (url, settings = '465x309', filter = false) => {
  /*
  Settings must be a string with the params. Ex:
  For resizing settings= 200x200
  For grayscale settgins= grayscale()
  You can concatenate setting. Ex: settings= 200x200/grayscale()
  More options at https://thumbor.readthedocs.io/

  If filter is set to true, we return the image with fit-in filter
  */
  let replaced = `https://assets-img.primaverasound.com/web/assets/default_image_placeholder_light_grey.png/fit-in/${settings}/`;

  if (typeof url === 'string') {
    if (url.match(/\.gif/)) return url;
    if (filter) return url.replace('assets.primaverasound.com/', `assets-img.primaverasound.com/fit-in/${settings}/`);
    replaced = url.replace('assets.primaverasound.com/', `assets-img.primaverasound.com/${settings}/`);
  }


  return replaced;
};

export default imageModifier;
