import gql from 'graphql-tag';

import { clientRadio, persistor } from '../graphqlAPI';

const GET_RADIO_SHOWS_EPISODES = gql`
query radioPrograms($parentSlug: String, $highlight: Boolean, $limit: Int, $offset: Int){
    radioPrograms(parentSlug: $parentSlug, highlight: $highlight, limit: $limit, offset: $offset) {
      channel
      id
      episodeId
      dateAir
      parent
      parentSlug
      slug
      mixcloud
      youtube
      spotify
      duration
      lang
      highlight
      program_title_multilang{ca en es}
      title_multilang{ca en es}
      text_multilang{ca en es}
      subtitle_multilang {ca en es}
      tags_array_multilang {
        ca
        en
        es
      }
      image
      status
      isPlaylist
    }
  }
`;

const executeQuery = async ({ parentSlug, limit, offset }, type = 'network') => {
  if (!process.env.RADIO_GRAPHQL_SERVER_URL) {
    return [];
  }

  const result = await clientRadio.query({
    query: GET_RADIO_SHOWS_EPISODES,
    variables: { parentSlug, limit, offset },
    errorPolicy: 'all',
    fetchResults: true,
    fetchPolicy: type === 'network' ? 'network-only' : 'cache-only',
  })
    .then(queryResult => queryResult)
    .catch((queryError) => {
      const errorMessage = type === 'network'
        ? `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error: ${queryError}`
        : `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error after Cache Restore: ${queryError}`;
      console.log('\x1b[31m%s\x1b[0m', errorMessage);

      return null;
    });

  const { data: { radioPrograms: episodes } } = result;

  if (episodes) {
    return episodes;
  }
  console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: GraphQL Query Error: episodes = null`);
  return null;
};

const getRadioShowsEpisodes = async (vars) => {
  try {
    const episodes = await executeQuery(vars);

    if (persistor) {
      persistor.persist();
    }

    return episodes;
  } catch (jsonError) {
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: JSON Object Error: ${jsonError}`);

    if (persistor) {
      const persistorStatus = await persistor.restore().then(() => 'OK');
      console.log(`${new Date().toLocaleTimeString()} ~ Client: Apollo Cache Restored - ${persistorStatus}`);

      try {
        const episodes = await executeQuery(vars, 'cache');

        return episodes;
      } catch (jsonErrorAfterCacheRestore) {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: JSON Object Error after Cache Restore, sending 404: ${jsonErrorAfterCacheRestore}`);
        return null;
      }
    }
    return null;
  }
};

export default getRadioShowsEpisodes;
