import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class BodyChildContainer extends Component {
  componentDidMount = () => {
    const body = document.getElementById('bodyChildContainer');
    Object.keys(body.childNodes[0]).length > 1 && body.childNodes[0].setAttribute('style', 'border-top: none');
  };

  render() {
    const {
      children,
      className,
      noVerticalPadding,
      noHorizontalPadding,
    } = this.props;
    return (
      <div id="bodyChildContainer" className={`max-w-6xl w-full relative ${noHorizontalPadding ? '' : 'px-4'} ${noVerticalPadding ? '' : 'py-4'} ${className}`}>
        {children}
      </div>
    );
  }
}

BodyChildContainer.propTypes = {
  children: PropTypes.shape().isRequired,
  // eslint-disable-next-line react/require-default-props
  className: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  noVerticalPadding: PropTypes.bool,
  noHorizontalPadding: PropTypes.bool,
};

BodyChildContainer.defaults = {
  className: 'mt-7',
  noVerticalPadding: false,
  noHorizontalPadding: false,
};
