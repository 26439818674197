/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useRoutes } from '../../context/routesContext';
import Link from '../../util/link';
import translateObj from '../../util/translateObj';

const getWindowDimensions = () => {
  if (typeof window === 'undefined') return { width: 0 }
  const { innerWidth: width } = window;
  return {
    width,
  };
};

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, []);

  return windowDimensions;
};

const NavigatorRadio = ({
  match, isWhite, title, showTitle, margin,
}) => {
  const subPosts = ['shows', 'explore', 'channel'];
  const { language, path } = useRoutes();
  // TODO: replace match prop with useParams()
  const { width } = useWindowDimensions();
  const itemTitle = translateObj(title, language);
  const itemShowTitle = translateObj(showTitle, language);

  const truncateString = (string) => {
    let trail = translateObj(string, language);
    if (width < 390 && trail.length > 3) {
      trail = trail.substring(0, 3);
      if (trail.indexOf(' ') > -1) {
        trail = trail.substr(0, Math.min(trail.length, trail.lastIndexOf(' ')));
      }
      return `${trail}...`;
    }
    if (width < 640 && width >= 390 && trail.length > 8) {
      trail = trail.substring(0, 8);
      if (trail.indexOf(' ') > -1) {
        trail = trail.substr(0, Math.min(trail.length, trail.lastIndexOf(' ')));
      }
      return `${trail}...`;
    }
    if (width >= 640 && width <= 992 && trail.length > 25) {
      trail = trail.substring(0, 25);
      if (trail.indexOf(' ') > -1) {
        trail = trail.substr(0, Math.min(trail.length, trail.lastIndexOf(' ')));
      }
      return `${trail}...`;
    }
    return trail;
  };

  return (
    <div className={`flex ${margin}`}>
      <div className={` ${isWhite ? 'bg-white text-black font-normal ' : 'bg-black text-white font-light'} text-sm sm:text-xs font-light px-2 py-1`}>
        <Link
          to=""
          localizationpath={path}
          className={`font-americaMonoRegular uppercase ${isWhite ? 'bg-white text-black font-normal' : 'bg-black text-white font-light'} tracking-submenu text-xs sm:text-xxs underline`}
        >
                home
        </Link>
        {match.path.includes('radio')
                && (
                  <Link
                    to="/radio"
                    localizationpath={path}
                    className={`font-americaMonoRegular uppercase ${isWhite ? 'bg-white text-black font-normal' : 'bg-black text-white font-light'} tracking-submenu text-xs sm:text-xxs`}
                  >
                    <span className="no-underline">{' > '}</span>
                    <span className="underline">radio</span>
                  </Link>
                )}
        {subPosts.some(subPost => match.path.includes(subPost))
                && (match.path.includes('channel')
                  ? (match.params.channel ? (
                    <>
                      <span className="font-americaMonoRegular uppercase text-black font-light tracking-submenu text-xs sm:text-xxs">{' > '}</span>
                      <span className="font-americaMonoRegular uppercase text-radio font-light tracking-submenu text-xs sm:text-xxs">{truncateString(match.params.channel)}</span>
                    </>
                  ) : null)
                  : (match.params.post
                    ? (
                      <Link
                        to={`/radio${match.path.includes('shows') ? '/shows' : ''}${match.path.includes('explore') ? '/explore' : ''}`}
                        localizationpath={path}
                        className={`font-americaMonoRegular uppercase ${isWhite ? 'bg-white text-black font-normal' : 'bg-black text-white font-light'} tracking-submenu text-xs sm:text-xxs`}
                      >
                        <span className="no-underline">{' > '}</span>
                        <span className="underline">
                          {match.path.includes('shows') ? 'shows' : match.path.includes('explore') && 'explore'}
                        </span>
                      </Link>
                    )
                    : (
                      <>
                        <span className="font-americaMonoRegular uppercase text-black font-light tracking-submenu text-xs sm:text-xxs">{' > '}</span>
                        <span className="font-americaMonoRegular uppercase text-radio font-light tracking-submenu text-xs sm:text-xxs">{match.path.includes('shows') ? 'shows' : match.path.includes('explore') && 'explore'}</span>
                      </>
                    )
                  )
                )}
        {match.params.post
                && (match.params.episode ? (
                  <Link
                    to={`/radio/shows/${match.params.post}`}
                    localizationpath={path}
                    className={`font-americaMonoRegular uppercase ${isWhite ? 'bg-white text-black font-normal' : 'bg-black text-white font-light'} tracking-submenu text-xs sm:text-xxs`}
                  >
                    <span className="no-underline">{' > '}</span>
                    <span className="underline">
                      {itemShowTitle.length > 0 ? truncateString(showTitle) : truncateString(match.params.post)}
                    </span>
                  </Link>
                ) : (
                  <>
                    <span className="font-americaMonoRegular uppercase text-black font-light tracking-submenu text-xs sm:text-xxs">{' > '}</span>
                    <span className="font-americaMonoRegular uppercase text-radio font-light tracking-submenu text-xs sm:text-xxs">{itemTitle.length > 0 ? truncateString(title) : truncateString(match.params.post)}</span>
                  </>
                )
                )}
        {match.params.episode && (
          <>
            <span className="font-americaMonoRegular uppercase text-black font-light tracking-submenu text-xs sm:text-xxs">{' > '}</span>
            <span className="font-americaMonoRegular uppercase text-radio font-light tracking-submenu text-xs sm:text-xxs">{itemTitle.length > 0 ? truncateString(title) : truncateString(match.params.episode)}</span>
          </>
        )}
      </div>
    </div>
  );
};

NavigatorRadio.propTypes = {
  isWhite: PropTypes.bool,
  margin: PropTypes.string,
  showTitle: PropTypes.shape({}),
  title: PropTypes.shape({}),
  match: PropTypes.shape({}),
};

NavigatorRadio.defaultProps = {
  isWhite: true,
  margin: 'mb-1',
  showTitle: {},
  title: {},
  match: {},
};

export default withRouter(NavigatorRadio);
