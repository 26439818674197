import React from 'react';
import PropTypes from 'prop-types';

const Submenu = ({ name, params, width }) => (
  <div id={name} className={`flex sm:w-full justify-end items-center mb-6 ${width}`}>
    {
        params.map((link, i) => {
          // check what element is render, the last one musn't have border
          let border = 'border-r';
          (i === params.length - 1) ? border = '' : border = 'border-r';
          
          return (
            <a key={link.key} href={link.link} className="no-underline">
              <button type="button" className={`font-americaMonoRegular uppercase text-black tracking-submenu px-3 text-xs sm:border-transparent border-black ${border}`}>
                {link.image && <img src={link.image} alt={link.key} className={`w-3 mr-2 sm:w-4 ${link.key === 'search' ? 'sm:w-6' : 'sm:w-4'}`} />}
                {link.text && <span> {link.text.en} </span>}
              </button>
            </a>
          );
        })
      }
  </div>
);

Submenu.propTypes = {
  name: PropTypes.string.isRequired,
  params: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
};


export default Submenu;
