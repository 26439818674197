import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { toQuery } from './utils';

const createWindow = (url, title, options) => window.open(url, title, toQuery(options, ','));

const PopupWindow = ({ children, onAuth, title, options, url }) => {
  const [externalWindow, setExternalWindow] = useState();

  const windowInterval = useRef();

  const clearWindowInterval = () => clearInterval(windowInterval.current);

  const initWindow = () => {
    setExternalWindow(createWindow(url, title, options));
  };

  useEffect(() => {
    if (externalWindow) {
      windowInterval.current = window.setInterval(() => {
        try {
          const currentUrl = externalWindow.location.href;
          const params = new URL(currentUrl).searchParams;
          const code = params.get('code');
          if (!code) return;
          onAuth(code, params);
          clearWindowInterval();
          externalWindow.close();
        } catch (error) {
          // eslint-ignore-line
        } finally {
          if (!externalWindow || externalWindow.closed) {
            clearWindowInterval();
          }
        }
      }, 500);
    }

    return () => clearWindowInterval();
  }, [externalWindow]);

  return (
    <div onClick={initWindow}>
      {children}
    </div>
  );
};

PopupWindow.propTypes = {
  children: PropTypes.node.isRequired,
  onAuth: PropTypes.func,
  title: PropTypes.node.isRequired,
  options: PropTypes.shape({
    height: PropTypes.number,
    width: PropTypes.number,
  }),
  url: PropTypes.string.isRequired,
};

PopupWindow.defaultProps = {
  onAuth: () => {},
  options: {
    height: 1000,
    width: 600,
  },
};

export default PopupWindow;
