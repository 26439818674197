import React from 'react';
import { PropTypes } from 'prop-types';
import moment from 'moment-timezone';

const userTimezone = moment.tz.guess() || 'Europe/Madrid';

const getNavigatorLanguage = () => ((navigator.languages && navigator.languages.length) ? navigator.languages[0] : navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en');

const SimpleScheduleTableCardItem = ({ data, timezone }) => {
  const locale = getNavigatorLanguage();
  moment.locale(locale);
  return (
    <div className="flex flex-col w-full">
      {data.map((item) => {
        if (!item.artist || !item.day) return null;
        const [year, month, dayOfMonth] = (item.day).split('-');
        const [hour, minute] = (item.dateTime).split(':');

        const letHasDatetime = hour && minute;
        let realDate;
        let userDate;

        if (letHasDatetime) {
          const sheduleTime = moment.tz([year, month - 1, dayOfMonth, hour, minute], timezone);
          realDate = sheduleTime.format('MMM D, HH:mm z');
          userDate = sheduleTime.tz(userTimezone).format('MMM D, HH:mm z');
        } else {
          realDate = moment([year, month - 1, dayOfMonth]).format('MMM D');
        }

        return (
          <div className="border-grey-light border-t mt-3">
            <p className="font-americaBold text-base text-black border-grey-light pt-3">{item.artist}</p>
            <p className="font-americaRegular text-sm text-black pt-1">
              {realDate}
              {
                ((userTimezone === timezone) || !letHasDatetime) ? null : ` | ${userDate}`
              }
            </p>
          </div>
        );
      })}
    </div>
  );
};

SimpleScheduleTableCardItem.propTypes = {
  data: PropTypes.shape().isRequired,
  timezone: PropTypes.string.isRequired,
};

export default SimpleScheduleTableCardItem;
