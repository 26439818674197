import React from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';
import Link from '../../../util/link';

import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';
import translateObj from '../../../util/translateObj';

const TagWithLink = ({
  text, url, className, match,
}) => (
  <RoutesContext.Consumer>
    {({ language: contextLanguage, enteredFrom }) => {
      const localization = getLocalization(contextLanguage, enteredFrom, match);
      let usableUrl = url;
      if (url.substring(0, 1) !== '/' && !/^https?:\/\//.test(url)) {
        usableUrl = `/${url}`;
      }
      if (url.length === 1 && url === '/') {
        usableUrl = '';
      }
      const Button = () => (
        <div
          className="mt-6 mr-1 inline-block ml-0 text-xs cursor-pointer align rounded px-2 leading-normal hover:bg-white text-black trans hover:text-black bg-transparent border-1 border-black py-3px font-americaMonoMedium md:mb-2"
        >
          <span className="uppercase">
            {translateObj(text, localization.language)}
          </span>
        </div>
      );

      return (
        <React.Fragment>
          <Link to={`${usableUrl}`} localizationpath={`${localization.path}`} className=" block">
            <Button />
          </Link>
        </React.Fragment>
      );
    }}
  </RoutesContext.Consumer>
);

TagWithLink.propTypes = {
  match: PropTypes.shape().isRequired,
  text: PropTypes.shape().isRequired,
  url: PropTypes.string,
  className: PropTypes.string,

};

TagWithLink.defaultProps = {
  className: '',
  url: null,

};

export default withRouter(TagWithLink);
