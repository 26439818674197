import React from 'react';
import PropTypes from 'prop-types';

const TopHeaderSubmenu = ({
  children, name, backgroundColor,
}) => (
  <section role="navigation" id={name} className={`flex bg-${backgroundColor} h-9 justify-between items-center`}>
    { children }
  </section>
);

TopHeaderSubmenu.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.shape().isRequired,
  backgroundColor: PropTypes.string,

};

TopHeaderSubmenu.defaultProps = {
  backgroundColor: 'white',
};

export default TopHeaderSubmenu;
