import React from 'react';
import { PropTypes } from 'prop-types';
import Emoji from 'react-emoji-render';
import { useTheme } from '../context/themeContext';

const QuestionsView = ({ messages }) => {
  const { color } = useTheme();

  return (
    <div className="py-2 text-base bg-black">
      {messages.map(message => (
        <div key={message.messageId} className={`px-4 break-words leading-normal text-${color}`}>
          <span className="font-americaBlack break-all text-psworld-orange">Q:</span>
          <span className="font-americaRegular pl-1 italic"><Emoji text={message.message} onlyEmojiClassName="text-xl" /></span>
        </div>
      ))}
    </div>
  );
};

QuestionsView.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default QuestionsView;
