import React from 'react';
import { PropTypes } from 'prop-types';

import { withRouter } from 'react-router-dom';
import moment from 'moment';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

class LineupIsOnNowHeader extends React.Component {
  state = {
    selectedDate: moment(),
    day: moment().date(),
    month: moment().month() + 1,
    year: moment().year(),
    maxDays: moment().daysInMonth(),
  }

  componentDidMount() {
  }

  handleChange = (e, name) => {
    const { value } = e.target;
    if (value > 0) {
      this.setState({ [name]: value }, () => {
        const { year, month, day } = this.state;
        this.setState({
          maxDays: moment(`${year}-${month}-01`, 'YYYY-MM-DD').daysInMonth(),
          selectedDate: moment(`${year}-${month}-${day}`, 'YYYY-MM-DD'),
        }, () => this.props.handleNewDate(this.state.selectedDate));
      });
    }
  }

  handleNextPreviousDay = (num) => {
    const { selectedDate } = this.state;
    const { handleNewDate } = this.props;
    const yesterDay = moment(selectedDate).add(num, 'days');
    this.setState({
      selectedDate: yesterDay,
    });
    handleNewDate(yesterDay);
  }


  renderDays = () => {
    const options = [];
    const { maxDays } = this.state;
    for (let i = 1; i <= maxDays; i++) {
      options.push(<MenuItem key={i} value={i}>{i}</MenuItem>);
    }
    return options;
  }

  static renderMonths() {
    const options = [];
    for (let i = 1; i <= 12; i++) {
      options.push(<MenuItem key={i} value={i}>{i}</MenuItem>);
    }
    return options;
  }

  renderYears = () => {
    const options = [];
    for (let i = moment().year(); i >= 2000; i--) {
      options.push(<MenuItem key={i} value={i}>{i}</MenuItem>);
    }
    return options;
  }

  render() {
    const {
      title, today, filterOn, text1, text2, text3, dayBeforeText, dayAfterText, dateFormat, match, language, isLight,
    } = this.props;
    const {
      selectedDate, day, month, year,
    } = this.state;

    const InfoRow = () => (
      <div className={`flex font-americaMonoRegular ${isLight ? 'bg-grayE2DDDB text-black' : 'bg-psworld-box text-white'}  uppercase py-3 sm:hidden w-full `}>
        <div className="w-1/6" />
        <div className="w-5/6 flex">
          <div className="w-1/5 text-xs">{text1[language]}</div>
          <div className="w-3/5 text-xs">{text2[language]}</div>
          {/* <div className="w-1/5 text-xs">{text3[language]}</div> */}
        </div>
      </div>
    );
    return (

      <>
        <div className="w-full text-white font-americaMonoRegular bg-black p-8 sm:p-2 md:p-4">
          <div className="flex align-center">
            <div className="flex justify-start">
              <span className="text-xs flex items-center uppercase cursor-pointer" onClick={() => this.handleNextPreviousDay(-1)}>
                <span className="font-icon icon-arrow-left mr-2" />
                {dayBeforeText[language]}
              </span>
            </div>
            <div className="flex-1 text-sm text-center font-americaMonoBold uppercase">
              {selectedDate.isSame(moment(), 'day') ? today[language] : moment(selectedDate).format(dateFormat[language]) }
            </div>
            <div className="flex justify-end">
              <span className="text-xs flex items-center uppercase cursor-pointer" onClick={() => this.handleNextPreviousDay(1)}>
                {dayAfterText[language]}
                <span className="font-icon icon-arrow-right ml-2" />
              </span>
            </div>
          </div>
        </div>
        <InfoRow text1={text1} text2={text2} text3={text3} />
      </>

    );
  }
}

LineupIsOnNowHeader.propTypes = {
  match: PropTypes.shape().isRequired,
  title: PropTypes.shape().isRequired,
  text1: PropTypes.shape().isRequired,
  text2: PropTypes.shape().isRequired,
  dayBeforeText: PropTypes.shape().isRequired,
  dayAfterText: PropTypes.shape().isRequired,
  dateFormat: PropTypes.shape().isRequired,
  handleNewDate: PropTypes.func.isRequired,
  language: PropTypes.string,
  isLight: PropTypes.bool,
};

LineupIsOnNowHeader.defaultProps = {
  language: 'en',
  isLight: false,
};

export default withRouter(LineupIsOnNowHeader);
