import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useRoutes } from '../../../context/routesContext';
import { getCountryFromCode } from '../../../util/getCountryNamesByCode';

import SquareImage from './SquareImage';
import IconButton from './IconButton';

const ReunionFormParticipantItem = ({
  _id,
  imageUrl,
  name,
  lastname,
  company,
  occupation,
  inputCountry,
  onClickCallback,
  checked,
  selected,
  areFriends,
  disabled,
}) => {
  const [check, setCheck] = useState(false);
  const { language } = useRoutes();
  const countryISOcode = getCountryFromCode(inputCountry, language);

  useEffect(() => {
    setCheck(checked);
  }, [checked]);

  return (
    <div
      key={_id}
      className={`flex justify-between items-center w-full p-4 ${check && !selected ? 'bg-black text-white' : 'bg-grayE2DDDB'} ${(selected || disabled) ? '' : 'cursor-pointer'} ${disabled ? 'opacity-50 pointer-events-none' : ''}`}
      onClick={onClickCallback}
    >
      <div className="flex items-center w-full pr-2">
        <div className={`font-icon text-pro-online ${areFriends ? 'icon-star-solid' : 'icon-star'}`} />
        <div className="ml-4 min-w-1/6 w-1/6 sm:min-w-1/4 sm:w-1/4 md:min-w-1/4 md:w-1/4 xl:min-w-1/12 xl:w-1/12">
          <SquareImage image={imageUrl} alt={name} />
        </div>
        <div className="flex flex-col ml-4 sm:w-3/4 md:w-3/4">
          <span className="text-sm font-tiemposBold">
            {name}
            <span className="mx-1">{lastname}</span>
          </span>
          <div className="flex flex-wrap  font-timesRegular sm:text-12 text-sm">
            {company && (
              <>
                <span>{company}</span>
                <div className="mx-1">|</div>
              </>
            )}
            {occupation && (
              <>
                <span>{occupation}</span>
                <div className="mx-1">|</div>
              </>
            )}
            {countryISOcode && <span>{countryISOcode}</span>}
          </div>
        </div>
      </div>
      {selected ? <IconButton onClickCallback={() => onClickCallback(_id)} icon="cancel" /> : (
        <div className={`border ${check && !selected ? 'border-white' : 'border-black'} rounded h-5 w-5 mt-px flex items-center justify-center`}>
          {check && !selected && <div className="font-icon icon-checkbox text-green-checkbox text-base" />}
        </div>
      )}
    </div>
  );
};


ReunionFormParticipantItem.propTypes = {
  _id: PropTypes.string,
  imageUrl: PropTypes.string,
  name: PropTypes.string,
  lastname: PropTypes.string,
  company: PropTypes.string,
  occupation: PropTypes.string,
  inputCountry: PropTypes.string,
  onClickCallback: PropTypes.func,
  checked: PropTypes.bool,
  selected: PropTypes.bool,
  areFriends: PropTypes.bool,
  disabled: PropTypes.bool,
};

ReunionFormParticipantItem.defaultProps = {
  _id: '',
  imageUrl: '',
  name: '',
  lastname: '',
  company: '',
  occupation: '',
  inputCountry: '',
  onClickCallback: () => {},
  checked: false,
  selected: false,
  areFriends: false,
  disabled: false,
};

export default ReunionFormParticipantItem;
