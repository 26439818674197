import React from 'react';
import { PropTypes } from 'prop-types';

import { withRouter } from 'react-router-dom';
import moment from 'moment';
import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';
import ComponentIterator from '../../containers/ComponentIterator';
import RadioPlayButton from './RadioPlayButton';

const themes = {
  radio: {
    text: 'white',
    bg: 'radio',
  },
  radioinverted: {
    text: 'radio',
    bg: 'white',
  },
  black: {
    text: 'white',
    bg: 'black',
  },
};

class RadioHeader2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.open || false,
    };
  }

  toggle() {
    const { open } = this.state;
    this.setState({ open: !open }, () => {
      if (this.props.toggleCallback) {
        this.props.toggleCallback(this.state.open);
      }
    });
  }

  render() {
    let {
      match,
      title,
      startDate,
      endDate,
      tags,
      durationText,
      icon1Url,
      icon2Url,
      radioPlayButton,
      theme,
    } = this.props;

    const { open } = this.state;

    return (
      <RoutesContext.Consumer>
        {
        ({ language: contextLanguage, enteredFrom }) => {
          const localization = getLocalization(contextLanguage, enteredFrom, match);

          startDate = moment(startDate);
          endDate = moment(endDate);
          const duration = moment.duration(endDate.diff(startDate)).asMinutes();

          return (
            <div className={`bg-${themes[theme].bg} w-full flex align-center justify-center p-5 text-12 text-${themes[theme].text}`}>
              <RadioPlayButton {...radioPlayButton} />
              <div className="flex-1 flex px-5 items-center">
                <h5 className="font-americaMonoBold uppercase">{title[localization.language]}</h5>
              </div>
              <div className="flex-1 pl-5 flex items-center justify-end sm:hidden">
                <div className="mr-5">
                  <ComponentIterator components={tags} />
                </div>
                <img className="w-5 mr-3" src={icon1Url} alt={durationText[localization.language]} />
                <span className="font-americaMonoBold uppercase">
                  {duration}
                  {' '}
                  {durationText[localization.language]}
                </span>
              </div>
              <div className="hidden sm:flex items-center" onClick={this.toggle.bind(this)}>
                <img className={`w-4 ${open ? 'rotate-180' : ''}`} src={icon2Url} alt={title[localization.language]} />
              </div>
            </div>
          );
        }
      }
      </RoutesContext.Consumer>
    );
  }
}

RadioHeader2.propTypes = {
  title: PropTypes.shape().isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  durationText: PropTypes.shape().isRequired,
  tags: PropTypes.array.isRequired,
  icon1Url: PropTypes.string.isRequired,
  icon2Url: PropTypes.string.isRequired,
  open: PropTypes.bool,
  radioPlayButton: PropTypes.shape().isRequired,
  theme(props, propName, componentName) {
    const themes = ['black', 'radio', 'radioinverted'];
    if (themes.indexOf(props[propName]) == -1) {
      return new Error(`Theme must be one of: ${themes.join(', ')}`);
    }
  },
};

RadioHeader2.defaultProps = {
  theme: 'black',
};

export default withRouter(RadioHeader2);
