import React from 'react';

const LinkedinIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 2 2" fill="none">
    <g clipPath="url(#a)">
      <path fill="#000" d="M.707 2C.69 1.99.685 1.976.685 1.958V.684c0-.03.011-.042.041-.042h.366c.03 0 .042.012.042.042v.07l.003.002.015-.016a.452.452 0 0 1 .266-.126.617.617 0 0 1 .3.034.39.39 0 0 1 .23.24c.03.078.043.16.047.243.004.101.004.202.005.304v.523c0 .02-.006.032-.023.04h-.42c-.017-.008-.022-.02-.023-.04l-.002-.697a.407.407 0 0 0-.018-.122c-.014-.048-.04-.085-.09-.102a.24.24 0 0 0-.099-.008.164.164 0 0 0-.155.126.482.482 0 0 0-.02.139v.664c0 .018-.006.032-.023.04h-.42v.001ZM.052 2c-.016-.01-.02-.025-.02-.044V.686c0-.033.01-.044.042-.044h.38c.032 0 .043.012.043.043v1.274c0 .019-.006.032-.023.04H.052ZM0 .235C.007.212.011.186.02.164a.265.265 0 1 1 .282.364.265.265 0 0 1-.3-.23L0 .29V.235Z" />
    </g>
  </svg>
);

export default LinkedinIcon;
