import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import getPostsBySlugNameQuery from '../../api/graphql/queries/getPostsBySlugNameQuery';
import Link from '../../util/link';
import imageModifier from './helpers/imageModifier';
import translateObj from '../../util/translateObj';
import { useRoutes } from '../../context/routesContext';

const PLACEHOLDER_IMAGE = 'https://assets.primaverasound.com/web/assets/default_image_placeholder_light_grey.png';
const LOGOS_SIZE = '600x400';

const logosClasses = 'w-28 sm:mr-0 mr-8';

const Logo = ({ slug, image, path, isLogoRow  }) => (
  <div key={slug} className={`${logosClasses} ${isLogoRow ? 'sm:mt-4' : ''}`}>
    <Link to={slug} localizationpath={path}>
      <img src={image} alt={image} />
    </Link>
  </div>
);

Logo.propTypes = {
  slug: PropTypes.string,
  image: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
};

Logo.defaultProps = {
  slug: null,
};

const getImage = (image, language) => imageModifier(translateObj(image, language), LOGOS_SIZE);

const LogosRowModule = ({ slugnames, column, color }) => {
  const [logos, setLogos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { language, path } = useRoutes();

  const fetchPostDescriptionsBySlugnames = (slugs) => {
    setIsLoading(true);
    getPostsBySlugNameQuery(slugs)
      .then((response) => {
        if (response) {
          const logosFromResponse = response.map((logo) => logo.postDescription);
          setLogos(logosFromResponse);
        } else console.log('LogosRowModule no active posts were returned');
      })
      .catch((err) => {
        console.log('Some error happened on fetch of fetchPostDescription:', err);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    fetchPostDescriptionsBySlugnames(slugnames);
  }, [slugnames]);

  return (
    <div className="w-full">
      <div className={`${column ? 'flex-col' : ''} bg-${color || 'transparent'} flex flex-wrap sm:justify-around ${!column ? '-mx-2' : ''}`}>
        {
          (isLoading && slugnames.length)
            ? slugnames.map((slug) => <Logo key={slug} image={imageModifier(PLACEHOLDER_IMAGE, LOGOS_SIZE)} path={path} />)
            : (logos.length) && logos.map(({ url, image }) => <Logo key={image?.en || url} slug={url} image={getImage(image, language)} path={path} isLogoRow />)
        }
      </div>
    </div>
  );
};

LogosRowModule.propTypes = {
  slugnames: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string])),
  column: PropTypes.bool,
  color: PropTypes.string,
};

LogosRowModule.defaultProps = {
  slugnames: [],
  column: false,
  color: '',
};

export default LogosRowModule;
