/* eslint-disable jsx-a11y/tabindex-no-positive */
import React from 'react';
import { PropTypes } from 'prop-types';
import withRadioPlayerContext from '../../../context/withRadioPlayerContext';
import withAllContexts from '../../../context/withAllContexts';
import translateObj from '../../../util/translateObj';

const ARIA_LABEL_PLAY_RADIO = {
  en: 'Play Radio Primavera Sound',
  es: 'Reproducir Radio Primavera Sound',
  ca: 'Reproduir Radio Primavera Sound',
  pt: 'Tocar Radio Primavera Sound',
};
const ARIA_LABEL_PAUSE_RADIO = {
  en: 'Pause Radio Primavera Sound',
  es: 'Pausar Radio Primavera Sound',
  ca: 'Pausar Radio Primavera Sound',
  pt: 'Pausar Radio Primavera Sound',
};

class RadioPlayButton extends React.Component {
  state = {
    iAmPlaying: false,
  }

  togglePlay = () => {
    const { iAmPlaying } = this.state;
    this.setState({
      iAmPlaying: !iAmPlaying,
    });
  }


  render() {
    const {
      togglePlaying, playing, channel, radioActive, size, color, showPlayer, headerContext, routesContext,
    } = this.props;
    const { language } = routesContext;
    const { iAmPlaying } = this.state;
    // if channel is passed, the radio play correspond to a radio tunner card, if not it belong to the player
    const RenderButton = () => {
      if (channel) {
        if ((playing && (channel === radioActive))) {
          return <span className={`font-icon icon-pause text-${color} text-${size} cursor-pointer`} />;
        }
        return <span className={`font-icon icon-player text-${color} text-${size} cursor-pointer`} />;
      }
      if (playing) return <span className={`font-icon icon-pause text-${color} text-${size} cursor-pointer`} />;
      return <span className={`font-icon icon-player text-${color} text-${size} cursor-pointer`} />;
    };
    return (
      <button
        tabIndex={headerContext.megaMenuIsOpenDesktop ? '-1' : '1'}
        type="button"
        onClick={() => { togglePlaying(channel); this.togglePlay(); showPlayer(); }}
        aria-label={playing ? translateObj(ARIA_LABEL_PAUSE_RADIO, language) : translateObj(ARIA_LABEL_PLAY_RADIO, language)}
      >
        <RenderButton />
      </button>
    );
  }
}

RadioPlayButton.propTypes = {
  playing: PropTypes.bool.isRequired,
  size: PropTypes.number,
  color: PropTypes.string,
  headerContext: PropTypes.shape({}).isRequired,
};

RadioPlayButton.defaultProps = {
  size: 52,
  color: 'white',
};

export default withRadioPlayerContext(withAllContexts(RadioPlayButton));
