import React, { Component } from 'react';

import ParagraphLineSM from './Text/ParagraphLineSM';


export default class SelectableOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: '',
    };
  }

  handleClick(selected) {
    this.setState({ selected });
  }

  render() {
    const { title, hasBorder, options } = this.props;

    return (
      <div className="flex text-xs h-full flex-col justify-center">
        {title
          ? (
            <ParagraphLineSM
              color="white"
              text={title}
            />
          ) : <div className="h-10" />
        }
        <div className="flex relative items-center h-full">
          {options.map(option => (

            <label
              onClick={() => this.handleClick(option.text)}
              key={option.text}
              id={option.text}
              className={`container ${hasBorder && 'border-white border-1'} p-3 rounded-lg cursor-pointer mr-5 text-white px-6 pl-12 container flex items-center relative block`}
              htmlFor={option.text}
            >
              {option.text}
              <input
                className="checkbox absolute opacity-0  cursor-pointer h-0 w-0"
                type="radio"
                id={option.text}
                name={option.text}
                value={option.text}
                checked={option.text === this.state.selected}
              />
              <span className={`checkmark ${!hasBorder && 'bg-white'} absolute pin-l rounded-full h-6 w-6 ml-4 border-white border-1`} />

            </label>

          ))}
        </div>
      </div>
    );
  }
}
