import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ComponentIterator from '../../containers/ComponentIterator';

class BlankTemplate extends Component {
  componentDidMount = () => {}

  render() {
    const { children, components } = this.props;

    return (
      <ComponentIterator components={components} />
    );
  }
}

BlankTemplate.propTypes = {
  children: PropTypes.shape().isRequired,
};

BlankTemplate.defaultProps = {

};

export default BlankTemplate;
