import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import IframeResizer from 'iframe-resizer-react';
import translateObj from '../../util/translateObj';

const IframeComponent = ({ url, fullWidth, match }) => {
  let urlObj = url;
  if (typeof url === 'string') {
    urlObj = {
      en: url,
    };
  }
  const { params: { lang } } = match;
  return (
    <div className={`${fullWidth ? 'full-width' : 'w-full'}`}>
      {translateObj(urlObj, lang).includes('goosebumps.primaverasound.com')
        ? (
          <div className="bg-white">
            <IframeResizer
              src={translateObj(urlObj, lang)}
              style={{ width: '1px', minWidth: '100%' }}
              heightCalculationMethod="taggedElement"
            />
          </div>
        ) : (
          <div className="iframe-container w-full">
            <iframe title="iframe" src={translateObj(urlObj, lang)} frameBorder="0" />
          </div>
        )
    }
    </div>
  );
};

IframeComponent.propTypes = {
  url: PropTypes.shape(),
  fullWidth: PropTypes.bool,
  match: PropTypes.shape().isRequired,
};

IframeComponent.defaultProps = {
  url: { en: 'https://map.closer2event.com/?event=primaverasound' },
  fullWidth: true,
};

export default withRouter(IframeComponent);
