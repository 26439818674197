/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';
// import { withStyles } from '@material-ui/core/styles';
// import Popover from '@material-ui/core/Popover';

// import { Button } from '@material-ui/core';
import Link from '../../../util/link';
import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';
import SearchOverlay from '../SearchOverlay';
import translateObj from '../../../util/translateObj';
// import AllContexts from '../../../context/allContexts';
import { UserProfileContextConsumer } from '../../../context/userProfileContext';

import UserMenuPopup from '../UserMenuPopup';


// const styles = theme => ({
//   paper: {
//     right: 0,
//   },
// });


class TopHeaderSubmenuItemsContainer extends React.Component {
  state = {
    isActiveSearch: false,
    ssrDone: false,
    // anchorEl: null,
  };

  // popover user logic

  // handleClick = (event) => {
  //   this.setState({ anchorEl: event.currentTarget });
  // };

  // handleClose = () => {
  //   this.setState({ anchorEl: null });
  // };


  handleSearchOverlay = () => {
    const { isActiveSearch } = this.state;
    this.setState({
      isActiveSearch: !isActiveSearch,
    });
  };

  handleSearch = (query, lang) => {
    this.props.history.push(`/search?query=${query}&lang=${lang}`);
    this.handleSearchOverlay();
  };

  componentDidMount() {
    this.setState({ ssrDone: true });
  }

  // componentWillUpdate(){

  // }


  render() {
    const {
      name, mainParent, params, match, textColor,
    } = this.props;
    const { ssrDone, isActiveSearch } = this.state;


    return (
      <UserProfileContextConsumer>
        {({
          userName, userLastName, token, loadTokenForLS,
        }) => (
          <RoutesContext.Consumer>
            {({ language: contextLanguage, enteredFrom }) => {
              const localization = getLocalization(contextLanguage, enteredFrom, match);
              const stylesContainer = (parent) => {
                let styles = '';
                if (parent === 'footerBottomSubmenu-xl') {
                  return 'flex sm:flex md:flex flex-wrap sm:justify-center md:w-7/8 md:justify-end xl:-mr-4 xl:pr-0';
                }
                if (parent === 'subMenu') {
                  styles = 'flex sm:hidden md:hidden';
                } else if (parent === 'loginMenu') {
                  styles = 'md:w-full flex ml-12 md:mr-0 md:ml-0 sm:mr-0 sm:ml-0 sm:flex-wrap md:flex-wrap justify-center md:justify-end sm:justify-end';
                } else if (parent === 'mobileMenu') {
                  styles = 'hidden sm:flex md:flex sm:pb-8 md:pb-8 flex-wrap sm:justify-center md:justify-center';
                } else {
                  styles = 'flex sm:flex md:flex flex-wrap sm:justify-center md:justify-center';
                }
                return styles;
              };
              const stylesButton = (parent) => {
                if (parent === 'footerBottomSubmenu') {
                  return `text-xxs sm:border-${textColor}`;
                }
                if (parent === 'mobileMenu') {
                  return 'sm:border-black text-sm sm:text-black md:text-black';
                }
                return 'text-xs md:text-xxs md:border-transparent sm:border-transparent';
              };
              const ButtonLink = ({ border, localization, icon, text, key }) => (
                <span className={`${mainParent} font-americaMonoRegular uppercase text-${textColor} tracking-submenu px-4 sm:px-3 md:px-3 sm:my-1 md:my-1 flex justify-center items-center ${stylesButton(mainParent)} ${border} ${key === 'tickets' ? 'hidden md:block sm:block' : ''}`}>
                  {icon && <span className={`${icon} text-base mr-2 sm:text-xl md:text-xl`} />}
                  {text && <span className={` ${name === 'loginMenu' ? 'sm:hidden md:hidden align-text-top mb-px' : ''}`}>{translateObj(text, localization.language)}</span>}
                </span>
              );
              ButtonLink.propTypes = {
                border: PropTypes.string.isRequired,
                localization: PropTypes.shape().isRequired,
                icon: PropTypes.string.isRequired,
                text: PropTypes.string.isRequired,
                key: PropTypes.string.isRequired,
              };
              return (
                <>
                  <div id={name} className={`sm:w-full items-center ml-12 sm:ml-0 md:ml-0 ${stylesContainer(mainParent)}`}>
                    {params.map((link, i) => {
                      // check what element is rendered, the last one musn't have border
                      let border = 'border-r';
                      // eslint-disable-next-line no-unused-expressions
                      i === params.length - 1 ? (border = '') : (border = `border-r border-${textColor}`);

                      if (link.key === 'login') {
                        // First we will check we have a correct login at local storage
                        const fullName = userName ? `${userName || ''} ${userLastName || ''}` : null;

                        const currentUserNameProfile = fullName && fullName.length ? {
                          en: `${fullName.length < 20 ? fullName : `${fullName.substring(0, 20)}...`}`,
                        } : {
                          en: 'LOG IN',
                          es: 'INICIA SESIÓN',
                          ca: 'INICIA SESSIÓ',
                          pt: 'INICIAR SESSÃO',
                        };

                        link.link = userName ? `/user-profile${(link.sourceWeb && `-${link.sourceWeb}`) || ''}` : `/login-screen${(link.sourceWeb && `-${link.sourceWeb}`) || ''}?e=sign-in`;
                        link.text = currentUserNameProfile;

                        // if not will show current button for login
                        // if we have a login we will change icon, text, and link for a user-profile page.
                      }

                      return (
                        <React.Fragment key={link.key}>
                          {
                              link.key !== 'login'
                                ? (
                                  <div>
                                    {(!userName && link.key === 'favoritesMobile') ? null : (
                                      <Link to={`${link.link}`} localizationpath={`${localization.path}`} className={`no-underline ${link.key}  ${link.key === 'favoritesMobile' ? 'hidden sm:block md:block' : 'flex'}`}>
                                        <ButtonLink border={border} icon={link.icon} key={link.key} text={link.text} localization={localization} />
                                      </Link>
                                    ) }

                                  </div>
                                )
                                : (
                                  <UserMenuPopup border={border} ButtonLink={ButtonLink} link={link} localization={localization} userName={userName} userLastName={userLastName} token={token} language={localization.language} loadTokenForLS={loadTokenForLS} history={this.props.history} formText={link.popUpFormText} sourceWeb={link.sourceWeb} />
                                )
                            }


                        </React.Fragment>
                      );
                    })}
                  </div>
                  {isActiveSearch ? <SearchOverlay closeCallback={this.handleSearchOverlay} searchCallback={this.handleSearch} /> : null}
                </>
              );
            }}
          </RoutesContext.Consumer>
        )
        }
      </UserProfileContextConsumer>
    );
  }
}

TopHeaderSubmenuItemsContainer.propTypes = {
  name: PropTypes.string.isRequired,
  match: PropTypes.shape().isRequired,
  mainParent: PropTypes.string,
  params: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
  textColor: PropTypes.string,
};

TopHeaderSubmenuItemsContainer.defaultProps = {
  mainParent: null,
  textColor: 'white',
};

export default withRouter(TopHeaderSubmenuItemsContainer);
// export default withRouter(withStyles(styles)(TopHeaderSubmenuItemsContainer));
