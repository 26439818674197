import React, { useEffect, useState } from 'react';
import CircleItem from '@primaveralabs/ui-circle-item';
import getUserData from '../../../api/graphql/queries/getUserData';
import LoadIcon from '../LoadIcon';
import 'Primavera/ui-themes/dist/primavera/index.css';
import 'Primavera/ui-circle-item/dist/primavera.css';
import imageModifier from '../helpers/imageModifier';
import translateObj from '../../../util/translateObj';
import { useRoutes } from '../../../context/routesContext';
import getPostsBySlugNameQuery from '../../../api/graphql/queries/getPostsBySlugNameQuery';
import translation from './translations/common';

const UserFavouritesPage = () => {
  const { language } = useRoutes();
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({});
  const [favourites, setFavourites] = useState([]);

  const loadData = () => {
    setLoading(true);
    getPostsBySlugNameQuery(userData.favourites.favouriteArtists).then((res) => {
      if (res) {
        setFavourites(res);
      }
    }).catch((err) => console.log(err));
    setLoading(false);
  };

  const loadUserData = () => {
    setLoading(true);
    getUserData()
      .then((res) => {
        if (res) {
          setUserData(res);
        }
      })
      .catch((err) => console.log(err));
    setLoading(false);
  };

  useEffect(() => {
    loadUserData();
  }, []);

  useEffect(() => {
    if (userData?.favourites?.favouriteArtists) {
      loadData();
    }
  }, [userData]);

  return (
    <div className="bg-panel-gray h-full">
      {loading ? (
        <div className="flex items-center justify-center h-full">
          <LoadIcon />
        </div>
      ) : (
        <div
          style={{ gap: 8 }}
          className="px-12 py-8 h-full sm:px-4 overflow-y-scroll pb-64 sm:pb-8"
        >
          {favourites?.length > 0 ? (
            <div className="flex flex-wrap" style={{ gap: 16 }}>
              {favourites?.map((item) => (
                <CircleItem
                  image={imageModifier(translateObj(item.postDescription?.image, language), '200x200')}
                  label={translateObj(item.postDescription?.title, language)}
                  link={item.postDescription?.url}
                />
              ))}
            </div>
          ) : (
            <div className="flex items-center justify-center text-white h-full font-americaRegular">
              {translateObj(translation.noFavourites)}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

UserFavouritesPage.propTypes = {};

export default UserFavouritesPage;
