/* eslint-disable object-curly-newline */
import React from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';

import { ToastProvider } from 'react-toast-notifications';

import ChannelToggler from './components/ChannelToggler';

import { ChatProvider } from './context/chatContext';
import { ThemeProvider } from './context/themeContext';
import { FirebaseAuthProvider } from './context/FirebaseAuthContext';

import { THEME, COLOR } from './utils/constants';


const Chat = ({ chatRules = null, moderators = [], chatChannelID = '', qaChannelID = '', shouldInitPrivateChat = true, streamContentHeight = null, device = '', match, zones = [] }) => {
  const { params: { section, post } } = match;
  return (
    <FirebaseAuthProvider moderators={moderators} chatChannelID={chatChannelID} channels={[chatChannelID]} postSlugName={post || section}>
      <ThemeProvider {...{ theme: THEME, color: COLOR }}>
        <ChatProvider {...{ chatChannelID, qaChannelID, device, zones }}>
          <ToastProvider placement="bottom-right">
            <ChannelToggler {...{ chatRules, streamContentHeight, device, shouldInitPrivateChat }} />
          </ToastProvider>
        </ChatProvider>
      </ThemeProvider>
    </FirebaseAuthProvider>
  );
};

export default withRouter(Chat);
