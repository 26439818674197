import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';
import translateObj from '../../util/translateObj';
import Link from '../../util/link';

const FullLink = ({
  linkTo, text, icon, textColor, textSize, newWindow, hasPaddingLeft, paddingY, match, className,
}) => {
  const iconSize = parseInt(textSize) - 2;
  const BlankLink = ({ localization }) => (
    <div className={`xl:flex lg:flex w-full ${hasPaddingLeft && 'pr-4'} ${paddingY ? 'pt-12 pb-16 sm:py-8 md:py-8' : 'pt-4'} ${className}`}>
      {hasPaddingLeft && <div className="sm:hidden md:hidden xl:w-1/5 lg:w-1/5 invisible">X</div>}
      <a href={linkTo} target="_blank" rel="noopener noreferrer" className={`border-b-2 border-${textColor} cursor-pointer`} aria-label={translateObj(text, localization.language)}>
        {' '}
        <span className={`font-icon ${icon} text-${iconSize}  text-${textColor}`} />
        {' '}
        <span className={`font-americaMonoBold text-${textSize} text-${textColor} leading-normal uppercase`}>{translateObj(text, localization.language)}</span>
      </a>
    </div>
  );
  const InnerLink = ({ localization }) => (
    <div className={`xl:flex lg:flex w-full ${hasPaddingLeft && 'pr-4'} ${paddingY ? 'pt-12 pb-16 sm:py-8 md:py-8' : 'pt-4'} ${className}`}>
      {hasPaddingLeft && <div className="sm:hidden md:hidden xl:w-1/5 lg:w-1/5 invisible">X</div>}
      <Link to={linkTo} localizationpath={localization.path} className={`border-b-2 border-${textColor} cursor-pointer`} aria-label={translateObj(text, localization.language)}>
        {' '}
        <span className={`font-icon ${icon} text-${iconSize}  text-${textColor}`} />
        {' '}
        <span className={`font-americaMonoBold text-${textSize} text-${textColor} leading-normal uppercase`}>{translateObj(text, localization.language)}</span>
      </Link>
    </div>
  );
  return (
    <RoutesContext.Consumer>
      {({ language: contextLanguage, enteredFrom }) => {
        const localization = getLocalization(contextLanguage, enteredFrom, match);
        return /^https?:\/\//.test(linkTo) ? <BlankLink localization={localization} /> : <InnerLink localization={localization} />;
      }}
    </RoutesContext.Consumer>
  );
};

FullLink.propTypes = {
  textColor: PropTypes.string,
  textSize: PropTypes.string,
  text: PropTypes.shape({}).isRequired,
  linkTo: PropTypes.string.isRequired,
  icon: PropTypes.string,
  newWindow: PropTypes.bool,
  hasPaddingLeft: PropTypes.bool,
  paddingY: PropTypes.bool,
  className: PropTypes.string,
  match: PropTypes.shape().isRequired,
};

FullLink.defaultProps = {
  textColor: 'black',
  icon: 'icon-arrow-right',
  textSize: '14',
  newWindow: true,
  hasPaddingLeft: false,
  paddingY: false,
  className: '',
};

export default withRouter(FullLink);
