import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useRoutes } from '../../context/routesContext';

const ADDEVENTSCRIPT = 'https://addevent.com/libs/atc/1.6.1/atc.min.js';

const copies = {
  en: 'Add event to calendar',
  es: 'Añadir evento al calendario',
  ca: 'Afegir event al calendari',
};

export const useScript = (url) => {
  useEffect(() => {
    if (typeof window === 'undefined') return null;

    const tag = document.createElement('script');
    tag.src = url;
    tag.async = true;
    tag.type = 'text/javascript';

    document.body.appendChild(tag);

    return () => {
      document.body.removeChild(tag);
    };
  }, [url]);
};

const props = {
  className: 'py-4 px-8 rounded-full bg-black uppercase font-white font-americaMonoBold text-base sm:text-12 md:text-12 text-center text-white hover:bg-psworld-zone-red trans',
  target: '_blank',
  rel: 'noopener noreferrer nofollow',
};

const AddEvent = ({ addeventId, match }) => {
  const { language } = useRoutes(match);
  useScript(ADDEVENTSCRIPT);

  const copy = copies[language];

  return (
    <a title={copy} data-id={addeventId} href={`https://www.addevent.com/event/${addeventId}`} {...{ ...props }}>
      <span>{copy}</span>
    </a>
  );
};
AddEvent.propTypes = {
  addeventId: PropTypes.string.isRequired,
  match: PropTypes.shape().isRequired,
};

export default withRouter(AddEvent);
