import React from 'react';
import PropTypes from 'prop-types';

const FooterBottom = ({ children, style }) => (
  <div className={`pr-28 bg-black sm:pr-0 md:pr-0 relative md:pr-5 ${style}`}>
    { children }
  </div>
);

FooterBottom.propTypes = {
  children: PropTypes.shape().isRequired,
  style: PropTypes.string,
};

FooterBottom.defaultProps = {
  style: '',
};

export default FooterBottom;
