import React from 'react';
import PropTypes from 'prop-types';


const HeadTitle = ({ title, className }) => (
  <div className={`${className || 'w-full py-10'}`}>
    <div className="font-americaBlack text-50 sm:text-24">{title}</div>
  </div>
);

HeadTitle.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
};

HeadTitle.defaultProps = {
  title: '',
  className: '',
};

export default HeadTitle;
