/* Will use it for compatibility issues with older versions only */
const addIfMissingComponent = (children, componentName = 'BodyComponent') => {
  const newChildren = { ...children };
  const { props, props: { components } } = children;
  if (components && components.length) {
    const hasHeaderComponent = Object.values(components[0]).includes(componentName);
    if (!hasHeaderComponent) {
      newChildren.props = { ...props, components: [{ type: componentName, components }] };
      return newChildren;
    }
  }
  return children;
};

export default addIfMissingComponent;
