/* eslint-disable object-curly-newline */
import React, { useState, useEffect, useRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import stickybits from 'stickybits';
import { useRoutes } from '../../../context/routesContext';
import translateObj from '../../../util/translateObj';

import TableListTable from './TableListTable';
import Toolbar from './TableListToolbar';
import LoadIcon from '../LoadIcon';

const TableList = ({
  columnTitles,
  rowsData,
  hasSearch,
  hasSort,
  match,
}) => {
  const el = useRef(null);
  const { language = 'en' } = useRoutes(match);
  const columns = translateObj(columnTitles, language).split(',');

  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [total, setTotal] = useState(0);

  // Filtering items:
  const [global, setGlobal] = useState('');

  // Sorting items:
  const [sorting, setSorting] = useState(['0', 1]);

  // Pagination
  const [page, setPage] = useState(0);
  const [runStickybits, setRunStickybits] = useState(false);
  const [previousPage, setPreviousPage] = useState(0);
  const [avoidEffect, setAvoidEffect] = useState(false);
  const rowsPerPage = 50;

  const translate = {
    search: {
      ca: 'Cercar',
      en: 'Search',
      es: 'Buscar',
    },
  };

  useEffect(() => {
    if (el.current !== null && hasSearch) setRunStickybits(true);
  }, [el.current]);

  useEffect(() => {
    stickybits('.stickyWrapper');
    return () => {
      const stickybitsInstancetoBeCleanedup = stickybits('.stickyWrapper');
      if (stickybitsInstancetoBeCleanedup) {
        stickybitsInstancetoBeCleanedup.cleanup();
      }
    };
  }, [runStickybits]);

  useEffect(() => {
    if (avoidEffect) {
      return;
    }
    let from = page * rowsPerPage;
    let to = (page + 1) * rowsPerPage;
    if (previousPage === page) {
      setAvoidEffect(true);
      setPage(0);
      from = 0;
      to = rowsPerPage;
    } else {
      from = page * rowsPerPage;
      to = (page + 1) * rowsPerPage;
      setPreviousPage(page);
    }
    const searchRegexp = new RegExp(global, 'i');

    const dataArray = (rowsData || '')
      .split('\n')
      .map(row => row.split(',').map(value => value.trim()))
      .filter(row => !global || global.length === 0 || row.some(field => searchRegexp.test(field)));

    const dataArraySorted = dataArray.sort((a, b) => (`${a[sorting[0]]}`).localeCompare(b[sorting[0]]) * sorting[1]);

    // setTotal(dataObject.total);
    setFilteredData(dataArraySorted);
    setIsLoading(false);

    setAvoidEffect(false);
  }, [
    rowsData,
    page,
    rowsPerPage,
    sorting,
    global,
  ]);

  return (
    <Fragment>
      <div className="w-full bg-white" id="line-up-container">
        {/* <div className="flex justify-between mb-4">
          <span className="font-americaMonoBold text-14 uppercase">
            Total:
            {total}
          </span>
        </div> */}
        <div className="mt-4">
          {!isLoading ? (
            <>
              {hasSearch && (
                <div ref={el} className="stickyWrapper">
                  <Toolbar
                    setGlobal={setGlobal}
                    searchLabel={translateObj(translate.search, language)}
                  />
                </div>
              )}
              <TableListTable
                tableData={filteredData}
                columns={columns}
                total={total}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                sorting={sorting}
                setSorting={setSorting}
                hasSort={hasSort}
                setGlobal={setGlobal}
              />
            </>
          ) : <LoadIcon />}
        </div>
      </div>
    </Fragment>
  );
};

TableList.propTypes = {
  columnTitles: PropTypes.shape().isRequired,
  rowsData: PropTypes.string.isRequired,
  hasSearch: PropTypes.bool,
  hasSort: PropTypes.bool,
  match: PropTypes.shape().isRequired,
};

TableList.defaultProps = {
  hasSearch: false,
  hasSort: false,
};

export default withRouter(TableList);
