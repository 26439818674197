import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import Navigator from 'ComponentsPath/Navigator';
import getNavigatorSection from '../util/getNavigatorSection';

class Paywall extends Component {
  state = {
    bannerHeight: 0,
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.addEventListener('verificationBannerRenderEvent', this.handleVerificationBanner);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('verificationBannerRenderEvent', this.handleVerificationBanner);
  }

  handleVerificationBanner = (event) => {
    if (event.detail.opened) {
      this.setState({ bannerHeight: event.detail.height });
    } else this.setState({ bannerHeight: 0 });
  }

  render() {
    const { children, match } = this.props;
    const { components } = children.props;
    const { bannerHeight } = this.state;
    return (
      <div
        id="body"
        className="w-full flex flex-col items-center relative min-h-screen-70 bg-white"
        style={{ paddingTop: `${bannerHeight}px` }}
      >
        {children}
        {components.length > 0 && components[0].type && components[0].type === 'Carousel' && getNavigatorSection(match.params) && <Navigator />}
      </div>
    );
  }
}

Paywall.propTypes = {
  children: PropTypes.shape().isRequired,
  match: PropTypes.shape().isRequired,
};


export default withRouter(Paywall);
