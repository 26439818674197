const getNavigatorSection = (params) => {
  if (!params.section) { return false; }

  // if (!params.post) {
  //   switch (params.section) {
  //     case 'about':
  //       return true;
  //     default:
  //       return false;
  //   }
  // }
  return true;
};

export default getNavigatorSection;
