import React from 'react';
import { PropTypes } from 'prop-types';

import { withRouter } from 'react-router-dom';
import moment from 'moment';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import getLocalization from '../../../util/localization';
import RoutesContext from '../../../context/routesContext';

const translation = {
  en: 'on',
  es: 'en',
  ca: 'al',
  pt: 'en',
};
class RadioTodayGridHeader extends React.Component {
  state = {
    selectedDate: moment(),
    day: moment().date(),
    month: moment().month() + 1,
    year: moment().year(),
    maxDays: moment().daysInMonth(),
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps) {
    const { match: { params }, handleNewDate } = this.props;
    if (params.channel !== prevProps.match.params.channel) {
      this.setState({
        selectedDate: moment(),
        day: moment().date(),
        month: moment().month() + 1,
        year: moment().year(),
        maxDays: moment().daysInMonth(),
      });
    }
  }

  handleChange = (e, name) => {
    const { value } = e.target;
    if (value > 0) {
      this.setState({ [name]: value }, () => {
        const { year, month, day } = this.state;
        this.setState({
          maxDays: moment(`${year}-${month}-01`, 'YYYY-MM-DD').daysInMonth(),
          selectedDate: moment(`${year}-${month}-${day}`, 'YYYY-MM-DD'),
        }, () => this.props.handleNewDate(this.state.selectedDate));
      });
    }
  }

  handleNextPreviousDay = (num) => {
    const { selectedDate } = this.state;
    const { handleNewDate } = this.props;
    const yesterDay = moment(selectedDate).add(num, 'days');
    this.setState({
      selectedDate: yesterDay,
    });
    handleNewDate(yesterDay);
  }


  renderDays = () => {
    const options = [];
    const { maxDays } = this.state;
    for (let i = 1; i <= maxDays; i++) {
      options.push(<MenuItem key={i} value={i}>{i}</MenuItem>);
    }
    return options;
  }

  static renderMonths() {
    const options = [];
    for (let i = 1; i <= 12; i++) {
      options.push(<MenuItem key={i} value={i}>{i}</MenuItem>);
    }
    return options;
  }

  renderYears = () => {
    const options = [];
    for (let i = moment().year(); i >= 2000; i--) {
      options.push(<MenuItem key={i} value={i}>{i}</MenuItem>);
    }
    return options;
  }

  render() {
    const {
      topTitle, title, today, filterOn, text1, text2, text3, dayBeforeText, dayAfterText, dateFormat, match, channel, changeChannel,
    } = this.props;
    const {
      selectedDate, day, month, year,
    } = this.state;

    const InfoRow = ({ localization }) => (
      <div className="flex font-americaMonoRegular bg-grayE2DDDB uppercase py-3 sm:hidden w-full text-black">
        <div className="w-1/6" />
        <div className="w-5/6 flex">
          <div className="w-1/5 text-xs">{text1[localization.language]}</div>
          <div className="w-3/5 text-xs">{text2[localization.language]}</div>
          <div className="w-1/5 text-xs">{text3[localization.language]}</div>
        </div>
      </div>
    );
    return (
      <RoutesContext.Consumer>
        {
        ({ language: contextLanguage, enteredFrom }) => {
          const localization = getLocalization(contextLanguage, enteredFrom, match);

          return (
            <>
              <div className="w-full text-white font-americaMonoRegular bg-black p-8">
                <div className="flex align-center border-dotted border-white border-b-1 pb-8">
                  <div className="flex justify-start">
                    <span className="text-xs flex items-center uppercase cursor-pointer" onClick={() => this.handleNextPreviousDay(-1)}>
                      <span className="font-icon icon-arrow-left mr-2" />
                      {dayBeforeText[localization.language]}
                    </span>
                  </div>
                  <div className="flex-1 text-sm text-center font-americaMonoBold uppercase">
                    {selectedDate.isSame(moment(), 'day') ? today[localization.language] : moment(selectedDate).format(dateFormat[localization.language]) }
                    {' '}
                    {`${translation[localization.language]} ${topTitle}`}
                  </div>
                  <div className="flex justify-end">
                    <span className="text-xs flex items-center uppercase cursor-pointer" onClick={() => this.handleNextPreviousDay(1)}>
                      {dayAfterText[localization.language]}
                      <span className="font-icon icon-arrow-right ml-2" />
                    </span>
                  </div>
                </div>
                <div className="items-center pt-8 flex sm:block md:block">
                  <div className="uppercase mr-4 sm:mr-0 sm:mb-4 md:mb-4 text-xs">
                    {filterOn[localization.language]}
                  </div>
                  <div className="flex flex-wrap">
                    <div className="mr-4 sm:mr-0 sm:w-full sm:mb-2 w-24">
                      <FormControl>
                        <Select
                          value={day}
                          onChange={e => this.handleChange(e, 'day')}
                          IconComponent={() => <span className="font-icon icon-arrow-down self-center mr-2 absolute pin-r" />}
                        >
                          {this.renderDays()}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="mr-4 sm:mr-0 sm:w-full sm:mb-2 w-24">
                      <FormControl>
                        <Select
                          value={month}
                          onChange={e => this.handleChange(e, 'month')}
                          IconComponent={() => <span className="font-icon icon-arrow-down self-center mr-2 absolute pin-r" />}
                        >
                          {RadioTodayGridHeader.renderMonths()}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="sm:w-full sm:mb-2 w-24">
                      <FormControl>
                        <Select
                          value={year}
                          onChange={e => this.handleChange(e, 'year')}
                          IconComponent={() => <span className="font-icon icon-arrow-down self-center mr-2 absolute pin-r" />}
                        >
                          {this.renderYears()}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </div>
              </div>
              <InfoRow text1={text1} text2={text2} text3={text3} localization={localization} />
            </>
          );
        }
      }
      </RoutesContext.Consumer>
    );
  }
}

RadioTodayGridHeader.propTypes = {
  match: PropTypes.shape().isRequired,
  title: PropTypes.shape().isRequired,
  text1: PropTypes.shape().isRequired,
  text2: PropTypes.shape().isRequired,
  dayBeforeText: PropTypes.shape().isRequired,
  dayAfterText: PropTypes.shape().isRequired,
  dateFormat: PropTypes.shape().isRequired,
  handleNewDate: PropTypes.func.isRequired,
};

export default withRouter(RadioTodayGridHeader);
