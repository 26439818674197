export const buttonClasses = ({ backgroundColor = 'black', textColor = 'white' }) => (
  `rounded-full bg-inherit bg-${backgroundColor} text-${textColor} text-12 font-americaMonoBold sm:static sm:mx-0 sm:w-full cursor-pointer text-center px-12 lg:px-16 xl:px-16 py-3.5 flex items-center my-1 justify-center md:px-8 sm:h-10 sm:mt-2 w-full uppercase`
);

export const buttonClasses2 = ({ backgroundColor = 'black', textColor = 'white', textSize = '12', width = 'auto', height = 'auto' }) => (
  `w-${width} h-${height} rounded-full bg-inherit bg-${backgroundColor} text-${textColor} text-${textSize} font-stratos cursor-pointer text-center flex items-center justify-center uppercase`
);

export const getButtonClasses = (type) => {
  switch (type) {
    case 'modal': return buttonClasses({ backgroundColor: 'google' });
    default: return 'rounded-full bg-inherit bg-google text-white text-12 font-americaMonoBold sm:static sm:mx-0 my-1 sm:w-full w-74 cursor-pointer text-center px-12 lg:px-16 xl:px-16 py-2 flex items-center justify-center md:px-16 h-10 sm:mt-2 mr-6';
  }
};
