import { useEffect, useReducer } from 'react';

function debounce(func, wait, immediate) {
  let timeout;
  return () => {
    const context = this;
    // eslint-disable-next-line prefer-rest-params
    const args = arguments;
    const later = () => {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

function _isTouch() {
  const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
  const mq = query => window.matchMedia(query).matches;

  // eslint-disable-next-line no-undef, no-mixed-operators
  if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
    return true;
  }

  // include the 'heartz' as a way to have a non matching MQ to help terminate the join
  // https://git.io/vznFH
  const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
  return mq(query);
}

const getDeviceSize = (width) => {
  if (width >= 1800) return 'xxl';
  if (width >= 1200) return 'xl';
  if (width >= 992) return 'lg';
  if (width >= 640) return 'md';
  return 'sm';
};

const initialState = {
  deviceWidth: 0,
  deviceHeight: 0,
  deviceScreen: '',
  deviceOrientation: '',
  isMobile: false,
};

const reducer = (state, payload) => ({
  deviceWidth: payload.deviceWidth || state.deviceWidth,
  deviceHeight: payload.deviceHeight || state.deviceHeight,
  deviceScreen: payload.deviceScreen || state.deviceScreen,
  deviceOrientation: payload.deviceOrientation || state.deviceOrientation,
  isMobile: payload.isMobile,
});

const setDimensions = dispatch => values => dispatch(values);

// TODO: move this into the utils folder
const useResponsiveUtility = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const updateDimensions = () => {
    const deviceWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const deviceHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    const deviceOrientation = (window.orientation === -90 || window.orientation === 90) ? 'landscape' : 'portrait';
    const deviceScreen = getDeviceSize(deviceWidth);
    const isMobile = deviceScreen === 'sm';

    setDimensions(dispatch)({ deviceWidth, deviceHeight, deviceOrientation, deviceScreen, isMobile });
  };

  const debouncedUpdateDimensions = debounce(() => updateDimensions(), 50);

  const isTouch = () => _isTouch();

  useEffect(() => {
    window.addEventListener('orientationchange', debouncedUpdateDimensions);
    window.addEventListener('resize', debouncedUpdateDimensions);
    debouncedUpdateDimensions();

    return () => {
      window.removeEventListener('resize', debouncedUpdateDimensions);
      window.removeEventListener('orientationchange', debouncedUpdateDimensions);
    };
  }, []);

  return { ...state, isTouch };
};

export default useResponsiveUtility;
