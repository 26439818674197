import React from 'react';
import { PropTypes } from 'prop-types';

import { withRouter } from 'react-router-dom';
import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';
import ComponentIterator from '../../containers/ComponentIterator';
import imageModifier from './helpers/imageModifier';
import RadioItemType from './RadioItemType';

const RadioBox3 = ({
  match,
  category,
  title,
  text1,
  radioItemType,
  tags,
  imageUrl,
  url,
}) => {
  return (
    <RoutesContext.Consumer>
    {
      ({ language: contextLanguage, enteredFrom }) => {
        
        const localization = getLocalization(contextLanguage, enteredFrom, match);
        
        return (
          <div className="w-full bg-white rounded-lg">
            <div className="relative">
              <div className="relative launcherOverlayContainer">
                <img className="block" src={imageModifier(imageUrl, '330x300')} alt={title[localization.language]} />
              </div>
              <div className="p-4 pb-6">
                <h2 className="font-americaBlack text-xl mt-1 mb-4">
                  <a className="text-black" href={url[localization.language]}>{title[localization.language]}</a>
                </h2>
                <span className="font-tiemposRegular mb-4 block text-sm">{text1[localization.language]}</span>
                <div>
                  <ComponentIterator components={tags} />
                </div>
                {radioItemType ?
                <div className="flex justify-between mt-8 items-center">
                  <RadioItemType {...radioItemType} />
                </div> : null}
              </div>
            </div>            
          </div>
        )
      }
    }
    </RoutesContext.Consumer>
  )
}

RadioBox3.propTypes = {
  category: PropTypes.string.isRequired,
  title: PropTypes.shape().isRequired,
  text1: PropTypes.shape().isRequired,
  tags: PropTypes.array.isRequired,
  imageUrl: PropTypes.string.isRequired,
  url: PropTypes.shape().isRequired,
};

export default withRouter(RadioBox3);