import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment-timezone';

const SessionDateTitle = ({ tickets, isReserved }) => {
  const items = [];
  const reservedTicket = isReserved ? tickets.find(t => t.status === 'RESERVED') : null;

  tickets.forEach((ticket) => {
    const { dateTimeStartReal } = ticket;
    const timestamp = Number(dateTimeStartReal);
    const timezone = moment.tz.guess();

    const year = moment(timestamp).tz(timezone).format('YYYY');
    const month = moment(timestamp).tz(timezone).format('MMMM');
    const day = moment(timestamp).tz(timezone).format('DD');
    const alreadyFormatted = items.find(item => item.year === year && item.month === month);

    if (isReserved) {
      if (reservedTicket === ticket) {
        const hour = moment(timestamp).tz(timezone).format('HH.mm[H]');
        items.push({
          year,
          month,
          days: [day],
          hour,
        });
      }
    } else {
      if (alreadyFormatted) {
        if (!alreadyFormatted.days.includes(day)) {
          alreadyFormatted.days.push(day);
        }
      } else {
        items.push({
          year,
          month,
          days: [day],
        });
      }
    }
  });

  const formatItems = [];

  items.forEach((i) => {
    const daysString = i.days.map((d, index) => {
      if (index === i.days.length - 2) {
        if (d[0] === '0') {
          return `${d[1]} &`;
        } return `${d} & `;
      }
      if (d[0] === '0') {
        return `${d[1]},`;
      } return `${d},`;
    }).join(' ');
    if (isReserved && reservedTicket) {
      formatItems.push(`${i.month} ${daysString} ${i.year} | ${i.hour}`);
    } else {
      formatItems.push(`${i.month} ${daysString} ${i.year}`);
    }
  });

  if (formatItems.length > 1) {
    return (
      <>
        {formatItems.map((i, index) => {
          if (index === 0) {
            return <span>{i}</span>;
          } return (
            <>
              <span className="px-3">|</span>
              <span>{i}</span>
            </>
          );
        })}
      </>
    );
  } return <span>{formatItems[0]}</span>;
};

SessionDateTitle.propTypes = {
  tickets: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isReserved: PropTypes.bool,
};

SessionDateTitle.defaultProps = {
  isReserved: false,
};

export default SessionDateTitle;
