import React from 'react';
import PropTypes from 'prop-types';

const DonationButton = () => (
  <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_blank">
    <input type="hidden" name="cmd" value="_s-xclick" />
    <input type="hidden" name="hosted_button_id" value="UJFRBN7F5YD78" />
    <input type="image" src="https://assets.primaverasound.com/ps/images/donate_button_en.png" height="45" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
    <img alt="Donate with PayPal button" border="0" src="https://www.paypal.com/en_ES/i/scr/pixel.gif" width="1" height="1" />
  </form>
);

DonationButton.propTypes = {
};

DonationButton.defaultProps = {
};

export default DonationButton;
