import translateObj from '../../../util/translateObj';

export const literals = {
  loginTitle: {
    es: 'Iniciar sesión',
    en: 'Log in',
    ca: 'Inicia sessió',
    pt: 'Iniciar sessão',
  },
  loginSubtitle: {
    es: '¿No tienes cuenta?',
    en: 'Don\'t you have an account?',
    ca: 'No tens compte?',
    pt: 'Você não tem uma conta?',
  },
  loginButtonText: {
    es: 'Acceder',
    en: 'Access',
    ca: 'Accedir',
    pt: 'Acessar',
  },
  register: {
    es: 'Regístrate ahora',
    en: 'Register now',
    ca: 'Registra\'t ara',
    pt: 'Registrar agora',
  },
  or: {
    es: 'o',
    en: 'or',
    ca: 'o',
    pt: 'ou',
  },
  email: {
    es: 'Correo electrónico',
    en: 'Email',
    ca: 'Correu electrònic',
    pt: 'Correio eletrônico',
  },
  emailPlaceholder: {
    es: 'Correo electrónico',
    en: 'Email',
    ca: 'Correu electrònic',
    pt: 'Correio eletrônico',
  },
  password: {
    es: 'Contraseña',
    en: 'Password',
    ca: 'Contrasenya',
    pt: 'Senha',
  },
  passwordPlaceholder: {
    es: 'Contraseña',
    en: 'Password',
    ca: 'Contrasenya',
    pt: 'Senha',
  },
  forgotPassword: {
    es: '¿Has olvidado tu contraseña?',
    en: 'Have you forgotten your password?',
    ca: 'Has oblidat la teva contrasenya?',
    pt: 'Esqueceu sua senha?',
  },
  signupPretitle: {
    es: 'Paso 1 de 3',
    en: 'Step 1 of 3',
    ca: 'Pas 1 de 3',
    pt: 'Passo 1 de 3',
  },
  signupTitle: {
    es: 'Registrarme',
    en: 'Register',
    ca: 'Registrar-me',
    pt: 'Registre-me',
  },
  signupSubtitle: {
    es: 'Rellena el siguiente formulario con tus datos personales para poder crear tu cuenta.',
    en: 'Fill in the following form with your personal data to create your account.',
    ca: 'Omple el següent formulari amb les teves dades personals per poder crear el teu compte.',
    pt: 'Preencha o seguinte formulário com os seus dados pessoais para criar a sua conta.',
  },
  signupButtonText: {
    es: 'Siguiente',
    en: 'Next',
    ca: 'Següent',
    pt: 'Seguindo',
  },
  haveAccount: {
    es: '¿Ya tienes una cuenta?',
    en: 'Do you already have an account?',
    ca: 'Ja tens un compte?',
    pt: 'Você já tem uma conta?',
  },
  login: {
    es: 'Inicia sesión',
    en: 'Log in',
    ca: 'Inicia sessió',
    pt: 'Conecte-se',
  },
  repeatEmail: {
    es: 'Repetir correo electrónico',
    en: 'Repeat email',
    ca: 'Repetir correu electrònic',
    pt: 'Repetir o e-mail',
  },
  repeatEmailPlaceholder: {
    es: 'Vuelve a escribir tu correo electrónico',
    en: 'Repeat your email',
    ca: 'Torna a escriture el teu correu elecctrònic',
    pt: 'Redigite seu e-mail',
  },
  createPassword: {
    es: 'Crea una contraseña',
    en: 'Create a password',
    ca: 'Crea una contrasenya',
    pt: 'Crie uma senha',
  },
  createPasswordPlaceholder: {
    es: 'Escribe aquí tu contraseña',
    en: 'Create your password here',
    ca: 'Escriu aquí la teva contrasenya',
    pt: 'Escreva sua senha aqui',
  },
  socialTitle: {
    es: 'Si lo prefieres, también puedes registrarte con tus redes sociales',
    en: 'If you prefer, you can also register with your social media accounts',
    ca: 'Si ho prefereixes, també et pots registrar amb les teves xarxes socials.',
    pt: 'Se preferir, você também pode se registrar com suas redes sociais',
  },
  registerWith: {
    es: 'Registrarme con',
    en: 'Register with',
    ca: 'Registrar-me amb',
    pt: 'Registre-se com',
  },
  name: {
    es: 'Nombre',
    en: 'Name',
    ca: 'Nom',
    pt: 'Nome',
  },
  namePlaceholder: {
    es: 'Nombre',
    en: 'Name',
    ca: 'Nom',
    pt: 'Nome',
  },
  lastname: {
    es: 'Apellido',
    en: 'Last name',
    ca: 'Cognom',
    pt: 'Sobrenome',
  },
  lastnamePlaceholder: {
    es: 'Apellido',
    en: 'Last name',
    ca: 'Cognom',
    pt: 'Sobrenome',
  },
  country: {
    es: 'País',
    en: 'Country',
    ca: 'País',
    pt: 'País',
  },
  countryPlaceholder: {
    es: 'Selecciona tu país',
    en: 'Choose your country',
    ca: 'Selecciona el teu país',
    pt: 'Escolha o seu país',
  },
  postalCode: {
    es: 'Código postal',
    en: 'Postal code',
    ca: 'Codi postal',
    pt: 'Código postal',
  },
  postalCodePlaceholder: {
    es: 'Código postal',
    en: 'Postal code',
    ca: 'Codi postal',
    pt: 'Código postal',
  },
  phone: {
    es: 'Teléfono',
    en: 'Phone',
    ca: 'Telèfon',
    pt: 'Telefone',
  },
  phonePlaceholder: {
    es: 'Ej.: 000 000 000',
    en: 'Ex.: 000 000 000',
    ca: 'Ex.: 000 000 000',
    pt: 'Ex.: 000 000 000',
  },
  phoneCodePlaceholder: {
    es: '+34',
    en: '+34',
    ca: '+34',
    pt: '+34',
  },
  birthdate: {
    es: 'Fecha de nacimiento',
    en: 'Birthdate',
    ca: 'Data de naixement',
    pt: 'Data de nascimento',
  },
  dayPlaceholder: {
    es: 'DD',
    en: 'DD',
    ca: 'DD',
    pt: 'DD',
  },
  monthPlaceholder: {
    es: 'MM',
    en: 'MM',
    ca: 'MM',
    pt: 'MM',
  },
  yearPlaceholder: {
    es: 'AAAA',
    en: 'YYYY',
    ca: 'AAAA',
    pt: 'AAAA',
  },
  preferencesPretitle: {
    es: 'Paso 2 de 3',
    en: 'Step 2 of 3',
    ca: 'Pas 2 de 3',
    pt: 'Passo 2 de 3',
  },
  preferencesTitle: {
    es: 'Personaliza tu perfil',
    en: 'Customize your profile',
    ca: 'Personalitza el teu perfil',
    pt: 'Personalize seu perfil',
  },
  preferencesSubtitle: {
    es: 'Selecciona tus intereses y mejora tu experiencia en Primavera Sound.',
    en: 'Select your interests and improve your experience at Primavera Sound.',
    ca: 'Selecciona els teus interessos i millora la teva experiència a Primavera Sound.',
    pt: 'Selecione seus interesses e melhore sua experiência no Primavera Sound.',
  },
  preferencesButtonText: {
    es: 'Siguiente',
    en: 'Next',
    ca: 'Següent',
    pt: 'Seguindo',
  },
  festivalsPretitle: {
    es: 'Paso 3 de 3',
    en: 'Step 3 of 3',
    ca: 'Pas 3 de 3',
    pt: 'Passo 3 de 3',
  },
  festivalsTitle: {
    es: '¡Ya casi estamos!',
    en: 'We\'re almost done!',
    ca: 'Ja gairebé estem!',
    pt: 'Estamos quase terminando!',
  },
  festivalsSubtitle: {
    es: 'Para finalizar tu registro, selecciona la edición o las ediciones de Primavera Sound que más te interesan y acepta las condiciones de uso.',
    en: 'To finish the register, select the Primavera Sound edition or editions that you\'re most interested in and accept the terms of use.',
    ca: 'Per a finalitzar el registre, selecciona l\'edició o les edicions de Primavera Sound que més t\'interessen i accepta les condicions d\'ús.',
    pt: 'Para finalizar o cadastro, selecione a edição ou edições do Primavera Sound de seu maior interesse e aceite os termos de uso.',
  },
  termsTip: {
    es: 'Importante: selecciona todo para estar bien informadx sobre Primavera Sound.',
    en: 'Important: select everything to be well informed about Primavera Sound.',
    ca: 'Important: selecciona-ho tot per a estar ben informat sobre Primavera Sound.',
    pt: 'Importante: selecione tudo para ficar bem informado sobre o Primavera Sound.',
  },
  festivalsButtonText: {
    es: 'Finalizar',
    en: 'Finish',
    ca: 'Finalitza',
    pt: 'Terminar',
  },
  completePretitle: {
    es: 'Registro completado',
    en: 'Registration completed',
    ca: 'Registre completat',
    pt: 'Registro concluído',
  },
  completeTitle: {
    es: '{username}, no olvides verificar tu cuenta',
    en: '{username}, don\'t forget to verify your account',
    ca: '{username}, no oblidis verificar el teu compte',
    pt: '{username}, não se esqueça de verificar sua conta',
  },
  completeSubtitle: {
    es: 'Para poder disfrutar de todas las ventajas de tu cuenta necesitamos verificar tu identidad. Podrás hacerlo a través del enlace que te hemos enviado a {userEmail}.',
    en: 'In order for you to enjoy all the benefits of your account we need to verify your identity. You can do it on the link that we have sent to {userEmail}.',
    ca: 'Per a poder gaudir de tots els avantatges del teu compte necessitem verificar la teva identitat. Podràs fer-ho a l\'enllaç que t\'hem enviat a {userEmail}.',
    pt: 'Para que você aproveite todos os benefícios de sua conta, precisamos verificar sua identidade. Você pode fazê-lo no link que enviamos para {userEmail}.',
  },
  completeCtaText: {
    es: '¿No has recibido ningún email?',
    en: 'Haven\'t you received any email?',
    ca: 'No has rebut cap correu electrònic?',
    pt: 'Você não recebeu nenhum e-mail?',
  },
  completeButtonText: {
    es: 'Acceder',
    en: 'Access',
    ca: 'Accedir',
    pt: 'Acesso',
  },
  choose_favourite_artists: {
    es: 'Elige 3 artistas que te gusten',
    en: 'Choose 3 artists that you like',
    ca: 'Escull 3 artistes que t\'agradin',
    pt: 'Escolha 3 artistas que você gosta',
  },
  search_placeholder: {
    es: 'Search artist or group...',
    en: 'Buscar artista o grupo...',
    ca: 'Buscar artista o grup...',
    pt: 'Pesquisar artista ou grupo...',
  },
  choose_musical_interests: {
    es: 'Tus intereses musicales',
    en: 'Your musical interests',
    ca: 'Els teus interessos musicals',
    pt: 'Seus interesses musicais',
  },
  choose_festival: {
    es: '¿Qué edición de Primavera Sound te interesa?',
    en: 'Which Primavera Sound edition are you interested in?',
    ca: 'Quina edició de Primavera Sound t\'interessa?',
    pt: 'Em qual edição do Primavera Sound você está interessado?',
  },
  permissions: {
    es: 'Comunicaciones y permisos',
    en: 'Communications and permissions',
    ca: 'Comunicacions i permisos',
    pt: 'Comunicações e permissões',
  },
  newsletter: {
    es: 'Me gustaría recibir de Primavera Sound información, elaborada a partir de mis intereses, con noticias relativas a la organización de sus festivales y productos o servicios relacionados.',
    en: 'I would like to receive from Primavera Sound elaborated information based on my interests, news related to the organization of its festivals and products or services.',
    ca: 'M\'agradaria rebre de Primavera Sound informació elaborada a partir dels meus interessos, notícies relatives a l\'organització dels seus festivals i productes o serveis relacionats.',
    pt: 'Desejo receber do Primavera Sound informações elaboradas de acordo com meus interesses, novidades relacionadas à organização de seus festivais e produtos ou serviços.',
  },
  readAndAccept: {
    es: 'He leído y acepto la ',
    en: 'I have read and I accept the ',
    ca: 'He llegit i accepto la ',
    pt: 'Li e aceito a ',
  },
  privacyPolicy: {
    es: 'Política de Privacidad.',
    en: 'Privacy Policy.',
    ca: 'Política de Privacitat.',
    pt: 'Política de Privacidade.',
  },
  read: {
    es: 'He leído las ',
    en: 'I have read the ',
    ca: 'He llegit les ',
    pt: 'Li as ',
  },
  useConditions: {
    es: 'Condiciones de Uso',
    en: 'Terms of Use',
    ca: 'Condicions d\'Ús',
    pt: 'Condições de Utilização',
  },
  termsAndConditions: {
    es: 'Términos y Condiciones',
    en: 'Terms and Conditions',
    ca: 'Termes i Condicions',
    pt: 'Termos e Condições',
  },
  andAccept: {
    es: ' y acepto los ',
    en: ' and I accept ',
    ca: ' i accepto els ',
    pt: ' e aceito os ',
  },
  fromPrimavera: {
    es: ' de Primavera Sound.',
    en: 'Primavera Sound\'s ',
    ca: ' de Primavera Sound.',
    pt: ' do Primavera Sound.',
  },
};

export const errorLiterals = {
  required_field: {
    es: 'Este campo es obligatorio',
    en: 'This field is required',
    ca: 'Aquest camp és obligatori',
    pt: 'Este campo é obrigatório',
  },
  invalid_email: {
    es: 'Correo electrónico no válido',
    en: 'Invalid email',
    ca: 'Correu electrònic no vàlid',
    pt: 'E-mail inválido',
  },
  password_length: {
    es: 'La contraseña debe tener un mínimo de 6 caracteres',
    en: 'Password must have a minimum of 6 characters',
    ca: 'La contrasenya ha de tenir un mínim de 6 caràcters',
    pt: 'A senha deve ter no mínimo 6 caracteres',
  },
  email_do_not_match: {
    es: 'El correo electrónico no coincide',
    en: 'Email does not match',
    ca: 'El correu electrònic no coincideix',
    pt: 'O e-mail não corresponde',
  },
  email_already_in_use: {
    es: 'Este correo electrónico ya está en uso',
    en: 'This email is already in use',
    ca: 'Aquest correu electrònic ja està en ús',
    pt: 'Esse email já está em uso',
  },
  user_not_found_or_wrong_password: {
    es: 'Usuario no encontrado o contraseña incorrecta',
    en: 'User not found or wrong password',
    ca: 'Usuari no trobat o contrasenya incorrecta',
    pt: 'Usuário não encontrado ou senha errada',
  },
  user_not_found: {
    es: 'Usuario no encontrado',
    en: 'User not found',
    ca: 'Usuari no trobat',
    pt: 'Usuário não encontrado',
  },
  day_min_length: {
    es: 'Mínimo 2 caracteres',
    en: 'Minimum 2 characters',
    ca: 'Mínim 2 caràcters',
    pt: 'Mínimo 2 caracteres',
  },
  day_max_length: {
    es: 'Máximo 2 caracteres',
    en: 'Minimum 2 characters',
    ca: 'Maximum 2 caràcters',
    pt: 'Máximo 2 caracteres',
  },
  year_min_length: {
    es: 'Mínimo 4 caracteres',
    en: 'Minimum 4 characters',
    ca: 'Mínim 4 caràcters',
    pt: 'Mínimo 4 caracteres',
  },
  year_max_length: {
    es: 'Máximo 4 caracteres',
    en: 'Minimum 4 characters',
    ca: 'Maximum 4 caràcters',
    pt: 'Máximo 4 caracteres',
  },
};

export const months = {
  jan: {
    es: 'Enero',
    en: 'January',
    ca: 'Gener',
    pt: 'Janeiro',
  },
  feb: {
    es: 'Febrero',
    en: 'February',
    ca: 'Febrer',
    pt: 'Fevereiro',
  },
  mar: {
    es: 'Marzo',
    en: 'March',
    ca: 'Març',
    pt: 'Março',
  },
  apr: {
    es: 'Abril',
    en: 'April',
    ca: 'Abril',
    pt: 'Abril',
  },
  may: {
    es: 'Mayo',
    en: 'May',
    ca: 'Maig',
    pt: 'Maio',
  },
  jun: {
    es: 'Junio',
    en: 'June',
    ca: 'Juny',
    pt: 'Junho',
  },
  jul: {
    es: 'Julio',
    en: 'July',
    ca: 'Juliol',
    pt: 'Julho',
  },
  aug: {
    es: 'Agosto',
    en: 'August',
    ca: 'Agost',
    pt: 'Agosto',
  },
  sep: {
    es: 'Septiembre',
    en: 'September',
    ca: 'Setembre',
    pt: 'Setembro',
  },
  oct: {
    es: 'Octubre',
    en: 'October',
    ca: 'Octubre',
    pt: 'Outubro',
  },
  nov: {
    es: 'Noviembre',
    en: 'November',
    ca: 'Novembre',
    pt: 'Novembro',
  },
  dec: {
    es: 'Diciembre',
    en: 'December',
    ca: 'Decembre',
    pt: 'Dezembro',
  },
};

export const getTranslationsObject = (language, userName, userEmail) => {
  const trasnlations = {
    loginTitle: translateObj(literals.loginTitle, language),
    loginSubtitle: translateObj(literals.loginSubtitle, language),
    loginButtonText: translateObj(literals.loginButtonText, language),
    register: translateObj(literals.register, language),
    or: translateObj(literals.or, language),
    email: translateObj(literals.email, language),
    emailPlaceholder: translateObj(literals.emailPlaceholder, language),
    password: translateObj(literals.password, language),
    passwordPlaceholder: translateObj(literals.passwordPlaceholder, language),
    forgotPassword: translateObj(literals.forgotPassword, language),
    signupPretitle: translateObj(literals.signupPretitle, language),
    signupTitle: translateObj(literals.signupTitle, language),
    signupSubtitle: translateObj(literals.signupSubtitle, language),
    signupButtonText: translateObj(literals.signupButtonText, language),
    haveAccount: translateObj(literals.haveAccount, language),
    login: translateObj(literals.login, language),
    repeatEmail: translateObj(literals.repeatEmail, language),
    repeatEmailPlaceholder: translateObj(literals.repeatEmailPlaceholder, language),
    createPassword: translateObj(literals.createPassword, language),
    createPasswordPlaceholder: translateObj(literals.createPasswordPlaceholder, language),
    socialTitle: translateObj(literals.socialTitle, language),
    registerWith: translateObj(literals.registerWith, language),
    name: translateObj(literals.name, language),
    namePlaceholder: translateObj(literals.namePlaceholder, language),
    lastname: translateObj(literals.lastname, language),
    lastnamePlaceholder: translateObj(literals.lastnamePlaceholder, language),
    country: translateObj(literals.country, language),
    countryPlaceholder: translateObj(literals.countryPlaceholder, language),
    postalCode: translateObj(literals.postalCode, language),
    postalCodePlaceholder: translateObj(literals.postalCodePlaceholder, language),
    phone: translateObj(literals.phone, language),
    phonePlaceholder: translateObj(literals.phonePlaceholder, language),
    phoneCodePlaceholder: translateObj(literals.phoneCodePlaceholder, language),
    birthdate: translateObj(literals.birthdate, language),
    dayPlaceholder: translateObj(literals.dayPlaceholder, language),
    monthPlaceholder: translateObj(literals.monthPlaceholder, language),
    yearPlaceholder: translateObj(literals.yearPlaceholder, language),
    preferencesPretitle: translateObj(literals.preferencesPretitle, language),
    preferencesTitle: translateObj(literals.preferencesTitle, language),
    preferencesSubtitle: translateObj(literals.preferencesSubtitle, language),
    preferencesButtonText: translateObj(literals.preferencesButtonText, language),
    festivalsPretitle: translateObj(literals.festivalsPretitle, language),
    festivalsTitle: translateObj(literals.festivalsTitle, language),
    festivalsSubtitle: translateObj(literals.festivalsSubtitle, language),
    festivalsButtonText: translateObj(literals.festivalsButtonText, language),
    completePretitle: translateObj(literals.completePretitle, language),
    completeTitle: translateObj(literals.completeTitle, language).replace('{username}', userName),
    completeSubtitle: translateObj(literals.completeSubtitle, language).replace('{userEmail}', userEmail),
    completeCtaText: translateObj(literals.completeCtaText, language),
    completeButtonText: translateObj(literals.completeButtonText, language),
  };

  return trasnlations;
};

export const getErrorTranslationsObject = (language) => {
  const translations = {
    required_field: translateObj(errorLiterals.required_field, language),
    invalid_email: translateObj(errorLiterals.invalid_email, language),
    password_length: translateObj(errorLiterals.password_length, language),
    email_do_not_match: translateObj(errorLiterals.email_do_not_match, language),
    day_min_length: translateObj(errorLiterals.day_min_length, language),
    day_max_length: translateObj(errorLiterals.day_max_length, language),
    year_min_length: translateObj(errorLiterals.year_min_length, language),
    year_max_length: translateObj(errorLiterals.year_max_length, language),
  };

  return translations;
};
