import gql from 'graphql-tag';

import { clientWeb } from '../graphqlAPI';

const GET_TICKET_CODE_QUERY = gql`
  query Get($scope: [String!], $fetchDate: String) {
    getTicketCode(scope: $scope, fetchDate: $fetchDate) {
      code
      scope
    } 
  }
`;

const getTicketCode = async (scope) => {
  try {
    const result = await clientWeb
      .query({
        query: GET_TICKET_CODE_QUERY,
        variables: { scope, fetchDate: new Date().getTime().toString() },
        fetchPolicy: 'no-cache',
      })
      .then(queryResult => queryResult)
      .catch((queryError) => {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error: ${queryError}`);
        return null;
      });
    const { data: { getTicketCode: getTicketCodeData } } = result;

    return getTicketCodeData;
  } catch (error) {
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: getTicketCode Error: ${error}`);
    return null;
  }
};

export default getTicketCode;
