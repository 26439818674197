import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { checkExpiredToken, initialUserProfileState, parseToken } from '../util/userProfile';

export const UserProfileContext = React.createContext();

export const UserProfileContextConsumer = UserProfileContext.Consumer;

const getCookieFromLocalStorage = () => (typeof localStorage === 'undefined' ? '' : localStorage.getItem(process.env.AUTH_TOKEN));

export const useUser = () => useContext(UserProfileContext);

export const checkIsAuth = () => (typeof localStorage === 'undefined' ? false : localStorage.getItem(process.env.AUTH_TOKEN));

const UserProfileContextProvider = ({ children, staticContext }) => {
  const [userProfileData, setUserProfileData] = useState(initialUserProfileState);
  const [isActivationBarOpen, setIsActivationBarOpen] = useState(false);
  const [isConditionBarOpen, setIsConditionBarOpen] = useState(false);
  const [token, setToken] = useState();

  const setNewAuthToken = (newToken) => {
    localStorage.setItem(process.env.AUTH_TOKEN, newToken);
    document.cookie = `${process.env.AUTH_TOKEN}=${newToken}; Secure; HttpOnly`;
    setToken(newToken);
  };

  const logoutUser = () => {
    document.cookie = `${process.env.AUTH_TOKEN}=; expires=Thu, 01 Jan 1970 00:00:01 GMT; Secure; HttpOnly`;
    if (typeof localStorage !== 'undefined') localStorage.removeItem(process.env.AUTH_TOKEN);
    setUserProfileData(initialUserProfileState);
    setToken();
  };

  const loadTokenForLS = async (cookieToken = null) => {
    let userToken;

    // NOTE: once all components start using setNewAuthToken this conditional should be removed
    if (typeof localStorage === 'undefined') userToken = cookieToken;
    else userToken = getCookieFromLocalStorage();
    if (!userToken) return logoutUser();

    const parsedToken = parseToken(userToken);

    const { isUserVerified, isUserUpToDateWithTermsAndConditions, tokenExpiration, userEmail } = parsedToken;

    setIsActivationBarOpen(!isUserVerified);
    setIsConditionBarOpen(isUserVerified ? !isUserUpToDateWithTermsAndConditions : false);

    // NOTE: Check if token has expired and renew it
    const renewedToken = await checkExpiredToken(tokenExpiration);
    if (renewedToken) return setNewAuthToken(renewedToken);

    setToken(userToken);
    return setUserProfileData(parsedToken);
  };

  // @deprecated
  const handleActivationBar = (isBarOpen) => {
    setIsActivationBarOpen(isBarOpen);
  };

  // @deprecated
  const handleConditionsBar = (isBarOpen) => {
    setIsConditionBarOpen(isBarOpen);
  };

  useEffect(() => {
    const { cookieToken } = staticContext;
    if (cookieToken) setToken(cookieToken);
    else setToken(getCookieFromLocalStorage());
  }, []);

  useEffect(() => {
    if (token) loadTokenForLS(token);
  }, [token]);

  const value = {
    ...userProfileData,
    isActivationBarOpen,
    isConditionBarOpen,
    handleActivationBar,
    handleConditionsBar,
    setNewAuthToken,
    token,
    loadTokenForLS,
    logoutUser,
  };

  return (
    <UserProfileContext.Provider value={value}>
      {children}
    </UserProfileContext.Provider>
  );
};

UserProfileContextProvider.propTypes = {
  staticContext: PropTypes.shape().isRequired,
  children: PropTypes.shape().isRequired,
};

export default UserProfileContextProvider;
