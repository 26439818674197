import gql from 'graphql-tag';

import { clientWeb } from '../graphqlAPI';

const USER_UPDATE_ROLES = gql`
  mutation Post($email: String!, $roles: [String]) {
    userUpdateRoles(
      email: $email,
      roles: $roles,
      ){
        token
        isValid
        error
    }
}`;

const userUpdateRoles = async ({
  email, roles = [],
}) => {
  try {
    const result = await clientWeb.mutate({
      mutation: USER_UPDATE_ROLES,
      variables: {
        email,
        roles,
      },
      fetchPolicy: 'no-cache',
    })
      .then(queryResult => queryResult)
      .catch((queryError) => {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error: ${queryError}`);
        return null;
      });

    const { data: { userUpdateRoles: validatedFormData } } = result;

    if (validatedFormData) {
      return validatedFormData;
    }
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: GraphQL Query Error: validatedFormData = null`);
    return null;
  } catch (error) {
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: userUpdateRoles Error: ${error}`);
    return null;
  }
};

export default userUpdateRoles;
