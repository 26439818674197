/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';

const TheaterModeBtn = ({
  onEnter,
  onExit,
  errorMessage,
  isOn,
  classes,
}) => {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    setIsActive(isOn);
  }, [isOn]);
  return (
    <div className={`theater-mode-button relative w-auto ${classes}`}>
      {errorMessage ? (
        <button
          type="button"
          onClick={() => alert(
            'Fullscreen is unsupported by this browser, please try another browser.',
          )
            }
        >
          {errorMessage}
        </button>
      ) : isActive ? (
        <button
          type="button"
          className="text-black font-americaMonoRegular text-center bg-psworld-red rounded px-2 py-1 text-xs text-black uppercase cursor-pointer trans hover:opacity-85"
          onClick={onExit}
        >
Theater mode
        </button>
      ) : (
        <button
          type="button"
          className="text-black font-americaMonoRegular text-center bg-grey-darkest rounded px-2 py-1 text-xs text-black uppercase cursor-pointer trans hover:opacity-85"
          onClick={onEnter}
        >
Theater mode
        </button>
      )}
    </div>
  );
};

TheaterModeBtn.propTypes = {
  onEnter: PropTypes.func,
  onExit: PropTypes.func,
  errorMessage: PropTypes.string,
  classes: PropTypes.string,
  isOn: PropTypes.bool,
};
TheaterModeBtn.defaultProps = {
  onEnter: null,
  onExit: null,
  errorMessage: null,
  isOn: false,
  classes: '',
};

export default TheaterModeBtn;
