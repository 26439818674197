/* eslint-disable import/no-extraneous-dependencies */
import React, { useContext, useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { withRouter, useHistory } from 'react-router-dom';
import { AuthWrapper as Wrapper } from '@primaveralabs/ui-auth-wrapper';
import 'Primavera/ui-themes/dist/primavera/index.css';
import 'Primavera/ui-auth-wrapper/dist/primavera.css';
import { useAuth, GoogleSSOButton } from '@primaveralabs/auth';
import { getCountryCallingCode, isSupportedCountry } from 'react-phone-number-input/input';
import Cookies from 'js-cookie';
import { literals, errorLiterals, months, getTranslationsObject, getErrorTranslationsObject } from './literals';
import slugify from '../../../util/slugify';
import translateObj from '../../../util/translateObj';
import { useRoutes } from '../../../context/routesContext';
import getCountryNamesByCode, { getCodeFromCountry, getCountryFromCode } from '../../../util/getCountryNamesByCode';
import UserPreferences from './UserPreferences';
import UserFestivals from './UserFestivals';
import TermsCheckbox from './TermsCheckbox';
import userCheckEmail from '../../../api/graphql/queries/userCheckEmail';
import { HeaderContext } from '../../../context/headerContext';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const AuthWrapper = ({ init, trigger, registration }) => {
  const { authOpen, setAuthOpen } = useContext(HeaderContext);
  const { token, user, actions } = useAuth();
  const location = useLocation();

  const { language } = useRoutes();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState(undefined);
  const [socialError, setSocialError] = useState(undefined);
  const [validSignupData, setValidSignupData] = useState(undefined);
  const [valueCountry, setValueCountry] = useState(undefined);
  const [valuePhoneCode, setValuePhoneCode] = useState(undefined);
  const [userPreferences, setUserPreferences] = useState([]);
  const [userFestivals, setUserFestivals] = useState([]);
  const [newsletterAccepted, setNewsletterAccepted] = useState(false);
  const [termsAndConditionsAccepted, setTermsAndConditionsAccepted] = useState(false);
  const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false);

  const browserLanguage = navigator?.language.split('-')[0].toLowerCase() || 'en';
  const errorTranslations = getErrorTranslationsObject(language);
  const translations = getTranslationsObject(language, user?.name, user?.email);

  const countryOptions = getCountryNamesByCode(language || 'en').map((c) => ({ label: c, value: c }));
  const phoneCodes = countryOptions.map((country) => {
    const countryCode = getCodeFromCountry(country.label, language);
    const phoneCode = isSupportedCountry(countryCode) ? getCountryCallingCode(countryCode) : null;
    if (!phoneCode) return null;
    return { country: country.label, countryCode, phoneCode };
  })
    .filter((c) => c !== null)
    .sort((a, b) => a.country - b.country);
  const phoneCodeOptions = phoneCodes.map((c) => ({ label: `+${c.phoneCode} - ${c.country}`, value: `${c.phoneCode}` })) || [];
  const monthOptions = Object.keys(months).map((key) => ({
    label: translateObj(months[key], language),
    value: translateObj(months[key], language),
  }));

  const handleSignupData = (data) => {
    const currentData = validSignupData ? { ...validSignupData } : {};
    Object.assign(currentData, { ...data });
    setValidSignupData(currentData);
  };

  const handleVerifyEmail = (values) => {
    setLoading(true);
    userCheckEmail(values.email).then((res) => {
      if (res.valid) {
        setFormError(undefined);
        setValidSignupData(values);
        setAuthOpen('profileData');
      } else {
        setFormError({
          error: 'email_already_in_use',
          message: translateObj(errorLiterals.email_already_in_use, language),
        });
      }
      setLoading(false);
    }).catch((err) => {
      setLoading(false);
      console.log(err);
    });
  };

  const handleSignup = (values) => {
    setLoading(true);
    const signupData = { ...values };

    const monthIndex = monthOptions.findIndex((o) => o.value === values.birthMonth);
    const monthNumber = `${monthIndex < 9 ? '0' : ''}${monthIndex}`;

    signupData.dateOfBirth = new Date(values.birthYear, monthNumber, values.birthDay);
    signupData.inputCountry = getCodeFromCountry(values.country, language);
    signupData.phone = `${values.phoneCode}${values.phoneNumber}`;
    signupData.customFields = {
      userPreferences: values.userPreferences,
      userFestivals: values.userFestivals,
    };
    signupData.newsletter = newsletterAccepted;
    signupData.language = browserLanguage;

    delete signupData.phoneCode;
    delete signupData.phoneNumber;
    delete signupData.repeatEmail;
    delete signupData.userPreferences;
    delete signupData.userFestivals;
    delete signupData.country;
    delete signupData.birthDay;
    delete signupData.birthMonth;
    delete signupData.birthYear;

    actions.signup(signupData).then((res) => {
      if (res.token) {
        setLoading(false);
        // NOTE: redirect to user-panel only qhen user is in home page
        if (location.pathname === `/${language}`) history.push(`/${language}/user-panel`);
        if (signupData.oauthId) setAuthOpen();
        else setAuthOpen('complete')
      }
    }).catch((err) => {
      setLoading(false);
      console.log(err);
    });
  };

  const handleSocialSignup = (res) => {
    setSocialError(undefined);
    if (res && !res.hasAccount && res.details) {
      const country = getCountryFromCode(res.details.country, language);
      handleSignupData({
        email: res.details.email,
        name: res.details.display_name,
        spotifyToken: res.details.spotifyToken,
        oauthId: res.details.userID,
        country,
      });
      setAuthOpen('profileData');
    } else {
      setAuthOpen(undefined);
    }
  };

  const handleSocialError = (err) => {
    setSocialError({
      error: err.cause,
      message: err.cause === 'emailAlreadyInUse' ? translateObj(errorLiterals.email_already_in_use, language) : err.message,
    });
  };

  const handleLogin = (values, helpers) => {
    setLoading(true);
    actions.login(values).then(() => {
      setFormError(undefined);
      setLoading(false);
      helpers.resetForm();
      setAuthOpen(undefined);
      // NOTE: redirect to user-panel only qhen user is in home page
      if (location.pathname === `/${language}`) history.push(`/${language}/user-panel`);
    }).catch((err) => {
      const errSlug = slugify(err.cause);
      const slug = errSlug.replaceAll('-', '_');
      setFormError({
        error: slug,
        message: translateObj(errorLiterals[slug], language),
      });
      setLoading(false);
    });
  };

  const handleComplete = () => {
    Cookies.set('verified', user?.verified);
    setAuthOpen(undefined);
  };

  useEffect(() => {
    if (valueCountry) {
      const phoneCode = phoneCodes.find((c) => c.country === valueCountry);
      if (phoneCode) {
        const phoneCodeValue = phoneCodeOptions.find((o) => o.value === phoneCode.phoneCode && o.label.includes(valueCountry))?.value;
        setValuePhoneCode(phoneCodeValue);
      }
    }
  }, [valueCountry]);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const registrationParam = urlSearchParams.get('registration');
    if (!token && registrationParam && ['login', 'signup'].includes(registrationParam)) {
      setAuthOpen(registrationParam);
    }
  }, []);

  return (
    <Wrapper
      trigger={trigger}
      init={init}
      open={authOpen}
      onOpenChange={(value) => {
        setFormError(undefined);
        setSocialError(undefined);
        if (value !== authOpen) {
          setAuthOpen(value);
        }
      }}
      spotifyCallback={() => actions.spotifySSO().then((res) => {
        handleSocialSignup(res);
      }).catch((err) => {
        handleSocialError(err);
      })}
      appleCallback={() => actions.appleSSO().then((res) => {
        handleSocialSignup(res);
      }).catch((err) => {
        handleSocialError(err);
      })}
      googleTrigger={(
        <GoogleSSOButton
          style={{ width: '100%', height: '100%', borderRadius: '100%' }}
          resultCallback={(res) => {
            if (res.isValid) {
              handleSocialSignup(res.data);
            } else {
              handleSocialError(res.error);
            }
          }}
        />
)}
      loginCallback={(values, helpers) => handleLogin(values, helpers)}
      verifyEmailCallback={(values) => handleVerifyEmail(values)}
      onChangeValueCountry={(v) => setValueCountry(v)}
      valuePhoneCode={valuePhoneCode}
      signupCallback={(values) => handleSignup(values)}
      signupValues={validSignupData}
      loading={loading}
      submitError={formError?.message}
      submitErrorSocial={socialError?.message}
      disableSignupButton={!privacyPolicyAccepted || !termsAndConditionsAccepted}
      preferencesContent={(
        <UserPreferences
          selectionCallback={(selection) => setUserPreferences(selection)}
          initArtists={registration.artists}
          genres={registration.genres}
        />
      )}
      userPreferences={userPreferences}
      festivalsContent={(
        <div>
          <UserFestivals
            selectionCallback={(selection) => setUserFestivals(selection)}
            festivals={registration.festivals}
          />
          <h3 className="font-americaRegular uppercase text-11px sm:text-xs tracking-wide mt-6 mb-3">
            {translateObj(literals.permissions, language)}
          </h3>
          <p className="font-americaRegular text-xs mb-4 text-gray58">{translateObj(literals.termsTip, language)}</p>
          <div className="mb-20 sm:mb-10">
            <TermsCheckbox
              id="termsAndConditions"
              label={(
                <label htmlFor="termsAndConditions" className="cursor-pointer font-americaRegular text-xs sm:text-sm text-black">
                  {translateObj(literals.read, language)}
                  <a
                    className="text-black underline"
                    href={`${language}/user-register-conditions-for-primavera-sound`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {translateObj(literals.useConditions, language)}
                  </a>
                  {translateObj(literals.andAccept, language)}
                  {language === 'en' ? (
                    <>
                      {translateObj(literals.fromPrimavera)}
                      <a
                        className="text-black underline"
                        href={`${language}/primavera-sound-festival-terms-and-conditions`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {translateObj(literals.termsAndConditions)}
                      </a>
                    </>
                  ) : (
                    <>
                      <a
                        className="text-black underline"
                        href={`${language}/primavera-sound-festival-terms-and-conditions`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {translateObj(literals.termsAndConditions, language)}
                      </a>
                      {translateObj(literals.fromPrimavera, language)}
                    </>
                  )}
                </label>
              )}
              onClick={() => setTermsAndConditionsAccepted(!termsAndConditionsAccepted)}
              checked={termsAndConditionsAccepted}
            />
            <TermsCheckbox
              id="privacyPolicy"
              label={(
                <label htmlFor="privacyPolicy" className="cursor-pointer font-americaRegular text-xs sm:text-sm text-black">
                  {translateObj(literals.readAndAccept, language)}
                  <a
                    className="text-black underline"
                    href={`${language}/privacy-policy`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {translateObj(literals.privacyPolicy, language)}
                  </a>
                </label>
              )}
              onClick={() => setPrivacyPolicyAccepted(!privacyPolicyAccepted)}
              checked={privacyPolicyAccepted}
            />
            <TermsCheckbox
              id="newsletter"
              label={translateObj(literals.newsletter, language)}
              onClick={() => setNewsletterAccepted(!newsletterAccepted)}
              checked={newsletterAccepted}
            />
          </div>
        </div>
          )}
      userFestivals={userFestivals}
      forgotPasswordUrl="/recover-password"
      // verifyEmailUrl="/" // ---> temporary hidden link
      completeCallback={() => handleComplete()}
      countryItems={countryOptions}
      phoneCodeItems={phoneCodeOptions}
      monthItems={monthOptions}
      literals={translations}
      errorLiterals={errorTranslations}
    />
  );
};

AuthWrapper.propTypes = {
  init: PropTypes.string || undefined,
  trigger: PropTypes.node || undefined,
  registration: PropTypes.shape(),
};

AuthWrapper.defaultProps = {
  init: 'login',
  trigger: undefined,
  registration: { artists: [], genres: [], festivals: [] },
};

export default withRouter(AuthWrapper);
