const getQueryVariable = (query, variable) => {
  const queryParams = query.split('&');
  for (let i = 0; i < queryParams.length; i++) {
    const pair = queryParams[i].split('=');
    if (decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1]);
    }
  }
  return false;
};

export default getQueryVariable;
