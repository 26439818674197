const validateProfileForm = (values, fieldValidationMap) => {
  const { city, inputCountry, language, lastname, name, password, repeatPassword } = values;
  const errors = {};

  if (!name) {
    errors.name = 'Required';
  }
  if (!lastname) {
    errors.lastname = 'Required';
  }
  if (!city) {
    errors.city = 'Required';
  }
  if (!inputCountry) {
    errors.inputCountry = 'Required';
  }
  if (!language) {
    errors.language = 'Required';
  }
  if (password) {
    if (!/^(?=.*[A-Z])(?=.*[@$!%*#?&]).{8,}$/i.test(password)) {
      errors.password = 'Password must contain at least 8 characters, one uppercase and one special case character';
    }
  }
  if (repeatPassword) {
    if (repeatPassword !== password) {
      errors.repeatPassword = 'Password do not match';
    }
  }

  if (fieldValidationMap) {
    fieldValidationMap.forEach((value, key) => {
      if (value.required && !values[key]) errors[key] = value.message;
    });
  }

  return errors;
};

export default validateProfileForm;
