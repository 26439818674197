/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Tag from '../Buttons/Tag';
import TitleLineH2 from '../Text/TitleLineH2';
import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';
import Link from '../../../util/link';
import imageModifier from '../helpers/imageModifier';
import translateObj from '../../../util/translateObj';
import NewsletterLayout from './Layouts/Newsletter';

const LauncherItemSingle = ({
  itemLayout, url, image, title, tags, match, changeCategory, isBanner, isNews, isHalf, layout, subtitle, isFullWidth,
}) => (
  <RoutesContext.Consumer>
    {({ language: contextLanguage, enteredFrom }) => {
      const localization = getLocalization(contextLanguage, enteredFrom, match);
      const urlWithSlash = url[0] === '/' || /^https?:\/\//.test(url) ? url : `/${url}`;
      const backgroundStyle = {
        backgroundImage: `url(${imageModifier(translateObj(image, localization.language), '1440x620')})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      };
      const imageSize = isHalf ? '1440x620' : '1000x1000';

      const renderSingleLayout = () => (
        <div>
          <div className={`relative border-t border-transparent launcherItemData ${itemLayout} ${isNews && !isHalf ? 'single-news' : ''} ${isBanner ? 'sliderComponent' : ''}`}>
            <Link localizationpath={localization.path} to={urlWithSlash} aria-label={translateObj(title, localization.language)}>
              {isBanner
                ? (
                  <div
                    key={image.en}
                    className="sliderBackground backgroundImgGray w-full sliderComponent overflow-hidden sm:h-launcherSingle-sm md:h-launcherSingle-md lg:h-launcherSingle-lg llg:h-launcherSingle-llg"
                    style={backgroundStyle}
                  />
                ) : (
                  <div className="launcherItemImg relative">
                    <img className="min-w-full" src={imageModifier(translateObj(image, localization.language), imageSize)} alt={translateObj(title, localization.language)} />
                  </div>
                )}
            </Link>
            <div className={`absolute pin-l pin-b ${!isHalf ? 'md:p-10' : 'md:p-4'} ${isFullWidth ? 'lg:p-10' : 'lg:p-4'} llg:p-10 text-white sm:p-5 w-full z-50 `}>
              {/* {date && <span className="font-americaMonoRegular text-14">{date}</span>} */}
              <Link localizationpath={localization.path} to={urlWithSlash} tabIndex="-1">
                {title && <TitleLineH2 text={title} margin="my-6 lg:my-4 hover:underline w-full" color="white" tabIndex="-1" />}
              </Link>
              {tags && <div className="flex flex-wrap">{tags.map((tag, i) => <Tag changeCategory={tag => changeCategory(tag)} text={tag} key={`${tag}-${i}`} category={tag} />)}</div>}
            </div>
          </div>
        </div>
      );
      return (
        <>
          {layout !== 'newsletter'
            ? renderSingleLayout() : <NewsletterLayout {...{ itemLayout, isBanner, isNews, isHalf, isFullWidth, image, backgroundStyle, title, subtitle, imageSize, tags }} />}
        </>
      );
    }}
  </RoutesContext.Consumer>
);

LauncherItemSingle.propTypes = {
  itemLayout: PropTypes.string.isRequired,
  layout: PropTypes.string,
  match: PropTypes.string.isRequired,
  title: PropTypes.shape().isRequired,
  isBanner: false,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
  }).isRequired,
  date: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string.isRequired),
  changeCategory: PropTypes.func,
  isFullWidth: PropTypes.bool,
}.isRequired;

export default withRouter(LauncherItemSingle);
