import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';
import Link from '../../util/link';
import translateObj from '../../util/translateObj';
import { useRoutes } from '../../context/routesContext';

const ARIA_LABEL_LANGUAGE = {
  en: {
    en: 'Language selector: English',
    es: 'Language selector: Spanish',
    ca: 'Language selector: Catalan',
    pt: 'Language selector: Portuguese',
  },
  es: {
    en: 'Selector de idioma: Inglés',
    es: 'Selector de idioma: Español',
    ca: 'Selector de idioma: Catalán',
    pt: 'Selector de idioma: Portugués',
  },
  ca: {
    en: 'Selector d\'idioma: Anglès',
    es: 'Selector d\'idioma: Castellà',
    ca: 'Selector d\'idioma: Català',
    pt: 'Selector d\'idioma: Portuguès',
  },
  pt: {
    en: 'Seletor de idioma: Inglês',
    es: 'Seletor de idioma: Espanhol',
    ca: 'Seletor de idioma: Catalão',
    pt: 'Seletor de idioma: Português',
  },
};


const stylesContainer = (parent) => {
  if (parent === 'header') {
    return 'hidden sm:flex md:flex border-black border-t-1 border-dashed pt-6 md:justify-center';
  }
  return 'flex';
};

const LanguageSelector = ({
  mainParent, languages, match, location, style = {},
}) => {
  const { language } = useRoutes();
  const currentLanguageAriaLabel = translateObj(ARIA_LABEL_LANGUAGE, language);

  return (
    <div parent={mainParent} className={`sm:w-full sm:justify-center justify-end items-center mb-6 xs:mt-4 sm:mt-1 w-full h-auto ${style} sm:order-2 md:pr-0 ${stylesContainer(mainParent)}`}>
      {
          languages.map((lang, i) => {
            // check what element is render, the last one musn't have border
            let border = 'border-white border-r';
            if (i === languages.length - 1) {
              border = '';
            } else {
              border = 'border-white border-r';
            }
            return (
              <Fragment key={lang.url}>
                {match.path.includes('radio')
                  ? (
                    <Link
                      to={`/radio${match.path.includes('shows') ? '/shows' : ''}${match.path.includes('explore') ? '/explore' : ''}${match.params.channel ? `/${match.params.channel}` : ''}${match.params.post ? `/${match.params.post}` : ''}${match.params.episode ? `/${match.params.episode}` : ''}`}
                      localizationpath={lang.url}
                      target="_self"
                      className={`font-americaMonoRegular uppercase  tracking-submenu px-3 text-xs sm:border-transparent sm:pt-4 ${style || 'text-black border-black'} ${border}`}
                      aria-label={currentLanguageAriaLabel[lang.lang.toLowerCase()]}
                    >
                      {lang.lang && <span className="text-white block">{lang.lang}</span>}
                    </Link>
                  )
                  : (
                    <>
                      {match.params.assetSlugName
                        ? (
                          <Link
                            to={`/share/${match.params.assetSlugName}`}
                            localizationpath={lang.url}
                            target="_self"
                            className={`font-americaMonoRegular uppercase tracking-submenu px-3 text-xs sm:border-transparent sm:pt-4 ${style || 'text-black border-black'} ${border}`}
                            aria-label={(currentLanguageAriaLabel[lang.lang.toLowerCase()])}
                          >
                            {lang.lang && <span className="text-white block">{lang.lang}</span>}
                          </Link>
                        )
                        : (
                          <Link
                            to={`${match.params.section ? `/${match.params.section}` : ''}${match.params.post ? `/${match.params.post}` : ''}${location && location.search ? `${location.search}` : ''}`}
                            localizationpath={lang.url}
                            target="_self"
                            className={`font-americaMonoRegular uppercase tracking-submenu px-3 text-xs sm:border-transparent sm:pt-4 ${style || 'text-black border-black'} ${border}`}
                            aria-label={(currentLanguageAriaLabel[lang.lang.toLowerCase()])}
                          >
                            {lang.lang && <span className="text-white block">{lang.lang}</span>}
                          </Link>
                        )}
                    </>
                  )
                }
              </Fragment>
            );
          })
        }
    </div>
  );
};

LanguageSelector.propTypes = {
  match: PropTypes.shape().isRequired,
  location: PropTypes.shape().isRequired,
  mainParent: PropTypes.string,
  languages: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
  style: PropTypes.string,
};

LanguageSelector.defaultProps = {
  style: '',
  mainParent: '',
  languages: [
    {
      lang: 'ES',
      url: '/es',
    },
    {
      lang: 'EN',
      url: '/en',
    },
    {
      lang: 'CA',
      url: '/ca',
    },
    {
      lang: 'PT',
      url: '/pt',
    },
  ],
};

export default withRouter(LanguageSelector);
