import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';

import ComponentIterator from '../../../containers/ComponentIterator';
import imageModifier from '../helpers/imageModifier';
import translateObj from '../../../util/translateObj';
import useResponsiveUtility from '../../../util/responsiveUtility';
import { useRoutes } from '../../../context/routesContext';
import { LinkWrapper } from '../../../util/link';

const IMAGE_SIZE = '2880x1240';
const IMAGE_SIZE_MD = '768x510';
const IMAGE_SIZE_SM = '414x420';

const getImageSize = (deviceScreen) => {
  switch (deviceScreen) {
    case 'sm': return IMAGE_SIZE_SM;
    case 'md': return IMAGE_SIZE_MD;
    default: return IMAGE_SIZE;
  }
};

const SliderBannerDestination = ({ image, left, right, url, showOpacity }) => {
  const [mobileArr, setMobileArr] = useState([...left, ...right]);
  const { language, path } = useRoutes();
  const { deviceScreen } = useResponsiveUtility();
  const areSlidesShort = false;
  const imageSrc = translateObj(image, language);

  const backgroundStyle = {
    backgroundImage: `url(${imageModifier(imageSrc, getImageSize(deviceScreen))})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  };

  useEffect(() => {
    const linkButton = mobileArr.find(el => el.type === 'LinkButtonRounded');
    if (linkButton) {
      const arr = mobileArr;
      arr.push(arr.splice(arr.indexOf(linkButton), 1)[0]);
      setMobileArr(arr);
    }
  }, []);

  return (
    <div key={imageSrc} className={`${!areSlidesShort ? 'h-screen80' : 'h-screen40'} ${showOpacity ? 'sliderComponent' : ''} md:h-carouselMD sm:h-carouselSM w-full relative flex flex-col justify-end overflow-hidden min-h-screen40`}>
      <LinkWrapper url={url} path={path}>
        <div
          className={`${!areSlidesShort ? 'h-screen80' : 'h-screen40'} md:h-carouselMD sm:h-carouselSM w-full absolute pin-l pin-b`}
          style={backgroundStyle}
        />
        <div className="text-white flex items-end h-full relative" style={{ zIndex: 2 }}>
          <div className={`flex justify-around sm:flex-col sm:justify-end w-full xl:px-36 md:px-16 lg:px-26 sm:px-7 h-full items-end ${areSlidesShort ? 'pb-12' : 'pb-20'} sm:pb-13 md:pb-16`}>
            {deviceScreen !== 'sm'
              ? (
                <>
                  {left && (
                    <div className="w-2/3 h-full flex flex-col justify-end mr-12 sm:mr-0 sm:hidden max-w-lg">
                      <div className="div">
                        <ComponentIterator components={left} slugName={imageSrc} />
                      </div>
                    </div>
                  )}
                  {right && (
                    <div className="w-1/3 sm:w-full sm:hidden" style={{ marginBottom: '3px' }}>
                      <ComponentIterator components={right} slugName={imageSrc} />
                    </div>
                  )}
                </>
              )
              : (
                <div className="w-full sm:block hidden">
                  <ComponentIterator components={mobileArr} slugName={imageSrc} />
                </div>
              )
            }
          </div>
        </div>
      </LinkWrapper>
    </div>
  );
};

SliderBannerDestination.propTypes = {
  image: PropTypes.shape({}).isRequired,
  right: PropTypes.arrayOf(PropTypes.shape()),
  left: PropTypes.arrayOf(PropTypes.shape()),
  url: PropTypes.string,
  showOpacity: PropTypes.bool,
};

SliderBannerDestination.defaultProps = {
  right: null,
  left: null,
  url: null,
  showOpacity: true,
};

export default SliderBannerDestination;
