/* eslint-disable react/no-multi-comp */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Formsy from 'formsy-react';
import {
  TweenMax, TimelineMax, Power3, Power1,
} from 'gsap';

import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';
import translateObj from '../../util/translateObj';

import postNewsletterForm from '../../api/graphql/mutations/postNewsletterForm';

import FormNewsletterInput from './Form/FormNewsletterInput';
import withAllContexts from '../../context/withAllContexts';

class NewsletterSubscribeWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canSubmit: false,
      didSubmit: false,
      error: false,
      isValidStatus: '',
    };

    this.childrenComponent = null;
    this.buttonTween = new TimelineMax();
    this.buttonLabelTween = new TimelineMax();
  }

  componentWillMount = () => {
    const {
      children: {
        props: { components: childrenComponents },
      },
    } = this.props;

    const extractedComponent = childrenComponents[0];

    this.childrenComponent = extractedComponent;
  };

  submitForm = (formData) => {
    const { location, origin, scopes } = this.props;
    const language = location.pathname.split('/')[1] || 'en';
    const formDataWithLang = {
      ...formData,
      language,
      origin,
      scopes,
    };

    this.setState({
      canSubmit: false,
    });

    postNewsletterForm({ ...formDataWithLang }).then((response) => {
      if (!response.isValid) {
        const emailErrorsArray = ['invalid', 'short', 'long'];
        let isValidStatus = 'error';
        if (emailErrorsArray.includes(response.email)) {
          isValidStatus = 'emailError';
        }
        this.setState({
          error: true,
          isValidStatus,
        });
      } else {
        this.setState({
          isValidStatus: 'valid',
        });
      }
      this.setState({ didSubmit: true });
      this.animateButton();
    });
  };

  animateButton = () => {
    this.buttonLabelTween.stop();

    this.buttonTween
      .to(this.submitButton, 0.35, { x: -80, opacity: 0.3, ease: Power3.easeOut })
      .to(this.submitButton, 0.35, { x: -40, opacity: 0.0, ease: Power3.easeIn })
      .play();
  };

  animateButtonLabel = () => {
    TweenMax.to(this.submitButtonLabel, 0, { opacity: 1 });

    this.buttonLabelTween
      .to(this.submitButtonLabel, 0.4, { opacity: 0, ease: Power1.easeIn })
      .to(this.submitButtonLabel, 0.4, { opacity: 1, ease: Power1.easeOut })
      .repeat(-1)
      .play();
  };

  enableButton = () => {
    this.setState({ canSubmit: true });

    this.buttonLabelTween.stop();
    TweenMax.to(this.submitButtonLabel, 0, { opacity: 1 });

    this.animateButtonLabel();
  };

  disableButton = () => {
    this.setState({ canSubmit: false });

    this.buttonLabelTween.stop();
    TweenMax.to(this.submitButtonLabel, 0, { opacity: 1 });
  };

  resetForm = () => {
    this.setState({
      canSubmit: false,
      didSubmit: false,
      error: false,
      isValidStatus: '',
    }, () => {
      this.buttonLabelTween.stop();
      TweenMax.to(this.submitButtonLabel, 0, { opacity: 1 });
      TweenMax.to(this.submitButton, 0, { x: 0, opacity: 1 });
    });
  }

  render() {
    const { match, name, mainParent, headerContext } = this.props;

    const { text = null, placeholder, title } = this.childrenComponent.params;

    const {
      canSubmit, didSubmit, error, isValidStatus,
    } = this.state;

    return (
      <RoutesContext.Consumer>
        {({ language: contextLanguage, enteredFrom }) => {
          const localization = getLocalization(contextLanguage, enteredFrom, match);
          const inputStyle = 'py-2 px-4 md:py-1 md:px-2 h-50px md:h-8 md:w-24 md:text-xxs w-123px sm:text-right sm:w-110px sm:flex sm:justify-around  xl:block';
          const definedLangPlaceholder = translateObj(placeholder, localization.language);
          // if (!placeholder[localization.language]) { definedLangPlaceholder = placeholder.en; } else { definedLangPlaceholder = placeholder[localization.language]; }
          return (
            <Formsy
              ref={(c) => {
                this.form = c;
                return this.form;
              }}
              name={name}
              onValidSubmit={this.submitForm}
              onValid={this.enableButton}
              onInvalid={this.disableButton}
              className={`mb-4 sm:w-full sm:order-1 sm:mt-0 sm:relative absolute ${mainParent === 'footer' ? 'w-1/3 mt-20' : 'pin-r w-1/2 mt-18'}`}
              tabIndex={headerContext.megaMenuIsOpenDesktop ? '-1' : '0'}
            >
              <div
                data-name="input-text-button"
                className={`${mainParent === 'footer' ? 'text-left' : 'text-right'}`}
                tabIndex={headerContext.megaMenuIsOpenDesktop ? '-1' : '0'}
              >
                {title && <span className={`text-black text-xs sm:text-center ${mainParent === 'footer' ? 'text-left' : 'text-right'} font-americaMonoRegular uppercase mb-4 w-full inline-block mt-2 md:mt-0 md:ml-5 sm:mt-4 md:w-ticket-sm`}>{title[localization.language]}</span>}
                <div className="relative md:pl-5">
                  <FormNewsletterInput
                    ref={(c) => {
                      this.email = c;
                      return this.email;
                    }}
                    name="email"
                    placeholder={!didSubmit ? definedLangPlaceholder : ''}
                    didSubmit={didSubmit}
                    validations={{ isEmail: true, maxLength: 50 }}
                    validationErrors=""
                    error={error}
                    isValidStatus={isValidStatus}
                    isLauncheritem={false}
                    language="es"
                    required
                    resetForm={this.resetForm}
                    tabIndex={headerContext.megaMenuIsOpenDesktop ? '-1' : '0'}
                  />
                  <button
                    type="submit"
                    ref={(c) => {
                      this.submitButton = c;
                      return this.submitButton;
                    }}
                    disabled={!canSubmit}
                    className={`absolute pin-r pin-t pin-b leading-normal sm:flex sm:items-center font-americaMonoBold text-xs rounded-full border-2 border-black text-black uppercase ${inputStyle} ${mainParent === 'footer' && 'bg-white'} ${!canSubmit && 'text-gray200'} outline-none`}
                    // style={{ outline: 'none' }}
                    tabIndex={headerContext.megaMenuIsOpenDesktop ? '-1' : '0'}
                  >
                    <span
                      className="pl-2"
                      ref={(c) => {
                        this.submitButtonLabel = c;
                        return this.submitButtonLabel;
                      }}
                    >
                      {translateObj(text, localization.language)}

                    </span>
                    <span className="font-icon icon-arrow-right text-black text-xxs pl-3 pr-1 sm:pr-0" />
                  </button>
                </div>
              </div>
            </Formsy>
          );
        }}
      </RoutesContext.Consumer>
    );
  }
}

NewsletterSubscribeWidget.propTypes = {
  match: PropTypes.shape().isRequired,
  children: PropTypes.shape().isRequired,
  name: PropTypes.string,
  origin: PropTypes.string,
  scopes: PropTypes.arrayOf(PropTypes.string),
  mainParent: PropTypes.string,
  headerContext: PropTypes.shape().isRequired,
};

NewsletterSubscribeWidget.defaultProps = {
  name: '',
  origin: 'web',
  scopes: ['scope_general'],
  mainParent: PropTypes.string,
};

export default withRouter(withAllContexts(NewsletterSubscribeWidget));
