const translation = {
  titleSessions: {
    en: 'Mentoring sessions',
    es: 'Mentoring sessions',
    ca: 'Mentoring sessions',
    pt: 'Mentoring sessions',
  },
  titleReunions: {
    en: 'Your meetings',
    es: 'Tus reuniones',
    ca: 'Ls teves reunions',
    pt: 'Suas reuniões',
  },
  filtersTitle: {
    en: 'Filter',
    es: 'Filtrar',
    ca: 'Filtrar',
    pt: 'Filtrar',
  },
  filtersAllSessions: {
    en: 'All sessions',
    es: 'Todas las sesiones',
    ca: 'Totes les sessions',
    pt: 'Todas as sessões',
  },
  filtersReservedSessions: {
    en: 'All my sessions',
    es: 'Todas mis sesiones',
    ca: 'Totes les meves sessions',
    pt: 'Todas as minhas sessões',
  },
  filtersAllReunions: {
    en: 'All meetings',
    es: 'Todas las reuniones',
    ca: 'Totes les reunions',
    pt: 'Todas as reuniões',
  },
  filtersConfirmedReunions: {
    en: 'Confirmed only',
    es: 'Solo las confirmadas',
    ca: 'Només les confirmades',
    pt: 'Só os confirmados',
  },
  filtersInvitedReunions: {
    en: 'Invitations received',
    es: 'Invitaciones recibidas',
    ca: 'Invitacions rebudes',
    pt: 'Convites recebidos',
  },
  filtersDate: {
    en: 'Sort by date',
    es: 'Ordenar por fecha',
    ca: 'Ordenar per data',
    pt: 'Classificar por data',
  },
  filtersLocation: {
    en: 'All venues',
    es: 'Todos los lugares',
    ca: 'Tots els llocs',
    pt: 'Todos os lugares',
  },
  filtersSortLocation: {
    en: 'Sort by place',
    es: 'Ordenar por lugar',
    ca: 'Ordenar per lloc',
    pt: 'Classificar por lugar',
  },
  filtersSortAsc: {
    en: 'Upwards',
    es: 'Ascendente',
    ca: 'Ascendent',
    pt: 'Para cima',
  },
  filtersSortDesc: {
    en: 'Downwards',
    es: 'Descendente',
    ca: 'Descendent',
    pt: 'Para baixo',
  },
  search: {
    en: 'Search',
    es: 'Buscar',
    ca: 'Cerca',
    pt: 'Procurar',
  },
  buttonLoadMore: {
    en: 'Load more',
    es: 'Cargar más',
    ca: 'Carregar més',
    pt: 'Carregue mais',
  },
  buttonNewReunion: {
    en: 'New meeting',
    es: 'Nueva reunión',
    ca: 'Nova reunió',
    pt: 'Nova reunião',
  },
};

export default translation;
