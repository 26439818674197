import React, { useState, useEffect, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import Swiper from 'react-id-swiper';
import imageModifier from './helpers/imageModifier';
import AssetImage from './AssetImage';
import AssetDescription from './AssetDescription';

const arrowStyles = {
  transform: 'translateY(-50%)',
  top: '50%',
  fontWeight: '100',
};

const CarouselWithThumbs = (props) => {
  const {
    images, currentSlide, location: { pathname }, history,
  } = props;
  const [gallerySwiper, getGallerySwiper] = useState(null);
  const [thumbnailSwiper, getThumbnailSwiper] = useState(null);

  const thumbnailSwiperParams = {
    getSwiper: getThumbnailSwiper,
    width: 199,
    spaceBetween: 8,
    slidesPerView: 'auto',
    touchRatio: 0.2,
    slideToClickedSlide: true,
    centerSlides: true,
  };
  const gallerySwiperParams = {
    getSwiper: getGallerySwiper,
    slidesPerView: 1,
    effect: 'fade',
    keyboard: {
      enabled: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  };
  useEffect(() => {
    if (
      gallerySwiper !== null
        && gallerySwiper.controller
        && thumbnailSwiper !== null
        && thumbnailSwiper.controller
    ) {
      gallerySwiper.controller.control = thumbnailSwiper;
      thumbnailSwiper.controller.control = gallerySwiper;
    }

    if (gallerySwiper && thumbnailSwiper) {
      gallerySwiper.slideTo(currentSlide);
    }
  }, [gallerySwiper, thumbnailSwiper]);

  return (
    <>
      {images ? (
        <>
          <div className="flex justify-center flex-column overflow-hidden relative modal-gallery-slider" style={{ height: '78vh' }}>
            <Swiper {...gallerySwiperParams}>
              {
            images.map(image => (
              <div key={image.assetSlugName} className="sm:w-full xl:w-1/2 lg:w-1/2 flex flex-col bg-black h-100 justify-center">
                <div className="relative">
                  <AssetImage {...image} />
                </div>
                <AssetDescription {...image} />
              </div>
            ))
          }
            </Swiper>
          </div>
          <div style={{ height: '22vh' }} className="thumbnail-slider flex flex-col justify-end pb-2">
            <Swiper {...thumbnailSwiperParams}>
              {images.map(image => (
                <div key={image.assetSlugName} style={{ width: '207px', height: '134px' }}>
                  <img src={imageModifier(image.url, '207x134')} className="block" alt={image.fileReadableName.en} />
                </div>
              ))}
            </Swiper>
          </div>
        </>
      ) : null}
    </>
  );
};

CarouselWithThumbs.propTypes = {
  images: PropTypes.arrayOf(PropTypes.shape({})),
  currentSlide: PropTypes.number,
  location: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
};

CarouselWithThumbs.defaultProps = {
  images: [],
  currentSlide: null,
};

export default withRouter(CarouselWithThumbs);
